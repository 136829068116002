import React, { useState } from "react";
import { LoaderBar } from "../../../../../Components/Common/LoaderBar";
import { FilterBadges } from "./utils";


const SubCard = ({ title, count, allData, type, setSubCardKey, subCardFilter, isLoading }) => {
  const handleActiveSubCard = () => {
    if(isLoading) return;
    setSubCardKey(type,{label:allData?.name, value:allData?.id})
  };
  return (
    <div
      class={`subcard-item w-100 d-flex align-items-center pointer text-capitalize ${subCardFilter[type]?.find(d => d?.value+"" === allData?.id+"") ? "subcard-item--active" : ""}`}
      onClick={handleActiveSubCard}
    >
      {title}
      <span class="ml-auto">{count}</span>
    </div>
  );
};
const TabSubCards = (props) => {
  const { subCardData, setSubCardKey, subCardFilter, isLoadingSubCard, isLoading = false } = props
  return (
    <div className="d-flex flex-column gap-15">
      {Object.keys(subCardData || {}).map((item, index) =>
        subCardData[item]?.length > 0 ? (
          <div key={index}>
            <h5>{FilterBadges[item] || item}</h5>
            <div className="form-row-sm">
              {(subCardData[item] || []).map((subItem, subIndex) => (
                <div className="col-md-6 mb-1" key={subIndex}>
                  <SubCard
                    title={subItem?.name || ""}
                    count={subItem?.count || 0}
                    allData={subItem}
                    type={item}
                    setSubCardKey={setSubCardKey}
                    subCardFilter={subCardFilter}
                    isLoading={isLoading}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null
      )}
      {isLoadingSubCard && <LoaderBar></LoaderBar>}
    </div>
  );
};

export default TabSubCards;
