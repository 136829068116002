import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getStorage } from '../../../../../../services';
import { convertFilterDataToStringArray, downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB, TypeDashBoardContext } from '../../helper';
import CounterCard from '../Common/CounterCard';
import SkeletonCard from '../Common/SkeletonCard';
import StackedBarChart from './StackedBarChart';

export default function CustomStackedBarChart(props) {
  const {
    title,
    description,
    startDate,
    endDate,
    reportType,
    isDownload,
    keysForStackedBarChart,
  } = props

  const { filters, chartLevelFilter, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext)
  const [stackedBarChartData, setStackedBarChartData] = useState({})
  const [isPopup, setIsPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fromDate, setFromDate] = useState(startDate)
  const [toDate, setToDate] = useState(endDate)
  const [filterPayload, setFilterPaylod] = useState(filters || {})

  const getDataForStackedBarChart = async (limit, from, to, isDownload = false, filterData) => {
    try {
      if (!isDownload) setIsLoading(true)

      const _startDate = from || fromDate
      const _endDate = to || toDate

      let payload = {
        chartType: reportType,
        fromDate: _startDate,
        toDate: _endDate
      }

      if(limit) payload.limit = limit
      if (isDownload) payload.isDownload = true
      payload.filter = convertFilterDataToStringArray(filterData) || {}
      let responseData = await getDataForChartFromDB(payload, reportType)

      if (!isDownload) setStackedBarChartData(responseData)
      setIsLoading(false)
      setIsDashboardFilterApplied(false)
      return responseData
    } catch (error) {
      console.log("🚀 ~ getDataForStackedBarChart ~ error:", error)
      setIsLoading(false)
      if (!isDownload) setStackedBarChartData({})
    }
  }

  const downloadCSV = async () => {
    const csvBuffer = await getDataForStackedBarChart(0, startDate, endDate, true, chartLevelFilter?.[reportType] ?? {})
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
  }

  const handleApplyFilterForChart = (payload) => {
    getDataForStackedBarChart(10, startDate, endDate, false, payload)
  }

  useEffect(() => {
    getDataForStackedBarChart(10)
  }, [])

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return
    setFromDate(startDate)
    setToDate(endDate)
    setFilterPaylod(filters)
    getDataForStackedBarChart(10, startDate, endDate, false, filters)
  }, [startDate, endDate, filters])

  useEffect(() => {
    if (!isDashboardFilterApplied) return
    getDataForStackedBarChart(10, startDate, endDate, false, filters)
  }, [isDashboardFilterApplied])

  if (isLoading){
    return <SkeletonCard style ={{ width: "100%", height: "100%"}}/>
  }

  return (
    <div className="bg-white rounded-5 d-flex flex-column h-100">
      <CounterCard
        title={title}
        description={description}
        count={stackedBarChartData?.count}
        percentageChange={stackedBarChartData?.percentageChange}
        startDate={startDate}
        endDate={endDate}
        fromExpandedCard={true}
        downloadCSV={downloadCSV}
        isPopup={isPopup}
        setIsPopup={setIsPopup}
        isDownload={isDownload}
        handleApplyFilterForChart={handleApplyFilterForChart}
        isDownloadEnabled={!stackedBarChartData?.reportData?.length}
        reportType={reportType}
      />
      <div className='flex-grow-1 overflow-hidden d-flex flex-column px-20'>
        <StackedBarChart
          data={stackedBarChartData?.reportData || []}
          downloadCSV={downloadCSV}
          isPopup={isPopup}
          setIsPopup={setIsPopup}
          keysForStackedBarChart={keysForStackedBarChart}
          reportType={reportType}
          startDate={startDate}
          endDate={endDate}
        />

        <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end py-10 pr-2 text-capitalize'>
          Last updated at: {`${moment(stackedBarChartData?.lastUpdatedAt || new Date())
            .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
        </div>
      </div>
    </div>
  )
}
