import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { IconClone } from "../../../../../Components/Common/Icons";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import AddRuleTable from "./AddRuleTable";
import { changePropertyName, getStorage, toastr, filterChargeCodesByScreenType } from "../../../../../services";
import QueryBuilder from "../../../../../Components/DynamicPricing/QueryBuilder";
import { useSelector } from "react-redux";
import { EFFECTIVE_LOAD_DATE } from "../../../CommonDynamicPay/constant";
import { Spinner } from "reactstrap";
import _ from "lodash";
import { getChargeRecords } from "../../service/chargeProfileService";
import { LoaderBar } from "../../../../../Components/Common/LoaderBar";
import "./addRule.scss";
import { radiusRate, nonRulesConstant } from "../../constants/chargeProfiles.js";
import { unitOfMeasure } from "../../service/customerRateService";
import { getCountryCode } from "../../../services";
import moment from "moment";
import { convertStatusToObj, } from "Components/DynamicPricing/utils";
import { useCallback } from "react";
import { mapOptionListToProfile } from "pages/tms/VendorTariffs/service/profileService";
import ProfileCondition from "pages/tms/Components/ProfileCondition";
import { useChargeCode } from "hooks/helpers/useChargeCode.js";

const AddRuleModal = ({ show, close, editSelectedData, handleSubmit, cloneNewChargeProfiles, handleEditChargeProfile, onEditComplete, isDisabledAllCustomerRateModal=false, isEditModal = false }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const isEditPopupOpen = isEditModal;
  const [newChargeProfile, setNewChargeProfile] = useState( 
    (isEditPopupOpen || editSelectedData?.isOneOffCharges)? 
      {...editSelectedData, autoAdd: editSelectedData.hasOwnProperty("autoAdd") ? editSelectedData?.autoAdd : true} : 
      {autoAdd: true, systemGenerated: false, isActive: true, isDeleted: false}
  );
  const { StatesReducer, HomeReducer } = useSelector(state => state);
  const { chargeCodeList, findChargeNameByCode } = useChargeCode();

  const timeZone = getStorage("timeZone");
  
  useEffect(() => {
    if(!Object.isObject(editSelectedData) && typeof editSelectedData === "string") {
      setIsFetching(true);

      getChargeRecords({limit: 1, query: {search: editSelectedData}})
        .then(data => {
          setNewChargeProfile( data?.[0] ?? {});
          setIsFetching(false);
        })
        .catch(err => setIsFetching(false));
    } else {
      if(Object.keys(editSelectedData?.ruleErrorMessages ?? {})?.length) setFormErrors({...formErrors, ...editSelectedData.ruleErrorMessages});
    }

    getCountryCode().then(countries => {
      const allCountryCodes = changePropertyName(countries);
      setCountries(allCountryCodes);
    });
  }, []);

  const handleChange = useCallback((key, val) => {
    if (key === "exactEvents") {
      
      const _exactEvents = newChargeProfile?.exactEvents ?? [];
      const fieldId = val?._selectFieldId;

      delete val?.fieldId;
      _exactEvents[fieldId] = val;

      setNewChargeProfile((prev) => {
        return {...prev, eventLocationRule:null, eventLocationRules:[],  fromEvent:null, toEvent:null,  inEvent:null, [key]: [..._exactEvents]}
      })
    } else if (key === "toEvent") {
      const _toEvent = val?.map(d => {
        const splitVal = d?.split("/");
        return {to: splitVal?.[1], toType: splitVal?.[0]}
      });
      setNewChargeProfile((prev) => {
        return {...prev, eventLocationRule:null, eventLocationRules:[], inEvent:null, exactEvents:null, [key]: _toEvent}
      });
    }
    else if (key === "eventLocationRule") {
      const fieldId = val?._selectFieldId;
      const prevEventLocationRule = newChargeProfile[key] ?? {};

      if (fieldId === 0) {
        setNewChargeProfile((prev) => ({ ...prev, fromEvent:null, toEvent:null, eventLocationRules: [], [key]: (!prevEventLocationRule?.eventLocation && !val.event?.value) ? null : { ...prev[key], event: val.event?.value } }));
      } else if (fieldId === 1) {
        let eventLocation = mapOptionListToProfile(val.eventLocation, key, true);
        if(!Object.keys(eventLocation ?? {})?.length) eventLocation = null;
        setNewChargeProfile((prev) => ({ ...prev, fromEvent:null, toEvent:null, eventLocationRules: [], [key]: (!prevEventLocationRule?.event && !eventLocation?.name) ? null : { ...prev[key], eventLocation: eventLocation } }));
      }
    }
    else if (key === "eventLocationRules") {
      const fieldId = val?._selectFieldId;
      let multiEvent = Object.values(newChargeProfile[key] ?? []);

      if (fieldId === 0) { 
        if (!multiEvent?.length) {
          multiEvent = [{ event: val.event?.value, eventLocation: null }];
        } else if (val.index >= multiEvent.length) {
          multiEvent.push({ event: val.event?.value, eventLocation: null });
        } else { 
          multiEvent[val?.index].event = val.event?.value
        }
        setNewChargeProfile((prev) => {
           return { ...prev, fromEvent:null, toEvent:null, eventLocationRule:null, eventLocationRules: [...multiEvent ?? []] }
        });
      } else if (fieldId === 1) {
        const multiEventLocation = mapOptionListToProfile(val.eventLocation, key, true)
       
        if (!multiEvent?.length) {
          multiEvent = [{ event: null, eventLocation: multiEventLocation }];
        } else if (val.index >= multiEvent.length) {
          multiEvent.push({ event: null, eventLocation: multiEventLocation });
        } else { 
          multiEvent[val?.index].eventLocation = (multiEventLocation?.name ||  multiEventLocation?.profileType) ? multiEventLocation : null;
        }
        setNewChargeProfile((prev) => {
           return { ...prev, fromEvent:null, toEvent:null, eventLocationRule:null, eventLocationRules: [...multiEvent ?? []] }
        });
      } else if (fieldId === 2) {
        if (!multiEvent?.length) {  
          multiEvent = [{ event: null, eventTime: val?.eventTime?.value,  eventLocation: null }];
        } else if (val.index >= multiEvent.length) {
          multiEvent.push({ event: null, fromEvent:null, toEvent:null, eventTime: val?.eventTime?.value, eventLocation: null });
        } else {
          multiEvent[val?.index].eventTime = val.eventTime?.value
        }
        setNewChargeProfile((prev) => {
          return { ...prev, fromEvent:null, toEvent:null, eventLocationRule: null, eventLocationRules: [...multiEvent ?? []] }
        });
      }
    }
    else if (key === 'eventLocationRulesUpdate') {
      setNewChargeProfile((prev) => ({...prev,  eventLocationRules: val}));
    }
    else if (key === "fromEvent") {
      const splitVal = val?.split("/");
      const _fromEvent =  {from: splitVal?.[1], fromType: splitVal?.[0]}
      setNewChargeProfile((prev) => {
        return {...prev, eventLocationRule:null, eventLocationRules:[], inEvent:null, exactEvents:null, [key]: _fromEvent}
      });
    } else if (key === "inEvent") {
      setNewChargeProfile((prev) => {
        return {...prev, fromEvent: null, toEvent:null, exactEvents:null, [key]: val}
      });
    }
    else if(key === "charge") setNewChargeProfile({...newChargeProfile, ...val});
      
    else if (key === "effectiveDateParameter") {
      const charges = val
        ? [{ ...newChargeProfile.charges[0], effectiveStartDate: val.effectiveStartDate, effectiveEndDate: val.effectiveEndDate }]
        : [{ ...newChargeProfile.charges[0], effectiveStartDate: null, effectiveEndDate: null }];
      const effectiveDateParameter = val ? val : null;
      setNewChargeProfile({ ...newChargeProfile, charges, effectiveDateParameter });
    }
          
    else if(key === "unitOfMeasure") {
      let temp = _.cloneDeep(newChargeProfile ?? {});

      if(nonRulesConstant.includes(val)) temp = {...temp, fromEvent: null, toEvent: null };
      if(radiusRate?.includes(val)) temp = {...temp, effectiveDateParameter: null, unitOfMeasure: val,  charges: []};
      else temp = {...temp, unitOfMeasure: val,  charges: []};

      setNewChargeProfile(temp);
    }
      
    else if (key === "vendor") {
      const entityProfile = mapOptionListToProfile(val, key)
      setNewChargeProfile((prev) => {
        return {...prev,  [key]: entityProfile}
      });
    }
    else if (key === "eventLocation") {
      const eventLocation = mapOptionListToProfile(val, key)
      setNewChargeProfile({...newChargeProfile, [key]: eventLocation});
    }
   
    else setNewChargeProfile({ ...newChargeProfile, [key]: val });
    if(val) {
      const _formErrors = {...formErrors};

      delete _formErrors?.[key];
      if(key === "unitOfMeasure") {
        delete _formErrors?.percentageOf;
      }
      if (key === "vendor") {
        delete _formErrors?.driverPayGroup;
      }
      setFormErrors(_formErrors);
    }
  }, [newChargeProfile])

  const onSubmit = () => {
    let newChargeProfileTemp = _.cloneDeep(newChargeProfile ?? {});
    const {
      name, 
      chargeName,
      unitOfMeasure, 
      charges, 
      percentageOf,
      fromEvent, toEvent, inEvent, exactEvents,
      effectiveDateParameter,
      eventLocationRule, eventLocationRules
    } = newChargeProfileTemp;

    const eventLocationRulesTemp = eventLocationRules?.map((d) => ({
      event: d?.event,
      eventTime: d?.eventTime,
      eventLocation: d?.eventLocation,
    }));

    if(!Object.keys(newChargeProfileTemp.ruleErrorMessages ?? {}).length) {
      delete newChargeProfileTemp.ruleErrorMessages;
    }

    const _formErrors = { ...formErrors };
    const isRadiusRate = radiusRate?.includes(unitOfMeasure);

    // error validation
    if (!name) _formErrors["name"] = "Name is required.";
    if (!chargeName) _formErrors["charge"] = "Charge Name is required.";
    if (!unitOfMeasure) _formErrors["unitOfMeasure"] = "Unit of Measure is required.";
    if (unitOfMeasure?.includes("percentage") && !percentageOf?.length ) _formErrors["percentageOf"] = "Percentage Based On is required.";
    if((fromEvent && toEvent) || inEvent || exactEvents || eventLocationRule?.event || eventLocationRules?.length > 0) {
      delete _formErrors["rules"];
      delete _formErrors["inEvent"];
      delete _formErrors["exactEvents"];
      delete _formErrors["fromEvent"];
      delete _formErrors["toEvent"];
    }
    if (chargeName) delete _formErrors["chargeCode"]

    setFormErrors(_formErrors);

    if (Object.keys(_formErrors)?.length) return toastr.show("Please fill the required fields!", "error");
    if ( !charges?.length ) return toastr.show("At least one charge is required!", "error");
    if((effectiveDateParameter && charges?.length && charges?.some((d) => !d?.effectiveEndDate || !d?.effectiveStartDate))) return toastr.show("Please select charge date!", "error");

    // required field validation
    const isRequiredFieldValid = charges?.some((charge, i) => {
      let {amount} = charge;

      if(isNaN(parseInt(amount))) {
        toastr.show("Amount is required!", "error");
        return true;
      }

    });
    if(isRequiredFieldValid) return;

    if(isRadiusRate) {
      let {startValue, endValue} = charges?.at(-1);
      startValue = startValue ? parseFloat(startValue) : 0;
      endValue = endValue ? parseFloat(endValue) : 0;

      if(/undefined|null/?.test(startValue)) return toastr.show(`Start distance is required at last row !`, "error");
      if(!endValue ) return toastr.show("End distance required at last row!", "error");
      if(startValue >= endValue) return toastr.show("Start distance must be less than end distance at last row!", "error");
      const isStartOrEndInvalid = charges?.some((charge, i) => {
        if(i === charges?.length-1) return false;
        const {endValue: b} = charge;
        return startValue <= b;
      });
      if(isStartOrEndInvalid) return toastr.show("Start distance must be greater than end distance of above charges!", "error");
    }


    // rules validations
    if(     
      !isRadiusRate &&
      !nonRulesConstant.includes(unitOfMeasure)
    ) {
      const isRulesNotSelected =  !(eventLocationRule?.event || eventLocationRule?.eventLocation) && !eventLocationRulesTemp?.length && !(fromEvent || toEvent?.length) && !inEvent && !exactEvents?.length;
      if (
        isRulesNotSelected
      ) return toastr.show("Please select atleast one Rules!", "error");

      if(fromEvent && !toEvent?.length) return toastr.show("To Event is required!", "error");
      if(toEvent?.length && !fromEvent) return toastr.show("From Event is required!", "error");
      if(
        ![...radiusRate, "permile"].includes(newChargeProfileTemp?.unitOfMeasure) && 
        isRulesNotSelected &&
        !inEvent
      ) return toastr.show("In Event is required!", "error");
    }

    // events validation
    const totalEventLocationRules = eventLocationRulesTemp?.length ?? 0;
    const hasSomeEmptyEventLocationRules = eventLocationRulesTemp?.some(d => !(d?.event || d?.eventLocation));

    if(totalEventLocationRules && totalEventLocationRules <= 1) return toastr.show("At least two events are required!", "error");
    if(totalEventLocationRules && hasSomeEmptyEventLocationRules) return toastr.show("Please fill in at least one field (If Event or Event Location)", "error");

    const chargeProfileEventError = validateChargeProfileEvents({chargeProfile:newChargeProfileTemp});
    if(chargeProfileEventError){
      return toastr.show(chargeProfileEventError, "error");
    } 

    // payload filtering
    newChargeProfileTemp.charges = charges?.map((d) => {
        const updatedCharge = {...d};
        updatedCharge.minimumAmount = updatedCharge.minimumAmount ?? 0; 
        updatedCharge.freeUnits = updatedCharge.freeUnits ?? 0; 

        if(!effectiveDateParameter) {
          delete updatedCharge?.effectiveStartDate;
          delete updatedCharge?.effectiveEndDate;
        }else{
          updatedCharge.effectiveStartDate = moment(d?.effectiveStartDate).tz(timeZone).startOf('day').toISOString();
          updatedCharge.effectiveEndDate = moment(d?.effectiveEndDate).tz(timeZone).endOf('day').toISOString();
        }        

        if(!radiusRate?.includes(unitOfMeasure)) {
          delete updatedCharge?.startValue;
          delete updatedCharge?.endValue;
          delete updatedCharge?.unitOfMeasure;
        }        

        return updatedCharge;
    });
    if(nonRulesConstant.includes(unitOfMeasure)){
      newChargeProfileTemp = {
        ...newChargeProfileTemp ?? {},
        fromEvent: null,
        toEvent: null,
        exactEvents: null,
        inEvent: null
      }
    }else{
      newChargeProfileTemp.percentageOf = [];
    }

    if(newChargeProfileTemp?.isOneOffCharges){
      onEditComplete({...newChargeProfileTemp, index: editSelectedData?.index});
      close()
    }else {
      setIsSaving(true);

      delete newChargeProfileTemp?.__v;
      delete newChargeProfileTemp?.owner;

      if(isEditPopupOpen) handleEditChargeProfile({...newChargeProfileTemp, chargeTemplateId: newChargeProfileTemp?._id}).then(() => setIsSaving(false));
      else handleSubmit(newChargeProfileTemp).then(() => setIsSaving(false));
    }
  }

  const _onChange = (val, key) => {
    setNewChargeProfile({...newChargeProfile, [key]: val});
  }


  const cloneTemplate = () => { 
    if(isEditPopupOpen) {
      const newCloneProfiles = {...newChargeProfile, name: `${newChargeProfile.name} Copy`};
      
      delete newCloneProfiles?._id;

      if(cloneNewChargeProfiles) cloneNewChargeProfiles(newCloneProfiles).then((res) => {
        setIsSaving(false);
      });
    }
  }

  // check if correct events selected while creating charge profile
  const validateChargeProfileEvents = ({chargeProfile})=>{
    try {
      // 0. Prior checks
      // 0.1 Check if fromEvent or toEvents exists. If does not exists return null
      if(!chargeProfile?.fromEvent && !chargeProfile?.toEvent?.length) return null;

      // 1. define the required variables
      // 1.1 status in object
      const statusInObj = convertStatusToObj();

      // 1.2 list of invalid apt values
      const invalidAptEvents = [statusInObj.PICKUP,statusInObj.DELIVERY,statusInObj.RETURN,statusInObj.READY_TO_RETURN,statusInObj.POD_IN,statusInObj.POD_OUT];
      
      // 1.3 from event
      const fromEvent = chargeProfile?.fromEvent?.fromType;

      // 1.4 list of toEvents
      const toEvents =chargeProfile?.toEvent?.map((event)=> event?.toType?.split("/")[0]) ?? [];

      // 2. check if all the conditions match
      // 2.1. Condition 1: Ensure that for chargeType 'permile, perRoadTollMile' both 'fromEvent' and 'toEvents' contain  'invalidAptEvents' events.
      // 2.1.1: check if chargeType is "permile" or "perRoadTollMile"
      const isChargeTypeInValid = ["permile", "perRoadTollMile"].includes(chargeProfile?.unitOfMeasure);

      // 2.1.2: check if 'fromEvent' contains 'invalidAptEvents' events
      const isFromEventInvalid = fromEvent ? invalidAptEvents.includes(fromEvent): false;

      // 2.1.3: check if toEvent contain 'invalidAptEvents' events
      const isToEventInvalid = invalidAptEvents.some((iAV)=>toEvents.includes(iAV));

      const isChargeProfileEventsInValid = isChargeTypeInValid && (isFromEventInvalid || isToEventInvalid);
      if(isChargeProfileEventsInValid){
        return "Please select from and to statuses other than APTs"
      }

      return null;
    } catch (error) {
      console.error(error);
      return "Something went wrong validating the charge profile events.";
    }
  }

  return (
    <Modal show={show} dialogClassName="modal-xl modal-dialog-scrollable" backdropClassName="z-1050">
      <Modal.Header className="pb-0">
        <Modal.Title>{isEditPopupOpen ? `Edit Charge Profile` : "Add Charge Profile"}</Modal.Title>
        { 
          isEditPopupOpen && 
            <button className="btn btn-outline-light" onClick={cloneTemplate}>
              <IconClone className="mr-2" />
              <span className="text-primary">Clone Charge Profile</span>
            </button>
        }
      </Modal.Header>
      <Modal.Body>
        {isFetching && <LoaderBar fullPageLoader={true}  /> }
        <div className="form-row-md mt-20">
          <div className={`form-group col-lg-3 ${formErrors["name"] && "error"}`}>
            <label> <span className="text-danger">*</span> Charge Profile Name</label>
            <input type="text" className="form-control" placeholder="My Base Price" value={newChargeProfile?.name} onChange={(e) => handleChange("name", e.target.value)} disabled={isDisabledAllCustomerRateModal} />
            <div className="d-flex flex-column">
              {
                Array.isArray(formErrors["name"]) ?
                  formErrors["name"]?.map((val, i) => (
                    <small className="text-danger mb-2" key={i}>{val}</small>
                  )) : <small className="text-danger">{formErrors["name"]}</small>
              }
            </div>
          </div>
          <div className="form-group col-lg-3">
            <label> <span className="text-danger">*</span> Charge Name</label>
            <Select 
              styles={smallSelectStyle} 
              value={findChargeNameByCode(newChargeProfile?.chargeCode)}
              options={filterChargeCodesByScreenType("receivable", chargeCodeList)?.filter(d => d.value !== "ALL")}
              onChange={({label, value}) => {
                handleChange("charge", {chargeName: label, chargeCode: value});
              }}
              isDisabled={isDisabledAllCustomerRateModal}
            />
            <div className="d-flex flex-column">
              {
                Array.isArray(formErrors["charge"]) ?
                  formErrors["charge"]?.map((val, i) => (
                    <small className="text-danger mb-2" key={i}>{val}</small>
                  )) : <small className="text-danger">{formErrors["charge"]}</small>
              }
            </div>
          </div>
          <div className="form-group col-lg-3">
            <label>Charge Description</label>
            <input type="text" className="form-control" placeholder="Enter Description" value={newChargeProfile?.description} onChange={(e) => handleChange("description", e.target.value)} disabled={isDisabledAllCustomerRateModal} />
          </div>
          <div className="form-group col-lg-3">
            <label> <span className="text-danger">*</span> Unit Of Measure</label>
            <Select 
              styles={smallSelectStyle} 
              value={unitOfMeasure?.find(d => d.value === newChargeProfile?.unitOfMeasure)}
              options={unitOfMeasure}
              onChange={({ value }) => handleChange("unitOfMeasure", value)}
              isDisabled={isDisabledAllCustomerRateModal}
            />
            <div className="d-flex flex-column">
              {
                Array.isArray(formErrors["unitOfMeasure"]) ?
                  formErrors["unitOfMeasure"]?.map((val, i) => (
                    <small className="text-danger mb-2" key={i}>{val}</small>
                  )) : <small className="text-danger">{formErrors["unitOfMeasure"]}</small>
              }
            </div>
          </div>
          {!radiusRate?.includes(newChargeProfile?.unitOfMeasure) && <div className="form-group col-md-3">
            <label htmlFor="calculate-from-top-2"> Effective Date Based On </label>
            <Select
              options={EFFECTIVE_LOAD_DATE}
              value={EFFECTIVE_LOAD_DATE?.find(d => d.value === newChargeProfile?.effectiveDateParameter) ?? null}
              onChange={(options) => handleChange("effectiveDateParameter", options?.value)}
              placeholder="Select Effective Date"
              styles={smallSelectStyle}
              isDisabled={isDisabledAllCustomerRateModal}
              isClearable
            />
          </div>}
          {
            newChargeProfile?.unitOfMeasure && newChargeProfile?.unitOfMeasure?.includes("percentage") && 
              <div className="form-group col-md-3">
                <label htmlFor="calculate-from-top-2"> <span className="text-danger">*</span> Percentage Based On </label>
                <Select 
                  styles={smallSelectStyle} 
                  isMulti
                  value={ 
                    newChargeProfile?.percentageOf ? newChargeProfile?.percentageOf.map(val => chargeCodeList?.find((d) => d.value === val)) :  null
                  }
                  options={filterChargeCodesByScreenType("receivable", chargeCodeList)}
                  isDisabled={isDisabledAllCustomerRateModal}
                  onChange={(options) => {
                    handleChange("percentageOf", options?.map((d) => d.value));
                  }}
                />
              <div className="d-flex flex-column">
                {
                  Array.isArray(formErrors["percentageOf"]) ?
                    formErrors["percentageOf"]?.map((val, i) => (
                      <small className="text-danger mb-2" key={i}>{val}</small>
                    )) : <small className="text-danger">{formErrors["percentageOf"]}</small>
                }
              </div>
              </div>
          }
          <div className="form-group col-md-3">
            <label htmlFor="calculate-from-top-2" className="my-2"> <span className="text-danger">*</span> Auto Add To Load </label>
            <div className="d-flex">
              <div className="d-flex mr-3">
                <input 
                  type="radio" 
                  className="form-check-input"
                  id="auto-add-load" 
                  checked={!newChargeProfile?.autoAdd} 
                  onClick={(e) => handleChange("autoAdd", false)} 
                  disabled={isDisabledAllCustomerRateModal}
                />
                <label className="form-check-label">No</label>
              </div>
              <div className="d-flex">
                <input 
                  type="radio" 
                  className="form-check-input"
                  id="auto-add-load" 
                  checked={newChargeProfile?.autoAdd} 
                  onClick={(e) => handleChange("autoAdd", true)} 
                  disabled={isDisabledAllCustomerRateModal}
                />
                <label className="form-check-label">Yes</label>
              </div>
            </div>
          </div>
        </div>
        <AddRuleTable 
          handleChange={handleChange} 
          newChargeProfile={newChargeProfile}
          isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
          formErrors={formErrors}
        />
        <ProfileCondition
          newChargeProfile={newChargeProfile}
          handleChange={handleChange}
          isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
          formErrors={formErrors}
        />
        {/* <AddRuleCalculate 
          newChargeProfile={newChargeProfile}
          handleChange={handleChange}
          isDisabledAllCustomerRateModal={isDisabledAllCustomerRateModal}
          formErrors={formErrors}
        /> */}
        <div className="hr-light" />
        <div className="font-16 font-medium mb-10 text-capitalize">Select the conditions when you want to calculate the pay</div>
        <div className={` ${isDisabledAllCustomerRateModal ? 'disabled-pointer-events' : ''}`}>
        {!isFetching && 
          <QueryBuilder
            data={Array.isArray(newChargeProfile?.rules) ? newChargeProfile?.rules : []}
            setData={(val) => _onChange(val, 'rules')}
            consingeeState={StatesReducer?.consingeeState}
            commodities={[]}
            cityGroups={[]}
            terminals={HomeReducer?.terminals.map((terminal) => ({ label: terminal.name, value: terminal._id }))}
            deliveryCountry={countries}
            readOnly={false}
          />
          }
          </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-close" onClick={close}>
          Cancel
        </button>
        <div className={` ${isDisabledAllCustomerRateModal ? 'disabled-pointer-events' : ''}`}>
          <button className="btn btn-primary" onClick={onSubmit} disabled={isSaving}>
            {isSaving && <Spinner size="sm" />}
            <span className="mx-2">{isSaving ? "Saving" : (isEditPopupOpen ? "Update Charge Profile" : "Add Charge Profile")}</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(AddRuleModal);
