import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { amplitudeTrack, DateTimeFormatUtils, defaultNumberSystem, getStorage, isCustomsNote, isManageFleetOrOutSource, isMilitaryTime, isNewBillingEnabled, toastr, checkUserPermisison, isReadFromNewLoadNotes, updateTerminology } from "../../../services/Common.services";
import { HTTP} from "../../../services/Http.service";
import {
  CustomIconBilling,
  CustomIconBuilding,
  CustomIconMapMarker,
  CustomIconTruck,
  IconCustomCrossing,
  IconInstructionNotes,
} from "../../../Components/Common/CustomIcons/Index";
import * as tmsAction from "./actionCreator";
import { LoaderBar } from '../../../Components/Common/LoaderBar';
import { IconPortNote } from "../../../Components/Common/Icons";
import { addLoadChargeNotes, getBillingChargeNotesForLoad, getDefaultChargeId } from "./Billing/actionCreator";
import _ from 'lodash';
import { NEWBILLINGNOTES, OLDBILLINGNOTES } from "./Billing/Components/BillingCard/Charges/constant";
import { getTimeZone } from "../NewDispatcher/constants";
class NewNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billingNote: {},
      officeNote: {},
      customsNote: {},
      yardNote: {},
      driverNote: {},
      instructionNote: {},
      portNote:{},
      isLoading: true,
      driver: "",
      billing: "",
      yard: "",
      office: "",
      isVatEnabled: JSON.parse(getStorage("userBasicSettings"))?.isVAT,
      taxResp:{},
      isNewBillingEnabled:isNewBillingEnabled(),
      newBillingChargeNotes: [],
      newBillingChargeNotesIndex: 0,
      isReadFromLoadNotes: isReadFromNewLoadNotes()
    };
    this.userTimeFormat = isMilitaryTime() ? 'MM/DD/YY HH:mm': 'MM/DD/YY hh:mm A';
    this.isManageFleetOrOutSource = isManageFleetOrOutSource()

  }
  componentDidMount() {
    if (!checkUserPermisison(["customer_service_notes"])) return
    const { createdAt } = this.props.selectedLoads
    let eventProperties = {
      source: 'LOAD_NOTE',
      days: moment().diff(moment(createdAt), 'days'),
      loadCreatedDate : moment(createdAt).toISOString(),
    }
    amplitudeTrack("LOAD_AUDIT_ACCESS",eventProperties);
    this.notesDetail()
    this.userTimeFormat = isMilitaryTime() ? 'MM/DD/YY HH:mm': 'MM/DD/YY hh:mm A';
    this.state.isNewBillingEnabled  && getDefaultChargeId({loadIds:[this.props?.selectedLoads?._id]}).then(resp => {
      this.setState({taxResp:resp?.data?.pop()})
    }).catch(err => {
      console.error(err)
    })
  }
  
  gettaxNote = (info) => {
    let taxNote = info?.taxDetail?.taxNote ?? "";
    let currency = info?.billToDetail?.currency
    const numberSystem = defaultNumberSystem()
    let currencyCode = currency?.currencyCode ?? "USD"
    let currencySymbol = currency?.symbol ?? "$";
    let currencyType = numberSystem?.currencyDisplayMode === "symbol" ? currencySymbol : currencyCode 
    let totalAmount = Number(info?.totalAmount)?.toFixed(2).toCurrency(currency);
    totalAmount = totalAmount.replaceAll(currencyType,"").trim();
    if(taxNote?.includes('[currency-code]') ){
      taxNote = taxNote.replaceAll("[currency-code]",currencyType)
    }
    if(taxNote?.includes('[document-amount]')){
      taxNote = taxNote.replaceAll("[document-amount]",totalAmount)
    }
    return taxNote;
}
  notesDetail(){
    let loadCount = 0;
    this.setState({isLoading :true});
    this.state.isNewBillingEnabled && getBillingChargeNotesForLoad({loadId: this.props.selectedLoads._id}).then((res)=>{
      let billingNotes = [];
      const charges = res?.data || []
      charges.forEach((charge) => {
        const notesObj = {};
        notesObj.chargeId = charge?._id || '';
        notesObj.charge_reference_number = charge?.charge_reference_number || '';
        notesObj.company_name = charge?.billToDetail?.company_name || '';
        notesObj.billingNote = charge?.billingNote || '';
        notesObj.lastBillingNoteEditedBy = charge?.lastBillingNoteEditedBy || {};
        if(charge?.billingNote) {
          loadCount = loadCount + 1;
          this.props.updatNotesCountFromChild(loadCount, this.state.instructionNote);
        }
        billingNotes.push(notesObj);
      })
      this.setState({
        newBillingChargeNotes: billingNotes.length ? billingNotes.reverse() : [],
        newBillingChargeNotesIndex: billingNotes.length ? billingNotes.length : 0,
      }, ()=>{})
    })
    this.fetchData();
  }

  async fetchData() {
    try {
      let data;
      if (this?.state?.isReadFromLoadNotes) {
        data = await tmsAction.getLoadNoteDetailsFromLoadNotes({ reference_number: this.props.selectedLoads.reference_number });
      } else {
        data = await tmsAction.getLoadNoteDetails({ reference_number: this.props.selectedLoads.reference_number });
      }
      if (data) {
        if (data.billingNote && Object.keys(data.billingNote).length !== 0) {
          this.setState({ billingNote: data.billingNote });
        }
        if (data.officeNote && Object.keys(data.officeNote).length !== 0) {
          this.setState({ officeNote: data.officeNote });
        }
        if (data.customsNote && Object.keys(data.customsNote).length !== 0) {
          this.setState({ customsNote: data.customsNote });
        }
        if (data.yardNote && Object.keys(data.yardNote).length !== 0) {
          this.setState({ yardNote: data.yardNote });
        }
        if (data.driverNote && Object.keys(data.driverNote).length !== 0) {
          this.setState({ driverNote: data.driverNote });
        }
        if (data.instructionNote && Object.keys(data.instructionNote).length !== 0) {
          this.setState({ instructionNote: data.instructionNote });
        }
        if (data.portNote && Object.keys(data.portNote).length !== 0) {
          this.setState({ portNote: data.portNote });
        }
  
        // Additional logic for notes count
        if (data) {
          const notes = this.state.isNewBillingEnabled ? NEWBILLINGNOTES : OLDBILLINGNOTES;
          let loadCount = 0;
  
          for (const [key, value] of Object.entries(data)) {
            notes.forEach((d) => {
              if (key.includes(d) && value.note) {
                loadCount++;
              }
            });
          }
  
          this.props.updatNotesCountFromChild(loadCount, data.instructionNote);
        }
  
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setState({ isLoading: false });
    }
  }
  
  handleNoteActive(i) {
    this.setState({
      isNoteActive: i,
    });
  }

  handleNoteNull() {
    this.setState({
      isNoteActive: null,
    });
  }

  changeHandler(e) {
    const note = this.state[e.target.name];
    note.note = String(e.target.value);
    this.setState({ [e.target.name] : note });
  }

  editNotes(id) {
    let data = {};
    data.reference_number = this.props.selectedLoads.reference_number;
    data[`${id}`] = this.state[`${id}`].note;
    HTTP("post", "tms/saveLoadNotes", data, {
      authorization: getStorage("token"),
    }).then((res) => {
      this.notesDetail()
      toastr.show("Notes have been added.", "success");
      this.setState({
        isNoteActive: null,
      });
    });
  }

  editChargeNotes(chargeId, value) {
    this.setState({isLoading: true})
    const data = {
      "chargeId": chargeId,
      "billingNote": value
    }
    addLoadChargeNotes(data).then((res)=>{
      this.notesDetail();
      toastr.show("Notes have been added.", "success");
      this.setState({isLoading: false, isNoteActive: null})
    })
  }

  changeNotesHandler(e, i) {
    const notes = _.cloneDeep(this.state.newBillingChargeNotes);
    const note = notes[i]
    note.billingNote = e.target.value;
    this.setState({ newBillingChargeNotes : notes });
  }
  renderReturn = () => {
    const timeZone = getTimeZone({preferred: true})
    return (  
      <React.Fragment>
        <div className="tab">
          {this.state.isLoading &&
            <LoaderBar></LoaderBar>
          }
          {this.isManageFleetOrOutSource?.isManageFleet && <div
            className={`card my-10 ${
              this.state.isNoteActive === 1 ? "active shadow-xl z-1" : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(1)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconTruck className="mr-10" />
                  <h5 className="font-16 mb-0">Driver</h5>
                </div>
                <div className="d-flex">
                  {this.state.driverNote.createdAt && this.state.driverNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.driverNote.createdBy} at {moment(this.state.driverNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.driverNote.note ? this.state.driverNote.note : ""}
                  placeholder="Notes For driver"
                  name="driverNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === 1 && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.driver}
                  onClick={() => this.editNotes("driverNote")}>
                  Add Note
                </button>
              </div>
            )}
          </div>}
          {this.state.isNewBillingEnabled && this.state.newBillingChargeNotes?.length ? 
            this.state.newBillingChargeNotes.map((note, i) => {
              return (
                <div
                  className={`card my-10 ${
                    this.state.isNoteActive === 2 + i ? "active shadow-xl z-1" : ""
                  }`}
                  key={i}
                >
                  <div
                    className="px-30 py-20"
                    onClick={() => this.handleNoteActive(2 + i)}
                  >
                    <div className="d-flex justify-content-between align-items-center mb-20">
                      <div className="d-flex align-items-center">
                        <CustomIconBilling className="mr-10" />
                        <div>
                        <h5 className="font-16 mb-0">Billing - {note?.company_name}</h5>
                        <h6 className="text-dark font-12 font-weight-500 mb-0"><span className="text-muted font-weight-normal">Charge Set #</span> {note?.charge_reference_number}</h6>
                        </div>
                      </div>
                      <div className="d-flex">
                        {note?.lastBillingNoteEditedBy?.time && note?.lastBillingNoteEditedBy?.name && 
                          (<h5 className="font-12 text-gray-500">Last Edited By {note?.lastBillingNoteEditedBy?.name} at {moment(note?.lastBillingNoteEditedBy?.time).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                        }
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        value={note.billingNote ? note.billingNote : ""}
                        placeholder="Notes For Billing"
                        name="billingNote"
                        rows={4}
                        onChange={(e) => {
                          this.changeNotesHandler(e, i);
                        }}
                      ></textarea>
                    </div>
                  </div>
                {this.state.isNoteActive === 2 + i && (
                  <div className="card-footer">
                    <button
                      className="btn btn-close"
                      onClick={() => this.handleNoteNull()}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary"
                      // disabled={!this.state.billing}
                      onClick={() => {this.editChargeNotes(note?.chargeId, note.billingNote)}}>
                      Add Note
                    </button>
                  </div>
                )}
                </div>
              )
            })
          :
            <div
              className={`card my-10 ${
                this.state.isNoteActive === 2 ? "active shadow-xl z-1" : ""
              }`}
            >
              <div
                className="px-30 py-20"
                onClick={() => this.handleNoteActive(2)}
              >
                <div className="d-flex justify-content-between align-items-center mb-20">
                  <div className="d-flex">
                    <CustomIconBilling className="mr-10" />
                    <h5 className="font-16 mb-0">Billing</h5>
                  </div>
                  <div className="d-flex">
                    {this.state.billingNote.createdAt && this.state.billingNote.createdBy && 
                      (<h5 className="font-12 text-gray-500">Last Edited By {this.state.billingNote.createdBy} at {moment(this.state.billingNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                    }
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    value={this.state.billingNote.note ? this.state.billingNote.note : ""}
                    placeholder="Notes For billing"
                    name="billingNote"
                    rows={4}
                    onChange={(e) => {
                      this.changeHandler(e);
                    }}
                  ></textarea>
                </div>
              </div>
              {this.state.isNoteActive === 2 && (
                <div className="card-footer">
                  <button
                    className="btn btn-close"
                    onClick={() => this.handleNoteNull()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    // disabled={!this.state.billing}
                    onClick={() => {this.editNotes("billingNote")}}>
                    Add Note
                  </button>
                </div>
              )}
            </div>
          }
          <div
            className={`card my-10 ${
              this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 2 : 3) ? "active shadow-xl z-1" : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive((this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 2 : 3))}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconMapMarker className="mr-10" />
                  <h5 className="font-16 mb-0">Yard</h5>
                </div>
                <div className="d-flex">
                  {this.state.yardNote.createdAt && this.state.yardNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.yardNote.createdBy} at {moment(this.state.yardNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.yardNote.note ? this.state.yardNote.note : ""}
                  placeholder="Notes For Yard"
                  name="yardNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 2 : 3) && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.yard}
                  onClick={() => {this.editNotes("yardNote")}}>
                  Add Note
                </button>
              </div>
            )}
          </div>
          <div
            className={`card my-10 ${
              this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 3 : 4) ? "active shadow-xl z-1" : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive((this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 3 : 4))}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <CustomIconBuilding className="mr-10" />
                  <h5 className="font-16 mb-0">Load</h5>
                </div>
                <div className="d-flex">
                  {this.state.officeNote.createdAt && this.state.officeNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.officeNote.createdBy} at {moment(this.state.officeNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.officeNote.note ? this.state.officeNote.note : ""}
                  placeholder="Notes For Office"
                  name="officeNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 3 : 4) && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.office}
                  onClick={() => {this.editNotes("officeNote")}}>
                  Add Note
                </button>
              </div>
            )}
          </div>
          {this.isManageFleetOrOutSource?.isManageFleet && <div
            className={`card my-10 ${
              this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 4 : 5) ? "active shadow-xl z-1" : ""
            }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive((this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 4 : 5))}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                <IconPortNote className="mr-10" />
                  <h5 className="font-16 mb-0">{updateTerminology('port')} Notes</h5>
                </div>
                <div className="d-flex">
                  {this.state.portNote.createdAt && this.state.portNote.createdBy && 
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.portNote.createdBy} at {moment(this.state.portNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.portNote.note ? this.state.portNote.note : ""}
                  placeholder={`Notes For ${updateTerminology('port')}`}
                  name="portNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 4 : 5) && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => {this.editNotes("portNote")}}>
                  Add Note
                </button>
              </div>
            )}
          </div>}

         {this.state.isVatEnabled && <div
            className={`card my-10`}
          >
            <div
              className="px-30 py-20"
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                <CustomIconBilling className="mr-10" />
                  <h5 className="font-16 mb-0 text-capitalize">Tax Notes </h5>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  disabled={true}
                  value={this.state.isNewBillingEnabled ? this.gettaxNote(this.state.taxResp): this.props.selectedLoads?.taxNote }
                  placeholder="Notes For Tax"
                  name="officeNote"
                  rows={4}
                ></textarea>
              </div>
            </div>
          </div>}
          {(this.state?.instructionNote?.note || this.isManageFleetOrOutSource?.isOutSource) && <div
            className={`card my-10 ${this.state.isNoteActive === 7 ? "active shadow-xl z-1" : ""}`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive(7)}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                <IconInstructionNotes className="mr-10" />
                  <h5 className="font-16 mb-0 text-capitalize">Instruction Notes</h5>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  disabled={!this.isManageFleetOrOutSource?.isOutSource}
                  value={this.state.instructionNote?.note ? this.state.instructionNote.note : "" }
                  placeholder="Notes For Instructions"
                  name="instructionNote"
                  rows={5}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {(this.state.isNoteActive === 7 && this.isManageFleetOrOutSource?.isOutSource) && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={() => this.editNotes("instructionNote")}>
                  Add Note
                </button>
              </div>
            )}
          </div>
          }
          {isCustomsNote() &&  <div
            className={`card my-10 ${this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 5 : 8) ? "active shadow-xl z-1" : ""
              }`}
          >
            <div
              className="px-30 py-20"
              onClick={() => this.handleNoteActive((this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 5 : 8))}
            >
              <div className="d-flex justify-content-between align-items-center mb-20">
                <div className="d-flex">
                  <IconCustomCrossing className="mr-10 text-primary" />
                  <h5 className="font-16 mb-0">Customs</h5>
                </div>
                <div className="d-flex">
                  {this.state.customsNote.createdAt && this.state.customsNote.createdBy &&
                    (<h5 className="font-12 text-gray-500">Last Edited By {this.state.customsNote.createdBy} at {moment(this.state.customsNote.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}</h5>)
                  }
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  value={this.state.customsNote.note ? this.state.customsNote.note : ""}
                  placeholder="Notes For Customs"
                  name="customsNote"
                  rows={4}
                  onChange={(e) => {
                    this.changeHandler(e);
                  }}
                ></textarea>
              </div>
            </div>
            {this.state.isNoteActive === (this.state.newBillingChargeNotesIndex ? this.state.newBillingChargeNotesIndex + 5 : 8) && (
              <div className="card-footer">
                <button
                  className="btn btn-close"
                  onClick={() => this.handleNoteNull()}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  // disabled={!this.state.office}
                  onClick={() => { this.editNotes("customsNote") }}>
                  Add Note
                </button>
              </div>
            )}
          </div>}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer") {
        return this.renderReturn();
      } else if (loggedInUser.permissions.length !== 0) {
        if (loggedInUser.permissions.includes("customer_service_notes")){ 
          return this.renderReturn();
        }
        else {return <div className="nopermission text-capitalize"><h1>You do not have permission to view this page</h1></div>}
      }

      else {return <div className="nopermission text-capitalize"><h1>You do not have permission to view this page</h1></div>;}
    }
    // return this.renderReturn();
  }
}
export default connect()(NewNotes);
