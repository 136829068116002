export const CHARGE_TOOLTIP = {
    MANUAL:"Manually Entered Charge",
    AUTOMATED:"System Generated Charge. Click To See Tariff Rate Definition.",
    SYSTEM_GENERATED:"System Generated Charge."
} 
export const MANUALLY_CHANGED = "MANUALLY_CHANGED"
export const CUSTOMER_RATE_RECORD = "CUSTOMER_RATE_RECORD"
export const SYSTEM_GENERATED = "SYSTEM_GENERATED"

export const NEWBILLINGNOTES = ["driverNote", "yardNote", "officeNote", "instructionNote", "portNote", "customsNote"];
export const OLDBILLINGNOTES = ["driverNote", "billingNote", "yardNote", "officeNote", "instructionNote", "portNote", "customsNote"];

export const REBILL_TOOLTIP = "The Connected Customer Has A Payment Applied To This Invoice, Preventing Rebill Or Void";
