import { useState } from "react";
import { toastr } from "services";
import { removeLabelsFromEmail } from "../actionCreator";
import { EMAIL_MSG } from "../constant";

export const useRemoveLabelFromMail = (allEmails, setAllEmails, callback, setRowOpen, closeModal) => {
  const [isApiCall, setIsApiCall] = useState(false);
  const onSuccess = (data) => {
    const hasSuccess = data?.success?.length > 0;
    const hasNotFound = data?.notFound?.length > 0;
    if (hasSuccess) {
      const updatedMail = allEmails.find(email => email?.id === data.success[0]?.id);
      if (updatedMail) {
        updatedMail.folders = data.success[0]?.folders;
        setAllEmails((prevEmails)=>{
          return prevEmails.map((email)=>{
            if(email.id===updatedMail.id) return updatedMail
            else return email
          })
        });
        setRowOpen && setRowOpen(updatedMail);
        toastr.show("Removed Successfully.", "success");
      }
    } else if (hasNotFound) {
      setAllEmails(allEmails.filter(email => email?.id !== data.notFound[0]));
      toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_SINGLE, "success");
      closeModal && closeModal();
    } else {
      toastr.show("Something went wrong.", "error");
    }

    callback && callback();
  };
  const removeLabelFromMail = async (payload) => {
    try {
      setIsApiCall(true);
      const data = await removeLabelsFromEmail(payload);
      onSuccess(data);
    } catch (error) {
      console.log("error", error);
      toastr.show("Something went wrong!", "error");
    } finally {
      setIsApiCall(false);
    }
  };
  return {
    removeLabelFromMail,
    isApiCall
  };
};