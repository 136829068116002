import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateTimeFormatUtils, isRadiusRateOn, showForTerminal } from "services";
import { updateTerminology } from 'services/Common.services';
import { LOAD_LABELS } from 'labels';
import { updateTerminologyForTableAndFilter } from 'labels';
function InvoiceSidebarAdditionalInfo({
  sideBarData,
  timeZone
}) {
  const [measureUnit, setMeasureUnit] = useState("")
  const [totalDistance, setTotalDistance] = useState(0)
  const showSecondaryDistance = isRadiusRateOn()
  const [secondaryDistance, setSecondaryDistance] = useState(0)
  const showTerminal = showForTerminal()
  useEffect(() => {
    setDistanceUnit()
  }, [])
  const setDistanceUnit = () => {
    const selectedLoad = sideBarData?.loadId || [];
    const measureUnits = selectedLoad && selectedLoad.carrier && selectedLoad.carrier.carrier && selectedLoad.carrier.carrier.distanceMeasure;
    let _measureUnit = measureUnits && measureUnits == 'km' ? "Kilometers" : "Miles";
    let _totalDistance = 0;
    if (selectedLoad && selectedLoad?.totalMiles) {
      if (selectedLoad.totalMiles > 6000) _totalDistance = "6000+";
      else _totalDistance = selectedLoad?.totalMiles?.toFixed(2);
    }
    let _secondaryDistance = 0;
    for (const driverOrder of (selectedLoad?.driverOrder ?? [])) {
      _secondaryDistance += (driverOrder.defaultDistance ?? driverOrder.distance);
    }
    _secondaryDistance = _secondaryDistance.toFixed(2);
    setMeasureUnit(_measureUnit)
    setTotalDistance(_totalDistance)
    setSecondaryDistance(_secondaryDistance)
  }
  let totalWeight = 0;
  let weightUnit = "LBS";
  if (sideBarData?.loadId && sideBarData?.loadId?.items) {
    sideBarData?.loadId?.items.forEach((item) => {
      if (sideBarData?.loadId?.carrier && sideBarData?.loadId?.carrier?.carrier && sideBarData?.loadId?.carrier?.carrier?.weightMeasure == "kg") {
        totalWeight += Number(item.weightKgs);
        weightUnit = "KG"
      } else {
        totalWeight += Number(item.weight);
      }
    });
  }

  const staticInfo = [
    { key: "Customer", value: sideBarData?.loadId?.caller?.company_name ?? "-" },
    showTerminal ? { key: updateTerminology('terminal'), value: sideBarData?.loadId?.terminal?.name ?? "-" } : {},
    { key: LOAD_LABELS.CREATED_BY, value: sideBarData?.loadId?.addedBy?.name ?? "-" },
    { key: "Created Date", value: (sideBarData?.loadId?.createdAt ? moment(sideBarData?.loadId?.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-") },
    { key: LOAD_LABELS.REFERENCE, value: sideBarData?.loadId?.secondaryReferenceNo ?? "-" },
    { key: LOAD_LABELS.DELIVERY_APPOINTMENT, value: (sideBarData?.loadId?.deliveryTimes?.length && (sideBarData?.loadId?.deliveryTimes[0]?.deliveryFromTime ? moment(sideBarData?.loadId?.deliveryTimes[0]?.deliveryFromTime).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-")) },
    { key: "Return Container Date", value: (sideBarData?.loadId?.returnFromTime ? moment(sideBarData?.loadId?.returnFromTime).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : "-") },
    { key: LOAD_LABELS.PURCHASE_ORDER_NUMBER, value: sideBarData?.loadId?.purchaseOrderNo ?? "-" },
    { key: LOAD_LABELS.CONTAINER_NUMBER, value: sideBarData?.loadId?.containerNo ?? "-" },
    { key: "Container Size & Type", value: (`${sideBarData?.loadId?.containerSize?.name ? sideBarData?.loadId?.containerSize?.name : "-"}' ${sideBarData?.loadId?.containerType?.name ? sideBarData?.loadId?.containerType?.name : "-"}`) },
    { key: LOAD_LABELS.CHASSIS_NUMBER, value: sideBarData?.loadId?.chassisNo ?? "-" },
    { key: "Chassis Size & Type", value: (`${sideBarData?.loadId?.chassisSize?.name ? sideBarData?.loadId?.chassisSize?.name : "-"}' ${sideBarData?.loadId?.chassisType?.name ? sideBarData?.loadId?.chassisType?.name : "-"}`) },
    { key: updateTerminologyForTableAndFilter('BOL/BKG'), value: sideBarData?.loadId?.callerbillLandingNo ?? "-" },
    { key: LOAD_LABELS.SEAL_NUMBER, value: sideBarData?.loadId?.sealNo ?? "-" },
    { key: "Vessel", value: sideBarData?.loadId?.deliveryOrderNo ?? "-" },
    { key: LOAD_LABELS.CONTAINER_OWNER, value: sideBarData?.loadId?.containerOwnerName ?? "-" },
    { key: LOAD_LABELS.TOTAL_WEIGHT, value: (`${totalWeight.toFixed(2)} ${weightUnit}`) },
    { key: LOAD_LABELS.COMMODITY, value: sideBarData?.loadId?.items?.length ? sideBarData?.loadId?.items[0]?.commodity : "-" },
    { key: (`Total ${measureUnit}`), value: totalDistance },
    showSecondaryDistance ? { key: (`Secondary ${measureUnit}`), value: secondaryDistance } : {},
  ];

  return (
    <>
      <div class="font-14 font-medium line-height-20 mb-1">Load Details</div>
      <div className="card card-outline flex-column gap-5 p-1 mb-15">
        {staticInfo.map((element, index) => {
          if(!element?.key) return (<></>)
          return (
            <div key={index} className="d-flex align-items-center text-muted">
              {element.key}
              <div className="ml-auto text-dark font-medium">{element.value}</div>
            </div>
          )
        }
        )}
      </div>
    </>
  );
}

export default InvoiceSidebarAdditionalInfo;
