import jsonToQueryParams, { objToQueryParams } from "Components/Common/jsonToQueryParams";
import { CUSTOMER_BILLING_CHARGE_LISTS_FAILED, CUSTOMER_BILLING_CHARGE_LISTS_PENDING, CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS, GET_BILLING_CHARGE_LISTS_FAILED, GET_BILLING_CHARGE_LISTS_PENDING, GET_BILLING_CHARGE_LISTS_SUCCESS } from "pages/tms/constant";
import { HTTP, checkIsCustomer, getStorage } from "services";

export function addPricing(params) {
    const url = 'load-charge/add-pricing';
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function updatePricing(params) {
    const url = 'load-charge/update-pricing';
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function removePricing(params) {
    const url = 'load-charge/remove-pricing';
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params, {
        'authorization': getStorage('token')
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


export function addLoadChargeNotes(params) {
  const url = 'load-charge/add-notes';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status === 200){
          resolve(result.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const getBillingChargeLists = (params) => (dispatch) => {
  
  const { loadId, chargeId } = params;
  let url = `load-charge/charge-list`
  if (chargeId) {
    url = `${url}?chargeId=${chargeId}`
  } else {
    url = `${url}?loadId=${loadId}`
  }
  dispatch({ type: GET_BILLING_CHARGE_LISTS_PENDING });
  return new Promise((resolve, reject) => {
    HTTP('get', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result?.status === 200) {
          dispatch({ type: GET_BILLING_CHARGE_LISTS_SUCCESS, payload: result?.data });
          resolve(result)
        }

      })
      .catch((error) => {
        dispatch({ type: GET_BILLING_CHARGE_LISTS_FAILED });
        reject(error);
      });
  })
}


export const  getBillingCount = (refNo)=> {
  try {
    const url = `load-charge/billing-summary?loadId=${refNo}`
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export function addNewCustomer(params) {
  const url = 'load-charge/add-charge';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function updateCustomer(params) {
  const url = 'load-charge/customer-update';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function approvedLoadCharge(params) {
  const url = 'load-charge/approve';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
          resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function unapproveLoadCharge(params) {
  const url = 'load-charge/unapprove';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
          resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function unApprovedLoadCharge(params) {
  const url = 'load-charge/unapprove';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function invoiceLoadCharge(params) {
  const url = 'invoice/create';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function invoiceRebilledLoadCharge(params) {
  const url = 'invoice/rebill-to-bill';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function rebillLoadCharge(params) {
  const url = 'invoice/rebill';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const deleteBillingCharge = (params)=> {
  try {
    const url = `load-charge/delete`
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const changeDefaultTaxType = (params)=> {
  try {
    const url = `load-charge/change-default-tax-type`
    return new Promise((resolve, reject) => {
      HTTP('PATCH', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}


export const getInvoicesWithPayment = (loadId, isGenerateSignUrl = false) => {
  try {
    let url = `invoice-payment/get-payment-by-load?loadId=${loadId}`;
    
    if(isGenerateSignUrl) url += `&isGenerateSignUrl=${isGenerateSignUrl}`;

    return new Promise((resolve, reject) => {
      HTTP('GET', url, {},{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const addPaymentToInvoice = (params)=> {
  try {
    const url = `invoice-payment/add`
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const removePaymentFromInvoice = (params)=> {
  try {
    const url = `invoice-payment/remove`
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}


export function replaceConflictedCharge(params) {
  const url = 'load-charge/replace-conflicted-charge';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}



export const addBatchPaymentToInvoices = (params)=> {
  try {
    const url = `invoice-payment/add-multiple-invoice-payment`
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const  getInvoicedDetail = (params)=> {
  const { inVoiceId, chargeId } = params;
  try {
    const url = `charge/invoice-detail?invoiceId=${inVoiceId}&chargeId=${chargeId}`
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const customerBillingChargeLists = (params) => (dispatch) => {
  const { loadId, chargeId } = params;
  let url = `customer/load-charge/charge-list`
  if (chargeId) {
    url = `${url}?chargeId=${chargeId}`
  } else {
    url = `${url}?loadId=${loadId}`
  }
  dispatch({ type: CUSTOMER_BILLING_CHARGE_LISTS_PENDING });
  return new Promise((resolve, reject) => {
    HTTP('get', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result?.status === 200) {
          dispatch({ type: CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS, payload: result?.data });
          resolve(result)
        }

      })
      .catch((error) => {
        dispatch({ type: CUSTOMER_BILLING_CHARGE_LISTS_FAILED });
        reject(error);
      });
  })
}

export const  customerInvoicedDetail = (params)=> {
  const { inVoiceId, chargeId } = params;
  try {
    const url = `customer/charge/invoice-detail?invoiceId=${inVoiceId}&chargeId=${chargeId}`
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}


export const getDefaultChargeId = (params)=> {
  try {
    let url = 'load-charge/get-default-chargeId'
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const getDocumentListByCharge = (params) => {
  const { loadId } = params;
  const isCustomer = checkIsCustomer()
  try {
    
    const url = isCustomer ? `customer/load-charge/get-charge-documents?loadId=${loadId}` : `load-charge/get-charge-documents?loadId=${loadId}`
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const moveChargeDocuments = (params)=> {
  try {
    const url = `load-charge/move-documents`
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const duplicateChargeDocuments = (params)=> {
  try {
    const url = `load-charge/duplicate-documents`
    return new Promise((resolve, reject) => {
      HTTP('post', url, params,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}

export const getBillingChargeNotesForLoad = (params)=> {
  try {
    let url = 'load/get-billing-notes-for-load'
    url = params ? url + "?" + objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if(result?.status===200){
            resolve(result?.data)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
    return
  }
}

export function requestRateConfirmation(params) {
  const url = 'load-charge/request-confirmation';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePaymentTerms(params) {
  console.log(params,"params")
  const url = 'load-change/update-payment-terms';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token')
    })
      .then((result) => {
        if(result?.status===200){
            resolve(result?.data?.data)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const checkBillToForShiposUser = (billToId) => {
  try {
    const url = `check-bill-to-for-broker?billToId=${billToId}`;
    return new Promise((resolve, reject) => {
      HTTP('GET', url, {},{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  } catch (error) {
    console.log(error)
  }
}