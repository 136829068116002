import { Modal } from "react-bootstrap";
import { IconPen } from "../../../Components/Common/Icons";

const Credentials = (props) => {
  const {
    showModal,
    hideModal,
    portMarkets,
    portStat,
    showLoginModal,
    isAlertView,
  } = props;

  return (
    <Modal show={showModal} centered animation={false} dialogClassName="modal-w900 modal-dialog-centered modal-dialog-scrollable">
      <Modal.Header className="justify-content-center">
        <div className="d-flex flex-column text-center">
          <Modal.Title className="mb-1">
            Your Login Credential Have Problems !
          </Modal.Title>
          <div className="text-gray-500 font-12 text-capitalize">
            Please check below errors and enter correct login information to optimal tracking.
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* No login required */}
        <div>
          {portMarkets?.map((port, i) => {
            const portMap = portStat?.[port?.value];
            const hasMissing = portMap?.missing > 0;
            const hasErrors = portMap?.errors > 0;
            if (!hasMissing && !hasErrors && isAlertView) {
              return;
            }
            return (
              <div key={i} className={ `rounded-5 p-10 mt-10 ${hasErrors ? "border-1 border-gray-100" : hasMissing ? "border-0 shadow-3" : "border-1 border-brand-100 bg-brand-50"}`}> 
                <div className="row align-items-center">
                  <div className="col-6">
                    <div className="d-flex">
                      <div>
                        {" "}
                        <button onClick={() => showLoginModal(port)} className="btn btn-primary mr-10">
                          <IconPen />
                        </button>
                      </div>
                      <div>
                        <div className="font-16 font-weight-500 text-capitalize">{port?.label}</div>
                        <div className={`${hasErrors ? "text-danger" : hasMissing ? "text-warning" : "text-primary"} text-capitalize`}>
                        {hasErrors ? "Problem with login and /or password" : hasMissing ? "Missing Credentials" : "All Connected!"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`col-2 ${portMap?.connected ?? 0 ? "" : "text-gray-500"}`} style={{ opacity: portMap?.connected ?? 0 ? 1 : 0.4 }}>
                    <div className="font-weight-600">Connected</div>
                    <div className={`font-20 line-height-25 ${portMap?.connected ?? 0 ? "text-primary" : "text-gray-500"}`}>{ portMap?.connected ?? 0 }</div>
                  </div>
                  <div className={`col-2 ${hasErrors ? "text-danger" : "text-gray-500"}`} style={{ opacity: hasErrors ? 1 : 0.4 }}>
                    <div className={`font-weight-600`}>Error</div>
                    <div className="font-20 line-height-25">{ portMap?.errors ?? 0 }</div>
                  </div>
                  <div className={`col-2 ${hasMissing ? "text-warning" : "text-gray-500"}`} style={{ opacity: hasMissing ? 1 : 0.4 }}>
                    <div className="font-weight-600">Missing</div>
                    <div className="font-20 line-height-25">{ portMap?.missing ?? 0 }</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary btn-lg" onClick={hideModal}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default Credentials;