const commodityItems = {
    profile: "",
    description: "",
    weightKgs: 0,
    weight: 0
}


export const defaultQuoteDetails = {
    type_of_load: null,
    caller: null,
    emptyOrigin: [],
    shipper: [],
    pricing: [],
    commodity: [commodityItems],
    numberofLoad: 1,
    quoteNotes: null,
    zipCode: null,
    validUpTo: null,
    selectedTerminal: null,
    containerType: null,
    containerSize: null,
    chassisType: null,
    temperature: null,
    hazmat: null,
    hot: null,
    liquor: null,
    genset: null,
    overweight: null,
    countryCode: null,
    consignee: [],
    invoiceCurrency: null,
    totalMiles: null,
    people: null,
}

export const pricingItems = {
    amount: 0,
    chargePerDay: 0,
    chargePerMinute: 0,
    finalAmount: 0,
    unit: 0,
    freeDays: 0,
    freeMinutes: 0,
    perType: "",
    name: "",
    manual: true,
    manualFinalAmount: 0,
};

export const moveLegs = [
    'shipper', 'selectedGroupShipper',
    'emptyOrigin', 'selectedGroupReturn',
    'consignee'
];

export const QUOTES_MAPPER = {
    cityState: "City/State",
    zipCode: "ZIP/Postal Code",
    "customer/group": "Customer Group",
    "cityState/group": "City/State Group",
    "zipCode/group": "ZIP/Postal Code Group"
};

export const CITYSTATE_ZIPCODE_KEYS = ["cityState", "zipCode",  "customer/group", "zipCode/group", "cityState/group"];

export const ENUM = Object.freeze({
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    ROAD: 'ROAD',
    CALLER: 'caller',
    SHIPPER: 'shipper',
    CONSIGNEE: 'consignee',
    EMPTY_ORIGIN: 'emptyOrigin',
    SELECTED_GROUP_SHIPPER: 'selectedGroupShipper',
    SELECTED_GROUP_RETURN: 'selectedGroupReturn',
    PRICING: "pricing",
    CITY: "city",
    CITY_STATE: "cityState",
    ZIP_CODE: 'zipCode',
    ZIP_CODE_GROUP_PROFILE: "zipCode/group",
    CITY_GROUP_PROFILE: "cityState/group",
    GROUP_PROFILE: "customer/group",
    PROFILE: "customer",
});