import React, { useRef, useState, useEffect } from 'react'
import { genratePillColor } from '../../../utility';
import DateEditor from '../CellComponents/DateEditor';
import '../../../DispatcherTable/style.css';
import { formatToDateAndTime } from '../../Functions';
import { useDispatch, useSelector } from 'react-redux';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";

const Cut = ({ row, column, onRowChange, isCellSelected, isCellEditable=true }) => {
	const [showBox, setShowBox] = useState(false)
    const [isColorPortal, setColorPortal] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	const dispatch = useDispatch();
  	const fAction = useSelector((state) => state.dispatcherTableReducer);

	const hideModel = () => {
		setShowBox(false)
	}

	const showModel = () => {
		setShowBox(true)
	}
	
	let cutPillColor = genratePillColor("cutOff", row);

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected || row.type_of_load !== "EXPORT" || cutPillColor=="bg-warning-500 text-center text-black"){
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal ,cutPillColor]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.Cut);
    }, [column ,row?.colorOfCells?.Cut]);


	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "cutOff") {
			onRowChange({ ...row, "cutOff": fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])

	return (
		<>
		<div ref={cellRef} className={`cell-content text-center ${cutPillColor}`} onClick={() => showModel()} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
			<div className='rdg-truncate'>				
				{row?.type_of_load === "EXPORT" && row?.cutOff &&
					formatToDateAndTime(row?.cutOff)}
				{!row?.cutOff && <span className='date_field'></span>}
				
			</div>
		</div>
			{isCellEditable && row?.isEditPermission && showBox
				&& row.type_of_load === "EXPORT" && <DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					row={row}
					column={column}
					onRowChange={onRowChange} />
			}
		{isColorPortal &&
        <ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="Cut" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
        }
	</>)
}
export default Cut;