import React, { Component, createRef } from "react";
import ReactDOMServer from "react-dom/server";

import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import * as L from 'leaflet';
import "react-datetime/css/react-datetime.css";

import { getGooglePlaces, toastr, getStorage } from "../../../services/Common.services";
import { getDriverLocationHistoryByLoad } from "../services";
import * as actionCreators from "../Dispatcher/actionCreators";
import { convertTMSStatusName } from "../../../Components/Common/functions";
import { updateDriverOrderById, uploadDocumentForLoad, uploadDocumentTracking } from "./actionCreator";
import {
  angleFromCoordinate,
  streetTile,
  baseMaps,
  truckIconWithActiveGPS,
  LeafIcon,
  arrivedDepartedDateTime,
  inactiveWaypointIcon,
} from "../../trucker/NewTrack1/utility.js";
import DriverDetails from "../../trucker/NewTrack1/DriverDetails";
import config from "../../../config";
import ContainerSummary from "../../trucker/NewTrack1/elements/ContainerSummary";
import NewDriverDetails from "../../trucker/NewTrack1/NewDriverDetails";
import html2canvas from "html2canvas";
import { Modal } from "react-bootstrap";
import { IconTimes, IconUpload } from "../../../Components/Common/Icons";

let mapBreadcrumb;
let locationTimer;
let firstpolyline;
let movingMarker;
const colorCodes = {
  DISPATCHED: "#FF6633",
  CHASSISPICK_ARRIVED: "#FFB399",
  CHASSISPICK_DEPARTED: "#FFB399",
  PULLCONTAINER_ARRIVED: "#FF33FF",
  PULLCONTAINER_DEPARTED: "#FFFF99",
  DELIVERLOAD_ARRIVED: "red",
  DELIVERLOAD_DEPARTED: "#E6B333",
  RETURNCONTAINER_ARRIVED: "#999966",
  RETURNCONTAINER_DEPARTED: "#99FF99",
  CHASSISTERMINATION_ARRIVED: "#B34D4D",
  CHASSISTERMINATION_DEPARTED: "#B34D4D",
  DROPCONTAINER_ARRIVED: "#80B300",
  DROPCONTAINER_DEPARTED: "#809900",
  HOOKCONTAINER_ARRIVED: "blue",
  HOOKCONTAINER_DEPARTED: "blue",
  COMPLETED: "#6680B3",
};

let [currentMap, allMarkers, driverMarker, driverLoad, locationRef] = [
  undefined,
  [],
  null,
  undefined,
  undefined,
];

let routeControlLayers = [];
const ZOOM_LEVEL_LIMIT = 13;
const zoomMapLayer = new window.L.FeatureGroup();

class NewLoadHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 250,
      eachTableHeight: window.outerHeight - 685,
      driverLocations: [],
      selectedSliderValue: 0,
      mappedMarkers: [],
      considerLocations: [],
      driverList: [],
      features: [],
      toggleLiveLocation: true,
      showConfirmModal: false,
      image:"",
      progress: 0,
      moveId: 0,
      loading: false,
      isClickUpload: false,
      containerCardId: null,
    };
    this.selectLocation = this.selectLocation.bind(this);
    this.driverLoadAddress = this.driverLoadAddress.bind(this);
    this.addLocationsToMap = this.addLocationsToMap.bind(this);
    this.changeCompleteDate = this.changeCompleteDate.bind(this);
    this.driversTypeGet = this.driversTypeGet.bind(this);
    this.getPlace = this.getPlace.bind(this);
    this.handleChangeSlider = this.handleChangeSlider.bind(this);
    this.geofenceLayer = new window.L.LayerGroup(null, {
      pane: "overlayPane",
    });
    this.geofenceLocationMarkerLayer = new window.L.LayerGroup(null, {
      pane: "overlayPane",
    });
    this.takeScreenShot = this.takeScreenShot.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.cancelUpload = this.cancelUpload.bind(this);
  }

  bindMap = () => {
    if (currentMap) {
      currentMap.remove();
      currentMap = new window.L.Map("TrackmapTab", {
        zoom: 8,
        center: [39.011902, -98.484246],
        minZoom: 1,
        zoomOffset: 1,
        layers: [streetTile()],
        renderer: window.L.canvas(),
      });
      currentMap.zoomControl.setPosition("bottomright");
      window.L.control.layers(baseMaps).addTo(currentMap);
      this.geofenceLayer.addTo(currentMap);
      this.geofenceLocationMarkerLayer.addTo(currentMap);
    } else {
      let check = true
      const permissionTrack =  document.getElementsByClassName("tracking-permission")
      if(permissionTrack && permissionTrack[0] && permissionTrack[0].innerHTML === "You do not have permission to view this page"){
        check = false
      }
      if(check){
        currentMap = new window.L.Map("TrackmapTab", {
          zoom: 8,
          center: [40.73061, -73.935242],
          minZoom: 1,
          zoomOffset: 1,
          layers: [streetTile()],
          renderer: window.L.canvas(),
        });
        currentMap.zoomControl.setPosition("bottomright");
        window.L.control.layers(baseMaps).addTo(currentMap);
        this.geofenceLayer.addTo(currentMap);
        this.geofenceLocationMarkerLayer.addTo(currentMap);
      }
    }


    this.subscribeToLocationChange();

    if (currentMap) {
      currentMap.on("zoomend", (e) => {
        this.zoomHandler(e.target._zoom);
      });
    }
    
    if(currentMap){
      currentMap.on("movestart",()=>{
        this.setState({toggleLiveLocation : false})
      })
    }
    
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/lib/MovingMarker.js";
    script.async = true;
    document.body.appendChild(script);
    this.bindMap();
    this.addLocationsToMap();
    this.setState({ driverList: this.props.driverList }, () => {
      this.driversTypeGet(this.props);
    });
  }

  takeScreenShot = (e,k) => {
    e.stopPropagation();
    
    let mapContainer = document.getElementById("trackingTabForScreenshot");
    let renderingArea = document.getElementById("renderingArea");
    this.setState({loading: true }, () => {
    html2canvas(mapContainer, {
      allowTaint: true,
      useCORS: true,
      width: mapContainer.offsetWidth,
      height: mapContainer.offsetHeight,
    }).then((canvas) => {
      let myImage = canvas.toDataURL("image/png");
      let ImageToUpload = canvas.toDataURL()
      this.setState({ image: myImage , imageToUpload: ImageToUpload },()=> {
        this.setState({ showConfirmModal: true , moveId: k , loading: false }, ()=> this.uploadDocument())
      });
    }).catch((e) => {
      console.error(e);
    });
  })
  }

  closeConfirmModal = () => {
    this.setState({ showConfirmModal: false , image: null,  docUploading:false })
  }

  toggleLiveLocation = (toggle) => {
    this.setState({
      toggleLiveLocation: toggle
    })
  }

  db = config.firebase.database();
  lastLocation = null;
  // subscribe to location change
  subscribeToLocationChange = () => {
    try {
      this.unsubscribeToLocationChange();
      const loadList = this.props.selectedLoads;
      if(loadList && loadList.driverOrderId && loadList.driverOrderId.driver) {
        let loggedInUser = JSON.parse(getStorage("loggedInUser"));

        const carrierId = 
        loggedInUser.role === "carrier"
          ? loggedInUser._id
          : loggedInUser.role === "fleetmanager"
          ? loggedInUser.fleetManager.carrier
          : undefined;

        const refString = `${carrierId}/currentLocation/${loadList.driverOrderId.driver}`;
        locationRef = this.db.ref(refString);
        locationRef.on("value", (snapshot) => {
          const currentDriverLocation = snapshot.val();

          if(!driverMarker && currentMap && currentDriverLocation && currentDriverLocation.location) {
            this.lastLocation = currentDriverLocation.location.reverse();
            driverMarker = window.L.Marker.movingMarker(
              [this.lastLocation],
              [],
              {
                id: loadList.driverOrderId.driver,
                icon: truckIconWithActiveGPS,
              }
            ).addTo(currentMap);

            if(this.state.toggleLiveLocation) {
              currentMap.fitBounds([this.lastLocation]);
            }
          } else if(driverMarker && currentMap && currentDriverLocation && currentDriverLocation.location) {
            const currentLocation = currentDriverLocation.location.reverse();
            const rotationAngle = angleFromCoordinate(
              this.lastLocation[0],
              this.lastLocation[1],
              currentLocation[0],
              currentLocation[1],
            );
            driverMarker.moveTo(
              { lat: currentLocation[0], lng: currentLocation[1] },
              6000,
              { rotationAngle: rotationAngle }
            )
            this.lastLocation = currentLocation;
            if(this.state.toggleLiveLocation) {
              currentMap.fitBounds([this.lastLocation]);
            }
          } else {
            const lastValidEvent = Array.isArray(loadList?.driverOrder) ? loadList.driverOrder.reverse().find((event) => !event.isVoidOut && event.departed) : {};
            const lastLoadLocation = (lastValidEvent?.address?.lat && lastValidEvent?.address?.lng) ? [lastValidEvent.address.lat, lastValidEvent.address.lng] : null;
            if (currentMap && lastLoadLocation) {
              const currentZoom = currentMap.getZoom();
              currentMap.setView(lastLoadLocation, currentZoom);
            }
          }
        });
      } else {
        const lastValidEvent = Array.isArray(loadList?.driverOrder) ? loadList.driverOrder.reverse().find((event) => !event.isVoidOut && event.departed) : {};
        const lastLoadLocation = (lastValidEvent?.address?.lat && lastValidEvent?.address?.lng) ? [lastValidEvent.address.lat, lastValidEvent.address.lng] : null;
        if (currentMap && lastLoadLocation) {
          const currentZoom = currentMap.getZoom();
          currentMap.setView(lastLoadLocation, currentZoom);
        }
      }
    } catch(e) {
      console.log("LoadHistoryException: ", e);
    }
  }

  // unsubscribe to location change
  unsubscribeToLocationChange = () => {
    try {
      if(locationRef) {
        locationRef.off();
        locationRef = null;
        if(driverMarker) {
          currentMap.removeLayer(driverMarker);
          driverMarker = null;
        }
      }
    } catch(e) {
      console.log("LoadHistoryException: ", e);
    }
  }

  componentWillUnmount() {
    this.unsubscribeToLocationChange();
  }

  componentWillReceiveProps(props) {
    this.driversTypeGet(props);
    if (
      (!this.props.selectedLoads && props.selectedLoads) ||
      (this.props.selectedLoads &&
        this.props.selectedLoads != props.selectedLoads)
    ) {
      if (locationTimer) {
        clearInterval(locationTimer);
      }
      this.addLocationsToMap();
      if(!this.state.isClickUpload) {
        this.bindMap();
    }
  }
  }

  componentDidUpdate(props) {
    if (!_.isEqual(props.driverList, this.props.driverList)) {
      this.setState({ driverList: this.props.driverList }, () => {
        this.driversTypeGet(this.props);
      });
    }
  }

  getTimeDifference = (prevTime, newTime) => {
    let calcTime = "00:00";
    let totalMinutes = moment(newTime).diff(moment(prevTime), "minutes");
    const isNegative = totalMinutes < 0;
    if (isNegative) {
      totalMinutes = totalMinutes * -1;
    }
    let h = Math.floor(totalMinutes / 60);
    let m = totalMinutes % 60;
    if (m == 59) {
      h = h + 1;
      m = 0;
    }
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    calcTime = h + ":" + m;
    return (
      <span style={{ color: isNegative ? "red" : "" }}>
        {isNegative ? "-" : ""}
        {calcTime}
      </span>
    );
  };

  getPlace = async (e, key, textKey) => {
    this.setState({ [textKey]: e.target.value });
   await getGooglePlaces(e.target.value).then((response) => {
      let features = response.data.results;
      this.setState({ [key]: features }, () => {});
    }).catch(e => console.log(e));
  };

  onSelect = (place, key, textKey, id) => {
    const address = {
      lat: place.geometry.location.lng,
      lng: place.geometry.location.lat,
      address: place.formatted_address,
    };
    this.setState({ [key]: [], [textKey]: "" });
    this.changeCompleteDate(id, undefined, undefined, address);
  };

  selectLocation(data) {
    let address = data.address;
    if (address && mapBreadcrumb) {
      mapBreadcrumb.flyTo([address.lat, address.lng], 18);
    }
  }

  driversTypeGet(props) {
    if (props.selectedLoads) {
      const groupedStatuses = props.selectedLoads.statusOrder.groupBy(function (
        n
      ) {
        return n.type;
      });
      const pullContainerDriver =
        groupedStatuses["PULLCONTAINER"] &&
        groupedStatuses["PULLCONTAINER"][0] &&
        groupedStatuses["PULLCONTAINER"][0].driver
          ? groupedStatuses["PULLCONTAINER"][0].driver._id
          : -1;
      const deliverLoadDriver =
        groupedStatuses["DELIVERLOAD"] &&
        groupedStatuses["DELIVERLOAD"][0] &&
        groupedStatuses["DELIVERLOAD"][0].driver
          ? groupedStatuses["DELIVERLOAD"][0].driver._id
          : -1;
      const dropAtYardDriver =
        groupedStatuses["DROPYARD"] &&
        groupedStatuses["DROPYARD"][0] &&
        groupedStatuses["DROPYARD"][0].driver
          ? groupedStatuses["DROPYARD"][0].driver._id
          : -1;
      const returnContainerDriver =
        groupedStatuses["RETURNCONTAINER"] &&
        groupedStatuses["RETURNCONTAINER"][0] &&
        groupedStatuses["RETURNCONTAINER"][0].driver
          ? groupedStatuses["RETURNCONTAINER"][0].driver._id
          : -1;
      this.setState({
        PULLCONTAINER: pullContainerDriver,
        DELIVERLOAD: deliverLoadDriver,
        DROPYARD: dropAtYardDriver,
        RETURNCONTAINER: returnContainerDriver,
      });
    }
  }

  async addLocationsToMap() {
    if (this.props.selectedLoads) {
      await getDriverLocationHistoryByLoad({
        loadRef: encodeURIComponent(this.props.selectedLoads.reference_number),
      }).then((response) => {
        const oneDotPoints = parseInt(response.data.data.length / 10);
        const sliderLables = {};
        if (oneDotPoints > 1) {
          for (let k = 0; k < 10; k++) {
            const time = moment(
              response.data.data[k * oneDotPoints].createdAt
            ).format("LT");
            sliderLables[k * oneDotPoints] = time;
          }
          sliderLables[response.data.data.length] = moment(
            response.data.data[response.data.data.length - 1].createdAt
          ).format("LT");
        }
        this.setState(
          {
            driverLocations: response.data.data,
            sliderLables,
            startIndexSlider: 0,
            stopIndexSlider: response.data.data.length,
          },
          () => {
            let LeafIcon = window.L.Icon.extend({
              options: {
                iconSize: [13, 50],
              },
            });
            let greenIcon = new LeafIcon({ iconUrl: "/assets/img/truck.png" });
            const groupedLocation = this.state.driverLocations.groupBy((n) => {
              return n.loadStatus;
            });
            Object.keys(groupedLocation).forEach((key, i) => {
              const driverLatlongs = [];
              const centerLocation = [];
              groupedLocation[key].forEach((obj) => {
                centerLocation.push([obj.location[1], obj.location[0]]);
                driverLatlongs.push(
                  new window.L.LatLng(obj.location[1], obj.location[0])
                );
              });
              if (i === 0) {
                try {
                  movingMarker = window.L.Marker.movingMarker(
                    [driverLatlongs[0]],
                    [],
                    { icon: greenIcon }
                  ).addTo(mapBreadcrumb);
                } catch (err) {}
              }
              if (driverLatlongs.length > 0 && mapBreadcrumb) {
                const firstpolyline = new window.L.Polyline(driverLatlongs, {
                  color: colorCodes[key],
                  weight: 7,
                  opacity: 1,
                  smoothFactor: 1,
                });
                firstpolyline.addTo(mapBreadcrumb);
                setTimeout(() => {
                  mapBreadcrumb.fitBounds([centerLocation]);
                }, 2000);
              }
            });
          }
        );
      }).catch(e => console.log(e));
    }
  }

  loadMap = () => {
    const loadInfo = this.props.selectedLoads;
    let allLocations = [];
    let centerLocation = [];

    if (mapBreadcrumb) {
      mapBreadcrumb.remove();
      mapBreadcrumb = undefined;
    }

    let satelliteTile = L.tileLayer(
      process.env.REACT_APP_MAP_BOX_SATELLITE_TILE_URL,
      {
        maxZoom: 19,
      }
    );
    let placeTile = L.tileLayer(
      process.env.REACT_APP_MAP_BOX_STREET_TILE_URL,
      {
        maxZoom: 19,
      }
    );
    let transportTile = L.tileLayer(
      process.env.REACT_APP_MAP_BOX_STREET_TILE_URL,
      {
        maxZoom: 19,
      }
    );
    let LeafIcon = window.L.Icon.extend({
      options: {
        iconSize: [12, 20],
      },
    });
    if (!window.L) {
      return;
    }
    let greenIcon = new LeafIcon({ iconUrl: "/assets/img/Small_Pin.png" });
    let breadcrumb1 = document.getElementById("breadcrumb1");
    if (breadcrumb1) {
      mapBreadcrumb = new window.L.Map("breadcrumb1", {
        minZoom: 1,
        zoomOffset: 1,
        renderer: window.L.canvas(),
      });
      mapBreadcrumb.addLayer(satelliteTile);
      mapBreadcrumb.addLayer(transportTile);
      mapBreadcrumb.addLayer(placeTile);
      if (loadInfo) {
        loadInfo.statusOrder.forEach((element) => {
          if (
            element.address &&
            element.address.lat !== 0 &&
            element.address.lng !== 0
          ) {
            allLocations.push(
              new window.L.LatLng(element.address.lat, element.address.lng)
            );
            allMarkers.push({
              status: convertTMSStatusName(
                loadInfo.type_of_load,
                element.status
              ),
              location: [element.address.lat, element.address.lng],
            });
            centerLocation.push([element.address.lat, element.address.lng]);
          }
        });
      }
      allMarkers.forEach((obj) => {
        window.L.marker(obj.location, { icon: greenIcon })
          .bindPopup(convertTMSStatusName(loadInfo.type_of_load, obj.status))
          .addTo(mapBreadcrumb);
      });
      if (centerLocation.length > 0) mapBreadcrumb.fitBounds([centerLocation]);
    }
  };

  driverLoadAddress(status) {
    const selectedLoad = this.props.selectedLoads;
    if (selectedLoad && selectedLoad.statusHistory) {
      return selectedLoad.statusHistory.find((obj) => {
        return obj.newStatus === status;
      });
    } else {
      return undefined;
    }
  }

  handleChangeSlider(value) {
    this.setState(
      {
        selectedSliderValue: value,
      },
      () => {
        if (movingMarker && mapBreadcrumb) {
          const previousLocation = this.state.driverLocations[value - 1];
          const obj = this.state.driverLocations[value];
          if (obj) {
            let rotationAngle = 0;
            if (previousLocation) {
              rotationAngle = angleFromCoordinate(
                previousLocation.location[1],
                previousLocation.location[0],
                obj.location[1],
                obj.location[0]
              );
            }
            movingMarker.moveTo(
              new window.L.LatLng(obj.location[1], obj.location[0]),
              100,
              { rotationAngle: rotationAngle }
            );
            mapBreadcrumb.fitBounds(
              [new window.L.LatLng(obj.location[1], obj.location[0])],
              { maxZoom: mapBreadcrumb.getZoom() }
            );
          }
        }
      }
    );
  }

  changeCompleteDate(driverOrderId, timeOfStatus, status, address) {
    const { selectedLoads } = this.props;
    const formData = new FormData();
    formData.append(
      "reference_number",
      selectedLoads && selectedLoads.reference_number
    );
    formData.append("driverOrderId", driverOrderId);
    formData.append("arrivedOrDeparted", status);
    formData.append("date", moment(timeOfStatus).toISOString());
    formData.append("strictChange", true);

    updateDriverOrderById(formData)
      .then((result) => {
        this.setState({ [`${driverOrderId}_calendar_${status}`]: undefined });
        toastr.show("You have updated the status!", "success");
      })
      .catch((err) => {
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });
  }

  addLocationsToMap1 = (log) => {
    const driverLatlongs = [];
    const centerLocation = [];
    let allMarkers = [];
    let mappedMarkers = [];
    let LeafIcon = window.L.Icon.extend({
      options: {
        iconSize: [5, 5],
      },
    });
    let greenIcon = new LeafIcon({ iconUrl: "/assets/img/Small_Pin.png" });

    log.forEach((obj) => {
      centerLocation.push([obj.location[1], obj.location[0]]);
      allMarkers.push({
        status: obj.loadStatus ? obj.loadStatus : obj.eldDtatus,
        location: [obj.location[1], obj.location[0]],
      });

      driverLatlongs.push(
        new window.L.LatLng(obj.location[1], obj.location[0])
      );
    });

    allMarkers.forEach((obj) => {
      let i = window.L.marker(obj.location, { icon: greenIcon })
        .bindPopup(
          convertTMSStatusName(
            this.props.selectedLoads.type_of_load,
            obj.status
          )
        )
        .addTo(mapBreadcrumb);
      mappedMarkers.push(i);
    });
    this.setState({ mappedMarkers });
    if (driverLatlongs.length > 0 && mapBreadcrumb) {
      firstpolyline = new window.L.Polyline(driverLatlongs, {
        color: "#A5DB03",
        weight: 5,
        opacity: 1,
        smoothFactor: 1,
      });
      firstpolyline.addTo(mapBreadcrumb);
      setTimeout(() => {
        mapBreadcrumb.fitBounds([centerLocation]);
      }, 2000);
    } else {
      this.state.mappedMarkers.forEach((e) => {
        mapBreadcrumb.removeLayer(e);
      });
      if (firstpolyline) {
        mapBreadcrumb.removeLayer(firstpolyline);
      }
    }
  };

  resetMap = () => {
    // TODO: add reset map logic
  };

  clearAllRoutes = () => {
    if (routeControlLayers.length) {
      for (const routeL in routeControlLayers) {
        routeControlLayers[routeL].remove();
      }
      routeControlLayers = [];
    }
  };

  drawRouteToMap = (routes) => {
    this.clearAllRoutes();
    zoomMapLayer.clearLayers();
    if (routes && routes.routes) {
      for (const key in routes.routes) {
        const route = routes.routes[key];
        const index = routeControlLayers.length;
        routeControlLayers.push(route);
        currentMap.addControl(routeControlLayers[index]);
      }
    }

    // remove before all markers 
    currentMap.eachLayer(function (layer) {
      layer.closePopup();
    });
    
    // Display company name marker on the screen
    let index = 0;
    let load = this.props.selectedLoads
    let arrivedDepartedDateTimes = arrivedDepartedDateTime({load});
    if (routes.companyNames) {
      for (const key in routes.companyNames) {
        zoomMapLayer.addLayer(routes.companyNames[key]);
        const summaryComponent = L.DomUtil.create('div', ' position-relative click-card');
        summaryComponent.innerHTML = ReactDOMServer.renderToString(
          <div className="position-relative">
            <ContainerSummary details={routes.driverOrders[index]} nextDetails = {routes.driverOrders[index+1]} arrivedDepartedDateTimes={arrivedDepartedDateTimes} index={index} />
          </div>
        );
        const driverLatlongs = routes.companyNames[key]._latlng;
        let marker = window.L.marker([driverLatlongs.lat, driverLatlongs.lng], { icon: inactiveWaypointIcon }).bindPopup(summaryComponent, { closeOnClick: false, autoClose: false, className: routes.driverOrders[index].selectedLeg ? 'z-1' : "" }).addTo(currentMap);
        window.L.DomEvent.addListener(summaryComponent, 'click', (e) => {
          const clickedElement = e.target.closest('.card-click');
          if (clickedElement) {
            const SummaryCardId = clickedElement.id;
            this.setState({ containerCardId: SummaryCardId })
            if (SummaryCardId) {
              marker.remove()
              marker.unbindPopup();
            }
          }
        });
        marker.openPopup();
        index++;
      }
    }

    this.geofenceLayer.clearLayers();
    this.getlatlngForPolygon(routes.driverOrders)
    
    if (routes.boundsToFit.length > 0) {
      currentMap.fitBounds(routes.boundsToFit, {
        padding: [500, 50],
      });
    }

  };

  getlatlngForPolygon = (driverOrder) => {
    _.forOwn(driverOrder, (leg, key) => {
      if (leg.customerId?.geofence?.polygon?.length > 0){
        let latlngs = leg.customerId?.geofence?.polygon[0];
        let actualLatlng = latlngs.map((arr)=> { return arr[0] < 0 ? _.reverse(arr): arr })
        let polygon = window.L.polygon(actualLatlng, { color: 'blue' }).addTo(this.geofenceLayer);
        currentMap.fitBounds(polygon.getBounds());
      } else if(leg.customerId?.address?.lat && leg.customerId?.address?.lng) {
        let latlngs = [leg.customerId?.address?.lat, leg.customerId?.address?.lng];
        let polygon = window.L.circle(latlngs, { color: 'blue', radius: 150 }).addTo(this.geofenceLayer);
        currentMap.fitBounds(polygon.getBounds());
      }
    });
  }

  zoomHandler = (zoomLevel) => {
    if (zoomLevel >= ZOOM_LEVEL_LIMIT) {
      if (currentMap) {
        currentMap.addLayer(zoomMapLayer);
      }
    } else {
      if (currentMap) {
        currentMap.removeLayer(zoomMapLayer);
      }
    }
  };

  uploadDocument = () => {
    const formData = new FormData();
    formData.append("proofOfDelivery", this.state.image);
    formData.append("shipmentId", new Date().getTime().toString());
    uploadDocumentTracking(formData).then((res) => {
      if(res) {
        this.setState({progress: 100, url: res.url})
      }
    })
  }

  uploadDocumentForLoad = () => {
    this.setState({isClickUpload:true})
    let data = {
      moveId: `move-${this.state.moveId}`,
      reference_number: this.props.selectedLoads.reference_number,
      type: "Wait Time",
      url: this.state.url
    }
    this.setState({docUploading: true})
    uploadDocumentForLoad(data).then((response) => {
      if (response) {
        this.setState({
          docUploading: false,
          progress: 0,
          showConfirmModal: false , image: null
        });
        this.props.updateList(response)
      }
    });
  
  };

  cancelUpload = ()=>{
    this.setState({
      progress: 0,
      image:null,
      showConfirmModal: false
    });
  }

  renderReturn = () => {
    const selectedLoad = this.props.selectedLoads;
    let { selectedSliderValue, driverList } = this.state;
    if (driverList && driverList.length > 0) {
      driverList = driverList.filter(
        (D) => D.driver && D.driver.isDeleted === false
      );
    }

    return (
      <React.Fragment>
        <div className="tab" style={{height: 'calc(100vh - 196px)'}} id='trackingTabForScreenshot'>
          <NewDriverDetails
            allLoads={this.props.selectedLoads}
            drawRouteToMap={this.drawRouteToMap}
            mapInstance={currentMap}
            toggleLiveLocation={this.toggleLiveLocation}
            liveLocationToggled={this.state.toggleLiveLocation}
            takeScreenShot={this.takeScreenShot}
            plotDriverEntryExitLocations={true}
            geofenceLocationMarkerLayer={this.geofenceLocationMarkerLayer}
            loading={this.state.loading}
            containerCardId={this.state.containerCardId}
          />
          <div
            className="tab__track-map"
            id="TrackmapTab"
            style={{
              height: `calc(100vh - 60px})`,
            }}
          ></div>
        </div>
        <Modal className="backdrop_modal_open" show={this.state.showConfirmModal} centered animation={false} bsSize="lg">
          <Modal.Header>
            <Modal.Title>Confirm Document</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
              <div id="renderingArea">
                <img src={this.state.image} />
              </div>
              <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                <IconUpload className="mr-20" />
                <div className="w-100">
                  <div className="font-medium d-flex justify-content-between w-100 mb-1">
                    <div></div>
                    <div className="ml-auto">
                      {this.state.progress}%
                    </div>
                  </div>
                  <div className="progress">
                    <div
                      className="progress-bar bg-primary"
                      role="progressbar"
                      aria-valuenow={`${this.state.progress}`}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: `${this.state.progress}%` }}
                    ></div>
                  </div>
                </div>
                <button 
                onClick={()=>this.cancelUpload()}
                className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                  <span>
                    {" "}
                    <IconTimes className="" />
                  </span>
                </button>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => this.closeConfirmModal()}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              disabled={this.state.docUploading || this.state.progress!==100}
              onClick={() => {
                this.uploadDocumentForLoad();
              }}
            >
              {this.state.isSaving && (
                <span
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Upload Document
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  
  };

  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_tracking") ||
          (((loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer)|| 
            loggedInUser.role === "customer") &&
            loggedInUser.permissions.includes("customer_employee_load_tracking"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1 className="tracking-permission">You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1 className="tracking-permission">You do not have permission to view this page</h1>
          </div>
        );
  }
}

function _toDeg(rad) {
  return (rad * 180) / Math.PI;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}

const states = (state) => ({
  driverList: state.TmsReducer.drivers,
});

NewLoadHistory = reduxForm({
  // a unique name for the form
  form: "tmsloadHistoryedit",
  enableReinitialize: true,
})(NewLoadHistory);
export default connect(states, mapDispatchToProps)(NewLoadHistory);
