import React, { PureComponent, Component } from "react";
import { IconAngleArrowDown, IconMinus } from '../../../Common/Icons';
import NewPriceInput from '../../../Common/PriceInput/NewPriceInput';
import { newSmallSelectStyle } from '../../../../assets/js/select-style';
import  SelectCustomComponent  from 'pages/tms/SelectComponent';
import { convertUnit, Currency, isGlobalizationEnabled, updateTerminology } from '../../../../services';
import CarrierRateRedirect from "./CarrierRateRedirect";
import CustomSelect from "Components/Common/CustomSelect";
import { NumberFormatterV2 } from "../Components/NumberFormater";
import SelectCustomCurrency from "pages/tms/SelectCurrency";

class CarrierPayRow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEdited: false,
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (
      this.state.isEdited &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target)
    ) {
      this.setState({ isEdited: false });

      this.props.updateRowPricing(this.props.index);
    }
  }

  onEnter(e,index) {
    if(e.keyCode === 13) {
        e?.target?.blur()
        this.props.unselectCell()
        if(!this.state.isEdited) return
        this.props.updateRowPricing(index);
    }
}

  render() {
    let { index, charges, item , pricingchanges, pricing,addCharge, DeletePriceRow, conflictedCharge, isSuccess, updateRowPricing, rowIndex, selectedCell, selectCell, unselectCell, isChargeLabel, carrierIndex, keyPressOnNumberInput } = this.props;
    let success = rowIndex === index && isSuccess;
    return (
      <tr 
 
        key={index}
        style={{ opacity: `${addCharge ? '0.2' : ""}`, transition: 'background .4s ease-out' }}
        className={`select-custom-input ${success ? 'background-success' : ''} ${conflictedCharge ? "outline-border-1 rounded-3" : ""} ${addCharge ? "disabled-pointer-events" : ""}`}
        onKeyDown={(e) => { this.setState({ isEdited: false }); this.onEnter(e, index); }}
        ref={this.setWrapperRef}
      >
        <td className={`px-1 pl-2 carrierpay-chargecell-${carrierIndex}-${index}
                ${selectedCell === `carrierpay-chargecell-${carrierIndex}-${index}` ? 'newdriverrow td-clicked' : ""}
                `}
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`carrierpay-chargecell-${carrierIndex}-${index}`)}
        >
          <div className="d-flex justify-content-between align-items-center">
            {pricing[index]?.chargeName ? pricing[index].chargeName.toUpperCase() : pricing[index].name.toUpperCase() || <span className="text-muted">Select Status</span>}
            {(!this.props.isTenderId && isChargeLabel) && <IconAngleArrowDown className='' /> }
            {selectedCell === `carrierpay-chargecell-${carrierIndex}-${index}` &&
              <SelectCustomComponent
                name='charges'
                searchable={true}
                refClassName={`carrierpay-chargecell-${carrierIndex}-${index}`}
                listData={charges}
                keyName="chargeName"
                onSelect={(name, value) => {
                  if (this.props.isTenderId || !isChargeLabel) return
                  pricingchanges(index, {
                    target: { value: value.value, name: "name", chargeData: { ...value } },
                  })
                  updateRowPricing(index);
                  unselectCell()
                }}
                unselectCell={unselectCell}
              />}
          </div>
        </td>
        {isChargeLabel && <td><span className="badge badge-sm text-white badge-status-navy-1">{pricing[index]?.labelName ? pricing[index].labelName : ""}</span></td>}
        <td className={` px-0
                 ${selectedCell === `cell-${index}-2` ? 'newdriverrow td-clicked' : ""}
                `}
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`cell-${index}-2`)}
        >
          <div className={"custom-select-border"}>
            <input
              disabled={this.props.isTenderId || !isChargeLabel}
              type="text"
              className={"form-control border-0 line-height-15"}
              name="description"
              value={pricing[index].description || ""}
              onChange={(e) => {
                if(this.props.isTenderId || !isChargeLabel) return
                this.setState({ isEdited: true });
                pricingchanges(index, e)
              }}
              placeholder="Add Description..."
              autoComplete="off"
              onBlur={() => unselectCell()}
            />
          </div>
        </td>

        {pricing[index].name === "Fuel" ? (
          <td className={` w-200 cell-${index}-3-${carrierIndex} px-0 ${[`amountCell-${index}-3-${carrierIndex}`,`finalAmountCell-${index}-3-${carrierIndex}`, `typeCell-${index}-3-${carrierIndex}`].includes(selectedCell) && 'newdriverrow td-clicked'}`}
          >
            <div className={"d-flex custom-select-border justify-content-between"}>
              <div className="d-flex align-items-center justify-content-center w-100" onClick={() => selectCell(`amountCell-${index}-3-${carrierIndex}`)}>
                {!isGlobalizationEnabled() && (<input
                  className="form-control border-0 text-right"
                  type="number"
                  name="amount"
                  min={0}
                  disabled={this.props.isTenderId || !isChargeLabel}
                  value={pricing[index].amount || ""}
                  onChange={(e) => {
                    if (this.props.isTenderId || !isChargeLabel) return
                    this.setState({ isEdited: true });
                    pricingchanges(index, e);
                  }}
                  autoComplete="off"
                  onBlur={() => unselectCell()}
                  onFocus={(e)=>e.target.select()}
                />)}
                {isGlobalizationEnabled() && (
                    <NumberFormatterV2 
                        selected={[`amountCell-${index}-3-${carrierIndex}`,`finalAmountCell-${index}-3-${carrierIndex}`,`typeCell-${index}-3-${carrierIndex}`].includes(selectedCell)}
                        className="form-control border-0 text-right"
                        type="number"
                        disabled={this.props.isTenderId || !isChargeLabel}
                        name="amount"
                        min={0}
                        value={pricing[index].amount || ""}
                        onChange={(e) => {
                          if (this.props.isTenderId || !isChargeLabel) return
                          this.setState({ isEdited: true });
                          pricingchanges(index, e);
                        }}
                        autoComplete="off"
                        onBlur={()=>unselectCell()}
                        onFocus={(e)=>e.target.select()}
                        showCurrency={false}
                        autoFocus={false}
                        childClassName="form-control border-0 text-right"
                    />
                  )}
              </div>
              <div className="d-flex justify-content-between align-items-center min-width-50 bg-gray-50 rounded-3 p-1 pointer" onClick={() => selectCell(`typeCell-${index}-3-${carrierIndex}`) }>
                <div >
                  {pricing[index].type && pricing[index].type === "percentage" ? "%" : (this.props.billFromCurrency?.symbol ?? Currency.default()?.symbol ?? '$')}
                </div>
                <div className="ml-1"><IconAngleArrowDown /></div>
                {selectedCell === `typeCell-${index}-3-${carrierIndex}` &&
                  <SelectCustomCurrency
                    name='value'
                    searchable={false}
                    refClassName={`cell-${index}-3-${carrierIndex}`}
                    listData={[
                      {
                        value: "fixed",
                        label: `${this.props?.billFromCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                      },
                      {
                        value: "percentage",
                        label: "%",
                      },
                    ]}
                    keyName="label"
                    onSelect={(name, value) => {
                      if (this.props.isTenderId || !isChargeLabel) return
                      this.setState({ isEdited: true });
                      pricingchanges(index, {
                        target: {
                          value: value.value,
                          name: "type",
                        },
                      })
                      this.props.updateRowPricing(index);
                      unselectCell()
                    }}
                    unselectCell={unselectCell}
                  />
                }
              </div>
              <div className="d-flex w-100" onClick={() => selectCell(`finalAmountCell-${index}-3-${carrierIndex}`)}>
                {!isGlobalizationEnabled() && (<input
                  disabled={this.props.isTenderId || !isChargeLabel}
                  placeholder="0.00"
                  onKeyDown={(e) => {
                     keyPressOnNumberInput(e);
                  }}
                  min={0}
                  className="form-control border-0 text-right"
                  type="number"
                  name="finalAmount"
                  value={pricing[index].finalAmount || ""}
                  onChange={(e) => {
                    if(this.props.isTenderId || !isChargeLabel) return
                    this.setState({ isEdited: true });
                    pricingchanges(index, e)
                  }}
                  autoComplete="off"
                  onBlur={() => unselectCell()}
                />)}
                  {isGlobalizationEnabled() && (
                      <NumberFormatterV2 
                      selected={[`finalAmountCell-${index}-3-${carrierIndex}`,`typeCell-${index}-3-${carrierIndex}`].includes(selectedCell)}
                      placeholder= "0.00"
                          min={0}
                          onKeyDown={(e) => {
                              keyPressOnNumberInput(e);
                          }}
                          className="form-control border-0 text-right"
                          type="number"
                          disabled={this.props.isTenderId || !isChargeLabel}
                          name="finalAmount"
                          value={pricing[index].finalAmount || ""}
                          onChange={(e) => {
                            if(this.props.isTenderId || !isChargeLabel) return
                            this.setState({ isEdited: true });
                            pricingchanges(index, e)
                          }}
                          autoComplete="off"
                          onBlur={()=>unselectCell()}
                          showCurrency={false}
                          childClassName="form-control border-0 text-right"
                          autoFocus={false}
                      />
                            )}
              </div>
            </div>
          </td>
        ) : (
          <td className={` text-right px-0
                     ${selectedCell === `cell-${index}-3` ? 'newdriverrow td-clicked' : ""}
                    `}
            onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`cell-${index}-3`)}
          >
            <div className={"custom-select-border"}>
              <span data-tip data-for={`discount${index}`}>
                {!isGlobalizationEnabled() && ( <input
                  disabled={this.props.isTenderId || !isChargeLabel}
                  min={0}
                  placeholder="0.00"
                  type="number"
                  className={`form-control line-height-15 text-right border-0`}
                  name="finalAmount"
                  value={pricing[index].finalAmount || ""}
                  onChange={(e) => {
                    if (this.props.isTenderId || !isChargeLabel) return
                    this.setState({ isEdited: true });
                    pricingchanges(index, e)
                  }}
                  autoComplete="off"
                  onBlur={() => unselectCell()}
                />)}
                {isGlobalizationEnabled() && (
                    <NumberFormatterV2
                    selected={[`cell-${index}-3`].includes(selectedCell)}
                    placeholder="0.00"
                    type="number"
                    className={`form-control line-height-15 text-right border-0`}
                    disabled={this.props.isTenderId || !isChargeLabel}
                    name="finalAmount"
                    onKeyDown={(e) => {
                      keyPressOnNumberInput(e);
                    }}
                    value={pricing[index].finalAmount || ""}
                    onChange={(e) => {
                      console.log("called>>>")
                      if (this.props.isTenderId || !isChargeLabel) return
                      this.setState({ isEdited: true });
                      pricingchanges(index, e)
                    }}
                    autoComplete="off"
                    onBlur={()=>unselectCell()}
                    showCurrency={false}
                    autoFocus={false}
                    childClassName={'pr-2'}
                />
                )}

              </span>
            </div>
          </td>
        )}

        <td className={` px-0 ${selectedCell === `cell-${index}-4` && 'newdriverrow td-clicked'}`}
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`cell-${index}-4`)}
        >
          <div className={"custom-select-border"}>
            <NewPriceInput
              disabled = {this.props.isTenderId || !isChargeLabel}
              name="unit"
              value={pricing[index].unit}
              onChange={(val) => {
                if (this.props.isTenderId || !isChargeLabel) return
                this.setState({ isEdited: true });
                pricingchanges(index, {
                  target: {
                    value: val,
                    name: "unit",
                  },
                });
              }}
              onBlur={() => unselectCell()}
            />
          </div>
        </td>

        <td className={`carrier-pertypecell-${carrierIndex}-${index} px-1 pl-2
                  ${selectedCell === `carrier-pertypecell-${carrierIndex}-${index}` ? 'newdriverrow td-clicked' : ""}
                `}
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`carrier-pertypecell-${carrierIndex}-${index}`)}
        >
          <div className="d-flex justify-content-between align-items-center">
            {pricing[index]?.perType || <span className="text-muted">Select...</span>}
            <IconAngleArrowDown className='' />
            {selectedCell === `carrier-pertypecell-${carrierIndex}-${index}` &&
              <SelectCustomComponent
                name='perType'
                searchable={true}
                refClassName={`carrier-pertypecell-${carrierIndex}-${index}`}
                listData={options}
                keyName="label"
                onSelect={(name, i) => {
                  if (this.props.isTenderId || !isChargeLabel) return
                  pricingchanges(index, {
                    target: {
                      value: i.value,
                      name: "perType",
                    },
                  })
                  if (pricing[index].name === "Fuel") {
                    pricingchanges(index, {
                        target: {
                            value: i.value !== "percentage" ? "fixed" : "percentage",
                            name: "type",
                        },
                    })
                  }
                  updateRowPricing(index);
                  unselectCell()
                }
                }
                unselectCell={unselectCell}
              />}
          </div>
        </td>
        <td className={` (${!pricing[index].perType} && ${!success}) ?  px-0
                ${selectedCell === `cell-${index}-6` ? 'newdriverrow td-clicked' : ""}
                 text-right` }
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`cell-${index}-6`)}
        >
          <div className={"custom-select-border"}>
          {!isGlobalizationEnabled() && 
            <input
              min={0}
              type="number"
              className={"form-control text-right border-0 line-height-15"}
              name="freeDays"
              disabled={this.props.isTenderId || !pricing[index].perType || pricing[index].perType === "percentage" || !isChargeLabel}
              value={pricing[index].freeDays || ""}
              onChange={(e) => {
                if (this.props.isTenderId || !isChargeLabel) return
                this.setState({ isEdited: true });
                pricing[index].perType && pricingchanges(index, e);
              }}
              placeholder="0"
              autoComplete="off"
              onBlur={() => unselectCell()}
            />}
            {isGlobalizationEnabled() && (
                <NumberFormatterV2
                    selected={selectedCell === `cell-${index}-6`}
                    min={0}
                    type="number"
                    className={"form-control text-right border-0 line-height-15"}
                    name="freeDays"
                    onKeyDown={(e) => {
                      keyPressOnNumberInput(e);
                    }}
                    disabled={this.props.isTenderId || !pricing[index].perType || pricing[index].perType === "percentage" || !isChargeLabel}
                    value={pricing[index].freeDays || ""}
                    onChange={(e) => {
                      if (this.props.isTenderId || !isChargeLabel) return
                      this.setState({ isEdited: true });
                      pricing[index].perType && pricingchanges(index, e);
                    }}
                    placeholder="0"
                    autoComplete="off"
                    onBlur={()=>unselectCell()}
                    showCurrency={false}
                    childClassName={'pr-2'}
                />
            )}
          </div>
        </td>

        <td className={`((${!pricing[index].perType} && ${!success}))  px-0
                 ${selectedCell === `cell-${index}-7` ? 'newdriverrow td-clicked' : ""} text-right
                `}
          onClick={() => (!this.props.isTenderId && isChargeLabel) && selectCell(`cell-${index}-7`)}
        >
          <div className={"custom-select-border"}>
            {!isGlobalizationEnabled() && (
            <input
              min={0}
              type="number"
              className={"form-control text-right border-0 line-height-15"}
              name="chargePerDay"
              disabled={this.props.isTenderId || !pricing[index].perType  || pricing[index].perType === "percentage" || !isChargeLabel}
              value={pricing[index].chargePerDay || ""}
              onChange={(e) => {
                if (this.props.isTenderId || !isChargeLabel) return
                this.setState({ isEdited: true });
                pricing[index].perType && pricingchanges(index, e);
              }}
              placeholder="0"
              autoComplete="off"
              onBlur={() => unselectCell()}
            />
            )}
            {isGlobalizationEnabled() && (
                <NumberFormatterV2
                    selected={selectedCell===`cell-${index}-7`}
                    min={0}
                    type="number"
                    className={"form-control text-right border-0 line-height-15"}
                    name="chargePerDay"
                    onKeyDown={(e) => {
                      keyPressOnNumberInput(e);
                    }}
                    disabled={this.props.isTenderId || !pricing[index].perType  || pricing[index].perType === "percentage" || !isChargeLabel}
                    value={pricing[index].chargePerDay || ""}
                    onChange={(e) => {
                      if (this.props.isTenderId || !isChargeLabel) return
                      this.setState({ isEdited: true });
                      pricing[index].perType && pricingchanges(index, e);
                    }}
                    placeholder="0"
                    autoComplete="off"
                    onBlur={()=>unselectCell()}
                    showCurrency={false}
                    childClassName={'pr-2'}
                />
            )}
            
          </div>
        </td>
        <td>
        <CarrierRateRedirect isTenderId={this.props.isTenderId} item={item}/>
        </td>
        <td>
        <button
          className="btn btn-xs btn-circle bg-soft-danger"
          disabled={this.props.isTenderId }
          onClick={() => !this.props.isAPICall && DeletePriceRow(item?._id)}
        >
          <IconMinus className="text-danger" />
        </button>
      </td>
      </tr>
    )
  }
}
export default CarrierPayRow;

const options = [
  { value: "perday", label: "perday" },
  { value: "perhour", label: "perhour" },
  { value: "permile", label: `per${convertUnit().toLowerCase()}` },
  {
    value: "perpound",
    label: `per${convertUnit("convertedUnit", "weight").toLowerCase()}`,
  },
  { value: "fixed", label: "fixed" },
  { value: "percentage", label: "percentage" },
];

const CHARGE_TYPES = {
  CUSTOMER_RATES: { title: `Customer Rates`, shortName: 'CR', name: 'CUSTOMER_RATES' },
  DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING' },
  SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED' },
  MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED' },
  PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES' },
  ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES' },
  CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES' },
};