import { browserHistory } from "react-router";
import moment from 'moment';
import _ from 'lodash';
import $ from "jquery";
import configuration from "../../../config";
import {DateTimeFormatUtils, checkAndHideClearButton, checkAndShowClearButton, getStorage, isManageFleetOrOutSource, makeOptionsForSelect, updateQueryString, visibleCloseBtn, visibleToastCount } from "../../../services";
import { convertTMSStatusName } from '../../../Components/Common/functions';
import { ALL_DISPATCHER_STATUS } from "../Dispatcher/utility";
import { createDispatcherFilter } from "../Dispatcher/DispatcherView/actionCreators";
import { colToLoadField, CUSTOMER_FILTER_TYPES, GroupTypes, getDynamicRanges, typeMapping } from "./constants";
import { getSelectedDate } from "../../../Components/Common/DispatcherDayCalenderFilter/dateFilter";
import { createSource, EXPORT_LOAD_TYPE, IMPORT_LOAD_TYPE, tmsEndMoveEvents } from "../constant";
import { MOVE_TYPES } from "constants";
import { SCREENGRAB_UNSUPPORTED_PORTS, DIRECT_APPT_BOOKING_PORTS } from "../../../constants";
import { getRoutingEvents } from "../Load/DriverOrderUtility";


const AllTypes = [
  'groupedCity',
  'groupedProfile',
  'groupedZipCode',
]
export const getCardSelectParams = (filterParams, selectedStatus, subFilter, subFilter2, selectedCard, STATUSES = ALL_DISPATCHER_STATUS) => {
  if (Array.isArray(selectedStatus) && selectedStatus?.length) {
     selectedStatus.forEach(select =>{
  if (
      [
        "CAUTION_COUNT",
        "LOCAL_COUNT",
        "SCALE_COUNT",
        "NOTE_COUNT",
        "GENSET_COUNT",
        "DOMESTIC_COUNT",
        "EV_COUNT",
        "WASTE_COUNT",
        "GDP_COUNT",
        "IS_RAIL_COUNT",
        "REEFER_COUNT",
        "HOT_COUNT",
        "LIQUOR_COUNT",
        "HIGHWAY_COUNT",
        "SCALES_COUNT",
        "STREET_TURN_COUNT"
      ].includes(select)
  ) {
    filterParams["status"] = STATUSES;
    if (selectedStatus.includes("CAUTION_COUNT")) {
      filterParams.hazmat = true;
    }
    
    if (selectedStatus.includes("LOCAL_COUNT")) {
      filterParams.routeTypeLocal = "Local";
    } 
    if (selectedStatus.includes("GENSET_COUNT")) {
      filterParams.isGenset = true;
    } 
    if (selectedStatus.includes("DOMESTIC_COUNT")) {
      filterParams.domestic = true;
    } 
    if (selectedStatus.includes("EV_COUNT")) {
      filterParams.ev = true;
    } 
    if (selectedStatus.includes("WASTE_COUNT")) {
      filterParams.waste = true;
    } 
    if (selectedStatus.includes("GDP_COUNT")) {
      filterParams.gdp = true;
    } 
    if (selectedStatus.includes("IS_RAIL_COUNT")) {
      filterParams.isRail = true;
    } 
    if (selectedStatus.includes("SCALE_COUNT")) {

      filterParams.overWeight = true;
    } 
    if (selectedStatus.includes("NOTE_COUNT")) {

      filterParams.officeNote = true;
    } 
    if (selectedStatus.includes("REEFER_COUNT")) {
      filterParams.temperature = true;
    } 
    if (selectedStatus.includes("HOT_COUNT")) {
      filterParams.isHot = true;
    }
    if (selectedStatus.includes("STREET_TURN_COUNT")) {
      filterParams.isStreetTurn = true;
    }
    if (selectedStatus.includes("LIQUOR_COUNT")) {
      filterParams.liquor = true;
    } 
    if (selectedStatus.includes("HIGHWAY_COUNT")) {
      filterParams.routeType = "Highway";
    }
    if (selectedStatus.includes("SCALES_COUNT")) {
      filterParams.scale = true;
    } 
  }

}
  )
    // Check if the selectedCard is "PENDING" or "AVAILABLE"
    if (selectedCard === "PENDING" || selectedCard === "AVAILABLE") {
      // If true, update the filterParams status array and remove selectedCard
      filterParams["status"] = [selectedCard];
      delete filterParams.selectedCard;
    } else {
      // If selectedCard is defined, update filterParams.selectedCard
      if (selectedCard) filterParams.selectedCard = selectedCard;
    }
  return filterParams;
} else {
   // Selected Card Param for getloads
   filterParams.selectedCard = selectedCard;

   if (selectedCard === "PENDING" || selectedCard === "AVAILABLE") {
     filterParams["status"] = [selectedCard];
     delete filterParams.selectedCard;
   }
 
   if (selectedCard === "DISPATCHED" && subFilter) {
     filterParams.subFilter = subFilter;
   }
 
   if (selectedCard === "APPLIED_PICKUP_COUNT") {
     if (["PENDING", "AVAILABLE"].indexOf(subFilter) > -1) {
       filterParams["status"] = [subFilter];
     }
     if (subFilter2) {
       filterParams.subFilter = subFilter2;
     }
   }
   if (selectedCard === "LFD_COUNT") {
     if (["PENDING", "AVAILABLE"].indexOf(subFilter) > -1) {
       filterParams["status"] = [subFilter];
     }
     if (subFilter2) {
       filterParams.subFilter = subFilter2;
     }
   }
   if (selectedCard === "PICKUP_APPT_COUNT") {
     if (["PENDING", "AVAILABLE"].indexOf(subFilter) > -1) {
       filterParams["status"] = [subFilter];
     }
     if (subFilter2) {
       filterParams.subFilter = subFilter2;
     }
   }
   if (selectedCard === "IN_PORT_DELIVERY_COUNT") {
     if (subFilter) {
       filterParams["status"] = [subFilter];
     }
   }
   if (selectedCard === "IN_YARD_DROPPED_COUNT") {
     if (subFilter2) {
       filterParams.subFilter = subFilter2;
     }
   }
   if (selectedCard === "AT_CUSTOMER_DROPPED_COUNT") {
     if (subFilter) {
       filterParams.isReadyForPickup = subFilter === "true" ? true : false;
     }
   }
   if(selectedCard === "DROPPED_COUNT"){
    if (subFilter) {
      filterParams.subFilter = subFilter;
    }
   }
return filterParams
}
}

export const getfilterParams = (filterParams, loadFilters) => {
  if (loadFilters.grid3csr && loadFilters.grid3csr.length > 0)
    filterParams.assignedCSR = loadFilters.grid3csr.map((csr) => csr.value);

  if (loadFilters.grid3containerNo && loadFilters.grid3containerNo.length > 0)
    filterParams.containerNo = loadFilters.grid3containerNo;

  if (loadFilters.grid3chassisNo && loadFilters.grid3chassisNo.length > 0)
    filterParams.chassisNo = loadFilters.grid3chassisNo;

  if (loadFilters.grid3TypeOfLoad && loadFilters.grid3TypeOfLoad.length > 0)
    filterParams.type_of_load = loadFilters.grid3TypeOfLoad.map((obj) => obj.value);  
  
  if (loadFilters.grid3loadtemplate || loadFilters.grid3loadtemplate === "")
    filterParams.preSetOrderStatus = loadFilters.grid3loadtemplate

  if (loadFilters.grid3caller && loadFilters.grid3caller.length > 0)
    filterParams.caller = loadFilters.grid3caller.map((obj) => obj.value);

  if (loadFilters.grid3shipper && loadFilters.grid3shipper.length > 0)
    filterParams.shipper = loadFilters.grid3shipper.map((obj) => obj);

  if (loadFilters.grid3consignee && loadFilters.grid3consignee.length > 0)
    filterParams.consignee = loadFilters.grid3consignee.map((obj) => obj);

  if (loadFilters.grid3emptyorigin && loadFilters.grid3emptyorigin.length > 0)
    filterParams.emptyOrigin = loadFilters.grid3emptyorigin.map((obj) => obj);

  if (loadFilters.grid3driver && loadFilters.grid3driver.length > 0)
    filterParams.drivers = loadFilters.grid3driver.map((obj) => obj.value);

  if (loadFilters.grid3filterStatusField && loadFilters.grid3filterStatusField.length > 0)
    filterParams.status = loadFilters.grid3filterStatusField.map((obj) => obj.value);

  if (loadFilters.grid3filterApptStatusField && loadFilters.grid3filterApptStatusField.length > 0)
      filterParams.appointmentStatus = loadFilters.grid3filterApptStatusField.map((obj) => obj.value)

  if (loadFilters.grid3ContainerType && loadFilters.grid3ContainerType.length > 0)
    filterParams.containerType = loadFilters.grid3ContainerType.map((obj) => obj.value);

  if (loadFilters.grid3ContainerSize && loadFilters.grid3ContainerSize.length > 0)
    filterParams.containerSize = loadFilters.grid3ContainerSize.map((obj) => obj.value);

  if (loadFilters.grid3ChassisType && loadFilters.grid3ChassisType.length > 0)
    filterParams.chassisType = loadFilters.grid3ChassisType.map((obj) => obj.value);

  if (loadFilters.grid3ChassisSize && loadFilters.grid3ChassisSize.length > 0)
    filterParams.chassisSize = loadFilters.grid3ChassisSize.map((obj) => obj.value);

  if (loadFilters.grid3ChassisOwner && loadFilters.grid3ChassisOwner.length > 0)
    filterParams.chassisOwner = loadFilters.grid3ChassisOwner.map((obj) => obj.value);
  
  if (loadFilters.grid3SSL && loadFilters.grid3SSL.length > 0)
    filterParams.containerOwner = loadFilters.grid3SSL.map((obj) => obj.value);

  if (loadFilters.pickupFrom && loadFilters.pickUpTo) {
    filterParams["pickupFrom"] = moment(loadFilters.pickupFrom).toISOString();
    filterParams["pickUpTo"] = moment(loadFilters.pickUpTo).toISOString();
  }

  if (loadFilters.deliveryFrom && loadFilters.deliveryTo) {
    filterParams["deliveryFrom"] = moment(loadFilters.deliveryFrom).toISOString();
    filterParams["deliveryTo"] = moment(loadFilters.deliveryTo).toISOString();
  }

  if (loadFilters.returnAPTFrom && loadFilters.returnAPTTo) {
    filterParams["returnAPTFrom"] = moment(loadFilters.returnAPTFrom).toISOString();
    filterParams["returnAPTTo"] = moment(loadFilters.returnAPTTo).toISOString();
  }

  if (loadFilters.lastFreeDayFrom && loadFilters.lastFreeDayTo) {
    filterParams["lastFreeDayFrom"] = moment(loadFilters.lastFreeDayFrom).toISOString();
    filterParams["lastFreeDayTo"] = moment(loadFilters.lastFreeDayTo).toISOString();
  }

  if (loadFilters.freeReturnFrom && loadFilters.freeReturnTo) {
    filterParams["freeReturnFrom"] = moment(loadFilters.freeReturnFrom).toISOString();
    filterParams["freeReturnTo"] = moment(loadFilters.freeReturnTo).toISOString();
  }

  if (loadFilters.cutOffFrom && loadFilters.cutOffTo) {
    filterParams["cutOffFrom"] = moment(loadFilters.cutOffFrom).toISOString();
    filterParams["cutOffTo"] = moment(loadFilters.cutOffTo).toISOString();
  }

  if (loadFilters?.terminal && Array.isArray(loadFilters?.terminal) && loadFilters?.terminal?.length) {
    filterParams["terminal"] = loadFilters.terminal.map((obj) => obj.value);
  } else if (loadFilters?.terminal && loadFilters?.terminal?.value) {
    filterParams["terminal"] = loadFilters.terminal.value;
  }

  if (loadFilters.grid3createdByUser && loadFilters.grid3createdByUser.length > 0)
    filterParams.addedBy = loadFilters.grid3createdByUser.map((obj) => obj.value);

  if (loadFilters.isReadyForPickupShow) {
    filterParams["isReadyForPickup"] = true;
    filterParams["status"] = ["DROPCONTAINER_DEPARTED"];
  }

  if (loadFilters.excludeCaller) {
    filterParams["excludeCaller"] = true;
  }

  if (loadFilters.excludeShipper) {
    filterParams["excludeShipper"] = true;
  }

  if (loadFilters.excludeConsignee) {
    filterParams["excludeConsignee"] = true;
  }

  if (loadFilters.excludeEmptyOrigin) {
    filterParams["excludeEmptyOrigin"] = true;
  }

  if (loadFilters.excludeDriver) {
    filterParams["excludeDriver"] = true;
  }

  if (loadFilters.excludeContainerOwner) {
    filterParams["excludeContainerOwner"] = true;
  }
  if (loadFilters.tenderStatus) filterParams["tenderStatus"] = loadFilters.tenderStatus?.value;

  if (loadFilters.selectedCarriers?.length > 0) filterParams["carrier"] = loadFilters.selectedCarriers.map(carrier => carrier.value)
   
  if (loadFilters?.routingEventStatus?.length > 0) {
    const options = optionMapper(loadFilters?.routingEventStatus || [])
    const { routingEvents, stopoffType } = routingEventPayloadMapper(options)
    filterParams["routingEventStatus"] = routingEvents || []
    if (stopoffType?.length > 0 && routingEvents?.length > 0) filterParams["stopoffType"] = stopoffType
  }

  if (loadFilters?.isEdiTest) {
    filterParams["createSource"] = createSource.EDI_TEST;
  }

  return filterParams;
};

export const isTerminal = () => {
  return (
    (getStorage("currentUserRole") == "fleetmanager" &&
      configuration.idForTerminal.indexOf(
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
      ) != -1) ||
    (getStorage("currentUserRole") == "carrier" &&
      configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
  );
};

export function genratePillColor(key, info) {
  let color = {};
  if (info[key]) {
    if (
      moment(info[key]).startOf("day").diff(moment().startOf("day"), "days") === 0
    ) {
      color = "line-height-inherit bg-warning-200 text-center";
    }
    if (
      moment(info[key]).startOf("day").diff(moment().startOf("day"), "days") === 1
    ) {
      color = "line-height-inherit text-center";
    }
    if (
      moment(info[key]).startOf("day").diff(moment().startOf("day"), "days") < 0
    ) {
      color = "line-height-inherit bg-warning-500 text-center text-black";
    }
  }

  if (
    ["lastFreeDay", "containerAvailableDay"].includes(key) &&
    ![
      "PENDING",
      "AVAILABLE",
      "DEPARTED",
      "DISPATCHED",
      "CHASSISPICK_ARRIVED",
      "CHASSISPICK_DEPARTED",
      "PULLCONTAINER_ARRIVED",
    ].includes(info.status)
  ) {
    color = "text-center";
  }

  return color;
}

export const isFetchSubCard = (status) => {
  if(Array.isArray(status)) {
    return
  }
  return [
      "APPLIED_PICKUP_COUNT",
      "LFD_COUNT",
      "PICKUP_APPT_COUNT",
      "DELIVERY_COUNT",
      "IN_PORT_DELIVERY_COUNT",
      "IN_YARD_DELIVERY_COUNT",
      "RETURNS_COUNT",
      "READY_FOR_PICKUP_COUNT",
      "NOT_READY_FOR_PICKUP_COUNT",
      "DROPPED_COUNT",
      "IN_YARD_DROPPED_COUNT",
      "AT_CUSTOMER_DROPPED_COUNT",
    ].includes(status);
}

export const getViewFilter = (viewParams, loadFilters, onlyValue = true, checkCustomerValidation = false) => {
  if (loadFilters.grid3csr)
    viewParams.assignedCSR = onlyValue
      ? loadFilters.grid3csr.map((csr) => csr.value)
      : loadFilters.grid3csr.map((csr) => ({_id: csr.value, name: csr.label}));

  if (loadFilters.grid3containerNo)
    viewParams.containerNo = loadFilters.grid3containerNo;

  if(loadFilters.grid3loadtemplate || loadFilters.grid3loadtemplate === "")
     viewParams.preSetOrderStatus = loadFilters.grid3loadtemplate

  if (loadFilters.grid3chassisNo)
    viewParams.chassisNo = loadFilters.grid3chassisNo;

  if (loadFilters.grid3TypeOfLoad)
    viewParams.type_of_load = loadFilters.grid3TypeOfLoad.map(
      (obj) => obj.value
    );

  if (loadFilters.grid3caller)
    viewParams.caller = onlyValue
    ? loadFilters.grid3caller.map((obj) => obj.value)
    : loadFilters.grid3caller.map((obj) => obj.allInfos);

  if (loadFilters.grid3shipper){
    const isFound = loadFilters.grid3shipper.some(e=>CUSTOMER_FILTER_TYPES.includes(e?.type))
    if (isFound && checkCustomerValidation) {
      const data = loadFilters.grid3shipper.map(e => ({
        type: e.type,
        ...(GroupTypes.includes(e.type)
          ? { groupId: e.value }
          : { ...e.allInfos })
      }));
      viewParams.extraShipperInfo = data
      viewParams.shipper = []
    } else {
      viewParams.shipper = onlyValue
      ? loadFilters.grid3shipper.map((obj) => obj.value)
      : loadFilters.grid3shipper.map((obj) => obj.allInfos);
      viewParams.extraShipperInfo =[]
    }
  }

  if (loadFilters.grid3consignee){
    const isFound = loadFilters.grid3consignee.some(e=>CUSTOMER_FILTER_TYPES.includes(e?.type))
    if(isFound && checkCustomerValidation){
      const data = loadFilters.grid3consignee.map(e => ({
        type: e.type,
        ...(GroupTypes.includes(e.type)
          ? { groupId: e.value }
          : { ...e.allInfos })
      }));
      viewParams.extraConsigneeInfo = data
      viewParams.consignee = []
    }else{
      viewParams.consignee = onlyValue
      ? loadFilters.grid3consignee.map((obj) => obj.value)
      : loadFilters.grid3consignee.map((obj) => obj.allInfos);
      viewParams.extraConsigneeInfo = []
    }
  }

  if (loadFilters.grid3emptyorigin){
    const isFound = loadFilters.grid3emptyorigin.some(e=>CUSTOMER_FILTER_TYPES.includes(e?.type))
    if(isFound && checkCustomerValidation){
      const data = loadFilters.grid3emptyorigin.map(e => ({
        type: e.type,
        ...(GroupTypes.includes(e.type)
          ? { groupId: e.value }
          : { ...e.allInfos })
      }));
      viewParams.extraEmptyOriginInfo = data
      viewParams.emptyOrigin = []
    }else{
      viewParams.emptyOrigin = onlyValue
      ? loadFilters.grid3emptyorigin.map((obj) => obj.value)
      : loadFilters.grid3emptyorigin.map((obj) => obj.allInfos);
      viewParams.extraEmptyOriginInfo = []
    }
  }

  if (loadFilters.grid3driver)
    viewParams.drivers = onlyValue
      ? loadFilters.grid3driver.map((obj) => obj.value)
      : loadFilters.grid3driver.map((obj) => obj.allInfo);

  if (loadFilters.grid3filterStatusField)
    viewParams.status = loadFilters.grid3filterStatusField.map(
      (obj) => obj.value
    );

  if (loadFilters.grid3ContainerType)
    viewParams.containerType = onlyValue
      ? loadFilters.grid3ContainerType.map((obj) => obj.value)
      : loadFilters.grid3ContainerType.map((obj) => obj.allInfos || obj.allInfo);

  if (loadFilters.grid3ContainerSize)
    viewParams.containerSize = onlyValue
      ? loadFilters.grid3ContainerSize.map((obj) => obj.value)
      : loadFilters.grid3ContainerSize.map((obj) => obj.allInfos || obj.allInfo );

  if (loadFilters.grid3ChassisType)
    viewParams.chassisType = onlyValue
      ? loadFilters.grid3ChassisType.map((obj) => obj.value)
      : loadFilters.grid3ChassisType.map((obj) => obj.allInfos || obj.allInfo);

  if (loadFilters.grid3ChassisSize)
    viewParams.chassisSize = onlyValue
      ? loadFilters.grid3ChassisSize.map((obj) => obj.value)
      : loadFilters.grid3ChassisSize.map((obj) => obj.allInfos || obj.allInfo);

  if (loadFilters.grid3ChassisOwner)
    viewParams.chassisOwner = onlyValue
      ? loadFilters.grid3ChassisOwner.map((obj) => obj.value)
      : loadFilters.grid3ChassisOwner.map((obj) => obj.allInfos || obj.allInfo);
  
  if (loadFilters.grid3SSL)
    viewParams.containerOwner = onlyValue
      ? loadFilters.grid3SSL.map((obj) => obj.value)
      : loadFilters.grid3SSL.map((obj) => obj.allInfos || obj.allInfo);

  viewParams.pickUpLabel = loadFilters.pickUpLabel || null;
  viewParams.pickupFrom = !loadFilters.pickUpLabel ? loadFilters.pickupFrom ? moment(loadFilters.pickupFrom).toISOString() : null : null;
  viewParams.pickUpTo = !loadFilters.pickUpLabel ? loadFilters.pickUpTo ? moment(loadFilters.pickUpTo).toISOString() : null : null;

  viewParams.deliveryLabel = loadFilters.deliveryLabel || null;
  viewParams.deliveryFrom = !loadFilters.deliveryLabel ? loadFilters.deliveryFrom ? moment(loadFilters.deliveryFrom).toISOString() : null : null;
  viewParams.deliveryTo = !loadFilters.deliveryLabel ? loadFilters.deliveryTo ? moment(loadFilters.deliveryTo).toISOString() : null : null;

  viewParams.returnAPTLabel = loadFilters.returnAPTLabel || null;
  viewParams.returnAPTFrom = !loadFilters.returnAPTLabel ? loadFilters.returnAPTFrom ? moment(loadFilters.returnAPTFrom).toISOString() : null : null;
  viewParams.returnAPTTo = !loadFilters.returnAPTLabel ? loadFilters.returnAPTTo ? moment(loadFilters.returnAPTTo).toISOString() : null : null;

  viewParams.lastFreeDayLabel = loadFilters.lastFreeDayLabel || null;
  viewParams.lastFreeDayFrom = !loadFilters.lastFreeDayLabel ? loadFilters.lastFreeDayFrom ? moment(loadFilters.lastFreeDayFrom).toISOString() : null : null;
  viewParams.lastFreeDayTo = !loadFilters.lastFreeDayLabel ? loadFilters.lastFreeDayTo ? moment(loadFilters.lastFreeDayTo).toISOString() : null : null;

  viewParams.freeReturnLabel = loadFilters.freeReturnLabel || null;
  viewParams.freeReturnFrom = !loadFilters.freeReturnLabel ? loadFilters.freeReturnFrom ? moment(loadFilters.freeReturnFrom).toISOString() : null : null;
  viewParams.freeReturnTo = !loadFilters.freeReturnLabel ? loadFilters.freeReturnTo ? moment(loadFilters.freeReturnTo).toISOString() : null : null;

  viewParams.cutOffLabel = loadFilters.cutOffLabel || null;
  viewParams.cutOffFrom = !loadFilters.cutOffLabel ? loadFilters.cutOffFrom ? moment(loadFilters.cutOffFrom).toISOString() : null : null;
  viewParams.cutOffTo = !loadFilters.cutOffLabel ? loadFilters.cutOffTo ? moment(loadFilters.cutOffTo).toISOString() : null : null;

  if (loadFilters.terminal) {
    if (onlyValue) {
      if (loadFilters.terminal && Array.isArray(loadFilters.terminal) && loadFilters.terminal.length) {
        viewParams["terminal"] = loadFilters.terminal.map((obj) => obj.value);
      } else {
        viewParams["terminal"] = loadFilters.terminal.value || null
      }
    } else {
      viewParams["terminal"] = loadFilters.terminal.allInfo;
    }
  }

  if (loadFilters.grid3createdByUser)
    viewParams.addedBy = onlyValue
      ? loadFilters.grid3createdByUser.map((obj) => obj.value)
      : loadFilters.grid3createdByUser.map((obj) => obj.allInfos || obj.allInfo);

  if (loadFilters.isReadyForPickupShow) {
    viewParams["isReadyForPickup"] = true;
    viewParams["status"] = ["DROPCONTAINER_DEPARTED"];
  }

  if (loadFilters.excludeCaller) {
    viewParams["excludeCaller"] = true;
  }

  if (loadFilters.excludeShipper) {
    viewParams["excludeShipper"] = true;
  }

  if (loadFilters.excludeConsignee) {
    viewParams["excludeConsignee"] = true;
  }

  if (loadFilters.excludeEmptyOrigin) {
    viewParams["excludeEmptyOrigin"] = true;
  }

  if (loadFilters.excludeDriver) {
    viewParams["excludeDriver"] = true;
  }

  if (loadFilters.excludeContainerOwner) {
    viewParams["excludeContainerOwner"] = true;
  }

  if (loadFilters.tenderStatus){
    viewParams["tenderStatus"] = loadFilters.tenderStatus?.value
  }
  if (loadFilters?.selectedCarriers?.length) {
    viewParams.carrier = onlyValue
      ? loadFilters.selectedCarriers.map((obj) => obj.value)
      : loadFilters.selectedCarriers.map((obj) => obj.allInfos);
  }

  if (loadFilters?.routingEventStatus?.length > 0) {
    viewParams["routingEventStatus"] = optionMapper(loadFilters?.routingEventStatus) ?? []
  }

  if (loadFilters?.pickUpApptStatus?.length > 0) {
    viewParams["pickUpApptStatus"] = loadFilters.pickUpApptStatus;
  }
  if (loadFilters?.emptyApptStatus?.length > 0) {
    viewParams["emptyApptStatus"] = loadFilters.emptyApptStatus;
  }

  return viewParams;
};

export const filteredData = (defaultView) => {
  let loadFilters = {},
    sortBy = {},
    selectedStatus = null,
    subFilter = null,
    subFilter2 = null,
    dateFilter = "all",
    multiSelectedFilters= [],
    selectedDate = null,
    selectedCard = null;
  const RANGES = getDynamicRanges();
  if (defaultView.drivers && defaultView.drivers.length > 0) {
    loadFilters.grid3driver = defaultView.drivers.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${d.lastName}`.trim(),
        allInfo: d,
      };
    });
  }

  if (defaultView?.multiSelectedFilters) {
      multiSelectedFilters= defaultView?.multiSelectedFilters;
  }
  
  if(defaultView.preSetOrderStatus || defaultView.preSetOrderStatus === ""){
    loadFilters.grid3loadtemplate = defaultView.preSetOrderStatus
  }

  if (defaultView.caller && defaultView.caller.length > 0) {
    loadFilters.grid3caller = makeOptionsForSelect(
      defaultView.caller,
      "company_name",
      "address.address",
      "_id"
    );
  }
  if(defaultView.carrier && defaultView.carrier.length > 0) {
    loadFilters.selectedCarriers = makeOptionsForSelect(defaultView.carrier,"company_name","defaultAddress","_id")
  }

  if (defaultView.shipper && defaultView.shipper.length > 0) {
    let data = []
    defaultView?.shipper.map((d) => {
      if (d?.customers || d?.cities || d?.zipcodes) {
        data.push(
            {
              value: d._id,
              label: `${d.name}`.trim(),
              type: d?.customers ? 'groupedProfile' : d?.cities ? "groupedCity" : d?.zipcodes ? "groupedZipCode" : "",
              allInfos: d,
            }
          )
      } else if (d?.type === "zip_code" || d?.type === "city" || d?.type === "zipCode") {
        data.push(
          {
            value: d._id,
            label: `${d.name}`.trim(),
            type: d.type,
            allInfos: d,
          }
        )
      } else {
        data.push( ...makeOptionsForSelect(
            [d],
            "company_name",
            "address.address",
            "_id"
          ))
        }
    })
    loadFilters.grid3shipper = data
  }

  if (defaultView?.consignee && defaultView?.consignee?.length > 0) {
    let data = []
    defaultView?.consignee.map((d) => {
      if (d?.customers || d?.cities || d?.zipcodes) {
        data.push(
            {
              value: d._id,
              label: `${d.name}`.trim(),
              type: d?.customers ? 'groupedProfile' : d?.cities ? "groupedCity" : d?.zipcodes ? "groupedZipCode" : "",
              allInfos: d,
            }
          )
      } else if (d?.type === "zip_code" || d?.type === "city" || d?.type === "zipCode") {
        data.push(
          {
            value: d._id,
            label: `${d.name}`.trim(),
            type: d.type,
            allInfos: d,
          }
        )
      } else {
        data.push( ...makeOptionsForSelect(
            [d],
            "company_name",
            "address.address",
            "_id"
          ))
        }
    })
    loadFilters.grid3consignee = data
    
  }

  if (defaultView?.emptyOrigin && defaultView?.emptyOrigin?.length > 0) {

    let op = []
    defaultView?.emptyOrigin.map((d) => {
      if (d?.customers || d?.cities || d?.zipcodes) {
        op.push(
            {
              value: d._id,
              label: `${d.name}`.trim(),
              type: d?.customers ? 'groupedProfile' : d?.cities ? "groupedCity" : d?.zipcodes ? "groupedZipCode" : "",
              allInfos: d,
            }
          )
      } else if (d?.type === "zip_code" || d?.type === "city" || d?.type === "zipCode") {
        op.push(
          {
            value: d._id,
            label: `${d.name}`.trim(),
            type: d.type,
            allInfos: d,
          }
        )
      } else {
          op.push( ...makeOptionsForSelect(
            [d],
            "company_name",
            "address.address",
            "_id"
          ))
        }
    })
    loadFilters.grid3emptyorigin = op
  }

  if (defaultView.addedBy && defaultView.addedBy.length > 0) {
    loadFilters.grid3createdByUser = defaultView.addedBy.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${
          d.name.includes(d.lastName) ? "" : d.lastName
        }`.trim(),
        allInfo: d,
      };
    });
  }

  if (defaultView.containerType && defaultView.containerType.length > 0) {
    loadFilters.grid3ContainerType = defaultView.containerType.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      };
    });
  }

  if (defaultView.containerSize && defaultView.containerSize.length > 0) {
    loadFilters.grid3ContainerSize = defaultView.containerSize.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      };
    });
  }

  if (defaultView.containerOwner && defaultView.containerOwner.length > 0) {
    loadFilters.grid3SSL = defaultView.containerOwner.map((d) => {
      return {
        value: d._id,
        label: d.company_name,
        allInfo: d,
      };
    });
  }

  if (defaultView.chassisType && defaultView.chassisType.length > 0) {
    loadFilters.grid3ChassisType = defaultView.chassisType.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      };
    });
  }

  if (defaultView.chassisSize && defaultView.chassisSize.length > 0) {
    loadFilters.grid3ChassisSize = defaultView.chassisSize.map((d) => {
      return {
        value: d._id,
        label: d.name,
        allInfo: d,
      };
    });
  }

  if (defaultView.chassisOwner && defaultView.chassisOwner.length > 0) {
    loadFilters.grid3ChassisOwner = defaultView.chassisOwner.map((d) => {
      return {
        value: d._id,
        label: d.company_name,
        allInfo: d,
      };
    });
  }

  if (defaultView.terminal && defaultView.terminal.length > 0) {
    loadFilters.terminal = makeOptionsForSelect(
      defaultView.terminal,
      "name",
      "",
      "_id"
    );
  }

  // pickup
  if (defaultView["pickupFrom"]) loadFilters["pickupFrom"] = defaultView["pickupFrom"];
  if (defaultView["pickUpTo"]) loadFilters["pickUpTo"] = defaultView["pickUpTo"];
  if (defaultView["pickUpLabel"]) loadFilters["pickUpLabel"] = defaultView["pickUpLabel"];

  if (loadFilters["pickUpLabel"]) {
    const _times = RANGES[loadFilters["pickUpLabel"]] || [];
    if (_times.length) {
      loadFilters["pickupFrom"] = _times[0];
      loadFilters["pickUpTo"] = _times[1];
    }
  }

  const dateRangeFields = ['delivery', 'returnAPT', 'lastFreeDay', 'freeReturn', 'cutOff'];
  dateRangeFields.forEach((field) => {
    if (defaultView[`${field}From`]) loadFilters[`${field}From`] = defaultView[`${field}From`];
    if (defaultView[`${field}To`]) loadFilters[`${field}To`] = defaultView[`${field}To`];
    if (defaultView[`${field}Label`]) loadFilters[`${field}Label`] = defaultView[`${field}Label`];

    if (loadFilters[`${field}Label`]) {
      const _times = RANGES[loadFilters[`${field}Label`]] || [];
      if (_times.length) {
        loadFilters[`${field}From`] = _times[0];
        loadFilters[`${field}To`] = _times[1];
      }
    }
  });

  if (defaultView.type_of_load && defaultView.type_of_load.length > 0) {
    loadFilters.grid3TypeOfLoad = defaultView.type_of_load.map((d) => {
      return {
        value: d,
        label: d,
      };
    });
  }

  if (defaultView.status && defaultView.status.length > 0) {
    loadFilters.grid3filterStatusField = defaultView.status.map((d) => {
      return {
        value: d,
        label: d,
      };
    });
  }

  if (defaultView.assignedCSR && defaultView.assignedCSR.length > 0) {
    loadFilters.grid3csr = defaultView.assignedCSR.map((d) => {
      return {
        value: d._id,
        label: d.name + (d.lastName ? (" " + d.lastName) : ""),
      };
    });
  }
  if (defaultView.tenderStatus) {
    loadFilters.tenderStatus = {value:defaultView.tenderStatus,label:defaultView.tenderStatus}
  }

  loadFilters.isReadyForPickupShow = defaultView.isReadyForPickup;

  loadFilters.excludeCaller = defaultView.excludeCaller;
  loadFilters.excludeShipper = defaultView.excludeShipper;
  loadFilters.excludeConsignee = defaultView.excludeConsignee;
  loadFilters.excludeEmptyOrigin = defaultView.excludeEmptyOrigin;
  loadFilters.excludeDriver = defaultView.excludeDriver;
  loadFilters.excludeContainerOwner = defaultView.excludeContainerOwner;

  if (defaultView.selectedStatuses) {
    let includesideFilter = defaultView.selectedStatuses.find(row => 
       (
        [
          "CAUTION_COUNT",
          "LOCAL_COUNT",
          "SCALE_COUNT",
          "NOTE_COUNT",
          "GENSET_COUNT",
          "DOMESTIC_COUNT",
          "EV_COUNT",
          "WASTE_COUNT",
          "GDP_COUNT",
          "IS_RAIL_COUNT",
          "REEFER_COUNT",
          "HOT_COUNT",
          "LIQUOR_COUNT",
          "HIGHWAY_COUNT",
          "SCALES_COUNT",
          "STREET_TURN_COUNT"
        ].includes(row))
    )
    if (includesideFilter) selectedStatus = defaultView.selectedStatuses;
  }
  if (defaultView.selectedCard) {
    selectedCard = defaultView.selectedCard
  }
  if (defaultView.sortBy) {
    if (typeof defaultView.sortBy === "string")
      sortBy = JSON.parse(defaultView.sortBy);
    else sortBy = defaultView.sortBy;
  }

  if (defaultView.subFilter) subFilter = defaultView.subFilter;
  if (defaultView.subFilter2) subFilter2 = defaultView.subFilter2;
  if (defaultView.dateFilter) {
    dateFilter = defaultView.dateFilter;
    selectedDate = getSelectedDate(dateFilter)
  }

  if (defaultView?.routingEventStatus?.length > 0) {
    loadFilters.routingEventStatus = optionMapper(defaultView?.routingEventStatus, true, getRoutingEvents()) ?? [];
  }

  if (defaultView?.pickUpApptStatus?.length > 0) {
    loadFilters.pickUpApptStatus = defaultView.pickUpApptStatus;
  }
  if (defaultView?.emptyApptStatus?.length > 0) {
    loadFilters.emptyApptStatus = defaultView.emptyApptStatus;
  }
  
  return {
    loadFilters,
    selectedStatus,
    sortBy,
    subFilter,
    subFilter2,
    dateFilter,
    selectedDate,
    multiSelectedFilters,
    selectedCard
  };
};

export const convertStateToViewParams = (state, onlyValue = true, checkCustomerFilterValidation = false) => {
  let viewParams = {};

  if (!_.isEmpty(state.loadFilters)) {
    viewParams = getViewFilter(viewParams, state.loadFilters, onlyValue, checkCustomerFilterValidation);
    if(!viewParams) return false
  }

  viewParams["sortBy"] = state.filterParams ? state.filterParams?.sortBy : state.sortBy;

  if (state.currentView) {
    viewParams["id"] = state.currentView;
  }

  if(state.selectedStatus){
    viewParams["selectedStatuses"] = state.selectedStatus
  }
  viewParams["selectedCard"] = state.selectedCard ?? "";
  if (state.currentViewName) {
    viewParams["viewName"] = state.currentViewName.trim();
  }
  viewParams["isDefaultView"] = state.isDefaultView;
  if (state.subFilter) {
    viewParams["subFilter"] = state.subFilter;
  }
  if (state.subFilter2) {
    viewParams["subFilter2"] = state.subFilter2;
  }
  if(state.dateFilter) {
    viewParams["dateFilter"] = state.dateFilter
  }
  if(state.multiSelectedFilters) {
    viewParams["multiSelectedFilters"] = state.multiSelectedFilters
  }
  return viewParams;
};

export const convertStateToQueryString = async (state) => {
  let filterParams = {};
  let params = convertStateToViewParams(state, false);
  if (state.filterBySubCard?.length) {
    params["filterBySubCard"] = state.filterBySubCard;
  }

  const act_filters = _.cloneDeep(params);
  delete act_filters.sortBy;
  if (!Object.keys(_.omitBy(act_filters, _.isEmpty))?.length) {
    browserHistory.push("/tms/Dispatcher");
    return;
  }

  const data = await createDispatcherFilter({
    filter: JSON.stringify(params),
  });
  if (data && data.filterId) {
    filterParams["filter"] = data.filterId;
  }
  if (state.currentView) {
    filterParams["view"] = state.currentView;
  }
  return filterParams;
};

export const convertStateToDispatchURL = (state) => {
  convertStateToQueryString(state).then((filterParams) => {
    updateQueryString(filterParams);
  });
};

// custom logic for creating dynamic column width according to content
const canvas = document.createElement("canvas");

const getWidthFromText = (text, font = "12px sans-serif") => {
  const context = canvas.getContext("2d");
  if (font) context.font = font;
  return context.measureText(text);
};

export const getAdaptiveColumnWidth = (_columns, _rows) => {
  try {
    _columns.forEach((_col) => {
      if (Object.keys(colToLoadField).includes(_col.key)) {
        if(_col.width) return 
        const loadRef = _.maxBy(
          _rows,
          (load) => (_.get(load, colToLoadField[_col.key], "") || "").length
        );
        if (_.get(loadRef, colToLoadField[_col.key])) {
          const widthObj = getWidthFromText(
            _.get(loadRef, colToLoadField[_col.key])
          );
          _col.width = widthObj.width + 30;
        }
      } else if (_col.key === "status") {
        if(_col.width) return 
        const loadStatus = _.maxBy(
          _rows,
          (load) =>
            convertTMSStatusName(load.type_of_load, load.status, load).length
        );
        const loadCaller = _.maxBy(
          _rows,
          (load) => `${load.driverOrderId?.company_name || ""}`.length
        );
        const statusText = convertTMSStatusName(
          loadStatus.type_of_load,
          loadStatus.status,
          loadStatus
        );
        const callerText = `${loadCaller.driverOrderId?.company_name || ""}`;
        const widthObj =
          statusText.length > callerText.length * 1.3
            ? getWidthFromText(statusText, "10px sans-serif")
            : getWidthFromText(callerText);
        _col.width = widthObj.width + 25;

      } else if (_col.key === "driver") {
        if(_col.width) return 
        const loadDriver = _.maxBy(_rows, (load) =>
          load.driver
            ? `${load.driver.name} ${load.driver.lastName || ""}`.length
            : 0
        );
        if (loadDriver.driver) {
          const widthObj = getWidthFromText(
            `${loadDriver.driver.name} ${loadDriver.driver.lastName || ""}`
          );
          _col.width = (widthObj.width < 130 ? widthObj.width : 130) + 120;
        }
      } else if (_col.key === "nextDriverOrderId") {
        if(_col.width) return 
        const nextAddress = _rows.map((load) => {
          let nextAddress = load.nextDriverOrderId;
          if (load.status === "DISPATCHED") nextAddress = load.driverOrder[0];
          return nextAddress;
        });
        const loadStatus = _.maxBy(
          nextAddress,
          (nextAddress) => `${nextAddress?.type || ""}`.length
        );
        const loadCaller = _.maxBy(
          nextAddress,
          (nextAddress) => `${nextAddress?.company_name || ""}`.length
        );
        const statusText = `${loadStatus?.type || ""}`;
        const callerText = `${loadCaller?.company_name || ""}`;
        const widthObj =
          statusText.length > callerText.length * 1.3
            ? getWidthFromText(statusText, "10px sans-serif")
            : getWidthFromText(callerText);
        _col.width = widthObj.width + 25;

      } else if (_col.key === "bol_bkg") {
        if(_col.width) return 
        const bolBkg = _rows.map((load) => {
          if (["IMPORT", "ROAD"].includes(load.type_of_load)) {
            return load.callerbillLandingNo;
          } else if (load.type_of_load === "EXPORT") {
            return load.bookingNo;
          } else {
            return load.secondaryReferenceNo;
          }
        });
        const bolBkgText = _.maxBy(bolBkg, (bb) => `${bb || ""}`.length);
        if (bolBkgText) {
          const widthObj = getWidthFromText(bolBkgText);
          _col.width = widthObj.width + 25;
        }
      } else if (_col.key === "consigneeInfo") {
        if(_col.width) return 
        const loadConsignee = _.maxBy(_rows, (load) =>
          load.consigneeInfo
            ? `${load.consigneeInfo.city}, ${load.consigneeInfo.state || ""}`
                .length
            : 0
        );
        if (loadConsignee.consigneeInfo) {
          const widthObj = getWidthFromText(
            `${loadConsignee.consigneeInfo.city}, ${
              loadConsignee.consigneeInfo.state || ""
            }`
          );
          _col.width = widthObj.width + 30;
        }
      } else if (_col.key === "change_status") {
        if(_col.width) return 
        const loadStatus = _.maxBy(
          _rows,
          (load) =>
            convertTMSStatusName(load.type_of_load, load.status, load).length
        );
        const statusText = convertTMSStatusName(
          loadStatus.type_of_load,
          loadStatus.status,
          loadStatus
        );
        const widthObj = getWidthFromText(statusText);
        _col.width = widthObj.width + 25;
      } else if (_col.key === "pickupTimes"){
        if(_col.width) return 
        const loadPickup = _.maxBy(_rows, (load) => load?.pickupTimes?.filter((d) => d.pickupFromTime).length || 0);
        _col.width = 70 * (loadPickup?.pickupTimes?.length || 0) + 16;
      } else if (_col.key === "apt"){
        if(_col.width) return 
        const loadapt = _.maxBy(_rows, (load) => load?.deliveryTimes?.filter((d) => d.deliveryFromTime).length || 0);
        _col.width = 70 * (loadapt?.deliveryTimes?.length || 0) + 16;
      }
    });
    return _columns;
  } catch (err) {
    console.log("err", err);
    return _columns;
  }
};

export const convertHexTransparentToHexSolid = (hexColor, opacity) => {
  // hex color with opacity to equivalent solid hex with white background
  hexColor = hexColor.replace('#', '');
  if (hexColor.toLowerCase() === 'ffffff') return "#" + hexColor;

  const _red = hexColor.substr(0, 2);
  const _green = hexColor.substr(2, 2);
  const _blue = hexColor.substr(4, 2);

  return '#'
    + Math.floor((1 - opacity) * parseInt('ff', 16) + opacity * parseInt(_red, 16) + 0.5).toString(16)
    + Math.floor((1 - opacity) * parseInt('ff', 16) + opacity * parseInt(_green, 16) + 0.5).toString(16)
    + Math.floor((1 - opacity) * parseInt('ff', 16) + opacity * parseInt(_blue, 16) + 0.5).toString(16);
}

export const successColorResponse = (cell, index, className) => {
  const querySelector = className ? document.querySelector(`.${className} [aria-selected="true"]`) : cell
  if (querySelector && querySelector.getAttribute('aria-colindex') == index + 1) {
    querySelector.classList.add("background-success")
    querySelector.style.transition = "background .4s ease-out";

    
    setTimeout(() => {
      querySelector.classList.remove("background-success")
    }, 400);
  }
}

export const errorColorResponse = (cell, index, className) =>{
  const querySelector = className ? document.querySelector(`.${className} [aria-selected="true"]`) : cell
  if (querySelector && querySelector.getAttribute('aria-colindex') == index + 1) {
    querySelector.classList.add("background-error")
    querySelector.style.transition = "background .4s ease-out";

    setTimeout(() => {
      querySelector.classList.remove("background-error")
    }, 400);
  }
}

export const changeBgColor = (rowId, colId, event, className) => {
  if (!event) {
    document.querySelector('div[role="gridcell"]')?.classList?.remove("background-error")
    document.querySelector('div[role="gridcell"]')?.classList?.remove("background-success")
  }
  if (rowId && colId) {
    const querySelector = className ? document.querySelector(`.${className} div[aria-rowindex="${rowId}"] div[aria-colindex="${colId}"]`) : document.querySelector(`div[aria-rowindex="${rowId}"] div[aria-colindex="${colId}"]`)
    if (event === "SAVE") {
      querySelector.classList.add("background-success")
      querySelector.style.transition = "background .4s ease-out";

      setTimeout(() => {
        querySelector.classList.remove("background-success")
      }, 400)
    } else if (event === "FAIL") {
      setTimeout(() => {
        querySelector.classList.add("background-error")
        querySelector.style.transition = "background .4s ease-out";

      }, 10);
      setTimeout(() => {
        querySelector.classList.remove("background-error")
      }, 410)
    }
  }
}

export function getWeekCount(date) {
  var firstOfMonth = new Date(moment(date || undefined).startOf("month").toISOString());
  var lastOfMonth = new Date(moment(date || undefined).endOf("month").toISOString());
  var days = firstOfMonth.getDay() + lastOfMonth.getDate();
  return Math.ceil(days / 7);
}

export const getFilterDate = (filterParams, dateFilter, timeZone) => {
  let isValidDate = moment(dateFilter, moment.ISO_8601).isValid();
  if (isValidDate) {
    filterParams.selectedDateFrom = moment(dateFilter).tz(timeZone).startOf("day").toISOString();
    filterParams.selectedDateTo = moment(dateFilter).tz(timeZone).endOf("day").toISOString();
  } else {
    if (dateFilter == "all") {
    } else if (dateFilter == "past due") {
      filterParams.selectedDateFrom = moment().tz(timeZone).add(-365, 'days').startOf("day").toISOString();
      filterParams.selectedDateTo = moment().tz(timeZone).add(-1, 'days').endOf("day").toISOString();
    } else if (dateFilter == "today + past due") {
      filterParams.selectedDateFrom = moment().tz(timeZone).add(-365, 'days').startOf("day").toISOString();
      filterParams.selectedDateTo = moment().tz(timeZone).endOf("day").toISOString();
    } else {
      filterParams.selectedDateFrom = moment().add(dateFilter, 'days').tz(timeZone).startOf("day").toISOString();
      filterParams.selectedDateTo = moment().add(dateFilter, 'days').tz(timeZone).endOf("day").toISOString();
    }
  }

  return filterParams;
};
export const getNextDriverOrderEvent = (_driverOrder)=>{
  let initialIndex = _driverOrder?.findIndex((ord)=>!ord.driver && !ord.drayosCarrier && !ord.isVoidOut)
  if(initialIndex === -1) return []
  let nextHookEventIndex = _driverOrder?.findIndex((ord,index)=> index>initialIndex && !ord.driver && !ord.isVoidOut && tmsEndMoveEvents.includes(ord.type) && !tmsEndMoveEvents.includes(_driverOrder?.[index + 1]?.type))
  if(nextHookEventIndex === -1){
    nextHookEventIndex = _driverOrder.length
  } else {
    nextHookEventIndex += 1
  }
  return _driverOrder.slice(initialIndex,nextHookEventIndex);
}

export const getNextTenderStatus = (_driverOrder) => {
  let driverOrder = _.cloneDeep(_driverOrder)
  driverOrder = _.uniqBy(driverOrder, 'moveId');
  return driverOrder
}

export const getMaxEventColumnNumber =(allLoads)=>{
  let maxColumns = -1;
  allLoads.forEach((load)=>{
    const nextEvents = getNextDriverOrderEvent(load?.driverOrder);
    maxColumns = Math.max(maxColumns,nextEvents.length)
  });
  return maxColumns;
}

export const getMaxTenderStatusColumnNumber = (allLoads) => {
  let maxColumns = -1;
  allLoads.forEach((load) => {
    const nextTenderStatus = getNextTenderStatus(load.driverOrder)
    maxColumns = Math.max(maxColumns,nextTenderStatus.length)
  })
  return maxColumns;
}

export const checkIfPortIsAccepted = (port, standardPorts, acceptedPorts, loadType, newPorts) => {
  const standardPortsMapper = {...standardPorts, ...newPorts};
  let standardPortName;
  if (port && standardPortsMapper) {
    standardPortName = Object.keys(standardPortsMapper).find((key) =>
      standardPortsMapper[key].map((d) => d.toLowerCase()).includes(port.toLowerCase())
    );
  }
  if (!standardPortName || !acceptedPorts) return false;
  return acceptedPorts?.[standardPortName?.replace(/ /g, "_")]?.includes(loadType);
};

export const checkIfAllLoadHasSamePort = (loads = [], moveType = "") => {
  let port = ""
  for (let i = 0; i < loads.length; i++) {
    const loadItem = loads[i]
    const portCode = moveType == MOVE_TYPES.PICKUP ? (loadItem?.shipper?.[0]?.company_name ?? loadItem?.shipperName) : (loadItem?.emptyOrigin?.company_name ?? loadItem?.emptyOriginName)
    if (port.length > 0 && port !== portCode) {
      return false // If different port is found, return false
    }
    port = portCode
  }
  return true // If all ports are same, return true
}

export const checkIfAllowedForManualBooking = (allowedPorts = [], load = {}, moveType = "", standardPorts={}) => {
  //return false // disabled the manual booking for now to disable the feature in prod
  const loads = Array.isArray(load) ? load : [load]
  const portCode =
    moveType == MOVE_TYPES.PICKUP
      ? loads[0]?.shipper?.[0]?.company_name ?? loads[0]?.shipperName
      : loads[0]?.emptyOrigin?.company_name ?? loads[0]?.emptyOriginName
  const loadType = loads[0]?.type_of_load
  let standardPortName;
  if (portCode && standardPorts) {
    standardPortName = allowedPorts?.find((key) =>
    standardPorts[key]?.map((d) => d.toLowerCase())?.includes(portCode.toLowerCase())
    )
  }
  const allowedLoadTypes = [ IMPORT_LOAD_TYPE, EXPORT_LOAD_TYPE ]
  return standardPortName && allowedLoadTypes.includes(loadType)
}

export const checkIfAllowedForDirectBooking = (port = "", standardPorts = []) => {
  let standardPortName = "";

  if (port && standardPorts) {
    standardPortName = Object.keys(standardPorts).find((key) =>
      standardPorts[key].map((d) => d.toLowerCase()).includes(port.toLowerCase())
    );
  }
  return DIRECT_APPT_BOOKING_PORTS.includes(standardPortName ?? port);
};

export const checkIfPortSupportsScreengrab = (port = "") => {
  return !SCREENGRAB_UNSUPPORTED_PORTS.includes(port);
};

export const optionMapper = (options, isCreateOption, data) => {
  if (options?.length > 0 && !isCreateOption) return options?.map((obj) => obj?.value) ?? [];

  if (isCreateOption && data?.length > 0 && options?.length > 0) {
    return options.map(ele => data?.find(e => e.value === ele))
  }
};

export const routingEventPayloadMapper = (options) => {
  const routingEvents = [];
  const stopoffType = [];
  const userBasicSettings = JSON.parse(getStorage('userBasicSettings'))

  options?.forEach(item => {
    if (userBasicSettings?.stopoff && userBasicSettings?.isStopOff && item?.includes("STOPOFF")) {
      const parts = item?.split('-');
      if (!routingEvents?.includes(parts[0]?.trim() || '')) routingEvents?.push(parts[0]?.trim() || '');
      if (parts.length > 1) {
        if (!stopoffType?.includes(parts[1].trim() || '')) stopoffType?.push(parts[1]?.trim() || '');
      }
    } else if(item){
      routingEvents?.push(item);
    }
  });

  return { routingEvents, stopoffType }
};

export const changeStatusToastr = (loadDetail) => {
  let dateAt = loadDetail?.updatedAt ? moment(loadDetail?.updatedAt).tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat()) : moment().tz(getStorage("timeZone")).format(DateTimeFormatUtils.fullDateTimeFormat())
  let driverName = isManageFleetOrOutSource()?.isManageFleet && loadDetail?.driverName ? `<li class="d-flex align-items-center border-0 m-0">
 <div class="text-dark font-weight-normal font-12 w-80px line-height-18">Driver:</div>
 <div class="text-dark font-weight-500 font-12 line-height-18">${loadDetail?.driverName}</div>
</li>` : '';
  let displayMsg = `<div class="toast-load-info d-flex align-items-start">
  <ul class="list-style-none w-300">
    ${driverName}
    <li class="d-flex align-items-center border-0 m-0 w-100">
      <div class="text-dark font-weight-normal font-12 w-80px line-height-18">Load:</div>
      <div class="text-dark font-weight-500 font-12 line-height-18">${loadDetail?.load}</div>
    </li>
    <li class="d-flex align-items-center border-0 m-0 w-100">
      <div class="text-dark font-weight-normal font-12 w-80px line-height-18">Status:</div>
      <div class="text-dark font-weight-500 font-12 line-height-18">${loadDetail?.status}</div>
    </li>
    <li class="d-flex align-items-center border-0 m-0 w-100">
      <div class="text-dark font-weight-normal font-12 w-80px line-height-18">Location:</div>
      <div class="text-dark font-weight-500 font-12 line-height-18">${loadDetail?.location}</div>
    </li>
    <li class="d-flex align-items-center border-0 m-0 w-100">
      <div class="text-dark font-weight-normal font-12 w-80px line-height-18">Time:</div>
      <div class="text-dark font-weight-500 font-12 line-height-18">${dateAt}</div>
    </li>
  </ul>
</div>`;

  let totalTimeOut = 1000 * 3 * 60;
  let remainingTime = totalTimeOut;
  let countdownInterval;

  function updateCountdown() {
    if (remainingTime <= 0) {
      clearInterval(countdownInterval);
      return;
    }
    remainingTime -= 1000;
  }

  window.toastr.success(displayMsg, '', {
    showMethod: "slideDown",
    hideMethod: "slideUp",
    timeOut: totalTimeOut,
    closeButton: true,
    onShown: function () {
      if (visibleToastCount() > 1 && !visibleCloseBtn()) checkAndShowClearButton();
      $('.toast-close-button').addClass('text-gray-400 pr-1 btn');
      countdownInterval = setInterval(updateCountdown, 1000);
    },
    onHidden: function () {
      clearInterval(countdownInterval);
      remainingTime = totalTimeOut;
      setTimeout(() => {
        if (visibleToastCount() <= 1 && visibleCloseBtn()) checkAndHideClearButton();
      }, 100);
    }
  });
}