import React, { useState } from "react";
import { StateFormNumberIntFormat, StateFormSelect } from "../../../../../../Components/FormComponent/StateFormComponent";
import { getStorage } from "../../../../../../services";
import AddressInputField from "../Components/AddressInputField";
import NumberInputField from "../Components/NumberInputField";
import TextInputField from "../Components/TextInputField";
import { YesOrNo } from "./utilities";
export default function Other(props) {
    const { fleetOwner, userProfile, error:formErrors, handleEventOnBlur, handleOnBlur } = props;
    const driverDetails = props.selectedDriver.driver
    const setValChange = () =>{}

    const [emergencyContactName,setEmergencyContactName] = useState(driverDetails?.EmergencyContactName)
    const [emergencyRelation,setEmergencyRelation] = useState(driverDetails?.EmergencyRelation)
    const [emergencyContactNumber,setEmergencyContactNumber] = useState(driverDetails?.EmergencyContactNumber)

    const [hstPercentage,setHstPercentage] = useState(driverDetails?.hstPercentage)
    const [tShirtSizes,setTShirtSizes] = useState(driverDetails?.tShirtSizes)
    const [permanentAddress,setPermanentAddress] = useState(driverDetails?.permanentAddress)

    const [driverPreferences,setDriverPreferences]= useState(driverDetails?.driverPreferences);
    const [isSettlementHide,setIsSettlementHide]= useState(driverDetails?.isSettlementHide);
  return (
    <>
    <div className="hr-light mt-10 mb-20"></div>
      <div className="font-14 font-medium mb-15">Other</div>

      <div className="form-row">
        <TextInputField
          type="text"
          name="EmergencyContactName"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Emergency Contact Name"}
          value={emergencyContactName}
          inputClassName="EmergencyContactName"
          onChange={(e)=>setEmergencyContactName(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="EmergencyRelation"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Emergency Relation"}
          value={emergencyRelation}
          inputClassName="EmergencyRelation"
          onChange={(e)=>setEmergencyRelation(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        {JSON.parse(getStorage("userBasicSettings"))?.phoneFormat ==
        "international"  ? (
          <TextInputField
            type="text"
            name="EmergencyContactNumber"
            value={emergencyContactNumber}
            onChange={(e)=>setEmergencyContactNumber(e.target.value)}
            formErrors={formErrors}
            className={"col-lg-2"}
            label={"Emergency Home Phone"}
            inputClassName="EmergencyContactNumber"
            onBlur={handleEventOnBlur}
          />
        ) : (
          <NumberInputField
            type="text"
            name="EmergencyContactNumber"
            format="###-###-####"
            mask="_"
            value={emergencyContactNumber}
            formErrors={formErrors}
            formsTouched={{EmergencyContactNumber:true}}
            className={"col-lg-2"}
            label={"Emergency Home Phone"}
            inputClassName="EmergencyContactNumber"
            onChange={(e)=>setEmergencyContactNumber(e.target.value)}
            onBlur={handleEventOnBlur}
          />
        )}
        <TextInputField
          type="text"
          name="truck"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Truck Owner"}
          value={
            fleetOwner ? fleetOwner.company_name : ""
          }
          disabled={true}
        />
        <TextInputField
          type="text"
          name="carrier"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Carrier Name"}
          value={
            userProfile &&
            userProfile.user.role === "carrier"
              ? userProfile.user.carrier.company_name
              : ""
          }
          disabled={true}
        />
        <TextInputField
          type="text"
          name="carrierAddress"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"Main Office Address"}
          placeholder="Street"
          value={
            userProfile &&
            userProfile.user.role === "carrier" &&
            userProfile.user.defaultAddressId
              ? userProfile.user.defaultAddressId.company_address
              : ""
          }
          disabled={true}
        />
        <TextInputField
          type="text"
          name="hstPercentage"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"HST Percentage"}
          value={hstPercentage}

          inputClassName="hstPercentage"
          onChange={(e)=>setHstPercentage(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="tShirtSizes"
          className={"col-lg-2"}
          formErrors={formErrors}
          label={"T-Shirt Sizes"}
          value={tShirtSizes}

          inputClassName="tShirtSizes"
          onChange={(e)=>setTShirtSizes(e.target.value)}
          onBlur={handleEventOnBlur}
        />
        <StateFormSelect
          type="text"
          name="driverPreferences"
          label={`Driver Preferences`}
          formErrors={formErrors}
          formsTouched={{driverPreferences: true}}
          onValChange={(key,value)=>{
            setDriverPreferences(value)
            handleOnBlur("driverPreferences",value)
          }}
          options={YesOrNo}
          className={"col-lg-2"}
          value={YesOrNo.find((pt) => pt.value == driverPreferences)}
          inputClassName="driverPreferences"
        />
        <StateFormSelect
          type="text"
          name="isSettlementHide"
          label={`Hide Settlements`}
          formErrors={formErrors}
          formsTouched={{isSettlementHide: true}}
          onValChange={(key,value)=>{
            setIsSettlementHide(value)
            handleOnBlur("isSettlementHide",value)
          }}
          options={YesOrNo}
          className={"col-lg-2"}
          value={YesOrNo.find((pt) => pt.value == isSettlementHide)}
          inputClassName="isSettlementHide"
        />
        <div className="col-md-4">
          <div>
            <AddressInputField
              type="text"
              label={"Permanent Address"}
              name="permanentAddress"
              inputClassName="permanentAddress"
              formErrors={formErrors}
              value={permanentAddress}
              placeholder="e.g. 1111 Parker Rd. Allentown, New Mexico 11111"
              onChange={(e) => {
                setPermanentAddress(e.target.value);
              }}
              onBlur={handleEventOnBlur}
            />
          </div>
        </div>
      </div>
    </>
  )
}
