import React, { useEffect } from "react";
import EachLoad from "./EachLoad";
import {
  useLiveEntitiesDispatch,
  useTrackingDriver,
  useTrackingDriverDispatch,
  useTrackingHistoryDispatch,
  useTrackingLoadDispatch,
} from "../../../../trucker/Tracking/context/TrackingContext";
import {
  DRIVER_ACTIONS,
  PROFILE_ACTIONS,
  LOAD_ACTIONS,
  TRACKING_HISTORY_ACTIONS,
  LIVE_ENTITIES_ACTIONS,
  TRACKING_SHARED_ACTIONS,
} from "../../../../trucker/Tracking/store/actions";
import * as eldServices from "services/eld.services";
import { LOAD_STATUSES } from "constants";
export default function LoadList(props) {
  const dispatchLoad = useTrackingLoadDispatch();

  const loads = props.allLoads && [props.allLoads];

  // return (
  //   <div className={`dlist dlist--cardselected d-flex flex-column pl-10"`}>
  //     {loads &&
  //       loads.map((load, index) => {
  //         return <EachLoad load={load} key={load._id ?? index} selectedDriverId={load?.driver?._id} {...props} />;
  //       })}
  //   </div>
  // );

  const dispatchDriver = useTrackingDriverDispatch();
  const dispatchLiveEntities = useLiveEntitiesDispatch();

  const selectedLoadForTracking = {
    _id: props.allLoads?.driver?._id,
    carrier: props.allLoads?.carrier,
    createdAt: props.allLoads?.createdAt,
    documents: props.allLoads?.documents,
    equipmentID: props.allLoads?.equipment?.equipmentID,
    equipment_type: props.allLoads?.equipment?.equipment_type,
    licence_plate_number: props.allLoads?.equipment?.licence_plate_number,
    updatedAt: props.allLoads?.updatedAt,
    // isFromLoadInfo: true,
    name: props.allLoads?.driver?.name,
    lastName: props.allLoads?.driver?.lastName,
    driver: {
      name: props.allLoads?.driver?.name,
      lastName: props.allLoads?.driver?.lastName,
      _id: props.allLoads?.driver?._id,
      truck: {
        _id: props.allLoads?.equipment?._id,
      },
    },
    equipmentId: props.allLoads?.equipment?._id,
    loads: [props.allLoads],
  };

  const getDriverById = async (driverId) => {
    const { data } = await eldServices.getTrackingDriversFilters({
      driverId,
    });

    return data;
  };

  useEffect(() => {
    if (props && props.allLoads) {
      const _selectedDriverId = props.allLoads?.driver?.driver?._id;
      const isDroppedLoad = !_selectedDriverId && [LOAD_STATUSES.DROPCONTAINER_DEPARTED].includes(props.allLoads?.status);

      if (!_selectedDriverId && !isDroppedLoad) return;

      getDriverById(_selectedDriverId).then((res) => {
        dispatchDriver({ type: DRIVER_ACTIONS.SET_DRIVERS, payload: [res.data?.[0]] });

        const _selectedLoadForTracking = {
          ...selectedLoadForTracking,
          ...res.data?.[0],
        };

        dispatchDriver({ type: DRIVER_ACTIONS.SET_SELECTED_DRIVER, payload: _selectedLoadForTracking });
      });
    }

    dispatchLiveEntities({ type: LIVE_ENTITIES_ACTIONS.SET_FOLLOW_LIVE_ENTITY, payload: true });
    dispatchLoad({ type: LOAD_ACTIONS.SET_SELECTED_LOAD, payload: props.allLoads });
  }, []);

  return (
    <div className={`dlist flex-grow-1 d-flex flex-column dlist--cardselected gap-1 mb-10`}>
      {loads &&
        loads.map((load, index) => {
          return <EachLoad load={load} key={load._id ?? index} selectedDriverId={load?.driver?._id} {...props} />;
        })}
    </div>
  );
}
