import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomIconAddUser } from "../../../../../../Components/Common/CustomIcons/Index";
import {
  IconCheck,
  IconTimes,
  IconLike,
} from "../../../../../../Components/Common/Icons";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';
import DriverList from "../portals/DriverList";
import { Modal } from "react-bootstrap";
import { makeLoadStartabelToDriver } from "../../actionCreator";
import confirm from "../../../../../../Components/Common/ConfirmAert";
import { hardRemoveDriver } from "../../actionCreator";
import { amplitudeTrack, getStorage, isCabotageEnabled, isFreeFlowOn, isHavePostTenderPermission, isManageFleetOrOutSource, isRoutingService, toastr } from "../../../../../../services/Common.services";
import { LoadReferenceContext } from "../../NewDispatcherContext";
import { assignLoadToDriver } from "../../actionCreator";
import { useSelector, shallowEqual } from "react-redux";
import PopperTooltip from "../portals/PopperTooltip";
import useHover from "../../../../../../Components/CustomHooks/useHover";
import TripIncompleteList from "../../../../FreeFlow/Components/TripIncompleteList";
import { DUAL_TRANSACTION_MESSAGES, loadEvents } from "../../../../constant";
import { ActionType } from "../../../../ChassisValidation/utils/constants";
import _ from "lodash";
import { setLoadStatusOrder } from "../../../../Load/actionCreator";
import { mappedPayloadSuggestion } from "../../../../ChassisValidation/utils";
import SuggestionModal from "../../../../ChassisValidation/components/SuggestionModal";
import { handleAddLegs, handleAddSupportrMove } from "../../../../ChassisValidation/actions";
import InvalidLegModal from "../../../../ChassisValidation/components/InvalidLegModal";
import { checkTrips } from "../../../../FreeFlow/FreeFlow.services";
import { createLegsFromDriverOrder, getIsLoadOrTripLive, isDriverAcceptMovesEnabled, isMoveEndingEvent } from "../../../../../../Components/Common/functions";
import moment from "moment";
import LoadRouting from "../../../../LoadRouting";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";
import ChassisValidationModal from "pages/tms/LoadRoutingV2/ChassisValidationModal";
import { assignVendorNewRouting, manageEventOrder, removeVendorNewRouting } from "../../../../LoadRoutingV2/actionCreator";
import AlertBreakDualTransaction from "../../../../../../Components/Common/AlertBreakDualTransaction";
import { getNewLoadDetail } from "../../../../Dispatcher/actionCreators";
import { VENDOR_TYPES } from "pages/tms/DrayosCarrier/constants";
import { CabotageViolationModal } from 'pages/tms/LoadRoutingV2/CabotageViolationModal'
import { CARRIER_REMOVE_MESSAGE } from "pages/tms/LoadRoutingV2/constant";
import { isOddIndexAndHidden } from "../../Functions";
import { toShowAcceptedinDispatcher, driverMoveAcceptedPopUp } from "../../../constants"
import { YARD_TABLE_NAME } from "pages/tms/carrier/AtYardContainerLoads/constant";

const AssignDriverCell = ({ row, column, isCellSelected }) => {
  const cellRef = useRef();
  const prevSelectionRef = useRef();
  const [isDriverAssingInProgress, setIsDriverAssingInProgress] = useState(false);
  const [isDriverList, setIsDriverList] = useState(false);
  const [showDriverOrderModal, setShowDriverOrderModal] = useState(false);
  const [showTripListModal, setShowTripListModel] = useState(false);
  const [selectedLoads, setSelectedLoads] = useState({});
  const { drivers , customers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionModal, setSuggestionModal] = useState(false);
  const [indexForSelect, setIndexForSelect] = useState(null);
  const [legLoader, setLegLoader] = useState(false)
  const [saveLoader, setSaveLoader] = useState(false)
  const [supportMove, setSupportMove] = useState(false)
  const [driver, setDriver] = useState()
  const [load,updatedLoad] = useState(row)
  const [_isInvalidLeg, _setIsInvalidLeg] = useState(false);
	const [showViolatedModal, setShowViolatedModal] = useState(null);

  const [referenceElementRef, referenceElement] = useHover();
  const [removeDriverButtonRef, removeDriverButtonElement] = useHover();

  const [uniqueReferenceElement, setUniqueReferenceElement] = React.useState(null);
  const [uniquePopperElement, setUniquePopperElement] = React.useState(null);
  const [unique, setUnique] = useState(false);
  const [chassisValidation, setChassisValidation] = useState(false)
  const { styles: uniqueStayles, attributes: uniqueAttributes } = usePopper(uniqueReferenceElement, uniquePopperElement, { placement: 'top' });
  const savedFunction = useRef();
  const isManageFleet = isManageFleetOrOutSource().isManageFleet;
  const isOutSource = isManageFleetOrOutSource().isOutSource;
  let context = useContext(LoadReferenceContext);
  const showModel = () => {
    context.selectLoad(row, "notes");
  };

  useEffect(() => {
    if (prevSelectionRef.current === false && isCellSelected) {
      setIsDriverList(true);
    }
    prevSelectionRef.current = isCellSelected;
  }, [isCellSelected]);

  useEffect(()=> {
    updatedLoad(row)
  },[row])

  const isHideDriver = () => {
    return isOddIndexAndHidden(context, row?.reference_number);
  }

  const getUniqueDrivers = () => {
    let uniqueDrivers = [];
    let uniqueDrayosCarrier = [];
    if (row.driverOrder) {
      row.driverOrder.forEach((obj) => {
        if(obj.drayosCarrier) {
          uniqueDrayosCarrier.push(obj.drayosCarrier)
        }
        if (obj.driver) {
          uniqueDrivers.push(obj.driver);
        }
      });
    }
    uniqueDrivers = uniqueDrivers.unique();
    uniqueDrayosCarrier = uniqueDrayosCarrier.unique();
    let finalData = [];
    if (isManageFleet) {
      finalData = [...uniqueDrivers];
    }
    if (isOutSource) {
      finalData = [...finalData, ...uniqueDrayosCarrier];
    }
    return finalData;
  };

  const OpenDriverOrderModal = () => {
    setShowDriverOrderModal(true);
  };

  const OpenTripListModel = (tripDetails) => {
    setShowTripListModel(tripDetails);
  };
 
  const updateList = (notes) => {
    const loadData = Object.assign({}, selectedLoads, { notes });
    setSelectedLoads(loadData);
  };
  const uniqueDrivers = getUniqueDrivers();

  const handleInCurrentDriverOrder = (obj) => {
    if (obj.action[0]?.actionType === ActionType.ADD && obj.action[0]?.actionEvent === loadEvents.CHASSISPICK) {
      updateDriverOrder({ eventType: loadEvents.CHASSISPICK, action: ActionType.ADD, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName, moveId: obj?.moveId })
    }
    else if (obj.action[0]?.actionType === ActionType.ADD && obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION) {
      updateDriverOrder({ eventType: loadEvents.CHASSISTERMINATION, action: ActionType.ADD, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName, moveId: obj?.moveId })
    }
    else if (obj.action[0]?.actionType === ActionType.REMOVE && obj.action[0]?.actionEvent === loadEvents.CHASSISPICK) {
      updateDriverOrder({ eventType: loadEvents.CHASSISPICK, action: ActionType.REMOVE, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName })
    }
    else if (obj.action[0]?.actionType === ActionType.REMOVE && obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION) {
      updateDriverOrder({ eventType: loadEvents.CHASSISTERMINATION, action: ActionType.REMOVE, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName })
    }
  }

  const handleAddValidLeg = async (obj) => {
    let params = {
      leg: obj
    }
    handleAddLegs(params).then((result) => {
      saveAnyWay()
    }).catch((err) => {
      console.log(err, "err")
    })
  }

  const addSuportMoveDynamic = async () => {
    let params = {
      reference_number: suggestions[indexForSelect]?.reference_number,
      driverId: suggestions[indexForSelect]?.driver,
      customerId: supportMove?.value,
      endLocation: supportMove?.allInfos?.address,
      saveInChassisHistory: true
    }
    handleAddSupportrMove(params).then((result) => {
      setSuggestionModal(false)
      OpenDriverOrderModal()
    }).catch((err) => {
      console.log(err, "err")
    })
  }

  const saveAnyWay = async () => {
    const formData = new FormData();
    formData.append("reference_number", row.reference_number);
    formData.append("userId", driver);
    formData.append("isloadStartable", false);
    formData.append("skipValidation", true);
    savedFunction.current = () => saveAnyWay()
    assignLoadToDriver(formData).then((el) => {
      setSaveLoader(false);
      setSuggestionModal(false)
      setDriver(null)
      toastr.show("Assigned!", "success");
      setIsDriverAssingInProgress(false);
      updatedLoad(el)
      OpenDriverOrderModal();
    })
    .catch((err) => {
      setIsDriverAssingInProgress(false);
      console.log("-*- GET Driver Assigned error -*-*", err);
    });
  }

  const updateDriverOrder = (eventType) => {
    let driverOrder = _.cloneDeep(row.driverOrder);
    const formData = new FormData();
      if(eventType){
        let mappedDriverOrder = mappedPayloadSuggestion(driverOrder, eventType)
        if(mappedDriverOrder?.length > 0) driverOrder = _.cloneDeep(mappedDriverOrder)
      }
      const driverOrderLegWise = createLegsFromDriverOrder(driverOrder)
      const firstMoveId = driverOrderLegWise?.driverLegs[0].map((v)=> v?._id)
      let driverOrderWithId = driverOrder.map((v)=> {
        if(firstMoveId.includes(v?._id) || v.type === "CHASSISPICK"){
          v.driver = driver
          v.loadAssignedDate = moment()
              .tz(getStorage("timeZone"))
              .toISOString();
        }
        return v;
      })
      formData.append("reference_number", row.reference_number);
      formData.append("driverOrder", JSON.stringify(driverOrderWithId));
      setLoadStatusOrder(formData).then((result) => {
        updatedLoad(result);
        OpenDriverOrderModal()
        setSuggestionModal(false)
        setLegLoader(false)
        setIndexForSelect(null)
      })
  }

  const checkIsChassisV3 = (chassisV3) => {
    if(chassisV3[0]?.error){
      _setIsInvalidLeg(chassisV3[0]?.error);
      return true;
    } else {
      setSuggestions(chassisV3)
      setSuggestionModal(true)
      return true
    }
  }
  const onDrop = async(e) => {
    const driverId = e.dataTransfer.getData("text")
    const val = drivers.find(item => item._id === driverId);
    const formData = new FormData();
    setDriver(driverId)
    formData.append("reference_number", row.reference_number);
    formData.append("userId", val._id);
    formData.append("isloadStartable", false);
    setIsDriverAssingInProgress(true);
    let isTrip =  await checkTrips(row?._id, row?.orderId, row?.reference_number, val?._id)
      if(isTrip) {
        OpenTripListModel(isTrip);
        setIsDriverAssingInProgress(false);
        return;
      }
      assignLoadToDriver(formData)
      .then((el) => {
        if(el?.isChassisv3){
          if(isRoutingService()){
            setChassisValidation(el?.isChassisv3)
            return
          } 
          let isChassiV3 = checkIsChassisV3(el?.isChassisv3);
          setIsDriverAssingInProgress(false);
          if(isChassiV3) return
        } else {
          let eventProperties = {
						source: 'dispatcher drag'
					}
					amplitudeTrack('DISPATCH_LOAD',eventProperties)
          toastr.show("Assigned!", "success");
          setIsDriverAssingInProgress(false);
          OpenDriverOrderModal();
        }
      })
      .catch((err) => {
        setIsDriverAssingInProgress(false);
        console.log("-*-* GET Driver Assigned error -*-*", err);
      });
  }

  const onChassisEventSuccess = () => {
    setIsDriverAssingInProgress(false)
    saveAnyWay()
  }

  const onChassisEventFailure = () => {
    setIsDriverAssingInProgress(false)
    setLegLoader(false)
  }

  const nextInactiveEvent = row.driverOrder?.find((d) => !d.isVoidOut && !d.departed);
  const isTenderHaveLoadRef = (nextInactiveEvent?.tenderId?.drayOSTenderRefNo && nextInactiveEvent?.tenderId?.status === "ACCEPTED") || false;
  const isRailMove = row?.driverOrder?.find(d => d?.vendorType === 'RAIL' && d.drayosCarrier?._id === row?.drayosCarrier?._id) || false;
  const isTenderSentAndNotRejected = nextInactiveEvent?.tenderId?.drayOSTenderRefNo && nextInactiveEvent?.tenderId?.status !== "REJECTED" || false;
  const postTenderCarrierRemovalPermission = isHavePostTenderPermission();
  const isMoveAccepted = isDriverAcceptMovesEnabled() && toShowAcceptedinDispatcher(row);
  const alertHeader = isMoveAccepted ? "Warning": isTenderHaveLoadRef ? "Vendor Remove" : "Alert";
  const alertBody = isMoveAccepted ? 
                      `A driver has already accepted a move on this load. 
                      Are you sure you want to remove all the drivers from this load ?` : isTenderHaveLoadRef ? CARRIER_REMOVE_MESSAGE
                          :"Are you sure you want to remove?";
  const confirmOption = isMoveAccepted ? { justifyContentCenter: true } : {};
  const moveWithoutVoidOut = row.driverOrder?.filter(move => !move.isVoidOut)
  const isLoadStarted = moveWithoutVoidOut?.length > 0 && moveWithoutVoidOut[0]?.arrived;
  const isLoadLiveOrCompleted = getIsLoadOrTripLive(row);
  const isAPICall = () => {
          let eventProperties = {
            source: "unassign_driver"
          }
          amplitudeTrack("DISPATCHER_TABLE_DRIVER",eventProperties)

          let params = { reference_number: row.reference_number };
          let apiToCall = hardRemoveDriver;

          if (isRoutingService()) {
            let lastCompletedDroppedStatusIndex = -1;
            row?.driverOrder.forEach((obj, k) => {
              const _isMoveEndingEvent = isMoveEndingEvent(obj, row?.driverOrder?.[k + 1]);
              if (_isMoveEndingEvent && obj.departed) {
                lastCompletedDroppedStatusIndex = k;
              }
            });

            const vendorType = row?.driver ? "driver" : "drayosCarrier";
            const vendorId = row?.driver ? row?.driver?._id : row?.drayosCarrier?._id;
            const nextEvent = row?.driverOrder?.find(
              (event, index) =>
                index > lastCompletedDroppedStatusIndex &&
                event?.[vendorType]?._id + "" === vendorId + "" &&
                !event?.isVoidOut
            );
            const _moveId = nextEvent?.moveId;

            params = { loadId: row?._id, moveId: _moveId }
            apiToCall = removeVendorNewRouting;
          }

          apiToCall(params)
            .then((result) => {
              setIsDriverList(false);
              toastr.show("Removed.", "success");
            })
            .catch((err) => {
              console.log("err", err);
            });
  }
  const removeDriver = async (alertHeader, alertBody, confirmOption) => {
    const isDualTransactionLoad = load?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction && leg?.driver?._id === row?.driver?._id);
    if (isDualTransactionLoad) {
      await AlertBreakDualTransaction(load, DUAL_TRANSACTION_MESSAGES.REMOVE_DRIVER);
      isAPICall();
    } else {
        confirm(
          alertHeader,
          alertBody,
          (confirm) => {
            if (confirm) {
              isAPICall()
            }
          },
          confirmOption
        );
    }
  }

  const getLoadInfo = async(reference_number)=>{
    const res = await getNewLoadDetail(reference_number);
    if(res)updatedLoad(res)
  }

  const handleAssignDriver = async (data) => {
    const formData = new FormData();
    formData.append("reference_number", row.reference_number);
    formData.append("userId", data.value);
    formData.append("isloadStartable", false);
    let nextValidEvent = row?.driverOrder?.find(
      (event) => !event?.driver && !event?.drayosCarrier && !event?.isVoidOut
    );
    if (column?.tableName === YARD_TABLE_NAME.DUAL_TRANSACTION_LINKED_TABLE) {
      const _nextValidEvent = row?.driverOrder?.find(
        (event) => !event?.driver && !event?.drayosCarrier && !event?.isVoidOut && event?.isDualTransaction && event?.dualTransactionTo && event?.type === loadEvents.RETURNCONTAINER
      );
      if (_nextValidEvent){
        nextValidEvent = _nextValidEvent;
      }
    }
    let payload = {
      loadId: row?._id,
      moveId: nextValidEvent?.moveId,
      loadAssignedDate: moment().toISOString(),
      ...(data?.value && {
        vendorId: data?.value,
        vendorType: data?.allInfos?.role === "driver" ? "DRIVER" : (data?.allInfos?.vendorType === VENDOR_TYPES.BARGE ? VENDOR_TYPES.BARGE : "CARRIER"),
      }),
    };
    let apiName = isRoutingService() ? assignVendorNewRouting(payload) : assignLoadToDriver(formData);
    setIsDriverAssingInProgress(true);
    setDriver(data.value);
    let isTrip = await checkTrips(row?._id, row?.orderId, row?.reference_number, data?.value);
    if (isTrip) {
      OpenTripListModel(isTrip);
      setIsDriverAssingInProgress(false);
      return;
    }
    apiName
      .then((el) => {
        if(showViolatedModal){
          setShowViolatedModal(null);
        }
        setIsDriverList(null);
        if (el?.isChassisv3) {
          if (isRoutingService()) {
            setChassisValidation(el?.isChassisv3);
            return;
          }
          let isChassiV3 = checkIsChassisV3(el?.isChassisv3);
          setIsDriverAssingInProgress(false);
          if (isChassiV3) return;
        } else {
          let eventProperties = {
            source: "dispatcher dropdown",
          };
          amplitudeTrack("DISPATCH_LOAD", eventProperties);
          setIsDriverAssingInProgress(false);
          OpenDriverOrderModal(el);
        }
      })
      .catch((err) => {
        if(showViolatedModal){
          setShowViolatedModal(null);
        }
        setIsDriverAssingInProgress(false);
        console.log("-*-* GET Driver Assigned error -*-*", err);
      });
  };

  const handleCloseRemoveDriverModal = () => {
    setShowViolatedModal(false);
    setIsDriverList(null);
  }

  if (!isHideDriver()) { 
   return (
    <>
    <div className="cell-content">
      {isDriverAssingInProgress && (
        <CellSpinner />
      )}
      {!isDriverAssingInProgress &&
        ((row?.drayosCarrier ? row.drayosCarrier : row.driver) ? (
          <React.Fragment>
            {row.driver?.profilePicture && (
              <div className="align-self-center">
                <img
                  src={row.driver?.profilePicture}
                  className="avatar-xs rounded-circle"
                  width="30"
                  alt=""
                />
              </div>
            )}
            <div 
              id={"Tooltip-" + row._id}
              className="mb-0 align-self-center d-flex align-items-center gap-5 rdg-truncate"
              onDrop={(e) => onDrop(e)}
            >
              
              <div
                ref={referenceElementRef}
                className="text-dark text-capitalize font-12 text-truncate pointer"
                onClick={() => {
                  let eventProperties = {
                    source: "open_routing_modal"
                  }
                  amplitudeTrack("DISPATCHER_TABLE_DRIVER",eventProperties)
                  OpenDriverOrderModal()
                }}
                title={row?.drayosCarrier ? row?.drayosCarrier?.company_name : row.driverName}
              >
                
                {isHideDriver() ? "" : row?.drayosCarrier ? row?.drayosCarrier?.company_name : row?.driverName}
              </div>

              {referenceElement &&
                <PopperTooltip refNo={referenceElementRef?.current} name={row?.drayosCarrier ? row?.drayosCarrier?.company_name : row.driverName} color={'gray-700'} cssClass={"driver_assign"} />
              }
              {uniqueDrivers && uniqueDrivers.length > 1 && (
                <span >
                  <span 
                    ref={setUniqueReferenceElement}
                    onMouseOver={() => setUnique(true)}
                    onMouseOut={() => setUnique(false)}
                    className={`badge badge-light pointer  ${row.reference_number}`}
                    onClick={() => {OpenDriverOrderModal()}}>
                    +{uniqueDrivers.length - 1}
                  </span>
                </span>
                
              )}
              {unique && ReactDOM.createPortal(
                  isDriverAcceptMovesEnabled() ?
                    driverMoveAcceptedPopUp({ row, setUniquePopperElement, uniqueStayles, uniqueAttributes })
                   :
                    <div
                        className="tooltip bs-tooltip-top tooltip--color text-capitalize portal-tooltip driver_assign"
                        ref={setUniquePopperElement}
                        style={uniqueStayles.popper}
                        {...uniqueAttributes.popper}
                      >
                        {uniqueDrivers && uniqueDrivers.length > 1 && (
                          <div>
                            {uniqueDrivers &&
                              uniqueDrivers.map((obj) => {
                                if(obj.company_name) {
                                  if(row.drayosCarrier && obj._id === row.drayosCarrier._id) {
                                    return "";
                                  } else {
                                    return (
                                      <div key={obj._id}>
                                          {obj.company_name?.toLowerCase()}
                                      </div>
                                    );
                                  }
                                } else {
                                  if ((row.driver && obj._id === row.driver._id)) {
                                    return "";
                                  } else {
                                    return (
                                      <div key={obj._id}>
                                          {obj.name.toLowerCase()}{" "}
                                          {obj.lastName.toLowerCase()}
                                      </div>
                                    );
                                  }
                                }
                              })}
                          </div>
                        )}
                        <div className="arrow"></div>
                      </div>,
                        document.querySelector('#poper-dest')
                 )}
              <div className="d-flex ml-auto">
                {isManageFleet && !isTenderHaveLoadRef && (row.driver || isRailMove) && (
                  !isLoadLiveOrCompleted && isMoveAccepted ? 
                  <span className="mr-1 wh-24px bg-brand-50 rounded-3 d-inline-flex align-items-center justify-content-center">
                      <IconLike className="text-blue-500 wh-16px" />
                  </span> :
                  <button
                    className="btn btn-xs btn-success mr-1"
                    data-testid="start-load-button"
                    onClick={() => {
                      let eventProperties = {
                        source: "start_load"
                      }
                      amplitudeTrack("DISPATCHER_TABLE_DRIVER",eventProperties)
                       makeLoadStartabelToDriver({
                        reference_number: row.reference_number,
                      })
                        .then(() => {
                          toastr.show("Load Started", "success");
                        })
                        .catch((err) => {
                          const errorMessage = err.data?.message || 'An unknown error occurred';
                          toastr.show(errorMessage, 'error');
                          // toastr.show(err.data.message, "error");
                        });
                    }}
                    disabled={row.isloadStartable || row.status === "COMPLETED"}
                  >
                    <IconCheck />
                  </button>
                )}
                {(row.driver || row?.drayosCarrier) && (
                  <>
                  <button
                    data-testid="remove-driver-button"
                    className="btn btn-xs btn-danger"
                    onClick={() => {
                      removeDriver(alertHeader, alertBody, confirmOption)
                    }}
                    disabled={JSON.parse(getStorage("userBasicSettings"))?.isChassisV3 || (isTenderHaveLoadRef && isLoadStarted) || (!postTenderCarrierRemovalPermission && isTenderSentAndNotRejected)}
                    ref={removeDriverButtonRef}
                  >
                    <IconTimes />
                  </button>
                  {removeDriverButtonElement && (isTenderHaveLoadRef || (!postTenderCarrierRemovalPermission && isTenderSentAndNotRejected)) &&
                    <PopperTooltip isOpen={removeDriverButtonElement} refNo={removeDriverButtonRef?.current} name={isLoadStarted ? "Load Started" : "Vendor Remove"} color={'gray-700'} cssClass={"driver_assign"} />
                  }
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="text-center w-100" data-testid="driver-drop-zone" onDrop={(e) => onDrop(e)} ref={cellRef}>
            <div className="position-relative d-inline-block">
              <div className="pointer" onClick={() => { setIsDriverList(true); }}>
                <CustomIconAddUser />
              </div>
              {
                isCabotageEnabled() && showViolatedModal && (
                  <CabotageViolationModal
                    isWarning
                    contentTitle=''
                    confirmText='Continue'
                    response={showViolatedModal?.res}
                    setShowModal={handleCloseRemoveDriverModal}
                    showModal={showViolatedModal ? true : false}
                    contentBody={showViolatedModal?.message ?? ''}
                    removeDriver={() => handleAssignDriver(showViolatedModal?.driver)}
                  />
                )
              }  
              <div>
                {isDriverList && (
                  <DriverList
                    Row={row}
                    CellRef={cellRef}
                    onClose={(val) => {
                      setIsDriverList(val);
                      const a = document.querySelector(
                        '[aria-selected="true"]'
                      );
                      if (a) a.focus();
                    }}
                    setSuggestions={setSuggestions}
                    setSuggestionModal={setSuggestionModal}
                    updatedLoad={updatedLoad}
                    setShowViolatedModal={setShowViolatedModal}
                    handleAssignDriver={handleAssignDriver}
                    moveId={(() => {
                      let nextValidEvent = row?.driverOrder?.find(
                        (event) => !event?.driver && !event?.drayosCarrier && !event?.isVoidOut
                      );
                      if (column?.tableName === YARD_TABLE_NAME.DUAL_TRANSACTION_LINKED_TABLE) {
                        const _nextValidEvent = row?.driverOrder?.find(
                          (event) =>
                            !event?.driver &&
                            !event?.drayosCarrier &&
                            !event?.isVoidOut &&
                            event?.isDualTransaction &&
                            event?.dualTransactionTo &&
                            event?.type === loadEvents.RETURNCONTAINER
                        );
                        if (_nextValidEvent) {
                          nextValidEvent = _nextValidEvent;
                        }
                      }
                      return nextValidEvent?.moveId;
                    })()}
                    bulkHosAvailability={context?.bulkHosAvailability}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      {showDriverOrderModal && (
        <>
        <Modal
          show={showDriverOrderModal}
          centered={true}
          className="modal-extend modal-full p-4 load-info-modal"
          scrollable={true}
        >
          <Modal.Body className="bg-gray-50 p-3 h-100 load-modal-full load-modal-driver">
            <div  className="overflow-y-auto" style={{height: 'calc(100vh - 81px)', overflowX: 'hidden'}}>
              <LoadRouting
                selectedLoads={load}
                reference_number={load && load.reference_number}
                key={`sub_${load ? load.reference_number : ""}`}
                changeList={updateList}
                closeStatusModal={() => setShowDriverOrderModal(false)}
                dispatcherModal={true}
                showNotesModel={showModel}
                updateLoadInfo={(loadNo) => getLoadInfo(loadNo)}
              />
            </div>
          </Modal.Body>
        </Modal>
        </>
      )}
      {isFreeFlowOn() && <TripIncompleteList
      setShowTripListModel={setShowTripListModel}
      tripDetails={showTripListModal}
      setIsDriverAssingInProgress={setIsDriverAssingInProgress}
      OpenDriverOrderModal={OpenDriverOrderModal}
       />}
      
      {suggestions && 
          <SuggestionModal 
              showModal={suggestionModal}
              suggestions={suggestions}
              customers={customers}
              selectedSupportMove={supportMove}
              indexForSelect={indexForSelect}
              addLegLoader={legLoader}
              saveLoader={saveLoader}
              setSupportMove={setSupportMove}
              setIndexForSelect={setIndexForSelect}
              onCancel={() => {
                setSuggestionModal(false)
                setIsDriverAssingInProgress(false)
                setIndexForSelect(null)
              }}
              onConfirm={() => {
                if (suggestions[indexForSelect]?.loadType === "Current") { handleInCurrentDriverOrder(suggestions[indexForSelect]) }
                else if (supportMove) addSuportMoveDynamic()
                else handleAddValidLeg(suggestions[indexForSelect])
                setLegLoader(true)
              }}
          />
        }
        {_isInvalidLeg && 
          <InvalidLegModal 
            isInvalidLeg={_isInvalidLeg}
            onCancel={() => {
              _setIsInvalidLeg(false);
            }}
          />
        }

      <ChassisValidationModal
        validations={chassisValidation}
        loadDetails={load}
        onChassisEventSuccess={onChassisEventSuccess}
        onChassisEventFailure={onChassisEventFailure}
      />
    </div>
    </>
    );
  } else {
    return (<></>)
  }
};

export default AssignDriverCell;
