import React, { useState, useCallback, useMemo, useEffect, useContext } from "react";
import FavoriteItem from "./FavoriteItem";
import { IconAngleArrowDown, IconAngleArrowRight, IconFolderOutline } from "Components/Common/Icons";
import { changeFavoriteOrder } from "../../../actionCreator";
import { EMAIL_CONTEXT } from '../../../constant';
import _ from "lodash";

let currentDragType = null;
const DRAG_TYPE = 'favorite-folder';

const FavoriteSection = React.memo(({ favorites: initialFavorites }) => {
    const context = useContext(EMAIL_CONTEXT);
    const { setFavoriteFolders } = context
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [favorites, setFavorites] = useState(initialFavorites);
    const [dragTarget, setDragTarget] = useState(null);

    useEffect(() => {
        setFavorites(initialFavorites);
    }, [initialFavorites]);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const debouncedChangeFavorite = useCallback(
        _.debounce((payload) => {
            changeFavoriteOrder(payload);
        }, 500),
        []
    );

    const handleDragStart = (e, folder) => {
        currentDragType = DRAG_TYPE;
        e.dataTransfer.setData('folderId', folder?.id);
        e.dataTransfer.setData('grantId', folder?.grantId);
        e.dataTransfer.setData('dragType', DRAG_TYPE);

        e.dataTransfer.dropEffect = 'copy';
    };

    const handleDragOver = (e, folder) => {
        e.preventDefault();
        e.stopPropagation();
        if (currentDragType === DRAG_TYPE) {
            e.dataTransfer.dropEffect = 'copy';
            setDragTarget(folder);
            return false;
        }
        e.dataTransfer.dropEffect = 'none';
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragTarget(null);
        e.stopPropagation();
    };

    const handleDragEnd = (e) => {
        currentDragType = null;
        setDragTarget(null);
        e.stopPropagation();
    };

    const handleDrop = useCallback((e, [targetFolderId, targetFolderGrantId]) => {
        e.preventDefault();
        e.stopPropagation();
        setDragTarget(null);
        const draggedFolderId = e.dataTransfer.getData('folderId');
        const draggedFolderGrantId = e.dataTransfer.getData('grantId');
        currentDragType = null;

        if (draggedFolderId === targetFolderId && draggedFolderGrantId === targetFolderGrantId) return;

        setFavorites(prev => {
            const draggedIndex = prev.findIndex(item => item?.id === draggedFolderId && item?.grantId === draggedFolderGrantId);
            const targetIndex = prev.findIndex(item => item?.id === targetFolderId && item?.grantId === targetFolderGrantId);

            if (draggedIndex === -1 || targetIndex === -1) return prev;

            const newOrder = [...prev];
            const [draggedItem] = newOrder.splice(draggedIndex, 1);
            newOrder.splice(targetIndex, 0, draggedItem);

            const updatedOrder = newOrder.map((item, index) => ({
                ...item,
                order: index,
            }));

            const updatedFolders = updatedOrder.map(({ id, name, order, grantId }) => ({ id, name, order, grantId, }));
            setFavoriteFolders(updatedOrder)
            debouncedChangeFavorite({ grant: targetFolderGrantId, favoriteFolders: updatedFolders });
            handleDragEnd(e)
            return updatedOrder;
        });
    }, [debouncedChangeFavorite]);


    const sortedFavorites = useMemo(() => {
        return [...favorites].sort((a, b) => (a?.order || 0) - (b?.order || 0));
    }, [favorites]);

    return (
        <div className="d-flex flex-column py-2 pointer">
            <div className="d-flex align-items-center h-26px gap-5" onClick={toggleCollapse}>
                {isCollapsed ? <IconAngleArrowRight /> : <IconAngleArrowDown />}
                <span className="font-10 line-height-12 text-muted">Favorites</span>
            </div>
            {!isCollapsed && (
                <div>
                    {sortedFavorites?.map((folder) => (
                        <DraggableFavoriteItem
                            key={`${encodeURIComponent(folder?.id)}-${folder?.grantId}`}
                            folder={folder}
                            onDragStart={handleDragStart}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                            isDragTarget={dragTarget?.id === folder?.id && dragTarget?.grantId === folder?.grantId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
});

const DraggableFavoriteItem = React.memo(({
    folder,
    onDragStart,
    onDragOver,
    onDragLeave,
    onDrop,
    isDragTarget,
}) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragStart = (e) => {
        setIsDragging(true);
        onDragStart(e, folder);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <div
            className={`${isDragTarget && !isDragging ? 'rounded-5 bg-primary-100' : ''}`}
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => onDragOver(e, { id: folder?.id, grantId: folder?.grantId })}
            onDragLeave={onDragLeave}
            onDrop={(e) => onDrop(e, [folder?.id, folder?.grantId])}
        >
            <FavoriteItem folder={folder} provider={folder?.provider} isDragging={isDragging}/>
        </div>
    );
});

export default FavoriteSection;