import { getStorage, updateTerminology } from "../../../../../../services";
import { timeZoneList } from "../../../../constant";
import * as Yup from "yup";

export const YesOrNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const profileOptions = [
  { label: "PORT", value: "PORT" },
  { label: "DELIVERY", value: "DELIVERY" },
  { label: "TRAILER", value: "TRAILER" },
  { label: "COMPANY DRIVER", value: "COMPANY DRIVER" },
  { label: "INDEPENDENT CONTRACTOR", value: "INDEPENDENT CONTRACTOR" },
  { label: "OWNER OPERATOR", value: "OWNER OPERATOR" },
  { label: "3rd PARTY DRIVER", value: "3rd PARTY DRIVER" },
  { label: "TEMPORARY", value: "TEMPORARY" },
  { label: "SUBCONTRACTOR", value: "SUBCONTRACTOR" },
  { label: "AGENT - COMPANY", value: "AGENT - COMPANY" },
  { label: "LONG HAUL", value: "LONG HAUL" },
  { label: "All", value: "All" },
];

export const LoadTypeOptions = [
  { value: "IMPORT", label: "IMPORT" },
  { value: "EXPORT", label: "EXPORT" },
  { value: "ROAD", label: "ROAD" },
];

export const terminalTimzoneOptions = timeZoneList;

export const DistanceOptions = (()=>{
    let options = []
    let i = 0;
    while (i < 3000) {
    options.push({
        value: { min: i === 0 ? 0 : i + 1, max: i + 50 },
        label: `${i === 0 ? 0 : i + 1}-${i + 50}`,
    });
    i += 50;
    }
    return options;
})();
export const USA_PHONE = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const schemaValidatorObjectFunc = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat
  const phoneRegExp = phoneformat === 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : USA_PHONE ;
  
  const schemaValidatorObject = Yup.object().shape({
    _id: Yup.string().nullable(),
    isTerminal: Yup.boolean(),
    newTerminal: Yup.array()
      .nullable()
      .when("isTerminal", {
        is: (val) => val === true,
        then: Yup.array()
          .min(1, `${updateTerminology('terminal')} Is Required.`)
          .of(
            Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
          )
          .typeError(`${updateTerminology('terminal')} is Required.`)
          .required(`${updateTerminology('terminal')} is Required.`),
      }),
  
    licenceNumber: Yup
      .string()
      .nullable()
      .when('licenceNumber', {
          is: (value) => value && value.length,
          then: (rule) => rule.min(7, "License Number is too short - should be 7 chars minimum."),
      }),
    mobile: Yup.string()
      .matches(phoneRegExp, "Mobile is not valid")
      .required("Mobile is Required."),
    email: Yup.string().email("Invalid Email").nullable().required("Email is Required."),
    lastName: Yup.string()
      .min(2, "Enter atleast 2 characters.")
      .max(50, "Enter less than 50 characters.")
      .required("Last Name is Required."),
    name: Yup.string()
      .min(3, "Enter atleast 3 characters.")
      .max(50, "Enter less than 50 characters.")
      .required("Name is Required."),
    password: Yup.string().when("_id", {
      is: (val) => !val,
      then: Yup.string()
        .min(5, "Enter atleast 5 characters.")
        .max(50, "Enter less than 50 characters.")
        .required("Password is required."),
    }),
    username: Yup.string()
    // .when("isUserNameValid",{
    //   is: (val) => val === false,
    //     then: Yup.string()
    //       // .typeError("Username is Required.")
    //       .required("Username is Required."),
    // })
    .min(7, "Please enter a stronger username - should be 7 chars minimum.")
    .required("Username is required."),
  },
  [
  
    ['licenceNumber', 'licenceNumber'],
  ]);
  return schemaValidatorObject
}

export const successColorResponseSelect = (element) => {
  const Element = document.querySelector(`.${element} > div`)
  Element?.classList.add('background-success');
  Element.style.transition = "background .4s ease-out";

  setTimeout(() => {
      Element?.classList.remove("background-success")
  }, 400);
}
export const errorColorResponseSelect = (element) => {
  const Element = document.querySelector(`.${element} > div`)
  Element?.classList.add("background-error")
  Element.style.transition = "background .4s ease-out";

  setTimeout(() => {
      Element.classList.remove("background-error")
  }, 400);
}