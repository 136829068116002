import _ from "lodash";
import { LOAD_TYPES } from "pages/tms/carrier/AccountReceivable/constants";
import { makeOptionsForSelect, updateTerminology } from "../../../../../services";
import moment from "moment";
import { statusLabels } from "../../constant";
import { getPreSetOrderStatus } from "pages/tms/Load/DriverOrderUtility";

export const readUnreadStatuses = [
  {label:"Read",value:false},
  {label:"Unread",value:true},
  {label:"All",value:null}
]

export const isValidMomentDate = (dateString) => {
  return moment(dateString,'YYYY-MM-DDTHH:mm:ss.SSSZ',true)?.isValid()
}

export const EMAIL_FILTERS_KEY = {
    emailFromDate: '',
    emailToDate: '',
    organizations: [],
    isExcludeOrganizations: null,
    people: [],
    isExcludePeople: null,
    myTeam: [],
    isExcludeMyTeam: null,
    branches: [],
    isExcludeBranches: null,
    csr: [],
    createdBy: [],
    marginFrom: '',
    marginTo: '',
    containerNo: '',
    callerbillLandingNo: '',
    secondaryReferenceNo: '',
    bookingNo: '',
    purchaseOrderNo: '',
    isPendingLoad: null,
    isInProgressLoad: null,
    isCompletedLoad: null,
    isCombinedTrip:null,
    hasAttachment:null,
    pickupFrom: '',
    pickupTo: '',
    deliveryFrom: '',
    deliveryTo: '',
    returnFrom: '',
    returnTo: '',
    lastFreeDayFrom: '',
    lastFreeDayTo: '',
    cutOffFrom: '',
    cutOffTo: '',
    type_of_load: [],
    shipper: [],
    consignee: [],
    emptyOrigin: [],
    drivers: [],
    containerTypes: [],
    containerSizes: [],
    containerOwners: [],
    preSetOrderStatus: [],
    isReadyForPickup: [],
    unread:readUnreadStatuses.find(op => op.label === 'All'),
    label:'',
    selectedStatus:[],
    chassisSizes:[],
    chassisTypes:[],
    isWithLoadEmail:null,
    isWithoutLoadEmail:null,
    isWithPeopleEmail:null,
    isWithoutPeopleEmail:null,
    tagIds:[],
    activePage:"",
    selectedTabForView:"",
    emailStatus: [],
    slaRule: [],
    assignees: [],
    searchTerm:''
}
export const createPayloadFromEmailFilters = (emailFilters) => {
  let payload = {};
  for (const key of Object.keys(emailFilters)) {
    const param = _.cloneDeep(emailFilters[key]); 
    if (key === "tagIds") {
      if (param?.length > 0) payload.tagIds = param.map((val) => val?._id ?? val?.value);
    }
    else if (key === 'unread') {
      if ([true,false].includes(param?.value)) payload.unread = param?.value      
    }
    else if (key === 'preSetOrderStatus') {
      if (param?.label) payload.preSetOrderStatus = [param?.value]
    }
    else if (key === "selectedStatus") {
      if (param?.length > 0) payload = handleLoadStatus(emailFilters[key], payload);
    } else if (key === "isReadyForPickup" && Array.isArray(param)) {
      if(param?.length === 1) {
        payload[key] = param.map((val) => val?.value)[0] ?? undefined;
      } else {
        delete payload[key];
      }
    } else if (dateTypeFields.includes(key)) {
      if (isValidMomentDate(emailFilters[key])) {
        let _date = _.cloneDeep(emailFilters[key])
        payload[key] = moment(_date).toISOString()
      } 
    } else if (!["", null].includes(param)) {
      if (key === "label") {
        payload[key] = param?.value;
      } else if (Array.isArray(param) && param?.length > 0) {
        if(key === "peoples") {
          payload["people"] = param.map((val) => val?.value || val);
        } else if(key === "teamMembers") {
          payload["myTeam"] = param.map((val) => val?.value || val);
        } else if(key === "assignees") {
          payload[key] = param.map((val) => val?.value || val);
        }else {
          payload[key] = param.map((val) => val?.value || val);
        }
      } else {
        if (param?.length || [true, false]?.includes(param)) payload[key] = param;
      }
    }
  }

  // update warehouseAddress to consignee
  if (payload.warehouseAddress?.length) {
    payload.consignee = [...( payload.consignee || []), ...(payload.warehouseAddress|| [] )]
    delete payload.warehouseAddress
  }
  return payload;
};



export const handleLoadStatus = (selectedStatus, params) => {
  selectedStatus.forEach((status) => {
      if (
        [
          "CAUTION_COUNT",
          "LOCAL_COUNT",
          "SCALE_COUNT",
          "REEFER_COUNT",
          "DOMESTIC_COUNT",
          "EV_COUNT",
          "WASTE_COUNT",
          "GDP_COUNT",
          "IS_RAIL_COUNT",
          "GENSET_COUNT",
          "LIQUOR_COUNT",
          "HIGHWAY_COUNT",
          "NOTE_COUNT",
          "HOT_COUNT",
          "SCALES_COUNT",
        ].includes(status)
      ) {
        if (selectedStatus.includes("CAUTION_COUNT")) {
          params.hazmat = true;
        }
        if (selectedStatus.includes("LOCAL_COUNT")) {
          if (params?.routeType?.length && !params.routeType.includes("Local")) {
            params.routeType.push("Local")
          } else {
            params.routeType = ["Local"]
          }
        }
        if (selectedStatus.includes("SCALE_COUNT")) {
          params.overWeight = true;
        }
        if (selectedStatus.includes("REEFER_COUNT")) {
          params.temperature = true;
        }
        if (selectedStatus.includes("DOMESTIC_COUNT")) {
          params.domestic = true;
        }
        if (selectedStatus.includes("EV_COUNT")) {
          params.ev = true;
        }
        if (selectedStatus.includes("WASTE_COUNT")) {
          params.waste = true;
        }
        if (selectedStatus.includes("GDP_COUNT")) {
          params.gdp = true;
        }
        if (selectedStatus.includes("IS_RAIL_COUNT")) {
          params.isRail = true;
        }
        if (selectedStatus.includes("GENSET_COUNT")) {
          params.isGenset = true;
        }
        if (selectedStatus.includes("LIQUOR_COUNT")) {
          params.liquor = true;
        }
        if (selectedStatus.includes("HIGHWAY_COUNT")) {
          if (params?.routeType?.length && !params?.routeType.includes("Highway")) {
            params.routeType.push("Highway")
          } else {
            params.routeType = ["Highway"]
          }
        }
        if (selectedStatus.includes("NOTE_COUNT")) {
          params.officeNote = true;
        }
        if (selectedStatus.includes("HOT_COUNT")) {
          params.isHot = true;
        }
        if (selectedStatus.includes("SCALES_COUNT")) {
          params.scale = true;
        }
      }
    });
    return params
}

export const FilterBadges = {
  emailFromDate: "Email Date From",
  emailToDate: "Email Date To",
  organizations: "Organization",
  people: "People",
  peoples: "People",
  myTeam: "My Team / Users",
  teamMembers: "My Team / Users",
  branches: "Terminal",
  csr: "Csr",
  createdBy: "Created By",
  marginFrom: "Margin From",
  marginTo: "Margin To",
  containerNo: "Container #",
  callerbillLandingNo: "Bill of Lading #",
  secondaryReferenceNo: "Reference #",
  bookingNo: "Booking #",
  purchaseOrderNo: "Purchase Order #",
  isPendingLoad: "Pending Load",
  isInProgressLoad: "In Progress Load",
  isCompletedLoad: "Completed Load",
  isCombinedTrip: "Combined Trip",
  pickupFrom: "Pickup From",
  pickupTo: "Pickup To",
  deliveryFrom: "Delivery From",
  deliveryTo:"Delivery To",
  returnFrom:"Return From",
  returnTo:"Return To",
  lastFreeDayFrom: "Last Free Day From",
  lastFreeDayTo: "Last Free Day To",
  cutOffFrom: "Cut Off From",
  cutOffTo: "Cut Off To",
  type_of_load: "Type of Load",
  shipper:updateTerminology("Shipper"),
  consignee: `${updateTerminology("Consignee")}`,
  emptyOrigin: "Return",
  drivers: "Drivers",
  containerTypes: "Container Type",
  containerSizes: "Container Size",
  containerOwners: "Container Owner",
  preSetOrderStatus: "Load Template",
  yardTermination: "Yard Termination",
  yardPickUp: "Yard Pick",
  isReadyForPickup: "Is Ready To Return",
  label:"Label",
  chassisSizes:"Chassis Size",
  chassisTypes:"Chassis Type",
  chassisOwners: "Chassis Owner",
  currentLocation: "Current Location",
  warehouseAddress: "Warehouse Address",
  routeType:"Route Type",
  tagIds: "Tags",
  emailStatus: "Status",
  hasAttachment: "With Attachment",
  unread: "Read/Unread",
  slaRule: "SLAs",
  assignees: "Assigned To",
}
   
export const generateStateViaParams = (view) => {
  try {
  const filters = {};

  const loadFilters = view?.filters;
  if (!loadFilters) return filters

  for (const key of Object.keys(EMAIL_FILTERS_KEY)) {
    if (key === 'tagIds') {
     if (_.has(loadFilters, key)) {

      filters[key] = loadFilters[key] || []
     }
    }
    else if (key === "organizations") {
      if (_.has(loadFilters, key)) {
        filters[key] = loadFilters[key]
          ? makeOptionsForSelect(loadFilters[key], "company_name", null, "_id")
          : [];
      }
    }
    else if (key === 'emailStatus') {
      if (_.has(loadFilters, key)) {
        const data = loadFilters[key]?.map((status) => {
          if (typeof status === "string") {
            const { label, badgeClass } = statusLabels.find(item => item.value === status) || {};
            return { label, value: status, badgeClass , _id:label};
          }
          return status;
        })
        filters[key] = data || []
      }
    }
    else if (["shipper", "consignee", "emptyOrigin"].includes(key)) {
      if (_.has(loadFilters, key)) {
        filters[key] = loadFilters[key]
          ? makeOptionsForSelect(loadFilters[key], "company_name", "address.address", "_id")
          : [];
      }
    } else if (key === "drivers" && _.has(loadFilters, "drivers")) {
      filters[key] =
        loadFilters[key]?.map((d) => {
          let val = {
            label: `${d.name} ${d.lastName}`.trim(),
            value: d._id,
            allInfo: d,
          };
          return val;
        }) || [];
    } else if (key === "branches" && _.has(loadFilters, "branches")) {
      filters[key] = loadFilters[key] ? makeOptionsForSelect(loadFilters[key], "name", "address.address", "_id") : [];
    } else if (key === "people" && _.has(loadFilters, "people")) {
      filters[key] =
        loadFilters[key]?.map((d) => {
          let val = {
            label: `${d.firstName} ${d.lastName}`.trim(),
            value: d._id,
            allInfo: d,
          };
          return val;
        }) || [];
    } 
    else if (key === "myTeam" && _.has(loadFilters, "myTeam")) {
      filters[key] =loadFilters[key]?.map(d => {
        let val = {
          label: `${d.name} ${d?.lastName || ""}`.trim(),
          value:d.fleetManager,
          allInfo:d
        }
        return val;
      }) || [];
    } else if (
      key === "createdBy" && _.has(loadFilters, "createdBy")
    ) {
      filters[key] =
        loadFilters[key]?.map((d) => {
          let val = {
            label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName}`.trim(),
            value: d._id,
            allInfo: d,
          };
          return val;
        }) || [];
    } else if (key === "csr" && _.has(loadFilters, "csr")) {
      filters[key] =
        loadFilters[key]?.map((d) => {
          let val = {
            label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName}`.trim(),
            value: d._id,
            allInfo: d,
          };
          return val;
        }) || [];
    } else if (key === 'unread' && _.has(loadFilters, "unread")) {
      if ([true,false].includes(loadFilters[key])) {
        if (loadFilters[key]) {
          filters[key] = readUnreadStatuses.find(st => st.label === 'Unread')
        } else {
          filters[key] = readUnreadStatuses.find(st => st.label === 'Read')
        }
      } else {
        filters[key] = readUnreadStatuses.find(st => st.label === 'ALL')
      }
    } else if (key === "label" && _.has(loadFilters, "label")) {
        filters[key] = loadFilters[key];
    } else if (
      [
        "marginFrom",
        "marginTo",
        "containerNo",
        "callerbillLandingNo",
        "secondaryReferenceNo",
        "bookingNo",
        "purchaseOrderNo",
        "pickupFrom",
        "pickupTo",
        "deliveryFrom",
        "deliveryTodeliveryTo",
        "returnFrom",
        "returnTo",
        "lastFreeDayFrom",
        "lastFreeDayTo",
        "cutOffFrom",
        "cutOffTo",
        "emailFromDate",
        "emailToDate",
      ].includes(key)
    ) {
      if (dateTypeFields.includes(key) && _.has(loadFilters, key)) {
        filters[key] = moment(loadFilters[key]) 
      } else if (_.has(loadFilters, key)) {
        filters[key] = loadFilters[key] || EMAIL_FILTERS_KEY[key];
      }
    } else if (
      ["isPendingLoad", "isInProgressLoad", "isCompletedLoad", "isCombinedTrip", "isReadyForPickup"].includes(key)
    ) {
      if (_.has(loadFilters, key) && [true, false].includes(loadFilters[key])) {
        filters[key] = loadFilters[key];
      } else {
        filters[key] = EMAIL_FILTERS_KEY[key];
      }
    } else if (key === "preSetOrderStatus" && _.has(loadFilters, "preSetOrderStatus")) {
      let found = getPreSetOrderStatus()?.find((order) => order?.value === loadFilters[key][0]);
      if (found) {
        filters[key] = found;
      } else {
        filters[key] = EMAIL_FILTERS_KEY[key];
      }
    } else if (key === "type_of_load" && _.has(loadFilters, "type_of_load")) {
      filters[key] = [];
      for (const val of loadFilters[key]) {
        let found = LOAD_TYPES?.find((order) => order?.value === val);
        if (found) {
          filters[key].push(found);
        }
      }
    } else if (key === "containerTypes" && _.has(loadFilters, "containerTypes")) {
      filters[key] = loadFilters[key] ? makeOptionsForSelect(loadFilters[key], "name", null, "_id") : [];
    } else if (key === "containerSizes" && _.has(loadFilters, "containerSizes")) {
      filters[key] = loadFilters[key] ? makeOptionsForSelect(loadFilters[key], "name", null, "_id") : [];
    } else if (key === "containerOwners" && _.has(loadFilters, "containerOwners")) {
      filters[key] = loadFilters[key] ? makeOptionsForSelect(loadFilters[key], "company_name", null, "_id") : [];
    } else if (key === "slaRule") {
      let slaRule = []
      SLA_FILTER.forEach((val) => {
        if(loadFilters[key]?.includes(val?.value)) slaRule.push(val)
      })
      filters[key] = slaRule ?? []
    } else if(key === "assignees"){
      filters[key] = loadFilters[key]?.map(d => {
        let val = {
          label: `${d.name} ${d?.lastName || ""}`.trim(),
          value:d._id,
          allInfo:d
        }
        return val;
      }) || [];
    }
    else {
      filters[key] = loadFilters[key] || EMAIL_FILTERS_KEY[key];
    }
  }
  filters.selectedStatus = [];
  for (const key of Object.keys(loadFilters)) {
    if (
      ["hazmat", "routeType", "overWeight", "temperature", "isGenset", "liquor", "officeNote", "isHot", "domestic", 'ev', 'waste','gdp','isRail', "scale"].includes(key)
    ) {
      switch (key) {
        case "hazmat":
          filters.selectedStatus.push("CAUTION_COUNT");
          break;
        case "routeType":
          if (loadFilters[key]?.includes("Highway")) {
            filters.selectedStatus.push("HIGHWAY_COUNT");
          }
          if (loadFilters[key]?.includes("Local")) {
            filters.selectedStatus.push("LOCAL_COUNT");
          }
          break;
        case "routeTypeLocal":
          filters.selectedStatus.push("LOCAL_COUNT");
          break;
        case "overWeight":
          filters.selectedStatus.push("SCALE_COUNT");
          break;
        case "liquor":
          filters.selectedStatus.push("LIQUOR_COUNT");
          break;
        case "isGenset":
          filters.selectedStatus.push("GENSET_COUNT");
          break;
        case "officeNote":
          filters.selectedStatus.push("NOTE_COUNT");
          break;
        case "domestic":
          filters.selectedStatus.push("DOMESTIC_COUNT");
          break;
        case "ev":
          filters.selectedStatus.push("EV_COUNT");
          break;
        case "waste":
          filters.selectedStatus.push("WASTE_COUNT");
          break;
        case "gdp":
          filters.selectedStatus.push("GDP_COUNT");
          break;
        case "isRail":
          filters.selectedStatus.push("IS_RAIL_COUNT");
          break;
        case "isHot":
          filters.selectedStatus.push("HOT_COUNT");
          break;
        case "temperature":
          filters.selectedStatus.push("REEFER_COUNT");
          break;
        case "scale":
          filters.selectedStatus.push("SCALES_COUNT");
          break;
        default:
          break;
      }
    }
  }

  return filters;
} catch (error) {
  console.log("error in generateStateViaParams: ",error)
  return {}
}
};

export const dateTypeFields = [
  "emailFromDate",
  "emailToDate",
  "pickupFrom",
  "pickupTo",
  "deliveryFrom",
  "deliveryTo",
  "returnFrom",
  "returnTo",
  "lastFreeDayFrom",
  "lastFreeDayTo",
  "cutOffFrom",
  "cutOffTo",
];

export const navBarFilters = ["isWithLoadEmail", "isWithoutLoadEmail", "isWithPeopleEmail", "isWithoutPeopleEmail"];

export const fieldsWithExcludeProperties = ['organizations','people',"myTeam","branches"]

export const ignoreQuickFilter = [
  "isExcludeBranches",
  "isExcludeOrganizations",
  "isExcludePeople",
  "isWithLoadEmail",
  "isWithoutLoadEmail",
  "isWithPeopleEmail",
  "isWithoutPeopleEmail",
  "activePage",
  "selectedStatus",
  "isExcludeMyTeam",
  'searchTerm'
];

export const excludeFilters = [
  "isExcludeBranches",
  "isExcludeOrganizations",
  "isExcludePeople",
  "isExcludeMyTeam"
]

export const boolStatuses = [
  "isPendingLoad",
  "isInProgressLoad",
  "isCompletedLoad",
  "isCombinedTrip",
  "isReadyForPickup",
  "hasAttachment"
]

export const viewRelatedTabs = [
"Inbox",
"Important",
"IMPORTANT",
"Sent",
"Starred",
"Deleted Items",
"Sent Items"
]

export const SLA_FILTER = [
  { label: "LOW", value: "low" },
  { label: "MEDIUM", value: "medium" },
  { label: "HIGH", value: "high" },
  { label: "URGENT", value: "urgent" },
];
