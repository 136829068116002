import { useState } from "react";
import { toastr } from "services";
import { addLabelsToEmail } from "../actionCreator";
import { EMAIL_MSG } from "../constant";

export const useAddLabelToMail = (allEmails, setAllEmails, callback, setRowOpen, closeModal) => {
  const [loading, setLoading] = useState(false);
  const onSuccess = (data) => {
    let hasNotFound = data?.notFound?.length > 0;
    let hasSuccess = data?.success?.length > 0;
    if (hasNotFound) {
      const updatedEmails = allEmails.filter(email =>
        !data.notFound.includes(email?.id)
      );
      setAllEmails(updatedEmails);
    }
    if (hasSuccess) {
      const newData = [...allEmails];
      data.success.forEach((d) => {
        const updatedMailIndex = newData.findIndex((email) => email?.id === d?.id);
        if (updatedMailIndex > -1) {
          newData[updatedMailIndex].folders = d?.folders;
        }
      });
      setAllEmails(newData);
      setRowOpen && hasSuccess && setRowOpen(newData.find(email =>
        data.success.some(successEmail => successEmail.id === email.id)
      ));
    }
    if (hasSuccess && hasNotFound) {
      toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE, "success");
      toastr.show("Added Successfully.", "success");
    } else if (hasSuccess) {
      toastr.show("Added Successfully.", "success");
    } else if (hasNotFound) {
      data.notFound.length > 1 ? toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE, "success") : toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_SINGLE, "success");
    } else {
      toastr.show("Something went wrong.", "error");
    }
    if(hasNotFound){
      closeModal && closeModal();
    }
    callback && callback();
  };
  const _addLabelToMail = async (payload) => {
    setLoading(true);
    try {
      const data = await addLabelsToEmail(payload);
      onSuccess(data);
    } catch (err) {
      toastr.show("Something went wrong.", "error");
    }
    setLoading(false);
  };
  return { loading, _addLabelToMail };
};