import { useEffect, useState } from "react";
import { IconLongArrowRight } from "../../../../../../../Components/Common/Icons"
import { toastr } from "../../../../../../../services";

const CompareTextBox = ({ label, value, placeholder, textValue, SaveVal,className, isDisabled=false}) => {
    const [val, setVal] = useState("")
    useEffect(() => {
        setVal(value)
    }, [value])
    return (
        <div className="d-flex align-items-end gap-5">
            <div>
                <label>{label}</label>
                <input className="form-control" type="text" value={textValue} disabled />
            </div>
            <IconLongArrowRight className="h-32px w-20px text-gray-300" />
            <div>
                <label></label>
                <input
                    className={`form-control ${className}`}
                    type="text"
                    placeholder={placeholder ? placeholder : ""}
                    value={val ?? ""}
                    onChange={(e) => {
                        setVal(e.target.value ?? "");
                    }}
                    onBlur={(e) => {
                        if(value===val) return
                        if(label==="Container #"){
                            if (val && !/^[A-Za-z]{4}\d{6,7}$/.test(val)){
                                return toastr.show('Please Enter Correct Container No', "warning");
                              }
                        }
                        SaveVal(val ?? "");
                    }}
                    disabled={isDisabled}
                />
            </div>
        </div>
    )
}

export default CompareTextBox