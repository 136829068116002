import moment from "moment";
import axiosClient from "./axiosClient";
import { getLocationHistory } from "../pages/trucker/Tracking/actionCreators";
import { isNewSupportMovesEnabled } from "./Common.services";
import jsonToQueryParams from "Components/Common/jsonToQueryParams";
import { flatMap } from "lodash";

export const connectEld = async () => {
  return axiosClient().post("eld/connect", {});
};

export const reconnectEld = async ({ tspSource }) => {
  return axiosClient().post("eld/connect", { tspSource });
};

export const disconnectEld = async (tspId) => {
  return axiosClient().post("/eld/disconnect", tspId);
};

export const fetchEldConnections = async (connectionType) => {
  let url = `/eld/fetch-connections`;

  if (connectionType) {
    url += `?connectionType=${encodeURIComponent(connectionType)}`;
  }
  return axiosClient().get(url);
};

export const getELDProfiles = async ({ profileType, tspSource }) => {
  let url = `eld/fetch-eld-profiles?type=${encodeURIComponent(profileType)}`;

  if (tspSource) {
    url += `&tspSource=${encodeURIComponent(tspSource)}`;
  }

  return axiosClient().get(url);
};

export const getELDProfileById = async ({ profileType, eldId }) => {
  return axiosClient().get(`eld/fetch-entity-details?type=${profileType}&eldId=${eldId}`);
};

export const linkEldProfiles = async (payload) => {
  return axiosClient().post(`/eld/update-eld-profiles`, payload);
};

export const disconnectEldProfile = async (payload) => {
  return axiosClient().delete(`/eld/update-eld-profiles`, {
    data: payload,
  });
};

export const updateDataPointsPermissions = async (payload) => {
  return axiosClient().put(`/eld/update-permissions`, payload);
};

export const getEldLocationHistory = async ({ refNo, startDate, endDate } = {}) => {
  let url = `eld/fetch-location-history`;

  const payload = {
    refNo,
  };

  if (startDate && endDate) {
    payload.startDate = startDate;
    payload.endDate = endDate;
  }

  return axiosClient()
    .post(url, payload)
    ?.then((resp) => resp?.data);
};

export const getEldLocationHistoryByEquipment = async ({
  equipmentId = null,
  pageCursor = null,
  startDate,
  endDate
} = {}) => {
  let url = `eld/fetch-vehicle-history`;

  const payload = {
    start_datetime: startDate,
    end_datetime: endDate,
    page_cursor: pageCursor,
    vehicle_id: equipmentId,
  };

  // const payload = {
  //   start_datetime: "2024-04-26T11:51:36.408Z",
  //   end_datetime: "2024-04-27T11:51:36.409Z",
  //   page_cursor: pageCursor,
  //   vehicle_id: "2356333",
  // };

  if (moment(endDate).isAfter(moment())) {
    payload.end_datetime = moment().utc().toISOString();
  }

  return axiosClient()
    .post(url, payload)
    ?.then((resp) => resp?.data);
};

export const getEldLocationHistoryByDriver = async ({
  refNo = [],
  driverId = null,
  pageCursor = null,
  startDate,
  endDate,
  driverUserId = null,
  systemDriverId,
  isEld,
} = {}) => {
  let url = `eld/fetch-driver-history`;

  const payload = {
    start_datetime: startDate,
    end_datetime: endDate,
    page_cursor: pageCursor,
  };

  // if(driverId) {
  //   payload.driver_id = driverId;
  // }

  // if (driverUserId) {
  //   delete payload.driver_id;
  //   payload.driver_userId = driverUserId;
  // }

  if (moment(endDate).isAfter(moment())) {
    payload.end_datetime = moment().utc().toISOString();
  }

  if(systemDriverId) {
    payload.systemDriverId = systemDriverId;
  }

  let response = [];
  
  if(isEld) {
    response = await axiosClient().post(url, payload)?.then((resp) => resp?.data);
  }
  if(!response?.data?.length) {
    const driverTrackingHistoryPayload = { startDate, endDate, driver: systemDriverId ?? driverId};

    if(refNo?.length > 0) driverTrackingHistoryPayload.refNo = refNo;
    
    response = await getLocationHistory(driverTrackingHistoryPayload);
    if(response?.data?.other?.length > 0) response.data = response?.data?.other ?? [];
    else if(response?.data?.data?.length > 0) response.data = flatMap(response?.data?.data?.map(d => d?.locations) ?? []);
    else response.data = [];
  }

  return response;
};

export const getTrackingTrucksFilters = async (filters) => {
  let _filters = {...filters};
  if (isNewSupportMovesEnabled()) {
    _filters.isSupportMove = true;
  }
  return axiosClient()
    .post(`carrier/getAllTrucks`, _filters)
    ?.then((resp) => resp?.data);
};

export const getTrackingTrucks = async () => {
  let url = 'carrier/getAllTrucks';
  if (isNewSupportMovesEnabled()) {
    url = url + "?" + jsonToQueryParams({ isSupportMove: true });
  }
  return axiosClient()
    .get(url)
    ?.then((resp) => resp?.data);
};

export const getTrackingDrivers = async () => {
  let url = 'carrier/getAllDriver';
  if (isNewSupportMovesEnabled()) {
    url = url + "?" + jsonToQueryParams({ isSupportMove: true });
  }
  return axiosClient()
    .get(url)
    ?.then((resp) => resp?.data);
};

export const getTrackingDriversFilters = async (filters) => {
  let _filters = {...filters};
  if (isNewSupportMovesEnabled()) {
    _filters.isSupportMove = true;
  }
  return axiosClient()
    .post(`carrier/getAllDriver`, _filters)
    ?.then((resp) => resp?.data);
};

export const getTrackingFilterLoadByDriver = async ({
  startDate,
  endDate,
  driverId,
}) => {
  if (moment(endDate).isAfter(moment())) {
    endDate = moment().utc().toISOString();
  }

  const loadCriteria = {
    filterLoads: true,
    dateCriteria: { startDate, endDate, },
  };

  if (!driverId) return [];
  let payload = { loadCriteria, driverId, isGenerateSignUrl: true }

  if (isNewSupportMovesEnabled()) {
    payload.isSupportMove = true
  }

  return axiosClient()
    .post(`carrier/getAllDriver`, payload )
    ?.then((resp) => resp?.data);
};

// List of loads for a given truck
export const getTrucksLoadByDateRange = async ({
  startDate,
  endDate,
  equipmentId,
}) => {
  if (!equipmentId) return [];

  if (moment(endDate).isAfter(moment())) {
    endDate = moment().utc().toISOString();
  }

  const loadCriteria = {
    filterLoads: true,
    dateCriteria: {
      startDate: startDate,
      endDate: endDate,
    },
    equipmentId: equipmentId
  };
  let payload = { loadCriteria, isGenerateSignUrl: true }
  if (isNewSupportMovesEnabled()) {
    payload.isSupportMove = true
  }

  return axiosClient()
    .post(`carrier/getAllTrucks`, payload)
    ?.then((resp) => resp?.data);
};

export const fetchLocationHistory = async (payload) => {
  return axiosClient()
    .post(`eld/fetch-location-history`, payload)
    ?.then((resp) => resp?.data);
};

export const fetchETA = async (payload) => {
  const { reference_number, currentLatLng, destinationLatLng, speed } = payload;
  if (!(reference_number && currentLatLng && destinationLatLng && speed)) throw new Error("Incorrect payload!");
  return axiosClient()
    .post(`eld/fetch-eta`, payload)
    ?.then((resp) => resp?.data);
};
