import React, { useContext, useRef, useState } from 'react'
import { EMAIL_CONTEXT, EMAIL_TABS } from '../../../constant'
import TabDropDown from '../GmailSideBar/TabDropDown'
import LabelListPortalWrapper from '../LabelListPortalWrapper'
import { getLabelOrFolder } from '../../../helper'
import { IconAngleArrowTop, IconDownArrow, IconPlus } from '../../../../../../Components/Common/Icons'
import { sortBy } from 'lodash'

const OutlookSideBar = (props) => {
    const {
        setActiveNav,
        activeNav,
        allLabels,
        allCounts,
        isGettingEmails,
        setSelectedRow,
        setShowEmailDetailsModalR,
        setEmailFilters,
        setSearchTerm
    } = props

    const context = useContext(EMAIL_CONTEXT)
    const { resetState } = context

    const [newLabelDrop, setNewLabelDrop] = useState(false)
    const [showLabelsDrop, setShowLabelDrop] = useState(true);

    const addLabelBtnRef = useRef()
    const portalRef = useRef()
    const addLabelModalRef = useRef()

    const handleToggleNewLabelDrop = (value) => {
        setNewLabelDrop(value)
    }

    const handleToggleLabelsDrop = () => {
        setShowLabelDrop((prevState) => !prevState)
    }

    const defaultTabs = [
        { label: EMAIL_TABS.INBOX, count: allLabels.find(e=>e?.name===EMAIL_TABS.INBOX)?.unreadCount||0, _id: allLabels.find(e=>e?.name===EMAIL_TABS.INBOX)?.id||"" },
        { label: EMAIL_TABS.DRAFT, count: allLabels.find(e=>e?.name===EMAIL_TABS.DRAFT)?.totalCount ||0, id: allLabels.find(e=>e?.name===EMAIL_TABS.DRAFT)?.id||"" },
        { label: EMAIL_TABS.SENT ,count: allLabels.find(e=>e?.name===EMAIL_TABS.SENT_ITEMS)?.unreadCount||0,id: allLabels.find(e=>e?.name===EMAIL_TABS.SENT_ITEMS)?.id||"" },
        { label: EMAIL_TABS.DELETED_ITEMS,  count: allLabels.find(e=>e?.name===EMAIL_TABS.DELETED_ITEMS)?.unreadCount||0,id: allLabels.find(e=>e?.name===EMAIL_TABS.DELETED_ITEMS)?.id||"" },
    ];

    const labelData = sortBy(
        allLabels
            ?.filter((label) => !label.systemFolder)
            ?.map((d) => {
                const labelName = d?.name
                const result = {
                    ...d,
                    label: d?.name,
                    count: d.unreadCount
                }
                return result
            }),
        (l) => l.label.toLowerCase()
    )

    return (
        <>
            <div className="d-flex flex-column gap-2 mb-15">
                {defaultTabs?.map((data, index) => {
                    return (
                        <TabDropDown
                            index={index}
                            {...data}
                            setActiveNav={setActiveNav}
                            activeNav={activeNav}
                            resetState={resetState}
                            isGettingEmails={isGettingEmails}
                            setSelectedRow={setSelectedRow}
                            setShowEmailDetailsModalR={setShowEmailDetailsModalR}
                            setEmailFilters={setEmailFilters}
                            setSearchTerm={setSearchTerm}
                            isOutlookProvider={true}
                            {...props}
                        />
                    )
                })}
            </div>
            <div className="d-flex mb-1 email-label">
                <div className="font-14 font-medium pointer" onClick={handleToggleLabelsDrop}>
                    {getLabelOrFolder(true)}
                    {showLabelsDrop ? <IconAngleArrowTop className="ml-2" /> : <IconDownArrow className="ml-2" />}
                </div>
                <button
                    className="btn btn-link btn-sm ml-auto"
                    onClick={() => {
                        handleToggleNewLabelDrop(!newLabelDrop)
                    }}
                    ref={addLabelBtnRef}
                >
                    <IconPlus className="text-primary" />
                </button>
                <LabelListPortalWrapper
                    showDropdown={newLabelDrop}
                    boxRef={portalRef}
                    cellRef={addLabelBtnRef}
                    onClose={handleToggleNewLabelDrop}
                    addLabelModalRef={addLabelModalRef}
                    allLabels={labelData}
                    setActiveNav={setActiveNav}
                />
            </div>
            <div className="d-flex flex-column gap-2 mb-15">
                {showLabelsDrop &&
                    labelData?.map((data, index) => (
                        <TabDropDown
                            key={index}
                            {...data}
                            setActiveNav={setActiveNav}
                            activeNav={activeNav}
                            resetState={resetState}
                            isGettingEmails={isGettingEmails}
                            setSelectedRow={setSelectedRow}
                            setShowEmailDetailsModalR={setShowEmailDetailsModalR}
                            setEmailFilters={setEmailFilters}
                            setSearchTerm={setSearchTerm}
                            isOutlookProvider={true}
                            {...props}
                        />
                    ))}
            </div>
        </>
    )
}

export default OutlookSideBar
