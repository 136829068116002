import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { mapOptionListToProfile } from "../service/profileService";
import { getStorage } from "../../../../services";
import { getCustomerGroupAsyncSearch } from "../service/vendorTariffService";
import { ROUTING_TEMPLATES } from "pages/tms/constant";


export const useRequiredOptions = ({ initialData, onUpdate, formErrors, setFormErrors, setShowDateWrapper, selectedRoutingTemplate, groupedProfilesOptions, isEdit, }) => {
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings"));
  const isTariffForTripEnabled = userBasicSettings?.isTariffForTrip;

  const [isTrip, setIsTrip] = useState(initialData?.isTrip ?? false);
  const [loadType, setLoadType] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [shipperFilter, setShipperFilter] = useState(null);
  const [consigneeFilter, setConsigneeFilter] = useState(null);
  const [returnLocationFilter, setReturnLocationFilter] = useState(null);
  const [terminalsFilter, setTerminalsFilter] = useState(null);
  const [rateRecordName, setRateRecordName] = useState(null);
  const [effectiveStartDate, setEffectiveStartDate] = useState(null)
  const [effectiveEndDate, setEffectiveEndDate] = useState(null)
  const [isApplyPerLoad, setIsApplyPerLoad] = useState(initialData?.isApplyPerLoad ?? false);


  const terminals = useSelector((state) => state.HomeReducer.terminals);

  const loadTypes = [
    { value: "IMPORT", label: "IMPORT" },
    { value: "EXPORT", label: "EXPORT" },
    { value: "ROAD", label: "ROAD" },
  ];

  const tripTypes = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const handleRateRecordName = (e, key) => {
    setRateRecordName(e.target.value)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  }

  const handleEffectiveStartDate = (e, key) => {
    setEffectiveStartDate(e)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
    setShowDateWrapper(null)
  }

  const handleEffectiveEndDate = (e, key) => {
    setEffectiveEndDate(e)
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
    setShowDateWrapper(null)
  }

  const handleLoadTypeChange = (selectedOptions, key) => {
    setLoadType(selectedOptions?.map((e) => e.value));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleTariffTripChange = (value) => {
    setIsTrip(value);
  };

  const handleCustomerListChange = (selectedOptions, key) => {
    setCustomerList(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handlePickUpLocation = (selectedOptions, key) => {
    setShipperFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleDeliveryLocation = (selectedOptions, key) => {
    setConsigneeFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleReturnLocation = (selectedOptions) => {
    setReturnLocationFilter(mapOptionListToProfile(selectedOptions));
  };

  const handleTerminal = (selectedOptions, key) => {
    setTerminalsFilter(mapOptionListToProfile(selectedOptions));
    const _formErrors = { ...formErrors };
    delete _formErrors?.[key];
    setFormErrors(_formErrors);
  };

  const handleIsApplyPerLoad = (isApplyPerLoadValue) => {
    setIsApplyPerLoad(!isApplyPerLoadValue);
  };

  const updatePayload = useMemo(() => {
    return {
      loadType: loadType,
      customers: customerList,
      pickupLocation: shipperFilter,
      deliveryLocation: consigneeFilter,
      returnLocation: returnLocationFilter,
      terminals: terminalsFilter,
      name: rateRecordName,
      effectiveStartDate: effectiveStartDate,
      effectiveEndDate: effectiveEndDate,
      isApplyPerLoad: isApplyPerLoad,
      isTrip,
    };
  }, [
    loadType,
    customerList,
    shipperFilter,
    consigneeFilter,
    returnLocationFilter,
    terminalsFilter,
    rateRecordName,
    effectiveStartDate,
    effectiveEndDate,
    isApplyPerLoad,
    isTrip,
  ]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(updatePayload);
    }
  }, [updatePayload])

  useEffect(() => {
    if (initialData) {
      setLoadType(initialData.loadType);
      setCustomerList(initialData.customers);
      setShipperFilter(initialData.pickupLocation);
      setConsigneeFilter(initialData.deliveryLocation);
      setReturnLocationFilter(initialData.returnLocation);
      setTerminalsFilter(initialData.terminals);
      setRateRecordName(initialData.name);
      setEffectiveStartDate(initialData.effectiveStartDate);
      setEffectiveEndDate(initialData.effectiveEndDate);
      setIsApplyPerLoad(initialData?.isApplyPerLoad)
      setIsTrip(initialData?.isTrip);
    }
  }, [initialData]);

  useEffect(()=>{
    if(ROUTING_TEMPLATES.includes(selectedRoutingTemplate) && !isEdit){
      if(!groupedProfilesOptions.length)return;
      getCustomerGroupAsyncSearch("All Customers", groupedProfilesOptions).then(response=>{
        const found = response.find(res=>res.label==="All Customers");
        handleCustomerListChange([found], "customers")
      })
    }else{
      if(initialData){
        setCustomerList(initialData.customers);
      }
    }
  },[selectedRoutingTemplate])

  return {
    isTrip,
    tripTypes,
    loadType,
    customerList,
    shipperFilter,
    consigneeFilter,
    returnLocationFilter,
    terminalsFilter,
    terminals,
    loadTypes,
    isTariffForTripEnabled,
    handleLoadTypeChange,
    handleCustomerListChange,
    handlePickUpLocation,
    handleDeliveryLocation,
    handleReturnLocation,
    handleTerminal,
    rateRecordName,
    setRateRecordName,
    handleRateRecordName,
    effectiveStartDate,
    effectiveEndDate,
    handleEffectiveStartDate,
    handleEffectiveEndDate,
    isApplyPerLoad,
    handleIsApplyPerLoad,
    handleTariffTripChange,
  };
};
