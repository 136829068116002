import React, { useState, useRef } from 'react'
import { getCustomerCityAsyncSearch, getCustomerCityWithCountryCodeAsyncSearch, getCustomerGroupAsyncSearch } from '../../../service/customerRateService';
import { smallSelectStyle } from '../../../../../../assets/js/select-style';
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { IconCalendar, IconInfoCircleFilledWhite } from '../../../../../../Components/Common/Icons';
import Select from "react-select";
import { useCustomerSelect } from '../../../hooks/useCustomerSelect';
import { useRequiredOptions } from '../../../hooks/useRequiredOptions';
import { convertProfileListToOption, mapOptionListToParameterProfileList } from '../../../service/profileService';
import { RateProfileType } from '../../../constants/rateProfile.types';
import { DateTimeFormatUtils, getStorage, isTerminalTrackingEnabled, isZipcodeWithCountryEnabled, showForTerminal, StatusDropdownWrapper, updateTerminology } from '../../../../../../services';
import DateTimePicker from '../../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateTimePicker';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { createPortal } from 'react-dom';
import { getCustomerCityAsyncSearchWithStatus, getCustomerCityWithCountryCodeAsyncSearchWithStatus } from 'pages/tms/Load/DriverOrderUtility';
import { LOAD_LABELS } from 'labels';

const BasicLeftOption = ({ activeTab, initialData, onUpdate, formErrors, setFormErrors }) => {
    const [showDateWrapper, setShowDateWrapper] = useState(null);
    const inputRef = useRef(null);

    const {groupedProfilesOptions, groupedCitiesOptions, customerFilterOptions, groupedZipCodesOptions,  consigneeFilterOptions, shipperFilterOptions } = useCustomerSelect();
    const timezone = getStorage("timeZone");
    const {
        loadType,
        customerList,
        shipperFilter,
        consigneeFilter,
        returnLocationFilter,
        terminalsFilter,
        terminals,
        loadTypes,
        handleLoadTypeChange,
        handleCustomerListChange,
        handlePickUpLocation,
        handleDeliveryLocation,
        handleReturnLocation,
        handleTerminal,
        rateRecordName,
        setRateRecordName,
        handleRateRecordName,
        effectiveStartDate,
        effectiveEndDate,
        handleEffectiveStartDate,
        handleEffectiveEndDate
    } = useRequiredOptions({
        onUpdate: onUpdate,
        initialData: initialData,
        formErrors,
        setFormErrors,
        setShowDateWrapper,
    });


    const handleShowDatewrapper = (type) => {
        if (showDateWrapper === type) {
            setShowDateWrapper(null);
        } else {
            setShowDateWrapper(type);
        }
    };

    const isBasicStart = showDateWrapper === "basic-start"; 
    const isBasicEnd = showDateWrapper === "basic-end"; 

    const defaultShipperOptions = isTerminalTrackingEnabled() ? [...shipperFilterOptions, ...groupedZipCodesOptions].map(d => ({
        ...d,
        label: <StatusDropdownWrapper label={d?.label} terminal={d?.allInfos } />,
    })) : [...shipperFilterOptions, ...groupedZipCodesOptions];

    const defaultConsigneeOption = isTerminalTrackingEnabled() ? [...consigneeFilterOptions, ...groupedZipCodesOptions].map(d => ({
        ...d,
        label: <StatusDropdownWrapper label={d?.label} terminal={d?.allInfos} />,
    })) : [...consigneeFilterOptions, ...groupedZipCodesOptions];

    const getLocationSearchOptions = (strSearch, types) => {
        const options = { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions };

        if (isTerminalTrackingEnabled()) {
            if (isZipcodeWithCountryEnabled()) {
                return getCustomerCityWithCountryCodeAsyncSearchWithStatus(
                    strSearch,
                    options.groupedProfilesOptions,
                    options.groupedCitiesOptions,
                    options.groupedZipCodesOptions,
                    types
                );
            }
            return getCustomerCityAsyncSearchWithStatus(
                strSearch,
                options.groupedProfilesOptions,
                options.groupedCitiesOptions,
                options.groupedZipCodesOptions,
                types
            );
        }

        if (isZipcodeWithCountryEnabled()) {
            return getCustomerCityWithCountryCodeAsyncSearch(
                strSearch,
                options.groupedProfilesOptions,
                options.groupedCitiesOptions,
                options.groupedZipCodesOptions,
                types
            );
        }
        return getCustomerCityAsyncSearch(
            strSearch,
            options.groupedProfilesOptions,
            options.groupedCitiesOptions,
            options.groupedZipCodesOptions,
            types
        );
    };

    return (
        <div>
            {activeTab === "basic" && (
                <>
                    <div className='d-flex my-10'>
                        <h5>Load Matching Conditions</h5>
                        <span data-tip data-for="charge-group2">
                            <IconInfoCircleFilledWhite className="ml-1" />
                        </span>
                        <ReactTooltip id="charge-group2" type="dark" effect="solid" className="w-350">
                            Load Tariffs define charges that will be automatically be added to Loads. When a Load is created, the system will look for a matching Load Tariff. 
                            Specify one or more conditions which will be used to match this Load Tariff to Loads. If multiple Load Tariffs match a Load, the most specific match will be used.
                        </ReactTooltip>
                    </div>
                    <div className={`form-group ${formErrors["name"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Load Tariff Name
                        </label>
                        <input styles={smallSelectStyle} type="text" className="form-control" placeholder="Enter Tariff Name"
                            onChange={(e) =>
                                handleRateRecordName(e, "name")
                            }
                            value={rateRecordName}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["name"]) ? formErrors["name"]?.map((error, i) => (
                                    <small className="text-danger" key={i}>{error}</small>
                                )) : <small className="text-danger">{formErrors["name"]}</small>
                            }
                        </div>
                    </div>

                    <div className={`form-group ${formErrors["effectiveStartDate"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Effective Start Date
                        </label>
                        <div className="input-wrapper">
                            <input 
                                ref={isBasicStart ? inputRef: null}
                                className={`form-control `} 
                                type="text"  
                                placeholder="Select Date"  
                                onClick={() => handleShowDatewrapper("basic-start")} 
                                value={effectiveStartDate ? moment(effectiveStartDate).format(DateTimeFormatUtils.fullDateFormat()) : ""} />
                            {isBasicStart && createPortal (
                                <DateTimePicker
                                    defaultDate={effectiveStartDate}
                                    onChange={(e) => {
                                        handleEffectiveStartDate(e, "effectiveStartDate")
                                    }}
                                    OnBlurUpdate={() =>  {
                                        setShowDateWrapper(false);
                                    }}
                                    displayTimeZone={timezone}
                                    hideShowSelectDate={true}
                                    popupPlacement="auto"
                                    datePickerCellRef={inputRef}
                                    inputRef={inputRef}
                                />, document.getElementById("pp-overlay-container")
                            )}
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["effectiveStartDate"]) ? formErrors["effectiveStartDate"]?.map((error, i) => (
                                    <small className="text-danger" key={i}>{error}</small>
                                )) : <small className="text-danger">{formErrors["effectiveStartDate"]}</small>
                            }
                        </div>
                    </div>

                    <div className={`form-group ${formErrors["effectiveEndDate"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Effective End Date
                        </label>
                        <div className="input-wrapper">
                            <input 
                                ref={isBasicEnd ? inputRef : null}
                                className={`form-control `} 
                                type="text" 
                                placeholder="Select Date"  
                                onClick={() => handleShowDatewrapper("basic-end")} 
                                value={effectiveEndDate ? moment(effectiveEndDate).tz(timezone).format(DateTimeFormatUtils.fullDateFormat()) : ""} 
                            />
                            {isBasicEnd && createPortal (
                                <DateTimePicker
                                    defaultDate={effectiveEndDate}
                                    onChange={(e) =>
                                        handleEffectiveEndDate(e, "effectiveEndDate")
                                    }
                                    OnBlurUpdate={() =>  {
                                        setShowDateWrapper(null);
                                    }}
                                    displayTimeZone={timezone}
                                    hideShowSelectDate={true}
                                    popupPlacement="auto"
                                    datePickerCellRef={inputRef}
                                    inputRef={inputRef}
                                />, document.getElementById('pp-overlay-container')
                            )}
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["effectiveEndDate"]) ? formErrors["effectiveEndDate"]?.map((error, i) => (
                                    <small className="text-danger" key={i}>{error}</small>
                                )) : <small className="text-danger">{formErrors["effectiveEndDate"]}</small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <label>
                            <span className="text-danger">*</span> {LOAD_LABELS.LOAD_TYPE}
                        </label>
                        <Select
                            styles={smallSelectStyle}
                            isMulti
                            options={loadTypes}
                            value={loadType?.map((e) => {
                                return {
                                    label: e,
                                    value: e,
                                };
                            })}
                            onChange={(selectedOptions) =>
                                handleLoadTypeChange(selectedOptions, "loadType")
                            }
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["loadType"]) ? formErrors["loadType"]?.map((error, i) => (
                                    <small className="text-danger" key={i}>{error}</small>
                                )) : <small className="text-danger">{formErrors["loadType"]}</small>
                            }
                        </div>
                    </div>

                    {showForTerminal() && (
                        <div className="form-group">
                            <label>
                                <span className="text-danger">*</span> {updateTerminology("terminal")}
                            </label>
                            <Select
                                value={convertProfileListToOption(terminalsFilter)}
                                styles={smallSelectStyle}
                                isMulti
                                placeholder={`Select The ${updateTerminology("terminal")}`}
                                onChange={(selectedOptions) =>
                                    handleTerminal(selectedOptions, "terminals")
                                }
                                name="choosenTerminals"
                                options={
                                    terminals && terminals.length > 0
                                        ? mapOptionListToParameterProfileList(terminals, 'name', '_id', RateProfileType.TERMINAL)
                                        : []
                                }
                                classNamePrefix="select"
                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                            />
                            <div className='d-flex flex-column'>
                                {
                                    Array.isArray(formErrors["terminal"]) ? formErrors["terminal"]?.map((error, i) => (
                                        <small className="text-danger text-capitalize" key={i}>{error}</small>
                                    )) : <small className="text-danger text-capitalize">{formErrors["terminal"]}</small>
                                }
                            </div>
                        </div>
                    )}
                    <div className="form-group">
                        <label>
                            <span className="text-danger">*</span> {LOAD_LABELS.CUSTOMER}
                        </label>
                        <AsyncSelect
                            cacheOptions
                            styles={smallSelectStyle}
                            placeholder={`Select Customer And Customer Group`}
                            defaultOptions={customerFilterOptions}
                            loadOptions={(strSearch) =>
                                getCustomerGroupAsyncSearch(strSearch, groupedProfilesOptions)
                            }
                            isMulti
                            value={convertProfileListToOption(customerList)}
                            onChange={(selectedOptions) =>
                                handleCustomerListChange(selectedOptions, "customers")
                            }
                            isClearable={true}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["customer"]) ? formErrors["customer"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["customer"]}</small>
                            }
                        </div>

                    </div>
                    <div className="form-group">
                        <label>
                            <span className="text-danger">*</span> {LOAD_LABELS.PICK_UP_LOCATION}
                        </label>
                        <AsyncCreatableSelect
                            defaultOptions={defaultShipperOptions}
                            loadOptions={(strSearch) => getLocationSearchOptions(strSearch, ['ALL', 'shipper'])}
                            isMulti={true}
                            placeholder="Select The Pick Up Location"
                            styles={smallSelectStyle}
                            value={isTerminalTrackingEnabled() ? 
                                convertProfileListToOption(shipperFilter).map(d => ({
                                    ...d,
                                    label: <StatusDropdownWrapper label={d?.label} terminal={d?.parameterProfile} />
                                })) : convertProfileListToOption(shipperFilter)
                            }
                            onChange={(selectedOptions) =>
                                handlePickUpLocation(selectedOptions, "pickupLocation")
                            }
                            formatCreateLabel={(inputValue) => `Add "${inputValue}" Zip/Postal Code`}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["pickupLocation"]) ? formErrors["pickupLocation"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["pickupLocation"]}</small>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <label style={{ height: "15px" }}>
                            <span className="text-danger">*</span> {LOAD_LABELS.DELIVERY_LOCATION}
                        </label>
                        <AsyncCreatableSelect
                            defaultOptions={defaultConsigneeOption}
                            loadOptions={(strSearch) => getLocationSearchOptions(strSearch, ['ALL', 'consignee'])}
                            isMulti
                            placeholder="Select The Delivery Location"
                            styles={smallSelectStyle}
                            value={isTerminalTrackingEnabled() ? 
                                convertProfileListToOption(consigneeFilter).map(d => ({
                                    ...d,
                                    label: <StatusDropdownWrapper label={d?.label} terminal={d?.parameterProfile} />
                                })) : convertProfileListToOption(consigneeFilter)
                            }
                            onChange={(selectedOptions) =>
                                handleDeliveryLocation(selectedOptions, "deliveryLocation")
                            }
                            formatCreateLabel={(inputValue) => `Add "${inputValue}" Zip/Postal Code`}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["deliveryLocation"]) ? formErrors["deliveryLocation"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["deliveryLocation"]}</small>
                            }
                        </div>

                    </div>
                    <div className="form-group">
                        <label>{LOAD_LABELS.RETURN_LOCATION}</label>
                        <AsyncCreatableSelect
                            defaultOptions={defaultShipperOptions}
                            cacheOptions
                            isMulti
                            placeholder={`Select The ${LOAD_LABELS.RETURN_LOCATION}`}
                            styles={smallSelectStyle}
                            value={isTerminalTrackingEnabled() ? 
                                convertProfileListToOption(returnLocationFilter).map(d => ({
                                    ...d,
                                    label: <StatusDropdownWrapper label={d?.label} terminal={d?.parameterProfile} />
                                })) : convertProfileListToOption(returnLocationFilter)
                            }
                            onChange={handleReturnLocation}
                            loadOptions={(strSearch) => getLocationSearchOptions(strSearch, ['ALL', 'containerReturn', 'shipper'])}
                            formatCreateLabel={(inputValue) => `Add "${inputValue}" Zip/Postal Code`}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["returnLocation"]) && formErrors["returnLocation"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                ))
                            }
                        </div>
                    </div>


                </>

            )}

            {activeTab === "advanced" && (
                <>
                    <div className='d-flex my-10'>
                        <h5>Load Matching Conditions</h5>
                        <span data-tip data-for="advanced">
                            <IconInfoCircleFilledWhite className="ml-1" />
                        </span>
                        <ReactTooltip id="advanced" type="dark" effect="solid" className="w-350">
                            <span className='text-capitalize'>
                            Load Tariffs define charges that will be automatically be added to Loads. When a Load is created, the system will look for a matching Load Tariff.
                            Specify one or more conditions which will be used to match this Load Tariff to Loads. If multiple Load Tariffs match a Load, the most specific match will be used.
                            </span>
                        </ReactTooltip>
                    </div>
                    <div className={`form-group ${formErrors["name"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Load Tariff Name
                        </label>
                        <input styles={smallSelectStyle} type="text" className="form-control" placeholder="Enter Tariff Name"
                            onChange={(e) =>
                                handleRateRecordName(e, "name")
                            }
                            value={rateRecordName}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["name"]) ? formErrors["name"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["name"]}</small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <label>
                            <span className="text-danger">*</span> {LOAD_LABELS.LOAD_TYPE}
                        </label>
                        <Select
                            styles={smallSelectStyle}
                            isMulti
                            options={loadTypes}
                            value={loadType?.map((e) => {
                                return {
                                    label: e,
                                    value: e,
                                };
                            })}
                            onChange={(selectedOptions) =>
                                handleLoadTypeChange(selectedOptions, "loadType")
                            }
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["loadType"]) ? formErrors["loadType"]?.map((error, i) => (
                                    <small className="text-danger" key={i}>{error}</small>
                                )) : <small className="text-danger">{formErrors["loadType"]}</small>
                            }
                        </div>
                    </div>

                    <div className="form-group">
                        <label>
                            <span className="text-danger">*</span> {LOAD_LABELS.CUSTOMER}
                        </label>
                        <AsyncSelect
                            cacheOptions
                            styles={smallSelectStyle}
                            placeholder="Select Customer And Customer Group"
                            defaultOptions={customerFilterOptions}
                            loadOptions={(strSearch) =>
                                getCustomerGroupAsyncSearch(strSearch, groupedProfilesOptions)
                            }
                            isMulti
                            value={convertProfileListToOption(customerList)}
                            onChange={(selectedOptions) =>
                                handleCustomerListChange(selectedOptions, "customers")
                            }
                            isClearable={true}
                        />
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["customers"]) ? formErrors["customers"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["customers"]}</small>
                            }
                        </div>
                    </div>

                    <div className={`form-group ${formErrors["effectiveStartDate"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Effective Start Date
                        </label>
                        <div className="input-wrapper">
                            <input 
                                ref={isBasicStart ? inputRef : null}
                                className={`form-control `} 
                                type="text" 
                                placeholder="Select Date" 
                                onClick={() => handleShowDatewrapper("basic-start")} 
                                value={effectiveStartDate ? moment(effectiveStartDate).tz(timezone).format(DateTimeFormatUtils.fullDateFormat()) : ""} 
                            />
                            {isBasicStart && createPortal (
                                <DateTimePicker
                                    defaultDate={effectiveStartDate}
                                    onChange={(e) => {
                                        handleEffectiveStartDate(e, "effectiveStartDate")
                                    }
                                    }
                                    displayTimeZone={timezone}
                                    hideShowSelectDate={true}
                                    popupPlacement="auto"
                                    inputRef={inputRef} 
                                    datePickerCellRef={inputRef}
                                />, document.getElementById("pp-overlay-container")
                            )}
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["effectiveStartDate"]) ? formErrors["effectiveStartDate"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["effectiveStartDate"]}</small>
                            }
                        </div>
                    </div>

                    <div className={`form-group ${formErrors["effectiveEndDate"] && "error"}`}>
                        <label>
                            <span className="text-danger">*</span> Effective End Date
                        </label>
                        <div className="input-wrapper">
                            <input 
                                ref={isBasicEnd ? inputRef: null}
                                className={`form-control `} 
                                type="text" 
                                placeholder="Select Date" 
                                onClick={() => handleShowDatewrapper("basic-end")} 
                                value={effectiveEndDate ? moment(effectiveEndDate).tz(timezone).format(DateTimeFormatUtils.fullDateFormat()) : ""} 
                            />
                            {isBasicEnd && createPortal (
                                <DateTimePicker
                                    defaultDate={effectiveEndDate}

                                    onChange={(e) =>
                                        handleEffectiveEndDate(e, "effectiveEndDate")
                                    }
                                    OnBlurUpdate={() => setShowDateWrapper(null)}
                                    displayTimeZone={timezone}
                                    hideShowSelectDate={true}
                                    popupPlacement="auto"
                                    inputRef={inputRef} 
                                    datePickerCellRef={inputRef}
                                />, document.getElementById("pp-overlay-container")
                            )}
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        </div>
                        <div className='d-flex flex-column'>
                            {
                                Array.isArray(formErrors["effectiveEndDate"]) ? formErrors["effectiveEndDate"]?.map((error, i) => (
                                    <small className="text-danger text-capitalize" key={i}>{error}</small>
                                )) : <small className="text-danger text-capitalize">{formErrors["effectiveEndDate"]}</small>
                            }
                        </div>
                    </div>


                    {showForTerminal() && (
                        <div className="form-group">
                            <label>
                                <span className="text-danger">*</span> {updateTerminology("terminal")}
                            </label>
                            <Select
                                value={convertProfileListToOption(terminalsFilter)}
                                styles={smallSelectStyle}
                                isMulti
                                placeholder={`Select The ${updateTerminology("terminal")}`}
                                onChange={(selectedOptions) =>
                                    handleTerminal(selectedOptions, "terminals")
                                }
                                name="choosenTerminals"
                                options={
                                    terminals && terminals.length > 0
                                        ? mapOptionListToParameterProfileList(terminals, 'name', '_id', RateProfileType.TERMINAL)
                                        : []
                                }
                                classNamePrefix="select"
                                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                            />
                            <div className='d-flex flex-column'>
                                {
                                    Array.isArray(formErrors["terminals"]) ? formErrors["terminals"]?.map((error, i) => (
                                        <small className="text-danger text-capitalize" key={i}>{error}</small>
                                    )) : <small className="text-danger text-capitalize">{formErrors["terminals"]}</small>
                                }
                            </div>
                        </div>
                    )}
                </>

            )}
        </div>
    )
}

export default BasicLeftOption;