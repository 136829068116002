import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

const CustomTooltip = (props) => {
  const { isOpen, refNo, color, className, children, isArrow = true, placement="top", offset = [0, -50], overflowContent = false } = props;
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } =
    usePopper(refNo, popperElement, {
      placement: placement, // Adjust this based on your preference
      modifiers: [
        {
          name: "offset",
          options: {
            offset: offset, // Adjust offset as needed
          },
        },
        {
          name: 'arrow',
           options: {
             element: arrowElement,
          },
        },
      ],
    }) || {};
    const arrowPlacement = attributes?.popper?.['data-popper-placement'] || placement;


  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className={`tooltip tooltip--${color ?? "gray-700"} bs-tooltip-${arrowPlacement?.split('-')[0]} portal-tooltip ${ className || ""}`}
            ref={setPopperElement}
            style={styles?.popper}
            {...(attributes?.popper || {})}
          >
            <div 
              className="tooltip__content" 
              style={overflowContent ? { 
                overflowY: "hidden", 
                maxHeight: "calc(100vh - 40px)" 
              } : {}}
            >
              {children}
            </div>

            {isArrow && (
                    <div
                        ref={setArrowElement}
                        className="arrow"
                        style={styles?.arrow}
                        />
                )}
          </div>,
          document.querySelector("#poper-dest")
        )}
    </>
  );
};
export default CustomTooltip;
