import React, { useMemo, useState } from "react";
import ReactTooltip from "react-tooltip";
import { IconCalendar } from "Components/Common/Icons";
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker-edited";
import { DateTimeFormatUtils } from "services/Common.services";
import { getRanges } from "../../../../constant";
import { useMap } from "react-leaflet";
import { useTrackingHistoryLive } from "pages/trucker/Tracking/context/TrackingContext";

const DatetimeRangePickerContainer = ({
  startDateTime,
  endDateTime,
  onApply = () => {},
  isHistoricalPlaying = false,
}) => {
  const map = useMap();
  const stateHistoryLive = useTrackingHistoryLive();
  const [locale, __] = useState({ format: DateTimeFormatUtils.fullDateFormat() });
  const RANGES = getRanges();
  const stopZoomOnDblClick = () => {
    if (map) map.doubleClickZoom.disable();
  };

  const fromToDateFormatter = () => {
    if (!startDateTime) {
      return "...";
    }

    return `${startDateTime && startDateTime.format(DateTimeFormatUtils.fullDateFormat())} to ${
      endDateTime && endDateTime.format(DateTimeFormatUtils.fullDateFormat())
    }`;
  };

  const toolTipLabel = useMemo(() => {
    if (stateHistoryLive?.isFollowLiveEntity) return "Not allowed on live view";
    return "";
  }, [stateHistoryLive?.isFollowLiveEntity]);

  const handleIconCalendarClick = (e) => {
    if (stateHistoryLive?.isFollowLiveEntity) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  };

  return (
    <div className="input-wrapper mr-10 pointer">
      <DatetimeRangePicker
        timePicker
        ranges={RANGES}
        drops="up"
        utc={false}
        startDate={startDateTime}
        endDate={endDateTime}
        locale={locale}
        onApply={(e, picker) => {
          if (picker?.chosenLabel === 'Custom Range') {
            let monthsDifference = picker?.endDate?.diff(picker?.startDate, 'months', true);
            if (monthsDifference > 1) return;
          }
          onApply({ startDate: picker.startDate, endDate: picker.endDate });
        }}
        onDoubleClick={stopZoomOnDblClick}
      >
        <div className="input-wrapper" onClick={handleIconCalendarClick}>
          <input
            type="text"
            className="form-control pointer"
            style={{ minWidth: "250px" }}
            value={fromToDateFormatter()}
            readOnly
            data-tip={toolTipLabel}
            data-for="historical-date-filter"
            disabled={stateHistoryLive?.isFollowLiveEntity}
          />
          <span className="input-icon pointer-none" data-tip={toolTipLabel} data-for="historical-date-filter">
            <IconCalendar />
          </span>
          <ReactTooltip effect="solid" id="historical-date-filter" />
        </div>
      </DatetimeRangePicker>
    </div>
  );
};

export default DatetimeRangePickerContainer;
