export const smallSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 5,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "7.5px 5px",
    margin: "5px 10px",
    borderRadius: 5,
    maxWidth: "100%",
    width: "auto"
  }),
};
export const smallSelectStyleWhite = {
  control: (base, state) => ({
    ...base,
    minHeight: 32,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "0px 1px 3px rgba(20, 28, 52, 0.2)",
    transition: "background-color .4s ease-out !important",
    border: "none",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 32,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

export const smallSelectPlaceHolderStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    // paddingLeft: "25px",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
  singleValue: (provided) => ({
    ...provided,
    background: 'transparent', // Set the background color to transparent
  }),
  option: (provided, state) => ({
    ...provided,
    background: !state.isDisabled && state.isFocused ? '#f0f0f0' : 'transparent', // Change the background color on hover
    cursor: 'pointer', // Show the pointer cursor on hover
  }),
};

export const mediumSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 40,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

export const newSmallSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    border: "none",
    // borderColor: "#BFCCDA",
    backgroundColor: "none",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
      border: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
      border: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
    color: "#172A41",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    width:0,
    backgroundColor: "#BFCCDA",
  }),
};

export const newXlSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

export const newSmSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 28,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 28,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 28,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

export const tableSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 28,
    height: "100%",
    backgroundColor: 'transparent',
    borderRadius: "3px",
    boxShadow: state.menuIsOpen ? "0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;" : "none", // Add box shadow when menu is open
    border: "none",
    "&:hover": {
      border: "none"
    },
    "&:focus": {
      border: "none",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
    },
    "&:active": {
      border: "none",
    },
  }),
  container: (provided) => ({
    ...provided,
    width: '100%', 
    height: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#A3B3C6", // Set the desired placeholder color here
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 28,
    zIndex: 5,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 28,
    color: "#394E66",
    alignSelf: "center",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none"
  }),
};

export const templateSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
    height: 172
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

export const loginSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 56,
    borderColor: "#DBE2EB",
    borderRadius: "12px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    minHeight: 56,
    paddingLeft: 20, 
    paddingRight: 20, 
    paddingTop: 16, 
    paddingBottom: 16,
    transition: "all 0.4s ease-in-out",
    ...(state.isHovered && {
      borderColor: "#687d96",
      boxShadow: "none",
    }),
    ...(state.isFocused && {
      border: "1px solid #3640F5",
      boxShadow: "none",
    }),
    ...(state.isActive && {
      border: "1px solid #3640F5",
      boxShadow: "none",
    }),
    "& input": {
      height: "auto"
    }
  }),
  menu: (base) => ({
    ...base,
    
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "auto",
    color: "#394E66",
  }),
  indicatorContainer: (base) => ({
    ...base,
    padding: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    color: "#394E66",
    svg: {
      width: 20,
      color: "#152F62",
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    display: "none"
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#879AAF",
    fontSize: 14, 
    margin: 0, 
    lineHeight: "22px"
  }),
  multiValue: (base) => ({
    ...base, 
    background: "transparent",
    border: "1px solid #D0D5DD",
    borderRadius: 6,
    margin: 0,
    lineHeight: "20px", 
    multiValueGeneric: (base) => ({
      ...base, 
      paddingLeft: 5, 
      paddingTop: 1,
      paddingBottom: 1,
      fontSize: "14px"
    })
  }),
  valueContainer: (base) => ({
    ...base, 
    padding: 0, 
    margin: 0, 
    gap: 8
  }), 
  input: (base) => ({
    ...base, 
    margin: 0, 
    padding: 0,
    lineHeight: "22px"
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    
  }),
  

};

export const smallSelectStyleLarge = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    transition: "background-color .4s ease-out !important",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 5,
  }),
  menuList: (base) => ({
    ...base,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 2
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "7.5px 5px",
    margin: "5px 10px",
    borderRadius: 5,
    maxWidth: "100%",
    width: "auto",
  }),
};
