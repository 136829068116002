import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";

const ChangeStatusPortal = ({ CellRef, onClose, onSelect, setIsAssignInProgress, Status }) => {
	const searchStatus = (search) => {
		let status = Status
		if (search !== null) {
			status = status.filter(s => {
				return (
					s.label
						.toLowerCase()
						.indexOf(search.toLowerCase().trim()) != -1
				);
			})
		}
		return status
	}
	const [search, setSearch] = useState(null)
	const [cursor, setCursor] = useState(null)
	const [statusList, setStatusList] = useState(searchStatus(search));
	const searchInputRef = React.useRef(null);
	
	const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)
	
	useEffect(() => {
		searchInputRef.current.focus();
		window.document.getElementById('root').style.pointerEvents = "none"
		return () => {
			window.document.getElementById('root').style.pointerEvents = "auto"
		}
	}, [])
	
	useOnClickOutside(boxRef, () => onClose(false))
	
	useEffect(() => {
		const _search = searchStatus(search);
		setStatusList(_search);
	}, [search]);

	const handleKeyDown = (event) => {
		if (event.keyCode === 38) {
			if (cursor >= 0) {
				setCursor(cursor - 1)
				document.getElementById(`status-${cursor}`).focus();
			}
			event.preventDefault();
		} else if (event.keyCode === 40) {
			if (cursor <= Status.length - 1) {
				if (cursor === null) {
					setCursor(0);
				} else {
					setCursor((prev) => prev + 1)
					document.getElementById(`status-${cursor}`).focus();
				}
			}
			event.preventDefault();
		} else if (event.keyCode === 13) {
			statusList.forEach((val, index) => {
				if (index === cursor) onSelect(val.value);
			});
			event.preventDefault();
		} else if (event.keyCode === 9 || event.keyCode === 27) {
			onClose(false)
			event.preventDefault();
			event.stopPropagation();
		}
	}

	return createPortal(
		<div ref={boxRef}
      data-testid="change-status-portal"
    className="card card--shadow-5  mb-0 p-10 text-left z-1 w-200" style={Style}>
			<div className="app-search bg-gray-50 rounded-lg mb-10">
				<input
					className="input-search-left form-control py-2"
					placeholder="Search Status..."
					id="domTextElement"
					onChange={(e) => setSearch(e.target.value)}
					onKeyDown={(event) => { handleKeyDown(event) }}
					ref={searchInputRef}
				/>
				<span className="search-icon ml-1"></span>
			</div>
			<ul
				className="driverList m-0"
				style={{ maxHeight: "200px" }}
			>
				{statusList.length === 0 ? (<p className="p-1">No status available</p>) : statusList.map((el, index) => (
					<li key={index}
						data-testid={`status-${el.value}`}
						className={`popdrop__dropdown-item ${cursor === index
								? "bg-gradient-light"
								: null
							}`}
						id={`status-${index}`}
						style={{ height: '30px' }} onKeyDown={(e) => handleKeyDown(e)} onClick={() => onSelect(el.value)}>{el.label}</li>
				))}
			</ul>
		</div>,
		document.getElementById('pp-overlay-container')
	);
};

export default ChangeStatusPortal;
