import moment from 'moment';
import React, { Component, Fragment, useEffect, useRef, useState } from 'react'
import _ from "lodash";
import Select from "react-select";
import { browserHistory } from 'react-router';
import { deleteNewDriverPay, fetchUsers, getTerminal, manualDriverPay } from '../../../pages/tms/services';
import {DateTimeFormatUtils, approveNewDeduction, Currency, displayName, getCarrierByUserId, getDriverCurrency, getDriverOrderLocation, getNewTranscation, getPlace, getPricingSettingsOptions, getStorage, isMilitaryTime, makeOptionsForSelect, showForCompany, showForTerminal, toastr, updateNewTranscation, isGlobalizationEnabled, hasVATPermissionForUser, amplitudeTrack, checkAccountPayablePermission, isStrictDriverPayTypes, isNegativeDriverPayAllowed, isDriverPayChargeCodeEnabled, filterChargeCodesByScreenType, updateTerminology } from '../../../services'
import { IconAngleArrowDown, IconCalendar, IconDelete, IconPlus, IconSaveAsDefault, IconTrash } from '../../Common/Icons';
import configuration from '../../../config'
import confirm from '../../Common/ConfirmAert';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import {  smallSelectStyle } from "../../../assets/js/select-style";

import { getAllLoadsStatus } from '../../../pages/tms/carrier/Transcation/actionCreator';
import { LoaderBar } from '../../Common/LoaderBar';
import { changeWeek } from '../../../services';
import { CustomIconAddUser } from '../../Common/CustomIcons/Index';
import NumberFormater, { NumberFormatterV2 } from './Components/NumberFormater';
import { createDriverPayStatus, deleteDriverPayStatus } from '../actions'; 
import { getUserById } from '../../FetchData/actionCreators';
import Avatar from '../../Common/Avatar';
import SelectCustomComponent from '../../../pages/tms/SelectComponent';
import DeleteCell from './Components/DeleteCell';
import { getDrivers } from '../../Common/FloatingChat/actionCreators';
import RedirectToRates from './Components/RedirectToRates';
import RedirectToSettlements from './Components/RedirectToSettlements';
import DateWrapper from '../../../pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper';
import DatePortal from '../../../pages/tms/NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal';
import BillingSummaryCountCard from 'Components/Common/BillingSummaryCountCard';
import ExpensesTable from './DriverPayExpensesComponent/ExpensesTable';
import { tariffType, vendorType } from 'pages/tms/VendorTariffs/constants/vendorType';
import VendorLoadTariffModal from 'pages/tms/VendorTariffs/Components/VendorLoadTariffModal/VendorLoadTariffModal';
import RedirectToChargeProfiles from './Components/RedirectToChargeProfiles';
import AddRuleModal from "pages/tms/VendorTariffs/Components/AddRule/addRuleModal.js";
import { editChargeProfile, getTarrifChargeProfilesById } from "pages/tms/VendorTariffs/service/chargeProfileService.js";

const firebase = configuration.firebase;

const pricingSettingsOptions = getPricingSettingsOptions();
const newDriverPay = {
    driver: null,
    from: null,
    to: null,
    amount: null,
    status: null,
    date: null,
    containerNo:'',
}
const sortingOptions = {
    Terminal: "terminal.name",
    Load: "reference_number",
    Time: "time",
    Owner: "fleetTruckOwner.company_name",
    Truck: "truck.equipmentID",
    Driver: "createdBy.name",
    Amount: "calculateAmount",
    "Load Status": "renderStatus",
    From: "from",
    To: "to",
};

const VAT_DEFAULT_TYPE = [
    {label: "APPLICABLE", value: 'APPLICABLE'},
    {label: "EXEMPT", value: 'EXEMPT'}
]

class NewDriverPay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transcations: [],
            allDrivers: [],
            startDate: null,
            endDate: null,
            from: null,
            to: null,
            showSearch: true,
            order: -1,
            searchTerm: props.searchTerm ?? "",
            terminals: [],
            allLoadStatus: [],
            terminal: null,
            driverPayTerminal: null,
            filterStatus: [],
            selectedLoad: undefined,
            sort: undefined,
            asce: true,
            isLoadingBarShow: false,
            choosenTerminals: [],
            fromDate: moment(),
            toDate: moment(),
            isSaving: false,
            isStatusHovered: null,
            selectedDate: moment(),
            isTerminal: false,
            missingDocType: [],
            isRequied: "transition-white-field",
            isFailed: "transition-white-field",
            isApiCall: false,
            newDriverPay: newDriverPay,
            address: [],
            filterFrom: false,
            filterTo: false,
            rowIndex: null,
            DateAndTime: "",
            selectedCell:null,
            driverQuery: null,
            columnHeader: [
                "Driver",
                "Status",
                "From",
                "To",
                "Amount",
                "Time",
            ],
            isVatEnabled: JSON.parse(getStorage("userBasicSettings"))?.isVAT,
            selectedTab: props?.selectedTab ?? "driver-pay",
            reloadBillingSummary: 1,
            driverTariffModelId: null,
            tariffReference: null,
            selectedChargeCode: null,
            chargeProfileDetails: null,
        };
        this.wrapperRef = React.createRef();

        const methodsToBind = [
            'handleClickOutside',
            'handleOnChange',
            'handleChargeCodeChange',
            'unselectCell',
            'removeStatus',
            'getTranscation',
            'deleteNewDriverPay',
            'approveDeduction',
            'selectCell',
            'addManual'
        ];
    
        methodsToBind.forEach(method => {
            if (typeof this[method] === 'function') {
                this[method] = this[method].bind(this);
            } else {
                console.warn(`Warning: Method ${method} is not defined but trying to bind`);
            }
        });
        this.userTimeZone = getStorage("timeZone") || moment.tz.guess()
        this.userTimeFormat = isMilitaryTime();
        this.isVATPermission = hasVATPermissionForUser()
        this.chargeCodeList = []
        if(this.props.chargeCode?.length) {
            this.chargeCodeList = _.orderBy(filterChargeCodesByScreenType("payable", this.props.chargeCode), "orderIndex", "asc");
        }
        this.chargeCodeOptions= this.chargeCodeList?.filter(d => d?.isActive)?.map(d=>({label:d?.chargeName,value:d?._id }));

        this.acceptNegativeNumber = isNegativeDriverPayAllowed();
        this.regex = this.acceptNegativeNumber ? /^[-]?[0-9\.]*$/ : /[0-9.]/;
    }

    static getDerivedStateFromProps(props, prevState) {
        if(!props.selectedTab && !props.searchTerm) return null;
        if(props.selectedTab === prevState.selectedTab && props.searchTerm === prevState.searchTerm) return null;

        return {
            ...prevState,
            selectedTab: props.selectedTab,
            searchTerm: props.searchTerm ?? "",
        };
    }

    getDriverChargeProfileDetails = async (details) => {
        const { chargeProfileId, driverTariffId } = details ?? {};

        try {
            const result = await getTarrifChargeProfilesById({id: chargeProfileId, vendorType: vendorType.Driver.toLowerCase()});

            this.setState({ chargeProfileDetails: result });
        } catch (error) {
            console.error(error);
            
            const { status } = error ?? {};
            if(status === 404 && driverTariffId) {
                this.setState({ 
                    chargeProfileDetails:null, 
                    driverTariffModelId: driverTariffId
                });
            }
        }
    }


    handleClickOutside(event) {
        if (
            this.wrapperRef?.current &&
            !this.wrapperRef?.current.contains(event.target) &&
            (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
        ) {
            if (this.state.addDriverPay) {
                if (this.state.isDateSelect || this.state.ShowDriverList) {
                    this.setState({ isDateSelect: false, ShowDriverList: false },()=>{
                        this.addManual()
                    })
                } else {
                    this.addManual()
                }
            }
        }
    }

    closeDriverTariffModal() {
        this.setState({ driverTariffModelId: null });
    }

    closeChargeProfileModal = () => {
        this.setState({ chargeProfileDetails: null });
    }

    redirectToRates = (payItem) => {
        if (payItem?.paymentType === tariffType.DRIVER) {
            this.setState({ driverTariffModelId: payItem?.dynamicDriverPayId })
        } else if (payItem.driverPayRateId) {
            if(this.props.closeCustomerServiceSideBar) this.props.closeCustomerServiceSideBar();
            browserHistory.push('/tms/driver-pay-rates?rateId=' + payItem.driverPayRateId);
        } else if (payItem.dynamicDriverPayId) {
            if(this.props.closeCustomerServiceSideBar) this.props.closeCustomerServiceSideBar();
            browserHistory.push('/dynamic-driverPay?rateId=' + payItem.dynamicDriverPayId);
        } else if (payItem.radiusRateId){
            if(this.props.closeCustomerServiceSideBar) this.props.closeCustomerServiceSideBar();
            browserHistory.push("/tms/driver-pay-rates?rateId=" + payItem.radiusRateId + "&rateType=driver_radius_pay_rate")
        } else if(payItem?.chargeProfileId) {
            this.getDriverChargeProfileDetails(payItem);
        }
    }

    redirectToSettlement = (payItem) => {
        if(payItem?.settlement){
            if(this.props.closeCustomerServiceSideBar) this.props.closeCustomerServiceSideBar();
            let url = `/tms/billing/settlements?settlementId=${payItem?.settlement?._id}&settlementType=${payItem?.settlement?.status}&startDate=${payItem?.settlement?.settlementStartDate}&endDate=${payItem?.settlement?.settlementEndDate}${payItem?.settlement?.settlementPeriodId ? `&settlementPeriodId=${payItem?.settlement?.settlementPeriodId}&settlementPeriodName=${payItem?.settlement?.settlementPeriodDetails?.name}` : ''}`
            browserHistory.push(url)
        }
    }

    getFromTo(val){
        return new Promise( (resolve,reject)=>{
            fetchUsers("carrier/getTMSCustomers", { searchTerm: val, limit: 10, }).then((res)=>{
                const response = Array?.isArray(res?.data?.data) ? res?.data?.data : [res?.data?.data];
                const customersWithAddressLabel = response?.map((item) => ({ ...item, label : `${item?.company_name ?? ""}, ${item?.city ?? ""}, ${item?.state ?? ""}` }))
                return resolve(customersWithAddressLabel)
            })
        })
        
    }
    getDriverList(val){
        return new Promise( (resolve,reject)=>{
            getDrivers(val).then((res)=>{
                return resolve(res)
            })
        })
        
    }
    componentDidMount() {
        const currentUser = JSON.parse(getStorage("loggedInUser"));
        let carrierId = currentUser._id;
        if (currentUser.role === "driver") {
            carrierId = currentUser.driver.carrier;
        }
        if (currentUser.role === "fleetmanager") {
            carrierId = currentUser.fleetManager.carrier;
        }
        let isTerminal = showForTerminal();
        this.setState({ isTerminal: isTerminal });
        if (_.get(this.props, "location.pathname") !== "/tms/billing/driver-pay") {
            if (isTerminal) {
                getTerminal().then((response) =>
                    this.setState({
                        terminals: response.data.data ? response.data.data : [],
                    })
                );
            }
        }
        this.getDriverList().then(res=>{
            this.setState({allDrivers:res})
        })
        if (
            this.props.location.query.reference_number ||
            this.props.reference_number
        ) {
            this.setState({ showSearch: false }, () => {
                let reference_number = decodeURIComponent(this.props.location.query.reference_number);
                if (this.props.reference_number) {
                    reference_number = decodeURIComponent(this.props.reference_number);
                }
                const query = Object.assign({}, this.state.query, { reference_number });
                this.getTranscation(query);
            });
        }

        if (
            (getStorage("currentUserRole") == "fleetmanager" &&
                configuration

                    .idForTerminal.indexOf(
                        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
                    ) != -1) ||
            (getStorage("currentUserRole") == "carrier" &&
                configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
        ) {
            getTerminal().then((response) => {
                let terminals = response.data.data;
                if (
                    getStorage("currentUserRole") == "fleetmanager" &&
                    configuration.idForTerminal.indexOf(
                        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
                    ) != -1
                ) {
                    let fleetTerminal = JSON.parse(getStorage("loggedInUser"))
                        .fleetManager.terminals;
                    terminals = terminals.filter(
                        (D) => fleetTerminal.indexOf(D._id) > -1
                    );
                }
                this.setState({ terminals });
            });
        }
        getCarrierByUserId(carrierId).then((mainAccount) => {
            let { settlementSettings } = mainAccount.data.data;
            this.setState(
                {
                    settlementSettings: settlementSettings ? settlementSettings : {},
                }
            );
        });
        
        document.addEventListener("mousedown", this.handleClickOutside);
        getAllLoadsStatus().then(data => {
            this.setState({ allLoadStatus: data.driverPayStatus || [] })
        })
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    createStatus = (status) => {
        let data = new FormData()
        data.append("status", status)
        createDriverPayStatus(data).then(data => {
            this.setState({ allLoadStatus: data.driverPayStatus || [] })
        }).catch(err => console.log(err))
    }


    removeStatus(status){
        return new Promise((resolve,reject)=>{
            let data = new FormData()
            data.append("status", status)
            deleteDriverPayStatus(data).then(data => {
                this.setState({ allLoadStatus: data.driverPayStatus || [] })
                resolve(data.driverPayStatus)
            }).catch(err => console.log(err))
        })
       
    }

    selectCell=(name)=>{
        this.setState({
            selectedCell:name
        });
    }
    unselectCell=()=>{
        this.setState({
            selectedCell:null
        });
    }

    keyPressOnNumberInput = (evt) => {
        if (!this.regex.test(evt.key) || (evt.target.value.indexOf('.')!=-1 && evt.key===".") || (evt.key==="-" && evt.target.value !== '')) {
            evt.preventDefault();
        }
    };

    deleteNewDriverPay = (params) => {
        confirm("Delete Driver Pay Rate?", "Are you sure to delete Driver Pay Rate?", (confirm) => {
            if (confirm) {
                params.from = "transcation";
                deleteNewDriverPay(params).then((result) => {
                    let transcations = JSON.parse(JSON.stringify(this.state.transcations));
                    transcations = transcations.filter((data) => data._id != params._id);
                    this.setState({ transcations },() => { this.handleReloadOfBillingSummary() });
                    toastr.show("Deleted!", "success");
                });
            }
        });
    };

    transitionEffect = (field, color) => {
        const element = document.querySelector(`.${field}`);
        if(color){
            element?.classList.remove("background-failure");
            element?.classList.add("background-success");
          setTimeout(()=>{
            element?.classList.remove("background-success","text-danger");
          },400)
        }else {
            element?.classList.add("background-error","text-danger");
            setTimeout(()=>{
                element?.classList.add("background-failure","text-danger");
                element?.classList.remove("background-error","text-danger");
            },400)
        }
      }

    getTranscation = (query = {}) => {
        this.setState({ isLoadingBarShow: true })
        if (this.state.missingDocType.length > 0) {
            query.missingDocType = JSON.stringify(this.state.missingDocType.map((d) => d.value))
        }

        getNewTranscation({
            ...query,
            from: this.state.startDate,
            to: this.state.endDate,
            order: 1
        })
            .then(async (response) => {
                let transcations =
                    !response.data.data || response.data.data.result == null
                        ? []
                        : response.data.data.result;
                transcations = transcations.filter((trans) => trans._id);
                const taxType = transcations?.[0]?.taxType
                let columns = this.state.columnHeader
                if(this.state.isVatEnabled && this.isVATPermission) columns = this.state.columnHeader.splice(4, 0,taxType)
                let updatedTransaction = [];
                try {
                    const { drivers = [] } = this.props
                    updatedTransaction = transcations.map((d) => {
                        let driver = drivers?.find((x) => x?._id === d?.createdBy?._id)
                        return { ...d, amount: d?.amount || 0, profilePicture: driver?.profilePicture }
                    })
                } catch (error) {
                    console.warn(error.message)
                }
                const tariffReferenceData = updatedTransaction?.find(
                    (singleTransaction) => {
                      if (
                        singleTransaction?.tariffReference?._id ||
                        singleTransaction?.tariffReference?.name
                      )
                        return true;
                    }
                  );
                  
                
                this.setState({
                    transcations: updatedTransaction,
                    isLoadingBarShow: false,
                    tariffReference: tariffReferenceData?.tariffReference,
                });

                if(tariffReferenceData?.tariffReference && this.props.setTariffInfo) {
                    this.props.setTariffInfo({
                        ...tariffReferenceData?.tariffReference,
                        vendorType: vendorType.Driver,
                    })
                }
            }).catch(err => {
                this.setState({ isLoadingBarShow: false })
            })
            .finally((err) => {
                this.setState({ isLoadingBarShow: false });
            });
    };

    addManual = () => {
        const { driver, from, to, amount, status, date, startDate, endDate, time, terminal } = this.state.newDriverPay
        const { selectedChargeCode }=this.state;
        const isLineTypeItem = this.props.accountingServiceInfo?.driverPayAccountInfo?.settings?.qBSettlementSettings?.lineType === 'item';
        const checkStatus = (isLineTypeItem || isDriverPayChargeCodeEnabled()) ? !selectedChargeCode : !status;
        if (!driver || !from || !to || !amount  || !date || !time || checkStatus ) {
            this.setState({ isRequied: "background-error" }, () => {
                setTimeout(() => {
                    this.setState({ isRequied: "background-failure" })
                }, 400);
            })

            return;
        }
        if (this.state.isApiCall) return
        const newFormData = new FormData();

        newFormData.append("startDate", startDate);
        newFormData.append("endDate", endDate);
        newFormData.append("time", time);
        newFormData.append("amount", parseFloat(amount));
        newFormData.append("driverId", driver.value);
        if ((isLineTypeItem || isDriverPayChargeCodeEnabled()) && this.state.selectedChargeCode) {
            newFormData.append("chargeCode", this.state.selectedChargeCode.value);
            newFormData.append("description", this.state.selectedChargeCode.label);
          } else {
            newFormData.append("description", status);
          }
        if (
            this.props.location &&
            this.props.location.query &&
            this.props.location.query.reference_number
        ) {
            newFormData.append(
                "reference_number",
                this.props.location.query.reference_number
            );
        } else {
            newFormData.append(
                "reference_number",
                this.props.selectedLoads.reference_number
            );
        }
        newFormData.append("from", from);
        newFormData.append("to", to);
        if (terminal) {
            newFormData.append("terminal", terminal.value);
        }
        newFormData.append("loadId", this.props.selectedLoads?._id)
        if(this.props.selectedLoads?.containerNo){
            newFormData.append("containerNo", this.props.selectedLoads?.containerNo)
        }
        this.setState({ isApiCall: true })
        manualDriverPay(newFormData)
            .then(async(response) => {
                let data = response.data.data;
                this.props.blockTabChange(false);
                let transcations = this.state.transcations.slice();

                let driver = this.props?.drivers?.find((x)=> x?._id === data?.createdBy?._id)
                data.profilePicture = driver?.profilePicture
                transcations.push(data);

                this.setState({ isSaving: false, showManual: false, transcations, newDriverPay: newDriverPay, addDriverPay: false, isApiCall: false },()=>{
                    const rowId = transcations.length
                    this.transitionEffect(`row-${rowId-1}`,true)
                    this.handleReloadOfBillingSummary()
                });
            })
            .catch((error) => {
                this.props.blockTabChange(false);
                this.setState({ isSaving: false, isApiCall: false });
            });
    };

    getLocation = (statusOrder, createdBy, status) => {
        let location = {
            from: [],
            to: [],
        };

        if (status == "DELIVERED") {
            var findIndex = JSON.parse(JSON.stringify(statusOrder))?.findIndex(
                (status) => {
                    return status.status == "ARRIVED_PICKUP";
                }
            );
            var D = statusOrder[findIndex + 2];
            if (D && D.status == "DROPPED" && D.isCompleted && D.address) {
                let formattedData = D.address.address.split(",");
                location.from = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            } else {
                var D = statusOrder[findIndex];
                if (D && D.address && D.address.address) {
                    let formattedData = D.address.address.split(",");
                    location.from = [
                        {
                            company: D.company,
                            street: formattedData[0],
                            city: D.city ? D.city : formattedData[1],
                            state: D.state ? D.state : formattedData[2],
                        },
                    ];
                }
            }
            var findIndex = JSON.parse(JSON.stringify(statusOrder))?.findIndex(
                (status) => {
                    return status.status == "DELIVERED";
                }
            );
            var D = statusOrder[findIndex];
            if (D && D.address && D.address.address) {
                let formattedData = D.address.address.split(",");
                location.to = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            }
        }
        if (status == "ARRIVED_PICKUP") {
            var driverId;
            var hasChanged = false;

            var findIndex = JSON.parse(JSON.stringify(statusOrder))?.findIndex(
                (status) => {
                    return status.address;
                }
            );
            var D = statusOrder[findIndex];
            if (D && D.address && D.address.address) {
                let formattedData = D.address.address.split(",");
                location.from = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            }
            var findIndex = statusOrder?.findIndex((status) => {
                return status.status == "DROPPED" && status.isCompleted;
            });
            if (findIndex != -1 && statusOrder[findIndex]) {
                var D = statusOrder[findIndex];
                if (D && D.address && D.address.address) {
                    let formattedData = D.address.address.split(",");
                    location.to = [
                        {
                            company: D.company,
                            street: formattedData[0],
                            city: D.city ? D.city : formattedData[1],
                            state: D.state ? D.state : formattedData[2],
                        },
                    ];
                } else {
                    var findIndex = JSON.parse(JSON.stringify(statusOrder))
                        ?.slice()
                        ?.reverse()
                        ?.findIndex((status) => {
                            return status.isCompleted == true;
                        });
                    var D = statusOrder[findIndex];
                    if (D && D.address && D.address.address) {
                        let formattedData = D.address.address.split(",");
                        location.to = [
                            {
                                company: D.company,
                                street: formattedData[0],
                                city: D.city ? D.city : formattedData[1],
                                state: D.state ? D.state : formattedData[2],
                            },
                        ];
                    }
                }
            } else {
                var findIndex = JSON.parse(JSON.stringify(statusOrder))
                    ?.slice()
                    ?.reverse()
                    ?.findIndex((status) => {
                        return status.status == "DELIVERED";
                    });
                var D = statusOrder[findIndex];
                if (D && D.address && D.address.address) {
                    let formattedData = D.address.address.split(",");
                    location.to = [
                        {
                            company: D.company,
                            street: formattedData[0],
                            city: D.city ? D.city : formattedData[1],
                            state: D.state ? D.state : formattedData[2],
                        },
                    ];
                }
            }
        }
        if (status == "COMPLETED") {
            var findIndexForDelivery = JSON.parse(
                JSON.stringify(statusOrder)
            )?.findIndex((status) => {
                return status.status == "DELIVERED";
            });
            var D = statusOrder[findIndexForDelivery];
            if (D && D.address && D.address.address) {
                var formattedData = D.address.address.split(",");
                location.from = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            }

            let returnData = JSON.parse(JSON.stringify(statusOrder)).reverse();
            var findIndex = returnData?.findIndex((status) => {
                return status.status == "ARRIVED_RETURN";
            });
            var D = returnData[findIndex];
            if (D && D.address && D.address.address) {
                let formattedData = D.address.address.split(",");
                location.to = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            }

            let dataForDrop = JSON.parse(JSON.stringify(statusOrder));
            if (findIndexForDelivery != -1) {
                var splicedDrop = dataForDrop
                    .splice(findIndexForDelivery, dataForDrop.length)
                    .reverse();
                var findIndexForDrop = splicedDrop?.findIndex((status) => {
                    return status.status == "DROPPED";
                });

                if (
                    findIndexForDrop != -1 &&
                    splicedDrop[findIndexForDrop] &&
                    splicedDrop[findIndexForDrop].isCompleted
                ) {
                    var D = splicedDrop[findIndexForDrop];
                    if (D && D.address && D.address.address) {
                        let formattedData = D.address.address.split(",");
                        location.from = [
                            {
                                company: D.company,
                                street: formattedData[0],
                                city: D.city ? D.city : formattedData[1],
                                state: D.state ? D.state : formattedData[2],
                            },
                        ];
                    }
                }
            }
        }
        if (status == "NEED_TO_GET_DROP") {
            var driverId;
            var hasChanged = false;
            statusOrder.forEach((status, index) => {
                if (index == 0) {
                    driverId = status.driver;
                } else {
                    if (status.driver != driverId) {
                        hasChanged = true;
                    }
                }
            });
            var findIndexForDelivery = statusOrder?.findIndex((status) => {
                return status.status == "DELIVERED";
            });
            var D = statusOrder[findIndexForDelivery];
            if (D && D.address && D.address.address) {
                let formattedData = D.address.address.split(",");
                location.from = [
                    {
                        company: D.company,
                        street: formattedData[0],
                        city: D.city ? D.city : formattedData[1],
                        state: D.state ? D.state : formattedData[2],
                    },
                ];
            }
            let dataForDrop = JSON.parse(JSON.stringify(statusOrder)).reverse();
            var findIndexForDrop = dataForDrop?.findIndex((status) => {
                return status.status == "DROPPED" && status.isCompleted;
            });
            if (findIndexForDrop != -1) {
                var D = dataForDrop[findIndexForDrop];
                if (D && D.address && D.address.address) {
                    let formattedData = D.address.address.split(",");
                    location.to = [
                        {
                            company: D.company,
                            street: formattedData[0],
                            city: D.city ? D.city : formattedData[1],
                            state: D.state ? D.state : formattedData[2],
                        },
                    ];
                }
            } else {
                var findIndex = statusOrder?.findIndex((status) => {
                    return status.status == "ARRIVED_RETURN";
                });
                var D = statusOrder[findIndex];
                if (D && D.address && D.address.address) {
                    let formattedData = D.address.address.split(",");
                    location.to = [
                        {
                            company: D.company,
                            street: formattedData[0],
                            city: D.city ? D.city : formattedData[1],
                            state: D.state ? D.state : formattedData[2],
                        },
                    ];
                }
            }
            let dataForNeedToGetDrop = JSON.parse(JSON.stringify(statusOrder));
            if (findIndexForDelivery != -1) {
                var splicedDrop = dataForNeedToGetDrop.splice(
                    findIndexForDelivery,
                    dataForNeedToGetDrop.length
                );
                var findIndexForDrop = splicedDrop?.findIndex((status) => {
                    return status.status == "NEED_TO_GET_DROP";
                });

                if (
                    findIndexForDrop != -1 &&
                    splicedDrop[findIndexForDrop] &&
                    splicedDrop[findIndexForDrop].isCompleted
                ) {
                    var D = splicedDrop[findIndexForDrop];
                    if (D && D.address && D.address.address) {
                        let formattedData = D.address.address.split(",");
                        location.from = [
                            {
                                company: D.company,
                                street: formattedData[0],
                                city: D.city ? D.city : formattedData[1],
                                state: D.state ? D.state : formattedData[2],
                            },
                        ];
                    }
                }
            }
        }
        return location;
    };


    approveDeduction = (params) => {
        params.from = "Driver Pay";
        this.setState({ rowIndex: params.key })
        if (params.approved) {
            let eventProperties = {
                source: 'driver_pay_tab_loadinfo'
              }
              amplitudeTrack("APPROV_DRIVER_PAY",eventProperties)
        } 
        approveNewDeduction(params).then((response) => {
            let transcations = JSON.parse(JSON.stringify(this.state.transcations));

            if (!response.data.data._id && typeof response.data.data === 'string') {
                toastr.show(response.data.data, 'error');
                return;
            }
            transcations = transcations.map((trans) => {
                if (trans._id == response.data.data._id) {
                    let data = response.data.data;
                    trans.lock = data.lock;
                    trans.approved = data.approved;
                }
                return trans;
            });
            this.setState({ transcations },()=>{this.handleReloadOfBillingSummary()});

            if (params.approved) {
                toastr.show("Approved!", "success");
            } else {
                toastr.show("This has been unapproved.", "success");
            }
           
        });
    };
    calculateAmount = (amount, additionalPrice) => {
        let totalAmount = amount.toFixed(2);
        let otherAmount = [];
        if (
            additionalPrice &&
            additionalPrice.length > 0 &&
            Array.isArray(additionalPrice)
        ) {
            additionalPrice.forEach((price) => {
                if (price && price != "") {
                    otherAmount.push(`${price.name} = ${price.amount.toFixed(2)}`);
                }
            });
        }
        if (otherAmount.length > 0) {
            return `${totalAmount}, ${otherAmount.join(",")}`;
        } else {
            return `${totalAmount}`;
        }
    };

    renderStatus = (status, reference_number) => {
        const firstLetter = reference_number?.substring(0, 1);
        let newStatus = "";
        switch (status) {
            case "ARRIVED_PICKUP":
                newStatus = firstLetter == "R" ? "Picked Up Load" : "Pulled Container";
                break;
            case "COMPLETED":
                newStatus = "Return Container";
                break;
            case "DELIVERED":
                newStatus = firstLetter == "R" ? "Delivered Load" : "Delivered";
                break;
            case "NEED_TO_GET_DROP":
                newStatus = "Get Container";
                break;
            default:
                newStatus = this.getPricingOptionsInStatus(status);
        }
        return newStatus;
    };

    updateRow(key, value, info,index) {
        let newP;
        if ((this.props.accountingServiceInfo?.driverPayAccountInfo?.settings?.qBSettlementSettings?.lineType === 'item' || isDriverPayChargeCodeEnabled()) && key === 'status') {
            newP = { id: info._id, chargeCode: value?.value };
            newP[key] = value?.label;
        } else {
            newP = { id: info._id, [key]: value };
        }
        newP.isFromSettlement = false
        newP.isManual = true

        updateNewTranscation(newP).then((response) => {
            let transcations = this.state.transcations

            transcations.forEach((data) => {
                if (data._id == response.data.data._id) {
                    let obj = transcations?.findIndex((obj) => obj._id === response.data.data._id)
                    let data = response.data.data;
                    transcations[obj] = data
                }
            })
           
            this.setState({ transcations: transcations },()=> { this.handleReloadOfBillingSummary() })
            this.transitionEffect(`row-${index}`,true)
        }).catch((e) => {
            this.transitionEffect(`row-${index}`,false)
        })
    }


    getPricingOptionsInStatus = (str) => {
        if (str) {
            pricingSettingsOptions.forEach((opt) => {
                if (str.includes(opt.value)) {
                    let re = new RegExp(opt.value, "g");
                    str = str.replace(re, opt.label);
                }
            });
        }
        return str;
    };

    sortTable(sort) {
        this.setState({
            sort: !this.state.asce ? undefined : sort,
            asce: this.state.sort !== sort ? true : !this.state.asce,
        });
    }


    handleOnChange(key, value) {
       if(key==="from"||key==="to"){value=value?.label ?? ''}
        const NewDpay = { ...this.state.newDriverPay, [key]: value }
        if(key === "driver" || key === "terminal") {
            const addDriverPayInvoiceCurrency = getDriverCurrency(this.props.selectedLoads, NewDpay?.driver?.allInfo?.driver, NewDpay?.terminal);
            this.setState({addDriverPayInvoiceCurrency: addDriverPayInvoiceCurrency});
        }

        this.setState({ newDriverPay: NewDpay, ShowDriverList: false }, () => {
            this.props.blockTabChange(true);
            if(key!="amount")this.unselectCell()})
    }
    handleChargeCodeChange(key,value){
        this.setState({selectedChargeCode: value},()=>{
            this.unselectCell()
        });

    }
    handleChangeDate = (val, info, index) => {
        if (val) {
            let startDate = moment(new Date(val))
                .tz(this.userTimeZone)
                .startOf("weeks")
                .toISOString();
            let endDate = moment(new Date(val))
                .tz(this.userTimeZone)
                .endOf("weeks")
                .toISOString();

            const time = moment(new Date(val))
                .tz(this.userTimeZone)
                .toISOString();
            changeWeek({
                _id: info._id,
                from: info.from,
                startDate,
                endDate,
                createdBy: info.createdBy._id,
                time,
                hasSettlementSettings:
                    this.state.settlementSettings &&
                        this.state.settlementSettings.duration &&
                        this.state.settlementSettings.day
                        ? true
                        : false,
                ...(info.terminal ? {terminal: info.terminal?._id} : {}),
                loadId : this.props.selectedLoads?._id
            }).then((response) => {
                const transcations = this.state.transcations.map((trans) => {
                    if (trans._id == response.data.data._id) {
                        let data = response.data.data;
                        trans.startDate = data.startDate;
                        trans.endDate = data.endDate;
                        trans.time = data.time;
                    }
                    return trans;
                });
                this.setState({ transcations,selectedCell:null, DateAndTime: null });
                this.transitionEffect(`row-${index}`,true)
                toastr.show("You have changed the settlement week. You must verify your settlements.", "success");
            }).catch(err => {
                this.transitionEffect(`row-${index}`,false)
            });
        }
    };

    hideModel = () => {
        this.setState({isShowBox: false})
	}

    update = (data) => {
        let rowId = null
        let transcations = JSON.parse(JSON.stringify(this.state.transcations));
        transcations = transcations.map((trans, index) => {
            if (trans._id == data._id) {
                data.profilePicture = trans?.profilePicture;
                trans = { ...data };
                rowId=index
            }
            return trans;
        });
        this.setState({ transcations });
        this.transitionEffect(`row-${rowId}`,true)
    };


    searchLoad = (e) => {
        const searchTerm = e.target.value;
        this.setState({ searchTerm }, () => { });
    };

    onDateChange = (e, dateType) => {
        let dates = {
            fromDate: dateType === "fromDate"
                ? new Date(e)
                : this.state.fromDate
                    ? this.state.fromDate
                    : this.state.startDate,
            toDate:
                dateType === "toDate"
                    ? new Date(e)
                    : this.state.toDate
                        ? this.state.toDate
                        : this.state.endDate,
        };

        this.setState(
            {
                fromDate: dates.fromDate,
                toDate: dates.toDate,
                isDateRange: true,
                isLoadingBarShow: true,
            },
            () => {
                let query = {
                    fromDate: moment(this.state.fromDate)
                        .tz(this.userTimeZone)
                        .toISOString(),
                    toDate: moment(this.state.toDate)
                        .tz(this.userTimeZone)
                        .toISOString(),
                };
                this.getTranscation(query);
            }
        );
    };

    handleAddDates = (e) => {
        if (e) {
            const startDate = moment(e)
                .tz(this.userTimeZone)
                .startOf("weeks")
                .toISOString();
            const endDate = moment(e)
                .tz(this.userTimeZone)
                .endOf("weeks")
                .toISOString();

            const time = moment(e)
                .tz(this.userTimeZone)
                .toISOString();
            const hasSettlementSettings =
                this.state.settlementSettings &&
                    this.state.settlementSettings.duration &&
                    this.state.settlementSettings.day
                    ? true
                    : false
            this.setState({ newDriverPay: { ...this.state.newDriverPay, startDate, endDate, time, hasSettlementSettings, date: time } })
        }
    };

    removeCommaIfString = (string) => {
        const typeIsString = typeof string === 'string' ? true : false
        if ( typeIsString && string.length > 0 && string.charAt(string?.trim().length - 1) == ','){
            return string?.trim().slice(0,-1)
        }
        return string
    }

    onChangeTerminal = () => {
        let query = {
            fromDate: moment(this.state.fromDate)
                .tz(this.userTimeZone)
                .toISOString(),
            toDate: moment(this.state.toDate)
                .tz(this.userTimeZone)
                .toISOString(),
        };
        if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
            let choosenTerminals = this.state.choosenTerminals.map(
                (Data) => Data.value
            );
            query = Object.assign({}, query, {
                terminal: JSON.stringify(choosenTerminals),
            });
        }
        this.getTranscation(query);
    }

    handleReloadOfBillingSummary = () => {
        if(this.props.reloadBillingSummary) {
            return this.props.reloadBillingSummary();
        }
        this.setState({ reloadBillingSummary: this.state.reloadBillingSummary + 1})
    }

    handleOnEditComplete = async (chargeProfileDetails) => {
        try {
            await editChargeProfile({ ...chargeProfileDetails, vendorType: vendorType?.Driver.toLowerCase() })
            
            this.setState({ chargeProfileDetails: null });
            
            return;
        } catch (error) {
            console.error(error);
        }
    }
    
    renderReturn = ()=>{
        let { transcations, filterStatus, isStatusHovered } = this.state;
        const customersWithAddressLabel = this.props?.allCustomers?.map((item) => ({ ...item, label: `${item?.company_name ?? ""}, ${item?.city ?? ""}, ${item?.state ?? ""}` }));
        
        let currentUser = JSON.parse(getStorage("loggedInUser"));
        let { driverPayEditPermission, driverPayApprovedPermission, driverPayDeletePermission, settlementShowPermission } = checkAccountPayablePermission(currentUser);

        if (filterStatus.length > 0) {
            transcations = transcations.filter(
                (f) =>
                    filterStatus.includes(
                        moment(f.time).tz(this.userTimeZone).format("ddd")
                    ) && !f.approved
            );
        }
        if (this.state.searchTerm !== "") {
            transcations = transcations.filter(
                (info) =>
                    (info &&
                        info.createdBy &&
                        info.createdBy.name &&
                        info.createdBy.name
                            .toLowerCase()
                            .includes(this.state.searchTerm.toLowerCase())) ||
                    (info &&
                        info.reference_number &&
                        info.reference_number
                            .toLowerCase()
                            .includes(this.state.searchTerm.toLowerCase()))
            );
        }
        transcations.forEach((el) => {
            el.calculateAmount = this.calculateAmount(
                el.amount,
                el.additionalPrice
            )
            el.renderStatus = this.renderStatus(el.status, el.reference_number);
        });
        if (this.state.sort) {
            transcations = _.orderBy(
                transcations,
                trans => {
                    if (this.state.sort === 'from') {
                        return trans[this.state.sort] ? trans[this.state.sort].toLowerCase() : (trans.groupFrom && trans.groupFrom.toLowerCase())
                    } else if (this.state.sort === 'to') {
                        return trans[this.state.sort] ? trans[this.state.sort].toLowerCase() : (trans.groupTo && trans.groupTo.toLowerCase())
                    } else if (this.state.sort === 'calculateAmount') {
                        return Number(trans[this.state.sort])
                    } else if (this.state.sort === 'createdBy.name') {
                        return trans.createdBy.name.toLowerCase()
                    } else if (this.state.sort === "fleetTruckOwner.company_name") {
                        return trans.fleetTruckOwner && trans.fleetTruckOwner.company_name ? trans.fleetTruckOwner.company_name.toLowerCase() : null
                    } else if (this.state.sort === "truck.equipmentID") {
                        return trans.truck && trans.truck.equipmentID ? trans.truck.equipmentID.toLowerCase() : null
                    } else if (this.state.sort === "terminal.name") {
                        return trans.terminal.name ? trans.terminal.name.toLowerCase() : null
                    } else {
                        return trans[this.state.sort]?.toLowerCase()
                    }
                },
                !this.state.asce && "desc"
            );

        }
        let Terminal = [];
        if (this.state.terminals && this.state.terminals.length > 0) {
            Terminal = JSON.parse(JSON.stringify(this.state.terminals));
            Terminal = Terminal.map((Data) => {
                return {
                    value: Data._id,
                    label: Data.name,
                };
            });
        }

        const handleOptionClick = (option) => {
            this.setState({selectedTab:option})
          };

        const isLineTypeItem = this.props.accountingServiceInfo?.driverPayAccountInfo?.settings?.qBSettlementSettings?.lineType === 'item'

        return (
            <>
                {!this.props.reloadBillingSummary && <div className="card py-15 mb-0 border-top">
                    <div className='px-15'>
                        <BillingSummaryCountCard loads={this.props.selectedLoads} selectedTab={this.state.selectedTab} reloadBillingSummary={this.state.reloadBillingSummary} driverTariffReference= {this.state.tariffReference} />
                    </div>
                    <div className='hr-light'></div>
                    <div className="d-flex justify-content-between px-15">
                        <div className="d-flex align-items-center gap-10">
                            <ul className="nav nav-compact" role="tablist">
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.selectedTab === "driver-pay" ? "active" : ""}`} onClick={() => handleOptionClick("driver-pay")}>
                                        Driver Pay
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${this.state.selectedTab === "expenses" ? "active" : ""}`} onClick={() => handleOptionClick("expenses")}>
                                        Expenses
                                    </a>
                                </li>
                            </ul>
                            {this.state.selectedTab === "driver-pay" &&
                                <div className="app-search header-search mr-20">
                                    <span className="search-icon"> </span>
                                    <div className="position-relative">
                                        <input
                                            className="form-control input-search-left"
                                            id="driver-expense-search"
                                            placeholder="Search"
                                            value={this.state.searchTerm}
                                            onChange={this.searchLoad}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        {(this.state.selectedTab === "driver-pay" && showForCompany()) &&
                            <div className="d-flex gap-10">
                                <div className="form-group mb-0 position-relative">
                                    <div className="input-wrapper">
                                        <DateWrapper
                                            popupPlacement="right"
                                            placeholder="From"
                                            onChange={(e) => this.onDateChange(e, "fromDate")}
                                            OnBlurUpdate={() => { this.setState({ filterFrom: false }) }}
                                            defaultDate={this.state.fromDate ? moment(this.state.fromDate) : moment(this.state.startDate)}
                                            displayTimeZone={this.userTimeZone}
                                            hideShowSelectDate={true}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                        />
                                        <div className="input-icon">
                                            <IconCalendar />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-0 position-relative">
                                    <div className="input-wrapper">
                                        <DateWrapper
                                            popupPlacement="right"
                                            placeholder="To"
                                            onChange={(e) => this.onDateChange(e, "toDate")}
                                            OnBlurUpdate={() => { this.setState({ filterTo: false }) }}
                                            defaultDate={this.state.toDate ? moment(this.state.toDate) : moment(this.state.endDate)}
                                            displayTimeZone={this.userTimeZone}
                                            hideShowSelectDate={true}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                        />
                                        <div className="input-icon">
                                            <IconCalendar />
                                        </div>
                                    </div>
                                </div>
                                {this.state.isTerminal && (['fleetmanager', 'carrier'].includes(getStorage('currentUserRole')) > -1) && (
                                    <Select
                                        styles={smallSelectStyle}
                                        className="w-150"
                                        onChange={(choosenTerminals) =>
                                            this.setState({ choosenTerminals }, () => {
                                                this.onChangeTerminal();
                                            })
                                        }
                                        isMulti
                                        isClearable={true}
                                        name="choosenTerminals"
                                        options={Terminal}
                                    />
                                )}
                            </div>
                        }
                    </div>
                </div>}

                {this.state.selectedTab === "driver-pay" ?
                <>
                <div className={`${this.state.isDateSelect ? "" : "table-responsive"}`} style={{ height: "calc(100vh - 285px})" }}>
                    <div className="position-relative" ref={this.setWrapperRef} >
                    {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
                    <table className="table table-card payment-table">
                            {this.state.isApiCall && <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                                <span
                                    className="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Processing...
                            </div>}
                            <thead>
                                <tr>
                                    {((getStorage("currentUserRole") == "fleetmanager" &&
                                        configuration.idForTerminal.indexOf(
                                            JSON.parse(getStorage("loggedInUser")).fleetManager
                                                .carrier
                                        ) != -1) ||
                                        (getStorage("currentUserRole") == "carrier" &&
                                            configuration.idForTerminal.indexOf(
                                                getStorage("currentUserID")
                                            ) != -1)) && (
                                            <th
                                                style={{width: '15%'}}
                                                scope="col"
                                                onClick={() =>
                                                    sortingOptions.hasOwnProperty("Terminal") &&
                                                    this.sortTable(sortingOptions["Terminal"])
                                                }
                                                className={`${sortingOptions.hasOwnProperty('Terminal') && (this.state.sort == undefined || this.state.sort !== sortingOptions['Terminal'])
                                                    ? 'table-sort table-sort--default'
                                                    : (sortingOptions.hasOwnProperty('Terminal') ? this.state.sort === sortingOptions['Terminal'] && this.state.asce == true ? 'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')}`}
                                            >
                                                {updateTerminology('Terminal')}
                                                {
                                                    sortingOptions.hasOwnProperty("Terminal")
                                                }
                                            </th>
                                        )}
                                    {this.state.columnHeader.map((str, i) => (
                                        <th
                                            key={i}
                                            style={{width: "15%"}}
                                            scope="col"
                                            onClick={() =>
                                                sortingOptions.hasOwnProperty(str) &&
                                                this.sortTable(sortingOptions[str])
                                            }
                                            className={`${str === "Amount" && 'text-right' } ${str === "Time" && 'text-center' }  ${sortingOptions.hasOwnProperty(str) && (this.state.sort == undefined || this.state.sort !== sortingOptions[str])
                                                ? 'table-sort table-sort--default'
                                                : (sortingOptions.hasOwnProperty(str) ? this.state.sort === sortingOptions[str] && this.state.asce == true ? 'table-sort table-sort--ascending' : 'table-sort table-sort--descending' : '')} z-0`}
                                        >
                                            {str}
                                        </th>
                                    ))}
                                    {transcations?.some(val =>(val?.settlement?.settlementNumber)) && <th>Settlement #</th>}
                                    <th style={{width: '10%'}} scope="col" className=" text-center"></th>
                                    <th style={{width: '2.75%'}} scope="col" className=" text-center"></th>
                                    <th style={{width: '15%'}} scope="col" className=" text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {transcations &&
                                    transcations.map((info, i) => {
                                        let address = undefined;
                                        if (info.statusOrder && !info.loadId) {
                                            address = this.getLocation(
                                                info.statusOrder,
                                                info.createdBy,
                                                info.status
                                            );
                                        } else {
                                            address = getDriverOrderLocation(
                                                info.loadId?.driverOrder ? info.loadId.driverOrder : [],
                                                info.createdBy,
                                                info.status
                                            );
                                        }
                                        if (info.terminal && !info.terminal.name) {
                                            info.terminal = this.state.terminals.find(
                                                (terminal) => terminal._id == info.terminal
                                            );
                                        }
                                        let conditionForWeekChange = true;
                                        if (info.lock) {
                                            conditionForWeekChange = false;
                                        }
                                        if (info.lock && info.approved != true) {
                                            conditionForWeekChange = true;
                                        }
                                        if (info.lock && info.approved == true) {
                                            conditionForWeekChange = false;
                                        }
                                        // Get From And to
                                        let from = info.from ? info.from : "";
                                        let to = info.to ? info.to : "";
                                        // paymenttype is DYNAMIC DRIVER
                                        if (
                                            info &&
                                            info.paymentType &&
                                            info.paymentType.includes("DYNAMIC DRIVER")
                                        ) {
                                            from = info.from;
                                            to = info.to;
                                        } else {
                                            if (info.isManual && info.from) {
                                                if (
                                                    info.from != "undefined" &&
                                                    info.from != undefined &&
                                                    info.from != "transcation"
                                                ) {
                                                    from = info.from;
                                                }
                                            }
                                            if (info.groupFrom) {
                                                from = info.groupFrom;
                                            }
                                            if (
                                                !from &&
                                                !info.groupForm &&
                                                address &&
                                                address.from.length > 0
                                            ) {
                                                from = address.from.map((address, keya) => {
                                                    return (
                                                        <span key={keya}>
                                                            {address.company ? `${address.company}` : ""}
                                                            {address.city ? `, ${address.city}` : ""}
                                                            {address.state ? `, ${address.state}` : ""}
                                                        </span>
                                                    );
                                                });
                                            }
                                            // To
                                            if (info.isManual && info.to) {
                                                to = info.to;
                                            }
                                            if (info.groupTo) {
                                                to = info.groupTo;
                                            }
                                            if (
                                                !to &&
                                                !info.groupTo &&
                                                address &&
                                                address.to.length > 0
                                            ) {
                                                to = address.to.map((address, keya) => {
                                                    return (
                                                        <div key={keya}>
                                                            {keya == 1 && <p>-----------</p>}
                                                            <span>
                                                                {address.company
                                                                    ? `${address.company}`
                                                                    : ""}
                                                                {address.city ? `, ${address.city}` : ""}
                                                                {address.state ? `, ${address.state}` : ""}
                                                            </span>
                                                        </div>
                                                    );
                                                });
                                            }
                                        }
                                        let status = this.renderStatus(
                                            info.status,
                                            info.reference_number
                                        )
                                        let isHovering = isStatusHovered === info._id;
                                        return (
                                            <>
                                                <tr
                                                    className={` select-custom-input inline-table-row row-${i} inline-table-row ${this.state.addDriverPay && "disabled-pointer-events"} `}
                                                    key={i}
                                                    style={{
                                                        opacity: `${this.state.addDriverPay
                                                            ? "0.2"
                                                            : ""
                                                            } `,
                                                        transition:
                                                            "background .4s ease-out",
                                                    }}
                                                >
                                                    {((getStorage("currentUserRole") ==
                                                        "fleetmanager" &&
                                                        configuration.idForTerminal.indexOf(
                                                            JSON.parse(
                                                                getStorage("loggedInUser")
                                                            ).fleetManager.carrier
                                                        ) != -1) ||
                                                        (getStorage("currentUserRole") ==
                                                            "carrier" &&
                                                            configuration.idForTerminal.indexOf(
                                                                getStorage("currentUserID")
                                                            ) != -1)) && (
                                                            <td
                                                            onClick={()=>this.unselectCell()}
                                                            >
                                                               <div className="min-width-100"> {info.terminal && info.terminal.name}</div>
                                                            </td>
                                                        )}
                                                    <td
                                                    onClick={()=>this.unselectCell()}
                                                    >
                                                        <div className="min-width-100">
                                                            {
                                                                info.profilePicture ?
                                                                    <div className="d-flex align-items-center justify-content-start">
                                                                        <Avatar src={info.profilePicture} className='mr-2'></Avatar>
                                                                        {info && info.createdBy && info.createdBy.name ? info.createdBy.name : " "}{" "}
                                                                        {info && info.createdBy && info.createdBy.lastName ? info.createdBy.lastName : ""}
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex align-items-center justify-content-start">
                                                                        <Avatar className='mr-2'>{displayName(info.createdBy.name + ' ' + info.createdBy.lastName)}</Avatar>
                                                                        {info && info.createdBy && info.createdBy.name ? info.createdBy.name : " "}{" "}
                                                                        {info && info.createdBy && info.createdBy.lastName ? info.createdBy.lastName : ""}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td
                                                    className={`pointer cell-${i}-1 pr-1 ${driverPayEditPermission && this.state.selectedCell === `update-status-${i}` && 'newdriverrow td-clicked'}`}
                                                     onClick={() => this.selectCell(`update-status-${i}`)}
                                                    >
                                                    <div className="d-flex justify-content-between align-items-center min-width-100">
                                                            {status || <span className="text-muted">Select Status</span>}
                                                            <IconAngleArrowDown className='' />
                                                            {driverPayEditPermission && this.state.selectedCell === `update-status-${i}` &&
                                                                <SelectCustomComponent
                                                                    name='status'
                                                                    searchable={true}
                                                                    refClassName={`cell-${i}-1`}
                                                                    listData={(isLineTypeItem || isDriverPayChargeCodeEnabled()) ? this.chargeCodeOptions :  this.state.allLoadStatus}
                                                                    onSelect={(name,value)=>{
                                                                        this.updateRow(name,value,info,i)
                                                                        this.unselectCell()

                                                                    }}
                                                                    onCreateOption={(isLineTypeItem || isDriverPayChargeCodeEnabled()) ? undefined : (!isStrictDriverPayTypes() && this.createStatus)}
                                                                    onDeleteItem={(isLineTypeItem || isDriverPayChargeCodeEnabled()) ? undefined : (!isStrictDriverPayTypes() && this.removeStatus)}
                                                                    unselectCell={this.unselectCell}
                                                                    keyName='label'
                                                                />}
                                                        </div>
                                                       
                                                    </td>
                                                    <td
                                                    className={`pointer cell-${i}-2 pr-1 ${driverPayEditPermission && this.state.selectedCell === `update-from-${i}` && 'newdriverrow td-clicked'}`}
                                                        onClick={() => this.selectCell(`update-from-${i}`)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center min-width-100">
                                                        {this.removeCommaIfString(from) || <span className="text-muted">From A Company</span>}
                                                            <IconAngleArrowDown className='' />
                                                            {driverPayEditPermission && this.state.selectedCell === `update-from-${i}` &&
                                                                <SelectCustomComponent
                                                                    name='from'
                                                                    searchable={true}
                                                                    refClassName={`cell-${i}-2`}
                                                                    listData={customersWithAddressLabel}
                                                                    onSelect={(name, value) => {
                                                                        this.updateRow(name, value?.label ?? '', info, i)
                                                                        this.unselectCell()
                                                                    }}
                                                                    loadOptions={this.getFromTo}
                                                                    keyName="label"
                                                                    unselectCell={this.unselectCell}
                                                                />}
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`pointer cell-${i}-3 pr-1 ${driverPayEditPermission && this.state.selectedCell === `update-to-${i}` && 'newdriverrow td-clicked'}`}
                                                        onClick={() => this.selectCell(`update-to-${i}`)}
                                                    >

                                                        <div className="d-flex justify-content-between align-items-center min-width-100">
                                                        {this.removeCommaIfString(to) || <span className="text-muted">To A Company</span>}
                                                            <IconAngleArrowDown className='' />
                                                            {driverPayEditPermission && this.state.selectedCell === `update-to-${i}` &&
                                                                <SelectCustomComponent
                                                                    name="to"
                                                                    refClassName={`cell-${i}-3`}
                                                                    searchable={true}
                                                                    listData={customersWithAddressLabel}
                                                                    onSelect={(name, value) => {
                                                                        this.updateRow(name, value?.label ?? '', info, i)
                                                                        this.unselectCell()
                                                                    }}
                                                                    loadOptions={this.getFromTo}
                                                                    keyName="label"
                                                                    unselectCell={this.unselectCell}
                                                                />}
                                                        </div>
                                                    </td>
                                                    
                                                    {this.state.isVatEnabled && this.isVATPermission && <td
                                                    className={`pointer cell-${i}-4 pr-1 ${driverPayEditPermission && this.state.selectedCell === `update-defaultTaxType-${i}` && 'newdriverrow td-clicked'}`}
                                                     onClick={() => this.selectCell(`update-defaultTaxType-${i}`)}
                                                    >
                                                    <div className="d-flex justify-content-between align-items-center min-width-100">
                                                            {info.defaultTaxType || <span className="text-muted">Select</span>}
                                                            <IconAngleArrowDown className='' />
                                                            {driverPayEditPermission && this.state.selectedCell === `update-defaultTaxType-${i}` &&
                                                                <SelectCustomComponent
                                                                    name='defaultTaxType'
                                                                    searchable={false}
                                                                    refClassName={`cell-${i}-4`}
                                                                    listData={VAT_DEFAULT_TYPE}
                                                                    onSelect={(name,value)=>{
                                                                        this.updateRow(name,value?.value,info,i)
                                                                        this.unselectCell()
                                                                    }}
                                                                    keyName="label"
                                                                    unselectCell={this.unselectCell}
                                                                />}
                                                        </div>
                                                    </td>}

                                                    <td
                                                        className={`text-right pointer cell-${i}-4  ${driverPayEditPermission && this.state.selectedCell === `update-amount-${i}` && 'newdriverrow td-clicked'}`}
                                                        onClick={() => {
                                                            this.selectCell(`update-amount-${i}`);
                                                        }}
                                                    >
                                                        <NumberFormatterV2 
                                                            id={`update-amount-${i}`}
                                                            currencyOption={info.invoiceCurrency ?? info.branchCurrency ?? this.props.selectedLoads?.invoiceCurrency ?? this.props.selectedLoads?.branchCurrency}
                                                            disabled={!driverPayEditPermission}
                                                            name={"amount"}
                                                            value={info.amount?.toFixed(2)}
                                                            min={this.acceptNegativeNumber ? undefined : 0}
                                                            selected={this.state.selectedCell === `update-amount-${i}`}
                                                            label={info.amount?.toCurrency(info.invoiceCurrency ?? info.branchCurrency ?? this.props.selectedLoads?.invoiceCurrency ?? this.props.selectedLoads?.branchCurrency)}
                                                            onBlur={(val) => {
                                                                const updateValue = parseFloat(val||0);
                                                                if(info.amount?.toFixed(2) !== updateValue?.toFixed(2)) this.updateRow('amount', updateValue, info, i);
                                                                this.unselectCell();
                                                            }}
                                                            onKeyPress={(event) => {
                                                                this.keyPressOnNumberInput(event);
                                                            }}
                                                        />
                                                    </td>
                                                    <td 
                                                      className={`datepicker-portal-${i} pointer ${driverPayEditPermission && this.state.selectedCell === `update-date-${i}` && 'newdriverrow td-clicked'}`}
                                                      onClick={() => {
                                                        let isDateSelect = `isDateSelect${i}`;
                                                        this.setState({ [isDateSelect]: true })
                                                          this.selectCell( `update-date-${i}`)
                                                      }}>
                                                        <div className="selectDate position-relative bg-transparent">
                                                            <div className={`${!info.time && 'text-center'}`}>
                                                                {info.time ? moment(info.time)
                                                                    .tz(this.userTimeZone)
                                                                    .format(DateTimeFormatUtils.abbreviatedDateFormat()) : "-"}
                                                            </div>
                                                            <div className="text-muted">
                                                                {info.time && moment(info.time)
                                                                    .tz(this.userTimeZone)
                                                                    .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                            </div>
                                                        </div>
                                                        {driverPayEditPermission && this.state[`isDateSelect${i}`] &&
                                                            <div>
                                                                <DatePortal
                                                                    refClassName={`datepicker-portal-${i}`}
                                                                    dateFormat={DateTimeFormatUtils.abbreviatedDateFormat()}
                                                                    displayTimeZone={this.userTimeZone}
                                                                    timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                                    onChange={(e) => {this.setState({ DateAndTime: e })}}
                                                                    defaultDate={info.time &&
                                                                        moment(info.time).tz(this.userTimeZone).format("lll")
                                                                    }
                                                                    dontShowSelectDate={true}
                                                                    isShowTime={true}
                                                                    hideShowSelectDate={true}
                                                                    className="right-0"
                                                                    OnBlurUpdate={() => {
                                                                        let isDateSelect = `isDateSelect${i}`;
                                                                        this.setState({ [isDateSelect]: false })
                                                                        this.unselectCell()
                                                                        this.handleChangeDate(this.state.DateAndTime, info, i)
                                                                    }}
                                                                />
                                                            </div>
                                                        }
                                                    </td>
                                                    {
                                                     transcations?.some(val =>(val?.settlement?.settlementNumber)) &&
                                                        <RedirectToSettlements
                                                            info={info}
                                                            redirectToSettlement={this.redirectToSettlement}
                                                            style={{ minWidth: "42px" }}
                                                            disabled={!settlementShowPermission}
                                                        />
                                                    }
                                                    <td>
                                                        {info.isDraft &&
                                                            <span className="pointer d-block text-center badge badge-soft-primary">
                                                                DRAFT
                                                            </span>
                                                        }
                                                        {!info.isDraft && (info.lock &&
                                                            info.approved === true ? (
                                                            <span className="pointer  d-block badge badge-purple-50 py-1 ">
                                                                Completed
                                                            </span>
                                                        ) : info.approved === true ? (
                                                            <Fragment>
                                                                <span
                                                                    className={`pointer d-block text-center badge ${isHovering
                                                                        ? "badge-danger"
                                                                        : "badge-accent-100"
                                                                        }`}
                                                                    onMouseEnter={() => driverPayApprovedPermission &&
                                                                        this.setState({
                                                                            isStatusHovered:
                                                                                info._id,
                                                                        })
                                                                    }
                                                                    onMouseLeave={() => driverPayApprovedPermission &&
                                                                        this.setState({
                                                                            isStatusHovered: null,
                                                                        })
                                                                    }
                                                                    onClick={() => driverPayApprovedPermission &&
                                                                        this.approveDeduction({
                                                                            _id: info._id,
                                                                            approved: info.approved
                                                                                ? false
                                                                                : true,
                                                                            key: i
                                                                        })
                                                                    }
                                                                >
                                                                    {isHovering ? (
                                                                        <span>UnApprove</span>
                                                                    ) : (
                                                                        <span>Approved</span>
                                                                    )}
                                                                </span>
                                                            </Fragment>
                                                        ) : (
                                                            <span
                                                                className={`pointer d-block text-center badge ${isHovering
                                                                    ? "badge-success"
                                                                    : "badge-danger-light"
                                                                    }`}
                                                                onMouseEnter={() => driverPayApprovedPermission &&
                                                                    this.setState({
                                                                        isStatusHovered: info._id,
                                                                    })
                                                                }
                                                                onMouseLeave={() => driverPayApprovedPermission &&
                                                                    this.setState({
                                                                        isStatusHovered: null,
                                                                    })
                                                                }
                                                                onClick={() => driverPayApprovedPermission &&
                                                                    this.approveDeduction({
                                                                        _id: info._id,
                                                                        approved: info.approved
                                                                            ? false
                                                                            : true,
                                                                        key: i
                                                                    })
                                                                }
                                                            >
                                                                {isHovering ? (
                                                                    <span>Approve</span>
                                                                ) : (
                                                                    <span>Unapproved</span>
                                                                )}
                                                            </span>
                                                        ))}
                                                    </td>
                                                    {<RedirectToRates
                                                        info={info}
                                                        redirectToRates={this.redirectToRates}
                                                        style={{ minWidth: "42px" }}
                                                    />}

                                                    <RedirectToChargeProfiles
                                                        info={info}
                                                        redirectToRates={this.redirectToRates}
                                                        style={{ minWidth: "42px" }}
                                                    />

                                                    {<DeleteCell
                                                        info={info}
                                                        deleteNewDriverPay={this.deleteNewDriverPay}
                                                        disabled={!driverPayDeletePermission}
                                                    />}
                                                </tr>
                                            </>

                                        );
                                    })}
                                {this.state.addDriverPay &&
                                    (<tr
                                        className={`shadow-md select-custom-input position-relative ${this.state.isFailed} ${this.state.isApiCall && 'loading'} newdriverrow rounded-1`}
                                        id="outline"
                                        style={{ transition: 'background .4s ease-out' }}
                                        ref={this.wrapperRef}
                                        onKeyDown={(e) => { !this.state.isApiCall && e.keyCode === 13 && this.addManual() }}
                                    >

                                    {((getStorage("currentUserRole") ==
                                        "fleetmanager" &&
                                        configuration.idForTerminal.indexOf(
                                            JSON.parse(getStorage("loggedInUser"))
                                                .fleetManager.carrier
                                        ) != -1) ||
                                        (getStorage("currentUserRole") == "carrier" &&
                                            configuration.idForTerminal.indexOf(
                                                getStorage("currentUserID")
                                            ) != -1)) && (
                                            <td
                                                className={`pointer cell-1 ${this.state.selectedCell === "add-terminal" && 'newdriverrow td-clicked'}`}
                                                
                                                onClick={() => this.selectCell("add-terminal","pointer cell-1")}
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {this.state.newDriverPay?.terminal?.label || `Select ${updateTerminology('Terminal')}`}
                                                    <IconAngleArrowDown className='' />
                                                    {this.state.selectedCell === "add-terminal" &&
                                                        <SelectCustomComponent
                                                            name='terminal'
                                                            searchable={true}
                                                            refClassName="cell-1"
                                                            listData={Terminal}
                                                            onSelect={this.handleOnChange}
                                                            keyName="label"
                                                            unselectCell = {this.unselectCell}
                                                            width={this.state.width}
                                                        />}
                                                </div>
                                            </td>
                                        )}
                                    <td
                                        className={`pointer cell-2 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.driver ? "transition-white-field" : this.state.isRequied} ${!this.state.newDriverPay.driver && 'requied_hover'}
                                            ${this.state.selectedCell === "add-driver" && 'newdriverrow td-clicked'}`}
                                        style={{ width: "195px", transition: 'background .4s ease-out' }}
                                        onClick={() => this.selectCell("add-driver")}
                                    >
                                            <div className="font-12 d-flex justify-content-between min-width-150"
                                               >
                                                {this.state.newDriverPay.driver ? <div className="d-flex align-items-center">
                                                    <span className="avatar-circle avatar-xs mr-1">
                                                        {this.state.newDriverPay?.driver?.label.charAt(
                                                            0
                                                        )}
                                                    </span>
                                                    {this.state.newDriverPay?.driver &&
                                                        this.state.newDriverPay?.driver.label}
                                                </div> : <div className="text-center w-100" >
                                                    <div className="position-relative d-inline-block">
                                                        <div className="pointer">
                                                            <CustomIconAddUser />
                                                        </div>
                                                    </div>
                                                </div>}
                                                {this.state.selectedCell === "add-driver" &&
                                                    <SelectCustomComponent
                                                        name="driver"
                                                        searchable={true}
                                                        refClassName="cell-2"
                                                        keyName="label"
                                                        listData={this.state.allDrivers}
                                                        onSelect={(name,el)=>this.handleOnChange("driver",el)}
                                                        unselectCell = {this.unselectCell}
                                                        loadOptions={this.getDriverList}
                                                    />
                                                }
                                            </div>
                                        </td>
                                        <td
                                            className={`pointer cell-3 pr-1 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.status ? "transition-white-field" : this.state.isRequied} ${!this.state.newDriverPay.status && 'requied_hover'} 
                                            ${this.state.selectedCell === "add-status" && 'newdriverrow td-clicked'}
                                            `}
                                            style={{ width: "195px", transition: 'background .4s ease-out' }}
                                            onClick={() => this.selectCell("add-status")}
                                        >
                                            <div className="d-flex justify-content-between align-items-center min-width-150">
                                            {
                                                (isLineTypeItem || isDriverPayChargeCodeEnabled()) ?
                                                (this.state.selectedChargeCode ? this.state.selectedChargeCode.label : "Select Status") :
                                                (this.state.newDriverPay?.status || "Select Status")
  }
                                                <IconAngleArrowDown className='' />
                                                {this.state.selectedCell === "add-status" &&
                                                ((isLineTypeItem || isDriverPayChargeCodeEnabled()) ?
                                                <SelectCustomComponent
                                                name='status'
                                                searchable={true}
                                                refClassName="cell-3"
                                                listData={this.chargeCodeOptions}
                                                onSelect={this.handleChargeCodeChange}
                                                unselectCell={this.unselectCell}
                                                keyName='label'
                                                /> :
                                                    <SelectCustomComponent
                                                    name='status'
                                                    searchable={true}
                                                    refClassName="cell-3"
                                                    listData={this.state.allLoadStatus}
                                                    onSelect={this.handleOnChange}
                                                    onCreateOption={!isStrictDriverPayTypes() && this.createStatus}
                                                    onDeleteItem={!isStrictDriverPayTypes() && this.removeStatus}
                                                    unselectCell={this.unselectCell}
                                                    />
                                                    )
                                                }
                                            </div>
                                        </td>
                                        <td
                                        className={`pointer cell-4 pr-1 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.from ? "transition-white-field" : this.state.isRequied} ${!this.state.newDriverPay.from && 'requied_hover'}
                                            ${this.state.selectedCell === "add-from" && 'newdriverrow td-clicked'}
                                            `}
                                        style={{ width: "195px", }}
                                        onClick={() => this.selectCell("add-from")}
                                    >
                                        <div className="d-flex justify-content-between align-items-center min-width-150">
                                            {this.state.newDriverPay?.from || "From a Company"}
                                            <IconAngleArrowDown className='' />
                                            {this.state.selectedCell === "add-from" &&
                                                <SelectCustomComponent
                                                    name='from'
                                                    searchable={true}
                                                    refClassName="cell-4"
                                                    listData={customersWithAddressLabel}
                                                    loadOptions={this.getFromTo}
                                                    onSelect={this.handleOnChange}
                                                    keyName="label"
                                                    unselectCell={this.unselectCell}
                                                />}
                                        </div>
                                    </td>
                                        <td
                                            className={`pointer cell-5  pr-1 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.to ? "transition-white-field" : this.state.isRequied} ${!this.state.newDriverPay.to && 'requied_hover'}
                                            ${this.state.selectedCell === "add-to" && 'newdriverrow td-clicked'}
                                            `}
                                            style={{ width: "195px", transition: 'background .4s ease-out' }}
                                            onClick={() => this.selectCell( "add-to")}
                                        >
                                            <div className="font-12 d-flex justify-content-between align-items-center min-width-150" >

                                                {this.state.newDriverPay?.to || "To a Company"}
                                                <IconAngleArrowDown className='' />
                                                {this.state.selectedCell === "add-to" &&
                                                    <SelectCustomComponent
                                                        name='to'
                                                        searchable={true}
                                                        refClassName="cell-5"
                                                        loadOptions={this.getFromTo}
                                                        listData={customersWithAddressLabel}
                                                        onSelect={this.handleOnChange}
                                                        keyName="label"
                                                        unselectCell={this.unselectCell}
                                                    />}
                                            </div>
                                        </td>
                                        <td className={`pointer cell-6 pr-20 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.amount ? "transition-white-field" : this.state.isRequied} "font-14 text-right text-dark" ${!this.state.newDriverPay.amount && 'requied_hover'}
                                          ${this.state.selectedCell === "add-amount" && 'newdriverrow td-clicked'}
                                        `}
                                            style={{ transition: 'background .4s ease-out', width: '120px' }}
                                            onClick={() => this.selectCell( "add-amount")}
                                        >
                                            <div className="min-width-100">                                            {!isGlobalizationEnabled() && <input
                                                className="form-control border-0 font-14 text-right text-dark bg-transparent p-0 line-height-30"
                                                placeholder={"0.00".toCurrency(this.state.addDriverPayInvoiceCurrency ?? Currency.default())}
                                                type="text"
                                                onKeyPress={(event) => {
                                                    if (!this.regex.test(event.key) || (event.target.value.indexOf('.')!=-1 && event.key===".") || (event.key==="-" && event.target.value !== '')) {
                                                      event.preventDefault();
                                                    }
                                                }}
                                                value={this.state?.newDriverPay?.amount ?? ''}
                                                onChange={(e) => this.handleOnChange("amount", e.target.value.replace('$', '').trim())}
                                                onBlur={this.unselectCell}
                                            />}
                                            {isGlobalizationEnabled() && (
                                                <NumberFormatterV2 
                                                    selected={this.state.selectedCell === "add-amount"}
                                                    className="form-control border-0 font-14 text-right text-dark bg-transparent p-0 line-height-30"
                                                    childClassName="form-control border-0 font-14 text-right text-dark bg-transparent p-0 line-height-30"
                                                    placeholder={"0.00"}
                                                    type="text"
                                                    onKeyPress={(event) => {
                                                        if (!this.regex.test(event.key) || (event.target.value.indexOf('.')!=-1 && event.key===".") || (event.key==="-" && event.target.value !== '')) {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    value={this.state?.newDriverPay?.amount}
                                                    onChange={(e) => this.handleOnChange("amount", e.target.value.replace('$', '').trim())}
                                                    onBlur={this.unselectCell}
                                                    currencyOption={this.state.addDriverPayInvoiceCurrency ?? Currency.default()}
                                                    showCurrency={true}
                                                />
                                            )}
                                            </div>
                                        </td>
                                        <td
                                            className={`datepicker-portal pointer cell-7 ${this.state.isFailed === "transition-white-field" && this.state.newDriverPay.date ? "transition-white-field" : this.state.isRequied} ${!this.state.newDriverPay.date && 'requied_hover'}
                                            ${this.state.selectedCell === "add-date" && 'newdriverrow td-clicked'}
                                            `}
                                            style={{ transition: 'background .4s ease-out' }}
                                            onClick={() => {
                                                this.setState({
                                                    isDateSelect: true,
                                                })
                                                this.selectCell("add-date")
                                            }}
                                        >
                                            <div tabIndex="1" id="driverPay-selectDate" className="selectDate position-relative bg-transparent min-width-100">

                                                <div className={`${!this.state.newDriverPay?.date && 'text-center'}`}>
                                                    {this.state.newDriverPay.date ? moment(this.state.newDriverPay.date)
                                                        .tz(this.userTimeZone)
                                                        .format(DateTimeFormatUtils.abbreviatedDateFormat()) : "-"}
                                                </div>
                                                <div className="text-muted">
                                                    {this.state.newDriverPay.date && moment(this.state.newDriverPay.date)
                                                        .tz(this.userTimeZone)
                                                        .format(`${DateTimeFormatUtils.timeFormat()}`)}
                                                </div>

                                            </div>
                                            {this.state.isDateSelect && this.state.selectedCell === "add-date" &&
                                                <div className="date-time-popup">
                                                    <DatePortal
                                                        refClassName={"datepicker-portal"}
                                                        dateFormat="MM/DD"
                                                        displayTimeZone={this.userTimeZone}
                                                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                        onChange={(e) => this.handleAddDates(e)}
                                                        defaultDate={this.state.newDriverPay.date &&
                                                            moment(this.state.newDriverPay.date).tz(this.userTimeZone).format("lll")
                                                        }
                                                        dontShowSelectDate={true}
                                                        isShowTime={true}
                                                        hideShowSelectDate={true}
                                                        className="right-0"
                                                        OnBlurUpdate={() => {
                                                            this.setState({ isDateSelect: false })
                                                            this.unselectCell()
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </td>
                                        {
                                            transcations?.some(val =>(val?.settlement?.settlementNumber)) &&
                                                <td></td>
                                        }
                                        <td>
                                        </td>
                                        <td style={{minWidth: "42px"}}>
                                        </td>
                                        <td>
                                            <Fragment>
                                                <button
                                                    onClick={() =>
                                                        this.setState({ addDriverPay: false, newDriverPay: newDriverPay ,isRequied:"transition-white-field"}, () => this.props.blockTabChange(false))
                                                    }
                                                    type="button"
                                                    className="btn btn-link text-muted pr-0 pl-0"
                                                >
                                                    <IconTrash />{" "}
                                                </button>

                                                <ReactTooltip id="delete" place="top">
                                                    <span>Delete</span>
                                                </ReactTooltip>
                                            </Fragment>


                                        </td>
                                    </tr>)
                                }

                            </tbody>
                    </table>
                    </div>
                    </div>
                <div className='mt-10'>
                    {driverPayEditPermission && 
                    <button
                        type="button"
                        className="btn btn-white btn-sm text-primary"
                        disabled={this.state.addDriverPay}
                        onClick={() => this.setState({ addDriverPay: true,isRequied:"transition-white-field",selectedCell:null })}
                    >
                        <IconPlus className="mr-2" />
                        Add Driver Pay
                    </button>
                    }
                </div>   
                    </> :
                    <ExpensesTable
                        chargeCodeList={this.chargeCodeList}
                        loads={this.props.selectedLoads}
                        id={this.props.selectedLoads.reference_number}
                        key={`sub_${this.props.selectedLoads.reference_number}`}
                        blockTabChange={this.props.blockTabChange}
                        selectedLoads={this.props.selectedLoads}
                        invoiceCurrency={this.props.selectedLoads?.invoiceCurrency}
                        branchCurrency={this.props.selectedLoads?.branchCurrency}
                        handleReloadOfBillingSummary={this.handleReloadOfBillingSummary}
                    />
                }
                {
                this.state.driverTariffModelId && (
                    <VendorLoadTariffModal
                    show={true}
                    isEdit={true}
                    rateRecordId={this.state.driverTariffModelId}
                    closeModal={() => this.closeDriverTariffModal()}
                    vendorType={vendorType.Driver}
                    />
                )
                }

                {
                    this.state.chargeProfileDetails && (
                        <AddRuleModal
                            isEditModal={true}
                            vendorType={vendorType.Driver}
                            close={this.closeChargeProfileModal}
                            show={this.state.chargeProfileDetails}
                            editSelectedData={this.state.chargeProfileDetails}
                            handleEditChargeProfile={this.handleOnEditComplete}
                        />
                    )
                }

            </>

        )
    }

    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser)
            if (loggedInUser.role === "carrier") return this.renderReturn();
            else if (loggedInUser.permissions.length !== 0)
                if (loggedInUser.permissions.includes("customer_service_driver_pay"))
                    return this.renderReturn();
                else
                    return (
                        <div className="nopermission">
                            <h1>You do not have permission to view this page</h1>
                        </div>
                    );
            else
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
    }
}



function mapStateToProps(state) {
    return {
        chargeCode: state.chargeCodeReducer.chargeCode,
        tmsLoads: state.TmsReducer.loads,
        drivers: state.TmsReducer.drivers,
        allCustomers: state.TmsReducer.allCustomers,
        accountingServiceInfo : state.accountingServiceReducer,
    };
}

export default connect(mapStateToProps, null)(NewDriverPay);