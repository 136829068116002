import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { getStorage } from '../../../../../../services';
import { convertFilterDataToStringArray, downloadCsvFromBuffer, formatTimeZoneString, getDataForChartFromDB, TypeDashBoardContext } from '../../helper';
import CounterCard from '../Common/CounterCard';
import SkeletonCard from '../Common/SkeletonCard';
import HorizontalBarChart from './HorizontalBarChart';

export default function BarChartCard(props) {
  const { 
    title,
    description,
    startDate,
    endDate,
    reportType,
    isDownload,
    tooltipPostFix,
    percentageChangeNotRequired,
    isToFixedNeeded,
    preFixOfcardCount,
  } = props

  const { filters, chartLevelFilter, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext)
  const [barChartData, setBarChartData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [fromDate, setFromDate] = useState(startDate)
  const [toDate, setToDate] = useState(endDate)
  const [isPopup, setIsPopup] = useState(false)
  const [filterPayload, setFilterPaylod] = useState(filters || {})

  const getDataForBarChart = async (limit, from, to, isDownload = false, filterData) => {
    try {
      if (!isDownload) setIsLoading(true)

      const _startDate = from || fromDate
      const _endDate = to || toDate

      let payload = {
        chartType: reportType,
        fromDate: _startDate,
        toDate: _endDate
      }

      if (limit) payload.limit = limit
      if (isDownload) payload.isDownload = true;
      payload.filter = convertFilterDataToStringArray(filterData) || {}

      let responseData = await getDataForChartFromDB(payload, reportType)

      if (!isDownload) setBarChartData(responseData)
      setIsLoading(false);
      setIsDashboardFilterApplied(false)
      return responseData;
    } catch (error) {
      console.log("🚀 ~ getDataForBarChart ~ error:", error)
      setIsLoading(false)
      if (!isDownload) setBarChartData({})
    }
  }

  const downloadCSV = async () => {
    const csvBuffer = await getDataForBarChart(0, startDate, endDate, true, chartLevelFilter?.[reportType] ?? {})
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`)
  }

  const handleApplyFilterForChart = (payload) => {
    getDataForBarChart(10, startDate, endDate, false, payload)
  } 

  useEffect(() => {
    getDataForBarChart(10)
  }, [])

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return
    setFromDate(startDate)
    setToDate(endDate)
    setFilterPaylod(filters)
    getDataForBarChart(10, startDate, endDate, false, filters)
  }, [startDate, endDate, filters])

  useEffect(() => {
    if (!isDashboardFilterApplied) return
    getDataForBarChart(10, startDate, endDate, false, filters)
  }, [isDashboardFilterApplied])

  const maxValue = barChartData?.reportData ? Math.max(...(barChartData?.reportData?.map(item => item?.count))) || 1500 : 100;

  if (isLoading)
    return <SkeletonCard style={{ width: "100%", height: "100%" }} />
  else
    return (
      <>
        <div className="bg-white rounded-5 d-flex flex-column h-100 justify-content-between">
          <CounterCard
            title={title}
            description={description}
            count={barChartData?.count}
            percentageChange={barChartData?.percentageChange}
            startDate={startDate}
            endDate={endDate}
            reportType={reportType}
            fromExpandedCard={true}
            downloadCSV={downloadCSV}
            isDownload={isDownload}
            isPopup={isPopup}
            setIsPopup={setIsPopup}
            percentageChangeNotRequired={percentageChangeNotRequired}
            isToFixedNeeded={isToFixedNeeded}
            preFixOfcardCount={preFixOfcardCount}
            handleApplyFilterForChart={handleApplyFilterForChart}
            isDownloadEnabled={!barChartData?.reportData?.length}
          />
          <div className='flex-grow-1 overflow-hidden d-flex flex-column px-20'>
            <HorizontalBarChart
              data={barChartData?.reportData}
              xAxisDataType={"number"}
              yAxisDataType={"category"}
              xAxisDomain={[0, maxValue || 100]}
              yAxisDataKey={"company_name"}
              barValueKey={"count"}
              lastUpdatedAt={""}
              showToolTip={true}
              reportType={reportType}
              downloadCSV={downloadCSV}
              startDate={startDate}
              endDate={endDate}
              isPopup={isPopup}
              setIsPopup={setIsPopup}
              tooltipPostFix={tooltipPostFix}
              isToFixedNeeded={isToFixedNeeded}
            />
            <div className='text-gray-300 font-12 font-weight-normal d-flex justify-content-end pb-10 text-capitalize'>
              Last updated at: {`${moment(barChartData?.lastUpdatedAt || new Date())
                .tz(getStorage("timeZone")).format('MM-DD-YY • hh:mm A')} (${formatTimeZoneString(getStorage("timeZone"))})`}
            </div>
          </div>
        </div>
      </>
    )
}
