import React, { useContext, useRef, useState } from "react";
import {
  IconHash,
  IconInbox,
  IconMailLabel,
  IconReply,
  IconReplyAll,
  IconStarOutline,
  IconTrash,
  IconMoveFolder,
  IconAngleArrowRight,
  IconDot,
} from "Components/Common/Icons";
import { createPortal } from "react-dom";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { useOnClickOutside } from "hooks";
import SharedSubMenu from "../../ContextSubMenus/SharedSubMenu";
import { renderLabel, renderMoveto } from "./dummyRenderContent.js";
import EmailTags from "../../EmailTags";
import { isEmbeddedEmailConnected } from "../../../helper.js";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, EMAIL_PROVIDER, EMAIL_TABS, OUTLOOK_PROVIDER, SEND_EMAIL_TYPE } from "../../../constant.js";
import { amplitudeTrack, getStorage } from "../../../../../../services/Common.services.js";
import AddLabelDropdown from "./AddLabelDropdown"
import _ from "lodash";
import MoveToDropdown from "./MoveToDropdown";

const CustomSubMenuLi = ({ label, boxRef, renderContent, icon }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const subBoxRef = useRef(null);
  const subCellLabelRef = useRef(null);

  let hideSubMenuTimeout,showSubMenuTimeout;
  const handleSubMenuMouseEnter = (index) => {
    clearTimeout(hideSubMenuTimeout); // Clear any pending hide timeout
    showSubMenuTimeout = setTimeout(() => {
      setHoveredIndex(index);
    }, 100);
  };
  
  const handleSubMenuMouseLeave = (e) => {
    clearTimeout(showSubMenuTimeout); // Clear any pending show timeout
    hideSubMenuTimeout = setTimeout(() => {
      if (boxRef.current.contains(e.relatedTarget)) {
        setHoveredIndex(null);
      }
    }, 100);
  
    if (e && e.relatedTarget) {
      // Check if the related target (where the mouse is going) is inside the boxRef
      if (boxRef.current && !boxRef.current.contains(e.relatedTarget)) {
        clearTimeout(hideSubMenuTimeout);
      }
    }
  };

  return (
    <li
      className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100"
      onMouseEnter={() => handleSubMenuMouseEnter(label)}
      onMouseLeave={(e) => handleSubMenuMouseLeave(e)}
      ref={subCellLabelRef}
    >
      {icon}
      <span class="font-14  ml-10">{label}</span>
      <IconAngleArrowRight className="ml-auto" />

      {hoveredIndex === label && (
        <SharedSubMenu
          subCellRef={subCellLabelRef}
          boxRef={boxRef}
          subBoxRef={subBoxRef}
          renderContent={renderContent}
          skin="light"
        />
      )}
    </li>
  );
};

const MoreDropdown = ({
  cellRef,
  closeDropdown,
  isExternalMessage,
  archiveMessage,
  deleteMessage,
  starredMail,
  actionBarOptions,
  handleClick,
  emailData,
  threadData,
  setRowOpen,
  handleCreateLabelModel,
  isEmailMode,
  handleBottomUnread,
  setShowCreateTag,
  activePage,
  isReadUnreadLoding,
  isToggleLoading,
  popThread,
  isFromLoad,
  closeModal
}) => {
  const boxRef = useRef();

  const popupPlacement = "auto";
  const moreDropdownStyles = useDropDownPosition(popupPlacement, boxRef, cellRef);

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {}

  useOnClickOutside([boxRef, cellRef], closeDropdown);
  const isEmailConnected = isEmbeddedEmailConnected()
  
  const { starredContent, starredOnClick, starredLabel } = starredMail;

  const junkEmailOptionDisabled = OUTLOOK_PROVIDER.includes(provider) && [EMAIL_TABS.JUNK_EMAIL].includes(activePage)

  return createPortal(
    <div
      className="w-300 bg-white rounded-10 py-10 position-fixed z-1051 overflow-auto shadow-5"
      ref={boxRef}
      style={moreDropdownStyles}
    >
      {isEmailConnected && 
        <ul className="list-style-none all-action-wrapper">
          <li
            className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${junkEmailOptionDisabled && 'pointer-not-allowed opacity-05'}`}
            onClick={() => {
              if(!junkEmailOptionDisabled) {
              handleClick(SEND_EMAIL_TYPE.REPLY)
              let eventProperties = {
                source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE
              }
              amplitudeTrack(AMPLITUDE_EVENTS.REPLY, eventProperties)
              }
            }}
          >
            <IconReply className="text-muted" />
            <span class="font-14 text-dark ml-10">Reply</span>
          </li>
          <li
            className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${junkEmailOptionDisabled && 'pointer-not-allowed opacity-05'}`}
            onClick={() => {
              if(!junkEmailOptionDisabled) {
              handleClick(SEND_EMAIL_TYPE.REPLY_ALL);
              let eventProperties = {
                source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE
              }
              amplitudeTrack(AMPLITUDE_EVENTS.REPLY_ALL, eventProperties)
              }
            }}
          >
            <IconReplyAll className="text-muted" />
            <span class="font-14 text-dark ml-10">Reply All</span>
          </li>
        </ul>
      }
      <div className="hr-light my-1" />
      <ul className="list-style-none all-action-wrapper">
        {!isExternalMessage && 
          <li className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${isToggleLoading && 'pointer-not-allowed opacity-05'}`} onClick={() => {
              if(!isToggleLoading) {
              starredOnClick();
              if (threadData) {
                let eventProperties = {
                source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE
              }
                if (threadData.starred) {
                amplitudeTrack(AMPLITUDE_EVENTS.UNSTAR, eventProperties)
                }
              else {
                amplitudeTrack(AMPLITUDE_EVENTS.STAR, eventProperties)
              }
              }
            }
          }}>
            {starredContent}
            <span class="font-14  ml-10">{starredLabel}</span>
        </li>}

        {!isExternalMessage && <li
          className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${isReadUnreadLoding && 'pointer-not-allowed opacity-05'}`}
          onClick={() => {
            if(!isReadUnreadLoding) {
            let eventProperties = {
              source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE
            }
            if (!threadData.unread) {
              handleBottomUnread([threadData.threadId], true)
              popThread && popThread()
              amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_UNREAD, eventProperties)
            } else {
              handleBottomUnread([threadData.threadId], false)
              amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_READ, eventProperties)
            }
            }
          }}
        >
          {isReadUnreadLoding ? 
            <span className="spinner-border spinner-border-sm batch-menu__itemicon" />
          : (<IconDot className="text-muted" />)}
          
          <span className="font-14  ml-10">{`${threadData?.unread ? 'Mark as Read' : 'Mark as Unread'}`}</span>
        </li>}
          <CustomSubMenuLi
            boxRef={boxRef}
            label="Tag as"
            renderContent={() => (
              <EmailTags
                threadData={threadData}
                event={AMPLITUDE_EVENTS.TAG_AS}
                setShowCreateTag={setShowCreateTag}
                closeDropdown={closeDropdown}
                eventProperties={{
                  source:
                    isEmailMode === "card"
                      ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE
                      : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE,
                }}
              />
            )}
            icon={<IconHash className="text-muted" />}
          />
        {!isExternalMessage && !isFromLoad && <>
            {provider === EMAIL_PROVIDER.GMAIL &&
              <CustomSubMenuLi
                boxRef={boxRef}
                label="Label"
            renderContent={() =>
                  <AddLabelDropdown
                    emailData={emailData}
                    threadData={threadData}
                    closeDropdown={closeDropdown}
                    setRowOpen={setRowOpen}
                    handleCreateLabelModel={handleCreateLabelModel}
                  />
                }
                icon={<IconMailLabel />}
              />
            }
            <CustomSubMenuLi
              boxRef={boxRef}
              label="Move to"
              renderContent={() => 
                <MoveToDropdown
                  threadData={threadData}
                  closeDropdown={closeDropdown}
                  handleCreateLabelModel={handleCreateLabelModel}
                  isEmailMode={isEmailMode}
                  activePage={activePage}
                  threadFolder={threadData?.folders??[]}
                  closeModal={closeModal}
                />
              }
              icon={<IconMoveFolder className="text-muted" />}
            />
          </>}
      </ul>
      

      {!isExternalMessage && !isFromLoad && (
        <>
          <div className="hr-light my-1" />
          <ul className="list-style-none all-action-wrapper">
            {actionBarOptions.map((action) => (
              <li className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${(action.disabled || action.loader) && 'pointer-not-allowed opacity-05'}`} onClick={!action.disabled && action.onClick} disabled={action.disabled}>
                {action.loader ? <span className="spinner-border spinner-border-sm batch-menu__itemicon" />: action.content}
                <span className="ml-10 font-14">{action.label}</span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>,
    document.getElementById(`pp-overlay-container`)
  );
};

export default MoreDropdown;
