import React, {useRef, useState, useEffect } from 'react'
import ChangeStatusPortal from "../portals/ChangeStatus"
import { changeLoadStatus } from '../../../../services'
import { convertTMSStatusName } from '../../../../../../Components/Common/functions'
import { errorColorResponse, successColorResponse } from '../../../utility'
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from '../../../../../../Components/Common/Spinner/CellSpinner'

const Status = [ "PENDING", "AVAILABLE","DEPARTED"];
const StatusList = [
	{ label: "PENDING", value: "PENDING" },
	{ label: "AVAILABLE", value: "AVAILABLE" },
	{ label: "DEPARTED", value: "DEPARTED" },
];
const ChangeStatusCell = ({ row, column, onRowChange, isCellSelected }) => {
    const prevSelectionRef = useRef();
    const cellRef = useRef()
    const cell = document.querySelector('[aria-selected="true"]');

    const [showBox, setShowBox] = useState(false);
    const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);


    const updateRow =(status) => {
        const payload = {
            reference_number: row.reference_number,
            status: status
        }
        setIsAssignInProgress(true);
        changeLoadStatus(payload).then(el => {
            onRowChange({ ...row, [column.key]: status });
            setIsAssignInProgress(false);
            setShowBox(false)
            successColorResponse(cell,column.idx, row?.className);
        }).catch((err) => {
            setIsAssignInProgress(false);
            setShowBox(false)
            errorColorResponse(cell,column.idx, row?.className)
        })
    }

    let statusObj = convertTMSStatusName(
        row.type_of_load,
        ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(row.status) >
          -1 && !row.isLive
          ? "DROPCONTAINER_DEPARTED"
          : row.status,
        row,
        false,
        true
      );
      
    useEffect(() => {
        if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
            setShowBox(Status.includes(row.status))
        }
        prevSelectionRef.current = isCellSelected
        if (!isCellSelected ) {
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

    useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.status);
	}, [column, row?.colorOfCells?.status]);

   

    return (
        <>
        <div ref={cellRef} className="cell-content" onClick={()=>setShowBox(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
       
            {isAssignInProgress && (
                <CellSpinner />
            )}
            {!isAssignInProgress && statusObj.status}
            {["PENDING", "AVAILABLE", "DEPARTED"].indexOf( row.status) !== -1 && showBox && !isAssignInProgress
                && <ChangeStatusPortal
                    CellRef={cellRef}
                    onClose={(val) => {
                        setShowBox(val);
                        const cell = document.querySelector('[aria-selected="true"]');
                        if (cell) cell.focus();
                    }}
                    onSelect={updateRow}
                    setIsAssignInProgress={setIsAssignInProgress}
                    Status={StatusList}
                />
            }
            
        </div>
        {isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="status" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
    </>
    )
}


export default ChangeStatusCell