
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import NewRow from './components/NewRow';
import DocUpload from '../../DocUpload';
import { checkAccountPayablePermission, checkIsVendorBillRebillEnabled, DateTimeFormatUtils, isAccountServiceEnabled, isMultiQBEnabled } from 'services';
import { IconPlus } from 'Components/Common/Icons';
import HoverBillSummary from "./components/HoverBillSummary";
import { IconTrash } from '../../../../../Components/Common/Icons';
import { useCarrierPaymentSet } from './hooks/useCarrierPaymentSet';
import StatusBadge from '../../Billing/Components/Badge/StatusBadge';
import ChargeSetModal from 'pages/tms/VendorBills/BillModal/BillModal';
import PopperTooltip from 'pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import { getAccountEntityList } from 'pages/tms/services';
import { useSelector } from 'react-redux';
import { DEPOSIT_ACCOUNT_TYPES } from 'pages/tms/Settings/QuickbooksSetup/constant';
import { getBillStatus, getPaymentStatus } from '../../../VendorBills/Components/vendorBillStatus';
import { useCurrentUser } from 'hooks';

const CarrierPaymentSet = ({ bill, refreshCarrierPaymentSection, handleDeleteCarrierPaymentSetRow, isFetching }) => {
  const currency = bill?.billFromDetail?.currency;
  const {
    timeZone,
    isNewRowShow,
    billNumberRef,
    selectedBillId,
    hoveredBill,
    handleShowBill,
    handleHoverBill,
    handleShowNewRow,
  } = useCarrierPaymentSet();

  const [accountToDeposite, setAccountToDepo] = useState([]);

  const { isCarrierPayEnabled, carrierPayAccountInfo } = useSelector((state) => state.accountingServiceReducer)
  const { currentUser } = useCurrentUser();
  const { vendorBillRebillPermission } = checkAccountPayablePermission(currentUser)
  useEffect(() => {
    if(isCarrierPayEnabled && isAccountServiceEnabled()){
      getAccountEntityList({...(isMultiQBEnabled()&&{accountID:carrierPayAccountInfo?.accountID})}).then((response)=>{
        if(response?.length>0){
          response = response.filter(e=> e.Type===DEPOSIT_ACCOUNT_TYPES.BANK||e.Type===DEPOSIT_ACCOUNT_TYPES.CREDIT_CARD)
        }
        setAccountToDepo(response??[]);
      }).catch(err=>setAccountToDepo([]))
    }
  }, [isCarrierPayEnabled]);

  return (
    <div className="card card-tborder border-gray mt-10 p-10" id="invoice-payment">
      <div className="d-flex align-items-center gap-10 pb-10 mb-1 border-bottom border-light">
        <div>
          <div className="font-14 font-medium">{bill?.billFromDetail?.company_name ?? '-'}</div>
          <div>
            <span className="text-muted">Bill #: </span>
            <span className="font-medium">{bill?.billNumber}</span>
          </div>
        </div>
        {
          false && (
            <ul className="nav nav-compact border-0" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${true === "applied-payments" ? "active" : ""}`}
                  href="#!"
                  onClick={() => console.log("applied-payments")}
                >
                  <span className="text-muted font-12 font-weight-500">Applied Payments</span>
                  <h6 className="m-0 font-12 font-weight-500 text-dark">{9999}</h6>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${true === "applied-credits" ? "active" : ""}`}
                  href="#!"
                  onClick={() => console.log("applied-credits")}
                >
                  <span className="text-muted font-12 font-weight-500">Applied Credits</span>
                  <h6 className="m-0 font-12 font-weight-500 text-dark">239923</h6>
                </a>
              </li>
            </ul>
          )
        }
      </div>

      <div className="d-flex justify-content-end gap-10 flex-wrap">
        <div className="d-flex align-items-center gap-10 flex-wrap">
          <div onClick={() => handleShowBill(bill?._id)} onMouseOver={() => handleHoverBill(bill)} onMouseLeave={() => handleHoverBill()} ref={billNumberRef}>
            <div className="text-muted">Bill #</div>
            <span className='text-primary pointer'>{bill?.billNumber}</span>
            {
              false && (
                <PopperTooltip
                  isArrow={false}
                  isOpen={hoveredBill}
                  refNo={billNumberRef?.current}
                  name={<HoverBillSummary timeZone={timeZone} bill={hoveredBill} />}
                  color={'gray-700'}
                  cssClass={"invoice_detail o-1 p-0 w-300"}
                />
              )
            }
          </div>
          <div>
            <div className="text-muted">Billing Date</div>
            <div className="font-medium">{bill?.billDate ? moment(bill?.billDate).tz(timeZone).format(DateTimeFormatUtils.verboseDateFormat()) : '-'}</div>
          </div>
          <div className="rbox text-right">
            <div className="rbox__title-mb">Bill Total</div>
            <div className="font-medium">{bill?.totalAmountWithTax ? parseFloat(bill.totalAmountWithTax).toFixed(2).toCurrency(currency) : '-'}</div>
          </div>
          <div className="rbox rbox--none text-right">
            <div className="rbox__title-mb">Billing Status</div>
            {bill?.paymentStatus ? getPaymentStatus(bill?.paymentStatus) : getBillStatus(bill?.status)}
          </div>
          <div className="rbox rbox--danger-50 text-right">
            <div className="rbox__title-mb">Balance Due</div>
            <div className="font-medium">{bill?.totalRemainAmount ? parseFloat(bill.totalRemainAmount).toFixed(2).toCurrency(currency) : '-'}</div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 p-1 mt-10 rounded-5">
        <div className="table-responsive">
          <table className="table table-card table-card-sm payment-table position-relative ">
            <thead>
              <tr>
                <th className="text-right">Amount</th>
                <th>Note</th>
                <th>Check #</th>
                {isAccountServiceEnabled() && <th>Account To Deposite</th>}
                <th>File</th>
                <th>Doc Type</th>
                <th>Date & Time</th>
                <th width="40px"></th>
              </tr>
            </thead>
            <tbody>
              {
                bill?.billPayment?.map((d, i) => (
                  <tr key={i.toString()} style={{ opacity: `${isNewRowShow ? '0.2' : ""}`, transition: 'background .4s ease-out' }}>
                    <td className=" font-14 text-right text-dark">
                      <div className="min-width-100">{d?.amount ? parseFloat(d.amount).toFixed(2).toCurrency(currency) : '-'}</div>
                    </td>
                    <td className="font-12 text-truncate">
                      <div className="min-width-200">{d?.notes ?? '-'}</div>
                    </td>
                    <td className="font-12">
                      <div className="min-width-150">{d?.checkNo ?? '-'}</div>
                    </td>
                    {isAccountServiceEnabled() && <td className="font-12">
                      <div className="min-width-150">{accountToDeposite?.find(e=>(e?.Id ?? e?.ID)===d?.DepositToAccountRef)?.Name?? '-'}</div>
                    </td>}
                    {
                      <DocUpload
                        addPayment={false}
                        onDocumentLoadSuccess={() => { }}
                        data={{ ...d, document: d?.document?.[0]?.url ?? '' }}
                      />
                    }
                    <td className="font-12 d-flex justify-content-between align-items-center min-width-200">{d?.depositType ?? '-'}</td>
                    <td className="font-weight-500">{d?.paymentDate ? moment(d?.paymentDate).tz(timeZone).format(DateTimeFormatUtils.abbreviatedDateTimeFormat()) : '-'}</td>
                    <td className={`text-center event_icon pointer ${isFetching ? "disabled-pointer-events" : ""}`} 
                        style={{ minWidth: "42px" }} 
                        onClick={() => {
                          if (isFetching) return
                          handleDeleteCarrierPaymentSetRow({ paymentId: d?._id })
                        }}
                    >
                      {d?.accountingInfo?.accountingId && checkIsVendorBillRebillEnabled() ? <button className='btn btn-sm btn-danger' disabled={isFetching || (checkIsVendorBillRebillEnabled() && !vendorBillRebillPermission) }>Void</button>: <IconTrash />}
                    </td>
                  </tr>
                ))
              }

              {isNewRowShow && <NewRow bill={bill} handleShowNewRow={handleShowNewRow} accountToDeposite={accountToDeposite} refreshCarrierPaymentSection={refreshCarrierPaymentSection} />}

            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-1">
          <button
            className="btn btn-white btn-sm text-primary"
            onClick={handleShowNewRow}
            disabled={isNewRowShow || !bill?.totalRemainAmount}
          >
            <IconPlus className="mr-1" />
            Add Payment to Bill
          </button>
          <div className="rbox rbox--primary-100 flex-grow-0">
            <span className="font-12 font-weight-normal text-gray-800">Payment Applied: </span>
            <span className="font-12 font-weight-500 text-dark">{bill?.totalPaidAmount ? parseFloat(bill.totalPaidAmount).toFixed(2).toCurrency(currency) : '-'}</span>
          </div>
        </div>
      </div>

      {
        selectedBillId && (
          <ChargeSetModal
            billId={selectedBillId}
            closeModal={handleShowBill}
            triggerRefresh={refreshCarrierPaymentSection}
          />
        )
      }
    </div>
  )
}

export default CarrierPaymentSet;