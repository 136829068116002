import { useState } from "react";
import { useEffect } from "react";
import { dateTimeConfig } from "../../../../../../../constants";

/**
 * useTimeRangePicker
 */
const useTimeRangePicker = ({ timeZone, defaultTimeSlot }) => {
  const hourOptions = Array.from({ length: dateTimeConfig.hoursLength }, (_, i) => {
    const hour = (i + 1).toString().padStart(2, "0");
    return { value: hour, label: hour };
  });

  const minuteOptions = Array.from({ length: dateTimeConfig.minutesLength }, (_, i) => {
    const minutes = i.toString().padStart(2, "0");
    return {
      value: minutes,
      label: minutes,
    };
  });

  const shiftTimeOptions = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
  ];

  const defaultTime = {
    hour: "00",
    min: "00",
    shift: "AM",
  };

  const [startTime, setStartTime] = useState(defaultTime);
  const [endTime, setEndTime] = useState(defaultTime);

  useEffect(() => {
    setStartTime(defaultTimeSlot?.startTime ?? defaultTime);
    setEndTime(defaultTimeSlot?.endTime ?? defaultTime);
  }, [defaultTimeSlot]);

  const resetForm = () => {
    setStartTime(defaultTime);
    setEndTime(defaultTime);
  };

  return {
    hourOptions,
    minuteOptions,
    shiftTimeOptions,
    defaultTime,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    resetForm,
  };
};

export default useTimeRangePicker;
