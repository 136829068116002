import React from "react";
import {
  IconBookMark,
  IconCart,
  IconHomeCheck,
  IconLoadCaretLeftRight,
  IconLoads,
  IconNoteClip,
  IconSteering,
  IconTruck,
  IconUsers,
} from "../../Common/Icons";
import { LOAD_LABELS } from "labels";

export const masterSearchHeader = [
  { 
    title: "All",
    icon: "",
    icon2: "",
    key: "ALL",
    apiText: 'all'
  },
  { 
    title: "Load",
    icon: <IconLoads className="wh-20px" />,
    icon2: <IconLoads className="mr-2" />,
    key: "LOAD",
    apiText: 'loads'
  },
  {
    title: LOAD_LABELS.CONTAINER_OWNER,
    icon: <IconBookMark className="wh-20px" />,
    icon2: <IconBookMark className="mr-2" />,
    key: "CONTAINEROWNER",
    apiText: 'containerOwners'
  },
  {
    title: LOAD_LABELS.CUSTOMER,
    icon: <IconUsers className="wh-20px" />,
    icon2: <IconUsers className="mr-2" />,
    key: "CUSTOMER",
    apiText: 'customers'
  },
  {
    title: LOAD_LABELS.CONTAINER_TYPE,
    icon: <IconNoteClip className="wh-20px" />,
    icon2: <IconNoteClip className="mr-2" />,
    key: "CONTAINERTYPE",
    apiText: 'containerTypes'
  },
  {
    title: LOAD_LABELS.CHASSIS_OWNER,
    icon: <IconHomeCheck className="wh-20px" />,
    icon2: <IconHomeCheck className="mr-2" />,
    key: "CHASSISOWNER",
    apiText: 'chassisOwners'
  },
  {
    title: LOAD_LABELS.CHASSIS_TYPE,
    icon: <IconLoadCaretLeftRight className="wh-20px" />,
    icon2: <IconLoadCaretLeftRight className="mr-2" />,
    key: "CHASSISTYPE",
    apiText: 'chassisTypes'
  },
  {
    title: LOAD_LABELS.DRIVER,
    icon: <IconSteering className="wh-20px" />,
    icon2: <IconSteering className="mr-2" />,
    key: "DRIVER",
    apiText: 'drivers'
  },
  {
    title: "Trucks",
    icon: <IconTruck className="wh-20px" />,
    icon2: <IconTruck className="mr-2" />,
    key: "TRUCKS",
    apiText: 'trucks'
  },
  {
    title: "Trailers",
    icon: <IconCart className="wh-20px" />,
    icon2: <IconCart className="mr-2" />,
    key: "TRAILER",
    apiText: 'trailers'
  },
];
