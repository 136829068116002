import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TrailerList from "../portals/TrailerList";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";
import PopperTooltip from "../portals/PopperTooltip";

const Container = ({ row, column, onRowChange, isCellSelected }) => {
  const cellRef = useRef();
  const prevSelectionRef = useRef();
  const dispatch = useDispatch();
  const fAction = useSelector((state) => state.dispatcherTableReducer);
  const [isOpen, setIsOpen] = useState(false);
  const referenceElementRef = useRef(null);

  const [isTrailerList, setIsTrailerList] = useState(false);
  const [isAssignInProgress, setIsAssignInProgress] = useState(false);

  useEffect(() => {
    if (fAction.reference_number === row.reference_number && fAction.field === column.key) {
      setIsOpen(true);
      setTimeout(() => {
        dispatch({ type: "CLEAR_VALUE" });
        setIsOpen(false);
      }, 500);
    } else if (fAction.reference_number === row.reference_number && fAction.action === "RESET_CONTAINER") {
      onRowChange({ ...row, containerNo: fAction.value });
      dispatch({ type: "CLEAR_VALUE" });
    }
  }, [fAction]);

  useEffect(() => {
    if (prevSelectionRef.current === false && isCellSelected) {
      setIsTrailerList(true);
    }
    prevSelectionRef.current = isCellSelected;
  }, [isCellSelected]);

  const value = row.type_of_load !== "ROAD" ? row.containerNo : row.trailer;
  return (
    <div
      ref={(node) => {
        cellRef.current = node;
        referenceElementRef.current = node;
      }}
      className="cell-content"
      data-testid="container-cell"
      onClick={() => setIsTrailerList(true)}
    >
      {isAssignInProgress && <CellSpinner />}
      {!isAssignInProgress && value ? <div className="rdg-truncate">{value}</div> : ""}
      {isTrailerList && !isAssignInProgress && row.type_of_load === "ROAD" && (
        <TrailerList
          Row={row}
          CellRef={cellRef}
          onClose={(val) => {
            setIsTrailerList(val);
            const a = document.querySelector('[aria-selected="true"]');
            if (a) a.focus();
          }}
          onRowChange={onRowChange}
          setIsAssignInProgress={setIsAssignInProgress}
          column={column}
        />
      )}
      {isOpen && fAction.reference_number === row.reference_number && fAction.field === column.key && (
        <PopperTooltip
          isOpen={isOpen}
          refNo={referenceElementRef?.current}
          name={"Copied"}
          color={"gray-700"}
          cssClass={"driver_assign"}
        />
      )}
    </div>
  );
};

export default Container;
