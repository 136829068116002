import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as $ from "jquery";
import _ from "lodash";
import mapboxgl from "mapbox-gl";
import moment from "moment";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import configuration from "../../config";
import AuditLoad from "../../pages/tms/Load/AuditLoad";
import NewBillOfLading from "../../pages/tms/Load/NewBillOfLading";
import Documents from "../../pages/tms/Load/Documents";
import Load from "../../pages/tms/Load/Load";
import LoadHistory from "../../pages/tms/Load/LoadHistory";
import { amplitudeTrack, convertMetersToCarrierDefault, getEmbeddedEmailAccount, getSessionStorage, isTerminalTrackingEnabled, updateTerminology } from '../../services/Common.services';
import {
  duplicateLoad, removeLoad, sendBillingEmail
} from "../../pages/tms/services";
import {
  convertUnit,
  getStorage, toastr, isTrialUser, isNewModal, isMilitaryTime, parsedAddressIntoLocality, splitStreetNameNumber, DateTimeFormatUtils, LoadInfoLabel, isCreditMemo, isShiposCarrier, isHideLoadSummary, handleRoutingTabChange, isNewBillingEnabled, checkIsCustomer, isCustomerAccount, isRadiusRateOn, isManageFleetOrOutSource, getCarrierId, isCustomerLogin,
  isEmbeddedEmailEnabled,
  isConnectedWithOutSource,
  IsELDEnabled 
} from "../../services/Common.services";
import confirm from "../Common/ConfirmAert";
import { CustomIconAlert, CustomIconSaveOutline } from "../Common/CustomIcons/Index";
import {
  IconAngleArrowLeft,
  IconAngleArrowRight, IconAt,
  IconBilling, IconCaretDown, IconClone, IconDocument,
  IconInfoFocus,
  IconMessage,
  IconNotes,
  IconPayment,
  IconPhone, IconPrinter, IconReset, IconSearchData, IconSendEmail, IconStatus,
  IconTrack, IconTrash, IconTruck, IconUserSingle, IconMapMarker, IconTimesThin, IconTimesThinClose, IconPlusSmall, IconCloseBold, IconCancel,
  IconBurgerInner,
  IconBurgerExapand,
  IconPlus
} from "../Common/Icons";

import ReactTooltip from "react-tooltip";
import StatusComponent from "../Common/StatusComponent";
import SubHeaderTab from "../../Components/SubHeaderTab";
import { debounce } from "throttle-debounce";
import * as actions from '../../pages/tms/Customer/actionCreators'
import { getLoadRoute, calculateLoadDistance } from "./actions";
import { streetTile } from "../../pages/trucker/NewTrack1/utility";
import NewPaymentInline from "../../pages/tms/Load/NewPaymentInline";
import { Modal } from "react-bootstrap";
import CustomTooltip from "../Common/CustomTooltip";
import DriverPay from "./Tabs/DriverPay";
import NewDriverPay from "./Tabs/NewDriverPay";
import CarrierPay from "./Tabs/CarrierPayComponent/CarrierPay"
import CarrierPayAndExpenses from "./Tabs/CarrierPayComponent/CarrierPayAndExpenses"
import NewOneLoadMessaging from "../../pages/tms/Load/NewOneLoadMessaging";
import NewNotes from "../../pages/tms/Load/NewNotes";
import NewLoadHistory from "../../pages/tms/Load/NewLoadHistory";
import SummaryDriverPay from "./SummaryDriverPay";
import SummaryDocuments from "../../pages/tms/SummaryDocuments";
import DrayosTrackAlert from "./DrayosTrackAlert";
import TenderReferenceNumber from "./TenderReferenceNumber";
import Port from "./Tabs/Components/Port";
import DeliveryWarehouse from "./Tabs/Components/DeliveryWarehouse";
import LoadRouting from "../../pages/tms/LoadRouting";
import NewSummaryRoutingHistory from "./NewSummaryRoutingHistory";
import SummaryCreditMemo from "./SummaryCreditMemo";
import { getByload } from "../../pages/tms/CreditMemo/actionCreator";
import { CREDITMEMODISPLAY } from "../../pages/tms/CreditMemo/constants";
import BillingMainTab from "../../pages/tms/Load/NewBillingComponents";
import BillingModule from "../../pages/tms/Load/Billing";
import PaymentsCreditsExtended from "pages/tms/Load/NewPaymentsCredits/PaymentsCreditsExtended";
import BrokerNewPaymentInline from "pages/tms/Load/BrokerPayment/BrokerNewPaymentInline";
import BrokerNewPaymentInlineExtended from "pages/tms/Load/BrokerPayment/BrokerNewPaymentInlineExtended";
import SummaryCreditMemoV2 from "./SummaryCreditMemoV2";
import { getByload as getByLoadV2 } from "pages/tms/CreditMemoV2/actionCreator";
import CustomerBilling from "pages/tms/Load/CustomerBilling";
import { isCustomerPortalUser } from "../../utils";
import NewDocumentsTab from "pages/tms/Load/NewDocumentsTab";
import { browserHistory } from "react-router"
import { getBillingChargeNotesForLoad } from "pages/tms/Load/Billing/actionCreator";
import { NEWBILLINGNOTES, OLDBILLINGNOTES } from "pages/tms/Load/Billing/Components/BillingCard/Charges/constant";
import config from "../../config";
import PayableExpenses from "./Tabs/PayableExpenses";
import AllMails from "pages/tms/Email/AllMails";
import { getLoadDetailFromRedis, lastUpdatedChannelName, lastUpdatedLoadChannel } from "../../services/common";
import { updateAccountInfo } from "pages/tms/Email/actionCreator";
import LoadTrackingHistory from "pages/tms/Load/LoadTrackingHistory/index";
import * as emailServices from '../../services/email.services'
import { isEmbeddedEmailConnected } from "pages/tms/Email/helper";
import EmailToolTip from "../../pages/tms/Email/Components/EmailDetails/EmailToolTip";
import EmailCommunicationLoad from "../../pages/tms/Email/Components/EmailCommunicationLoad";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { getTenderReferenceNumber } from "./actions";
import { DUAL_TRANSACTION_MESSAGES, createSource } from "pages/tms/constant";
import AlertBreakDualTransaction from "Components/Common/AlertBreakDualTransaction"
import { handleNavigateToCustomer, handleRightClickNavigateToCustomer } from "../Common/functions";
import { getUnreadCountByLoad } from "pages/tms/NewDispatcher/DispatcherTable/actionCreator";
import { LOAD_LABELS } from "labels";

const firebase = configuration.firebase;
const newFirebase = config.getNewFirebaseInstance;
const db = newFirebase('last-updated-load-customer-service-side-bar').database();

const polyline = require("@mapbox/polyline");
const carrierDetail = JSON.parse(getStorage("carrierDetail"));
const distanceMeasure = carrierDetail?.carrier?.distanceMeasure || "mi"

mapboxgl.accessToken = configuration.map_box_api_key;
let routePoints = [];

const NoPermission = () => {
  return (
    <div className="nopermission">
      <h1>You do not have permission to view this page</h1>
    </div>
  )
}

class CustomerServiceSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.type ? props.types : props.activeTab,
      selectedLoad: null,
      TMSCustomers: [],
      activeUsers: [],
      loggedUser: null,
      user: JSON.parse(getStorage("loggedInUser")),
      showBilling: true,
      showExtraData: false,
      isSavingLoad: false,
      isSaveOptions: false,
      billingActiveTab: props.billingActiveTab ? props.billingActiveTab : "main-customer",
      noteCount: 0,
      showAllCSRList: false,
      csrSearchTerm: "",
      isloading: false,
      assignedCSR: [],
      listAllCSR: [],
      supportMoves: [],
      showRemoveIcon: undefined,
      measureUnit: "Miles",
      totalDistance: "",
      isShowingPrompt: false,
      isBlockingTabChange: false,
      nextTab: undefined,
      closingSideBar: false,
      toChangeLoad: undefined,
      AllCreditMemo: [],
      AllCreditMemoV2: [],
      isOldBilling: false,
      hideSummaryInfo : false,
      showSecondaryDistance: isRadiusRateOn(),
      chargesNoteCount: 0,
      newSyncRequests : {},
      totalTollDistance: '',
      unreadMailCount : 0,
      tenderDetails: null
    };
    this.wrapperRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._duplicateLoad = this._duplicateLoad.bind(this);
    this.getHeaderButtons = this.getHeaderButtons.bind(this);
    this.blockTabChange = this.blockTabChange.bind(this);
    this.changeTopTab = this.changeTopTab.bind(this);
    this.showPrompt = this.showPrompt.bind(this);
    this.continueWithoutSaving = this.continueWithoutSaving.bind(this);
    this.updateLoadSummaryCreditMemos = this.updateLoadSummaryCreditMemos.bind(this);
    this.closeCustomerServiceSideBar = this.closeCustomerServiceSideBar.bind(this);
    this.handleNextPrevLoad = this.handleNextPrevLoad.bind(this);
    this.setNewCreditMemo = this.setNewCreditMemo.bind(this);
    this.getLoadNotesCount = this.getLoadNotesCount.bind(this);
    this.routeLayers = [];
    this.routeLayerGroup = null;
    this.isShipOsCarrier = isShiposCarrier()
    this.isChassisV3 = JSON.parse(getStorage('userBasicSettings'))?.isChassisV3;
    this.orderForCheck = [];
    this.timezone = getStorage('timeZone');
    this.isManageFleetOrOutSource = isManageFleetOrOutSource()
  }


  handleTabChange = (value) => {
    this.setState({ billingActiveTab: value, isBlockingTabChange: false });
  };

  updateActiveTab = (tab) => {
    if(!tab) return;
    this.setState({activeTab: tab});
  }

  setOrderForCheck = (driverOrder) => {
    this.orderForCheck = _.cloneDeep(driverOrder)
  }
  handleExpenseTab = () => {
    this.setState({ isBlockingTabChange: false });
  }
  componentDidUpdate(props) { }
  handleSubmitLoad = () => { };

  showMap(props) {
    if (props.showSidebar) {
      setTimeout(() => {
        this.loadMap();
      }, 1000);
      setTimeout(() => {
        this._getLoadRoute();
      }, 2000);
    }
  }


  componentWillReceiveProps(props) {
    if (props.selectedLoad) {
      this.setDistanceUnit();
      this.setState({
        selectedLoad: props.selectedLoad,
        assignedCSR: props.selectedLoad.assignedCSR,
        activeTab: props.activeTab
      });
    }
  }

  getFirebaseFieldName(path) {
    let fieldName = "test";
    if (path.toLowerCase() === "load".toLowerCase()) {
      fieldName = "loadinfo";
    } else if (path.toLowerCase() === "billing".toLowerCase()) {
      fieldName = "billing";
    } else if (path.toLowerCase() === "documents".toLowerCase()) {
      fieldName = "document";
    } else if (path.toLowerCase() === "payments".toLowerCase()) {
      fieldName = "payment";
    } else if (path.toLowerCase() === "orderstatus".toLowerCase()) {
      fieldName = "statusorder";
    } else if (path.toLowerCase() === "driverpay".toLowerCase()) {
      fieldName = "driverpay";
    }
    return fieldName;
  }

  getLoadNotesCount = (load) => {
    let loadChargeCount = 0
    let loadNoteCount = 0
    const notes = isNewBillingEnabled() ? NEWBILLINGNOTES : OLDBILLINGNOTES;
    if (load) {
      for (const [keys, values] of Object.entries(load)) {
        notes.forEach((d) => {
          if (keys.includes(d) && values !== "") {
            loadNoteCount = loadNoteCount + 1;
          }
        });
      }
      const loadId = load?.reference_number === this.state.selectedLoad.reference_number ? this.state.selectedLoad._id : load?._id
      if(isNewBillingEnabled() && !isCustomerPortalUser()){
        getBillingChargeNotesForLoad({loadId}).then((res) => {
          if(res?.data.length){
            res?.data?.forEach((charge) => {
              if(charge?.billingNote) loadChargeCount = loadChargeCount + 1;
            })
          }
          this.setState({chargesNoteCount: loadChargeCount, noteCount: loadNoteCount + loadChargeCount})
          return loadChargeCount;
        })
      }else {
        this.setState({ noteCount: loadNoteCount})
      }
    }
  }
  componentDidMount() {
    // this.fetchUsers();
    this.setState({ selectedLoad: this.props.selectedLoad, assignedCSR: this.props.selectedLoad.assignedCSR }, () => {
      if (
        this.state.selectedLoad &&
        Object.keys(this.state.selectedLoad).length &&
        getStorage("loggedInUser") != null
      ) {
        const currentUser = JSON.parse(getStorage("loggedInUser"));
        this.setState({ loggedUser: currentUser });
        this.getTenderReferenceNumberWithLoad();
        let carrierId = currentUser._id;
        if (currentUser.role === "driver") {
          carrierId = currentUser.driver.carrier;
        }
        if (currentUser.role === "fleetmanager") {
          carrierId = currentUser.fleetManager.carrier;
        }
        if (isCustomerLogin() && currentUser?.customer?.customerType && !([currentUser?.customer?._id, ...(currentUser?.customer?.extraSubCustomer || [])].includes(this.state?.selectedLoad?.caller?._id))) {
          this.setState({ showBilling: false });
        }
        if(currentUser.permissions?.length !== 0) {
          if(isCustomerPortalUser() && !currentUser.permissions.includes("customer_employee_load_summary")){
            this.setState({hideSummaryInfo: true});
          }
        }

         // Listen Load Updates
         this.socketLastUpdateLoad = db.ref(lastUpdatedLoadChannel());
        if (this.props.isFirebaseUpdateNeeded) {
          this.socketLastUpdateLoad.on("value", (snapshot) => {
            try {
              const data = snapshot.val()
              const loadUpdates = JSON.parse(data);
              if(this.state.selectedLoad.reference_number === loadUpdates.reference_number && moment().diff(moment(loadUpdates.updatedAt), "seconds") <= 10){
                getLoadDetailFromRedis(this.state.selectedLoad.reference_number, loadUpdates).then((res) => {
                  if(res){
                    this.setState({
                      selectedLoad: res,
                    })
                  }
              })
              }
            } catch(e) {
              console.log("Tracking exception: ", e);
            }
          });
        }
        if(isConnectedWithOutSource()) {
        this.socketupdatedForSyncRequests = firebase.database().ref(`${getCarrierId()}/updateSyncRequest`);
        this.socketupdatedForSyncRequests.on("value", (snapshot) => {
          const data = snapshot.val()
          const newSyncRequests = JSON.parse(data)
          if (newSyncRequests
             && newSyncRequests.load_reference_number === this.state.selectedLoad.reference_number) {
            this.setState({newSyncRequests})
          }
        }, (error) => {
          console.log("error", error)
        })
        }
        this.getLoadNotesCount(this.state.selectedLoad)
        if (this.hasCommunicationTabPermission() &&
          isEmbeddedEmailEnabled()) {
            this.getUnreadEmailCount()
        }
      }
    });
    document.addEventListener("mousedown", this.handleClickOutside);
    this.showMap(this.props);
    this.listAllCSR();
    this.setDistanceUnit();
    const subCheck = this.isSubAfterInvoice(this.props.selectedLoad?.additionalPricing)
    if ((CREDITMEMODISPLAY.includes(this.props.selectedLoad.status) || subCheck) && isCreditMemo() && !isNewBillingEnabled()) {
      this.getAllCreditMemo()
    }
    if(isNewBillingEnabled() && isCreditMemo() && !checkIsCustomer()){
      this.getAllCreditMemoV2()
    }
  }

  getUnreadEmailCount = ()=>{
    const isEmailConnected = isEmbeddedEmailConnected()
    const params = {loadIds: [this.state.selectedLoad._id]}
    if(!isEmailConnected) params.skipMiddleWare = true 
    getUnreadCountByLoad(params).then((data) => {
      const counts = data?.data ?? {}
      this.setState({ unreadMailCount: counts[this.state.selectedLoad._id] ?? 0 })
    })
  }
  isSubAfterInvoice = (additionalPricing) => {
    if (additionalPricing) {
      const filterdPricing = additionalPricing?.filter((pricing) => CREDITMEMODISPLAY.includes(pricing.status))
      return filterdPricing?.length > 0 ? true : false
    } else return false
  }

  componentWillUnmount() {
    const scroller = document?.getElementById('load-tabs');
    scroller?.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleClickOutside);
    if (this.socketLastUpdateLoad) {
      this.socketLastUpdateLoad.off("value");
    }
    if(this.socketupdatedForSyncRequests){
      this.socketupdatedForSyncRequests.off("value")
    }
    if (this.map) {
      this.map.remove();
    }
    this.handleClickOutside = null;
    this._duplicateLoad = null;
    this.getHeaderButtons = null;
  }

  handleClickOutside(event) {
    if (
      this.state.isSaveOptions &&
      this.wrapperRef?.current &&
      !this.wrapperRef?.current.contains(event.target)
    ) {
      this.setState({ isSaveOptions: false });
    }
    if (this.state.showAllCSRList && this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showAllCSRList: false })
    }
  }


  getAllCreditMemo() {
    let params = {
      reference_number: this.props.selectedLoad?.reference_number,
    }
    getByload(params)
      .then((result) => {
        if (result) {
          this.setState({
            AllCreditMemo: result
          })
        }
      })
      .catch((err) => {
        console.log("err -*-*- ", err)
      })
  }

  getAllCreditMemoV2() {
    let params = {
      reference_number: this.props.selectedLoad?.reference_number,
    }
    getByLoadV2(params)
      .then((result) => {
        if (result) {
          this.setState({
            AllCreditMemoV2: result
          })
        }
      })
      .catch((err) => {
        console.log("err -*-*- ", err)
      })
  }

  setNewCreditMemo = (newData, actions) => {
    let replace = _.cloneDeep(this.state.AllCreditMemo);
    let index = replace.findIndex(item => item._id === newData?._id);
    if (index > -1) {
      replace[index] = newData;
    } else if (actions === "remove") {
      let index = replace.findIndex(item => item.credit_memo_number === newData);
      if (index > -1) {
        replace.splice(index, 1);
      }
    } else {
      replace.push(newData)
    }
    this.setState({
      AllCreditMemo: replace
    })
  }
  checkAddress() {
    let isValid = true
    // if (this.state.activeTab == "orderstatus" && !handleRoutingTabChange(this.orderForCheck, this.props.selectedLoad.driverOrder)) isValid = false
    return isValid
  }
  changeTopTab(tab) {
    if (tab !== this.state.activeTab && this.state.isBlockingTabChange) {
      this.setState({ isShowingPrompt: true, nextTab: tab });
    } else {
      if (this.props && this.props.changeCustomerServiceSidebarTab) {
        this.props.changeCustomerServiceSidebarTab(
          tab
        );
      }
      this.setState({ activeTab: tab, nextTab: undefined })
    }
  }
  showPrompt(value) {
    this.setState({ isShowingPrompt: value })
  }

  blockTabChange(value) {
    this.setState({ isBlockingTabChange: value })
  }
  handleClosePrompt() {
    if (this.state.isBlockingTabChange) {
      this.setState({ isShowingPrompt: true });
    } else {
      this.props.closeCustomerServiceSideBar()
    }
  }
  updateLoadSummaryCreditMemos(data) {
    this.setState({ AllCreditMemoV2: data })
  }
  continueWithoutSaving() {
    this.setState({ isShowingPrompt: false, isBlockingTabChange: false }, () => {
      if (this.state.nextTab) {
        this.changeTopTab(this.state.nextTab)
      }
      if (this.state.closingSideBar) {
        this.closeCustomerServiceSideBar()
      }
      if (this.state.toChangeLoad) {
        this.handleNextPrevLoad(this.state.toChangeLoad)
      }
      if (this.state.isCloseModal) {
        this.handleClosePrompt()
      }
    });
  }
  closeCustomerServiceSideBar() {
    if (this.state.isBlockingTabChange) {
      this.showPrompt(true);
      this.setState({ closingSideBar: true })
    } else {
      this.setState({ closingSideBar: false })
      this.props.closeCustomerServiceSideBar()
    }
  }
  handleNextPrevLoad(dir) {
    let handleFunction = dir == 'next' ? this.props.handleNextLoad : this.props.handlePrevLoad;
    if (this.state.isBlockingTabChange) {
      this.showPrompt(true);
      this.setState({ toChangeLoad: dir })
    } else {
      this.setState({ toChangeLoad: undefined })
      handleFunction()
    }
  }
  updateRouteLine() {
    if (this.map) {
      this.addRouteToMap();
    }
  }

  boundToRouteLine() {
    let coordinates = routePoints[0];
    if (coordinates.length > 0) {
      this.map.fitBounds(new window.L.LatLngBounds(coordinates[0], coordinates[coordinates.length - 1]));
    }
  }

  addRouteToMap() {

    if (!this.routeLayerGroup) {
      const layerGroup = new window.L.LayerGroup(null, {
        pane: "overlayPane",
      });
      layerGroup.addTo(this.map);
      this.routeLayerGroup = layerGroup;
    }
    if (this.routeLayers.length > 0 && this.routeLayerGroup) {
      // delete existing routes
      this.routeLayerGroup.clearLayers();
      this.routeLayers = [];
    }

    const routeLayer = window.L.polyline(routePoints[0].map((e) => e.reverse()), {
      color: "var(--color-primary-500)",
      weight: 7,
      opacity: 1.0,
    })

    this.routeLayers.push(routeLayer);
    routeLayer.addTo(this.routeLayerGroup);
    this.boundToRouteLine();
  }

  checkUserPermisison = (permission) => {
    let allow = false;
    const { user } = this.state;
    if (user) {
      if (user.role === "carrier") {
        allow = true;
      } else if (user.permissions) {
        permission.map((x) => {
          if (user.permissions.indexOf(x) > -1) {
            return (allow = true);
          }
        });
      }
      return allow;
    }
  };

  loadMap() {
    if (this.mapContainer) {
      if (!this.map) {
        // this.map = new mapboxgl.Map({
        //   container: this.mapContainer,
        //   style: "mapbox://styles/mapbox/streets-v9",
        //   center: [-75.25, 35.71],
        //   zoom: 1,
        // });

        this.map = new window.L.Map("CustomerServiceSidebarMap", {
          zoom: 1,
          minZoom: 1,
          center: [35.71, -75.25],
          layers: [streetTile()],
        });
      }
    }
  }

  customerDetails() {
    $(".cm-d").toggle();
  }

  enableload(_id) {
    confirm(
      "Enable Load",
      "Are you sure you want to enable this load?",
      (value) => {
        if (value) {
          removeLoad(_id, true).then(() => {
            toastr.show("Successfully Enable", "success");
            this.props.refreshDispatcher();
            this.props.closeCustomerServiceSideBar();
          });
        }
      }
    );
  }

  removeload(_id) {
    confirm(
      "Delete Load",
      "Are you sure you want to delete this load?",
      (value) => {
        if (value) {
          removeLoad(_id).then((el) => {
            this.props.closeCustomerServiceSideBar((this.props.isStreetTurn || this.props.isDualTransaction) ?? false);
            toastr.show("Removed.", "success");
            this.props.refreshDispatcher && this.props.refreshDispatcher();
          });
        }
      }
    );
  }

  _duplicateLoad(count, reference_number, carryOverPricing, carryOverDriverAndBillingNotes, createWithExistingLoadRouting) {
    const payload = { reference_number };

    if (count > 50) {
      toastr.show("You have exceeded the number of times allowed!", "error");
      return;
    }
    if (!count) {
      toastr.show("You must enter a value between 0 and 50.", "error");
      return;
    }

    if (carryOverPricing) {
      payload.carryOverPricing = carryOverPricing;
    }

    if (carryOverDriverAndBillingNotes) {
      payload.carryOverDriverAndBillingNotes = carryOverDriverAndBillingNotes
    }

    payload.createWithExistingLoadRouting = createWithExistingLoadRouting ?? true;

    duplicateLoad(payload).then(() => {
      count -= 1;
      if (count > 0) {
        this._duplicateLoad(count, reference_number, carryOverPricing, carryOverDriverAndBillingNotes, createWithExistingLoadRouting);
      } else {
        toastr.show("Added!", "success");
        this.props.refreshDispatcher();
        this.props.closeCustomerServiceSideBar();
      }
    });
  }

  duplicateLoad(reference_number) {
    const options = {
      showInputText: true,
      showCheckBox: true,
    };

    confirm(
      "Duplicate This Load",
      "How Many New Loads Do You Want To Create From This Load?",
      async (confirm, count, carryOverPricing, carryOverDriverAndBillingNotes, value1, value2, createWithExistingLoadRouting) => {
        if (confirm)
          this._duplicateLoad(count, reference_number, carryOverPricing, carryOverDriverAndBillingNotes, createWithExistingLoadRouting);
      },
      options
    );
  }

  previewOtherDocument = (type) => {
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.state.selectedLoad.reference_number
    );
    formData.append("type", type);

    sendBillingEmail(formData).then((result) => {
      const fileName = `${this.state.selectedLoad.reference_number}-${type}.pdf`;
      var byteArray = new Uint8Array(result.data.data.data);
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], { type: "application/octet-stream" })
      );
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  _getLoadRoute() {
    if (this.map) {
      const { selectedLoad = {} } = this.props;
      const { reference_number } = selectedLoad;
      this.map && this.map.stop();
      if (reference_number) {
        this.setState({ isMapRouteLoading: true });
        getLoadRoute(reference_number)
          .then((routePath) => {
            //let routePoints = [];
            let pointsArr = routePath.map((d) => polyline.decode(d));
            //routePoints = pointsArr.slice().flat()
            routePoints = pointsArr
              .slice()
              .map((pArr) => pArr.map((p) => p.slice().reverse()));
            //geojson.data.geometry.coordinates = routePoints[2];
            this.updateRouteLine();
          })
          .catch(() => {
            this.setState({ isMapRouteLoading: false });
          });
      }
    }
  }

  getHeaderButtons = (isDeleteEnable, disabledSaveButton) => {
    return (
      <div className="ml-auto position-relative d-flex">
        {this.props.isFromCustomerService && this.props.customerServiceButtons}
        {this.checkUserPermisison(["print_all_docs"]) && this.props.printModal && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            onClick={() => this.props.printModal()}
          >
            <IconPrinter />
            <CustomTooltip position="top-center" content="Print" />
          </button>
        )}
        {this.props.duplicateLoad && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            disabled={isTrialUser() ? true : false}
            onClick={() => {
              this.props.duplicateLoad(
                this.state.selectedLoad.reference_number
              );
            }}
          >
            <IconClone />
            <CustomTooltip position="top-center" content="Duplicate Load" />
          </button>
        )}
        {isDeleteEnable && (
          <button
            className="btn btn-link mr-1 tooltip-label"
            onClick={async () => {
              const isDualTransactionLoad = this.state.selectedLoad?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
              if (isDualTransactionLoad) {
                await AlertBreakDualTransaction(this.state.selectedLoad, DUAL_TRANSACTION_MESSAGES.RESET_LOAD);
              }
              this.props.isFromCustomerService ? this.props.removeLoad() : this.removeload(this.state.selectedLoad._id);
            }}
          >
            <IconTrash />
            <CustomTooltip position="top-center" content="Remove Load" />
          </button>
        )}
        {!this.props.isFromCustomerService &&
          <button
            className="btn btn-outline-light mr-2 "
            onClick={() => this.closeCustomerServiceSideBar()}
          >
            Close
          </button>
        }
        <div
          className="position-relative mr-2"
          ref={this.wrapperRef}
        >
          <button
            className="btn btn-success p-0"
            disabled={disabledSaveButton || this.state.isSavingLoad}
          >
            <div
              style={{
                padding: "5px 10px",
                paddingRight:
                  this.state.activeTab === "billing" &&
                    this.state.billingActiveTab === "main-customer"
                    ? 0
                    : 10,
              }}
              onClick={() => !disabledSaveButton && this.handleSubmitLoad()}
            >
              {this.state.isSavingLoad && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save Changes
            </div>
            {this.state.activeTab === "billing" &&
              this.state.billingActiveTab === "main-customer" && (
                <span
                  className="pr-1"
                  onClick={() =>
                    !disabledSaveButton &&
                    this.setState({
                      isSaveOptions: !this.state.isSaveOptions,
                    })
                  }
                >
                  <IconCaretDown />
                </span>
              )}
          </button>
          {this.state.activeTab === "billing" &&
            this.state.billingActiveTab === "main-customer" &&
            this.state.isSaveOptions && (
              <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                <span
                  className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                  onClick={() => {
                    this.handleSubmitLoad(true, false);
                    this.setState({ isSaveOptions: false });
                  }}
                >
                  <CustomIconSaveOutline className="mr-3" />
                  {`Save & Add to Customer Rates`}
                </span>

                <React.Fragment>
                  <hr className="m-2" />
                  <span
                    className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    onClick={() => {
                      this.handleSubmitLoad(false, true);
                      this.setState({ isSaveOptions: false });
                    }}
                  >
                    <IconSendEmail className="mr-3" />
                    {`Save & Send Rate Confirmation`}
                  </span>
                </React.Fragment>
              </div>
            )}
        </div>
        {this.props.handlePrevLoad && (
          <button
            className="btn btn-outline-light mr-2 tooltip-label"
            onClick={() => this.handleNextPrevLoad('prev')}
          >
            <IconAngleArrowLeft />
            <CustomTooltip position="top-center" content="Prev. Load" />
          </button>
        )}
        {this.props.handleNextLoad && (
          <button
            className="btn btn-outline-light tooltip-label"
            onClick={() => this.handleNextPrevLoad('next')}
          >
            <IconAngleArrowRight />
            <CustomTooltip position="top-center" content="Next Load" />
          </button>
        )}
      </div>
    )
  }
  handleScroll = (e) => {
    const scroller = e.target;
    const header = document?.getElementById('load-tabs');
    scroller.addEventListener("scroll", () => {
      if (scroller?.scrollTop > 10) header?.classList.add("shadow-3", "z-1")
      else header?.classList.remove("shadow-3", "z-1");
    });
  }
  getNewHeaderButtons = (isDeleteEnable, disabledSaveButton) => {
    return (
      <div className="ml-auto position-relative d-flex gap-5 navbar--icon">
        {this.props.isFromCustomerService && this.props.customerServiceButtons}
        {this.checkUserPermisison(["print_all_docs"]) && this.props.printModal && (
          <div>
            <button
              data-tip
              data-for="printicon"
              className="btn btn-link bg-white day-card"
              onClick={() => this.props.printModal()}
            >
              <IconPrinter />
            </button>
            <ReactTooltip id="printicon" place="top" effect="solid" className="react-tooltip-new">
              {"Print"}
            </ReactTooltip>
          </div>
        )}
        {!this.isShipOsCarrier && this.props.duplicateLoad && !isCustomerAccount() && (
          <div>
            <button
              data-tip
              data-for="cloneicon"
              className="btn btn-link bg-white day-card"
              disabled={isTrialUser() ? true : false}
              onClick={() => {
                this.props.duplicateLoad(
                  this.state.selectedLoad.reference_number
                );
                document.querySelector(".load-info-modal")?.removeAttribute("tabindex")
              }}
            >
              <IconClone />
            </button>
            <ReactTooltip id="cloneicon" place="top" effect="solid" className="react-tooltip-new">
              {"Duplicate Load"}
            </ReactTooltip>
          </div>
        )}

        {isDeleteEnable && (
          <div>
            <button
              data-tip
              data-for="removeicon"
              className="btn btn-link bg-white day-card"
              onClick={async () => {
                const isDualTransactionLoad = this.state.selectedLoad?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
                if (isDualTransactionLoad) {
                  await AlertBreakDualTransaction(this.state.selectedLoad, DUAL_TRANSACTION_MESSAGES.RESET_LOAD);
                }
                this.props.isFromCustomerService ?
                  this.props.removeLoad() :
                  this.removeload(this.state.selectedLoad._id);
              }}
            >
              <IconTrash />
            </button>
            <ReactTooltip id="removeicon" place="top" effect="solid" className="react-tooltip-new">
              {"Remove Load"}
            </ReactTooltip>
          </div>
        )}
        {this.props.handlePrevLoad && (
          <div>
            <button
              data-tip
              data-for="anglearrowlefticon"
              className="btn btn-outline-light bg-white day-card prev__arrow ml-10"
              onClick={() => {
                if (this.isChassisV3 && !this.checkAddress()) return;
                this.props.handlePrevLoad()
              }}
            >
              <IconAngleArrowLeft />
            </button>
            <ReactTooltip id="anglearrowlefticon" place="top" effect="solid" className="react-tooltip-new">
              {"Previous Load"}
            </ReactTooltip>
          </div>
        )}
        {this.props.handleNextLoad && (
          <div>
            <button
              data-tip
              data-for="anglearrowrighticon"
              className="btn btn-outline-light bg-white day-card mr-10"
              onClick={() => {
                if (this.isChassisV3 && !this.checkAddress()) return;
                this.props.handleNextLoad()
              }}
            >
              <IconAngleArrowRight />
            </button>
            <ReactTooltip id="anglearrowrighticon" place="top" effect="solid" className="react-tooltip-new">
              {"Next Load"}
            </ReactTooltip>
          </div>
        )}
        {!this.props.isFromCustomerService &&
          <button
            className="btn btn-outline-light bg-white day-card pr-10 pl-10"
            onClick={() => {

              if (this.isChassisV3 && !this.checkAddress()) return;
              this.setState({
                isCloseModal: true
              }, () => {
                this.handleClosePrompt()
              })
            }}
          >
            <IconCloseBold className="mr-15" /> <span className="font-12 font-medium">Close</span>
          </button>

        }
          {this.state.activeTab === "billing" &&
            this.state.billingActiveTab === "main-customer" &&
            this.state.isSaveOptions && (
              <div
                className="position-relative"
                ref={this.wrapperRef}
              >
              <div className="card card--shadow-5 popdrop popdrop--right mb-0 p-3 animated">
                <span
                  className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                  onClick={() => {
                    this.handleSubmitLoad(true, false);
                    this.setState({ isSaveOptions: false });
                  }}
                >
                  <CustomIconSaveOutline className="mr-3" />
                  {`Save & Add to Customer Rates`}
                </span>

                <React.Fragment>
                  <hr className="m-2" />
                  <span
                    className="text-gray-900 rounded-3 d-flex align-items-center py-2 px-3 animated hover-bg-success-100"
                    onClick={() => {
                      this.handleSubmitLoad(false, true);
                      this.setState({ isSaveOptions: false });
                    }}
                  >
                    <IconSendEmail className="mr-3" />
                    {`Save & Send Rate Confirmation`}
                  </span>
                </React.Fragment>
              </div>
              </div>
            )}
      </div>
    )
  }

  assignCSR = (csr) => {
    this.setState({ isloading: true })
    let csrList = this.state.assignedCSR && this.state.assignedCSR.length > 0 ? [...this.state.assignedCSR] : []
    const existingCSR = csrList.length > 0 ? csrList.find(item => item._id === csr.value) : undefined
    if (!existingCSR) {
      csrList.push({ value: csr.value })
      this.assignCSRtoLoad(csrList, `${csr.label} assigned successfully`)
    } else {
      toastr.show(`${csr.label} is already added`, "error")
      this.setState({ isloading: false })
    }
    this.setState({ showAllCSRList: false })
  }
  listAllCSR = (search) => {

    let data = {
      CSR: true,
      active: true
    };
    if (search) {
      data.searchTerm = search
    }
    return new Promise((resolve, reject) => {
      this.props.actions.listAllFleetManagers(data).then((options) => {
        const CSR = [];
        let allDatas = options || [];

        allDatas.forEach((obj) => {
          CSR.push({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          });
        });
        this.setState({ AllCSRList: CSR });
        resolve(options);
      });
    });
  }
  assignCSRtoLoad = (csrList, msg) => {

    const formData = new FormData()
    formData.append('assignedCSR', JSON.stringify(csrList.map(item => item._id || item.value)))
    formData.append('loadId', this.props.selectedLoad && this.props.selectedLoad._id)
    actions.assignCSRtoLoad(formData).then(result => {
      if (result && result.assignedCSR) {
        toastr.show(msg, 'success')
        this.setState({ assignedCSR: result.assignedCSR })
      }
      let eventProperties = {
        source: "load_info_modal"
      }
      amplitudeTrack("ASSIGNED_CSR_TO_LOADS",eventProperties)
      this.setState({ isloading: false })
    })
      .catch((err) => this.setState({ isloading: false }))
    // assignCSRtoCustomer(formData)

  }

  updatNotesCountFromChild = (loadCount, instructionNote) => {
    this.setState({ noteCount: loadCount });
    this.setState(prevState => ({
      selectedLoad: {
        ...prevState.selectedLoad,
        instructionNote: instructionNote?.note,
      }
    })
    );
  }

  removeCSRfromCustomer = (csr) => {
    this.setState({ isloading: true })
    let csrList = this.state.assignedCSR.filter(item => item._id != csr._id)
    this.setState({ assignedCSR: csrList })
    this.assignCSRtoLoad(csrList, `${csr.name} ${csr.lastName} Removed Successfully`)
  }
  showInvoicePage = (invoiceNumber) => {
    if (invoiceNumber) {
      this.setState({ invoiceNumber: invoiceNumber })
    }
    this.setState({ showInvoice: !this.state.showInvoice })

  }
  setDistanceUnit() {
    const selectedLoad = this.props.selectedLoad;
    const measureUnits = selectedLoad && selectedLoad.carrier && selectedLoad.carrier.carrier && selectedLoad.carrier.carrier.distanceMeasure;
    let measureUnit = measureUnits && measureUnits == 'km' ? "Kilometers" : "Miles";
    let totalDistance;
    if (selectedLoad && selectedLoad.totalMiles) {
      if (selectedLoad.totalMiles > 6000) totalDistance = "6000+";
      else totalDistance = selectedLoad.totalMiles.toFixed(2);
      // totalDistance = convertUnit("convertedValue", "distance", totalDistance);
    }
    let secondaryDistance = 0;
    for (const driverOrder of (selectedLoad?.driverOrder ?? [])) {
      secondaryDistance += (driverOrder.defaultDistance ?? driverOrder.distance);
    }
    secondaryDistance = secondaryDistance.toFixed(2);

    this.setState({ measureUnit, totalDistance, secondaryDistance, totalTollDistance: selectedLoad?.totalTollDistance?.toFixed(2) })
  }

  hasCommunicationTabPermission = () => {
    const loggedInUser = JSON.parse(getStorage("loggedInUser") ?? "{}");
    const { role, fleetManager, permissions = [] } = loggedInUser
    return (
      (role === "carrier" || role === "fleetcustomer") ||
      this.checkUserPermisison(["customer_service_messaging"]) ||
      (
        (role === "fleetmanager" && fleetManager?.isCustomer || role === "customer") &&
        permissions?.includes("customer_employee_load_messaging")
      )
    )
  }

  getTenderReferenceNumberWithLoad = () => {
    if(!this.props.selectedLoad?.reference_number && this.props.selectedLoad?.createSource != createSource.BROKER_TENDER) return;
    getTenderReferenceNumber({referenceNumber: this.props.selectedLoad?.reference_number}).then((res) => {
      this.setState({tenderDetails: res})
      if(res?.status === "CANCELED") this.showWarningPopup()
    })
    .catch((err) => {
        console.log("err", err);
    })
  }

  showWarningPopup = () => {
    const message =
      "This load has been cancelled by the Broker.  Status updates will no longer update the broker.  Billing will continue to possible.";
    confirm(
      "Alert",
      message,
      (confirm) => {},
      {
        showOnlyClose: true,
      }
    );
  };

  render() {
    const { showSidebar, selectedLoad, changeList, Terminals } = this.props;

     const isDeleteEnable =
       ((this.props.removeload !== null && this.state.user.role === "carrier") ||
       (this.state.user.role === "fleetmanager" &&
         this.checkUserPermisison(["deleteloads"])))&& !selectedLoad?.isDeleted


    let totalWeight = 0;
    let weightUnit = "LBS";
    if (selectedLoad && selectedLoad.items) {
      selectedLoad.items.forEach((item) => {
        if (selectedLoad.carrier && selectedLoad.carrier.carrier && (selectedLoad.carrier.carrier.weightMeasure == "kg" && item?.weightKgs)) {
          totalWeight += Number(item.weightKgs);
          weightUnit = "KG"
        } else {
          totalWeight += Number(item.weight);
        }
      });
    }

    let userDetails = JSON.parse(getStorage("loggedInUser"));
    let disabledSaveButton = false;
    if (!["load", "billing", "orderstatus"].includes(this.state.activeTab)) {
      disabledSaveButton = true;
    }
	if(selectedLoad?.isDeleted){
		disabledSaveButton = true;
	}
    if (["load", "billing", "orderstatus"].includes(this.state.activeTab)) {
      if (selectedLoad &&
        !disabledSaveButton &&
        [
          "APPROVED",
          "BILLING",
          "REBILLING",
          "PARTIAL_PAID",
          "FULL_PAID",
        ].includes(selectedLoad.status)
      ) {
        disabledSaveButton = true;
      }
    }
    if (this.state.activeTab === "billing" || this.state.billingActiveTab === "sub-customer") {

      if (
        JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
      ) {
        disabledSaveButton = true;
      } else if (
        ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
      ) {
        disabledSaveButton = true;
      }
      else {
        disabledSaveButton = false;
      }
    }

    if (selectedLoad && this.state.activeTab === "load") {
      if (
        !disabledSaveButton &&
        getStorage("currentUserRole") == "fleetmanager" &&
        userDetails.fleetManager.carrier !== selectedLoad.carrier._id
      ) {
        disabledSaveButton = true;
      } else if (
        !disabledSaveButton &&
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") !== selectedLoad.carrier._id
      ) {
        disabledSaveButton = true;
      } else if (
        !disabledSaveButton &&
        userDetails.role == "customer" &&
        (!userDetails.customer || !userDetails.customer.canEditLoad)
      ) {
        disabledSaveButton = true;
      }
    }
    const allCSRList = this.state.AllCSRList && this.state.AllCSRList.length > 0 && this.state.AllCSRList.filter(item => {
      return !this.state.assignedCSR || !this.state.assignedCSR.find(csr => csr._id == item.value)
    })
    let showAddRemoveOptions = (getStorage("currentUserRole") == "carrier" || (getStorage("currentUserRole") == "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager && JSON.parse(getStorage("loggedInUser")).fleetManager.admin))
 

    return (
      <React.Fragment>
        <Modal show={true} className="load-info-modal pr-20 pl-20 vh-100 mt-24" animation={false}>
          <Modal.Header>
            {showSidebar && this.state.selectedLoad && !this.props.isFromCustomerService && (
              <>
                {this.props?.isNewDeleted ?
                  <div className="subheader" style={{ height: "70px" }}>
                    <div className="subheader__inner d-flex align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <div className="d-flex align-items-center">
                          <div>
                            <h5 className="mb-0 mr-2 font-20">
                              Load #: {this.state.selectedLoad.reference_number}
                            </h5>
                            <span className="badge badge-danger mr-2">
                              Deleted
                            </span>
                            <StatusComponent load={this.state.selectedLoad} deleted={true} />
                          </div>
                          {(userDetails.role === "carrier" || (userDetails.role === "fleetmanager" && !userDetails.fleetManager.isCustomer && this.checkUserPermisison(["enable_deleted_loads"]))) &&
                            <button
                              data-tip
                              data-for="reEnable"
                              type="button"
                              className="btn btn-success border-0 text-white p-2 ml-2"
                              onClick={() => {
                                this.enableload(selectedLoad._id);
                              }}
                            >
                              Enable this Load
                            </button>
                          }
                        </div>
                      </div>
                      {this.getNewHeaderButtons(isDeleteEnable, disabledSaveButton)}
                    </div>
                  </div> : 
                  <div className="subheader d-flex align-items-center">
                    <div className="mx-20 d-flex align-items-center justify-content-between w-100 border-0">
                      <div className="d-flex flex-row align-items-center">
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 mr-10 font-20">
                            Load #: {this.state.selectedLoad.reference_number}
                          </h5>
                          <StatusComponent load={this.state.selectedLoad} />
                          <DrayosTrackAlert />

                        </div>
                      </div>
                      {this.getNewHeaderButtons(isDeleteEnable, disabledSaveButton)}
                    </div>
                  </div>}
              </>
            )}
          </Modal.Header>

    <Modal.Body className='load-modal-full pt-0'>
      {showSidebar && this.state.selectedLoad && (
          <div className="form-row-md fcpopup">
            <div className="col-lg fcpopup__left pr-0 mr_12" style={{ maxWidth: 265 }}>
              {this.props.isFromCustomerService &&
                <div className="card p-15 mb-1">
                  <div className={`d-flex justify-content-between ${
                      selectedLoad?.isDeleted ? "flex-column" : "flex-row"
                    }`}
                  >
                    <h5 className={`mb-1 font-20 ${
                        selectedLoad?.isDeleted ? "text-danger" : ""
                      }`}
                    >
                      #
                      {selectedLoad && selectedLoad.reference_number
                        ? selectedLoad.reference_number
                        : ""}
                    </h5>
                    {selectedLoad?.isDeleted && (
                      <div className="d-flex align-items-center">
                        <span className="badge badge-danger mr-2">
                          Deleted
                        </span>
                        <button
                          data-tip
                          data-for="reEnable"
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            this.enableload(selectedLoad._id);
                          }}
                        >
                          Enable this Load
                        </button>
                        <ReactTooltip id="reEnable">
                          <span>Press Here to Enable it</span>
                        </ReactTooltip>
                      </div>
                    )}
                  </div>
                  <div className="justify-content-center align-items-center">
                    <StatusComponent load={selectedLoad}/>   
                  </div>
                </div>
              }
              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">{LOAD_LABELS.CUSTOMER}</h5>
                <div className="mt-10">
                      <a href="#" className="font-medium mb-2 font-14 text-capitalize text-dark"
                        onClick={(event) => handleNavigateToCustomer(event, selectedLoad?.caller?._id)}
                        onContextMenu={(event) => handleRightClickNavigateToCustomer(event, selectedLoad?.caller?._id)} 
                      >
                        {selectedLoad.caller
                          ? selectedLoad.caller.company_name
                          : ""}
                      </a>
                <div
                  className={`${
                    (selectedLoad?.caller?.mobile ||
                      selectedLoad?.caller?.billingEmail ||
                      selectedLoad?.caller?.main_contact_name) ?
                    "text-gray-900 font-weight-normal font-12" : ""
                  }`}
                >
                  {selectedLoad &&
                  selectedLoad.caller &&
                  selectedLoad.caller.address
                    ? parsedAddressIntoLocality(selectedLoad.caller.address)
                    : ""}
                </div>
                </div>
              </div>

          {
            selectedLoad.type_of_load !== "BILL_ONLY" ?
            <>
              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                {(isTerminalTrackingEnabled() && selectedLoad?.type_of_load === "EXPORT") ? LOAD_LABELS.EMPTY_PICK_UP_LOCATION : LoadInfoLabel(selectedLoad?.type_of_load,"shipper")}
                </h5>
                {selectedLoad.shipper.map((shipper, sI) => {
                  return (
                    <React.Fragment key={sI}>
                      <Port shipper={shipper} />
                    </React.Fragment>
                  );
                })}
                  {selectedLoad.shipper?.length === 0 &&
                    <div className="mt-10">N/A</div>
                  }
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                  {LoadInfoLabel(selectedLoad.type_of_load,"consignee")}
                  </h5>
                  {selectedLoad.consignee.map((consignee,i)=>{
                    return (
                      <React.Fragment key={i}>
                        <DeliveryWarehouse consignee={consignee} />
                      </React.Fragment>
                    );
                  })}
                  {selectedLoad.consignee?.length === 0 &&
                    <div className="mt-10">N/A</div>
                  }
              </div>

              <div className="card p-10 mb_5">
                <h5 className="text-black mt_3 font-14 mb-0 text-capitalize">
                  {LOAD_LABELS.RETURN_LOCATION}
                </h5>
                {selectedLoad && selectedLoad.type_of_load !== "ROAD" && (
                <div className="d-flex justify-content-between align-items-start">
                <div className="mt-10">
                  {selectedLoad.emptyOrigin && selectedLoad.emptyOrigin.address && (
                    <div className="d-flex justify-content-between align-items-start">
                      <a href="#" className="font-medium mb-2 font-12 text-capitalize mr-1 text-dark"
                      onClick={(event) => handleNavigateToCustomer(event, selectedLoad?.emptyOrigin?._id)}
                      onContextMenu={(event) => handleRightClickNavigateToCustomer(event, selectedLoad?.emptyOrigin?._id)} 

                      >
                        {" "}
                        {selectedLoad.emptyOrigin
                          ? selectedLoad.emptyOrigin.company_name
                          : ""}</a>
                    </div>
                  )}
                  <div>
                    {selectedLoad.emptyOrigin &&
                    selectedLoad.emptyOrigin.address
                      ? parsedAddressIntoLocality(selectedLoad.emptyOrigin.address)
                      : "N/A"}
                  </div>
                </div>
              </div>
                )}
              </div>
            </>
            : <></>
          }
              {this.state.tenderDetails?.tenderReferenceNumber && <TenderReferenceNumber tenderRefNumber={this.state.tenderDetails.tenderReferenceNumber} />}
              <div className="card p-10 mb_5">
                <h5 className="text-black font-14 mt_3">Load Info Summary</h5>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">{`Customer Reps`} </span>&nbsp;
                  <div className="group d-flex align-items-center mt-1" >
                    {this.state.assignedCSR && this.state.assignedCSR.length> 0 && 
                     this.state.assignedCSR.slice(0, 3).map((csr, index)=>{
                      return(
                        <React.Fragment key={index}>
                          <div className="group__avatar position-relative">
                            <div className={`avatar-circle avatar-sm wh-20px ${!csr.profilePicture && "border-1 text-primary" }  `} data-tip data-for={csr._id} onMouseEnter={()=> this.setState({showRemoveIcon : index})} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {csr.profilePicture ?
                                   <img className="avatar rounded-circle " src={csr.profilePicture} />
                                 : <div className="avatar-circle__initial font-10 " >{csr?.name && csr?.lastName ? `${csr.name[0]}${csr.lastName[0]}` : ""}</div>}
                                {
                                this.state.showRemoveIcon == index &&  showAddRemoveOptions &&
                                <div className="notification-icon-header z-1 pointer"
                                onMouseEnter={()=> ReactTooltip.hide()}
                                 onClick={()=>this.removeCSRfromCustomer(csr)}  style={{top: "-7px", color : "#444242", backgroundColor : "#dbe2eb", border : "1px solid #b3b3b3" }}><IconTimesThinClose/></div>}  
                            </div>
                            </div>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                            id={csr._id}
                          >
                            <span key ={index}>{csr?.name && csr?.lastName ? `${csr.name} ${csr.lastName}` : ""} 

                            </span>
                          </ReactTooltip>                           

                          </React.Fragment>
                      )
                    })}
                    {this.state.assignedCSR && this.state.assignedCSR.length > 3 && (
                      <div className="group__avatar z-3">
                        <div className="avatar avatar-circle avatar-sm bg-light border-1 text-primary" onMouseEnter={()=> this.setState({showRemoveIcon : 'more'})} >
                        <span className="text-dark">  +{this.state.assignedCSR.length - 3}</span>  
                        </div>

                          {this.state.showRemoveIcon == 'more' &&  <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated z-3 pointer" style={{right : "0" }} ref={this.wrapperRef}>
                          <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }} onMouseLeave={()=> this.setState({showRemoveIcon : undefined})}>
                            {this.state.assignedCSR && this.state.assignedCSR.length > 3 && 
                                this.state.assignedCSR.slice(3).map((csr, index)=>{
                              return(
                                <div  key={index} className="d-flex align-items-center mb-10 pointer" >
                                  <div className="flex-shrink-0 mr-10 align-self-center">
                                    {
                                      csr && csr.profilePicture ? 
                                      <img className="avatar rounded-circle " src={csr.profilePicture}  /> : 
                                      <div className="avatar-circle undefined">
                                      {`${csr.name[0]}${csr.lastName[0]}`}
                                    </div>
                                    }                                          
                                  </div>
                                  <div className="flex-grow-1 text-truncate">
                                    <h5 className="font-12 font-weight-500 text-dark m-0">{`${csr.name} ${csr.lastName}`}</h5>
                                  </div>
                                  {showAddRemoveOptions && 
                                  <div   onClick={()=>this.removeCSRfromCustomer(csr)}>
                                  <IconTimesThin />
                                  </div>
                                }
                                </div>
                              ) 
                            })}
                          </div>
                        </div>}


                      </div>
                    )}
                    { showAddRemoveOptions
                     && <div className="ml-10 group__avatar position-relative">
                          
                     {this.state.isloading ? 
                       <span
                           className="spinner-border spinner-border-sm mr-2"
                           role="status"
                           aria-hidden="true"
                         >
                         </span>
                     : <div className="avatar-circle avatar-sm bg-primary pointer wh-16px" onMouseEnter={()=> this.setState({showRemoveIcon : undefined})} onClick={()=>{this.setState({showAllCSRList : !this.state.showAllCSRList,csrSearchTerm : ""}); this.listAllCSR()}}> 
                       <div className="avatar-circle__initial text-white font-10" >
                       <IconPlus className="wh-16px" /> 
                       </div>   
                       </div>
                       }  
                       {this.state.showAllCSRList && 
                         <div className="position-absolute rounded-lg bg-white mt-2 w-200 p-2 csr-popup card--shadow-5 animated popdrop--hcenter z-1" ref={this.wrapperRef}>
                           <div className="app-search position-relative mb-1">
                             <span className="search-icon ml-1"></span>
                             <div className="position-relative mb-10">
                               <input 
                                 type="input" 
                                 className="form-control input-search-left bg-gray-50" 
                                 placeholder="Search CSR.." 
                                 value={this.state.csrSearchTerm} 
                                 onChange={ e => {
                                   this.setState({csrSearchTerm : e.target.value});
                                     debounce(this.listAllCSR(e.target.value),500)
                                     }} 
                               />
                             </div>
                           </div>
                           <div className="clist" style={{ maxHeight: "calc(350px - 191px)" }}>
                             {allCSRList && allCSRList.length > 0 ? allCSRList.map((csr, index)=>{
                               return(
                                 <div  key={index} className="d-flex align-items-center mb-10 pointer" onClick={()=>this.assignCSR(csr)}>
                                   <div className="flex-shrink-0 mr-10 align-self-center">
                                     {
                                       csr && csr.allInfos && csr.allInfos.allInfos && csr.allInfos.allInfos.profilePicture ? 
                                       <img className="avatar rounded-circle " src={csr.allInfos.allInfos.profilePicture}  /> : 
                                       <div className="avatar-circle undefined">
                                       {`${csr.label[0]}${csr.label.split(" ")[1][0]}`}
                                     </div>
                                     }
                                     
                                   </div>
                                   <div className="flex-grow-1 text-truncate">
                                     <h5 className="font-12 font-weight-500 text-dark m-0">{csr.label}</h5>
                                   </div>
                                 </div>
                               ) 
                             }) :  <div> No CSR's Available</div>}
                           </div>
                         </div>
                       }
                     </div>
                    }
                   
                    
                  </div>
                </div>
                {!this.state.hideSummaryInfo && (<div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created By </span>&nbsp;
                  <span className="text-muted text-dark font-medium">{`${selectedLoad.addedBy.name}`.trim()}</span>
                </div>)}
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Created Date </span>&nbsp;
                  <span className="text-muted text-dark font-medium">
                    {moment(selectedLoad.createdAt).tz(getTimeZone({preferred: true})).format(
                     DateTimeFormatUtils.fullDateTimeFormat()
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Container #</span>
                  <span className="text-muted text-dark font-medium">{selectedLoad.containerNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  {selectedLoad.type_of_load === "IMPORT" && (
                    <React.Fragment>
                    <span className="text-muted">BOL #</span>
                    <span className="text-muted text-dark font-medium">{selectedLoad.callerbillLandingNo}</span>
                    </React.Fragment>
                  )} 
                  { selectedLoad.type_of_load === "EXPORT" &&  (
                    <React.Fragment>
                    <span className="text-muted">BKG #</span>
                    <span className="text-muted text-dark font-medium">{selectedLoad.bookingNo}</span>
                    </React.Fragment>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Chassis #</span>
                  <span className="text-muted text-dark font-medium">{selectedLoad.chassisNo}</span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">SSL</span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad && selectedLoad.containerOwner
                      ? selectedLoad.containerOwner.company_name
                      : ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Size</span>
                  <span className="text-muted text-dark font-medium">
                    {" "}
                    {selectedLoad && selectedLoad.type_of_load === "ROAD" 
                      ? selectedLoad?.trailerSize
                      : selectedLoad?.containerSize?.name ?? ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Weight</span>
                  <span className="text-muted text-dark font-medium">
                    {totalWeight.toFixed(2)} {weightUnit}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Type</span>
                  <span className="text-muted text-dark font-medium">
                  {selectedLoad && selectedLoad.type_of_load === "ROAD" 
                      ? selectedLoad?.trailerType
                      : selectedLoad?.containerType?.name ?? ""}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-10">
                  <span className="text-muted">Commodity </span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad &&
                      selectedLoad.items.length > 0 &&
                      selectedLoad.items[0].commodity}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Reference</span>
                  <span className="text-muted text-dark font-medium">
                    {selectedLoad && selectedLoad.secondaryReferenceNo
                      ? selectedLoad.secondaryReferenceNo
                      : ""}
                  </span>
                </div>
                {!isHideLoadSummary() && !this.state.hideSummaryInfo && (<div className="d-flex align-items-center justify-content-between">
                  <span className="text-muted">Total {this.state.measureUnit}</span>                   

                  <div className="ml-auto">
                  <span>{this.state.totalDistance || ""}</span>
                    <button
                      className="btn btn-link pr-0"
                      onClick={() => {
                        calculateLoadDistance(
                          this.props.selectedLoad.reference_number
                        );
                        this.setDistanceUnit()
                      }
                        
                      }
                    >
                      <span>
                        <IconReset className="text-primary" />
                      </span>
                    </button>
                  </div>
                </div>)}
                {!isHideLoadSummary() && <> 
                  {this.state.showSecondaryDistance && (
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Secondary {this.state.measureUnit}</span>                   
                      <div className="ml-auto">
                        <span>{this.state.secondaryDistance || ""}</span>
                      </div>
                    </div>
                  )}
                </>}
                {!isHideLoadSummary() && <> 
                  {this.state.totalTollDistance?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Total Toll {this.state.measureUnit}</span>                   
                      <div className="ml-auto">
                        <span>{convertMetersToCarrierDefault(this.state.totalTollDistance, false) ?? ""}</span>
                      </div>
                    </div>
                  )}
                </>}
                {selectedLoad && selectedLoad.customerEmployee && selectedLoad.customerEmployee.length > 0 && selectedLoad.customerEmployee.map((eachEmployee) =>{  
                  return (
                    <React.Fragment key={eachEmployee._id}>
                       <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Email</span>
                        <div className="ml-auto">
                            {eachEmployee.billingEmail}
                        </div>

                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">Emp Name</span>
                        <div className="ml-auto">
                            {eachEmployee.firstName &&  eachEmployee.firstName} {eachEmployee.lastName &&  eachEmployee.lastName}
                        </div>

                      </div>
                      </React.Fragment>
                    )
                  })}
                {!isHideLoadSummary() && !this.state.hideSummaryInfo && 
                  (<div className="align-items-center">
                    <span className="text-muted">Preview </span>
                    <div className="ml-auto">
                      <div
                        id="CustomerServiceSidebarMap"
                        className="mapboxgl-map-300 mt-10"
                        style={{ height: 300 }}
                        ref={(el) => (this.mapContainer = el)}
                      ></div>
                      <div className="overlay mt-10"></div> 
                    </div>
                  </div>)
                }
              </div>
              {this.isManageFleetOrOutSource?.isManageFleet && !isHideLoadSummary() &&
                <div ref={(el) => { this.scrollRef = el }}>
                  <SummaryDocuments selectedLoad={this.props.selectedLoad} />
                </div>
              }

              {this.isManageFleetOrOutSource?.isManageFleet && !isHideLoadSummary() &&
                <>
                  {this.props.selectedLoad.type_of_load !== "BILL_ONLY"
                    ? <NewSummaryRoutingHistory
                        selectedLoads={this.props.selectedLoad}
                        reference_number={this.props.selectedLoad.reference_number}
                      />
                    : ""
                  } 
                </>
              }
            
              {this.isManageFleetOrOutSource?.isManageFleet && (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                getStorage("currentUserRole") !== "fleetcustomer" &&
                !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) ) && 
                <SummaryDriverPay reference_number={this.props.selectedLoad.reference_number} />
              }
                {(getStorage("currentUserRole") == 'carrier' || (getStorage("currentUserRole") != "customer" &&
                  getStorage("currentUserRole") !== "fleetcustomer" &&
                  !JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer)) && this.state.AllCreditMemo.length > 0 && isCreditMemo() && !isNewBillingEnabled() &&
                  <SummaryCreditMemo selectedLoad={this.props.selectedLoad} allCreditMemo={this.state?.AllCreditMemo} />
                }

                {(getStorage("currentUserRole") == 'carrier' || (getStorage("currentUserRole") != "customer" &&
                  getStorage("currentUserRole") !== "fleetcustomer" &&
                  !JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer)) && this.state.AllCreditMemoV2.length > 0 && isCreditMemo() && isNewBillingEnabled() &&
                  <SummaryCreditMemoV2 selectedLoad={this.props.selectedLoad} allCreditMemo={this.state.AllCreditMemoV2} />
                }
            </div>
            <div ref={(el) => { this.scrollRef = el }} className="col-lg fcpopup__right ml-2">
              <div className="card mb-0">
                <ul className="nav nav-tabs nav-tabs-custom nav-justified" id="load-tabs">
                  <li
                    className="nav-item"
                    onClick={() => {
                      if(this.isChassisV3 && !this.checkAddress()) return;
                      this.changeTopTab("load")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "load" ? "active" : ""
                      }`}
                      // data-toggle={!this.state.isBlockingTabChange && "tab"}
                      role="tab"
                    >
                      <IconInfoFocus />
                      <div className={`nav-link__text mt_4 ${this.state.activeTab === "load" ? "active_text font-semi-bold" : ""}` }>Load Info</div>
                    </a>
                  </li>
                  {this.state.showBilling && (
                    <li
                      className="nav-item"
                      onClick={() => {
                        if(this.isChassisV3 && !this.checkAddress()) return;
                        this.changeTopTab("billing")
                        this.scrollRef.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                      }}
                    >
                      <a
                        className={`nav-link ${
                          this.state.activeTab === "billing" ? "active" : ""
                        }`}
                        // data-toggle={!this.state.isBlockingTabChange && "tab"}
                        role="tab"
                      >
                        <IconBilling />                           
                        <div className={`nav-link__text ${this.state.activeTab === "billing" ? "active_text font-semi-bold" : ""}` }>Billing</div>
                      </a>
                    </li>
                  )}

                  <li
                    className="nav-item"
                    onClick={() => {
                      if(this.isChassisV3 && !this.checkAddress()) return;
                      this.changeTopTab("documents")
                    }}
                  >
                    <a
                      className={`nav-link ${
                        this.state.activeTab === "documents" ? "active" : ""
                      }`}
                      // data-toggle={!this.state.isBlockingTabChange && "tab"}
                      role="tab"
                    >
                      <IconDocument />                          
                      <div className={`nav-link__text ${this.state.activeTab === "documents" ? "active_text font-semi-bold" : ""}` }>Documents</div>
                    </a>
                  </li>

                  {!checkIsCustomer() && <li
                        className={`nav-item ${this.isShipOsCarrier && "mt-2"}`}
                        onClick={() => {
                          if (this.isShipOsCarrier) return;
                          if (this.isChassisV3 && !this.checkAddress()) return;
                          this.changeTopTab("payments")
                        }}
                      >
                    <a
                      className={`${this.isShipOsCarrier ? "disabled__tab":"nav-link"} ${
                        this.state.activeTab === "payments" ? "active" : ""
                      }`}
                      // data-toggle={!this.state.isBlockingTabChange && "tab"}
                      role="tab"
                    >
                      <IconPayment />                          
                      <div className={`nav-link__text ${this.state.activeTab === "payments" ? "active_text font-semi-bold" : ""}` }>{isCreditMemo() ? "Payments & Credits" : "Payment"}</div>
                    </a>
                  </li>}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className={`nav-item`}
                        onClick={() => {
                          if(this.isChassisV3 && !this.checkAddress()) return;
                          this.changeTopTab("orderstatus")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "orderstatus"
                              ? "active"
                              : ""
                          }`}
                          // data-toggle={!this.state.isBlockingTabChange && "tab"}
                          role="tab"
                        >
                          <IconStatus />                              
                          <div className={`nav-link__text ${this.state.activeTab === "orderstatus" ? "active_text font-semi-bold" : ""}` }>Routing</div>
                        </a>
                      </li>
                    )}

                  {!this.props.type && this.isManageFleetOrOutSource?.isManageFleet && !this.isManageFleetOrOutSource?.isOutSource &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className={`nav-item ${this.isShipOsCarrier && "mt-3"}`}
                        onClick={() => {
                          if(this.isShipOsCarrier) return;
                          if(this.isChassisV3 && !this.checkAddress()) return;
                          this.props.changeCustomerServiceSidebarTab(
                            "driverpay"
                          );
                          this.setState({ activeTab: `driverpay` });
                        }}
                      >
                        <a
                          className={`${this.isShipOsCarrier ? "disabled__tab":"nav-link"} ${
                            this.state.activeTab === "driverpay"
                              ? "active"
                              : ""
                          }`}
                          // data-toggle={!this.state.isBlockingTabChange && "tab"}
                          role="tab"
                        >
                          <IconTruck />                              
                          <div className={`nav-link__text ${this.state.activeTab === "driverpay" ? "active_text font-semi-bold" : ""}`}>{isNewBillingEnabled() ? "Driver Pay & Expenses" : "Driver Pay"}</div>
                        </a>
                      </li>
                    )}

                  {!this.props.type && this.isManageFleetOrOutSource?.isOutSource && !this.isManageFleetOrOutSource?.isManageFleet &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className={`nav-item`}
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "carrierpay"
                          );
                          this.setState({ activeTab: `carrierpay` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "carrierpay"
                              ? "active"
                              : ""
                          }`}
                          data-toggle={!this.state.isBlockingTabChange && "tab"}
                          href="#Carrierpay"
                          role="tab"
                        >
                          <IconTruck />                              
                          <div className={`nav-link__text ${this.state.activeTab === "carrierpay" ? "active_text font-semi-bold" : ""}`}>{isNewBillingEnabled() ? "Carrier Pay & Expenses" : "Carrier Pay"}</div>
                        </a>
                      </li>
                    )}
                    {(this.isManageFleetOrOutSource?.isManageFleet && this.isManageFleetOrOutSource.isOutSource) && 
                    (getStorage("currentUserRole") != "customer" && getStorage("currentUserRole") !== "fleetcustomer" && !JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer) && 
                    (<li
                        className={`nav-item`}
                        onClick={() => {
                          this.props.changeCustomerServiceSidebarTab(
                            "payable-expenses"
                          );
                          this.setState({ activeTab: `payable-expenses` });
                        }}
                      >
                        <a
                          className={`nav-link ${
                            ["payable-expenses", "driverpay", "carrierpay"].includes(this.state.activeTab)
                              ? "active"
                              : ""
                          }`}
                          role="tab"
                        >
                          <IconTruck />                              
                          <div className={`nav-link__text ${["payable-expenses", "driverpay", "carrierpay"].includes(this.state.activeTab)  ? "active_text font-semi-bold" : ""}`}>Payable & Expenses</div>
                        </a>
                    </li>)}
                  {/* { ((this.props.type === "customer" && getStorage("currentUserRole") == "customer") ||
                    (!this.props.type && getStorage("currentUserRole") !== "fleetcustomer" ))&& ( */}
                      <li
                        className={`nav-item ${this.isShipOsCarrier && "mt-2"}`}
                        onClick={() => {
                          if(this.isShipOsCarrier) return;
                          if(this.isChassisV3 && !this.checkAddress()) return;
                          this.changeTopTab("tracking")
                        }}
                      >
                        <a
                          className={`${this.isShipOsCarrier ? "disabled__tab":"nav-link"} ${
                            this.state.activeTab === "tracking"
                              ? "active"
                              : ""
                          }`}
                          // data-toggle={!this.state.isBlockingTabChange && "tab"}
                          role="tab"
                        >
                          <IconTrack />                              
                          <div className={`nav-link__text ${this.state.activeTab === "tracking" ? "active_text font-semi-bold" : ""}` }>Tracking</div>
                        </a>
                      </li>
                    {/* )} */}
                   

                  <li
                    className={`nav-item ${this.isShipOsCarrier && "mt-2"}`}
                    onClick={() => {
                     if(this.isShipOsCarrier) return;
                     if(this.isChassisV3 && !this.checkAddress()) return;
                     this.changeTopTab("messaging")
                    }}
                  >
                    <a
                      className={`${this.isShipOsCarrier ? "disabled__tab":"nav-link"} ${
                        this.state.activeTab === "messaging" ? "active" : ""
                      }`}
                      // data-toggle={!this.state.isBlockingTabChange && "tab"}
                      role="tab"
                    >
                      <span className="position-relative">
                      <IconMessage />          
                            {this.state.unreadMailCount > 0 ? (
                              <span
                                className="avatar-xs bg-danger-500 rounded-circle text-white d-flex align-items-center justify-content-center"
                                style={{
                                  position: "absolute",
                                  top: -8,
                                  left: -8,
                                }}
                              >
                                {this.state.unreadMailCount}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>   
                                     
                      <div className={`nav-link__text ${this.state.activeTab === "messaging" ? "active_text font-semi-bold" : ""}` }>{isEmbeddedEmailEnabled() ? 'Communication' : 'Messaging'}</div>                         
                    </a>
                  </li>

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          if(this.isChassisV3 && !this.checkAddress()) return;
                          this.changeTopTab("audit")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "audit" ? "active" : ""
                          }`}
                          // data-toggle={!this.state.isBlockingTabChange && "tab"}
                          role="tab"
                        >
                          <IconSearchData />                              
                          <div className={`nav-link__text ${this.state.activeTab === "audit" ? "active_text font-semi-bold" : ""}` }>Audit</div>   
                        </a>
                      </li>
                    )}

                  {!this.props.type &&
                    (getStorage("currentUserRole") == 'carrier' ||  (getStorage("currentUserRole") != "customer" &&
                      getStorage("currentUserRole") !== "fleetcustomer" &&
                      !JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer) )  && (
                      <li
                        className="nav-item"
                        onClick={() => {
                          if(this.isChassisV3 && !this.checkAddress()) return;
                          this.changeTopTab("notes")
                        }}
                      >
                        <a
                          className={`nav-link ${
                            this.state.activeTab === "notes" ? "active" : ""
                          }`}
                          // data-toggle={!this.state.isBlockingTabChange && "tab"}
                          role="tab"
                        >
                          <span className="position-relative">
                            <IconNotes />
                            {this.state.noteCount > 0 ? (
                              <span
                                className="avatar-xs bg-primary rounded-circle text-white d-flex align-items-center justify-content-center"
                                style={{
                                  position: "absolute",
                                  top: -8,
                                  left: -8,
                                }}
                              >
                                {this.state.noteCount}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>                              
                          <div className={`nav-link__text ${this.state.activeTab === "notes" ? "active_text font-semi-bold" : ""}` }>Notes</div>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
                  <div className={`tab-loads ${this.state.activeTab === "messaging" ? 'overflow-hidden' : ""}`} onScroll={this.handleScroll} id="tab-loads">
                    {this.state.activeTab === "load" && (
                      <Load
                        showButton={true}
                        Terminals={Terminals}
                        selectedLoads={this.props.selectedLoad}
                        loadType={this.props.selectedLoad.type_of_load}
                        location={{
                          query: this.props.selectedLoad.reference_number,
                        }}
                        id={this.props.selectedLoad.reference_number}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        updateList={this.props.updateData}
                        showExtraData={this.state.showExtraData}
                        handleSubmitLoad={(submit) => {
                          this.handleSubmitLoad = submit;
                        }}
                        isSavingLoad={this.state.isSavingLoad}
                        changeSavingLoadStatus={(status) =>
                          this.setState({ isSavingLoad: status })
                        }
                        changeTopTab={this.changeTopTab}
                        newSyncRequests={this.state.newSyncRequests}
                      />
                    )}
                    {this.state.activeTab === "billing" &&  (
                      isNewBillingEnabled() ?
                        checkIsCustomer() ? <CustomerBilling loads={this.props.selectedLoad}
                        id={this.props.selectedLoad.reference_number}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        chargeConflicts={this.props.selectedLoad.chargeConflicts}
                        updateList={this.props.updateData}
                        changeList={changeList}
                        setLoadEmails={this.props.setLoadEmails}
                        onEditorStateChange={this.props.onEditorStateChange}
                        editorState={this.props.editorState}
                        inpSubject={this.props.inpSubject}
                        handleSubmitLoad={(submit) => {
                          this.handleSubmitLoad = submit;
                        }}
                        changeSavingLoadStatus={(status) =>
                          this.setState({ isSavingLoad: status })
                        }
                        setBillingActiveTab={this.handleTabChange}
                        billingActiveTab={this.state.billingActiveTab}
                        blockTabChange={this.blockTabChange}
                        isBlockingTabChange={this.state.isBlockingTabChange}
                        showPrompt={this.showPrompt}
                        handleExpenseTab={this.handleExpenseTab}
                        isShowingPrompt={this.state.isShowingPrompt}
                        closeCustomerServiceSideBar={this.closeCustomerServiceSideBar}
                        allCreditMemo={this.state.AllCreditMemo}
                        setNewCreditMemo={this.setNewCreditMemo}
                        updateLoadSummaryCreditMemos={this.updateLoadSummaryCreditMemos}
                        /> :
                        <BillingModule loads={this.props.selectedLoad}
                          id={this.props.selectedLoad.reference_number}
                          key={`sub_${this.props.selectedLoad.reference_number}`}
                          // pricing={this.props.selectedLoad.pricing}
                          chargeConflicts={this.props.selectedLoad.chargeConflicts}
                          updateList={this.props.updateData}
                          changeList={changeList}
                          setLoadEmails={this.props.setLoadEmails}
                          onEditorStateChange={this.props.onEditorStateChange}
                          editorState={this.props.editorState}
                          inpSubject={this.props.inpSubject}
                          handleSubmitLoad={(submit) => {
                            this.handleSubmitLoad = submit;
                          }}
                          changeSavingLoadStatus={(status) =>
                            this.setState({ isSavingLoad: status })
                          }
                          setBillingActiveTab={this.handleTabChange}
                          billingActiveTab={this.state.billingActiveTab}
                          blockTabChange={this.blockTabChange}
                          isBlockingTabChange={this.state.isBlockingTabChange}
                          showPrompt={this.showPrompt}
                          handleExpenseTab={this.handleExpenseTab}
                          isShowingPrompt={this.state.isShowingPrompt}
                          closeCustomerServiceSideBar={this.closeCustomerServiceSideBar}
                          allCreditMemo={this.state.AllCreditMemo}
                          setNewCreditMemo={this.setNewCreditMemo}
                          updateLoadSummaryCreditMemos={this.updateLoadSummaryCreditMemos}
                          getLoadNotesCount={this.getLoadNotesCount}
                          />
                        :
                        <NewBillOfLading
                          loads={this.props.selectedLoad}
                          id={this.props.selectedLoad.reference_number}
                          key={`sub_${this.props.selectedLoad.reference_number}`}
                          pricing={this.props.selectedLoad.pricing}
                          chargeConflicts={this.props.selectedLoad.chargeConflicts}
                          updateList={this.props.updateData}
                          changeList={changeList}
                          setLoadEmails={this.props.setLoadEmails}
                          onEditorStateChange={this.props.onEditorStateChange}
                          editorState={this.props.editorState}
                          inpSubject={this.props.inpSubject}
                          handleSubmitLoad={(submit) => {
                            this.handleSubmitLoad = submit;
                          }}
                          changeSavingLoadStatus={(status) =>
                            this.setState({ isSavingLoad: status })
                          }
                          setBillingActiveTab={this.handleTabChange}
                          billingActiveTab={this.state.billingActiveTab}
                          blockTabChange={this.blockTabChange}
                          isBlockingTabChange={this.state.isBlockingTabChange}
                          showPrompt={this.showPrompt}
                          handleExpenseTab={this.handleExpenseTab}
                          isShowingPrompt={this.state.isShowingPrompt}
                          closeCustomerServiceSideBar={this.closeCustomerServiceSideBar}
                          allCreditMemo={this.state.AllCreditMemo}
                          setNewCreditMemo={this.setNewCreditMemo}
                        />
                    )}
                    {this.state.activeTab === "documents" && (
                      <>
                        {isNewBillingEnabled() ?
                          <NewDocumentsTab
                            selectedLoads={this.props.selectedLoad}
                            id={this.props.selectedLoad._id}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            type={this.props.type}
                          /> :
                          <Documents
                            selectedLoads={this.props.selectedLoad}
                            id={this.props.selectedLoad.reference_number}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            type={this.props.type}
                          />}
                      </>
                    )}
                    {this.state.activeTab === "payments" &&  (
                      <BrokerNewPaymentInlineExtended
                        loads={this.props.selectedLoad}
                        id={this.props.selectedLoad.reference_number}
                        key={`sub_${selectedLoad.reference_number}`}
                        updateList={this.props.updateData}
                        type={this.props.type}
                        blockTabChange={this.blockTabChange}
                        isBlockingTabChange={this.state.isBlockingTabChange}
                        showPrompt={this.showPrompt}
                      />
                    )}
                    {this.state.activeTab === "orderstatus" &&  (
                        <LoadRouting
                          selectedLoads={this.props.selectedLoad}
                          reference_number={
                            this.props.selectedLoad.reference_number
                          }
                          key={`sub_${this.props.selectedLoad.reference_number}`}
                          updateList={this.props.updateData}
                          changeList={changeList}
                          originalLoad={_.cloneDeep(this.props.selectedLoad)}
                          supportMoves={this.supportMoves}
                          setSupportMoves={(moves) => { this.supportMoves = moves; }}
                          changeTopTab={this.changeTopTab}
                          handleSubmitLoad={(submit) => {
                            this.handleSubmitLoad = submit;
                          }}
                          changeSavingLoadStatus={(status) =>
                            this.setState({ isSavingLoad: status })
                          }
                          newSyncRequests={this.state.newSyncRequests}
                          _setOpenModalRefNumber={this.props._setOpenModalRefNumber}
                          isLoadWithSingleVendor={this.state.tenderDetails?.isLoadWithSingleVendor}
                          updateLoadInfo={this.props.updateLoadInfo}
                        />
                    )}
                    {(this.state.activeTab === "driverpay" && this.isManageFleetOrOutSource?.isManageFleet && !this.isManageFleetOrOutSource?.isOutSource ) && (
                      <>
                        {isNewBillingEnabled() ?
                          <NewDriverPay
                            isTab={true}
                            location={{
                              query: {
                                reference_number:
                                  this.props.selectedLoad.reference_number,
                              },
                            }}
                            selectedLoads={this.props.selectedLoad}
                            id={this.props.selectedLoad.reference_number}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            reference_number={
                              this.props.selectedLoad.reference_number
                            }
                            blockTabChange={this.blockTabChange}
                            isBlockingTabChange={this.state.isBlockingTabChange}
                            showPrompt={this.showPrompt}
                            closeCustomerServiceSideBar={this.props.closeCustomerServiceSideBar}
                          />
                          : <DriverPay
                            isTab={true}
                            location={{
                              query: {
                                reference_number:
                                  this.props.selectedLoad.reference_number,
                              },
                            }}
                            selectedLoads={this.props.selectedLoad}
                            id={this.props.selectedLoad.reference_number}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            reference_number={
                              this.props.selectedLoad.reference_number
                            }
                            blockTabChange={this.blockTabChange}
                            isBlockingTabChange={this.state.isBlockingTabChange}
                            showPrompt={this.showPrompt}
                            closeCustomerServiceSideBar={this.props.closeCustomerServiceSideBar}
                          />
                        }
                      </>

                    )}
                    {(this.state.activeTab === "carrierpay" && this.isManageFleetOrOutSource?.isOutSource && !this.isManageFleetOrOutSource?.isManageFleet) && (
                      <>
                      {
                        isNewBillingEnabled() ? 
                        <CarrierPayAndExpenses
                            selectedTab={"carrier-pay"}
                            selectedLoads={this.props.selectedLoad}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            blockTabChange={this.blockTabChange}
                            isBlockingTabChange={this.state.isBlockingTabChange}
                            showPrompt={this.showPrompt}
                            isShowingPrompt={this.state.isShowingPrompt}
                          />
                        : <CarrierPay
                            selectedLoads={this.props.selectedLoad}
                            key={`sub_${this.props.selectedLoad.reference_number}`}
                            updateList={this.props.updateData}
                            blockTabChange={this.blockTabChange}
                            isBlockingTabChange={this.state.isBlockingTabChange}
                            showPrompt={this.showPrompt}
                            isShowingPrompt={this.state.isShowingPrompt}
                          />
                      }
                      </>

                    )}
                    {
                      (this.state.activeTab === "payable-expenses" || this.state.activeTab === "driverpay" || this.state.activeTab === "carrierpay") && (this.isManageFleetOrOutSource?.isManageFleet && this.isManageFleetOrOutSource?.isOutSource) &&
                      <PayableExpenses 
                        activeTab={this.state.activeTab}
                        selectedLoads={this.props.selectedLoad}
                        handleTabChange={this.updateActiveTab}
                        driverPayProps={{ 
                          isTab: true,
                          location: {
                            query: {
                              reference_number:
                                this.props.selectedLoad.reference_number,
                            },
                          },
                          selectedLoads: this.props.selectedLoad,
                          id: this.props.selectedLoad.reference_number,
                          key: `sub_${this.props.selectedLoad.reference_number}`,
                          updateList: this.props.updateData,
                          reference_number: this.props.selectedLoad.reference_number,
                          blockTabChange: this.blockTabChange,
                          isBlockingTabChange: this.state.isBlockingTabChange,
                          showPrompt: this.showPrompt,
                          closeCustomerServiceSideBar: this.props.closeCustomerServiceSideBar,
                        }}
                        carrierPayProps={{
                          selectedLoads:this.props.selectedLoad,
                          key:`sub_${this.props.selectedLoad.reference_number}`,
                          updateList:this.props.updateData,
                          blockTabChange:this.blockTabChange,
                          isBlockingTabChange:this.state.isBlockingTabChange,
                          showPrompt:this.showPrompt,
                          isShowingPrompt:this.state.isShowingPrompt,
                        }}
                      />
                    }
                    {this.state.activeTab === "tracking" && (
                      IsELDEnabled() ? <LoadTrackingHistory
                        selectedLoads={this.props.selectedLoad}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        updateList={this.props.updateData}
                      />
                      : <NewLoadHistory
                        selectedLoads={this.props.selectedLoad}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        updateList={this.props.updateData}
                      />
                    )}
                    {this.state.activeTab === "messaging" &&  (
                      <>
                        {
                          this.hasCommunicationTabPermission() ?
                            isEmbeddedEmailEnabled() ?
                              <EmailCommunicationLoad
                                selectedLoad={this.props.selectedLoad}
                                updateUnreadCount={() => this.getUnreadEmailCount()}
                              /> :
                              <NewOneLoadMessaging
                                selectedLoads={this.props.selectedLoad}
                                key={`sub_${this.props.selectedLoad.reference_number}`}
                                location={{
                                  query: {
                                    reference_number: this.props.selectedLoad.reference_number,
                                  },
                                }}
                              /> :
                            <NoPermission />
                        }
                      </>
                    )}
                    {this.state.activeTab === "audit" && (
                      <AuditLoad
                        Terminals={Terminals}
                        selectedLoads={this.props.selectedLoad}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        location={{
                          query: {
                            reference_number:
                              this.props.selectedLoad.reference_number,
                          },
                        }}
                      />
                    )}
                    {this.state.activeTab === "notes" && (
                      <NewNotes
                        updatNotesCountFromChild={this.updatNotesCountFromChild}
                        selectedLoads={this.props.selectedLoad}
                        key={`sub_${this.props.selectedLoad.reference_number}`}
                        showNotes={this.props.showNotes}
                        location={{
                          query: {
                            reference_number:
                              this.props.selectedLoad.reference_number,
                          },
                        }}
                      />
                    )}
                    <Modal show={this.state.isShowingPrompt} className="backdrop_modal_open" dialogClassName="modal-sm modal-dialog-centered">
                      <Modal.Header></Modal.Header>

                      <Modal.Body>
                        <div className="modal-body">
                          <div className={`text-center`}>
                            <div className="mb-30">
                              <CustomIconAlert />
                            </div>

                            <h4 className="modal-title mb-10 text-capitalize">
                              You are missing required fields.
                            </h4>

                            <p className="text-muted font-14 text-capitalize">
                              Do you want to exit the page without finishing ?
                              Any changes made will not be saved
                            </p>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-close"
                          id='cancleChanges'
                          onClick={() =>
                            this.setState({
                              isShowingPrompt: false,
                              nextTab: undefined,
                              closingSideBar: false,
                              toChangeLoad: undefined
                            })
                          }
                        >
                          Cancel
                        </button>
                        <button className={`btn btn-danger`} id='continueWthoutSaving' onClick={() => this.continueWithoutSaving()}>
                          Continue Without Saving
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    actions: bindActionCreators(actions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    allPublicUsers: state.TmsReducer.allPublicUsers,
    drayosTrackRefLoading: state.TmsReducer.drayosTrackRefLoading
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerServiceSideBar);
