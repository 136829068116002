import React, { useEffect, useState } from "react";
import moment from "moment";
import { createLegsFromDriverOrder, legTypeToHuman } from '../Common/functions'
import { getStorage, DateTimeFormatUtils } from "../../services";
import { IconAmount, IconCheck, IconCheckCircle, IconRightMark, IconTimesThin } from "../Common/Icons";
import { getDurationInHHMMFormat } from "../../pages/tms/LoadRouting/utilities";
import { getTrackingEventClasses, isCustomerPortalUser } from "utils";
const NewSummaryRoutingHistory = (props) => {
  const { selectedLoads } = props;
  const timeZone = getStorage("timeZone");
  const [permissions, setPermissions] = useState(null);
  const [driverLegs, setDriverLegs] = useState(null);
  const [legIndexes, setLegIndexes] = useState(null);
  const [measureUnit, setMeasureUnit] = useState(null);
  const [hideTracking, setHideTracking] = useState(false);
  const setting = JSON.parse(getStorage("userBasicSettings"));
  const chackPermissions = () => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer") {
        return true;
      } else if (loggedInUser.permissions.length !== 0) {
        if (loggedInUser.permissions.includes("customer_service_order_status")){ 
          return true;
        }
        else {return false}
      }

      else {return false}
    }
  }


  useEffect(() => {
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    if(currentUser.permissions?.length !== 0) {
      if(isCustomerPortalUser() && !currentUser.permissions.includes("customer_employee_load_summary")){
        setHideTracking(true);
      }
    }
    setPermissions(chackPermissions())
  }, [])
  useEffect(() => {
    const { driverLegs, legIndexes } = createLegsFromDriverOrder(selectedLoads?.driverOrder)
    setDriverLegs(driverLegs);
    setLegIndexes(legIndexes);
    setMeasureUnit(selectedLoads?.carrier?.carrier?.distanceMeasure)
  }, [selectedLoads]);

  return (
    <>
    {permissions && !hideTracking &&
      <div className="card p-10 mb_5">
        <div className="font-14 font-medium line-height-20 mb-10">Tracking</div>
        {/* START TrackBar Design */}
        <div className="d-flex flex-column gap-5">
          {driverLegs?.map((leg, legIndex) => {
            let legColor = legIndex % 2 == 0 ? "blue" : "pink";
            let firstStatus = leg.find((status) => !status?.isVoidOut);
            let showStatus = leg[leg.length - 1]?.departed;
            if (!firstStatus) firstStatus = leg[0];
            const firstValidIndex = leg.findIndex(d => !d.isVoidOut);
            const firstValidEvent = leg?.[firstValidIndex];
            if (leg.length > 0) {
              return (
                <>
                  <div className="px_5 py_5 outline-1 outline-gray-100 rounded-5" key={legIndex}>
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <span className={`badge bg-brand-50 text-blue-500 badge-rounded font-10 font-weight-500 line-height-14 px-2 mr-1`}>
                          Container Move {legIndex + 1}
                        </span>
                        <div>
                          {firstStatus.driver && !hideTracking && (
                            <div className="d-flex align-items-center">
                              <div className="avatar-circle avatar-xs undefined text-uppercase mr-1">
                                  {firstStatus.driver?.name[0]}
                              </div>
                              <div className="font-medium">
                                  {firstStatus?.driver
                                      ? `${firstStatus.driver?.name} ${
                                              firstStatus.driver?.lastName || ""
                                          }`.trim()
                                      : null}
                              </div>
                            </div>
                          )}
                       
                      </div>
                    </div>
                        
                    <div className="d-flex flex-column gap-3">
                      {leg.map((legItem, key) => {
                        const nextValidEventIndex = leg.findIndex((d,i)=> i>key&&!d.isVoidOut);
                        const nextValidEvent = leg?.[nextValidEventIndex];
                        
                        const isFirstEvent = key === 0;
                        const isLastEvent = key === leg.length - 1;
                        const isVoidOut = legItem?.isVoidOut;
                        const isOnProgress = legItem?.departed && !nextValidEvent?.arrived && !isVoidOut;
                        const isArrivedCompleted = isOnProgress && isLastEvent;
                        const isCompleted = (nextValidEvent?.arrived && legItem?.departed && !isVoidOut) || isArrivedCompleted;
                        
                        const isStartMoveEvent = isFirstEvent && firstValidEvent?.arrived && !firstValidEvent?.departed;
                        const isNextPending = !isVoidOut && nextValidEvent?.arrived && !nextValidEvent?.departed;
                        
                        const arrivalEventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress && !isNextPending, isNextPending && !isOnProgress);
                        
                        const topEventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress || isStartMoveEvent, isNextPending);

                        const departureEventClass = getTrackingEventClasses(isVoidOut, isCompleted, isNextPending, isNextPending);

                        const BASE_ROUTE_LINE_CLASS = `route-line route-line--tracking route-line--sidebar route-line--top ${!isLastEvent ? "route-line--bottom" : ""}`;

                        const startRouteLineClass = `${BASE_ROUTE_LINE_CLASS} ${topEventClass?.routeLineColorClass}`;
                        const arrivalRouteLineClass = `${BASE_ROUTE_LINE_CLASS} ${arrivalEventClass?.routeLineColorClass}`;
                        const departureRouteLineClass = `${BASE_ROUTE_LINE_CLASS} ${departureEventClass?.routeLineColorClass}`;
                        
                        const eventMainClass = `route-line route-line-full route-line-full-sm route-line--bottom ${topEventClass?.routeLineColorClass} ${topEventClass?.eventCircleColorClass}`  
                        
                        const cardRouteLineClass = `position-relative route-line ${!isLastEvent? "route-line--bottom" : ""} ${key === 0 ? "" : "route-line--top"} route-line-tracking-card route-line-tracking-card--sidebar`;
                          return (
                            <>
                                  <div className={`outline-1 outline-gray-100 rounded-5 p_5 d-flex flex-column align-items-start ${cardRouteLineClass}`} key={key}>                                      
                                    <div className="d-flex gap-5">
                                      <div className="position-relative w-16px flex-shrink-0 d-flex align-items-start justify-content-center">
                                      <div className={`wh-16px d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle ${eventMainClass}`}>
                                          {(legItem?.departed && nextValidEvent?.arrived) ? 
                                            <IconCheck className="wh-16px" /> 
                                            : legItem?.isVoidOut ? <IconTimesThin className="wh-16px text-danger" /> 
                                            : key + 1
                                          }                                          
                                      </div>
                                      </div>
                                      <div className="d-flex flex-column align-items-start">
                                        <div className="d-flex gap-5">
                                          <span className={`badge badge-sm ${topEventClass?.badgeColorClass}`}>
                                            {legItem?.type && legTypeToHuman(legItem?.type)}
                                          </span>
                                          {legItem?.isVoidOut && <span className="warning badge badge-sm badge-danger">Voided Out</span>}
                                        </div>
                                        <div className="d-flex flex-column gap-3">
                                            <div className="font-medium">{legItem?.company_name && legItem?.company_name}</div>
                                            <div className="text-muted">{legItem?.address && legItem.address?.address}</div>
                                        </div>
                                      </div>
                                    </div>
                                    {isFirstEvent && 
                                    <div className="d-inline-flex position-relative">
                                      <div className="d-flex gap-5">
                                        <div class="wh-16px d-flex align-items-center justify-content-center position-relative">
                                          <div className={`wh-6px rounded-circle outline-1 ${topEventClass?.dotColorClass} ${startRouteLineClass}`}></div>
                                        </div>
                                          <div>
                                            <div className="text-muted font-10 line-height-12">Start</div>
                                              <div className="font-medium">
                                              {firstValidEvent?.arrived ? `${moment(firstValidEvent?.arrived)
                                              .tz(timeZone)
                                              .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                                              : "-"}
                                                </div>
                                          </div>
                                        </div>
                                        <span 
                                          className="position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative ml-10 curved-arrow-left-top curved-arrow-left-bottom left-100"
                                          style={{top: "120%", transform: "translateY(-50%)"}}
                                        >
                                        {legItem?.arrived ?
                                                    legItem?.departed &&
                                                    `${getDurationInHHMMFormat(
                                                      legItem?.departed,
                                                      legItem?.arrived,
                                                      (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: legItem?.timeZone || timeZone})
                                                  )}` : <span className="mx-1">-</span>}
                                        </span>
                                      </div>
                                      }
                                      <div className="d-inline-flex flex-column gap-5 py_5 position-relative">
                                          <div className="d-flex gap-5">
                                          <div class="wh-16px d-flex align-items-center justify-content-center position-relative">
                                            <div className={`wh-6px rounded-circle outline-1 ${arrivalEventClass?.dotColorClass} ${arrivalRouteLineClass}`}></div>
                                          </div>
                                            <div>
                                              <div className="text-muted font-10 line-height-12">Arrival</div>
                                                <div className="font-medium">
                                                  {legItem?.departed
                                                                ? `${moment(legItem?.departed)
                                                                .tz(timeZone)
                                                                .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                                                                : "-"}
                                                  </div>
                                            </div>
                                          </div>
                                          {nextValidEventIndex >=0 && 
                                              <span 
                                                className="position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative ml-10 curved-arrow-left-top curved-arrow-left-bottom left-100"
                                                style={{top: "60%", transform: "translateY(-50%)"}}
                                              >
                                              {nextValidEvent?.arrived ?
                                                          legItem?.departed &&
                                                          `${getDurationInHHMMFormat(
                                                            nextValidEvent?.arrived,
                                                            legItem?.departed,
                                                            (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: legItem?.timeZone || timeZone})
                                                        )}` : <span className="mx-1">-</span>}
                                              </span>
                                          }
                                          {nextValidEventIndex >= 0 && <div className="d-flex gap-5">
                                          <div class="wh-16px d-flex align-items-center justify-content-center position-relative">
                                            <div className={`wh-6px rounded-circle outline-1 ${departureEventClass?.dotColorClass} ${departureRouteLineClass}`}></div>
                                          </div>
                                          <div>
                                              <div className="text-muted font-10 line-height-12">Departure</div>
                                              <div className="font-medium">
                                                  {nextValidEvent?.arrived
                                                      ? `${moment(nextValidEvent?.arrived)
                                                      .tz(timeZone)
                                                      .format(`${DateTimeFormatUtils.abbreviatedDateFormat()} • ${DateTimeFormatUtils.timeFormat()}`)}`
                                                      : "-"}
                                                  </div>
                                                  </div>
                                          </div>}
                                      </div>
                                    
                                  </div>

                                  {!legItem.isVoidOut && nextValidEvent && 
                                    <div className="d-flex justify-content-between" style={{margin: "0 2px"}}>
                                        <span className="font-10 line-height-12 font-weight-normal">
                                          {
                                            (( nextValidEvent && (nextValidEvent?.arrived && nextValidEvent?.departed) ?
                                              `${getDurationInHHMMFormat(
                                                nextValidEvent?.departed,
                                                nextValidEvent?.arrived,
                                                (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: nextValidEvent?.timeZone || timeZone})
                                            )}` : '-')
                                            )}
                                          </span>
                                          {!hideTracking && (
                                            <span className="font-10 line-height-12 font-weight-normal">
                                              {(`${nextValidEvent?.defaultDistance || nextValidEvent?.distance} ${measureUnit ?? 'mi'}` || 0)}
                                            </span>
                                          )}
                                    </div> 
                                  }
                            </>
                          );
                      })}
                    </div>
                  </div>
                  {(legIndex+1) < driverLegs.length && 
                        <div className="d-flex mt_5">
                          <span className="badge badge-light font-12 font-medium">
                                  {
                                      driverLegs[legIndex+1][0]?.arrived &&
                                      driverLegs[legIndex][leg.length -1]?.departed &&
                                          `${getDurationInHHMMFormat(
                                            driverLegs[legIndex+1][0]?.arrived,
                                            driverLegs[legIndex][leg.length -1]?.departed,
                                            (setting?.isUserSpecificTimeZoneEnabled && {
                                              fromTimeZone: driverLegs?.[legIndex+1]?.[0]?.timeZone || timeZone, 
                                              toTimeZone: driverLegs?.[legIndex+1]?.[0]?.timeZone || timeZone
                                            })
                                  )}`}
      
                          </span> 
                      </div>
                  }
                  
                </>
              );
            }
          })}
        </div>
        {/* END TrackBar Design */}
      </div>
    }
    </>
  );
};

export default NewSummaryRoutingHistory;
