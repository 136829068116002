import React, { useState } from "react";
import moment from "moment";
import { DateTimeFormatUtils, defaultDateTimeFormat } from "../../../../../services";
import useCurrentUser from "../../../../../hooks/users/useCurrentUser";
import CustomTooltip from "Components/Common/CustomTooltip";
import { useHover } from "hooks";

const AppointmentDateTimeFrom = ({ row }) => {
  const { currentUserTimeZone } = useCurrentUser();
  const [hoverRef, isHovering] = useHover();

  const showSlotCount = row.requestedAppointmentDateTimeSlots?.length > 1 || row.requestedAppointmentTimeSlots?.length > 1;
  const extraSlotCount = (row.requestedAppointmentDateTimeSlots?.length > 0 ? row.requestedAppointmentDateTimeSlots?.length :
    row.requestedAppointmentTimeSlots?.length) - 1;

  return (
    <>
      <div className="cell-content d-flex align-items-center justify-content-between">
        {(Array.isArray(row.requestedAppointmentDateTimeSlots) || Array.isArray(row.requestedAppointmentTimeSlots)) && (
          <>
            {row.requestedAppointmentDateTimeSlots && row.requestedAppointmentDateTimeSlots.length > 0 && (
              <div className="rdg-truncate">
                {moment(row.requestedAppointmentDateTimeSlots[0]?.appointmentDateTimeStart)
                  .tz(currentUserTimeZone)
                  .format(DateTimeFormatUtils.fullDateTimeFormat())}{" "}
                -{" "}
                {moment(row.requestedAppointmentDateTimeSlots[0]?.appointmentDateTimeEnd)
                  .tz(currentUserTimeZone)
                  .format(DateTimeFormatUtils.fullDateTimeFormat())}
              </div>
            )}

            {row.requestedAppointmentTimeSlots?.length > 0 &&
              <div className="mt-1">
                {moment.utc(row.requestedAppointmentTimeSlots?.[0]?.appointmentTimeStart, "HH:mm").format(defaultDateTimeFormat.time)} -{" "}
                {moment.utc(row.requestedAppointmentTimeSlots?.[0]?.appointmentTimeEnd, "HH:mm").format(defaultDateTimeFormat.time)}
              </div>
            }

            {showSlotCount && (
              <span
                className="badge badge-secondary ml-1"
                ref={hoverRef}
              >
                +{extraSlotCount}
              </span>
            )}
            {isHovering &&
                <CustomTooltip isOpen={isHovering} refNo={hoverRef?.current} color={"gray-700"} className={"max-w-300px max-vh-100 pull-y"} placement={"auto"} offset={[0, 0]}>
                  {row.requestedAppointmentDateTimeSlots && row.requestedAppointmentDateTimeSlots.length > 0 && row.requestedAppointmentDateTimeSlots.slice(1).map((dateTime, index) => (
                    <div key={index} className="mt-1">
                      {moment(dateTime?.appointmentDateTimeStart)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}{" "}
                      -{" "}
                      {moment(dateTime.appointmentDateTimeEnd)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}
                    </div>
                  ))}
                  {row.requestedAppointmentTimeSlots?.slice(1)?.map((time) => (
                    <div className="mt-1" key={time._id}>
                      {moment.utc(time?.appointmentTimeStart, "HH:mm").format(defaultDateTimeFormat.time)} -{" "}
                      {moment.utc(time?.appointmentTimeEnd, "HH:mm").format(defaultDateTimeFormat.time)}
                    </div>
                  ))}
                </CustomTooltip>
                }
          </>
        )}
      </div>
    </>
  );
};

export default AppointmentDateTimeFrom;
