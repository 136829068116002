import { IconMinus, IconPlus } from "Components/Common/Icons";
import { useOnClickOutside } from "hooks";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import _ from "lodash";
import { useContext, useRef } from "react";
import { createPortal } from "react-dom";
import { toastr } from "services";
import { addChartToMyDashboard, removeChartFromMyDashboard } from "../../actionCreator";
import { COUNTER_CARDDS_CHARTS, DASHBOARDS, TAB_NAMES } from "../../constants";
import { getMyDashboardComponents, TypeDashBoardContext } from "../../helper";

const DashboardMenuPortal = (props) => {
  const { cellRef, setShowPopup, reportType } = props;
  const { myDashboardCharts, activeTabName, myDashboardPageConfig, getMyDashboardCharts, isChartsPositionChanged, setActiveTabName } = useContext(TypeDashBoardContext);
  const boxRef = useRef(null);
  const style = useDropDownPosition("auto", boxRef, cellRef);
  const chartAddDisabled = myDashboardCharts?.length >= 20;
  const isChartAddedToMyDashboard = myDashboardCharts?.includes(reportType)
  useOnClickOutside([boxRef, cellRef], () => {
    setShowPopup(false);
  });

  const removeChart = async () => {
    try {
      const payload = { chartName: reportType };
      const response = await removeChartFromMyDashboard(payload);
      if (response) {
        toastr.show(response?.data?.message, "success");
      }
      if(activeTabName===DASHBOARDS.MY_DASHBOARD && !(myDashboardCharts?.length - 1)){
        setActiveTabName(DASHBOARDS.ADMIN)
      }
    } catch (err) {
      toastr.show(err?.message, "error");
    }
    getMyDashboardCharts();
    setShowPopup(false);
  };
  const addChart = async () => {
    try {
      let _myDashboardCharts = [];
      if (chartAddDisabled) {
        throw new Error("Only 20 Charts Allowed")
      }
      if (isChartsPositionChanged) {
        const component = {
          x: 0,
          y: 0,
          w: COUNTER_CARDDS_CHARTS.includes(reportType) ? 3 : 5,
          h: COUNTER_CARDDS_CHARTS.includes(reportType) ? 1 : 3,
          chartName: reportType,
          dashboardName: TAB_NAMES[activeTabName],
        };
        _myDashboardCharts = _.cloneDeep(myDashboardPageConfig?.components || [])?.map((e) => {
          let _e = _.cloneDeep(e);
          _e = {
            ..._e,
            chartName: e?.reportType,
          };
          delete _e?.reportType;
          return _e;
        });
        _myDashboardCharts.push(component);
      } else {
        _myDashboardCharts = getMyDashboardComponents([...(myDashboardCharts || []), reportType]);
      }
      const payload = {
        chartsSetup: _.uniqBy(_myDashboardCharts, "chartName"),
      };
      const response = await addChartToMyDashboard(payload);
      if (response) {
        toastr.show(response.message, "success");
      }
    } catch (err) {
      toastr.show(err.message, "error");
    }

    getMyDashboardCharts();
    setShowPopup(false);
  };

  return createPortal(
    <div
      ref={boxRef}
      className="w-230 bg-white rounded-5 p-10 position-fixed z-1051 overflow-auto shadow-5 d-flex flex-column gap-5"
      style={style}
    >
      <div
        className="d-flex align-items-center"
        style={{
          backgroundColor: !isChartAddedToMyDashboard && chartAddDisabled ? "" : "",
          opacity: !isChartAddedToMyDashboard && chartAddDisabled ? 0.5 : 1,
          pointerEvents: !isChartAddedToMyDashboard && chartAddDisabled ? "auto" : "auto",
          cursor: !isChartAddedToMyDashboard && chartAddDisabled ? "not-allowed" : "pointer",
        }}
        onClick={!isChartAddedToMyDashboard && chartAddDisabled
          ? (e) => e.preventDefault()
          : isChartAddedToMyDashboard
            ? removeChart
            : addChart}
      >
        {!isChartAddedToMyDashboard ? (
          <IconPlus className="text-muted" />
        ) : (
          <IconMinus className="text-muted" />
        )}
        <span className="font-14 text-dark ml-2">
          {isChartAddedToMyDashboard ? "Remove from My Dashboard" : "Add to My Dashboard"}
        </span>
      </div>
    </div>,
    document.body
  );
};

export default DashboardMenuPortal;
