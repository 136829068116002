import React from 'react'
import { IconFire, IconHighway, IconLiquor, IconMapMarker, IconOverHeight, IconScale, IconStreetTurn, IconTrainTrack, IconWarning, IconWaste, IconWeightTool, IconSnowFlake, IconPlusCircle, IconFlash, IconPlug } from 'Components/Common/Icons';
import { isNewEndorsement, getStorage, showForCarrier, getCarrierId, isEurope } from '../../../services/Common.services';
import { StateFormSearchPlaces } from "../../../Components/FormComponent/StateFormComponent";
export const allEndorsements = ()=>( 
    [
    {
        name:"hazmat",
        label:"Hazmat",
        icon:<IconWarning className="text-warning mr-1" />,
        show:true
    },
    {
        name:"liquor",
        label:showForCarrier() ? "Liquid" : "Liquor",
        icon:<IconLiquor className="mr-1" />,
        show:true,
        isBooleanString: true
    },
    {
        name:"overHeight",
        label:"Over Height",
        icon:<IconOverHeight className=" mr-1" />,
        show:true
    },

    {
        name:"reefer",
        label:"Reefer",
        icon:<IconSnowFlake className="text-skyblue-500 mr-1" />,
        show:true,
        isBooleanString:true
    },
    {
        name:"waste",
        label:"Waste",
        icon:<IconWaste className="text-error-500 mr-1" />,
        show:isNewEndorsement()
    },
    {
        name:"highway",
        label:"Highway",
        icon:<IconHighway className="text-gray-700 mr-1" />,
        show:true
    },
    {
        name:"local",
        label:"Local",
        icon: <IconMapMarker className="text-gray-700 mr-1" />,
        show:true
    },
    // {
    //     name:"bonded",
    //     label:"Bonded",
    //     show:true
    // },
    {
        name:"gdp",
        label:"GDP",
        icon:<IconPlusCircle className="text-gray-700 mr-1" />,
        show:isNewEndorsement()
    },
    {
        name:"domestic",
        label:"Domestic",
        icon:<IconTrainTrack className="text-gray-700 mr-1" />,
        show: isNewEndorsement()
    },
    {
        name:"genset",
        label:"Genset",
        icon: <IconFlash className="text-gray-700 mr-1" />,
        show: true
    },
    {
        name:"hot",
        label:"Hot",
        icon:<IconFire className="text-error-500 mr-1" />,
        show: true
    },
    {
        name:"ev",
        label:"EV",
        icon: <IconPlug className="text-gray-700 mr-1" />,
        show: isNewEndorsement()
    },
    {
        name:"streetTurn",
        label:"Street Turn",
        icon:<IconStreetTurn className="text-gray-700 mr-1" />,
        show: true
    },
    {
        name: "scale",
        label:"Scale",
        icon:<IconScale className="text-gray-700 mr-1" />,
        show:JSON.parse(getStorage("userBasicSettings"))?.isScaleEnabled
    },
    {
        name:"overweight",
        label:"Overweight",
        icon: <IconWeightTool className="text-gray-700 mr-1" />,
        show: true
    }


])
const Endorsements = (props) => {
    const {
        state,
        setState,
        formErrors,
        formsTouched,
        setValChange,
        isDriverPreferencesDisabled,
        updateLabel,
        getDefaultOptions
    } = props;
    const handleChange = (selectedOption, selectedValue) => {
        setState?.({key:selectedOption, 
            value: selectedValue
        })
    }
    return (
        <div>
            <div className="font-14 font-medium mb-15">Endorsements</div>
            <div className='grid grid-cols-5 row-gap-10 column-gap-5'>
            {allEndorsements()?.map((endorsement,index)=>{
                let value = state?.[endorsement?.name]; 
                if(endorsement?.isBooleanString && typeof value === "string"){
                    value = value === "true"
                }
                return (endorsement?.show && (
                <div className='' key={index}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            onClick={()=>handleChange(endorsement?.name, !value)}
                            checked={value}
                            id={endorsement?.name}
                            disabled={isDriverPreferencesDisabled}
                        />
                        <label className="form-check-label pl-1" htmlFor={endorsement?.name}>
                            {endorsement?.icon}
                            {endorsement?.label}
                        </label>
                    </div>
                   {endorsement?.name === "overweight" &&
                   <>
                    <div className='mt_5'>
                    {
                     isEurope()? <StateFormSearchPlaces
                    name="overWeightCountry"
                    label={""}
                    formErrors={formErrors}
                    formsTouched={formsTouched}
                    onValChange={setValChange}
                    isMulti={true}
                    value={state?.overWeightCountry}
                    isDisabled={state?.isShiposCarrier || isDriverPreferencesDisabled || !value}
                    placeType="COUNTRY"
                    placeholder="Select Overweight"
                    notShowLabel={false}
                    components={true}
                    inputClassName={"not-show-count"}
                    isShowCountryStates={true}
                    defaultOptions={getDefaultOptions(state?.defaultStates,state?.overWeightCountry )|| []}
                    className="w-200"
                    updateLabel={updateLabel}
                    isCustom={true}
                    /> :
                        <StateFormSearchPlaces
                            name="owerWeightStates"
                            label={""}
                            formErrors={formErrors}
                            formsTouched={formsTouched}
                            onValChange={setValChange}
                            isMulti={true}
                            value={state?.owerWeightStates}
                            isDisabled={state?.isShiposCarrier || isDriverPreferencesDisabled || !value}
                            placeType="STATE"
                            placeholder="Select Overweight"
                            notShowLabel={false}
                            components={true}
                            inputClassName={"not-show-count"}
                            isShowCountryStates={true}
                            defaultOptions={getDefaultOptions(state?.defaultStates, state?.owerWeightStates) || []}
                            className="w-200"
                            updateLabel={updateLabel}
                            isCustom={true}
                        />
                   }
                    </div>
                </>
                   }
                </div>
            ))})}
            </div>
        </div>
    )
}

export default Endorsements