import React, { useState, useRef, useEffect, memo } from 'react'
import { createPortal } from 'react-dom';
import { IconDelete, IconPlus, IconGear, IconCheck } from '../../../Components/Common/Icons';
import useBoxPosition from './useBoxPosition';
import { useOnClickOutside } from 'hooks';

const SelectCustomComponent = (props) => {
    const { searchable, name, onDeleteItem, unselectCell, onCreateOption, listData, keyName, descriptionName, onSelect, loadOptions, refClassName, size, isSelectBoxOptions, selectedValues, isEmailSignature } = props
    const [searchResult, setSearchResult] = useState([]);
    const [isItemHover, setItmeHover] = useState({ state: false, name: null });
    const searchVal = useRef(null);
    const boxRef = useRef(null)
    const [cursor, setCursor] = useState(null);
    const [showAdd,setshowAdd] = useState(false)
    const cellRef = document.querySelector(`.${refClassName}`)
    const effectiveCellRef = useRef(cellRef)
    
    const style = useBoxPosition(cellRef, boxRef)
    const [updateList,setUpdateList] = useState(false)
    useEffect(() => {
        let element = document.querySelector(".credit-info-modal");
        if (element) {
            element.removeAttribute('tabindex');
        } else {
            document.querySelector(".load-info-modal")?.removeAttribute('tabIndex');
        }
        searchVal?.current?.focus()
        return () => {
            if (element) {
                element.setAttribute('tabindex', -1);
            } else {
                document.querySelector(".load-info-modal")?.setAttribute("tabIndex", -1);
            }
        }
    }, [])
    useEffect(() => {
        setSearchResult(listData);
    }, [])

    useOnClickOutside([boxRef, effectiveCellRef], ()=> unselectCell())

    const handleSelect = (e, name, data) => {
        e.preventDefault();
        e.stopPropagation()
        unselectCell();
        onSelect(name, data)
    }

    const handleOnDelete = (e, data) => {
        e.stopPropagation();
        onDeleteItem && onDeleteItem(data).then(res=>setSearchResult(res));
    }

    let filterTimeout
    const onHandleInput = async (e) => {
       const {value} = e.target
        setCursor(null);
        clearTimeout(filterTimeout)
        if (loadOptions) {
            filterTimeout = setTimeout(() => {
                loadOptions(value).then(res => {
                    setSearchResult(res)
                })
            }, 500)
        } else {
            if (typeof listData[0] === "object") {
                let filterSearch = listData.filter((d) => d[keyName].toLowerCase().indexOf(value.toLowerCase().trim()) != -1)
                const isAvaiable =listData.find(el=>el[keyName]===value)
                if(!isAvaiable){
                    setshowAdd(true);
                } else{
                    setshowAdd(false)
                }
                setSearchResult(filterSearch)
            } else {
                let filterSearch = listData.filter((d) => d.toLowerCase().indexOf(value.toLowerCase().trim()) != -1);
                const isAvaiable =listData.find(el=>el===value)
                if(!isAvaiable){
                    setshowAdd(true);
                } else{
                    setshowAdd(false)
                } 
                setSearchResult(filterSearch);
            }
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 38) {
            if (cursor <= searchResult?.length - 1) {
                if (cursor === null) {
                    setCursor(0);
                    document.getElementById(`driver-${cursor}`)?.scrollIntoView({ behavior: "smooth" });
                } else {
                    if (cursor === 0) return
                    setCursor((prev) => prev - 1);
                    document.getElementById(`driver-${cursor - 1}`)?.scrollIntoView({ behavior: "smooth" });
                }
            }
            event.preventDefault();
        } else if (event.keyCode === 40) {
            if (cursor <= searchResult?.length - 1) {
                if (cursor === null) {
                    setCursor(0);
                    document.getElementById(`driver-${cursor}`)?.scrollIntoView({ behavior: "smooth" });
                } else {
                    if (searchResult.length - 1 === cursor) return
                    setCursor((prev) => prev + 1);
                    document.getElementById(`driver-${cursor}`)?.scrollIntoView({ behavior: "smooth" });
                }
            }
            event.preventDefault();
        } else if (event.keyCode === 13) {
            searchResult?.forEach((val, index) => {
                if (index === cursor) handleSelect(event,name,val);
            });
            event.preventDefault();
        } else if (event.keyCode === 9 || event.keyCode === 27) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return createPortal(
        <div id="SelectPopUp" ref={boxRef} className={`card card--shadow-5 popdrop popdrop--sm mb-0 mt-3 p-10 text-left card-cstm-selector rounded-4`} style={style}>
            {searchable && <div className="app-search  mb-10">
                <span className="search-icon ml-1"></span>
                <div className="position-relative text-dark">
                    <input
                        type="input"
                        className="form-control input-search-left bg-gray-50"
                        placeholder="Search..."
                        onKeyDown={(event) => {
                            handleKeyDown(event);
                        }}
                        onChange={(e) => onHandleInput(e)}
                        ref={searchVal}
                    />
                </div>
            </div>}
            <div className="select-list-menu" style={{ maxHeight: "181px", overflowY: "auto" }}>
                <ul className="mb-0 mr-1">
                    {searchResult?.length === 0 ? (
                        <p className="p-1">No {name} Available</p>
                    ) : (
                        searchResult?.map((data, index) => {
                            const item = typeof data === "object" ? data[keyName] : data
                            const desc = typeof data === "object" ? data[descriptionName] : null
                            return (
                                <li
                                    key={index}
                                    href="javascript:void(0)"
                                    onClick={(e) => handleSelect(e, name, data)}
                                    className={`pointer d-flex justify-content-between text-break text-wrap align-items-center font-14 popdrop__dropdown-item ${cursor === index ? "bg-gradient-light" : null
                                        }`}
                                    tabIndex="-1"
                                    id={`driver-${index}`}
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    onMouseOver={() => setItmeHover({ state: true, name: `item${index}` })}
                                    onMouseOut={() => setItmeHover({ state: false, name: null })}
                                >
                                    {isSelectBoxOptions ? 
                                    <div className='d-flex'>
                                        <div>
                                            <input
                                                className="form-check-input rounded-3 border-secondary"
                                                type="checkbox"
                                                checked={selectedValues ? selectedValues?.includes(data?.value)  : false }
                                            />
                                        </div>
                                        <div className="ml-1">{item}</div>
                                    </div> : 
                                    isEmailSignature ?
                                    <div className='d-flex align-items-center'>
                                        
                                            {
                                                data?.isManageOpt ? <IconGear className={'mr-1 text-dark'}/> :
                                                data?.value === selectedValues?.value ? <IconCheck className={'mr-2'}/> :
                                                <div className='mr-4'></div>
                                            }
                                            <span className="m-0">{item}</span>
                                    </div>
                                    :
                                    <div className='d-flex align-items-center justify-content-between' style={{flex:1}}>
                                    <div className='d-flex flex-column gap-2'>
                                    <div>{item}</div>
                                    {desc && <div className="text-muted font-10">{desc}</div>}
                                    </div>
                                    {onDeleteItem && <div className={`${(isItemHover.state && isItemHover.name === `item${index}`) ? "visible" : "invisible"} pointer`}
                                        onClick={(e) => handleOnDelete(e, data)}><IconDelete /></div>}
                                    </div>
                                    }
                                </li>
                            );
                        })
                    )}
                </ul>
            </div>
            {
                onCreateOption && showAdd && searchVal.current?.value&& <button type="button" onClick={(e) => {
                    e.stopPropagation()
                    if (searchVal.current?.value.length !== 0) {
                        onCreateOption(searchVal.current.value)
                        onSelect(name, searchVal.current.value)
                    }
                }} className="btn btn-sm text-primary">
                    <IconPlus className="mr-15" /> <span class="text-break text-wrap text-left">{`Add ${searchVal?.current?.value}`}</span>
                </button>
            }
        </div >, document.getElementById("pp-overlay-container")
    )
}

export default memo(SelectCustomComponent) 