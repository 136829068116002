import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import PortImages from "../../../assets/images/ports/PortImages";
import AppointmentSlotsPicker from "./AppointmentSlotsPicker";
import ConfirmationModal from "./Components/ConfirmationModal";

import { MOVE_TYPES, FORM_MODE, APPOINTMENT_STATUSES } from "../../../constants";

import useCurrentUser from "../../../hooks/users/useCurrentUser";
import useAppointmentModal from "./hooks/useAppointmentModel";
import PromptAlert from "./Components/PromptAlert";
import { checkIfMoveIsCancelled, checkIfPickupReturnTimesExists } from "utils";
import { updateTerminology } from "services";

const AppointmentModal = ({ load, moveType, closeModal, appointmentId, isAllowedForDirectBooking = false, standardPorts, isBulkAppointment, bookDirectAppointment, updateSelectedRows = () => {}, isScheduleAppointment = false, recommendedAppointmentDate }) => {
  const { currentUserTimeZone } = useCurrentUser();

  const [appointmentSystemId, setAppointmentSystemId] = useState(null);

  useEffect(() => {
    if (load && Array.isArray(load)) {
      load.forEach((loadItem) => {
        const pickupApptId = moveType === MOVE_TYPES.PICKUP ? loadItem.pickupAppointmentSystem : null;
        const emptyApptId = moveType === MOVE_TYPES.EMPTY ? loadItem.emptyAppointmentSystem : null;

        if (pickupApptId || emptyApptId) {
          setAppointmentSystemId(pickupApptId ? pickupApptId : emptyApptId);
        }
      });
    }
    return () => { };
  }, [load]);

  const {
    isAppointmentModal,
    isConfirmationModal,
    requestedDatesList,
    isLoading,
    slotsCount,
    defaultTimeSots,
    defaultDateTimeSots,
    formMode,
    onSubmitAppointment,
    onCloseConfirmationModal,
    setSelectedSlot,
    appointmentLastDate,
  } = useAppointmentModal({
    moveType,
    load,
    appointmentId: appointmentId ? appointmentId : appointmentSystemId,
    closeModal,
    currentUserTimeZone,
    standardPorts,
    bookDirectAppointment,
    updateSelectedRows,
    isScheduleAppointment,
    recommendedAppointmentDate,
  });

  const [isPrompt, setIsPrompt] = useState(null);

  const handleClostPrompt = () => {
    setIsPrompt(null);
    closeModal();
  };


  useEffect(() => {
    if (checkIfPickupReturnTimesExists(moveType, load)) {
      setIsPrompt(`Do you want to remove entered ${moveType === MOVE_TYPES.PICKUP ? 'pickup' : 'return'} times ?`)
    }
    else if (formMode == FORM_MODE.EDIT) {
      if (!checkIfMoveIsCancelled(moveType, load))// don't show the confirmation modal on cancel status
        setIsPrompt("Are you sure you want to update and re-schedule the appointment?")
    } else {
      setIsPrompt(null)
    }
  }, [formMode || load])


  useEffect(() => {
    if (isAllowedForDirectBooking) {
      onSubmitAppointment();
    };
  }, [isAllowedForDirectBooking])

  return (
    <React.Fragment>
      <Modal
        show={isAppointmentModal && !isPrompt && !isConfirmationModal && !isAllowedForDirectBooking}
        animation={false}
        onHide={closeModal}
        dialogClassName="modal-fullpage modal-dialog-scrollable"
      >
        <Modal.Header>
          <Modal.Title>
            {formMode === FORM_MODE.EDIT
              ? `Update ${updateTerminology('terminal')} Appointment`
              : `Automatically Schedule ${updateTerminology('terminal')} Appointment`}{" "}
          </Modal.Title>
          {/* TODO: ShipperName/EmptyOrigin Port Images */}
          <img
            style={{
              maxWidth: "100px",
              maxHeight: "40px",
              objectFit: "contain",
            }}
            src={PortImages[moveType === MOVE_TYPES.PICKUP ? load?.shipperName : load?.emptyOriginName]}
          />
        </Modal.Header>
        <Modal.Body className="flex-grow-1 d-flex flex-column pb-0">
          <AppointmentSlotsPicker
            lastDate={appointmentLastDate}
            isLoading={isLoading}
            slotsCount={slotsCount}
            defaultTimeSlots={defaultTimeSots}
            defaultDateTimeSlots={defaultDateTimeSots}
            timeZone={currentUserTimeZone}
            handleSlotSelection={(data) => setSelectedSlot(data)}
            isScheduleAppointment={isScheduleAppointment}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-link" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSubmitAppointment} disabled={isLoading}>
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            )}
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmationModal
        key={isConfirmationModal}
        isConfirmationModal={isConfirmationModal}
        dates={requestedDatesList}
        onClose={onCloseConfirmationModal}
        isAllowedForDirectBooking={isAllowedForDirectBooking}
      />
      <PromptAlert
        showPrompt={isPrompt && !isAllowedForDirectBooking}
        content={isPrompt}
        confirm={() => setIsPrompt(null)}
        closePrompt={() => {
          handleClostPrompt();
          // onCloseConfirmationModal();
        }}
        modalBodyClass="px-10"
      />
    </React.Fragment>
  );
};

export default AppointmentModal;
