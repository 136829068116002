import React from 'react'
import { Modal } from "react-bootstrap";
import { CustomIconAlert } from "../../../Components/Common/CustomIcons/Index";
import { IconCloseBold } from "../../../Components/Common/Icons/index";

const UpdateBillCharge = ({ show, onselect, close }) => {
  return (
    <div>
      <Modal show={show} centered={"true"} animation={true} dialogClassName="modal-500px" backdropClassName="z-1050">
        <div className='position-absolute right-0 top-0 mt-15 mr-20 z-3 cursor-pointer' onClick={close}>
          <IconCloseBold className="font-20"/>
        </div>
        <Modal.Body>
          <div className='text-center'>
              <CustomIconAlert className="my-25" />
              <h2 className='font-20 font-weight-500 text-center text-dark text-capitalize'>Update Default Bill To Charge Set?</h2>
              <p className='font-14 line-height-22 font-weight-normal text-dark text-center text-capitalize'>Would you like to update the Bill To party of the default charge set on this load?</p>
              <div>
                <button className='btn btn-outline-light my-10 text-capitalize' onClick={ () => onselect(true) }>Yes, update the Bill To party of the Default Charge Set</button>
                <button className='btn btn-outline-light text-capitalize' onClick={ () => onselect(false) }>No, keep the Bill To party of the Default Charge Set as it is</button>
              </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpdateBillCharge