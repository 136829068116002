import { useContext, useState } from "react";
import { getStorage, toastr } from "services";
import { moveEmail } from "../actionCreator";
import { EMAIL_CONTEXT, EMAIL_MSG, EMAIL_PROVIDER, EMAIL_TABS } from "../constant";
import _ from "lodash";

export const useMoveEmail = (allEmails, setAllEmails, callback, notFoundCallback) => {
  const [data, setData] = useState(null);
  const [moveEmailloaderIds, setMoveEmailLoaderIds] = useState([]);
  const { allLabels,activeTabId, activeNav } = useContext(EMAIL_CONTEXT);

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
	const { provider } = embeddedEmailAccount ?? {};

  const onSuccess = (data, isTrash) => {
    if(data?.failed?.length) {
      return toastr.show('Something went wrong!', "error");
    }
    const isSystemFolder =allLabels.find(e=>e.id===activeTabId)?.systemFolder ?? true
    const newMails = allEmails?.filter((email) => {
      if (data?.notFound?.some(e => e?.id === email?.id)) {
        return false;
      }
      const found = data.success.find(e=>e.id===email.id)
      if(found){
        if (activeNav === EMAIL_TABS.DRAFT) return true
        if(!isSystemFolder && (found?.folders?.includes("SPAM") || found?.folders?.includes("TRASH"))) return false
        if(["SPAM", "TRASH"].some(folder => found?.folders?.includes(folder)) && activeTabId==="SENT") return false
        if(found?.folders?.includes(activeTabId)) return true
        if (activeTabId === 'all' && !["SPAM", "TRASH"].some(folder => found?.folders?.includes(folder))) return true
        else return false
      } else return true
    });
    const uniqueFolders = _.uniq(_.flatMap(data.success, 'folders'));
    if(isTrash && provider===EMAIL_PROVIDER.GMAIL) uniqueFolders.push("INBOX")
    callback(uniqueFolders);
    setAllEmails(newMails);
    const hasNotFound = data?.notFound?.length > 0;
    const hasSuccess = data?.success?.length > 0;

    if (isTrash) {
      if (hasSuccess) {
        toastr.show(EMAIL_MSG.EMAIL_DELETED, "success");
      }
      if (hasNotFound) {
        hasNotFound > 1 ? toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE, "success") : toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_SINGLE, "success");
      }
    } else {
      if (hasNotFound && hasSuccess) {
        toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE, "success");
        toastr.show(EMAIL_MSG.EMAIL_MOVED, "success");
      } else if (hasNotFound) {
        data?.notFound?.length > 1 ? toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE, "success") : toastr.show(EMAIL_MSG.NOT_FOUND_IN_NYLAS_SINGLE, "success");
      } else if (hasSuccess) {
        toastr.show(EMAIL_MSG.EMAIL_MOVED, "success");
      }
    }

    if(hasNotFound){
      notFoundCallback && notFoundCallback();
    }
  };

  const _moveEmail = async (params, isTrash = false, folderId) => {
    setMoveEmailLoaderIds(params.ids);
    try {
      let payload = params;
      if (isTrash) {
        const deletefolderId = allLabels.find((l) => ["Deleted Items","TRASH"].includes(l.name)).id;
        payload = { moveToId:deletefolderId, ids: params.ids };
      }
      if(folderId){
        payload.moveToId = folderId
      }
      if(activeTabId){
        payload.moveFromId = activeTabId
      }
      const data = await moveEmail(payload);
      setData(data);
      onSuccess(data, isTrash);
      setMoveEmailLoaderIds([]);
    } catch (err) {
      console.log(err);
      setMoveEmailLoaderIds([]);
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    }
  };
  return { data, moveEmailloaderIds, _moveEmail };
};
