import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { smallSelectStyle } from "assets/js/select-style";
import { IconPlus } from "Components/Common/Icons";
import { DEFAULT_TAGS_COLORS } from "./const";
import TagOption from "./TagOption";
import { isEqual, uniqBy } from "lodash";
import { amplitudeTrack, toastr } from "services";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "pages/tms/Email/constant";
import { useSelector } from "react-redux";
import { calculateTextColor } from "pages/tms/Email/helper";

const EditTagModal = ({ tag: oldTag, hide, updateTag, loading, createTag }) => {
  const { emailTagStore } = useSelector(store => store.emailTagsReducer)
  const initialTag = {
    label: oldTag.label,
    color: oldTag.color || DEFAULT_TAGS_COLORS[0],
    description: oldTag.description,
    value: oldTag.value,
    orderIndex: oldTag.orderIndex
  };

  const [tag, setTag] = useState(initialTag);
  const [showColorPicker, setShowColorPicker] = useState(null);
  const textColor = calculateTextColor(tag.color) // Using the hook here

  const onChange = (e) => {
    const { name, value } = e.target || e;
    setTag({ ...tag, [name]: value });
    calculateTextColor(tag.color);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(tag?.label?.length > 30) {
      toastr.show("label length must be less than or equal to 30 characters long", "error"); 
      return;
    }

    if(tag?.label && !tag?.label?.trim()) {
      toastr.show("label cannot be empty", "error"); 
      return;
    }
    if (oldTag._id === "new") {
      amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_TAG_ADD, { source: AMPLITUDE_EVENTS_SOURCE.EMAIL_TAG_ADD });
      await createTag(tag);
      hide(false);
      return;
    }
    amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_TAG_EDIT, { source: AMPLITUDE_EVENTS_SOURCE.EMAIL_TAG_EDIT });
    await updateTag(oldTag._id, tag);
    hide(false);
  };
  const customOptions = uniqBy(emailTagStore, "value")?.map((tag) => ({
    label: <TagOption tag={tag} />,
    value: tag.value,
  }));
  return (
    <Modal show={!!tag} dialogClassName="modal-dialog modal-sm">
      <form id="create-tag-form" onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>
            {oldTag._id === "new" ? "Create" : "Edit"} Tag
            <div className="text-muted font-12 font-weight-normal mt-1">
              For each Tag Type, you can change the Tag name & color that displays
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="form-group mb-15">
            <label className="d-block">
              <span className="text-danger">*</span> Tag
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter tag"
              name="label"
              value={tag.label}
              onChange={onChange}
            />
          </div>
          <div className="mb-15">
            <label className="d-block">
              <span className="text-danger">*</span> Tag Type
            </label>
            {oldTag.isDefault ? (
              (tag.value || tag.color) && (
                <span
                  className={`badge badge-sm text-white px-2 py-1 ${tag.color ? "" : "badge-light"} ${
                    textColor ? textColor : ""
                  }`}
                  style={{ backgroundColor: tag.color }}
                >
                  {tag.value}
                </span>
              )
            ) : (
              <div className="w-200">
                <Select
                  name="value"
                  styles={smallSelectStyle}
                  options={customOptions}
                  onChange={({ value }) => onChange({ name: "value", value })}
                  value={customOptions.find((o) => o.value === tag.value)}
                />
              </div>
            )}
          </div>
          <div className="form-group mb-15">
            <label>Description</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter description"
              name="description"
              value={tag.description || ""}
              onChange={onChange}
            />
          </div>
          <div className="form-group mb-15">
            <label>Tag Color</label>
            <div className="text-muted font-10 mb-1">Highlight your label with helpful visuals</div>
            <div className="d-flex flex-wrap gap-7 align-items-center">
              {DEFAULT_TAGS_COLORS.map((color, index) => (
                <input
                  type="checkbox"
                  className={`form-check-input border-0 rounded-3 ${
                    tag.color === color ? "outline-2 outline-gray-100" : ""
                  }`}
                  style={{ backgroundColor: color }}
                  key={index}
                  name="color"
                  value={color}
                  onChange={onChange}
                  checked={tag.color === color}
                />
              ))}
              <span className="pointer" onClick={() => setShowColorPicker((prevState) => !prevState)}>
                <IconPlus className="text-muted" />
              </span>
            </div>
          </div>
          {showColorPicker && (
            <div className="form-group mb-0">
              <label>Manual Color</label>
              <input
                type="color"
                name="color"
                className="form-control p-1 wh-24px"
                value={tag.color}
                onChange={onChange}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-close" onClick={hide}>
            Cancel
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading || !tag?.value || !tag?.label || isEqual(initialTag, tag)}>
            {loading && (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            )}
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditTagModal;
