import { useEffect, useMemo, useState } from "react";
import { createAppointmentSystem, updateAppointmentSystem } from "../../../../services/appointmentSystem.services";
import { toastr } from "../../../../services";

import * as appointmentSystemServices from "../../../../services/appointmentSystem.services";

import { APPOINTMENT_TIME_CATEGORY } from "../AppointmentSlotsPicker/constants";
import { FORM_MODE, LOAD_TYPES, MOVE_TYPES, ORDER_NO, APPOINTMENT_SOURCES } from "../../../../constants";

import { convertMilitaryToTime } from "../AppointmentSlotsPicker/helpers/timeConverter";
import moment from "moment";
import { getOrderNo } from "../AppointmentSlotsPicker/helpers/appointmentHelper";
import { hasOnlyUndefined } from "utils";
import { checkIfAllowedForDirectBooking } from "../../NewDispatcher/utility";

/**
 * useAppointmentModal is a companion hook for AppointmentModal component
 */
const useAppointmentModal = ({ moveType, load, appointmentId, closeModal, currentUserTimeZone, standardPorts = [], bookDirectAppointment = () => { }, updateSelectedRows = () => { }, isScheduleAppointment = false, recommendedAppointmentDate = {} }) => {
  const [slotsCount, setSlotsCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultTimeSots, setDefaultTimeSlots] = useState([]);
  const [defaultDateTimeSots, setDefaultDateTimeSlots] = useState([]);
  const [isAppointmentModal, setIsAppointmentModal] = useState(moveType ? true : false);
  const [requestedDatesList, setRequestedDatesList] = useState([]);

  const isConfirmationModal = useMemo(() => {
    if (isLoading) return false;
    if (requestedDatesList?.length == 0) return false;
    return true;
  }, [requestedDatesList, isLoading]);

  const onCloseConfirmationModal = () => {
    setRequestedDatesList([]);
    setIsAppointmentModal(false);
    closeModal();
    bookDirectAppointment(false);
  };

  const loadType = useMemo(() => {
    if (!load) return null;
    return load?.type_of_load;
  }, [load]);

  const appointmentLastDate = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP) return load?.lastFreeDay;
    if (moveType == MOVE_TYPES.EMPTY) return load?.cutOff;
    return null;
  }, [load]);

  // EXAMPLE selected slot
  // {
  //   category: "DATETIME_RANGE_SLOT",
  //   dateTimeSlots: [
  //     {
  //       startDate: "2023-06-11T22:00:00.000Z",
  //       endDate: "2023-06-11T23:00:00.000Z",
  //     },
  //     {
  //       startDate: "2023-06-11T21:00:00.000Z",
  //       endDate: "2023-06-11T22:00:00.000Z",
  //     },
  //   ],
  //   timeSlots: [],
  // };
  const [selectedSlot, setSelectedSlot] = useState({});

  // CREATE, EDIT
  const formMode = useMemo(() => {
    if (appointmentId) return FORM_MODE.EDIT;
    return FORM_MODE.CREATE;
  }, [appointmentId]);

  const countAppointmentsSlot = async () => {
    try {
      setIsLoading(true);
      const startDate = moment.tz(currentUserTimeZone).toISOString();
      const endDate = moment.tz(appointmentLastDate, currentUserTimeZone).toISOString();

      const slotsCountArgs = {
        moveType,
      };

      if (appointmentLastDate) {
        slotsCountArgs.startDate = startDate;
        slotsCountArgs.endDate = endDate;
      }

      const slotsCountResp = await appointmentSystemServices
        .countAppointmentsSlot(slotsCountArgs)
        .then((resp) => resp?.data?.data);
      setSlotsCount(slotsCountResp);
    } catch (e) {
      console.log("Error in Appointment Slots Count:", e);
    } finally {
      setIsLoading(false);
    }
  };

  const getAppointmentSystem = async ({ appointmentId }) => {
    try {
      setIsLoading(true);
      const appointmentSystem = await appointmentSystemServices
        .getAppointmentSystem({ appointmentId })
        .then((resp) => resp?.data?.data);

      if (appointmentSystem?.requestedAppointmentDateTimeSlots?.length > 0) {
        const { requestedAppointmentDateTimeSlots } = appointmentSystem;
        const transformedSlots = requestedAppointmentDateTimeSlots.map((eachSlot) => {
          return {
            startDate: eachSlot?.appointmentDateTimeStart,
            endDate: eachSlot?.appointmentDateTimeEnd,
          };
        });
        setDefaultDateTimeSlots(transformedSlots);
      }

      if (appointmentSystem?.requestedAppointmentTimeSlots?.length > 0) {
        const { requestedAppointmentTimeSlots } = appointmentSystem;
        let startTime = convertMilitaryToTime(requestedAppointmentTimeSlots[0]?.appointmentTimeStart);
        let endTime = convertMilitaryToTime(requestedAppointmentTimeSlots[0]?.appointmentTimeEnd);
        setDefaultTimeSlots([{ startTime, endTime }]);
      }
    } catch (e) {
      console.log("error fetching appointment: ", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!moveType) return;
    countAppointmentsSlot();
  }, []);

  useEffect(() => {
    if (isScheduleAppointment && recommendedAppointmentDate) {
      // For DateTime Slots
      const transformedSlots = [
        {
          startDate: recommendedAppointmentDate?.appointmentDateTimeStart,
          endDate: recommendedAppointmentDate?.appointmentDateTimeEnd,
        }
      ];
      setDefaultDateTimeSlots(transformedSlots);
    }
    if (formMode == FORM_MODE.CREATE) return;
    getAppointmentSystem({ appointmentId });
  }, []);


  const onSubmitAppointment = async () => {
    // If No Slots are selected
    if (
      selectedSlot &&
      selectedSlot.dateTimeSlots &&
      selectedSlot.dateTimeSlots.length === 0 &&
      selectedSlot.timeSlots &&
      selectedSlot.timeSlots.length === 0
    ) {
      toastr.show("Please Select one of the option to book an appointment!", "error");
      return;
    }

    // Check if load is an array or an object- bulk or single appointment?
    const loads = Array.isArray(load) ? load : [load];

    // For Bulk Appointment
    const promises = loads.map(async (loadItem) => {

      // For Export Containers to be synced
      if (loadItem.type_of_load === LOAD_TYPES.EXPORT && (!loadItem.bookingNo || !loadItem.emptyOriginName)) return toastr.show(`Please add ${!loadItem.bookingNo ? "bookingNo" : "return terminal"} to sync export container.`, "warning");

      const orderNo = getOrderNo(loadItem.type_of_load, moveType);
      const bookingPayload = {
        loadId: loadItem?._id,
        containerNo: loadItem?.containerNo,
        loadType: loadItem?.type_of_load,
        moveType,
        portCode: moveType == "PICKUP" ? (loadItem?.shipper?.[0]?.company_name ?? loadItem?.shipperName) : (loadItem?.emptyOrigin?.company_name ?? loadItem?.emptyOriginName),
        lineScac: loadItem?.containerOwnerName ?? loadItem?.containerOwner?.company_name,
        sealNo: loadItem?.sealNo,
        source: APPOINTMENT_SOURCES.GENERAL_APPOINTMENT,
      };

      if (loadItem[orderNo]) {
        bookingPayload.orderNo = loadItem[orderNo];
      }

      if (loadItem.type_of_load === LOAD_TYPES.EXPORT && moveType === MOVE_TYPES.EMPTY && !bookingPayload.orderNo)
        return toastr.show(`Please Enter ${ORDER_NO[orderNo]} to book Export Appointment.`, "warning");

      if (loadItem.containerSizeName && loadItem.containerTypeName) {
        bookingPayload.sizeType = `${loadItem.containerSizeName}${loadItem.containerTypeName}`;
      }

      if (selectedSlot?.category === APPOINTMENT_TIME_CATEGORY?.TIME_RANGE_SLOT) {
        const { timeSlots } = selectedSlot;
        bookingPayload.requestedAppointmentTimeSlots = timeSlots?.map((eachSlot) => {
          return {
            appointmentTimeStart: eachSlot.startTime,
            appointmentTimeEnd: eachSlot.endTime,
          };
        });
      }

      if (selectedSlot?.category === APPOINTMENT_TIME_CATEGORY?.DATETIME_RANGE_SLOT) {
        const { dateTimeSlots } = selectedSlot;
        bookingPayload.requestedAppointmentDateTimeSlots = dateTimeSlots?.map((eachSlot) => {
          return {
            appointmentDateTimeStart: eachSlot.startDate,
            appointmentDateTimeEnd: eachSlot.endDate,
          };
        });
      }

      // bookingPayload.requestedAppointmentDate = ;
      // bookingPayload.requestedAppointmentTimeSlots = requestedAppointmentTimeSlots;
      // console.log(
      //   "Appointment Booking Payload:",
      //   JSON.stringify(bookingPayload, null, 2)
      // );

      const appointmentSystemId =
        moveType === MOVE_TYPES.PICKUP
          ? loadItem.pickupAppointmentSystem
          : moveType === MOVE_TYPES.EMPTY
            ? loadItem.emptyAppointmentSystem
            : appointmentId;

      const port = moveType == "PICKUP" ? (loadItem?.shipper?.[0]?.company_name ?? loadItem?.shipperName) : (loadItem?.emptyOrigin?.company_name ?? loadItem?.emptyOriginName);
      const isAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, standardPorts);

      if (isAllowedForDirectBooking) {
        delete bookingPayload.requestedAppointmentTimeSlots;
        delete bookingPayload.requestedAppointmentDateTimeSlots;
      }

      try {
        let appointmentRes;
        if (!appointmentSystemId) {
          appointmentRes = await createAppointmentSystem(bookingPayload).then((res) => res.data.data);
        } else {
          appointmentRes = await updateAppointmentSystem(bookingPayload, appointmentSystemId).then(
            (res) => res.data.data
          );
        }

        // console.log(appointmentRes, "appointment Response in FE");
        return appointmentRes;
      } catch (e) {
        // console.log("Error in Bulk Appointment Response:", e);
        if (loadItem.containerNo) {
          toastr.show(`${loadItem.containerNo}: ${e?.data?.message}`, "error", null, 3000);
        } else {
          toastr.show(`${e?.data?.message}`, "error", null, 3000);
        }
      }
    });

    try {
      setIsLoading(true);
      const results = await Promise.all(promises);
      updateSelectedRows();
      !hasOnlyUndefined(results) && setRequestedDatesList(results);
      return results;
    } catch (error) {
      setRequestedDatesList([]);
    } finally {
      setIsLoading(false);
      bookDirectAppointment(false);
      // setIsAppointmentModal(false);
    }
  };

  return {
    isAppointmentModal,
    slotsCount,
    isLoading,
    defaultTimeSots,
    defaultDateTimeSots,
    onSubmitAppointment,
    selectedSlot,
    setSelectedSlot,
    formMode,
    loadType,
    requestedDatesList,
    isConfirmationModal,
    onCloseConfirmationModal,
    appointmentLastDate,
  };
};

export default useAppointmentModal;
