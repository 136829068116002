import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateTimeFormatUtils, differnceFinder, getDriverAuditMessage } from "../../../../services";
import { getDriverAudits } from "../actionCreators";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import _, { isEmpty } from "lodash";
import { getTimeZone } from "../../NewDispatcher/constants";
import { isAtBottom } from "../../NewDispatcher/DispatcherTable/Functions";
import { useSelector } from "react-redux";
import { Object } from "sugar";
import { DRIVER_PREFERRED_MOVE_TYPES } from "../constant";
import { IconDocument } from "Components/Common/Icons";

export default function Audit(props) {
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMore, setIsMore] = useState(true);

  const { terminals, currencyType } = useSelector((state) => state.HomeReducer);

  const currencyNameFromId = (_id) => {
    return currencyType.find((x) => x?._id + "" === _id)?.name || "";
  };

  const terminalNamesFromIds = (_terminals) => {
    return terminals.filter((x) => _terminals.includes(x._id + ""))?.map((x) => x?.name);
  };

  const getAuditDescWithWorkingDays = (description, oldDays, newDays) => {
    const getTime12 = (time24) => moment(time24, "HH:mm").format("hh:mm A");

    // Function to map days by their names
    const mapDaysByName = (days) =>
      days.reduce((acc, day) => {
        acc[day.day] = day;
        return acc;
      }, {});

    oldDays = oldDays?.map(({ _id, ...rest }) => ({ ...rest }));
    newDays = newDays?.map(({ _id, ...rest }) => ({ ...rest }));

    const oldDaysMap = mapDaysByName(oldDays);
    const newDaysMap = mapDaysByName(newDays);

    const formatDayDesc = (dayData) => (
      <b>
        {dayData?.is_active ? "Active" : "Inactive"} | {dayData?.day} {getTime12(dayData?.in_time)} -{" "}
        {getTime12(dayData?.out_time)}
      </b>
    );

    // Handle days that exist in newDaysMap
    const daysDesc = Object.keys(newDaysMap)
      .map((day) => {
        const oldDay = oldDaysMap[day];
        const newDay = newDaysMap[day];

        if (!oldDay && newDay) {
          return <>Added {formatDayDesc(newDay)}</>;
        }
        if (oldDay && newDay && !isEmpty(differnceFinder(oldDay, newDay))) {
          return (
            <>
              {formatDayDesc(oldDay)} to {formatDayDesc(newDay)}
            </>
          );
        }

        return null;
      })
      .filter(Boolean);

    // Handle days that exist in oldDaysMap but not in newDaysMap (Removed days)
    const removedDaysDesc = Object.keys(oldDaysMap)
      .map((day) => {
        if (!newDaysMap[day]) {
          const oldDay = oldDaysMap[day];
          return <>Removed {formatDayDesc(oldDay)}</>;
        }
        return null;
      })
      .filter(Boolean);

    const allDesc = [...daysDesc, ...removedDaysDesc];

    if (allDesc.length) {
      const daysTitle = (
        <>
          <br />
          <p>Working days hours changed</p>
        </>
      );
      description = [
        ...description,
        daysTitle,
        ...allDesc.flatMap((desc, i) => [desc, i < allDesc.length - 1 && <br />]),
      ];
    }
    return description;
  };

  const getAudits = () => {
    setIsLoading(true);
    const p = {
      driverId: props.driverId,
      limit: 20,
      skip: audits.length || 0,
      isGenerateSignUrl: true,
    };
    getDriverAudits(p)()
      .then((result) => {
        setIsLoading(false);
        setAudits((e) => [...e, ...result]);
        setIsMore(result.length);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const loadMoreData = (e) => {
    if (isAtBottom(e) || isLoading || !isMore) return;
    getAudits();
  };
  const handleDefaultScroll = () => {
    const dataTable = document.getElementsByClassName(`driver-audit-table-8fj8f`);
    if (dataTable && dataTable[0]) {
      loadMoreData({ currentTarget: dataTable[0] });
    }
  };

  useEffect(() => {
    if (audits?.length > 0) handleDefaultScroll();
  }, [audits]);

  useEffect(() => {
    if (isMore && !isLoading) {
      getAudits();
    }
  }, []);

  return (
    <div className="tab" id="tab-chassis">
      <div className="tab" id="tab-document">
        <div
          className="overflow-auto driver-audit-table-8fj8f"
          style={{ height: "calc(100vh - 258px)" }}
          onScroll={loadMoreData}
        >
          {isLoading && <LoaderBar />}
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th>User</th>
                <th>Type</th>
                <th>Time</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {audits.length === 0 && (
                <tr>
                  <td colSpan="100%" className="text-center">
                    No Result Found
                  </td>
                </tr>
              )}
              {audits &&
                audits.map((d, key) => {
                  let description;
                  let url;
                  let driverName;
                  if (["DOCUMENT_UPLOAD", "DOCUMENT_REMOVE"].includes(d.type)) {
                    description = <p><b>{d.data.document.type}</b> {(d.type === "DOCUMENT_REMOVE")? " removed " : " added "}</p>;
                    url = <a href={d.data.document.url} target='_blank'><IconDocument /> Open Document</a>;
                  }
                  if (d.type === "ADD") {
                    description = "Driver Name ";
                    driverName = <b>{d.data.name}</b>;
                  }

                  if (d.type === "UPDATE") {
                    let data;
                    if (key !== audits.length - 1 && audits[key + 1].userId) {
                      if (audits[key + 1].type === "DOCUMENT_UPLOAD" || audits[key + 1].type === "DOCUMENT_REMOVE") {
                        data = audits[key + 2] && audits[key + 2].data;
                      } else {
                        data = audits[key + 1] && audits[key + 1].data;
                      }
                    }
                    const additionalInfo = d.data;

                    let differanceDescription = differnceFinder(data, additionalInfo);
                    description = Object.keys(differanceDescription || [])
                      .map((key1) => {
                        if (key1 === "invoiceCurrencyWithCarrier") {
                          return (
                            <p>
                              Default Currency changed to <b>{currencyNameFromId(differanceDescription[key1])}</b>
                            </p>
                          );
                        }
                        if (key1 === "currency") {
                          return (
                            <p>
                              Currency changed to <b>{currencyNameFromId(differanceDescription[key1])}</b>
                            </p>
                          );
                        }
                        if (key1 === "accountHold") {
                          if (differanceDescription[key1]) {
                            return <p>Driver is put on hold.</p>;
                          } else {
                            return <p>Driver is no longer on hold.</p>;
                          }
                        }
                        if (key1 === "preferredTypesOfLoad") {
                          if (_.xor(data?.preferredTypesOfLoad, additionalInfo?.preferredTypesOfLoad).length && additionalInfo?.preferredTypesOfLoad?.length !== 0) {
                            return <p>Preferred types of Load changed to <b>{d?.data?.preferredTypesOfLoad?.toString()}</b></p>
                          }else if(additionalInfo?.preferredTypesOfLoad?.length===0 && data?.preferredTypesOfLoad?.length!==0){
                            return <p>Preferred types of Load removed</p>
                          }
                        }
                        if (key1 === "preferredMoveTypes") {
                          if (_.xor(data?.preferredMoveTypes, additionalInfo?.preferredMoveTypes).length && additionalInfo?.preferredMoveTypes?.length !== 0) {
                            return <p>Preferred Event Types changed to <b>{d?.data?.preferredMoveTypes?.map(type=> DRIVER_PREFERRED_MOVE_TYPES.find(item => item.value === type)?.label).join(', ')}</b></p>
                          }else if(additionalInfo?.preferredMoveTypes?.length===0 && data?.preferredMoveTypes?.length!==0){
                            return <p>Preferred Event Types  removed</p>
                          }
                        }
                        if (key1 === "tags") {
                          if (_.xor(data?.tags, additionalInfo?.tags).length && additionalInfo?.tags?.length !== 0) {
                            return <p>Driver Tags changed to <b>{d?.data?.tags?.toString()}</b></p>
                          }else if(additionalInfo?.tags?.length===0 && data?.tags?.length!==0){
                            return <p>Driver Tags removed</p>
                          }
                        }
                        if(key1 === "preferredDistance"){
                          if (_.xorWith(data?.preferredDistance, additionalInfo?.preferredDistance, _.isEqual).length && additionalInfo?.preferredDistance?.length !== 0) {
                            return <p>Preferred Distance changed to <b>{d?.data?.preferredDistance.map(range => `${range.min}${range.max ? '-' : ''}${range.max ?? '+'} ml`).join(', ')}</b></p>
                          }else if(additionalInfo?.preferredDistance?.length===0 && data?.preferredDistance?.length!==0){
                            return <p>Preferred Distance removed</p>
                          }
                        }
                        if(key1 === "reefer"){
                          if(differanceDescription[key1]==="true"){
                            return <p>Reefer <b>Enabled</b></p>
                          }else{
                            return <p>Reefer <b>Disabled</b></p>
                          }
                        }
                        if(typeof differanceDescription[key1]==="object") {
                          return null; 
                        }
                        return getDriverAuditMessage(key1, differanceDescription);
                      }).filter(key => key !== null);
                    if(additionalInfo?.preferredChassisTypes){
                      if(additionalInfo?.preferredChassisTypes?.length>0){
                        description.push(
                          <p>Preferred Chassis Types changed to <b>{d?.data?.preferredChassisTypes?.map((chassisType) => chassisType.name).join(', ')}</b></p>
                        )
                      }else {
                        description.push(
                          <p>Preferred Chassis Types removed</p>
                        )
                      }
                    }
                    if(additionalInfo?.restrictedLocations){
                      if(additionalInfo?.restrictedLocations?.length>0){
                        description.push(
                          <p>Restricted Locations changed to <b>{d?.data?.restrictedLocations?.map((location) => location.company_name).join(', ')}</b></p>
                        )
                      }else{
                        description.push(
                          <p>Restricted Locations removed</p>
                        )
                      }
                    }
                    if(additionalInfo?.preferredStates){
                      if(additionalInfo?.preferredStates?.length>0){
                        description.push(
                          <p>Preferred States changed to <b>{d?.data?.preferredStates?.map((state) => props.updateLabel ? props.updateLabel(state) : state?.split(', ')[0]).join(', ')}</b></p>
                        )
                      }else{
                        description.push(
                          <p>Preferred States removed</p>
                        )
                      }
                    }
                    if(additionalInfo?.preferredCountry){
                    if (additionalInfo?.preferredCountry?.length > 0) {
                      description.push(
                        <p>Preferred Country changed to <b>{d?.data?.preferredCountry?.map((state) => props.updateLabel ? props.updateLabel(state) : state?.split(', ')[0]).join(', ')}</b></p>
                      )
                    } else {
                      description.push(
                        <p>Preferred Country removed</p>
                      )
                    }
                  }
                    if(additionalInfo?.owerWeightStates){
                      if(additionalInfo?.owerWeightStates?.length>0){
                        description.push(
                          <p>Over Weight States changed to <b>{d?.data?.owerWeightStates?.map((state) => state?.split(', ')[0]).join(', ')}</b></p>
                        )
                      }else{
                        description.push(
                          <p>Over Weight States removed</p>
                        )
                      }
                    }
                    if(additionalInfo?.overWeightCountry){
                    if (additionalInfo?.overWeightCountry?.length > 0) {
                      description.push(
                        <p>Over Weight Country changed to <b>{d?.data?.overWeightCountry?.map((state) => state?.split(', ')[0]).join(', ')}</b></p>
                      )
                    } else {
                      description.push(
                        <p>Over Weight Country removed</p>
                      )
                    }
                  }
                    if(additionalInfo?.profileType){
                      if(additionalInfo?.profileType?.length>0){
                        description.push(
                          <p>Profile Type changed to <b>{d?.data?.profileType?.map((type) => type).join(', ')}</b></p>
                        )
                      }else{
                        description.push(
                          <p>Profile Type removed</p>
                        )
                      }
                    }
                    if(additionalInfo?.newTerminal){
                      if(additionalInfo?.newTerminal?.length>0){
                        description.push(
                          <p>Branches changed to <b>{terminalNamesFromIds(d?.data?.newTerminal)?.toString()}</b></p>
                        )
                      }else{
                        description.push(
                          <p>Branches removed</p>
                        )
                      }
                    }
                    const oldDays = data?.workingDaysHours || [];
                    const newDays = additionalInfo?.workingDaysHours || [];
                    description = getAuditDescWithWorkingDays(description, oldDays, newDays);
                  }

                  if (
                    [
                      "DL_EXPIRY_UPDATED",
                      "MEDICAL_EXPIRY_UPDATED",
                      "TWIC_EXPIRY_UPDATED",
                      "SEALINK_EXPIRY_UPDATED",
                      "HOLD_UPDATED",
                      "UNHOLD_UPDATED",
                    ].includes(d.type)
                  ) {
                    let data;
                    const additionalInfo = d.data;

                    let dateFormat = [];
                    if (d.type === "DL_EXPIRY_UPDATED") dateFormat.push("dlExp");
                    if (d.type === "MEDICAL_EXPIRY_UPDATED") dateFormat.push("medicalExp");
                    if (d.type === "TWIC_EXPIRY_UPDATED") dateFormat.push("twicExp");
                    if (d.type === "HOLD_UPDATED") dateFormat.push("driverHoldStartDt");
                    if (d.type === "UNHOLD_UPDATED") dateFormat.push("driverHoldEndDt");
                    if(d.type === "SEALINK_EXPIRY_UPDATED") dateFormat.push("seaLinkExp");
                    let differanceDescription = differnceFinder(data, additionalInfo);
                    description = Object.keys(differanceDescription || [])
                      .filter((key1) => typeof differanceDescription[key1] !== "object")
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          return getDriverAuditMessage(key1, differanceDescription);
                        }
                      });
                  }
                  if (["HOLD_CRON", "UNHOLD_CRON"].includes(d.type)) {
                    let data;
                    const additionalInfo = d.data;
                    let dateFormat = [];
                    if (d.type === "HOLD_CRON") dateFormat.push("driverHoldStartDt");
                    if (d.type === "UNHOLD_CRON") dateFormat.push("driverHoldEndDt");
                    let differanceDescription = differnceFinder(data, additionalInfo);
                    description = Object.keys(differanceDescription || [])
                      .filter((key1) => typeof differanceDescription[key1] !== null)
                      .map((key1) => {
                        if (dateFormat.includes(key1)) {
                          if (!differanceDescription[key1]) {
                            return getDriverAuditMessage(key1, differanceDescription);
                          }
                        }
                      });
                  }

                  if (d.type === "ELD_UPDATE") {
                    const eldDetails = d.data?.eldDetails;
                    if (d.data?.eldIdentifierCreated) {
                      description = `Driver connected with Eld Profile ${eldDetails?.name}.`;
                    }

                    if (d.data?.eldIdentifierDeleted) {
                      description = `Driver disconnected from Eld Profile ${eldDetails?.name}.`;
                      if (eldDetails?.tspDisconnect) {
                        description = `${eldDetails?.tspSource?.toUpperCase()} connection was removed.`;
                      }
                    }

                    if (d.data?.eldIdentifierChanged) {
                      description = `Driver connected with Eld Profile ${eldDetails?.name}`;
                    }
                  }

                  if (d?.type === "DRIVER_CLOCK_IN_OUT") {
                    if (d?.data?.workingStatus === "AVAILABLE") {
                      description = (
                        <p>
                          Driver clocked <b>in</b>
                        </p>
                      );
                    } else if (d?.data?.workingStatus === "OFF_DUTY") {
                      description = (
                        <p>
                          Driver clocked <b>out</b>
                        </p>
                      );
                    }
                  }

                  if (!description || (Array.isArray(description) && description?.length === 0)) return null;

                  return (
                    <tr key={d._id} id={d._id}>
                      <td>
                        {d.userId && (
                          <div className="d-flex align-items-center">
                            <span className="avatar-circle avatar-xs mr-1">
                              {d.userId && d.userId.name
                                ? `${d.userId.name.charAt(0)}${d.userId?.lastName ? d.userId?.lastName.charAt(0) : ""}`
                                : d.type && (d.type == "HOLD_CRON" || d.type == "UNHOLD_CRON")
                                ? "S"
                                : ""}
                            </span>
                            {d.userId && d.userId.name
                              ? `${d.userId.name}${d.userId?.lastName ? ` ${d.userId?.lastName}` : ""}${
                                  d?.adminId ? ` (via PortPro Admin User)` : ""
                                }`
                              : d.type && (d.type == "HOLD_CRON" || d.type == "UNHOLD_CRON")
                              ? "SYSTEM"
                              : ""}
                          </div>
                        )}
                        {!d.userId && d.type === "ELD_UPDATE" && (
                          <div className="d-flex align-items-center">
                            <span class="avatar-circle avatar-xs mr-1">S</span>
                            SYSTEM
                          </div>
                        )}
                      </td>
                      <td>
                        <span className="badge badge-gray-100">{d.type.replace(/_/g, " ")}</span>
                      </td>
                      <td>
                        <div>
                          {moment(d.createdAt)
                            .tz(getTimeZone({ preferred: true }))
                            .format(DateTimeFormatUtils.fullDateFormat())}
                        </div>
                        <div className="text-muted">
                          {moment(d.createdAt)
                            .tz(getTimeZone({ preferred: true }))
                            .format(DateTimeFormatUtils.timeFormat())}
                        </div>
                      </td>
                      <td>
                        {description} {driverName} {url}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
