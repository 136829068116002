import { legTypeToHuman } from "Components/Common/functions";
import React,{useState} from "react";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';

const NextAddress = ({row})=>{

  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const [open, setOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' });


    let nextAddress = row.nextDriverOrderId;
    if (row.status === 'DISPATCHED') {
      nextAddress = row.driverOrder[0];
    }
    return (
        <div className="cell-content">
            <span className="badge badge-sm badge-light text-capitalize">
                {nextAddress ? legTypeToHuman(nextAddress.type) : ""}
            </span>
            <div>
                {nextAddress && (
                    <>
                        <div className="tooltip-label position-relative rdg-truncate"
                       >
                            <span 
                                ref={setReferenceElement}
                                onMouseOver={() => setOpen(true)}
                                onMouseOut={() => setOpen(false)}
                                className=""
                            >
                                {nextAddress.company_name}
                            </span>
                            {open && ReactDOM.createPortal(
                                <div
                                    className="tooltip bs-tooltip-top tooltip--color text-capitalize portal-tooltip load-nextDriverOrderId"
                                    ref={setPopperElement}
                                    style={styles.popper}
                                    {...attributes.popper}
                                >
                                    <div className="arrow"></div>
                                    {nextAddress.company_name}
                                </div>,
                                document.querySelector('#poper-dest')
                             )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
export default NextAddress;