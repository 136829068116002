import { ADMIN_CHARTS, CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS, CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS_OBJ, REPORT_TYPES } from '../../constants';
import { fixedNumer } from '../../helper';

const CustomBarChartToolTip = ({ active, payload, tooltipPostFix, reportType, isToFixedNeeded }) => {

    if (active && payload && payload.length) {
        const { count, company_name } = payload[0].payload;
        let keys = []
        let keysObj = {}

        if (ADMIN_CHARTS.includes(reportType)) {
            keys = ["Import", "Export", "Road", "Bill"]
            keysObj = {
                "Import": "IMPORT",
                "Export": "EXPORT",
                "Road": "ROAD",
                "Bill": "BILL_ONLY",
            }
        }

        if (reportType === REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR) {
            keys = CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS
            keysObj = CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS_OBJ
        }

        return (
            <div
                className="p-2 custom-tooltip dlist__card mb-1 shadow-5"
            >
                <div className='d-flex justify-content-between '>
                    <div className='font-medium mr-2'>{company_name}</div>
                    <span className='bg-gray-500 text-white badge badge-sm badge-sm '>
                        {tooltipPostFix === '$' ? '$' : ''}
                        {`${isToFixedNeeded ? fixedNumer(count || 0) : (count || 0)}`}
                        {tooltipPostFix !== '$' ? ` ${tooltipPostFix || 'Loads'}` : ''}
                    </span>
                </div>
                {
                    keys?.length > 0 && <>
                        <div className='hr-light my-10' />
                        <div className='d-flex flex-column gap-5'>
                            {
                                keys.map((e) => {
                                    if(!payload[0].payload[keysObj?.[e]]) return
                                    return (
                                        <div className='d-flex justify-content-between'>
                                            <div className='text-gray-500'>{e} :</div>
                                            <div>{payload[0].payload[keysObj?.[e]]}</div>
                                        </div>
                                    )
                                })
                            }
                            <div className='text-primary pointer mt-1 text-capitalize'>
                                Click to view more details
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

    return null;
};

export default CustomBarChartToolTip;
