import { useCurrentUser } from "hooks";
import React, { useEffect, useState } from "react";
import { arrivedDateTimeForTracking, driverGeofenceIcon, trackingDriverOrdersRoutes, getRoutesFromDriverOrders } from "../../../utility";
import { getStatusClass } from "../../../utility";
import EventCard from "../../../../Tracking/elements/EventCard";
import { useTrackingLoadDispatch } from "../../../context/TrackingContext";
import { LOAD_ACTIONS } from "../../../store/actions";
import { getDistanceBetweenEvents, getDurationInHHMMFormat } from "pages/tms/LoadRouting/utilities";
import { getStorage } from "services";
import { isUserSpecificTimeZoneEnabled } from "../../../../../../services";

export default function EachDriverOrder({
  driverOrder,
  driverOrders,
  index,
  selectedDriverOrder,
  setSelectedDriverOrder,
  onClick = () => {},
  load,
  driverHistoryList = {},
  legIndex,
  startMove,
  readonly
}) {
  let showLoad = true;
  const { currentCarrier } = useCurrentUser();
  const loadDispatch = useTrackingLoadDispatch();

  const statusClassData = getStatusClass(driverOrder, load);
  const statusClass = statusClassData && statusClassData[2];
  const statusIcon = statusClassData && statusClassData[1];

  if (!showLoad) {
    return;
  }
  
  const { validNextLegIndex } = driverOrder || {};
  const isDriverOrderSelected = selectedDriverOrder?.id && driverOrder?._id && selectedDriverOrder.id === driverOrder._id;
  const distanceMeasure = currentCarrier?.carrier?.distanceMeasure || "mi";
  const distanceBwEvents = getDistanceBetweenEvents(driverOrders, index, validNextLegIndex);

  const timeZoneEnable = isUserSpecificTimeZoneEnabled();
  const timeZone = getStorage("timeZone");
  const nextValidEvent = driverOrders[validNextLegIndex]
  let nextEventDuration = getDurationInHHMMFormat(
    nextValidEvent?.arrived, 
    driverOrders[index]?.departed, 
    (timeZoneEnable && {fromTimeZone : driverOrders[index]?.timeZone || timeZone, toTimeZone: nextValidEvent?.timeZone || timeZone}))

  const isLastIndex = driverOrders.length - 1 === index;  
  const cardRouteLineClass = `position-relative route-line ${!isLastIndex? "route-line--bottom" : ""} ${index === 0 ? "" : "route-line--top"} route-line-tracking-card`;
  return (
    <>
      <div
        className={`
          d-flex gap-5 outline-1 rounded-5 px-10 pt-10 pb_5 hover-bg-primary-50 pointer 
          ${statusClass} 
          ${isDriverOrderSelected ? "bg-brand-50 outline-primary shadow-outline-primary-3px": "outline-gray-100"}
          ${cardRouteLineClass}
        `}
        onClick={(e) => {
          if(readonly) return
          e.stopPropagation();
          setSelectedDriverOrder(null);
          loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
        }}
      >
        {/* <div className="dlist__timeframe showline pt-10">
          <div className="dlist__timeframe--icon">{statusIcon}</div>
        </div> */}
        <div className="w-100 d-flex flex-column gap-5">

        <EventCard 
            driverOrder={driverOrder} 
            driverOrders={driverOrders} 
            orderIndex={index}
            isDriverOrderSelected={isDriverOrderSelected}
            setSelectedDriverOrder={setSelectedDriverOrder}
            load={load}
            driverHistoryList={driverHistoryList}
            legIndex={legIndex}
            startMove={startMove}
            readonly={readonly}
          />
          
          
        </div>
      </div>
      {!driverOrder?.isEndingMove && !driverOrder?.isDateException && !driverOrder?.isVoidOut && (
        <div className="d-flex justify-content-between position-relative" style={{lineHeight: "12px", padding: "0 2px"}}>
          <span className="font-10 line-height-12 font-weight-normal">
            {  
              ((distanceBwEvents ?? 0) === distanceBwEvents)
                ? `${distanceBwEvents}${distanceMeasure}` 
              : "-"
            }
          </span>
          <span className="font-10 line-height-12">
            {
              nextValidEvent?.arrived
                ?
                nextEventDuration
                : "-"
            }
          </span>
        </div>
      )}
    </>
  );
}
