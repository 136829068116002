import React, { Fragment, useState, useMemo } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from "assets/js/select-style";
import {
  IconWarning,
  IconFire,
  IconWeightTool,
  IconLiquor,
  IconSnowFlake,
  IconHighway,
  IconFlash,
  IconMapMarker,
  IconFile
} from "Components/Common/Icons";
import { useSelector } from "react-redux";
import {
  amplitudeTrack,
  checkUserPermisison,
  getStorage,
  isNewEndorsement,
  isTerminalTrackingEnabled,
  mapTerminalTrackingOptionLabel,
  updateTerminology
} from "../../../../../services";
import { LOAD_TYPES } from "pages/tms/carrier/AccountReceivable/constants";
import { getCustomerAsyncSearch, getCustomerAsyncSearchWithOutAddress } from "pages/tms/Dispatcher/utility";
import { isTerminal } from "pages/tms/NewDispatcher/utility";
import { EMAIL_PROVIDER } from "pages/tms/Email/constant";
import { getDynamicRanges, convertRawDateToISOString, getTimeZone } from "pages/tms/NewDispatcher/constants";
import CustomDateTimeRangePicker from "Components/CustomDateTimeRangePicker";
import { getPeopleAsyncSearch } from "Components/DynamicPricing/utils";
import _ from "lodash";
import { EMAIL_FILTERS_KEY, SLA_FILTER, fieldsWithExcludeProperties, readUnreadStatuses } from "./utils";
import { useCommonSelectOptions } from '../../hooks/useSelectorOptionsData'
import CheckboxAsyncSelect from "Components/Common/CustomSelect/CheckboxAsyncSelect"
import moment from "moment";
import { isCombineTripEnabled } from "pages/tms/Dispatcher/NewDriverItinerary/constants";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../constant";
import { StatusFilter } from "../EmailStatus";
import { statusLabels } from "../../constant";
import BadgeCheckBoxDropdown from "../BadgeCheckBoxDropdown";
import { IconInfo, IconPlug, IconPlusCircle, IconRail, IconTrainTrack, IconWaste } from "../../../../../Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { getPreSetOrderStatus } from "pages/tms/Load/DriverOrderUtility";
import { getCustomerAsyncSearchWithStatus } from "../../../Load/DriverOrderUtility";

const CustomFormItem = ({ label, children }) => (
  <div className="form-group form-row align-items-center">
    <label className="col-md-5 col-form-label">{label}</label>
    <div className="col-md-7">{children}</div>
  </div>
);

const TabFilters = (props) => {
  const { emailFilters, allCSR, labelsOption, isFromLoad, selectLabelForCheckBoxes, peopleData , handleApplyFilter, handleResetLocalState, getTags, isTagsLoading, handleSearchTerm, allTags, isLoading = false} = props;

  let { allPublicUsers, shippers, consignees, customers, drivers, callers } = useSelector((state) => state.TmsReducer);
  let { containerType, containerSize, containerOwner } = useSelector((state) => state.containerReducer);
  let { terminals } = useSelector((state) => state.HomeReducer);
  const clonedData = _.cloneDeep(emailFilters)
  const [localFilter, setLocalFilter] = useState(clonedData)
  const {
    shippersOptions,
    consigneesOptions,
    customersOptions,
    terminalsOptions,
    containerTypeOptions,
    containerSizeOptions,
    containerOwnerOptions,
    callersOptions,
    peopleDataOptions
  } = useCommonSelectOptions({
    shippers,
    consignees,
    customers,
    terminals,
    containerType,
    containerSize,
    containerOwner,
    callers,
    peopleData
  })

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
  const { provider } = embeddedEmailAccount ?? {};
  const RANGES = getDynamicRanges();
  const removeExcludes = (key) => {
    const handleExcludes = (key1,key2) => {
      setLocalFilter((prevStates) => {
        const updatedStates = { ...prevStates, [key1]: [], [key2]: null };
        return updatedStates;
      });
    }
    switch (key) {
      case "organizations":
        handleExcludes("organizations","isExcludeOrganizations")
        break;
      case "people":
        handleExcludes("people","isExcludePeople")
        break;
      case "myTeam":
        handleExcludes("myTeam","isExcludeMyTeam")
        break;
      case "branches":
        handleExcludes("branches","isExcludeBranches")
        break;
      default:
        break;
    }
  };


  const setLocalFilterKey = (key, data, makeApiCall) => {
    if (fieldsWithExcludeProperties.includes(key) && data?.length === 0) {
      removeExcludes(key,data)
      return
    } 
    setLocalFilter((prevEmailFilters) => {
      const updatedEmailFilters = { ...prevEmailFilters, [key]: data };
      return updatedEmailFilters;
    });
  };

  const setLocalCheckBoxes = (status) => {
    if (!Array.isArray(localFilter?.selectedStatus)) {
      setLocalFilter((prevEmailFilters) => {
        const updatedEmailFilters = { ...prevEmailFilters, selectedStatus: [status] };
        return updatedEmailFilters;
      });
    } else {
      let newStatus = _.cloneDeep(localFilter?.selectedStatus);
      if (localFilter?.selectedStatus?.includes(status)) {
        newStatus = localFilter?.selectedStatus?.filter(st => st !== status)
      } else {
        newStatus.push(status)
      }
      setLocalFilter((prevEmailFilters) => {
        const updatedEmailFilters = { ...prevEmailFilters, selectedStatus: newStatus };
        return updatedEmailFilters;
      });
    }
  }  

  const resetLocalState = () => {
    const clonedData = _.cloneDeep(EMAIL_FILTERS_KEY)
    setLocalFilter(clonedData);
  }

  //pass function to parent
  handleResetLocalState && handleResetLocalState(resetLocalState)
  
    const selectedEmailStatus = useMemo(() => {
      const data = localFilter.emailStatus?.map((status) => {
        if (typeof status === "string") {
          const { label, badgeClass } = statusLabels.find(item => item.value === status) || {};
          return { label, value: status, badgeClass };
        }
        return status;
      })
      return data || [];
    }, [localFilter]);
  return (
    <>
      <CustomFormItem label="Email Date">
        <CustomDateTimeRangePicker
          ranges={RANGES}
          userTimeZone ={getTimeZone({ preferred: true })}
          onApply={(e, picker) => {
            setLocalFilterKey("emailFromDate", picker.startDate);
            setLocalFilterKey("emailToDate", picker.endDate);
            let eventProperties = {
              source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
            }
            amplitudeTrack(AMPLITUDE_EVENTS.FILTER.DATE, eventProperties);
          }}
          onClear={(e) => {
            setLocalFilterKey("emailFromDate", null);
            setLocalFilterKey("emailToDate", null);
          }}
          fromDate={localFilter?.emailFromDate}
          toDate={localFilter?.emailToDate}
          isTimePicker={true}
        />
      </CustomFormItem>
      <div className="hr-light" />
      <CustomFormItem label="Status">
        <StatusFilter options={statusLabels} onSelect={(val) => setLocalFilterKey("emailStatus", val)} value={selectedEmailStatus} />
      </CustomFormItem>

      <>
        <div className="hr-light" />
        <CustomFormItem label="Tags">
          <BadgeCheckBoxDropdown
            options={allTags}
            value={localFilter?.tagIds || []}
            setFilterKey={setLocalFilterKey}
            emailFilters={localFilter}
            handleSearchTerm={handleSearchTerm}
            fetchMore={getTags}
            isTagsLoading={isTagsLoading}
            field="tagIds"
          />
        </CustomFormItem>
      </>

      <Fragment>
        <div className="hr-light" />
        <CustomFormItem label={"Read/Unread"}>
          <Select
            className="w-100"
            styles={smallSelectStyle}
            cacheOptions
            options={readUnreadStatuses}
            value={localFilter?.unread}
            onChange={(st) => {
              setLocalFilterKey("unread", st);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.READ_UNREAD, eventProperties);
            }}
          />
        </CustomFormItem>
      </Fragment>

      <div className="hr-light" />
      <div class={`form-check form-check--gray pr-30`}>
        <input
          class="form-check-input"
          type={"checkbox"}
          id={"hasAttachment"}
          checked={localFilter?.hasAttachment === true}
          onChange={() => {
            if (localFilter?.hasAttachment === true) {
              setLocalFilterKey("hasAttachment", null);
            } else {
              setLocalFilterKey("hasAttachment", true);
            }
            let eventProperties = {
              source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
            }
            amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_WITH_ATTACHMENT, eventProperties);
          }}
        />
        <label class="form-check-label" htmlFor={"hasAttachment"}>
          {"With Attachment"}
        </label>
      </div>
      <div className="hr-light" />

      <Fragment>
        <Fragment>
          <div className="font-medium mb-10">Organizations</div>
          
            <div className="d-flex align-items-center gap-10">
              <CheckboxAsyncSelect 
                className="w-80"
                styles={smallSelectStyle}
                cacheOptions
                isMulti
                isClearable={true}
                options={_.uniqBy([...(callersOptions || []),...(localFilter?.organizations || [])], 'value' )}
                value={localFilter?.organizations}
                onChange={(organizations) => {
                  setLocalFilterKey("organizations", organizations);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.ORG, eventProperties);
                }}
                loadOptions={getCustomerAsyncSearchWithOutAddress}
                isAsync={true}
              />
              {/* <AsyncSelect
                className="w-100"
                styles={smallSelectStyle}
                cacheOptions
                isMulti
                defaultOptions={callersOptions}
                value={localFilter?.organizations}
                onChange={(organizations) => {
                  setLocalFilterKey("organizations", organizations);
                }}
                loadOptions={getCustomerAsyncSearch}
              /> */}
              <div className={`form-check form-check--gray w-20`}>
                <input
                  className="form-check-input"
                  id={`org-from-exclude`}
                  type="checkbox"
                  disabled={localFilter?.organizations?.length ? false : true }
                  checked={!!localFilter?.isExcludeOrganizations}
                  onChange={(e) => setLocalFilterKey("isExcludeOrganizations", localFilter?.isExcludeOrganizations ? null : true)}
                />
                <label className="form-check-label" htmlFor={"org-from-exclude"}>
                  Exclude
                </label>
              </div>
            </div>

        </Fragment>
        <div className="hr-light" />
        <Fragment>
          <div className="font-medium mb-10">People</div>
          
            <div className="d-flex align-items-center gap-10">
              <CheckboxAsyncSelect
                className="w-80"
                styles={smallSelectStyle}
                cacheOptions
                isMulti
                options={_.uniqBy([...(peopleDataOptions || []),...(localFilter?.people || [])], 'value' )}
                value={localFilter?.people}
                onChange={(people) => {
                  setLocalFilterKey("people", people);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.PERSON, eventProperties);
                }}
                loadOptions={getPeopleAsyncSearch}
                isAsync={true}
              />
              <div className={`form-check form-check--gray w-20`}>
                <input 
                  className="form-check-input" 
                  id={`people-from-exclude`} 
                  type="checkbox" 
                  disabled={localFilter?.people?.length ? false : true }
                  checked={!!localFilter?.isExcludePeople}
                  onChange={(e) => setLocalFilterKey("isExcludePeople", localFilter?.isExcludePeople ? null : true)}
                  />
                <label className="form-check-label" htmlFor={"people-from-exclude"}>
                  Exclude
                </label>
              </div>
            </div>

        </Fragment>
        <div className="hr-light" />
        <Fragment>
          <div className="font-medium mb-10">My Team / Users</div>
          
            <div className="d-flex align-items-center gap-10">
              <CheckboxAsyncSelect
                className="w-80"
                styles={smallSelectStyle}
                cacheOptions
                isMulti
                options={_.uniqBy([...(allPublicUsers.filter((d, i) => {
                  if (d.isDeleted === false) {
                    return ["fleetmanager"].indexOf(d.role) > -1;
                  }
                })
                .map((d) => {
                  return {
                    value: d.fleetManager,
                    label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName}`.trim(),
                    allInfo: d,
                  };
                }) || []),...(localFilter?.myTeam || [])], 'value' )}
                value={localFilter?.myTeam}
                onChange={(val) => {
                  setLocalFilterKey("myTeam", val);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.TEAM_USERS, eventProperties);
                }}
                isAsync={false}
              />
              <div className={`form-check form-check--gray w-20`}>
                <input
                  className="form-check-input"
                  id={`users-from-exclude`}
                  type="checkbox"
                  disabled={localFilter?.myTeam?.length ? false : true }
                  checked={!!localFilter?.isExcludeMyTeam}
                  onChange={(e) => setLocalFilterKey("isExcludeMyTeam", localFilter?.isExcludeMyTeam ? null : true)}
                />
                <label className="form-check-label" htmlFor={"users-from-exclude"}>
                  Exclude
                </label>
              </div>
            </div>

        </Fragment>
        <div className="hr-light" />
          <Fragment>
            <div className="font-medium mb-10">Assigned To</div>
            
              <div className="d-flex align-items-center gap-10">
                <CheckboxAsyncSelect
                  className="w-80"
                  styles={smallSelectStyle}
                  cacheOptions
                  isMulti
                  options={_.uniqBy([...(allPublicUsers.filter((d, i) => {
                    if (d.isDeleted === false) {
                      return ["fleetmanager", "carrier"].indexOf(d.role) > -1;
                    }
                  })
                  .map((d) => {
                    return {
                      value: d._id,
                      label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName}`.trim(),
                      allInfo: d,
                    };
                  }) || []),...(localFilter?.assignees || [])], 'value' )}
                  value={localFilter?.assignees}
                  onChange={(val) => {
                    setLocalFilterKey("assignees", val);
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.ASSIGNEE, eventProperties);
                  }}
                  isAsync={false}
                />
                
              </div>

          </Fragment>
          {isTerminal() && !isFromLoad && (
          <Fragment>
            <div className="hr-light" />
            <div className="font-medium mb-10">Terminal</div>

              <div className="d-flex align-items-center gap-10">
                <CheckboxAsyncSelect
                  className="w-80"
                  styles={smallSelectStyle}
                  options={_.uniqBy([...(terminalsOptions || []),...(localFilter?.branches || [])], 'value' )}
                  isMulti
                  value={localFilter?.branches}
                  onChange={(branches) => {
                    setLocalFilterKey("branches", branches);
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.BRANCH, eventProperties);
                  }}
                  isAsync={false}
                />
                <div className={`form-check form-check--gray w-20`}>
                  <input 
                    className="form-check-input" 
                    id={`branch-exclude`} 
                    type="checkbox"
                    disabled={localFilter?.branches?.length ? false : true }
                    checked={!!localFilter?.isExcludeBranches}
                    onChange={(e) => setLocalFilterKey("isExcludeBranches", localFilter?.isExcludeBranches ? null : true)}
                    />
                  <label className="form-check-label" htmlFor={`branch-exclude`}>
                    Exclude
                  </label>
                </div>
              </div>

          </Fragment>
        )}

        {!isFromLoad && (<Fragment>
          <div className="hr-light" />
          <div className="font-medium mb-10">CSR</div>
          <div className="d-flex flex-column gap-10">
            <div className="d-flex align-items-center gap-10">
              <Select
                className="w-100"
                styles={smallSelectStyle}
                cacheOptions
                isMulti
                options={allCSR}
                value={localFilter?.csr}
                onChange={(csr) => {
                  setLocalFilterKey("csr", csr);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.CSR, eventProperties);
                }}
              />
            </div>
          </div>
        </Fragment>)}
        {!isFromLoad &&(
          <Fragment>
            <div className="hr-light" />
            <div className="font-medium mb-10 d-flex ">{provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"}<div data-tip data-for="label" className={`ml-10 bg-light wh-16px rounded-circle d-flex justify-content-center align-items-center bg-gray-200 pointer`}>
              <IconInfo className="text-gray-900 font-10" />
            </div>
              <ReactTooltip id="label" place="top" effect="solid" className="react-tooltip-new w-150 h-auto">
                {"The Label filter will not be saved with a View because Views can be used with multiple mailboxes that may have different Labels."}
              </ReactTooltip></div>
            <div className="d-flex flex-column gap-10">
              <div className="d-flex align-items-center gap-10">
                <Select
                  styles={smallSelectStyle}
                  placeholder="All Labels"
                  options={labelsOption}
                  value={localFilter?.label}
                  onChange={(data) => {
                    setLocalFilterKey("label", data)
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LABEL, eventProperties);
                  }}
                  isClearable
                  className="w-100"
                />
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>

      {!isFromLoad && (<>
        <div className="hr-light" />
      <>
        <div className="font-16 font-medium line-height-20 mb-10">Connected Loads</div>
        <CustomFormItem label="Created by">
          <Select
            styles={smallSelectStyle}
            cacheOptions
            isMulti
            options={allPublicUsers
              .filter((d, i) => {
                if (d.isDeleted === false) {
                  return ["carrier", "fleetmanager"].indexOf(d.role) > -1;
                }
              })
              .map((d) => {
                return {
                  value: d._id,
                  label: `${d.name} ${d.name.includes(d.lastName) ? "" : d?.lastName || ""}`.trim(),
                  allInfo: d,
                };
              })}
            value={localFilter?.createdBy}
            onChange={(val) => {
              setLocalFilterKey("createdBy", val);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_CREATED_BY, eventProperties);
            }}
          />
        </CustomFormItem>
        {/* {marginEnabled && (
          <div className="form-group form-row align-items-center">
            <label className="col-md-5 col-form-label">Load Margins %</label>
            <div className="col-md-7">
              <div className="form-row">
                <div className=" col-md">
                  <NumberFormatterV2
                    className="form-control"
                    type="number"
                    selected={true}
                    autoFocus={false}
                    showCurrency={false}
                    onChange={(e) => {
                      setLocalFilterKey("marginFrom", e.target.value);
                    }}
                    value={localFilter?.marginFrom}
                    autocomplete="off"
                    emptyIfErased={true}
                    placeholder="From"
                    overridePlaceholder={true}
                  />
                </div>
                <div className=" col-md-3 pl-25 pr-25">
                  <hr />
                </div>
                <div className=" col-md">
                  <NumberFormatterV2
                    className="form-control"
                    type="number"
                    selected={true}
                    autoFocus={false}
                    showCurrency={false}
                    onChange={(e) => {
                      setLocalFilterKey("marginTo", e.target.value);
                    }}
                    value={localFilter?.marginTo}
                    autocomplete="off"
                    emptyIfErased={true}
                    placeholder="To"
                    overridePlaceholder={true}
                  />
                </div>
              </div>
            </div>
          </div>
        )} */}
        <CustomFormItem label="Container #">
          <input
            type="text"
            className="form-control"
            placeholder="Enter"
            value={localFilter?.containerNo}
            onChange={(e) => {
              setLocalFilterKey("containerNo", e.target.value)
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.CONTAINER_NO, eventProperties);
          }}
          />
        </CustomFormItem>
        <CustomFormItem label="Bill of Landing #">
          <input
            type="text"
            className="form-control"
            placeholder="Enter"
            value={localFilter?.callerbillLandingNo}
            onChange={(e) => {
              setLocalFilterKey("callerbillLandingNo", e.target.value)
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.BILL_LANDING_NO, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="Reference #">
          <input
            type="text"
            className="form-control"
            placeholder="Enter"
            value={localFilter?.secondaryReferenceNo}
            onChange={(e) => {
              setLocalFilterKey("secondaryReferenceNo", e.target.value)
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.REFERENCE_NO, eventProperties);
          }}
          />
        </CustomFormItem>
        <CustomFormItem label="Booking #">
          <input
            type="text"
            className="form-control"
            placeholder="Enter"
            value={localFilter?.bookingNo}
            onChange={(e) => {
              setLocalFilterKey("bookingNo", e.target.value)
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.BOOKING_NO, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="Purchase Order #">
          <input
            type="text"
            className="form-control"
            placeholder="Enter"
            value={localFilter?.purchaseOrderNo}
            onChange={(e) => {
              setLocalFilterKey("purchaseOrderNo", e.target.value)
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.PURCHASE_ORDER_NO, eventProperties);
            }}
          />
        </CustomFormItem>

        <CustomFormItem label="Pending">
          <div class="d-flex ">
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"pending-yes"}
                checked={localFilter?.isPendingLoad === true}
                onChange={() => {
                  setLocalFilterKey("isPendingLoad", true);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_PENDING, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"pending-yes"}>
                {"Yes"}
              </label>
            </div>
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"pending-no"}
                checked={localFilter?.isPendingLoad === false}
                onChange={() => {
                  setLocalFilterKey("isPendingLoad", false);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_PENDING, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"pending-no"}>
                {"No"}
              </label>
            </div>
          </div>
        </CustomFormItem>
        <CustomFormItem label="In Progress">
          <div class="d-flex ">
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"progress-yes"}
                checked={localFilter?.isInProgressLoad === true}
                onChange={() => {
                  setLocalFilterKey("isInProgressLoad", true);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_IN_PROGRESS, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"progress-yes"}>
                {"Yes"}
              </label>
            </div>
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"progress-no"}
                checked={localFilter?.isInProgressLoad === false}
                onChange={() => {
                  setLocalFilterKey("isInProgressLoad", false);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_IN_PROGRESS, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"progress-no"}>
                {"No"}
              </label>
            </div>
          </div>
        </CustomFormItem>
        <CustomFormItem label="Completed">
          <div class="d-flex ">
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"completed-yes"}
                checked={localFilter?.isCompletedLoad === true}
                onChange={() => {
                  setLocalFilterKey("isCompletedLoad", true);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_COMPLETED, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"completed-yes"}>
                {"Yes"}
              </label>
            </div>
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"completed-no"}
                checked={localFilter?.isCompletedLoad === false}
                onChange={() => {
                  setLocalFilterKey("isCompletedLoad", false);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_COMPLETED, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"completed-no"}>
                {"No"}
              </label>
            </div>
          </div>
        </CustomFormItem>
        { isCombineTripEnabled() && <CustomFormItem label="Combined Trip">
          <div class="d-flex ">
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"isCombinedTrip-yes"}
                checked={localFilter?.isCombinedTrip === true}
                onChange={() => {
                  setLocalFilterKey("isCombinedTrip", true);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_COMBINED_TRIP, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"isCombinedTrip-yes"}>
                {"Yes"}
              </label>
            </div>
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"radio"}
                id={"isCombinedTrip-no"}
                checked={localFilter?.isCombinedTrip === false}
                onChange={() => {
                  setLocalFilterKey("isCombinedTrip", false);
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_COMBINED_TRIP, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"isCombinedTrip-no"}>
                {"No"}
              </label>
            </div>
          </div>
        </CustomFormItem>}

        <CustomFormItem label="Pick Up Appointment">
          <CustomDateTimeRangePicker
            ranges={RANGES}
            onApply={(e, picker) => {
              setLocalFilterKey("pickupFrom", picker.startDate);
              setLocalFilterKey("pickupTo", picker.endDate);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_PICK_UP_DATE, eventProperties);
            }}
            onClear={(e) => {
              setLocalFilterKey("pickupFrom", null);
              setLocalFilterKey("pickupTo", null);
            }}
            fromDate={localFilter?.pickupFrom}
            toDate={localFilter?.pickupTo}
            isTimePicker={true}
          />
        </CustomFormItem>
        <CustomFormItem label="Delivery Appointment">
          <CustomDateTimeRangePicker
            ranges={RANGES}
            onApply={(e, picker) => {
              setLocalFilterKey("deliveryFrom", picker.startDate);
              setLocalFilterKey("deliveryTo", picker.endDate);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_DELIVERY_DATE, eventProperties);
            }}
            onClear={(e) => {
              setLocalFilterKey("deliveryFrom", null);
              setLocalFilterKey("deliveryTo", null);
            }}
            fromDate={localFilter?.deliveryFrom}
            toDate={localFilter?.deliveryTo}
            isTimePicker={true}
          />
        </CustomFormItem>
        <CustomFormItem label="Return Appointment">
          <CustomDateTimeRangePicker
            ranges={RANGES}
            onApply={(e, picker) => {
              setLocalFilterKey("returnFrom", picker.startDate);
              setLocalFilterKey("returnTo", picker.endDate);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_RETURN_DATE, eventProperties);
            }}
            onClear={(e) => {
              setLocalFilterKey("returnFrom", null);
              setLocalFilterKey("returnTo", null);
            }}
            fromDate={localFilter?.returnFrom}
            toDate={localFilter?.returnTo}
            isTimePicker={true}
          />
        </CustomFormItem>
        <CustomFormItem label="Last Free Day">
          <CustomDateTimeRangePicker
            ranges={RANGES}
            onApply={(e, picker) => {
              setLocalFilterKey("lastFreeDayFrom", picker.startDate);
              setLocalFilterKey("lastFreeDayTo", picker.endDate);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_LAST_FREE_DATE, eventProperties);
            }}
            onClear={(e) => {
              setLocalFilterKey("lastFreeDayFrom", null);
              setLocalFilterKey("lastFreeDayTo", null);
            }}
            fromDate={localFilter?.lastFreeDayFrom}
            toDate={localFilter?.lastFreeDayTo}
            isTimePicker={true}
          />
        </CustomFormItem>
        <CustomFormItem label="Cut Off">
          <CustomDateTimeRangePicker
            ranges={RANGES}
            onApply={(e, picker) => {
              setLocalFilterKey("cutOffFrom", picker.startDate);
              setLocalFilterKey("cutOffTo", picker.endDate);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_CUTT_OFF_DATE, eventProperties);
            }}
            onClear={(e) => {
              setLocalFilterKey("cutOffFrom", null);
              setLocalFilterKey("cutOffTo", null);
            }}
            fromDate={localFilter?.cutOffFrom}
            toDate={localFilter?.cutOffTo}
            isTimePicker={true}
          />
        </CustomFormItem>

        <CustomFormItem label="Load Type">
          <Select
            styles={smallSelectStyle}
            isMulti
            options={LOAD_TYPES}
            value={localFilter?.type_of_load}
            onChange={(e) => {
              setLocalFilterKey("type_of_load", e);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_TYPE, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label={updateTerminology("Port")}>
          <AsyncSelect
            styles={smallSelectStyle}
            cacheOptions
            isMulti
            defaultOptions={isTerminalTrackingEnabled() ? mapTerminalTrackingOptionLabel({options: shippersOptions, terminalKey: 'allInfos'}) : shippersOptions}
            value={localFilter?.shipper}
            onChange={(shipper) => {
              setLocalFilterKey("shipper", shipper);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_PORT, eventProperties);
            }}
            loadOptions={isTerminalTrackingEnabled() ? getCustomerAsyncSearchWithStatus : getCustomerAsyncSearch}
          />
        </CustomFormItem>
        <CustomFormItem label={`${updateTerminology("Consignee")}`}>
          <AsyncSelect
            styles={smallSelectStyle}
            cacheOptions
            isMulti
            defaultOptions={isTerminalTrackingEnabled() ? mapTerminalTrackingOptionLabel({options: consigneesOptions, terminalKey: 'allInfos'}) : consigneesOptions}
            value={localFilter?.consignee}
            onChange={(consignee) => {
              setLocalFilterKey("consignee", consignee);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_CONSIGNEE, eventProperties);
            }}
            loadOptions={isTerminalTrackingEnabled() ? getCustomerAsyncSearchWithStatus : getCustomerAsyncSearch}
          />
        </CustomFormItem>
        <CustomFormItem label="Return">
          <AsyncSelect
            styles={smallSelectStyle}
            cacheOptions
            isMulti
            defaultOptions={isTerminalTrackingEnabled() ? mapTerminalTrackingOptionLabel({options: customersOptions, terminalKey: 'allInfos'}) : customersOptions}
            value={localFilter?.emptyOrigin}
            onChange={(emptyOrigin) => {
              setLocalFilterKey("emptyOrigin", emptyOrigin);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_RETURN_LOCATION, eventProperties);
            }}
            loadOptions={isTerminalTrackingEnabled() ? getCustomerAsyncSearchWithStatus : getCustomerAsyncSearch}
          />
        </CustomFormItem>
        <CustomFormItem label="Drivers">
          <Select
            styles={smallSelectStyle}
            isMulti
            options={drivers.map((d) => ({
              value: d._id,
              label: `${d.name} ${d.lastName}`.trim(),
              allInfo: d,
            }))}
            value={localFilter?.drivers}
            onChange={(driver) => {
              setLocalFilterKey("drivers", driver);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_DRIVERS, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="Container Type">
          <Select
            styles={smallSelectStyle}
            cacheOptions
            options={containerTypeOptions}
            isMulti
            value={localFilter?.containerTypes}
            onChange={(containerTypes) => {
              setLocalFilterKey("containerTypes", containerTypes);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_CONTAINER_TYPE, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="Container Size">
          <Select
            styles={smallSelectStyle}
            cacheOptions
            options={containerSizeOptions}
            isMulti
            value={localFilter?.containerSizes}
            onChange={(containerSizes) => {
              setLocalFilterKey("containerSizes", containerSizes);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_CONTAINER_SIZE, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="SSL">
          <Select
            styles={smallSelectStyle}
            cacheOptions
            options={containerOwnerOptions}
            isMulti
            value={localFilter?.containerOwners}
            onChange={(containerOwners) => {
              setLocalFilterKey("containerOwners", containerOwners);
              let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
              }
              amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_SSL, eventProperties);
            }}
          />
        </CustomFormItem>
        <CustomFormItem label="Load Template">
          <Select
            name="loadRoute"
            options={getPreSetOrderStatus()}
            value={localFilter?.preSetOrderStatus}
            onChange={(value) => {setLocalFilterKey("preSetOrderStatus", value)
            let eventProperties = {
              source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
            }
            amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_TEMPLATE, eventProperties);
          }}
            styles={smallSelectStyle}
          />
        </CustomFormItem>
        <CustomFormItem label="SLAs">
          <Select
            name="slaRule"
            options={SLA_FILTER}
            isMulti
            value={localFilter?.slaRule}
            onChange={(value) => {setLocalFilterKey("slaRule", value)
            let eventProperties = {
              source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
            }
            amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_TEMPLATE, eventProperties);
          }}
            styles={smallSelectStyle}
          />
        </CustomFormItem>
      </>

      <div className="hr-light" />
      <>
        <CustomFormItem label="Ready to Return">
          <div class="d-flex ">
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"checkbox"}
                id={"isReadyForPickup-yes"}
                checked={localFilter?.isReadyForPickup === true}
                onChange={() => {
                  if (localFilter?.isReadyForPickup === true) {
                    setLocalFilterKey("isReadyForPickup", null);
                  } else {
                    setLocalFilterKey("isReadyForPickup", true);
                  }
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_READY_TO_RETURN, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"isReadyForPickup-yes"}>
                {"Yes"}
              </label>
            </div>
            <div class={`form-check form-check--gray pr-30`}>
              <input
                class="form-check-input"
                type={"checkbox"}
                id={"isReadyForPickup-no"}
                checked={localFilter?.isReadyForPickup === false}
                onChange={() => {
                  if (localFilter?.isReadyForPickup === false) {
                    setLocalFilterKey("isReadyForPickup", null);
                  } else {
                    setLocalFilterKey("isReadyForPickup", false);
                  }
                  let eventProperties = {
                    source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                  }
                  amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_READY_TO_RETURN, eventProperties);
                }}
              />
              <label class="form-check-label" htmlFor={"isReadyForPickup-no"}>
                {"No"}
              </label>
            </div>
          </div>
        </CustomFormItem>
      </>

      <div className="hr-light" />
      <div className="row mb-60">
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="Caution"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("CAUTION_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("CAUTION_COUNT")
                selectLabelForCheckBoxes("CAUTION_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_HAZMAT, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="Caution">
              <IconWarning className="text-warning mr-10" />
              Hazmat
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="hot"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("HOT_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("HOT_COUNT")
                selectLabelForCheckBoxes("HOT_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_HOT, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="hot">
              <IconFire className="text-danger mr-10" />
              Hot
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="SCALE_COUNT"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("SCALE_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("SCALE_COUNT")
                selectLabelForCheckBoxes("SCALE_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_OVERWEIGHT, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="SCALE_COUNT">
              <IconWeightTool className="text-gray-700 mr-10" />
              Overweight
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="LIQUOR_COUNT"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("LIQUOR_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("LIQUOR_COUNT")
                selectLabelForCheckBoxes("LIQUOR_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_LIQUOR, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="LIQUOR_COUNT">
              <IconLiquor className="text-warning-500 mr-10" />
              Liquor
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="REEFER_COUNT"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("REEFER_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("REEFER_COUNT")
                selectLabelForCheckBoxes("REEFER_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_REEFER, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="REEFER_COUNT">
              <IconSnowFlake className="text-skyblue-500 mr-10" />
              Reefer
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="highway"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("HIGHWAY_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("HIGHWAY_COUNT")
                selectLabelForCheckBoxes("HIGHWAY_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_HIGHWAY, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="highway">
              <IconHighway className="mr-10" />
              Highway
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="genset"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("GENSET_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("GENSET_COUNT")
                selectLabelForCheckBoxes("GENSET_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_GENSET, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="genset">
              <IconFlash className="mr-10" />
              Genset
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="local"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("LOCAL_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("LOCAL_COUNT")
                selectLabelForCheckBoxes("LOCAL_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_LOCAL, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="local">
              <IconMapMarker className="mr-10" />
              Local
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
        <div className="col-md-6 d-flex align-items-center mt-10">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="NOTE_COUNT"
              checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("NOTE_COUNT")}
              onClick={() => {
                if(isLoading) return;
                setLocalCheckBoxes("NOTE_COUNT")
                selectLabelForCheckBoxes("NOTE_COUNT");
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                }
                amplitudeTrack(AMPLITUDE_EVENTS.FILTER.LOAD_NOTES, eventProperties);
              }}
              onChange={() => {}}
            />
            <label className="form-check-label d-flex align-items-center" htmlFor="NOTE_COUNT">
              <IconFile className=" mr-10" />
              Notes
            </label>
          </div>
          {/* <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{0}</span> */}
        </div>
          {isNewEndorsement() && <>
            <div className="col-md-6 d-flex align-items-center mt-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="domestic"
                  checked={Array.isArray(localFilter?.selectedStatus) && localFilter.selectedStatus.includes("DOMESTIC_COUNT")}
                  onClick={() => {
                    if(isLoading) return;
                    setLocalCheckBoxes("DOMESTIC_COUNT")
                    selectLabelForCheckBoxes("DOMESTIC_COUNT");
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.DOMESTIC_COUNT, eventProperties);
                  }}
                  onChange={() => { }}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor="domestic"
                >
                  <IconTrainTrack className="text-gray-700 mr-10" />
                  Domestic
                </label>
              </div>
            </div>

            <div className="col-md-6 d-flex align-items-center mt-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="ev"
                  checked={Array.isArray(localFilter?.selectedStatus) && localFilter.selectedStatus.includes("EV_COUNT")}
                  onClick={() => {
                    if(isLoading) return;
                    setLocalCheckBoxes("EV_COUNT")
                    selectLabelForCheckBoxes("EV_COUNT");
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.EV_COUNT, eventProperties);
                  }}
                  onChange={() => { }}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor="ev"
                >
                  <IconPlug className="text-gray-700 mr-10" />
                  EV
                </label>
              </div>
            </div>

            <div className="col-md-6 d-flex align-items-center mt-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="waste"
                  checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("WASTE_COUNT")}
                  onClick={() => {
                    if(isLoading) return;
                    setLocalCheckBoxes("WASTE_COUNT")
                    selectLabelForCheckBoxes("WASTE_COUNT");
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.WASTE_COUNT, eventProperties);
                  }}
                  onChange={() => { }}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor="waste"
                >
                  <IconWaste className="text-gray-700 mr-10" />
                  Waste
                </label>
              </div>
            </div>

            <div className="col-md-6 d-flex align-items-center mt-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gdp"
                  checked={Array.isArray(localFilter?.selectedStatus) && localFilter?.selectedStatus.includes("GDP_COUNT")}
                  onClick={() => {
                    if(isLoading) return;
                    setLocalCheckBoxes("GDP_COUNT")
                    selectLabelForCheckBoxes("GDP_COUNT");
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.GDP_COUNT, eventProperties);
                  }}
                  onChange={() => { }}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor="gdp"
                >
                  <IconPlusCircle className="text-gray-700 mr-10" />
                  GDP
                </label>
              </div>
            </div>

            <div className="col-md-6 d-flex align-items-center mt-10">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isRail"
                  checked={Array.isArray(localFilter?.selectedStatus) && localFilter.selectedStatus.includes("IS_RAIL_COUNT")}
                  onClick={() => {
                    if(isLoading) return;
                    setLocalCheckBoxes("IS_RAIL_COUNT")
                    selectLabelForCheckBoxes("IS_RAIL_COUNT");
                    let eventProperties = {
                      source: AMPLITUDE_EVENTS_SOURCE.FILTER_SIDEBAR
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.FILTER.IS_RAIL_COUNT, eventProperties);
                  }}
                  onChange={() => { }}
                />
                <label
                  className="form-check-label d-flex align-items-center"
                  htmlFor="isRail"
                >
                  <IconRail className="text-gray-700 mr-10" />
                  Rail
                </label>
              </div>
            </div>
          </>}
      </div>
      </>)}

      <div className="bdpopup__footer  page-fluid">
            <button disabled={isLoading || _.isEqual(localFilter,emailFilters)} className="btn btn-primary" onClick={() => {
              handleApplyFilter(localFilter)
            }}>
              Apply
            </button>
      </div>

    </>
  );
};

export default TabFilters;
