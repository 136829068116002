import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconConfirm } from "../../../../Components/Common/CustomIcons/Index";
import { IconCancelCircle, IconWarning } from "../../../../Components/Common/Icons";

class ConfirmAppointment extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedDateTime, selectedSlot, onClose, apptData, closeModals } = this.props;
        return (
            <React.Fragment>
                <Modal.Body>
                    <div className="d-flex flex-column p-10 align-items-center text-center">
                        <div>
                            {apptData.status === "CONFIRMED" ?
                                <CustomIconConfirm className="text-primary mb-20" />
                                :
                                <IconWarning className="text-orange wh-50px mb-20" />
                            }
                        </div>
                        <h3>{apptData.portStatus}</h3>
                        <div className="mt-15 p-20 text-center bg-gray-50 font-20 font-weight-500 rounded-5 w-max">
                            {selectedDateTime} • {selectedSlot.text}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn btn-outline-light" onClick={() => {onClose(); closeModals()}}>
                            Close
                        </button>
                    </div>
                </Modal.Footer>
            </React.Fragment>
        )
    }
}

export default ConfirmAppointment;