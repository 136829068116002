import React, {useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse , errorColorResponse } from "../../../utility";
const fetchContainerType = (containerType, searchType) => {
	let allContainerType = containerType
	if (allContainerType.length > 0) {
		if (searchType !== null) {
			allContainerType = allContainerType.filter((type) => {
				return (
					type.name
						.toLowerCase()
						.indexOf(
							searchType.toLowerCase().trim()
						) != -1
				);
			});
		}
		return allContainerType
	}
}

const ContainerTypeList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress , column }) => {
	const { containerType } = useSelector((store) => store.containerReducer, shallowEqual);

	const [cursor, setCursor] = useState(null);
	const [searchType, setSearchType] = useState(null);
	const [dList, setDList] = useState(fetchContainerType(containerType, searchType));
	const searchInputRef = React.useRef(null);

	const cell = document.querySelector('[aria-selected="true"]');
	const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)

	useEffect(() => {
		searchInputRef.current.focus();
		window.document.getElementById('root').style.pointerEvents="none"
		return ()=>{
			window.document.getElementById('root').style.pointerEvents="auto"
		}
	}, [])

	useOnClickOutside(boxRef, () => onClose(false))

	useEffect(() => {
		const _containerType = fetchContainerType(containerType, searchType);
		setDList(_containerType);
	}, [searchType]);

	const get = (data) => {
		if (Row) {
			const payload = {
				reference_number: Row.reference_number,
				containerType: data._id,
			}
			setIsAssignInProgress(true);
			editLoad(payload).then(el => {
				onRowChange({ ...Row, containerTypeName: data.name });
				setIsAssignInProgress(false);
				onClose(false)
                successColorResponse(cell,column.idx, Row?.className);
			}).catch((err) => {
				setIsAssignInProgress(false);
				onClose(false)
				errorColorResponse(cell,column.idx, Row?.className)
			})
		}
	}
	const handleKeyDown = (event) => {
		if (event.keyCode === 38) {
			if (cursor >= 0) {
				setCursor(cursor - 1)
				document.getElementById(`containerType-${cursor}`).focus();
			}
			event.preventDefault();
		} else if (event.keyCode === 40) {
			if (cursor <= dList.length - 1) {
				if (cursor === null) {
					setCursor(0)
				} else {
					setCursor((prev) => prev + 1)
					document.getElementById(`containerType-${cursor}`).focus();

				}
			}
			event.preventDefault();
		} else if (event.keyCode === 13) {
			dList.forEach((val, index) => {
				if (index === cursor) get(val);
			});
			event.preventDefault();
		}else if (event.keyCode === 9 || event.keyCode === 27) {
			onClose(false)
			event.preventDefault();
			event.stopPropagation();
		  }
	}
	return createPortal(
		<div 
			ref={boxRef}
			className="card card--shadow-5  mb-0 p-10 text-left z-1 w-200"
		 	style={Style}
			data-testid="container-type-list"
			>
			<div className="app-search bg-gray-50 rounded-lg mb-10">
				<input
					className="input-search-left form-control py-2"
					placeholder="Search Container Type.."
					id="domTextElement"
					onChange={(e) => setSearchType(e.target.value)}
					onKeyDown={(event) => { handleKeyDown(event) }}
					ref={searchInputRef}
				/>
				<span className="search-icon ml-1"></span>
			</div>
			<ul className="driverList m-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
				{(!dList || dList?.length === 0) ? (<p className="p-1">No type available</p>) : dList?.map((el, index) => (
					<li
						key={index}
						className={`popdrop__dropdown-item pointer ${cursor === index
							? "bg-gradient-light"
							: null
							}`}
						tabIndex="-1"
						id={`containerType-${index}`}
						style={{ height: '30px' }}
						onClick={async () => get(el)}
						onKeyDown={(e) => handleKeyDown(e)}
					>{el.name}</li>
				))}
			</ul>
		</div>,
		document.getElementById('pp-overlay-container')
	);
};

export default ContainerTypeList;
