import { IconWarning } from 'Components/Common/Icons';
import { LOAD_LABELS } from 'labels';

export const EdiTestLabel = ({ className }) => {
  return (
    <span
      className={`badge badge-sm outline-warning outline-1 bg-warning-100 align-items-center gap-2 w-fit ${
        className ? className : ""
      }`}
    >
      <IconWarning className="text-warning wh-12px" />
      {LOAD_LABELS.TEST_EDI}
    </span>
  );
};
