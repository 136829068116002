import React, { useRef, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { amplitudeTrack } from 'services'
import { AMPLITUDE_EVENTS, SEND_EMAIL_TYPE, EMAIL_CONTEXT, EMAIL_PROVIDER } from '../constant'
import { useDropDownPosition } from 'hooks/helpers/useDropDownPosition'
import { useOnClickOutside, useOnEscKey } from 'hooks'
import { useFavoriteFolders } from "../hooks"
import { IconDelete, IconPlus, IconStarOutline, IconStar } from '../../../../Components/Common/Icons'
import RemoveLabelModal from './EmailNav/LabelModals/RemoveLabelModal'

const FolderContextMenu = ({
  cellRef,
  onClose,
  hanldeOnDelete,
  onClick,
  setRightClickKey,
  eventProperties,
  folder,
  fromFavorites,
  setIsFavFolder,
  isFavFolder,
  handleAddFolder,
  handleDeleteFolder,
  isSubFolder,
  provider
}) => {
  const boxRef = useRef(null)
  const popupPlacement = "auto"
  const style = useDropDownPosition(popupPlacement, boxRef, cellRef)
  const { favoriteFolders, setFavoriteFolders } = useContext(EMAIL_CONTEXT);
  const { toggleFavoriteFolder } = useFavoriteFolders(favoriteFolders, setFavoriteFolders);

  const isGmailSubfolder = isSubFolder && provider === EMAIL_PROVIDER.GMAIL

  useOnClickOutside([boxRef], () => {
    onClose();
  })

  useOnEscKey(() => {
    if (boxRef.current) {
      onClose();
    }
  })

  const handleClick = () => {
    if (fromFavorites) {
      toggleFavoriteFolder(folder, fromFavorites);
    } else {
      toggleFavoriteFolder(folder, fromFavorites, setIsFavFolder, isFavFolder);
    }
    onClose();
  }

  return createPortal(
    <>
      <div
        className="w-200 bg-white rounded-5 position-fixed z-1051 overflow-auto shadow-5 d-flex flex-column gap-10"
        ref={boxRef}
        style={style}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {fromFavorites ?
          (<ul className="p-1 mb-0">
            <li className="d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0"
              onClick={handleClick}
            >
              <IconStar className="text-muted" />  Remove from Favorite
            </li>
          </ul>) :
          (<ul className="p-1 mb-0">
            {isFavFolder ?
              (<li className="d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0"
                onClick={handleClick}
              >
                <IconStar className="text-muted" />  Remove from Favorite
              </li>) :
              (<li className="d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0"
                onClick={handleClick}
              >
                <IconStarOutline className="text-muted" />  Add To Favorite
              </li>)}
              {!isGmailSubfolder && <li className={`d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0 border-gray-100 border-top-1 ${folder?.systemFolder ? "" : "border-bottom-1"}`}
                onClick={handleAddFolder}
              >
                <IconPlus className="text-muted" /> Add Folder
              </li>}
              {!folder?.systemFolder && <li className="d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0 text-danger"
                onClick={handleDeleteFolder}
              >
                <IconDelete className="text-danger ml-1" /> Delete {provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"}
              </li>}
          </ul>)}
      </div>
    </>,
    document.getElementById("pp-overlay-container")
  )
}

export default FolderContextMenu