import React, { useRef, useState, useEffect } from 'react'
import ContainerSizeList from '../portals/ContainerSize'
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from '../../../../../../Components/Common/Spinner/CellSpinner';

const ContainerSize = (props) => {
	const [showBox, setShowBox] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();

	useEffect(() => {
		if (prevSelectionRef.current === false && props.isCellSelected && !isColorPortal) {
			setShowBox(true)
		}
		prevSelectionRef.current = props.isCellSelected
		if (!props.isCellSelected) {
			setColorPortal(false);
		}
	}, [props.isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, props.row?.colorOfCells?.containerSize);
	}, [props.column, props.row?.colorOfCells?.containerSize]);

	return (
		<>
		<div ref={cellRef} className="cell-content" onClick={() => setShowBox(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
	
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && props.row.containerSize ? props.row.containerSize.name : ""}
			{props.row?.isEditPermission && showBox && !isAssignInProgress
				&& <ContainerSizeList
					Row={props.row}
					CellRef={cellRef}
					onClose={(val) => {
						setShowBox(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={props.onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={props.column}
				/>
			}
			
		</div>
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={props.row?._id} fieldName="containerSize" defaultCellColors={props.row?.colorOfCells || {}} Row={props.row} onRowChange={props.onRowChange} />
		}
	</>)
}
export default ContainerSize