import useMarginPercentageStyles from 'Components/CustomHooks/useMarginPercentageStyles';
import { LOAD_LABELS } from 'labels';
import moment from 'moment';
import { titleCase } from 'pages/tms/CreditMemo/utility';
import React from 'react';
import { checkUserPermisison, getStorage, isMarginEnabled } from 'services';


const LoadDetails = ( {loadDetail} ) => {
    const billingConfiguration = JSON.parse(getStorage('userBasicSettings'))?.billingConfiguration;
    const { min, max } = billingConfiguration?.config?.loadMargin ?? {};
    const { percentBGColor } = useMarginPercentageStyles(
        loadDetail?.marginPercent ?? 0,
        min,
        max
      );
    const timeZone = getStorage("timeZone");
    const isMargin = isMarginEnabled() && checkUserPermisison(["show_load_margin"])
    return (
        <div className='px-15'>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Load #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.reference_number || "-"}</span>
            </div>
            {isMargin && <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Load Margin</span>
                <span className={`badge ${percentBGColor} badge-success rounded-20 mr-1text-muted text-dark font-weight-normal font-12 text-right`}>{`${loadDetail?.marginPercent || 0}%`  || "-"}</span>
            </div>}
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.REFERENCE_NUMBER}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.secondaryReferenceNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Purchase Order</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.purchaseOrderNo || "-"}</span>
            </div>
            {loadDetail?.terminalDetails && <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.BRANCH}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.terminalDetails ? loadDetail?.terminalDetails?.name : "-"}</span>
            </div>}
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.PICK_UP_LOCATION}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.shipperName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.DELIVERY_LOCATION}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.consigneeName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.RETURN_LOCATION}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.emptyOriginName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.CONTAINER_NUMBER}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.containerNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.SEAL_NUMBER}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.sealNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.VESSEL_NAME}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.deliveryOrderNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{LOAD_LABELS.MASTER_BILL_OF_LADING}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.callerbillLandingNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Delivered</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{`${loadDetail?.deliverTime ? moment(loadDetail.deliverTime).tz(timeZone).format("MM/DD hh:mm a") : "-"}`}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Return Container Date</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{`${loadDetail?.returnTime ? moment(loadDetail?.returnTime).tz(timeZone).format("MM/DD hh:mm a") : "-"}`}</span>
            </div>
        </div>
    );
}

export default LoadDetails;
