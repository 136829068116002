import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { saveNotes, updateLoad } from "../../actionCreator";
import { textEditorClassname } from "../../defaultClass/classlist";
import { toastr } from "../../../../../../services";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import confirm from "../../../../../../Components/Common/ConfirmAert";
import { removeValueLoad } from "../../../../services";
import { changeBgColor } from "../../../utility";
import { SYNC_REQUEST_SENT } from "pages/tms/Load/utility";

function autoFocusAndSelect(input) {
    input?.focus();
    // input?.select();
}

let InitialValue = null;
let InitialTime = moment().toISOString();

const TextEditor = ({ row, column, onRowChange, onClose }) => {
    const [state, setState] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        row[column.key] = row[column.key] ? (row[column.key]).trim() : "";
        InitialTime = moment().toISOString();
        if (column.key === "bol_bkg") {
            if(["IMPORT", "ROAD"].includes(row.type_of_load)){
                row["callerbillLandingNo"] = row["callerbillLandingNo"] ? (row["callerbillLandingNo"]).trim() : "";
                setState(() => "callerbillLandingNo");
                InitialValue = row["callerbillLandingNo"]
            }
            if(row.type_of_load === "EXPORT") {
                row["bookingNo"] = row["bookingNo"] ? (row["bookingNo"]).trim() : "";
                setState(() => "bookingNo");
                InitialValue = row["bookingNo"]
            }
            if(row.type_of_load === "BILL_ONLY"){
                setState();
            }
        } else if (column.key === "road_container_ref") {
            if(row.type_of_load === "ROAD"){
                row["containerNo"] = row["containerNo"] ? (row["containerNo"]).trim() : "";
                setState(() => "containerNo")
                InitialValue = row["containerNo"]
            }else{
                setState();
            }
        } else {
            setState(column.key);
            InitialValue = row[column.key]
        }
        window.document.getElementById("root").style.pointerEvents = "none";
        return () => {
            window.document.getElementById("root").style.pointerEvents = "auto";
        };
    }, []);

    const onReConfirmUpdate = (event, data, reference_number) => {
        let title = "Container: " + data;
        let body = (
          <div>
            <h4>
                This container is already in the system.
            </h4>
            <div>
              <p>
                Do you want to save the{" "}
                {"Container: " + data}{" "}
                to Load {reference_number} anyway?
              </p>
            </div>
          </div>
        );
        confirm(title, body, (confirm) => {
          if (confirm) {
            return apiCall(event, data, true);
          } else {
            changeBgColor(event.rowId, event.colId, "FAIL", row?.className);
            dispatch({ type: 'RESET_CONTAINER', reference_number, value: InitialValue });
          }
        });
    };
    const onReConfirmUpdateReferenceNo = (event, data, reference_number) => {
        let title = "Reference #: " + data;
        let body = (
          <div>
            <h4>
                This Reference # is already in the system.
            </h4>
            <div>
              <p>
                Do you want to save the{" "}
                {"Reference # : " + data}{" "}
                to Load {reference_number} anyway?
              </p>
            </div>
          </div>
        );
        confirm(title, body, (confirm) => {
          if (confirm) {
            return apiCall(event, data, false, null, true);
          } else {
            changeBgColor(event.rowId, event.colId, "FAIL", row?.className);
            dispatch({ type: 'RESET_REFERENCENO', reference_number, value: InitialValue });
          }
        });
    };

    const apiCall = (event, val, isContainerConfirm, noCopy, isSecondaryReferenceNoConfirm) => {
        val = val.trim();
        const rowId = event?.target?.parentElement?.parentElement?.getAttribute('aria-rowindex') || event.rowId;
        const colId = event?.target?.parentElement?.getAttribute('aria-colindex') || event.colId;
        if ((val || InitialValue) && !noCopy) {
            const actionDuration = moment().diff(InitialTime, 'milliseconds');
            if (actionDuration < 500 && window.isSecureContext) {
                dispatch({ type: 'COPY_VALUE', reference_number: row.reference_number, field: column.key });
                navigator.clipboard.writeText(val || InitialValue);
            }
        }

        const isValueRemoved = !val && InitialValue;
        if (isValueRemoved) {
            const params = {
                reference_number: row.reference_number,
                key: state,
            };
            removeValueLoad(params).then((el) => {
                changeBgColor(rowId, colId, "SAVE", row?.className);
            }).catch((err) => {
                changeBgColor(rowId, colId, "FAIL", row?.className);
                toastr.show('Failed to Remove !', 'error');
            });
        }
        else {
            if (!val || _.isEqual(val, InitialValue))
                return;
    
            if ((state === "containerNo" || state === "grayContainerNo") && val && !/^[A-Za-z]{4}\d{6,7}$/.test(val) && column.key !== "road_container_ref") {
                toastr.show('Please Enter Correct Container No', "warning");
                changeBgColor(rowId, colId, "FAIL", row?.className);
                dispatch({ type: 'RESET_CONTAINER', reference_number: row.reference_number, value: InitialValue });
                return;
            }
            if (state === "containerNo" || state === "grayContainerNo" || state === "secondaryReferenceNo" && column.key !== "road_container_ref") {
                const payload = { reference_number: row.reference_number, [state]: val };
                if(isContainerConfirm) {
                    payload.isContainerConfirm = isContainerConfirm
                }
                if(isSecondaryReferenceNoConfirm) {
                    payload.isSecondaryReferenceNoConfirm = isSecondaryReferenceNoConfirm
                }
                if (state === "secondaryReferenceNo") payload.isCheckValidation = true
                if(row.type_of_load === "ROAD" && state === "containerNo") payload.isContainerConfirm = true
                editLoad(payload).then((res) => {
                    if(res === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        if(state === "secondaryReferenceNo") {
                            dispatch({ type: 'RESET_REFERENCENO', reference_number: row?.reference_number, value: InitialValue });
                        } 
                        if(state === "containerNo") {
                            dispatch({ type: 'RESET_CONTAINER', reference_number: row?.reference_number, value: InitialValue });
                        }
                    }
                    if (res.hasContainer) {
                        onReConfirmUpdate(
                            { rowId, colId },
                            val,
                            row.reference_number,
                        )
                    } else if (res.hasSecondaryReferenceNo) {
                        onReConfirmUpdateReferenceNo(
                            { rowId, colId },
                            val,
                            row.reference_number,
                        )
                    } else {
                        changeBgColor(rowId, colId, "SAVE", row?.className);
                    }
                    if(isContainerConfirm){
                        changeBgColor(rowId, colId, "SAVE", row?.className);
                    }
                }).catch((err) => {
                    changeBgColor(rowId, colId,"FAIL", row?.className)
                    toastr.show('Failed to Update !', 'error');
                });
            }
            if (state === "yardNote" || state === "officeNote" || state === "driverNote" || state === "portNote" || state === "customsNote"){
                const payload = { reference_number: row.reference_number, [state]: val}
                saveNotes(payload).then(()=>{
                    changeBgColor(rowId, colId, "SAVE", row?.className);
                }).catch((err)=>{
                    changeBgColor(rowId, colId, "FAIL", row?.className);
                })
                return;
            }
            else if(state !== "containerNo") {
                const payload = {_id: row._id, [state]: val };
                updateLoad(payload).then((el) => {
                    if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, [state]: InitialValue });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: InitialValue, field: state});
                    }
                    changeBgColor(rowId, colId, "SAVE", row?.className);
                }).catch((err) => {
                    changeBgColor(rowId, colId, "FAIL", row?.className);
                    toastr.show('Failed to Update !', 'error');
                });
            }
        }
    };

    const keyPress = (event, state) => {
        event = event || window.event;
        const charCode = event.keyCode || event.which;
        if (charCode === 9 || charCode === 13) {
            apiCall(event, event.target.value?.trim(), false, true);
        }
    };

    return (
        <>
            {state ? (
                <input
                    data-testid="text-editor"
                    className={`border-0 ${textEditorClassname}`}
                    ref={autoFocusAndSelect}
                    value={row[state]}
                    onChange={(event) => {
                        onRowChange({ ...row, [state]: event.target.value });
                    }}
                    onKeyDown={(e) => keyPress(e, state)}
                    // onBlur={(e)=>handleBlur(e)}
                    onBlur={(e) => {
                        apiCall(e, (e.target.value).trim());
                        onClose(true);
                    }}
                />
            ) : (
                ""
            )}
        </>
    );
};

export default TextEditor;