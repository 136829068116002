import React, { useRef, useState, useEffect } from "react";
import ChassisList from "../portals/ChassisList";
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import PreventChassisList from "../portals/PreventChassisList";
import { getStorage } from "../../../../../../services";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const Chassis = ({ row, column, onRowChange, isCellSelected }) => {
	const cellRef = useRef();
	const prevSelectionRef = useRef();
	const [isChassisList, setIsChassisList] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);
	const isPreventChassis = JSON.parse(getStorage("userBasicSettings"))?.isPreventChassis;
	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setIsChassisList(true);
		}
		prevSelectionRef.current = isCellSelected;
		if (!isCellSelected || row.type_of_load=="ROAD") {
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.chassis);
	}, [column]);

	return (
	<>
		{row.type_of_load !== "ROAD" && <div ref={cellRef} className="cell-content" onClick={()=>setIsChassisList(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
	
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && row.chassisNo ? row.chassisNo : ""}
			{isChassisList && !isAssignInProgress && (
				isPreventChassis ? 
				<PreventChassisList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsChassisList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
				:
				<ChassisList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsChassisList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
			)}
		
		</div>}
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="chassis" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
	</>
	);
};

export default Chassis;
