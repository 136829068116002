import ReactTooltip from "react-tooltip";
import { getScheduleButtonColor, checkForDisable } from "../../NewDispatcher/DispatcherTable/Functions";
import { useMemo } from "react";
import { createPortal } from "react-dom";
import { useCurrentUserSettings } from "hooks";
import ButtonFormGroup from './ButtonFormGroup';
import { getStorage } from "services";

const APPT_TYPES = {
  "PICKUP": "pick",
  "EMPTY": "return",
}

const AutoBookAppointmentButton = ({ loadStatus, moveType, openAppointmentModal, btnClass = "", load = {}, isPortAccepted = false, wrapWithFormGroup = false, isAppointmentProcessing = false }) => {
  const btnId = new Date().valueOf()?.toString()
  const isDisabled =
    useMemo(() => {
      return checkForDisable(loadStatus, moveType)
    }, [loadStatus, moveType])

  const className = useMemo(() => {
    return `${btnClass ?? ""} ${getScheduleButtonColor(loadStatus, moveType)}`
  }, [btnClass, loadStatus, moveType])
  const button = <>
    <button
      className={`btn btn-sm btn-block ${className}`}
      onClick={openAppointmentModal}
      data-tip
      data-for={btnId}
      disabled={isDisabled}
    >
      {isAppointmentProcessing &&
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
      }
      Auto Appointment
    </button>
    {isDisabled && createPortal(
      <ReactTooltip id={btnId} place="top" multiline>
        <span>You cannot request {moveType?.toLowerCase()} apt because <br />the load status arrived {APPT_TYPES[moveType]} container is <br /> already completed.</span>
      </ReactTooltip>, document.getElementById("poper-dest")
    )
    }
  </>
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {}
  const showButton = isAppointmentFeatureEnabled && !JSON.parse(getStorage("userBasicSettings"))?.myappFeature?.includes("PORTBASE") && isPortAccepted
  if (showButton)
    return wrapWithFormGroup ? <ButtonFormGroup>{button}</ButtonFormGroup> : button
  return null
};

export default AutoBookAppointmentButton;