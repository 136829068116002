import { useEffect, useRef } from "react";

const TextInputField = (props) => {
  const {
    formsTouched,
    formErrors,
    label,
    name,
    value,
    onChange,
    placeholder,
    type,
    isRequired,
    className,
    disabled,
    onBlur,
    inputClassName
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.value = value ?? "";
  }, [name, value]);

  let err = formErrors?.[name] && typeof formErrors[name] === "string"
      ? formErrors[name]
      : null;

  return (
    <div className={`form-group ${className} ${err && "error"}`}>
      <label className="col-form-label">
        {isRequired && <span className="text-danger">* </span>}
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        ref={inputRef}
        className={`form-control transition-white-field ${inputClassName}`}
        formErrors={formErrors}
        formsTouched={formsTouched}
        placeholder={placeholder}
        disabled={disabled ? disabled : false}
        onChange={onChange}
        onBlur={(e) => onBlur && onBlur(e)}
        autoComplete="off"
      />
      {err && (
        <small className="text-danger text-capitalize">{formErrors[name]}</small>
      )}
    </div>
  );
};

export default TextInputField;