import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { IconAngleArrowDown, IconPlus, IconTrash } from "../../../../../Components/Common/Icons";
import DateTimePicker from "../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateTimePicker";
import { getStorage, DateTimeFormatUtils, toastr, isNegativeDriverPayAllowed } from "../../../../../services";
import moment from "moment";
import { radiusRate, radiusRateType } from "../../constants/chargeProfiles";
import SelectCustomComponent from "../../../SelectComponent";
import { NumberFormatterV2 } from "../../../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";

const AddRuleTable = ({ handleChange, newChargeProfile, isDisabledAllCustomerRateModal, formErrors, vendorType}) => {
  const [currentPickerType, setCurrentPickerType] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCell, setSelectCell] = useState(null);
  const [show,setShow] = useState(false);

  const tdRefs = useRef([]);
  const timezone = getStorage("timeZone");

  const defaultCharge = {
    minimumAmount: null,
    amount: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    unitOfMeasure: radiusRateType[0].value,
    freeUnits: null,
  }

  const handleShowDate = (type, index) => {
    setShow(true);
    setCurrentPickerType(type);
    setSelectedIndex(index);
  };

  const handleHideDate = () => {
    setShow(false);
    setCurrentPickerType(null);
  };

  const onValidateError = (id, selectedIndex, errorMsg) => {
    highlighTD(id, selectedIndex, false);
    toastr.show(errorMsg, "error");
  }

  const onValidateDateError = (id, selectedIndex, errorMsg) => {
    onChange(id, null, selectedIndex);
    onValidateError(id, selectedIndex, errorMsg)
  }

  const onChangeDate = (date) => {
    const charges = newChargeProfile?.charges;
    const selectedCharges = charges?.at(selectedIndex);
    const isStartDate = currentPickerType === "start";
    const selectDateName = isStartDate ? "effectiveStartDate" : "effectiveEndDate";

    // date validation
    const { effectiveStartDate, effectiveEndDate } = selectedCharges;
    const isEndDateBeforeStartDate = moment(!isStartDate ? date : effectiveEndDate).isBefore(isStartDate ? date : effectiveStartDate);

    if (isEndDateBeforeStartDate) {
      return onValidateDateError(selectDateName, selectedIndex, "End date must be after start date!")
    }

    const isInBetweenAnyCharges = charges?.some((charge, i) => {
      const { effectiveStartDate: a, effectiveEndDate: b } = charge;
      if (selectedIndex === i) return false;
      if (isStartDate && moment(date).isBefore(a) && moment(effectiveEndDate).isAfter(b)) return true;
      if (!isStartDate && moment(effectiveStartDate).isBefore(a) && moment(date).isAfter(b)) return true;
      return moment(date).isBetween(a, b) || (moment(date).isSame(a) || moment(date).isSame(b));
    });

    if (isInBetweenAnyCharges) {
      return onValidateDateError(selectDateName, selectedIndex, "Given date must not be in between above charges!");
    }

    // update the date state
    onChange(selectDateName, date, selectedIndex);
  };

  const highlighTD = (idName, index, value) => {
    const eleRef = document.getElementById(`${idName}${index}`);

    if (!eleRef) return;
    if (!value) eleRef.style.background = "#f7cfd3";
    else eleRef.style.background = "";
  }

  const handleAddCharge = () => {
    const { charges, unitOfMeasure } = newChargeProfile;
    const lastChargesRow = charges?.at(-1) ?? null;
    const lastRowIndex = charges?.length - 1;
    const isRadiusRate = radiusRate?.includes(unitOfMeasure);

    if (lastChargesRow) {
      let { startValue, endValue, effectiveStartDate, effectiveEndDate, minimumAmount, amount } = lastChargesRow;
      startValue = parseFloat(startValue);
      endValue = parseFloat(endValue);

      // startValue & endValue verification
      if (isRadiusRate && /undefined|null/?.test(startValue)) return onValidateError("startValue", lastRowIndex, "Start distance is required!");
      if (isRadiusRate && !endValue) return onValidateError("endValue", lastRowIndex, "End distance required!");
      if (isRadiusRate && (startValue >= endValue)) return onValidateError("startValue", lastRowIndex, "Start distance must be less than end distance!");
      if (isRadiusRate) {
        const isStartOrEndInvalid = charges?.some((charge, i) => {
          if (i === lastRowIndex) return false;
          const { endValue: b } = charge;
          return startValue <= b;
        });
        if (isStartOrEndInvalid) return onValidateError("startValue", lastRowIndex, "Start distance must be less than end distance of above charges!");
      }

      // required field verification
      if(isNaN(parseInt(amount))) return onValidateError("amount", lastRowIndex, "Amount is required!");

      // date verification
      if (!effectiveStartDate) highlighTD("effectiveStartDate", lastRowIndex, false);
      else highlighTD("effectiveStartDate", lastRowIndex, true);
      if (!effectiveEndDate) highlighTD("effectiveEndDate", lastRowIndex, false);
      else highlighTD("effectiveEndDate", lastRowIndex, true);

      if (newChargeProfile?.effectiveDateParameter && (!effectiveStartDate || !effectiveEndDate)) return;
      else if (!newChargeProfile?.effectiveDateParameter && !radiusRate.includes(newChargeProfile.unitOfMeasure)) return;
    }

    handleChange("charges", [...charges ?? [], defaultCharge]);
  };

  const handleRemoveCharge = (index) => {
    const _charges = newChargeProfile?.charges?.filter((d, i) => i !== index) ?? [];
    handleChange("charges", _charges);
  };

  useEffect(() => {
    const modalBodies = window.document.getElementsByClassName("modal-body");
    if (currentPickerType) {
      for (const modalBody of modalBodies) {
        modalBody.style.pointerEvents = "none";
      }
    } else {
      for (const modalBody of modalBodies) {
        modalBody.style.pointerEvents = "auto";
      }
    }
    return () => {
      for (const modalBody of modalBodies) {
        modalBody.style.pointerEvents = "auto";
      }
    };
  }, [currentPickerType, selectedIndex]);

  const onChange = (key, value, index) => {
    let _charges = [...newChargeProfile?.charges ?? []];
    _charges[index] = { ..._charges[index] ?? {}, [key]: value };

    highlighTD(key, index, value);
    handleChange("charges", _charges);
  }

  const handleKeyDown = (event) => {
    var keyCode = event.which || event.keyCode;

    // Allow digits, decimal point, and control keys like Backspace, Delete, etc.
    if (!(
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) || // Digits 0-9
      keyCode === 46 || // Decimal point
      keyCode === 8 || // Backspace
      keyCode === 37 || // Left arrow
      keyCode === 39 // Right arrow
    )) event.preventDefault();
  }

  return (
    <>
      <div className="my-2 font-16 font-medium text-capitalize">Add your pay amounts</div>
      <div className="d-flex flex-column">
        {
          Array.isArray(formErrors["charges"]) && formErrors["charges"]?.map((val, i) => (
            <small className="text-danger mb-2 text-capitalize" key={i}>{val}</small>
          ))
        }
      </div>
      <div className="card mb-0 bg-gray-50 p-1">
        {newChargeProfile && newChargeProfile?.charges?.length > 0 && (
          <table className="table table-card table-card-sm">
            <thead>
              <tr>
                {!radiusRate?.includes(newChargeProfile?.unitOfMeasure) && <th className="text-right">Minimum Amount</th>}
                {!radiusRate?.includes(newChargeProfile?.unitOfMeasure) && <th className="text-right">Free Units</th>}
                <th className="text-right">Amount</th>
                {
                  radiusRate?.includes(newChargeProfile?.unitOfMeasure) &&
                  <>
                    <th className="text-right">Start Distance</th>
                    <th className="text-right">End Distance</th>
                  </>
                }
                {
                  newChargeProfile?.effectiveDateParameter && (
                    <>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </>
                  )
                }
                {radiusRate.includes(newChargeProfile?.unitOfMeasure) && <th>Rate Type</th>}
                <th width="40px"></th>
              </tr>
            </thead>
            <tbody>
              {newChargeProfile?.charges && newChargeProfile?.charges?.map((charge, index) => {
                const isStartPickerVisible = show && currentPickerType === "start" && selectedIndex === index;
                const isEndPickerVisible = show && currentPickerType === "end" && selectedIndex === index;
                return (
                  <tr key={index} className="select-custom-input">
                    {!radiusRate?.includes(newChargeProfile?.unitOfMeasure) && <td className="p-0" id={`minimumAmount${index}`}>
                      <div className="custom-select-border">
                        <NumberFormatterV2
                          selected={true}
                          autoFocus={false}
                          min={isNegativeDriverPayAllowed() && vendorType === 'Driver' ? undefined : "0"}
                          disabled={isDisabledAllCustomerRateModal}
                          value={charge?.minimumAmount}
                          onChange={(e) => onChange("minimumAmount", e.target.value, index)}
                          className="form-control border-0 text-right bg-transparent px-2"
                          placeholder="0.00"
                          type="number"
                          showCurrency={false}
                          overridePlaceholder={true}
                          highlightOnSelect={true}
                        />
                      </div>
                    </td>}
                    {!radiusRate?.includes(newChargeProfile?.unitOfMeasure) && <td className="p-0">
                      <NumberFormatterV2
                        selected={true}
                        autoFocus={false}
                        min="0"
                        type="number"
                        className="form-control form-control--table text-right"
                        name="free-units"
                        placeholder="0.00"
                        value={charge?.freeUnits}
                        onKeyDown={handleKeyDown}
                        disabled={isDisabledAllCustomerRateModal}
                        onChange={(e) => onChange("freeUnits", e.target.value, index)}
                        showCurrency={false}
                        overridePlaceholder={true}
                        highlightOnSelect={true}
                      />
                    </td>}
                    <td className="p-0" id={`amount${index}`}>
                      <NumberFormatterV2
                        selected={true}
                        autoFocus={false}
                        min={isNegativeDriverPayAllowed() && vendorType === 'Driver' ? undefined : "0"}
                        disabled={isDisabledAllCustomerRateModal}
                        value={charge?.amount}
                        onChange={(e) => onChange("amount", e.target.value, index)}
                          className="form-control border-0 text-right bg-transparent px-2"
                        placeholder="0.00"
                        type="number"
                        showCurrency={false}
                        overridePlaceholder={true}
                        highlightOnSelect={true}
                      />
                    </td>
                    {
                      radiusRate?.includes(newChargeProfile?.unitOfMeasure) &&
                      <>
                        <td className="p-0" id={`startValue${index}`}>
                          <NumberFormatterV2
                            selected={true}
                            autoFocus={false}
                            min="0"
                            disabled={isDisabledAllCustomerRateModal}
                            value={charge?.startValue}
                            onChange={(e) => onChange("startValue", e.target.value, index)}
                            className="form-control border-0 text-right bg-transparent px-2"
                            placeholder="0.00"
                            type="number"
                            showCurrency={false}
                            overridePlaceholder={true}
                            highlightOnSelect={true}
                          />
                        </td>
                        <td className="p-0" id={`endValue${index}`}>
                          <NumberFormatterV2
                            selected={true}
                            autoFocus={false}
                            min="0"
                            disabled={isDisabledAllCustomerRateModal}
                            value={charge?.endValue}
                            onChange={(e) => onChange("endValue", e.target.value, index)}
                            className="form-control border-0 text-right bg-transparent px-2"
                            placeholder="0.00"
                            type="number"
                            showCurrency={false}
                            overridePlaceholder={true}
                            highlightOnSelect={true}
                          />
                        </td>
                      </>
                    }
                    {
                      newChargeProfile?.effectiveDateParameter && (
                        <>
                          <td onClick={() => !isDisabledAllCustomerRateModal && handleShowDate("start", index)} ref={isStartPickerVisible? tdRefs: null} id={`effectiveStartDate${index}`}>
                            {charge?.effectiveStartDate
                              ? moment(charge?.effectiveStartDate).tz(timezone).format(DateTimeFormatUtils.fullDateFormat())
                              : "-"}
                          </td>
                          {isStartPickerVisible &&
                            createPortal(
                              <DateTimePicker
                                onOpen={true}
                                OnBlurUpdate={handleHideDate}
                                onChange={onChangeDate}
                                displayTimeZone={timezone}
                                disabled={isDisabledAllCustomerRateModal}
                                value={charge?.effectiveStartDate}
                                datePickerCellRef={tdRefs}

                              />,document.body
                          )}
                          <td onClick={() => !isDisabledAllCustomerRateModal && handleShowDate("end", index)} ref={isEndPickerVisible ? tdRefs: null} id={`effectiveEndDate${index}`}>
                            {charge?.effectiveEndDate
                              ? moment(charge?.effectiveEndDate).tz(timezone).format(DateTimeFormatUtils.fullDateFormat())
                              : "-"}
                          </td>
                          {isEndPickerVisible &&
                            createPortal(
                              <DateTimePicker
                                onOpen={true}
                                OnBlurUpdate={handleHideDate}
                                onChange={onChangeDate}
                                displayTimeZone={timezone}
                                disabled={isDisabledAllCustomerRateModal}
                                value={charge?.effectiveEndDate}
                                datePickerCellRef={tdRefs}

                              />,document.body
                          )}
                        </>
                      )
                    }
                    {
                      radiusRate.includes(newChargeProfile?.unitOfMeasure) && (
                        <div className={` ${isDisabledAllCustomerRateModal ? 'disabled-pointer-events' : ''}`}>
                          <td className={`rate-type-${index} cell-1-${index} text-dark  ${selectedCell === `rate-type-${index}` ? 'td-clicked' : ""} pl-2 pr-1`}
                            style={{ transition: 'background .4s ease-out' }}
                            onClick={() => setSelectCell(`rate-type-${index}`)}
                          >
                            <div className="d-flex justify-content-between align-items-center text-dark min-width-100 ">
                              <span className="text-muted">{radiusRateType?.find(d => d.value === (charge?.unitOfMeasure || radiusRateType[0]?.value))?.label}</span>
                              <IconAngleArrowDown className='' />
                              {selectedCell === `rate-type-${index}` &&
                                <SelectCustomComponent
                                  name={`rate-type-${index}`}
                                  refClassName={`cell-1-${index}`}
                                  listData={radiusRateType}
                                  keyName="label"
                                  onSelect={(name, val) => {
                                    onChange("unitOfMeasure", val?.value, index)
                                    setSelectCell(null);
                                  }}
                                  unselectCell={setSelectCell}
                                  size="w-161"
                                  disabled={isDisabledAllCustomerRateModal}
                                />
                              }
                            </div>
                          </td>
                        </div>
                      )
                    }
                    <td>
                      <div className={` ${isDisabledAllCustomerRateModal ? 'disabled-pointer-events' : ''}`}>
                        <button className="btn btn-link pointer" onClick={() => handleRemoveCharge(index)}>
                          <IconTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        <div className={` ${isDisabledAllCustomerRateModal ? 'disabled-pointer-events' : ''}`}>
          <div className={`${!newChargeProfile?.charges?.length > 0 ? "text-center" : "mt-10"}`}>
            <button className="btn btn-white btn-sm text-primary" onClick={handleAddCharge}>
              <IconPlus className="mr-1" />
              Add New Charge
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRuleTable;
