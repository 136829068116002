import React, { useState } from "react";
import ReactDOM from "react-dom";

import ReactTooltip from "react-tooltip";
import moment from "moment";
import { DateTimeFormatUtils } from "../../../../../services";
import useCurrentUser from "../../../../../hooks/users/useCurrentUser";

const AppointmentDateTimeTo = ({ row }) => {
  const { currentUserTimeZone } = useCurrentUser();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="cell-content d-flex align-items-center justify-content-between">
        {row.requestedAppointmentDateTimeSlots && (
          <>
            {row.requestedAppointmentDateTimeSlots.length > 0 &&
              moment(row.requestedAppointmentDateTimeSlots[0]?.appointmentDateTimeEnd)
                .tz(currentUserTimeZone)
                .format(DateTimeFormatUtils.fullDateTimeFormat())}
            {row.requestedAppointmentDateTimeSlots.length > 1 && (
              <span
                className="badge badge-secondary ml-1"
                data-tip
                data-for={"to_id" + row._id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                +{row.requestedAppointmentDateTimeSlots.length - 1}
              </span>
            )}
            {isHovered &&
              ReactDOM.createPortal(
                <ReactTooltip id={"to_id" + row._id}>
                  {row.requestedAppointmentDateTimeSlots.slice(1).map((dateTime, index) => (
                    <div key={index} className="mt-1">
                      {moment(dateTime?.appointmentDateTimeEnd)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}
                    </div>
                  ))}
                </ReactTooltip>,
                document.getElementById("pp-tooltip")
              )}
          </>
        )}
      </div>
    </>
  );
};

export default AppointmentDateTimeTo;
