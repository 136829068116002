import React from 'react';
import { updateTerminology } from 'services';
const TabList = ( { id, label, icon, tab, setTab, Selected_customer } ) => {

  if (Selected_customer && !Selected_customer._id && id == 5) {
    return null;
  }

  return(
    <li className="nav-item">
      <a href="javascript:void(0)" className={`nav-link ${tab === id ? 'active' : ''}`} onClick={() => setTab(id)} >
        <span>{icon}</span>
        <div className="nav-link__text">{updateTerminology(label)}</div>
      </a>
    </li>
  )
}


export default TabList;
