import { getStorage } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams';
import _ from 'lodash';
import { TMS_UPDATE_STATE } from '../constant';

export const getCurrencyList = () => {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HTTP(
          "GET",
          "currency",
          null,
          {
            authorization: getStorage("token"),
          }
        );
  
        const data = response?.data?.data?.map((e) => {
          return {
            label: `${e.currencyCode} (${e.symbol})`,
            value: e._id,
          }
        });
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
}


export const getAddressExceptionsFromSystemConfig = (isDispatch = true) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const response = await HTTP(
        "GET",
        "system-config/get-address-exception",
        null,
        {
          authorization: getStorage("token"),
        }
      );

      const data = response?.data?.data;
      resolve(data);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });

  if (isDispatch) {
    return function(dispatch) {
      return promise;
    }
  } else {
    return promise;
  }

  
}

export function resetCustomerPassword(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'user/customer-resetpassword', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCountryCode() {
  let url = 'tms/getCountryCode';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
}

export function getTMSCustomerAudit(data, payload = {}) {
  let url = 'carrier/getTMSCustomerAudit';
  url = data ? url + "?" + objToQueryParams({ customerId: data, ...payload }) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSCustomerCount(data) {
  let url = 'carrier/getTMSCustomerCount';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function getCustomersByIDS(data) {
  let url = "carrier/getTMSCustomers";
  url = data ? url + "?" + jsonToQueryParams(data) : url;

  return new Promise((resolve, reject) => {
    HTTP("get", url, data, {
      'authorization': getStorage('token'),
    })
      .then(response => {
        const customers = _.get(response, "data.data", [])

        resolve(customers)
      })
      .catch(error => {
        console.log("---- getCustomersByIDS error -----", error)
        reject(error);
      })
  })
}


export function addCustomerTags(params) {
  let url = 'tms/addCustomerTag';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getCustomerTags(params) {
  let url = 'tms/getCustomerTags';
  if(params)
    url+="?"+jsonToQueryParams(params)
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, {},{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function listAllFleetManagers(params, limit) {
  let obj = { ...params, limit: 10 }

  if (limit && typeof(limit)==='number') {
    if (params){
      obj = { ...params, limit}
    } else {
      obj = { limit }
    }
  }else{
    delete obj.limit
  }

  let url = 'carrier/getFleetManagers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          let allDatas = result.data.data;
          if(limit && typeof(limit)==='number'){
              resolve(allDatas);
          }
          let fleetManagers = []
          allDatas.forEach((obj) => {
            fleetManagers.push({ value: obj._id, label: `${obj.name}`, allInfos: obj });
          });
          resolve(fleetManagers);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
 
export function getTMSCustomerById(params) {
  let url = 'carrier/getTMSCustomerById';
  url = params ? url + "?" + jsonToQueryParams(params) : url;

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          const data = result.data ? result.data.data :  {}

          dispatch({ type: TMS_UPDATE_STATE, payload: data })
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function getAllCustomers(params) {
  let url = 'carrier/getTMSCustomers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function assignCSRtoCustomer(params){
  let url = 'carrier/assignCSRtoCustomer';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data );
    })
    .catch((error) => {
      reject(error);
    });
  })
}
export function assignCSRtoLoad (params){
  let url = 'carrier/assignCSRtoLoad';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data );
    })
    .catch((error) => {
      reject(error);
    });
  })
} 

export function addTaxInformation(params){
  let url = 'tax';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data );
    })
    .catch((error) => {
      reject(error);
    });
  })
} 


export function getOrgByDomain(params) {
  let url = "org-by-domain";
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("GET", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFleetByUserId(params) {
  let url = "fleetmanager-by-userId";
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("GET", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        const data = result.data.data
        let fleetManagers = []
        for(const obj of data){
          fleetManagers.push({ value: obj._id, label: `${obj.name}`, allInfos: obj });
        }
        resolve(fleetManagers);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkIsConnectEnabled(){
  let url = 'master-customers';
  const params = {
    isEnabled: true,
    limit:10,
    skip: 0
  }
  url = url + "?" + jsonToQueryParams(params)
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result && result.data && result.data.data) resolve(result.data.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
} 

export function getPortMarkets(params) {
  let url = "setup/portMarkets";
  return new Promise((resolve, reject) => {
    HTTP(
      "get",
      url,
      params,
      {
        authorization: getStorage("token"),
      },
      url
    )
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPlaceDetails(data) {
  let url = 'place-details';
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function searchPlaces(data) {
  let url = 'search-places';
  if (!(data?.searchTerm || "")?.trim()) 
    return []

  data.searchTerm = data?.searchTerm?.trim()
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCityOptions(data) {
  let url = 'carrier/getCityOptions';
  if (!(data?.searchTerm || "")?.trim()) 
    return []

  data.searchTerm = data?.searchTerm?.trim()
  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getProfileLabels = (searchQuery = '') => {
  const url = 'profile-labels';
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let profileLabelsData = result?.data?.data || [];

        if (searchQuery) {
          profileLabelsData = profileLabelsData?.filter((label) => 
            label?.label?.toLowerCase().includes(searchQuery?.toLowerCase())
          );
        }

        const sortedProfileLabelData = profileLabelsData?.sort((a, b) => {
          if (a?.label && b?.label) {
            return a?.label?.localeCompare(b?.label);
          }
          return 0;
        });
        resolve(sortedProfileLabelData); 
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getProfileLabelValue = (profileLabel = '') => {
  const url = 'profile-label-tracking-status';

  return new Promise((resolve, reject) => {
    const queryUrl = profileLabel
      ? `${url}?profileLabel=${encodeURIComponent(profileLabel.trim())}`
      : url;

    HTTP('GET', queryUrl, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const profileLabelData = result?.data?.data || [];

        resolve(profileLabelData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function getCountriesByLocation(data) {
  let url = 'countries-by-location';

  url = data ? url + "?" + objToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
