import React, { useRef, useState, useEffect } from 'react'
import DateEditor from '../CellComponents/DateEditor';
import { formatToDateAndTime } from '../../Functions';
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import { useDispatch, useSelector } from 'react-redux';

const ReadyToReturn = ({ row, column, onRowChange, isCellSelected }) => {
	const [showBox, setShowBox] = useState(false);
	const cellRef = useRef();
	const prevSelectionRef = useRef();
  	const [isColorPortal, setColorPortal] = useState(false);
  	const dispatch = useDispatch();
  	const fAction = useSelector((state) => state.dispatcherTableReducer);

	const hideModel = () => {
		setShowBox(false);
	}
	const showModel = () => {
		setShowBox(true);
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel();
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected){
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.readyToReturnDate);
  }, [column, row?.colorOfCells?.readyToReturnDate]);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "readyToReturnDate") {
			onRowChange({ ...row, readyToReturnDate : fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])

	return (
		<>
			<div ref={cellRef} className="cell-content" onClick={() => showModel()} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
        <div className="rdg-truncate">
          {row.readyToReturnDate &&
            formatToDateAndTime(row.readyToReturnDate)
          }
          {!row.readyToReturnDate && <span data-testid="date-field"  className='date_field'></span>}
        </div>	
			</div>
			{row?.isEditPermission && showBox &&
				row.type_of_load !== "BILL_ONLY" && row.type_of_load !== "ROAD" && <DateEditor
				CellRef={cellRef}
				onClose={() => {
					hideModel();
					const a = document.querySelector('[aria-selected="true"]');
					if (a) a.focus();
				}}
				onOpen={showModel}
				row={row}
				column={column}
				onRowChange={onRowChange} />
			}
      {isColorPortal &&
        <ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="readyToReturnDate" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
      }
		</>
	)
}

export default ReadyToReturn;