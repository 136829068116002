import { useCallback, useMemo } from 'react';
import { changeFavoriteOrder } from "../actionCreator"
import { getStorage } from 'services';
import { toastr } from 'services';

// import { updateFavoriteFolders } from './folderService';

export const useFavoriteFolders = (favoriteFolders, setFavoriteFolders) => {

    const foldersMap = useMemo(() => {
        return favoriteFolders.reduce((acc, folder) => {
            acc[`${folder.id?.replace(/\s/g, '-')}-${folder.grantId}`] = true;
            return acc;
        }, {});
    }, [favoriteFolders]);

    const toggleFavoriteFolder = useCallback(async (folder, fromFavorites, setIsFavFolder, isFavFolder) => {
        const folderKey = `${folder.id?.replace(/\s/g, '-')}-${folder.grantId}`;
        let updatedFolders;

        if (foldersMap[folderKey]) {
            updatedFolders = favoriteFolders
                .filter(f => !(f.id === folder.id && f.grantId === folder.grantId))
                .map((f, index) => ({
                    ...f,
                    order: index
                }));
        } else {
            updatedFolders = [
                ...favoriteFolders,
                {
                    ...folder,
                    order: favoriteFolders.length
                }
            ];
        }

        const updatePayload = updatedFolders.map(({ id, name, order, grantId }) => ({
            id,
            name,
            order,
            grantId
        }));

        try {
            let grant;
            if(!grant){
                const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
                grant = embeddedEmailAccount?.accountId;
            }
            const favoriteFolders = updatePayload;
            await changeFavoriteOrder({ grant, favoriteFolders });
            // When favorite is being removed from the favorite section
            if (fromFavorites) {
                setFavoriteFolders(updatedFolders);
                return toastr.show("Folder has been removed from Favorites", "success");
            }
            // When favorite is being added or removed from the folders section
            if(!isFavFolder){
                const allConnectedMails = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? [];
                const matchedMail = allConnectedMails.find(mail => mail?.accountId === folder?.grantId);
                const accountName = matchedMail && matchedMail?.name;
                const provider = matchedMail && matchedMail?.provider;
                if (accountName) {
                    updatedFolders = updatedFolders.map(favorite => 
                        favorite?.grantId === folder?.grantId && favorite?.id === folder?.id ? { ...favorite, accountName: accountName, provider: provider } : favorite
                    );
                }
            }
            setFavoriteFolders(updatedFolders);
            if (fromFavorites) {
                return toastr.show("Folder has been removed from Favorites", "success");
            }
            setIsFavFolder && setIsFavFolder(prev => !prev);
            return toastr.show(isFavFolder ? "Folder has been removed from Favorites" : "Folder has been added to Favorites", "success");
        } catch (error) {
            setFavoriteFolders(favoriteFolders);
            console.error('Failed to update favorite folders:', error);
            return toastr.show("Something went wrong!", "error");
        }
    }, [favoriteFolders, foldersMap]);

    return { toggleFavoriteFolder };
};