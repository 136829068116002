import React, { useContext, useEffect, useRef, useState } from "react"
import Select from "react-select"
import { smallSelectStyleWhite } from "assets/js/select-style"
import { IconLink, IconPlus, IconReset, IconTimesThin, IconTimesThinClose, IconDisconnect, Icondots, IconAngleArrowDown, IconAI, IconConversation, IconInfo } from "Components/Common/Icons"
import { CustomIconFileInfo } from "Components/Common/CustomIcons/Index"
import * as action from "pages/tms/Dispatcher/actionCreators"
import { DateTimeFormatUtils, checkUserPermisison, getStorage, toastr, amplitudeTrack, updateTerminology } from "services"
import moment from "moment"
import { calculateLoadDistance, getLoadRoute } from "Components/CustomerServiceSideBar/actions"
import { streetTile } from "pages/trucker/NewTrack1/utility"
import * as actions from '../../../../Customer/actionCreators'
import ReactTooltip from "react-tooltip";
import { debounce } from "throttle-debounce";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import configuration from "../../../../../../config";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import AddNewLoad from "pages/tms/Load/AddNewLoad";
import { Modal } from "react-bootstrap";
import Port from "Components/CustomerServiceSideBar/Tabs/Components/Port"
import DeliveryWarehouse from "Components/CustomerServiceSideBar/Tabs/Components/DeliveryWarehouse";
import _ from 'lodash';
import { getTMSLoads } from "pages/tms/NewDispatcher/actionCreator"
import PeopleBlock from "../PeopleBlock"
import { connectToLoads, disconnectToLoads, getLoadsByThreadId, addEmailAssignee, removeEmailAssignee, getEmailAssignees } from "../../../actionCreator"
import { CustomIconMultiReport } from "Components/Common/CustomIcons/Index"
import LoadInfoModal from '../../../../../../Components/LoadInfoModal/LoadInfoModal';
import confirm from "Components/Common/ConfirmAert"
import EmailToolTip from "../EmailToolTip"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, DO_EXTRACTION_STATUS, EMAIL_CONTEXT, PERMISSION_ERROR } from "../../../constant"
import QuoteSidebar from "./QuoteSidebar"
import { isEmbeddedEmailConnected, isExternalEmail, isGenAIEnabled } from "../../../helper"
import AddEditQuote from "../../../../Load/Components/AddEditQuoteModal";
import { useEmailTags } from "../../../hooks"
import NewOrderReviewModal from "../NewOrderReview/NewOrderReviewModal"
import SidebarHeader from "../SidebarHeader"
import AIConversationSummary from "./AIConversationSummary"
import AssignedReps from "./AssignedReps"
import { IconInfoFocus, IconTaskDetail } from "../../../../../../Components/Common/Icons"
import ConnectedLoad from "./ConnectedLoad"
import config from "../../../../../../config";
import { getLoadDetailFromRedis, lastUpdatedLoadChannel } from "../../../../../../services/common"

const firebase = config.getNewFirebaseInstance;
const db = firebase('connected-load').database();

const polyline = require("@mapbox/polyline");
mapboxgl.accessToken = configuration.map_box_api_key;
let routePoints = [];

const ValueBlock = ({value}) => {
  return (
    <div className="font-medium">{value ? value : <span className="text-muted">-</span>}</div>
  )
}

const ModalLoadSidebar = (props) => {
  const {
    rowData,
    allEmailFromInitials,
    handleRowClick,
    emailPeople,
    setIsLoading,
    isLoading,
    getEmailPeople,
    removeThread,
    isFromLoad,
    peopleLoader,
    isLoadDetected,
    draftloadEmails,
    updateDraftloadEmails,
    isEmailMode,
    threadUpdates,
    getReply,
    openRowThreadId,
    _getDraftLoadEmailInfo,
    setAllFleetUsers,
    allFleetUsers,
    setIsLoadDetected
  } = props;
  const [hasLoadinfo, setHasLoadInfo] = useState(null);
  const [addNewLoad, setAddNewLoad] = useState(false);
  const [relatedLoads, setRelatedLoads] = useState([]);
  const [addNewQuote, setAddNewQuote] = useState(false);
  const [selectedLoad, setSelectedLoad] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [AllCSRList, setAllCSRList] = useState([]);
  const [isConnectToLoad, setIsConnectToLoad] = useState(false);
  const [isConnectToQuote, setIsConnectToQuote] = useState(false);
  const [searchLoad, setSearchLoad] = useState("");
  const [searchQuote, setSearchQuote] = useState("");
  const [allLoads, setAllLoads] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);
  const [_selectedLoads, _setSelectedLoads] = useState([]);
  const [showLoadModel, setShowLoadModel] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState('loads');
  const [showNewOrderModal, setShowNewOrderModal] = useState(false)
  const [loadingAssignCSR, setLoadingAssignCSR] = useState([])
  const [connectedLoads, setConnectedLoads] = useState([])
  const [connectedLoadsDetails, setConnectedLoadsDetails] = useState([])
  const [token, setToken]= useState(null)
  const context = useContext(EMAIL_CONTEXT)
  const { currentOpenLoad, updateTagInOneMail } = context
  const loadRef = useRef(null);
  const [allUsers, setAllUsers] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [assigneeLoading, setAssigneeLoading] = useState(false);
  const canUseGenAI = isGenAIEnabled() 
  const [isExtractingDO, setIsExtractingDO] = useState(false)
  const [isActive, setIsActive] = useState(false);

  let deliveryDetectedFB 

  const subToFireBase = () => {
    if(!openRowThreadId) return
    const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount') ?? "{}")
    const carrierDetail = JSON.parse(getStorage("carrierDetail") ?? '{}')

    const { accountId } = embeddedEmailAccount
    const { _id: companyId } = carrierDetail
    const channelName = `email/delivery-order-detected/${companyId}/${accountId}/${openRowThreadId}`
    
    deliveryDetectedFB = db.ref(channelName)
    if (deliveryDetectedFB) {
      deliveryDetectedFB.on("value", async (snapshot) => {
        let data = snapshot.val()
        if (!data) {
          setIsExtractingDO(false)
          return
        }
        data = JSON.parse(data ?? '{}')
        const { threadId, status, updatedAt } = data
        if ((updatedAt && moment().diff(moment(updatedAt), 'minutes') <= 10) && (threadId && (threadId === openRowThreadId))) {
          if (status === DO_EXTRACTION_STATUS.RUNNING) {
            setIsExtractingDO(true)
          } else if ([DO_EXTRACTION_STATUS.DONE, DO_EXTRACTION_STATUS.ALL_LOAD_CREATED].includes(status)) {
            await _getDraftLoadEmailInfo(threadId)
            setIsExtractingDO(false)
          }
        } else {
          setIsExtractingDO(false)
        }
      })
    }
  }

  const unSubToFireBase = () => {
    if(deliveryDetectedFB){
      deliveryDetectedFB?.off("value")
    }
  }

  const getEmailAssigneesList = () => {
    
    getEmailAssignees(rowData?.message?.threadId).then((res) => {
      setAssignees(res);
      setAssigneeLoading(false);
    }).catch((err) => {
      console.log(err);
      setAssigneeLoading(false);
    })
  }


  const addAssignee = (assignee) => {
    setAssigneeLoading(true);
    const formData = new FormData();
    formData.append('threadId', rowData?.message?.threadId);
    formData.append('userId', assignee.value);
    addEmailAssignee(formData).then((res) => {
      if(res?.assigneeId && !assignees?.find(user => user.assigneeId == res.assigneeId)){
        setAssignees(prev =>  [...prev, res])
      }
       setAssigneeLoading(false);
    }).catch((err) => {
      console.log(err);
      setAssigneeLoading(false);
    })
  }
  const removeAssignee = (assignee) => {
    setAssigneeLoading(true);
    const params = {threadId : rowData?.message?.threadId, assigneeId: assignee.assigneeId}
    removeEmailAssignee(params).then((res) => {
      setAssignees(prev => prev.filter((user) => user.assigneeId !== assignee.assigneeId))
      setAssigneeLoading(false);
    }).catch((err) => {
      console.log(err);
      setAssigneeLoading(false);
    })
  }
  
  useEffect(()=>{
    setIsActive(false)
    if(rowData?.message?.threadId){
      getRefNumberByThread()
      getEmailAssigneesList();
      subToFireBase()
    }
    return () => {
      unSubToFireBase()
      if(setIsLoadDetected){
        setIsLoadDetected(false)
      }
    };
  },[rowData?.message])

  useEffect(()=>{
    listAllCSR();
  },[])


  const [isLoader, setIsLoader] = useState(false);
  const isEmailConnected = isEmbeddedEmailConnected()

  const canSeeLoad = checkUserPermisison(["customer_service_load_info"])

  const getRefNumberByThread = () => {
    const param = {}
    if (!isEmailConnected) param.skipMiddleWare = true
    const threadId = rowData?.message?.threadId
    setIsloading(true)
    getLoadsByThreadId(threadId, param).then((data) => {
      setHasLoadInfo(data?.load);
      setRelatedLoads(data?.load?.referenceNumbers?.length > 0 ? data?.load?.referenceNumbers?.map((d, i) => ({ label: d, value: d, index: i + 1 })) : [])
      setConnectedLoads(data?.load?.connectedLoadDetails)
      getConnectedLoadDetails(data?.load?.referenceNumbers)
      setIsloading(false)
    }).catch((err) => {
      setIsloading(false)
      console.log("err", err)
    })
  }

  const _getLoadDetails = (reference_number) => {
    if(hasLoadinfo) {
      props.action.getLoadDetail(reference_number).then((res) => {
        setSelectedLoad(res)
        setIsLoading(false);
      })
    }
  }

  const assignCSRtoLoad = (_id, csrList, isRemove, name ) => {
    setLoadingAssignCSR(csrs => [...csrs,_id])
    const formData = new FormData()
    formData.append('assignedCSR', JSON.stringify(csrList))
    formData.append('loadId', _id)
    actions.assignCSRtoLoad(formData).then(result => {
      if (result && result.assignedCSR) {
        toastr.show(`${name ?? "CSR"} ${isRemove ? "removed" : "assigned"} successfully`, 'success')
        const connectedLoadDetails = [...connectedLoadsDetails].map((item) => {
          if(item._id === _id){
            item.assignedCSR = result.assignedCSR
          }
          return item
        })
        setConnectedLoadsDetails(connectedLoadDetails)
      }
      setLoadingAssignCSR(csrs => csrs.filter((csr) => csr !== _id))
    })
    .catch((err) => setIsloading(false))
  }

  const listAllCSR = (search) => {
    let data = {
      active: true,
      isIncludeCarrier : true
    };
    if (search) {
      data.searchTerm = search
    }
    return new Promise((resolve, reject) => {
      props.actions.listAllFleetManagers(data).then((options) => {
        const CSR = [];
        const users = [];
        let allDatas = options || [];

        allDatas.forEach((obj) => {
          users.push({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj.allInfos,
          })
          if(obj?.allInfos?.fleetManager?.CSR){
            CSR.push({
              value: obj.value,
              label: obj.allInfos.name + " " + obj.allInfos.lastName,
              allInfos: obj.allInfos,
            });
          }
        });
        setAllCSRList(CSR);
        setAllUsers(users);
        if(!allFleetUsers?.length) {
          setAllFleetUsers(users);
        }
        resolve(options);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  const handleOpenNewLoad = () => {
    setAddNewLoad(true); 
  }
  const handleCloseNewLoad = () => {
    setAddNewLoad(false); 
  }

  const handleCloseNewQuote = () => {
    setAddNewQuote(false); 
  }

  const handleSearchLoad = _.debounce(() => {
    const params = searchLoad;
    _getLoads(params);
  }, 500);

  useEffect(() => {
    if (searchLoad === null || searchLoad === '') return;
    handleSearchLoad();
    return () => {
      handleSearchLoad.cancel();
    }
  }, [searchLoad]);

  const handleSearchQuote = _.debounce(() => {
    const params = searchQuote;
    _getLoads(params);
  }, 500);

  useEffect(() => {
    if (searchQuote === null || searchQuote === '') return;
    handleSearchQuote();
    return () => {
      handleSearchQuote.cancel();
    }
  }, [searchQuote]);

  const _getLoads = (searchData) => {
    getTMSLoads({searchTerm: searchData}).then((res) => {
      let uniqData = [...new Map(res?.data.concat(_selectedLoads).map(item => [item["_id"], item])).values()]
      setAllLoads(uniqData)
      let valueData = uniqData?.filter((d) => relatedLoads.map((dd) => dd.value).includes(d.reference_number));
      _setSelectedLoads([..._selectedLoads, ...valueData]);
    }).catch((err) => {
      console.log(err);
    })
  }

  const addLoad = (load) => {
    let loads = [..._selectedLoads];
    loads.push(load);
    _setSelectedLoads(loads);
  };

  const removeLoad = (load) => {
    let loads = _selectedLoads && _selectedLoads.filter((dd) => dd._id !== load._id);
    _setSelectedLoads(loads);
  };
    const setGetLoadsByThreadVals = (data)=>{
      setHasLoadInfo(data?.load);
      setRelatedLoads(data?.load?.referenceNumbers?.length > 0 ? data?.load?.referenceNumbers?.map((d, i) => ({label: d, value: d, index: i + 1 })) : [])
      if(data?.load?.referenceNumbers?.[0]) _getLoadDetails(data?.load?.referenceNumbers?.[0])
    }
  const handleConnect = (reference_number) => {
    const uniqueData = _selectedLoads?.map(load => load.reference_number).filter(refNumber => !relatedLoads.map(load => load.value).includes(refNumber))
    let params = {
      reference_number: reference_number ? [reference_number] : uniqueData,
      thread_id: rowData?.message?.threadId,
      accountId: rowData?.message?.grantId
    }
    if(!params?.reference_number?.length) {
      return toastr.show("Please select the reference number", "error");
    }
    if(!isEmailConnected) params.skipMiddleWare = true
    setIsLoader(true)
    connectToLoads(params).then((res) => {
      if(res) {
        getRefNumberByThread()
        toastr.show("Loads connect successfully", "success");
        let eventProperties = {
          source: hasLoadinfo.count === 0 ? (isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL) : isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODAL_PLUS_ICON : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODAL_PLUS_ICON,
          loadCount: reference_number ? 1 : uniqueData.length
        }
        if(reference_number){
          amplitudeTrack(AMPLITUDE_EVENTS.ADD_CONNECT_LOAD , eventProperties)
        }
        else{
          amplitudeTrack(AMPLITUDE_EVENTS.CONNECT_LOAD , eventProperties)
        }
        setIsConnectToLoad(false);
        setIsLoader(false)
        setSearchLoad("");
        setAllLoads([]);
        handleCloseNewLoad()
        updateTagInOneMail(res?.success[0]?.threadId)
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
        handleCloseNewLoad()
      });
  }


  const handleDisconnect = (loadDetail, event, eventProperties) => {
    confirm(
      "Disconnect Load",
      "Are you sure you want to disconnect the load?",
      (value) => {
        if (value) {
          let params = {
            id: loadDetail._id,
            threadIds: [rowData?.message?.threadId]
          }
          const isExternalEmailMessage = isExternalEmail(rowData?.message?.grantId)
          if(isExternalEmailMessage) params.isExternalMessage = true
          if(!isEmailConnected) params.skipMiddleWare = true
          setIsLoading(true)
          disconnectToLoads(params).then((res) => {
            toastr.show("Load disconnected successfully.", "success");
            setIsLoading(false)
            if(currentOpenLoad === loadDetail?.reference_number && isFromLoad){
              removeThread(rowData?.message?.threadId)
            } else {
              removeLoad(selectedLoad)
              getRefNumberByThread()
              updateTagInOneMail(rowData?.message?.threadId)
            }
            if(event && eventProperties){
              amplitudeTrack(event,eventProperties)
            }
          }).catch((err) => {
            console.log(err);
            setIsLoading(false)
          })
        }
      }
    )
    
  }

  const isSameArrays = () => {
    let array1 = relatedLoads?.map((d) => d.value);
    let array2 = _selectedLoads?.map((d) => d.reference_number)
    return array1.length === array2.length && array1.every((value, index) => array2.find((d) => d === value));
  };

  const handleCheckBoxInput = (checked, load) => {
    if (checked) {
      addLoad(load)
    } else {
      removeLoad(load)
    }
  };

  const handleShowNewOrderModal= () => {
    setShowNewOrderModal(true);
  }
  
  const handleHideNewOrderModal= () => {
    setShowNewOrderModal(false);
  }

  const handleActiveTab = (tab) => {
    setIsActiveTab(tab)
  }




  const getConnectedLoadDetails = (referenceNumbers) => {
    if(referenceNumbers?.length === 0) return;
    const query = {reference_numbers : referenceNumbers};
    getTMSLoads(query).then((res) => {
       setConnectedLoadsDetails(res?.data)
    })
  }

  const isQuotesTab = isActiveTab === "quotes";
  const isLoadsTab = isActiveTab === "loads";

  const subscribeToLoadChange = async () => {
    loadRef.current = db.ref(lastUpdatedLoadChannel());

    loadRef.current.on("value", (snapshot) => {
      try {
        const data = snapshot.val()
        const load = JSON.parse(data);
        const loadIndex = connectedLoadsDetails?.findIndex((l) => l?.reference_number === load?.reference_number);
        if(loadIndex > -1 && token !== load.token && moment().diff(moment(load.updatedAt), "seconds") <= 10){
          setToken(load.token);
          getLoadDetailFromRedis(load.reference_number, load).then((res) => {
            if(res){
              const connectedLoadDetail = [...connectedLoadsDetails];
              if(!_.isEqual(connectedLoadDetail[loadIndex], res)){
                connectedLoadDetail[loadIndex] = res;
                setConnectedLoadsDetails(connectedLoadDetail)
              }
              
            }
          });
        }
      } catch(e) {
        console.log("Tracking exception: ", e);
      }
    });
  }
 
  useEffect(()=>{
    subscribeToLoadChange()
    return () => {
      loadRef?.current?.off?.();
    }
  },[connectedLoadsDetails])

  useEffect(() => {
    if(threadUpdates &&  rowData?.message?.threadId === threadUpdates?.threadId && moment().diff(moment(threadUpdates?.updatedAt), 'seconds')<=10){
        if(threadUpdates?.unassigned){
          setAssignees(prev => prev.filter((assignee) => assignee.assigneeId !== threadUpdates.unassigned))
        } 
        if(threadUpdates?.assigned){
          if(assignees?.findIndex((assignee) => assignee.assigneeId === threadUpdates.assigned) === -1){
             getEmailAssigneesList();
          }
        }
     }
  },[threadUpdates])
return (
    <div className="bg-white w-100 d-flex flex-column gap-10 overflow-y-auto light-scrollbar">
      <div className="pt-10 position-sticky top-0 z-4 gap-10 d-flex flex-column bg-white">
      {canUseGenAI && 
        <AIConversationSummary
          threadId={rowData?.message?.threadId}
          grantId={rowData?.message?.grantId}
          rowData={rowData?.message}
          isEmailMode={isEmailMode}
          isActive={isActive}
          setIsActive={setIsActive}
        />
      }
      {(!isExtractingDO && isLoadDetected && canSeeLoad && canUseGenAI) && <div className="border-1 border-primary rounded-5 p-10 mx-10">
        <div className="d-flex align-items-center">
          <div className="font-14 font-medium line-height-20">New Tender Detected</div>
          <IconAI className="ml-auto text-primary" />
        </div>
        <div className="text-muted">We created a Load Tender for you automatically.</div>
        <button className="btn btn-primary btn-block mt-10" onClick={()=>{
          handleShowNewOrderModal();
          let eventProperties = {
            source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL 
          }
          amplitudeTrack(AMPLITUDE_EVENTS.REVIEW_TENDER,eventProperties)
        }} ><IconPlus className="mr-3" />Review Load Tender</button>
      </div>}
      {(isExtractingDO && canSeeLoad && canUseGenAI) && <div className="border-1 border-primary rounded-5 p-10 mx-10">
        <div className="d-flex align-items-center">
          <div className="font-14 font-medium line-height-20">Tender Extraction Processing</div>
          <IconAI className="ml-auto text-primary" />
        </div>
        <div className="text-muted">Please wait while we process the email delivery order</div>
        <button className="btn btn-primary btn-block mt-10" disabled  >Processing...</button>
      </div>}

      <AssignedReps
          allUsers={allUsers}
          assignees={assignees}
          listAllCSR={listAllCSR}
          isEmailMode={isEmailMode}
          addAssignee={addAssignee}
          removeAssignee={removeAssignee}
          isLoading={assigneeLoading}
          isEmailConnected={isEmailConnected}
      />
     
      <ul className="nav nav-compact border-0 mx-10">
      <li className="nav-item active w-50 text-center">
        <a
          className={`nav-link ${isLoadsTab ? "active" : ""}`}
          onClick={() => handleActiveTab("loads")}
          data-tip="Loads"
          data-for="tab-tooltip"
        >
          <IconInfoFocus />
        </a>
      </li>
      <li className="nav-item w-50 text-center">
        <a
          className={`nav-link ${isQuotesTab ? "active" : ""}`}
          onClick={() => handleActiveTab("quotes")}
          data-tip="Quotes"
          data-for="tab-tooltip"
        >
          <IconTaskDetail />
        </a>
      </li>
      <EmailToolTip toolTipId={"tab-tooltip"} width="auto" />
    </ul>
    </div> 
      <div className="bg-gray-50">
      {isQuotesTab &&
        <QuoteSidebar threadId={rowData?.message?.threadId} rowData={rowData} isEmailMode={isEmailMode} />
      }
      {isLoadsTab && Object.keys(hasLoadinfo ?? {})?.length > 0 && 
      <>
      {hasLoadinfo?.count === 0 && canSeeLoad ? (
        <>
        <div className="p-10 bg-white rounded-5 m-1 d-flex flex-column gap-10 text-center align-items-start justify-content-center">
        <div className="font-14 font-medium">Load</div>
          <div className="font-14 text-muted">Not connected to any <b>Loads</b> yet</div>
          <button className="btn btn-success w-100 justify-content-center" onClick={handleOpenNewLoad}>
            <IconPlus className="mr-10" />
            Add New Load
          </button>
          <button className="btn btn-outline-light w-100 justify-content-center" 
            onClick={() => {
              setIsConnectToLoad(true);
            }}
          >
            <IconLink className="mr-10" />
            Connect to Load
          </button>
        </div>
        </>
      ) : (
        <>   
          <ConnectedLoad 
              connectedLoads={connectedLoads}
              connectedLoadDetails={connectedLoadsDetails}
              listAllCSR={listAllCSR}
              AllCSRList={AllCSRList}
              assignCSRtoLoad={assignCSRtoLoad}
              loadingAssignCSR={loadingAssignCSR}
              handleDisconnect={handleDisconnect}
              setIsConnectToLoad={setIsConnectToLoad}
              handleOpenNewLoad={handleOpenNewLoad}
              setShowLoadModel={setShowLoadModel}
              isEmailMode={isEmailMode}
          />
          
        </>
      )}
   
      {showNewOrderModal && 
        <NewOrderReviewModal show={showNewOrderModal} hide={handleHideNewOrderModal} draftLoadEmails={draftloadEmails} rowData={rowData} getRefNumberByThread={getRefNumberByThread} updateDraftloadEmails={updateDraftloadEmails} setGetLoadsByThreadVals={setGetLoadsByThreadVals} emailPeople={emailPeople} isEmailMode={isEmailMode} updateTagInOneMail={updateTagInOneMail} getReply={getReply}/>
      }      
      </>
      }
      </div>
      {addNewLoad ?
        <Modal show={addNewLoad} bsSize="sm" dialogClassName="modal-dialog-scrollable" animation="false">
          <Modal.Header>
            <h4 className="modal-title" id="myModalLabel">
              Create Load
            </h4>
          </Modal.Header>
          <AddNewLoad closeModal={handleCloseNewLoad} isEmailLoadCreate={true} handleConnect={handleConnect} />
        </Modal>
      : "" }
      
      { addNewQuote && <AddEditQuote onClose={handleCloseNewQuote} /> }

      {isConnectToLoad && (
        <Modal show={isConnectToLoad} centered animation={false} dialogClassName="modal-dialog-scrollable" backdropClassName="z-1050">
          <Modal.Header className="py-20 px-30 flex-column align-items-start">
            <Modal.Title>
              Connect to Load
            </Modal.Title>
              {/* <div className="form-check form-switch form-switch-md mt-1">
                <input type="checkbox" name="show-loads-casco" class="form-check-input" />
                <label class="mb-0 ml-2">Show Loads for CASCO Shipping only</label>
              </div> */}
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="form-wrapper bg-gray-50 rounded-5 p-1">
            <div className="form-group mb-0 position-sticky top-0 bg-white mb-1 z-2">
                {/* Search Loads */}
                <div className="app-search rounded-5">
                  <input
                    type="text"
                    className="input-search-left form-control py-2 input-search-wactive"
                    placeholder="Start typing Load #, Customer, BOL #, Container #, Booking # or Reference #"
                    value={searchLoad}
                    onChange={(e) =>{
                      setSearchLoad(e.target.value)
                      if(e.target.value =="") {
                        setAllLoads([]);
                      }
                    }}
                  />
                  <span className="search-icon ml-1"></span>
                </div>
              </div>
              <div className="table-responsive overflow-y-auto" style={{height: `${allLoads?.length > 0 ? 'calc(100vh - 565px)' : 'auto'}`}}>
                <table className="table table-card" >
                  <thead>
                    <tr>
                      <th></th>
                      <th>Load #</th>
                      <th>Load Customer</th>
                      <th>BOL #</th>
                      <th>Container #</th>
                      <th>Booking #</th>
                      <th>Reference #</th>
                    </tr>
                  </thead>
                  <tbody>
                  {allLoads.map((load, index) => {
                    return (
                      <tr>
                        <td>
                          <div className='d-flex align-items-center justify-content-center'>
                            <input
                              className="form-check-input rounded-3 border-secondary"
                              id={`check${index}`}
                              type="checkbox"
                              onClick={(e) => {
                                handleCheckBoxInput(e.target.checked, load)
                              }}
                              checked={_selectedLoads?.map((d) => d._id).includes(load._id)}
                              disabled={relatedLoads?.map((d) => d.value).includes(load.reference_number)}
                            />
                          </div>
                        </td>
                        <td>{load?.reference_number}</td>
                        <td>{load?.caller?.company_name}</td>
                        <td>{load?.callerbillLandingNo || "-"}</td>
                        <td>{load?.containerNo || "-"}</td>
                        <td>{load?.bookingNo || "-"}</td>
                        <td>{load?.secondaryReferenceNo || "-"}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </div>
              {allLoads?.length === 0 &&
              <div className="text-center">
                <CustomIconMultiReport />
              </div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="">
              <button
                className="btn btn-close mr-10"
                onClick={() => {
                  setIsConnectToLoad(false);
                  setSearchLoad("");
                  setAllLoads([]);
                  _setSelectedLoads([]);
                }}
                type="button"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleConnect()}
                disabled={isSameArrays() || isLoader}
              >
                {isLoader && <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"></span>
                }
                Connect
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      
      {
        isConnectToQuote && (
          <Modal show centered animation={false} dialogClassName="modal-dialog-scrollable" backdropClassName="z-1050">
            <Modal.Header className="py-20 px-30 flex-column align-items-start">
              <Modal.Title>Connect to Quote</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-wrapper bg-gray-50 rounded-5 p-1">
                <div className="form-group mb-0 position-sticky top-0 bg-white mb-1">
                  <div className="app-search rounded-5">
                    <input
                      type="text"
                      className="input-search-left form-control py-2 input-search-wactive"
                      placeholder="Start typing Load #, Customer, BOL #, Container #, Booking # or Reference #"
                      value={searchLoad}
                      onChange={(e) => {
                        setSearchQuote(e.target.value);
                        if (e.target.value == "") setAllQuotes([]);
                      }}
                    />
                    <span className="search-icon ml-1"></span>
                  </div>
                </div>
                <div className="table-responsive overflow-y-auto" style={{ height: `${allLoads?.length > 0 ? 'calc(100vh - 565px)' : 'auto'}` }}>
                  <table className="table table-card" >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Load #</th>
                        <th>BOL #</th>
                        <th>Container #</th>
                        <th>Booking #</th>
                        <th>Reference #</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allQuotes.map((load, index) => {
                        return (
                          <tr>
                            <td>
                              <div className='d-flex align-items-center justify-content-center'>
                                <input
                                  className="form-check-input rounded-3 border-secondary"
                                  id={`check${index}`}
                                  type="checkbox"
                                  onClick={(e) => {
                                    handleCheckBoxInput(e.target.checked, load)
                                  }}
                                  checked={_selectedLoads?.map((d) => d._id).includes(load._id)}
                                  disabled={relatedLoads?.map((d) => d.value).includes(load.reference_number)}
                                />
                              </div>
                            </td>
                            <td>{load?.reference_number}</td>
                            <td>{load?.callerbillLandingNo || "-"}</td>
                            <td>{load?.containerNo || "-"}</td>
                            <td>{load?.bookingNo || "-"}</td>
                            <td>{load?.secondaryReferenceNo || "-"}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                {allQuotes?.length === 0 &&
                  <div className="text-center">
                    <CustomIconMultiReport />
                  </div>
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="">
                <button
                  className="btn btn-close mr-10"
                  onClick={() => {
                    setIsConnectToQuote(false);
                    setSearchQuote("");
                    setAllQuotes([]);
                    _selectedLoads([]);
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleConnect()}
                  disabled={isSameArrays() || isLoader}
                >
                  {isLoader && <span
                    className="spinner-border spinner-border-sm mr-2"
                    role="status"
                    aria-hidden="true"></span>
                  }
                  Connect
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )
      }

      {showLoadModel && (
        <LoadInfoModal
          openWithTab={"messaging"}
          refNumber={showLoadModel}
          hideLoadModal={() => {
            handleRowClick(rowData?.message, true, rowData)
            setShowLoadModel(false);
          }}
        />
      )} 
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: dispatch,
    actions: bindActionCreators(actions, dispatch),
    action: bindActionCreators(action, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ModalLoadSidebar);
