import { getStorage, showForTerminal, updateTerminology } from "services"
import BarChartCard from "./Components/BarChart/index"
import CounterCard from "./Components/Common/CounterCard"
import LineChart from "./Components/LineChart/index"
import ListTable from './Components/ListTable'
import ExpandedPieDonutChart from './Components/PieDonutChart'
import CustomStackedBarChart from "./Components/StackedBarChart"
import { IconFile, IconFire, IconFlash, IconHighway, IconLiquor, IconMapMarker, IconSnowFlake, IconStreetTurn, IconWarning, IconWeightTool } from "Components/Common/Icons"
import { LOAD_LABELS } from "labels"


export const REPORT_NAMES = {
    // Admin
    CREATED_LOADS: "Created Loads",
    OPEN_LOADS: "Open Loads",
    PICKED_UP: "Picked Up",
    DELIVERED: "Delivered",
    RETURNED: "Returned",
    LOADS_BY_TYPE: "Loads By Type",
    LOADS_BY_CUSTOMER: "Loads By Customer",
    TOP_DELIVERY_LOCATIONS: "Top Delivery Locations",
    TOP_RETURN_LOCATIONS: "Top Return Locations",
    TOP_PICK_UP_LOCATIONS: "Top Pick Up Locations",
    COMPLETED_LOADS: "Completed Loads",

    // Broker
    BROKER_TENDERS_ACCEPTED:"Tenders Accepted",
    BROKER_TENDERS_REJECTED: "Tenders Rejected",
    BROKER_INVOICES_RECEIVED_BY_CARRIER: "Invoices Received by Vendor",
    BROKER_TENDERS_BY_CARRIER: "Tenders by Vendor",
    BROKER_LOAD_COMPLETED_NOT_INVOICED: "Completed, Not Invoiced",
    
    // Employees
    DRIVER_PAY_APPROVES: "Driver Pay Approved",
    DRIVER_SETTLEMENTS_FINALIZED: "Driver Settlements Finalized",
    // Track
    TRACK_DWELL_TIME: "Dwell Time at Port",
    TRACK_NO_OF_APPTS: "Number of Appts",
    TRACK_LAST_FREE_DAY_PERCENTAGE: "LFDs Percentage",
    TRACK_PER_DIEM_PERCENTAGE: "Per Diem Percentage",
    TRACK_DEMURRAGE_TOTAL: "Demurrage Totals",
    TRACK_PER_DIEM_TOTAL: "Per Diem Totals",
    TRACK_NUMBER_OF_TIRS: "Number of TIRs Uploaded",
    // Financials
    FINANCIAL_DAYS_TO_INVOICE: "Days to Invoice",
    FINANCIAL_NUMBER_OF_INVOICES: "Number of Invoices",
    FINANCIAL_TOTAL_PROFIT: "Total Profit",
    FINANCIAL_GROSS_DRIVER_PAY: "Gross Driver Pay",
    FINANCIAL_GROSS_VENDOR_PAY: "Gross Vendor Pay",
    FINANCIAL_AVERAGE_MARGIN: "Average Margin",
    FINANCIAL_REVENUE_BY_LOAD_TYPE: "Revenue by Load Type",
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "Revenue by Delivery Location",
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "Most Profitable Delivery Locations",
    FINANCIAL_REVENUE_BY_CHARGE_NAME: "Revenue by Charge Name",
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "Most Profitable Customers Percentage",
    FINANCIAL_PAST_DUE_INVOCES: "Past Due invoices",
    FINANCIAL_TOP_EARNING_DRIVERS: "Top Earning Drivers",
    FINANCIAL_PERCENTAGE_OF_MARGIN: "Percentage of Margin",
    FINANCIAL_EXPENSES: "Expenses",
    FINANCIAL_TOTAL_REVENUE: "Total Revenue",
    
    // Equipment
    EQUIPMENT_TRUCK_USAGE: "Truck Usage",
    EQUIPMENT_CHASSIS_USAGE :"Chassis Usage",
    EQUIPMENT_TRAILER_USAGE : "Trailer Utilization",
    EQUIPMENT_CHASSIS_LAST_USED: "Under Utilized Equipment - Chassis",
    EQUIPMENT_TRUCK_LAST_USED: "Under Utilized Equipment - Truck",
    EQUIPMENT_TRAILER_LAST_USED: "Under Utilized Equipment - Trailer",

    // Customer service
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "Employee Updates per Load",
    CUSTOMER_SERVICE_LOADS_CREATED: 'Loads Created',
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "Loads Assigned to CSR",

    // Operations
    OPERATION_LOADS_COMPLETED: "Loads Completed",
    OPERATION_LOADS_COMPLETED_BY_WEEK: "Completed - Day Of Week",
    OPERATION_TOTAL_MOVES_COMPLETED: "Total Moves Completed",
    OPERATION_MOVES_COMPLETED_BY_DRIVER: "Moves Completed By Driver",
    OPERATION_EVENTS_BY_DRIVER: "Breakdown Of Moves By Driver",
    OPERATION_TIME_TO_TERMINATE: "Time To Terminate",
    OPERATION_TIME_TO_RETURN: "Time To Return",
    OPERATION_DISPATCHES_BY_EMPLOYEE: "Dispatches By Employee",
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "Daily Average Moves Per Driver",

    // System
    SYSTEM_CHARGE_CREATION_PERCENTAGE: "Charge Creation Percentage",
    SYSTEM_PAY_CREATION_PERCENTAGE: "Pay Creation Percentage",
    SYSTEM_LOAD_STATUS_PERCENTAGE: "Load Status Percentage",
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "Document Uploads Percentage",
    SYSTEM_LOAD_CREATED_BY: "Load Created By",
    SYSTEM_CRON_REPORTS: "Cron Reports",

    // Employee
    EMPLOYEE_DRIVER_PAY_APPROVED: "Driver Pay Approved",
    EMPLOYEE_SETTLMENT_APPROVED: "Driver Settlements Finalized",

    // Customer
    CUSTOMER_CUSTOMER_PORTAL_USAGE: "Customer Portal Usage",
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "Customer Portal Usage Percentage",
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "Times Marked Ready to Return",
}

export const REPORT_TYPES = {
    CREATED_LOADS: "CREATED_LOADS",
    OPEN_LOADS: "OPEN_LOADS",
    PICKED_UP: "PICKED_UP",
    DELIVERED: "DELIVERED",
    RETURNED: "RETURNED",
    LOADS_BY_TYPE: "LOADS_BY_TYPE",
    LOADS_BY_CUSTOMER: "LOADS_BY_CUSTOMER",
    TOP_DELIVERY_LOCATIONS: "TOP_DELIVERY_LOCATIONS",
    TOP_RETURN_LOCATIONS: "TOP_RETURN_LOCATIONS",
    TOP_PICK_UP_LOCATIONS: "TOP_PICK_UP_LOCATIONS",
    COMPLETED_LOADS: "COMPLETED_LOADS",
    
    // Broker
    BROKER_TENDERS_ACCEPTED:"BROKER_TENDERS_ACCEPTED",
    BROKER_TENDERS_REJECTED: "BROKER_TENDERS_REJECTED",
    BROKER_INVOICES_RECEIVED_BY_CARRIER: "BROKER_INVOICES_RECEIVED_BY_CARRIER",
    BROKER_TENDERS_BY_CARRIER: "BROKER_TENDERS_BY_CARRIER",
    BROKER_LOAD_COMPLETED_NOT_INVOICED: "BROKER_LOAD_COMPLETED_NOT_INVOICED",

    // Employees
    DRIVER_PAY_APPROVES: "DRIVER_PAY_APPROVES",
    DRIVER_SETTLEMENTS_FINALIZED: "DRIVER_SETTLEMENTS_FINALIZED",

    // Track
    TRACK_DWELL_TIME: "TRACK_DWELL_TIME",
    TRACK_NO_OF_APPTS: "TRACK_NO_OF_APPTS",
    TRACK_LAST_FREE_DAY_PERCENTAGE: "TRACK_LAST_FREE_DAY_PERCENTAGE",
    TRACK_PER_DIEM_PERCENTAGE: "TRACK_PER_DIEM_PERCENTAGE",
    TRACK_DEMURRAGE_TOTAL: "TRACK_DEMURRAGE_TOTAL",
    TRACK_PER_DIEM_TOTAL: "TRACK_PER_DIEM_TOTAL",
    TRACK_NUMBER_OF_TIRS: "TRACK_NUMBER_OF_TIRS",

    // Financials
    FINANCIAL_DAYS_TO_INVOICE: "FINANCIAL_DAYS_TO_INVOICE", 
    FINANCIAL_NUMBER_OF_INVOICES: "FINANCIAL_NUMBER_OF_INVOICES", 
    FINANCIAL_TOTAL_PROFIT: "FINANCIAL_TOTAL_PROFIT", 
    FINANCIAL_GROSS_DRIVER_PAY: "FINANCIAL_GROSS_DRIVER_PAY",
    FINANCIAL_GROSS_VENDOR_PAY: "FINANCIAL_GROSS_VENDOR_PAY",
    FINANCIAL_AVERAGE_MARGIN: "FINANCIAL_AVERAGE_MARGIN", 
    FINANCIAL_REVENUE_BY_LOAD_TYPE: "FINANCIAL_REVENUE_BY_LOAD_TYPE", 
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "FINANCIAL_REVENUE_BY_DELIVERY_LOCATION", 
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS", 
    FINANCIAL_REVENUE_BY_CHARGE_NAME: "FINANCIAL_REVENUE_BY_CHARGE_NAME", 
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE", 
    FINANCIAL_PAST_DUE_INVOCES: "FINANCIAL_PAST_DUE_INVOCES", 
    FINANCIAL_TOP_EARNING_DRIVERS: "FINANCIAL_TOP_EARNING_DRIVERS", 
    FINANCIAL_PERCENTAGE_OF_MARGIN: "FINANCIAL_PERCENTAGE_OF_MARGIN",
    FINANCIAL_EXPENSES: "FINANCIAL_EXPENSES",
    FINANCIAL_TOTAL_REVENUE: "FINANCIAL_TOTAL_REVENUE",
    // Equipments
    EQUIPMENT_TRUCK_USAGE: "EQUIPMENT_TRUCK_USAGE",
    EQUIPMENT_CHASSIS_USAGE: "EQUIPMENT_CHASSIS_USAGE",
    EQUIPMENT_TRAILER_USAGE: "EQUIPMENT_TRAILER_USAGE",
    EQUIPMENT_CHASSIS_LAST_USED: "EQUIPMENT_CHASSIS_LAST_USED",
    EQUIPMENT_TRUCK_LAST_USED: "EQUIPMENT_TRUCK_LAST_USED",
    EQUIPMENT_TRAILER_LAST_USED: "EQUIPMENT_TRAILER_LAST_USED",

    //Customer Service
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD",
    CUSTOMER_SERVICE_LOADS_CREATED: "CUSTOMER_SERVICE_LOADS_CREATED",
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR",

    // Operations
    OPERATION_LOADS_COMPLETED: "OPERATION_LOADS_COMPLETED",
    OPERATION_LOADS_COMPLETED_BY_WEEK: "OPERATION_LOADS_COMPLETED_BY_WEEK",
    OPERATION_TOTAL_MOVES_COMPLETED: "OPERATION_TOTAL_MOVES_COMPLETED",
    OPERATION_MOVES_COMPLETED_BY_DRIVER: "OPERATION_MOVES_COMPLETED_BY_DRIVER",
    OPERATION_EVENTS_BY_DRIVER: "OPERATION_EVENTS_BY_DRIVER",
    OPERATION_TIME_TO_TERMINATE: "OPERATION_TIME_TO_TERMINATE",
    OPERATION_TIME_TO_RETURN: "OPERATION_TIME_TO_RETURN",
    OPERATION_DISPATCHES_BY_EMPLOYEE: "OPERATION_DISPATCHES_BY_EMPLOYEE",
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER",

    // System
    SYSTEM_CHARGE_CREATION_PERCENTAGE: "SYSTEM_CHARGE_CREATION_PERCENTAGE",
    SYSTEM_PAY_CREATION_PERCENTAGE: "SYSTEM_PAY_CREATION_PERCENTAGE",
    SYSTEM_LOAD_STATUS_PERCENTAGE: "SYSTEM_LOAD_STATUS_PERCENTAGE",
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE",
    SYSTEM_LOAD_CREATED_BY: "SYSTEM_LOAD_CREATED_BY",
    SYSTEM_CRON_REPORTS: "SYSTEM_CRON_REPORTS",

    // Employee
    EMPLOYEE_DRIVER_PAY_APPROVED: "EMPLOYEE_DRIVER_PAY_APPROVED",
    EMPLOYEE_SETTLMENT_APPROVED: "EMPLOYEE_SETTLMENT_APPROVED",

    // Customer
    CUSTOMER_CUSTOMER_PORTAL_USAGE: "CUSTOMER_CUSTOMER_PORTAL_USAGE",
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE",
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "CUSTOMER_TIMES_MARKED_READY_TO_RETURN",
}

export const INFINITE_SCROLL_IN_POPUP = [
    // Admin
    REPORT_TYPES.LOADS_BY_CUSTOMER,
    REPORT_TYPES.TOP_DELIVERY_LOCATIONS,
    REPORT_TYPES.TOP_PICK_UP_LOCATIONS,
    REPORT_TYPES.TOP_RETURN_LOCATIONS,
    REPORT_TYPES.COMPLETED_LOADS,
    REPORT_TYPES.CREATED_LOADS,
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.RETURNED,
    // Financial 
    REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES,
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
    REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME,
    REPORT_TYPES.FINANCIAL_EXPENSES,
    REPORT_TYPES.FINANCIAL_TOTAL_REVENUE,
    // Operation
    REPORT_TYPES.OPERATION_LOADS_COMPLETED,
    REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
    REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER,
    REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE,
    REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER,
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,

    // Customer Service
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
    REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,

    // Broker
    REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER,
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,
    REPORT_TYPES.BROKER_TENDERS_BY_CARRIER,
    REPORT_TYPES.BROKER_LOAD_COMPLETED_NOT_INVOICED,

    // Employee
    REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED,
    REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED,

    // System
    REPORT_TYPES.SYSTEM_CRON_REPORTS,

    // Customer
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,

    // Equipment
    REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE,
    REPORT_TYPES.EQUIPMENT_TRUCK_USAGE,
    REPORT_TYPES.EQUIPMENT_TRAILER_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED,

    // Track
    REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE,
    REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE,
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const KEYS_FOR_OPERATION_EVENTS_BY_DRIVER = ["Pulled", "Delivered", "Dropped", "Returned"]

export const COUNTER_CARDS_POPUP_NOT_REQUIRED = [
    //Operation
    REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED,
    REPORT_TYPES.OPERATION_TIME_TO_TERMINATE,
]

export const COUNTER_CARDS_POPUP = [
    // Financial
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,

    // Customer
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
    // Track
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const ADMIN_CHARTS = [
    REPORT_TYPES.CREATED_LOADS,
    REPORT_TYPES.OPEN_LOADS,
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.RETURNED,
    REPORT_TYPES.LOADS_BY_TYPE,
    REPORT_TYPES.LOADS_BY_CUSTOMER,
    REPORT_TYPES.TOP_DELIVERY_LOCATIONS,
    REPORT_TYPES.TOP_RETURN_LOCATIONS,
    REPORT_TYPES.TOP_PICK_UP_LOCATIONS,
    REPORT_TYPES.COMPLETED_LOADS,
]

export const BROKER_CHARTS = [
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,
    REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER,
    REPORT_TYPES.BROKER_TENDERS_BY_CARRIER,
    REPORT_TYPES.BROKER_LOAD_COMPLETED_NOT_INVOICED,
]
export const EQUIPMENT_CHARTS = [
    REPORT_TYPES.EQUIPMENT_TRUCK_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE,
    REPORT_TYPES.EQUIPMENT_TRAILER_USAGE,
    REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED,
    REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED,
]

export const FINANCIALS_CHARTS = [
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES,
    REPORT_TYPES.FINANCIAL_TOTAL_PROFIT,
    REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY,
    REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
    REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME,
    REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
    REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES,
    REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS,
    REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN,
    REPORT_TYPES.FINANCIAL_EXPENSES,
    REPORT_TYPES.FINANCIAL_TOTAL_REVENUE,
]

export const CUSTOMER_SERVICE_CHARTS = [
    REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED,
    REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
]

export const OPERATIONS_CHARTS = [
    REPORT_TYPES.OPERATION_LOADS_COMPLETED,
    REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK,
    REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED,
    REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER,
    REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER,
    REPORT_TYPES.OPERATION_TIME_TO_TERMINATE,
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,
    REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE,
    REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
]

export const SYSTEM_CHARTS = [
    REPORT_TYPES.SYSTEM_CHARGE_CREATION_PERCENTAGE,
    REPORT_TYPES.SYSTEM_PAY_CREATION_PERCENTAGE,
    REPORT_TYPES.SYSTEM_LOAD_STATUS_PERCENTAGE,
    REPORT_TYPES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
    REPORT_TYPES.SYSTEM_LOAD_CREATED_BY,
    REPORT_TYPES.SYSTEM_CRON_REPORTS,
]

export const EMPLOYEE_CHARTS = [
    REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED,
    REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED,
]

export const CUSTOMER_CHARTS = [
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
]

export const TRACK_CHARTS = [
    REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE,
    REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE,
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const PAGE_CONFIGS = {
    ADMIN: {
        layout: {
            column: 4,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CREATED_LOADS
            },
            // {
            //     x: 1,
            //     y: 0,
            //     w: 1,
            //     h: 1,
            //     reportType: REPORT_TYPES.OPEN_LOADS
            // },
            {
                x: 0,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.COMPLETED_LOADS
            },
            {
                x: 3,
                y: 9,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.PICKED_UP
            },
            {
                x: 2,
                y: 10,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.DELIVERED
            },
            {
                x: 2,
                y: 9,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.RETURNED
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.LOADS_BY_TYPE
            },
            {
                x: 0,
                y: 6,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.LOADS_BY_CUSTOMER
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_DELIVERY_LOCATIONS
            },
            {
                x: 0,
                y: 9,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_RETURN_LOCATIONS
            },
            {
                x: 2,
                y: 6,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.TOP_PICK_UP_LOCATIONS
            },
        ]
    },
    FINANCIALS: {
        layout: {
            column: 15,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE
            },
            {
                x: 3,
                y: 0,
                w: 6,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES
            },
            {
                x: 9,
                y: 0,
                w: 6,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_TOTAL_PROFIT
            },
            {
                x: 0,
                y: 12,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY
            },
            {
                x: 0,
                y: 1,
                w: 3,
                h: 1,
                reportType: REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN
            },
            {
                x: 5,
                y: 3,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE
            },
            {
                x: 10,
                y: 6,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION
            },
            {
                x: 5,
                y: 6,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS
            },
            {
                x: 10,
                y: 9,
                w: 5,
                h: 6,
                reportType: REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME
            },
            {
                x: 0,
                y: 6,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE
            },
            {
                x: 0,
                y: 3,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES
            },
            {
                x: 5,
                y: 12,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS
            },
            {
                x: 5,
                y: 9,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN
            },
            {
                x: 10,
                y: 3,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_EXPENSES
            },
            {
                x: 0,
                y: 9,
                w: 5,
                h: 3,
                reportType: REPORT_TYPES.FINANCIAL_TOTAL_REVENUE
            }
        ]
    },
    BROKER: {
        layout: {
            column: 3,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_LOAD_COMPLETED_NOT_INVOICED
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_TENDERS_ACCEPTED
            },
            {
                x: 2,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.BROKER_TENDERS_REJECTED
            },

            {
                x: 0,
                y: 1,
                w: 1,
                h: 3,
                reportType: REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER
            },
            {
                x: 1,
                y: 1,
                w: 1,
                h: 3,
                reportType: REPORT_TYPES.BROKER_TENDERS_BY_CARRIER
            },
        ]
    },
    EMPLOYEE: {
        layout: {
            column: 4,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 4,
                reportType: REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED
            },
            {
                x: 2,
                y: 2,
                w: 2,
                h: 4,
                reportType: REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED
            },
        ]
    },
    TRACK: {
        layout: {
            column: 10,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_DWELL_TIME
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_NO_OF_APPTS
            },
            {
                x: 4,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_DEMURRAGE_TOTAL
            },
            {
                x: 6,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_PER_DIEM_TOTAL
            },
            {
                x: 8,
                y: 0,
                w: 2,
                h: 1,
                reportType: REPORT_TYPES.TRACK_NUMBER_OF_TIRS
            },
            {
                x: 0,
                y: 1,
                w: 5,
                h: 4,
                reportType: REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE
            },
            {
                x: 5,
                y: 1,
                w: 5,
                h: 4,
                reportType: REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE
            }
        ]
    },
    EQUIPMENT: {
        layout: {
            column: 3,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRUCK_USAGE
            },
            {
                x: 2,
                y: 0,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRAILER_USAGE
            },
            {
                x: 0,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED
            },
            {
                x: 1,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED
            },
            {
                x: 2,
                y: 6,
                w: 1,
                h: 4,
                reportType: REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED
            },
        ]
    },
    CUSTOMER_SERVICE: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED
            },
            {
                x: 0,
                y: 4,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR
            },
            
        ]
    },
    OPERATIONS: {
        layout: {
            column: 6,
            rowHeight: 115
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_LOADS_COMPLETED
            },
            {
                x: 3,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED
            },
            {
                x: 4,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TIME_TO_TERMINATE
            },
            {
                x: 5,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.OPERATION_TIME_TO_RETURN
            },
            {
                x: 0,
                y: 3,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK
            },
            {
                x: 3,
                y: 4,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER
            },
            {
                x: 0,
                y: 6,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER
            },
            {
                x: 3,
                y: 1,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE
            },
            {
                x: 0,
                y: 9,
                w: 3,
                h: 3,
                reportType: REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER
            },
        ]
    },
    SYSTEM: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE
            },
            {
                x: 2,
                y: 0,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_PAY_CREATION_PERCENTAGE
            },
            {
                x: 0,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_LOAD_CREATED_BY
            },
            {
                x: 2,
                y: 3,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_CHARGE_CREATION_PERCENTAGE
            },
            {
                x: 0,
                y: 6,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.SYSTEM_LOAD_STATUS_PERCENTAGE
            },
            {
                x: 2,
                y: 6,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.SYSTEM_CRON_REPORTS
            },
            
        ]
    },
    CUSTOMER: {
        layout: {
            column: 4,
            rowHeight: 115,
        },
        components: [
            {
                x: 0,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE
            },
            {
                x: 1,
                y: 0,
                w: 1,
                h: 1,
                reportType: REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN
            },
            {
                x: 0,
                y: 1,
                w: 2,
                h: 3,
                reportType: REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE
            },
        ]
    }
}


export const REPORT_PROPS = {
    CREATED_LOADS: {
        component: LineChart,
        title: REPORT_NAMES.CREATED_LOADS,
        description: "Total loads created during for that date range",
        isDownload: true
    },
    COMPLETED_LOADS: {
        component: LineChart,
        title: REPORT_NAMES.COMPLETED_LOADS,
        // description: "All loads that were completed for that date range",
        isDownload: true
    },
    PICKED_UP: {
        component: CounterCard,
        title: REPORT_NAMES.PICKED_UP,
        // description: "All loads that had 'picked up' event completed for that date range",
    },
    DELIVERED: {
        component: CounterCard,
        title: REPORT_NAMES.DELIVERED,
        // description: "All loads that had 'delivered' event completed for that date range",
    },
    RETURNED: {
        component: CounterCard,
        title: REPORT_NAMES.RETURNED,
        // description: "All loads that had 'returned' event completed for that date range",
    },
    LOADS_BY_TYPE: {
        component: ExpandedPieDonutChart,
        isHalfPieChart: true,
        isHoleChart: true,
        isDownload: true,
        title: REPORT_NAMES.LOADS_BY_TYPE,
        // description: "All the created loads for that date range broken down by type",
        isTooltipVisible: true,
    },
    LOADS_BY_CUSTOMER: {
        component: BarChartCard,
        title: REPORT_NAMES.LOADS_BY_CUSTOMER,
        // description: "All the created loads for that date range broken down by Customer profile",
        isDownload: true,
    },
    TOP_DELIVERY_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_DELIVERY_LOCATIONS,
        // description: "All the delivery events that were completed for that date range broken down by which profiles were delivered to the most",
        isDownload: true,
    },
    TOP_RETURN_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_RETURN_LOCATIONS,
        // description: "All the return events that were completed for that date range broken down by which profiles were returned to the most",
        isDownload: true,
    },
    TOP_PICK_UP_LOCATIONS: {
        component: BarChartCard,
        title: REPORT_NAMES.TOP_PICK_UP_LOCATIONS,        
        // description: "All the pick up events that were completed for that date range broken down by which profiles were picked up at the most",
        isDownload: true,
    },
}

export const REPORT_PROPS_EQUIPMENT = {
    EQUIPMENT_CHASSIS_USAGE:{
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_CHASSIS_USAGE,
        description: "Count of Completed moves by Chassis #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_TRUCK_USAGE: {
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_TRUCK_USAGE,
        description: "Count of Completed moves by Driver and Truck #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_TRAILER_USAGE: {
        component: BarChartCard,
        title: REPORT_NAMES.EQUIPMENT_TRAILER_USAGE,
        description: "Count of Completed moves by Trailer #",
        isDownload: true,
        tooltipPostFix: "Moves"
    },
    EQUIPMENT_CHASSIS_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_CHASSIS_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,

    },
    EQUIPMENT_TRUCK_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_TRUCK_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,

    },
    EQUIPMENT_TRAILER_LAST_USED: {
        component: ListTable,
        title: REPORT_NAMES.EQUIPMENT_TRAILER_LAST_USED,
        description: "Data is irrespective of date range and stored profiles only",
        hideCounts: true,
        isDownload: true,
        hideFormatting: true,
    },
}

export const REPORT_PROPS_BROKER = {
    BROKER_TENDERS_ACCEPTED: {
        component: CounterCard,
        title: REPORT_NAMES.BROKER_TENDERS_ACCEPTED,
        description: "Total number of tenders that have been accepted",
    },
    BROKER_TENDERS_REJECTED:{
        component: CounterCard,
        title: REPORT_NAMES.BROKER_TENDERS_REJECTED,
        description: "Total number of tenders that have been rejected",
    },
    BROKER_INVOICES_RECEIVED_BY_CARRIER: {
        component: BarChartCard,
        isDownload: true,
        title: REPORT_NAMES.BROKER_INVOICES_RECEIVED_BY_CARRIER,
        description: "Total number of invoices received, by Carrier",
        isToFixedNeeded: true,
        tooltipPostFix: 'Tenders Received',
    },
    BROKER_TENDERS_BY_CARRIER: {
        component: ExpandedPieDonutChart,
        isHoleChart: true,
        title: REPORT_NAMES.BROKER_TENDERS_BY_CARRIER,
        description: "Total number of tenders that have been sent",
        isDownload: true,
        isTooltipVisible: true,
        tooltipPostFix: "%"
    },
    BROKER_LOAD_COMPLETED_NOT_INVOICED:{
        component: CounterCard,
        title: REPORT_NAMES.BROKER_LOAD_COMPLETED_NOT_INVOICED,
        description: "# of Brokered Loads that have not been Invoiced",
    },
}

export const REPORT_PROPS_EMPLOYEE = {
    EMPLOYEE_DRIVER_PAY_APPROVED: {
        component: BarChartCard,
        title: REPORT_NAMES.EMPLOYEE_DRIVER_PAY_APPROVED,
        isDownload: true,
        tooltipPostFix: "Driver Pays",
        description: "Count of Driver Pay line items approved by Employee",
    },
    EMPLOYEE_SETTLMENT_APPROVED: {
        component: BarChartCard,
        title: REPORT_NAMES.EMPLOYEE_SETTLMENT_APPROVED,
        isDownload: true,
        tooltipPostFix: "Settlements",
        description: "Count of Settlements finalized by Employee",
    },
    
}


export const REPORT_PROPS_FINANCIAL = {
    FINANCIAL_DAYS_TO_INVOICE: {
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_DAYS_TO_INVOICE,
        description: "# of Days between Load Completion & Invoice Date (at least 1 Charge Set invoiced)",
    },
    FINANCIAL_NUMBER_OF_INVOICES: {  
        component: LineChart,
        title: REPORT_NAMES.FINANCIAL_NUMBER_OF_INVOICES,
        description: "The total # of invoices created during this time period",
        tooltipPostFix: "Invoices",
        isDownload: true,
    },
    FINANCIAL_TOTAL_PROFIT: {  
        component: LineChart,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_TOTAL_PROFIT,
        description: "Total Profit",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        isDownload: true,
    },
    FINANCIAL_GROSS_DRIVER_PAY: {  
        component: LineChart,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_GROSS_DRIVER_PAY,
        description: "Driver Pay prior to Deductions",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        isDownload: true,
    },
    FINANCIAL_GROSS_VENDOR_PAY: {  
        component: LineChart,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_GROSS_VENDOR_PAY,
        description: "Gross Vendor Pay",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        isDownload: true,
    },
    FINANCIAL_AVERAGE_MARGIN: {
        component: CounterCard,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_AVERAGE_MARGIN,
        description: "Average margin of invoiced loads",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        percentageChangeNotRequired: true
    },
    FINANCIAL_REVENUE_BY_LOAD_TYPE: {
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_LOAD_TYPE,
        description: "Revenue of Invoiced Loads (at least 1 Charge Set invoiced)",
        isDownload: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
        description: "Revenue of Loads based on Final Delivery Location",
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        isDownload: true,
    },
    FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
        description: "Breakdown of Profit ranked by Delivery Locations",
        isDownload: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_REVENUE_BY_CHARGE_NAME: {  
        component: ListTable,
        isToFixedNeeded: true,
        title: REPORT_NAMES.FINANCIAL_REVENUE_BY_CHARGE_NAME,
        description: "Total revenue broken down by charge code",
        isDownload: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        isHoleChart: true,
        title: REPORT_NAMES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
        description: "Profit by Customer, as a % of total profit",
        isDownload: true,
        isTooltipVisible: true,
        percentageChangeNotRequired: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_PAST_DUE_INVOCES: {  
        component: ExpandedPieDonutChart,
        isHoleChart: true,
        title: REPORT_NAMES.FINANCIAL_PAST_DUE_INVOCES,
        description: "Percentage of invoices past due",
        isDownload: true,
        isTooltipVisible: true,
        tooltipPostFix: "%"
    },
    FINANCIAL_TOP_EARNING_DRIVERS: {  
        component: BarChartCard,
        isToFixedNeeded: true,
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        title: REPORT_NAMES.FINANCIAL_TOP_EARNING_DRIVERS,
        description: "Driver Pay post Deductions",
        isDownload: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_PERCENTAGE_OF_MARGIN: {  
        component: BarChartCard,
        tooltipPostFix: "%",
        title: REPORT_NAMES.FINANCIAL_PERCENTAGE_OF_MARGIN,
        description: "% of Loads in each margin category",
        isDownload: true,
        percentageChangeNotRequired: true
    },
    FINANCIAL_EXPENSES: {
        component: BarChartCard,
        title: REPORT_NAMES.FINANCIAL_EXPENSES,
        description: "Expense Breakdown",
        isDownload: true,
        percentageChangeNotRequired: true,
        isToFixedNeeded: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
    FINANCIAL_TOTAL_REVENUE: {
        component: LineChart,
        title: REPORT_NAMES.FINANCIAL_TOTAL_REVENUE,
        isDownload: true,
        percentageChangeNotRequired: true,
        isToFixedNeeded: true,
        preFixOfcardCount: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
        tooltipPostFix: JSON.parse(getStorage("accountCurrencies") || "{}")?.carrier?.symbol ?? '$',
    },
};

export const REPORT_PROPS_CUSTOMER_SERVICE = {
    CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
        description: "Total number of saved load changes, by Employee",
        isDownload: true,
        tooltipPostFix: "Updates"
    },
    CUSTOMER_SERVICE_LOADS_CREATED: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_LOADS_CREATED,
        description: "Total # of Loads Created",
        isDownload: true,
        tooltipPostFix: "Loads",
    },
    CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: {
        component: BarChartCard,
        title: REPORT_NAMES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
        description: "Loads assigned to CSR, by Load Status",
        isDownload: true,
        percentageChangeNotRequired: true
    },
}

export const REPORT_PROPS_OPERATIONS = {
    OPERATION_LOADS_COMPLETED: {
        component: LineChart,
        title: REPORT_NAMES.OPERATION_LOADS_COMPLETED,
        description: "# of Loads Completed",
    },
    OPERATION_LOADS_COMPLETED_BY_WEEK:{
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_LOADS_COMPLETED_BY_WEEK,
        description: "# of loads completed by day of the week",
        isDownload: true,
        tooltipPostFix: "Loads",
        percentageChangeNotRequired: true
    },
    OPERATION_TOTAL_MOVES_COMPLETED: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TOTAL_MOVES_COMPLETED,
        description: "Moves completed during this timeframe",
        percentageChangeNotRequired: true
    },
    OPERATION_MOVES_COMPLETED_BY_DRIVER: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_MOVES_COMPLETED_BY_DRIVER,
        description: "Moves completed by Driver during this timeframe",
        tooltipPostFix: "Moves",
        isDownload: true,
    },
    OPERATION_EVENTS_BY_DRIVER: {
        component: CustomStackedBarChart,
        title: REPORT_NAMES.OPERATION_EVENTS_BY_DRIVER,
        description: "Moves completed by progress by driver/vendor",
        isDownload: true,
        keysForStackedBarChart: KEYS_FOR_OPERATION_EVENTS_BY_DRIVER
    },
    OPERATION_TIME_TO_TERMINATE: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TIME_TO_TERMINATE,
        description: "Average number of days it takes to terminate an empty after it's been unloaded",
    },
    OPERATION_TIME_TO_RETURN: {
        component: CounterCard,
        title: REPORT_NAMES.OPERATION_TIME_TO_RETURN,
        description: "Average number of days it takes to return an export after it's been loaded",
    },
    OPERATION_DISPATCHES_BY_EMPLOYEE: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_DISPATCHES_BY_EMPLOYEE,
        description: "# of dispatch events, by employee",
        tooltipPostFix: "Dispatches",
        isDownload: true,
        percentageChangeNotRequired: true
    },
    OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: {
        component: BarChartCard,
        title: REPORT_NAMES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
        description: "# of average daily moves, by driver",
        tooltipPostFix: "Moves",
        isDownload: true,
        percentageChangeNotRequired: true,
        isToFixedNeeded: true
    },
}

export const REPORT_PROPS_SYSTEM = {
    SYSTEM_CHARGE_CREATION_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_CHARGE_CREATION_PERCENTAGE,
        description: "Manually created chargesets vs. automatically created chargesets",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
    },
    SYSTEM_PAY_CREATION_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_PAY_CREATION_PERCENTAGE,
        description: "Manually created driver pay vs. automatically created driver pay",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
        tooltipPostFix: "Pay",
    },
    SYSTEM_LOAD_CREATED_BY: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_LOAD_CREATED_BY,
        description: "Load Creation Method",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
    },
    SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
        description: "PODs uploaded by driver vs. system users",
        isHoleChart: true,
        isDownload: true,
        percentageChangeNotRequired: true,
        isTooltipVisible: true,
        tooltipPostFix: "Documents",
    },
    SYSTEM_LOAD_STATUS_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.SYSTEM_LOAD_STATUS_PERCENTAGE,
        description: "Load progression updated manually (by user) vs. automatically (geo-fence)",
        isHoleChart: true,
        isDownload: true,
        percentageChangeNotRequired: true,
        isTooltipVisible: true,
    },
    SYSTEM_CRON_REPORTS: {
        component: CounterCard,
        title: REPORT_NAMES.SYSTEM_CRON_REPORTS,
        description: "How many reports are being sent out",
        percentageChangeNotRequired: true,
    },
}

export const REPORT_PROPS_CUSTOMER = {
    CUSTOMER_CUSTOMER_PORTAL_USAGE: {
        component: CounterCard,
        title: REPORT_NAMES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
        description: "Count of customer user activity per day",
    },
    CUSTOMER_TIMES_MARKED_READY_TO_RETURN: {
        component: CounterCard,
        title: REPORT_NAMES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
        // description: "How many times has a customer marked a load 'Ready to Return",
        percentageChangeNotRequired: true,
    },
    CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        title: REPORT_NAMES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
        description: "Customer user activity as a percentage of total loads",
        isHoleChart: true,
        isDownload: true,
        isTooltipVisible: true,
        percentageChangeNotRequired: true,
        tooltipPostFix: "%"
    },
}

export const REPORT_PROPS_TRACK = {
    TRACK_DWELL_TIME: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_DWELL_TIME,
        description: "Average days a container is at port"
    },
    TRACK_NO_OF_APPTS: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_NO_OF_APPTS,
        description: "Number of Appts created by the system",
    },
    TRACK_DEMURRAGE_TOTAL: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_DEMURRAGE_TOTAL,
        description: "How many loads weren't picked up by LFD"
    },
    TRACK_PER_DIEM_TOTAL: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_PER_DIEM_TOTAL,
        description: "Loads weren't returned by Return Date",
    },
    TRACK_NUMBER_OF_TIRS: {
        component: CounterCard,
        title: REPORT_NAMES.TRACK_NUMBER_OF_TIRS,
        description: "Number of the TIRs uploaded by the system",
    },
    TRACK_LAST_FREE_DAY_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isTooltipVisible: true,
        isHoleChart: true,
        tooltipPostFix: "%",
        title: REPORT_NAMES.TRACK_LAST_FREE_DAY_PERCENTAGE,
        description: "Percentage of Loads picked up on or before LFD",
        isDownload: true,
        innerRadius: 55,
    },
    TRACK_PER_DIEM_PERCENTAGE: {
        component: ExpandedPieDonutChart,
        isTooltipVisible: true,
        isHoleChart: true,
        tooltipPostFix: "%",
        title: REPORT_NAMES.TRACK_PER_DIEM_PERCENTAGE,
        description: "Percentage of Loads that incurred Perdiem",
        isDownload: true,
        innerRadius: 55,
    },
}

export const ICON_COLORS = ["text-purple-200", "text-slategray-600", "text-lightgreen-150", "text-steelblue-150"];
export const COLORS = ["#9E8FFA", "#447780", "#84C46C", "#3778B6"];
export const HOVER_COLORS = ["#d4cefd", "#bcd7dc", "#c2e2b6", "#b0cde8"];

export const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS = [
    "Pending",
    "Dispatched",
    "Enroute to Chassis",
    "Arrived to Chassis",
    "Enroute to Pick Container",
    "Arrived at Pick Container",
    "Enroute to Deliver",
    "Arrived at Deliver",
    "Enroute to Drop Container",
    "Dropped",
    "Enroute to Hook Container",
    "Arrived to Hook Container",
    "Enroute to Return",
    "Arrived at Return",
    "Arrived at Stop Off",
    "Enroute to Stop Off",
    "Enroute to Return Chassis",
    "Arrived to Return Chassis",
    "Available",
    "Departed",
    "Completed"
]

export const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR_KEYS_OBJ = {
    "Pending": "pending_load",
    "Dispatched": "dispatched_load",
    "Enroute to Chassis": "enroute_to_chassis_load",
    "Arrived to Chassis": "arrived_to_chassis_load",
    "Enroute to Pick Container": "enroute_to_pick_container_load",
    "Arrived at Pick Container": "arrived_at_pick_container_load",
    "Enroute to Deliver": "enroute_to_deliver_load",
    "Arrived at Deliver": "arrived_at_deliver_load",
    "Enroute to Drop Container": "enroute_to_drop_container_load",
    "Dropped": "dropped_load",
    "Enroute to Hook Container": "enroute_to_hook_container_load",
    "Arrived to Hook Container": "arrived_to_hook_container_load",
    "Enroute to Return": "enroute_to_return_load",
    "Arrived at Return": "arrived_at_return_load",
    "Arrived at Stop Off": "arrived_at_stop_off_load",
    "Enroute to Stop Off": "enroute_to_stop_of_load",
    "Enroute to Return Chassis": "enroute_to_return_chassis_load",
    "Arrived to Return Chassis": "arrived_to_return_chassis_load",
    "Available": "available_load",
    "Departed": "departed_load",
    "Completed": "completed_load"
}

export const DASHBOARDS = {
    ADMIN: "admin",
    BROKER: "broker", // Only if `isManageFleetOrOutSource()?.isOutSource` is `true`
    CUSTOMER: "customer",
    CUSTOMER_SERVICE: "customerService",
    EMPLOYEES: "employees",
    EQUIPMENT: "equipment",
    FINANCIALS: "financials",
    OPERATIONS: "operations",
    SYSTEM: "system",
    TRACK: "track", // Only if TrackOS User
    MY_DASHBOARD: "myDashboard"
}

export const DASHBOARDS_CHARTS_LIST = {
    [DASHBOARDS.ADMIN]: ADMIN_CHARTS,
    [DASHBOARDS.BROKER]: BROKER_CHARTS,
    [DASHBOARDS.CUSTOMER]: CUSTOMER_CHARTS,
    [DASHBOARDS.CUSTOMER_SERVICE]: CUSTOMER_SERVICE_CHARTS,
    [DASHBOARDS.EMPLOYEES]: EMPLOYEE_CHARTS,
    [DASHBOARDS.EQUIPMENT]: EQUIPMENT_CHARTS,
    [DASHBOARDS.FINANCIALS]: FINANCIALS_CHARTS,
    [DASHBOARDS.OPERATIONS]: OPERATIONS_CHARTS,
    [DASHBOARDS.SYSTEM]: SYSTEM_CHARTS,
    [DASHBOARDS.TRACK]: TRACK_CHARTS
}
export const DISPATCHER_FILTER_DASHBOARDS = [
    DASHBOARDS.ADMIN,
    DASHBOARDS.CUSTOMER_SERVICE,
    DASHBOARDS.OPERATIONS
]

export const DISPATCHER_FILTER_OF_LOAD_INFO = [
    {
        lable: "Hazmat",
        icon: <IconWarning className="text-warning-500 mr-10"/>,
        countKey: "CAUTION_COUNT",
        filterKey: "hazmat"
    },
    {
        lable: "Liquor",
        icon: <IconLiquor className=" mr-10" />,
        countKey: "LIQUOR_COUNT",
        filterKey: "liquor"
    },
    {
        lable: "Local",
        icon: <IconMapMarker className="text-gray-700 mr-10" />,
        countKey: "LOCAL_COUNT",
        filterKey: "routeTypeLocal"
    },
    {
        lable: "Highway",
        icon: <IconHighway className="text-gray-700 mr-10" />,
        countKey: "HIGHWAY_COUNT",
        filterKey: "routeTypeHighway"
    },
    {
        lable: "Overweight",
        icon: <IconWeightTool className="text-gray-700 mr-10" />,
        countKey: "SCALE_COUNT",
        filterKey: "overWeight"
    },
    {
        lable: "Reefer",
        icon: <IconSnowFlake className="text-skyblue-500 mr-10" />,
        countKey: "REEFER_COUNT",
        filterKey: "temperature"
    },
    {
        lable: "Hot",
        icon: <IconFire className="text-error-500 mr-10" />,
        countKey: "HOT_COUNT",
        filterKey: "isHot"
    },
    {
        lable: "Genset",
        icon: <IconFlash className="text-gray-700 mr-10" />,
        countKey: "GENSET_COUNT",
        filterKey: "isGenset"
    },
    {
        lable: "Street Turn",
        icon: <IconStreetTurn className="text-gray-700 mr-10" />,
        countKey: "STREET_TURN_COUNT",
        filterKey: "isStreetTurn"
    },
]

export const EXCLUDE_FILTER_KEYS = [
    "customer",
    "consignee",
    "shipper",
    "empty_origin",
    "driver",
    "containerOwner"
]

export const excludeVeriableName = {
    "customer": "excludeCaller",
    "consignee": "excludeConsignee",
    "shipper": "excludeShipper",
    "empty_origin": "excludeEmptyOrigin",
    "driver": "excludeDriver",
    "containerOwner": "excludeContainerOwner"
}

export const FILTER_FOR_DASHBOARD_CHARTS = {
    [DASHBOARDS.FINANCIALS]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
    ],
    [DASHBOARDS.BROKER]: [
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
    ],
    [DASHBOARDS.CUSTOMER]: [
        "customer"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
        "employee"
    ],
    // [DASHBOARDS.EQUIPMENT]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.OPERATIONS]: [
        "customer",
        "consignee",
        "shipper",
        "empty_origin",
        "route",
        "type_of_load",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
    ],
    // [DASHBOARDS.SYSTEM]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    // [DASHBOARDS.TRACK]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.ADMIN]: [
        "type_of_load",
        "customer",
        "shipper",
        "consignee",
        "empty_origin",
        ...(showForTerminal() ? ["terminal"] : []),
        "driver",
        "addedBy",
        "status",
        "containerType",
        "containerSize",
        "containerOwner",
        "chassisType",
        "chassisOwner",
        "assignedCSR",
        "preSetOrderStatus",
        "routingEventStatus",
        "route",
    ],
    // [DASHBOARDS.EMPLOYEES]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
};

export const DASHBOARD_CHARTS_FILTER_NAME = {
    "customer": "Customer",
    "consignee": LOAD_LABELS?.DELIVERY_LOCATION,
    "shipper": LOAD_LABELS?.PICK_UP_LOCATION,
    "empty_origin": LOAD_LABELS?.RETURN_LOCATION,
    "route": LOAD_LABELS?.ROUTE,
    "type_of_load": LOAD_LABELS?.LOAD_TYPE,
    "terminal": LOAD_LABELS?.BRANCH,
    "driver": "Driver",
    "employee": "Employee",
    "addedBy": "Created By",
    "status": "Status",
    "containerType": LOAD_LABELS?.CONTAINER_TYPE,
    "containerSize": LOAD_LABELS?.CONTAINER_SIZE,
    "containerOwner": LOAD_LABELS?.CONTAINER_OWNER,
    "chassisType": LOAD_LABELS?.CHASSIS_TYPE,
    "chassisOwner": LOAD_LABELS?.CHASSIS_OWNER,
    "assignedCSR": "CSR",
    "routingEventStatus": "Routing Event",
    "preSetOrderStatus": "Load Template",
    "isReadyForPickup": "Ready To Return"
}

export const DASHBOARD_MULTI_SELECT_FILTER = [
    DASHBOARD_CHARTS_FILTER_NAME.customer,
    DASHBOARD_CHARTS_FILTER_NAME.consignee,
    DASHBOARD_CHARTS_FILTER_NAME.shipper,
    DASHBOARD_CHARTS_FILTER_NAME.empty_origin,
    DASHBOARD_CHARTS_FILTER_NAME.route,
    DASHBOARD_CHARTS_FILTER_NAME.type_of_load,
    DASHBOARD_CHARTS_FILTER_NAME.terminal,
    DASHBOARD_CHARTS_FILTER_NAME.driver,
    DASHBOARD_CHARTS_FILTER_NAME.employee,
    DASHBOARD_CHARTS_FILTER_NAME.addedBy,
    DASHBOARD_CHARTS_FILTER_NAME.status,
    DASHBOARD_CHARTS_FILTER_NAME.containerType,
    DASHBOARD_CHARTS_FILTER_NAME.containerSize,
    DASHBOARD_CHARTS_FILTER_NAME.containerOwner,
    DASHBOARD_CHARTS_FILTER_NAME.chassisType,
    DASHBOARD_CHARTS_FILTER_NAME.chassisOwner,
    DASHBOARD_CHARTS_FILTER_NAME.assignedCSR,
    DASHBOARD_CHARTS_FILTER_NAME.routingEventStatus
]

export const ADD_GROUPED_PROFILES_FILTER_KEYS = [
    DASHBOARD_CHARTS_FILTER_NAME.consignee,
    DASHBOARD_CHARTS_FILTER_NAME.shipper,
    DASHBOARD_CHARTS_FILTER_NAME.empty_origin,
]

export const DASHBOARD_DATE_FILTERS = []

export const DAHSBOARD_CLEARABLE_DATE_FILTER = [] 

export const DASHBOARD_FILTER_APPLIES = [
    "admin",
    "broker",
    "customer",
    "customerService",
    "financials",
    "operations",
]


export const COUNTER_CARDS_CSV_CHARTS_NAME = [
    //Admin
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.RETURNED,

    //Broker
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,
    REPORT_TYPES.BROKER_LOAD_COMPLETED_NOT_INVOICED,

    //Operations
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,

    //System
    REPORT_TYPES.SYSTEM_CRON_REPORTS,
]
export const NEWEST_10_CUSTOMERS_LABEL = "Newest 10 Customers";

export const COUNTER_CARDDS_CHARTS = [
    // ADMIN    
    REPORT_TYPES.PICKED_UP,
    REPORT_TYPES.DELIVERED,
    REPORT_TYPES.RETURNED,

    // OPERATIONS
    REPORT_TYPES.OPERATION_TOTAL_MOVES_COMPLETED,
    REPORT_TYPES.OPERATION_TIME_TO_TERMINATE,
    REPORT_TYPES.OPERATION_TIME_TO_RETURN,

    // BROKER
    REPORT_TYPES.BROKER_TENDERS_ACCEPTED,
    REPORT_TYPES.BROKER_TENDERS_REJECTED,

    // CUSTOMER
    REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    REPORT_TYPES.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,

    // FINANCIALS
    REPORT_TYPES.FINANCIAL_DAYS_TO_INVOICE,
    REPORT_TYPES.FINANCIAL_AVERAGE_MARGIN,

    // System
    REPORT_TYPES.SYSTEM_CRON_REPORTS,
    
    // Track
    REPORT_TYPES.TRACK_NUMBER_OF_TIRS,
    REPORT_TYPES.TRACK_PER_DIEM_TOTAL,
    REPORT_TYPES.TRACK_DEMURRAGE_TOTAL,
    REPORT_TYPES.TRACK_DWELL_TIME,
    REPORT_TYPES.TRACK_NO_OF_APPTS,
]

export const TAB_NAMES = {
    admin: "ADMIN",
    broker: "BROKER",
    customer: "CUSTOMER",
    customerService: "CUSTOMER_SERVICE",
    employees: "EMPLOYEES",
    equipment: "EQUIPMENT",
    financials: "FINANCIALS",
    operations: "OPERATIONS",
    system: "SYSTEM",
    track: "TRACK",
}
