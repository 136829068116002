import { getCarrierId, getStorage } from "./Common.services";
import { HTTP } from "./Http.service";


export const isNewLoadUpdateChannel = () => {
  const flag =  JSON.parse(getStorage("flagConfig") ?? "{}")?.isNewLoadUpdateChannel ?? false;
  return flag;
}
export function lastUpdatedLoadChannel() {
  const carrierId = getCarrierId();
  const oldChannelName = '/lastUpdatedLoad';
  const newChannelName = '/v2/updatedLoad';
  const channelName = isNewLoadUpdateChannel() ? `${carrierId}${newChannelName}` : `${carrierId}${oldChannelName}`;
  return channelName;
}

export const lastUpdatedPlanChannel = () => {
  const carrierId = getCarrierId();
  const channelName = `${carrierId}/updated_plan`;
  return channelName;
}

export async function getLoadDetailFromRedis(reference_number, load) {
  if(!reference_number) return null;
  if(load.token){
    const response = await HTTP(
      "get",
      `redis/getLoadDetail/${reference_number}/${load.token}`,
      {},
      {}
    );
    return response?.data?.data;
  }else{
    return load
  }
  
}

export async function getTripDetailFromRedis(trip){
  if(trip?.token && trip?.type && trip?.tripNumber){
    const response = await HTTP(
      "get",
      `redis/getTripDetail/${trip.type}/${trip.tripNumber}/${trip.token}`,
      {},
      {}
    );
    return response?.data?.data;
  }else{
    return trip
  }
}

export async function getCabotageDetailFromRedis(token) {
  if (!token) return null;
  const response = await HTTP(
    "get",
    `redis/getCabotageDetail/${token}`,
    {},
    {},
  );
  return response?.data?.data;
}
