import { useState } from "react";
import Select from "react-select";
import { IconArrowLongRight } from "../../../../../../Components/Common/Icons";
import useTimeRangePicker from "./hooks/useTimeRangePicker";
import { smallSelectStyle } from "../../../../../../assets/js/select-style";
import { APPOINTMENT_TIME_CATEGORY } from "../../constants";
import { validateTimeRange } from "../../helpers/timeConverter";
import { toastr } from "../../../../../../services";
const { TIME_RANGE_SLOT } = APPOINTMENT_TIME_CATEGORY;

const TimeRangePicker = ({ handleSelectedTimeSlot, timeZone, defaultTimeSlot, selectedOption, onOptionChange }) => {
  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    hourOptions,
    minuteOptions,
    shiftTimeOptions,
    defaultTime,
    resetForm,
  } = useTimeRangePicker({
    timeZone,
    defaultTimeSlot,
  });
  const [validationError, setValidationError] = useState("");

  const [showTimeRangePicker, setShowTimeRangePicker] = useState(false);

  const handleToggleTimeRangePicker = () => {
    setShowTimeRangePicker(!showTimeRangePicker);
  };

  const selectStyle = {
    ...smallSelectStyle,
    control: (provided) => ({
      ...provided,
      minHeight: 30,
      width: "70px",
    }),
  };

  const onConfirmTimeRange = () => {
    if (!validateTimeRange(startTime, endTime)) {
      toastr.show("Please choose valid time range.", "error");
      return;
    }
    
    // TODO: Proper validations - after error if user selects value, error validation should be removed.
    if ((startTime || endTime) === defaultTime) {
      setValidationError("Please choose a time range to book appointment!");
      return;
    }
    handleSelectedTimeSlot(startTime, endTime);
    setShowTimeRangePicker(false);
  };

  return (
    <div
      className={`form-check form-check-inline ${selectedOption === TIME_RANGE_SLOT ? "" : "app-slot-disabled"}`}
    >
      <label className="form-check-label ml-0">
        <input
          type="radio"
          className="form-check-input"
          name="appointment-option"
          checked={selectedOption === TIME_RANGE_SLOT}
          onChange={() => onOptionChange(TIME_RANGE_SLOT)}
        />
        I want to schedule an appointment within
        <span
          className="btn btn-sm btn-gray-50 mx-2 pointer"
          onClick={() => {
            handleToggleTimeRangePicker();
            onOptionChange(TIME_RANGE_SLOT);
          }}
          style={{ opacity: selectedOption === TIME_RANGE_SLOT ? "1" : "0.3" }}
        >
          {`${startTime.hour} - ${startTime.min} ${startTime.shift}`}
        </span>
        {/* Timerange Picker */}
        <span>-</span>
        <span
          className="btn btn-sm btn-gray-50 mx-2 pointer"
          onClick={handleToggleTimeRangePicker}
          style={{ opacity: selectedOption === TIME_RANGE_SLOT ? "1" : "0.3" }}
        >
          {`${endTime.hour} - ${endTime.min} ${endTime.shift}`}
        </span>
        time range.
      </label>
      {showTimeRangePicker && selectedOption === TIME_RANGE_SLOT && (
        <div
          className="card card--shadow-5 mb-0 px-20 py-10 text-left z-1 position-absolute"
          style={{ top: 38, zIndex: 2 }}
        >
          <div className="form-row align-items-center flex-nowrap">
            <div className="col">
              <Select
                styles={selectStyle}
                options={hourOptions}
                defaultValue={() => ({ value: startTime.hour, label: startTime.hour })}
                onChange={(selectedTime) => {
                  setStartTime({
                    ...startTime,
                    hour: selectedTime.value,
                  });
                  setValidationError("");
                }}
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
            <div className="col">
              <Select
                styles={selectStyle}
                options={minuteOptions}
                defaultValue={() => ({ value: startTime.min, label: startTime.min })}
                onChange={(selectedTime) =>
                  setStartTime({
                    ...startTime,
                    min: selectedTime.value,
                  })
                }
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
            <div className="col">
              <Select
                styles={selectStyle}
                options={shiftTimeOptions}
                defaultValue={() => ({ value: startTime.shift, label: startTime.shift })}
                onChange={(selectedShift) =>
                  setStartTime({
                    ...startTime,
                    shift: selectedShift.value,
                  })
                }
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
            <div className="col" style={{ maxWidth: 30 }}>
              <IconArrowLongRight />
            </div>
            <div className="col">
              <Select
                styles={selectStyle}
                options={hourOptions}
                defaultValue={() => ({ value: endTime.hour, label: endTime.hour })}
                onChange={(selectedTime) =>
                  setEndTime({
                    ...endTime,
                    hour: selectedTime.value,
                  })
                }
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
            <div className="col">
              <Select
                styles={selectStyle}
                options={minuteOptions}
                defaultValue={() => ({ value: endTime.min, label: endTime.min })}
                onChange={(selectedTime) =>
                  setEndTime({
                    ...endTime,
                    min: selectedTime.value,
                  })
                }
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
            <div className="col">
              <Select
                styles={selectStyle}
                options={shiftTimeOptions}
                defaultValue={() => ({ value: endTime.shift, label: endTime.shift })}
                onChange={(selectedShift) =>
                  setEndTime({
                    ...endTime,
                    shift: selectedShift.value,
                  })
                }
                className={`${validationError ? "border-red" : null}`}
              />
            </div>
          </div>
          <div className="my-10 text-danger">{validationError}</div>
          <div className="d-flex pt-30 justify-content-end">
            <button
              className="btn btn-lg btn-outline-light mr-10"
              onClick={() => {
                resetForm();
                setShowTimeRangePicker(false);
              }}
            >
              Cancel
            </button>
            <button className="btn btn-lg btn-primary" onClick={onConfirmTimeRange}>
              Apply Date
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default TimeRangePicker;
