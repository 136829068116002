import CellSpinner from "Components/Common/Spinner/CellSpinner";
import _ from "lodash";
import moment from "moment";
import Papa from "papaparse";
import { useContext, useEffect, useRef, useState } from "react";
import { amplitudeTrack, toastr, updateTerminology } from "services";
import {
  IconDecreaseArrow,
  Icondots,
  IconDownload,
  IconFilter,
  IconIncreaseArrow
} from "../../../../../../Components/Common/Icons";
import { COUNTER_CARDS_POPUP, COUNTER_CARDS_POPUP_NOT_REQUIRED, DASHBOARD_FILTER_APPLIES, REPORT_NAMES, REPORT_TYPES, COUNTER_CARDS_CSV_CHARTS_NAME } from "../../constants";
import { checkDateRange, convertChartFilterDataToStringArray, convertFilterDataToStringArray, downloadCsvFromBuffer, fixedNumer, getDataForChartFromDB, hasChartFilters, TypeDashBoardContext } from '../../helper';
import Popup from "../Popup";
import DashboardFilterDropdown from "./../DashBoardFilters/DashboardFilterDropdown";
import SkeletonCard from './SkeletonCard';
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import DashboardMenuPortal from "./DashboardMenuPortal";
/**
 * CounterCard component displays a card with a title, description, count, percentage change, and options for filtering and downloading data.
 * 
 * @param {Object} props - Component properties.
 * @param {String} props.title - The title of the card.
 * @param {String} props.description - The description of the card.
 * @param {Number} props.count - The initial count to display.
 * @param {Number} props.percentageChange - The initial percentage change to display.
 * @param {String} props.startDate - The start date for the data.
 * @param {String} props.endDate - The end date for the data.
 * @param {String} props.reportType - The type of report.
 * @param {Boolean} props.fromExpandedCard - Indicates if the card is expanded.
 * @param {Function} props.downloadCSV - Function to download CSV data.
 * @param {Boolean} props.isDownload - Indicates if the download option is available.
 * @param {Function} props.setIsPopup - Function to set the popup state.
 * @param {Boolean} props.isToFixedNeeded - Indicates if the count needs to be fixed.
 * @param {Boolean} props.percentageChangeNotRequired - Indicates if percentage change is not required.
 * @param {String} props.preFixOfcardCount - Prefix for the card count.
 * @param {Boolean} props.hideCounts - Indicates if counts should be hidden.
 * @param {Function} props.handleApplyFilterForChart - Function to apply filter for the chart.
 * @param {Boolean} props.isDownloadEnabled - Indicates if download should be enabled.
 */
const CounterCard = (props) => {
  const {
    title,
    description,
    count,
    percentageChange,
    startDate,
    endDate,
    reportType,
    fromExpandedCard,
    downloadCSV,
    isDownload,
    setIsPopup,
    isToFixedNeeded,
    percentageChangeNotRequired,
    preFixOfcardCount,
    hideCounts,
    handleApplyFilterForChart,
    isDownloadEnabled,
  } = props;

  const { filters, chartLevelFilter, activeTabName, isDraggable, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext);
  const isNegative = percentageChange < 0;
  const isPastDueInvoice = reportType === REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES;
  const [chartCount, setChartCount] = useState(count || 0);
  const [chartPercentageChange, setChartPercentageChange] = useState(percentageChange || 0);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCounterCardPopup, setIsCounterCardPopup] = useState(false);
  const [rowDataForPopup, setRowDataForPopup] = useState([]);
  const [filterPayload, setFilterPaylod] = useState(filters || {});
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const cellRef = useRef(null);
  const cellRefForDashboard = useRef(null);

  /**
   * Fetches data for the counter card based on the given parameters.
   * 
   * @param {String} from - The start date for the data.
   * @param {String} to - The end date for the data.
   * @param {Boolean} isDownload - Indicates if the data is for download.
   * @param {Object} filterData - The filter data.
   * @returns {Promise} A promise that resolves to the fetched data.
   */
  const getDataForCounterCard = async (from, to, isDownload, filterData) => {
    try {
      if (!isDownload) setIsLoading(true);

      const _startDate = from || fromDate;
      const _endDate = to || toDate;

      let payload = {
        chartType: reportType,
        fromDate: _startDate,
        toDate: _endDate
      };

      let responseData = {};
      if (isDownload) payload.isDownload = true;
      payload.filter = convertFilterDataToStringArray(filterData) || {};
      responseData = await getDataForChartFromDB(payload, reportType);

      if (responseData?.count > -1 && !isDownload) {
        setChartCount(responseData?.count);
      }
      if (responseData?.percentageChange || responseData?.percentageChange === 0) {
        setChartPercentageChange(responseData?.percentageChange);
      }
      setIsLoading(false);
      setIsDashboardFilterApplied(false)
      return responseData;
    } catch (error) {
      console.log("🚀 ~ getDataForCounterCard ~ error:", error);
      setIsLoading(false);
      setChartCount(0);
      setChartPercentageChange(0);
    }
  };

  /**
   * Handles the download of CSV data.
   */
  const handleOnDownload = async () => {
    setIsDownloading(true);
    if (downloadCSV) await downloadCSV();
    setIsDownloading(false);
  };

  /**
   * Closes the counter card popup.
   */
  const handleOnCloseOfCounterCardPopup = () => {
    setIsCounterCardPopup(false);
  };


  /**
   * This function is used to track the amplitude for chart filter.
   * @param {string} eventName - The name of the event to be tracked.
   */
  const amplitudeForChartFilter = (eventName) => {        
    // Convert the filter data to string array
    const filterData = convertFilterDataToStringArray(chartLevelFilter?.[reportType] ?? {});
    // Get the current time zone
    const timeZone = getTimeZone();

    // Format the start date in MM/DD/YYYY format
    const startDate = fromDate ? moment(fromDate).tz(timeZone).format('MM/DD/YYYY') : ""
    // Format the end date in MM/DD/YYYY format
    const endDate = toDate ? moment(toDate).tz(timeZone).format('MM/DD/YYYY') : ""

    // Define the event properties
    const eventProperties = {
        dashboard_name: activeTabName,
        chart_name: reportType,
        // If the filter data has keys, add the filters property with the filter data
        ...(Object.keys(filterData)?.length && {filters: JSON.stringify(filterData)}),
        // Add the filter data
        ...filterData,
        // If the start date is defined, add the fromDate property with the start date
        ...(startDate && {fromDate: startDate}), 
        // If the end date is defined, add the toDate property with the end date
        ...(endDate && {toDate: endDate}) 
    }
    // Track the amplitude event with the provided event name and properties
    amplitudeTrack(eventName, eventProperties)
}

  /**
   * Downloads CSV data for the counter card.
   */
  const downloadCSVForCounterCard = async () => {
    let csvBuffer = [];
    if (COUNTER_CARDS_POPUP_NOT_REQUIRED?.includes(reportType)) {
      if(!chartCount) {
        toastr.show("No data to download", "error")
        return
      }
      const csv = Papa.unparse(rowDataForPopup, { delimiter: ',' });
      csvBuffer = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    } else {
      csvBuffer = await getDataForCounterCard(startDate, endDate, true, chartLevelFilter?.[reportType] ?? {});
    }
    amplitudeForChartFilter('DOWNLOAD_REPORT')
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`);
  };

  useEffect(() => {
    if (!fromExpandedCard) {
      getDataForCounterCard();
    }
  }, []);

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return;
    setFromDate(startDate);
    setToDate(endDate);
    if (!fromExpandedCard) {
      setFilterPaylod(filters);
      getDataForCounterCard(startDate, endDate, false, filters);
    }
  }, [startDate, endDate, filters]);

  useEffect(() => {
    if (!isDashboardFilterApplied) return
    if (!fromExpandedCard) {
      getDataForCounterCard(startDate, endDate, false, filters);
    }
  }, [isDashboardFilterApplied])

  /**
   * Toggles the visibility of the filter dropdown.
   */
  const handleToggleFilterDropdown = () => {
    if(isDraggable) return
    setShowFilterDropdown(prevState => !prevState);
  };

  const handleOnClickOfPopup = () => {
    if(isDraggable) return
    setShowPopup(prevState => !prevState);
  }

  /**
   * Applies chart level filters.
   * 
   * @param {Object} chartLevelFilterData - The chart level filter data.
   */
  const handleChartLevelFilters = (chartLevelFilterData) => {
    let mergedFilter = _.cloneDeep(chartLevelFilterData)
    if (!chartLevelFilterData || Object.keys(chartLevelFilterData)?.length < 1) mergedFilter = {};
    if (!fromExpandedCard) {
      getDataForCounterCard(startDate, endDate, false, mergedFilter);
    } else {
      handleApplyFilterForChart(chartLevelFilterData);
    }
  };

  const helpMapper = (title) => {
    const mappings = {
      "Loads By Customer": `Loads By Customer`,
      "Customer Portal Usage": `Customer Portal Usage`,
      "Customer Portal Usage Percentage": `Customer Portal Usage Percentage`
    };
    return mappings[title] || title;
  }

  if (isLoading)
    return <SkeletonCard style={{ width: "100%", height: "100%" }} />;
  else
    return (
      <>
        <div className="px-20 py-15 d-flex flex-column">
          <div className="d-flex justify-content-between pb-15">
            <div className="">
              {title &&
                <div
                  className="font-14 font-medium line-height-20 text-capitalize"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    transition: 'text-decoration 0.3s'
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.textDecoration = 'underline'
                  }}
                  onMouseLeave={(e) => e.target.style.textDecoration = 'none'}
                  onClick={() => {
                    if(isDraggable) return
                    let eventProperties = {
                      report_name: reportType
                    };
                    amplitudeTrack("VIEW_COUNTER_CARD", eventProperties);
                    if (COUNTER_CARDS_POPUP_NOT_REQUIRED?.includes(reportType)) {
                      setRowDataForPopup([{
                        "Event Name": REPORT_NAMES[reportType],
                        "Count": chartCount || count || 0,
                      }]);
                      setIsCounterCardPopup(true);
                      return;
                    }
                    if (COUNTER_CARDS_POPUP.includes(reportType)) {
                      setIsCounterCardPopup(true);
                      return;
                    }
                    if(COUNTER_CARDS_CSV_CHARTS_NAME?.includes(reportType)){
                      setIsCounterCardPopup(true);
                      return
                    }
                    setIsPopup && setIsPopup(true);
                  }}
                >
                  {title}
                </div>}
              {description &&
                <div className="font-12 text-left text-muted font-weight-normal clamp-1 text-capitalize">
                  {description}
                </div>}
            </div>
            <div className="d-flex align-items-center gap-5">
              {(DASHBOARD_FILTER_APPLIES.includes(activeTabName) || hasChartFilters(reportType)) && <button className={`btn btn-outline-light wh-32px px-2 ${Object.keys(chartLevelFilter?.[reportType] || {}).length > 0 ? "btn--has-notification" : ""}`} onClick={handleToggleFilterDropdown} ref={cellRef}>
                <IconFilter />
              </button>}
              {showFilterDropdown &&
                <DashboardFilterDropdown
                  cellRef={cellRef}
                  setShowFilterDropdown={setShowFilterDropdown}
                  handleChartLevelFilters={handleChartLevelFilters}
                  reportType={reportType}
                  fromDate={fromDate}
                  toDate={toDate}
                />
              }
              {isDownload && (
                <div className="d-flex">
                  <button
                    className={`btn btn-outline-light wh-32px px-2 ${isDownloadEnabled ? 'disabled': ''}`}
                    onClick={() => {
                      if (isDownloading || isDraggable) return;
                      handleOnDownload();
                    }}
                    disabled={isDownloadEnabled}
                  >
                    {isDownloading ? <CellSpinner /> :
                      <IconDownload className="text-muted " />
                    }
                  </button>
                </div>
              )}
              <div className="d-flex align-items-center gap-5">
                <button
                  className={`btn h-32px w-20px px-2`}
                  onClick={handleOnClickOfPopup}
                  ref={cellRefForDashboard}
                >
                  <Icondots className="text-muted" />
                </button>
                {showPopup && (
                  <DashboardMenuPortal
                    cellRef={cellRefForDashboard}
                    setShowPopup={setShowPopup}
                    reportType={reportType}
                    title={title}
                  />
                )}
              </div>
            </div>
          </div>
          {!hideCounts && (chartCount || count || chartPercentageChange > 0 || (endDate && startDate)) && <div className="d-flex gap-10 align-items-center">
            <div
              className="font-weight-bold font-40 text-dark">
              {`${preFixOfcardCount ? preFixOfcardCount : ''}${isToFixedNeeded ? fixedNumer(chartCount || count || 0) : (chartCount || count || 0)}`}
            </div>
            <div>
              {!percentageChangeNotRequired && <>
                <div className="align-items-center d-flex gap-5">
                  {(isNegative || chartPercentageChange < 0) ? <IconDecreaseArrow className={isPastDueInvoice ? "text-success" : "text-danger"} /> : <IconIncreaseArrow className={isPastDueInvoice ? "text-danger" : "text-success"} />}
                  <span
                    className={`badge badge-pill badge-margin text-white ${isPastDueInvoice
                      ? (isNegative || chartPercentageChange < 0)
                        ? "bg-success-500"  // Green for negative change in past due invoices
                        : "bg-danger-500"   // Red for positive change in past due invoices
                      : (isNegative || chartPercentageChange < 0)
                        ? "bg-danger-500"   // Red for negative change in normal cases
                        : "bg-accent-500"   // Accent color for positive change in normal cases
                      }`}
                  >
                    {(isNegative || chartPercentageChange < 0) ? "" : "+"}
                    {chartPercentageChange || percentageChange || 0}%
                  </span>
                </div>
                {endDate && startDate &&
                  <div className="font-weight-normal font-12 text-gray-500">
                    {checkDateRange(startDate, endDate)}
                  </div>}
              </>}
            </div>
          </div>}
        </div>
        {isCounterCardPopup && <Popup
          rows={rowDataForPopup}
          downloadCSV={downloadCSVForCounterCard}
          reportType={reportType}
          isPopup={isCounterCardPopup}
          handleOnClose={handleOnCloseOfCounterCardPopup}
          fromDate={startDate}
          toDate={endDate}
        />}
      </>

    );
};

export default CounterCard;
