import React from "react";
import { IconChassis, IconTruckTracking } from "../Icons";

const EquipmentDetails = ({ load, layout = "horizontal", isGrayPool = false }) => {

  const getEquipmentText = (items) => items.filter(Boolean).join(", ");

  const containerText = getEquipmentText([
    isGrayPool ? load?.grayContainerNo : load?.containerNo,
    isGrayPool ? load?.grayContainerSizeName : load?.containerSizeName,
    isGrayPool ? load?.grayContainerTypeName : load?.containerTypeName,
  ]);

  const chassisText = getEquipmentText([
    isGrayPool ? load?.grayChassisNo : load?.chassisNo,
    isGrayPool ? load?.grayChassisSizeName : load?.chassisSizeName,
    isGrayPool ? load?.grayChassisTypeName : load?.chassisTypeName,
  ]);

  const isVertical = layout === "vertical";
  const containerDivClass = `d-flex ${isVertical ? "flex-column align-items-end" : "align-items-center gap-10"}`;
  const itemClass = `d-flex align-items-center gap-5 font-medium ${isVertical ? "gap-5" : "gap-3"}`;

  return (
    <div className={containerDivClass} data-layout={layout}>
      <div className={itemClass}>
        <IconTruckTracking className="text-muted" />
        <span>{containerText || "-"}</span>
      </div>
      <div className={itemClass}>
        <IconChassis className="text-muted" />
        <span>{chassisText || "-"}</span>
      </div>
    </div>
  );
};

export default EquipmentDetails;
