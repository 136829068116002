
import React from 'react'

const allFinancialPreferencesInput = [
    // {
    //     label:"Min. Pay (per day)",
    //     name: "minDriverPay",
    //     inputType:"number"
    // },
    // {
    //     label:"Mix. Pay",
    //     name: "maxDriverPay",
    //     inputType:"number"
    // },
    // {
    //     label:"Min. Amt. of Moves",
    //     name: "minAmtOfMoves",
    //     inputType:"number"
    // },
    // {
    //     label:"Max Amt. of Moves",
    //     name: "maxAmtOfMoves",
    //     inputType:"number"
    // },
    {
        label:"HST %",
        name: "hstPercentage",
        inputType:"number"
    },
   
]

const allFinancialPreferencesRadio= [
    {
        label:"Disable Driver Pay",
        name: "disableDriverPay",
        inputType:"radio",
       
    },
    {
        label:"Hide Settlements",
        name: "isSettlementHide",
        inputType:"radio",
        disabled:"isShiposCarrier"
    }
]
export const allFinancialPreferences = [
    ...allFinancialPreferencesInput,
    ...allFinancialPreferencesRadio
]
const FinancialPreferences = ({state,setValChange }) => {
    
    return (
        <div>
            <div className="font-14 font-medium mb-15">Financial Preferences</div>
            <div className='form-row'>
            {allFinancialPreferencesInput.map((item)=>(
                      <div className='col-md-3 form-group'>
                      <label className="label-control">{item.label}</label>
                      <input
                          type={item.inputType}
                          className="form-control"
                          placeholder={item.placeholder}
                          value={state[item.name] ? state[item.name]: ''}
                          onChange={(e)=>setValChange(item.name, e?.target?.value||'')}
                      />
                  </div>
            ))}
            <div className='col-md-3'>
                <div className='form-row'>
            {
                allFinancialPreferencesRadio.map((item)=>(
                    <div className='col-md-6 '>
                    <label>{item?.label}</label>
                    <div className="d-flex mt-2">
                        <div className="form-check mr-4">
                            <input
                                type="radio"
                                className={`form-check-input ${state[item?.disabled] ? "disabled-pointer-events":""}`}

                                id={`${item.name}_no`}
                                name={`${item.name}_no`}
                                checked={!state[item.name]}
                                onClick={()=>setValChange(item.name, false)}
                                disabled={state[item?.disabled]}
                            />
                            <label className="form-check-label" htmlFor={`${item.name}_no`}>
                                No
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                type="radio"
                                className={`form-check-input ${state[item?.disabled] ? "disabled-pointer-events":""}`}
                                id={`${item.name}_yes`}
                                name={`${item.name}_yes`}
                                checked={state[item.name]}
                                onClick={()=>setValChange(item.name, true)}
                                disabled={state[item?.disabled]}
                            />
                            <label className="form-check-label" htmlFor={`${item.name}_yes`}>
                                Yes
                            </label>
                        </div>
                    </div>
                </div>
                ))
            }
            </div>
            </div>
            </div>
        </div>
    )
}

export default FinancialPreferences