import React, {  useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { getTMSUsers } from "../../actionCreator";
import { makeOptionsForSelect } from "../../../../../../services/Common.services";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse ,errorColorResponse } from "../../../utility";
import { APPOINTMENT_STATUSES, APPOINTMENT_MESSAGES } from "../../../../../../constants";
import { useAwaitablePrompt, useCurrentUserSettings } from "../../../../../../hooks";
import { DUAL_TRANSACTION_MESSAGES, loadEvents } from "pages/tms/constant";

const EmptyOriginList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress , column }) => {
  const { openAwaitablePrompt, isAwaitablePrompt, AwaitablePromptAlert } = useAwaitablePrompt();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};

  const { shippers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const allEmptyOrigin = makeOptionsForSelect(
    shippers,
    "company_name",
    "address.address",
    "_id"
  );
  const [dList, setDList] = useState(allEmptyOrigin);
  const [cursor, setCursor] = useState(null);
  const [SearchEmptyOrigin, setSearchEmptyOrigin] = useState(null);
  const searchInputRef = React.useRef(null);
  const onSearch = useCallback(
    _.debounce((params) => getSearchData(params), 500),
    []
  );

  const cell = document.querySelector('[aria-selected="true"]');

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef,boxRef)

  useEffect(() => {
    searchInputRef.current.focus();
    window.document.getElementById('root').style.pointerEvents="none"
    return ()=>{
        window.document.getElementById('root').style.pointerEvents="auto"
    }
  }, []);

  useEffect(() => {
      onChangeDebounced();
  }, [SearchEmptyOrigin]);

  const onChangeDebounced = () => {
    onSearch(SearchEmptyOrigin);
  };

  const getSearchData = (params) => {
    const obj = { limit: 10 }
    if (params) {
      obj.nameSearch = params
      obj.customerType = ['ALL', 'containerReturn','shipper']
    }
    getTMSUsers(obj).then((data) => {
      setDList(data);
    });
  };

  useOnClickOutside(boxRef, () =>  {
    if (isAppointmentFeatureEnabled && isAwaitablePrompt) return;
    onClose(false);
  });

  const get = async (data) => {
    const showAwaitablePrompt = Row && isAppointmentFeatureEnabled && Row?.emptyAppointmentSystem && !Row?.emptyApptStatus?.includes(APPOINTMENT_STATUSES.CANCELLED);
    if(showAwaitablePrompt) {
      const confirmed = await openAwaitablePrompt();
      if (!confirmed) return;
    }

    if (Row) {
      const payload = {
        emptyOrigin: data.value,
        reference_number: Row.reference_number,
      };

      let returnContainers = [];
        Row?.driverOrder?.forEach((d) => {
          if (d.type === loadEvents.RETURNCONTAINER) {
            returnContainers.push(d);
          }
        })
      setIsAssignInProgress(true);
      editLoad(payload).then((el) => {
        onRowChange({ ...Row, emptyOrigin: data.allInfos });
        setIsAssignInProgress(false);
        onClose(false);
        successColorResponse(cell,column.idx, Row?.className);
      }).catch((err) => {
        onClose(false);
        setIsAssignInProgress(false);
        errorColorResponse(cell,column.idx, Row?.className)
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`empty-origin-${cursor}`).focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= dList.length - 1) {
        if (cursor === null) {
          setCursor(0);
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`empty-origin-${cursor}`).focus();
        }
      }

      event.preventDefault();
    } else if (event.keyCode === 13) {
      dList.forEach((val, index) => {
        if (index === cursor) get(val);
      });
      event.preventDefault();
    }else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false);
      event.preventDefault();
      event.stopPropagation();
    }

  };

  return createPortal(
    <div
      ref={boxRef}
      className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
      data-testid="empty-origin-list"
      style={Style}
    >
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder="Search RTN.."
          id="domTextElement"
          onChange={(e) => setSearchEmptyOrigin(e.target.value)}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          ref={searchInputRef}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul
        className="driverList m-0"
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        {dList.length === 0 ? (
          <p className="p-1 bg-light">No RTN available</p>
        ) : (
          dList.map((el, index) => (
            <li
              key={index}
              className={`popdrop__dropdown-item ${
                cursor === index ? "bg-gradient-light" : null
              }`}
              tabIndex="-1"
              id={`empty-origin-${index}`}
              onClick={async () => get(el)}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              {el.label}
            </li>
          ))
        )}
      </ul>
      <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENTo} />
    </div>,
    document.getElementById("pp-overlay-container")
  );
};
export default EmptyOriginList;
