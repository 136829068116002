import moment from "moment-timezone";
/*
 * Convert this:
 * 1300
 * 1200
 * 240
 *
 * to this:
 * {
 *  hour: "02",
 *  min: "01",
 *  shift: "AM"
 * }
 */
const convertMilitaryToTime = (militaryTime) => {
  if (militaryTime?.toString().length == 3) militaryTime = ("" + militaryTime).padStart(4, "0");
  const parsedTime = moment(militaryTime, "HH:mm");

  const formatted = {
    hour: `${parsedTime.format("h")}`?.padStart(2, "0")?.toString(),
    min: `${parsedTime.minute()}`?.padStart(2, "0")?.toString(),
    shift: parsedTime.format("A"),
  };

  return formatted;
};

/* Convert this:
 * {
 *  hour: "02",
 *  min: "01",
 *  shift: "AM"
 * }
 *
 * to this:
 * 1300
 * 1200
 * 240
 */
const convertTimeToMilitary = (normalTime) => {
  let time = `${normalTime?.hour}:${normalTime?.min} ${normalTime?.shift}`;
  const parsedTime = moment(time, "hh:mm A");
  const militaryFormat = parsedTime.format("HHmm");

  return militaryFormat;
};

const slotsCountTransform = (rawSlots, timeZone) => {
  return rawSlots.map((each) => {
    const { docDate } = each;

    const startDate = moment.tz(docDate.date, timeZone);
    const endDate = startDate.clone().add(1, "hour");
    const count = each?.docs?.length;

    return {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      count,
    };
  });
};

const validateTimeRange = (startTime, endTime) => {
  if(!startTime || !endTime) return false;
  const milStartTime = convertTimeToMilitary(startTime);
  const milEndTime = convertTimeToMilitary(endTime);

  if(Number(milStartTime) >= Number(milEndTime)) return false;
  return true;
};

export { convertMilitaryToTime, convertTimeToMilitary, slotsCountTransform, validateTimeRange };
