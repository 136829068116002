import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import EmptyOriginList from "../portals/EmptyOriginList";
import { getStorage } from "../../../../../../services/Common.services";
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const EmptyOrigin = ({ row, column, onRowChange, isCellSelected }) => {
	const cellRef = useRef()
	const prevSelectionRef = useRef();

	const [isEmptyOriginList, setIsEmptyOriginList] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);

	const showModel = () => {
		setIsEmptyOriginList(true);
	};

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setIsEmptyOriginList(true)
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected || row.type_of_load=="ROAD"){
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.emptyOrigin);
    }, [column, row?.colorOfCells?.emptyOrigin]);

	return (
	<>
		{row.type_of_load !== "ROAD" && <div ref={cellRef} className="cell-content" onClick={showModel} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
		
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && 
				
					row.emptyOrigin ? row.emptyOrigin.company_name : ""
				}
			{row?.isEditPermission && isEmptyOriginList && !isAssignInProgress && (
				<EmptyOriginList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsEmptyOriginList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
			)}
			
		</div>}
		{isColorPortal &&
        	<ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="emptyOrigin" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
        }
	</>
	);
};

export default EmptyOrigin;
