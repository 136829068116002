import React from "react";
import moment from "moment-timezone";
import { Modal } from "react-bootstrap";

import { IconCheckCircle } from "../../../../Components/Common/Icons";
import useCurrentUser from "../../../../hooks/users/useCurrentUser";

const ConfirmationModal = ({ isConfirmationModal, dates, onClose, isAllowedForDirectBooking }) => {
  const { currentUserTimeZone } = useCurrentUser();

  const formatMilitaryTime = (time) => {
    const formattedTime = moment(time, "HHmm").format("hh:mm A");
    return formattedTime;
  };

  const DateList = () => {
    return dates?.map((item, index) => (
      <ul key={index} className="border-0 mb-10 list-style-none">
        {item?.requestedAppointmentDateTimeSlots?.map((date, index) => (
          <>
            <li className="mt-10 border-0 text-primary">{item.containerNo}</li>
            <li key={index} className="border-0">
              <span>{moment(date?.appointmentDateTimeStart).tz(currentUserTimeZone).format("LLL")}</span>
              <span className="mx-1">•</span>
              <span>{moment(date?.appointmentDateTimeEnd).tz(currentUserTimeZone).format("LLL")}</span>
            </li>
          </>
        ))}
      </ul>
    ));
  };

  return (
    <Modal
      show={isConfirmationModal}
      onHide={() => {
        onClose(true);
      }}
      dialogClassName="modal-sm modal-dialog-centered"
    >
      <Modal.Body className="text-center pt-35">
        <IconCheckCircle className={"text-primary mb-15 w-54 h-54"} />
        <div className="font-20 font-weight-500 line-height-25 mb-15">
          {isAllowedForDirectBooking ? (
            <>
              The Appointment is successfully <br /> requested!
            </>
          ) : (
            <>
              The Appointment is successfully <br /> requested for
            </>
          )}
        </div>
        <div className={`bg-gray-50 px-20 py-10 ${isAllowedForDirectBooking ? "d-none" : ""}`}>
          {Array.isArray(dates) &&
            dates.map(
              (item, index) =>
                item && (
                  <React.Fragment key={index}>
                    {item.requestedAppointmentTimeSlots && (
                      <>
                        <div className="font-bold my-10 text-primary">{item?.containerNo}</div>
                        <span>{formatMilitaryTime(item.requestedAppointmentTimeSlots[0]?.appointmentTimeStart)}</span>
                        <span className="mx-1">•</span>
                        <span>{formatMilitaryTime(item.requestedAppointmentTimeSlots[0]?.appointmentTimeEnd)}</span>
                      </>
                    )}
                  </React.Fragment>
                )
            )}
          <DateList />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-dark" onClick={onClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
