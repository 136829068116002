import React, {useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import _ from "lodash";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { updateLoad, getTMSUsers } from "../../actionCreator";
import { makeOptionsForSelect, updateTerminology } from "../../../../../../services/Common.services";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse ,errorColorResponse } from "../../../utility";
import { isShowDeliveryReferenceField } from "Components/Common/functions";
const ConsigneeList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress ,column}) => {
  const { consignees } = useSelector((state) => state.TmsReducer, shallowEqual);
  const allConsignee = makeOptionsForSelect(
    consignees,
    "company_name",
    "address.address",
    "_id"
  );
  const [cList, setCList] = useState(allConsignee);
  const [cursor, setCursor] = useState(null);
  const [SearchConsignee, setSearchConsignee] = useState(null);
  const searchInputRef = React.useRef(null);
  const onSearch = useCallback(
    _.debounce((params) => getSearchData(params), 500),
    []
  );

  const cell = document.querySelector('[aria-selected="true"]');
  
  const boxRef = useRef();
  const Style = useBoxPosition(CellRef,boxRef)

  useEffect(() => {
    searchInputRef.current.focus();
    window.document.getElementById('root').style.pointerEvents="none"
    return ()=>{
      window.document.getElementById('root').style.pointerEvents="auto"
    }
  }, []);

  useEffect(() => {
      onChangeDebounced();
  }, [SearchConsignee]);

  const onChangeDebounced = () => {
    onSearch(SearchConsignee);
  };

  const getSearchData = (params) => {
    const obj = { limit: 10 }
    if (params) {
      obj.nameSearch = params
      obj.customerType = ['ALL', 'consignee']
    }
    getTMSUsers(obj).then((data) => {
      setCList(data);
    });
  };

  useOnClickOutside(boxRef, () => onClose(false));

  const get = (data) => {
    if (Row) {
      let updatedConsignee = Row?.consignee ? Row?.consignee?.map(cons => cons._id) : [];
      updatedConsignee[0] = data.value.toString();

      const payload = {
        consignee: [ ...updatedConsignee ],
        reference_number: Row.reference_number,
      };
      if (isShowDeliveryReferenceField()) {
        let _deliveryReference = _.cloneDeep(Row?.deliveryReference);
          if (_deliveryReference.length !== 0) {
            _deliveryReference[0].customerId = data.value.toString();
            _deliveryReference[0].customerName = data?.allInfos?.company_name;
          }
          payload['deliveryReference'] = _deliveryReference
      }
      let _deliveryTimes = _.cloneDeep(Row?.deliveryTimes)
      if (_deliveryTimes?.length) {
        _deliveryTimes[0].customerId = data.value.toString();
        payload['deliveryTimes'] = _deliveryTimes
      }
      setIsAssignInProgress(true);
      editLoad(payload).then((res) => {
        onRowChange({ ...Row, consignee: [...res.consignee] });
        setIsAssignInProgress(false);
        onClose(false);
        successColorResponse(cell,column.idx, Row?.className);
      }).catch((err) => {
        setIsAssignInProgress(false);
        onClose(false);
        errorColorResponse(cell,column.idx, Row?.className)
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`consignee-${cursor}`)?.focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= cList.length - 1) {
        if (cursor === null) {
          setCursor(0);
          document.getElementById(`consignee-${cursor}`)?.focus();
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`consignee-${cursor}`).focus();
        }
      }

      event.preventDefault();
    } else if (event.keyCode === 13) {
      cList.forEach((val, index) => {
        if (index === cursor) get(val);
      });
      event.preventDefault();
    }else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false)
      event.preventDefault();
      event.stopPropagation();
    }

  };

  return createPortal(
    <div
      ref={boxRef}
      className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
      data-testid="consignee-list"
      style={Style}
    >
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder={`Search ${updateTerminology("Consignee")}..`}
          id="domTextElement"
          onChange={(e) => setSearchConsignee(e.target.value)}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          ref={searchInputRef}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul
        className="driverList m-0"
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        {cList && cList.length === 0 ? (
          <p className="p-1 bg-light">No Options</p>
        ) : (
          cList?.map((el, index) => (
            <li
              key={index}
              className={`popdrop__dropdown-item ${
                cursor === index ? "bg-gradient-light" : null
              }`}
              tabIndex="-1"
              data-testid={`consignee-${index}`}
              id={`consignee-${index}`}
              onClick={async () => get(el)}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              {el.label}
            </li>
          ))
        )}
      </ul>
    </div>,
    document.getElementById("pp-overlay-container")
  );
};
export default ConsigneeList;
