import React, { useEffect, useState, useMemo, useRef } from "react";
import { getDriverTrackingStatus } from "../../../../utility";
import moment from "moment";
import Avatar from "Components/Common/Avatar";
import { liveEntitiesSelectors } from "pages/trucker/Tracking/store/selectors";
import { useLiveEntities } from "pages/trucker/Tracking/context/TrackingContext";
import CustomTooltip from "Components/Common/CustomTooltip";

function useDriverLiveMetadata(driver) {
  const liveEntities = useLiveEntities();

  return useMemo(() => {
    const currentLiveMetaData = liveEntitiesSelectors.getLiveEntityById(liveEntities, driver);
    if (!currentLiveMetaData) return null;
    return currentLiveMetaData;
  }, [liveEntities, driver]);
}

function LiveDriverAvatar({ driver, size }) {
  const [isHoveredAvatar, setIsHoveredAvatar] = useState(false);
  const defaultOption = getDriverTrackingStatus({});
  const [trackingStatusInfo, setTrackingStatusInfo] = useState(defaultOption);
  const avatarRef = useRef(null);

  const currentLiveMetaData = useDriverLiveMetadata(driver);

  useEffect(() => {
    if (currentLiveMetaData) {
      const { source, state, last } = currentLiveMetaData;
      const currentTime = moment();
      const lastUpdatedTime = moment(last);
      const differenceInMinutes = currentTime.diff(lastUpdatedTime, "minutes");
      const statusInfo = getDriverTrackingStatus({ state, source });
      if (differenceInMinutes <= 10) {
        setTrackingStatusInfo(statusInfo);
      } else {
        setTrackingStatusInfo(defaultOption);
      }
    } else {
      setTrackingStatusInfo(defaultOption);
    }
  }, [currentLiveMetaData]);

  const avatarSizeClass = size === "sm" ? "avatar-xs" : ""; // Check if size is 'sm'
  const statusCircleSizeClass = size === "sm" ? "status-circle--sm" : ""; // Check if size is 'sm'

  return (
    <React.Fragment>
      <div
        className="flex-shrink-0 align-self-start position-relative"
        onMouseEnter={() => setIsHoveredAvatar(true)}
        onMouseLeave={() => setIsHoveredAvatar(false)}
        ref={avatarRef}
      >
        <Avatar
          src={driver.profilePicture}
          className={avatarSizeClass}
          children={`${driver?.name?.charAt(0)}${driver?.lastName?.charAt(0)}`}
        />
        <span
          className={`mr-10 status-circle d-inline-block position-absolute bottom-0 ${statusCircleSizeClass} ${
            trackingStatusInfo?.className || ""
          }`}
          style={{ right: -10 }}
        ></span>
      </div>
      {isHoveredAvatar && (
        <CustomTooltip 
          isOpen={isHoveredAvatar} 
          refNo={avatarRef?.current}
        >
          <span className={`flex-shrink-0 mr-10 status-circle d-inline-flex ${trackingStatusInfo?.className}`}></span>
          {trackingStatusInfo?.text}
        </CustomTooltip>
      )}
    </React.Fragment>
  );
}

export default React.memo(LiveDriverAvatar);
