export const completedStatus = [
    "COMPLETED", "BILLING", "APPROVED", "UNAPPROVED", "REBILLING", "PARTIALLY_PAID", "FULL_PAID"
]

export const CABOTAGE_CODE_STATUS = Object.freeze({
    VIOLATED: 'VIOLATED',
    NOT_VIOLATED: 'NOT_VIOLATED',
});

export const CABOTAGE_VIOLATION_MESSAGE = Object.freeze({
    MAX_CABOTAGE_EXCEEDED: {
        message: 'This action will exceed the maximum allowed cabotage limit.',
        code: 'VIOLATED'
    },
    COOLDOWN_NOT_EXPIRED: {
        message: 'The cooldown period has not expired for this action.',
        code: 'VIOLATED'
    },
    SHOULD_EXIT_BY_VIOLATION: {
        message: 'Maximum Number of allowed days exceeded.',
        code: 'VIOLATED'
    },
    NOT_VIOLATED: {
        message: 'No cabotage violation detected.',
        code: 'NOT_VIOLATED'
    },
    NOT_APPLICABLE : {
        message: 'Cabotage is not applicable to this change.',
        code: 'NOT_VIOLATED'
    }
});

export const APPOINTMENT_DATE_LABEL = Object.freeze({
    START: 'appointmentStart',
    END: 'appointmentEnd',
}); 

export const dateUtilsObj = {
    "HH:mm": "99:99",
    "hh:mm": "99:99",
    "HH:mm A": "99:99 aa",
    "hh:mm A": "99:99 aa",

    "DD/MM/YY": "99/99/99",
    "DD-MMM-YY": "99-aaa-99",
    "DD-MM-YY": "99-99-99",
    "MM/DD/YY": "99/99/99",
    "MMM-DD-YY": "aaa-99-99",
    "MM-DD-YY": "99-99-99",

    "DD/MM/YY hh:mm A": "99/99/99 99:99 aa",
    "DD-MMM-YY hh:mm A": "99-aaa-99 99:99 aa",
    "DD-MM-YY hh:mm A": "99-99-99 99:99 aa",
    "MM/DD/YY hh:mm A": "99/99/99 99:99 aa",
    "MMM-DD-YY hh:mm A": "aaa-99-99 99:99 aa",
    "MM-DD-YY hh:mm A": "99-99-99 99:99 aa",

    "DD/MM/YY HH:mm": "99/99/99 99:99",
    "DD-MMM-YY HH:mm": "99-aaa-99 99:99",
    "DD-MM-YY HH:mm": "99-99-99 99:99",
    "MM/DD/YY HH:mm": "99/99/99 99:99",
    "MMM-DD-YY HH:mm": "aaa-99-99 99:99",
    "MM-DD-YY HH:mm": "99-99-99 99:99",
}
export const CARRIER_REMOVE_MESSAGE = 'Vendor has already accepted tender. Are you sure you want to remove this Vendor? Vendor pay will remain for removed vendors after tender acceptance.'
