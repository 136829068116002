import { useEffect, useMemo, useReducer } from "react";
import reducers from "../store/reducer";
import { APPOINTMENT_TIME_CATEGORY } from "../constants";

/**
 * useAppointmentSystem is a companion hook for AppointmentSystem component
 */
const useAppointmentSystem = ({ timeZone, handleSlotSelection = () => {} }) => {
  const defaultState = {
    dateTimeSlots: [],
    timeSlots: [],
    timeCategory: APPOINTMENT_TIME_CATEGORY.TIME_RANGE_SLOT,
  };

  const [state, dispatch] = useReducer(reducers, defaultState);

  const selectedTimeSlots = useMemo(() => {
    return state.timeSlots;
  }, [state]);

  const selectedDateTimeSlots = useMemo(() => {
    return state.timeSlots;
  }, [state]);

  const timeCategory = useMemo(() => {
    return state.timeCategory;
  });

  useEffect(() => {
    handleSlotSelection({
      category: state.timeCategory,
      dateTimeSlots: state.dateTimeSlots,
      timeSlots: state.timeSlots,
    });
  }, [state]);

  return {
    form: state.form,
    dispatch,
    selectedTimeSlots,
    selectedDateTimeSlots,
    timeCategory,
    state,
  };
};

export default useAppointmentSystem;
