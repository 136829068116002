import React from "react";
import { debounce } from "lodash";
import { IconStarOutline, IconStar } from "Components/Common/Icons";
import { isExternalEmail } from "../helper";
import { EMAIL_TOOL_TIPS, PERMISSION_ERROR, AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant";
import { amplitudeTrack } from "services";
import ReactTooltip from "react-tooltip";

const StarEmailToggle = ({ row, toggleStar, loaderIds, eventProperties }) => {
  const isStarred = !!row.starred;

  // Function to toggle the star status
  const onToggleStar = debounce((e) => {
    toggleStar({ ids: [row.id], isStar: !isStarred });
    if(isStarred){
      amplitudeTrack(AMPLITUDE_EVENTS.UNSTAR , eventProperties)
    }
    else{
      amplitudeTrack(AMPLITUDE_EVENTS.STAR , eventProperties)
    }
  }, 500);

  const isExternalMessage = isExternalEmail(row?.grantId)
  const toolTip = isExternalMessage ? PERMISSION_ERROR.CONNECT_MAIL : isStarred ? EMAIL_TOOL_TIPS.STARRED : EMAIL_TOOL_TIPS.NOT_STARRED

  return (
    <>
      {!!loaderIds.length && loaderIds.includes(row.id) ? (
        <span key={row.id} className="spinner-border spinner-border-sm batch-menu__itemicon" />
      ) : (
          <div
            className={`pl-0 ${isExternalMessage ? "pointer-not-allowed" : ""}`}
            data-tip={toolTip}
            data-for={`email-table-tooltip`}
            key={row.id}
            onClick={(e) => {
              if (!isExternalMessage) {
                e.stopPropagation();
                onToggleStar(e)
              }
              ReactTooltip.hide();
            }}
          >
          <span>{isStarred ? <IconStar className="text-primary" /> : <IconStarOutline className="text-muted" />}</span>
        </div>
      )}
    </>
  );
};

export default StarEmailToggle;
