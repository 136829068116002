import React from "react";
import LiveDriverAvatar from "./LiveDriverAvatar";
import LiveAddressAndTime from "./LiveAddressAndTime";
import { HosAvailability } from "Components/Common/DriversList/HosAvailability";
import { IsELDEnabled } from "services";
import PhoneNumberWithCopy from 'Components/Common/PhoneNumberWithCopy';

const CardHeader = ({ driver, currentLiveLoad, latestAssignedLoad, onClickName = () => { } }) => {

  return (
    <div className="d-flex align-items-start gap-7 px-10">
      <LiveDriverAvatar driver={driver} />
      <div className="flex-grow-1 text-truncate">
        <div className="d-flex align-items-start">
        <div className="d-flex flex-column">
        <span
          className="text-dark font-16 font-medium line-height-20 text-truncate mw-100 pointer"
          onClick={onClickName}
        >
          {driver?.name?.capitalize()} {driver?.lastName?.capitalize()}
        </span>
        {driver?.driver?.mobile &&
          <PhoneNumberWithCopy 
            mobile={driver?.driver?.mobile}
          />
        }

        <LiveAddressAndTime driver={driver} />
        </div>

       
      <div className="ml-auto text-right mw-50">
        <span className="badge badge-sm badge-light badge-driver">{driver.driver?.truck?.equipmentID}</span>
        <div className="text-muted">
          {currentLiveLoad?.containerNo || latestAssignedLoad?.containerNo ? (
            <div className="text-muted">{currentLiveLoad?.containerNo ?? latestAssignedLoad?.containerNo}</div>
          ) : (
            "-"
          )}
        </div>
      </div>
      </div>
      {IsELDEnabled() && driver?.driver?.isEldAssigned && <HosAvailability className="mt_5" hosAvailability={driver?.driver?.hosAvailability}/>}
      </div>
    </div>
  );
};

export default CardHeader;
