import { smallSelectStyle } from "assets/js/select-style";
import React, { useEffect, useState,useLayoutEffect, useRef } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import CustomTooltip from "Components/Common/CustomTooltip";
import { useHover } from "hooks";

export const TooltipWrapper = ({ children, content }) => {
  const [ref, isHovered] = useHover();
  return (
    <>
      <span ref={ref} className="text-truncate hover:text-gray-900">
        {children}
      </span>
      <CustomTooltip isOpen={isHovered} refNo={ref.current}>
        {content}
      </CustomTooltip>
    </>
  );
};

export const BadgeTooltipWrapper = ({ count, children, className }) => {
  const [badgeRef, isBadgeHovered] = useHover();
  return (
    <>
      <span ref={badgeRef} className={`badge badge-sm ml-1 badge-secondary hover-bg-gray-700 ${className}`}>
        {children}
      </span>
      <CustomTooltip isOpen={isBadgeHovered} refNo={badgeRef.current}>
        {count}
      </CustomTooltip>
    </>
  );
};

export const calculateVisibleOptions = (selectedOptions, updateLabel, valueContainerWidth, isCustom) => {
  if (!isCustom) {
    return 3;
  }

  let totalWidth = 0;
  let visibleOptions = 0;
  
  // Loop through selected options and check how many fit
  for (let i = 0; i < selectedOptions.length; i++) {
    const option = updateLabel ? updateLabel(selectedOptions[i]) : selectedOptions[i];

    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.textContent = `${option},`;

    document.body.appendChild(span);

    const optionWidth = span.offsetWidth;
    document.body.removeChild(span);
    if ((totalWidth + optionWidth) <= (valueContainerWidth - 40)) {
      visibleOptions++;
      totalWidth += optionWidth;
    } else {
      break;
    }
  }
  return visibleOptions;
};


export const ValueContainer = ({ children,updateLabel,isCustom,name, ...props }) => {
  let [values, input] = children;
  const [valueContainerWidth, setValueContainerWidth] = useState(0);

  const calculateVisibleOption = (selectedOptions) => {
    return calculateVisibleOptions(selectedOptions, updateLabel, valueContainerWidth, isCustom);
  };
   
    if (Array.isArray(values)) {
      const val = (i) => values[i].props.children;
      const { length } = values;
      
      if (length > 0) {
        const selectedOptions = values.map((option, index) => val(index));
        const visibleOptions = calculateVisibleOption(selectedOptions);

        const displayText = length <= visibleOptions ? (
          <div className={`d-flex align-items-center ${isCustom ? "" :"text-truncate"}`}>
            {selectedOptions.map((option, index) => (
              <React.Fragment key={index}>
                <TooltipWrapper content={<span>{updateLabel ? updateLabel(option) : option}</span>}>
                 {updateLabel ? updateLabel(option) : option}
                </TooltipWrapper>
                {index < selectedOptions.length - 1 && ","}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className={`d-flex align-items-center ${isCustom ? "" :"text-truncate"}`}>
            {selectedOptions.slice(0,visibleOptions).map((option, index) => (
              <React.Fragment key={index}>
                <TooltipWrapper content={<span>{updateLabel ? updateLabel(option) : option}</span>}>
                  {updateLabel ? updateLabel(option) : option}
                </TooltipWrapper>
                {index < (visibleOptions - 1) && ","}
              </React.Fragment>
            ))}
            {!props?.selectProps?.className?.includes("not-show-count") ? <BadgeTooltipWrapper count={
              <ul className="d-flex flex-column gap-5 mb-0">
                {selectedOptions.slice(visibleOptions).map((option, index) => (
                  <li key={index}>{updateLabel ? updateLabel(option) : option}</li>
                ))}
              </ul>
            }>
              +{length - visibleOptions}
            </BadgeTooltipWrapper> :<></>}
          </div>
        );
  
        values = <>{displayText}</>;
      }
    } 
    
 
  useLayoutEffect(() => {
    const updateWidth = () => {
      if(!name){
        return
      }
      const valueContainerElement = document?.querySelector?.(`.${name}`);
      if (valueContainerElement.offsetWidth) {
        setValueContainerWidth(valueContainerElement.offsetWidth);
        // console.log("width:",valueContainerElement.offsetWidth);
      }
    };

    updateWidth();  // Calculate width immediately after render

    const handleResize = () => {
      updateWidth();
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // This only runs once after the first render


  return (
    <components.ValueContainer {...props} className={`flex-nowrap ${name}`}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const CheckboxAsyncSelect = (props) => {
  const {options, onChange, value, loadOptions, className, isClearable, isAsync, name, defaultOptions, placeholder, isDisabled, styles, onBlur, closeMenuOnSelect=false, formatOptionLabel,isCustom} = props;
  const [selectedOptions, setSelectedOptions] = useState(value);
  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);
  const [menuIsOpen, setMenuIsOpen] = useState(false); 
  const selectRef = useRef(null);

  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    onChange(selectedValues);
  };

  const customOption = ({ innerProps, label, value, isSelected }) => {
    return (
      <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
        <input type="checkbox" checked={isSelected} onChange={() => {}} className="mr-10" />
        {label}
      </div>
    );
  };

  useEffect(() => {
   if(isCustom){
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false); // Close the menu if clicked outside
      }else if(selectRef.current && selectRef.current.contains(event.target) && !isDisabled){
        setMenuIsOpen(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }
  }, []);

  return (
    <div ref={selectRef}>
      { isAsync ? <AsyncSelect
        defaultOptions={defaultOptions || options}
        isMulti
        styles={styles || smallSelectStyle}
        components={{ Option: customOption,...(props.components || {}), ValueContainer: props.components?.ValueContainer || ValueContainer}}
        value={selectedOptions}
        onChange={handleChange}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        MultiValueRemove={false}
        isClearable={isClearable && isClearable}
        loadOptions={loadOptions && loadOptions}
        className={className && className}
        name={name && name}
        placeholder={placeholder && placeholder}
        isDisabled={isDisabled && isDisabled}
        menuIsOpen={isCustom ? menuIsOpen : undefined}
        onFocus={()=> {if(isCustom && !isDisabled){setMenuIsOpen(true)}}}
        onBlur={onBlur && !isCustom ? onBlur : ()=>setMenuIsOpen(false)}
      />
      :
      <Select
        options={options}   
        isMulti
        styles={styles || smallSelectStyle}
        components={{ Option: customOption, ValueContainer,...(props.components || {})}}
        value={selectedOptions}
        onChange={handleChange}
        isClearable={isClearable && isClearable}
        className={className && className}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        onBlur={onBlur && !isCustom ? onBlur : ()=>setMenuIsOpen(false)}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled && isDisabled}
        menuIsOpen={isCustom ? menuIsOpen : undefined}
        onFocus={()=> {if(isCustom && !isDisabled){setMenuIsOpen(true)}}}
      />
      }
    </div>
  );
};

export default CheckboxAsyncSelect;
