import React, { useEffect, useRef, useState, useMemo } from "react";
import { browserHistory } from "react-router";
import moment from "moment-timezone";
import { IconAngleArrowLeft, IconAngleArrowRight, IconCircleClose, IconCloseBold } from "../../../../../../Components/Common/Icons";
import { DateTimeFormatUtils , isNewAppointmentUIEnabled } from "../../../../../../services";
import useOnClickOutsideMany from "../../CustomHooks/useOnClickOutsideMany";
import { useDropDownPosition } from "../../../../../../hooks/helpers/useDropDownPosition";
import ScheduleButton from "../../../../Load/Components/ScheduleButton";
import AutoBookAppointmentButton from "../../../../Load/Components/AutoBookAppointmentButton";
import ManualBookingButton from "pages/tms/Load/Components/ManualBookingButton";

const DateTimePicker = ({ defaultDate, isShowTime, onChange, OnBlurUpdate, onRemoveDate, displayTimeZone, hideShowSelectDate, setShow, className,inputRef, handleInputOnBlur, openAppointmentModal, popupPlacement, isPortAccepted, disableFromThisday, disableUptoThisday, datePickerCellRef, loadId, loadStatus, moveType , openManualBooking, load, isAppointmentProcessing, appointmentId, autoAppointmentId, isAllowedForDirectBooking }) => {
	if (moment(defaultDate, moment.ISO_8601, true).isValid()) {
		defaultDate = moment(defaultDate).tz(displayTimeZone).format('lll');
	}
	const today = moment().startOf("day");
	const [selectedDay, setSelectedDay] = useState(defaultDate);
	const [isTime, setIsTime] = useState(false);
	const [currentMonth, setCurrentMonth] = useState(defaultDate ? defaultDate:today);
	const firstDayCurrentMonth = moment(currentMonth).startOf("month")
	
	const boxRef = useRef();
	const refArray = datePickerCellRef
	? [boxRef, datePickerCellRef] // If datePickerCellRef exists
	: inputRef
	? [boxRef, inputRef] // If inputRef exists and datePickerCellRef does not
	: [boxRef]; // Default case with only boxRef

	useOnClickOutsideMany(refArray, async () => {
		if (OnBlurUpdate) {
			await OnBlurUpdate();
		}
		// Now, setShow(false) after onBlurUpdate is completed
		setShow && setShow(false);
	});
	  
	const [yearPage,setYearPage] = useState(0);
	const [isSelectYear,setIsSelectYear] = useState(false);
	const [isSelectMonth,setIsSelectMonth] = useState(false);
	const displayYear = Number(currentMonth ? moment(currentMonth).year() : moment().year())
	const displayMonth = Number(currentMonth ? moment(currentMonth).month() : moment().month())
	const disableUptoThisdayMoment = disableUptoThisday ? moment(disableUptoThisday).isValid() ? moment(disableUptoThisday).startOf("day") : null : null;
	const disableFromThisdayMoment = disableFromThisday ? moment(disableFromThisday).isValid() ? moment(disableFromThisday).startOf("day") : null : null;
	useEffect(()=>{
		setSelectedDay(defaultDate)
	},[defaultDate])

	const checkIfDateShouldBeDisabled = (date) => {
		let ans = false;
		if(disableUptoThisdayMoment && moment(date).startOf("day").diff(disableUptoThisdayMoment) < 0)
		ans = true
		if(disableFromThisdayMoment && moment(date).startOf("day").diff(disableFromThisdayMoment) > 0)
		ans = true

		return ans
	}
	const previousMonth = (e) => {
		e.preventDefault();
		let firstDayNextMonth = moment(firstDayCurrentMonth)
			.subtract(1, "month")
			.startOf("month")

		setCurrentMonth(firstDayNextMonth);
	};

	const nextMonth = (e) => {
		e.preventDefault();
		let firstDayNextMonth = moment(firstDayCurrentMonth)
			.add(1, "month")
			.startOf("month")
		setCurrentMonth(firstDayNextMonth);
	};

	const days = (startDate, endDate) => {
		var now = startDate,
			dates = [];

		while (now.isSameOrBefore(endDate)) {
			dates.push(now.format("MM/DD/yyyy"));
			now.add(1, "days");
		}
		return dates;
	};

	const time = () => {
		const starOfDay = moment(today).startOf("day"),
			endOfDay = moment(today).endOf("day"),
			time = [];

		while (starOfDay.isSameOrBefore(endOfDay)) {
			time.push(starOfDay.format("lll"));
			starOfDay.add(15, "m");
		}
		return time;
	};

	const handleOnMonthYearChange =(isYear,selectedState)=>{
		if(isYear){
			let firstDayNextMonth = moment(firstDayCurrentMonth).year(selectedState)
			setCurrentMonth(firstDayNextMonth);
		}else{
			let firstDayNextMonth = moment(firstDayCurrentMonth).month(selectedState)
			setCurrentMonth(firstDayNextMonth);
		}
		setIsSelectYear(false)
		setIsSelectMonth(false)
	}
	const OnHandleChange = (val, _time) => {
		let selectedDate = moment(val).startOf("day").format("MM/DD/yyyy");
		if (isTime || _time) {
			const time = moment(_time).format("hh:mm A");
			const date = selectedDate + " " + time;
			setSelectedDay(date);

			const _date = moment.tz(date, displayTimeZone)
				.add("minutes", moment(date).tz(displayTimeZone).utcOffset() * -1)
				.toISOString();
			onChange(_date);
		} else {
			setSelectedDay(selectedDate);

			const _date = moment.tz(selectedDate, displayTimeZone)
				.add("minutes", moment(selectedDate).tz(displayTimeZone).utcOffset() * -1)
				.toISOString();
			onChange(_date);
		}
	};

	const selectedTime = moment(selectedDay).format(DateTimeFormatUtils.timeFormat());
	const yearOptions = [-7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map(z=>z+displayYear-(yearPage*15))
	const monthOptions = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	const options = isSelectYear ? yearOptions :( isSelectMonth ? monthOptions : []);
	const dateTimePopupStyles = useDropDownPosition(popupPlacement, boxRef, datePickerCellRef);
	const positionClass = `${(!popupPlacement || popupPlacement === "auto") && datePickerCellRef  ? "position-fixed" : "position-relative"}`;
	const innerPositionClass = `${(!popupPlacement || popupPlacement === "auto") && datePickerCellRef  ? "" : "position-absolute"}`;

	return (
		<div id={"pp-custom-datepicker"} ref={boxRef} className={`${positionClass}`} style={{zIndex: 1051}}>
			{(isSelectMonth || isSelectYear) ?
		<div className={`${isShowTime ? `date-time-dropdown__menu` : `date-dropdown__menu`} mb-0 shadow-5 card ${className ? className : ''} date-time-picker-ref ${innerPositionClass}`} style={dateTimePopupStyles}>
			<div className="d-flex border_top">
				<div className="calendar-picker__calendar w-100 p-10 pb-0">
					<div className="calendar-picker__calendar-nav d-flex justify-content-between align-items-center mt-20 px-10">
						<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
							{isSelectYear && <div>
								<span
									className="text-dark"
									onClick={(e) => setYearPage(yearPage+1)}
								>
									<IconAngleArrowLeft />
								</span>
							</div>}
						</div>
						<div onClick={()=>{
							setIsSelectYear(false)
							setIsSelectMonth(false)
						}} className="cursor-pointer calendar-picker__calendar-nav-month font-weight-bold font-14 text-dark position-relative">
							Select {isSelectMonth && "Month"} {isSelectYear && "Year"}
						</div>
						<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
							{isSelectYear && <div>	
								<span
									className="text-dark"
									onClick={(e) => setYearPage(yearPage-1)}
								>
									<IconAngleArrowRight />
								</span>

							</div>}
						</div>
					</div>
					<div className="calendar-week d-flex justify-content-between align-items-center">
						<div className="calendar-day grid w-100 grid-cols-3 text-gray-900 mt-20">
							{options.map((year, index) => {
								
								const isSame = isSelectYear ? displayYear === year : displayMonth === index
								return (
									<div
										key={index}
										className={`${colStartClasses[index%3]}`}
									>
										<div
											onClick={(e) =>  {
												e.stopPropagation();
												handleOnMonthYearChange(isSelectYear,isSelectYear ? year : index)}
											}
											className={`calendar-year__cell ${isSame && "text-white"}
												${isSame  && "bg-black"}
												${isSame  && "bg-black"}
												${isSame && "font-semibold"}
											}`}
										>
											{year}
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				{isShowTime && <div className="calendar-picker__sidebar">
					<div className="p-10 p-15 pb-0 pr-1">
					<ul
						className="timing_list m-0 grid grid-cols-3 pr-10 text-gray-900"
						style={{ maxHeight: "287px", overflowY: "auto" }}
					>
						{time().map((el, i) => {
							const _disTime = moment(el).format(DateTimeFormatUtils.timeFormat());
							return (
								<li
									key={"times" + i}
									className={`d-flex justify-content-center align-items-center pointer text-center rounded list-unstyled
										py-2 rounded-10 ${selectedTime === _disTime ? 'bg-primary text-white' : ''}`}
									onClick={() => {
										OnHandleChange(selectedDay ? selectedDay : today, el);
										setIsTime(true);
									}}
								>
									{_disTime}
								</li>
							)
						})}
					</ul>
					</div>
				</div>}
			</div>
			</div>
			:
			<div className={`${isShowTime ? `date-time-dropdown__menu` : `date-dropdown__menu`} mb-0 shadow-5 card ${className ? className : ''} date-time-picker-ref ${innerPositionClass}`} style={dateTimePopupStyles}>
				{!hideShowSelectDate && <div className="form-group p-10 m-0 d-flex justify-content-center justify-content-between">
				<div className={`form-control datetime-input__value w-200 text-gray-400 d-flex position-relative ml-10 ${isShowTime ? 'align-items-center' : 'justify-content-between'}`}>
						<div className="position-relative">
							<input
								className="border-0"
								type="text"
								placeholder="Select Date"
								value={
									(selectedDay && !isShowTime)
										? moment(selectedDay).format(DateTimeFormatUtils.fullDateFormat())
										: (selectedDay && isShowTime)
											? moment(selectedDay).format(DateTimeFormatUtils.fullDateTimeFormat())
											: ""
								}
								onChange={() => {}}
							/>
						</div>
						{selectedDay && (
							<div className="input-icon">
								<span
									className="pointer"
									onClick={() => {
										onRemoveDate && onRemoveDate();
										setSelectedDay(null);
									}}
								>
									<IconCircleClose className="fill-primary" />
								</span>
							</div>
						)}
					</div>
					<div
						className="d-flex justify-content-center align-items-center pointer mr-10 gap-10"
						
					>
						<ManualBookingButton openManualBooking={()=>openManualBooking()} load={load} isPortAccepted={isPortAccepted} moveType={moveType} loadStatus={loadStatus} />
							{
								(isNewAppointmentUIEnabled() ? (
									<AutoBookAppointmentButton
										moveType={moveType}
										loadStatus={loadStatus}
										openAppointmentModal={openAppointmentModal}
										isPortAccepted={isPortAccepted}
										isAppointmentProcessing={isAppointmentProcessing}
									/>)
									:
									(<ScheduleButton
										moveType={moveType}
										loadStatus={loadStatus}
										openAppointmentModal={openAppointmentModal}
										isPortAccepted={isPortAccepted}
										isAppointmentProcessing={isAppointmentProcessing}
									/>)
								)
							}
						<div onClick={() => OnBlurUpdate()} >
							<IconCloseBold />
						</div>
					</div>
				</div>}
				<div className="d-flex border_top">
					<div className="calendar-picker__calendar w-100 p-10 pb-0">
						<div className="calendar-picker__calendar-nav d-flex justify-content-between align-items-center my-15 px-10">
							<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
								<div>
									<span
										className="text-dark"
										onClick={(e) => previousMonth(e)}
									>
										<IconAngleArrowLeft />
									</span>
								</div>
							</div>
							<div className="d-flex">

							<p 
								onClick={(e) => {
									e.stopPropagation(); // Prevent the click from propagating to the outer container
									setIsSelectMonth(true);
								  }}
								className="cursor-pointer calendar-picker__calendar-nav-month font-medium font-14 text-dark position-relative"
							>
								{moment(currentMonth).format("MMMM")}{", "}
							</p>
							<p 
								onClick={(e) => {
									e.stopPropagation(); // Prevent the click from propagating to the outer container
									setIsSelectYear(true);
								  }}
								className="cursor-pointer calendar-picker__calendar-nav-month font-medium font-14 text-dark position-relative"
							>
								{moment(currentMonth).format("yyyy")}
							</p>
							</div>
							<div className="calendar-picker__calendar-nav-controls d-flex justify-content-between align-items-center pointer">
								<div>	
									<span
										className="text-dark"
										onClick={(e) => nextMonth(e)}
									>
										<IconAngleArrowRight />
									</span>

								</div>
							</div>
						</div>
						<div className="grid grid-cols-7 my-10">
							{["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((el, i) =>
								<div key={el+i} className="calendar-weekday d-flex justify-content-center text-gray-900 font-semibold">
									{el}
								</div>
							)}
						</div>
						<div className="calendar-week d-flex justify-content-between align-items-center">
							<div className="calendar-day grid w-100 grid-cols-7 text-gray-900">
								{days(
									moment(firstDayCurrentMonth),
									moment(firstDayCurrentMonth).endOf("month")
								).map((day, index) => {
									const isToday =
										moment(0, "HH").diff(moment(day), "days") == 0;
									
									const _currentDay = moment(day).format("MM/DD/yyyy")
									const _selectedDay = selectedDay ? moment(selectedDay).format("MM/DD/yyyy") : null;
									const isSame = _currentDay === _selectedDay;

									return (
										<div
											key={index}
											className={` ${index === 0 && colStartClasses[moment(day).day()]}`}
										>
											<div
												onClick={() => !((disableUptoThisdayMoment || disableFromThisdayMoment) && checkIfDateShouldBeDisabled(day)) && OnHandleChange(day)}
												className={`calendar-day__cell ${isSame && "text-white"} 
															${!((disableUptoThisdayMoment || disableFromThisdayMoment) && checkIfDateShouldBeDisabled(day)) && !isSame && isToday && "today_dateseleted"}
															${isSame && isToday && "bg-black"}
															${isSame && !isToday && "bg-black"}
															${(isSame || isToday) && "font-semibold"}
															${(disableUptoThisdayMoment || disableFromThisdayMoment) && checkIfDateShouldBeDisabled(day) && "text-gray-100"}
														}`}
											>
												{moment(day).format("D")}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{isShowTime && <div className="calendar-picker__sidebar">
						<div className="p-10 p-15 pb-0 pr-1">
						<ul
							className="timing_list m-0 grid grid-cols-3 pr-10 text-gray-900"
							style={{ maxHeight: "287px", overflowY: "auto" }}
						>
							{time().map((el, i) => {
								const _disTime = moment(el).format(DateTimeFormatUtils.timeFormat());
								return (
									<li
										key={"times" + i}
										className={`d-flex justify-content-center align-items-center pointer text-center rounded list-unstyled
											py-2 rounded-10 ${selectedTime === _disTime ? 'bg-primary text-white' : ''}`}
										onClick={() => {
											OnHandleChange(selectedDay ? selectedDay : today, el);
											setIsTime(true);
										}}
									>
										{_disTime}
									</li>
								)
							})}
						</ul>
						</div>
					</div>}
				</div>
			</div>}
		</div>
	);
};

const colStartClasses = [
	"",
	"col-start-2 mx-1",
	"col-start-3 mx-1",
	"col-start-4 mx-1",
	"col-start-5 mx-1",
	"col-start-6 mx-1",
	"col-start-7 mx-1",
];

export default DateTimePicker;
