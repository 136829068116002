import React, { useContext } from "react";
import CounterCard from "../Common/CounterCard";
import Linechart from "./LineChart";
import { convertFilterDataToStringArray, downloadCsvFromBuffer, getDataForChartFromDB, TypeDashBoardContext } from "../../helper";
import { useState } from "react";
import moment from 'moment';
import { getStorage } from "services";
import { useEffect } from "react";
import _ from "lodash";
import SkeletonCard from "../Common/SkeletonCard";
const CustomLineChart = (props) => {
  const { startDate, endDate, reportType } = props;
  const { filters, chartLevelFilter, isDashboardFilterApplied, setIsDashboardFilterApplied } = useContext(TypeDashBoardContext);

  const [lineChartData, setLineChartData] = useState([]);
  const tz = getStorage("timeZone");
  const [isPopup, setIsPopup] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const [filterPayload, setFilterPaylod] = useState(filters || {})
  const getDataForLineChart = async (from, to, isDownload = false, filterData) => {
    try {
        if (!isDownload) setIsLoading(true)
        
        const _startDate = from || fromDate
        const _endDate = to || toDate

      let payload = {
        chartType: reportType,
        fromDate: _startDate,
        toDate: _endDate,
      };

      // if (!isPopup) payload.limit = 10
      if (isDownload) payload.isDownload = true
      payload.filter = convertFilterDataToStringArray(filterData) || {}
      const responseData = await getDataForChartFromDB(payload,reportType);
      setIsLoading(false);

      if (!isDownload) setLineChartData(responseData)
      setIsLoading(false)
      setIsDashboardFilterApplied(false)
      return responseData;
    } catch (error) {
      console.log("🚀 ~ getDataForLineChart ~ error:", error);
      setLineChartData({});
    } finally{
      setIsLoading(false);
    }
  };

  const downloadCSV = async () => {
    const csvBuffer = await getDataForLineChart(startDate, endDate, true, chartLevelFilter?.[reportType]);
    downloadCsvFromBuffer(csvBuffer, `${reportType}-${new Date().getTime()}`);
  };

  const handleApplyFilterForChart = (payload) => {
    getDataForLineChart(startDate, endDate, false, payload)
  }

  useEffect(() => {
    getDataForLineChart();
  }, []);

  useEffect(() => {
    if (moment(fromDate)?.isSame(moment(startDate)) && moment(toDate)?.isSame(moment(endDate)) && _.isEqual(filters, filterPayload)) return;
    setFromDate(startDate);
    setToDate(endDate);
    setFilterPaylod(filters)
    getDataForLineChart(startDate, endDate, false, filters);
  }, [startDate, endDate, filters]);

  useEffect(() => {
    if(!isDashboardFilterApplied) return
    getDataForLineChart(startDate, endDate, false, filters);
  }, [isDashboardFilterApplied])

  if(isLoading) 
    return <SkeletonCard style ={{ width: "100%", height: "100%"}}/>
  return (
    <>
      <div className='rounded-5 bg-white d-flex flex-column h-100 justify-content-between'>
        <CounterCard {...{ ...props, ...lineChartData }}
          downloadCSV={downloadCSV}
          fromExpandedCard={true}
          isPopup={isPopup}
          setIsPopup={setIsPopup}
          handleApplyFilterForChart={handleApplyFilterForChart}
          isDownloadEnabled={!lineChartData?.reportData?.length}
        />
        <div className='flex-grow-1 overflow-hidden d-flex flex-column'>
          <Linechart  
            {...props} 
            data={lineChartData?.reportData} 
            downloadCSV={downloadCSV} 
            setIsPopup={setIsPopup} 
            startDate={startDate} 
            endDate={endDate} 
            isPopup={isPopup}  
          />
          <div className="text-gray-300 font-12 font-weight-normal d-flex justify-content-end py-10 pr-2">
            Last updated at:{" "}
            {moment(lineChartData?.lastUpdatedAt || new Date())
              .tz(tz)
              .format("MM-DD-YY • hh:mm A")}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomLineChart;
