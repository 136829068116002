import { getStorage } from "services";
import { DELIVERY_APPT_KEYS, LOAD_FILTER_MULTI_SELECT, LOAD_FILTERS, PICKUP_APPT_KEYS, RETURN_APPT_KEYS, SCHEDULE_APPT_COL_KEYS } from "./constants";
import moment from "moment";
import _ from "lodash";
import { getTimeZone } from "../NewDispatcher/constants";
import { sortableFields } from "../NewDispatcher/constants";
/**
 * Calculates the percentage of a given count from a total.
 * 
 * @param {number} total - The total value.
 * @param {number} count - The count value.
 * @returns {number} The percentage of the count from the total, rounded to two decimal places.
 */
export const getPercentageOfSummary = (total, count) => {
    // Check if either total or count is 0 to avoid division by zero
    if (total === 0 || count === 0) {
        return 0;
    }
    // Calculate the percentage and round it to two decimal places
    return (Math.round((count / total) * 100));
}

/**
 * Calculates the sum of counts from two objects.
 * 
 * @param {Object} obj1 - The first object containing counts.
 * @param {Object} obj2 - The second object containing counts.
 * @returns {Object} An object containing the sum of counts from both objects.
 */
export const getSumOfSummaryCount = (obj1, obj2) => {
    // Calculate the sum of counts from the first object
    const recommendationTotalCount = Object.values(obj1).reduce((a, b) => a + b, 0);
    // Calculate the sum of counts from the second object
    const totalCount = Object.values(obj2).reduce((a, b) => a + b, 0);
    // Return an object containing both sums
    return { recommendationTotalCount, totalCount };
}

export const getPayloadForFilter = (filter) => {
    const payload = {}
    Object.keys(filter)?.forEach(key => {
        if(filter[key]?.length > 0 && LOAD_FILTER_MULTI_SELECT.includes(key)){
            payload[key] = filter[key] 
        }else if(key === LOAD_FILTERS.searchTerm && filter[key]){
            payload.searchTerm = filter[key]
        }
    })
    return payload
}

export const getNextAptEvent = (row) => {
    const NextAptEventFilter = {
        PULLCONTAINER: "DELIVERLOAD",
        DELIVERLOAD: "RETURNCONTAINER",
    }
    const nextAptEventIndex = row?.move?.findIndex((move) => move.type === row?.profileType)
    if (row?.move?.[nextAptEventIndex]) {
        return row.move.find((move, index) => {
            return index > nextAptEventIndex && NextAptEventFilter[row?.profileType] === move?.type
        })
    }
}

/**
 * Sums up specified properties from an object, handling undefined values
 * @param {Object} obj - The source object
 * @param {string[]} keys - Array of property keys to sum
 * @returns {number} The sum of all specified properties
 */
export const sumProperties = (obj, keys) => 
    keys.reduce((sum, key) => sum + (obj?.[key] || 0), 0);


export const getEventDetails = ({key, row}) => {
    let value;

    const pickupFields = PICKUP_APPT_KEYS;
    const returnFields = RETURN_APPT_KEYS;
    const deliveryFields = DELIVERY_APPT_KEYS;

    if (pickupFields.includes(key)) {
        const pickupDates = {...row?.pickRecommendation};
        if(pickupDates?.appointmentFrom && pickupDates?.appointmentTo){
            pickupDates.fromDate = pickupDates?.appointmentFrom;
            pickupDates.toDate = pickupDates?.appointmentTo;
        }
        value = pickupDates;
    }
    else if (returnFields.includes(key)) {
        const returnDates = {...row?.returnRecommendation};
        if(returnDates?.appointmentFrom && returnDates?.appointmentTo){
            returnDates.fromDate = returnDates?.appointmentFrom;
            returnDates.toDate = returnDates?.appointmentTo;
        }
        value = returnDates;
    }
    else if (deliveryFields.includes(key)) {
        const deliverDates = {...row?.deliverRecommendation};
        if(deliverDates?.appointmentFrom && deliverDates?.appointmentTo){
            deliverDates.fromDate = deliverDates?.appointmentFrom;
            deliverDates.toDate = deliverDates?.appointmentTo;
        }
        value = deliverDates;
    }
    return value;
};
export function calculatePercentage(scheduled, recommended) {
    if (!recommended) return 0;
    const percentage = (scheduled / recommended) * 100;
    const roundedPercentage = Math.round(percentage);
    return roundedPercentage % 1 === 0 ? roundedPercentage : roundedPercentage?.toFixed(2);
}

function normalizeIntoArray(inputData = {}) {
	const result = {};
	if (!Object?.keys(inputData)?.length) {
		const timeSlots = [
			"PICK_UP",
            "PICK_UP_SCHEDULED",
            "PICK_UP_NEED_APT",
            "PICK_UP_REQUESTED",
            "DELIVER",
            "DELIVER_SCHEDULED",
            "DELIVER_NEED_APT",
            "DELIVER_REQUESTED",
            "RETURN",
            "RETURN_SCHEDULED",
			"RETURN_NEED_APT",
			"RETURN_REQUESTED",
		];

		timeSlots?.forEach((timeSlot) => {
			result[timeSlot] = Array(24).fill({ label: "0/0", percentage: 0 });
		});
	} else {
		Object.keys(inputData)?.forEach((timeSlot) => {
			const timeSlotData = inputData?.[timeSlot];
			Object.keys(timeSlotData)?.forEach((key) => {
				const value = timeSlotData[key];
				if (!result?.[key]) {
					result[key] = [];
				}
				result[key].push(value);
			});
		});
	}
	return result;
}


export function percantageAndLabelFormatter(inputData = {}, totalCapacity) {
    const result = {};
  
    for (let timeSlot in totalCapacity) {
        const {
            TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT = 0,
            TOTAL_PULLCONTAINER_NEED_APT_COUNT = 0,
            TOTAL_PULLCONTAINER_REQUESTED_COUNT = 0,
            TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT = 0,
            TOTAL_DELIVERLOAD_NEED_APT_COUNT = 0,
            TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT = 0,
            TOTAL_DELIVERLOAD_REQUESTED_COUNT = 0,
            TOTAL_RETURNCONTAINER_REQUESTED_COUNT = 0,
            TOTAL_RETURNCONTAINER_NEED_APT_COUNT = 0
        } = inputData?.[timeSlot] || {};
        const totalTimeSlotCapacity = totalCapacity?.[timeSlot] ?? 0

        const TotalPickUpAppointment = (TOTAL_PULLCONTAINER_NEED_APT_COUNT || 0) + (TOTAL_PULLCONTAINER_REQUESTED_COUNT || 0) + (TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT || 0);
        const TotalReturnAppointment = (TOTAL_RETURNCONTAINER_NEED_APT_COUNT || 0) + (TOTAL_RETURNCONTAINER_REQUESTED_COUNT || 0) + (TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT || 0);
        const TotalDeliverAppointment = (TOTAL_DELIVERLOAD_NEED_APT_COUNT || 0) + (TOTAL_DELIVERLOAD_REQUESTED_COUNT || 0) + (TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT || 0);
        

        const pickUpPercent = calculatePercentage(TotalPickUpAppointment, totalTimeSlotCapacity);
        const pullContainerNeedAptPercent = calculatePercentage(TOTAL_PULLCONTAINER_NEED_APT_COUNT, totalTimeSlotCapacity);
        const pullRequestedPercent = calculatePercentage(TOTAL_PULLCONTAINER_REQUESTED_COUNT, totalTimeSlotCapacity);
        const deliverPercent = calculatePercentage(TotalDeliverAppointment, totalTimeSlotCapacity);
        const deliverNeedApptPercent = calculatePercentage(TOTAL_DELIVERLOAD_NEED_APT_COUNT, totalTimeSlotCapacity);
        const deliverRequestedPercent = calculatePercentage(TOTAL_DELIVERLOAD_REQUESTED_COUNT, totalTimeSlotCapacity);
        const returnPercentage = calculatePercentage(TotalReturnAppointment, totalTimeSlotCapacity);
        const returnNeedApptPercent = calculatePercentage(TOTAL_RETURNCONTAINER_NEED_APT_COUNT, totalTimeSlotCapacity);
        const returnRequestedPercent = calculatePercentage(TOTAL_RETURNCONTAINER_REQUESTED_COUNT, totalTimeSlotCapacity);


      
        result[timeSlot] = {
            PICK_UP: {
                label: `${TotalPickUpAppointment}/${totalTimeSlotCapacity}`,
                percentage: pickUpPercent
            },
            PICK_UP_SCHEDULED: {
                label: `${TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: pickUpPercent
            },
            PICK_UP_NEED_APT: {
                label: `${TOTAL_PULLCONTAINER_NEED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: pullContainerNeedAptPercent
            },
            PICK_UP_REQUESTED: {
                label: `${TOTAL_PULLCONTAINER_REQUESTED_COUNT}/${totalTimeSlotCapacity}`,
                percentage: pullRequestedPercent
            },
            DELIVER: {
                label: `${TotalDeliverAppointment}/${totalTimeSlotCapacity}`,
                percentage: deliverPercent
            },
            DELIVER_SCHEDULED: {
                label: `${TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: deliverPercent
            },
            DELIVER_NEED_APT: {
                label: `${TOTAL_DELIVERLOAD_NEED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: deliverNeedApptPercent
            },
            DELIVER_REQUESTED: {
                label: `${TOTAL_DELIVERLOAD_REQUESTED_COUNT}/${totalTimeSlotCapacity}`,
                percentage: deliverRequestedPercent
            },
            RETURN: {
                label: `${TotalReturnAppointment}/${totalTimeSlotCapacity}`,
                percentage: returnPercentage
            },
            RETURN_SCHEDULED: {
                label: `${TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: returnPercentage
            },
            RETURN_NEED_APT: {
                label: `${TOTAL_RETURNCONTAINER_NEED_APT_COUNT}/${totalTimeSlotCapacity}`,
                percentage: returnNeedApptPercent
            },
            RETURN_REQUESTED: {
                label: `${TOTAL_RETURNCONTAINER_REQUESTED_COUNT}/${totalTimeSlotCapacity}`,
                percentage: returnRequestedPercent
            },
        };
    }

    return normalizeIntoArray(result);
}

export const getRelatedEventKeys = (key) => {
    if (PICKUP_APPT_KEYS.includes(key)) return PICKUP_APPT_KEYS;
    if (RETURN_APPT_KEYS.includes(key)) return RETURN_APPT_KEYS;
    if (DELIVERY_APPT_KEYS.includes(key)) return DELIVERY_APPT_KEYS;
    return [];
}

export const generateTimeSlots = () => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
        const start = hour.toString().padStart(2, '0') + ':00';
        const end = (hour === 23 ? '00' : (hour + 1).toString().padStart(2, '0')) + ':00';
        slots.push(`${start}-${end}`);
    }
    return slots;
}

export const isAppointmentDateInSameDay = (appointmentDates, planDate, timeZone) => {
    const { appointmentFrom, recommendedAppointmentFrom } = appointmentDates || {};
    const appointmentDate = appointmentFrom ? appointmentFrom : recommendedAppointmentFrom;
    const date1 = moment(appointmentDate).tz(timeZone);
    const date2 = moment(planDate).tz(timeZone);
    if (appointmentDate) {
        return date1.isSame(date2, 'day');
    }
    return false;
}

export const getTotalCapacity = (hourlyCapacity) => {
    return Object.values(hourlyCapacity).reduce((acc, curr) => acc + curr, 0);
}

export const editAppointmentDates = (load) => {
    if (load?.pickRecommendation) {
        load.pickupDates = {
          pickupFromTime: load?.pickupTimes?.[0]?.pickupFromTime || load?.pickRecommendation?.recommendedAppointmentFrom,
          pickupToTime: load?.pickupTimes?.[0]?.pickupToTime || load?.pickRecommendation?.recommendedAppointmentTo,
          appointmentStatus: load?.pickRecommendation.appointmentStatus || "",
          isRecommendedAPT: load?.pickupTimes?.[0]?.pickupFromTime ? false : (load?.pickRecommendation.isRecommendedAPT || null),
          customerId: load?.pickupTimes?.[0]?.customerId || "",
        };
      }
      if (load?.returnRecommendation) {
        load.returnDates = {
          returnFromTime: load?.returnFromTime || load?.returnRecommendation?.recommendedAppointmentFrom,
          returnToTime: load?.returnToTime || load?.returnRecommendation?.recommendedAppointmentTo,
          appointmentStatus: load?.returnRecommendation.appointmentStatus || "",
          isRecommendedAPT: load?.returnFromTime ? false : (load?.returnRecommendation.isRecommendedAPT || null),
        };
      }
      if (load?.deliverRecommendation) {
        load.deliverDates = {
          deliverFromTime: load?.deliverTimes?.[0]?.deliverFromTime || load?.deliverRecommendation?.recommendedAppointmentFrom,
          deliverToTime: load?.deliverTimes?.[0]?.deliverToTime || load?.deliverRecommendation?.recommendedAppointmentTo,
          appointmentStatus: load?.deliverRecommendation.appointmentStatus || "",
          isRecommendedAPT: load?.deliverTimes?.[0]?.deliverFromTime ? false : (load?.deliverRecommendation.isRecommendedAPT || null),
          customerId: load?.deliverTimes?.[0]?.customerId || "",
        };
      }
      return load;
}

export const sortSchedulingColumnLocal = (sortColumns) => {
    let col = sortColumns[0]?.columnKey;
    const sortableField = sortableFields.find(el => el.key === col);
    let data = sortableField?.field ? sortableField?.field : col
    return data;
}

  /**
   * Organizes scheduling table columns into specific groupings
   */
  export const groupColumnsSchedulingTable = (scheduleColumns = [], baseColumns = []) => {

    if (scheduleColumns.length === 0 || baseColumns.length === 0) return [];
  
    // Create working copy to prevent input mutation
    const workingColumns = [...baseColumns];
  
    // Configuration for each appointment type processing
    const APPOINTMENT_CONFIGS = [
      {
        baseKey: SCHEDULE_APPT_COL_KEYS.PICKUP_APPT,
        toKey: SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO,
        bookedKey: SCHEDULE_APPT_COL_KEYS.PICKUP_BOOKED_STATUS
      },
      {
        baseKey: SCHEDULE_APPT_COL_KEYS.DELIVER_APPT,
        toKey: SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO,
        bookedKey: SCHEDULE_APPT_COL_KEYS.DELIVER_BOOKED_STATUS
      },
      {
        baseKey: SCHEDULE_APPT_COL_KEYS.RETURN_APPT,
        toKey: SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO,
        bookedKey: SCHEDULE_APPT_COL_KEYS.RETURN_BOOKED_STATUS
      }
    ];
  
    // Process each appointment type configuration
    APPOINTMENT_CONFIGS.forEach(config => 
      processAppointmentColumns(workingColumns, scheduleColumns, config)
    );
  
    // Return optimized column array with unique keys
    return deduplicateColumns(workingColumns);
  };
  
  /**
   * Processes columns for a specific appointment type
   */
  const processAppointmentColumns = (workingColumns, sourceColumns, config) => {
    const { baseKey, toKey, bookedKey } = config;
    
    // Insert status column before base column
    const baseIndex = workingColumns.findIndex(col => col.key === baseKey);
    if (baseIndex === -1) return;
  
  
    // Insert 'to' column after base column
    const updatedBaseIndex = workingColumns.findIndex(col => col.key === baseKey);
    const toColumn = sourceColumns.find(col => col.key === toKey);
    if (toColumn) {
      workingColumns.splice(updatedBaseIndex + 1, 0, toColumn);
    }
  
    // Insert booked status after 'to' column
    const toIndex = workingColumns.findIndex(col => col.key === toKey);
    const bookedColumn = sourceColumns.find(col => col.key === bookedKey);
    if (toIndex !== -1 && bookedColumn) {
      workingColumns.splice(toIndex + 1, 0, bookedColumn);
    }
  };
  
  /**
   * Removes duplicate columns while preserving order
   */
  const deduplicateColumns = columns => {
    const seen = new Set();
    return columns.filter(col => {
      const duplicate = seen.has(col.key);
      seen.add(col.key);
      return !duplicate;
    });
  };


export const searchLoadList = (loadList, searchTerm) => {
    const searchKeys = [
        "callerName", "reference_number", "invoiceNumber", "containerNo", "bookingNo",
        "callerbillLandingNo", "chassisNo", "callerPONo", "deliveryOrderNo", "doNo",
        "purchaseOrderNo", "releaseNo", "sealNo", "secondaryReferenceNo", "shipmentNo",
        "driverName", "tenderReferenceNumber", "appointmentNo", "returnNo", "reservationNo", "grayContainerNo"
    ];
    const searchTerms = searchTerm.split(/, |,/).filter(Boolean);
    const result = loadList?.filter((item) => {
        return searchKeys.some(key => searchTerms.some(term => item[key]?.toLowerCase().includes(term.toLowerCase())));
    });
    return result;
}

export const CARD_COUNTS = {
    TOTAL_DELIVERLOAD: "TOTAL_DELIVERLOAD",
    TOTAL_PULLCONTAINER: "TOTAL_PULLCONTAINER",
    TOTAL_RETURNCONTAINER: "TOTAL_RETURNCONTAINER",
    TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT: "SIDE_TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT",
    TOTAL_PULLCONTAINER_NEED_APT_COUNT: "SIDE_TOTAL_PULLCONTAINER_NEED_APT_COUNT",
    TOTAL_PULLCONTAINER_REQUESTED_COUNT: "SIDE_TOTAL_PULLCONTAINER_REQUESTED_APT_COUNT",
    TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT: "SIDE_TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT",
    TOTAL_DELIVERLOAD_NEED_APT_COUNT: "SIDE_TOTAL_DELIVERLOAD_NEED_APT_COUNT",
    TOTAL_DELIVERLOAD_REQUESTED_COUNT: "SIDE_TOTAL_DELIVERLOAD_REQUESTED_APT_COUNT",
    TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT: "SIDE_TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT",
    TOTAL_RETURNCONTAINER_NEED_APT_COUNT: "SIDE_TOTAL_RETURNCONTAINER_NEED_APT_COUNT",
    TOTAL_RETURNCONTAINER_REQUESTED_COUNT: "SIDE_TOTAL_RETURNCONTAINER_REQUESTED_APT_COUNT"
}

export const mapCardCounts = (cardData, cardCountsMap) => {
    const result = {};

    // Iterate through the CARD_COUNTS mapping
    Object.entries(cardCountsMap).forEach(([cardCountKey, rawDataKey]) => {
        // If the raw data key exists in cardData, assign its value to the CARD_COUNTS key
        if (rawDataKey in cardData) {
            result[cardCountKey] = cardData[rawDataKey];
        }
    });

    return result;
};

export const isTimeSlotBased = (data) => {
    return Object.keys(data).some(key => /^\d{2}:00-\d{2}:00$/.test(key));
};

export const formatDateKeys = (data) => {
    if (!data) return {};

    const formattedData = {};
    if(isTimeSlotBased(data)) {
        defaultDateHeader().forEach((d) => {
            // Convert ISO date string to dd/mm/yyyy format
            const date = moment(d).format('DD/MM/YYYY');
            formattedData[date] = 0;
        });
    } else {
        Object.entries(data).forEach(([key, value]) => {
            // Convert ISO date string to dd/mm/yyyy format
            const date = moment(key).format('DD/MM/YYYY');
            formattedData[date] = value;
        });
    }
    return formattedData;
};

export const defaultDateHeader = () => {
    const timeZone = getStorage("timeZone");
    const dates = [];
    let currentDate = moment().tz(timeZone);
    // Add today if it's not a weekend
    if (![0, 5].includes(currentDate.day())) {    
        dates.push(currentDate.clone().toISOString());
    }
    // Get next 5 weekdays
    let daysAdded = 0;
    let dayToCheck = currentDate.clone().add(1, 'days');

    while (daysAdded < 4) {
        // Skip weekend (0 = Sunday, 6 = Saturday)
        if (![0, 6].includes(dayToCheck.day())) {
            dates.push(dayToCheck.clone().toISOString());
            daysAdded++;
        }
        dayToCheck.add(1, 'days');
    }

    return dates
}

export const defaultDateData = () => {
    const timeZone = getStorage("timeZone");
    const dates = [];
    let dataValue = {
        "TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT": 0,
        "TOTAL_PULLCONTAINER_NEED_APT_COUNT": 0,
        "TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT": 0,
        "TOTAL_DELIVERLOAD_NEED_APT_COUNT": 0,
        "TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT": 0,
        "TOTAL_RETURNCONTAINER_NEED_APT_COUNT": 0,
        "TOTAL_PULLCONTAINER_REQUESTED_COUNT": 0,
        "TOTAL_DELIVERLOAD_REQUESTED_COUNT": 0,
        "TOTAL_RETURNCONTAINER_REQUESTED_COUNT": 0
    }
    let currentDate = moment().tz(timeZone);
    // Add today if it's not a weekend
    if (![0, 5].includes(currentDate.day())) {   
        let data = {
            [currentDate.clone().toISOString()] : dataValue
        } 
        dates.push(data);
    }
    // Get next 5 weekdays
    let daysAdded = 0;
    let dayToCheck = currentDate.clone().add(1, 'days');

    while (daysAdded < 4) {
        // Skip weekend (0 = Sunday, 6 = Saturday)
        if (![0, 6].includes(dayToCheck.day())) {
            let data = {
                [dayToCheck.clone().toISOString()] : dataValue
            } 
            dates.push(data);
            daysAdded++;
        }
        dayToCheck.add(1, 'days');
    }

    return dates
}

export const filterWeekdayCapacity = (_capacity) => {
    const filteredCapacity = {};
    const isWeekday = (date) => {
        const weekday = moment(date).isoWeekday();
        return weekday !== 6 && weekday !== 7; // Not Saturday or Sunday
    };

    // Get initial dates from capacity
    const validDates = Object.keys(_capacity)
        .filter(isWeekday)
        .slice(0, 5);

    // Add existing capacity dates
    validDates.forEach(date => {
        filteredCapacity[date] = _capacity[date];
    });

    // Add additional weekdays if needed
    let currentDate = moment(validDates[validDates.length - 1] || Object.keys(_capacity).pop());
    let totalCapacity = _capacity[currentDate.toISOString()]
    while (Object.keys(filteredCapacity).length < 5) {
        currentDate.add(1, 'days');
        if (isWeekday(currentDate)) {
            filteredCapacity[currentDate.toISOString()] = totalCapacity; // Default value
        }
    }

    return filteredCapacity;
};

export const addMissingDates = (data, capacity) => {
    const defaultCounts = {
        TOTAL_DELIVERLOAD_NEED_APT_COUNT: 0,
        TOTAL_DELIVERLOAD_REQUESTED_COUNT: 0,
        TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT: 0,
        TOTAL_PULLCONTAINER_NEED_APT_COUNT: 0,
        TOTAL_PULLCONTAINER_REQUESTED_COUNT: 0,
        TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT: 0,
        TOTAL_RETURNCONTAINER_NEED_APT_COUNT: 0,
        TOTAL_RETURNCONTAINER_REQUESTED_COUNT: 0,
        TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT: 0
    };

    // Create a new object to avoid mutating the input
    const updatedData = { ...data };

    // Add missing dates from capacity
    Object.keys(capacity).forEach(date => {
        if (!updatedData[date]) {
            updatedData[date] = { ...defaultCounts };
        }
    });

    return updatedData;
};

export const checkScheduleConflict = (scheduleList, loadDetail) => {
    if (!scheduleList || !loadDetail) return false;
    
    const schedule = scheduleList;
    
    return schedule?.some(d => 
        d.reference_number === loadDetail.reference_number && (
            d.pickupTimes?.[0]?.pickupFromTime === loadDetail.pickupTimes?.[0]?.pickupFromTime ||
            d.deliverTimes?.[0]?.deliveryFromTime === loadDetail.deliverTimes?.[0]?.deliveryFromTime ||
            d.returnFromTime === loadDetail.returnFromTime ||
            d.containerNo === loadDetail.containerNo
        )
    );
}