import { IconCheck } from "../../../../../../../Components/Common/Icons";
import moment from "moment";
import useCurrentUser from "../../../../../../../hooks/users/useCurrentUser";
import { DateTimeFormatUtils } from "../../../../../../../services/Common.services";

const APPOINTMENT_CELL_DAY_FORMAT = "dddd";

const SlotCell = ({ isActive, toggleSelection, eachCell, cellIndex, rowIndex, slotColor, count, isLoading }) => {
  const { currentUserTimeZone } = useCurrentUser();
  return (
    <>
      {eachCell?.isFirstCell && (
        <td>
          <div className="font-14 font-line-height-20 font-weight-500">
            {moment(eachCell.date).tz(currentUserTimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat())}
          </div>
          <div className="font-12 text-muted">
            {moment(eachCell.date).tz(currentUserTimeZone).tz(currentUserTimeZone).format(APPOINTMENT_CELL_DAY_FORMAT)}
          </div>
        </td>
      )}
      {!eachCell?.isFirstCell && (
        <td>
          <div
            className={`d-flex align-items-center bg-gray-50 rounded-3 text-center position-relative overflow-hidden border-accent-500 pointer user-select-none border-0 ${slotColor}`}
            style={{ width: 57, height: 24 }}
            onClick={() => toggleSelection({ ...eachCell, rowIndex, cellIndex })}
          >
            <pre>{isActive}</pre>
            <div className={`font-weight-500 w-100 z-1 ${isActive ? "text-white" : ""}`}>
              {isActive ? (
                <>
                  <IconCheck className="mr-2" /> {count + 1}
                </>
              ) : isLoading ? (
                ""
              ) : (
                count
              )}
            </div>
            <div
              className={`rounded-0 h-100 position-absolute ${
                isActive ? "bg-primary" : "bg-gray-100"
              } d-flex align-items-center justify-content-center`}
              style={{
                width: "100%",
              }}
            >
              {isLoading && !isActive && (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              )}
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default SlotCell;
