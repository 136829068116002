import React, {  useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";

import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { getTrailers } from "../../../../services";
import { successColorResponse ,errorColorResponse } from "../../../utility";

const TrailerList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress, column }) => {
  const { trailers } = useSelector((state) => state.chassisReducer, shallowEqual);
  const trailerList = trailers.map((obj) => ({ value: obj.equipmentID, label: obj.equipmentID, allInfo: obj }));
  
  const [dList, setDList] = useState(trailerList);
  const [cursor, setCursor] = useState(null);
  const [searchTrailer, setSearchTrailer] = useState(null);
  const searchInputRef = React.useRef(null);
  const onSearch = useCallback(
    _.debounce((params) => getSeachData(params), 500),
    []
  );

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef,boxRef);
  const cell = document.querySelector('[aria-selected="true"]');

  useEffect(() => {
    searchInputRef.current.focus();
    window.document.getElementById('root').style.pointerEvents="none"
    return ()=>{
      window.document.getElementById('root').style.pointerEvents="auto"
    }
  }, []);

  useEffect(() => {
      onChangeDebounced();
  }, [searchTrailer]);

  const onChangeDebounced = () => {
    onSearch(searchTrailer);
  };

  const getSeachData = (params) => {
    const obj = { limit: 5 }
    if (params) {
      obj.searchTerm = params
    }
    getTrailers(obj).then((data) => {
      setDList(data);
    });
  };


  useOnClickOutside(boxRef, () => onClose(false));

  const get = (data) => {
    if (Row) {
      const payload = {
        trailer: data.value,
        reference_number: Row.reference_number,
      };
      setIsAssignInProgress(true);
      editLoad(payload).then((el) => {
        onRowChange({ ...Row, trailer: data.value });
        setIsAssignInProgress(false);
        successColorResponse(cell, column.idx, Row?.className);
        onClose(false);
      }).catch((err) => {
        errorColorResponse(cell, column.idx, Row?.className);
        setIsAssignInProgress(false);
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`trailer-${cursor}`).focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= dList.length - 1) {
        if (cursor === null) {
          setCursor(0);
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`trailer-${cursor}`).focus();
        }
      }
      event.preventDefault();
    } else if (event.keyCode === 13) {
      dList.forEach((val, index) => {
        if (index === cursor) get(val);
      });
      event.preventDefault();
    } else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return createPortal(
    <div
      ref={boxRef}
      className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
      data-testid="trailer-list"
      style={Style}
    >
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder="Search Trailer.."
          id="domTextElement"
          onChange={(e) => setSearchTrailer(e.target.value)}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          ref={searchInputRef}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul
        className="driverList m-0"
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        {dList.length === 0 ? (
          <p className="p-1 bg-light">No Options</p>
        ) : (
          dList.map((el, index) => (
            <li
              key={index}
              className={`popdrop__dropdown-item ${
                cursor === index ? "bg-gradient-light" : null
              }`}
              tabIndex="-1"
              id={`trailer-${index}`}
              onClick={async () => get(el)}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              {el.label}
            </li>
          ))
        )}
      </ul>
    </div>,
    document.getElementById("pp-overlay-container")
  );
};
export default TrailerList;
