import React, { Fragment, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import remove from "lodash/remove";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import * as actionCreators from "../actionCreators";
import config from "../../../../config";
import { getStorage, isAdminCustomerPortalPermission, isManageFleetOrOutSource, toastr } from "../../../../services/Common.services";
import HeaderChatIconComponent from "../../Components/HeaderChatIconComponent";
import ChatDropdownComponent from "./ChatDropdownComponent";
import ChatBoxWrapperNew from "../ChatBoxWrapperNew";
import { chatTabs, isCustomerPortalUser } from "../../../../utils";

let loggedInUser;
let carrierDetails;
const firebase = config.firebase;

class ChatComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allDrivers: [],
      activeBox: 0,
      filterdDrivers: [],
      groupChatList: [],
      fleetManagers: [],
      customers: [],
      chatBoxes: [],
      truckers: [], 
      selectedCustomer: "",
      driver: "",
      fleetManager: "",
      modalIsOpen: false,
      groupName: "",
      searchCustomer: "",
      searchTrucker: "",
      memberList: [],
      selectedDriver: "",
      selectedEmployee: "",
      isChatPopDrop: false,
      unreadUserMessages: [],
      allGroups: [],
      isChatPop: false,
      selectedChatTab: (isCustomerPortalUser() || isManageFleetOrOutSource()?.isOutSource) ? 'fleetmanager' : "driver",
      isChatFirstLoad: true,
      openedChatBoxesID: []
    };

    this.newMessageHandler = this.newMessageHandler.bind(this);
    this.displayChatBox = this.displayChatBox.bind(this);
    this.findDuplicate = this.findDuplicate.bind(this);
    this.closeChatBox = this.closeChatBox.bind(this);
    this.toggleChatBox = this.toggleChatBox.bind(this);
    this.changeActiveBox = this.changeActiveBox.bind(this);
    this.getChatGroups = this.getChatGroups.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.handleChatTabSelection = this.handleChatTabSelection.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    //Close when click outside the event
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    //for close when click outside the event
    document.addEventListener("mousedown", this.handleClickOutside);

    setTimeout(() => {
      loggedInUser = JSON.parse(getStorage("loggedInUser"));
      carrierDetails = JSON.parse(getStorage("carrierDetail"));
      if (
        !(
          (loggedInUser &&
            (loggedInUser.role === "customer" ||
              loggedInUser.role === "fleetcustomer")) ||
          (loggedInUser.fleetManager && loggedInUser.fleetManager.isCustomer)
        )
          && !isAdminCustomerPortalPermission()
      ) {
        this.getChatGroups();
      } else if(!isAdminCustomerPortalPermission()){
        this.props.actions
          .getTruckersByCustomer()
          .then((truckers) =>
            this.setState({ truckers }, () => this.getChatGroups())
          );
      }
    }, 1000);

    // reset the opened chatboxes cache on refresh
    window.addEventListener("load", () => {
      localStorage.removeItem("openedChatBoxesID");
    });

  }

  componentWillUnmount() {
    //for close when click outside the event
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(props) {
    if(!_.isEqual(props.groups, this.state.groupChatList)) {
      this.setState({ groupChatList: props.allGroups });
    }
  }

  /**
   * Set the wrapper ref //for close when click outside the event
   */
   setWrapperRef(node) {
    this.wrapperRef = node;
  }

  //for close when click outside the event
   handleClickOutside(event) {
    if (this.state.isChatPopDrop && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
    if (this.state.isChatPopDrop && document.querySelectorAll('.modal-open').length == 0 && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ isChatPopDrop: null });
    }
  }
}

  createGroup(data) {
    let carrierId = loggedInUser && loggedInUser._id;
    if (loggedInUser && loggedInUser.role === "driver") {
      carrierId = loggedInUser.driver.carrier;
    }
    if (loggedInUser && loggedInUser.role === "fleetmanager") {
      carrierId = loggedInUser.fleetManager.carrier;
    }
    if (loggedInUser && loggedInUser.role === "customer") {
      carrierId =
        this.state.truckers[0] &&
        this.state.truckers[0].carrier &&
        this.state.truckers[0].carrier._id;
    }

    if (loggedInUser?.role === "fleetmanager" && loggedInUser?.fleetManager?.isCustomer) {
      carrierId = carrierDetails?._id;
    }
    let params;
    if (!data) {
      params = {
        groupName: this.state.groupName,
        members: [
          ...this.state.memberList.map((d) => d.value),
          loggedInUser._id,
        ].unique(),
        type: this.state.type ? this.state.type : "groups",
      };
    } else {
      params = data;
    }

    if (data && data.edit_grp_id) {
      params._id = data.edit_grp_id;
      delete data.edit_grp_id;
    }

    if (params.members && params.members.length < 2) {
      toastr.show(`Can't have empty members`, "error");
      return;
    }
    this.props.actions.createGroupChat(params).then((res) => {
      this.setState(
        {
          groupName: "",
          edit_grp_id: undefined,
          type: undefined,
          defaultValue: [],
          memberList: [],
          modalIsOpen: false,
        },
        () => {
          if (!this.state.isEditing === false) {
            firebase
              .database()
              .ref(`${carrierId}/GROUP_CHAT/${res._id}`)
              .off("value");
            firebase
              .database()
              .ref(`${carrierId}/GROUP_CHAT/${res._id}`)
              .on("value", (snapshot) => {
                const values = snapshot.val();
                const data = values && JSON.parse(values);
                if (data) {
                  this.newMessageHandler(data);
                }
              });
          }
          this.setState({ isEditing: false });
          this.props.actions
            .getChatGroup()
            .then((groupChatList) => {
              this.setState({ groupChatList });
              this.props.actions.setGroupChats(groupChatList);
            });
          this.displayChatBox(res && res.length > 0 ? res[0] : res);
        }
      );
    });
  }

  async newMessageHandler(data) {
    const self = this;
    if (
      data &&
      data.msg &&
      moment().diff(moment(data.msg.sentAt), "seconds") <= 10
    ) {
      let id = data._id;
      let { chatBoxes } = self.state;
      let loggedUserCache = JSON.parse(localStorage.getItem('loggedInUser'));
      let message = data.msg;
      let read = loggedInUser._id === message?.sentBy?._id ? true : false;
      self.props.actions.addMessageToGroup(id, message);
      let group = self.props.unreadUserMessages.find((d) => d?.groupId === id);

      if (!read) {
        // only play when notif sound is allowed
        if (loggedUserCache && loggedUserCache?.isPlaySoundOnMessage) {
          loggedUserCache.currentRingtone && loggedUserCache.currentRingtone == "iphone"
            ? new Audio("/assets/media/message.mp3").play().catch((err) => console.warn(err)) : new Audio("/assets/media/whatsapp.mp3").play().catch((err) => console.warn(err))
        }

        // only open if the chatbox popup is allowed
        let chatbox_index = _.findIndex(chatBoxes, { _id: data._id });
        if (loggedUserCache.isOpenChatboxOnNewMessage) {
          let shallow = this.state.openedChatBoxesID;
          if (!this.state.isChatFirstLoad) {
            if (this.state.openedChatBoxesID.length > 0) {
              if (!shallow.includes(group.groupId)) {
                shallow.push(group.groupId);
                this.displayChatBox(data)
              }
              if(chatbox_index > -1 && chatBoxes?.length > 0) chatBoxes[chatbox_index].hide = false;
              this.setState({openedChatBoxesID: shallow});
            } else {
              this.displayChatBox(data)
              this.setState({openedChatBoxesID: [group.groupId]});
            }
          } else {
            this.displayChatBox(data)
            this.setState({ isChatFirstLoad: false, openedChatBoxesID: [group.groupId]});
          }
        } 
        if (chatbox_index === -1) {
          self.props.actions.addUnreadMessageCount(group?.groupId);
        }
      }
      // self.setState({ chatBoxes });
    }
  }

  getChatGroups() {
    let carrierId = loggedInUser && loggedInUser._id;
    if (loggedInUser && loggedInUser.role === "driver") {
      carrierId = loggedInUser.driver.carrier;
    }
    if (loggedInUser && loggedInUser.role === "fleetmanager") {
      carrierId = loggedInUser.fleetManager.carrier;
    }
    if (loggedInUser && loggedInUser.role === "customer") {
      carrierId =
        this.state.truckers[0] &&
        this.state.truckers[0].carrier &&
        this.state.truckers[0].carrier._id;
    }

    if (loggedInUser?.role === "fleetmanager" && loggedInUser?.fleetManager?.isCustomer) {
      carrierId = carrierDetails?._id;
    }
    let self = this;
    this.props.actions.getChatGroup().then((groupChatList) => {
      this.props.actions.resetChatGrooup();
      this.props.actions.setGroupChats(groupChatList);
      self.setState({ groupChatList }, () => {
        groupChatList.map((d) => {
          let lastActiveTime = d?.lastUsed?.find(
            (user) => user.userId === loggedInUser._id
          )?.time;
          let unreadMessage = d.messages.filter((message) =>
            message?.sentBy?._id !== loggedInUser._id &&
            moment(lastActiveTime).diff(message.sentAt, "seconds") < 0
              ? true
              : false
          ).length;
          this.props.actions.setUnreadMessageCount(
            d._id,
            unreadMessage,
            d.type
          );
        });

        let _ids = groupChatList.map((d) => `${carrierId}/GROUP_CHAT/${d._id}`);
        _ids.forEach((key) => {
          firebase
            .database()
            .ref(key)
            .on("value", (snapshot) => {
              const values = snapshot.val();
              const data = values && JSON.parse(values);
              if (data) {
                self.newMessageHandler(data);
              }
            });
        });
        firebase
          .database()
          .ref(`${carrierId}/${loggedInUser._id}/NEW_GROUP`)
          .on("value", (snapshot) => {
            const values = snapshot.val();
            const data = values && JSON.parse(values);
            if (data && moment().diff(moment(data.updatedAt), "seconds") <= 10) {
              if (data.isNew || data.action === "add") {
                firebase
                  .database()
                  .ref(`${carrierId}/GROUP_CHAT/${data._id}`)
                  .on("value", (snapshot) => {
                    const values = snapshot.val();
                    const data = values && JSON.parse(values);
                    if (data) {
                      self.newMessageHandler(data);
                    }
                  });
                  
                let group = self.props.groups?.find((d) => d._id === data?._id);
                this.props.actions.setUnreadMessageCount(data?._id, 0, data?.data?.type)
                this.props.actions.addGroupChats(data?.data)

                let lastActiveTime =
                  group &&
                  group.lastUsed.find(
                    (user) => user.userId === loggedInUser._id
                  )
                    ? group.lastUsed.find(
                        (user) => user.userId === loggedInUser._id
                      ).time
                    : null;
                let unreadMessage =
                  group &&
                  group.messages.filter((message) =>
                    message?.sentBy?._id !== loggedInUser._id &&
                    moment(lastActiveTime).diff(message.sentAt, "seconds") < 0
                      ? true
                      : false
                  ).length;
                self.props.actions.setUnreadMessageCount(
                  data._id,
                  unreadMessage,
                  group?.type
                );
              }
              if (data.action === "remove") {
                let { chatBoxes } = self.state;
                chatBoxes.remove((n) => {
                  return n._id == data._id;
                });
                self.setState({ chatBoxes });
                firebase
                    .database()
                    .ref(`${carrierId}/GROUP_CHAT/${data._id}`)
                    .off("value", (snapshot) => {});
                self.props.actions.removeChatGrooup(data._id);
                self.props.actions.removeGroupChats(data._id);
              }
            }
              // self.props.actions.getChatGroup().then((groupChatList) => {
              //   self.props.actions.setGroupChats(groupChatList);
              //   self.setState({ groupChatList });
                
              // });
          });
      });
    });
  }

  changeActiveBox(activeBox) {
    this.setState({ activeBox });
  }

  closeChatBox(id) {
    let { chatBoxes } = this.state;
    remove(chatBoxes, (d) => d._id === id);
    this.setState({ chatBoxes });
    // get the opened chat boxes
    let shallow = this.state.openedChatBoxesID;
    if (shallow) {
      let isFound = shallow.includes(id);
      if (isFound) {
        shallow = shallow.filter((index) => index !== id)
        this.setState({openedChatBoxesID: shallow});
      }
    }
  }

  toggleChatBox(groupId) {
    let _chatBoxes = _.cloneDeep(this.state.chatBoxes);
    if (_chatBoxes.length > 0) {
      _chatBoxes.forEach((el) => (el._id === groupId ? (el.hide = !el.hide) : el.hide));
      this.setState({ chatBoxes: _chatBoxes });
    }
  }

  findDuplicate(data) {
    let match;
    this.state.groupChatList.forEach((el) => {
      if (el.type === data.type) {
        if (el.members.map((d) => d._id).intersect(data.members).length === 2) {
          match = el;
          return;
        }
      }
    });
    return match;
  }

  handleChatTabSelection(chatTabID) {
    this.setState({ isChatPop: false, selectedChatTab: chatTabID });
  }

  handleStateChange(key, value) {
    if (key) {
      this.setState({ [key]: value });
    }
  }

  async displayChatBox(chatDetails) {
    let { chatBoxes } = this.state;
    let data = {};
    const limit = 50; // Default number of messages to load
    let skip = 0; // Initialize the skip value for pagination
    await this.props.actions
      .getChatByGroupIds({ _ids: [chatDetails._id], limit, skip })
      .then((res) => {
        // ???
        if(!this.props.allGroups.find((ele) => ele._id === res[0]?._id)) this.props.actions.addGroupChats(res[0])
        
        let result = res ? res[0] : null;
        if (result) {
          const toPerson = result.members.find((f) => f._id !== loggedInUser._id);
          if (result) {
            data = {
              ...result,
              title:
                result.type === "groups"
                  ? result.groupName
                  : `${toPerson.name} ${toPerson.lastName || ""}`.trim(),
              messages: result.messages || [],
              members: result.members,
              _id: result._id,
              type: result.type,
              hide: false,
              channel:
                result.type === "driver"
                  ? `${result._id}`
                  : null,
              skip,
              limit,
              loadingMore: false, // To prevent multiple simultaneous fetches
            };
          }
        }
      });

    /// ???
    const index = chatBoxes.findIndex((obj) => obj._id === data._id);
    if (index > -1) {
      chatBoxes.splice(index, 1);
      chatBoxes.unshift(data);
    } else {
      if (!isEmpty(data)) {
        chatBoxes.unshift(data);
      }
    }

    const chatBox = chatBoxes?.find((c) => c?._id+"" === data._id+"");
    const groups = _.cloneDeep(this.state.groupChatList)
    const groupIndex = groups?.findIndex((g) => g?._id+ "" === chatBox?._id+"")
    if(groupIndex > -1) groups[groupIndex] = chatBox
    this.props.actions.setGroupChats(groups);

    this.setState({ chatBoxes }, () => {
      // $("#floatingChatsSidebar").slideUp(100);
      this.setState({ isChatPopDrop: false });
    });
  }

  render() {
    const { unreadUserMessages } = this.props;

    const driverUnreadMessage = unreadUserMessages.filter(
      (d) => d.type === "driver" && d.count > 0
    );
    const groupsUnreadMessage = unreadUserMessages.filter(
      (d) => d.type === "groups" && d.count > 0
    );
    const fleetmanagerUnreadMessage = unreadUserMessages.filter(
      (d) => d.type === "fleetmanager" && d.count > 0
    );
    const groupUnreadMessage = unreadUserMessages.filter(
      (d) => d.type === "group" && d.count > 0
    );
    const totalUnreadMessageCount =
      driverUnreadMessage.sum("count") +
      groupsUnreadMessage.sum("count") +
      fleetmanagerUnreadMessage.sum("count") +
      groupUnreadMessage.sum("count");

    const counts = {
      driverUnreadMessage,
      groupsUnreadMessage,
      fleetmanagerUnreadMessage,
      groupUnreadMessage,
    };

    const driver = this.state.groupChatList
      .filter((d) => d.type === "driver")
      .sort((a) =>
        driverUnreadMessage.map((d) => d.groupId).indexOf(a._id) > -1 ? -1 : 0
      );
    const groups = this.state.groupChatList
      .filter((d) => d.type === "groups")
      .sort((a) =>
        groupsUnreadMessage.map((d) => d.groupId).indexOf(a._id) > -1 ? -1 : 0
      );
    const fleetmanager = this.state.groupChatList
      .filter((d) => d.type === "fleetmanager")
      .sort((a) =>
        fleetmanagerUnreadMessage.map((d) => d.groupId).indexOf(a._id) > -1
          ? -1
          : 0
      );
    const group = this.state.groupChatList
      .filter((d) => d.type === "group")
      .sort((a) =>
        groupUnreadMessage.map((d) => d.groupId).indexOf(a._id) > -1 ? -1 : 0
      );

    const driverIndex = chatTabs.findIndex((d) => d.chatTabID === "driver");
    if (driverIndex > -1) {
      chatTabs[driverIndex].messageSnippets = driver
    }

    const groupsIndex = chatTabs.findIndex((d) => d.chatTabID === "groups");
    if (groupsIndex > -1) {
      chatTabs[groupsIndex].messageSnippets = groups;
    }

    const fleetmanagerIndex = chatTabs.findIndex(
      (d) => d.chatTabID === "fleetmanager"
    );
    if (fleetmanagerIndex > -1) {
      chatTabs[fleetmanagerIndex].messageSnippets = fleetmanager;
    }

    const groupIndex = chatTabs.findIndex((d) => d.chatTabID === "group");
    if (groupIndex > -1) {
      chatTabs[groupIndex].messageSnippets = group;
    }

    return (
      <Fragment>
        <li
          className={`nav-item ${
            this.state.isChatPopDrop === true ? "outline-1 outline-primary bg-primary-50 rounded-4" : ""
          } `}
          ref={this.setWrapperRef}
        >
          <HeaderChatIconComponent
            onClick={(e) => {
              e.preventDefault();
              this.setState({ isChatPopDrop: !this.state.isChatPopDrop });
            }}
            isChatPopDrop={this.state.isChatPopDrop}
            messageCount={totalUnreadMessageCount}
          />
          {this.state.isChatPopDrop && (
          <ChatDropdownComponent
            chatTabs={chatTabs}
            createGroupModal={this.createGroupModal}
            selectedChatTabID={this.state.selectedChatTab}
            onChatTabClicked={this.handleChatTabSelection}
            isChatPop={this.state.isChatPop}
            onBackButtonClicked={this.handleStateChange}
            displayChatBox={this.displayChatBox}
            counts={counts}
            createGroup={this.createGroup}
          />
        )}
        </li>
        
        { this.state.chatBoxes?.map((data, index) => {
            if (index < parseInt((window.innerWidth - 259) / 250))
              return (
                <ChatBoxWrapperNew
                  key={data._id + data.messages.length}
                  index={index}
                  {...data}
                  activeBox={this.state.activeBox}
                  changeActiveBox={this.changeActiveBox}
                  closeChatBox={this.closeChatBox}
                  toggleChatBox={this.toggleChatBox}
                  groupChatList={this.state.groupChatList}
                />
              );
          })}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userProfile: state.ProfileReducer,
    unreadUserMessages: state.HomeReducer.unreadMessages,
    allGroups: state.HomeReducer.groups,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatComponents);
