import React, { useState } from "react";
import { IconEye, IconEyeShow, IconMinus, IconPen, IconPlus, IconTrash } from "../../../../../Components/Common/Icons";
import AddRuleModal from "../AddRule/addRuleModal";
import SelectChargeModal from "../SelectCharge/selectChargeModal";
import _ from "lodash";
import { useAddChargeTable } from "../../hooks/useAddChargeTable.js";
import { unitOfMeasure } from "../../service/customerRateService";
import ReactTooltip from "react-tooltip";
import { Modal } from "react-bootstrap";
import { ChargeProfilesFromGroup } from "../../../Components/ChargeProfilesFromGroup/index.jsx";
import { useChargeCode } from "../../../../../hooks/helpers/useChargeCode.js";
import { getLatestTarrifAmount } from "../../../../../services";

const AddChargeTable = ({ initialData, onUpdate, hasTableRowErrors, chargeProfilesErr }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [showEyeIcon, setShowEyeIcon] = useState(null)
  const [openErrorModal, setOpenErrorModal] = useState([]);

  const { findChargeNameByCode } = useChargeCode();

  const {
    charges,
    setCharges,
    showAddRuleModal,
    selectChargeProfileModal,
    handleRemoveCharge,
    handleShowSelectChargeProfileModal,
    handleShowAddRuleModal,
    handleCloseAddRuleModal,
    handleCloseSelectChargeProfileModal,
    appendSelectedProfileCharges,
    cloneNewChargeProfiles,
  } = useAddChargeTable({ initialData: initialData, onUpdate: onUpdate });


  const onEditComplete = (updatedRow) => {
    const temp = charges?.length === 0 ? [updatedRow] :
      charges?.some((d, i) => i === updatedRow?.index) ?
        charges.map((data, i) => {
          if (i == updatedRow?.index) return { ...updatedRow }
          else return data;
        }) : [...charges, updatedRow];
    setCharges(temp);
  }

  const handleShowRowExpansionModal = (index) => {
    setExpandedRows(index);
  };

  const handleHideRowExpansionModal = (index) => {
    setExpandedRows(false);
  };

  const handleMouseOver = (index) => {
    setShowEyeIcon(index);
  }

  const handleMouseLeave = () => {
    setShowEyeIcon(null);
  }

  let ruleErrorMessages = {};
  Object.keys(hasTableRowErrors ?? {})?.forEach((key) => {
    ruleErrorMessages = { ...ruleErrorMessages, ...hasTableRowErrors?.[key] };
  });


  return (
    <>
      {charges?.length > 0 && (
        <div className="rounded-5 mb-0 bg-gray-50 p-1 mt-10">
          <table className="table table-card table-card-sm">
            <thead>
              <tr>
                <th>Charge/Profile</th>
                <th>Charge Name</th>
                <th>Unit Of Measure</th>
                <th>Description Of Charge</th>
                <th>Auto Add</th>
                <th className="text-right">Per Unit</th>
                <th className="text-right">Min. Total Price</th>
                <th className="text-right">Free Units</th>
                <th width="40px"></th>
                <th width="40px"></th>
              </tr>
            </thead>
            <tbody>
              {charges.map((charge, index) => (
                (!charge?.chargeTemplates && !charge?.isChargeProfileGroup) ?
                  (
                    <tr key={index} onMouseOver={() => handleMouseOver(index)} onMouseLeave={handleMouseLeave} className={`${hasTableRowErrors?.[index] ? 'bg-red-soft' : ''}`}>
                      <td className={`d-flex align-items-center justify-content-between ${showEyeIcon === index && 'bg-primary-50'}`} >
                        <div>
                          <div className="text-primary pointer" onClick={() => handleShowAddRuleModal({ ...charge, index: index, isDisabledAllCustomerRateModal: !charge?.isOneOffCharges })}>{charge?.name}</div>
                          {!charge?.isOneOffCharges && <div className="badge badge-light py-0">Profile</div>}
                        </div>
                        <button className={`btn btn-xs btn-primary wh-24px ${showEyeIcon === index ? "visible" : "invisible"}`} onClick={() => handleShowAddRuleModal({ ...charge, index: index, isDisabledAllCustomerRateModal: !charge?.isOneOffCharges })}><IconEye /></button>
                      </td>
                      <td> {findChargeNameByCode(charge?.chargeCode)?.label}</td>
                      <td> {unitOfMeasure?.find(d => d.value === charge.unitOfMeasure)?.label} </td>
                      <td> {charge?.description ?? <span className="text-muted">Add Description...</span>} </td>
                      <td> <span className="badge badge-light">{charge?.autoAdd ? "Yes" : "No"}</span> </td>
                      <td className="text-right"> {getLatestTarrifAmount(charge?.charges)?.amount.print()} </td>
                      <td className="text-right"> {getLatestTarrifAmount(charge?.charges)?.minimumAmount.print()} </td>
                      <td className="text-right"> {getLatestTarrifAmount(charge?.charges)?.freeUnits} </td>
                      <td>
                        <button className="btn btn-link px-0" onClick={() => handleShowAddRuleModal({ ...charge, index: index, isDisabledAllCustomerRateModal: !charge?.isOneOffCharges, ruleErrorMessages })} >
                          {!charge?.isOneOffCharges ? <IconEye /> : <IconPen />}
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-circle btn-xs bg-soft-danger"
                          onClick={() => handleRemoveCharge(index)}
                        >
                          <IconMinus />
                        </button>
                      </td>
                    </tr>
                  ) :
                  (
                    <>
                      {hasTableRowErrors?.[index] && 'border bg-danger'}
                      <tr key={`main-${index}`} className={`expanded-row ${hasTableRowErrors?.[index] ? 'bg-red-soft' : ''}`}>
                        <td className="p-0" colSpan="10">
                          <table className="w-100">
                            <tr>
                              <td width="40px">
                                <button className="btn btn-link px-0" onClick={() => handleShowRowExpansionModal(index)}>
                                  <IconEyeShow />
                                </button>
                              </td>
                              <td width="100%">{charge.name}</td>
                              <td width="40px">
                                <button
                                  className="btn btn-link px-0"
                                  onClick={() => handleRemoveCharge(index)}
                                >
                                  <IconTrash />
                                </button>
                              </td>
                            </tr>
                          </table>
                        </td>

                      </tr>
                      {expandedRows === index && (
                       
                              <ChargeProfilesFromGroup
                                showDeleteOptions={false} 
                                showChargeProfileGroup={expandedRows === index} 
                                chargeName={charge?.name}
                                chargeProfileGroupId= {charge?._id}
                                hide={handleHideRowExpansionModal}
                                chargeProfilesData= {charge?.chargeTemplates}
                              />
                           
                      )}
                    </>
                  )
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div
        className={`position-absolute d-flex align-items-center gap-5 mt-10`}
        style={{ top: 0, right: 40 }}
      >
        {chargeProfilesErr?.length > 0 &&
          <button
            className="btn btn-danger"
            data-tip
            data-for={`error`}
            onClick={() => setOpenErrorModal(chargeProfilesErr ?? [])}
          >
            Charge Profiles Error <span className="ml-10 badge bg-white text-dark rounded-circle wh-20px">{chargeProfilesErr?.length ?? 0}</span>
          </button>
        }
        <ReactTooltip id={`error`}>
          {
            chargeProfilesErr?.map((msg, i) => {
              return (
                <div className='w-100 d-flex align-items-center my-10 text-capitalize'>
                  <span className='mr-10'>{++i}.</span>
                  <span key={i}>{msg}</span>
                </div>
              )
            })
          }
        </ReactTooltip>
        <button className="btn btn-primary" onClick={() => handleShowAddRuleModal({ index: !charges?.length ? 0 : charges?.length, isOneOffCharges: true, systemGenerated: false, isActive: true, isDeleted: false })}>
          <IconPlus className="mr-1" />
          Add Charge Item
        </button>
        <button className="btn btn-primary" onClick={handleShowSelectChargeProfileModal}>
          Select Charge Profiles
        </button>
      </div>

      {showAddRuleModal && <AddRuleModal show={showAddRuleModal} editSelectedData={showAddRuleModal} close={handleCloseAddRuleModal} isDisabledAllCustomerRateModal={showAddRuleModal?.isDisabledAllCustomerRateModal} onEditComplete={onEditComplete} isEditModal={showAddRuleModal?.unitOfMeasure} cloneNewChargeProfiles={cloneNewChargeProfiles} />}
      {selectChargeProfileModal && (
        <SelectChargeModal
          show={selectChargeProfileModal}
          appendSelectedProfileCharges={appendSelectedProfileCharges}
          close={handleCloseSelectChargeProfileModal}
          checkedChargeProfiles={charges?.filter(d => d?._id && !d?.chargeTemplates)}
          checkedChargeGroups={charges?.filter(d => d?.chargeTemplates)}
        />
      )}

      {
        openErrorModal?.length > 0 && (
          <Modal show={true} bsSize='sm' backdropClassName="z-1050">
            <Modal.Header className="pb-0 pt-15">
              <Modal.Title>Error List</Modal.Title>
            </Modal.Header>
            <div className="hr-light" />
            <Modal.Body className="pt-0">
              <div className="p-1 bg-gray-50 rounded-5 gap-2 d-flex flex-column">
                {
                  openErrorModal?.map((msg, i) => {
                    return (
                      <div className='bg-white rounded-5 d-flex align-items-center p-10 text-capitalize'>
                        <span className='mr-10'>{++i}.</span>
                        <span key={i}>{msg}</span>
                      </div>
                    )
                  })
                }
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-outline-light" onClick={() => setOpenErrorModal([])}>
                Close
              </button>
            </Modal.Footer>
          </Modal>
        )
      }

    </>
  );
};

export default AddChargeTable;