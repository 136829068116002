import _ from 'lodash';
import confirm from '../ConfirmAert';
import { removeDualTransaction } from 'pages/tms/Dispatcher/NewDriverItinerary/actionCreators';

const AlertBreakDualTransaction = async (loadInfo, message, isLoading = () => {}, isDiscard=false, isRemoveDriver = false) => {
    const isDualTransactionLoad = loadInfo?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
    if(isDualTransactionLoad) {
        let reference_number = loadInfo?.reference_number;
        const isDualTransactionLoad = loadInfo?.driverOrder?.filter((leg) => !leg.isVoidOut && leg.isDualTransaction);
        const lastReferenceNumber = isDualTransactionLoad?.[0]?.dualTransactionTo?.reference_number || isDualTransactionLoad?.[0]?.dualTransactionFrom?.reference_number;
        const options = {
            isDualTransaction: true         
          };
        if (isDualTransactionLoad) {
            await new Promise(resolve => {
                confirm('Disconnect Dual Transaction', `This Will Disconnect The Dual Transaction. Once Disconnected, This Will No Longer Show On The Linked Tab Of The Dual Transaction Page.`,
                 (confirm) => {
                    if (confirm) {
                        if (isDiscard) {
                            const payload = {
                                _id: isDualTransactionLoad[0]?.combineTripId?._id ||isDualTransactionLoad[0]?.combineTripId
                            }
                            isLoading(true)
                            if (isRemoveDriver) payload.isRemoveDriver = true;
                            removeDualTransaction(payload).then((data) => {
                                isLoading(false)
                            }).catch((err) => {
                                isLoading(false)
                                console.log("🚀 ~ removeDualTransaction ~ err:", err)
                            })
                        }
                        resolve();
                    } else {
                        isLoading(false);
                        return false
                    }
                },options);  
            })
        }
    }
     else {
        return true;
    }
}

export default AlertBreakDualTransaction;