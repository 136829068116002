import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse ,errorColorResponse } from "../../../utility";
import { SYNC_REQUEST_SENT } from "pages/tms/Load/utility";
import { toastr } from "services";

const fetchContainerOwner = (containerOwner, searchSSL) => {
	let allSSLList = containerOwner
	if (allSSLList.length > 0) {
		if (searchSSL !== null) {
			allSSLList = allSSLList.filter((ssl) => {
				return (
					ssl.company_name
						.toLowerCase()
						.indexOf(
							searchSSL.toLowerCase().trim()
						) != -1
				);
			});
		}
		return allSSLList
	}
}

const ContainerOwnerList = ({ CellRef, onClose, Row, onRowChange, setIsAssignInProgress, column }) => {
	const { containerOwner } = useSelector((store) => store.containerReducer, shallowEqual);
	
	const [cursor, setCursor] = useState(null);
	const searchInputRef = React.useRef(null);
	const [searchSSL, setSearchSSL] = useState(null);
	const [sList, setSList] = useState(fetchContainerOwner(containerOwner, searchSSL));

	const cell = document.querySelector('[aria-selected="true"]');

	const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)
	const dispatch = useDispatch();

	useEffect(() => {
		searchInputRef.current?.focus();
		window.document.getElementById('root').style.pointerEvents = "none"
		return () => {
			window.document.getElementById('root').style.pointerEvents = "auto"
		}
	}, [])

	useOnClickOutside(boxRef, () => onClose(false))

	useEffect(() => {
		const _containerOwner = fetchContainerOwner(containerOwner, searchSSL);
		setSList(_containerOwner);
	}, [searchSSL]);

	const get = (data) => {
		const payload = {
			reference_number: Row.reference_number,
			containerOwner: data._id,
		}
		setIsAssignInProgress(true);
		editLoad(payload).then(el => {
			const containerOwner = { _id: data.id, company_name: data.company_name }
			const containerOwnerName = data.company_name
			if(el === SYNC_REQUEST_SENT){
				toastr.show(SYNC_REQUEST_SENT, "success");
				onRowChange({ ...Row, containerOwner: {_id: Row.containerOwner ?? '', company_name: Row.containerOwnerName ?? ''}, containerOwnerName: Row.containerOwnerName ?? ''});
				dispatch({ type: 'RESET_ANYKEY', reference_number: Row?.reference_number, value: {_id: Row.containerOwner ?? '',  company_name: Row.containerOwnerName ?? ''}, field: "containerOwner"});
				dispatch({ type: 'RESET_ANYKEY', reference_number: Row?.reference_number, value: Row.containerOwnerName ?? '', field: "containerOwnerName"});
			}else {
				onRowChange({ ...Row, containerOwner, containerOwnerName });
			}
			setIsAssignInProgress(false);
			onClose(false)
      		successColorResponse(cell,column.idx, Row?.className);
		}).catch((err) => {
			setIsAssignInProgress(false);
			onClose(false)
			errorColorResponse(cell,column.idx, Row?.className)
		})
	}

	const handleKeyDown = (event) => {
		if (event.keyCode === 38) {
			if (cursor >= 0) {
				setCursor(cursor - 1)
				document.getElementById(`driver-${cursor}`).focus();
			}
			event.preventDefault();
		} else if (event.keyCode === 40) {
			if (cursor <= containerOwner.length - 1) {
				if (cursor === null) {
					setCursor(0)
				} else {
					setCursor((prev) => prev + 1)
					document.getElementById(`driver-${cursor}`).focus();
				}
			}
			event.preventDefault();
		}
		else if (event.keyCode === 13) {
			sList.forEach((val, index) => {
				if (index === cursor) get(val);
			});
			event.preventDefault();
		} else if (event.keyCode === 9 || event.keyCode === 27) {
			onClose(false);
			event.preventDefault();
			event.stopPropagation();
		}
	}

	return createPortal(

		<div
			ref={boxRef}
			className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
			data-testid="container-owner-list"
			style={Style}
			>
			<div className="app-search bg-gray-50 rounded-lg mb-10">
				<input
					className="input-search-left form-control py-2"
					placeholder="Search SSL.."
					ref={searchInputRef}
					id="domTextElement"
					onChange={(e) => {
						setSearchSSL(e.target.value);
					}}

					onKeyDown={(event) => { handleKeyDown(event) }}
				/>
				<span className="search-icon ml-1"></span>
			</div>


			<ul className="driver_list m-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
				{(!sList || sList?.length === 0) ? (<p className="p-1">No SSL available</p>) : (sList?.map((el, index) => (
					<li
						key={index}
						className={`d-flex align-items-center pointer ${cursor === index
								? "bg-gradient-light"
								: null
							}`}
						onKeyDown={(e) => handleKeyDown(e)}
						tabIndex="-1"
						id={`driver-${index}`}

						onClick={async () => get(el)}
					>
						{el.company_name}
					</li>
				)))}
			</ul>
		</div>,
		document.getElementById('pp-overlay-container')
	);
};

export default ContainerOwnerList;
