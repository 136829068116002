import React ,{ useState,useRef, createRef } from "react";
import useHover from "../../../../../../Components/CustomHooks/useHover";
import PopperTooltip from "../portals/PopperTooltip";
import CsrToolTip from "./CsrToolTip";

const Csr = ({row}) => {
    const [csrRef, scrElement] = useHover();
  return(
    <div className="cell-content h-100">
        {row.assignedCSR ? 
              <div className="d-flex align-items-center h-100 rdg-truncate">
            {row.assignedCSR && row.assignedCSR.length> 0 && 
             row.assignedCSR.slice(0, 4).map((csr, index)=>{
                  return(
                    <CsrToolTip csr={csr} key={index} />
                  )	
            })}
            {row.assignedCSR && row.assignedCSR.length > 4 && 
                <>
                    <span ref={csrRef} >

                        <div className="group__avatar">
                            <div className="avatar avatar-circle avatar-xs bg-light border-1 text-primary ">
                                <span className= "text-dark"> +{row.assignedCSR.length - 4} </span> 
                            </div>
                        </div>

                    </span>
                    {row.assignedCSR && row.assignedCSR.length > 4 &&  scrElement &&
                        <PopperTooltip isOpen={scrElement} refNo={csrRef?.current} nameArray={row.assignedCSR.slice(4,row.assignedCSR.length )} color={'gray-700'} cssClass={"csr_tooltip"} />
                    }
                </>
                }

          </div>
          : ""}
    </div>
    
)
}
export default Csr;
