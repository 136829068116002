import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopperTooltip from "../portals/PopperTooltip";

const ReferenceNo = ({ row, column, onRowChange }) => {
	const cellRef = useRef();
	const dispatch = useDispatch();
	const fAction = useSelector((state) => state.dispatcherTableReducer);
	const [isOpen, setIsOpen] = useState(false);
  	const referenceElementRef = useRef(null);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.field === column.key) {
			setIsOpen(true);
			setTimeout(() => {
				dispatch({ type: 'CLEAR_VALUE' });
				setIsOpen(false);
			}, 500);
		}
		else if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_REFERENCENO') {
			onRowChange({ ...row, secondaryReferenceNo: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction]);
	return (
		<div ref={(node) => {
			cellRef.current = node;
			referenceElementRef.current = node;
		}} className="cell-content">
			{row[column.key] ? <div className="rdg-truncate">{row[column.key]}</div> : ""}
			{isOpen && fAction.reference_number === row.reference_number && fAction.field === column.key &&
				<PopperTooltip isOpen={isOpen} refNo={referenceElementRef?.current} name={'Copied'}  color={'gray-700'} cssClass={"driver_assign"} />
			}
		</div>
	);
};

export default ReferenceNo;
