import React, { Component } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { smallSelectStyle } from "../../../assets/js/select-style";
import { IconClock, IconDistance, IconDownloadAlt, IconSaveAsDefault } from "../../../Components/Common/Icons";
import { getStorage, updateNewTranscation, isNegativeDriverPayAllowed, isNewSupportMovesEnabled } from "../../../services";
import { ROUTING_LABELS } from "../constant";
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import RedirectToRates from "../../../Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates";
import { createManualDriverPay, getDurationInHHMMFormat } from "../LoadRouting/utilities";
import _ from "lodash";
import { manualDriverPay } from "../services";
import NewSupportMove from "./NewSupportMove";
import { getTrackingEventClasses } from "utils";

class SupportMove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editDriverPayData: null,
    };
  }

  updateDriverPay = (driverPay, value, status, nextValidLeg) => {
    value = value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '');
    const loadDetails = {
      _id: this.props.supportMoveDetail?.prevLoadId,
      reference_number:this.props.supportMoveDetail?.prevReferenceNo
    }
    if (driverPay) {
      const editDriverPayData = _.cloneDeep(driverPay);
      editDriverPayData.amount = value;
      this.setState({ editDriverPayData });
    }
    else if (status && nextValidLeg) {
      const editDriverPayData = _.cloneDeep(createManualDriverPay(loadDetails, status, nextValidLeg));
      editDriverPayData.amount = value;
      this.setState({ editDriverPayData });
    }
  }
  
  onBlurHandleForDrivePay = (field) => {
    const _editDriverPayData = _.cloneDeep(this.state.editDriverPayData);
    if (!_editDriverPayData) return;
    
    if (_editDriverPayData?._id) {
      const actualPay = this.props.driverPays.find((d) => d._id === _editDriverPayData._id);
      if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
        this.setState({ editDriverPayData: null });
        return;
      }
      const params = {
        id: _editDriverPayData._id,
        amount: _editDriverPayData.amount || 0,
        isManual: true,
        isFromSettlement: false
      }
      updateNewTranscation(params)
      .then((response) => {
        const _driverPays = _.cloneDeep(this.props.driverPays);
        if (response?.data?.data) {
          const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
          if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
        }
        this.props.setNewDriverPays(_driverPays)
        this.setState({ editDriverPayData: null });
        this.transitionEffect(field, true);
      }).catch(err=>{
        this.transitionEffect(field, false);
      })
    }
    else {
      delete _editDriverPayData?.invoiceCurrency?.symbol;
      delete _editDriverPayData.isManual;
      delete _editDriverPayData.currencySymbol;
      const params = {
        ..._editDriverPayData,
        supportMove: this.props.supportMoveDetail?._id
      };
      manualDriverPay(params).then(async (response) => {
        const _driverPays = _.cloneDeep(this.state.driverPays);
        if (response?.data?.data) _driverPays.push(response.data.data);
        this.setState({ driverPays: _driverPays, editDriverPayData: null });
        this.transitionEffect(field, true)
      }).catch(err => {
        this.transitionEffect(field, false)
      })
    }
  }

  render() {
    const move = this.props.move;
    const firstMoveEvent = move[0];
    const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
    const timeZone = getStorage("timeZone");

    return (
      isNewSupportMovesEnabled() ? <NewSupportMove {...this.props}/> :
      <>
        <div className="card card-blue mb-10">
          <div className="p-10 d-flex align-items-center gap-10 flex-wrap">
                <div className={`btn bg-purple-150 rounded-6 card-blue__avatar text-white line-height-18`}>
                 <div className="text-purple-400 font-12 font-weight-500">Support Move</div>
                </div>
                <div>
                <Select
                  value={firstMoveEvent?.driver ? {
                    label: `${firstMoveEvent.driver.name} ${firstMoveEvent.driver.lastName || ""}`.trim(),
                    value: firstMoveEvent.driver._id
                  } : ""}
                  className={`w-100 `}
                  isDisabled={true}
                  styles={smallSelectStyle}
                />
                </div>
          </div>
          <div className="hr-light my-0" />

          <div className="p-10 col d-flex flex-column gap-10">
            {move?.length && move.map((_mEvent, index) => {
              const nextValidLegIndex = move.findIndex((d, i) => i > index && !d.isVoidOut);
              const nextValidLeg = move[nextValidLegIndex];
              let nextEventDuration = getDurationInHHMMFormat(
                nextValidLeg?.time, 
                _mEvent?.time,
                (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: _mEvent?.timeZone || timeZone, toTimeZone: _mEvent?.timeZone || timeZone})
              );
              const carrierDetails = JSON.parse(getStorage("carrierDetail"));
              const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";
              const isEditingPay = this.state.editDriverPayData
              let driverPay = this.props?.driverPays?.find(pay => pay.fromEventId === _mEvent._id && pay?.createdBy?._id === firstMoveEvent?.driver?._id );
              if (isEditingPay) driverPay = this.state.editDriverPayData;
              const regex = isNegativeDriverPayAllowed() ? /[0-9.-]/ : /[0-9.]/

              const isFirstEvent = index === 0;
              const isLastEvent = index === (move.length - 1);
              const isVoidOut = _mEvent.isVoidOut;
              const _isCompletedEvent = _mEvent.time;
              const _isInProgressEvent = !_mEvent.time;
              
              const eventClass = getTrackingEventClasses(isVoidOut, _isCompletedEvent, _isInProgressEvent);
              const eventMainClass = `route-line route-line-full route-line--bottom ${eventClass?.routeLineColorClass} ${eventClass?.eventCircleColorClass}`  

              const LayoutComponent = ({label, children, isDuration}) => {
                                
              const routeLineClass = !isDuration && `route-line route-line--top ${!isLastEvent ? "route-line--bottom":""} ${eventClass.routeLineColorClass}`;
                return(
                    <div className='d-flex align-items-center gap-15'>
                        <div className={`wh-20px d-flex align-items-center justify-content-center position-relative`}>
                            {!isDuration &&
                                <div className={`wh-6px rounded-circle outline-1 ${eventClass.dotColorClass} ${routeLineClass}`}>
                                </div>
                            }
                        </div>
                        <div className='w-125 text-muted'>
                            {label && label}
                        </div>
                        {children && children}
                    </div>
                )
              }

              return (
                <div className="d-flex flex-column gap-10 position-relative ">
               
               <div className={`outline-card p-10 ${eventClass.routeLineColorClass} ${!isLastEvent ? "route-line--cardbottom" : ""}`}>
                <div className='d-flex align-items-center mb-10 gap-10'>         
                  <div className={`position-relative route-line route-line--bottom ${eventClass.routeLineColorClass} ${!isFirstEvent ? "route-line--top route-line--topfull" : ""} wh-20px d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle ${eventClass.eventCircleColorClass}`}>
                      <span className='font-10 line-height-12 font-bold'>{index + 1}</span>
                  </div>
                  <div className='w-125'>
                      <span className={`badge ${eventClass.badgeColorClass}`}>
                        {SUPPORT_MOVES_LABEL[_mEvent.type] && SUPPORT_MOVES_LABEL[_mEvent.type].label}
                      </span>
                  </div>

                  <AsyncSelect
                    className={`w-100 pl-10`}
                    value={_mEvent.customerId ? {
                      label: `${_mEvent.customerId.company_name} ${_mEvent.customerId?.address?.address || ""}`,
                      value: _mEvent.customerId?._id,
                    } : null}
                    styles={smallSelectStyle}
                    isDisabled={true}
                  />

                  {_mEvent.conReferenceNo && this.props.reference_number !== _mEvent.conReferenceNo  && <span className="badge badge-info">
                      {_mEvent.conReferenceNo}
                    </span>}
                </div>

                  
                  
                {_mEvent.time &&
                  <LayoutComponent label={SUPPORT_MOVES_LABEL[_mEvent.type]?.action}>
                        <div className="input-wrapper">
                            <ExactDateWrapper
                              className="mr-2 laDate right-0"
                              inputClassName="form-control-xss"
                              displayTimeZone={getStorage("timeZone")}
                              disabled={true}
                              defaultDate={_mEvent.time}
                              dateFormat="MM-DD-yyyy hh:mm A"
                              timeFormat={this.props.userTimeFormat}
                              OnBlurUpdate={() => { }}
                              hideShowSelectDate={true}
                              isShowTime={true}
                            />
                        </div>
                  </LayoutComponent>
                }
              </div>
              {index !== (move.length - 1) && !_mEvent.isVoidOut && ( <div className="">
                    <div className="d-flex align-items-center gap-10 pl-10">
                      <div className="w-20px"/>
                      <div className="w-125" />
                      <div className="d-flex px-10 py-1 bg-gray-50 rounded-5 align-items-center gap-10 ml-15 w-100">
                        
                        <div className="d-flex align-items-center gap-5">
                          <IconClock className="text-primary" />
                          <span className="text-gray-700">Duration:</span>
                          <div className="text-dark  font-12 font-weight-bold">{nextValidLeg?.time ? nextEventDuration : "-"}</div>
                        </div>
                        <div className="d-flex align-items-center gap-5">
                          <IconDistance className="text-primary" />
                          <span className="text-gray-700">Distance:</span>
                          <div className="text-dark  font-12 font-weight-bold">{(_mEvent.customerId && nextValidLeg.customerId && _mEvent?.distance>=0 ) ? _mEvent?.distance + " " + distanceMeasure : "-"}</div>
                        </div>
                        <div className="d-flex align-items-center gap-5">
                          <span className="text-gray-700">Driver Pay</span>
                          <div className="form-group m-0" style={{ width: "103px" }}>
                            <div className={`input-wrapper driverPay-${0}-${index}`}>
                              <div>
                                <input
                                  type="text"
                                  name="driverPay"
                                  disabled={this.props.isLoading}
                                  className={`form-control form-control-xs driverPay-${0}-${index} pr-30 transition-white-field`}
                                  id={`driverPay-${0}-${index}`}
                                  placeholder={"$00.00"}
                                  value={driverPay
                                    ? `${driverPay?.invoiceCurrency?.symbol || this?.props?.currencySymbol}${
                                      isEditingPay ? driverPay.amount : parseFloat(driverPay.amount || 0)?.toFixed(2)
                                    }`
                                    : ""
                                  }
                                  onChange={(e) => this.updateDriverPay(driverPay, e.target.value, _mEvent, nextValidLeg)}
                                  onBlur={(e) => this.onBlurHandleForDrivePay(`driverPay-${0}-${index}`)}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      this.onBlurHandleForDrivePay(`driverPay-${0}-${index}`)
                                    }
                                    if (!regex.test(event.key) || 
                                      (event.target.value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '').trim().indexOf('.') != -1
                                      && event.key===".") || (event.key==="-" && event.target.value.replace(driverPay?.invoiceCurrency?.symbol || this.props.currencySymbol, '').trim() !== '')
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                              <div className="input-icon p-0 pr-10">
                                  {driverPay ?
                                    <RedirectToRates
                                      info={driverPay}
                                      redirectToRates={this.props?.redirectToRates}
                                    /> : 
                                    <IconSaveAsDefault />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>)}
                </div>
              );
            })}
          </div>

        </div>
      </>
    );
  }
}

export default SupportMove;

const SUPPORT_MOVES_LABEL = {
  'CHASSIS_STARTED': { label: 'Chassis Started', action: 'Start' },
  'CHASSIS_ENDED': { label: 'Chassis Ended', action: 'Arrival' },
  'BOBTAIL_STARTED': { label: 'Bobtail Start', action: 'Start' },
  'BOBTAIL_ENDED': { label: 'Bobtail End', action: 'Arrival' },
  'CHASSISTRANSFER': { label: "Chassis Transfer", action: 'Start' },
  'BOBTAILTRANSFER': { label: "Bobtail Transfer", action: 'Start' }
};