import React, { useRef, useState, useEffect } from 'react'
import DateEditor from '../CellComponents/DateEditor';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import { formatToDateAndTime } from '../../Functions';
import { useDispatch, useSelector } from 'react-redux';

const ReturnToTimes = ({ row, column, onRowChange, isCellSelected }) => {
	const [showBox, setShowBox] = useState(false)
	const [isColorPortal, setColorPortal] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	const dispatch = useDispatch();
  	const fAction = useSelector((state) => state.dispatcherTableReducer);

	const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected;
		if (!isCellSelected || row.type_of_load == "ROAD") {
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.returnToTime);
	}, [column, row?.colorOfCells?.returnToTime]);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "returnToTime") {
			onRowChange({ ...row, returnToTime : fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])
	return (
		<>
			<div ref={cellRef} className="cell-content" onClick={() => showModel()} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
				{row.returnToTime && 
					<div>
						{formatToDateAndTime(row.returnToTime)}
						<span>&nbsp;</span>
					</div>
				}
			</div>
			{row.isEditPermission && showBox
				&& row.type_of_load !== "ROAD" && <DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onOpen={showModel}
					row={row}
					column={column}
					onRowChange={onRowChange} />
			}
			{isColorPortal && 
				<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="returnToTime" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
			}
		</>
	)
}

export default ReturnToTimes;