import React, { useState, useCallback, useContext } from 'react';
import FolderItem from './FolderItem';
import { changeFolderOrderCollapseStatus } from '../../../actionCreator';
import { debounce } from 'lodash';
import { NEW_EMAIL_NAV_CONTEXT } from 'pages/tms/Email/constant';
import FolderContextMenuWrapper from '../../FolderContextMenuWrapper';

const FOLDER_DRAG_TYPE = 'folder-tree-drag';
let currentDragType = null;

const FolderTree = ({ nestedFolders, provider, isCollapsed, setNestedFolders }) => {
    const [folders, setFolders] = useState(nestedFolders);
    const [draggedFolder, setDraggedFolder] = useState(null);
    const [dragOverId, setDragOverId] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const { setActiveNav } = useContext(NEW_EMAIL_NAV_CONTEXT);

    const handleAddFolder = (folder) => {
        const newFolder = {
            ...folder,
            collapse: true,
            order: folders.length + 1
        };
        const updatedFolders = [...folders, newFolder];
        setFolders(updatedFolders);
    }

    setNestedFolders && setNestedFolders(setFolders);

    const debouncedChangeFolderStatus = useCallback(
        debounce((updatedFolders) => {
            changeFolderOrderCollapseStatus(updatedFolders);
        }, 500),
        []
    );

    const handleDragStart = (e, folder) => {
        currentDragType = FOLDER_DRAG_TYPE;
        setIsDragging(true);
        setDraggedFolder(folder);
        e.dataTransfer.setData('dragType', FOLDER_DRAG_TYPE);
        e.dataTransfer.setData('text/plain', ''); // Required for Firefox
        e.stopPropagation();
    };

    const handleDragEnd = (e) => {
        currentDragType = null;
        setIsDragging(false);
        setDraggedFolder(null);
        setDragOverId(null);
        e.stopPropagation();
    };

    const handleDragOver = (e, folder) => {
        e.preventDefault();
        if (currentDragType !== FOLDER_DRAG_TYPE) {
            e.dataTransfer.dropEffect = 'move';
            return;
        }

        if (!draggedFolder || draggedFolder.id === folder?.id || draggedFolder?.parentId !== folder?.parentId) {
            e.dataTransfer.dropEffect = 'move';
            return;
        }
        setDragOverId(folder?.id);
        e.dataTransfer.dropEffect = 'copy';
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOverId(null);
        e.stopPropagation();
    };

    const toggleFolder = (folderId) => {
        const updateFolderExpanded = (folders) => {
            return folders.map((folder) => {
                if (folder.id === folderId) {
                    return { ...folder, collapse: !folder.collapse };
                }
                if (folder.subfolders) {
                    return {
                        ...folder,
                        subfolders: updateFolderExpanded(folder.subfolders),
                    };
                }
                return folder;
            });
        };

        const updatedFolders = updateFolderExpanded(folders);
        debouncedChangeFolderStatus(updatedFolders);
        setFolders(updatedFolders);
    };

    const reorderFolders = (folders, draggedFolder, targetFolder) => {
        const updateFolderOrder = (folderList) => {
            return folderList.map((folder, index) => ({
                ...folder,
                order: index + 1,
            }));
        };

        if (draggedFolder.parentId === targetFolder.parentId) {
            const updatedFolders = [...folders];
            const draggedIndex = updatedFolders.findIndex((f) => f.id === draggedFolder.id);
            const targetIndex = updatedFolders.findIndex((f) => f.id === targetFolder.id);

            if (draggedIndex !== -1 && targetIndex !== -1) {
                const [removed] = updatedFolders.splice(draggedIndex, 1);
                updatedFolders.splice(targetIndex, 0, removed);
                return updateFolderOrder(updatedFolders);
            }
        }

        return folders.map((folder) => {
            if (folder.subfolders && folder.subfolders.length > 0) {
                if (draggedFolder.parentId === folder.id && targetFolder.parentId === folder.id) {
                    const updatedSubFolders = [...folder.subfolders];
                    const draggedIndex = updatedSubFolders.findIndex((f) => f.id === draggedFolder.id);
                    const targetIndex = updatedSubFolders.findIndex((f) => f.id === targetFolder.id);

                    if (draggedIndex !== -1 && targetIndex !== -1) {
                        const [removed] = updatedSubFolders.splice(draggedIndex, 1);
                        updatedSubFolders.splice(targetIndex, 0, removed);
                        return {
                            ...folder,
                            subfolders: updateFolderOrder(updatedSubFolders),
                        };
                    }
                }
                return {
                    ...folder,
                    subfolders: reorderFolders(folder?.subfolders, draggedFolder, targetFolder),
                };
            }
            return folder;
        });
    };

    const handleDrop = (e, targetFolder) => {
        e.preventDefault();
        const dragType = e.dataTransfer.getData('dragType');
        if (dragType !== FOLDER_DRAG_TYPE) {
            return;
        }
        if (!draggedFolder || draggedFolder?.id === targetFolder?.id) {
            return;
        }

        const updatedFolders = reorderFolders(folders, draggedFolder, targetFolder);
        debouncedChangeFolderStatus(updatedFolders);
        setFolders(updatedFolders);
        handleDragEnd(e);
    };

    return (
        <>
            {!isCollapsed && <div className="d-flex flex-column gap-1 mt-1">
                {folders.map((folder) => (
                    <FolderItem
                        key={folder?.id}
                        folder={folder}
                        level={0}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        updateFolders={setFolders}
                        onToggle={toggleFolder}
                        dragOverId={dragOverId}
                        draggedFolder={draggedFolder}
                        isDragging={isDragging && draggedFolder?.id === folder?.id}
                        provider={provider}
                        folders={folders}
                    />
                ))}
            </div>}
        </>
    );
};

export default FolderTree; 