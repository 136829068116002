import { ACTIONS } from "./action.js";

export default function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.TOGGLE_SLOT: {
      const { payload } = action;

      const { selectedSlots, rows } = state;

      const startDate = payload.startDate;
      const endDate = payload.endDate;

      const slotAlreadyExists = selectedSlots.find((eachSlot) => {
        return eachSlot?.startDate == startDate && eachSlot?.endDate == endDate;
      });

      if (slotAlreadyExists) {
        return {
          ...state,
          selectedSlots: selectedSlots.filter((eachSlot) => {
            return eachSlot?.startDate != startDate && eachSlot?.endDate != endDate;
          }),
        };
      }

      return {
        ...state,
        selectedSlots: [...selectedSlots, { startDate, endDate }],
      };
    }

    // ADD bulk selected slots
    case ACTIONS.BULK_ADD_SLOTS: {
      const { payload } = action;
      // PAYLOAD sample
      // [
      //   {
      //     startDate: "2023-06-08T20:00:00.000Z",
      //     endDate: "2023-06-08T21:00:00.000Z",
      //   },
      //   {
      //     startDate: "2023-06-08T21:00:00.000Z",
      //     endDate: "2023-06-08T22:00:00.000Z",
      //   },
      // ];
      return {
        ...state,
        selectedSlots: payload,
      };
    }

    case ACTIONS.COUNT_APPOINTMENTS: {
      const { payload } = action;
      return {
        ...state,
        slotsCount: payload,
      };
    }
    default:
      return state;
  }
}
