import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import { getStorage } from './../services/Common.services';

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
});
// testid 5ae8972b5eb3b02839cad0e1
global.config = {
  map_box_api_key: 'pk.eyJ1IjoiYmlrcmFtYmFzbmV0IiwiYSI6ImNqNXZhMjZtMDIzb3MzM3M2dHBpM3Z2Nm8ifQ.axKcLyOqjTsIyQ17MtnoeQ',
  publishKey: 'pub-c-removed',
  subscribeKey: 'sub-c-removed',
  baseUrl: process.env.REACT_APP_API_URL|| 'https://testapidev.app.portpro.io/',
  docUrl: process.env.REACT_APP_DOC_API_URL || 'https://tmx-doc.axle.network/',
  pdfUrl: process.env.REACT_APP_PDF_API_URL || 'https://tmx-pdf-api.app.portpro.io/' ,
  newDocUrl : process.env.REACT_APP_DOC_DESIGN_URL || 'https://new-doc-dev.axle.network/',
  publicApiUrl: process.env.REACT_APP_PUBLIC_API_URL|| 'https://myapps.portpro.io/',
  ediUrl: process.env.REACT_APP_EDI_URL || 'https://dev.edi.axle.network/',
  devUrl:'https://stagingapi.app.portpro.io/',
  localUrl: 'http://localhost:8081/',
  socketUrl: 'https://testapi.app.portpro.io/',
  idForTerminal: getStorage("idForTerminal") || [],
  idForCarrier:['5ae8972b5eb3b02839cad0e1','606c912832bce740e2a297fe','607d7623b8e7522545be3f13'],
  idForCompany:['60196e05993170084efd0f4d','5ae8972b5eb3b02839cad0e1','60747b8448cb687a0f377e82'],
  idForCanada:['5ae8972b5eb3b02839cad0e1','6058a1f9afecf214ec2fab2b','60196e05993170084efd0f4d'],
  idForCanadaSettings:['5ae8972b5eb3b02839cad0e1','6058a1f9afecf214ec2fab2b'],
  idForCustomChargeReport: getStorage("idForCustomChargeReport") || [],
  pubNubPublishKey: 'pub-c-6f5631a5-1b5e-48ca-9f26-526897f613bb',
  pubNubSubscribeKey: 'sub-c-6de51438-0fe3-11e9-a971-425ad67106f3',
  prodFe: "https://app.portpro.io/tms/ai",
  devFe: "https://dev.app.portpro.io/tms/ai",
  localFe: "http://localhost:3000/tms/ai",
  version: '2.37.20',
  newModal: false,
  sandBoxUrl: process.env.REACT_APP_SANDBOX_URL || "https://sandbox-backend.app.portpro.io/",
  mongoChartBaseUrl: process.env.REACT_APP_MONGO_CHART_BASE_URL || "https://charts.mongodb.com/charts-project-0-zzier"
}

const getNewFirebaseInstance = (appName) => {
  return firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
  }, appName);
}

global.config.firebase = firebaseApp;
global.config.getNewFirebaseInstance = getNewFirebaseInstance;

export default global.config;
