import { components } from "react-select";
import { BadgeTooltipWrapper, calculateVisibleOptions } from "Components/Common/CustomSelect/CheckboxAsyncSelect";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
export const Option = (props) => {
  return (
    <div>
      <components.Option {...props} className={`${props.className} d-flex gap-10 `}>
        <input className="form-check-primary" type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label className="mb-0">{props.updateLabel ? props.updateLabel(props.label) : props.label}</label>
      </components.Option>
    </div>
  );
};

export const customOption = ({ innerProps, label, value, isSelected }) => {
  return (
    <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
      <input type="checkbox" checked={isSelected} onChange={() => {}} className="mr-10" />
      {label}
    </div>
  );
};

const CustomClearText = () => <>clear all</>;
export const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    innerProps: { ref, ...restInnerProps },
    name,
    updateLabel,
  } = props;
  const [valueContainerWidth, setValueContainerWidth] = useState(0);
  
    const visibleOptions = calculateVisibleOptions(
      props?.selectProps?.value.map(option => option.label),
      updateLabel,
      valueContainerWidth,
      true
    );

  useLayoutEffect(() => {
      const updateWidth = () => {
        if(!name){
          return
        }
        const valueContainerElement = document?.querySelector?.(`.${name}`);
        if (valueContainerElement.offsetWidth) {
          setValueContainerWidth(valueContainerElement.offsetWidth);
        }
      };
  
      updateWidth();
  
      const handleResize = () => {
        updateWidth();
      };
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <>
      {(props?.selectProps?.value?.length || 0) - (visibleOptions) > 0 && visibleOptions ? (
        <BadgeTooltipWrapper
          className={"mr-1"}
          count={
            <ul className="d-flex flex-column gap-5 mb-0">
              {props?.selectProps?.value?.slice(visibleOptions).map((option, index) => (
                <li key={index}>{props?.updateLabel ? props.updateLabel(option.label) : option.label}</li>
              ))}
            </ul>
          }
        >
          +{(props?.selectProps?.value?.length || 0) - (visibleOptions)}
        </BadgeTooltipWrapper>
      ) : null}
      {props?.isClearable && (
        <div {...restInnerProps} ref={ref}>
          <div style={{ padding: "0px 5px" }}>{children}</div>
        </div>
      )}
    </>
  );
};
