const REPORT_NAMES = {
  // Admin
  CREATED_LOADS: "Created Loads",
  OPEN_LOADS: "Open Loads",
  PICKED_UP: "Picked Up",
  DELIVERED: "Delivered",
  RETURNED: "Returned",
  LOADS_BY_TYPE: "Loads By Type",
  LOADS_BY_CUSTOMER: "Loads By Customer",
  TOP_DELIVERY_LOCATIONS: "Top Delivery Locations",
  TOP_RETURN_LOCATIONS: "Top Return Locations",
  TOP_PICK_UP_LOCATIONS: "Top Pick Up Locations",
  COMPLETED_LOADS: "Completed Loads",
  // Broker
  BROKER_TENDERS_ACCEPTED:"Tenders Accepted",
  BROKER_TENDERS_REJECTED: "Tenders Rejected",
  BROKER_INVOICES_RECEIVED_BY_CARRIER: "Invoices Received by Vendor",
  BROKER_TENDERS_BY_CARRIER: "Tenders by Vendor",
  BROKER_LOAD_COMPLETED_NOT_INVOICED: "Completed, Not Invoiced",

  // Employees
  DRIVER_PAY_APPROVES: "Driver Pay Approved",
  DRIVER_SETTLEMENTS_FINALIZED: "Driver Settlements Finalized",
  // Financials
  FINANCIAL_DAYS_TO_INVOICE: "Days To Invoice",
  FINANCIAL_NUMBER_OF_INVOICES: "Number Of Invoices",
  FINANCIAL_TOTAL_PROFIT: "Total Profit",
  FINANCIAL_GROSS_DRIVER_PAY: "Gross Driver Pay",
  FINANCIAL_GROSS_VENDOR_PAY: "Gross Vendor Pay",
  FINANCIAL_AVERAGE_MARGIN: "Average Margin",
  FINANCIAL_REVENUE_BY_LOAD_TYPE: "Revenue by Load Type",
  FINANCIAL_REVENUE_BY_DELIVERY_LOCATION: "Revenue by Delivery Location",
  FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS: "Most Profitable Delivery Locations",
  FINANCIAL_REVENUE_BY_CHARGE_NAME: "Revenue By Charge Name",
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE: "Most Profitable Customers Percentage",
  FINANCIAL_PAST_DUE_INVOCES: "Past Due invoices",
  FINANCIAL_TOP_EARNING_DRIVERS: "Top Earning Drivers",
  FINANCIAL_PERCENTAGE_OF_MARGIN: "Percentage of Margin",
  FINANCIAL_EXPENSES: "Expenses",
  FINANCIAL_TOTAL_REVENUE: "Total Expenses",

  // Equipment
  EQUIPMENT_TRUCK_USAGE: "Truck Usage",
  EQUIPMENT_CHASSIS_USAGE :"Chassis Usage",
  EQUIPMENT_TRAILER_USAGE : "Trailer Utilization",
  EQUIPMENT_CHASSIS_LAST_USED: "Chassis",
  EQUIPMENT_TRUCK_LAST_USED: "Truck",
  EQUIPMENT_TRAILER_LAST_USED: "Trailer",

  // Customer service
  CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD: "Employee Updates Per Load",
  CUSTOMER_SERVICE_LOADS_CREATED: 'Loads Created',
  CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR: "Loads Assigned to CSR",

  // Operations
  OPERATION_LOADS_COMPLETED: "Loads Completed",
  OPERATION_LOADS_COMPLETED_BY_WEEK: "Completed - Day Of Week",
  OPERATION_TOTAL_MOVES_COMPLETED: "Total Moves Completed",
  OPERATION_MOVES_COMPLETED_BY_DRIVER: "Moves Completed By Driver",
  OPERATION_EVENTS_BY_DRIVER: "Breakdown Of Moves By Driver",
  OPERATION_TIME_TO_TERMINATE: "Time To Terminate",
  OPERATION_TIME_TO_RETURN: "Time To Return",
  OPERATION_DISPATCHES_BY_EMPLOYEE: "Dispatches By Employee",
  OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER: "Daily Average Moves Per Driver",

  // System
  SYSTEM_CHARGE_CREATION_PERCENTAGE: "Charge Creation Percentage",
  SYSTEM_PAY_CREATION_PERCENTAGE: "Pay Creation Percentage",
  SYSTEM_LOAD_STATUS_PERCENTAGE: "Load Status Percentage",
  SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE: "Document Uploads Percentage",
  SYSTEM_LOAD_CREATED_BY: "Load Created By",
  SYSTEM_CRON_REPORTS: "Cron Reports",

  // Employee
  EMPLOYEE_DRIVER_PAY_APPROVED: "Driver Pay Approved",
  EMPLOYEE_SETTLMENT_APPROVED: "Driver Settlements Finalized",

  // Customer
  CUSTOMER_CUSTOMER_PORTAL_USAGE: "Customer Portal Usage",
  CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE: "Customer Portal Usage Percentage",
  CUSTOMER_TIMES_MARKED_READY_TO_RETURN: "Times Marked Ready To Return",

  // Track
  TRACK_PER_DIEM_PERCENTAGE: "Per Diem Percentage",
  TRACK_LAST_FREE_DAY_PERCENTAGE: "LFD Percentage",
  TRACK_NUMBER_OF_TIRS: "TIRs Uploaded",
  TRACK_PER_DIEM_TOTAL: "Per Diem Total",
  TRACK_DEMURRAGE_TOTAL: "Demurrage Total",
  TRACK_DWELL_TIME: "Dwell Time At Port",
  TRACK_NO_OF_APPTS: "Number Of Appts",
}

const CREATED_LOADS = () => {
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "created_date",
      name: "Created Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Created Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <>
          <div class="cell-content">
            <div class="text-wrap clamp-2">
              {row?.["Pickup Location"]}
            </div>
          </div>
        </>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <>
          <div class="cell-content">
            <div class="text-wrap clamp-2">
              {row?.["Delivery Location"]}
            </div>
          </div>
        </>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <>
          <div class="cell-content">
            <div class="text-wrap clamp-2">
              {row?.["Return Location"]}
            </div>
          </div>
        </>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CREATED_LOADS,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
};

const COMPLETED_LOADS = () => {
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "completed_date",
      name: "Completed Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Completed Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pickup Location"]}</div>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Delivery Location"]}</div>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Location"]}</div>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.COMPLETED_LOADS,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
}

const PICKED_UP = () => {
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "pickedup_date",
      name: "Pick Up Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pick Up Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pickup Location"]}</div>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Delivery Location"]}</div>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Location"]}</div>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.PICKED_UP,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
}

const DELIVERED = () => {
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "delivere_date",
      name: "Deliver Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Deliver Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pickup Location"]}</div>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Delivery Location"]}</div>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Location"]}</div>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.DELIVERED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
}

const RETURNED = () => {
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "return_date",
      name: "Return Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pickup Location"]}</div>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Delivery Location"]}</div>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Location"]}</div>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.RETURNED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
}

const FINANCIAL_NUMBER_OF_INVOICES = ()=>{
  const columns = ()=> [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.Customer}</div>;
      },
    },
    {
      key: "revenue",
      name: "# Of Invoices",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Invoices"])}</div>;
      },
    },
    {
      key: "total_amount",
      name: "Total Amount",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Total Amount"] || 0)?.toCurrency()}</div>;
      },
    },
    {
      key: "average_amount_per_invoice",
      name: "Average Amount/Invoice",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Amount/Invoice"] || 0)?.toCurrency()}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_NUMBER_OF_INVOICES,
    isFilter: false
  }
}

const FINANCIAL_GROSS_DRIVER_PAY = ()=>{
  const columns = () => [
    {
      key: "company_name",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver Name"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Gross Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Gross Pay"] || 0)?.toCurrency()}</div>;
      },
    },
    {
      key: "average_pay_per_load",
      name: "Average Pay/Load",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Pay/Load"] || 0)?.toCurrency()}</div>;
      },
    },
    {
      key: "# of Loads",
      name: "# of Loads",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Loads"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_GROSS_DRIVER_PAY,
    isFilter: false
  };
}

const FINANCIAL_GROSS_VENDOR_PAY = ()=>{
  const columns =()=> [
    {
      key: "company_name",
      name: "Vendor Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Vendor Name"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Gross Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Gross Pay"] || 0)?.toCurrency()}</div>;
      },
    },
    {
      key: "average_pay_per_load",
      name: "Average Pay/Load",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Pay/Load"] || 0)?.toCurrency()}</div>;
      },
    },
    {
      key: "# of Loads",
      name: "# of Loads",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Loads"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_GROSS_VENDOR_PAY,
    isFilter: false
  }
}

const FINANCIAL_DAYS_TO_INVOICE = ()=>{
  const columns = () =>[
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Customer"]}</div>;
      },
    },
    {
      key: "revenue",
      name: "Average Days To Invoice",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Days To Invoice"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_DAYS_TO_INVOICE,
    isFilter: false
  };
}

const FINANCIAL_AVERAGE_MARGIN = ()=>{
  const columns = (openLoadModal) =>[
    {
      key: "load",
      name: "Load Number",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.["Load Number"])}>{row?.["Load Number"]}</div>;
      },
    },
    {
      key: "margin",
      name: "Margin ($)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Margin ($)"] || 0)}</div>;
      },
    },
    {
      key: "margin",
      name: "Margin (%)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Margin (%)"] || 0)} %</div>;
      },
    },
    {
      key: "load_type",
      name: "Load Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Load Type"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_AVERAGE_MARGIN,
    isFilter: false
  };
}

const LOADS_BY_TYPE = () => {
  const columns = (openLoadModal) => [
    {
      key: "name",
      name: "Load Type",
      formatter: ({ row }) => {
        return <span className={`font-12 text-white font-weight-normal badge ml-2 ${typeOfLoadBadgeColor[row?.name]}`}>{row?.name}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.LOADS_BY_TYPE,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false,
  };
};

const TOP_RETURN_LOCATIONS = () => {
  const columns = (openLoadModal) => [
    {
      key: "company_name",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.TOP_RETURN_LOCATIONS,
    // description: "Total revenue broken down by Customer",
    isFilter: false,
  };
};

const BROKER_TENDERS_ACCEPTED = ()=>{
  const columns = (openLoadModal) =>[
    {
      key:"tender_status",
      name:"Tender Status",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Status"]}</span>;
      },
    },
    {
      key: "tender_id",
      name: "Tender ID",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender ID"]}</span>;
      },
    },
    {
      key: "load_number",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        )
      },
    },
    {
      key: "tender_sent_date",
      name: "Tender Sent Date",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Sent Date"]}</span>;
      },
    },
    {
      key: "tender_accept_date",
      name: "Tender Accept Date",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Accept Date"]}</span>;
      },
    },
    {
      key: "time_to_accept",
      name: "Time to Accept",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Time To Accept"]}</span>;
      },
    }
  ];
  return {
    columns,
    title: REPORT_NAMES?.BROKER_TENDERS_ACCEPTED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const BROKER_TENDERS_REJECTED = ()=>{
  const columns = () =>[
    {
      key: "tender_status",
      name: "Tender Status",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Status"]}</span>;
      },
    },
    {
      key: "tender_id",
      name: "Tender ID",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender ID"]}</span>;
      },
    },
    {
      key: "tender_sent_date",
      name: "Tender Sent Date",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Sent Date"]}</span>;
      },
    },
    {
      key: "tender_reject_date",
      name: "Tender Reject Date",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Tender Reject Date"]}</span>;
      },
    },
    {
      key: "time_to_reject",
      name: "Time to Reject",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Time To Reject"]}</span>;
      },
    }
  ];
  return {
    columns,
    title: REPORT_NAMES?.BROKER_TENDERS_REJECTED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const BROKER_LOAD_COMPLETED_NOT_INVOICED = ()=>{
  const columns = (openLoadModal) =>[
    {
      key: "vendor",
      name: "Vendor",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Vendor"]}</div>;
      },
    },
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        );
      },
    },
    {
      key: "complete_date",
      name: "Complete Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Complete Date"]}</div>;
      },
    },
    {
      key: "days_since_completion",
      name: "Days since Completion",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Days since Completion"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.BROKER_LOAD_COMPLETED_NOT_INVOICED,
    isFilter: false
  }
}

const BROKER_INVOICES_RECEIVED_BY_CARRIER = ()=>{
  const columns = ()=> [
    {
      key: "vendor",
      name: "Vendor",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "#_of_invoices_received",
      name: "# of Invoices Received",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Invoices Received"] || 0)}</div>;
      },
    },
    {
      key: "average_days_to_invoice",
      name: "Average Days to Invoice",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Days to Invoice"] || 0)}</div>;
      },
    },
    {
      key: "average_invoice_amount",
      name: "Average Invoice Amount",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Invoice Amount"] || 0)}</div>;
      },
    }
  ];
  return {
    columns,
    title: REPORT_NAMES?.BROKER_INVOICES_RECEIVED_BY_CARRIER,
    // description: "All the created loads for that date range broken down by Customer",
    isFilter: false
  }
}

const BROKER_TENDERS_BY_CARRIER = ()=>{
  const columns = () =>[
    {
      key: "carrier",
      name: "Vendor",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Vendor"]}</div>;
      },
    },
    {
      key: "tenders_sent",
      name: "Tenders Sent",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Tenders Sent"]}</div>;
      },
    },
    {
      key: "accept_count",
      name: "Accept Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Accept Count"]}</div>;
      },
    },
    {
      key: "accept_rate",
      name: "Accept Rate",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Accept Rate"]}</div>;
      },
    },
    {
      key: "reject_count",
      name: "Reject Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Reject Count"]}</div>;
      },
    },
    {
      key: "reject_rate",
      name: "Reject Rate",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Reject Count"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.BROKER_TENDERS_BY_CARRIER,
    isFilter: false
  }
}

const FINANCIAL_REVENUE_BY_LOAD_TYPE = ()=>{
  const columns = () =>[
    {
      key: "name",
      name: "Load Type",
      formatter: ({ row }) => {
        return <div className={`font-12 text-white font-weight-normal badge ml-2 ${typeOfLoadBadgeColor[row?.company_name]}`}>{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "percentage_of_total_revenue",
      name: "Percent of Total Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage_of_total_revenue || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_LOAD_TYPE,
    // description: "Total revenue broken down by Load Type",
    isFilter: false
  }
};

const FINANCIAL_REVENUE_BY_DELIVERY_LOCATION = ()=>{
  const columns = () => [
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "percentage_of_total_revenue",
      name: "Percent of Total Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Percent of Total Revenue"] || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
    // description: "Total revenue broken down by Delivery Location",
    isFilter: false
  }
};

const FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS = ()=>{
  const columns = () =>[
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "percentage_of_total_profit",
      name: "% of Total Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["% of Total Profit"] || 0)} %</div>;
      },
    },
    {
      key: "average_profit_per_load",
      name: "Average Profit/Load",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Profit/Load"] || 0)?.toCurrency()}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
    // description: "Delivery locations broken down by which are the most profitable",
    isFilter: false
  }
};

const FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE = () =>{
  const columns = () => [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Profit ($ amount)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "percentage",
      name: "Profit (% Of Total Profit)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{`${(row?.percentage || 0)} %`}</div>;
      },
    },
    {
      key: "average_profit_per_load",
      name: "Average Profit/Load",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Profit/Load"] || 0)?.toCurrency()}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
    // description: "Customers profitability broken down as a perctange. So seeing what percentage that customer is of your total profit",
    isFilter: false
  }
};

const FINANCIAL_PAST_DUE_INVOCES = () =>{
  const columns = () =>[
    {
      key: "name",
      name: "Invoice #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Date Invoiced",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{`${(row?.count || 0)}`}</div>;
      },
    },
    {
      key: "due_date",
      name: "Due Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Due Date"]}</div>;
      },
    },
    {
      key: "status",
      name: "Payment Status",
      formatter: ({ row }) => {
        const colorOfText = {
          "Paid": "bg-success-500",
          "Partially Paid": "bg-danger-500",
          "Outstanding": "bg-danger-500"
        }
        return <div className={`font-12 p-2 font-weight-normal badge ${colorOfText?.[row?.status]}`}>{`${(row?.status || "")}`}</div>;
      },
    }
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_PAST_DUE_INVOCES,
    // description: "Percentage of invoices past due",
    isFilter: false
  }
};

const FINANCIAL_TOP_EARNING_DRIVERS = () =>{
  const columns = () => [
    {
      key: "company_name",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Net Pay",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "#_of_loads",
      name: "# of Loads",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["# of Loads"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_TOP_EARNING_DRIVERS,
    // description: "Drivers with the highest net pay (driver pay - deductions)",
    isFilter: false
  }
};

const FINANCIAL_PERCENTAGE_OF_MARGIN = () => {
  const columns = () =>[
    {
      key: "Margin Badge",
      name: "Margin Badge",
      formatter: ({ row }) => {
        const colorOfBadges = {
          "Low": "bg-danger-500",
          "Middle": "bg-warning-300",
          "High": "bg-success-500"
        }
        return <div className={`font-12 p-2 text-dark font-weight-normal badge ${colorOfBadges?.[row?.company_name]} ml-2`}>{row?.company_name}</div>;
      },
    },
    {
      key: "% of Loads",
      name: "% Of Loads",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_PERCENTAGE_OF_MARGIN,
    // description: "Drivers with the highest net pay (driver pay - deductions)",
    isFilter: false
  }
};

const FINANCIAL_REVENUE_BY_CHARGE_NAME = () =>{
  const columns = () =>[
    {
      key: "chargeName",
      name: "Charge Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.percentage}</div>;
      },
    },
    {
      key: "count",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0).toCurrency()}</div>;
      },
    },
    {
      key: "avg_revenue_per_charge",
      name: "Average Revenue/Charge",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Revenue/Charge"] || 0)?.toCurrency()}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_REVENUE_BY_CHARGE_NAME,
    // description: "Total revenue broken down by charge name",
    isFilter: false
  }
};

const FINANCIAL_TOTAL_PROFIT = () =>{
  const columns = () =>[
    {
      key: "chargeName",
      name: "Load Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Customer"]}</div>;
      },
    },
    {
      key: "profit",
      name: "Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Profit"]}</div>;
      },
    },
    {
      key: "percentage",
      name: "% of Total Profit",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["% of Total Profit"] || 0} %</div>;
      },
    },
    {
      key: "average_revenue_per_charge",
      name: "Average Profit/Load",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Average Profit/Load"] || 0)?.toCurrency()}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_TOTAL_PROFIT,
    isFilter: false
  }
}

const FINANCIAL_EXPENSES = () => {
  const columns = () => [
    {
      key: "company_name",
      name: "Expense",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Amount",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count || 0}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_EXPENSES,
    isFilter: false
  }
}

const FINANCIAL_TOTAL_REVENUE = () =>{
  const columns = () =>[
    {
      key: "customer",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.Customer}</div>;
      }
    },
    {
      key: "revenue",
      name: "Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Revenue"]}</div>;
      }
    },
    {
      key: "%_of_total_revenue",
      name: "% of Total Revenue",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["% of Total Revenue"]}</div>;
      }
    },
  ]

  return {
    columns,
    title: REPORT_NAMES?.FINANCIAL_TOTAL_REVENUE,
    isFilter: false
  }
}

const TOP_DELIVERY_LOCATIONS = () => {
  const columns = (openLoadModal) => [
    {
      key: "company_name",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.TOP_DELIVERY_LOCATIONS,
    isFilter: false,
  };
};

const TOP_PICK_UP_LOCATIONS = () => {
  const columns = (openLoadModal) => [
    {
      key: "company_name",
      name: "Pick Up Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TOP_PICK_UP_LOCATIONS,
    isFilter: false,
  };
};

const LOADS_BY_CUSTOMER = () => {
  const columns = (openLoadModal) => [
    {
      key: "company_name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count || 0}</div>;
      },
    },
  ];

  return {
    columns,
    title: REPORT_NAMES?.LOADS_BY_CUSTOMER,
    isFilter: false,
  };
};

const typeOfLoadBadgeColor = {
  Import: 'bg-purple-200',
  Export: 'bg-slategray-600',
  Road: 'bg-green-150',
  Bill: 'bg-steelblue-400',
}

const OPERATION_LOADS_COMPLETED = ()=>{
  const columns = (openLoadModal) => [
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load Number"])}
          >
            {row?.["Load Number"]}
          </span>
        );
      },
    },
    {
      key: "completed_date",
      name: "Completed Date",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Completed Date"]}</div>;
      },
    },
    {
      key: "pickup_location",
      name: "Pickup Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Pickup Location"]}</div>;
      },
    },
    {
      key: "delivery_location",
      name: "Delivery Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Delivery Location"]}</div>;
      },
    },
    {
      key: "return_location",
      name: "Return Location",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Return Location"]}</div>;
      },
    },
    {
      key: "load_status",
      name: "Load Status",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Load Status"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_LOADS_COMPLETED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const OPERATION_TOTAL_MOVES_COMPLETED = () =>{
  const columns = (openLoadModal) =>[
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Count"] || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_TOTAL_MOVES_COMPLETED,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const OPERATION_TIME_TO_RETURN = () =>{
  const columns = (openLoadModal) =>[
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        );
      },
    },
    {
      key: "time_to_return",
      name: "Time to Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Time to Return"])}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_TIME_TO_RETURN,
    //description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const OPERATION_TIME_TO_TERMINATE = () => {
  const columns = (openLoadModal) => [
    {
      key: "name",
      name: "Event",
      formatter: ({ row }) => {
        return <span className={`font-12 p-2 text-dark font-weight-normal`}>{row?.["Event Name"]}</span>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Count"] || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_TIME_TO_TERMINATE,
    // description: "All the created loads for that date range broken down by type",
    isFilter: false
  }
}

const OPERATION_LOADS_COMPLETED_BY_WEEK = () => {
  const columns = (openLoadModal) => [
    {
      key: "day",
      name: "Day Of Week",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_loads",
      name: "Loads Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_LOADS_COMPLETED_BY_WEEK,
    isFilter: false
  };
};

const OPERATION_MOVES_COMPLETED_BY_DRIVER = () => {
  const columns = (openLoadModal) => [
    {
      key: "driver_name",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_loads",
      name: "Moves Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_MOVES_COMPLETED_BY_DRIVER,
    isFilter: false
  }
};

const OPERATION_DISPATCHES_BY_EMPLOYEE = ()=>{
  const columns = (openLoadModal) =>[
    {
      key: "employee",
      name: "Employee",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Number Of Dispatches",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_DISPATCHES_BY_EMPLOYEE,
    isFilter: false
  }
};

const OPERATION_EVENTS_BY_DRIVER = () =>{
  const columns = (openLoadModal) => [
    {
      key: "driver_name",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "Pulled",
      name: "Pulled",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Pulled || 0)}</div>;
      },
    },
    {
      key: "Delivered",
      name: "Delivered",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Delivered || 0)}</div>;
      },
    },
    {
      key: "Dropped",
      name: "Dropped",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Dropped || 0)}</div>;
      },
    },
    {
      key: "Returned",
      name: "Returned",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.Returned || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_EVENTS_BY_DRIVER,
    isFilter: false
  }
};

const OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER = ()=>{
  const columns = (openLoadModal) =>[
    {
      key: "employee",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "load#",
      name: "Load #",
      formatter: ({ row }) => {
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        );
      },
    },
    {
      key: "no_of_moves",
      name: "Count of moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.["Count of Moves"] || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,
    isFilter: false
  }
};

const CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD = () =>{
  const columns = (openLoadModal) =>[
    {
      key: "employee",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Employee Name"]}</div>;
      },
    },
    {
      key: "load#",
      name: "Load #",
      formatter:({row})=>{
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        )
      }
    },
    {
      key: "customer",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Customer"]}</div>;
      },
    },
    {
      key: "origin",
      name: "Origin",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Origin"]}</div>;
      },
    },
    {
      key: "destination",
      name: "Destination",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Destination"]}</div>;
      },
    }
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
    isFilter: false
  }
};

const CUSTOMER_SERVICE_LOADS_CREATED = () =>{
  const columns = (openLoadModal) => [
    {
      key: "employee",
      name: "Employee Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Employee Name"]}</div>;
      },
    },
    {
      key:"load#",
      name:"Load #",
      formatter:({row})=>{
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        )
      }
    },
    {
      key:"customer",
      name:"Customer",
      formatter:({row})=>{
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Customer"]}</div>
      }
    },
    {
      key:"origin",
      name:"Origin",
      formatter:({row})=>{
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Origin"]}</div>
      }
    },
    {
      key:"destination",
      name:"Destination",
      formatter:({row})=>{
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Destination"]}</div>
      }
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_SERVICE_LOADS_CREATED,
    isFilter: false
  }
};

const CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR = () =>{
  const columns = () =>[
    {
      key: "employee",
      name: "CSR Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "pending_load",
      name: "Pending",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.pending_load || 0}</div>;
      },
    },
    {
      key: "dispatched_load",
      name: "Dispatched",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.dispatched_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_chassis_load",
      name: "Enroute To Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_chassis_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_chassis_load",
      name: "Arrived To Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_chassis_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_pick_container_load",
      name: "Enroute To Pick Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_pick_container_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_pick_container_load",
      name: "Arrived At Pick Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_pick_container_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_deliver_load",
      name: "Enroute To Deliver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_deliver_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_deliver_load",
      name: "Arrived At Deliver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_deliver_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_drop_container_load",
      name: "Enroute To Drop Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_drop_container_load || 0}</div>;
      },
    },
    {
      key: "dropped_load",
      name: "Dropped",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.dropped_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_hook_container_load",
      name: "Enroute To Hook Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_hook_container_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_hook_container_load",
      name: "Arrived To Hook Container",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_hook_container_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_return_load",
      name: "Enroute To Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_return_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_return_load",
      name: "Arrived At Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_return_load || 0}</div>;
      },
    },
    {
      key: "arrived_at_stop_off_load",
      name: "Arrived At Stop Off",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_at_stop_off_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_stop_of_load",
      name: "Enroute To Stop Off",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_stop_of_load || 0}</div>;
      },
    },
    {
      key: "enroute_to_return_chassis_load",
      name: "Enroute To Return Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.enroute_to_return_chassis_load || 0}</div>;
      },
    },
    {
      key: "arrived_to_return_chassis_load",
      name: "Arrived To Return Chassis",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.arrived_to_return_chassis_load || 0}</div>;
      },
    },
    {
      key: "available_load",
      name: "Available",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.available_load || 0}</div>;
      },
    },
    {
      key: "departed_load",
      name: "Departed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.departed_load || 0}</div>;
      },
    },
    {
      key: "completed_load",
      name: "Completed",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.completed_load || 0}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
    isFilter: false
  }
};


const SYSTEM_PAY_CREATION_PERCENTAGE = () => {
  const columns = () => [
    {
      key: "name",
      name: "Pay Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_PAY_CREATION_PERCENTAGE,
    isFilter: false
  }
}

const SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE = () => {
  const columns = () => [
    {
      key: "name",
      name: "Document Upload Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
    isFilter: false
  }
}

const SYSTEM_LOAD_CREATED_BY = () => {
  const columns = () =>[
    {
      key: "name",
      name: "Load Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_LOAD_CREATED_BY,
    isFilter: false
  }
}

const SYSTEM_LOAD_STATUS_PERCENTAGE = () => {
  const columns = () => [
    {
      key: "name",
      name: "Load Status Update Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_LOAD_STATUS_PERCENTAGE,
    isFilter: false
  }
}

const SYSTEM_CRON_REPORTS = ()=>{
  const columns= () => [
    {
      key: "name",
      name: "Report Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Report Name"]}</div>;
      },
    },
    {
      key: "report_object",
      name: "Report Object",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Report Object"]}</div>;
      },
    },
    {
      key: "#_of_times_sent",
      name: "# of Times Sent",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["# Of Times Sent"]}</div>;
      },
    },
    {
      key: "last_sent",
      name: "Last Sent(timestamp)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Last Sent(Timestamp)"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_CRON_REPORTS,
    isFilter: false
  }
}

const SYSTEM_CHARGE_CREATION_PERCENTAGE = () =>{
  const columns = (openLoadModal) => [
    {
      key: "name",
      name: "Charge Creation Type",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "percentage",
      name: "% Of Total",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)} %</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.SYSTEM_CHARGE_CREATION_PERCENTAGE,
    isFilter: false
  }
}

const EMPLOYEE_SETTLMENT_APPROVED = ()=>{
  const columns= ()=> [
    {
      key: "name",
      name: "Employee Name (Approver)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Employee Name (Approver)"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
    {
      key: "settlement#",
      name: "Settlement #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Settlement #"]}</div>;
      },
    },
    {
      key: "amount",
      name: "Amount",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Amount"]}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EMPLOYEE_SETTLMENT_APPROVED,
    isFilter: false
  } 
}

const EMPLOYEE_DRIVER_PAY_APPROVED = ()=>{
  const columns = (openLoadModal)=> [
    {
      key: "name",
      name: "Employee Name (Approver)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Employee Name (Approver)"]}</div>;
      },
    },
    {
      key: "driver",
      name: "Driver",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver"]}</div>;
      },
    },
    {
      key: "approved_line_item",
      name: "Approved Line Item",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Approved Line Item"]}</div>;
      }
    },
    {
      key: "amount",
      name: "Amount",
      formatter : ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Amount"]}</div>;
      }
    },
    {
      key:"load#",
      name:"Load #",
      formatter:({row})=>{
        return (
          <span
            className={`font-12 p-2 text-primary font-weight-normal pointer`}
            onClick={() => openLoadModal(row?.["Load #"])}
          >
            {row?.["Load #"]}
          </span>
        )
      }
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EMPLOYEE_DRIVER_PAY_APPROVED,
    isFilter: false
  }
}

const CUSTOMER_CUSTOMER_PORTAL_USAGE = ()=>{
  const columns = ()=> [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "# Of Active Sessions",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "lastSession",
      name: "Last Session",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.lastSession}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_CUSTOMER_PORTAL_USAGE,
    isFilter: false
  }
}

const CUSTOMER_TIMES_MARKED_READY_TO_RETURN = ()=>{
  const columns = ()=> [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "count",
      name: "Load #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
    {
      key: "lastDateMarkedReadytoReturn",
      name: "Date Marked Ready To Return",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.lastDateMarkedReadytoReturn}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
    isFilter: false
  }
}

const CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE = ()=>{
  const columns = ()=> [
    {
      key: "name",
      name: "Customer",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.name}</div>;
      },
    },
    {
      key: "count",
      name: "Active Within Timeframe",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,
    isFilter: false
  }
}
const TRACK_PER_DIEM_PERCENTAGE = () => {
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "loadReturnedOnTime",
      name: "Per Diem",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.loadReturnedOnTime}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_PER_DIEM_PERCENTAGE,
    isFilter: false,
  };
}

const TRACK_LAST_FREE_DAY_PERCENTAGE = () => {
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "loadReturnedOnTime",
      name: "LFD on Time",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.loadReturnedOnTime}</div>;
      },
    },
    {
      key: "loadReturnedOnTimepercentage",
      name: "LFD on Time %",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.loadReturnedOnTime}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_LAST_FREE_DAY_PERCENTAGE,
    isFilter: false,
  };
}

const TRACK_NUMBER_OF_TIRS = () =>{
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "tirUploaded",
      name: "TIR Uploaded",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.tirUploaded}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_NUMBER_OF_TIRS,
    isFilter: false,
  };
}

const TRACK_PER_DIEM_TOTAL = () => {
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "perDiemDaysCount",
      name: "Per Diem Count",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.perDiemDaysCount}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_PER_DIEM_TOTAL,
    isFilter: false,
  };
}

const TRACK_DEMURRAGE_TOTAL = () => {
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "Demurrage",
      name: "Demurrage",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.demurrage}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_DEMURRAGE_TOTAL,
    isFilter: false,
  };
}

const TRACK_DWELL_TIME = () => {
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "dwellDay",
      name: "Dwell Time (days)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{Number(row?.dwellDay)?.toFixed(2)}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_DWELL_TIME,
    isFilter: false,
  };
}

const TRACK_NO_OF_APPTS = () =>{
  const columns = (openLoadModal) => [
    {
      key: "referenceNumber",
      name: "Load#",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-primary font-weight-normal pointer" onClick={()=>openLoadModal(row?.referenceNumber)}>{row?.referenceNumber}</div>;
      },
    },
    {
      key: "confirmedApptCount",
      name: "# Of Appts (Confirmed)",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.confirmedApptCount}</div>;
      },
    },
  ];
  
  return {
    columns,
    title: REPORT_NAMES?.TRACK_NO_OF_APPTS,
    isFilter: false,
  };
}

const EQUIPMENT_CHASSIS_USAGE = () => {
  const columns = (openLoadModal) => [
    {
      key: "chassisNo",
      name: "Chassis #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count Of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_CHASSIS_USAGE,
    isFilter: false
  }
};

const EQUIPMENT_TRUCK_USAGE = () => {
  const columns = (openLoadModal) => [
    {
      key: "truckNo",
      name: "Truck #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.truckNo}</div>;
      },
    },
    {
      key: "driverName",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.driverName}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count Of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_TRUCK_USAGE,
    isFilter: false
  }
};

const EQUIPMENT_TRAILER_USAGE = () => {
  const columns = (openLoadModal) => [
    {
      key: "trailerNo",
      name: "Trailer #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.company_name}</div>;
      },
    },
    {
      key: "no_of_moves",
      name: "Count Of Completed Moves",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.count || 0)}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_TRAILER_USAGE,
    isFilter: false
  }
};

const EQUIPMENT_CHASSIS_LAST_USED = () =>{
  const columns = (openLoadModal) => [
    {
      key: "chassisNo",
      name: "Chassis #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_CHASSIS_LAST_USED,
    isFilter: false
  };
};

const EQUIPMENT_TRUCK_LAST_USED = () => {
  const columns = (openLoadModal) => [
    {
      key: "driverName",
      name: "Driver Name",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.["Driver Name"]}</div>;
      },
    },
    {
      key: "truck",
      name: "Truck #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_TRUCK_LAST_USED,
    isFilter: false
  }
};

const EQUIPMENT_TRAILER_LAST_USED = () => {
  const columns = () => [
    {
      key: "trailer",
      name: "Trailer #",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.chargeName}</div>;
      },
    },
    {
      key: "daysSinceLastUse",
      name: "Days Since Last Use",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{(row?.percentage || 0)}</div>;
      },
    },
    {
      key: "dateLastUsed",
      name: "Date Last Used",
      formatter: ({ row }) => {
        return <div className="font-12 p-2 text-dark font-weight-normal">{row?.count}</div>;
      },
    },
  ];
  return {
    columns,
    title: REPORT_NAMES?.EQUIPMENT_TRAILER_LAST_USED,
    isFilter: false
  }
};

export const REPORT_CONFIGS = {
  //Admin
  LOADS_BY_TYPE,
  LOADS_BY_CUSTOMER,
  TOP_RETURN_LOCATIONS,
  TOP_DELIVERY_LOCATIONS,
  TOP_PICK_UP_LOCATIONS,
  COMPLETED_LOADS,
  CREATED_LOADS,
  PICKED_UP,
  DELIVERED,
  RETURNED,

  //Broker
  BROKER_LOAD_COMPLETED_NOT_INVOICED,
  BROKER_TENDERS_ACCEPTED,
  BROKER_TENDERS_REJECTED,
  BROKER_INVOICES_RECEIVED_BY_CARRIER,
  BROKER_TENDERS_BY_CARRIER,

  //Financials
  FINANCIAL_NUMBER_OF_INVOICES,
  FINANCIAL_GROSS_DRIVER_PAY,
  FINANCIAL_GROSS_VENDOR_PAY,
  FINANCIAL_DAYS_TO_INVOICE,
  FINANCIAL_AVERAGE_MARGIN,
  FINANCIAL_REVENUE_BY_LOAD_TYPE,
  FINANCIAL_REVENUE_BY_DELIVERY_LOCATION,
  FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS,
  FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE,
  FINANCIAL_PAST_DUE_INVOCES,
  FINANCIAL_TOP_EARNING_DRIVERS,
  FINANCIAL_PERCENTAGE_OF_MARGIN,
  FINANCIAL_REVENUE_BY_CHARGE_NAME,
  FINANCIAL_TOTAL_PROFIT,
  FINANCIAL_EXPENSES,
  FINANCIAL_TOTAL_REVENUE,
  //Operations
  OPERATION_LOADS_COMPLETED,
  OPERATION_TOTAL_MOVES_COMPLETED,
  OPERATION_TIME_TO_RETURN,
  OPERATION_TIME_TO_TERMINATE,
  OPERATION_LOADS_COMPLETED_BY_WEEK,
  OPERATION_MOVES_COMPLETED_BY_DRIVER,
  OPERATION_DISPATCHES_BY_EMPLOYEE,
  OPERATION_EVENTS_BY_DRIVER,
  OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER,

  //Customer Service
  CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD,
  CUSTOMER_SERVICE_LOADS_CREATED,
  CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR,
  
  //System
  SYSTEM_DOCUMENT_UPLOADS_PERCENTAGE,
  SYSTEM_PAY_CREATION_PERCENTAGE,
  SYSTEM_LOAD_CREATED_BY,
  SYSTEM_CHARGE_CREATION_PERCENTAGE,
  SYSTEM_LOAD_STATUS_PERCENTAGE,
  SYSTEM_CRON_REPORTS,

  // Employee
  EMPLOYEE_SETTLMENT_APPROVED,
  EMPLOYEE_DRIVER_PAY_APPROVED,

  // Customer
  CUSTOMER_CUSTOMER_PORTAL_USAGE,
  CUSTOMER_TIMES_MARKED_READY_TO_RETURN,
  CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE,

  // Equipment
  EQUIPMENT_CHASSIS_USAGE,
  EQUIPMENT_TRUCK_USAGE,
  EQUIPMENT_TRAILER_USAGE,
  EQUIPMENT_CHASSIS_LAST_USED,
  EQUIPMENT_TRUCK_LAST_USED,
  EQUIPMENT_TRAILER_LAST_USED,
  
  //Track
  TRACK_PER_DIEM_PERCENTAGE,
  TRACK_LAST_FREE_DAY_PERCENTAGE,
  TRACK_NUMBER_OF_TIRS,
  TRACK_PER_DIEM_TOTAL,
  TRACK_DEMURRAGE_TOTAL,
  TRACK_DWELL_TIME,
  TRACK_NO_OF_APPTS,
}