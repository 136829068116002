import moment from "moment";
import { SelectColumn } from "react-data-grid";
import _ from "lodash"
import TextEditor from "./Components/CellComponents/TextEditor";
import AssignDriver from "./Components/FormatedCell/AssignDriver";
import Chassis from "./Components/FormatedCell/Chassis";
import Customer from "./Components/FormatedCell/Customer";
import ChangeStatus from "./Components/FormatedCell/ChangeStatus";
import Container from "./Components/FormatedCell/Container";
import ContainerOwner from "./Components/FormatedCell/ContainerOwner";
import ContainerSize from "./Components/FormatedCell/ContainerSize";
import ContainerType from "./Components/FormatedCell/ContainerType";
import Cut from "./Components/FormatedCell/Cut";
import PerDiemLFD from "./Components/FormatedCell/PerDiemLFD";
import Csr from "./Components/FormatedCell/Csr";
import Eta from "./Components/FormatedCell/Eta";
import Lfd from "./Components/FormatedCell/Lfd";
import PickupTimes from './Components/FormatedCell/PickupTimes';
import DeliveryTimes from './Components/FormatedCell/DeliveryTimes';
import NextAddress from "./Components/FormatedCell/NextAddress";
import LoadRefNo from "./Components/FormatedCell/LoadRefNo";
import LoadStatus from "./Components/FormatedCell/LoadStatus";
import Consignee from "./Components/FormatedCell/Consignee";
import Shipper from "./Components/FormatedCell/Shipper";
import EmptyOrigin from "./Components/FormatedCell/EmptyOrigin";
import GrayChassis from "./Components/FormatedCell/GrayChassis";
import Reset from "./Components/FormatedCell/Reset";
import EditableField from "./Components/FormatedCell/EditableField";
import { isTerminal } from "../utility";
import ReturnTimes from "./Components/FormatedCell/ReturnTimes";
import { getStorage,DateTimeFormatUtils, isTerminalContainerStatusEnabled, isManageFleetOrOutSource, getDriverAndCarrierName, isCustomsNote, isCustomsTypeEnabled, isEmbeddedEmailEnabled, checkUserPermisison, updateTerminology } from "../../../../services";
import RecommendedReturnTerminals from "../../Load/RecommendedReturnTerminals";
import Event from "./Components/FormatedCell/Event";
import DeliveryToTimes from "./Components/FormatedCell/DeliveryToTimes";
import PickupToTimes from "./Components/FormatedCell/PickupToTimes";
import ReturnToTimes from "./Components/FormatedCell/ReturnToTimes";
import DestinationETA from "./Components/FormatedCell/DestinationETA";
import { getPreSetOrderStatus } from "../../Load/DriverOrderUtility";
import ReadyToReturn from "./Components/FormatedCell/ReadyToReturn";
import ReferenceNo from "./Components/FormatedCell/ReferenceNo";
import { isTareWeightEnabled } from "../../../../Components/Common/functions";
import Note from "./Components/FormatedCell/Note";
import MasterBillOfLading from "./Components/FormatedCell/MasterBillOfLading";
import TenderStatus from "./Components/FormatedCell/TenderStatus";
import { getTimeZone } from "../constants";
import { IconMailOutline } from "Components/Common/Icons";
import { LOAD_LABELS } from "labels";
import ProblemContainerWarning from "./Components/FormatedCell/ProblemContainerWarning";
SelectColumn.width = 58;
SelectColumn.minWidth = 58;

const getCustomColor = (row) => {
	const currentUserDetails = JSON.parse(getStorage("loggedInUser"));
	if (currentUserDetails.rowColorPreferences && currentUserDetails.rowColorPreferences.length) {
		const findStatusColor = currentUserDetails.rowColorPreferences.find((e) => e.status === row.status);
		return findStatusColor && findStatusColor.color;
	}
	return "#ffffff";
}
let user = JSON.parse(getStorage('loggedInUser'));
const isEditPermission = user?.role == 'carrier' || (user?.role == 'fleetmanager' && user?.permissions?.includes('dispatcher_page_edit'))
const weightUnit = JSON.parse(getStorage('userBasicSettings'))?.weightMeasureUnit

export const getColumns = () => {
	return [
	SelectColumn,
	{
		key: "reference_number",
		name: "Load #",
		sortable: true,
		minWidth: 175,
		cellClass: "cell load_number_cell frozen_cell",
		formatter: LoadRefNo,
		colOptions:true
	},
	{
		key: "problemContainer",
        name: "Warning",
        sortable: false,
        minWidth: 70,
        cellClass: "cell",
		colOptions: true,
        formatter: ProblemContainerWarning,
		hidden: false,
	},
	...((isEmbeddedEmailEnabled() && checkUserPermisison(['embedded_email_rules'])) ? [{
		key: "email", // only for load type road
		name: "Emails",
		formatter: ({ row }) => {
			return (
				<>
					<div className="cell-content">
						<div className="d-flex justify-content-center">
							{row.emailCount ? <div className="wh-20px bg-danger-500 text-white font-12 rounded-circle d-flex  align-items-center justify-content-center dispatch-email-count">
								{row?.emailCount ?? 0}
							</div> : 
							<IconMailOutline className="text-gray-100 icon-mail" />}
						</div>
					</div>
				</>
			);
		},
		hidden: false,
		colOptions:true
	}]:[]),
	{
		key: "status",
		name: LOAD_LABELS.LOAD_STATUS,
		sortable: true,
		minWidth: 120,
		resizable: true,
		cellClass: "cell load_tooltip",
		formatter: LoadStatus,
		hidden: false,
		colOptions:true
	},
	{
		key: "returnToTime",
		name: LOAD_LABELS.RETURN_APPT_TO,
		sortable: true,
		frozen: false,
		minWidth: 140,
		cellClass: "cell",
		formatter: ReturnToTimes,
		hidden: true,
		colOptions:true
	},
	{
		key: "pickupToTime",
		name: LOAD_LABELS.PICK_UP_APPT_TO,
		sortable: true,
		frozen: false,
		minWidth: 140,
		cellClass: (row) => row.pickupTimes?.length === 0 ? "disabled_input_bg cell" : "cell",
		formatter: PickupToTimes,
		hidden: true,
		colOptions:true
	},
	{
		key: "deliveryToTime",
		name: LOAD_LABELS.DELIVERY_APPT_TO,
		sortable: true,
		frozen: false,
		minWidth: 140,
		cellClass: (row) => row.deliveryTimes?.length === 0 ? "disabled_input_bg cell" : "cell",
		formatter: DeliveryToTimes,
		hidden: true,
		colOptions:true
	},
	{
		key: "driver",
		name: getDriverAndCarrierName(),
		sortable: true,
		formatter: AssignDriver,
		hidden: false,
		frozen: false,
		minWidth: 150,
		colOptions:true,
		cellClass: "cell",
	},
	{
		key: "event1",
		name: "Event 1",
		sortable: true,
		cellClass: "cell",
		formatter: Event,
		hidden: false,
		frozen: false,
		minWidth: 170,
		colOptions:true
	},
	{
		key: "containerNo",
		name: LOAD_LABELS.CONTAINER_NUMBER,
		sortable: true,
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: (row) =>  row.type_of_load !== "ROAD",
		cellClass: "cell",
		minWidth: 110,
		formatter: Container,
		hidden: false,
		colOptions:true
	},
	{
		key: "secondaryReferenceNo",
		name: LOAD_LABELS.REFERENCE_NUMBER,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 110,
		formatter: ReferenceNo,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "type_of_load",
		name: LOAD_LABELS.LOAD_TYPE,
		sortable: true,
		frozen: false,
		minWidth: 110,
		cellClass:(row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: ({ row }) => {
			return (
				<div className="cell-content">
					{_.startCase(_.toLower(row.type_of_load))}
				</div>
			);
		},
		hidden: false,
		colOptions:true
	},
	{
		key: "nextDriverOrderId",
		name: LOAD_LABELS.NEXT_ADDRESS,
		sortable: true,
		frozen: false,
		minWidth: 130,
		cellClass:(row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: NextAddress,
		hidden: false,
		colOptions:true
	},
	{
		key: "readyToReturnDate",
		name: LOAD_LABELS.READY_TO_RETURN_DATE,
		sortable: true,
		frozen: false,
		cellClass: (row) => (row.type_of_load === "BILL_ONLY" || row.type_of_load === "ROAD") && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: ReadyToReturn,
		minWidth: 130,
		colOptions:true,
		hidden: true,
	},
	{
		key: "containerSize",
		name: LOAD_LABELS.SIZE,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: ContainerSize,
		hidden: false,
		colOptions:true
	},
	{
		key: "pickupTimes",
		name: LOAD_LABELS.PICK_UP_APPT_FROM,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.pickupTimes?.length === 0 ? "disabled_input_bg cell" : "cell",
		width: 100,
		minWidth: 110,
		editorOptions: {
			editOnClick: true
		},
		formatter: PickupTimes,
		hidden: false,
		colOptions:true
	},
	{
		key: "lfd",
		name: LOAD_LABELS.LFD_ERD,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: Lfd,
		hidden: false,
		colOptions:true
	},
	{
		key: "eta",
		name: LOAD_LABELS.ETA,
		sortable: true,
		frozen: false,
		cellClass: (row) => (row.type_of_load === "ROAD" || row.type_of_load === "EXPORT") && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: Eta,
		hidden: false,
		colOptions:true
	},
	{
		key: "apt",
		name: LOAD_LABELS.DELIVERY_APPT_FROM,
		sortable: true,
		frozen: false,
		width: 100,
		cellClass: (row) => row.deliveryTimes?.length === 0 ? "disabled_input_bg cell" : "cell",
		formatter: DeliveryTimes,
		hidden: false,
		minWidth: 115,
		colOptions:true
	},
	{
		key: "cut",
		name: LOAD_LABELS.CUT_OFF,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load !== "EXPORT" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: Cut,
		hidden: false,
		colOptions:true
	},
	{
		key: "freeReturnDate",
		name: LOAD_LABELS.RETURN_DATE,
		sortable: true,
		frozen: false,
		minWidth: 120,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: PerDiemLFD,
		hidden: false,
		colOptions:true
	},
	{
		key: "containerType",
		name: LOAD_LABELS.TYPE,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: ContainerType,
		hidden: false,
		colOptions:true
	},
	{
		key: "shipmentNo",
		name: LOAD_LABELS.SHIPMENT_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 110,
		formatter: EditableField,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "caller",
		name: LOAD_LABELS.CUSTOMER,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		width: 140,
		minWidth: 110,
		formatter: Customer,
		hidden: false,
		colOptions:true
	},
	{
		key: "containerOwner",
		name: LOAD_LABELS.CONTAINER_OWNER,
		sortable: true,
		frozen: false,
		formatter: ContainerOwner,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? 'disabled_input_bg cell' : "cell",
		hidden: false,
		colOptions:true
	},
	{
		key: "bol_bkg",
		name: LOAD_LABELS.MBOL_BKG,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 100,
		formatter: MasterBillOfLading,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "road_container_ref", // only for load type road
		name: LOAD_LABELS.REF_CONTAINER_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load !== "ROAD" && getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: (row) => row.type_of_load === "ROAD" && isEditPermission,
		minWidth: 123,
		formatter: ({ row }) => {
			return (
				<>
					<div className="cell-content">
						{row.type_of_load === "ROAD" && row.containerNo}
					</div>
				</>
			);
		},
		hidden: false,
		colOptions:true
	},
	{
		key: "chassisNo",
		name: LOAD_LABELS.CHASSIS_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? 'disabled_input_bg cell' : "cell",
		minWidth: 100,
		formatter: Chassis,
		hidden: false,
		colOptions:true
	},
	{//ON Progress
		key: "totalWeight",
		name: LOAD_LABELS.TOTAL_WEIGHT,
		sortable: true,
		frozen: false,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: ({ row }) => {
			const totalWeightInKgs = row.items?.sum((x)=>x?.weightKgs ?? 0)
			return (
				<>
					<div className="cell-content">
						{weightUnit==="kg" ? (totalWeightInKgs>=0 ? totalWeightInKgs?.toFixed(2) : "") : (row.totalWeight ? row.totalWeight?.toFixed(2) : "")}
					</div>
				</>
			);
		},
		hidden: false,
		colOptions:true
	},
	{
		key: "consignee",
		name: LOAD_LABELS.DELIVERY_LOCATION,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: Consignee,
		minWidth: 140,
		hidden: false,
		colOptions:true
	},
	{
		key: "shipper",
		name: LOAD_LABELS.PICK_UP_LOCATION,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: Shipper,
		hidden: false,
		minWidth: 130,
		colOptions:true
	},
	{
		key: "consigneeInfo",
		name: LOAD_LABELS.DELIVERY_CITY_STATE,
		sortable: true,
		frozen: false,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: ({ row }) => (
			<>
				<div className="cell-content">
					{row.consigneeInfo && 
					( row.consigneeInfo.city + ", " + row.consigneeInfo.state).replace(/,\s*$/,"")}
				</div>
			</>
		),
		hidden: false,
		minWidth: 135,
		colOptions:true
	},
	{
		key: "emptyOrigin",
		name: LOAD_LABELS.RETURN_LOCATION,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: EmptyOrigin,
		hidden: false,
		minWidth: 135,
		colOptions:true
	},
	// myAppEmptyOrigin
	{
		key: "myAppEmptyOrigin",
		name: "Container Return",
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: RecommendedReturnTerminals,
		hidden: false,
		minWidth: 500,
		colOptions:true
	},
	{
		key: "deliveryOrderNo",
		name: LOAD_LABELS.VESSEL_NAME,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: (row) =>isEditPermission && row.type_of_load !== "ROAD",
		minWidth: 130,
		formatter: EditableField,
		hidden: false,
		colOptions:true
	},
	{
		key: "terminal",
		name: LOAD_LABELS.BRANCH,
		sortable: true,
		frozen: false,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: ({ row }) => {
			return (
				<>
					<div className="cell-content">
						{row.terminal ? row.terminal.name : ""}
					</div>
				</>
			);
		},
		hidden: !isTerminal?.(),
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "loadtemplate",
		name: LOAD_LABELS.LOAD_TEMPLATE,
		sortable: true,
		frozen: false,
		cellClass : "cell",
		formatter: ({ row }) => {
			let preSetOrderStatus = getPreSetOrderStatus().find((el)=> el.value === row?.preSetOrderStatus)
			return (
				<>
					<div className="cell-content">
						{preSetOrderStatus?.label ?? getPreSetOrderStatus().find((el)=>el?.value === "")?.label}
					</div>
				</>
			);
		},
		minWidth: 85,
		colOptions:true,
		hidden: false,
	},
	{
		key: "sealNo",
		name: LOAD_LABELS.SEAL_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		formatter: EditableField,
		minWidth: 90,
		hidden: false,
		colOptions:true
	},
	{
		key: "returnFromTime",
		name: LOAD_LABELS.RETURN_APPT_FROM,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		width: 100,
		minWidth: 100,
		formatter: ReturnTimes,
		hidden: false,
		colOptions:true
	},
	{
		key: "callerPONo",
		name: LOAD_LABELS.PICK_UP_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		formatter: EditableField,
		minWidth: 110,
		hidden: false,
		colOptions:true
	},
	{
		key: "returnNo",
		name: LOAD_LABELS.RETURN_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: isEditPermission,
		minWidth: 100,
		formatter: EditableField,
		hidden: false,
		colOptions:true
	},
	{
		key: "reset",
		name: LOAD_LABELS.RESET_ROUTING,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		width: 100,
		formatter: Reset,
		hidden: false,
		minWidth: 95,
		colOptions:true
	},
	{
		key: "change_status",
		name: LOAD_LABELS.CHANGE_STATUS,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: ChangeStatus,
		minWidth: 120,
		hidden: false,
		colOptions:true
	},
	{
		key: "lastContainerTracedDate",
		name: "Last Tracked",
		sortable: true,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		width: 150,
		minWidth: 110,
		formatter: ({ row }) => {
		  return (
			<>
			  <div className="cell-content">
				{ 
					(row?.loadTracks?.lastContainerTracedDate || row?.lastContainerTracedDate)
						&& moment(row?.loadTracks?.lastContainerTracedDate || row.lastContainerTracedDate)
						.tz(getTimeZone({ preferred: true }))
						.format(DateTimeFormatUtils.fullDateTimeFormat())		
				}	
			  </div>
			</>
		  );
		},
		hidden: false,
		colOptions: true,
	},
	{
		key: "genset",
		name: LOAD_LABELS.GENSET_NUMBER,
		sortable: true,
		frozen: false,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: (row) => isEditPermission && row.type_of_load !== "ROAD",
		minWidth: 90,
		formatter: EditableField,
		hidden: false,
		colOptions:true
	},
	{
		key: "distance",
		name: "Distance",
		sortable: false,
		frozen: false,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		formatter: ({ row }) => {
			let startIndex =
				row.driverOrder &&
				row.driverOrder.findIndex(
					(status) => status.type === "PULLCONTAINER" && !status.isVoidOut
				);
			let endIndex =
				row.driverOrder &&
				row.driverOrder.findIndex(
					(status) => status.type === "DELIVERLOAD" && !status.isVoidOut
				);
			if (startIndex === -1) startIndex = 0;
			if (endIndex === -1)
				endIndex = row.driverOrder ? row.driverOrder.length - 1 : 0;
			return (
				<>
					<div className="cell-content">
						{(
							(row.driverOrder &&
								row.driverOrder
									.slice(startIndex, endIndex + 1)
									.sum((obj) => obj.distance)) ||
							0
						).toFixed(2)}
					</div>
				</>
			)
		},
		hidden: false,
		minWidth: 100,
		colOptions:true
	},
	{
		key: "CSR",
		name: "CSR",
		sortable: false,
		frozen: false,
		cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell disabled_input_bg" : "cell",
		width: 100,
		formatter: Csr,
		hidden: false,
		colOptions:true
	},
	{
		key: "grayContainerNo",
		name: LOAD_LABELS.GRAY_CONTAINER_NUMBER,
		sortable: false,
		frozen: false,
		cellClass: (row) => (row.type_of_load === "ROAD" || !row.isGrayPool) && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		editable: (row) => isEditPermission && row.type_of_load !== "ROAD" && row.isGrayPool,
		minWidth: 130,
		formatter: EditableField,
		hidden: false,
		colOptions:true
	},
	{
		key: "grayChassisNo",
		name: LOAD_LABELS.GRAY_CHASSIS_NUMBER,
		sortable: false,
		frozen: false,
		cellClass: (row) => (row.type_of_load === "ROAD" || !row.isGrayPool) && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		formatter: GrayChassis,
		minWidth: 120,
		hidden: false,
		colOptions:true
	},
	{
		key: "doNo",
		name: LOAD_LABELS.HOUSE_BILL_OF_LADING,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 150,
		formatter: EditableField,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "releaseNo",
		name: LOAD_LABELS.VOYAGE,
		sortable: true,
		cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 140,
		formatter: EditableField,
		hidden: false,
		editable : (row) => isEditPermission && row.type_of_load !== "ROAD",
		colOptions:true
	},
	{
		key: "purchaseOrderNo",
		name: LOAD_LABELS.PURCHASE_ORDER_NUMBER,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 140,
		formatter: EditableField,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "appointmentNo",
		name: LOAD_LABELS.APPOINTMENT_NUMBER,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 140,
		formatter: EditableField,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "reservationNo",
		name: LOAD_LABELS.RESERVATION_NUMBER,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 140,
		formatter: EditableField,
		hidden: false,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "scac",
		name: LOAD_LABELS.SCAC,
		sortable: true,
		cellClass: "cell",
		frozen: false,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
		minWidth: 120,
		formatter: EditableField,
		hidden: true,
		editable : isEditPermission,
		colOptions:true
	},
	{
		key: "destinationETA",
		name: LOAD_LABELS.DRIVER_ETA,
		sortable: true,
		frozen: false,
		cellClass: "cell",
		formatter: DestinationETA,
		hidden: false,
		colOptions: true,
		minWidth: 140,
		editable : isEditPermission,
	},
    {
        key: "officeNote",
        name: LOAD_LABELS.OFFICE_NOTE,
        sortable: true,
        minWidth: 120,
        resizable: true,
        cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
        hidden: false,
		colOptions: true,
        formatter: Note,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
    },
	...(isManageFleetOrOutSource()?.isManageFleet ? [
		{
			key: "driverNote",
			name: LOAD_LABELS.DRIVER_NOTE,
			sortable: true,
			minWidth: 120,
			resizable: true,
			cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
			hidden: false,
			colOptions: true,
			formatter: Note,
			editorOptions: {
				editOnClick: true,
			},
			editor: TextEditor,
		}
	] : [] ),
    {
        key: "yardNote",
        name: LOAD_LABELS.YARD_NOTE,
        sortable: true,
        minWidth: 120,
        resizable: true,
        cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
        hidden: false,
		colOptions: true,
        formatter: Note,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
    },
	...(isCustomsNote() ? [{
        key: "customsNote",
        name: LOAD_LABELS.CUSTOMS_NOTE,
        sortable: true,
        minWidth: 120,
        resizable: true,
        cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
        hidden: false,
		colOptions: true,
        formatter: Note,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor,
    }] : []),
	...(isManageFleetOrOutSource()?.isManageFleet ? [ {
        key: "portNote",
        name: LOAD_LABELS.PORT_NOTE,
        sortable: true,
        minWidth: 120,
        resizable: true,
        cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
        hidden: false,
		colOptions: true,
        formatter: Note,
		editorOptions: {
			editOnClick: true,
		},
		editor: TextEditor
    } ] : []),
	...(isTareWeightEnabled() ?
	[
		{
			key: "tareWeight",
			name: LOAD_LABELS.TARE_WEIGHT,
			sortable: true,
			cellClass: (row) => row.type_of_load === "ROAD" && getCustomColor(row) === "#ffffff" ? "disabled_input_bg cell" : "cell",
			frozen: false,
			editorOptions: {
			  editOnClick: true,
			},
			editor: TextEditor,
			minWidth: 110,
			hidden: false,
			formatter: EditableField,
			editable:  (row) => isEditPermission && row?.type_of_load !== "ROAD",
			colOptions: true,
		  }
	]:[]
	),

	...(isManageFleetOrOutSource().isOutSource ? [
		{
			key: "tenderStatus1",
			name: "Tender Status 1",
			sortable: false,
			cellClass: "cell",
			formatter: TenderStatus,
			hidden: false,
			frozen: false,
			minWidth: 170,
			colOptions:true
		}
	] :[]),
		...(isManageFleetOrOutSource().isOutSource ? [{
			key: "shiposCarrier",
			name: LOAD_LABELS.CARRIER,
			sortable: false,
			frozen: false,
			cellClass: (row) => getCustomColor(row) === "#ffffff" ? "cell p_5 disabled_input_bg" : "cell p_5",
			width: 150,
			minWidth: 110,
			formatter: ({ row }) => {
				return (
					<>
						<div className="cell-content">
							{row?.driverOrder?.[0]?.drayosCarrier?.company_name}
						</div>
					</>
				);
			},
			hidden: false
		},
	] : []),
	...(isTerminalContainerStatusEnabled() ?
	[
		{
			key: "myApp_tracking_status",
			name: "Terminal Status",
			sortable: true,
			frozen: false,
			cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
			formatter: ({ row }) => {
				return (
					<>
						<div className="cell-content">
							{row.myApp_tracking_status ? row.myApp_tracking_status : ""}
						</div>
					</>
				);
			},
			hidden: false,
			minWidth: 140,
			colOptions: true
		}
	]:[]
	),
	...(isCustomsTypeEnabled() ?
	[
		{
			key: "customsType",
			name: "Customs Type",
			sortable: false,
			frozen: false,
			cellClass: "cell p-0 overflow-hidden load_tooltip justify-content-start",
			formatter: ({ row }) => {
				return (
					<>
						<div className="cell-content">
							{row.customsType ? row.customsType : ""}
						</div>
					</>
				);
			},
			hidden: false,
			minWidth: 110,
			colOptions: true
		}
	]:[]
	),
]};
// fallback in case column used in any component
export const column = getColumns();