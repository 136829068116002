import React, { useState } from "react";
import { StateFormDateTime, StateFormSelect } from "../../../../../../Components/FormComponent/StateFormComponent";
import { IconCheckCircle, IconWarningCircle } from "../../../../../../Components/Common/Icons";
import moment from "moment";
import { getStorage, updateTerminology } from "../../../../../../services";
import { smallSelectStyle } from "../../../../../../assets/js/select-style";
import Select from "react-select"
import ReactTooltip from "react-tooltip";
import { profileOptions, terminalTimzoneOptions } from "./utilities";
import TextInputField from "../Components/TextInputField";
import NumberInputField from "../Components/NumberInputField";
export default function DriverInfo(props) {
    const error = props.error
    const {selectedDriver, isTerminal, isUserNameValid, countries, terminalOptions, handleEventOnBlur, allTerminals, handleOnBlur} = props
    const {driver:driverDetails} = selectedDriver

    const [fname,setFname] = useState(selectedDriver.name)
    const [lname,setLname] = useState(selectedDriver.lastName)
    const [email,setEmail] = useState(selectedDriver.email)
    const [mobile,setMobile] = useState(driverDetails?.mobile)
    const [profileTypes,setProfileTypes] = useState(driverDetails?.profileType.map(d=> { return{value: d, label: d} }) || selectedDriver?.profileType.map(d=> { return{value: d, label: d} }) || [])
    const [dob,setDob] = useState(selectedDriver?.dob || driverDetails?.dob)
    const [doh,setDoh] = useState(selectedDriver?.doh || driverDetails?.doh)
    const [billingEmail,setBillingEmail] = useState(selectedDriver?.billingEmail || driverDetails?.billingEmail)
    const [homeTerminalTimezone,setHomeTerminalTimezone] = useState(driverDetails.homeTerminalTimezone)
    const [username,setUsername] = useState(selectedDriver.username)
    const selectedTerminals = allTerminals.filter((x)=>driverDetails.newTerminal.includes(x._id+"")).map(d=>({value:d._id,label:d.name}))
    const [terminal,setTerminal] = useState(selectedTerminals || [])

    const [registrationNumber,setRegistrationNumber] = useState(driverDetails.registrationNumber)
    const [registrationCountry,setRegistrationCountry] = useState(driverDetails.registrationCountry)

  return (
    <>
    <div className="font-14 font-medium mb-15">Driver Info</div>
      <div className="form-row">
        <TextInputField
          type="text"
          name="name"
          formErrors={error}
          onChange={(e)=>setFname(e.target.value)}
          label={"First Name"}
          isRequired={true}
          className={"col-lg"}
          inputClassName={"name"}
          value={fname}
          onBlur={handleEventOnBlur}
        />
        <TextInputField
          type="text"
          name="lastName"
          formErrors={error}
          onChange={(e)=>setLname(e.target.value)}
          label={"Last Name"}
          isRequired={true}
          className={"col-lg"}
          inputClassName={"lastName"}
          value={lname}
          onBlur={handleEventOnBlur}
        />
        <div className={`form-group col-lg-2`}>
          <div className="position-relative w-100">
            <TextInputField
              type="text"
              name="username"
              formErrors={error}
              onChange={(e)=>setUsername(e.target.value)}
              label={"Username"}
              isRequired={true}
              value={username}
              inputClassName={"username"}
              onBlur={handleEventOnBlur}
            />
            {isUserNameValid === false && (
              <small className="text-danger">
                This username is already taken, please try another one
              </small>
            )}
            <div className="position-absolute msg-pop-tooltip">
              {isUserNameValid === false && (
                <div>
                  <React.Fragment>
                    <span data-tip data-for="userreEnable" type="button">
                      <IconWarningCircle className="text-danger" />
                    </span>
                    <ReactTooltip id="userreEnable" place="right">
                      <span>
                        This username is already taken, please try another one
                      </span>
                    </ReactTooltip>
                  </React.Fragment>
                </div>
              )}
              {isUserNameValid && (
                <IconCheckCircle className="text-success" />
              )}
            </div>
          </div>
        </div>
        <TextInputField
          type="text"
          name="email"
          formErrors={error}
          onChange={(e)=>setEmail(e.target.value)}
          label={"Email"}
          isRequired={true}
          className={"col-lg"}
          inputClassName={"email"}
          value={email}
          onBlur={handleEventOnBlur}
        />
        {JSON.parse(getStorage("userBasicSettings"))?.phoneFormat ==
        "international" ? (
            <TextInputField
            type="text"
            name="mobile"
            value={mobile}
            onChange={(e)=>setMobile(e.target.value)}
            formErrors={error}
            isRequired={true}
            className={"col-lg"}
            inputClassName="mobile"
            label={"Phone"}
            onBlur={handleEventOnBlur}
          />
        ) : (
          <NumberInputField
            type="text"
            name="mobile"
            format="###-###-####"
            mask="_"
            value={mobile}
            onChange={(e)=>setMobile(e.target.value)}
            formErrors={error}
            isRequired={true}
            className={"col-lg"}
            inputClassName="mobile"
            label={"Phone"}
            onBlur={handleEventOnBlur}
          />
        )}
        <StateFormSelect
          type="text"
          name="profileType"
          label={"Select Profile Type"}
          formErrors={error}
          formsTouched={{profileTypes:true}}
          onValChange={(key,value)=>{
            setProfileTypes(value)
          }}
          onBlur={(e)=>{
            handleOnBlur(
              "profileType",
              JSON.stringify(profileTypes.map((x) => x.value))
            );
          }}
          options={profileOptions}
          className={"col-lg"}
          inputClassName="profileType"
          value={profileTypes}
          isClearable
          isMulti={true}
        />
      </div>
      <div className="form-row">
        <StateFormDateTime
          formsTouched={true}
          formErrors={error}
          label="Date of Birth"
          name="dob"
          value={dob ? moment(dob) : ""}
          onValChange={(key,val)=>setDob(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="left dob"
          onBlur={()=>handleOnBlur("dob",dob)}
        />
        <StateFormDateTime
          formsTouched={true}
          formErrors={error}
          label="Date of Hire (DOH)"
          name="doh"
          value={doh ? moment(doh) : ""}
          onValChange={(key,val)=>setDoh(val)}
          isRequired={false}
          className="col-lg-2"
          inputClassName="right doh"
          onBlur={()=>handleOnBlur("doh",doh)}
        />
        <TextInputField
          type="text"
          name="billingEmail"
          className={"col-lg-2"}
          formErrors={error}
          onChange={(e)=>setBillingEmail(e.target.value)}
          label={"Billing Email"}
          value={billingEmail}
          inputClassName={"billingEmail"}
          onBlur={handleEventOnBlur}
        />
        <StateFormSelect
          type="text"
          name="homeTerminalTimezone"
          label={"Home Branch Time Zone"}
          formErrors={error}
          formsTouched={true}
          onValChange={(key,value)=>{
            setHomeTerminalTimezone(value)
            props.updateData("homeTerminalTimezone",value)
          }}
          inputClassName="homeTerminalTimezone"
          options={terminalTimzoneOptions}
          className={"col-lg-2"}
          value={
            homeTerminalTimezone &&
            terminalTimzoneOptions.find(
              (option) => option.value === homeTerminalTimezone
            )
          }
        />
        {isTerminal && (
          <StateFormSelect
            name="newTerminal"
            label={updateTerminology('terminal')}
            isRequired={true}
            className="col-lg-2"
            formErrors={error}
            formsTouched={{newTerminal:true}}
            inputClassName="newTerminal"
            onValChange={(name, value) => {
              setTerminal(value)
              props.handleTerminalChange(value);
            }}
            options={terminalOptions}
            value={terminal}
            isMulti
            onBlur={()=>{
              handleOnBlur(
                "newTerminal",
                JSON.stringify(terminal.map((x) => x.value))
              );
            }}
          />
        )}
        {JSON.parse(getStorage("userBasicSettings"))?.isVAT && (
          <>
            <TextInputField
              type="text"
              name="registrationNumber"
              className={"col-lg-2"}
              formErrors={error}
              onChange={(e)=>{
                setRegistrationNumber(e.target.value)
              }}
              label={"Registration Number"}
              inputClassName="registrationNumber"
              value={registrationNumber}
              onBlur={()=>{
                props.updateData("registrationNumber",registrationNumber)
              }}
            />
            <div className="form-group col-lg-2 null">
              <label className="col-form-label">Tax Country Code</label>
              <Select
                value={countries?.find(
                  (el) => el.value === registrationCountry
                )}
                styles={smallSelectStyle}
                isSearchable={true}
                options={countries || []}
                name="registrationCountry"
                placeholder="Tax Country Code"
                className="registrationCountry"
                onChange={(val) => {
                  setRegistrationCountry(val.value);
                  props.handleOnBlur("registrationCountry",val.value)
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  )
}
