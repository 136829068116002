

export const ENUM = Object.freeze({
    NOTE: 'notes',
    AMOUNT: 'amount',
    CHECK_NO: 'checkNo',
    DOC_NAME: 'docName',
    DOCUMENT: 'document',
    DOC_TYPE: 'documentType',
    PAYMENT_DATE: 'paymentDate',
    DEPOSITE_TO_ACCOUNT_REF: 'DepositToAccountRef',
});

export const defaultBill = {
    notes: '',
    amount: null,
    docName: '',
    checkNo: null,
    document: null,
    paymentDate: null,
    documentUrl: null,
    documentType: 'Other',
}

export const requiredCells = ['amount', 'paymentDate'];