import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from "react-dom";
import useBoxPosition from '../../../../../../Components/CustomHooks/useBoxPosition';
import { addCellColor } from '../../../actionCreator';
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { colors } from '../../../constants';

export const cellColorSetter = (cellRef, color) => {
  if(color){
    cellRef.current.closest('.rdg-cell').classList.remove(...colors);
    cellRef.current.closest('.rdg-cell').classList.add(color);
    cellRef.current.closest('.rdg-cell').style.color = color !== 'bg-cell-White' && color !== 'bg-cell-LightlyDesaturated'? 'white' : 'Black';
  }
} 

const ColorPortal = ({ CellRef, onClose, loadId, fieldName, defaultCellColors, Row, onRowChange }) => {

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef, boxRef);
  const [cellColor, setCellColor] = useState(null);
 
  const updateColor = async() => {
    const params = {
      loadId: loadId,
      colors: defaultCellColors || {}
    }
    params.colors[fieldName] = cellColor
    const response = await addCellColor(params);
    if (response) {
      onRowChange({ ...Row, ["colorOfCells"]: response?.colorOfCells })
    }

  }
  
  useEffect(() => {
    if (cellColor) {
      updateColor();
      CellRef.current.closest('.rdg-cell').classList.remove(...colors);
      CellRef.current.closest('.rdg-cell').classList.add(cellColor);
      CellRef.current.closest('.rdg-cell').style.color = cellColor !== 'bg-cell-White' && cellColor !== 'bg-cell-LightlyDesaturated'? 'white' : 'Black';
      onClose(false);
    }

    window.document.getElementById('root').style.pointerEvents = "none";
    return () => {
      window.document.getElementById('root').style.pointerEvents = "auto";
    }
  }, [cellColor]);

  useOnClickOutside(boxRef, () => onClose(false));

  return createPortal(
    <div ref={boxRef} className="dispatch-card-bg shadow-xl card p-10" style={Style}>
      <h6 className='font-12 font-weight-normal text-muted m-0'>Change Color</h6>
      <div className='card--wrapper grid'>
        {colors.map((color, index) => (
          <div className={`card-list wh-24px rounded mt-10 pointer ${color==='bg-cell-White'?'text-gray-100 border-1':''} ${color}`} onClick={() => {
            setCellColor(color);
          }}>
          </div>
        ))
        }
      </div>
    </div>,
    document.getElementById('pp-overlay-container')
  );
}

export default ColorPortal;