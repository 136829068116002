import React, { useRef, useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import GrayChassisList from "../portals/GrayChassisList";
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import { getStorage } from "../../../../../../services";
import PreventGrayChassisList from "../portals/PreventGrayChassisList";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const GrayChassis = ({ row, column, onRowChange, isCellSelected }) => {
	const cellRef = useRef();
	const prevSelectionRef = useRef();

	const [isGrayChassisList, setIsGrayChassisList] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);
	const isPreventChassis = JSON.parse(getStorage("userBasicSettings")||"{}")?.isPreventChassis;


	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected  && !isColorPortal) {
			setIsGrayChassisList(true);
		}
		prevSelectionRef.current = isCellSelected;
		if (!isCellSelected || row.type_of_load=="ROAD" || row.isGrayPool==false ) {
			setColorPortal(false);
		}
	}, [isCellSelected ,isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.grayChassisNo);
	}, [column, row?.colorOfCells?.grayChassisNo]);


	return (
		<>
		<div ref={cellRef} className="cell-content" onClick={()=>setIsGrayChassisList(true)}onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>

			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && row.grayChassisNo ? row.grayChassisNo : ""}
			{row.isEditPermission && isGrayChassisList && !isAssignInProgress && row.type_of_load !== "ROAD" && row.isGrayPool && (
				isPreventChassis?
				<PreventGrayChassisList
				Row={row}
				CellRef={cellRef}
				onClose={(val) => {
					setIsGrayChassisList(val);
					const a = document.querySelector('[aria-selected="true"]');
					if (a) a.focus();
				}}
				onRowChange={onRowChange}
				setIsAssignInProgress={setIsAssignInProgress}
				/>
				:<GrayChassisList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsGrayChassisList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
				/>
			)}
			
		</div>
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="grayChassisNo" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
	</>
	);
};

export default GrayChassis;
