import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import ShipperList from "../portals/ShipperList";
import { DateTimeFormatUtils, getStorage } from "../../../../../../services/Common.services";
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const Shipper = ({ row, column, onRowChange, isCellSelected }) => {
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	let TimeZone = getStorage("timeZone");

	const [isShipperList, setIsShipperList] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setIsShipperList(true)
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected){
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.shipper);
    }, [column, row?.colorOfCells?.shipper])

	return (
		<>
		<div ref={cellRef} className="cell-content" onClick={() => setIsShipperList(true)} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
				{isAssignInProgress && (
					<CellSpinner />
				)}
				{!isAssignInProgress &&
					<div className=" d-block">
						{row.shipper &&
							row.shipper.map((e, i) => {
								return (
									<div className="rdg-truncate" key={i}>
										{e.company_name}
										{(e.officeHoursStart || e.officeHoursEnd) && (
											<small>
												<br />
												{e.officeHoursStart
													? moment(e.officeHoursStart)
														.tz(TimeZone)
														.format(DateTimeFormatUtils.timeFormat())
													: ""} - {e.officeHoursEnd
													? moment(e.officeHoursEnd)
														.tz(TimeZone)
														.format(DateTimeFormatUtils.timeFormat())
													: ""}
											</small>
										)}
									</div>
								);
							})}
					</div>}
				{row?.isEditPermission && isShipperList &&  !isAssignInProgress && (
					<ShipperList
						Row={row}
						CellRef={cellRef}
						onClose={(val) => {
							setIsShipperList(val);
							const a = document.querySelector('[aria-selected="true"]');
							if (a) a.focus();
						}}
						onRowChange={onRowChange}
						setIsAssignInProgress={setIsAssignInProgress}
						column={column}
					/>
				)}
			
		</div>
		{isColorPortal &&
			<ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="shipper" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
	</>);
};

export default Shipper;
