import React, { useState, useRef, useEffect } from "react";
import { IconPhone } from "Components/Common/Icons";
import CustomTooltip from "Components/Common/CustomTooltip";
import { formatPhoneNumber } from "pages/trucker/Tracking/utility";
import { useHover } from "hooks";
import { IconClone } from "./Icons";

const PhoneNumberWithCopy = ({ mobile, className = "" }) => {
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [showCopiedFeedback, setShowCopiedFeedback] = useState(false);
  const timeoutRef = useRef(null);
  const [hoverRef, isHovering] = useHover();

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  const handlePhoneClick = (e) => {
    e.stopPropagation();
    if (!mobile) return;

    navigator.clipboard
      .writeText(mobile)
      .then(() => {
        setTooltipMessage("Copied!");
        setShowCopiedFeedback(true);
        timeoutRef.current = setTimeout(() => {
          setShowCopiedFeedback(false);
          setTooltipMessage("");
        }, 2000);
      })
      .catch((err) => {
        console.error("Copy failed:", err);
        setTooltipMessage("Copy failed");
        setShowCopiedFeedback(true);
      });
  };

  if (!mobile) return null;

  return (
    <>
      <div
        ref={hoverRef}
        onClick={handlePhoneClick}
        className={`hover-bg-primary-50 rounded-2 pointer d-inline-flex align-items-center gap-5 align-self-start ${className}`}
        style={{ paddingLeft: 1, paddingRight: 5 }}
      >
        {isHovering ? <IconClone className="wh-15px" /> : <IconPhone className="wh-15px text-muted" />}
        <span className="font-medium">{formatPhoneNumber(mobile)}</span>
      </div>

      {showCopiedFeedback && (
        <CustomTooltip isOpen={true} refNo={hoverRef?.current}>
          {showCopiedFeedback ? tooltipMessage : formatPhoneNumber(mobile)}
        </CustomTooltip>
      )}
    </>
  );
};

export default PhoneNumberWithCopy;
