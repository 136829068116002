import { IconClock9, IconDocument, IconMobilePermission, IconNotes, IconSearchData, IconSteering, IconClockwiseRotationArrow } from "../../../Components/Common/Icons";

export const loadFlags = [
  { label: 'Yes', value: true },
  { label: 'No', value: false}
]

export const loadFlagsString = [
    { label: 'Yes', value: "true" },
    { label: 'No', value: "false"}
  ]
export const driverRankingOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]

export const TabLists = [
  {
      label: "Driver",
      value: "driver",
      icon: <IconSteering />,
  },
  {
      label: "Documents",
      value: "documents",
      icon: <IconDocument />,
  },
  {
      label: "History",
      value: "history",
      icon: <IconClock9 />,
  },
    {
        label: "Hours of Service",
        value: "hourOfService",
        icon: <IconClockwiseRotationArrow />
    },
    {
      label: "Mobile Permissions",
      value: "mobilePermissions",
      icon: <IconMobilePermission />,
  },
  {
      label: "Audit",
      value: "audit",
      icon: <IconSearchData />,
  },
  {
      label: "Notes",
      value: "notes",
      icon: <IconNotes />,
  },
];