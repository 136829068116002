import React, { useState, useRef, useMemo, useEffect } from "react";
import { LoadReferenceContext } from "../../NewDispatcherContext";
import moment from 'moment';
import { DateTimeFormatUtils, getStorage } from "services";
import DateEditor from "../CellComponents/DateEditor";
import ColorPortal, { cellColorSetter } from "../portals/ColorPortal";
import { useDispatch, useSelector } from "react-redux";

const DestinationETA = ({row, column, onRowChange, isCellSelected}) => {
  const [showBox, setShowBox] = useState(false);
  const cellRef = useRef();
	const prevSelectionRef = useRef();
  const [isColorPortal, setColorPortal] = useState(false);
  const dispatch = useDispatch();
  const fAction = useSelector((state) => state.dispatcherTableReducer);
  
  const getDestinationETA = () => {
    const userBasicSettings = JSON.parse(localStorage.getItem('userBasicSettings'))
    let timeZone = getStorage("timeZone");
    if(userBasicSettings?.isUserSpecificTimeZoneEnabled) {
      if (row?.driverOrderId?.timeZone) {
        timeZone = row.driverOrderId.timeZone;
      }
    }
    return moment(row?.destinationETA).tz(timeZone).format(DateTimeFormatUtils.verboseDateTimeFormat())
  }

  const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel();
		}
		prevSelectionRef.current = isCellSelected;
		if(!isCellSelected){
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

  useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.pickupToTime);
  }, [column, row?.colorOfCells?.pickupToTime]);

  useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "destinationETA") {
			onRowChange({ ...row, destinationETA : fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])

  return (
    <>
    <div
      ref={cellRef}
      className="cell-content text-center"
      onClick={() => showModel()}
      onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}
    >
      <div data-testid="rdg-truncate" className="rdg-truncate">
        {row?.destinationETA ? getDestinationETA() : '' }
      </div>
    </div>
    {row?.isEditPermission && showBox &&
      <DateEditor
        CellRef={cellRef}
        onClose={() => {
          hideModel();
          const a = document.querySelector('[aria-selected="true"]');
          if (a) a.focus();
        }}
        onOpen={showModel}
        row={row}
        column={column}
        onRowChange={onRowChange} 
      />
    }
    {isColorPortal &&
			<ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName= "pickupToTime" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}  />
		}
    </>
  );
};

export default DestinationETA;
