import React from 'react';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';

import useProfileCondition from './hooks/useProfileCondition';
import { IconMinus, IconPlus } from 'Components/Common/Icons';
import { nonRulesConstant } from 'pages/tms/VendorTariffs/constants/chargeProfiles';
import { useCustomerSelect } from 'pages/tms/VendorTariffs/hooks/useCustomerSelect';
import { getCustomerCityAsyncSearch, getCustomerCityWithCountryCodeAsyncSearch } from 'pages/tms/CustomerRates/service/customerRateService.js';
import { TariffUnitOfMeasureConst } from 'pages/tms/VendorTariffs/service/vendorTariffService';
import { isZipcodeWithCountryEnabled } from 'services';

const ProfileCondition = ({ handleChange, newChargeProfile = {}, isDisabledAllCustomerRateModal, vendorType=null}) => {
    const {
        STATUS,
        checkboxes,
        eventOptions,
        selectOptions,
        ConditionKeys,
        getEventTimes,
        smallSelectStyle,
        getRulesEvent,
        getRulesEventTime,
        getRulesEventLocation,
        handleFocus,
        handleAddEvent,
        handleRemoveEvent,
        getFromToEventOptionMap,
        getFromToEventLocation,
        selectOptionsForLegs,
    } = useProfileCondition({ newChargeProfile, handleChange, vendorTypeName: vendorType });
    const {groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, shipperFilterOptions } = useCustomerSelect();

    return (
        <div className='my-3' key={newChargeProfile}>
            <div className="font-16 font-medium text-capitalize">Define how you want to calculate the pay</div>
            <div className='my-15 d-flex gap-15'>
                {
                    !nonRulesConstant.includes(newChargeProfile?.unitOfMeasure) && (
                        <div className="d-flex align-items-center">
                            <input type="radio" name="select-rules" checked={checkboxes?.firstCondition} className="mr-1 mb-1" onClick={() => handleFocus(ConditionKeys.FIRST_COND)} />
                            <label htmlFor="based-multiple-events" className="font-14">Between Statuses</label>
                        </div>
                    )
                }
                
                <div className="d-flex align-items-center mb-1">
                    <input type="radio" name="select-rules" checked={checkboxes?.eventLocationRule} className="mr-1" onClick={()=>
                    handleFocus(ConditionKeys.SECOND_COND)} />
                    <label htmlFor="based-one-event" className="mb-0 font-14">By Event</label>
                </div>
                
                {
                TariffUnitOfMeasureConst.PERCENTAGE_BY_LEG !== newChargeProfile?.unitOfMeasure && <div
                    className="d-flex align-items-center mb-1">
                    <input type="radio" name="select-rules" checked={checkboxes?.eventLocationRules} className="mr-1" onClick={()=>
                    handleFocus(ConditionKeys.THIRD_COND)} />
                    <label htmlFor="based-multiple-events" className="mb-0 font-14">By Move</label>
                </div>
                }
                { (vendorType?.toLowerCase() === 'driver' && TariffUnitOfMeasureConst.PERCENTAGE_BY_MOVE !==
                newChargeProfile?.unitOfMeasure) && (
                <div className="d-flex align-items-center mb-1">
                    <input type="radio" name="select-rules" checked={checkboxes?.byLeg} className="mr-1" onClick={()=>
                    handleFocus(ConditionKeys.FOURTH_COND)} />
                    <label htmlFor="based-multiple-events" className="mb-0 font-14">By Leg</label>
                </div>
                )}
            </div>

            {
                checkboxes?.firstCondition && !nonRulesConstant.includes(newChargeProfile?.unitOfMeasure) && (
                    <>
                        <div className="form-group mb-15">
                            <div className="form-group d-flex flex-start">
                                <div className="form-row-md w-100">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="calculate-from-top">Calculate From This</label>
                                        <Select
                                            options={STATUS}
                                            value={eventOptions?.fromEvent}
                                            styles={smallSelectStyle}
                                            onChange={({ value }) => handleChange(ConditionKeys.FROM_EVENT, value)}
                                            isDisabled={isDisabledAllCustomerRateModal}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="calculate-from-to">Calculate To This</label>
                                        <Select
                                            options={STATUS}
                                            isMulti
                                            value={eventOptions?.toEvent}
                                            styles={smallSelectStyle}
                                            onChange={(option) => handleChange(ConditionKeys.TO_EVENT, option?.map(d => d?.value))}
                                            isDisabled={isDisabledAllCustomerRateModal}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {
                checkboxes?.eventLocationRule && (
                    <div className='form-group'>
                        <div className="form-row">
                            <div className="col-md-5">
                                <label htmlFor="based-one-event">If Event</label>
                                <Select
                                    options={selectOptions}
                                    value={eventOptions?.ruleEvent}
                                    styles={smallSelectStyle}
                                    onChange={(value) => handleChange(ConditionKeys.SECOND_COND, { event: value, eventTime: "", eventLocation: "", _selectFieldId: 0 })}
                                    isDisabled={isDisabledAllCustomerRateModal}
                                    isClearable={true}
                                />
                            </div>
                            <div className="col-md-5">
                                <label htmlFor="based-one-event">Event Location</label>
                                <AsyncCreatableSelect
                                    isClearable
                                    cacheOptions
                                    styles={smallSelectStyle}
                                    value={eventOptions?.ruleEventLocation?.[0]?.label ? eventOptions?.ruleEventLocation: null }
                                    isDisabled={isDisabledAllCustomerRateModal}
                                    placeholder='City/State/ZipCode/Profile/Profile-Group'
                                    formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                    defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                    onChange={(selectedOptions) => handleChange(ConditionKeys.SECOND_COND, { event: " ", eventTime: " ", eventLocation: selectedOptions, _selectFieldId: 1 })}
                                    loadOptions={(strSearch) => 
                                        isZipcodeWithCountryEnabled()
                                            ? getCustomerCityWithCountryCodeAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                            : getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )
            }


            {
                (checkboxes?.eventLocationRules && TariffUnitOfMeasureConst.PERCENTAGE_BY_LEG !== newChargeProfile?.unitOfMeasure) && (
                    <div className="form-group">
                        {newChargeProfile?.eventLocationRules?.map((rules, index) => (
                            <div key={index} className="d-flex gap-10 mb_5 align-items-end">
                                <div className="wh-20px bg-secondary rounded-5 text-white d-flex align-items-center justify-content-center text-white mb-1" styles={{ minWidth: "20px" }}>
                                    {index + 1}
                                </div>
                                <div className="form-row flex-grow-1">
                                    <div className="col-md-5">
                                        {index === 0 && <label htmlFor={`based-multiple-events-${index}`}>If Event</label>}
                                        <Select
                                            options={selectOptions}
                                            styles={smallSelectStyle}
                                            value={getRulesEvent(rules)}
                                            onChange={(value) => handleChange(ConditionKeys.THIRD_COND, { event: value, _selectFieldId: 0, index })}
                                            isDisabled={isDisabledAllCustomerRateModal}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        {index === 0 && <label htmlFor={`based-multiple-events-${index}`}>Event Time</label>}
                                        <Select
                                            options={getEventTimes(rules?.event)}
                                            styles={smallSelectStyle}
                                            onChange={(value) => handleChange(ConditionKeys.THIRD_COND, { eventTime: value, _selectFieldId: 2, index })}
                                            isDisabled={isDisabledAllCustomerRateModal || (index !== 0 && index !== (newChargeProfile?.eventLocationRules?.length - 1))}
                                            value={getRulesEventTime(rules)}
                                        />
                                    </div>

                                    <div className="col-md-5">
                                        {index === 0 && <label htmlFor={`based-multiple-events-${index}`}>Event Location</label>}
                                        <AsyncCreatableSelect
                                            isClearable
                                            cacheOptions
                                            styles={smallSelectStyle}
                                            value={getRulesEventLocation(rules)}
                                            isDisabled={isDisabledAllCustomerRateModal}
                                            placeholder='City/State/ZipCode/Profile/Profile-Group'
                                            formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                            defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                            onChange={(value) => handleChange(ConditionKeys.THIRD_COND, { eventLocation: value, _selectFieldId: 1, index })}
                                            loadOptions={(strSearch) => 
                                                isZipcodeWithCountryEnabled()
                                                    ? getCustomerCityWithCountryCodeAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                                    : getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                            }
                                        />
                                    </div>
                                </div>
                                {newChargeProfile?.eventLocationRules?.length > 1 && (
                                    <button className="btn btn-circle btn-xs bg-soft-danger mb-1" onClick={() => handleRemoveEvent(index)}>
                                        <IconMinus />
                                    </button>
                                )}
                            </div>
                        ))}

                        <div className="text-primary pointer mt-2" onClick={handleAddEvent}>
                            <IconPlus className="mr-2" />
                            Add Event
                        </div>
                    </div>
                )
            }

            {
                (vendorType?.toLowerCase() === 'driver' && checkboxes?.byLeg && TariffUnitOfMeasureConst.PERCENTAGE_BY_MOVE !==
                newChargeProfile?.unitOfMeasure) && (
                    <div className="form-group">
                        <div key={1} className="d-flex gap-10 mb_5 align-items-end">
                            <div className="form-row flex-grow-1">
                                <div className="col-md-5">
                                    <label htmlFor={`based-multiple-events-${1}`}>From Legs</label>
                                    <Select
                                        isMulti
                                        options={selectOptionsForLegs}
                                        styles={smallSelectStyle}
                                        value={getFromToEventOptionMap(newChargeProfile?.fromLegs)}
                                        onChange={(value) => handleChange(ConditionKeys.FOURTH_COND,  { event: 'fromLegs', value: !value?.length ? null : value?.map((e) => e.value) })}
                                        isDisabled={isDisabledAllCustomerRateModal}
                                        isClearable={true}
                                    />
                                </div>

                                <div className="col-md-5">
                                    <label htmlFor={`based-multiple-events-${1}`}>Event Location</label>
                                    <AsyncCreatableSelect
                                        isClearable
                                        cacheOptions
                                        styles={smallSelectStyle}
                                        value={getFromToEventLocation(newChargeProfile?.fromProfile)}
                                        isDisabled={isDisabledAllCustomerRateModal}
                                        placeholder='City/State/ZipCode/Profile/Profile-Group'
                                        formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                        defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                        onChange={(value) => handleChange(ConditionKeys.FOURTH_COND, { event: 'fromProfile', value: value })}
                                        loadOptions={(strSearch) => 
                                            isZipcodeWithCountryEnabled()
                                                ? getCustomerCityWithCountryCodeAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                                : getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div key={2} className="d-flex gap-10 mb_5 align-items-end">
                            <div className="form-row flex-grow-1">
                                <div className="col-md-5">
                                    <label htmlFor={`based-multiple-events-${2}`}>To Legs</label>
                                    <Select
                                        isMulti
                                        options={selectOptionsForLegs}
                                        styles={smallSelectStyle}
                                        value={getFromToEventOptionMap(newChargeProfile?.toLegs)}
                                        onChange={(value) => handleChange(ConditionKeys.FOURTH_COND, { event: 'toLegs', value: !value?.length ? null : value?.map((e) => e.value) })}
                                        isDisabled={isDisabledAllCustomerRateModal}
                                        isClearable={true}
                                    />
                                </div>

                                <div className="col-md-5">
                                    <label htmlFor={`based-multiple-events-${2}`}>Event Location</label>
                                    <AsyncCreatableSelect
                                        isClearable
                                        cacheOptions
                                        styles={smallSelectStyle}
                                        value={getFromToEventLocation(newChargeProfile?.toProfile)}
                                        isDisabled={isDisabledAllCustomerRateModal}
                                        placeholder='City/State/ZipCode/Profile/Profile-Group'
                                        formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                        defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                        onChange={(value) => handleChange(ConditionKeys.FOURTH_COND, { event: 'toProfile', value: value })}
                                        loadOptions={(strSearch) => 
                                            isZipcodeWithCountryEnabled()
                                                ? getCustomerCityWithCountryCodeAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                                : getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default ProfileCondition;