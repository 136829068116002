import _ from "lodash"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import confirm from "../../../Components/Common/ConfirmAert"
import { IconCheck, IconDrag, IconPlus } from "../../../Components/Common/Icons"
import LoadInfoModal from "../../../Components/LoadInfoModal/LoadInfoModal"
import { newXlSelectStyle } from "../../../assets/js/select-style"
import { LOAD_TYPES } from "../../../constants/tms.constant"
import { amplitudeTrack, DateTimeFormatUtils, getDriverAndCarrierName, getStorage, isNewBillingEnabled, isNewSupportMovesEnabled, isTerminalTrackingEnabled, supportMoveEnabled, toastr } from "../../../services"
import DomesticMove from "../Load/DomesticMove"
import { getPreSetOrderStatus, PRESET_ORDER_STATUS } from "../Load/DriverOrderUtility"
import StreetTurn from "../Load/StreetTurn"
import { setLoadStatusOrder } from "../Load/actionCreator"
import { ROUTING_DRAG_LEGS, ROUTING_SUPPORT_DRAG_LEGS } from "../LoadRouting/constants"
import { createRoutingOnTemplateChange, movesFromDriverOrder } from "../LoadRouting/utilities"
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper"
import { LOAD_CHARGE_STATUS } from "../carrier/AccountReceivable/constants"
import { DUAL_TRANSACTION_MESSAGES, EXPORT_LOAD_TYPE, IMPORT_LOAD_TYPE, ROAD_LOAD_TYPE, TRIP_TYPES, createSource, loadEvents } from "../constant"
import { completeLoad, manageEventOrder, removeDomesticMove, removeStreetTurn, uncompleteLoad, updateCompleteLoadDate, updateGrayPool, updateLoadOptions } from "./actionCreator"
import { completedStatus } from "./constant"
import { createLegsFromDriverOrder, validateOrderStatus } from "./helpers"
import NewStreetTurn from "../Load/NewStreetTurn"
import CombineTripCreateModal from './CombineTripCreateModal'
import { isCombineTripEnabled } from "../Dispatcher/NewDriverItinerary/constants"
import AlertBreakDualTransaction from "Components/Common/AlertBreakDualTransaction"
import { TERMINALTRACKING } from "constants"



const billingConfiguration = JSON.parse(getStorage("userBasicSettings") || "{}")?.billingConfiguration;

const RoutingHelperSection = (props) => {
    const {
        loadDetails,
        driverOrder,
        selectedMoveFilter,
        handleDragStart,
        handleDragOver,
        handleSelectMoveFilter,
        hadleonDragEnd,
        showEvent,
        supportMoves,
        originalLoad,
        customer,
        setDriverOrder,
        setLoading,
        allowDriverCompletion,
        isTenderHaveLoadRef,
        isInvalidDriverOrder,
        setIsInvalidDriverOrder,
        selectedRoute,
        setSelectedRoute,
        _setOpenModalRefNumber
    } = props

    const [isGrayPool, setIsGrayPool] = useState()
    const [canChangeTemplate, setCanChangeTemplate] = useState(loadDetails.driverOrder?.[0]?.driver);
    const [options, setOptions] = useState({})
    const [openModalRefNumber, setOpenModalRefNumber] = useState(null);
    const [showLoadModel, setShowLoadModel] = useState(false);
    const [loadCompletedAt, setLoadCompletedAt] = useState(loadDetails.loadCompletedAt);
    const [isTripModalOpen, setIsTripModalOpen] = useState(false);

    const isChassisV3 = JSON.parse(getStorage("userBasicSettings"))?.isChassisV3;
    const isCompleted =
        completedStatus.indexOf(
            loadDetails.status
        ) > -1
    const isLoadDispatch = driverOrder.find((status) => !status.isVoidOut && status?.arrived)
    const isHook = driverOrder.find((order) => order.type === loadEvents.HOOKCONTAINER)
    const hasCombinedTrip = driverOrder.find((order) => order.combineTripId && !order?.isDualTransaction);
    const isDualTransaction = driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
    const allDriverLegs = createLegsFromDriverOrder(driverOrder, supportMoves)
    let supportMoveCount = allDriverLegs?.filter((d) => [TRIP_TYPES.SM_SYSTEM, TRIP_TYPES.SM_MANUAL].includes(d[0]?.tripType) || d[0]?.isSupportMove)?.length
    let containerMoveCount = allDriverLegs?.filter((d) => !d[0]?.isSupportMove)?.length
    let allMoveCount = allDriverLegs?.length
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}")
    const canUncomplete =
        ["COMPLETED", "UNAPPROVED"].indexOf(loadDetails.status) > -1 &&
        (!isNewBillingEnabled() ||
            (isNewBillingEnabled() &&
                ((billingConfiguration?.config?.isLoadCompletedForMainChargeInvoice &&
                    [LOAD_CHARGE_STATUS.UNAPPROVED, LOAD_CHARGE_STATUS.REBILLING, ""].includes(loadDetails?.chargeStatus)) ||
                    !billingConfiguration?.config?.isLoadCompletedForMainChargeInvoice)))

    useEffect(() => {
        if (loadDetails) {
            setIsGrayPool(loadDetails?.isGrayPool)

            const _Options = {
                isPrePullCharge: loadDetails.isPrePullCharge,
                isNextContainerNeedToPickup: loadDetails.isNextContainerNeedToPickup,
            }
            setOptions(_Options)
        }

        if (loadDetails.preSetOrderStatus) {
            setSelectedRoute(loadDetails.preSetOrderStatus)
        } else {
            setSelectedRoute("")
        }
        setLoadCompletedAt(loadDetails?.loadCompletedAt)
    }, [loadDetails?.isGrayPool, loadDetails?.isPrePullCharge, loadDetails?.isNextContainerNeedToPickup, loadDetails?.preSetOrderStatus, loadDetails?.loadCompletedAt])

    useEffect(() => {
        if (props.loadDetails?.driverOrder[0]?.driver && isChassisV3) {
            setCanChangeTemplate(true)
        } else {
            setCanChangeTemplate(false)
        }
    }, [props])

    const onSelectedRouteChange = async(selectedRoute, reset) => {
        // check with previous value if same than return
        const noChange = _.isEqual(originalLoad?.preSetOrderStatus, selectedRoute)
        if (noChange) return

        let _driverOrder = []
        if (!reset) {
            _driverOrder = driverOrder.filter((status) => status.isVoidOut || status.arrived || status.departed)
        }
        const _customer = {
            DROPCONTAINER: customer?.DROPCONTAINERCUSTOMER,
            HOOKCONTAINER: customer?.DROPCONTAINERCUSTOMER,
            DELIVERLOAD: customer?.DELIVERLOADCUSTOMER,
            RETURNCONTAINER: customer?.RETURNCONTAINERCUSTOMER,
        }

        const { newDriverOrder, isGrayPool } = createRoutingOnTemplateChange(
            _.cloneDeep(_driverOrder),
            _customer,
            loadDetails,
            selectedRoute,
            loadDetails?.driver
        )
        setSelectedRoute(selectedRoute)
        setDriverOrder(newDriverOrder)
        setIsGrayPool(isGrayPool)

        const payloadDriverOrder = newDriverOrder?.map(event => {
            const _event = { type: event.type }
            if(event?._id) _event._id = event?._id
            if(event?.customerId) _event.customerId = (event?.customerId?._id || event?.customerId)
            _event.isGrayPool = event?.isGrayPool
            return _event;
        })

        let payload = {
            loadId: loadDetails?._id,
            driverOrder: payloadDriverOrder,
            preSetOrderStatus: selectedRoute
        }

        const isValid = validateOrderStatus(newDriverOrder, loadDetails)

        if (!isValid) {
            setIsInvalidDriverOrder(true)
            return;
        }
        setLoading(true)
        
        manageEventOrder(payload)
            .then((result) => {
                setLoading(false)
                setIsInvalidDriverOrder(false)
            })
            .catch((err) => {
                setDriverOrder(loadDetails?.driverOrder)
                setIsInvalidDriverOrder(false)
                console.log("🚀 ~ file: index.jsx:429 ~ onSelectedRouteChange ~ err:", err)
                setLoading(false)
            })
    }

    const grayPoolUpdate = (value) => {
        setIsGrayPool(value)

        let payload = {
            loadId: loadDetails?._id,
            isGrayPool: value,
        }
        setLoading(true)
        updateGrayPool(payload)
            .then((result) => {
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log("🚀 ~ file: index.jsx:414 ~ preSetOrderStatusUpdate ~ err:", err)
            })
    }

    const updateOptions = (viewName, value) => {
        let _Options = _.cloneDeep(options)
        _Options[viewName] = value

        setOptions(_Options)

        let payload = {
            loadId: loadDetails?._id,
            [`${viewName}`]: value,
        }
        setLoading(true)
        updateLoadOptions(payload)
            .then((result) => {
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                console.log("🚀 ~ file: index.jsx:381 ~ updateOptions ~ err:", err)
            })
    }

    const removeAssignedDomesticMove = (reference_number, domesticLoadNo) => {
        const formData = new FormData()
        formData.append("reference_number", reference_number);
        formData.append("domesticLoadNo", domesticLoadNo);
        confirm("Remove Domestic Move?", `Are You sure to Remove Domestic Move?`, (confirm) => {
            if (confirm) {
                removeDomesticMove(formData).then(() => {
                    toastr.show("Removed Domestic Move!", "success")
                })
            }
        })
    }

    const removeExtraLeg = (index) => {
        let _driverOrder = _.cloneDeep(driverOrder)
        _driverOrder.splice(index)

        setDriverOrder(_driverOrder)
        const formDataOrder = new FormData()
        formDataOrder.append("reference_number", loadDetails && loadDetails.reference_number)
        formDataOrder.append("driverOrder", JSON.stringify(_driverOrder))
        formDataOrder.append("preSetOrderStatus", selectedRoute)
        formDataOrder.append("allowDriverCompletion", allowDriverCompletion ? true : false)

        formDataOrder.append("isPrePullCharge", loadDetails.isPrePullCharge ? true : false)
        formDataOrder.append("isNextContainerNeedToPickup", loadDetails.isNextContainerNeedToPickup ? true : false)
        formDataOrder.append("isCompleteLoad", true)
        setLoadStatusOrder(formDataOrder).then(() => {
            setOpenModalRefNumber(loadDetails?.streetTurn)
            setShowLoadModel(true)
        })
    }

    const loadComplete = () => {
        let _driverOrder = _.cloneDeep(driverOrder)

        if (!validateOrderStatus(_driverOrder, loadDetails)) return
        let inValid = _driverOrder.find((status) => !status.driver && !status.drayosCarrier);
        if (inValid) return toastr.show(`A ${getDriverAndCarrierName()} is required.`, "error");
        const params = {
            loadId: loadDetails?._id,
        }
        setLoading(true)

        completeLoad(params)
            .then((res) => {
                let eventProperties = {
                    source: 'web'
                }
                amplitudeTrack('COMPLETED_LOAD', eventProperties)
                toastr.show("Completed!", "success")
                setLoading(false)
            })
            .catch((err) => {
                toastr.show("The update has failed. Please try again.", "error")
                setLoading(false)
            })
    }

    const changeLoadCompletedDate = () => {
      const noChange = _.isEqual(originalLoad?.loadCompletedAt, loadCompletedAt)
      if (noChange) return
      if (loadCompletedAt) {
        const params = {
          loadId: loadDetails?._id,
          loadCompletedAt: loadCompletedAt,
        }
        updateCompleteLoadDate(params)
          .then((res) => {
            toastr.show("You have updated load completed date successfully.", "success")
          })
          .catch((err) => {
            console.log(err)
            toastr.show("The update has failed.  Please try again.", "error")
            setLoadCompletedAt(loadDetails?.loadCompletedAt)
          })
      } else {
        toastr.show("Enter Completed Date", "error")
        return
      }
    }

    const loadUncomplete = () => {
      const params = {
        loadId: loadDetails?._id,
      }
      setLoading(true)
      uncompleteLoad(params)
        .then((res) => {
          toastr.show("You have uncompleted this load.", "success")
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }

    const driverMoves = movesFromDriverOrder(driverOrder)
    const unassignedMoves = driverMoves?.map(move => {
        const _load = _.cloneDeep(loadDetails)
        _load.driverOrder = move;
        return _load
    })?.filter(move => {
        const event = move?.driverOrder?.find(x => x?.driver || x?.drayosCarrier || x?.combineTripId || x?.orderId)
        return !event;
    }) || []
  
    return (
        <>
            <div className="col fcpopup__sidebar px-0 h-100 overflow-y-auto custom-scrollbar-sm" style={{ maxWidth: 210 }}>
                <div className="card p-10 mb-10">
                    {loadDetails?.createSource !== createSource.BROKER_TENDER && !showEvent && !isLoadDispatch && (
                        <React.Fragment>
                            <div className="form-group mb-0">
                                <label>Routing Template</label>
                                <Select
                                    name="loadRoute"
                                    options={getPreSetOrderStatus()}
                                    value={getPreSetOrderStatus().find((val) => val.value === selectedRoute)}
                                    onChange={(value) => onSelectedRouteChange(value.value, true)}
                                    className="w-100 loadRoute"
                                    styles={newXlSelectStyle}
                                    isDisabled={
                                        (isChassisV3 && canChangeTemplate) ||
                                        hasCombinedTrip || isTenderHaveLoadRef || isDualTransaction
                                    }
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className="form-group mb-0 mt-3">
                        <div className="hide-menu d-flex mb-3">
                            <div className="hr-light w-15" style={{margin: "7px 0px"}}></div>
                                <span className="bg-white px-1 text-muted">Drag & Drop Events</span> 
                            <div className="hr-light w-15" style={{margin: "7px 0px"}}></div>
                        </div>
                        {isNewSupportMovesEnabled() && (<label className="text-muted-400 mb-10">Container</label>)}
                        {ROUTING_DRAG_LEGS.map((dLeg, dIndex) => {
                            if (
                                loadDetails?.type_of_load === ROAD_LOAD_TYPE &&
                                [loadEvents.CHASSISPICK, loadEvents.RETURNCONTAINER, loadEvents.CHASSISTERMINATION].includes(dLeg.leg)
                            ) {
                                return
                            }
                            if ([loadEvents.STOPOFF].includes(dLeg.leg) && !userBasicSettings?.isStopOff) {
                                return
                            }
                            if (isNewSupportMovesEnabled() && [loadEvents.CHASSISTERMINATION, loadEvents.CHASSISPICK].includes(dLeg.leg)) return;
                            if (!showEvent || showEvent === dLeg.leg)
                                return (
                                    <button
                                        className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                                        id={dLeg.leg}
                                        draggable
                                        key={dIndex}
                                        onDragStart={handleDragStart}
                                        onDragOver={handleDragOver}
                                        onDragEnd={() => hadleonDragEnd()}
                                    >
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <span>
                                            {isTerminalTrackingEnabled() && loadDetails?.type_of_load === EXPORT_LOAD_TYPE
                                                ? TERMINALTRACKING[dLeg?.leg] || dLeg?.legTitle
                                                : dLeg?.legTitle
                                            }
                                        </span>
                                    </button>
                                )
                        })}
                    </div>
                    {isNewSupportMovesEnabled() && 
                    <div className="form-group mb-0 mt-10">
                        <label className="text-purple-400 mb-10">Support</label>

                        {ROUTING_SUPPORT_DRAG_LEGS.map((dLeg, dIndex) => {
                            return (
                                <button
                                    key={dIndex}
                                    className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable border-purple-50"
                                    id={dLeg.leg}
                                    draggable={!isTenderHaveLoadRef}
                                    onDragStart={handleDragStart}
                                    onDragOver={handleDragOver}
                                    onDragEnd={() => hadleonDragEnd()}
                                    disabled={isTenderHaveLoadRef}
                                >
                                    <IconDrag className="mr-10 text-gray-200" />
                                    <span>{dLeg.legTitle}</span>
                                </button>
                            )
                        })}

                    </div>
                }
                </div>
                
                {!showEvent && <div className="card m-0">
                    {loadDetails && <div className="pt-10 px-10">
                        {loadDetails?.domestic && !loadDetails?.domesticLoadNo && 
                         !['COMPLETED', 'APPROVED', 'BILLING', 'UNAPPROVED', 'REBILLING', 'PARTIAL_PAID', 'FULL_PAID'].includes(loadDetails.status) &&
                            [LOAD_TYPES.IMPORT, LOAD_TYPES.EXPORT].includes(loadDetails?.type_of_load) && (
                                <React.Fragment>
                                    <label>Domestic Move</label>
                                    <DomesticMove
                                        selectedLoads={loadDetails}
                                    />
                                    <div className="hr-light"></div>
                                </React.Fragment>
                            )}
                            {loadDetails?.domesticLoadNo && (
                            <div className="">
                                <h4 className="tre d-flex flex-wrap mt-2 mb-2">
                                    Assigned Domestic Move{" "}
                                    <span className="badge mt-1 badge-info badge-rounded text-uppercase font-medium">
                                        {loadDetails.domesticLoadNo}
                                    </span>
                                </h4>
                                <button
                                    className="btn btn-danger btn-sm btn-block"
                                    onClick={() => removeAssignedDomesticMove(loadDetails.reference_number, loadDetails.domesticLoadNo)}
                                >
                                    Remove Domestic Move
                                </button>
                                <div className="hr-light"></div>
                            </div>
                        )}
                        {loadDetails &&
                            loadDetails.type_of_load === IMPORT_LOAD_TYPE &&
                            loadDetails.containerOwner && (
                              <NewStreetTurn 
                                loadDetails={loadDetails}
                                removeExtraLeg={removeExtraLeg}
                                setShowLoadModel={setShowLoadModel}
                                setOpenModalRefNumber={(reference_number) => {
                                    setShowLoadModel(true);
                                    _setOpenModalRefNumber ? _setOpenModalRefNumber(reference_number) : setOpenModalRefNumber(reference_number)
                                }}
                              />
                            )}

                        {loadDetails &&
                            loadDetails.type_of_load === EXPORT_LOAD_TYPE &&
                            loadDetails.streetTurn && (
                              <NewStreetTurn 
                                loadDetails={loadDetails}
                                removeExtraLeg={removeExtraLeg}
                                setShowLoadModel={setShowLoadModel}
                                setOpenModalRefNumber={(reference_number) => {
                                    setShowLoadModel(true);
                                    setOpenModalRefNumber(reference_number)
                                    _setOpenModalRefNumber ? _setOpenModalRefNumber(reference_number) : setOpenModalRefNumber(reference_number)
                                }}
                              />
                            )}
                        {loadDetails && loadDetails.bobTail && (
                            <div className="">
                                <h4 className="tre">
                                    BobTail
                                    {loadDetails.bobTail && loadDetails.bobTail.driver && (
                                        <span className="badge badge-info badge-rounded text-uppercase font-medium">{` ${loadDetails.bobTail.driver.name} ${loadDetails.bobTail.driver.lastName}`}</span>
                                    )}
                                </h4>
                            </div>
                        )}
                    </div>}
                    {isCombineTripEnabled() && unassignedMoves?.length > 0 &&
                        <>
                            <div className="form-group px-10 mb-0">
                                <div>
                                    <button className="btn btn-outline-light w-100" onClick={()=>{
                                        setIsTripModalOpen(true)
                                    }}>
                                        <IconPlus /> Create Trip
                                    </button>
                                </div>
                            </div>
                            <div className="hr-light"></div>
                        </>
                    }
                    {isTripModalOpen && (
                      <CombineTripCreateModal
                        onCancel={() => {
                            setIsTripModalOpen(false)
                        }}
                        onSubmit={()=>{
                            setIsTripModalOpen(false)
                        }}
                        step1Moves={unassignedMoves}
                      />
                    )}
                    <div className="form-group px-10 mb-0">
                        <label className="mb-15">Options</label>
                        <div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isPrePullCharge"
                                    checked={options?.isPrePullCharge}
                                    value={1}
                                    onChange={(e) => {
                                        updateOptions("isPrePullCharge", e.target.checked)
                                    }}
                                    disabled={isCompleted}
                                />
                                <label className="custom-control-label cstm-label" htmlFor="isPrePullCharge">
                                    Add Pre Pull Charge
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mb-2">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="isNextContainerNeedToPickup"
                                    name="isNextContainerNeedToPickup"
                                    checked={options?.isNextContainerNeedToPickup}
                                    value={1}
                                    onChange={(e) => {
                                        updateOptions("isNextContainerNeedToPickup", e.target.checked)
                                    }}
                                    disabled={isCompleted}
                                />
                                <label className="custom-control-label cstm-label" htmlFor="isNextContainerNeedToPickup">
                                    Driver Select Container
                                </label>
                            </div>
                            {isHook && (
                                <div className="custom-control custom-checkbox mb-2">
                                    <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="isGrayPool"
                                        name="isGrayPool"
                                        checked={isGrayPool}
                                        value={1}
                                        onChange={(e) => {
                                            grayPoolUpdate(e.target.checked)
                                        }}
                                        disabled={isCompleted}
                                    />
                                    <label className="custom-control-label cstm-label" htmlFor="isGrayPool">
                                        Add Gray Pool
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>

                    {supportMoveEnabled() && (
                        <>
                            <div className="hr-light"></div>
                            <div className="form-group px-10">
                                <label className="mb-15">Views</label>
                                <div>
                                    <div className="custom-control custom-checkbox mb-2">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="allMoves"
                                            checked={!selectedMoveFilter}
                                            onChange={(e) => handleSelectMoveFilter(null)}
                                        />
                                        <label className="custom-control-label" htmlFor="allMoves">
                                            All Moves
                                            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{allMoveCount}</span>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox mb-2">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="containerMove"
                                            checked={selectedMoveFilter === "containerMove"}
                                            onChange={(e) => handleSelectMoveFilter("containerMove")}
                                        />
                                        <label className="custom-control-label" htmlFor="containerMove">
                                            Container Moves
                                            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{containerMoveCount}</span>
                                        </label>
                                    </div>
                                    <div className="custom-control custom-checkbox mb-2">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="supportMove"
                                            checked={selectedMoveFilter === "supportMove"}
                                            onChange={(e) => handleSelectMoveFilter("supportMove")}
                                        />
                                        <label className="custom-control-label" htmlFor="supportMove">
                                            Support Moves
                                            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{supportMoveCount}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="hr-light m-0"></div>
                    <div className="p-10 moreinfo-card">
                        {!isCompleted && (
                            <button
                                className="btn btn-outline-error-100 btn-block"
                                disabled={isCompleted || isInvalidDriverOrder} // TODO: Add !disabled ||
                                onClick={() => loadComplete()}
                            >
                                Complete Load
                            </button>
                        )}

                        {canUncomplete && (
                            <React.Fragment>
                                <span className="text-dark font-weight-500 mr-3">Load Completed Date</span>
                                {loadCompletedAt && (
                                    <div className="input-wrapper outline-btn-success mt-1 mb-10">
                                        <div className="btn-outline-success-100">
                                            <DateWrapper
                                                dateFormat="MM-DD-yyyy"
                                                timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                OnBlurUpdate={() => changeLoadCompletedDate()}
                                                onChange={(date) => setLoadCompletedAt(moment(date).toISOString())}
                                                displayTimeZone={getStorage("timeZone")}
                                                isShowTime={true}
                                                defaultDate={loadCompletedAt ? moment(loadCompletedAt) : ""} 
                                                hideShowSelectDate={true}
                                            />
                                            <div className="input-icon" onClick={() => changeLoadCompletedDate()}>
                                                <IconCheck />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <button className="btn btn-outline-error-100 btn-block" onClick={() => loadUncomplete()}>
                                    Uncomplete Load
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                </div>}
            </div>
            {showLoadModel && openModalRefNumber && (
                <LoadInfoModal
                    refNumber={openModalRefNumber}
                    hideLoadModal={() => {
                        setShowLoadModel(false);
                        setOpenModalRefNumber(null);
                    }
                }
                    _setOpenModalRefNumber={setOpenModalRefNumber}
                />
            )}
        </>
    )
}

export default RoutingHelperSection
