import React, { useEffect, useState } from "react";
import { getInvoicesWithPayment } from "../Billing/actionCreator";
import InvoicePayments from "./InvoicePayments";
import { LoaderBar } from "Components/Common/LoaderBar";
import { checkAccountReceivablePermission } from "services";

const PaymentsCreditsExtended = (props) => {

  const [allInvoiceData, setAllInvoiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const { loadApplayPaymentTabPermission, applyPaymentInvoicePermission } = checkAccountReceivablePermission();

  const getInvoiceAndPaymentData = (setDeleteDisable) => {
    const loadId = props.loads._id;
    const isGenerateSignUrl = true;

    getInvoicesWithPayment(loadId, isGenerateSignUrl).then(result => {
      setAllInvoiceData(result.data)
      setDeleteDisable && setDeleteDisable(false)
    }).catch(err => console.log("errr", err))
      .finally(() => setIsLoading(false))
  }
  useEffect(() => {
    setIsLoading(true)
    getInvoiceAndPaymentData()
  }, [])
  return (
    <>
      {loadApplayPaymentTabPermission ? <div className="position-relative">
        {isLoading && <LoaderBar></LoaderBar>}
        {(allInvoiceData?.length > 0) ? allInvoiceData.map((invoice, index) => {
          return (<div>
            <InvoicePayments
              {...props}
              key={index}
              invoice={invoice}
              getInvoiceAndPaymentData={getInvoiceAndPaymentData}
              applyPaymentCreditDisable={!applyPaymentInvoicePermission}
            />
          </div>)
        }) : <div className="card mt-10 py-40 text-center px-20 text-capitalize"><span className='text-muted'>{"There are no invoices referencing this load."}</span></div>}
      </div> : <div className="nopermission text-capitalize"><h1>You do not have permission to view this page</h1></div>}
    </>
  )
};

export default PaymentsCreditsExtended;
