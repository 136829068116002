import { IconAngleArrowTop, IconInfoDarkI, IconLongArrowRight, IconMinus, IconPlus } from "Components/Common/Icons"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { fleetKeysToUpdate } from "../../constant"
import { updatePeople } from "pages/tms/People/actionCreator"
import _ from "lodash"
import { displayName, isManageFleetOrOutSource, isNewBillingEnabled, toastr } from "services"
import Select from 'react-select';
import { smallSelectStyle } from "assets/js/select-style"
import { getStorage } from "../../../../../services"
import NumberFormat from "react-number-format";
import { convertS3ToCloudFrontURL } from "../../../People/constants"
import { IconInfo } from "../../../../../Components/Common/Icons"
import ReactTooltip from "react-tooltip";

const Permissions = {
    customer_shipments: "customer_shipments",
    customer_employee_load: [
        "customer_employee_load_info",
        "customer_employee_load_billing",
        "customer_employee_load_documents",
        "customer_employee_load_upload_documents",
        "customer_employee_load_payments",
        "customer_employee_load_tracking",
        "customer_employee_load_messaging",
        "customer_employee_load_summary",
    ],
    dropped_containers: "dropped_containers",
    account_payable: "account_payable",
    customer: "customer",
    reports: "reports",
    customer_quotes: "customer_quotes"
};

const employeePermissionList = [
    { label: "Loads", value: "customer_employee_load" },
    { label: "Dropped container", value: "dropped_containers" },
    { label: "Accounts payable", value: "account_payable" },
    { label: "Info", value: "customer_employee_load_info" },
    { label: "Billing", value: "customer_employee_load_billing" },
    { label: "Documents", value: "customer_employee_load_documents" },
    { label: "Document Upload", value: "customer_employee_load_upload_documents" },
    { label: "Summary", value: "customer_employee_load_summary" },
    { label: "Payments", value: "customer_employee_load_payments" },
    { label: "Tracking", value: "customer_employee_load_tracking" },
    { label: "Service Messaging", value: "customer_employee_load_messaging" },
    { label: "Tracking", value: "customer_shipments" },
    { label: "Customer", value: "customer" },
    { label: "Reports", value: "reports" },
    { label: "Reports Customer Revenue", value: "reports_customer_revenue" },
    { label: "Reports Aging", value: "reports_aging" },
    { label: "Reports Driver Revenue", value: "reports_driver_revenue" },
    { label: "Reports Status Driver", value: "reports_status_driver" },
    { label: "Reports Clock Report", value: "reports_clock_report" },
    { label: "Customer Quotes", value: "customer_quotes" }
];

const MatchDetailsModal = ({ show, hide, selectedFleet, personData, closeInsight, closeExisting, getEmailPeople, isExternal, isEmailConnected, threadId }) => {
    const [selectedFleetData, setSelectedFleetData] = useState(selectedFleet)
    const [newPersonData, setNewPersonData] = useState(personData)
    const [newMobileNumbers, setNewMobileNumbers] = useState(newPersonData?.mobileNumbers?.length > 0 ? newPersonData?.mobileNumbers : [{mobile: "", label: ""}])
    const [newAlternativeEmails, setNewAlternativeEmails] = useState(newPersonData?.alternativeEmailAddresses?.length > 0 ? newPersonData?.alternativeEmailAddresses : [{email: "", label: ""}])
    const [isShowNewData, setIsShowNewData] = useState(false);    
    const [isLoader, setIsLoader] = useState(false);
    const mobileObj = {}
    const emailObj = {}
    newMobileNumbers?.forEach((mob, i) => {
        mobileObj[`mobile-${i}`] = 1
    })
    newAlternativeEmails?.forEach((mob, i) => {
        emailObj[`alternativeEmail-${i}`] = 1
    })
    const [considerData, setConsiderData] = useState({
        title: 1,
        firstName: 1,
        lastName: 1,
        profileUrl: 1,
        ...mobileObj,
        ...emailObj
    })

    useEffect(() => {
        if(!selectedFleetData?.email){
            handleAutoEmailGenerate()
        }
    }, [])
    const updateFleetManagerDetails = () => {
        const _id = selectedFleet?._id
        const payload = {
            _id: _id,
            ...(considerData.title && { title: newPersonData.title }),
            ...(considerData.firstName && { firstName: newPersonData.firstName }),
            ...(considerData.lastName && { lastName: newPersonData.lastName }),
            ...(considerData.profileUrl && { profileUrl: newPersonData.profileUrl }),
        }

        const mobilesToConsider = extractMobileAndMail(considerData, 'mobile-');
        const mailsToConsider = extractMobileAndMail(considerData, 'alternativeEmail-');

        const newPersonAltMails = newAlternativeEmails?.filter((mail, i) => mailsToConsider.includes(i))
        const newPersonMobiles = newMobileNumbers?.filter((mail, i) => mobilesToConsider.includes(i))

        if(newPersonAltMails?.length){
            const alternativeEmailAddresses = selectedFleet?.alternativeEmailAddresses?.length > 0 ? [ ...selectedFleet?.alternativeEmailAddresses].concat(newPersonAltMails.filter((d) => d.email|| d.label)) : newPersonAltMails.filter((d) => d.email || d.label)
            payload.alternativeEmailAddresses = alternativeEmailAddresses
        }
        if(newPersonMobiles?.length){
            const mobileNumbers = [ ...selectedFleet?.mobileNumbers].concat(newPersonMobiles.filter((d) => d.mobile))
            payload.mobileNumbers = mobileNumbers
        }

        const mappedFleet = mapFleetObj(selectedFleet);
        
        Object.keys(payload).map((key) =>{
            mappedFleet[key] = payload[key];
        })
        mappedFleet.peopleEmail = newPersonData.email
        if(isExternal) mappedFleet.isExternalEmail = isExternal
        if(!isEmailConnected) mappedFleet.skipMiddleWare = true
        if(threadId) mappedFleet.threadId = threadId
        if(!selectedFleet?.organizationDetails?.id) mappedFleet.addWithOutOrganization = true
        mappedFleet.skipMobileCheck = true
        setIsLoader(true);
        updatePeople(mappedFleet).then(() => {
            toastr.show("Person updated successfully.", "success");
            closeInsight()
            closeExisting()
            getEmailPeople(null, isExternal)
            setIsLoader(false);
        }).catch((err) => {
            setIsLoader(false);
        })
    }

    const mapFleetObj = (fleet) => {
        const newFleet = {};
        fleetKeysToUpdate.forEach((key) => {
            if(key === "CustomerID"){
                newFleet[key] = fleet?.organizationDetails?.id
                newFleet["isCustomer"] = true

            }
            else if(fleet?.hasOwnProperty(key)){
                newFleet[key] = fleet[key]
            }
        })
        return newFleet
    }

    function extractMobileAndMail(obj, prefix) {
        let numbers = [];
        for (let key in obj) {
            if (key.startsWith(prefix) && obj[key]) {
                numbers.push(parseInt(key.split('-')[1]));
            }
        }
        return numbers;
    }

    const renderProfileImage = (data) => {
        if (data?.profileUrl) {
            return (
                <img
                    src={convertS3ToCloudFrontURL(data.profileUrl)}
                    className="avatar rounded-circle"
                    alt=""
                    width={30}
                />
            );
        } else if (data?.firstName) {
            return data.lastName ? `${data?.firstName?.[0]}${data?.lastName?.[0]}` : displayName(data?.firstName);
        } else {
            return "PN";
        }
    };

    const onInputChange = (key, value) => {
        setNewPersonData(prevState => ({
            ...prevState,
            [key]: value
          }));
    }


    const renderInputRow = (label, selectedValue, newValue, name) => (
        <div className="form-row-md align-items-end mt-2">
            <div className="col-md">
                <label>{label}</label>
                <input type="text" className="form-control" value={selectedValue} disabled />
            </div>
            <div className="col-md" style={{ maxWidth: 45 }}>
                <IconLongArrowRight className="text-muted my-1 wh-30px" />
            </div>
            <div className="col-md">
                <label></label>
                <input type="text" className="form-control" value={newValue} onChange={(e) => onInputChange(name, e.target.value)}/>
            </div>
            <div className="col-md" style={{ maxWidth: 145 }}>
                <RadioButtonSelect name={name} label={label === "First Name" ? "Replace?" : null} checked={considerData[name]} onChange={onReplaceChange} />
            </div>
        </div>
    );
    

    const onReplaceChange = (name, value) => {
        setConsiderData({ ...considerData, [name]: value })
    }

    const ConvertObjectintoArrays = (permissionList = []) => {
		let temp = [];
		Object.keys(Permissions).forEach((permission) => {
			if (Array.isArray(Permissions[permission])) {
				let final = [];
				Permissions[permission].forEach(elem => {
					final.push({
						values: elem,
						checked: permissionList.includes(elem) ? true : false
					})
				})
				temp.push({ values: permission, checked: permissionList.includes(permission) ? true : false, children: final });
			} else {
				temp.push({
					values: permission,
					checked: permissionList.includes(permission) ? true : false,
				})
			}
		})
		return temp
	}

    const addMobileNumbers = () => {
		let data = [...newMobileNumbers]
		data.push({mobile: "", label: ""})
		setNewMobileNumbers(data);
	}
    const addAleternativeEmails = () => {
        let data = [...newAlternativeEmails]
        data.push({ email: "", label: "" })
        setNewAlternativeEmails(data);
    }

    const handleRemoveMobileNumbers = (index) => {
		let data = newMobileNumbers?.filter((d, i) => i !== index)
		setNewMobileNumbers(data);
	}

    const handleChangeMobileNumbers = (event, index, key) => {
		let mobileNumbersData = newMobileNumbers;
		mobileNumbersData = mobileNumbersData.map((d, i) => {
			if (index === i) {
				if (key === "label") d.label = event.target.value
				if (key === "mobile") d.mobile = event.target.value
			}
			return d
		})
		setNewMobileNumbers(mobileNumbersData);
	}

    const handleChangeAlternativeEmail = (event, index, key) => {
        let alternativeEmailsData = newAlternativeEmails;
        alternativeEmailsData = alternativeEmailsData.map((d, i) => {
            if (index === i) {
                if (key === "label") d.label = event.target.value
                if (key === "email") d.email = event.target.value
            }
            return d
        })
        setNewAlternativeEmails(alternativeEmailsData);
    }

    const handleRemoveAlternativeEmail = (index) => {
        let data = newAlternativeEmails?.filter((d, i) => i !== index)
        setNewAlternativeEmails(data);
    }

    const handleAutoEmailGenerate = () => {
        let _email = ""
        const chars = 'abcdefghijklmnopqrstuvwxyz1234567890';
        let string = '';
        for (let ii = 0; ii < 15; ii++) {
            string += chars[Math.floor(Math.random() * chars.length)];
            _email = `${string}@portpro.io`;
        }
        setSelectedFleetData({...selectedFleetData, email: _email})
    }

    return (
        <>
            <Modal show={show} dialogClassName="modal-lg modal-dialog-scrollable" backdropClassName="z-1050">
                <Modal.Header className="border-bottom-1 border-gray-100">
                    <Modal.Title className="w-100"><div className="d-flex justify-content-between align-items-center"><span>Match Person Details</span>
                    <div className="d-flex align-item-center">
                        <div className="form-check form-switch form-switch-md">
                            <input
                                type="checkbox"
                                className={`form-check-input`}
                                onChange={() => setIsShowNewData(!isShowNewData)}
                            />
                            <label className="form-check-label font-12 font-weight-500" for="Available">
                            Show Only New data
                            </label>
                        </div>
                    </div>
                    </div></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row-md mt-10">
                        <div className="col-md">
                            <div className="rounded-5 bg-white  w-90px">
                                <div className="bg-primary-200 rounded-5 p-15 d-flex align-items-center justify-content-center">
                                    <div className="avatar-circle avatar-lg text-uppercase wh-60px border-2 text-white">
                                        <span className="text-dark">{renderProfileImage(selectedFleetData)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md" style={{ maxWidth: 45 }}>
                            <IconLongArrowRight className="text-muted my-1 wh-30px" />
                        </div>
                        <div className="col-md">
                            <div className="rounded-5 bg-white  w-90px">
                                <div className="bg-primary-200 rounded-5 p-15 d-flex align-items-center justify-content-center">
                                    <div className="avatar-circle avatar-lg text-uppercase wh-60px border-2 text-white">
                                        <span className="text-dark">{renderProfileImage(newPersonData)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md" style={{ maxWidth: 145 }}>
                            <RadioButtonSelect name={"profileUrl"} label="Replace?" checked={considerData["profileUrl"]} onChange={onReplaceChange} />
                        </div>
                    </div>
                    <div className="hr-light" />
                    <div className="d-flex flex-column gap-15">
                        {renderInputRow("First Name", selectedFleetData?.firstName, newPersonData?.firstName, "firstName")}
                    </div>
                    <div className="d-flex flex-column gap-15">
                        {renderInputRow("Last Name", selectedFleetData?.lastName, newPersonData?.lastName, "lastName")}
                    </div>
                    <div className="d-flex flex-column gap-15">
                        {renderInputRow("Title", selectedFleetData?.title, newPersonData?.title, "title")}
                    </div>
                    <div className="hr-light" />
                    {!isShowNewData && selectedFleetData?.mobileNumbers?.length > 0 && <div className="form-row-md align-items-end">
                        <div className="col-md">
                            <label>Phone</label>
                        </div>
                        <div className="col-md">
                            <label>Label</label>
                        </div>
                    </div>}
                    {!isShowNewData && selectedFleetData?.mobileNumbers?.length > 0 && selectedFleetData?.mobileNumbers?.map((mobile) => {
                        return <div className="form-row-md align-items-end mt-2">
                            <div className="col-md">
                                <input type="number" className="form-control" value={mobile?.mobile} disabled />
                            </div>
                            <div className="col-md">
                                <input type="text" className="form-control" value={mobile?.label} disabled />
                            </div>
                        </div>
                    })}

                    <div className="form-row-md align-items-end mt-2">
                        <div className="col-md">
                            <label>Phone</label>
                        </div>
                        <div className="col-md">
                            <label>Label</label>
                        </div>
                        <div className="col-md" style={{ maxWidth: 145 }}>
                            Add New?
                        </div>
                    </div>
                    {newMobileNumbers?.map((mobile, i) => { 
                        return <>
                            <div className="form-row-md align-items-end mt-2">
                                <div className="col-md">
                                    {JSON.parse(getStorage("userBasicSettings"))?.phoneFormat === 'international' ?
                                        <div>
                                            <input
                                                name="mobile"
                                                mask="_"
                                                className="form-control"
                                                value={mobile?.mobile}
                                                placeholder={"Enter Phone Number"}
                                                type="text"
                                                onChange={(e) => handleChangeMobileNumbers(e, i, "mobile")}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <NumberFormat
                                                name="mobile"
                                                format="###-###-####"
                                                mask="_"
                                                className="form-control"
                                                value={mobile?.mobile}
                                                placeholder={"Enter Phone Number"}
                                                type="text"
                                                onChange={(e) => handleChangeMobileNumbers(e, i, "mobile")}
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="col-md">
                                    <input type="text" className="form-control" value={mobile?.label} onChange={(e) => handleChangeMobileNumbers(e, i, "label")} />
                                </div>
                                <div className="col-md" style={{ maxWidth: 145 }}>
                                    <RadioButtonSelect name={`mobile-${i}`} checked={considerData[`mobile-${i}`]} onChange={onReplaceChange} />
                                </div>
                                {newMobileNumbers?.length !== 1 && (
                                    <div
                                        className="btn btn-circle btn-xs bg-soft-danger ml-20 mb-2 pointer"
                                        onClick={() => handleRemoveMobileNumbers(i)}
                                    >
                                        <IconMinus />
                                    </div>
                                )}
                            </div>
                        </>
                    })}
                    <button 
                        className="btn-sm bg-white border-0 text-primary pointer mt-10 d-flex align-items-center" 
                        onClick={() => {
                            addMobileNumbers()
                            onReplaceChange(`mobile-${newMobileNumbers?.length}`, 1)
                        }}
                    >
                        <IconPlus className="mr-2" />
                        Add Phone
                    </button>
                    {!isShowNewData && <div className="form-row-lg mt-2">
                        <div className="col-md-6 form-group">
                            <label>Primary Email</label>
                            <input type="text" className="form-control" value={selectedFleetData?.email} disabled />
                        </div>
                        <div className="col-md-6 form-group">
                            <label>Label</label>
                            <input type="text" className="form-control" value={selectedFleetData?.primaryEmailLabel} disabled />
                        </div>
                    </div>}
                    {!isShowNewData && <div className="">
                        <label>Notification Email</label>
                        <input type="text" className="form-control" value={selectedFleetData?.billingEmail} disabled />
                    </div>}

                    {!isShowNewData && selectedFleetData?.alternativeEmailAddresses?.length > 0 &&<div className="form-row-md align-items-end mt-2">
                        <div className="col-md">
                            <label>Alternative Emails</label>
                        </div>
                        <div className="col-md">
                            <label>Label</label>
                        </div>
                    </div>}
                    {!isShowNewData && selectedFleetData?.alternativeEmailAddresses?.length > 0 && selectedFleetData?.alternativeEmailAddresses?.map((email, i) => {
                        return <>
                            <div className="form-row-md align-items-end mt-2">
                                <div className="col-md">
                                    <input type="email" className="form-control" value={email?.email || ""} disabled />
                                </div>
                                <div className="col-md">
                                    <input type="text" className="form-control" value={email?.label} disabled/>
                                </div>
                            </div>
                        </>
                    })}


                    <div className="form-row-md align-items-end mt-2">
                        <div className="col-md">
                            <label>Alternative Emails</label>
                        </div>
                        <div className="col-md">
                            <label>Label</label>
                        </div>
                        <div className="col-md" style={{ maxWidth: 145 }}>
                            Add New?
                        </div>
                    </div>
                    {newAlternativeEmails?.map((email, i) => {
                        return <>
                            <div className="form-row-md align-items-end mt-2">
                                <div className="col-md">
                                    <input type="email" className="form-control" value={email?.email} onChange={(e) => handleChangeAlternativeEmail(e, i, "email")} />
                                </div>
                                <div className="col-md">
                                    <input type="text" className="form-control" value={email?.label} onChange={(e) =>  handleChangeAlternativeEmail(e, i, "label")}/>
                                </div>
                                <div className="col-md" style={{ maxWidth: 145 }}>
                                    <RadioButtonSelect name={`alternativeEmail-${i}`} checked={considerData[`alternativeEmail-${i}`]} onChange={onReplaceChange} />
                                </div>
                                {newAlternativeEmails?.length !== 1 && (
                                    <div
                                        className="btn btn-circle btn-xs bg-soft-danger ml-20 mb-2 pointer"
                                        onClick={() => handleRemoveAlternativeEmail(i)}
                                    >
                                        <IconMinus />
                                    </div>
                                )}
                            </div>
                        </>
                    })}
                    <button 
                        className="btn-sm bg-white border-0 text-primary pointer mt-10 d-flex align-items-center"
                        onClick={() => {
                            addAleternativeEmails()
                            onReplaceChange(`alternativeEmail-${newAlternativeEmails?.length}`, 1)
                        }}
                    >
                        <IconPlus className="mr-2" />
                        Add Alternative Email
                    </button>
                     <div className="hr-light" />
                    {!isShowNewData && <div className="">
                        <label>Organization</label>
                        <Select 
                            className="mb-10"
                            styles={smallSelectStyle}
                            isDisabled
                            value={{label: selectedFleetData?.organizationDetails?.company_name, value: selectedFleetData?.organizationDetails?.id}}
                        />
                    </div>}
                    <div className="mt-1">
                        <div className="d-flex align-items-center mb-1">
                            <label className="mb-0">Portal Login</label>
                            <div
                                className={`pointer ml-1`}
                                data-tip
                                data-for={"email"}
                            >
                                <IconInfoDarkI className="text-gray-100" />
                            </div>
                            <ReactTooltip
                                className={`w-300`}
                                place="top"
                                effect="solid"
                                id={"email"}
                            ><span>{selectedFleetData?.email}</span>
                            </ReactTooltip>
                        </div>
                        <input 
                            type="text" 
                            className="form-control" 
                            value={selectedFleetData?.email} 
                            disabled 
                        />
                    </div>
                    {!isShowNewData && <div className="hr-light" />}
                    {!isShowNewData && <div className="d-flex flex-column gap-15 mb-2">
                        <div className="d-flex align-items-center">
                            <IconAngleArrowTop className="mr-2" />
                            <div className="font-14 line-height-25 font-medium">Portal Permission</div>
                        </div>

                        {ConvertObjectintoArrays(selectedFleetData?.permissions)?.map((permission, key) => {
                            if (!isNewBillingEnabled() && permission.values === 'account_payable_invoices') return <></>
                            if (isManageFleetOrOutSource()?.isOutSource && ["customer_shipments"].includes(permission.values)) return <></>
                            return (
                                <div className="" key={key}>
                                    <div className="form-check">
                                        <input
                                            name="permissions"
                                            id={`check${key}_${permission.values
                                                }`}
                                            checked={permission.checked}
                                            type="checkbox"
                                            className="form-check-input"
                                            data-userId={selectedFleetData?._id || ""}
                                            disabled={true}
                                        />
                                        <label className="form-check-label text-capitalize" for="tracking">
                                            {employeePermissionList.find(item => item.value === permission.values) && employeePermissionList.find(item => item.value === permission.values).label}
                                        </label>
                                    </div>
                                    {permission.children && permission.children.length > 0 && (
                                        <div className="bg-gray-50 rounded-5 p-10 mt-2">
                                            <div className="form-row-md ">
                                                {permission.children.map((d, index) => {
                                                    return (
                                                        <div className="col-lg-4 col-md-6 mb-10">
                                                            <div className="form-check" key={`${index}-children`}>
                                                                <input
                                                                    name="permissions"
                                                                    id={`check${index + "_" + key
                                                                        }_${d.values}`}
                                                                    checked={d.checked}
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    data-userId={selectedFleetData?._id || ""}
                                                                    disabled={true}
                                                                />
                                                                <label className="form-check-label"
                                                                    htmlFor={`check${index + "_" + key}_${d.values}`}
                                                                >
                                                                    {employeePermissionList.find(item => item.value === d.values) && employeePermissionList.find(item => item.value === d.values).label}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>}
                    {/* <div className="d-flex flex-column gap-15">
                        <MatchRowEmail email="phil.fix@me.com" label="Mobile" radioLabel="Add New?" checked={considerData["alternativeEmailAddresses"]} />
                        <MatchRowEmail email="philf@me.com" label="" />
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-close" onClick={hide}>
                        Cancel
                    </button>
                    <button 
                        className="btn btn-primary" 
                        onClick={updateFleetManagerDetails}
                        disabled={isLoader}
                    >
                        {isLoader && (
                            <span
                                class="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        )}
                        Confirm Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default MatchDetailsModal

const RadioButtonSelect = ({ name, label, checked, onChange }) => (
    <>
        {label && <label>{label}</label>}
        <div className="d-flex justify-content-end align-items-start gap-5">
            <label htmlFor={name + "-no"} className="btn btn-outline-light gap-5 rounded-5 mb-0" onClick={() => onChange(name, 0)}>
                <input id={name + "-no"} className="form-check-input" type="radio" name={name} checked={checked === 0} />
                No
            </label>
            <label htmlFor={name + "-yes"} className="btn btn-outline-light gap-5 rounded-5 mb-0" onClick={() => onChange(name, 1)}>
                <input id={name + "-yes"} className="form-check-input" type="radio" name={name} checked={checked === 1} />
                Yes
            </label>
        </div>
    </>
)