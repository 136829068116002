import { useEffect, useId, useMemo, useState } from "react";
import moment from "moment-timezone";

import { generateStatusColor } from "../../NewDispatcher/DispatcherTable/Functions";
import { useCurrentUser, useHover, useCurrentUserSettings } from "../../../../hooks";
import { DateTimeFormatUtils } from "../../../../services";
import { APPOINTMENT_STATUSES, MOVE_TYPES, APPOINTMENT_AWAITING_REMARKS } from "../../../../constants";
import { IconInfo, IconInfoDarkI } from "../../../../Components/Common/Icons";
import { formatToTime } from "../../services";
import { getAppointment } from "../actionCreator";
import CustomTooltip from "Components/Common/CustomTooltip";
import { SCHEDULED_STATUS, STATUS_APPOINTMENT } from "pages/tms/Scheduling/constants";

const RequestedAppointmentTimeInfo = ({ label, moveType, load, isAbsolute = false, isPortal = true, isAllowedForDirectBooking = false, scheduleStatusValue = null }) => {
  const id = useId();
  const [hoverRef, isHovering] = useHover();

  const { currentUserTimeZone } = useCurrentUser();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};
  const [errorRemarks, setErrorRemarks] = useState({ isLoading: false, isCompleted: false, remarks: null, systemRemarks: null });
  

  // Must have startTime property
  const isValidTime = (timeArgs) => {
    if (!timeArgs?.length) return false;
    return timeArgs?.some((eachTime) => eachTime?.startTime || eachTime?.appointmentTimeStart);
  };

  const [hasAwaiting, hasPickUpAwaiting, hasEmptyAwaiting] = useMemo(() => {
    const hasPickUpAwaiting =
      moveType === MOVE_TYPES.PICKUP &&
      load?.pickupAutoAppointmentSystem &&
      load?.pickUpApptStatus?.startsWith("AWAITING") &&
      !load?.pickupTimes?.[0]?.pickupFromTime;
  
    const hasEmptyAwaiting =
      moveType === MOVE_TYPES.EMPTY &&
      load?.dropAutoAppointmentSystem &&
      load?.emptyApptStatus?.startsWith("AWAITING") &&
      !load?.returnFromTime;

    const hasAwaiting = hasPickUpAwaiting || hasEmptyAwaiting;
    return [hasAwaiting, hasPickUpAwaiting, hasEmptyAwaiting];
  }, 
    [ moveType, 
     load?.pickupAutoAppointmentSystem, 
     load?.pickUpApptStatus, 
     load?.dropAutoAppointmentSystem, 
     load?.emptyApptStatus, 
     load?.pickupTimes?.[0]?.pickupFromTime, 
     load?.returnFromTime
    ]
  );

  const times = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem) return load?.requestedPickupTimes;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem) return load?.requestedReturnTimes;

    return [];
  }, [moveType, load]);

  const status = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.pickUpApptStatus)) {
      if (load?.pickUpApptStatus === "TEMPLATE_APPLIED" || hasPickUpAwaiting) {
        return "REQUESTED";
      }
      if (load?.pickupAppointmentSystem && load?.pickUpApptStatus) {
        return load?.pickUpApptStatus;
      }
      if (load?.pickupAutoAppointmentSystem && load?.loadTracks?.pickupAutoApptStatus && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.loadTracks?.pickupAutoApptStatus)) {
        return load?.loadTracks?.pickupAutoApptStatus;
      }
    }
   
    // DROP
    if (moveType == MOVE_TYPES.EMPTY && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.emptyApptStatus)) {
      if (load?.emptyApptStatus === "TEMPLATE_APPLIED" || hasEmptyAwaiting) {
        return "REQUESTED";
      }
      if(load?.emptyAppointmentSystem && load?.emptyApptStatus){
        return load?.emptyApptStatus;
      }
      if(load?.dropAutoAppointmentSystem && load?.loadTracks?.emptyAutoApptStatus && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.loadTracks?.emptyAutoApptStatus)){
        return load?.loadTracks?.emptyAutoApptStatus;
      }
    }
    if(scheduleStatusValue?.appointmentStatus) {
      return scheduleStatusValue?.appointmentStatus === STATUS_APPOINTMENT.NEED_APPOINTMENT ? "Open" : SCHEDULED_STATUS[scheduleStatusValue?.appointmentStatus]
    }
    return "";
  }, [
    moveType,
    load?.pickUpApptStatus,
    load?.pickupAppointmentSystem,
    load?.pickupAutoAppointmentSystem,
    load?.emptyApptStatus,
    load?.emptyAppointmentSystem,
    load?.dropAutoAppointmentSystem,
  ]);

  // Hide ToolTip if there is user entered time
  const isUserEnteredTime = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.pickUpApptStatus)) return true;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.emptyApptStatus)) return true;
    return false;
  }, [moveType, load]);

  const handleAwaitingRemarks = (status) => { 
    if (!status) return;

    const missingRelativeField = status.split("-")[1];
    if (!missingRelativeField) return;

    const relativeRemarks = APPOINTMENT_AWAITING_REMARKS[missingRelativeField] || "";
    
    setErrorRemarks(prev => ({
      ...prev,
      isLoading: false,
      isCompleted: true,
      remarks: relativeRemarks,
      systemRemarks: null
    }));    
  };

  useEffect(() => {
 
    if (hasPickUpAwaiting) return handleAwaitingRemarks(load.pickUpApptStatus);
    if (hasEmptyAwaiting) return handleAwaitingRemarks(load.emptyApptStatus);

    if (!isHovering || errorRemarks.isCompleted || status !== APPOINTMENT_STATUSES.ERROR) return;

    const appointmentId =
      (moveType === MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem) ||
      (moveType === MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem);

    if (!appointmentId) return;

    setErrorRemarks((prev) => ({ ...prev, isLoading: true }));

    getAppointment({ appointmentId })
      .then((data) => {
        setErrorRemarks((prev) => ({
          ...prev,
          isLoading: false,
          isCompleted: true,
          remarks: data?.remarks,
          systemRemarks: data?.systemRemarks,
        }));
      })
      .catch(() => {
        setErrorRemarks((prev) => ({
          ...prev,
          isLoading: false,
          isCompleted: true,
          remarks: null,
          systemRemarks: null,
        }));
      });
  }, [isHovering, moveType, load, status, errorRemarks.isCompleted]);

  if (!isAppointmentFeatureEnabled || !status) return null;

  const CommonToolTip = ({ isOpen, refNo }) => {
    const tooltipContent = useMemo(() => {
      if (status === APPOINTMENT_STATUSES.ERROR) {
        if (errorRemarks.isLoading) return "Loading...";
        if (errorRemarks.isCompleted && !errorRemarks.remarks && !errorRemarks.systemRemarks) {
          return "No remarks available for this appointment";
        }
        return (
          <div>
            {errorRemarks?.remarks && <div>{errorRemarks.remarks}</div>}
            {errorRemarks?.systemRemarks && (
              <div className={errorRemarks.remarks ? "mt-1" : ""}>System Remarks: {errorRemarks.systemRemarks}</div>
            )}
          </div>
        )
      }
  
      if (hasAwaiting) {
        return <div className="mt-1">{errorRemarks?.remarks}</div>;
      }
  
      const hasValidTimes = isUserEnteredTime && isValidTime(times);

      return (
        <>
          <div>Requested {label ?? "Time"}</div>
          {hasValidTimes && times?.map((eachTime) => {
            return (
              eachTime?.startTime &&
              eachTime?.endTime && (
                <div className="mt-1">
                  {eachTime?.dateType?.includes("TIME") ? (
                    <>
                      {formatToTime(eachTime.startTime)} - {formatToTime(eachTime.endTime)}
                    </>
                  ) : (
                    <>
                      {moment(eachTime?.startTime)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}{" "}
                      -{" "}
                      {moment(eachTime?.endTime)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}
                    </>
                  )}
                </div>
              )
            );
          })}
          {times?.length === 0 && <p>N/A</p>}
        </>
      );
    }, [status, errorRemarks, hasPickUpAwaiting, hasEmptyAwaiting, times, label, currentUserTimeZone]);
  
    return (
      <CustomTooltip isOpen={isOpen} refNo={refNo} color={"gray-700"} className={"max-w-500px max-vh-100 pull-y"} placement={"auto"} offset={[0, 0]} overflowContent={true}>
        {tooltipContent}
      </CustomTooltip>
    );
  };

  const showWithIcon = (isUserEnteredTime && isValidTime(times)) || hasAwaiting;
  return showWithIcon 
   ? (
    <div>
      <div className="d-inline-flex align-items-center gap-5" ref={hoverRef}>
        <div className={`appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
        <div className="appointment-time-info_icon">
          <IconInfoDarkI className={hasAwaiting ? 'text-danger' : 'text-gray-200'} />
        </div>
      </div>

      {isHovering && <CommonToolTip isOpen={isHovering} refNo={hoverRef?.current} /> }
    </div>
   ) :(
    <div className={`d-block w-max appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
    ) 
};

export default RequestedAppointmentTimeInfo;
