import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import ConsigneeList from "../portals/ConsigneeList";
import { DateTimeFormatUtils, getStorage } from "../../../../../../services/Common.services";
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const Consignee = ({ row, column, onRowChange, isCellSelected }) => {
	let TimeZone = getStorage("timeZone");
	const cellRef = useRef();
	const prevSelectionRef = useRef();
	const [isConsigneeList, setIsConsigneeList] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setIsConsigneeList(true);
		}
		prevSelectionRef.current = isCellSelected;
		if (!isCellSelected) {
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.consignee);
	}, [column, row?.colorOfCells?.consignee]);

	return (
	<>
		<div ref={cellRef} className="cell-content" onClick={()=>setIsConsigneeList(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
			
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress &&
				
					row.consignee &&
						row.consignee.map((e, i) => {
							return (
								<div className="d-block" key={i}>
									<div className={`rdg-truncate ${row.carrierHold ? "redColor" : ""}`} key={i}>
										{e.company_name}
										{(e.officeHoursStart || e.officeHoursEnd) && (
											<small>
												<br />
												{e.officeHoursStart
													? moment(e.officeHoursStart)
														.tz(TimeZone)
														.format(DateTimeFormatUtils.timeFormat())
													: ""} - {e.officeHoursEnd
													? moment(e.officeHoursEnd)
														.tz(TimeZone)
														.format(DateTimeFormatUtils.timeFormat())
													: ""}
											</small>
										)}
									</div>
								</div>
							);
						})}
				
			{row?.isEditPermission && isConsigneeList && !isAssignInProgress && (
				<ConsigneeList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsConsigneeList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
			)}
			
		</div>
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="consignee" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
	</>);
};

export default Consignee;
