import  _  from 'lodash';

const AUTOBOOK_PICKUP_APPT_TYPES = Object.freeze({
  NO_RESTRICTION: "NO_RESTRICTION",
  ON_LFD: "ON_LFD",
  CUSTOM_N_HOURS_BEFORE_LFD: "CUSTOM_N_HOURS_BEFORE_LFD",
});

const AUTOBOOK_EMPTY_APPT_TYPES = Object.freeze({
  FIRST_AVAILABLE: "FIRST_AVAILABLE",
  CUSTOM_N_HOURS_AFTER_MARKED_EMPTY: "CUSTOM_N_HOURS_AFTER_MARKED_EMPTY",
});

const DEFAULT_ANY_TIME_SLOT = Object.freeze({
  appointmentTimeStart: "00:00",
  appointmentTimeEnd: "23:59",
  isActive: true,
});

const appointmentSettingsLabels = {
  MAX_APPOINTMENT: "Set the maximum amount of appointments per time",
  CONTAINER_HOLD_APPOINTMENTS: "I want to allow appointments to be made if container is on hold.",
  CONTAINER_AVAILABILITY: "I want to begin booking this many hours after container becomes available?",
  AUTO_APPOINTMENTS: "I want the system to automatically book appointments for me",
  WEEKEND_AUTO_APPOINTMENTS: "I want the system to book appointments during the weekend",
  RESCHEDULE_APPOINTMENTS: "I want the system to automatically reschedule my appointment if I miss it",
  PICKUP_APPOINTMENTS: "I want to book pick up appointments",
  CONTAINER_EMPTY_APPOINTMENTS: "Schedule appointment as soon as container is marked as empty",
  RETURN_RECOMMENDED_APPOINTMENTS: "I want to book return appointments based on the systems return recommendations"
}

// New Auto Appointment System Settings CONSTANTS & UTILS
function generate24HrsData(hour) {
  if (hour) {
    return {
      label: `${hour} hr`, 
      value: `${hour}h`,
    };
  } else {
    const hourlyData = [];
    for (let i = 0; i <= 24; i++) {
      hourlyData.push({
        label: `${i} hr`,
        value: `${i}h`,
      });
    }
    // Add additional hour options
    [36, 48, 72].forEach(hr => {
      hourlyData.push({
        label: `${hr} hr`,
        value: `${hr}h`,
      });
    });
    return hourlyData;
  }
}

function generateRequestTimeOption(time) {

  if (time) {
    return {
      label: time,
      value: time
    };
  } else {
    const options = [];
    
    [1, 15, 30].forEach(min => {
      options.push({
        label: `${min} min`,
        value: `${min}m`
      });
    });

    for (let hr = 1; hr <= 24; hr++) {
      options.push({
        label: `${hr} hr`,
        value: `${hr}h`
      });
    }

    return options;
  }
}

function getFactors(n) {
  const factors = [];
  for (let i = 1; i <= n; i++) {
    if (n % i === 0) {
      factors.push(i);
    }
  }
  return factors;
}

function generateFactorHrsData (factor) {
  const factors = getFactors(factor);
  return factors?.map((f) => ({
    label: `${f} hr`,
    value: f,
  }));
}

function formatTime(hour) {
  const isAM = hour < 12 || hour === 24;
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
  const ampm = isAM ? "AM" : "PM";
  return `${formattedHour}${ampm}`;
}

function formatTimeValue(hour) {
  return `${hour.toString().padStart(2, "0")}:00`;
}

function getTimePreferences(bucket) {
  const intervals = [];
  for (let startHour = 0; startHour < 24; startHour += bucket) {
    const endHour = startHour + bucket === 24 ? 0 : startHour + bucket;
    intervals.push({
      startTime: formatTime(startHour),
      endTime: formatTime(endHour),
      startTimeValue: formatTimeValue(startHour),
      endTimeValue: formatTimeValue(endHour),
    });
  }
  return intervals;
}

const MOVE_TYPE_CONSTANTS = {
  PICKUP: "PICKUP",
  EMPTY: "EMPTY",
};

const LOAD_TYPE_CONSTANTS = {
IMPORT: 'IMPORT',
EXPORT: 'EXPORT',
};

const DAY_PREFERENCE = [
  {
    id: 0,
    label: "Relative to Last Free Day",
    key: "lastFreeDay",
    dayPreferences: [
      { id: 1, key: { day: 5, type: 'before' }, label: "5 Days Before LFD", value: "5 Days Before LFD" },
      { id: 2, key: { day: 4, type: 'before' }, label: "4 Days Before LFD", value: "4 Days Before LFD" },
      { id: 3, key: { day: 3, type: 'before' }, label: "3 Days Before LFD", value: "3 Days Before LFD" },
      { id: 4, key: { day: 2, type: 'before' }, label: "2 Days Before LFD", value: "2 Days Before LFD" },
      { id: 5, key: { day: 1, type: 'before' }, label: "1 Day Before LFD", value: "1 Day Before LFD" },
      { id: 6, key: { day: 0, type: 'exact' }, label: "On LFD", value: "On LFD" },
      { id: 7, key: { day: 7, type: 'after' }, label: "First After LFD", value: "After LFD" },
    ]
  },
  {
    id: 1,
    label: "Relative to Delivery Apt",
    key: "deliveryAppt",
    dayPreferences: [
      { id: 1, key: { day: 5, type: 'before' }, label: "5 Days Before Delivery Appointment", value: "5 Days Before Delivery Apt" },
      { id: 2, key: { day: 4, type: 'before' }, label: "4 Days Before Delivery Appointment", value: "4 Days Before Delivery Apt" },
      { id: 3, key: { day: 3, type: 'before' }, label: "3 Days Before Delivery Appointment", value: "3 Days Before Delivery Apt" },
      { id: 4, key: { day: 2, type: 'before' }, label: "2 Days Before Delivery Appointment", value: "2 Days Before Delivery Apt" },
      { id: 5, key: { day: 1, type: 'before' }, label: "1 Day Before Delivery Appointment", value: "1 Day Before Delivery Apt" },
      { id: 6, key: { day: 5, type: 'exact' }, label: "On the Delivery Appointment Day", value: "Day of Deliver Apt" },
      { id: 7, key: { day: 1, type: 'after' }, label: "1 Day After Delivery Appointment", value: "1 Day After Delivery Apt" },
      { id: 8, key: { day: 2, type: 'after' }, label: "2 Days After Delivery Appointment", value: "2 Days After Delivery Apt" },
    ]
  },
  {
    id: 2,
    label: "Relative to Per Diem LFD",
    key: "perDiemLfd",
    dayPreferences: [
      { id: 1, key: { day: 5, type: 'before' }, label: "5 Days Before Per Diem LFD", value: "5 Days Before Per Diem LFD" },
      { id: 2, key: { day: 4, type: 'before' }, label: "4 Days Before Per Diem LFD", value: "4 Days Before Per Diem LFD" },
      { id: 3, key: { day: 3, type: 'before' }, label: "3 Days Before Per Diem LFD", value: "3 Days Before Per Diem LFD" },
      { id: 4, key: { day: 2, type: 'before' }, label: "2 Days Before Per Diem LFD", value: "2 Days Before Per Diem LFD" },
      { id: 5, key: { day: 1, type: 'before' }, label: "1 Day Before Per Diem LFD", value: "1 Day Before Per Diem LFD" },
      { id: 6, key: { day: 0, type: 'exact' }, label: "On the Per Diem LFD", value: "Day of Per Diem LFD" },
      { id: 7, key: { day: 0, type: 'firstAvailable' }, label: "First Available After LFD Passes", value: "First Available After Passes" },
    ]
  },
  {
    id: 3, label: "Relative to Cut Off", key: "cutOff", dayPreferences: [
      { id: 1, key: { day: 5, type: 'before' }, label: "5 Days Before Cut-Off", value: "5 Days Before Cut Off" },
      { id: 2, key: { day: 4, type: 'before' }, label: "4 Days Before Cut-Off", value: "4 Days Before Cut Off" },
      { id: 3, key: { day: 3, type: 'before' }, label: "3 Days Before Cut-Off", value: "3 Days Before Cut Off" },
      { id: 4, key: { day: 2, type: 'before' }, label: "2 Days Before Cut-Off", value: "2 Days Before Cut Off" },
      { id: 5, key: { day: 1, type: 'before' }, label: "1 Day Before Cut-Off", value: "1 Day Before Cut Off" },
      { id: 6, key: { day: 0, type: 'exact' }, label: "On the Cut-Off Day", value: "Day of Cut Off" },
      { id: 7, key: { day: 1, type: 'after' }, label: "1 Day After Cut-Off", value: "1 Day After Cut Off" },
      { id: 8, key: { day: 2, type: 'after' }, label: "2 Days After Cut-Off", value: "2 Days After Cut Off" },
    ]
  },
  {
    id: 4, label: "First Available Appointment", key: "firstAvailableDate", dayPreferences: [
      { id: 1, key: { day: 5, type: 'before' }, label: "5 Days Before First Available Appointment", value: "5 Days Before First Available Appointment" },
      { id: 2, key: { day: 4, type: 'before' }, label: "4 Days Before First Available Appointment", value: "4 Days Before First Available Appointment" },
      { id: 3, key: { day: 3, type: 'before' }, label: "3 Days Before First Available Appointment", value: "3 Days Before First Available Appointment" },
      { id: 4, key: { day: 2, type: 'before' }, label: "2 Days Before First Available Appointment", value: "2 Days Before First Available Appointment" },
      { id: 5, key: { day: 1, type: 'before' }, label: "1 Day Before First Available Appointment", value: "1 Day Before First Available Appointment" },
      { id: 6, key: { day: 0, type: 'firstAvailable' }, label: "On the First Available Appointment", value: "Day of First Available Appointment" },
      { id: 7, key: { day: 1, type: 'after' }, label: "1 Day After First Available Appointment", value: "1 Day After First Available Appointment" },
      { id: 8, key: { day: 2, type: 'after' }, label: "2 Days After First Available Appointment", value: "2 Days After First Available Appointment" },
    ]
  },
]

const defaultEmptyAppointmentTemplate =  {
  isNew: true,
  templateName: "",
  moveType: "",
  loadType: "",
  isDefaultBackgroundTemplate: false,
  bookAfterTriggerTime: undefined,
  bookDuringWeekend: false,
  bookEvenIfContainerHasHolds: false,
  automaticRescheduleMissed: false,
  automaticRescheduleAfterFallbackBooked: false,
  automaticRescheduleAfterFallbackBookedTime: undefined,
  rules: [],
  relativeTo: {
      relativeKey: ""
  },
  timeBucketInHrs: 3,
  timePreferences: [],
  dayPreferences: [],
}

function compareTemplates(obj1, obj2, mode = "differences") {
  const fieldsToCheck = Object.keys(defaultEmptyAppointmentTemplate).filter(key => key !== "isNew");

  const differences = _.reduce(fieldsToCheck, (result, key) => {
    let value1 = _.get(obj1, key);
    let value2 = _.get(obj2, key);

    if (_.isArray(value1) && _.isArray(value2)) {
      if (["timePreferences", "dayPreferences"].includes(key)) {
        const cleanedValue1 = value1.map(v => _.omit(v, ["_id"]));
        const cleanedValue2 = value2.map(v => _.omit(v, ["_id"]));

        if (!_.isEqual(cleanedValue1, cleanedValue2)) {
          result[key] = cleanedValue2;
        }
      } else if (!_.isEqual(value1, value2)) {
        result[key] = value2;
      }
    } else if (_.isObject(value1) && _.isObject(value2)) {
      if (key === "relativeTo" && !_.isEqual(value1, value2)) {
        result[key] = value2;
      } else {
        const diff = compareTemplates(value1, value2, "differences");
        if (!_.isEmpty(diff)) result[key] = diff;
      }
    } else if (!_.isEqual(value1, value2)) {
      result[key] = value2;
    }

    return result;
  }, {});

  return mode === "isEqual" ? _.isEmpty(differences) : differences;
}

export {
  AUTOBOOK_PICKUP_APPT_TYPES,
  AUTOBOOK_EMPTY_APPT_TYPES,
  DEFAULT_ANY_TIME_SLOT,
  appointmentSettingsLabels,
  generate24HrsData,
  generateFactorHrsData,
  generateRequestTimeOption,
  getTimePreferences,
  DAY_PREFERENCE,
  MOVE_TYPE_CONSTANTS,
  LOAD_TYPE_CONSTANTS,
  defaultEmptyAppointmentTemplate,
  compareTemplates
};
