import React from "react";
import Select from "react-select";
import { smallSelectStyle } from "assets/js/select-style";
import TimeSlot from "./TimeSlot";

const PreferenceSection = ({ title, slots, showTimeBucket = false, timeBucketOptions, timeBucketInHrs, handleSelectChange, handleRowClick, timePreferencesValue, isDayPreference = false }) => {
  return (
    <div className="d-flex gap-10 flex-column w-100">
      <div className="d-flex gap-10 align-items-center h-30px">
        <div className="font-14 line-height-20 font-medium">{title}</div>
        {showTimeBucket && (
          <div className="d-flex gap-10 ml-auto align-items-center">
            <div className="font-medium">Time Bucket:</div>
            <Select
              styles={{ smallSelectStyle }}
              options={timeBucketOptions}
              id="timeBucketInHrs"
              value={timeBucketInHrs}
              onChange={(e) => handleSelectChange("timeBucketInHrs", e)}
              onInputChange={(e) => {
                const numericValue = e.replace(/[^0-9]/g, '');
                return numericValue;
              }}
            />
          </div>
        )}
      </div>
      <div className="d-flex flex-column gap-5">
        {slots?.map((slot, index) => (
          <TimeSlot 
            key={index} 
            slot={slot} 
            timeBucket={timeBucketInHrs?.value} 
            timePreferencesValue={timePreferencesValue} 
            index={index} 
            handleRowClick={handleRowClick} 
            isDayPreference={isDayPreference}
          />
        ))}
      </div>
    </div>
  );
};

export default PreferenceSection; 