import jsonLogic from "json-logic-js";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "underscore";
import __ from "lodash"
import { IconMinus, IconPlus, IconAngleArrowDown } from "Components/Common/Icons";
import NewPriceInput from "Components/Common/PriceInput/NewPriceInput";
import { getExpenseRules } from "Components/DynamicPricing/actionCreators";
import ReactTooltip from "react-tooltip";
import ExpenseComponent from "pages/tms/Load/ExpenseComponent";
import {
  checkUserPermisison,
  convertUnit,
  Currency,
  getStorage,
  isGlobalizationEnabled,
  isNewBillingEnabled,
  toastr,
  treeToJsonParser,
  types,
  filterChargeCodesByScreenType
} from "services";
import { TMS_GET_USER_LOAD } from "pages/tms/constant";
import * as tmsAction from "pages/tms/Load/actionCreator";
import SelectCustomComponent from "pages/tms/SelectComponent";
import { NumberFormatterV2 } from "Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import SelectCustomCurrency from "pages/tms/SelectCurrency"
import { getLoadExpenses } from "Components/CustomerServiceSideBar/actions";
import { checkAccountReceivablePermission } from "../../../../services";
import { LoaderBar } from "Components/Common/LoaderBar";
import { getChargeReferencesByLoadRef } from "../../actions";

const MANUALLY_CHANGED = 'MANUALLY_CHANGED';

class ExpensesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAPICall: false,
      expenseRules: [],
      asce: true,
      addExpense: false,
      amount: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
      finalAmount: 0,
      unit: 0,
      freeDays: 0,
      perType: "",
      name: "",
      manualFinalAmount: 0,
      selectedCell: null,
      nameColor: "transition-white-field",
      chargeRefColor: "transition-white-field",
      invoiceCurrency: props.invoiceCurrency,
      branchCurrency: props.branchCurrency,
      expense: [],
      isLoading: false,
      charge_reference_number: null,
      charge_reference_id: null,
      chargeSetReferenceNumbersList: [],
    };
    this.pricingDescription = null;
    this.pricingFreeUnit = null;
    this.DeletePriceRow = this.DeletePriceRow.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.expenseChanges = this.expenseChanges.bind(this)
    this.updateRowExpense = this.updateRowExpense.bind(this)
    this.unselectCell = this.unselectCell.bind(this)
    this.loggedInUserRole = JSON.parse(getStorage("loggedInUser"))?.role;
  }

  componentWillMount() {
    this.getExpenseRules();
    if (this.props.selectedLoads && this.props.selectedLoads._id) {
      this.getLoad(this.props.selectedLoads.reference_number);
      getChargeReferencesByLoadRef(this.props.selectedLoads.reference_number).then((res) => {
        if(res?.length > 0) {
          this.setState({
            chargeSetReferenceNumbersList: res?.map(d => ({
              ...d,
              value: d?.charge_reference_number,
              chargeName: d?.charge_reference_number,
            }))
          });
        }
      });
    } else {
      this.componentWillReceiveProps(this.props);
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  async componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({ isLoading: true })
    await getLoadExpenses({reference_number: this.props.selectedLoads?.reference_number}).then(
      (res) => {
        this.props.loads.expense = res?.expense
      }
    )
    if (this.props.loads.expense) {
      let allExpenses = this.props.loads.expense.filter((x) => x);
      if (allExpenses && allExpenses.length > 0) {
        allExpenses = _.sortBy(allExpenses, (price) => this.props.chargeCodeList.findIndex((charge) => charge.value === price.name));
        allExpenses.map((x) => {
          if (x) {
            x.manualFinalAmount = x.finalAmount;
          }
        })
      }
      this.setState({ expense: allExpenses });
    }
    if (getStorage("currentUserRole") == "customer" && !this.props.loads.expense) {
      this.setState({ expense: [] });
    }
    this.setState({ isLoading: false })
  }
  selectCell = (name) => {
    if (this.state.selectedCell === name) return
    this.setState({ selectedCell: name });
  }

  unselectCell = () => {
    this.setState({ selectedCell: null });
  }

  getLoad(reference_number) {
    this.props.dispatch({
      type: TMS_GET_USER_LOAD,
      reference_number: reference_number,
      payload: {
        skip: 0,
        limit: 1,
      },
    });
  }

  getExpenseRules() {
    getExpenseRules()
      .then((expenseRules) => {
        this.setState({ expenseRules });
      })
      .catch((error) => {
        console.log(" -*-*-* error get expense rules -*-*-*- ", error);
      });
  }

  componentWillReceiveProps(props) {
    if (
      this.props.loads.expense !== props.loads.expense ||
      (props.loads.expense && !this.state.expense)
    ) {
      let allExpenses = props.loads.expense.filter((x) => x);
      if (allExpenses && allExpenses.length > 0) {
        allExpenses = _.sortBy(allExpenses, (price) => this.props.chargeCodeList.findIndex((charge) => charge.value === price.name));
        allExpenses.map((x) => {
          if (x) {
            x.manualFinalAmount = x.finalAmount;
          }
        })
      }
      this.setState({ expense: allExpenses });
    }
    if (getStorage("currentUserRole") == "customer" && !props.loads.expense) {
      this.setState({ expense: [] });
    }

  }


  setWrapperRef(node) {
    this.wrapperRef = node;
  }




  DeletePriceRow(index) {
    let data = [...this.state.expense];
    let payloadData = {};
    if (this.props.selectedLoads) {
      payloadData.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payloadData.reference_number = this.props.loads.reference_number;
    }
    if (data[index]?._id) {
      payloadData.expenseId = data[index]._id;
      this.setState({ isAPICall: true });
      tmsAction.removeExpense(payloadData).then((response) => {
        let allExpenses = this.props.loads.expense.filter((x) => x);
        if (allExpenses && allExpenses.length > 0) {
          allExpenses = _.sortBy(allExpenses, (price) => this.props.chargeCodeList.findIndex((charge) => charge.value === price.name));
        }
        this.setState({
          expense: allExpenses,
          isAPICall: false
        },()=>{
          this.props.handleReloadOfBillingSummary()
        })
        toastr.show(`Your expense has been deleted!`, "success");
      }).catch((error) => {
        console.log("error", error);
        this.setState({ isAPICall: false })
        toastr.show("Something went wrong! Please try again.", "error");
      })
    } else {
      this.setState({ addExpense: false }, () => {
        this.clearAddExpense();
      })
    }
  }


  keyPressOnNumberInput = (evt) => {
    var invalidChars = [
      "-",
      "+",
      "e",
    ];
    if (invalidChars.includes(evt.key)) {
      evt.preventDefault();
    }
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  handleClickOutside(event) {

    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      !document.querySelector("[role='tablist']")?.contains(event.target)
    ) {
      if (this.state.selectedCell) return
      this.handleSubmit()
    }
  }

  clearAddExpense = () => {
    this.setState({
      name: "",
      description: "",
      unit: 1,
      amount: 0,
      finalAmount: "",
      perType: "",
      chargeType: MANUALLY_CHANGED,
      freeDays: 0,
      chargePerDay: 0,
      chargePerMinute: 0,
    })
  }

  findMatchingExpenseRule(chargeName) {
    let selectedLoads = undefined;
    if (this.props.selectedLoads) {
      selectedLoads = this.props.selectedLoads;
    } else {
      selectedLoads = this.props.loads;
    }

    const loads = {
      ...selectedLoads,
      caller: selectedLoads.caller && selectedLoads.caller._id,
      shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
      consignee:
        selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
      chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
      emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
      chassisTermination:
        selectedLoads.chassisTermination &&
        selectedLoads.chassisTermination._id,
      containerType:
        selectedLoads.containerType && selectedLoads.containerType._id,
      containerSize:
        selectedLoads.containerSize && selectedLoads.containerSize._id,
      containerOwner:
        selectedLoads.containerOwner && selectedLoads.containerOwner._id,
      chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
      chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
      chassisOwner:
        selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
      terminal: selectedLoads.terminal && selectedLoads.terminal._id,
      commodityProfile:
        selectedLoads.commodityProfile &&
        selectedLoads.commodityProfile.map((s) => s._id),
    };

    const { expenseRules } = this.state;
    const newExpenseFromSettings = [];
    let weight = 0;

    if (selectedLoads.items) {
      selectedLoads.items.forEach((obj) => {
        weight += parseInt(obj.weight);
      });
    }
    selectedLoads.weight = weight;
    expenseRules
      .filter((rule) => rule.name === chargeName && rule.isManual)
      .forEach((obj) => {
        let isAllConditionMatched = true;

        if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
          const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
          const data = JSON.stringify(loads);
          isAllConditionMatched = jsonLogic.apply(
            JSON.parse(rule),
            JSON.parse(data)
          );
        }

        if (isAllConditionMatched) {
          let totalDays = 0;
          if (obj.type === "fixed") {
            obj.finalAmount = obj.amount;
          } else if (obj.type === "perpound") {
            totalDays = weight;
          }
          obj.totalDays = totalDays;
          newExpenseFromSettings.push(obj);
        }
      });
    return newExpenseFromSettings[0];
  }

  handleSubmit() {
    if (!this.state.name) {
      this.setState({ nameColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ nameColor: "background-failure" })
        }, 400);
      });
      return false;
    }

    if (!this.state.charge_reference_number) {
      this.setState({ chargeRefColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ chargeRefColor: "background-failure" })
        }, 400);
      });
      return false;
    }

    if (this.state.selectedCell) return
    if (!this.state.isAPICall &&
      this.state.isEdited &&
      this.state.name) {
      let payload = {};
      if (this.props.selectedLoads) {
        payload.reference_number = this.props.selectedLoads.reference_number;
      } else {
        payload.reference_number = this.props.loads.reference_number;
      }
      payload.expense = {
        "name": this.state.name,
        "amount": this.state.amount,
        "chargePerDay": this.state.chargePerDay,
        "chargePerMinute": this.state.chargePerMinute,
        "finalAmount": (parseFloat(this.state.finalAmount) || 0).toFixed(2),
        "unit": (parseFloat(this.state.unit) || 0).toFixed(2),
        "freeDays": this.state.freeDays,
        "perType": this.state.perType,
        "chargeType": this.state.chargeType,
        "customerRateId": null,
        "description": this.state.description,
        "type": this.state.type,
      }
      if(this.state.charge_reference_number && this.state.charge_reference_id){
        payload.charge_reference_number = this.state.charge_reference_number;
        payload.charge_reference_id = this.state.charge_reference_id;
      }
      this.setState({ isAPICall: true, rowIndex: this.state.expense?.length }, () => this.props.blockTabChange(true))
      tmsAction.addExpense(payload).then((response) => {
        this.setState({ isAPICall: false, isSuccess: true, isFailed: "transition-white-field" });
        let x_charges = 0, base_price = 0;
        const _addedExpense = __.cloneDeep(response.expense)?.at(-1)
        response.expense = _.sortBy(response.expense, (price) => this.props.chargeCodeList.findIndex((charge) => charge.value === price.name));
        response.expense.map((x) => {
          if (x) {
            if (x.name == "Base Price") {
              base_price += parseFloat(x.finalAmount);
            } else {
              x_charges = (
                parseFloat(x_charges) + parseFloat(x.finalAmount)
              ).toFixed(2);
            }
            x.manualFinalAmount = x.finalAmount;
          }
        });
        this.setState({ base_price, x_charges });
        toastr.show(`Your expense has been added`, "success");
        setTimeout(() => {
          this.setState({ isSuccess: false });
        }, 400);
        this.setState({ expense: response.expense, addExpense: false, selectedCell: false }, () => {
          document.querySelector(`#addChargeFocus`)?.focus()
          this.props.blockTabChange(false)
          this.props.handleReloadOfBillingSummary()
          const expenseIndexToBeUpdated = this.state.expense?.findIndex(val => val?._id === _addedExpense?._id)
          const doesRuleExist =  this.findMatchingExpenseRule(_addedExpense?.name)

          if(expenseIndexToBeUpdated > -1 && _addedExpense?.name && doesRuleExist) {
            this.expenseChanges(expenseIndexToBeUpdated, {
              target: { value: _addedExpense.name, name: "name" },
            })
            this.updateRowExpense(expenseIndexToBeUpdated);
            this.unselectCell()
          }
        });
        this.clearAddExpense();
      })
        .catch((err) => {
          toastr.show("Something went wrong! Please try again.", "error");
          this.setState({ isFailed: "background-error", isAPICall: false }, () => {
            this.props.blockTabChange(false);
            setTimeout(() => {
              this.setState({ isFailed: "background-failure" })
            }, 400);
          });
        })
    }
  }

  expenseChanges(index, e) {
    let { name, value } = e.target;
    const old = [...this.state.expense];


    const positiveValuesOnly = [
      "freeDays",
      "chargePerDay",
    ];

    const parseFloatValue = parseFloat(value) || 0;

    if (positiveValuesOnly.includes(name)) {
      if (parseFloatValue >= 0) {
        old[index][name] = value;
      } else {
        toastr.show("You can not add a negative number.", "error");
      }
    } else {
      if (name === "amount" && !value) value = 0
      old[index][name] = value;
    }

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    if (name === "name") {
      const data = this.findMatchingExpenseRule(value);

      if (data) {
        let units = 0;
        const freeDays = data.freeDays || 0;
        const chargePerDay = data.amount || 0;

        if (data.type === "fixed") units = 1;

        let finalAmount = (units - freeDays) * chargePerDay;

        if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;

        old[index]["description"] = data.description;
        old[index]["perType"] = data.type;
        old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
        old[index]["chargePerDay"] = chargePerDay;
        old[index]["unit"] = units;
        old[index]["finalAmount"] = finalAmount;
        old[index]["manualFinalAmount"] = 0;
        old[index]["amount"] = finalAmount;

        this.setState({
          expense: [...old],
          rowIndex: index,
        });
        return;
      }
    }
    if (name === "type") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
          100;
      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "amount") {
      old[index]["finalAmount"] =
        old[index]["type"] === "fixed"
          ? old[index]["amount"]
          : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
          100;

      old[index]["manualFinalAmount"] = old[index]["finalAmount"];
    } else if (name === "finalAmount" && value) {
      old[index]["manualFinalAmount"] = value;
    } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
      let computedAmount = (parseFloat(old[index].unit) - parseFloat(old[index].freeDays)) * parseFloat(old[index].chargePerDay);

      old[index][name] = value;
      if (old[index].name != "Fuel") {
        old[index]["finalAmount"] = computedAmount.toFixed(2);
      }
      if ((name == "perType" && value == "percentage")
        || (name !== "perType" && old[index]["perType"] == "percentage")) {
        if (old[index]["name"] !== 'Fuel') {
          old[index]["finalAmount"] = (this.state.base_price * old[index]["unit"] / 100).toFixed(2);

        }
      }

    } else if (name === "charge_reference_number") {
      old[index]["charge_reference_number"] = value?.charge_reference_number;
      old[index]["charge_reference_id"] = value?._id;
    }

    if (!old[index].chargeType || old[index].chargeType !== MANUALLY_CHANGED) {
      old[index].chargeType = MANUALLY_CHANGED;
    }
    this.setState({
      expense: [...old],
    });
  }

  updateRowExpense = (index) => {
    this.setState({ rowIndex: index })
    let expense = this.state.expense;
    let currentRow = expense[index];
    currentRow.finalAmount = (parseFloat(currentRow.finalAmount) || 0).toFixed(2);
    currentRow.unit = (parseFloat(currentRow.unit) || 0).toFixed(2);
    currentRow.chargePerDay = parseInt(currentRow.chargePerDay) || 0;
    currentRow.freeDays = parseInt(currentRow.freeDays) || 0;
    delete currentRow.subChargeType;
    currentRow.chargeType = MANUALLY_CHANGED;
    if (!currentRow.name) {
      this.setState({ nameColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ nameColor: "background-failure" })
        }, 400);
      });
      return false;
    }
    if (!currentRow.charge_reference_number) {
      this.setState({ chargeRefColor: "background-error" }, () => {
        setTimeout(() => {
          this.setState({ chargeRefColor: "background-failure" })
        }, 400);
      });
      return false;
    }
    let payload = {};
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    delete currentRow?.manualFinalAmount;
    delete currentRow?.dynamicPricingId;
    payload.expense = currentRow;
    this.setState({ isAPICall: true });
    tmsAction.updateExpense(payload)
      .then((response) => {
        if (response) {
          this.setState({ Total: response.totalAmount, isSuccess: true, isAPICall: false });
          let x_charges = 0, base_price = 0;
          response.expense = _.sortBy(response.expense, (price) => this.props.chargeCodeList.findIndex((charge) => charge.value === price.name));
          response.expense.map((x) => {
            if (x) {
              if (x.name == "Base Price") {
                base_price += parseFloat(x.finalAmount);
              } else {
                x_charges = (
                  parseFloat(x_charges) + parseFloat(x.finalAmount)
                ).toFixed(2);
              }
              x.manualFinalAmount = x.finalAmount;
            }
          });
          this.setState({ base_price, x_charges });
          setTimeout(() => {
            this.setState({ isSuccess: false });
          }, 400);
          this.setState({ expense: response.expense, addCharge: false }, () => {
            this.props.blockTabChange(false)
            this.props.handleReloadOfBillingSummary()
          });
        }
      })
      .catch((err) => {
        toastr.show("Something went wrong! Please try again.", "error");
        this.setState({ isFailed: "background-error", isAPICall: false }, () => {
          this.props.blockTabChange(false);
          setTimeout(() => {
            this.setState({ isFailed: "background-failure" })
          }, 400);
        });
      })

  }
  onAddSelectCharge = (name, value) => {
    this.setState({
      name: value.value,
      nameColor: "transition-white-field",
      isEdited: true,
      selectedCell: null
    })
    if (this.pricingDescription) this.pricingDescription.focus();
  }

  handleSelectChargeRefs = (_name, value) => {
    this.setState({
      charge_reference_number: value.value,
      charge_reference_id: value?._id,
      chargeRefColor:"transition-white-field",
      isEdited: true,
      selectedCell: null
    })
    if (this.pricingDescription) this.pricingDescription.focus();
  }

  handleKeyDown(e) {
    if (e.keyCode === 13) {
      e?.target?.blur();
      this.unselectCell()
      this.handleSubmit();
      e.stopPropagation()
    }
  }

  newRowChanges(e) {
    const { name, value } = e.target;
    const old = [...this.state.expense];

    const basePrice = old.find((obj) => {
      return obj.name === "Base Price";
    });

    let { type, amount, unit, freeDays, chargePerDay, perType, manualFinalAmount, finalAmount } = this.state;
    if (name === "type") {
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "amount") {
      finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
      manualFinalAmount = finalAmount;
    } else if (name === "finalAmount" && value) {
      manualFinalAmount = value;
    } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
      let computedAmount = (parseFloat(unit) - parseFloat(freeDays)) * parseFloat(chargePerDay);
      if (this.state.name !== 'Fuel') {
        if (computedAmount >= parseFloat(manualFinalAmount || 0)) {
          finalAmount = computedAmount.toFixed(2);
        } else {
          finalAmount = parseInt(manualFinalAmount || 0).toFixed(2);
        }
      }

      if ((name == "perType" && value == "percentage") || (name !== "perType" && perType == "percentage")) {
        if (this.state.name !== 'Fuel') {
          finalAmount = (20 * unit / 100).toFixed(2);
        }
      }

    }

    this.setState({
      finalAmount: finalAmount,
      manualFinalAmount: manualFinalAmount
    });
  }

  focusOnButton() {
    const element = document?.getElementById("addChargeFocus")
    element.style.border = "1px solid black"
  }

  handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.setState({ addExpense: true, isFailed: "transition-white-field", nameColor: "transition-white-field", chargeRefColor: "transition-white-field" }, () => { this.props.blockTabChange(true); this.selectCell("add-Expense") })
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
    } else {
      const element = document?.getElementById("addChargeFocus")
      element.style.border = "none"
      return
    }
  }

  renderReturn = () => {
    let { expense } = this.state
    var disabled = false;
    var selectedLoads;
    if (this.props.loads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
    } else if (this.props.selectedLoads) {
      selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
    } else {
      selectedLoads = undefined;
    }
    let showSave = false;
    if (selectedLoads) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
        selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "carrier" &&
        getStorage("currentUserID") == selectedLoads.carrier._id
      ) {
        showSave = true;
      } else if (
        getStorage("currentUserRole") == "customer" &&
        getStorage("currentUserID") == selectedLoads.addedBy._id
      ) {
        showSave = true;
      }
    }

    const { driverExpenseEditPermission } = checkAccountReceivablePermission()
    // if (this.state.sort) {
    //   expense = orderBy(
    //     expense,
    //     (charge)=>charge[this.state.sort].toLowerCase(), !this.state.asce && "desc"
    //   );
    // }

    const chargeCodeListForGL = filterChargeCodesByScreenType("payable", this.props.chargeCodeList);

    return (
      <div>
        {selectedLoads && (
          <div className="position-relative">
            <div className="table-responsive">
              {this.state.isLoading && <LoaderBar />}
              <table className="table table-card table-billing main-cstm-charge">
                {this.state.isAPICall && this.state.addExpense &&
                  <div className="overly-lays d-flex justify-content-center align-items-center text-white b-36">
                    <span
                      class="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Processing...
                  </div>
                }
                <thead>
                  <tr>
                    <th width="200">Name of Expense</th>
                    <th width="200">Charge Set</th>
                    <th width="300">Description</th>
                    <th width="210" className="text-right">Price {this.state.invoiceCurrency && (
                      `(${this.state.invoiceCurrency?.currencyCode})`
                    )}</th>
                    <th width="140" className="z-3">Unit Count</th>
                    <th width="150" className="z-3">Unit of Measure</th>
                    <th width="100" className="text-right">Free Units</th>
                    <th width="100" className="text-right">Per Units</th>
                    <th width="42"></th>
                  </tr>
                </thead>
                <tbody>
                  {expense &&
                    expense.length > 0 &&
                    expense.map((item, i) => {
                      let chargeValue = this.props.chargeCode.find((d) => d.value === item.name)
                      let charges = this.props.chargeCode.filter((d) => d.isActive)
                      return (
                          <ExpenseComponent
                            index={i}
                            key={i}
                            charges={charges}
                            chargeValue={chargeValue}
                            expenseChanges={this.expenseChanges}
                            item={item}
                            disabled={disabled}
                            showSave={showSave}
                            addExpense={this.state.addExpense}
                            DeletePriceRow={this.DeletePriceRow}
                            keyPressOnNumberInput={this.keyPressOnNumberInput}
                            redirectToRates={this.redirectToRates}
                            isAPICall={this.state.isAPICall}
                            isSuccess={this.state.isSuccess}
                            updateRowExpense={this.updateRowExpense}
                            rowIndex={this.state.rowIndex}
                            setWrapperRef={this.setWrapperRef}
                            handleSubmit={this.handleSubmit}
                            selectedCell={this.state.selectedCell}
                            selectCell={this.selectCell}
                            unselectCell={this.unselectCell}
                            name={this.state.name}
                            nameColor={this.state.nameColor}
                            branchCurrency={this.state.branchCurrency}
                            invoiceCurrency={this.state.invoiceCurrency}
                            chargeSetReferenceNumbersList={this.state.chargeSetReferenceNumbersList}
                            disableDriverExpenseEditAccess={!driverExpenseEditPermission}
                          />
                      );
                    })}
                  {this.state.addExpense &&
                    <tr className={`select-custom-input newdriverrow shadow-md ${this.state.isFailed}`}
                      onKeyDown={(e) => this.handleKeyDown(e)}
                      ref={this.setWrapperRef}
                    >
                      <td className={`${this.state.nameColor} cell-1  text-dark ${!this.state.name ? 'requied_hover' : ""} 
                            ${this.state.selectedCell === "add-Expense" ? 'newdriverrow td-clicked' : ''}
                            pl-2 pr-1`}
                        style={{ transition: 'background .4s ease-out' }}
                        onClick={() => this.selectCell("add-Expense")}
                      >
                        <div className="d-flex justify-content-between align-items-center text-dark">
                          {this.state.name.toUpperCase() || <span className="text-muted">Select Charge</span>}
                          <IconAngleArrowDown className='' />
                          {this.state.selectedCell === "add-Expense" &&
                            <SelectCustomComponent
                              name='charges'
                              searchable={true}
                              refClassName="cell-1"
                              listData={chargeCodeListForGL.filter((d) => d.isActive)}
                              keyName="chargeName"
                              onSelect={this.onAddSelectCharge}
                              unselectCell={this.unselectCell}
                            />}
                        </div>
                      </td>

                      <td 
                        className={`${this.state.chargeRefColor} chargeSetCell  text-dark ${!this.state.charge_reference_number ? 'requied_hover' : ""} 
                          ${this.state.selectedCell === "chargeSets" ? 'newdriverrow td-clicked' : ''}
                          pl-2 pr-1`
                        }
                        style={{ transition: 'background .4s ease-out' }}
                        onClick={() => this.selectCell("chargeSets")}
                      >
                        <div className="d-flex justify-content-between align-items-center text-dark">
                          {this.state.charge_reference_number?.toUpperCase() || <span className="text-muted">Select</span>}
                          <IconAngleArrowDown className='' />
                          {
                            this.state.selectedCell === "chargeSets" && (
                              <SelectCustomComponent
                                name='chargeSets'
                                searchable={true}
                                refClassName="chargeSetCell"
                                keyName="chargeName"
                                unselectCell={this.unselectCell}
                                onSelect={this.handleSelectChargeRefs}
                                listData={this.state.chargeSetReferenceNumbersList}
                              />
                            )
                          }
                        </div>
                      </td>

                      <td
                        className={`px-0 ${this.state.selectedCell === "description" ? 'newdriverrow td-clicked' : ''}`}
                        onClick={() => this.selectCell("description")}>
                        <input
                          type="text"
                          className={`form-control border-0`}
                          name="description"
                          value={this.state.description || ""}
                          onChange={(e) =>
                            this.setState({ description: e.target.value, isEdited: true })
                          }
                          disabled={disabled}
                          placeholder="Add Description..."
                          autoComplete="off"
                          onBlur={() => this.unselectCell()}
                          ref={(i) => { this.pricingDescription = i; }}
                        />
                      </td>
                      {this.state.name === "Fuel" ? (
                        <td
                          className={`lint px-0 cell-7 ${["FA1", "FA2", "fixed"].includes(this.state.selectedCell) && 'newdriverrow td-clicked'}`}
                        // onClick={() => this.selectCell("finalAmount")}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="mr-1 d-flex w-100" onClick={() => this.selectCell("FA1")}>
                              {!isGlobalizationEnabled() && <input
                                className="form-control border-0 text-left"
                                type="number"
                                disabled={disabled}
                                name="amount"
                                min={0}
                                value={this.state.amount || ""}
                                onChange={(e) => {
                                  this.setState({ amount: e.target.value, isEdited: true }, () => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "amount",
                                      },
                                    });
                                  });
                                }}
                                autoComplete="off"
                                onBlur={() => this.unselectCell()}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2
                                  selected={["FA1", "FA2", "fixed"].includes(this.state.selectedCell)}
                                  className="form-control border-0 text-left"
                                  childClassName="form-control border-0 text-left"
                                  type="number"
                                  disabled={disabled}
                                  name="amount"
                                  min={0}
                                  value={this.state.amount || ""}
                                  onChange={(e) => {
                                    this.setState({ amount: e.target.value, isEdited: true }, () => {
                                      this.newRowChanges({
                                        target: {
                                          value: e.target.value,
                                          name: "amount",
                                        },
                                      });
                                    });
                                  }}
                                  autoComplete="off"
                                  onBlur={() => this.unselectCell()}
                                  showCurrency={false}
                                  autoFocus={false}
                                />
                              )}
                            </div>
                            <div className="mr-1 d-flex"
                              onClick={() => this.selectCell("fixed")}
                            >
                              <div className="d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer"
                                onClick={() => this.selectCell("fixed")}
                              >
                                <div className="transition-y2">{this.state.type && this.state.type === "fixed" ? `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                                <div className="ml-20"><IconAngleArrowDown /></div>
                                {this.state.selectedCell === "fixed" &&
                                  <SelectCustomCurrency
                                    name='value'
                                    searchable={false}
                                    refClassName="cell-7"
                                    listData={[
                                      {
                                        value: "fixed",
                                        label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                      },
                                      {
                                        value: "percentage",
                                        label: "%",
                                      },
                                    ]}
                                    keyName="label"
                                    onSelect={(name, value) => {
                                      this.setState({ type: value.value, isEdited: true }, () => {
                                        this.newRowChanges({
                                          target: {
                                            value: value,
                                            name: "type",
                                          },
                                        });
                                      })
                                      this.unselectCell()
                                    }}

                                    unselectCell={this.unselectCell}
                                  />}
                              </div>
                            </div>
                            <div className="d-flex w-100" onClick={() => this.selectCell("FA2")}>
                              {!isGlobalizationEnabled() && <input
                                min={0}
                                placeholder="0.00"
                                onKeyDown={(e) => {
                                  this.keyPressOnNumberInput(e);
                                }}
                                className="form-control border-0 text-right"
                                type="number"
                                disabled={disabled}
                                name="finalAmount"
                                value={this.state.finalAmount || ""}
                                onChange={(e) => {
                                  this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "finalAmount",
                                      },
                                    });
                                  })
                                }}
                                autoComplete="off"
                                onBlur={() => this.unselectCell()}
                              />}
                              {isGlobalizationEnabled() && (
                                <NumberFormatterV2
                                  selected={["FA1", "FA2", "fixed"].includes(this.state.selectedCell)}
                                  min={0}
                                  placeholder="0.00"
                                  onKeyDown={(e) => {
                                    this.keyPressOnNumberInput(e);
                                  }}
                                  className="form-control border-0 text-right"
                                  type="number"
                                  disabled={disabled}
                                  name="finalAmount"
                                  value={this.state.finalAmount || ""}
                                  onChange={(e) => {
                                    this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                      this.newRowChanges({
                                        target: {
                                          value: e.target.value,
                                          name: "finalAmount",
                                        },
                                      });
                                    })
                                  }}
                                  autoComplete="off"
                                  onBlur={() => this.unselectCell()}
                                  showCurrency={false}
                                  autoFocus={false}
                                />
                              )}
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className={`px-0 ${this.state.selectedCell === "finalAmount" ? 'newdriverrow td-clicked' : ''}`}
                          onClick={() => this.selectCell("finalAmount")}
                        >
                          <span data-tip data-for={`discount${expense?.length}`}>
                            {!isGlobalizationEnabled() && <input
                              min={0}
                              placeholder="0.00"
                              type="number"
                              className={`form-control border-0 text-right`}
                              disabled={disabled}
                              name="finalAmount"
                              value={this.state.finalAmount || ""}
                              onChange={(e) => {
                                this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                  this.newRowChanges({
                                    target: {
                                      value: e.target.value,
                                      name: "finalAmount",
                                    },
                                  });
                                })
                              }}
                              autoComplete="off"
                              onBlur={() => this.unselectCell()}
                              ref={(i) => { this.pricingFreeUnit = i; }}
                            />}
                            {isGlobalizationEnabled() && (
                              <NumberFormatterV2
                                selected={this.state.selectedCell === "finalAmount"}
                                min={0}
                                placeholder="0.00"
                                type="number"
                                className={`form-control border-0 text-right`}
                                disabled={disabled}
                                name="finalAmount"
                                value={this.state.finalAmount || ""}
                                onChange={(e) => {
                                  this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                    this.newRowChanges({
                                      target: {
                                        value: e.target.value,
                                        name: "finalAmount",
                                      },
                                    });
                                  })
                                }}
                                autoComplete="off"
                                onBlur={() => this.unselectCell()}
                                ref={(i) => { this.pricingFreeUnit = i; }}
                                showCurrency={false}
                              />
                            )}
                          </span>
                          {this.state.discountApplied &&
                            <ReactTooltip id={`discount${expense?.length}`}>
                              <span>A discount has been applied</span>
                            </ReactTooltip>
                          }
                        </td>
                      )}
                      <td
                        className={`lint px-0 ${this.state.selectedCell === "unit" && 'newdriverrow td-clicked'}`}
                        onClick={() => this.selectCell("unit")}
                      >
                        <div>
                          <NewPriceInput
                            name="unit"
                            value={this.state.unit}
                            onChange={(val) => {
                              this.setState({ unit: val, isEdited: true }, () => {
                                this.newRowChanges({
                                  target: {
                                    value: val,
                                    name: "unit",
                                  },
                                });
                              })
                            }}
                            selected={this.state.selectedCell === "unit"}
                          // disabled={false}
                          />
                        </div>
                      </td>

                      <td className={`px-1 pl-2 cell-5 ${this.state.selectedCell === "perType" ? 'newdriverrow td-clicked' : ''}`}
                        onClick={() => this.selectCell("perType")}>
                        <div className="d-flex justify-content-between align-items-center">
                          {this.state.perType || <span className="text-muted">Select...</span>}
                          <IconAngleArrowDown className='' />
                          {this.state.selectedCell === "perType" &&
                            <SelectCustomComponent
                              name='perType'
                              searchable={true}
                              refClassName="cell-5"
                              listData={options}
                              keyName="label"
                              onSelect={(name, value) =>
                                this.setState({ perType: value.value, isEdited: true, selectedCell: null }, () => {
                                  this.newRowChanges({
                                    target: {
                                      value: value,
                                      name: "perType",
                                    },
                                  });
                                  if (this.pricingFreeUnit) this.pricingFreeUnit.focus();
                                })
                              }
                              unselectCell={this.unselectCell}
                            />}
                        </div>
                      </td>
                      <td
                        className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ''} px-0 pl-2 ${this.state.selectedCell === "freeDays" && 'newdriverrow td-clicked'}`}
                        onClick={() => {
                          if (!this.state.perType) return
                          this.selectCell("freeDays")
                        }}
                      >
                        {!isGlobalizationEnabled() && <input
                          min={0}
                          type="number"
                          className={`form-control border-0 text-right`}
                          name="freeDays"
                          onKeyDown={(e) => {
                            this.keyPressOnNumberInput(e);
                          }}
                          disabled={!this.state.perType || disabled}
                          value={this.state.freeDays || ""}
                          onChange={(e) => {
                            this.state.perType &&
                              this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                this.newRowChanges({
                                  target: {
                                    value: e.target.value,
                                    name: "freeDays",
                                  },
                                });
                              })
                          }}
                          placeholder="0"
                          autoComplete="off"
                          onBlur={() => this.unselectCell()}
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2
                            selected={this.state.selectedCell === "freeDays"}
                            min={0}
                            type="number"
                            className={`form-control border-0 text-right`}
                            name="freeDays"
                            onKeyDown={(e) => {
                              this.keyPressOnNumberInput(e);
                            }}
                            disabled={!this.state.perType || disabled}
                            value={this.state.freeDays || ""}
                            onChange={(e) => {
                              this.state.perType &&
                                this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                  this.newRowChanges({
                                    target: {
                                      value: e.target.value,
                                      name: "freeDays",
                                    },
                                  });
                                })
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={() => this.unselectCell()}
                            disablePlaceholder={!this.state.perType}
                            showCurrency={false}
                          />
                        )}
                      </td>
                      <td
                        className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ''} px-0 pl-2 ${this.state.selectedCell === "chargePerDay" && 'newdriverrow td-clicked'}`}
                        onClick={() => {
                          if (!this.state.perType) return
                          this.selectCell("chargePerDay")
                        }}
                      >
                        {!isGlobalizationEnabled() && <input
                          min={0}
                          type="number"
                          className={`form-control border-0 text-right`}
                          name="chargePerDay"
                          disabled={!this.state.perType || disabled}
                          value={this.state.chargePerDay || ""}
                          onChange={(e) => {
                            this.state.perType &&
                              this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                this.newRowChanges({
                                  target: {
                                    value: e.target.value,
                                    name: "chargePerDay",
                                  },
                                });
                              })
                          }}
                          placeholder="0"
                          autoComplete="off"
                          onBlur={() => this.unselectCell()}
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2
                            selected={this.state.selectedCell === "chargePerDay"}
                            min={0}
                            type="number"
                            className={`form-control border-0 text-right`}
                            name="chargePerDay"
                            disabled={!this.state.perType || disabled}
                            value={this.state.chargePerDay || ""}
                            onChange={(e) => {
                              this.state.perType &&
                                this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                  this.newRowChanges({
                                    target: {
                                      value: e.target.value,
                                      name: "chargePerDay",
                                    },
                                  });
                                })
                            }}
                            placeholder="0"
                            autoComplete="off"
                            onBlur={() => this.unselectCell()}
                            showCurrency={false}
                            disablePlaceholder={!this.state.perType}
                          />
                        )}
                      </td>
                      {showSave && (
                        <td className="">
                          <button
                            className="btn btn-xs btn-circle bg-soft-danger"
                            onClick={() => !this.state.isAPICall && this.DeletePriceRow(expense?.length)}
                          >
                            <IconMinus className="text-danger" />
                          </button>
                        </td>
                      )}
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="my-10">
              <button className="btn btn-white btn-sm text-primary"
                onKeyDown={(e) => driverExpenseEditPermission && this.handleOnKeyDown(e)}
                id="addChargeFocus"
                onFocus={this.focusOnButton}
                disabled={!driverExpenseEditPermission}
              >
                <a
                  className="text-primary font-weight-500"
                  onClick={() => {
                    if(driverExpenseEditPermission) {
                      const element = document?.getElementById("addChargeFocus")
                      element.style.border = "none"
                      this.setState({ addExpense: true, isFailed: "transition-white-field", nameColor: "transition-white-field", chargeRefColor: "transition-white-field" }, () => { this.props.blockTabChange(true); this.selectCell("add-Expense") })
                    }
                  }}
                >
                  <IconPlus className="mr-2" />
                  Add Expense
                </a>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser)
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      )
        return this.renderReturn();
      else if (loggedInUser.permissions.length !== 0)
        if (
          loggedInUser.permissions.includes("customer_service_billing") ||
          (loggedInUser.role === "fleetmanager" &&
            loggedInUser.fleetManager.isCustomer &&
            loggedInUser.permissions.includes("customer_service_billing"))
        )
          return this.renderReturn();
        else
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
      else
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    tmsAction: bindActionCreators(tmsAction, dispatch),
    dispatch: dispatch,
  };
}
const mapStateToProps = (state) => {
  return {
    chargeCode: state.chargeCodeReducer.chargeCode,
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(ExpensesTable);

const options = types;



