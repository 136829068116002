import React, { useState, useEffect, useMemo, useRef } from "react";
import Select from "react-select";
import _ from 'lodash';
import { IconTrash, IconClone } from "Components/Common/Icons";
import { smallSelectStyle } from "assets/js/select-style";
import TemplateRules from "./TemplateRules";
import TemplateTimingPreferences from "./TemplateTimingPreferences";
import { toastr } from 'services';
import { generate24HrsData, generateFactorHrsData, getTimePreferences, compareTemplates, generateRequestTimeOption } from '../constants'
import { DAY_PREFERENCE, MOVE_TYPE_CONSTANTS, LOAD_TYPE_CONSTANTS } from '../constants'
import * as appointmentSystemServices from "services/appointmentSystem.services";
import CellSpinner from "Components/Common/Spinner/CellSpinner";
import { templateDataMapper, generateTimePreferences, generateDayPreferences } from '../utils/dataMapper';
import { NEW_APPOINTMENT_DELETE_MESSAGE } from "constants/appointmentSystem.constant";
import PromptAlert from "pages/tms/AppointmentModal/Components/PromptAlert";

const TemplateBlock = (props) => {
  const { 
    countries,
    selectedApptTemplate,
    setFilteredAppointmentTemplates,
    filteredAppointmentTemplates,
    getAppointmentTemplates, 
    isFromCompanySettings,
    moveType,
    load,
    appointmentId,
    buildPayload,
    closeModal,
    setIsAppointmentModal,
    setSelectedTemplateRow,
    setIsAppointmentProcessing,
    isCloned
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const [isApplying, setIsApplying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isPrompt, setIsPrompt] = useState(null);
  const [isEqual, setIsEqual] = useState(false);
  const [settings, setSettings] = useState(null);
  const [generatedTimePreferences, setGeneratedTimePreferences] = useState([]);
  const defaultTimingPreferences = useRef(null);

  const twentyFourHrData = useMemo(() => generate24HrsData(), []);
  const requestTimeOptions = useMemo(() => generateRequestTimeOption(), []);
  const twentyFourFactorData = useMemo(() => generateFactorHrsData(24), []);

  const handleTemplateToSettingsMapping = () => {
    if (!selectedApptTemplate) return;

    const timeBucketHrs = parseInt(selectedApptTemplate.timeBucketInHrs);
    const timePreference = getTimePreferences(timeBucketHrs);
    const mappedSettings = templateDataMapper(selectedApptTemplate, 'ui', timePreference);

    setGeneratedTimePreferences(timePreference);
    setSettings(mappedSettings);

    const { relativeKey, timePreferences, dayPreferences } = mappedSettings;
    defaultTimingPreferences.current = {
      key: relativeKey,
      time: timePreferences,
      day: dayPreferences
    };
  };

  // const handleCancelChanges = () => {
  //   handleTemplateToSettingsMapping();
  // }

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setSettings(prev => {
      const newSettings = { ...prev, [id]: value };
      return newSettings;
    });
  };

  const handleCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    
    setSettings(prev => {
      let newSettings;
      if (id === 'automaticRescheduleAfterFallbackBooked') {
        newSettings = {
          ...prev,
          [id]: checked,
          automaticRescheduleAfterFallbackBookedTime: checked ? prev.automaticRescheduleAfterFallbackBookedTime : '0h'
        };
      } else if (id === 'isDefaultBackgroundTemplate') {
        newSettings = { 
          ...prev, 
          [id]: checked,
          rules: checked ? (prev.rules || []) : null
        };
      } else {
        newSettings = { ...prev, [id]: checked };
      }
      return newSettings;
    });

  };

  const handleRadioChange = (e) => {
    const { name, id } = e.target;

    setSettings(prev => {
      const newSettings = { ...prev, [name]: id };
      return newSettings;
    });

  };

  const handleSelectChange = (name, value) => {

    setSettings(prev => {
      let newSettings = { ...prev, [name]: value };
      if (name === "timeBucketInHrs" && value?.value) {
        newSettings = { 
          ...newSettings, 
          timePreferences: {},
        };
      }
      return newSettings;
    });

  };

  const handleTimePreferenceRowClick = ({ index, timeBucket, time, label, value, additionalUpdates }) => {

    setSettings(prev => {
      const updatedTimePreferences = {
        ...prev?.timePreferences,
        [index]: {
          ...prev?.timePreferences?.[index],
          time,
          timeBucket,
          [label]: value,
          ...(additionalUpdates || {})
        }
      };
      const newSettings = {
        ...prev,
        timePreferences: updatedTimePreferences
      };
      return newSettings;
    });

  };

  const handledayPreferencesRowClick = ({ index, label, value, type, day, additionalUpdates }) => {

    setSettings(prev => {
      const updatedDayPreferences = {
        ...prev?.dayPreferences,
        [index]: {
          ...prev?.dayPreferences?.[index],
          type,
          day,
          [label]: value,
          ...(additionalUpdates || {})
        }
      };
      const newSettings = {
        ...prev,
        dayPreferences: updatedDayPreferences
      };
      return newSettings;
    });

  };

  const handleTimingPreferencesChange = (preferences) => {

    if (!Array.isArray(preferences) || !preferences.length) return;
    
    setSettings(prev => {
      const newSettings = { ...prev };
      preferences.forEach(({ setterKey, value }) => {
        newSettings[setterKey] = value;
      });
      return newSettings;
    });

  };

  const handleTemplateRuleChange = (val) => {
    
    setSettings((prev) => {
      let newSettings = _.cloneDeep(prev);
      return {...newSettings, rules: val};
    });

  }

  const handleSelectSearchValidation = (inputValue) => {
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    return numericValue;
  };

  const handleTemplateSelectionOnDelete = (templates, templateId) => {
    let currentIndex;
    if (templateId) {
      currentIndex = templates.findIndex(template => template._id === templateId);
    } else {
      currentIndex = templates.findIndex(template => template.isNew);
    }

    if (currentIndex > 0) {
      setSelectedTemplateRow(currentIndex - 1);
    } else if (templates.length > 1) {
      setSelectedTemplateRow(0); 
    } else {
      setSelectedTemplateRow(null);
    }
  };

  const deleteApptTemplateThroughPrompt = async (templateId) => {
    try {
      setIsDeleting(true);
      const { message } = await appointmentSystemServices.deleteAppointmentTemplates(templateId);
      setIsPrompt(null);
      toastr.show(`${message || "Successfully deleted appointment template."}`, "success");

      await getAppointmentTemplates();
      handleTemplateSelectionOnDelete(filteredAppointmentTemplates, templateId);

    } catch (e) {
      toastr.show(`${e?.message || "Unable to delete auto appointment template."}`, "error");
    } finally {
      setIsDeleting(false);
    }
  }

  const handleDeleteAppointment = async (templateId) => {
    if (templateId) {
      setIsPrompt(NEW_APPOINTMENT_DELETE_MESSAGE)
    } else {
      setFilteredAppointmentTemplates((prev) => {
        const filteredTemplates = prev.filter((template) => template._id);
        return filteredTemplates;
      });
      handleTemplateSelectionOnDelete(filteredAppointmentTemplates, templateId);
    }
  }

  const handleSaveUpdateTemplate = async () => {
    if (!settings?.templateName?.trim()) {
      toastr.show("Template name is required.", "error");
      return;
    }

    const hasTimePreferences = Object.values(settings?.timePreferences || {}).some(item => item?.isPriority || item?.isFallback);
    if (!hasTimePreferences) {
      toastr.show("Time preferences are required.", "error");
      return;
    }

    const hasDayPreferences = Object.values(settings?.dayPreferences || {}).some(item => item?.isPriority || item?.isFallback);
    if (!hasDayPreferences && settings?.relativeKey != 'firstAvailableDate') {
      toastr.show("Day preferences are required.", "error");
      return;
    }

    setIsSaving(true);
    try {
      const payload = templateDataMapper(settings, 'payload');
      if (settings?._id) {
        const changedValues = compareTemplates(selectedApptTemplate, payload, 'differences');

          if (changedValues && Object.keys(changedValues?.dayPreferences || {}).length > 0) {
            changedValues.dayPreferences = generateDayPreferences(changedValues.dayPreferences, null, 'payload');
          }
          if (changedValues && Object.keys(changedValues?.timePreferences || {}).length > 0) {
            changedValues.timePreferences = generateTimePreferences(changedValues.timePreferences, null, 'payload');
          }
          const { data, message } = await appointmentSystemServices.updateAppointmentTemplates(settings._id, {
            relativeTo: payload?.relativeTo,
            moveType: payload?.moveType,
            loadType: payload?.loadType,
            ...changedValues,
            dayPreferences: payload?.dayPreferences,
          });

          if (data) {
            toastr.show(`${message || "Successfully updated appointment template."}`, "success");
            getAppointmentTemplates(false);
          }


      } else {
        const { data, message } = await appointmentSystemServices.postAppointmentTemplates(payload);

        if (data) {
          toastr.show(`${message || 'Successfully added appointment template.'}`, "success");
          getAppointmentTemplates(false);
        }

      }
    } catch (error) {
      toastr.show(error?.message || "Failed to save appointment template.", "error");
    } finally {
      setIsSaving(false);
    }
  };

  const handleApplyTemplate = async () => {
    setIsApplying(true);
    try {
      const payload = buildPayload(load, moveType, appointmentId);
      if (Array.isArray(payload) && payload.length > 0) {
        // Track invalid loads and their reasons

        const invalidMessages  = payload.reduce((messages, item) => {
          
          if (item.moveType !== selectedApptTemplate?.moveType) {
            messages.push(`Failed to apply Template for load: ${item.referenceNumber}. Template is not valid for this move type`);
            return messages;
          }
          if (item.loadType !== selectedApptTemplate?.loadType) {
            messages.push(`Failed to apply Template for load: ${item.referenceNumber}. Template is not valid for this load type`);
            return messages;
          }

          return messages;
        }, []);

        const validPayloads = payload.filter(item => 
          item.moveType === selectedApptTemplate?.moveType && 
          item.loadType === selectedApptTemplate?.loadType
        );

        if(invalidMessages.length > 0) {
          invalidMessages.forEach(message => toastr.show(message, "error"));
        }

        if (validPayloads.length === 0) {
          toastr.show("No valid loads to process", "error");
          return;
        }

        const appointmentPayloads = validPayloads.map(item => ({
          ...item,
          templateId: selectedApptTemplate?._id
        }));

        // Show processing toast and update states
        toastr.show("Appointments are processing.", "info");
        setIsApplying(false);
        setIsAppointmentProcessing(false);
        setIsAppointmentModal(false);
        closeModal();

        // Using Promise.allSettled instead of Promise.all
        const results = await Promise.allSettled(
          appointmentPayloads.map(({referenceNumber, ...rest}) => 
            appointmentSystemServices.createAppointmentSystem({...rest})
              .then(response => ({
                status: 'fulfilled',
                referenceNumber,
                data: response.data,
                message: response.message
              }))
              .catch(error => ({
                status: 'rejected',
                referenceNumber,
                message: error?.data?.message ? `Failed to apply Template for ${referenceNumber}: ${error?.data?.message}` : `Failed to apply template for: ${referenceNumber}`
              }))
          )
        );

        // Separate successful and failed results
        const successes = results.filter(r => r.value?.status === 'fulfilled');
        const failures = results.filter(r => r.value?.status === 'rejected');

        // Show success message if any appointments were created
        if (successes.length > 0) {
          toastr.show(
            `Successfully applied template to ${successes.length} loads`, 
            "success"
          );
        }

        // Show error messages for failed appointments
        failures.forEach(result => {
          toastr.show(
            `${result.value.message} for load: ${result.value.referenceNumber}`, 
            "error"
          );
        });

      } else {
        const {referenceNumber, appointmentId, ...rest} = payload;
        const builtPayload = appointmentId ? 
          { ...rest, appointmentId, templateId: selectedApptTemplate?._id } :
          { ...rest, templateId: selectedApptTemplate?._id};

        const { data, message } = await appointmentSystemServices.createAppointmentSystem(builtPayload);
        data && toastr.show(`${message || "Successfully applied appointment template"}`, "success");
      }

    } catch (error) {
      toastr.show(error?.data?.message || error?.message || "Failed to apply appointment template", "error");
    } finally {
      setIsApplying(false);
      setIsAppointmentProcessing(false);
      setIsAppointmentModal(false);
      closeModal();
    }
  };

  const getNextAvailableVersion = (baseName, existingTemplates) => {
    // Use a more efficient regex that prevents backtracking
    const baseNameWithoutVersion = baseName.replace(/\sV\d+$/, '').trim();
    
    // Find all existing versions of this template
    const existingVersions = existingTemplates
      .filter(template => template.templateName.startsWith(baseNameWithoutVersion))
      .map(template => {
        const match = template.templateName.match(/V(\d+)$/);
        return match ? parseInt(match[1]) : 1;
      });

    // If no versions exist, start with V2
    if (existingVersions.length === 0) {
      return `${baseNameWithoutVersion} V2`;
    }

    // Find the highest version number and increment by 1
    const nextVersion = Math.max(...existingVersions) + 1;
    return `${baseNameWithoutVersion} V${nextVersion}`;
  };

  const handleCloneTemplate = async () => {
    setIsCloning(true);

    try {
      const payload = templateDataMapper(settings, 'payload');
      const currentName = payload.templateName || '';
      
      // Get next available version name
      payload.templateName = getNextAvailableVersion(currentName, filteredAppointmentTemplates);

      if(payload?.isDefaultBackgroundTemplate) {
        payload.isDefaultBackgroundTemplate = false;
      }

      const { data, message } = await appointmentSystemServices.postAppointmentTemplates(payload);

      if (data) {
        toastr.show(`${message || "Successfully cloned appointment template"}`, "success");
        setSettings(null);
        isCloned.current = true;
        getAppointmentTemplates(false);
      } 

    } catch (error) {
      toastr.show(error?.message || "Failed to clone appointment template", "error");
    } finally {
      setIsCloning(false);
    }
  };

  useEffect(() => {
    if (selectedApptTemplate && Object.keys(selectedApptTemplate)?.length) {
      handleTemplateToSettingsMapping();
    }
  }, [selectedApptTemplate]);

  useEffect(() => {

    if (!settings) return;

    const updatedTemplate = templateDataMapper(settings, 'payload')
    if (compareTemplates(selectedApptTemplate, updatedTemplate, 'isEqual')) {
      setIsEqual(true);
    } else {
      setIsEqual(false);
    }
    
    if (settings?.timeBucketInHrs?.value !== selectedApptTemplate?.timeBucketInHrs?.value) {
      const timePreferences = getTimePreferences(settings?.timeBucketInHrs?.value);
      setGeneratedTimePreferences(timePreferences);
    }

  }, [settings])

  return (
    <div className="bg-white rounded-10 h-100 d-flex flex-column">
      <div className="px-20 py-15 d-flex flex-column gap-15 h-100 overflow-y-auto">
        {/* Template Name */}
        <div className="">
          <label>Template Name</label>
          <div className="d-flex align-items-center gap-10">
            <input 
              type="text" 
              className="form-control" 
              id="templateName" 
              value={settings?.templateName || ''} 
              placeholder="Template Name"
              onChange={handleInputChange}
            />
            <button 
              className="btn btn-outline-light" 
              onClick={() => handleDeleteAppointment(settings?._id)}
            >
              <IconTrash />
            </button>
          </div>
        </div>

        {/* Template Type */}
        <div className="d-flex gap-20 align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-5">
            <div className={`${settings?.moveType === MOVE_TYPE_CONSTANTS?.PICKUP ? 'bg-primary-50' : ''} px-20 py-10 rounded-5 outline-1 ${settings?.moveType === MOVE_TYPE_CONSTANTS?.PICKUP ? 'outline-primary' : 'outline-gray-100'}`}>
              <div class="form-check cursor-pointer">
                <input class="form-check-input" name="moveType" type="radio" id={MOVE_TYPE_CONSTANTS?.PICKUP} checked={settings?.moveType === MOVE_TYPE_CONSTANTS?.PICKUP}  onChange={handleRadioChange}/>
                <label class="form-check-label cursor-pointer" htmlFor={MOVE_TYPE_CONSTANTS?.PICKUP}>
                  Pick Up Apt.
                </label>
              </div>
            </div>
            <div className={`px-20 py-10 rounded-5 outline-1 ${settings?.moveType === MOVE_TYPE_CONSTANTS?.EMPTY ? 'outline-primary bg-primary-50' : 'outline-gray-100'}`}>
              <div class="form-check cursor-pointer">
                <input class="form-check-input" name="moveType" type="radio" id={MOVE_TYPE_CONSTANTS?.EMPTY} checked={settings?.moveType === MOVE_TYPE_CONSTANTS?.EMPTY} onChange={handleRadioChange}/>
                <label class="form-check-label cursor-pointer" htmlFor={MOVE_TYPE_CONSTANTS?.EMPTY}>
                  Return Apt.
                </label>
              </div>
            </div>
          </div>
          <div className="vr-line mx-0" />
          <div className=" px-20 py-10 rounded-5 outline-1 outline-gray-100">
            <div class="form-check form-switch form-switch-md">
              <input type="checkbox" class="form-check-input" id="isDefaultBackgroundTemplate" checked={settings?.isDefaultBackgroundTemplate} onChange={handleCheckBoxChange} />
              <div className="d-flex flex-column">
                <label class="mb-0 ml-15 font-14 font-weight-500 d-flex flex-column cursor-pointer" htmlFor="isDefaultBackgroundTemplate">
                  Use this template on a background basis
                  <span className="text-muted font-12 line-height-15">Toggle on this setting to automatically book appointments in accordance with this template in the background.</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-5">
            <div className={`${settings?.loadType === LOAD_TYPE_CONSTANTS?.IMPORT ? 'bg-primary-50' : ''} px-20 py-10 rounded-5 outline-1 ${settings?.loadType === LOAD_TYPE_CONSTANTS?.IMPORT ? 'outline-primary' : 'outline-gray-100'}`}>
              <div class="form-check cursor-pointer">
                <input class="form-check-input" name="loadType" type="radio" id={LOAD_TYPE_CONSTANTS?.IMPORT} checked={settings?.loadType === LOAD_TYPE_CONSTANTS?.IMPORT}  onChange={handleRadioChange}/>
                <label class="form-check-label cursor-pointer" htmlFor={LOAD_TYPE_CONSTANTS?.IMPORT}>
                  Import
                </label>
              </div>
            </div>
            <div className={`${settings?.loadType === LOAD_TYPE_CONSTANTS?.EXPORT ? 'bg-primary-50' : ''} px-20 py-10 rounded-5 outline-1 ${settings?.EXPORT === LOAD_TYPE_CONSTANTS?.EXPORT ? 'outline-primary bg-primary-50' : 'outline-gray-100'}`}>
              <div class="form-check cursor-pointer">
                <input class="form-check-input" name="loadType" type="radio" id={LOAD_TYPE_CONSTANTS?.EXPORT} checked={settings?.loadType === LOAD_TYPE_CONSTANTS?.EXPORT} onChange={handleRadioChange}/>
                <label class="form-check-label cursor-pointer" htmlFor={LOAD_TYPE_CONSTANTS?.EXPORT}>
                  Export
                </label>
              </div>
            </div>
          </div>

        {/* Template Settings */}
        <div className=" d-flex flex-column gap-5">
          <div className="font-14 font-medium d-flex align-items-center gap-10 mb-10">
            Book appointment this many hours after request initiated
            <Select
              styles={{ smallSelectStyle }}
              value={settings?.bookAfterTriggerTime}
              id="bookAfterTriggerTime"
              options={requestTimeOptions}
              onChange={(e) => handleSelectChange("bookAfterTriggerTime", e)}
              onInputChange={handleSelectSearchValidation}
            />
          </div>

          <div class="form-check form-switch form-switch-md h-30px">
            <input type="checkbox" class="form-check-input" id="bookDuringWeekend" checked={settings?.bookDuringWeekend} onClick={handleCheckBoxChange}/>
            <div className="d-flex flex-column">
              <label class="mb-0 ml-15 font-14 font-weight-500 cursor-pointer"htmlFor="bookDuringWeekend">Book appointments during the weekend</label>
            </div>
          </div>

          <div class="form-check form-switch form-switch-md h-30px">
            <input type="checkbox" class="form-check-input" id="bookEvenIfContainerHasHolds" checked={settings?.bookEvenIfContainerHasHolds} onClick={handleCheckBoxChange}/>
            <div className="d-flex flex-column">
              <label class="mb-0 ml-15 font-14 font-weight-500 cursor-pointer" htmlFor="">Book appointments even container has holds</label>
            </div>
          </div>
          <div class="form-check form-switch form-switch-md h-30px">
            <input type="checkbox" class="form-check-input" id="automaticRescheduleMissed" checked={settings?.automaticRescheduleMissed} onClick={handleCheckBoxChange}/>
            <div className="d-flex flex-column">
              <label class="mb-0 ml-15 font-14 font-weight-500 cursor-pointer" htmlFor="automaticRescheduleMissed"> Automatically reschedule my appointment missed</label>
            </div>
          </div>
          <div class="form-check form-switch form-switch-md h-30px">
            <input type="checkbox" class="form-check-input" id="automaticRescheduleAfterFallbackBooked" checked={settings?.automaticRescheduleAfterFallbackBooked} onClick={handleCheckBoxChange}/>
            <div className="d-flex align-items-center gap-10 font-14 font-medium">
              <label class="mb-0 ml-15 font-weight-500 cursor-pointer" htmlFor="automaticRescheduleAfterFallbackBooked">
                Automatically reschedule my appointment if a fall back is booked and a priority apt becomes available{" "}
              </label>
              <Select
                styles={{ smallSelectStyle }}
                value={settings?.automaticRescheduleAfterFallbackBookedTime}
                isDisabled={!settings?.automaticRescheduleAfterFallbackBooked}
                id="automaticRescheduleAfterFallbackBookedTime"
                options={twentyFourHrData}
                onChange={(e) => handleSelectChange("automaticRescheduleAfterFallbackBookedTime", e)}
                onInputChange={handleSelectSearchValidation}
              />
            </div>
          </div>
        </div>

        <div className="hr-line my-0" />

        {
          settings?.isDefaultBackgroundTemplate && (
            <TemplateRules
              key={settings?._id}
              rules={settings?.rules}
              setRules={handleTemplateRuleChange}
              countries={countries}
            />
          )
        }

        <div className="hr-line my-0" />
        <TemplateTimingPreferences
          timePreferencesValue={settings?.timePreferences}
          dayPreferencesValue ={settings?.dayPreferences}  
          timePreferences={generatedTimePreferences}
          timeBucketOptions={twentyFourFactorData} 
          timeBucketInHrs={settings?.timeBucketInHrs} 
          handleSelectChange={handleSelectChange}
          handleTimePreferenceRowClick={handleTimePreferenceRowClick}
          handledayPreferencesRowClick={handledayPreferencesRowClick}
          handleTimingPreferencesChange={handleTimingPreferencesChange}
          dayPreferencesOptions={DAY_PREFERENCE}
          timingPreferencesTab={settings?.relativeKey}
          defaultTimingPreferences={defaultTimingPreferences?.current || {}}
        />
      </div>
      <div className="hr-light my-0" />
      <div className="p-20 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-5">
          <button className="btn btn-outline-light" onClick={handleCloneTemplate}>
            <IconClone className="mr-1" />
            Clone to New Template
            {isCloning && <CellSpinner className="ml-1" />}
          </button>
        </div>
        <div className="d-flex align-items-center gap-5">
          {/* <button className="btn btn-outline-light" onClick={handleCancelChanges}>
            Cancel
          </button> */}
          {!isFromCompanySettings && (
            <button
              className="btn btn-outline-light"
              onClick={handleSaveUpdateTemplate}
              disabled={isEqual}
            >
              {settings?.isNew ? 'Save' : 'Update'} Template
              {isSaving && <CellSpinner className="ml-1" />}
            </button>
          )}

          <button
            className="btn btn-primary"
            onClick={isFromCompanySettings ? handleSaveUpdateTemplate : handleApplyTemplate}
            disabled={isFromCompanySettings ? isEqual : !isEqual}
          >
            {isFromCompanySettings ? (
              <>
                {settings?.isNew ? 'Save' : 'Update'} Template
                {isSaving && <CellSpinner className="ml-1" />}
              </>
            ) : (
              <>
                Apply Template
                {isApplying && <CellSpinner className="ml-1" />}
              </>
            )}
          </button>
        </div>
      </div>
      <PromptAlert
        showPrompt={isPrompt}
        content={isPrompt}
        confirm={() => {
          deleteApptTemplateThroughPrompt(selectedApptTemplate?._id);
        }}
        closePrompt={() => {
          setIsPrompt(null);
        }}
        modalBodyClass="px-10"
        loader={isDeleting}
      />
    </div>
  );
};

export default TemplateBlock;
