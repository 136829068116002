import moment from "moment";

const SIDE_PANEL_MAP_OVERLAY_EVENTS = Object.freeze({
  TRUCK_SELECTED: "TRUCK_SELECTED",
  TRUCK_UNSELECTED: "TRUCK_UNSELECTED",
  LOAD_SELECTED: "TRUCK_UNSELECTED",
  LOAD_UNSELECTED: "TRUCK_UNSELECTED",
});

const STATES_ABBREVIATION = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "West Virginia": "WV",
  "Virginia": "VA",
  "Washington": "WA",
  "Wisconsin": "WI",
  "Wyoming": "WY",
  "California": "CA",
  "Delaware": "DE",
};

export const getTimeZone = (options = {}) => {
  let timeZone = localStorage.getItem("timeZone");
  const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
  if (setting?.isUserSpecificTimeZoneEnabled && options?.preferred) {
    timeZone = JSON.parse(localStorage.getItem("loggedInUser"))?.preferredTimezone || timeZone;
  }
  return timeZone || "America/New_York";
}

const getRanges = () => {
  const timeZone = getTimeZone();

  return {
    Today: [
      moment().tz(timeZone).startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    Yesterday: [
      moment().tz(timeZone).subtract(1, "days").startOf("day"),
      moment().tz(timeZone).subtract(1, "days").endOf("day"),
    ],
    "Last 7 Days": [
      moment().tz(timeZone).subtract(6, "days").startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    "Last 14 Days": [
      moment().tz(timeZone).subtract(13, "days").startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    "This Month": [
      moment().tz(timeZone).startOf("month").startOf("day"),
      moment().tz(timeZone).endOf("month").endOf("day"),
    ]
  };
};


export { SIDE_PANEL_MAP_OVERLAY_EVENTS,STATES_ABBREVIATION, getRanges };
