import React, { useContext, useState, useCallback } from 'react'
import { Modal } from "react-bootstrap"
import { LABEL_COLORS } from './const'
import { convertToTitleCase, getBackgroundColorByClassName, getIconV2, getLabelOrFolder, getLabelV2, setDefaultSystemFolderForOutLook } from "./../../../helper"
import { createFolder, createLabel, getFolders } from "./../../../actionCreator"
import { amplitudeTrack, getStorage, isDesktopMailboxUI, toastr } from '../../../../../../services/Common.services'
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, convertFolderNameForOutlook, EMAIL_CONTEXT, EMAIL_PROVIDER, OUTLOOK_PROVIDER } from '../../../constant'
import _ from 'lodash'
import { smallSelectStyle } from 'assets/js/select-style'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'

const AddLabelModal = (props) => {

    const context = useContext(EMAIL_CONTEXT)
    const { allLabels, setAllLabels, allFolderRef, allAccountFolders } = context
    const {
        show,
        modalRef,
        selectedColor,
        handleColorCheckboxChange,
        handleCreateLabelModel,
        onAddFolder,
        folder
    } = props

    
    const allConnectedAccounts = JSON.parse(getStorage('allConnectedEmailAccounts'))
    let provider = allConnectedAccounts?.find(account => account?.accountId === folder?.grantId)?.provider

    if(!provider){
        const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
        provider = embeddedEmailAccount?.provider
    }

    const currentActiveProviderGrantId = JSON.parse(getStorage('embeddedEmailAccount'))?.accountId

    const [labelName, setlabelName] = useState("")
    const [isApiCall, setIsApiCall] = useState(false)
    const [parentFolder, setParentFolder] = useState({ label: folder?.name, value: folder })
    const [folders, setFolders] = useState(() => {
        const systemFolderOrder = {
            'Inbox': 0,
            'Sent Items': 1,
            'Drafts': 2,
            'Junk Email': 3,
            'Deleted Items': 4,
            'Archive': 5
        };

        const flds = allAccountFolders[folder?.grantId]?.map((fld) => ({
            label: fld.name,
            value: fld.id,
            isSystemFolder: fld.name in systemFolderOrder,
            order: systemFolderOrder[fld.name] ?? 999
        }));

        return (flds ?? []).sort((a, b) => {
            if (a.isSystemFolder && b.isSystemFolder) {
                return a.order - b.order;
            }
            if (a.isSystemFolder) return -1;
            if (b.isSystemFolder) return 1;
            return a.label.localeCompare(b.label);
        }).map(({ label, value }) => ({ label, value }));
    });

    const addNewLabelToNav = (newLabel) => {
        const oldLabels = _.cloneDeep(allLabels)
        const labelToAdd = {
            id: newLabel?.id,
            background_color: newLabel?.background_color,
            display_name: newLabel?.name,
            name: newLabel?.name,
            text_color: newLabel?.text_color
        }
        const newLabelData = [...oldLabels, labelToAdd]
        allFolderRef.current = newLabelData
        setAllLabels(newLabelData)
    }

    const handleChangeLabelName = (e) => {
        const cleanedInput = e.target.value;
        if (cleanedInput.trim() !== '' || cleanedInput === '') {
            setlabelName(cleanedInput);
        } 
    }

    const handleParentFolderChange = useCallback((e) => {
        setParentFolder(e);
    }, []);


    const addlabel = () => {
        if(!labelName || !labelName?.length){
            let errorName = ""
            if (provider === EMAIL_PROVIDER.GMAIL) {
                errorName = "label"
            } else if (OUTLOOK_PROVIDER.includes(provider)) {
                errorName = "folder"
            }
            toastr.show(`Add ${errorName} name.`,"error")
            return
        }
        setIsApiCall(true)
        const bgColor = getBackgroundColorByClassName(selectedColor)
        const param = {
            displayName: labelName?.trim() ?? ""
        }
        if(bgColor){
            param.backgroundColor = bgColor
        }
        if(provider === EMAIL_PROVIDER.GMAIL){
            createLabel(param, folder?.grantId).then((data) => {
                if (!isDesktopMailboxUI()) addNewLabelToNav(data)
                if (onAddFolder) {
                    onAddFolder(data, null)
                    if(data?.grantId === currentActiveProviderGrantId){
                        addNewLabelToNav(data)
                    }
                    handleCreateLabelModel(false)
                    setIsApiCall(false)
                    toastr.show("Label Added.", "success")
                } else {
                    handleCreateLabelModel(false)
                    setIsApiCall(false)
                }
            }).catch((err) => {
                console.log("Error creating label", err)
                toastr.show("Something went Wrong.", "error")
                handleCreateLabelModel(false)
                setIsApiCall(false)
            })
        } else if(OUTLOOK_PROVIDER.includes(provider)){
            let parentId = undefined
            if(parentFolder?.value?.id) parentId = parentFolder?.value?.id
            else if(typeof parentFolder?.value === 'string') parentId = parentFolder?.value
            param.parentId = parentId
            createFolder(param, folder?.grantId).then((data) => {
                if (!isDesktopMailboxUI()) addNewLabelToNav(data)
                if (onAddFolder) {
                    onAddFolder(data, data?.parentId)
                    if(data?.grantId === currentActiveProviderGrantId){
                        addNewLabelToNav(data)
                    }
                    handleCreateLabelModel(false)
                    setIsApiCall(false)
                    toastr.show("Folder Added.", "success")
                } else {
                    handleCreateLabelModel(false)
                    setIsApiCall(false)
                }
            }).catch((err) => {
                console.log("Error creating label", err)
                handleCreateLabelModel(false)
                setIsApiCall(false)
            })
        }
    }
    return (
        <>
            <Modal show={show} dialogClassName="modal-sm" ref={modalRef}>
                <Modal.Header>
                    <Modal.Title>Create {provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label>{provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"} Name</label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={`Enter ${provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"} Name..`}
                            value={labelName}
                            onChange={handleChangeLabelName}
                        />
                    </div>
                    {provider === EMAIL_PROVIDER.GMAIL &&
                        <div className="d-flex flex-wrap gap-7 align-items-center">
                            {LABEL_COLORS.map((bgColor, index) => (
                                <input
                                    type="checkbox"
                                    className={`form-check-input border-0 rounded-3 ${bgColor} ${bgColor}-labelColor ${selectedColor === bgColor ? "outline-2 outline-gray-100" : ""}`}
                                    key={index}
                                    name="label-color"
                                    onChange={() => handleColorCheckboxChange(bgColor)}
                                    checked={selectedColor === index}
                                />
                            ))}
                        </div>
                    }
                    {provider === EMAIL_PROVIDER.MICROSOFT && isDesktopMailboxUI() &&
                        <div className="mt-3">
                            <label>{`Nested Under`}</label>
                            <AsyncSelect
                                styles={{
                                    ...smallSelectStyle,
                                    option: (provided, state) => ({
                                        ...provided,
                                        padding: "3px 8px",
                                        margin: "3px 8px",
                                        borderRadius: 5,
                                    }),
                                }}
                                defaultOptions={folders}
                                value={parentFolder ?? []}
                                onChange={handleParentFolderChange}
                                components={{
                                    Option: (props) => {
                                        return <components.Option {...props}>
                                            <div className={`d-flex p-0 gap-5 align-items-center`}>
                                                <div className="w-20px" style={{ minWidth: '20px' }}>
                                                    {getIconV2(props.data.label)}
                                                </div>
                                                <span className={`ms-2 font-12 font-weight-normal text-dark`}>{ convertFolderNameForOutlook(props.data.label)}</span>
                                            </div>
                                        </components.Option>
                                    }
                                }}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-close"
                        onClick={() => { handleCreateLabelModel(false) }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            addlabel();
                            let eventProperties = { source: AMPLITUDE_EVENTS_SOURCE.ADD_LABEL_BTN };
                            amplitudeTrack(AMPLITUDE_EVENTS.ADD_LABEL, eventProperties)
                        }}
                        disabled={isApiCall}
                    >
                        Create {provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"}
                        {isApiCall && <span class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddLabelModal
