import _ from "lodash";
import moment from 'moment';
import { getChassisSize, getChassisType } from 'pages/tms/AddChassis/actionCreators';
import { getTMSChassis, updateStopOffType } from 'pages/tms/Dispatcher/actionCreators';
import { TransitionEffect } from 'pages/tms/LoadRoutingV2/TransitionEffect';
import React, { useEffect, useRef, useState } from 'react';
import { connect, shallowEqual, useSelector } from "react-redux";
import { browserHistory } from "react-router";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import ReactTooltip from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { newSmSelectStyle, smallSelectStyle } from '../../../../../assets/js/select-style';
import confirm from '../../../../../Components/Common/ConfirmAert/index.js';
import { convertTMSStatusName, getIsLoadOrTripLive, isDriverAcceptMovesEnabled, legTypeToHuman } from "../../../../../Components/Common/functions";
import { getLoads } from '../../../../../Components/Common/Header/actionCreators.js';
import { IconAt, IconCalendar, IconCheck, IconClock, IconCompleted, IconDelete, IconDistance, IconDrag, IconLike, IconNavigationArrowBottom, IconPen, IconPhone, IconSaveAsDefault, IconThreeDots, IconTimer, IconTimes, IconTimesThin, IconTrash } from '../../../../../Components/Common/Icons';
import { LoaderBar } from '../../../../../Components/Common/LoaderBar';
import RedirectToRates from '../../../../../Components/CustomerServiceSideBar/Tabs/Components/RedirectToRates';
import { getChassisPickCustomersAsyncSearch } from '../../../../../Components/DynamicPricing/utils';
import LoadInfoModal from '../../../../../Components/LoadInfoModal/LoadInfoModal';
import config from '../../../../../config';
import { amplitudeTrack, checkAccountPayablePermission, DateTimeFormatUtils, getCarrierId, getNewTranscation, getStorage, isNegativeDriverPayAllowed, isNewSupportMovesEnabled, makeOptionsForSelect, toastr, updateNewTranscation, updateTerminology, getValidDrivers } from '../../../../../services';
import { getTripDetailFromRedis } from "../../../../../services/common.js";
import { RemoveDriverModal } from '../../../../tms/LoadRoutingV2/RemoveDriverModal';
import { loadEvents, supportMoveTypes, TRIP_TYPES } from '../../../constant';
import { removeTrip } from '../../../Dispatcher/NewDriverItinerary/actionCreators.js';
import { assignDriverToCombineTrip, removeDriverFromCombineTrip } from '../../../Load/actionCreator';
import { containerPositionConst, ROUTING_DRAG_LEGS, statusType, SUPPORT_MOVE_TO_ROUTING } from '../../../LoadRouting/constants';
import { getDistanceBetweenEvents, getDurationInHHMMFormat } from '../../../LoadRouting/utilities';
import { deleteSupportMove, removeLegForCombinedTrip, updateEventAddress, updateSupportMove } from '../../../LoadRoutingV2/actionCreator';
import CombineTripCreateModal from '../../../LoadRoutingV2/CombineTripCreateModal';
import { getTimeZone } from '../../../NewDispatcher/constants';
import DateWrapper from '../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper';
import ExactDateWrapper from '../../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper';
import { getContainerSize, manualDriverPay } from '../../../services';
import { addEditTripContainerPositions, AssignDriver, completeTrip, makeTripStartableToDriver, RemoveDriver, removeRoutingStatus, removeVoidLeg, unCompleteTrip, updateChassisDetail, updateCompleteDateTrip, updateRoutingStatus, updateTrip, updateTripOrder, voidOutTrip } from '../../actionsCreator';
import confirmComplete from '../confirmComplete';
import { createManualDriverPayForTrip } from './utilities';
import { AmplitudeSourceName, AmplitudeSupportMoveEvent } from "utils/AmplitudeEventConstant";
import ChassisValidationModal from '../../../LoadRoutingV2/ChassisValidationModal';
import DriversSelect from "../../../../../Components/Common/DriversList/DriversSelect.js";
import { getTrackingEventClasses, getTrackingEventClassesForNewSupportMove } from "utils";
import AddEventConfirm from "Components/Common/AddEventConfirm";
import { STOPOFF_TYPES } from "utils";
import { ROUTING_ADDRESS_FIELDS } from "pages/tms/Load/DriverOrderUtility";
import { isEmptyOrLoaded } from "../../../../../Components/Common/functions";

const firebase = config.getNewFirebaseInstance;
const db = firebase('trip-routing').database();

const NewRoutingTab = (props) => {
  const {orderDetails,updateTripDetails, closeModal, setStatusCount, isActiveCard} = props;
  const initSelectedOption = orderDetails?.driverId ? {
    label: orderDetails?.driverId.name + ' ' + orderDetails?.driverId.lastName,
    value: orderDetails?.driverId._id
  } : "";
    const {chassisTerms,drivers:AllDrivers, customers, shippers, consignees} = useSelector((store)=>store.TmsReducer)
    const [dropIndex, setDropIndex] = useState(null)
    const [dragEventId, setDragEventId] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [tripOrder, setTripOrder] = useState([])
    const [driverPays, setDriverPays] = useState([])
    const [editDriverPayData, setEditDriverPayData] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const [isDriverPayLoading, setIsDriverPayLoading] = useState(false);
    const [containerPositions, setContainerPositions] = useState([])
    const [loadInfoModal, setLoadInfoModal] = useState(false);
    const [tripCompletedAt, setTripCompletedAt] = useState(orderDetails?.completedAt);
    const [tripEditModal, setTripEditModal] = useState(false);
    const [tripMoveDetails, setTripMoveDetails] = useState([]);
    const [chassisNo, setChassisNo] = useState(orderDetails?.chassisId ? { label: orderDetails?.chassisId?.chassisNo, value: orderDetails?.chassisId?._id, allInfos: orderDetails?.chassisId } : null);
    const [_chassisOwner, setChassisOwner] = useState(orderDetails?.chassisOwner ? { label: orderDetails?.chassisOwner?.company_name, value: orderDetails?.chassisOwner?._id, allInfos: orderDetails?.chassisOwner } : null);
    const [_chassisSize, setChassisSize] = useState(orderDetails?.chassisSize ? { label: orderDetails?.chassisSize?.name, value: orderDetails?.chassisSize?._id, allInfos: orderDetails?.chassisSize } : null);
    const [_chassisType, setChassisType] = useState(orderDetails?.chassisType ? { label: orderDetails?.chassisType?.name, value: orderDetails?.chassisType?._id, allInfos: orderDetails?.chassisType } : null);
    const [addEventConfirm, setAddEventConfirm] = useState(null);
    const DriverPayRef = useRef(null);
    const tripRef = useRef(null);
    const [selectedOption, setSelectedOption] = useState(initSelectedOption)
    const [showWarningDriverModal, setShowWarningDriverModal]= useState(false);
    const optionValueRef = useRef(null);
    const regex = isNegativeDriverPayAllowed() ? /[0-9.-]/ : /[0-9.]/
    const setting = JSON.parse(localStorage.getItem('userBasicSettings'));
    const timeZone = getStorage("timeZone");
    const isManualSupportMove = isNewSupportMovesEnabled() && [TRIP_TYPES.SM_MANUAL].includes(orderDetails?.tripType);
    const isSystemSupportMove = isNewSupportMovesEnabled() && [TRIP_TYPES.SM_SYSTEM].includes(orderDetails?.tripType);
    const isSupportMove = isManualSupportMove || isSystemSupportMove;
    const isFreeFlowTrip = orderDetails?.tripType === TRIP_TYPES.FREE_FLOW ?? false;
    const isCombinedTrip = orderDetails?.tripType === TRIP_TYPES.COMBINED ?? false;
    const showChassisNo = isSupportMove ? (
      ([loadEvents.CHASSISPICK, SUPPORT_MOVE_TO_ROUTING.CHASSIS_STARTED].includes(orderDetails?.tripOrder?.[0]?.type) || [loadEvents.CHASSISPICK].includes(orderDetails?.tripOrder?.[1]?.type)) || 
      [loadEvents.CHASSISTERMINATION, SUPPORT_MOVE_TO_ROUTING.CHASSIS_STARTED].includes(orderDetails?.tripOrder?.[1]?.type)) : ([loadEvents.CHASSISPICK].includes(orderDetails?.tripOrder?.[0]?.type) && isFreeFlowTrip);

    const { chassis, chassisOwner, chassisSize, chassisType } = useSelector(
        (state) => state.chassisReducer,
        shallowEqual
    );
    const chassisOptions = makeOptionsForSelect(chassis, "chassisNo", "", "_id");
    const chassisSizeOptions = makeOptionsForSelect(chassisSize, "name", "", "_id");
    const chassisTypeOptions = makeOptionsForSelect(chassisType, "name", "", "_id");
    const chassisOwnerOptions = makeOptionsForSelect(chassisOwner, "company_name", "address.address", "_id");
	  const [chassisValidation, setChassisValidation] = useState(false);
    const savedFunction = useRef();
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}")

    useEffect(() => {
        setTripOrder(orderDetails?.tripOrder || [])
    }, [orderDetails?.tripOrder])

    useEffect(() => {
      if (orderDetails?.isCombinedTrip || isSupportMove) {
        _getDriverPays()
        subscribeToFirebase();
      }
      return () => {
        unsubscribeFromFirebase();      
      };
    }, []);

    useEffect(() => {
      getContainerPositions();
    }, [orderDetails]);

    const getChassis = (searchTerm = "") => {
      return new Promise((resolve, reject) => {
        props
          .getTMSChassis({ limit: 10, searchTerm })
          .then((result) => {
            let data = result.map((d) => {
              const options = {
                value: d._id,
                label: d.chassisNo,
                allInfos: d,
              };
              return options;
            });
            resolve(data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    };

    const fetchDriverUser = (info) => {
      const AlldriverList = AllDrivers;
      let driversList = AllDrivers;
      const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? '{}');
      let driverValidation = []
      if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
        driverValidation = userBasicSettings.driverValidation;
      }
      if (AlldriverList.length > 0) {
        let ddrivers = AlldriverList.filter(
          (d) => !d.isDeleted && !d.isBlocked
        );
        const emptyLoadedStatus  = isEmptyOrLoaded(info)
        if(!emptyLoadedStatus.status){
          emptyLoadedStatus.status = info.type_of_load === "IMPORT" ? "loaded" : "empty" 
        }
        let completedStatusIndex = -1;
        info?.driverOrder?.forEach((obj, k) => {
          if (obj.departed || obj.isVoidOut) {
            completedStatusIndex = k+1;
          }
        });
        let isDeliveryInThisMove = false;
        
        if(info.type_of_load === "EXPORT" && isDeliveryInThisMove){
          emptyLoadedStatus.status = "loaded"
        }
        let isLoaded = emptyLoadedStatus.status === "loaded" && ["IMPORT", "EXPORT", "ROAD"].includes(info.type_of_load)
        driversList = getValidDrivers(ddrivers, driverValidation, info, isLoaded);
      } else {
        driversList = [];
      }
  
      
      
      let FinalDrivers = driversList.map((d) => {
        return {
          value: d?._id,
          label: d?.role === "driver" ? `${d?.name} ${d?.lastName || ""}` : d?.company_name,
          isDisabled: d?.disableValue,
          allInfos: d,
        };
      })
      return FinalDrivers;
    }

    const getContainerPositions = () => {
      if(orderDetails?.combinedMoves?.length) {
        const containerPositionValue = orderDetails?.combinedMoves?.map(val => {
          let obj={}
          obj.moveId = val?.moveId;
          if(val?.position) obj.position = val?.position;
          return obj;
        })
        setContainerPositions(containerPositionValue)
      }
    }

    const subscribeToFirebase = () => {
      unsubscribeFromFirebase();
      DriverPayRef.current = db.ref(`${getCarrierId()}/reloadDriverPays`);
      tripRef.current = db.ref(`${getCarrierId()}/trip-edit`);
      DriverPayRef.current.on("value", (snapshot) => {
        const data = snapshot.val();
        const loadDetail = JSON.parse(data);
        if (loadDetail?.reference_number === orderDetails.tripNumber
          && moment().diff(moment(loadDetail.updatedAt), "seconds") <= 10) {
            _getDriverPays();
          }
      });
      tripRef.current.on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          const tripInfo = JSON.parse(snapshot.val());
          if(moment().diff(moment(tripInfo?.updatedAt), "seconds") <= 10 && tripInfo?._id === orderDetails?._id) {
            getTripDetailFromRedis(tripInfo).then((trip)=>{
              if(trip){
                updateTripDetails(trip)
              }
            })
            .catch(err => {
              console.log(err)
            })
          }
        }
      });
    };

    const unsubscribeFromFirebase = () => {
      if (DriverPayRef.current) {
        DriverPayRef.current?.off();
      }
      tripRef?.current?.off();
    };

    const transitionEffect = (field, color, callback = () => {}) => {
      const element = document.querySelector(`.${field}`);
      let dateOrInpt = ["input-wrapper"];
      const isThere = dateOrInpt.some((e)=>element?.className?.includes(e));
      const transitionElement = isThere ? document.querySelector(`.${field} > div > input`) : document.querySelector(`.${field}`);
      if(color){
        transitionElement?.classList.remove("background-failure");
        transitionElement?.classList.add("background-success");
        setTimeout(()=>{
          transitionElement?.classList.remove("background-success","text-danger");
          callback();
        },400)
      }else {
        transitionElement?.classList.add("background-error","text-danger");
          setTimeout(()=>{
            transitionElement?.classList.add("background-failure","text-danger");
            transitionElement?.classList.remove("background-error","text-danger");
            callback();
          },400)
      }
    }
    const _getDriverPays = () => {
      const isDriverExists = orderDetails?.tripOrder?.find((d) => d.driver && !d.isVoidOut);
      if (isDriverExists) {
        setIsDriverPayLoading(true)
        const driverPayParams = { reference_number: orderDetails.tripNumber };
        if (isSupportMove) driverPayParams.supportMoves = [orderDetails._id];
        getNewTranscation(driverPayParams)
          .then((response) => {
            if (response?.data?.data?.result) {
              setDriverPays(response.data.data.result)
              setEditDriverPayData(null);
              setIsDriverPayLoading(false)
            }
          })
          .catch((err) =>{
            setIsDriverPayLoading(false)
            console.log(err)
          });
      } else {
        setDriverPays([])
        setEditDriverPayData(null);
      }
    }

    const updateDriverPay = (driverPay, value, status, nextValidLeg) => {
      value = value.replace(driverPay?.invoiceCurrency?.symbol || '$', '');
      if (driverPay) {
        const editDriverPayData = _.cloneDeep(driverPay);
        editDriverPayData.amount = value;
        setEditDriverPayData(editDriverPayData)
      }
      else if (status && nextValidLeg) {
        const editDriverPayData = _.cloneDeep(createManualDriverPayForTrip(orderDetails, status, nextValidLeg));
        if (isSystemSupportMove) {
          editDriverPayData.supportMove = orderDetails._id;
          editDriverPayData.reference_number = orderDetails?.tripOrder?.[0]?.reference_number || orderDetails?.tripOrder?.[0]?.reference_number || orderDetails?.tripNumber;
        }
        editDriverPayData.amount = value;
        setEditDriverPayData(editDriverPayData)
      }
    }

    const onBlurHandleForDrivePay = (field) => {
      const _editDriverPayData = _.cloneDeep(editDriverPayData);
      if (!_editDriverPayData) return;
      setIsDriverPayLoading(_editDriverPayData._id)
      if (_editDriverPayData?._id) {
        const actualPay = driverPays.find((d) => d._id === _editDriverPayData._id);
        if (!actualPay || parseFloat(actualPay.amount) === parseFloat(_editDriverPayData.amount)) {
          setEditDriverPayData(null)
          return;
        }
        const params = {
          id: _editDriverPayData._id,
          amount: _editDriverPayData.amount || 0,
          isManual: true,
          isFromSettlement: false,
          isCombinedTrip:true
        }
        updateNewTranscation(params)
          .then((response) => {
            transitionEffect(field, true);
            const _driverPays = _.cloneDeep(driverPays);
            if (response?.data?.data) {
              const dpIndex = _driverPays.findIndex((d) => d._id === response.data.data._id);
              if (dpIndex > -1) _driverPays.splice(dpIndex, 1, response.data.data);
            }
            setDriverPays(_driverPays);
            setEditDriverPayData(null);
            setIsDriverPayLoading(false)
          }).catch(err=>{
            transitionEffect(field, false);
            setIsDriverPayLoading(false)
          })
      }
      else {
        delete _editDriverPayData?.invoiceCurrency?.symbol;
        delete _editDriverPayData.isManual;
        delete _editDriverPayData.currencySymbol;
        const params = {
          ..._editDriverPayData,
          isTrip: true
        };
        
        if (orderDetails?.terminal)
          params.terminal = orderDetails?.terminal?._id || orderDetails?.terminal

        manualDriverPay(params).then(async (response) => {
          transitionEffect(field, true)
          const _driverPays = _.cloneDeep(driverPays);
          if (response?.data?.data) _driverPays.push(response.data.data);
          setDriverPays(_driverPays)
          setEditDriverPayData(null)
          setIsDriverPayLoading(false)
        }).catch(err => {
          transitionEffect(field, false)
          setIsDriverPayLoading(false)
        })
      }
    }

    const handleDragEnter = (index) => {
        if (isDragging && dropIndex !== index)
            setDropIndex(index)
    };
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }
    const createLoadStatus = (type, customerId, prevType) => {
        const status = {
            type: type,
        };
        if (customerId) status.customerId = customerId;
        return status;
    }
    const handleOnDrop = async (e) => {
        setIsLoading(true)
        const loadStatusType = e.dataTransfer.getData("loadStatusType");
        let removeDriverIndex = null;
        let tripOrderClone = _.cloneDeep(tripOrder);
        if (dropIndex === 100) {
            const currentIndex = 0;
            if (
                (tripOrderClone[currentIndex] &&
                    (tripOrder[currentIndex].isVoidOut ||
                        tripOrderClone[currentIndex].arrived)) ||
                (loadStatusType === "CHASSISPICK" &&
                    tripOrderClone[currentIndex].type !== "PULLCONTAINER") ||
                ["CHASSISPICK", "PULLCONTAINER"].indexOf(loadStatusType) === -1
            ) {
              setIsLoading(false)
                return;
            }
            const createdleg = createLoadStatus(
                loadStatusType,
            )
            tripOrderClone.splice(
                currentIndex,
                0,
                createdleg
            );
            setTripOrder(tripOrderClone)
            updateChassisDetail({...createdleg,_id:orderDetails._id}).then(res=>{
                setIsLoading(false)
                updateTripDetails(res)
            }).catch((er)=>{
              setIsLoading(false)
            })
        
        }
        setDropIndex(null)
    }

    const handleOnSwap = (e) => {
      setIsLoading(true);
      const loadEventId = e.dataTransfer.getData("loadEventId");
      const newLoadEventId = e.dataTransfer.getData("newLoadEventId");
      const draggedLoadEvent = tripOrder.find((d) => d.eventId === loadEventId);
      const dragIndex = tripOrder.findIndex((d) => d.eventId === loadEventId);
      const _dropIndex = dropIndex === 100 ? -1 : dropIndex;
      const _tripOrder = _.cloneDeep(tripOrder);


      if (newLoadEventId) {
        setAddEventConfirm({isOpen: true, loadStatusType: newLoadEventId, dropIndex: dropIndex})
        setIsLoading(false);
        setDropIndex(null)
        return
      }

      const validEventIds = tripOrder.map((move)=>move.eventId);
      if(!validEventIds.includes(loadEventId)){
        setIsLoading(false);
        setDropIndex(null)
        return
      }
      let validStartIndex, validEndIndex;

      let dpIndex = dropIndex === 100 ? 0 : dropIndex + 1;
      for(let i = dpIndex; i< tripOrder.length; i++){
        if(tripOrder[i].arrived || tripOrder[i].departed){
          setIsLoading(false);
          setDropIndex(null)
          return
        }
      }

      for (let i = dragIndex - 1; i >=0 ; i--) {
        if (tripOrder[i]?.loadId === draggedLoadEvent.loadId) {
          validStartIndex = i;
          break;
        }
      }
      if(validStartIndex>=0){
        validStartIndex-=1;
      }
      for (let i = dragIndex + 1; i < tripOrder.length; i++) {
        if (tripOrder[i]?.loadId === draggedLoadEvent.loadId) {
          validEndIndex = i;
          break;
        }
      }

      if (_dropIndex < dragIndex && (validStartIndex === undefined || _dropIndex > validStartIndex)) {
        _tripOrder.splice(dragIndex, 1);
        _tripOrder.splice(_dropIndex + 1, 0, draggedLoadEvent);
      }
      else if (_dropIndex > dragIndex && (validEndIndex === undefined || _dropIndex < validEndIndex)) {
        _tripOrder.splice(_dropIndex + 1, 0, draggedLoadEvent);
        _tripOrder.splice(dragIndex, 1);
      }
      setTripOrder(_tripOrder);

      const updatedTripOrder = _.cloneDeep(_tripOrder);
      updatedTripOrder.forEach((tEvent) => {
        tEvent.customerId = tEvent.customerId?._id || tEvent.customerId;
        tEvent.driver = tEvent.driver?._id || tEvent.driver;
      })

      let noChange = _.isEqual(_tripOrder, tripOrder)
      if(noChange) {
        setIsLoading(false);
        setDropIndex(null)
        return
      }
      updateTripOrder({ tripOrderId: orderDetails._id, tripOrder: updatedTripOrder })
        .then((res) => {
          toastr.show("Successfully updated", "success");
          setDropIndex(null)
          setIsLoading(false);
        })
        .catch((err) => {
          toastr.show("Failed !", "error");
          setTripOrder(tripOrder)
          setDropIndex(null)
          setIsLoading(false);
        })
    }
    
    const handleDragStart = (e, loadEvent) => {
      setIsDragging(true)
        e.dataTransfer.setData("loadStatusType", e.target.id);
        if (loadEvent) {
          e.dataTransfer.setData("loadEventId", loadEvent.eventId);
          setDragEventId(loadEvent.eventId);
        }
        e.stopPropagation();
    };
    const handleDragStartForAddEvent = (e) => {
      setIsDragging(true);
      e.dataTransfer.setData("newLoadEventId", e.target.id);
      e.stopPropagation();
    };
    const removeLeg = (index,leg)=>{
        setIsLoading(true)
        let tripOrderClone = _.cloneDeep(tripOrder);
        tripOrderClone.splice(index, 1);
        setTripOrder(()=>[...tripOrderClone])
        updateChassisDetail({_id:orderDetails._id,tripOrderId:leg._id}).then(res=>{
            setIsLoading(false)
            updateTripDetails(res)
        }).catch((er)=>{
          setIsLoading(false)
        })
    }

    const _removeLegForCombinedTrip = (index,leg)=>{
      setIsLoading(true)
      let tripOrderClone = _.cloneDeep(tripOrder);
      tripOrderClone.splice(index, 1);
      setTripOrder(()=>[...tripOrderClone]);
      const params = {
        tripId:orderDetails._id,
        eventId:leg.eventId
      }
      removeLegForCombinedTrip(params).then(res=>{
          setIsLoading(false)
      }).catch((er)=>{
        setIsLoading(false)
      })
    }
   

     const assignDriverToTrip = (tripId,driverId,orderId)=>{
      setIsLoading(true)
        const params ={_id:tripId,orderId:orderId,driver:driverId}
        AssignDriver(params).then(res=>{
            if(res)updateTripDetails(res)
            setIsLoading(false)
        }).catch((er)=>{
          setIsLoading(false)
        })
     }

     const handleUpdateDriverOfTrip = (tripOrderId, driverId, tripAssignedDate) =>{
      setIsLoading(true)
        const params ={tripOrderId, driverId, tripAssignedDate}
        assignDriverToCombineTrip(params).then(res=>{
            if(res)updateTripDetails(res)
            setIsLoading(false)
        }).catch((er)=>{
          setIsLoading(false)
        })
     }

     const handleRemoveDriverFromTrip = (tripOrderId) =>{
      setIsLoading(true)
        const params ={tripOrderId}
        removeDriverFromCombineTrip(params).then(res=>{
            if(res)updateTripDetails(res)
            setIsLoading(false)
        }).catch((er)=>{
          setIsLoading(false)
        })
     }

     const removeDriverToTrip = (tripId,orderId)=>{
        setIsLoading(true)
        const params ={_id:tripId,orderId:orderId}
        RemoveDriver(params).then(res=>{
          if(res)updateTripDetails(res)
            setIsLoading(false)
        }).catch((er)=>{
          setIsLoading(false)
        })
     }

     const handleDateChanged = (_date,i,type)=>{
      const _tripOrder = _.cloneDeep(tripOrder);
      _tripOrder[i][type] = _date;
   }
     const updateLegStatus=(tripId,legId,status,index)=>{
      if(isFreeFlowTrip && tripOrder[0].type==="CHASSISPICK" && !(orderDetails?.chassisId || orderDetails?.chassisNo)){
        return toastr.show("Please enter chassis no.","error")
      }
      if(isFreeFlowTrip && tripOrder[index].type==="CHASSISPICK" && !tripOrder[index]?.customerId){
        return toastr.show("Please select customer.","error")
      }
      setIsLoading(true)
        const params ={
            "_id" : tripId,
            "tripOrderId" :legId,
            "status" : status
        }
        updateRoutingStatus(params).then((res)=>{
          setIsLoading(false)
            updateTripDetails(res)
        }).catch((er)=>{
          setIsLoading(false)
        })
     }

     const removeLegStatus=(tripId,legId,status)=>{
      setIsLoading(true)
      const params ={
          "_id" : tripId,
          "tripOrderId" :legId,
          "status" : status
      }
      removeRoutingStatus(params).then((res)=>{
        setIsLoading(false)
          updateTripDetails(res)
      }).catch((er)=>{
        setIsLoading(false)
      })
   }

     const completeOrderTrip=async()=>{
      if(!orderDetails.driverId){
        return toastr.show("Please select driver.","error")
      }
      let errorMessage = "";
      await tripOrder.forEach((d, index) => {
        if(!d?.isVoidOut) {
          if(isFreeFlowTrip && d?.type==="CHASSISPICK" && !(orderDetails?.chassisId || orderDetails?.chassisNo) ){
            errorMessage="Please select chassis."
          }
          if(isFreeFlowTrip && d?.type==="CHASSISPICK" && !d?.customerId){
            errorMessage="Please select customer."
          }
        }
      })
      
      if(errorMessage) {
        return toastr.show(errorMessage, "error")
      }
        document.querySelector(".tripModal").removeAttribute("tabIndex");
        confirmComplete( "Complete Trip",
        "Are you sure you want to completed the Trip?",
        (value,res) => {
            if(value){
              updateTripDetails(res,res.reference_number)
            }
            document.querySelector(".tripModal").setAttribute("tabIndex",-1);
        },{showInputText:true,tripId:orderDetails._id, setStatusCount: setStatusCount, isActiveCard: isActiveCard, tripType: orderDetails?.tripType})
    }

    const handleTripDateChanged = () => {
      const params = {tripId: orderDetails._id, completedDate: tripCompletedAt}
      updateCompleteDateTrip(params).then(res=>{
        let params = {}
        if(isActiveCard) {
          params._id = isActiveCard
        }
        updateTripDetails(res,res.reference_number);
        toastr.show("Updated!", "success");
        }).catch((err)=>{
        })
    }

    const completeTripForCombinedTrip =()=>{
      if(!orderDetails.driverId){
        return toastr.show("Please select driver.","error")
      }
      if(isFreeFlowTrip && tripOrder[0]?.type==="CHASSISPICK" && !orderDetails.chassisId ){
        return toastr.show("Please select chassis.","error")
      }
      if(isFreeFlowTrip && tripOrder[0]?.type==="CHASSISPICK" && !tripOrder[0]?.customerId){
        return toastr.show("Please select customer.","error")
      }
      document.querySelector(".tripModal").removeAttribute("tabIndex");


      const params= {_id:orderDetails._id,containerNo:orderDetails.tripNumber}
      setIsLoading(true)
      completeTrip(params).then(res=>{
        let params = {}
        if(isActiveCard) {
          params._id = isActiveCard
        }
        setIsLoading(false)
        updateTripDetails(res,res.reference_number)
        closeModal()
        document.querySelector(".tripModal").setAttribute("tabIndex",-1);
      }).catch((err)=>{
        setIsLoading(false)
      })

                  
    }

    const unCompleteTripForCombinedTrip = () => {
      const params = { _id: orderDetails._id }
      setIsLoading(true)
      unCompleteTrip(params).then(res => {
        let params = {}
        if (isActiveCard) {
          params._id = isActiveCard
        }
        setIsLoading(false)
        updateTripDetails(res, res.reference_number)
        toastr.show("Updated!", "success");
        document.querySelector(".tripModal").setAttribute("tabIndex", -1);
      }).catch((err) => {
        setIsLoading(false)
      })
    }

    const changeEventAddress = (customerId, type, eventId)=>{
        setIsLoading(true);
        let payloadData = {
          _id: orderDetails._id,
          customerId: customerId,
          type,
        }
        if (eventId && !isCombinedTrip) {
          payloadData.tripOrderId = eventId
        } else if (eventId && isCombinedTrip) {
          delete payloadData.type;
          delete payloadData._id;
          payloadData.eventId = orderDetails.tripOrder.find(tOrder => tOrder._id + "" === eventId + "")?.eventId;
          let loadId = orderDetails.tripOrder.find(tOrder => tOrder._id + "" === eventId + "")?.loadId;
          payloadData.loadId = loadId;
        }
        if (isManualSupportMove) {
          delete payloadData.type;
          delete payloadData.tripOrderId;
          payloadData.eventId = eventId;
        }

        const API = isCombinedTrip ? updateEventAddress : isManualSupportMove ? updateSupportMove : updateChassisDetail;
        API(payloadData).then(res=>{
            setIsLoading(false)
            !isCombinedTrip && updateTripDetails(res)
        }).catch((er)=>{
          setIsLoading(false)
        })
    }

    const updateStopOffName = async (value, loadId, eventId, field) => {
      let param = {
        loadId: loadId,
        eventId: eventId,
        stopOffType: value.value,
        stopOffName: value.label
      }
      let _driverOrder = _.cloneDeep(orderDetails.tripOrder)
      debugger
      _driverOrder.forEach((p, index) => {
        if (p._id + "" === eventId + "") {
          _driverOrder[index].stopOffType = value.value
          _driverOrder[index].stopOffTypeName = value.label
        }
      })
      updateStopOffType(param)
        .then((res) => {
          setTripOrder(_driverOrder)
          toastr.show("Update Successfully!", 'success')
          TransitionEffect(field, true);
        })
        .catch((err) => {
          toastr.show(err, 'error')
          TransitionEffect(field, false);
          console.log("error -=-=-= ", err)
        })
    }
    const selectCustomerForChassisPick = (customerId, type, eventId) => {
      setIsLoading(true);
      let payloadData = {
        _id: orderDetails._id,
        customerId: customerId,
        type,
      }
      if (eventId) {
        payloadData.tripOrderId = eventId
      }
      if (isManualSupportMove) {
        delete payloadData.type;
        delete payloadData.tripOrderId;
        payloadData.eventId = eventId;
      }
      const API = isManualSupportMove ? updateSupportMove : updateChassisDetail;
      API(payloadData).then(res => {
        setIsLoading(false)
        updateTripDetails(res)
      }).catch((er) => {
        setIsLoading(false)
      })
    }

    const addChassisNo = (chassisValue)=>{
        if(!chassisValue) return toastr.show("Please enter Chassis no","error");
        const API = isManualSupportMove ? updateSupportMove : updateTrip;
        setIsLoading(true)
        API({_id:orderDetails._id,chassisId:chassisValue.value, chassisNo:chassisValue.label }).then(res=>{
            setIsLoading(false)
            updateTripDetails(res)
        }).catch((er)=>{
          setIsLoading(false)
        })
    }
     const chassisTermOptions= makeOptionsForSelect(
        chassisTerms,
        "company_name",
        "address.address",
        "_id"
      )
      const isCompleted = orderDetails?.status==="COMPLETED"
      let statusObj = orderDetails
      ? convertTMSStatusName(
          "IMPORT",
          orderDetails.status,
          null,
          false,
          true
        )
      : null;
      
  const updateLegTime = (tripId, legId, status, index, time, field) => {
  
    const isEqual = orderDetails?.tripOrder?.[index]?.[status] && time && _.isEqual(moment(orderDetails?.tripOrder?.[index]?.[status]).format("DD/MM/YYYY HH:mm"),moment(time).format("DD/MM/YYYY HH:mm"))
    if(isEqual) return
    setIsLoading(true);

    const _tripOrder = _.cloneDeep(tripOrder);
    _tripOrder[index][status] = time;
    setTripOrder(_tripOrder)

    const params = {
      "_id": tripId,
      "tripOrderId": legId,
      "type": status,
      "time": moment(time).toISOString()
    }
    updateTrip( params ).then(res => {
      transitionEffect(field, true, () => {
        setIsLoading(false)
      });
      updateTripDetails(res)
    }).catch((er)=>{
      transitionEffect(field, true, () => {
        setIsLoading(false)
      });
    })
  }
  const voidOutModal = (tripId,legId) => {
		setIsLoading(true);
		const params = { _id : tripId, tripOrderId: legId  }
		voidOutTrip(params).then((res) => {
			setIsLoading(false);
       updateTripDetails(res)
      setTripOrder(res?.tripOrder)
		}).catch(res => {
			setIsLoading(false);
			toastr.show("Something went wrong.", "error");
		})
	}
  const removeVoid = (tripId,legId) => {
    setIsLoading(true);
		const params = { _id : tripId, tripOrderId: legId  }
		removeVoidLeg(params).then((res) => {
			setIsLoading(false);
      updateTripDetails(res)
      setTripOrder(res?.tripOrder)
		}).catch(res => {
			setIsLoading(false);
			toastr.show("Something went wrong.", "error");
		})
  }
  const returnNextValidMoveIndex = (i,leg) =>{
    const driverOrder = _.cloneDeep(leg);
    let ans = -1;
    driverOrder.forEach((move,index)=>{
      if(index > i){
        if((ans===-1) && !move.isVoidOut){
          ans = index;
          return;
        }
      }
    })

    return ans;
  }
  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i].address === obj.address) {
            return true;
        }
    }

    return false;
  }

  const getConsigneePortFromTrip = (type) =>{
    const tripOrder = orderDetails?.tripOrder
    const answer = []

    tripOrder.forEach((move)=>{
      if(move.type === type){
        const res = {
          company_name: move?.company_name,
          address : move?.address?.address
        }
        if (!containsObject(res,answer)) {
          answer.push(res);
      }
      }
    })
    return answer
  }
  const redirectToRates = (payItem) => {
    if (payItem.driverPayRateId) {
        browserHistory.push('/tms/driver-pay-rates?rateId=' + payItem.driverPayRateId);
    } else if (payItem.dynamicDriverPayId) {
        browserHistory.push('/dynamic-driverPay?rateId=' + payItem.dynamicDriverPayId);
    }
  }

  const addorUpdateContainerPositions = (data, field) => {
      let params = {}
      if(orderDetails?._id) params.tripOrderId = orderDetails?._id;
      params.moveId = data?.moveId;
      if(data?.position) params.position = data?.position;

      addEditTripContainerPositions(params).then(res => {
        const containerPositionValue = res?.combinedMoves.map(val => {
          let obj={}
          obj.moveId = val?.moveId;
          if(val?.position) obj.position = val?.position;
          return obj;
        })
        setContainerPositions(containerPositionValue);
        toastr.show("Position updated", "success");
      }).catch(err => {
        console.log(err)
      })
  }

  const handlePositionChange = (position, moveId) => {
    let _containerPositions = _.cloneDeep(containerPositions)
    const isMoveIdExists = _containerPositions?.findIndex(value => value?.moveId === moveId)
    let obj = {}
    if(position) obj.position = position;
    if(moveId) obj.moveId = moveId;
  
    if(isMoveIdExists!==-1) _containerPositions[isMoveIdExists] = obj;
    else _containerPositions.push(obj);
  
    _containerPositions = _containerPositions?.filter(val => val)
    addorUpdateContainerPositions({moveId, position})
    setContainerPositions(_containerPositions)
  }

  const onChassisEventSuccess = (result) => {
    setIsLoading(false)
    if (savedFunction.current) {
        savedFunction.current()
        savedFunction.current = null
    }
  }
  const onChassisEventFailure = () => {
    setIsLoading(false)
  }

  const removeDriverFromSupportMove = (supportMove, skipValidation = false) => {
    setIsLoading(true)
    let params = {
      _id: supportMove?._id
    }
    if(skipValidation) params.skipValidation = true;
    savedFunction.current = () => removeDriverFromSupportMove(supportMove, skipValidation = true)
    deleteSupportMove(params)
      .then((data) => {
        if (data?.isChassisv3) {
          setChassisValidation(data?.isChassisv3)
          return
      }
        setIsLoading(false)
        closeModal()
        toastr.show("Removed.", "success");
      })
      .catch((err) => {
        setIsLoading(false)
        console.log("🚀 ~ _deleteSupportMove ~ err:", err)
      })
  }
  const changeSupportMoveDriver = (driverInfo, supportMove) => {
    let params = {
      _id: supportMove?._id,
      driverId: driverInfo?.value
    }
    const isConnectedWithLoad = supportMove?.tripOrder?.find((p) => p.reference_number);
    if ([TRIP_TYPES.SM_MANUAL].includes(supportMove?.tripType) && isConnectedWithLoad) {
        toastr.show("Can not change driver", 'error');
        return;
    }
    setIsLoading(true)
    updateSupportMove(params)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false)
        console.log("🚀 ~ updateNewSupportMove ~ err:", err)
      })
  }

  //For combined Trip
  const getCombinedMoveDetails = async () => {
    try {
      setIsLoading(true)
      const reference_numbers = orderDetails?.combinedMoves?.map(x => x?.reference_number)?.filter(Boolean);
      
      if (!reference_numbers?.length) {
        setIsLoading(false)
        return;
      }
      
      const res = await new Promise((resolve) => {
          getLoads({
            reference_numbers: reference_numbers
          })
          .then(res => resolve(res))
          .catch((err) => {
            console.log("Error : ", err)
            return resolve(null)
          })
        }
      )
      
      const loads = res?.data
      const moveIds = orderDetails?.combinedMoves?.map(x => x?.moveId) || []
      const _loads = loads?.map(load => {
        const driverOrder = load?.driverOrder?.filter(x => moveIds?.includes(x?.moveId)) || []
        load.driverOrder = driverOrder
        return load;
      })
      setTripMoveDetails(_loads || [])
      setIsLoading(false)
      setTripEditModal(true)
    }catch(err) {
      console.log("Error ", err);
      setIsLoading(false)
    }
  }

  const handleRemoveTrip = () => {
    if (orderDetails?.tripOrder?.[0].arrived && orderDetails?.tripType !== TRIP_TYPES.SM_MANUAL) {
      toastr.show("Trip is in progress", "error")
      return
    }
    const title = orderDetails?.tripType !== TRIP_TYPES.SM_MANUAL ? "Delete Trip" : "Delete Support Move"
    const message = orderDetails?.tripType !== TRIP_TYPES.SM_MANUAL ? 
    "This will delete the trip. Once deleted, you will need to create a new trip." : 
    "This will delete the support move. Once deleted, you will need to create a new support move."
    confirm(title, message, (e) => { if (e) {
        setIsLoading(true)
        if (orderDetails?.tripType === TRIP_TYPES.SM_MANUAL) {
          let params = {
            _id: orderDetails?._id
          }
          savedFunction.current = () => handleRemoveTrip()
          deleteSupportMove(params).then((data)=>{
            if (data?.isChassisv3) {
              setChassisValidation(data?.isChassisv3)
              return
            }
            let eventProperties = {
              source: AmplitudeSourceName.SUPPORT_MOVE_MODAL,
            }
            amplitudeTrack(AmplitudeSupportMoveEvent.SUPPORT_MOVE_DELETE, eventProperties);
            closeModal()
            setIsLoading(false)
          }).catch(()=>{
            setIsLoading(false)
          })
        } else {
          removeTrip({ tripOrderId: orderDetails?._id }).then(()=>{
            closeModal()
            setIsLoading(false)
          }).catch(()=>{
            setIsLoading(false)
          })
        }
      } 
    }, {isDeleteTrip:true})
  }
  const checkForAcceptedTrip = () => {
    if(isDriverAcceptMovesEnabled() && orderDetails?.tripOrder?.length > 0 && orderDetails?.tripOrder?.every(tripValue => tripValue?.accepted)){
      return true
    }
    return false
  }

  const handleMakeTripStartableToDriver = (e)=>{
    e.stopPropagation();
    makeTripStartableToDriver({
      _id: orderDetails?._id,
    })
    .then(() => {
      toastr.show("Trip Started", "success");
    })
    .catch((err) => {
      toastr.show(err.data.message, "error");
    });
  }

  const isTripLive  = getIsLoadOrTripLive({ ...orderDetails, isTrip:true })
    
  const CustomValueContainer = ({ children, ...props }) => {
    const selectedValue = props?.getValue()[0]?.label;
  
    return (
      <>
        <components.ValueContainer {...props}>
          <div className="d-flex align-items-center align-items-center justify-content-between">
            {children}
            {selectedValue && (
              <div className="d-flex align-items-center">
                {!isTripLive && checkForAcceptedTrip() ? (
                  <span className="mr-1 wh-24px bg-brand-50 rounded-3 d-inline-flex align-items-center justify-content-center">
                    <IconLike className="text-blue-500 wh-16px" />
                  </span>
                ) : (
                  <button
                    className="mr-1 btn btn-xs btn-success"
                    disabled={orderDetails?.isTripStartable || orderDetails?.status === "COMPLETED"}
                    onMouseDown={handleMakeTripStartableToDriver}
                  >
                    <IconCheck />
                  </button>
                )}

                {(isFreeFlowTrip || isCombinedTrip || isManualSupportMove) && <button 
                  className="btn btn-xs btn-danger" 
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleChange(null)
                  }}
                  >
                  <IconTimes />
                </button>
                }
              </div>
            )}
          </div>
        </components.ValueContainer>
      </>
    );
  };

  const handleChange = (value) => {
    if(selectedOption?.value === value?.value) return
    
    optionValueRef.current = value
    if (value && checkForAcceptedTrip()) {
      setShowWarningDriverModal(true)
    } else {
      setSelectedOption(value)
      if (value && value.allInfos && value.allInfos.driver && value.allInfos.driver.accountHold) {
        toastr.show('This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles', 'error');
        return;
      }
      if (orderDetails?.isCombinedTrip) {
        // combine Trip
        if(value) {
          handleUpdateDriverOfTrip(orderDetails?._id,value.value,orderDetails?.tripAssignedDate)
        } else {
          handleRemoveDriverFromTrip(orderDetails?._id)
        }
      } else {
        //free flow
        if ([TRIP_TYPES.SM_MANUAL].includes(orderDetails.tripType)) {
          if (value) {
            changeSupportMoveDriver(value, orderDetails)
          } else {
            removeDriverFromSupportMove(orderDetails)
          }
        } else {
          if (value) {
            assignDriverToTrip(orderDetails._id,value.value, orderDetails.orderId._id)
          } else {
            removeDriverToTrip(orderDetails._id,orderDetails.orderId._id)
          }
        }
      }    
    }
  }

  const handleWarningConfirm = () => {
    const value = optionValueRef?.current 
    setSelectedOption(value)
    if (value && value.allInfos && value.allInfos.driver && value.allInfos.driver.accountHold) {
      toastr.show('This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles', 'error');
      return;
    }
    if (orderDetails?.isCombinedTrip) {
      // combine Trip
      handleUpdateDriverOfTrip(orderDetails?._id,value.value,orderDetails?.tripAssignedDate)
    } else {
      //free flow
      if (value) {
        assignDriverToTrip(orderDetails._id,value.value, orderDetails.orderId._id)
      } else {
        removeDriverToTrip(orderDetails._id,orderDetails.orderId._id)
      }
    } 
    setShowWarningDriverModal(false)
  }
  const _updateChassisDetail = (chassisDetailName, value, className) => {
    let payload = {
      _id: orderDetails?._id,
      [chassisDetailName] : value?.value,
    }
    if (chassisDetailName === "chassisId") {
      payload.chassisNo = value.label;
    }        
    const API = isManualSupportMove ? updateSupportMove : updateTrip;
    API(payload).then((res) => {
      console.log("🚀 ~ updateSupportMove ~ res:", res)
      TransitionEffect(className, true)
    }).catch((err) => {
      console.log("🚀 ~ updateSupportMove ~ err:", err)
      TransitionEffect(className, false)
    })
  }

  const selectChassis = (data) => {
    if (!data) {
      setChassisNo(null)
      return
    }
    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
      chassisNo
    } = data;
    setTimeout(() => {
      setChassisNo(data)
      TransitionEffect('chassisNo', true)
    }, 100);
    setTimeout(() => {
      setChassisSize({
        value: chassisSize?._id,
        label: chassisSize?.name,
      });
      TransitionEffect('chassisSize', true)
    }, 200);
    setTimeout(() => {
      setChassisType({
        value: chassisType?._id,
        label: chassisType?.name,
      });
      TransitionEffect('chassisType', true)
    }, 300);
    setTimeout(() => {
      setChassisOwner({
        value: chassisOwner?._id,
        label: chassisOwner?.company_name,
      });
      TransitionEffect('chassisOwner', true)
    }, 400);


    let chassisObj = {
      chassisSize: chassisSize?._id,
      chassisType: chassisType?._id,
      chassisOwner: chassisOwner?._id,
    }
    Object.keys(chassisObj).forEach(key => chassisObj[key] === undefined && delete chassisObj[key]);
    let payload = {
      _id: orderDetails?._id,
      chassisId: value,
      chassisNo: chassisNo ?? label,
    }
    if (isManualSupportMove) {
      payload = {
        ...payload,
        ...chassisObj
      }
    }
    let arr = ["chassisNo"];
    if (isNewSupportMovesEnabled()) {
      arr.push("chassisOwner", "chassisType", "chassisSize")
    }
    const API = isManualSupportMove ? updateSupportMove : updateTrip;
    API(payload).then((res) => {
      console.log("🚀 ~ updateSupportMove ~ res:", res)
      setTimeout(() => {
        arr.forEach((element) => TransitionEffect(element, true))
      }, 400)
    }).catch((err) => {
      console.log("🚀 ~ updateSupportMove ~ err:", err)
      setTimeout(() => {
        arr.forEach((element) => TransitionEffect(element, true))
      }, 400)
    })

  }

  let ROUTING_ADDRESS_FIELDS_NEW = ["DELIVERLOAD","RETURNCONTAINER","DROPCONTAINER","HOOKCONTAINER","LIFTOFF","LIFTON","CHASSISTERMINATION","STOPOFF", "CHASSISPICK"]
  if(isNewSupportMovesEnabled()) {
    ROUTING_ADDRESS_FIELDS_NEW = [...ROUTING_ADDRESS_FIELDS_NEW, "BOBTAILTRANSFER", "CHASSISTRANSFER", "BOBTAIL_ENDED", "BOBTAIL_STARTED", "DROPCHASSIS"]
  }
  const firstValidIndex = tripOrder?.findIndex((x)=>!x.isVoidOut);
  const firstValidLeg = tripOrder?.[firstValidIndex];
  const carrierDetails = JSON.parse(getStorage("carrierDetail"));
  let currentUser = JSON.parse(getStorage("loggedInUser"));
  let { driverPayShowPermission, driverPayEditPermission } = checkAccountPayablePermission(currentUser);

    return (
        <>
            <div className="h-100 d-flex flex-column">
                <div className="d-flex align-items-center mb-15">
                    <div className="d-flex align-items-center">
                        <div className="font-20 line-height-25">
                            Trip #:{" "}
                            {orderDetails.tripNumber}
                        </div>
                        <span className={`ml-10 text-capitalize text-white badge badge-sm badge-status-${
        statusObj && statusObj.colorStatus ? statusObj.colorStatus : "purple"
      }`}>
              {statusObj && statusObj.status && statusObj.status.toLowerCase()}

                        {/* {(orderDetails.status).toUpperCase()} */}
                        </span>
                    </div>
                    <div className="d-flex ml-auto gap-5">
                        {(orderDetails?.isCombinedTrip || orderDetails?.tripType === TRIP_TYPES.SM_MANUAL) && <button
                          className="btn btn-outline-light bg-white"
                          onClick={() => handleRemoveTrip()}
                        >
                            <IconDelete className="text-muted" />
                        </button>}
                        {orderDetails?.isCombinedTrip && ["AVAILABLE", "DISPATCHED", "PENDING"].includes(orderDetails?.status) && <button className="btn btn-outline-light bg-white" onClick={() => {
                          getCombinedMoveDetails()
                        }}>
                            <IconPen className="text-muted" />
                        </button>}
                        {
                          tripEditModal && tripMoveDetails?.length > 0 && <CombineTripCreateModal 
                            onCancel={() => {
                              setTripEditModal(false)
                            }}
                            onSubmit={()=>{
                              setTripEditModal(false)
                            }}
                            tripId={orderDetails?._id}
                            step1Moves={tripMoveDetails}
                            initiallySelectedMoves={tripMoveDetails}
                            driverId={orderDetails?.driverId?._id}
                          />
                        }
                        <button
                            className="btn btn-outline-light bg-white"
                          onClick={() => closeModal()}
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div className="form-row-md overflow-hidden">
                    {orderDetails?.isCombinedTrip?
                      <div className="col-md overflow-y-auto h-100 custom-scrollbar-sm" style={{ maxWidth: 270 }}>
                      <div className="card p-15 mb-1">
                          <h5>{updateTerminology("Port")}</h5>
                          {
                            getConsigneePortFromTrip("PULLCONTAINER").map((consignee)=>(
                              <>
                                <div>
                                    {consignee.company_name}
                                </div>
                                <div className="mb-10">
                                    {consignee.address}
                                </div>
                              </>
                            ))
                          }
                      </div>
                      {getConsigneePortFromTrip("DELIVERLOAD").length > 0 && (
                      <div className="card p-15 mb-1">
                          <h5>{updateTerminology("Consignee")}</h5>
                          {
                            getConsigneePortFromTrip("DELIVERLOAD").map((consignee)=>(
                              <>
                                <div>
                                    {consignee.company_name}
                                </div>
                                <div className="mb-10">
                                    {consignee.address}
                                </div>
                              </>
                            ))
                          }
                          
                          
                      </div>
                      )}
                      {getConsigneePortFromTrip("RETURNCONTAINER").length > 0 && (
                        <div className="card p-15 mb-1">
                            <h5>Container Return</h5>
                            {
                              getConsigneePortFromTrip("RETURNCONTAINER").map((consignee)=>(
                                <>
                                  <div>
                                      {consignee.company_name}
                                  </div>
                                  <div className="mb-10">
                                      {consignee.address}
                                  </div>
                                </>
                              ))
                            }
                            
                            
                        </div>
                      )}
                      {orderDetails?.isCombinedTrip &&
                        <div className="card p-15 mb-1">
                            <h5>Position</h5>
                            {
                              orderDetails?.combinedMoves.map((val, i) => {
                               const containerValue = containerPositions?.find(opt => opt?.moveId === val?.moveId)
                               return (
                                 <>
                                   <div className="mb-10">
                                     <div className='d-flex align-items-center'>
                                       <div className="font-12 font-weight-500 mb-2">{val?.reference_number}</div>
                                       {val?.containerNo && (
                                         <div className="font-12 font-weight-500 mb-2 bg-gray-100 px-2 py-1 rounded-3 ml-2">{val?.containerNo}</div>
                                       )}
                                     </div>
                                     <div className="d-flex">
                                       <div className="form-check mr-4">
                                         <input
                                           type="radio"
                                           className={`form-check-input`}
                                           id={`front-${i}`}
                                           checked={
                                             containerValue?.position
                                               ? containerValue?.position === containerPositionConst.FRONT
                                               : false
                                           }
                                           onChange={(e) =>
                                             handlePositionChange(containerPositionConst.FRONT, val?.moveId)
                                           }
                                           disabled={isCompleted || isSystemSupportMove}
                                         />
                                         <label className="form-check-label" htmlFor={`front-${i}`}>
                                           Front
                                         </label>
                                       </div>
                                       <div className="form-check">
                                         <input
                                           type="radio"
                                           className={`form-check-input`}
                                           id={`back-${i}`}
                                           checked={
                                             containerValue?.position
                                               ? containerValue?.position === containerPositionConst.BACK
                                               : false
                                           }
                                           onChange={(e) =>
                                             handlePositionChange(containerPositionConst.BACK, val?.moveId)
                                           }
                                           disabled={isCompleted || isSystemSupportMove}
                                         />
                                         <label className="form-check-label" htmlFor={`back-${i}`}>
                                           Back
                                         </label>
                                       </div>
                                     </div>
                                   </div>
                                 </>
                               );
                              })
                            }
                        </div>
                      }
                      {orderDetails?.isCombinedTrip && isCompleted &&
                        <div className="card p-15 mb-1">
                          <h5>Trip Completed Date</h5>
                          <div className="form-group">
                            <div className={`input-wrapper vessel vesselETA`}>
                              <div className="position-relative">
                                <DateWrapper
                                    timeFormat= {DateTimeFormatUtils.fullDateTimeFormat()}
                                    isShowTime={true}
                                    displayTimeZone={getStorage("timeZone")}
                                    defaultDate={tripCompletedAt ? tripCompletedAt : "" }
                                    hideShowSelectDate={true}
                                    onChange={(date) => setTripCompletedAt(moment(date).toISOString()) }
                                    OnBlurUpdate={() => handleTripDateChanged()}
                                  />
                                <div className="input-icon">
                                  <IconCalendar />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {orderDetails?.isCombinedTrip && isCompleted &&
                      <div className="card">
                          <div className="p-10 moreinfo-card">
                                <button
                                    className="btn btn-outline-error-100 btn-block"
                                    onClick={() => unCompleteTripForCombinedTrip()}
                                  >
                                      Uncomplete Trip
                                </button>
                            </div>
                        </div>
                      }
                      {orderDetails?.isCombinedTrip && !isCompleted &&
                      <div className="card">
                          <div className="p-10 moreinfo-card">
                                <button
                                    className="btn btn-outline-error-100 btn-block"
                                    disabled={(orderDetails.isVoidOut) ? true : (isCompleted || orderDetails?.status==="PENDING")  }
                                    onClick={() => completeTripForCombinedTrip()}
                                  >
                                      Complete Trip
                                </button>
                            </div>
                        </div>
                      }
                      </div>
                      : 
                      <div className="col-md overflow-y-auto h-100 custom-scrollbar-sm" style={{ maxWidth: 270 }}>
                          <div className="card p-15 mb-1">
                              <h5>{isSupportMove  ? statusType[orderDetails?.tripOrder?.[0]?.type] : "Customer"}</h5>
                              <div>
                                {isSupportMove ? <>{orderDetails?.tripOrder?.[0]?.company_name
                                  ? orderDetails?.tripOrder?.[0]?.company_name
                                  : ""}</> : <>{orderDetails?.orderId?.callerInfo
                                    ? orderDetails.orderId.callerInfo.company_name
                                    : ""}</>}
                              </div>
                              <div className="mb-10">
                                {isSupportMove ? `${orderDetails?.tripOrder?.[0]?.address?.address
                                  ? orderDetails?.tripOrder?.[0]?.address?.address
                                  : ""}` : <> {orderDetails?.orderId?.callerInfo && orderDetails?.orderId?.callerInfo.address
                                    ? orderDetails.orderId.callerInfo.address.address
                                    : ""}</>}
                              </div>
                              
                          {!isSupportMove && <div className="mt_5 load__infocard d-flex">
                            <span data-tip className="info__icon rounded d-inline-block d-flex align-items-center p-1 mr-1" data-for="customerinfo2"><IconPhone className="" /></span>
                            <span data-tip className="info__icon rounded d-inline-block d-flex align-items-center p-1 mr-1" data-for="customerinfo3"><IconAt className="text-primary rounded" /></span>
                          </div>}

                          {orderDetails.orderId?.callerInfo?.mobile !== "" && <ReactTooltip id="customerinfo2" place="top" effect="solid" className="react-tooltip-new">
                            {orderDetails.orderId?.callerInfo?.mobile}
                          </ReactTooltip>}
                          {orderDetails.orderId?.callerInfo?.billingEmail !== "" && <ReactTooltip id="customerinfo3" place="top" effect="solid" className="react-tooltip-new">
                            {orderDetails.orderId?.callerInfo?.billingEmail}
                          </ReactTooltip>}
                          </div>
                          {isSupportMove ?
                            <>
                              {orderDetails?.tripOrder?.[1] &&<div className="card p-15 mb-1">
                                <h5>{statusType[orderDetails?.tripOrder?.[1]?.type]}</h5>
                                <div>
                                  {orderDetails?.tripOrder?.[1]?.company_name
                                    ? orderDetails?.tripOrder?.[1]?.company_name
                                    : ""}
                                </div>
                                <div className='mb-10'>{orderDetails?.tripOrder?.[1]?.address?.address
                                  ? orderDetails?.tripOrder?.[1]?.address?.address
                                  : ""}
                                </div>
                              </div>}
                            </>
                            : <>
                              <div className="card p-15 mb-1">
                              <h5>{updateTerminology("Port")}</h5>
                                {orderDetails.orderId?.shipperInfo?.map((shipper, sI) => {
                                  return (
                                    <React.Fragment>
                                      <div className="font-medium mb-1">
                                        {shipper.company_name}
                                      </div>
                                      <div className="mb-10">
                                        {shipper.address && shipper.address.address ? shipper.address.address : ""}
                                        <div className="mt_5 load__infocard d-flex">
                                          <span data-tip className="info__icon rounded d-inline-block d-flex align-items-center p-1 mr-1" data-for="contactinfo"><IconPhone className="" /></span>
                                          <span data-tip className="info__icon rounded d-inline-block d-flex align-items-center p-1 mr-1" data-for="emailinfo"><IconAt className="text-primary" /></span>
                                        </div>
                                      </div>
                                      {shipper?.mobile !== "" && <ReactTooltip id="contactinfo" place="top" effect="solid" className="react-tooltip-new">
                                        <div
                                          className={`${shipper?.email && "mb-10"
                                            } d-flex align-items-center`}
                                        >
                                          <div className="flex-grow-1">
                                            {shipper?.mobile}
                                          </div>
                                        </div>

                                      </ReactTooltip>}
                                      {shipper?.email !== "" && <ReactTooltip id="emailinfo" place="top" effect="solid" className="react-tooltip-new">
                                        <div className="d-flex align-items-center">
                                          <div className="flex-grow-1 text-break">
                                            {shipper?.email}
                                          </div>
                                        </div>
                                      </ReactTooltip>}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </>
                          }
                          {/* TODO: add condition for show Chassis details */}
                          {/* ![SUPPORT_MOVE_TO_ROUTING.BOBTAIL_STARTED].includes("supportMoveType") && */}
                             {showChassisNo && <div className="card p-15 mb-1">
                                {/* Chassis No */}
                                 <div class="form-group">
                                  <label>Chassis #</label>
                                  <div className="d-flex">
                                    <AsyncSelect
                                      className={`chassisNo w-100`}
                                      styles={smallSelectStyle}
                                      cacheOptions
                                      loadOptions={getChassis}
                                      defaultOptions={chassisOptions}
                                      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                                      value={chassisNo ?? null}
                                      onChange={(val) => {
                                        selectChassis(val)
                                      }}
                                      isDisabled={isCompleted || isSystemSupportMove}
                                    />
                                  </div>
                                </div>
                                {isSupportMove && (
                                  <>
                                    {/* Chassis Size */}
                                    <div class="form-group">
                                      <label>Chassis Size</label>
                                      <div className="d-flex">
                                        <AsyncSelect
                                          className={`chassisSize w-100`}
                                          placeholder="Size"
                                          styles={smallSelectStyle}
                                          defaultOptions={chassisSizeOptions}
                                          loadOptions={getChassisSize}
                                          onChange={(val) => {
                                            setChassisSize(val)
                                            _updateChassisDetail("chassisSize", val, 'chassisSize')
                                          }}
                                          isDisabled={isCompleted || isSystemSupportMove}
                                          value={_chassisSize ?? null}
                                        />
                                      </div>
                                    </div>
                                    {/* Chassis Type */}
                                    <div class="form-group">
                                      <label>Chassis Type</label>
                                      <div className="d-flex">
                                        <AsyncSelect
                                          className={`chassisType w-100`}
                                          placeholder="Type"
                                          styles={smallSelectStyle}
                                          defaultOptions={chassisTypeOptions}
                                          loadOptions={getChassisType}
                                          onChange={(val) => {
                                            setChassisType(val);
                                            _updateChassisDetail("chassisType", val, 'chassisType')
                                          }}
                                          isDisabled={isCompleted || isSystemSupportMove}
                                          value={_chassisType ?? null}
                                        />
                                      </div>
                                    </div>
                                    {/* Chassis Owner */}
                                    <div class="form-group">
                                      <label>Chassis Owner</label>
                                      <div className="d-flex">

                                        <AsyncSelect
                                          className={`chassisOwner w-100`}
                                          placeholder="Select Owner"
                                          styles={smallSelectStyle}
                                          defaultOptions={chassisOwnerOptions}
                                          loadOptions={getContainerSize}
                                          onChange={(val) => {
                                            setChassisOwner(val)
                                            _updateChassisDetail("chassisOwner", val, 'chassisOwner')
                                          }}
                                          isDisabled={isCompleted || isSystemSupportMove}
                                          value={_chassisOwner ?? null}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>}
                              {isSupportMove && (<div className="card p-15 mb-1">
                                <h5>Load Completed Date</h5>
                                <React.Fragment>
                                  <div className="input-wrapper mt-1 mb-10">
                                    <div className="position-relative">
                                      <DateWrapper
                                        disabled={true}
                                        // onRemoveDate={(e) => {this.onRemovePress(e, { name: "emptyDay" });}}
                                        // OnBlurUpdate={(e) => this.onDateSelect("emptyDay", this.props.customerProfile.emptyDay)}
                                        // onChange={(e) => {this.props.datechanges("emptyDay", e) }}
                                        displayTimeZone={timeZone}
                                        isShowTime={false}
                                        defaultDate={orderDetails?.completedAt} 
                                        hideShowSelectDate={true}
                                      />
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                                {!isCompleted && !isSystemSupportMove && <button
                                  className="btn btn-outline-error-100 btn-block"
                                  onClick={() => completeOrderTrip()}
                                >
                                  {"Complete Trip"}
                                </button>}
                              </div>)}
                      </div>
                    }
                    {!isSupportMove && (isCombinedTrip || isFreeFlowTrip) && <div className="col" style={{ maxWidth: 250 }}>
                        {!isSupportMove && <div className="card p-10 mb-10">
                            <div className="form-group">
                                <label>{!isCombinedTrip ? 'Drag & Drop to Add Legs' : 'Events'}</label>
                                {!isCombinedTrip && <button
                                    className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                                    id="CHASSISPICK"
                                    draggable={(orderDetails.isVoidOut) ? false : (!tripOrder.some(el=>(el.type==="CHASSISPICK"||el.arrived||el.departed))) }
                                    onDragStart={(e) => handleDragStart(e)}
                                    onDragOver={handleDragOver}
                                    onDragEnd={() =>{setIsDragging(false); setDropIndex(null)}}
                                >
                                    <IconDrag className="mr-10 text-gray-200" />
                                    {/* <IconLoadCartRight className="mr-10" /> */}
                                    <span>Hook Chassis</span>
                                </button>}
                                {isCombinedTrip &&
                                  ROUTING_DRAG_LEGS.map((dLeg) => {
                                    if ([loadEvents.STOPOFF].includes(dLeg.leg) && !userBasicSettings?.isStopOff) {
                                      return
                                    }
                                    if (['COMPLETED'].includes(dLeg.leg)) return;
                                    if (isNewSupportMovesEnabled() && [loadEvents.CHASSISTERMINATION, loadEvents.CHASSISPICK].includes(dLeg.leg)) return;
                                    return (
                                      <button
                                        className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                                        id={dLeg.leg}
                                        draggable
                                        onDragStart={(e) => handleDragStartForAddEvent(e)}
                                        onDragOver={handleDragOver}
                                        onDragEnd={() => { setIsDragging(false); setDropIndex(null) }}
                                      >
                                        <IconDrag className="mr-10 text-gray-200" />
                                        <span>{dLeg.legTitle}</span>
                                      </button>
                                    )
                                  })}
                            </div>
                        </div>}
                        {!orderDetails?.isCombinedTrip &&<div className="card">
                            <div className="p-10 moreinfo-card">
                                {!isCompleted && !isSystemSupportMove && <button
                                    className="btn btn-outline-error-100 btn-block"
                                disabled={(orderDetails.isVoidOut) ? true : (isCompleted || (isFreeFlowTrip ? orderDetails?.status==="PENDING" : false))  }
                                onClick={() => completeOrderTrip()}
                                >
                                    Complete Trip
                                </button>}
                                {isCompleted && <React.Fragment>
                                    <span className="text-dark font-weight-500 mr-3">
                                        Trip Completed Date
                                    </span>
                                    <div className="input-wrapper outline-btn-success mt-1 mb-10">
                                        <div className="btn-outline-success-100">
                                            <DateWrapper
                                                dateFormat="MM-DD-yyyy"
                                                // timeFormat={this.userTimeFormat}
                                                isShowTime={true}
                                                // onChange={(date) =>
                                                //     this.setState({ loadCompletedAt: moment(date).toISOString() })
                                                // }
                                                displayTimeZone={getStorage("timeZone")}
                                                defaultDate={
                                                    orderDetails?.completedAt ? moment(orderDetails?.completedAt) : ""
                                                }
                                                disabled={true}
                                                // OnBlurUpdate={() => this.changeLoadCompletedDate()}
                                                hideShowSelectDate={true}
                                            />
                                            <div
                                                className="input-icon"
                                            // onClick={() => this.changeLoadCompletedDate()}
                                            >
                                                <IconCheck />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <button
                                        className="btn btn-outline-error-100 btn-block"
                                    // onClick={() => this.uncompleteLoad()}
                                    >
                                        Uncomplete Trip
                                    </button> */}
                                </React.Fragment>}
                            </div>
                        </div>}
                    </div>}
                    <div className="col overflow-y-auto h-100 custom-scrollbar-sm">
                        {isLoading && <LoaderBar />}
                        <div>
                        <div className="card card-blue mb-10">
                            <div className="p-15">
                                <div className="d-flex align-items-center gap-10">
                                    <div className={`btn bg-primary-50 flex-shrink-0 rounded-6 line-height-18 ${isManualSupportMove ? "bg-purple-150" : "bg-primary-50"}`}>
                                        <div className={`font-medium ${isManualSupportMove ? 'text-purple-400' : "text-blue-500"}`}>Move 1</div>
                                    </div>

                                      <DriversSelect 
                                        options={
                                          fetchDriverUser(orderDetails)
                                          // AllDrivers
                                          // .filter((dd) => !dd.isDeleted && !dd.isBlocked)
                                          // .map((d) => ({
                                          //   value: d._id,
                                          //   label: `${d.name} ${d.lastName || ""}`.trim(),
                                          //   allInfos: d
                                          // }))
                                      }
                                        value={selectedOption}
                                        onChange={handleChange}
                                        className={`select-driver-1`}
                                        isDisabled={
                                          (tripOrder[0]?.arrived && !tripOrder[0]?.isVoidOut) ||
                                          ["PENDING","COMPLETED"].includes(orderDetails.status) 
                                          // this.disabledDriver
                                        }
                                        isClearable={!isDriverAcceptMovesEnabled() && (isFreeFlowTrip || isCombinedTrip || isManualSupportMove)}
                                        components={isDriverAcceptMovesEnabled() ? {ValueContainer: CustomValueContainer} : {}}
                                        showOptionTypes={["DRIVER"]}
                                      />
                                    {isSupportMove &&
                                    <div className="form-group w-200 mb-0 date__picker-wrapper h-30">
                                      <div className={`input-wrapper loadAssignDate`}>
                                        <span className="input-icon-left" data-tip data-for={`loadAssignDate`}>
                                          <IconTimer className="text-gray-300" />
                                        </span>
                                        <ReactTooltip
                                          effect="solid"
                                          id={`loadAssignDate`}
                                          className="type-gray w-300 text-wrap text-break line-break"
                                        >
                                          <span>Use this date to select when you want to schedule the driver to work on the load</span>
                                        </ReactTooltip>
                                        <div>
                                          <ExactDateWrapper
                                            className={`loadAssignDate laDate`}
                                            inputClassName="pl-35"
                                            dateFormat="MM-DD-yyyy"
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            isShowTime={true}
                                            displayTimeZone={getTimeZone({ preferred: true })}
                                            disabled={true}
                                            defaultDate={orderDetails?.tripAssignedDate ? moment(orderDetails.tripAssignedDate) : ""}
                                            // OnBlurUpdate={(e) => _updateLoadAssignDate(e, `loadAssignDate-${dIndex}`, move)}
                                            hideShowSelectDate={true}
                                          />
                                        </div>
                                      </div>
                                    </div>}
                                </div>
                                <div
                                    className="form-row pb-3 align-items-end"
                                    onDrop={(e) => orderDetails?.isCombinedTrip ? handleOnSwap(e) : handleOnDrop(e)}
                                    onDragOver={(e) => handleDragOver(e)}
                                    onDragEnter={() => handleDragEnter(100)}
                                >
                                    {dropIndex === 100 && (
                                        <div className="col-12 kanban-card-view mt-4">
                                            <p className="text-center my-4">
                                                Drop Your Status Here
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex flex-column gap-10">
                                {tripOrder.map((leg, index) => {
                                  const addressComp = ROUTING_ADDRESS_FIELDS[leg.type];
                                  const nextValidIndex = returnNextValidMoveIndex(index,tripOrder)
                                  const nextValidLeg = tripOrder[nextValidIndex];
                                  let durationForMove = (leg.departed && nextValidLeg?.arrived && moment(nextValidLeg?.arrived).diff(moment(leg.departed), "minute"))
                                  let hoursForMove = durationForMove && parseInt(durationForMove / 60);
                                  let minutesForMove = durationForMove && parseInt(durationForMove % 60);
                                  let durationForMoveString = getDurationInHHMMFormat(
                                    nextValidLeg?.arrived,
                                    leg.departed,
                                    (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: leg?.timeZone || timeZone, toTimeZone: leg?.timeZone || timeZone})
                                  )
                                  let durationForStartToArrival = getDurationInHHMMFormat(
                                    leg?.departed,
                                    firstValidLeg?.arrived,
                                    (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: leg?.timeZone || timeZone, toTimeZone: leg?.timeZone || timeZone})
                                  )
                                  let durationBetweenLegs = (nextValidLeg?.arrived && nextValidLeg?.departed) ? getDurationInHHMMFormat(
                                    nextValidLeg.departed, 
                                    nextValidLeg.arrived,
                                    (setting?.isUserSpecificTimeZoneEnabled && {fromTimeZone: leg?.timeZone || timeZone, toTimeZone: nextValidLeg?.timeZone || timeZone})
                                  ) :"-";
                                  const distanceBetweenLegs = getDistanceBetweenEvents(tripOrder,index,nextValidIndex);
                                  const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";
                                  const showLegPairs = orderDetails?.isCombinedTrip || isSupportMove;
                                  let driverPayRate = null;
                                  const isEditingPay = editDriverPayData?.fromEventId === leg._id && editDriverPayData?.toEventId === nextValidLeg?._id;
                                  if (isEditingPay) driverPayRate = editDriverPayData;
                                  const currentDriverId = nextValidLeg?.driver?._id || nextValidLeg?.driver
                                  if (!driverPayRate) {
                                    driverPayRate = driverPays.find((pay) => pay.fromEventId === leg._id && pay.toEventId === nextValidLeg?._id && pay?.createdBy?._id === currentDriverId );
                                  }
                                  const infoDriverPay = {
                                    driverPayRateId: !driverPayRate?.isManual && driverPayRate?.driverPayRateId,
                                    dynamicDriverPayId:!driverPayRate?.isManual && driverPayRate?.dynamicDriverPayId,
                                    isManual:driverPayRate?.isManual
                                  }
                                  const isHideStatus = isNewSupportMovesEnabled() && orderDetails?.tripType === TRIP_TYPES.SM_MANUAL && leg?.type === supportMoveTypes.BOBTAIL_STARTED;
                                  const currencySymbol = "$"

                                  const isFirstEvent = index === 0;
                                  const isLastEvent = index === tripOrder.length - 1;

                                  const isStartMoveEvent = isFirstEvent && firstValidLeg.arrived;
                                  const isNextPending = !leg.isVoidOut && nextValidLeg && !nextValidLeg.departed; 

                                  const isArrivedCompleted = orderDetails?.driverId && !leg.isVoidOut && leg.departed && isLastEvent;
                                  const isCompletedEvent = (orderDetails?.driverId && !leg?.isVoidOut && nextValidLeg?.arrived) || isArrivedCompleted;

                                  const inProgressEvent = ((orderDetails?.driverId || orderDetails?.drayosCarrier) && !leg.isVoidOut && leg?.departed && leg?.arrived && !nextValidLeg?.arrived && !isLastEvent) || isStartMoveEvent;
                                  
                                  const eventClass = getTrackingEventClasses(leg?.isVoidOut, isCompletedEvent || isArrivedCompleted, inProgressEvent && !isNextPending, isNextPending && !inProgressEvent);
                                  const topEventClass = getTrackingEventClasses(leg?.isVoidOut, isCompletedEvent || isArrivedCompleted, inProgressEvent, isNextPending);
                                  
                                  const LayoutComponent = ({ label="", children, isDuration, isActive }) => {
                                    const showArrow = !leg.isVoidOut && isActive && (
                                      (label === "Start" && leg.arrived && !leg.departed) ||
                                      (label === "Arrival" && !isCompletedEvent) || 
                                      (label === "Departure" && !nextValidLeg?.departed)
                                    );
                                    const isStart = label === "Start" && isStartMoveEvent;
                                    const routeLineClass = !isDuration && `route-line route-line--top ${!isLastEvent? "route-line--bottom": ""} ${isStart? topEventClass.routeLineColorClass : eventClass.routeLineColorClass}`;
                                    return (
                                      <div className="d-flex align-items-center gap-10">
                                        <div className={`wh-20px d-flex align-items-center justify-content-center position-relative`}>
                                          {showArrow && 
                                            <div 
                                              className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                                              style={{
                                                outline: '5px solid rgba(54, 123, 246, 0.3)',
                                              }}
                                            >
                                              <IconNavigationArrowBottom />
                                            </div>
                                          }
                                          {!isDuration && !showArrow && <div className={`wh-6px rounded-circle outline-1 ${isStart ? topEventClass.dotColorClass : eventClass.dotColorClass} ${routeLineClass}`}></div>}
                                        </div>
                                        <div className="w-125 text-muted">{label && label}</div>
                                        {children && children}
                                      </div>
                                    );
                                  };
                                    return (
                                      <>

                                          <div
                                            onDragOver={handleDragOver}
                                            onDragEnter={() => { handleDragEnter(index); }}
                                            onDrop={(e) => handleOnSwap(e)}
                                            className={`d-flex flex-column gap-10 ${!leg.isCombinedTrip ? "pointer-grab position-relative" : ""} ${leg?.eventId === dragEventId ? "bg-light opacity-05" : ""}`}
                                          >
                                              <div 
                                              className={`outline-card p-10 d-flex flex-column ${eventClass.routeLineColorClass} ${!leg.isVoidOut && !isLastEvent ? "route-line--cardbottom" : ""}`} 
                                              draggable={orderDetails?.isCombinedTrip && !leg.arrived && !leg.departed}
                                              onDragStart={(e) => handleDragStart(e, leg)}
                                              onDragEnd={() => { setIsDragging(false); setDropIndex(null); setDragEventId(null); }}
                                              >
                                                <div className={`d-flex align-items-center mb-10 gap-10`}>
                                                  <div className={`position-relative route-line route-line--bottom ${topEventClass.routeLineColorClass} ${!isFirstEvent ? "route-line--top route-line--topfull" : ""} wh-20px ${leg.isVoidOut ? "" : "outline-1"} d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle ${topEventClass.eventCircleColorClass}`}>
                                                    {isCompletedEvent && !leg.isVoidOut ?
                                                      <IconCompleted />
                                                      :
                                                      leg.isVoidOut ?
                                                        <IconTimesThin className="text-danger" />
                                                      :
                                                      <span className='font-10 line-height-12 font-bold'>{index + 1}</span>
                                                    }
                                                  </div>
                                                  <div className={`d-flex flex-column align-items-start gap-2 w-125`}>
                                                    <span className={`badge ${topEventClass.badgeColorClass}`}>
                                                        {statusType[leg.type]}
                                                    </span>
                                                    
                                                  </div>   
                                                  <div className="w-100 d-flex align-items-start gap-10">
                                                      {leg.type === "PULLCONTAINER" && (
                                                          <AsyncSelect
                                                              className={`w-100 leg-${index}`}
                                                              value={
                                                                leg.customerId ? {
                                                                  label: `${
                                                                    leg.customerId.company_name
                                                                  } ${
                                                                    leg.customerId.address
                                                                      ? leg.customerId.address
                                                                          .address
                                                                      : ""
                                                                  }`.trim(),
                                                                  value: leg.customerId._id,
                                                                } : null
                                                              }
                                                              isDisabled={true}
                                                              styles={smallSelectStyle}
                                                          />
                                                      )}
                                                      {ROUTING_ADDRESS_FIELDS_NEW.includes(leg.type) && (
                                                          <AsyncSelect
                                                              className={`w-100 leg-${index}`}
                                                              value={
                                                                leg.customerId ? {
                                                                  label: `${
                                                                    leg.customerId.company_name
                                                                  } ${
                                                                    leg.customerId.address
                                                                      ? leg.customerId.address
                                                                          .address
                                                                      : ""
                                                                  }`.trim(),
                                                                  value: leg.customerId._id,
                                                                } : null
                                                              }
                                                              defaultOptions={chassisTermOptions}
                                                              onChange={(value) =>{
                                                                selectCustomerForChassisPick(value.value, leg.type, leg._id)                                        
                                                            }}                                           
                                                            loadOptions={getChassisPickCustomersAsyncSearch}
                                                            isDisabled={isCombinedTrip || isCompleted || (isManualSupportMove && leg?.eventId)}
                                                              styles={smallSelectStyle}
                                                              menuPosition="fixed"
                                                          />
                                                      )}
                                                      {leg.type === loadEvents.STOPOFF && (
                                                        <div className="p-0 select-wrapper__input">
                                                          <Select
                                                            isDisabled={leg.arrived}
                                                            placeholder="Select Option"
                                                            className={`w-200 stopoff-${index}`}
                                                            styles={newSmSelectStyle}
                                                            options={STOPOFF_TYPES()
                                                              ?.filter((d) => !d.isDeleted)
                                                              ?.map((d) => {
                                                                return { label: d.name, value: d._id }
                                                              })}
                                                            value={
                                                              leg?.stopOffType
                                                                ? { value: leg?.stopOffType, label: leg?.stopOffTypeName }
                                                                : ""
                                                            }
                                                            onChange={(e) => {
                                                              updateStopOffName(e, leg.loadId, leg.eventId, `stopoff-${index}`)
                                                            }}
                                                          />
                                                        </div>
                                                      )}

                                                      <div className="d-flex align-items-center gap-10">
                                                        {leg?.stopOffTypeName && <span className="badge badge-status-light-brown">{leg.stopOffTypeName}</span>}
                                                        <span class="badge badge-sm badge-light pointer" onClick={() => setLoadInfoModal({reference_number : leg?.reference_number, isShow : true})}>{leg?.reference_number}</span>
                                                        <div className="d-flex ml-auto align-items-center flex-shrink-0">
                                                          {isFreeFlowTrip && orderDetails?.driverId 
                                                          && !leg.isVoidOut && leg.arrived && !nextValidLeg?.arrived 
                                                          && !isCompleted  &&(
                                                                  <button
                                                                      className="btn btn-secondary btn-sm"
                                                                  onClick={() =>
                                                                    voidOutModal(orderDetails._id,leg._id)
                                                                  }
                                                                  >
                                                                      {'Void Out'}
                                                                  </button>
                                                              )}
                                                            { !leg.isVoidOut && leg.type ==="CHASSISPICK" && isFreeFlowTrip &&!leg.departed && (
                                                              <button
                                                                  className="btn btn-link text-muted"
                                                                disabled={(orderDetails.isVoidOut) ? true : isCompleted}
                                                                onClick={() =>removeLeg(index, leg)}
                                                              >
                                                                  <IconTrash />
                                                              </button>
                                                          )}
                                                          {isCombinedTrip && !leg.departed && !isCompleted && (
                                                            <button
                                                              className="btn btn-link text-muted"
                                                              disabled={(orderDetails.isVoidOut) ? true : isCompleted}
                                                              onClick={() => _removeLegForCombinedTrip(index, leg)}
                                                            >
                                                              <IconTrash />
                                                            </button>
                                                          )
                                                          }
                                                        </div>
                                                      </div>
                                                  </div>    
                                                </div>                 
                                                <div className="d-flex flex-column gap-8 align-items-start">
                                                  {isFirstEvent && ((orderDetails?.driverId && firstValidLeg?.arrived)|| (firstValidLeg && orderDetails?.driverId && !firstValidLeg?.arrived)) &&
                                                  <div className="position-relative">
                                                    <LayoutComponent label="Start" isActive={isStartMoveEvent}>
                                                      {firstValidLeg && orderDetails?.driverId && !firstValidLeg?.arrived && ( //done
                                                        <button
                                                          className={`btn btn-xss ${isSupportMove ?  "btn-purple-400" : "btn-success"} w-150 text-center`}
                                                          onClick={() =>
                                                            updateLegStatus(orderDetails._id, firstValidLeg?._id, "arrived", firstValidIndex)
                                                          }
                                                          disabled={isSystemSupportMove} 
                                                        >
                                                          <span className="w-100">Start Move</span>
                                                        </button>
                                                      )}
                                                      { orderDetails?.driverId && firstValidLeg?.arrived && (
                                                        <div className="input-wrapper">
                                                          {/* <p className="driver-order-status-text text-primary">Enroute</p> */}

                                                            <ExactDateWrapper
                                                              className="mr-2 laDate right-0"
                                                              inputClassName={`w-150 form-control-sm date-arrived-${firstValidLeg._id}`}
                                                              isShowTime={true}
                                                              onChange={(e)=>{handleDateChanged(e,firstValidIndex,"arrived", `date-arrived-${firstValidLeg._id}`)}}
                                                              OnBlurUpdate={(e) =>
                                                                updateLegTime(orderDetails._id, firstValidLeg._id, "arrived",firstValidIndex, e, `date-arrived-${firstValidLeg._id}`)
                                                              }
                                                              dateFormat="MM-DD-yyyy"
                                                              timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                              //   timeFormat={this.userTimeFormat}
                                                              displayTimeZone={getStorage("timeZone")}
                                                              disabled={isCompleted || isSystemSupportMove}
                                                              defaultDate={firstValidLeg.arrived ? moment(firstValidLeg.arrived) : ""}
                                                              //   OnBlurUpdate={() => this.state.changeCompletionDate && this.updateDriverOrderById('arrived', status)}
                                                              hideShowSelectDate={true}
                                                            />
                                                            {!isSystemSupportMove && !isCompleted && firstValidLeg?.arrived && (
                                                              <div
                                                                className="input-icon"
                                                                onClick={() =>
                                                                  removeLegStatus(orderDetails._id, firstValidLeg._id, "arrived")
                                                                }
                                                              >
                                                                <IconTimes />
                                                              </div>
                                                            )}

                                                        </div>
                                                      )}
                                                    </LayoutComponent>
                                                    {!isHideStatus &&
                                                    <span 
                                                         className="font-12 font-normal position-absolute badge badge-light badge-sm position-relative left-100 ml-10 curved-arrow-left-top curved-arrow-left-bottom"
                                                         style={{ 
                                                             top: "100%", 
                                                             transform: "translateY(-50%)",
                                                         }}
                                                      >
                                                      {leg?.departed && firstValidLeg?.arrived && durationForStartToArrival ? 
                                                      (
                                                        <span>
                                                          {durationForStartToArrival}
                                                        </span>
                                                      )
                                                      : <span className="px-1">-</span>}
                                                      </span>
                                                  }
                                                  </div>
                                                  }                              
                                                  
                                                 {!isHideStatus && <div className="d-inline-flex flex-column align-items-start gap-8 position-relative align-self-start">
                                                  {orderDetails?.driverId  && !leg.isVoidOut && !leg.departed && ( //done
                                                    <LayoutComponent label="Arrival">
                                                    <button
                                                      className={`btn btn-xss ${isSupportMove ?  "btn-purple-400" : "btn-primary"} w-150 text-center`}
                                                      onClick={() =>
                                                        updateLegStatus(orderDetails._id, leg._id, "departed", index)
                                                      }
                                                      disabled={isSystemSupportMove}
                                                    >
                                                      <span className="w-100">Arrived</span>
                                                    </button>
                                                    </LayoutComponent>
                                                  )}
                                                  
                                                  {orderDetails?.driverId && !leg.isVoidOut && leg.departed && (
                                                    <LayoutComponent label="Arrival" isActive>
                                                    <div className="input-wrapper">
                                                      <div className="right">
                                                        <ExactDateWrapper
                                                          className="mr-2 laDate"
                                                          inputClassName={`w-150 form-control-sm date-departed-${leg?._id}`}
                                                          OnBlurUpdate={(e) =>
                                                            updateLegTime(orderDetails._id, leg._id, "departed",index , e, `date-departed-${leg?._id}`)
                                                          }
                                                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                          onChange={(e)=>{handleDateChanged(e,index,"departed", `date-departed-${leg?._id}`)}}

                                                          displayTimeZone={getStorage("timeZone")}
                                                          disabled={isCompleted || isSystemSupportMove}
                                                          defaultDate={leg.departed ? moment(leg.departed) : ""}
                                                          dateFormat="MM-DD-yyyy hh:mm"
                                                          //   timeFormat={this.userTimeFormat}
                                                          //   OnBlurUpdate={() => this.state.changeCompletionDate && this.updateDriverOrderById('departed', status)}
                                                          hideShowSelectDate={true}
                                                          isShowTime={true}
                                                        />
                                                        {!isSystemSupportMove && !isCompleted && leg?.departed && (
                                                          <div
                                                            className="input-icon"
                                                            onClick={() =>
                                                              removeLegStatus(orderDetails._id, leg._id, "departed")
                                                            }
                                                          >
                                                            <IconTimes />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    </LayoutComponent>
                                                  )}
                                                  {
                                                  !leg.isVoidOut && nextValidLeg && nextValidLeg?.driver &&
                                                    (
                                                      <span 
                                                        className="font-12 font-normal position-absolute badge badge-light badge-sm position-relative left-100 ml-10 curved-arrow-left-top curved-arrow-left-bottom"
                                                        style={{ 
                                                          top: "50%", 
                                                          transform: "translateY(-50%)",
                                                        }}
                                                      >
                                                      {(leg?.driver && leg?.arrived && leg?.departed) && durationForMoveString ? 
                                                      (
                                                        <span>
                                                          {durationForMoveString}
                                                        </span>
                                                      )
                                                      : <span className="px-1">-</span>}
                                                      </span>
                                                  )}
                                                  {nextValidLeg && orderDetails?.driverId && !leg?.isVoidOut && !nextValidLeg?.arrived && ( //done
                                                    <LayoutComponent label="Departure">
                                                    <button
                                                      className={`btn btn-xss ${isSupportMove ?  "btn-purple-400" : "btn-primary"} w-150 text-center`}
                                                      onClick={() =>
                                                        updateLegStatus(orderDetails._id, nextValidLeg?._id, "arrived", index+1)
                                                      }
                                                      disabled={isSystemSupportMove}
                                                    >
                                                      <span className="w-100">Departed</span>
                                                    </button>
                                                    </LayoutComponent>
                                                  )}
                                                  {orderDetails?.driverId && !leg?.isVoidOut && nextValidLeg?.arrived && (
                                                    <LayoutComponent label="Departure" isActive>
                                                    <div className="input-wrapper">
                                                      {/* <p className="driver-order-status-text text-primary">Enroute</p> */}
                                                      <div className="right">
                                                        <ExactDateWrapper
                                                          className="mr-2 laDate"
                                                          inputClassName={`w-150 form-control-sm date-arrived-${nextValidLeg?._id}`}
                                                          isShowTime={true}
                                                          OnBlurUpdate={(e) =>
                                                            updateLegTime(orderDetails._id, nextValidLeg._id, "arrived", nextValidIndex, e, `date-arrived-${nextValidLeg?._id}`)
                                                          }
                                                          onChange={(e)=>{handleDateChanged(e,nextValidIndex,"arrived", `date-arrived-${nextValidLeg?._id}`)}}
                                                          dateFormat="MM-DD-yyyy"
                                                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                          //   timeFormat={this.userTimeFormat}
                                                          displayTimeZone={getStorage("timeZone")}
                                                          disabled={isCompleted || isSystemSupportMove}
                                                          defaultDate={nextValidLeg.arrived ? moment(nextValidLeg.arrived) : ""}
                                                          //   OnBlurUpdate={() => this.state.changeCompletionDate && this.updateDriverOrderById('arrived', status)}
                                                          hideShowSelectDate={true}
                                                        />
                                                        {!isSystemSupportMove && !isCompleted && nextValidLeg?.arrived && (
                                                          <div
                                                            className="input-icon"
                                                            onClick={() =>
                                                              removeLegStatus(orderDetails._id, nextValidLeg._id, "arrived")
                                                            }
                                                          >
                                                            <IconTimes />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                    </LayoutComponent>
                                                  )}
                                                  {leg.isVoidOut && isFreeFlowTrip &&
                                                  <LayoutComponent label="Voided Out">
                                                    <span class="badge bg-danger text-light">
                                                      Voided Out
                                                      <span
                                                        className="ml-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => removeVoid(orderDetails._id, leg._id)}
                                                      >
                                                        <IconTimes />
                                                      </span>
                                                    </span>
                                                    <span class="text-muted">
                                                      <span>Driver:</span>
                                                      <span className="font-medium">
                                                       {`${leg?.driver?.name} ${leg?.driver?.lastName || ""}`.trim()}
                                                      </span>
                                                    </span>
                                                  </LayoutComponent>
                                                  }
                                                  </div>}
                                                </div>
                                              </div>
                                              {dropIndex === index && ![leg?.eventId, tripOrder?.[index + 1]?.eventId].includes(dragEventId) && (
                                                <div className="col-12 kanban-card-view my-2">
                                                  <p className="text-center my-4">
                                                    Drop Your Status Here
                                                  </p>
                                                </div>
                                              )}
                                              {showLegPairs && driverPayShowPermission && index+1 < tripOrder.length && 
                                              <div className="d-flex align-items-center gap-10 pl-10">
                                                <div className="w-20px"></div>
                                                <div className="w-125"></div>
                                                <div className='d-flex px-10 py-1 bg-gray-50 rounded-5 align-items-center gap-10 flex-grow-1'>
                                                  <div className="d-flex align-items-center gap-5">
                                                    <IconClock className="text-primary" />
                                                    <span className="text-gray-700">Duration:</span>
                                                    <div className="text-dark font-weight-bold">
                                                    {durationBetweenLegs}
                                                    </div>
                                                  </div>
                                                  <div className='d-flex align-items-center gap-5'>
                                                    <IconDistance className="text-primary" />
                                                    <span>Distance:</span>
                                                    <div className='text-dark font-bold'>{distanceBetweenLegs}{" "}{distanceMeasure}</div>
                                                  </div>
                                                  <div className='d-flex align-items-center gap-5'>
                                                    <span className='mr-1 text-muted font-12 font-weight-normal'>Driver Pay</span>
                                                    <div className='form-group m-0' style={{ width: "103px" }}>
                                                        <div className='input-wrapper'>
                                                          <div>
                                                          <input
                                                            type="text"
                                                            name="driverPay"
                                                            className={`form-control form-control-xs pr-30 driverPay-${leg?._id} transition-white-field`}
                                                            placeholder={currencySymbol+" "+"00.00"}
                                                            disabled={!currentDriverId || isDriverPayLoading || !driverPayEditPermission}
                                                            value={driverPayRate
                                                              ? `${driverPayRate?.invoiceCurrency?.symbol || currencySymbol}${
                                                                  isEditingPay ? driverPayRate.amount : parseFloat(driverPayRate.amount)?.toFixed(2)
                                                                  }`
                                                              : ""
                                                            }
                                                            onBlur={(e) => onBlurHandleForDrivePay( `driverPay-${leg?._id}`)}
                                                            onChange={(e) => updateDriverPay(driverPayRate, e.target.value, leg, nextValidLeg)}
                                                            onKeyPress={(e) => {
                                                              if(e.key==="Enter"){
                                                                onBlurHandleForDrivePay(`driverPay-${leg?._id}`)
                                                              }
                                                              if (!regex.test(e.key) || (e.target.value.replace(currencySymbol || '$', '').trim().indexOf('.')!=-1 && e.key===".") || (e.key==="-" && e.target.value.replace(currencySymbol || '$', '').trim() !== '')) {
                                                                e.preventDefault();
                                                              }
                                                            }}
                                                            />
                                                          </div>
                                                          <div className="input-icon p-0 pr-10">
                                                              {(isDriverPayLoading) ? <IconThreeDots /> : (
                                                                infoDriverPay ? 
                                                                <RedirectToRates
                                                                  info={infoDriverPay}
                                                                  redirectToRates={redirectToRates} 
                                                                  style={{display:"contents"}}
                                                                /> : 
                                                                <IconSaveAsDefault />)}
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              }
                                          </div>

                                      </>
                                    )
                                })}
                                </div>
                                </div>
                            </div>
                        </div>
                        {
                          orderDetails?.isCombinedTrip && isCompleted &&
                          <div className='bg-white px-15 py-1 d-flex align-items-center rounded-5'>
                            <h5 className='mb-0 font-14 font-weight-500'>Trip Completed Date</h5>
                            <div className="form-group mb-0 mx-10 mw-130">
                              <div className="input-wrapper">
                                <div className="position-relative">
                                  <DateWrapper
                                    dateFormat="MM-DD-yyyy"
                                    isShowTime={true}
                                    displayTimeZone={getStorage("timeZone")}
                                    defaultDate={tripCompletedAt ? tripCompletedAt : "" }
                                    hideShowSelectDate={true}
                                    onChange={(date) => setTripCompletedAt(moment(date).toISOString()) }
                                    OnBlurUpdate={() => handleTripDateChanged()}
                                  />
                                  <div className="input-icon">
                                    <IconCalendar />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button className='btn btn-outline-error-100 btn-sm' onClick={() => unCompleteTripForCombinedTrip()}>Uncomplete Trip</button>
                          </div>
                        }
                        </div>
                    </div>
                </div>
        {loadInfoModal &&
          loadInfoModal.isShow &&
          loadInfoModal.reference_number && (
            <LoadInfoModal
              refNumber={loadInfoModal.reference_number}
              hideLoadModal={() =>
                setLoadInfoModal({ isShow: false, reference_number: null })
              }
            />
          )}
        {
          <RemoveDriverModal
            showModal={showWarningDriverModal}
            setShowModal={setShowWarningDriverModal}
            isLoading={isLoading}
            isWarning={false}
            removeDriver={handleWarningConfirm}
            contentTitle={'Warning'}
            contentBody={`${selectedOption?.label} has already accepted this trip. Are you sure you want to assign it to another driver?`}
            confirmText={'Confirm'}
          />
        }
        {chassisValidation && 
          <ChassisValidationModal
            validations={chassisValidation}
            loadDetails={{}}
            onChassisEventSuccess={onChassisEventSuccess}
            onChassisEventFailure={onChassisEventFailure}
          />
        }
        {addEventConfirm?.isOpen &&
          <AddEventConfirm
            loadStatusType={addEventConfirm.loadStatusType}
            dropIndex={addEventConfirm.dropIndex}
            setShowModal={setAddEventConfirm}
            setIsLoading={setIsLoading}
            orderDetails={orderDetails}
            updateTripDetails={updateTripDetails}
          />
        }
        </>
    )
}


const mapDispatchToProps = (dispatch) => ({
  getTMSChassis: bindActionCreators(getTMSChassis, dispatch),
});

export default connect(null, mapDispatchToProps)(NewRoutingTab);