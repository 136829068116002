import React, { useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useOnClickOutside, useOnEscKey, useDisableContextMenu } from "hooks"
import {
  IconForward,
  IconInbox,
  IconReply,
  IconReplyAll,
  IconTrash,
  IconTags,
  IconDownload,
  IconAngleArrowRight,
  IconHash,
  IconDot,
  IconMoveFolder
} from "Components/Common/Icons"
import { EMAIL_CONTEXT, EMAIL_PROVIDER, EMAIL_TABS, IGNORED_TABS, OUTLOOK_PROVIDER, SEND_EMAIL_TYPE,AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant"
import _ from "lodash"
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition"
import { getStorage } from "services"
import LabelSubMenu from "./ContextSubMenus/LabelSubMenu"
import MovetoSubMenu from "./ContextSubMenus/MovetoSubMenu"
import TagSubMenu from "./ContextSubMenus/TagSubMenu"

import { useMoveEmail, useAddLabelToMail, useRemoveLabelFromMail, useEmailTags } from "../hooks"
import { amplitudeTrack, checkUserPermisison } from "../../../../services"

const EmailContextMenu = ({
  cellRef,
  onClose,
  hanldeOnDelete,
  row,
  handleOnArchive,
  onClick,
  setRightClickKey,
  _getCounts,
  setSelectedRows,
  setShowCreateLabelModal,
  setShowCreateTag,
  handleBottomUnread,
  eventProperties,
  activePage
}) => {
  const context = useContext(EMAIL_CONTEXT)
  const {allLabels, activeNav, allEmails, setAllEmails} = context

  const mailLabelIds = row?.folders?.filter(labelName =>
    allLabels.some(label =>
      label.id === labelName &&
      (!label.systemFolder || label.id.startsWith('CATEGORY_')) &&
      label.id !== 'CATEGORY_PERSONAL'
    )
  ) ?? []

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {};

  const labelList = _.sortBy(
    allLabels?.filter((label) => !label?.systemFolder),
    (l) => l.name.toLowerCase()
  )
  labelList.push(...allLabels?.filter(label => label?.name?.includes("CATEGORY") && !label?.name?.includes("PERSONAL")))
  const boxRef = useRef(null)

  const { allTags, assignTagsToEmails, getTags, isTagsLoading, handleSearchTerm } = useEmailTags([]);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [checkedLabels, setCheckedLabel] = useState(mailLabelIds)
  const [applyLoader, setApplyLoader] = useState(false)
  const subCellLabelRef = useRef(null);
  const subCellMovetoRef = useRef(null);
  const subCellTagRef = useRef(null);
  const subBoxRef = useRef(null);
  const { _moveEmail, loading } = useMoveEmail(allEmails, setAllEmails, _getCounts);
  const { _addLabelToMail } = useAddLabelToMail(allEmails, setAllEmails, _getCounts);
  const { removeLabelFromMail } = useRemoveLabelFromMail(allEmails, setAllEmails, _getCounts);

  const disableArchive = ["Spam", "Trash", "Deleted Items", "Deletions"].includes(activeNav) || [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.DRAFT].includes(activePage)
  const disableTrash = ["Trash", "Deleted Items", "Deletions"].includes(activeNav) || [EMAIL_TABS.DELETED_ITEMS].includes(activePage)

  useOnClickOutside([boxRef, subBoxRef], () => {
    onClose()
  })
  useOnEscKey(() => {
    if (subBoxRef.current) {
      setHoveredIndex(false);
    } else if (boxRef.current) {
      onClose();
    }
  })

  useDisableContextMenu([boxRef, subBoxRef])

  const popupPlacement = "auto"
  const style = useDropDownPosition(popupPlacement,boxRef, cellRef)

  let hideSubMenuTimeout,showSubMenuTimeout;
  const handleSubMenuMouseEnter = (index) => {
    clearTimeout(hideSubMenuTimeout); // Clear any pending hide timeout
    showSubMenuTimeout = setTimeout(() => {
      setHoveredIndex(index);
    }, 100);
  };

  const handleSubMenuMouseLeave = (e) => {
    clearTimeout(showSubMenuTimeout); // Clear any pending show timeout
    hideSubMenuTimeout = setTimeout(() => {
      if (boxRef.current.contains(e.relatedTarget)) {
        setHoveredIndex(null);
      }
    }, 100);

    if (e && e.relatedTarget) {
      // Check if the related target (where the mouse is going) is inside the boxRef
      if (boxRef.current && !boxRef.current.contains(e.relatedTarget)) {
        clearTimeout(hideSubMenuTimeout);
      }
    }
  };

  useEffect(() => {
    const handleScroll = (e) => {
      const isOutsideBox = !boxRef?.current.contains(e.target);
      if (isOutsideBox) {
        onClose();
      }
    };

    const scrollDivs = document.querySelectorAll('[data-testid="rdg-email-table"]');

    scrollDivs.forEach((scrollDiv) => {
      scrollDiv.addEventListener("scroll", handleScroll);
    });

    return () => {
      scrollDivs.forEach((scrollDiv) => {
        scrollDiv.removeEventListener("scroll", handleScroll);
      });
    };
  }, []);

  const checkLabels = (lableId, checked) => {
    const _checkedLabel = checked ? [...checkedLabels, lableId] : [...checkedLabels].filter(id=>id !== lableId)
    setCheckedLabel(_checkedLabel)
  }

  const handleAddRemoveLabel = async () => {
    setApplyLoader(true)
    const allEmailLabel = _.cloneDeep(row?.folders);
    const validLabelIds = labelList?.map(label => label?.id);
    const oldLabelsOfEmail = allEmailLabel?.filter(label => validLabelIds?.includes(label))?.map(label => label);
    const addedLabel = checkedLabels.filter(id => !oldLabelsOfEmail.includes(id));
    const removedLabel = oldLabelsOfEmail.filter(id => !checkedLabels.includes(id));

    if (addedLabel?.length) {
      const addLabelParams = [{ [row.id]: [...row.folders, ...addedLabel] }];
      await _addLabelToMail(addLabelParams);
    }
    if (removedLabel?.length) {
      const removeLabelParam = {
        messageId: row?.id,
        labelIds: removedLabel
      }
      const data = await removeLabelFromMail(removeLabelParam)
      if (!IGNORED_TABS.includes(activeNav)) {
        const updatedMailIndex = allEmails.findIndex((email) => email?.id === data?.id);
        if (updatedMailIndex > -1) {
          const isSameTab = !data?.folders?.includes(activeNav);
          if (isSameTab) {
            const newData = allEmails?.filter((_, index) => index !== updatedMailIndex);
            setAllEmails(newData);
          }
        }
      }
    }
    setApplyLoader(false)
    setHoveredIndex(false)
    setSelectedRows([])
    onClose()
  };

  const showMoveTo = () => {
    if (provider === EMAIL_PROVIDER.GMAIL) {
      return true
    }
    if (OUTLOOK_PROVIDER.includes(provider) && activePage !== EMAIL_TABS.DRAFT) {
      return true
    }
    return false
  }

  const moveEmailToLabel = (folderId) => {
    setSelectedRows([])
    setHoveredIndex(false)
    const payload = {
      ids: [row?.id],
      moveToId: folderId
    }
    _moveEmail(payload);
    onClose();
  }

  const junkEmailOptionDisabled = OUTLOOK_PROVIDER.includes(provider) && [EMAIL_TABS.JUNK_EMAIL].includes(activePage);
  const draftEmailOptionDisabled = OUTLOOK_PROVIDER.includes(provider) && [EMAIL_TABS.DRAFT].includes(activePage);
  return createPortal(
    <>
      <div
        className="w-300 bg-gray-600 rounded-10 py-10 position-fixed z-1051 overflow-auto shadow-5"
        ref={boxRef}
        style={style}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {!row?.draftIds?.length && (
        <>
        <ul className="list-style-none all-action-wrapper">
          <li className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${(junkEmailOptionDisabled || draftEmailOptionDisabled) && 'pointer-not-allowed opacity-05'}`} onClick={() => {
            if(!junkEmailOptionDisabled && !draftEmailOptionDisabled) {
              onClick(row)
              setRightClickKey(SEND_EMAIL_TYPE.REPLY)
              amplitudeTrack(AMPLITUDE_EVENTS.REPLY, eventProperties)
            }
            onClose()
          }}>
            <IconReply />
            <span className="font-14  ml-10">Reply</span>
          </li>
          <li className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${(junkEmailOptionDisabled || draftEmailOptionDisabled)  && 'pointer-not-allowed opacity-05'}`}
            onClick={() => {
              if(!junkEmailOptionDisabled && !draftEmailOptionDisabled) {
                onClick(row)
                setRightClickKey(SEND_EMAIL_TYPE.REPLY_ALL)
                amplitudeTrack(AMPLITUDE_EVENTS.REPLY_ALL, eventProperties)
              }
              onClose()
            }}
          >
            <IconReplyAll />
            <span className="font-14  ml-10">Reply All</span>
          </li>
          <li className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${(draftEmailOptionDisabled)  && 'pointer-not-allowed opacity-05'}`}
            onClick={() => {
              if(!draftEmailOptionDisabled) {
                onClick(row)
                setRightClickKey(SEND_EMAIL_TYPE.FORWARD)
                amplitudeTrack(AMPLITUDE_EVENTS.FORWARD, eventProperties)
              }
              onClose()
            }}
          >
            <IconForward />
            <span className="font-14  ml-10">Forward</span>
          </li>
        </ul>
        <div className="hr-light bg-gray-500 my-0" />
        </>
        )}
        <ul className="list-style-none all-action-wrapper">
          {(OUTLOOK_PROVIDER.includes(provider) || row?.folders?.includes("INBOX")) && !row?.draftIds?.length && <li
            className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${(disableArchive || junkEmailOptionDisabled) ? "pointer-not-allowed opacity-05" : ""}`}
            onClick={() => {
              if (!(disableArchive || junkEmailOptionDisabled)) {
                handleOnArchive([row.id]);
                onClose()
                amplitudeTrack(AMPLITUDE_EVENTS.ARCHIVE, eventProperties)
              }
            }}
          >
            <IconInbox />
            <span className="font-14  ml-10">Archive</span>
          </li>}
          <li
            className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${disableTrash ? "pointer-not-allowed opacity-05" : ""}`}
            onClick={() => {
              if (!disableTrash) {
                hanldeOnDelete(row, AMPLITUDE_EVENTS.TRASH, eventProperties)
              }
            }}
          >
            <IconTrash />
            <span className="font-14  ml-10">Delete</span>
          </li>
          <li
            className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${(draftEmailOptionDisabled)  && 'pointer-not-allowed opacity-05'}`}
            onClick={() =>{
              if(!draftEmailOptionDisabled) {
                if(!row?.unread){
                  handleBottomUnread([row.id], true)
                  amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_UNREAD, eventProperties)
                } else {
                  handleBottomUnread([row.id], false) 
                  amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_READ, eventProperties)
                }
            }
            }}
          >
            <IconDot />
            <span className="font-14  ml-10">{`${row?.unread ? 'Mark as Read': 'Mark as Unread'}`}</span>
          </li>
          {(provider === EMAIL_PROVIDER.GMAIL) && <li
              className="d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 position-relative"
              onMouseEnter={() => handleSubMenuMouseEnter('label')}
              onMouseLeave={(e) => handleSubMenuMouseLeave(e)}
              ref={subCellLabelRef}
            >
              <IconTags />
              <span className="font-14  ml-10">Label as</span>
              <IconAngleArrowRight className="ml-auto" />

              {hoveredIndex === "label" && (
                <LabelSubMenu
                  boxRef={boxRef}
                  subBoxRef={subBoxRef}
                  subCellRef={subCellLabelRef}
                  handleAddRemoveLabel={handleAddRemoveLabel}
                  checkedLabels={checkedLabels}
                  checkLabels={(lableId, checked)=>{checkLabels(lableId, checked)}}
                  applyLoader={applyLoader}
                  setShowCreateLabelModal={setShowCreateLabelModal}
                />
              )}
            </li>}
          {showMoveTo() &&
            <li
              className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0 ${loading ? "pointer-not-allowed opacity-05" : ""}`}
              onMouseEnter={() => handleSubMenuMouseEnter('moveTo')}
              onMouseLeave={(e) => handleSubMenuMouseLeave(e)}
              ref={subCellMovetoRef}
            >
              <IconMoveFolder />
              <span className="font-14  ml-10">Move to</span>
              {loading ? <span class="spinner-border spinner-border-sm text-white ml-10"></span> : ""}
              <IconAngleArrowRight className="ml-auto" />

              {hoveredIndex === 'moveTo' &&
                <MovetoSubMenu
                  boxRef={boxRef}
                  subBoxRef={subBoxRef}
                  subCellRef={subCellMovetoRef}
                  moveEmailToLabel={(folderId) => { moveEmailToLabel(folderId) }}
                  eventProperties={eventProperties}
                  activePage={activePage}
                  threadFolder={row.folders}
                />
              }
            </li>}

          {!draftEmailOptionDisabled && <li
              className={`d-flex align-items-center py-2 px-15 rounded-0 pointer text-white border-0`}
              onMouseEnter={() => handleSubMenuMouseEnter('tag')}
              onMouseLeave={(e) => handleSubMenuMouseLeave(e)}
              ref={subCellTagRef}
            >
              <IconHash/>
              <span className="font-14  ml-10">Tag as</span>
              <IconAngleArrowRight className="ml-auto" />

              {hoveredIndex === 'tag' &&
                <TagSubMenu
                  row={row}
                  boxRef={boxRef}
                  subBoxRef={subBoxRef}
                  subCellRef={subCellTagRef}
                  moveEmailToLabel={(folderId) => { moveEmailToLabel(folderId) }}
                  setShowCreateTag={setShowCreateTag}
                  onClose={onClose}
                  allTags={allTags}
                  assignTagsToEmails={assignTagsToEmails}
                  getTags={getTags}
                  isTagsLoading={isTagsLoading}
                  handleSearchTerm={handleSearchTerm}
                />
              }
            </li>}
        </ul>
      </div>
    </>,
    document.getElementById("pp-overlay-container")
  )
}

export default EmailContextMenu
