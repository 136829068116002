import { IconPlus, IconReset } from "Components/Common/Icons"
import _, { sortBy } from "lodash"
import moment from "moment"
import { listAllFleetManagers } from "pages/tms/ReportsV2/actionCreator"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { amplitudeTrack, getStorage, toastr } from "services"
import config from "../../../config"
import { getEmbeddedEmailAccount } from "../../../services"
import { isAtBottom } from "../NewDispatcher/DispatcherTable/Functions"
import { getPeople } from "../People/actionCreator"
import EditTagModal from "../Settings/Tags/EditTagModal"
import CreateEmailModal from "./Components/CreateEmailModal"
import EmailFilterBar from "./Components/EmailFilterBar/EmailFilterBar"
import { EMAIL_FILTERS_KEY, createPayloadFromEmailFilters, dateTypeFields, fieldsWithExcludeProperties, generateStateViaParams, navBarFilters, viewRelatedTabs } from "./Components/EmailFilterBar/utils"
import EmailNav from "./Components/EmailNav/EmailNav"
import AddLabelModal from "./Components/EmailNav/LabelModals/AddLabelModal"
import EmailTable from "./Components/EmailTable"
import EmailTopPage from "./Components/EmailTopPage"
import { getAllEmails, getEmailByQuoteId, getFolders, getFoldersInfo, getMainFolderList, getSentEmails, getSubCardCounts, replyEmail, sendMail, getEmails, getEmailFromThreadId, getEmailByLoadId, getEmailByLabel, getAllEmailSignature } from "./actionCreator"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, EMAIL_PROVIDER, EMAIL_TABLE_VIEW, EMAIL_TABS, OUTLOOK_PROVIDER, statusLabels } from "./constant"
import { cancelDelayedCall, convertToTitleCase, isEmbeddedEmailConnected, isSentTabSelected, registerDelayedCall, setDefaultSystemFolderForOutLook } from "./helper"
import { useEmailStatus, useEmailTags } from "./hooks"
import useAllMails from "./hooks/useAllMails"
import useCustomQueue from "./hooks/useCustomQueue"
import { getTimeZone } from "../NewDispatcher/constants"


const AllMails = ({
  allNestedFolders,
  isFromLoad = false,
  isFromQuote = false,
  reference_number,
  loadId,
  quoteId,
  quoteNumber,
  onDisconnectMail,
  getFilters,
  setViewparams,
  emailChanged,
  isEmailMode = "grid",
  callMasterFun = true,
  isLoaderOn,
  setSelectedRowEmpty,
  getAllUnreadCount,
  triggerUnSelectView,
  setIsDraft,
  checkViewsChanges,
  resetFirebase,
  updateUnreadCount,
  setEmailChanged,
  favoriteFolders,
  setFavoriteFolders,
  setAllNestedFolders,
  changeInEmail,
  activeFolder,
  allAccountFolders,
  setActiveFolder,
  getAllNestedFolders
}) => {
  const [showFilterBar, setShowFilterBar] = useState(false)
  const [isLoading, setIsLoading] = useState(isLoaderOn??false);
  const [activeNav, setActiveNav] = useState(activeFolder ? activeFolder?.id :  EMAIL_TABS.INBOX);
  const [searchQuery, setSearchQuery] = useState("");
  const [allCSR, setAllCSR] = useState([])
  const [refreshPage,setRefreshPage]= useState(false)
  const [filterState,setFilterState] = useState({
    filters:{
      fromDate: '',
      toDate: ''
    },
    emailFilters:EMAIL_FILTERS_KEY,
    subCardFilter:EMAIL_FILTERS_KEY,
  })

  const [filters, setFilters] = useState({
    fromDate: '',
    toDate: ''
  })
  const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
  const { provider } = embeddedEmailAccount ?? {};
  const [allEmails, setAllEmails] = useState([])
  const [allLabels, setAllLabels] = useState([]);
  const [selectLabels, setSelectLabels] = useState(null);
  const [isGettingEmails, setIsGettingEmails] = useState(false)
  const [isGettingLabels, setIsGettingLabels] = useState(false)
  const [showCreateEmailModal, setShowCreateEmailModal] = useState(false)
  const [peopleData, setPeopleData] = useState([])
  const [subCardData, setSubCardData] = useState([])
  const [isSubCardSeletected, setIsSubCardSeletected] = useState(false)
  const [isLoadingSubCard, setIsLoadingSubCard] = useState(false)
  const [allEmailSignature, setAllEmailSignature] = useState([])
  const [showCreateTag, setShowCreateTag] = useState(false);
  const [showEmailDetailsModal, setShowEmailDetailsModal] = useState(false)
  const [showEmailDetailsModalR, setShowEmailDetailsModalR] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMasterApiCall, setIsMasterApiCall] = useState(true)
  const [pageToken, setPageToken] = useState("");
  const [allCounts,setAllCounts]= useState({});
  const [threadUpdates, setThreadUpdates] = useState({})
  const messageUpdateRef = useRef(null);
  const emailStatusTagRef = useRef(null);
  const firebase = config.firebase;
  const allEmailRef = useRef(null);
  const activeNavRef = useRef(null);
  const pageTokenRef = useRef(null)
  const [timeoutID, setTimeoutID] = useState(null);
  const allFolderRef = useRef([]);
  const [isSubFolderActive,setIsSubFolderActive] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [isMoveEmailLoader,setIsMoveEmailLoader] = useState(false)
  const [isEditModeOn,setIsEditMode] = useState(false);
  const filterStateRef = useRef(null);
  const searchQueryRef = useRef(null);
  const filterAppliedRef = useRef(null);
  const isSubCardSeletectedRef = useRef(null);
  const activeTabIdRef = useRef()

  const handleMasterApiCall = (value) => {
    if(!value && resetFirebase) resetFirebase(true) 
    setIsMasterApiCall(value)
  }

  useEffect(() => {
    filterStateRef.current = filterState
    if (checkViewsChanges) setTimeout(()=>checkViewsChanges(isLoading),1000)
    setSelectedRows([])
  }, [filterState])

  useEffect(()=> {
    searchQueryRef.current = searchQuery
  }, [searchQuery])

  useEffect(()=>{
    isSubCardSeletectedRef.current = isSubCardSeletected
  }, [isSubCardSeletected])

  useEffect(()=>{
    filterAppliedRef.current = !(_.isEqual((filterStateRef.current || filterState)?.emailFilters ?? "", EMAIL_FILTERS_KEY) && _.isEqual((filterStateRef.current || filterState)?.subCardFilter ?? "", EMAIL_FILTERS_KEY) && (searchQueryRef.current || searchQuery) === "")
  }, [filterState, searchQuery])

  useEffect(()=> {
    allEmailRef.current = allEmails
  },[allEmails])

  useEffect(()=> {
    pageTokenRef.current = pageToken
  },[pageToken])

  useEffect(()=>{
    setActivePageId()
  },[activeNav])

  const setActivePageId = () => {
    const tab = handleActiveTab()
    const params = { activePage: tab }
    updateActivePageKey(params)
    activeTabIdRef.current = params.activePage
  }

  const setEmailFilters = (val) => {
    setFilterState((prev) => ({
      ...prev,
      emailFilters: _.isEqual(val, {}) ? EMAIL_FILTERS_KEY : val
    }))
  }

  const subToMessageUpdates = () => {
    unSubFromMessageUpdates()
    const embeddedEmailAccount = JSON.parse(localStorage.getItem('embeddedEmailAccount'))
    const carrierDetail = JSON.parse(localStorage.getItem("carrierDetail"))


    const { _id: carrierId } = carrierDetail
    const grantId = embeddedEmailAccount?.accountId
    const channelName = `${carrierId}/${grantId}`
    messageUpdateRef.current = firebase.database().ref(channelName);
    if(messageUpdateRef?.current) {
      messageUpdateRef.current.on("value", (snapshot) => {
        const data = snapshot.val();
        if(!data || typeof data !== 'string') return;
        const thread = JSON.parse(data);
        if(thread?.threadId && moment().diff(moment(thread.updatedAt), 'seconds')<=10){
          if (filterAppliedRef.current) {
            if(timeoutID){
              cancelDelayedCall(timeoutID)
              setTimeoutID(null);
            }
            const _timeoutID = registerDelayedCall(getFirstPageData, [], 30)
            setTimeoutID(_timeoutID)
          } else {
            addThreadToInbox(thread.threadId)
          }
        } 
      })
    }
  }
 
  const checkConditionToAppend = (email) => {
    const params = { activePage:activeNav}
    updateActivePageKey(params)
    const isSystemFolder =allLabels.find(e=>e.id===params?.activePage)?.systemFolder ?? true
    let condition = email?.folders?.includes(params?.activePage) || (EMAIL_TABS.ALL_MAIL?.toUpperCase() === activeNav?.toUpperCase());
    if(!isSystemFolder && (email?.folders?.includes("SPAM") ||  email?.folders?.includes("TRASH"))) return false
    if(["SPAM", "TRASH"].some(folder =>  email?.folders?.includes(folder)) && params?.activePage==="SENT") return false
    if(params?.activePage==='All Emails'&& ["SPAM", "TRASH"].some(folder =>  email?.folders?.includes(folder)))  return false
    return condition
  }
  const shouldEmailAppend = (email) => {
    
      if(!isFromLoad && !reference_number && !loadId){
        return true && checkConditionToAppend(email)
      }else if(reference_number){
        if(!email?.connectedLoads?.length) return true
        else{
          return email?.connectedLoads?.map(load => load?.reference_number)?.includes(reference_number)
        }
      }
  }
  const unSubFromMessageUpdates = () => {
    if(messageUpdateRef?.current?.off)
      messageUpdateRef?.current?.off("value")
  }

  const subscribeToUpdateEmailStatusTags = () => {
    const embeddedEmailAccount = JSON.parse(localStorage.getItem('embeddedEmailAccount'))
    const carrierDetail = JSON.parse(localStorage.getItem("carrierDetail"))
    const { _id: carrierId } = carrierDetail
    const grantId = embeddedEmailAccount?.accountId
    const channelName = `email/${carrierId}/${grantId}/status-tag`
    emailStatusTagRef.current = firebase.database().ref(channelName);
    if(emailStatusTagRef?.current) {
      emailStatusTagRef.current.on("value", (snapshot) => {
        const data = snapshot.val();
        if(!data || typeof data !== 'string') return;
        const thread = JSON.parse(data);       
        if(thread?.threadId && moment().diff(moment(thread.updatedAt), 'seconds')<=10){
           setThreadUpdates(thread)
           if(thread.status || thread.hasOwnProperty("starred") || thread.hasOwnProperty("unread")){
            updateEmailStatusInList(thread)
           }
           if(thread.tag){
            tagUpdateQueue(thread.threadId)
           }
           if(thread.folders?.length){
            moveToFolderQueue(thread)
           }
        }
      })
    }
  
  }

  const unsubscribeToUpdateEmailStatusTags = () => {
    if(emailStatusTagRef?.current?.off)
    emailStatusTagRef?.current?.off("value")
    setThreadUpdates({})
  }

  useEffect(() => {
    unsubscribeToUpdateEmailStatusTags()
    activeNavRef.current = activeNav
    if(isFromLoad || activeNav ){
      subscribeToUpdateEmailStatusTags()
    }
    return () => {
      if(!isFromLoad){
      unsubscribeToUpdateEmailStatusTags()
      }
    }
  },[emailChanged, activeNav])

  useEffect(() => {
    unSubFromMessageUpdates();
    activeNavRef.current = activeNav;
    if (isFromLoad || activeNav) {
      subToMessageUpdates();
    }

    return () => {
      unSubFromMessageUpdates();
    };
  }, [activeNav]);

  const isAccountDisconnected = () => {
    try {
      let embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount") ?? '{}');
      return embeddedEmailAccount?.isDisconnected
    } catch (err){
      console.log(err)
      return false
    }
  }

  const addThreadToInbox = async (threadId) => {
    try{
      if(isAccountDisconnected()) return
      const _email = await getEmailFromThreadId({threadId})
      if(_email && Object.keys(_email)?.length){
        updateFoldersCount(_email.folders, false, true)
        setAllEmails(prevEmails => {
          const _allEmails = _.cloneDeep( prevEmails?.length ? prevEmails :  (allEmailRef?.current || []))
          const existingThreadIndex = _allEmails.findIndex(x => x?.id === threadId)
          if(existingThreadIndex >= 0) {
            _allEmails.splice(existingThreadIndex, 1)
          }
          const newMails = [...(shouldEmailAppend(_email) ? [_email] : []), ..._allEmails].map((email) => {
            let labelValue = provider === EMAIL_PROVIDER.GMAIL ? email.labels : OUTLOOK_PROVIDER.includes(provider) && email?.folders;
            const isTrashOrSpamEmail = labelValue?.length && labelValue.find((label) => ["spam", "trash"].includes(label.toLowerCase()));
            if (!isTrashOrSpamEmail) {
              return email;
            }
          }).filter(Boolean)
          return newMails
        })
        
      }
    }
    catch(err) {
      console.log("Error : ", err)
    }
  }

  const updateEmailStatusInList = (thread) => {
    setAllEmails(prevEmails => {
      const updatedEmails = prevEmails.map((email) => {
        if (email?.id === thread.threadId) {
          const updates = {}
          if(thread.hasOwnProperty("status")) updates.status = thread.status;
          if(thread.hasOwnProperty("starred")) updates.starred = thread.starred;
          if(thread.hasOwnProperty("unread")) updates.unread = thread.unread;
          if(thread.hasOwnProperty("timeElapsedLow")) updates.timeElapsedLow = thread.timeElapsedLow;
          if(thread.hasOwnProperty("timeElapsedMedium")) updates.timeElapsedMedium = thread.timeElapsedMedium;
          if(thread.hasOwnProperty("timeElapsedHigh")) updates.timeElapsedHigh = thread.timeElapsedHigh;
          return { ...email, ...updates }; 
        }
        return email;
      });
      return updatedEmails;
    });
  };

  const updateEmailOnFirebaseInList = async (threadId) => {
    try{
      const emailList = allEmailRef?.current;
      const emailFound = emailList.find(email => email?.id === threadId);
      if(!emailFound || isAccountDisconnected()) return;
      const _email = await getEmailFromThreadId({threadId})
      setAllEmails(prevEmails => {
        const updatedEmails = prevEmails.map((email) => {
          if (email?.id === threadId) {
            return _email; 
          }
          return email;
        });
        return updatedEmails;
      })
    }
    catch(err) {
      console.log("Error : ", err)
    }
  }
  const moveEmailToOtherFolder = async (thread) => {
    const updateCount = typeof updateUnreadCount === "function" ? updateUnreadCount : () => {};
    try{
      const threadId = thread.threadId;
      const condition = checkConditionToAppend(thread)
      updateCount();
      if(condition && !isAccountDisconnected()) {
        const email = await getEmailFromThreadId({threadId});
        if(email){
          setAllEmails(prevEmails => {
          let _allEmails = _.cloneDeep( prevEmails?.length ? prevEmails :  (allEmailRef?.current || []))
          _allEmails = _allEmails.map(emailDetail => {
            if (emailDetail?.id && email?.id && emailDetail.id.toLowerCase() === email.id.toLowerCase()) {
              return email;
            }
            return emailDetail;
          });
          shouldEmailAppend(email) && _allEmails.push(email)
          const newMails = _.uniqBy(_allEmails, 'id') ;
          const sortedMails = newMails.sort((a, b) => b?.lastMessageTime - a?.lastMessageTime)
            return sortedMails
          })
        }
  
      }else if (!isFromLoad){
        setAllEmails(prevEmails => {
          const _allEmails = _.cloneDeep( prevEmails?.length ? prevEmails :  (allEmailRef?.current || []))
          const newMails = _allEmails.filter((email) => {
          return threadId !== email?.id
        })
        return newMails
      })
    }
    }catch(err){
      console.log("Error : ", err)
    } 
  }

  const tagUpdateQueue = useCustomQueue({ processFunction: updateEmailOnFirebaseInList })
  const moveToFolderQueue = useCustomQueue({ processFunction: moveEmailToOtherFolder })

  const handleShowCreateEmailModal = (value) => {
    setShowCreateEmailModal(value)
  }
  useEffect(()=> {
    if(emailChanged){
      setAllEmails([])
      setAllLabels([])
      setSelectedRows([])
      handleReset()
      triggerUnSelectView && triggerUnSelectView()
      subToMessageUpdates()
      setSubCardData({})
      setShowEmailDetailsModalR(false)
      setShowEmailDetailsModal(false)
      setIsSubFolderActive(false)
      allFolderRef.current = [];
    }
  },[emailChanged])

  const setNullValue = (key1,key2) => {
    setFilterState((prev) => ({ ...prev, emailFilters: { ...prev.emailFilters, [key1]: null, [key2]: null } }))
  }

  useEffect(() => {
    if(activeNav && setIsDraft) {
      setIsDraft(handleActiveTab() === EMAIL_TABS.DRAFT || false)
    }
    if (activeNav === EMAIL_TABS.DRAFT) handleResetFilter()
  },[activeNav])

  
  const removeTimes = (key) => {
    switch (key) {
      case "emailFromDate":
      case "emailToDate":
        setNullValue("emailFromDate","emailToDate")
        break;
      case "pickupFrom":
      case "pickupTo":
        setNullValue("pickupFrom","pickupTo")
        break;
      case "deliveryFrom":
      case "deliveryTo":
        setNullValue("deliveryFrom","deliveryTo")
        break;
      case "returnFrom":
      case "returnTo":
        setNullValue("returnFrom","returnTo")
        break;
      case "lastFreeDayFrom":
      case "lastFreeDayTo":
        setNullValue("lastFreeDayFrom","lastFreeDayTo")
        break;
      case "cutOffFrom":
      case "cutOffTo":
        setNullValue("cutOffFrom","cutOffTo")
        break;
      default:
        break;
    }
  };

  const removeCheckBox = (key, data) => {
    setFilterState((prevState) => {
      const updatedEmailFilters = { ...prevState.emailFilters };
  
      switch (key) {
        case "isWithLoadEmail":
          updatedEmailFilters.isWithLoadEmail = data;
          updatedEmailFilters.isWithoutLoadEmail = null;
          break;
        case "isWithoutLoadEmail":
          updatedEmailFilters.isWithLoadEmail = null;
          updatedEmailFilters.isWithoutLoadEmail = data;
          break;
        case "isWithPeopleEmail":
          updatedEmailFilters.isWithPeopleEmail = data;
          updatedEmailFilters.isWithoutPeopleEmail = null;
          break;
        case "isWithoutPeopleEmail":
          updatedEmailFilters.isWithPeopleEmail = null;
          updatedEmailFilters.isWithoutPeopleEmail = data;
          break;
        default:
          return prevState; // If the key does not match, return the previous state
      }
  
      return {
        ...prevState,
        emailFilters: updatedEmailFilters,
      };
    });
  };  

  const removeExcludes = (key) => {
    let updatedEmailFilters = {};
  
    switch (key) {
      case "organizations":
        updatedEmailFilters = { organizations: [], isExcludeOrganizations: null };
        break;
      case "people":
        updatedEmailFilters = { people: [], isExcludePeople: null };
        break;
      case "myTeam":
        updatedEmailFilters = { myTeam: [], isExcludeMyTeam: null };
        break;
      case "branches":
        updatedEmailFilters = { branches: [], isExcludeBranches: null };
        break;
      default:
        return;
    }
  
    setFilterState((prevState) => ({
      ...prevState,
      emailFilters: { ...prevState.emailFilters, ...updatedEmailFilters },
    }));
  };

  const handleActiveTab = () => {
    let val = "";
    let embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount") ?? '{}');
    let { provider } = embeddedEmailAccount ?? {};
    if(OUTLOOK_PROVIDER.includes(provider)) {
      let found = allFolderRef?.current?.find(label => label.id === activeNav || label.name === activeNav)
      val = found?.name || "Inbox";
    } else {
      switch (activeNav) {
        case "Inbox":
        case "Important":
        case "Sent":
        case "Spam":
        case "Starred":
        case "Trash":
          val = activeNav?.toLowerCase() || ""
          break;
        case "All Emails":
          val = "all"
          break;
        case "social":
          val = "CATEGORY_SOCIAL"
          break;
        case "updates":
          val = "CATEGORY_UPDATES"
          break;
        case "forums":
          val = "CATEGORY_FORUMS"
          break;
        case "promotions":
          val = "CATEGORY_PROMOTIONS"
          break;
        default:
          let found = allFolderRef?.current?.find(label => label.id === activeNav || label.name === activeNav)
          val = found?.name;
          break;
      }
    }

    return val;
  };


  const handleActiveTabForView = () => {
    let val = "";
    let embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
    let { provider } = embeddedEmailAccount ?? {};
    if(OUTLOOK_PROVIDER.includes(provider)) {
      let found = allFolderRef?.current?.find(label => label.id === activeNav || label.name === activeNav)
      val = found
    } else {
      switch (activeNav) {
        case "Inbox":
        case "Important":
        case "Sent":
        case "Spam":
        case "Starred":
        case "Trash":
          val = activeNav?.toLowerCase() || ""
          break;
        case "All Emails":
          val = "all"
          break;
        case "social":
          val = "CATEGORY_SOCIAL"
          break;
        case "updates":
          val = "CATEGORY_UPDATES"
          break;
        case "forums":
          val = "CATEGORY_FORUMS"
          break;
        case "promotions":
          val = "CATEGORY_PROMOTIONS"
          break;
        default:
          let found = allLabels.find(label => label.id === activeNav || label.name === activeNav)
          val = found;
          break;
      }
    }
    return val;
  };

  const reverseMapActiveTab = (activePage) => {
    let val = "";
    if (['inbox','sent','spam','trash','starred'].includes(activePage)) {
      val =  activePage.charAt(0).toUpperCase() + activePage.slice(1);
    } else if (activePage === 'important') {
      val = 'important'
    } else if (activePage === 'all') {
      val = 'All Emails'
    } else {
      // let found = allLabels.find(label => label.name === activePage)
      // val = found.id || found.name
      if(OUTLOOK_PROVIDER.includes(provider)) val = activePage.name === "Inbox" ? activePage.name : activePage?.id 
      else val = activePage?.name
    }
    return val;
  };

  const setFilterKey = (key, data, event, eventProperties) => {
    setSelectedRows([]);
    setIsSubCardSeletected(false); 
    setPageToken("");

    setFilterState((prevState) => {
      let updatedFilters = _.cloneDeep(prevState.filters);
      let updatedEmailFilters = _.cloneDeep(prevState.emailFilters);
      let updatedSubCardFilter = _.cloneDeep(prevState.subCardFilter);
  
      if (dateTypeFields.includes(key) && !data) {
        removeTimes(key);
      } else if (navBarFilters.includes(key)) {
        removeCheckBox(key, data);
      } else if (fieldsWithExcludeProperties.includes(key) && data?.length === 0) {
        removeExcludes(key, data);
      } else {
        if (data?.length === 0) setPageToken("");
        updatedEmailFilters[key] = data;
      }
  
      return {
        ...prevState,
        filters: updatedFilters,
        emailFilters: updatedEmailFilters,
        subCardFilter: updatedSubCardFilter,
      };
    });
    if (event && eventProperties) {
      amplitudeTrack(event, eventProperties);
    }
  };

  const setSubCardKey = (key, data, isRemoveSubCard) => {
    setFilterState((prevState) => {
      const prevFilters = prevState.subCardFilter;
      let updatedFilters;
  
      if (isRemoveSubCard) {
        updatedFilters = { ...prevFilters, [key]: [] };
      } else {
        const exists = prevFilters[key]?.some(d => d?.value === data?.value);
  
        if (exists) {
          updatedFilters = { 
            ...prevFilters, 
            [key]: prevFilters[key].filter(d => d?.value !== data?.value) 
          };
        } else {
          updatedFilters = { 
            ...prevFilters, 
            [key]: [...(prevFilters[key] || []), data] 
          };
        }
      }
  
      return {
        ...prevState,
        subCardFilter: _.isEqual(updatedFilters, {}) ? EMAIL_FILTERS_KEY : updatedFilters,
        emailFilters: EMAIL_FILTERS_KEY, // Ensure emailFilters are reset
      };
    });
    setSelectedRows([]);
    setIsSubCardSeletected(true);
    setPageToken("");
  };

  const listAllCSR = () => {
    listAllFleetManagers()
      .then((data) => {
        const allCSR = data?.map((_user) => {
          const obj = {
            value: _user?._id,
            label: (_user?.name ?? "") + " " + (_user?.lastName ?? ""),
            allInfo: _user,
          };
          return obj;
        });
        setAllCSR(allCSR);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSubCardData = () => {
    setIsLoadingSubCard(true)
    let params = {}
    if (![EMAIL_TABS.DRAFT].includes(activeNav)) params.activePage = handleActiveTab()
    updateActivePageKey(params,true)
    getSubCardCounts(params)?.then(res => {
      let data = res;
      if(data?.emailStatus?.length) {
        data = {
          ...data,
          emailStatus : data.emailStatus.map((item) => {
            const matchedLabel = statusLabels.find(label => label.value === item.id);
            return {
              ...item,
              name: matchedLabel ? matchedLabel.label : item.name
            }
          }) 
        }
      }

      if(!_.isEmpty(data)) {
        for (const key in data) {
          if (Array.isArray(data[key]) && data[key]?.length ) {
            data[key] = _.sortBy(data[key], 'name');
          } else {
            data = {
              ...data,
              [key] : data[key]
            }
          }
        }
      }

      setSubCardData(data || {})
      setIsLoadingSubCard(false)
    }).catch(err => {
      setIsLoadingSubCard(false)
    })

  }

  const selectLabelForCheckBoxes = (status) => {
    const _filterState = _.cloneDeep(filterState)
    if (!Array.isArray(_filterState.emailFilters?.selectedStatus)) {
      setFilterKey('selectedStatus',[status])
    } else {
      let newStatus = _filterState.emailFilters?.selectedStatus;
      if (_filterState.emailFilters?.selectedStatus?.includes(status)) {
        newStatus = _filterState.emailFilters?.selectedStatus?.filter(st => st !== status)
      } else {
        newStatus.push(status)
      }
      setFilterKey('selectedStatus',newStatus)
    }
  }  

  const labelsOption = useMemo(()=>{
    return sortBy(
      allLabels
        .map((d) => ({
          label:OUTLOOK_PROVIDER.includes(provider) ? d?.name : (d.systemFolder? _.startCase(_.toLower(d?.name)):d?.name),
          value: d.id,
          info: d,
          lName: d.name,
        })),
      (l) => l.label.toLowerCase())
  },[allLabels,provider]) 
  
  const _getEmailFilterParamsHelper = () => {
    const _filterState = filterStateRef.current || filterState
    let payload = {}
    if (isSubCardSeletectedRef.current) {
       payload = createPayloadFromEmailFilters(_filterState.subCardFilter)
    } else {
      payload = createPayloadFromEmailFilters(_filterState.emailFilters)
    }
    return payload
  }
  
  const handleApplyFilter = (filters) => {
    setSelectedRows([])
    resetScroll()
    setPageToken("")
    setIsSubCardSeletected(false)
    setFilterState((prev) => ({ ...prev, subCardFilter: EMAIL_FILTERS_KEY, emailFilters: _.isEqual(filters, {}) ? EMAIL_FILTERS_KEY : filters }))
  }
  

  const handleResetFilter = () => {
    setSelectedRows([])
    setFilterState((prev) => ({ ...prev, subCardFilter: EMAIL_FILTERS_KEY, emailFilters: EMAIL_FILTERS_KEY }))
    resetScroll()
  }

  const viewParamsToSet = (view) => {
    resetScroll()
    const params = generateStateViaParams(view)
    let activePage = view?.filters?.["activePage"] || false;
    // if (activePage) {
    //   setActiveNav(reverseMapActiveTab(activePage) || EMAIL_TABS.INBOX)
    // }
    // else {
    //   setActiveNav(EMAIL_TABS.INBOX)
    // }

    delete params?.activePage
    delete params?.selectedTabForView
    setSearchQuery(params?.searchTerm??'')
    setSearchTerm(params?.searchTerm??'')
    delete params?.searchTerm
    setFilterState((prevState) => ({
      ...prevState,
      emailFilters: _.isEqual(params, {}) ? EMAIL_FILTERS_KEY : { ...params },
      subCardFilter: EMAIL_FILTERS_KEY
    }));
    setIsSubCardSeletected(false)
    return params;
  }
  
  const getFitersForView = () => {
    let filters = createPayloadFromEmailFilters(filterState.emailFilters)
    filters = updateViewPayloadForSpecialFields(filterState.emailFilters, filters)
    return filters
  }

  const updateViewPayloadForSpecialFields = (emailFilters, filters) => {

    if (filters.hasOwnProperty("tagIds") && filters['tagIds']?.length) {
      filters['tagIds'] = emailFilters['tagIds']
    } 

    if (filters.hasOwnProperty('label') && filters['label']?.length) {
      filters['label'] = emailFilters['label']
    }

    if (filters.hasOwnProperty('myTeam') && filters['myTeam']?.length) {
      filters['myTeam'] = emailFilters['myTeam'].map(d => d?.allInfo?._id)
    }

    if (filters.hasOwnProperty('assignees') && filters['assignees']?.length) {
      filters['assignees'] = emailFilters['assignees'].map(d => d?.allInfo?._id)
    }
    
    if (viewRelatedTabs.includes(activeNav) || viewRelatedTabs.includes(handleActiveTab())) {
      filters['activePage'] = handleActiveTabForView()
    }
    if(searchTerm?.trim()?.length>0){
      filters['searchTerm'] =searchQuery
    }

    return filters
  }

  const getAccounts = async() => {
    try {
      const params = new URLSearchParams(window.location.search);
      const email = params?.get('email') || params?.get('connectedEmail');
      setIsLoading(true)
      await getEmbeddedEmailAccount(email)
      if(params?.has('connectedEmail')){
        params.delete('connectedEmail')
        const newUrl = `${window.location.pathname}`;
        window.history.replaceState(null, '', newUrl);
      }
      getAllUnreadCount && getAllUnreadCount()
      handleMasterApiCall(false)
    } catch (error) {
      handleMasterApiCall(false)
      setIsLoading(false)
    }
  }

  const getAllEmailSignatures = () => {
    if(!isEmbeddedEmailConnected() || embeddedEmailAccount?.isDisconnected) return;
    getAllEmailSignature().then((data) => {
      setAllEmailSignature(data)
    }).catch((err) => {
      console.log("🚀 ~ getAllEmailSignature ~ err:", err)
    })
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Your code to execute before the page is refreshed or unloaded
      event.preventDefault();
      event.returnValue = '';
      handleMasterApiCall(true)
    };
    document.addEventListener('beforeunload', handleBeforeUnload);
    if(!isFromLoad && !isFromQuote){
      getAccounts()
    } else {
      handleMasterApiCall(false)
    }
    getAllEmailSignatures()
    listAllCSR();
    getPeople({limit:10})?.then(res => {
      setPeopleData(res)
    })

    return () => {
      document.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, []);


  const [isCompleted,setIsCompleted] = useState(false)
  const limit = getStorage('isEmailLimit') || 20;
  const skipRef = useRef(0)
  const tz = getTimeZone({preferred: true})

  const { allTags, getTags, handleSearchTerm, isTagsLoading, createTag, assignTagsToEmails, getTagsByThreadId } = useEmailTags()
  const {  allEmailTags, fetchEmailTags, updateSelectedEmailtags, updateTagInOneMail, allEmailStatus, setAllEmailStatus } = useAllMails(getTagsByThreadId);
  const { _getStatus, updateEmailsStatus } = useEmailStatus(allEmailStatus, setAllEmailStatus);
  const isEmailConnected = isEmbeddedEmailConnected()
  
  const handleSearch = (searchTerm, selectLabels, filters, skip = 0) => {
    checkViewsChanges && checkViewsChanges()
    resetScroll()
    if(isFromLoad) {
      _getEmailByLoad({searchTerm, selectLabels, filters, skip}, true)
      return
    }
    if(isFromQuote) {
      _getEmailByQuote({searchTerm, selectLabels, filters, skip}, true)
      return
    }
    if(activeNav === EMAIL_TABS.ALL_MAIL) {
      const starred = activeNav === EMAIL_TABS.STARRED
      _getAllEmails({searchTerm, selectLabels, filters, skip}, true, starred)
      return
    }
    if(activeNav === EMAIL_TABS.INBOX) {
      _getEmails({searchTerm, selectLabels, filters, skip}, true)
      return
    }
    // if(activeNav === EMAIL_TABS.SENT) {
    //   _getSentEmails({searchTerm, selectLabels, filters, skip}, true)
    //   return
    // }
    if(![EMAIL_TABS.ALL_MAIL,EMAIL_TABS.INBOX].includes(activeNav)) {
      _getEmailByLabel(activeNav,{searchTerm, selectLabels, filters, skip}, true)
      return
    }
  }
  const [showCreateLabelModal, setShowCreateLabelModal] = useState(false);
  const addLabelModalRef = useRef()
  const [selectedColor, setSelectedColor] = useState(null)
  const [updateStatusDataFlag, setupdateStatusDataFlag] = useState(false)
  const [updateStatusData, setupdateStatusData] = useState(null)
  const fetchData = (reset = false) => {
    if(reset){
      resetScroll()
    }
    if(isFromLoad){
      _getEmailByLoad({},reset)
      return
    }
    if(isFromQuote){
      _getEmailByQuote({},reset);
      return;
    }
    // if(activeNav === EMAIL_TABS.DRAFT){
    //   _getDraftEmails({},reset);
    //   return
    // }
    if(activeNav === EMAIL_TABS.ALL_MAIL){
      const starred = activeNav === EMAIL_TABS.STARRED
      _getAllEmails({},reset, starred);
      return
    }
    if(activeNav === EMAIL_TABS.INBOX){
      _getEmails({},reset);
      return
    }
    if(activeNav === EMAIL_TABS.DELETED_ITEMS){
      _getEmailByLabel("trash",{},reset)
      return
    }
    if(![EMAIL_TABS.ALL_MAIL,EMAIL_TABS.INBOX,EMAIL_TABS.DELETED_ITEMS].includes(activeNav)) {
      _getEmailByLabel(activeNav,{},reset)
      return
    }
  };
  const handleDefaultScroll = () => {
    const dataTable = document.getElementsByClassName('rdg r104f42s700-beta13 fill-grid rdg-light rdg-no-outline rdg-email-table');
    if (dataTable && dataTable[0]) {
      if (isLoading || isCompleted || isAtBottom({ currentTarget: dataTable[0] })) return;
      if(skipRef.current !== 0) fetchData();
    }
  }

  const getFirstPageData = () => {
    fetchData(true)
    setTimeoutID(null)
  }
  const onLoadCallFun = async()=>{
    if(embeddedEmailAccount?.isDisconnected) return
    if(!allFolderRef.current.length) {
      if (!isFromLoad && !isFromQuote) {
        setIsGettingLabels(true)
        await getMainFolderList().then((data) => {
          let folders = data?.folders
          if (OUTLOOK_PROVIDER.includes(provider)) {
            folders = setDefaultSystemFolderForOutLook(folders)
          } else {
            const allCounts = {}
            folders?.forEach(folder => {
              const fName = folder.systemFolder ? convertToTitleCase(folder.name) : folder.name
              allCounts[fName] = folder.unreadCount;
            })
            setAllCounts(allCounts)
          }
          setAllLabels(folders)
          allFolderRef.current = folders
          setActivePageId()
          setIsGettingLabels(false)
        }).catch((err) => {
          console.log("err", err)
          setIsGettingLabels(false)
        })
        _getLabels()
      }
      fetchData(true);
    }else{
      fetchData(true);
    }
  }
  useEffect(() => {
    const statusFilter = filterState?.emailFilters?.emailStatus?.length
    if (updateStatusDataFlag && statusFilter !== 0) {
     
      let arr = allEmails;
      let checkStatus = updateStatusData;
      let statusSelected = filterState?.emailFilters?.emailStatus;
  
      let filteredArr = arr.filter((item) => {
        // Check if the item's id matches any value in payloadThreadIds
        let isIdMatch = checkStatus.payloadThreadIds.some(
          (threadId) => threadId === item.id
        );
      
        if (isIdMatch) {
          // Check if the status matches any value in statusSelected
          let isStatusMatch = statusSelected.some(
            (status) => status?.value === checkStatus?.status
          );
      
          if (isStatusMatch) {
            // Update item.status if there's a match
            item.status = checkStatus?.status;
          }
      
          // If no match, filter out; otherwise, keep
          return isStatusMatch;
        } else {
          // Keep the item if id doesn't match any payloadThreadIds
          return true;
        }
      });
      
      setAllEmails(filteredArr)
      setupdateStatusDataFlag(false);
      setupdateStatusData(null)
    }else{
      setupdateStatusDataFlag(false);
    }
  }, [updateStatusDataFlag]);
  useEffect(() => {
    if(isMasterApiCall) return
    if (callMasterFun) {
      if(timeoutID){
        cancelDelayedCall(timeoutID)
        setTimeoutID(null)
      }
      onLoadCallFun();
    }

  }, [filterState, selectLabels, activeNav, callMasterFun, isMasterApiCall, refreshPage])

  useEffect(() => {
    if (callMasterFun) {
      if(timeoutID){
        cancelDelayedCall(timeoutID)
        setTimeoutID(null)
      }
      handleSearch(searchQuery,selectLabels, filters)
    } 
  },[searchQuery])

  const updateActivePageKey = (params,isRemoveExcludeLabels)=>{
    if(params?.activePage){
      if(OUTLOOK_PROVIDER.includes(provider)){
        if(params?.activePage==="Inbox" && !isRemoveExcludeLabels){
          const excludedLabels = new Set(["Deleted Items", "Archive"]);
          const isExcludeLabels = allFolderRef?.current?.filter(folder => excludedLabels.has(folder?.name))?.map(folder => folder?.id);
          params.isExcludeLabels = isExcludeLabels
        }
        const folder = allFolderRef?.current?.find(e=>e?.name==params?.activePage)
        if (folder) params.activePage = folder.id
      }else{
        if (params?.activePage === "inbox" && !isRemoveExcludeLabels) {
          const excludedLabels = new Set(["TRASH"]);
          const isExcludeLabels = allFolderRef?.current?.filter(folder => excludedLabels.has(folder?.name))?.map(folder => folder?.id);
          params.isExcludeLabels = isExcludeLabels
        }
        const folder = allFolderRef?.current?.find(e=>e?.name?.toLowerCase()==params?.activePage?.toLowerCase())
        if (folder) params.activePage = folder.id
      }
    }
  }


  const updateParams = (params = {}) => {
    const _params = _.cloneDeep(params);
    if(!_params?.selectLabels) delete _params.selectLabels

    if(!_.isEmpty(_params?.filters?.fromDate)) {
      _params.received_after = moment(_params?.filters?.fromDate).tz(tz).startOf('day').toISOString()
      delete _params.filters.fromDate
    } else if(!_.isEmpty(filters?.fromDate)) {
      _params.received_after = moment(filters.fromDate).tz(tz).startOf('date').toISOString()
    }
    if(!_.isEmpty(_params?.filters?.toDate)) {
      _params.received_before = moment(_params?.filters?.toDate).tz(tz).endOf('day').toISOString()
      delete _params.filters.toDate
    } else if(!_.isEmpty(filters?.toDate)) {
      _params.received_before = moment(filters.toDate).tz(tz).endOf('day').toISOString()
    }

    if(_params?.selectLabels?.value) {
      _params.label = _params?.selectLabels?.value
      delete _params?.selectLabels 
    }
    else if(selectLabels?.value) {
      _params.label = selectLabels.value;
    }
    delete _params.filters

    const _searchQuery = searchQueryRef.current || searchQuery
    if(("searchTerm" in _params) && _.isEmpty(_params?.searchTerm)) delete _params.searchTerm
    else if(!_.isEmpty(_searchQuery)) {
      _params.searchTerm = _searchQuery
    }
    if(_params.hasOwnProperty('skip')){
      _params.pageToken = pageToken
      delete _params.skip
    } else {
      _params.pageToken = pageToken;
    }
    _params.limit = limit

    return _params
  }

  const handleShowFilterBar = () => {
    setShowFilterBar(true)
  }


  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value);
      setPageToken("");
    }, 500),
    []
  );

  const handleDebounceSarch = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
    handleSearchChange(value);
  };

  const handleHideFilterBar = () => {
    setShowFilterBar(false)
  }

  const filterThreadsByExcludeLabelIds=(labels, threads) =>{
    const filteredThreads = threads.filter((thread) => checkConditionToAppend(thread));
    return filteredThreads;
  }

  const _getEmails = async (params = {}, reset = false) => {
    
    if(pageTokenRef.current === "END") return;

    setIsLoading(true)
    let _updatedParams = updateParams(params);
    _updatedParams = sendTabOrSubCardFilterParams(_updatedParams)
    setIsGettingEmails(true)
    if (reset || !_updatedParams?.pageToken)
      delete _updatedParams.pageToken  
    updateActivePageKey(_updatedParams)
  
    getEmails(_updatedParams).then(async({ messages, apiCallRequired, pageToken }) => {
      await changeTableData(messages, reset, false, apiCallRequired, false, _updatedParams?.isExcludeLabels)
      setIsGettingEmails(false)
      setIsLoading(false)
      setPageToken(pageToken ? `${pageToken}` : "END")
    }).catch((err) => {
      setIsLoading(false)
      setIsGettingEmails(false)
      console.log("err", err)
    })
  }

  const _getAllEmails = (params = {}, reset = false, starred = false) => {
    if(!isFromLoad) {
      setIsLoading(true)
      let _updatedParams = updateParams(params);
      _updatedParams = sendTabOrSubCardFilterParams(_updatedParams)
      if(starred){
        _updatedParams.starred = true
      }
      setIsGettingEmails(true)
      if (reset || !_updatedParams?.pageToken) delete _updatedParams.pageToken
      updateActivePageKey(_updatedParams)
      getAllEmails(_updatedParams).then(async({ messages, apiCallRequired, pageToken }) => {
        await changeTableData(messages, reset, false, apiCallRequired)
        setIsLoading(false)
        setIsGettingEmails(false)
        setPageToken(pageToken ? `${pageToken}` : "")
      }).catch((err) => {
        setIsLoading(false)
        console.log("err", err)
        setIsGettingEmails(false)
      })
    }
  }

  const _getEmailByLabel = (label, params = {}, reset = false) => {
    const isDraft = allFolderRef?.current?.find((l) => l.name === EMAIL_TABS.DRAFT)?.id === label
    let updatedLabel = OUTLOOK_PROVIDER.includes(provider) && isDraft ? "outlook-draft" : label

    setIsLoading(true);
    if (reset) delete params.pageToken
    let _updatedParams = updateParams(params);
    _updatedParams = sendTabOrSubCardFilterParams(_updatedParams)
    _updatedParams.isSendEmailApiCall = isSentTabSelected(label, allLabels)
    setIsGettingEmails(true)
    updateActivePageKey(_updatedParams)
    if(EMAIL_PROVIDER.GMAIL===provider){
      const found = allFolderRef?.current?.find((l) => l.name === updatedLabel)
      if(found && !found?.systemFolder){
        updatedLabel = found.id
        _updatedParams.isSubFolder = true
      } 
    }

    getEmailByLabel(updatedLabel, _updatedParams).then(async({ messages, apiCallRequired, pageToken }) => {
      await changeTableData(messages, reset, false, apiCallRequired)
      setIsLoading(false)
      setIsGettingEmails(false)
      setPageToken(pageToken ? `${pageToken}` : "")
    }).catch((err) => {
      setIsLoading(false)
      console.log("err", err)
      setIsGettingEmails(false)
    })
  }

  const resetScroll = () => {
    skipRef.current = 0
    pageTokenRef.current = ""
    setIsCompleted(false)
    setAllEmails([])
    setPageToken("")
    let table= document.querySelector(".fill-grid")
    if(table) table.scrollTop=0;
    return
  }

  const resetState = (props = {}) => {
    const { isClearSelectedEmailData = true } = props
    setFilterState((prev) => ({
      ...prev,
      filters: {
        fromDate: "",
        toDate: ""
      },
      subCardFilter: EMAIL_FILTERS_KEY,
    }))
    setSearchQuery("")
    setPageToken("")
    setSubCardData([])
    setSelectLabels(null)
    setIsCompleted(false)
    resetScroll()
    setSelectedRows([])
    if (isEmailMode === EMAIL_TABLE_VIEW.CARD && isClearSelectedEmailData) {
      setSelectedRow(null)
      setShowEmailDetailsModalR(false)
    }
  }

  useEffect(() => {
    if (!!allEmails?.length) {
      _getStatus(allEmails);
      fetchEmailTags(allEmails, true);
    }
  }, [allEmails]);

  const changeTableData = async (newData, reset, dataComplete = false, apiCallRequired = false, isFromCache = false, isExcludeLabels=[]) => {
    newData = newData || [];
    let complete = dataComplete ? dataComplete : (newData?.length < limit);
    if(apiCallRequired) complete = false
    const prevEmails = reset ? [] : [...allEmails];
    if(isExcludeLabels?.length>0 && newData?.length>0){
      newData = await filterThreadsByExcludeLabelIds(isExcludeLabels,newData)
    }
    let newEmails = [...prevEmails, ...newData];
    const currentSkip = skipRef.current;

    if(!isFromCache)
    skipRef.current = currentSkip + limit;

    const trashLabels = ["Trash", "Deleted Items", "Deletions"];
    const spamLabels = ["Spam"];
    
    let isNotJunk = true
    if(OUTLOOK_PROVIDER.includes(provider)){
      const JunkEmail = allLabels?.find((label) => label?.name.toLowerCase() === "junk email");
      if(JunkEmail?.id === activeNav){
        isNotJunk = false
      }
    }
    if ((!trashLabels.includes(activeNav) && !spamLabels.includes(activeNav)) && isNotJunk) {
      newEmails = newEmails.map((email) => {
        let labelValue = provider === EMAIL_PROVIDER.GMAIL ? email.labels : OUTLOOK_PROVIDER.includes(provider) && email?.folders;
        const isTrashOrSpamEmail = labelValue?.length && labelValue.find((label) => ["spam", "trash"].includes(label?.name));
        if (!isTrashOrSpamEmail) {
          return email;
        }
      }).filter((element) => element != null);
    }
    if (newEmails?.length === 200) {
      const eventCardProperties = {
        source: isEmailMode
      }
      amplitudeTrack("MANY_INFINITE_SCROLL", eventCardProperties)
    }
    setAllEmails(newEmails);
    setIsCompleted(complete);
  }

  useEffect(() => {
    handleDefaultScroll();
  }, [allEmails, isEmailMode]);

  const _getAllLabels = () => {
    setIsGettingLabels(true)
    const allKeys = Object.keys(allAccountFolders)
    const paramsList = allKeys.map(key => ({ grant: key }))
    Promise.allSettled(paramsList.map(params => getFolders(params))).then((data) => {
      console.log("all labels", data)
      setIsGettingLabels(false)
    }).catch((err) => {
      console.log("err", err)
      setIsGettingLabels(false)
    }).finally(() => {
      getAllNestedFolders()
    })
  }

  const _getLabels = () => {
    if (embeddedEmailAccount?.isDisconnected) return
    setIsGettingLabels(true)
    getFolders().then((data) => {
      if (data?.length) {
        const selectedAccount = JSON.parse(localStorage.getItem('embeddedEmailAccount'))
        const grantId = selectedAccount?.accountId
        const folderGrantId = data[0]?.grantId
        if (folderGrantId !== grantId) return
      }
      if (provider === EMAIL_PROVIDER.GMAIL) {
        const allCounts = {}
        data?.forEach(folder => {
          const fName = folder.systemFolder ? convertToTitleCase(folder.name) : folder.name
          allCounts[fName] = folder.unreadCount;
        })
        setAllCounts(allCounts)
        setAllLabels(data)
        allFolderRef.current = data
      } else if (OUTLOOK_PROVIDER.includes(provider)) {
        const folder = setDefaultSystemFolderForOutLook(data)
        allFolderRef.current = folder
        setAllLabels(folder)
      }
      setIsGettingLabels(false)
    }).catch((err) => {
      console.log("err", err)
      setIsGettingLabels(false)
    })
  }

  const updateFoldersCount = (folderIds, archiveCount, ignoreActiveNav) => {
    setTimeout(()=>{
      let Ids = []
      const payload = { activePage: handleActiveTab() }
      updateActivePageKey(payload)
      if (payload?.activePage && activeNav !== EMAIL_TABS.ALL_MAIL && !ignoreActiveNav) Ids.push(payload?.activePage)
      if (folderIds?.length > 0) Ids = [...Ids, ...folderIds]
      if (archiveCount && OUTLOOK_PROVIDER.includes(provider)) {
        const id = allFolderRef?.current?.find(e => e?.name === EMAIL_TABS.ARCHIVE)?.id
        if (id) Ids.push(id)
      }
      Ids = Ids.filter(Boolean)
      if (!Ids?.length) return
      getFoldersInfo({ folderIds: Ids, shouldFetchFromNylas: true }).then(res => {
        if(provider === EMAIL_PROVIDER.GMAIL){
          res?.forEach(folder => {
            const fName= folder.systemFolder? convertToTitleCase(folder.name):folder.name
            setAllCounts((prev)=>({...prev,[fName] : folder.unreadCount}))
          })
        }else{
          res = setDefaultSystemFolderForOutLook(res)
          const data = allFolderRef.current.map(e=>{
            const found = res.find(i=>i.id===e.id)
            if (found) e = { ...e, unreadCount: found.unreadCount, totalCount: found.totalCount }
            return e
          })
          allFolderRef.current = data
          setAllLabels(data)
        }
        
      }).catch((err) => {
        console.log("err", err)
      })
    },3000)
  }

  const sendEmail = (mailData, isDraft) => {
      sendMail(mailData, isDraft).then(() => {
        if(isDraft){
          toastr.show("Draft Saved", "success")
        } else {
          toastr.show("Email sent", "success")
        }
      }).catch((err)=>{
        console.log("err",err)
        toastr.show("Mail Not Sent.","error")
      })
  }

  const _replyEmail = (mailData) => {
    replyEmail(mailData).then(() => {
      toastr.show("Email Replyed.", "success")
    }).catch((err) => {
      toastr.show("Something went wrong email not sent.", "error")
      console.log("err", err)
    })
  }

  const sidebarWidth = 260
  const _setAllEmails = (data) => {
    setAllEmails(data)
  }

  const handleReset = () => {
    // _getEmails()
    skipRef.current = 0
    pageTokenRef.current = ""
    setSearchQuery("")
    getAllEmailSignatures()
    if (activeFolder?.id === EMAIL_TABS.INBOX) {
      setActiveNav(EMAIL_TABS.INBOX);
    } else {
      setActiveNav((activeNav) => activeNav)
    }
    setPageToken("")
    setSelectLabels(null)
  }

  const sendTabOrSubCardFilterParams = (_updatedParams) => {
    
    const _filterState = filterStateRef.current || filterState
    if (![EMAIL_TABS.DRAFT].includes(activeNav)) _updatedParams.activePage = handleActiveTab()
    
    if (Object.keys(createPayloadFromEmailFilters(_filterState.subCardFilter))?.length > 0 || Object.keys(createPayloadFromEmailFilters(_filterState.emailFilters))?.length > 0) {
      let payload = _getEmailFilterParamsHelper()
      _updatedParams = { ..._updatedParams ,...payload}
      if(searchQuery.length) _updatedParams.searchTerm = searchQuery
      return _updatedParams
    }
    return _updatedParams
  }

  const _getEmailByLoad = (params = {}, reset = false) => {
    setIsLoading(true)
    let _updatedParams = updateParams(params);
    _updatedParams = sendTabOrSubCardFilterParams(_updatedParams)
    setIsGettingEmails(true)
    if(!isEmailConnected) _updatedParams.skipMiddleWare = true
    getEmailByLoadId(_updatedParams, loadId).then(async({messages, dataComplete}) => {
      await changeTableData(messages, reset, dataComplete)
      setIsGettingEmails(false)
      setIsLoading(false)
    }).catch((err) => {
      console.log(err);
      setIsLoading(false)
      setIsGettingEmails(false)
    })
  }

  getFilters && getFilters(getFitersForView)
  setViewparams && setViewparams(viewParamsToSet)
  setSelectedRowEmpty && setSelectedRowEmpty(()=>setSelectedRows([]))
  
  const _getEmailByQuote = (params = {}, reset = false) => {
    setIsLoading(true);
    setIsGettingEmails(true);

    const _updatedParams = updateParams(params);

    delete _updatedParams.limit;
    delete _updatedParams.pageToken;
    const param = {}
    if(!isEmailConnected) param.skipMiddleWare = true
    getEmailByQuoteId(param, quoteNumber).then(async(res) => {
      await changeTableData(res, reset)
      setIsGettingEmails(false)
      setIsLoading(false)
    }).catch((err) => {
      console.error(err);
      setIsLoading(false)
      setIsGettingEmails(false)
    })
  }

  useEffect(() => {
    fetchEmailTags(allEmails, threadUpdates?.tag || !threadUpdates?.threadId);
  }, [threadUpdates]);

  const handleHideBatchMenu = () => {
    setSelectedRows([])
    setIsEditMode(false)
  }

  return (
    <EMAIL_CONTEXT.Provider
      value={{
        _getEmails: _getEmails,
        allLabels: allLabels,
        setAllLabels: setAllLabels,
        allFolderRef:allFolderRef,
        allEmails: allEmailRef.current,
        setAllEmails: _setAllEmails,
        sendEmail: sendEmail,
        replyEmail: _replyEmail,
        resetState: resetState,
        activeNav: activeNav,
        activeFolder, 
        setActiveFolder,
        _getCounts:updateFoldersCount,
        allEmailTags: allEmailTags,
        updateSelectedEmailtags,
        allEmailStatus,
        setAllEmailStatus,
        currentOpenLoad: reference_number,
        updateTagInOneMail,
        isSubFolderActive,
        activeTabId:activeTabIdRef.current,
        selectedRows,
        isMoveEmailLoader,
        setIsMoveEmailLoader:(val)=>setIsMoveEmailLoader(val),
        setAllNestedFolders,
        setFavoriteFolders,
        allNestedFolders,
        favoriteFolders,
        allAccountFolders
      }}
    >
    <div className={`${isFromLoad ? "flex-grow-1 d-flex flex-column overflow-hidden" :"row flex-grow-1 overflow-hidden"}`}>
      {(!isFromLoad && !isFromQuote) && 
        <div className="col-lg flex-grow-1 overflow-y-auto h-100 pr-1" style={{ maxWidth: sidebarWidth }}>
          <div className="d-flex gap-10 pb-10 position-sticky bg-light top-0 bg-gray-50 z-1">
            <button className="btn btn-primary w-100 justify-content-center" onClick={()=>{handleShowCreateEmailModal(true)}}>
              <IconPlus className="mr-2" />
              Compose
            </button>
            <button 
              className="btn btn-white"
              onClick={()=>{
                handleReset()
                _getAllLabels()
                let eventProperties = {
                  source: AMPLITUDE_EVENTS_SOURCE.REFRESH_EMAIL
                }
                setRefreshPage((prev)=>!prev)
                amplitudeTrack(AMPLITUDE_EVENTS.REFRESH, eventProperties)
              }}
              disabled={isGettingEmails || isGettingLabels}
            >
              <IconReset />
            </button>
          </div>
          <EmailNav
            setEmailChanged={setEmailChanged}
            setActiveNav={setActiveNav}
            setIsSubFolderActive={setIsSubFolderActive}
            activeNav={activeNav}
            allLabels={allLabels}
            allCounts={allCounts}
            isGettingEmails={isGettingEmails}
            setSelectedRow={setSelectedRow}
            setShowEmailDetailsModalR={setShowEmailDetailsModalR}
            setEmailFilters={setEmailFilters}
            setSearchTerm={setSearchTerm}
            allEmails={allEmails}
            setAllEmails={setAllEmails}
            handleHideBatchMenu={handleHideBatchMenu}
            isEmailMode={isEmailMode}
            changeInEmail={changeInEmail}
            allNestedFolders={allNestedFolders}
            favoriteFolders={favoriteFolders}
          />
        </div>
      }
      <div className={`flex-grow-1 overflow-hidden d-flex flex-column ${isFromLoad ? "": isFromQuote ? "col-lg": "col-lg h-100"}`}>
        <EmailTopPage
          handleSearchChange={handleDebounceSarch}
          showFilterBar={handleShowFilterBar}
          emailFilters={filterState.emailFilters} 
          setFilterKey={setFilterKey}
          subCardFilter={filterState.subCardFilter}
          setSubCardKey={setSubCardKey}
          allEmailSignature={allEmailSignature}
          getAllEmailSignatures={getAllEmailSignatures}
          isEmailMode={isEmailMode}
          allTags={allTags}
          handleSearchTerm={handleSearchTerm}
          getTags={getTags}
          isTagsLoading={isTagsLoading}
          activePage={handleActiveTab()}
          isLoading={isLoading}
          searchTerm={searchTerm}
        />
        <EmailTable 
          sidebarWidth={sidebarWidth}
          setFilters={setFilters}
          handleSearchChange={handleDebounceSarch}
          setupdateStatusData={setupdateStatusData}
          setupdateStatusDataFlag={setupdateStatusDataFlag}
          filters={filters}
          searchQuery={searchQuery}
          selectLabels={selectLabels}
          setSelectLabels={setSelectLabels}
          isApiCall = {isGettingEmails || isGettingLabels}
          showFilterBar={handleShowFilterBar}
          emailFilters={filterState.emailFilters} 
          setFilterKey={setFilterKey}
          subCardFilter={filterState.subCardFilter}
          setSubCardKey={setSubCardKey}
          isLoading={isLoading}
          allEmails={allEmails}
          allLabels={allLabels}
          allEmailStatus={allEmailStatus}
          setIsLoading={setIsLoading}
          activeNav={activeNav}
          _getCounts={updateFoldersCount}
          isFromLoad={isFromLoad}
          loadId={loadId}
          quoteId={quoteId}
          _getEmailByLoad={(param, reset)=>{_getEmailByLoad(param, reset)}}
          _getEmailByQuote={_getEmailByQuote}
          onDisconnectMail={onDisconnectMail}
          setShowCreateLabelModal={setShowCreateLabelModal}
          setShowCreateTag={setShowCreateTag}
          allTags={allTags}
          isEmailMode={isEmailMode}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          showEmailDetailsModalR={showEmailDetailsModalR}
          setShowEmailDetailsModalR={setShowEmailDetailsModalR}
          fetchData={fetchData}
          skipRef={skipRef}
          handleSearchTerm={handleSearchTerm}
          isTagsLoading={isTagsLoading}
          assignTagsToEmails={assignTagsToEmails}
          getTags={getTags}
          activePage={handleActiveTab()}
          allEmailSignature={allEmailSignature}
          getAllEmailSignatures={getAllEmailSignatures}
          isCompleted={isCompleted}
          threadUpdates = {threadUpdates}
          showEmailDetailsModal={showEmailDetailsModal}
          setShowEmailDetailsModal={(data)=>{
            setShowEmailDetailsModal(data)
          }}
          updateUnreadCountLoadInfoTab={updateUnreadCount}
          updateActivePageKey={updateActivePageKey}
          isSubFolderActive={isSubFolderActive}
          searchTerm={searchTerm}
          handleHideBatchMenu={handleHideBatchMenu}
          isEditModeOn={isEditModeOn}
          setIsEditMode={setIsEditMode}
        />  
      </div>
      {showFilterBar && activeNav !== EMAIL_TABS.DRAFT && 
        <EmailFilterBar 
          show={showFilterBar} 
          closeFilterBar={handleHideFilterBar} 
          emailFilters={filterState.emailFilters} 
          setFilterKey={setFilterKey} 
          handleResetFilter={handleResetFilter}
          allCSR={allCSR}
          handleApplyFilter={handleApplyFilter}
          labelsOption={labelsOption}
          selectLabels={selectLabels}
          setSelectLabels={setSelectLabels}
          activeNav={activeNav}
          isFromLoad={isFromLoad}
          isFromQuote={isFromQuote}
          selectLabelForCheckBoxes={selectLabelForCheckBoxes}
          peopleData={peopleData}
          subCardData={subCardData}
          setSubCardKey={setSubCardKey}
          subCardFilter={filterState.subCardFilter}
          setEmailFilters={setEmailFilters}
          setSubCardData={setSubCardData}
          handleSubCardData={handleSubCardData}
          isLoadingSubCard={isLoadingSubCard}
          handleSearchTerm={handleSearchTerm}
          isTagsLoading={isTagsLoading}
          getTags={getTags}
          allTags={allTags}
          isLoading={isLoading}
          />}
      {showCreateEmailModal && 
          <CreateEmailModal 
            show={showCreateEmailModal} 
            hide={()=>{handleShowCreateEmailModal(false)}} 
            allEmailSignature={allEmailSignature} 
            getAllEmailSignatures={getAllEmailSignatures} 
      />}
      {showCreateLabelModal && (
        <AddLabelModal
          show={showCreateLabelModal}
          modalRef={addLabelModalRef}
          selectedColor={selectedColor}
          handleColorCheckboxChange={setSelectedColor}
          handleCreateLabelModel={setShowCreateLabelModal}
          />
      )}
      {showCreateTag && (
        <EditTagModal
          tag={{ _id: "new", orderIndex: allTags.length + 2 }}
          hide={() => setShowCreateTag(false)}
          createTag={createTag}
          loading={isTagsLoading}
          allTags={allTags}
        />
      )}
    </div>
    </EMAIL_CONTEXT.Provider>
  )
}

export default AllMails
