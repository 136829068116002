import moment from "moment";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { amplitudeTrack, getStorage, makeOptionsForSelect, showForTerminal, toastr } from "services";
import { getAdminChartCounts, getBrokerChartCounts, getCustomerChartCounts, getCustomerServiceChartCounts, getEmployeeChartCounts, getFinancialsChartCounts, getOperatinsChartCounts, getSystemChartCounts, getTrackChartCounts, getEquipmentChartCounts, getCustomersByIDS } from "./actionCreator";
import { ADMIN_CHARTS, BROKER_CHARTS, CUSTOMER_CHARTS, CUSTOMER_SERVICE_CHARTS, EMPLOYEE_CHARTS, FINANCIALS_CHARTS, OPERATIONS_CHARTS, REPORT_TYPES, SYSTEM_CHARTS, TRACK_CHARTS, EQUIPMENT_CHARTS, DASHBOARDS_CHARTS_LIST, DASHBOARD_CHARTS_FILTER_NAME, DASHBOARDS, REPORT_PROPS, REPORT_PROPS_BROKER, REPORT_PROPS_CUSTOMER, REPORT_PROPS_CUSTOMER_SERVICE, REPORT_PROPS_EMPLOYEE, REPORT_PROPS_FINANCIAL, REPORT_PROPS_OPERATIONS, REPORT_PROPS_SYSTEM, REPORT_PROPS_TRACK, REPORT_PROPS_EQUIPMENT, COUNTER_CARDDS_CHARTS, TAB_NAMES, DASHBOARD_FILTER_APPLIES, REPORT_NAMES, NEWEST_10_CUSTOMERS_LABEL } from "./constants";
import numbro from "numbro";
import _ from "lodash";
import { LOAD_TYPES } from "pages/tms/carrier/AccountReceivable/constants";
import { getCustomerAsyncSearch } from "Components/DynamicPricing/utils";
import { createContext } from "react";
import { STATUSES_NOT_INCLUDED_IN_FILTER } from "pages/tms/Dispatcher/utility";
import { getPreSetOrderStatus, getRoutingEvents } from "pages/tms/Load/DriverOrderUtility";
import { getCustomerCityAsyncSearch } from "pages/tms/NewDispatcher/actionCreator";


// Function to define date ranges
export const DATE_RANGE = () => {
  const tz = getStorage("timeZone")
  return {
    "Yesterday": [moment().tz(tz).subtract(1, 'day').startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Week": [moment().tz(tz).subtract(1, 'week').startOf('week'), moment().tz(tz).subtract(1, 'week').endOf('week')],
    "Last 7 Days": [moment().tz(tz).subtract(7, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 15 Days": [moment().tz(tz).subtract(15, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 30 Days": [moment().tz(tz).subtract(30, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Month": [moment().tz(tz).subtract(1, "month").startOf("month"), moment().tz(tz).subtract(1, "month").endOf("month")],
    "Last 3 Month": [
      moment().tz(tz).subtract(3, "month").startOf("month"),
      moment().tz(tz).subtract(1, "month").endOf("month"),
    ],
  }
}

// Function to convert data for chart
export const convertDataForChart = (data, reportType, isTableData) => {
  const desiredOrder = ["Import", "Export", "Road", "Bill"];

  if(isTableData && reportType === REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES){
    return data?.map(item => ({ name: item?.["Invoice #"], count: item?.["Date Invoiced"], status: item?.["Payment Status"], ...item }))
  }

  if(isTableData && reportType === REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE){
    return data?.map(item => ({ name: item?.company_name, count: item?.loadCount}))
  }
  if(!isTableData && reportType === REPORT_TYPES.BROKER_TENDERS_BY_CARRIER){
    return data?.map(item => ({ name: item?.status, count: Number(item?.count) }))
  }
  const transformers = {
    // Admin
    [REPORT_TYPES.LOADS_BY_TYPE]: () => {
      const chartData = typeOfLoadDataMapper(data, "Load Type", "Count", "name", "count", REPORT_NAMES?.LOADS_BY_TYPE);
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.LOADS_BY_CUSTOMER]: () => 
      data.map(item => ({ company_name: item?.["Customer"], count: item?.["Load Count"], ...item })),

    [REPORT_TYPES.TOP_DELIVERY_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_PICK_UP_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Pick Up Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_RETURN_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Return Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.CREATED_LOADS]: () => 
      data.map(item => ({ start_date: item?.start_date, count: item?.load_count, ...item })),

    [REPORT_TYPES.COMPLETED_LOADS]: () => 
      data.map(item => ({ start_date: item?.start_date, count: item?.load_count, ...item })),

    // Broker
    [REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Vendor"], count: item?.["# of Invoices Receives"], ...item })),

    // Financials
    [REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE]: () =>{
      const chartData = typeOfLoadDataMapper(data, "Load Type", "Revenue", "company_name", "count", REPORT_NAMES?.FINANCIAL_REVENUE_BY_LOAD_TYPE, "Percent of Total Revenue", "percentage_of_total_revenue");
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME]: () =>
      data.map(item => ({ chargeName: item?.["Charge Name"], count: item?.["Revenue"], percentage: item?.["% of Total Revenue"], ...item })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE]: () => 
      data.map(item => ({ name: item?.["Customer"], count: item?.["Profit ($ amount)"], percentage: item?.["Profit (% of Total Profit)"], ...item })),
    
    [REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN]: () => 
      data.map(item => ({ company_name: item?.["Margin Badge"], count: item?.["% of Loads"] })),

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Revenue, ...item })),

    [REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS]: () =>
      data.map(item => ({ company_name: item?.["Driver Name"], count: item?.["Net Pay"], ...item })),
    
    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS]: () =>
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Profit, ...item})),

    [REPORT_TYPES.FINANCIAL_EXPENSES]: () =>
      data.map(item => ({ company_name: item?.["Expense"], count: item?.["Amount"], ...item })),

    [REPORT_TYPES.FINANCIAL_TOTAL_REVENUE]: () =>
      data.map(item => ({start_date: item?.start_date, count: item?.revenue_per_day, ...item})),

    [REPORT_TYPES.FINANCIAL_NUMBER_OF_INVOICES]: () =>
      data.map(item => ({start_date: item?.start_date, count: item?.day_count, ...item})),

    [REPORT_TYPES.FINANCIAL_TOTAL_PROFIT]: () =>
      data.map(item => ({start_date: item?.start_date, count: item?.day_profit, ...item})),

    [REPORT_TYPES.FINANCIAL_GROSS_DRIVER_PAY]: () =>
      data.map(item => ({start_date: item?.start_date, count: item?.daydriverpay, ...item})),

    [REPORT_TYPES.FINANCIAL_GROSS_VENDOR_PAY]: () =>
      data.map(item => ({start_date: item?.start_date, count: item?.day_total_vendor_pay, ...item})),

    // Operation
    [REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Average number of Moves"], ...item })),
    
    [REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Moves Completed"] })),
    
    [REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK]: () =>
      data.map(item => ({ company_name: item?.["Day of Week"], count: item?.["Loads Completed"] })),
    
    [REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE]: () =>
      data.map(item => ({ company_name: item?.["Employee"], count: item?.["Number of Dispatches"] })),
    
    [REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER]: () =>
      data.map(item => ({ name: item?.["Driver"], ...item })),

    [REPORT_TYPES.OPERATION_LOADS_COMPLETED]: () => 
      data.map(item => ({ start_date: item?.start_date, count: item?.load_count, ...item })),

    // Customer Service
    [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED]: () =>
      data.map(item => ({ company_name: item?.name, count: item?.loads, ...item })),

    // Empployee
    [REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name (Approver)"], count: item?.["Count of Approved Line Items"], ...item })),

    [REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name (Approver)"], count: item?.["Count of Settlements Approved"], ...item })),

    [REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE]: () =>
      data.map(item => ({ name: item?.name, count: parseFloat(item?.percentage || 0) })),  

    // Equipment
    [REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Chassis #"], count: item?.["Count of Completed Moves"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_USAGE]: () =>
      data.map(item => ({ company_name: `${item?.["Driver Name"]} ( ${item?.["Truck #"]} )`, count: item?.["Count of Completed Moves"], truckNo: item?.["Truck #"], driverName: item?.["Driver Name"] })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Trailer #"], count: item?.["Count of Completed Moves"] })),
    
    [REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Chassis #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Truck #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"], ...item })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Trailer #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),
    [REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),

    [REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),
};

  return transformers[reportType] ? transformers[reportType]() : data;
};

// Function to check date range
export function checkDateRange(startDate, endDate, isDefultLabel = 'Custom Range') {
  const start = moment(startDate);
  const end = moment(endDate);
  const userTimeZone = getTimeZone();
  const dateRange = DATE_RANGE() || {}
  for (const [rangeName, [rangeStart, rangeEnd]] of Object.entries(dateRange)) {
    if (moment(start).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeStart).format("DD/MM/YYYY") && moment(end).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeEnd).format("DD/MM/YYYY")) {
      return rangeName;
    }
  }
  return isDefultLabel;
}

// Function to download CSV from buffer
export const downloadCsvFromBuffer = (csvBuffer = '', fileName = 'fileName') => {
  if (!csvBuffer) {
    return toastr.show('No data to download', 'error')
  };

  try {
    // Convert the buffer to a Blob
    const csvBlob = new Blob([csvBuffer], { type: 'text/csv' });

    // Create a URL for the Blob and set up a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvBlob);
    link.download = `${fileName}.csv`;

    // Append to body, trigger download, and remove link immediately after
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free up memory
    setTimeout(() => URL.revokeObjectURL(link.href), 100);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

// Function to get data for chart from DB
export const getDataForChartFromDB = async (payload, reportType) => {
  let responseData = {}
  const timeZone = getTimeZone();

  payload.fromDate = moment(payload.fromDate).tz(timeZone).format('MM/DD/YYYY')
  payload.toDate = moment(payload.toDate).tz(timeZone).format('MM/DD/YYYY')

  if([...TRACK_CHARTS, ...EQUIPMENT_CHARTS, ...SYSTEM_CHARTS, ...EMPLOYEE_CHARTS]?.includes(reportType)) {
    delete payload.filter
  }

  /**
   * Tracks the download of a report using Amplitude.
   * 
   * @param {Object} payload - The payload containing report details.
   * @param {String} reportType - The type of report being downloaded.
   */
  if (payload?.isDownload) {
    // Find the dashboard name based on the report type
    const dashboardName = Object.keys(DASHBOARDS_CHARTS_LIST).find((dashboard) => DASHBOARDS_CHARTS_LIST[dashboard]?.includes(reportType));
    // Prepare event properties for Amplitude tracking
    const eventProperties = {
      dashboard_name: dashboardName,
      chart_name: reportType,
      // Include filters if payload has any keys (excluding 'isDownload' and 'filter')
      ...(Object.keys(payload).filter(key => key !== 'isDownload' && key !== 'filter').length && {filters: JSON.stringify(payload)}),
      // Include filter properties directly if 'filter' is present in payload
      ...payload.filter,
      // Include dates if present in payload
      ...(payload?.fromDate && {fromDate: payload?.fromDate}),
      ...(payload?.toDate && {toDate: payload?.toDate})
    };
    // Track the download event
    amplitudeTrack("DOWNLOAD_REPORT", eventProperties);
    payload.pagination_limit = 5000
  }

  

  if (ADMIN_CHARTS?.includes(reportType)) { 
    responseData = await getAdminChartCounts(payload)
  }

  if (FINANCIALS_CHARTS?.includes(reportType)) {
    responseData = await getFinancialsChartCounts(payload)
  }

  if (OPERATIONS_CHARTS?.includes(reportType)) {
    responseData = await getOperatinsChartCounts(payload)
  }

  if (CUSTOMER_SERVICE_CHARTS?.includes(reportType)) {
    responseData = await getCustomerServiceChartCounts(payload)
  }

  if (SYSTEM_CHARTS?.includes(reportType)) {
    responseData = await getSystemChartCounts(payload)
  }

  if (BROKER_CHARTS?.includes(reportType)) {
    responseData = await getBrokerChartCounts(payload)
  }
  
  if (EMPLOYEE_CHARTS?.includes(reportType)) {
    responseData = await getEmployeeChartCounts(payload)
  }

  if (CUSTOMER_CHARTS?.includes(reportType)) {
    responseData = await getCustomerChartCounts(payload)
  }

  if (EQUIPMENT_CHARTS?.includes(reportType)) {
    responseData = await getEquipmentChartCounts(payload)
  }
  if (TRACK_CHARTS?.includes(reportType)) {
    responseData = await getTrackChartCounts(payload)
  }

  if (responseData && !payload?.isDownload && Object.keys(responseData)?.length > 0) {
    responseData.reportData = convertDataForChart(responseData?.reportData, reportType, payload?.isTableData)
    if(!payload?.isTableData) responseData = {
      ...responseData,
      reportData: responseData?.reportData?.filter(item => item && ( !_.isNumber(item?.count || 0) || (item?.count) > 0 ))
  }
  }
  return responseData
}

// Function to map type of load data
export const typeOfLoadDataMapper = (data, payloadObjKey1, payloadObjKey2, responseObjKey3, responseObjKey4 = "count", reportName, payloadObjKey5, responseObjKey6) => {
  const transformedData = []
  data.forEach(item => {
    const transformedObj = {}
    let type;
    switch (item[payloadObjKey1]) {
      case 'IMPORT':
        type = 'Import';
        break;
      case 'EXPORT':
        type = 'Export';
        break;
      case 'ROAD':
        type = 'Road';
        break;
      case 'BILL_ONLY':
        type = 'Bill';
        break;
      default:
        type = item.type;
    }
    transformedObj[responseObjKey3] = type
    transformedObj[responseObjKey4] = item[payloadObjKey2]  
    if(reportName === REPORT_NAMES?.FINANCIAL_REVENUE_BY_LOAD_TYPE){
      transformedObj[responseObjKey6] = item[payloadObjKey5]
    }
    transformedData?.push(transformedObj);
  })
  return transformedData
}

// Function to get rows of IndexDB
export const getRowsOfIndexDB = (inputObj, regex) => {
  const financialKeys = [];
  for (const key in inputObj) {
    if (key?.startsWith(regex)) {
      financialKeys.push(key);
    }
  }

  return financialKeys;
}

// Function to format number
export const formatNumber = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Function to get data keys for stacked bar chart
export const getDataKeysForStackedBarChart = (data, keysForStackedBarChart) => {
  if (!data || !Array.isArray(data) || data.length === 0 || !data[0]) {
      return [];
  }
  const keys = Object.keys(data[0]);
  return keys.filter(key => keysForStackedBarChart.includes(key));
};

// Function to format time zone string
export const formatTimeZoneString = (str) => {
  return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

// Function to fix number
export const fixedNumer = (number = 0) => {
  let average = true
  if (number < 1000) {
    average = false
  }
  return numbro(number).format({
    average: average,
    mantissa: 2,
    optionalMantissa: false
  })?.replace('k', 'K').replace('m', 'M').replace('b', 'B').replace('t', 'T')
}

// Function to convert filter data to string array
export const convertFilterDataToStringArray = (filterData) => {
  const result = {};
  Object.keys(filterData || {}).forEach((key) => {
    if (filterData[key]?.length > 0) {
      if(['consignee', 'shipper', 'empty_origin'].includes(key)) {
        let customerIds = []
        filterData[key].forEach((item)=> {
          if(item?.type === 'groupedProfile') {
            const allIds = item?.allInfos?.customers?.map((p) => p?._id);
            if(allIds?.length) customerIds.push(...allIds)
          } else {
            customerIds.push(item?.value)
          }
        })
        result[key] = customerIds || []
      } else if(key === 'customer') {
        result[key] = _.uniq(filterData[key]?.map(item => item?.value).flat())
      } else { 
        result[key] = filterData[key]?.map(item => item?.value);
      }
    } else if((typeof filterData[key] === "boolean" && filterData[key])) {
      result[key] = filterData[key]
    } else if(typeof filterData[key] === "object" && Object.keys(filterData[key] || {})?.length) {
      result[key] = filterData[key]['value'] || ""
    }
  });
  return result;
}

// Function to convert chart filter data to string array
export const convertChartFilterDataToStringArray = (filterData, chartLevelFilterData = {}) => {
  const result = {};
  Object.keys({ ...filterData, ...chartLevelFilterData } || {}).forEach((key) => {
    if(Array.isArray(filterData?.[key]) || Array.isArray(chartLevelFilterData?.[key])){
      const data = [...(filterData?.[key] || []), ...(chartLevelFilterData?.[key] || [])].filter(val => val !== null && val !== undefined);
      result[key] =  _.uniqBy(data, 'value');
    }
  });
  return result;
}

export const getGroupProfiles = (measureUnits) => {

  const mapGroupedData = (data, type) => {
    return data?.map(item => ({
      label: item?.name,
      value: item?._id,
      type: type,
      allInfos: item
    }));
  };
  let groupedProfileOptions = []

  if (Object.keys(measureUnits || {}).length > 0) {
    if (measureUnits.groupedProfiles) {
      groupedProfileOptions = mapGroupedData(measureUnits?.groupedProfiles, 'groupedProfile');
    }
  }
  return { groupedProfileOptions }
}

const getCustomers = async () => {
  const payload = {
    carrier: getStorage("currentUserID"),
    isDeleted: false,
    limit: 10,
    sortBy: { createdAt: -1 }
  }
  const customers = await getCustomersByIDS(payload)
  const customersIds = customers.map(item => item?._id)
  return customersIds
}

// Function to get dashboard filter options
export const getDashboardFilterOptions = async (props) => {
  const {
    filterTitle,
    customers,
    callers,
    terminals,
    consignees,
    shippers,
    driverList,
    customerActions,
    allPublicUsers,
    statusType,
    containerType,
    containerOwner,
    containerSize,
    chassisOwners,
    chassisTypes,
    measureUnitsReducer,
    isChartLevelFilter,
    displayDispatcherFilter,
    filterValue
  } = props;
  let options = [];
  const addGroupedProfiles = displayDispatcherFilter && !isChartLevelFilter
  let allOption = {label: "All", value: 'all'}

  switch (filterTitle) {
    case DASHBOARD_CHARTS_FILTER_NAME?.customer:
      let customerIds =[]
      if(!(filterValue?.some(value => value.label === NEWEST_10_CUSTOMERS_LABEL)) || filterValue === null || filterValue?.length === 0){
        customerIds = await getCustomers()
      }else{
        customerIds = filterValue?.find(value => value.label === NEWEST_10_CUSTOMERS_LABEL)?.value
      }
      if (callers?.length > 0) {
        options = [{ label: NEWEST_10_CUSTOMERS_LABEL, value: customerIds?.length > 0 ? customerIds : [] }, ...makeOptionsForSelect(callers, "company_name", "address.address", "_id")];
      } else {
        options = [];
      }
      break;

    case DASHBOARD_CHARTS_FILTER_NAME?.terminal:
      if (terminals?.length > 0) {
        options = [allOption, ...makeOptionsForSelect(terminals, "name", null, "_id")];
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.consignee:
      if (consignees?.length > 0) {
        const { groupedProfileOptions } = addGroupedProfiles ? getGroupProfiles(measureUnitsReducer) : {}
        options = makeOptionsForSelect(consignees, "company_name", "address.address", "_id", addGroupedProfiles);
        if(addGroupedProfiles){
          options = [...(options || []), ...(groupedProfileOptions || [])]
        }
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.shipper:
      if(shippers?.length){
        const { groupedProfileOptions } = addGroupedProfiles ? getGroupProfiles(measureUnitsReducer) : {}
        options = makeOptionsForSelect(shippers, "company_name", "address.address", "_id", addGroupedProfiles);
        if(addGroupedProfiles){
          options = [...(options || []), ...(groupedProfileOptions || [])]
        }
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.empty_origin:
      if (customers?.length > 0) {
        const { groupedProfileOptions } = addGroupedProfiles ? getGroupProfiles(measureUnitsReducer) : {}
        options = makeOptionsForSelect(customers, "company_name", "address.address", "_id", addGroupedProfiles);
        if(addGroupedProfiles){
          options = [...(options || []), ...(groupedProfileOptions || [])]
        }
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.type_of_load:
      options = [allOption, ...LOAD_TYPES]
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.route:
      options = [
        { label: "Local", value: "Local" },
        { label: "Highway", value: "Highway" }
      ];
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.driver:
      options = driverList?.map((d) => ({
        value: d._id,
        label: `${d.name} ${d.lastName}`.trim(),
        allInfo: d,
      }));
      break;
    case DASHBOARD_CHARTS_FILTER_NAME.employee:
      if (customerActions) {
        let data = { active: true };
        await customerActions.listAllFleetManagers(data, 'fromCsr').then((options2) => {
          options = (options2 || []).map((obj) => ({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          }));
        });
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.addedBy:
      options = [allOption, ...allPublicUsers?.filter((d, i) => {
        if (d?.isDeleted === false) {
          return ["carrier", "fleetmanager"].indexOf(d?.role) > -1;
        }
      }).map((d) => {
        return {
          value: d._id,
          label: `${d.name} ${d.name.includes(d.lastName) ? "" : d.lastName
            }`.trim(),
          allInfo: d,
        };
      })];
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.status:
      options = [allOption, ...statusType?.filter(e => !STATUSES_NOT_INCLUDED_IN_FILTER.includes(e.value))]
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.containerType:
      options = [allOption, ...makeOptionsForSelect(containerType, "name", null, "_id")]
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.containerSize:
      options = [allOption, ...makeOptionsForSelect(containerSize, "name", null, "_id")]
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.containerOwner:
      options = makeOptionsForSelect(containerOwner, "company_name", null, "_id")
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.assignedCSR:
      if (customerActions) {
        await customerActions.listAllFleetManagers({ CSR: true }, 'fromCsr').then((data) => {
          options = [allOption, ...(data || [])?.map((obj) => ({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          }))];
        });
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.routingEventStatus:
      options = getRoutingEvents()
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.preSetOrderStatus:
      options = getPreSetOrderStatus()
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.chassisType:
      options = [allOption, ...makeOptionsForSelect(chassisTypes, "name", null, "_id")];
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.chassisOwner:
      options = [allOption, ...makeOptionsForSelect(chassisOwners, "company_name", null, "_id")];
      break;
    default:
      options = [];
      break;
  }
  return options;
};

export const getDashboardFilterLoadOptions = async (filterTitle, e, filterOptions, extraOptions) => {
  const {addGroupedProfiles, groupedProfilesOptions } = extraOptions
  if ([
    DASHBOARD_CHARTS_FILTER_NAME?.customer,
    DASHBOARD_CHARTS_FILTER_NAME?.consignee,
    DASHBOARD_CHARTS_FILTER_NAME?.shipper,
    DASHBOARD_CHARTS_FILTER_NAME?.empty_origin,
  ].includes(filterTitle)) {
    if(addGroupedProfiles){
      const data = await getCustomerCityAsyncSearch(e, groupedProfilesOptions)
      return data?.filter(item => !['city','state','country', 'zipCode'].includes(item?.type))
    }
    return await getCustomerAsyncSearch(e);
  } else {
    return filterOptions?.filter((i) => i?.label?.toLowerCase()?.includes(e?.toLowerCase())) || [];
  }
};

export const validChartFilters = (dashboardName, reportName) => {
  const isTerminalAccount = showForTerminal()
  const commonCustomerServiceFiter = [
    "customer",
    "consignee",
    "shipper",
    "empty_origin",
    "route",
    "type_of_load",
    ...(isTerminalAccount ? ["terminal"] : []),
    "driver",
  ]
  const chartFilters = {
    [DASHBOARDS.CUSTOMER_SERVICE]: {
      [REPORT_TYPES.CUSTOMER_SERVICE_EMPLOYEE_UPDATED_PER_LOAD]:[
        ...commonCustomerServiceFiter,
        "employee"
      ],
      [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_ASSIGNED_TO_CSR]:[
        ...commonCustomerServiceFiter,
        "assignedCSR"
      ],
      [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED]:[
        ...commonCustomerServiceFiter,
        "addedBy"
      ],
    }
  }
  return chartFilters?.[dashboardName]?.[reportName] || []
}

export const getDashboardFilters = (dashboardName, dashboardLevelFilter = false, reportType) => {
  const CHART_LEVEL_FILTER = {
    [DASHBOARDS.FINANCIALS]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
    ],
    [DASHBOARDS.BROKER]: [
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
    ],
    [DASHBOARDS.CUSTOMER]: [
      "customer"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
      "employee",
      "addedBy",
      "assignedCSR"
    ],
    [DASHBOARDS.OPERATIONS]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
    ],
    [DASHBOARDS.ADMIN]: [
      "type_of_load",
      "customer",
      "shipper",
      "consignee",
      "empty_origin",
      ...(showForTerminal() ? ["terminal"] : []),
      "route"
    ],
    [DASHBOARDS.MY_DASHBOARD]: getMyDashboardFilters(reportType)
  };

  const DASHBOARD_LEVEL_FILTER = {
    [DASHBOARDS.FINANCIALS]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
    ],
    [DASHBOARDS.BROKER]: [
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
    ],
    [DASHBOARDS.CUSTOMER]: [
      "customer"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
      "type_of_load",
      "customer",
      "shipper",
      "consignee",
      "empty_origin",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
      "addedBy",
      "status",
      "containerType",
      "containerSize",
      "containerOwner",
      "chassisType",
      "chassisOwner",
      "assignedCSR",
      "routingEventStatus",
      "preSetOrderStatus",
      "isReadyForPickup"
    ],
    [DASHBOARDS.OPERATIONS]: [
      "type_of_load",
      "customer",
      "shipper",
      "consignee",
      "empty_origin",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
      "addedBy",
      "status",
      "containerType",
      "containerSize",
      "containerOwner",
      "chassisType",
      "chassisOwner",
      "assignedCSR",
      "routingEventStatus",
      "preSetOrderStatus",
      "isReadyForPickup"
    ],
    [DASHBOARDS.ADMIN]: [
      "type_of_load",
      "customer",
      "shipper",
      "consignee",
      "empty_origin",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
      "addedBy",
      "status",
      "containerType",
      "containerSize",
      "containerOwner",
      "chassisType",
      "chassisOwner",
      "assignedCSR",
      "routingEventStatus",
      "preSetOrderStatus",
      "isReadyForPickup"
    ],

  }
  return dashboardLevelFilter ? (DASHBOARD_LEVEL_FILTER?.[dashboardName] || []) : (CHART_LEVEL_FILTER?.[dashboardName] || [])
}

export const getBooleanFilter = (dashboardName) => {
  const DASHBOARD_BOOLEAN_FILTERS = {
    [DASHBOARDS.ADMIN]: [
        "isReadyForPickup"
    ],
    [DASHBOARDS.OPERATIONS]: [
        "isReadyForPickup"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
        "isReadyForPickup"
    ]
  }
  return DASHBOARD_BOOLEAN_FILTERS?.[dashboardName] || []
}

export const removeExtraFilter = (filters, dashboardName, chartName) => {
  const result = {}
  let dashBoardFilterKeys = getDashboardFilters(dashboardName,false)
  if(dashboardName === DASHBOARDS.CUSTOMER_SERVICE){
    dashBoardFilterKeys = validChartFilters(dashboardName, chartName)
  }
  Object.keys(filters || {})?.forEach((key)=> {
    const chartFilters = filters[key]
    Object.keys(chartFilters || {})?.forEach((filterKey)=> {
      if(dashBoardFilterKeys?.includes(filterKey)){
        if(!result[key]){
          result[key]={}
        }
        result[key][filterKey] = chartFilters[filterKey]
      }
    })
  })
  return result
}

export const getMyDashboardChartsProps = (charts) => {
  const reportProps = {}
  charts.forEach(chart => {
    if(ADMIN_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS[chart]
    if(BROKER_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_BROKER[chart]
    if(CUSTOMER_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_CUSTOMER[chart]
    if(CUSTOMER_SERVICE_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_CUSTOMER_SERVICE[chart]
    if(EMPLOYEE_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_EMPLOYEE[chart]
    if(FINANCIALS_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_FINANCIAL[chart]
    if(OPERATIONS_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_OPERATIONS[chart]
    if(SYSTEM_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_SYSTEM[chart]
    if(TRACK_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_TRACK[chart]
    if(EQUIPMENT_CHARTS.includes(chart)) reportProps[chart] = REPORT_PROPS_EQUIPMENT[chart]
  })
  return reportProps;
}

export const getMyDashboardFilters = (reportType) => {
  let dash;
  for (const key in DASHBOARDS_CHARTS_LIST) {
    if (Array.isArray(DASHBOARDS_CHARTS_LIST[key]) && DASHBOARDS_CHARTS_LIST[key].includes(reportType)) {
      dash = key;
      break;
    }
  }
  if (dash) {
    return getDashboardFilters(dash);
  }
};

export const getMyDashboardChartConfig = (x, y, w, h, reportType, index) => {
  const chartConfig = {
    x: index * x,
    y: y,
    w: w,
    h: h,
    chartName: reportType,
    dashboardName: TAB_NAMES[Object.keys(DASHBOARDS_CHARTS_LIST).find((dashboard) => DASHBOARDS_CHARTS_LIST[dashboard]?.includes(reportType))],
  };
  return chartConfig;
};

export const getMyDashboardComponents = (charts) => {
  const components = [];
  const counterCardCharts = charts.filter((chart) => COUNTER_CARDDS_CHARTS.includes(chart));
  const fullCharts = charts.filter((chart) => !COUNTER_CARDDS_CHARTS.includes(chart));
  counterCardCharts.forEach((chart, index) => {
    const _index = index % 5;
    if (index < 5) {
      components.push(getMyDashboardChartConfig(3, 0, 3, 1, chart, _index));
    }

    if (index < 10 && index >= 5) {
      components.push(getMyDashboardChartConfig(3, 1, 3, 1, chart, _index));
    }

    if (index < 15 && index >= 10) {
      components.push(getMyDashboardChartConfig(3, 2, 3, 1, chart, _index));
    }

    if (index < 20 && index >= 15) {
      components.push(getMyDashboardChartConfig(3, 3, 3, 1, chart, _index));
    }
  });

  fullCharts.forEach((chart, index) => {
    let y = 0;
    const _index = index % 3;
    if (counterCardCharts?.length < 5) {
      y = 1;
    }

    if (counterCardCharts?.length < 10 && counterCardCharts?.length >= 5) {
      y = 2;
    }

    if (counterCardCharts?.length < 15 && counterCardCharts?.length >= 10) {
      y = 3;
    }

    if (counterCardCharts?.length < 20 && counterCardCharts?.length >= 15) {
      y = 4;
    }

    if (index < 3) {
      components.push(getMyDashboardChartConfig(5, y, 5, 3, chart, _index));
    }

    if (index < 6 && index >= 3) {
      components.push(getMyDashboardChartConfig(5, y + 3, 5, 3, chart, _index));
    }

    if (index < 9 && index >= 6) {
      components.push(getMyDashboardChartConfig(5, y + 6, 5, 3, chart, _index));
    }

    if (index < 12 && index >= 9) {
      components.push(getMyDashboardChartConfig(5, y + 9, 5, 3, chart, _index));
    }

    if (index < 15 && index >= 12) {
      components.push(getMyDashboardChartConfig(5, y + 12, 5, 3, chart, _index));
    }

    if (index < 18 && index >= 15) {
      components.push(getMyDashboardChartConfig(5, y + 15, 5, 3, chart, _index));
    }

    if (index < 20 && index >= 18) {
      components.push(getMyDashboardChartConfig(5, y + 18, 5, 3, chart, _index));
    }
  });

  return components;
};

export const hasChartFilters = (reportType) => {
  let dash;
  for (const key in DASHBOARDS_CHARTS_LIST) {
    if (Array.isArray(DASHBOARDS_CHARTS_LIST[key]) && DASHBOARDS_CHARTS_LIST[key].includes(reportType)) {
      dash = key;
      break;
    }
  }
  if (dash) {
    return DASHBOARD_FILTER_APPLIES?.includes(dash);
  }
  return false;
};

export const isMyDashboardChartsPositionChanged=(chartsSetup,myDashboardCharts)=>{
  const normalize = (array) => {
    return array?.map((item) => {
      // Remove _id and return the normalized object
      const { _id, ...normalizedItem } = item;
      return normalizedItem;
    });
  };

  const component = getMyDashboardComponents(myDashboardCharts);

  return !_.isEqual(normalize(chartsSetup), normalize(component));
}

export const TypeDashBoardContext = createContext();
