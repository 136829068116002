import React from "react";
import { IconProblem } from "Components/Common/Icons";
import { useHover } from "hooks";
import CustomTooltip from "Components/Common/CustomTooltip";
import { browserHistory } from "react-router";
import { LOAD_STATUSES } from "constants/tms.constant";

const ProblemContainerWarning = ({ row }) => {
  const [hoverRef, isHovering] = useHover();

  const handleClick = () => {
    // Navigate to problem container page with reference_number as query parameter
    browserHistory.push(`/tms/customer-service/problem-container?searchTerm=${encodeURIComponent(row?.reference_number)}`);
  };

  // Check if we should show the warning
  const showWarning =
    row &&
    ((row.loadTracks?.caution && row.loadTracks?.errorMessage) ||
    (row.caution && row.errorMessage)) &&
    (row.status === LOAD_STATUSES.PENDING || row.status === LOAD_STATUSES.AVAILABLE);

  return (
    <div className="cell-content text-center">
      {showWarning ? (
        <span
          ref={hoverRef}
          className="pointer"
          onClick={handleClick}
          onKeyDown={(e) => e.key === "Enter" && handleClick()}
          role="button"
          tabIndex={0}
        >
          <IconProblem className="text-danger" />
          {isHovering && (
            <CustomTooltip isOpen={isHovering} refNo={hoverRef.current} placement="top" offset={[0, -50]}>
              <span className="text-nowrap">{row?.loadTracks?.errorMessage || row?.errorMessage}</span>
            </CustomTooltip>
          )}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProblemContainerWarning;
