import _ from "lodash";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";

import configuration from "../../../../../../config";
import { getPeople } from "pages/tms/People/actionCreator";
import { isEmbeddedEmailConnected } from "pages/tms/Email/helper";
import { isNewCustomerRateEnabled, isZipCodeForQuotesEnabled } from "Components/Common/functions";
import { defaultQuoteDetails, ENUM, moveLegs, pricingItems } from "../constant";
import { getTotalEmailCountByQuoteId } from "pages/tms/Email/actionCreator";
import { getAddEditQuotePayload, getCustomerRateParams, getFilterLocationPayload } from "../utilities";
import { getCommodityProfile, getTMSshipper } from "pages/tms/Dispatcher/actionCreators";
import { getV1LocationDistance, getQuotePricing, getQuotePricingFromTariff } from "pages/tms/services";
import { CUSTOMER_RATE_RECORD } from "pages/tms/Load/Billing/Components/BillingCard/Charges/constant";
import { amplitudeTrack, isCustomerAccount, isEmbeddedEmailEnabled, makeOptionsForSelectPeople } from "../../../../../../services";
import { addressFormatEnum, defaultAddressFormat, getCustomerCurrency, getStorage, toastr, types } from "services";
import { addLoadQuoteSettings, editLoadQuoteSettings, getChassisType, getLoadQuoteSettingsById, getTerminal } from "../../../../services";
import { getPlaceDetails, searchPlaces } from "pages/tms/Customer/actionCreators";
import { getCustomerCityAsyncSearch, getCustomerCityWithCountryCodeAsyncSearch } from "pages/tms/NewDispatcher/actionCreator";
import { convertResultToSelectWithStatus } from "pages/tms/Load/DriverOrderUtility";

export const useAddEditQuote = ({ editId, onClose, onSave }) => {
    const [isMail, setIsMail] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [terminals, setTerminals] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    const [commodities, setCommodities] = useState([]);
    const [quoteDetails, setQuoteDetails] = useState({});
    const [selectedCell, setSelectedCell] = useState(null);
    const [totalMailCount, setTotalMailCount] = useState(0);
    const [invoiceCurrency, setInvoiceCurrency] = useState([]);
    const [isFetchingPricing, setIsFetchingPricing] = useState(false);

    const currentChangeFieldKey = useRef(null);
    const { containerReducer, chassisReducer, chargeCodeReducer } = useSelector((state) => state);

    const { chassisType } = chassisReducer;
    const { chargeCode } = chargeCodeReducer;
    const { containerType, containerSize } = containerReducer;
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings"));

    useEffect(() => {
        if (editId) {
            setIsFetchingPricing(true);

            getLoadQuoteSettingsById(editId).then((res) => {
                const data = res?.data?.data;

                setQuoteDetails({
                    ...data,
                    shipper: data?.shipper?.map((d) => ({...d ?? {}, parameterProfile: d})) ?? [],
                    consignee: data?.consignee?.map((d) => ({...d ?? {}, parameterProfile: d})) ?? [],
                    emptyOrigin: data?.emptyOrigin?.map((d) => ({...d ?? {}, parameterProfile: d})) ?? [],
                    quoteNotes: data?.notes,
                    selectedTerminal: data?.terminal
                });

                if(!isCustomerAccount() && isEmbeddedEmailEnabled()) fetchTotalMailCount(data?.quote);

            }).catch((err) => console.error(err)).finally(() => setIsFetchingPricing(false));
        }
    }, [editId]);

    useEffect(() => {
        fillTerminals();
        fillCommodities();
        setQuoteDetails(defaultQuoteDetails);

        if (isEmbeddedEmailEnabled()) fillPeopleList();
    }, []);

    useEffect(() => {
        if (moveLegs.includes(currentChangeFieldKey.current)) calculateTotalDistance();
    }, [quoteDetails])


    const fetchTotalMailCount = (quote = null) => {
        const param = {}
        if(!isEmbeddedEmailConnected()) param.skipMiddleWare = true
        if (quote || quoteDetails?.quote) getTotalEmailCountByQuoteId(param ?? {}, quote ?? quoteDetails?.quote).then((res) => {
            const { count } = res ?? {};

            setTotalMailCount(count ?? 0);
        }).catch((err) => console.error(err));

    }

    const fillTerminals = () => {
        if (
            (getStorage("currentUserRole") == "fleetmanager" && configuration.idForTerminal.indexOf(JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
            (getStorage("currentUserRole") == "carrier" && configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
        ) {
            getTerminal().then((response) => {
                let terminals = response.data.data ? response.data.data : [];
                terminals = terminals.map((terminal) => ({
                    label: terminal.name,
                    value: terminal._id,
                    allInfo: terminal,
                }));
                setTerminals(terminals);
            });
        }
    }

    const fillPeopleList = (params = {}) => {
        getPeople({ ...params, skip: 0, limit: 10, sortBy: { _id: 1 } }).then((responseData) => setPeopleList(responseData));
    }

    const fillCommodities = () => {
        if (JSON.parse(getStorage("measureUnits")).isCommodityProfileShow) {
            getCommodityProfile().then((response) => {
                let data = response.data.data ? response.data.data : [];
                let commodities = data.map((d) => {
                    if (d.isRestricted) return { label: d.name, value: d.name, isDisabled: true, allInfo: d };
                    else return { label: d.name, value: d.name, allInfo: d };
                });
                setCommodities(commodities);
            });
        }
    }

    const filterCounties = (country) => {
        return countries.filter((i) => i?.label?.toLowerCase().includes(country?.toLowerCase()));
    };

    const getCurrency = (customer, branch) => {
        const currency = getCustomerCurrency(customer, branch);
        if (currency) {
          setInvoiceCurrency(currency);
          setQuoteDetails((prevQuoteDetails) => ({
            ...prevQuoteDetails,
            invoiceCurrency: currency,
            invoiceCurrencyDetail: currency,
          }));
        } else {
          setInvoiceCurrency([]);
          setQuoteDetails((prevQuoteDetails) => ({
            ...prevQuoteDetails,
            invoiceCurrency: defaultQuoteDetails?.invoiceCurrency,
            invoiceCurrencyDetail: defaultQuoteDetails?.invoiceCurrency,
          }));
        }
    }

    const getDebouncedPeoples = (inputValue, callback) => {
        getPeople({ skip: 0, limit: 10, sortBy: { _id: 1 }, searchTerm: inputValue }).then((responseData) => {
            const mappedPeopleOptions = [];
            responseData?.forEach((singlePeople) => {
                mappedPeopleOptions.push({
                    value: singlePeople?._id,
                    label: `${singlePeople?.firstName ? singlePeople?.firstName : ""} ${singlePeople?.lastName ? singlePeople?.lastName : ""
                        }`?.trim(),
                    allInfos: singlePeople,
                });
            });
            return callback(mappedPeopleOptions);
        });
    };

    const getDebouncedShippers = (inputValue, callback) => {
        getTMSshipper(inputValue, true).then((results) => callback(results));
        return;
    };

    const getChassisTypes = (searchTerm = "") => {
        return new Promise((resolve, reject) => {
            getChassisType({ searchTerm, limit: 10 })
                .then((result) => {
                    let data = result.data.data;
                    data = data.map((d) => ({ value: d._id, label: d.name, allInfos: d }));
                    resolve(data);
                }).catch((error) => reject(error));
        });
    }

    const getCustomerRates = () => {
        const { consignee } = quoteDetails;

        if (consignee.length === 0) {
            toastr.show("Please select delivery location.", "error");
            return;
        }

        setIsFetchingPricing(true);

        const params = getCustomerRateParams(quoteDetails);

        if (isNewCustomerRateEnabled()) {
            getQuotePricingFromTariff(params).then((responseData) => {
                if (responseData.data && responseData.data.statusCode === 200) {
                    const customerRates = responseData?.data?.data;
                    const existingPricing =
                        quoteDetails.pricing?.filter(
                            (singlePricing) => singlePricing?.chargeType !== CUSTOMER_RATE_RECORD
                        ) ?? [];
                    const updatePricing = [...existingPricing, ...(customerRates ?? [])];
                    handleChange('pricing', updatePricing)
                }
                setIsFetchingPricing(false);
            }).catch((err) => setIsFetchingPricing(false));

            return;
        }

        getQuotePricing(params).then((data) => {
            if (data && data.data && data.data.statusCode === 200) {
                let customerRates = data.data.data && data.data.data.loadPricing;
                this.setState({ pricing: customerRates });
            }
            setIsFetchingPricing(false);
        }).catch((err) => setIsFetchingPricing(false));
    }

    const calculateTotalDistance = () => {
        const { shipper, consignee, emptyOrigin } = quoteDetails;
        let params = {}

        params.shipper = getFilterLocationPayload(shipper);
        params.consignee = getFilterLocationPayload(consignee);
        params.emptyOrigin = getFilterLocationPayload(emptyOrigin);

        if ((params?.consignee?.length > 0 || (params?.zipCode || (params?.city && params?.state))) && params?.shipper?.length > 0) {
            setIsSaving(true);
            setIsFetchingPricing(true);
            getV1LocationDistance(params).then((data) => {
                if (data && data.data && data.data.statusCode === 200) {
                    let totalMiles = data.data && data.data.data || 0;
                    handleChange('totalMiles', parseFloat((totalMiles).toFixed(2)));
                }

                setIsSaving(false);
                setIsFetchingPricing(false);
            }).catch((err) => {
                setIsSaving(false);
                setIsFetchingPricing(false);
            });
        } else {
            handleChange('totalMiles', 0);
        }

    }

    const handleAddEditLoadQuoteSettings = useCallback(() => {
        const { shipper, numberofLoad, type_of_load, validUpTo, consignee } = quoteDetails;

        if (!type_of_load || type_of_load.length === 0) {
            toastr.show("Please select a load type.", "error");
            return;
        }
        if (!shipper || shipper.length === 0) {
            toastr.show("Please select pick up location.", "error");
            return;
        }
        if (!numberofLoad || numberofLoad.length === 0) {
            toastr.show("Please select the number of loads this quote is good for.", "error");
            return;
        }
        if (consignee.length === 0) {
            toastr.show("Please select delivery location.", "error");
            return;
        }
        if(!isCustomerAccount() && !validUpTo){
            toastr.show("Valid Upto date is required!.", "error");
            return;
        }

        const payload = getAddEditQuotePayload(quoteDetails);
        setIsSaving(true);

        const apiCall = editId ? editLoadQuoteSettings(payload) : addLoadQuoteSettings(payload);

        if (apiCall) apiCall.then((data) => {
            setIsSaving(false);
            if (data && data.data && data.data.statusCode === 200) {
                toastr.show(data.data.message, "success");
                setActiveTab(1);
                setQuoteDetails({ ...defaultQuoteDetails });

                if(onSave) onSave({ quoteNumber: data?.data?.data?.quote, ...data?.data?.data ?? {} });
                if (onClose) onClose({ quoteNumber: data?.data?.data?.quote, quoteId: data?.data?.data?._id });
            } else {
                if (data && data.data && data.data.statusCode !== 400) toastr.show(data.data.message, "success");
            }
            amplitudeTrack( `${editId ? "EDIT" : "ADD_NEW"}_QUOTE_BUTTONE`,{source: 'quotes'})
        });
    }, [quoteDetails]);

    const handleUnselectCell = () => setSelectedCell(null);

    const handleSelectCell = (name) => {
        if (selectedCell === name) return
        setSelectedCell(name);
    }
    
    const reCalculatePricing = (charges, index) => {
        return charges.map(d => {
            if ([d?.perType, d?.type].includes("percentage")) {
                let totalBasePriceFinalAmount = 0;
                const percentageOf = d?.percentageOf;
                const totalBasePriceCharges = charges.filter(e => e?.name === "Base Price"  && charges?.[index]?.name !== "Base Price");


                if (!percentageOf?.length) totalBasePriceFinalAmount = totalBasePriceCharges?.sum(e => parseFloat(e?.finalAmount));

                if (percentageOf?.length > 0) {
                    let filterChargeByName = [];

                    if(percentageOf?.some((d => /ALL/i.test(d)))) filterChargeByName = charges?.filter(charge => charge?.name != d?.name);
                    else filterChargeByName = charges?.filter(charge => percentageOf?.includes(charge?.name));

                    filterChargeByName?.forEach((charge) => totalBasePriceFinalAmount += parseFloat(charge?.finalAmount ?? 0));
                }

                d["chargePerDay"] = totalBasePriceFinalAmount;
                d["finalAmount"] = (d["amount"] * (totalBasePriceFinalAmount ?? 0)) / 100;
                d["manualFinalAmount"] = d["finalAmount"];
                return d;
            }

            return d;
        });

    }

    const handlePricingchanges = (index, e) => {
        const { name, value } = e.target;

        let arr = ["amount", "finalAmount"];
        let old = [...quoteDetails?.pricing ?? []];

        old[index][name] = arr.includes(name) ? value : value;
        
        let totalBasePriceFinalAmount = 0;
        const percentageOf = old[index]?.percentageOf;
        const totalBasePriceCharges = old.filter(d => d?.name === "Base Price" && old[index]?.name !== "Base Price");

        if(!percentageOf?.length) {
            totalBasePriceFinalAmount = totalBasePriceCharges?.sum(d => parseFloat(d?.finalAmount));
        }

        if(percentageOf?.length > 0) {
            percentageOf?.forEach((chargeName) => {
                const filterChargeByName = old?.filter(charge => charge?.name === chargeName);
                filterChargeByName?.forEach((charge) =>  totalBasePriceFinalAmount += parseFloat(charge?.finalAmount ?? 0));
            });
        }

        if(value === "Fuel") {
            old[index]["perType"] = "fixed";
            old[index]["finalAmount"] = (old[index]["amount"] * (totalBasePriceFinalAmount ?? 0)) / 100;
            old[index]["manualFinalAmount"] = old[index]["finalAmount"];
        } else if (name === "type") {
            old[index]["perType"] = value;
            old[index]["finalAmount"] = old[index]["type"] !== "percentage" ? old[index]["amount"] : (old[index]["amount"] * (totalBasePriceFinalAmount ?? 0)) / 100;
            old[index]["manualFinalAmount"] = old[index]["finalAmount"];
        } else if (name === "amount") {
            if(old[index]?.type === "percentage") old[index]["unit"] = value;
            old[index]["chargePerDay"] = totalBasePriceFinalAmount;
            old[index]["finalAmount"] = old[index]["type"] !== "percentage" ? old[index]["amount"] : (old[index]["amount"] * (totalBasePriceFinalAmount ?? 0)) /100;
            old[index]["manualFinalAmount"] = old[index]["finalAmount"];
        } else if (name === "finalAmount") {
            old[index]["manualFinalAmount"] = value;
        } else if (["unit", "chargePerDay", 'perType'].includes(name)) {
            if(name === "perType" && !["percentage"].includes(value) && old[index].name === "Fuel") {
                old[index]['type'] = value;
                old[index]["finalAmount"] = old[index]["amount"];
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            } else if(name === "perType" && !["percentage"].includes(value) && old[index].name !== "Fuel") {
                old[index]['type'] = value;
                old[index]["finalAmount"] = (parseFloat(old[index]["unit"]) * parseFloat(old[index]["chargePerDay"])).toFixed(2);
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            } else if (name === "perType" && value === "percentage" && old[index].name !== "Fuel") {
                old[index]["amount"] = 1;
                old[index]['type'] = value;
                old[index]["unit"] = old[index]["unit"] || 1;
                old[index]["chargePerDay"] = totalBasePriceFinalAmount;
                old[index]["finalAmount"] = (old[index]["amount"] * (totalBasePriceFinalAmount ?? 0)) / 100;
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            } else if (name === "perType" && value === "percentage" && old[index].name === "Fuel") {
                old[index]['type'] = value;
                old[index]["finalAmount"] = (old[index]["amount"] * (totalBasePriceFinalAmount ?? 0)) / 100;
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            } else if(["chargePerDay", "unit"].includes(name) && old[index].name !== "Fuel") {
                old[index]["finalAmount"] = (parseFloat(old[index]["unit"]) * parseFloat(old[index]["chargePerDay"])).toFixed(2);
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            } else if(old[index].name !== "Fuel"){
                old[index]['type'] = value;
                old[index]["finalAmount"] = totalBasePriceFinalAmount;
                old[index]["manualFinalAmount"] = old[index]["finalAmount"];
            }
        } else {
            old[index][name] = value;
        }
        old = reCalculatePricing(old, index);
        handleChange('pricing', [...old]);
    }

    const handleCommodityChanges = (index, e) => {
        const { name, value } = e.target;
        const old = [...quoteDetails?.commodity ?? []];

        if (name === 'weight') {
            old[index]["weightKgs"] = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
            old[index]["weight"] = value;
        } else if (name === 'weightKgs') {
            old[index]["weight"] = parseFloat((parseFloat(value) * 2.20462).toFixed(2));
            old[index]["weightKgs"] = value;
        } else {
            old[index] = {...old?.[index] ?? {}, [name]: value};
        }

        handleChange('commodity', [...old]);
    }

    const handleDeletePriceRow = (index) => {
        let data = [...quoteDetails?.pricing ?? []];
        data.splice(index, 1);

        handleChange('pricing', data);
    }

    const handleAddPriceRows = () => {
        handleChange('pricing', [...quoteDetails?.pricing ?? [], { ...pricingItems }]);
    }

    const handleAfterDisconnectMail = () => {
        fetchTotalMailCount();
    }

    const handleChange = useCallback((key, value) => {
        currentChangeFieldKey.current = key;
        if(key === "pricing")  value = reCalculatePricing(value);
        setQuoteDetails((prevQuoteDetails) => ({ ...prevQuoteDetails, [key]: value }));
    }, [quoteDetails]);

    const handleCloseModal = () => {
        setActiveTab(1);
        setQuoteDetails({ ...defaultQuoteDetails });
        onClose({});
    }

    const handleTabChange = (tabIndex) => setActiveTab(tabIndex);

    const searchPlacesAsync = (() => {
        const debouncedSearch = _.debounce((searchText, placeType, resolve, reject) => {
          searchPlaces({ searchTerm: searchText, types: placeType })
            .then(resolve)
            .catch(reject);
        }, 500);
      
        return (searchText, placeType) => {
          return new Promise((resolve, reject) => {
            debouncedSearch(searchText, placeType, resolve, reject);
          });
        };
      })();

    /**
     * Performs an address search based on the provided search term and options.
     * 
     * @param {string} searchTerm - The term to search for.
     * @param {Object} options - An object containing grouped options for profiles, cities, and zip codes.
     * @param {Object} additionalOptions - Additional options for the search.
     * @returns {Promise<Array>} A promise that resolves to an array of search results.
     */


    const onAddressSearch = useCallback(async (searchTerm = "", { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions }, options) => {
        
        const customerData = await getCustomerCityAsyncSearch(searchTerm, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions);

        return (customerData ?? []).map((place) => {
            const [city, state] = (place?.allInfos?.name ?? "").split(",").map(part => part.trim());
            let profileType = place?.allInfos?.type || place?.parameterProfile?.profileType || place?.type ;
            profileType = profileType === ENUM?.CITY ? ENUM?.CITY_STATE : profileType;
            const zipCode = profileType !== ENUM?.ZIP_CODE ? place?.allInfos?.postalCode : (place?.value ?? "");
          return{
            value: place?.value ?? place?.label,
            name: `${place?.allInfos?.name ?? place?.label}`,
            label: `${place?.allInfos?.name ?? place?.label}`,
            allInfos: place?.allInfos,
            parameterProfile: {
                name: `${place?.allInfos?.name ?? place?.label}`,
                company_name: `${place?.allInfos?.name ?? place?.label}`,
                profileGroup: place?.parameterProfile?.profileGroup ?? [],
                profileType: profileType, 
                profile: {
                    name: `${place?.allInfos?.name ?? place?.label}`,
                    country: place?.allInfos?.country ?? "",
                    zipCode: zipCode,                   
                    city: place?.allInfos?.city,
                    state: place?.allInfos?.state,
                    address1:place?.allInfos?.address1,
                    address:place?.allInfos?.address?.address,
                    ...(profileType !== ENUM?.ZIP_CODE && { city: city ?? "", state: state ?? "" }),
                    ...(![ENUM.ZIP_CODE, ENUM.ZIP_CODE_GROUP_PROFILE, ENUM.CITY_STATE, ENUM.CITY_GROUP_PROFILE].includes(profileType) && {
                        _id: place?.value ?? place?.allInfos?._id
                    })
                },
                ...(![ENUM.ZIP_CODE, ENUM.ZIP_CODE_GROUP_PROFILE, ENUM.CITY_STATE, ENUM.CITY_GROUP_PROFILE].includes(profileType) && {
                    _id: place?.value ?? place?.allInfos?._id
                })
            }
        }
        });
    }, []);  
    
    const onAddressSearchWithCountryCode = useCallback(async (searchTerm = "", { groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions }, options) => {
        
        const customerData = await getCustomerCityWithCountryCodeAsyncSearch(searchTerm, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions);

        return (customerData ?? []).map((place) => {
            const [city, state] = (place?.allInfos?.name ?? "").split(",").map(part => part.trim());
            let profileType = place?.allInfos?.type || place?.parameterProfile?.profileType || place?.type ;
            profileType = profileType === ENUM?.CITY ? ENUM?.CITY_STATE : profileType;
            const zipCode = profileType !== ENUM?.ZIP_CODE ? place?.allInfos?.postalCode : (place?.value ?? "");
          return{
            value: place?.value ?? place?.label,
            name: `${place?.allInfos?.name ?? place?.label}`,
            label: `${place?.allInfos?.name ?? place?.label}`,
            allInfos: place?.allInfos,
            parameterProfile: {
                name: `${place?.allInfos?.name ?? place?.label}`,
                company_name: `${place?.allInfos?.name ?? place?.label}`,
                profileGroup: place?.parameterProfile?.profileGroup ?? [],
                profileType: profileType, 
                profile: {
                    name: `${place?.allInfos?.name ?? place?.label}`,
                    country: place?.allInfos?.country ?? "",
                    zipCode: zipCode,                   
                    city: place?.allInfos?.city,
                    state: place?.allInfos?.state,
                    address1:place?.allInfos?.address1,
                    address:place?.allInfos?.address?.address,
                    ...(profileType !== ENUM?.ZIP_CODE && { city: city ?? "", state: state ?? "" }),
                    ...(![ENUM.ZIP_CODE, ENUM.ZIP_CODE_GROUP_PROFILE, ENUM.CITY_STATE, ENUM.CITY_GROUP_PROFILE].includes(profileType) && {
                        _id: place?.value ?? place?.allInfos?._id
                    })
                },
                ...(![ENUM.ZIP_CODE, ENUM.ZIP_CODE_GROUP_PROFILE, ENUM.CITY_STATE, ENUM.CITY_GROUP_PROFILE].includes(profileType) && {
                    _id: place?.value ?? place?.allInfos?._id
                })
            }
        }
        });
    }, []); 

    const onAddressSearchWithStatus = async (searchTerm = "", groupOptions, options) => {
        try {
            const res = await onAddressSearch(searchTerm, groupOptions, options);
            return convertResultToSelectWithStatus(res);
        } catch (error) {
            console.error(error);
        }
    }

    const onAddressSearchWithCountryCodeWithStatus = async (searchTerm = "", groupOptions, options) => {
        try {
            const res = await onAddressSearchWithCountryCode(searchTerm, groupOptions, options);
            return convertResultToSelectWithStatus(res);
        } catch (error) {
            console.error(error);
        }
    }


    const chassisTypes = chassisType;
    const unitOfMeasureOptions = types;
    const containerTypes = containerType;
    const containerSizes = containerSize;

    const debouncedShipperOptions = _.debounce(getDebouncedShippers, 500);
    const debouncedPeopleOptions = _.debounce(getDebouncedPeoples, 500);
    const countryCodeLoadOptions = (inputValue, callback) => callback(filterCounties(inputValue));
    const peopleOptions = makeOptionsForSelectPeople(peopleList, "firstName", "lastName", "_id");

    const peopleOptionsValue = (quoteDetails?.people?._id ?? quoteDetails?.people) ? (
        peopleOptions?.find(d => d.value === (quoteDetails?.people?._id ?? quoteDetails?.people)) ?? {
            label: quoteDetails?.people?.fullName ?? quoteDetails?.people?.label,
            value: quoteDetails?.people?._id ?? quoteDetails?.people?.value,
            ...quoteDetails?.people ?? {},
        }) : null;

    let shipperSelectValue = quoteDetails?.shipper?.map(d => ({ ...d, label: d?.name ?? d?.label ?? d?.company_name, value: d?._id ?? d?.value}));
    let consigneeSelectValue = quoteDetails?.consignee?.map(d => ({ ...d, label: d?.name ?? d?.label ?? d?.company_name, value: d?._id ?? d?.value}));
    let emptyOriginSelectValue = quoteDetails?.emptyOrigin?.map(d => ({ ...d, label: d?.name ?? d?.label ?? d?.company_name, value: d?._id ?? d?.value}));
    let customerSelectValue = quoteDetails?.caller ? {
        ...quoteDetails?.caller, 
        label: quoteDetails?.caller?.label ?? quoteDetails?.caller?.name, 
        value: quoteDetails?.caller?.value ?? quoteDetails?.caller?._id
    } : null;


    let chargeCodeList = [];
    if (chargeCode && chargeCode.length) chargeCodeList = _.orderBy(chargeCode, "orderIndex", "asc");

    return {
        isSaving,
        countries,
        terminals,
        activeTab,
        commodities,
        selectedCell,
        quoteDetails,
        chassisTypes,
        peopleOptions,
        totalMailCount,
        containerTypes,
        containerSizes,
        chargeCodeList,
        userBasicSettings,
        isFetchingPricing,
        peopleOptionsValue,
        shipperSelectValue,
        customerSelectValue,
        consigneeSelectValue,
        unitOfMeasureOptions,
        emptyOriginSelectValue,
        countryCodeLoadOptions,
        debouncedPeopleOptions,
        debouncedShipperOptions,
        getCurrency,
        handleChange,
        getChassisTypes,
        handleTabChange,
        handleSelectCell,
        handleCloseModal,
        getCustomerRates,
        handleUnselectCell,
        handleAddPriceRows,
        handlePricingchanges,
        handleDeletePriceRow,
        handleCommodityChanges,
        handleAfterDisconnectMail,
        handleAddEditLoadQuoteSettings,
        onAddressSearch,
        onAddressSearchWithCountryCode,
        onAddressSearchWithStatus,
        onAddressSearchWithCountryCodeWithStatus
    }
};
