import moment from "moment";
import { ENUM } from "../constant";
import configuration from "../../../../../../config";
import { getStorage, isCustomerAccount, isEurope } from "services";

export const getFilterLocationPayload = (options = [], isMapZipcodeGroup = false, isMapCityGroup = false, ) => {
    const measureUnits = JSON.parse(getStorage('measureUnits'));        
    const locationPayload = options?.map((d) => {
        let value = {...d};

        if(d?.allInfos) value = {
            ...d?.parameterProfile, 
            email: d?.allInfos?.email,
            address: d?.allInfos?.address,
            mobile: d?.allInfos?.mobile,
            company_name: d?.parameterProfile?.name, 
            billingEmail: d?.allInfos?.billingEmail,
            main_contact_name: d?.allInfos?.main_contact_name,
        }
        else if (d?.__isNew__) value = { company_name: d?.label, name: d?.label, profileType: 'zipCode', profileGroup: [], profile: { name: d?.label, zipCode: d?.label } };
        else if (d?.parameterProfile?.profileType === ENUM.ZIP_CODE) value = { ...(d?.parameterProfile ?? {}) };
        else if (d?.parameterProfile?.profileType === ENUM.ZIP_CODE_GROUP_PROFILE) {
            let groupCodes = [];
            if(value?.parameterProfile?.profileGroup?.length > 0) {
                groupCodes = value?.parameterProfile?.profileGroup;
            }
            else if(isMapZipcodeGroup) {
                groupCodes =  measureUnits.groupedZipcodes?.find(group => group._id === d.value);
                groupCodes = groupCodes?.zipcodes?.map(b => ({name: b, zipCode: b}));
            }
            value = { ...d?.parameterProfile ?? {}, company_name: d?.parameterProfile?.name, profileGroup: groupCodes}
        }
        else if (d?.parameterProfile?.profileType === ENUM.CITY_GROUP_PROFILE) {
            let cityGroups = [];
            if(d?.parameterProfile?.profileGroup?.length > 0) {
                cityGroups = d?.parameterProfile?.profileGroup;
                cityGroups = cityGroups?.map(({name,}) => {
                    const splitCityState = name.split(",") ?? [];
                    return {name: name, city: splitCityState?.[0], state: splitCityState?.[1]};
                });
            }
            else if(isMapCityGroup) {
                cityGroups =  measureUnits.groupedCities?.find(group => group._id ===  d.value);
                cityGroups = cityGroups?.cities?.map(b => {
                    const splitCityState = b.split(",") ?? [];
                    return {name: b, city: splitCityState?.[0], state: splitCityState?.[1]};
                });
            }
            value = { ...d?.parameterProfile ?? {}, company_name: d?.parameterProfile?.name, profileGroup: cityGroups}
        }
        else if (d?.parameterProfile?.profileType === ENUM.GROUP_PROFILE) value = { ...d?.parameterProfile ?? {}, company_name: d?.parameterProfile?.name};
        else if (d?.parameterProfile?.profileType === ENUM.CITY_STATE) value = {...d?.parameterProfile ?? {}, company_name: d?.parameterProfile?.name};

        delete value.type;
        delete value.profileLabel;
        delete value.isTrackedByTrackOS;
        delete value.isLoginSystemDefault;
        delete value.isSystemGeneratedTerminal;

        return value;
    }) ?? [];

    return locationPayload ?? [];
}

export const getAddEditQuotePayload = (quoteDetails = {}) => {
    const {
        _id, caller, emptyOrigin, shipper, pricing, quote,
        commodity, currentLoadSettingID, numberofLoad, quoteNotes,
        validUpTo, selectedTerminal, containerType, containerSize,
        chassisType, temperature, hazmat, hot, liquor, genset, overweight,
        type_of_load, countryCode, consignee, totalMiles, people,
    } = quoteDetails;

    pricing?.forEach((element) => {
        if (!element.type) element.type = "fixed";
        element.finalAmount = parseFloat(element.finalAmount ?? 0).toFixed(2);
        element.amount = parseFloat(element.amount ?? 0).toFixed(2);
        element.chargePerDay = parseFloat(element.chargePerDay ?? 0).toFixed(0);
        element.freeDays = parseFloat(element.freeDays ?? 0).toFixed(0);

        delete element.manual;
        delete element.manualFinalAmount;
    });

    let data = { ...quoteDetails ?? {}, pricing, numberofLoad, validUpTo: moment(validUpTo).toISOString() };

    if(caller?.parameterProfile) data.caller = {
        ...caller?.parameterProfile ?? {}, 
        email: caller?.allInfos?.email,
        address: caller?.allInfos?.address,
        mobile: caller?.allInfos?.mobile,
        company_name: caller?.parameterProfile?.name, 
        billingEmail: caller?.allInfos?.billingEmail,
        main_contact_name: caller?.allInfos?.main_contact_name,
        invoiceCurrencyWithCarrier: caller?.allInfos?.invoiceCurrencyWithCarrier,
        invoiceCurrencyWithBranch: caller?.allInfos?.invoiceCurrencyWithBranch,
    } 
    else data.caller = caller;
    if (countryCode && isEurope()) data.countryCode = countryCode.value;


    data.totalMiles = totalMiles;
    data.shipper = getFilterLocationPayload(shipper);
    data.consignee = getFilterLocationPayload(consignee);
    data.emptyOrigin = getFilterLocationPayload(emptyOrigin);

    if (quote) data.quote = quote;
    if (quoteNotes) data.notes = quoteNotes;

    if (
        (getStorage("currentUserRole") == "fleetmanager" && configuration.idForTerminal.indexOf(JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
        (getStorage("currentUserRole") == "carrier" && configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) data.terminal = selectedTerminal?.allInfo ?? selectedTerminal ?? null;

    if (containerType?.label || containerType?.name) data.containerType = { _id: containerType.value ?? containerType._id, name: containerType.label ?? containerType?.name };
    if (containerSize?.label || containerSize?.name) data.containerSize = { _id: containerSize.value ?? containerSize._id, name: containerSize.label ?? containerSize?.name };
    if (chassisType?.label || chassisType?.name) data.chassisType = { _id: chassisType.value ?? chassisType._id, name: chassisType.label ?? chassisType?.name };

    if(isCustomerAccount()) {
        const currentUser = JSON.parse(getStorage('loggedInUser'));        
        data.people = currentUser.fleetManager;
    } else if (people) {
        data.people = people?.allInfos ?? people;
        data.peopleName = people?.label ?? people?.fullName;
    }

    if (temperature) data.temperature = temperature;

    data.hazmat = hazmat ? true : false;
    data.hot = hot ? true : false;
    data.overweight = overweight ? true : false;
    data.liquor = liquor ? true : false;
    data.genset = genset ? true : false;

    if (type_of_load) data.type_of_load = type_of_load;
    if (commodity && commodity.length > 0) data.commodity = commodity;
    else data.commodity = [];

    if (currentLoadSettingID || _id) data._id = currentLoadSettingID ?? _id;

    delete data.zipCode;
    delete data.quoteNotes;
    delete data.countryCode;
    delete data.selectedTerminal;

    if(isCustomerAccount()){
        delete data.validUpTo;
    }

    return data ?? {};
}

export const getCustomerRateParams = (quoteDetails = {}) => {
    const {
        shipper, caller, containerType, containerSize, chassisType,
        temperature, hazmat, hot, liquor, genset, selectedTerminal,
        consignee, emptyOrigin, type_of_load, overweight, people,
    } = quoteDetails;

    let params = {};

    if (type_of_load) params.type_of_load = type_of_load;
    if (caller) params.caller = caller?.parameterProfile ?? caller;

    params.shipper = getFilterLocationPayload(shipper, true, true);
    params.consignee = getFilterLocationPayload(consignee, true, true);
    params.emptyOrigin = getFilterLocationPayload(emptyOrigin, true, true);

    if(isCustomerAccount()) {
        const currentUser = JSON.parse(getStorage('loggedInUser'));        
        params.people = currentUser.fleetManager;
    } else if (people) params.people = people?.allInfos ?? people;
    if (containerType) params.containerType = containerType.value;
    if (containerSize) params.containerSize = containerSize.value;
    if (chassisType) params.chassisType = chassisType.value;
    if (hazmat) params.hazmat = hazmat;
    if (temperature) params.temperature = temperature;
    if (hot) params.hot = hot;
    if (liquor) params.liquor = liquor;
    if (genset) params.genset = genset;
    if (overweight) params.overweight = overweight;
    if (selectedTerminal) params.terminal = selectedTerminal.value;


    return params ?? {};
}