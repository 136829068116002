import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import CustomerList from "../portals/CustomerList";
import { DateTimeFormatUtils, getStorage } from "../../../../../../services/Common.services";
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";

import CellSpinner from "../../../../../../Components/Common/Spinner/CellSpinner";

const Customer = ({ row, column, onRowChange, isCellSelected }) => {
	const cellRef = useRef()
	let TimeZone = getStorage("timeZone");
	const [isCustomerList, setIsCustomerList] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const prevSelectionRef = useRef();

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setIsCustomerList(true)
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected){
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.customer);
	}, [column, row?.colorOfCells?.customer]);

	let isHoldCustomer = false;
    let holdStatus = [
        'PENDING',
        'AVAILABLE',
        'DEPARTED',
        'DISPATCHED',
        'CHASSISPICK_ARRIVED',
        'CHASSISPICK_DEPARTED',
        'PULLCONTAINER_ARRIVED',
    ]
    if (row.brokerHold && holdStatus.includes(row.status)) {
      isHoldCustomer = true
  	}

	return (
		<>
		<div ref={cellRef} className={`cell-content ${isHoldCustomer ? "redColor" : ""}`} onClick={() => setIsCustomerList(true)} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
		
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && 
				<div className="rdg-truncate">
					{row.caller && row.caller.company_name
						? row.caller.company_name
						: ""}
					{row.caller &&
						(row.caller.officeHoursStart || row.caller.officeHoursEnd) && (
							<div className="small rdg-truncate">
								{row.caller.officeHoursStart
									? moment(row.caller.officeHoursStart)
										.tz(TimeZone)
										.format(DateTimeFormatUtils.timeFormat())
									: ""} - {row.caller && row.caller.officeHoursEnd
									? moment(row.caller.officeHoursEnd)
										.tz(TimeZone)
										.format(DateTimeFormatUtils.timeFormat())
									: ""}
							</div>
						)}
				</div>}
			{row?.isEditPermission &&  isCustomerList && !isAssignInProgress &&(
				<CustomerList
					Row={row}
					CellRef={cellRef}
					onClose={(val) => {
						setIsCustomerList(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
			)}
	
		</div>
		{isColorPortal &&
			<ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="customer" defaultCellColors={row?.colorOfCells} Row={row} onRowChange={onRowChange}/>
		}
	</>);
};

export default Customer;
