import _ from "lodash";

const defaultState = {
  userType: null,
  unreadMessages: [],
  groups: [],
  terminals: [],
  allTerminals: [],
  currencyType:[],
  portMarkets: [],
  enabledMarkets: [],
  notTrackingContainerPortCount: 0,
  portStats: {market: null, updateStats: {connected: 0, errors: 0, missing: 0}},
  isEnabledMarketsError: false
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case 'UPDATE_TYPE':
      return {
        ...state,
        userType: action.payload
      }
    case 'CHANGE_NAV':
      return {
        ...state,
        commonUrl: action.payload
      }
    case 'SET_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: [...state.unreadMessages, { groupId: action.groupId, count: action.count, type: action.groupType }].unique()
      }
    case 'ADD_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.map((el) => {
          if (el.groupId+'' === action.groupId+'') {
            el.count = el.count + 1
          }
          return el
        }).unique()
      }
    case 'READ_ALL_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.map((el) => {
          if (el.groupId === action.groupId) {
            el.count = 0
          }
          return el
        }).unique()
      }
    case 'REMOVE_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: state.unreadMessages.filter((el) => el.groupId !== action.groupId)
      }
    case 'RESET_UNREAD_MESSAGE_COUNT':
      // debugger
      return {
        ...state,
        unreadMessages: []
      }
    case 'SET_GROUPS':
      return {
        ...state,
        groups: [...action.payload]
      }
    case 'ADD_GROUP':
      return {
        ...state,
        groups: [...state.groups, action.payload].unique()
      }
    case 'REMOVE_GROUP':
      return {
        ...state,
        groups: state.groups.filter(group => group._id !== action.groupId)
      }
    case 'ADD_MESSAGE_TO_GROUP':
      return {
        ...state,
        groups: [...state.groups.map(group => {
          if(action.groupId === group._id) {
            return {...group, updatedAt: action.message.sentAt, messages: [...group.messages, action.message]}
          }
          return group
        })]
      }
    case 'ADD_TERMINAL_DATA':
      return {
        ...state,
        terminals: [...action.payload]
      }
    case 'ADD_ALL_TERMINAL_DATA':
      return {
        ...state,
        allTerminals: [...action.payload]
      }
    case 'FETCH_PORT_MARKETS_SUCCESS':
      return {
        ...state,
        portMarkets: [...(action?.payload?.portMarkets ?? state.portMarkets)],
        enabledMarkets: [...(action?.payload?.enabledMarkets ?? state.enabledMarkets)],
        portStats: action?.payload?.portStats ?? state.portStats
      }

    // update the total ports count for not tracking container
    case "NOT_TRACKING_CONTAINER_PORT_COUNT": {
      let totalCount = action.payload;
      return {
        ...state,
        notTrackingContainerPortCount: totalCount ?? 0
      }
    }

    case "SUBSCRIBED_GROUP_CHAT": {
      return {
        ...state,
        subscribedChatList: action.payload,
      };
    }

    case "UNSUBSCRIBED_GROUP_CHAT": {
      return {
        ...state,
        unsubscribedChatList: action.payload,
      };
    }

    case "SUBSCRIBED_TO_GROUP_CHAT": {
      return {
        ... state,
        subscribedChatListForCheck: [...(state.subscribedChatListForCheck ?? []), action.payload],
      }
    }

    case "UNSUBSCRIBED_FROM_GROUP_CHAT": {
      return {
        ... state,
        subscribedChatListForCheck: (state.subscribedChatListForCheck ?? [])?.filter((e) => e !== action.payload),
      }
    }

    case "READ_DRIVERGROUP_CHAT": {
      const chatId = action.chatId;
      const updatedChat = action.payload;

      const unsubbedCopy = _.cloneDeep(state.unsubscribedChatList);
      const subbedCopy = _.cloneDeep(state.subscribedChatList);

      if(unsubbedCopy?.find((e) => e?._id === chatId)) {
        unsubbedCopy?.forEach((e) => {
          if(e?._id === chatId) e.messages = updatedChat.messages;
        });

        return {
          ... state,
          unsubscribedChatList: unsubbedCopy ?? [],
        }
      } else if(subbedCopy?.find((e) => e?._id === chatId)) {
        subbedCopy?.forEach((e) => {
          if(e?._id === chatId) e.messages = updatedChat.messages;
        });

        return {
          ... state,
          subscribedChatList: subbedCopy ?? [],
        }
      }
      return {
        ... state,
      }
    }
    case 'SET_CURRENCY_TYPE':
      return{
        ...state,
        currencyType:[...action.payload]
      }
    case 'SET_ENABLED_MARKETS_ERROR':
      return {
        ...state,
        isEnabledMarketsError: action?.payload ?? false
      }
    case 'SET_SUGGESTED_CHANGES_MODEL':
      return {
        ...state,
        showSuggestedChangesModel: action?.payload || false
      }
    default:
      return state;
  }
}
