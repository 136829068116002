import {
    getChassisPickCustomers,
    getChassisTerminationCustomers,
    getCustomers,
    getDroppedCustomers,
    getEmptyOriginCustomers,
    getTMSconsignee,
    getTMSshipper,
    getTMSShipperConsigneeChassis,
  } from "../Dispatcher/actionCreators";

import {
    IconDownloadAlt,
    IconFlag,
    IconLoadCartLeft,
    IconLoadCartRight,
    IconMapMarkerLoad,
    IconNoteArrowRight,
    IconReturn,
    IconUploadAlt,
} from "../../../Components/Common/Icons";
import { getStorage, isNewSupportMovesEnabled, isRailOrderOn, makeOptionsForSelectWithStatus } from "services";
import { getCustomerCityAsyncSearch } from "../NewDispatcher/actionCreator";
import { getCustomerCityAsyncSearch as getCustomerCityAsyncSearch2, getCustomerCityWithCountryCodeAsyncSearch } from "../CustomerRates/service/customerRateService";
import { getCustomerAsyncSearch, getCustomerAsyncSearchForDriverPlanner } from "../Dispatcher/utility";

export const ROUTING_DRAG_LEGS = [
    { leg: 'CHASSISPICK', legTitle: 'Hook Chassis' },
    { leg: 'PULLCONTAINER', legTitle: 'Pull Container' },
    { leg: 'DELIVERLOAD', legTitle: 'Deliver Load' },
    { leg: 'RETURNCONTAINER', legTitle: 'Return Container' },
    { leg: 'DROPCONTAINER', legTitle: 'Drop Container' },
    { leg: 'STOPOFF', legTitle: 'Stop Off' },
    { leg: 'LIFTOFF', legTitle: 'Lift Off' },
    { leg: 'CHASSISTERMINATION', legTitle: 'Terminate Chassis' },
    { leg: 'COMPLETED', legTitle: 'Completed' },
];

let freeze = false; //mark delay
let timer; //saved timer

const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisPickCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMSshipperAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSshipper(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMSconsigneeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSconsignee(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getEmptyOriginCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getEmptyOriginCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};


export const getEmptyOriginCustomersAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getEmptyOriginCustomers(strSearch); //request
        r = convertResultToSelectWithStatus(r);
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const convertResultToSelectWithStatus = (result = []) => {
  result = result.map(i => ({...i?.allInfos ?? {}, ...(i?.parameterProfile ? {parameterProfile: i?.parameterProfile} : {})}));
  result = makeOptionsForSelectWithStatus(
    result, 
    "company_name",
    "address.address",
    "_id",
    "isConnected"
  );
  return result
}

export const getTMSshipperAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getTMSshipper(strSearch); //request
        r = convertResultToSelectWithStatus(r);
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getChassisPickCustomersAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getChassisPickCustomers(strSearch); //request
        r = convertResultToSelectWithStatus(r);
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMSconsigneeAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getTMSconsignee(strSearch); //request
        r = convertResultToSelectWithStatus(r)
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getDroppedCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getDroppedCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getDroppedCustomersAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getDroppedCustomers(strSearch); //request
        r = convertResultToSelectWithStatus(r);
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const getChassisTerminationCustomersAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMSShipperConsigneeChassisAsyncSearchForSelectWithStatus = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getTMSShipperConsigneeChassis(strSearch); //request
        r = convertResultToSelectWithStatus(r);
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMSShipperConsigneeChassisAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        let r = await getTMSShipperConsigneeChassis(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getCustomersWithStatus = async (strSearch) => {
  try {
    let r = await getCustomers(strSearch);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerAsyncSearchForDriverPlannerWithStatus = async (strSearch) => {
  try {
    let r = await getCustomerAsyncSearchForDriverPlanner(strSearch);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerAsyncSearchWithStatus = async (strSearch) => {
  try {
    let r = await getCustomerAsyncSearch(strSearch);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerCityAsyncSearchWithStatus = async (strSearch, groupedProfiles = [], groupedCities = [], groupedZipcodes = [], customerType) => {
  try {
    let r = customerType ? await getCustomerCityAsyncSearch2(strSearch, groupedProfiles, groupedCities, groupedZipcodes, customerType) : await getCustomerCityAsyncSearch(strSearch, groupedProfiles, groupedCities, groupedZipcodes);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerCityWithCountryCodeAsyncSearchWithStatus = async (strSearch, groupedProfiles = [], groupedCities = [], groupedZipcodes = [], customerType) => {
  try {
    let r = customerType ? await getCustomerCityWithCountryCodeAsyncSearch(strSearch, groupedProfiles, groupedCities, groupedZipcodes, customerType) : await getCustomerCityAsyncSearch(strSearch, groupedProfiles, groupedCities, groupedZipcodes);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
};

export const updateStatusTypeForLoad = (selectedLoads, status) => {
  const loadTypes = ['EXPORT', 'ROAD'];
  const statusTypes = ['PULLCONTAINER'];
  if (loadTypes.includes(selectedLoads?.type_of_load || selectedLoads) && statusTypes.includes(status.type)) {
    status.type = "PULLCONTAINEREXPORTANDROAD";
  }
  return status;
};

export const updateStatusTypeForLoadRoad = (status) => {
  const statusTypes = ['PULLCONTAINER'];
  if (statusTypes.includes(status.type)) {
    status.type = "PULLCONTAINEREXPORTANDROAD";
  }
  return status;
};

export const ROUTING_ADDRESS_FIELDS = {
    CHASSISPICK: { optionsField: 'chassisTerms', loadOptions: getChassisPickCustomersAsyncSearchForSelectWithStatus },
    PULLCONTAINER: { optionsField: 'shippersOptions', loadOptions: getTMSshipperAsyncSearchForSelectWithStatus },
    DELIVERLOAD: { optionsField: 'consigneesOptions', loadOptions: getTMSconsigneeAsyncSearchForSelectWithStatus },
    RETURNCONTAINER: { optionsField: 'customers', loadOptions: getEmptyOriginCustomersAsyncSearchForSelectWithStatus },
    DROPCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearchForSelectWithStatus },
    HOOKCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearchForSelectWithStatus },
    LIFTOFF: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearchForSelectWithStatus },
    LIFTON: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearchForSelectWithStatus },
    STOPOFF: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearchForSelectWithStatus },
    CHASSISTERMINATION: { optionsField: 'chassisTerms', loadOptions: getChassisTerminationCustomersAsyncSearchForSelectWithStatus }, 
    PULLCONTAINEREXPORTANDROAD: { optionsField: 'shippersOptions', loadOptions: getTMSShipperConsigneeChassisAsyncSearchForSelectWithStatus },
};

export const ROUTING_ADDRESS_FIELDS_WITHOUT_STATUS = {
  CHASSISPICK: { optionsField: 'chassisTerms', loadOptions: getChassisPickCustomersAsyncSearch },
  PULLCONTAINER: { optionsField: 'shippersOptions', loadOptions: getTMSshipperAsyncSearch},
  DELIVERLOAD: { optionsField: 'consigneesOptions', loadOptions: getTMSconsigneeAsyncSearch },
  RETURNCONTAINER: { optionsField: 'customers', loadOptions: getEmptyOriginCustomersAsyncSearch },
  DROPCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
  HOOKCONTAINER: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
  LIFTOFF: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
  LIFTON: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
  STOPOFF: { optionsField: 'customers', loadOptions: getDroppedCustomersAsyncSearch },
  CHASSISTERMINATION: { optionsField: 'chassisTerms', loadOptions: getChassisTerminationCustomersAsyncSearch },
  PULLCONTAINEREXPORTANDROAD: { optionsField: 'shippersOptions', loadOptions: getTMSShipperConsigneeChassisAsyncSearch },
};

export const ICONS = {
    CHASSISPICK: <IconLoadCartRight className="mr-10" />,
    PULLCONTAINER: <IconNoteArrowRight className="mr-10" />,
    DELIVERLOAD: <IconMapMarkerLoad className="mr-10" />,
    RETURNCONTAINER: <IconReturn className="mr-10" />,
    DROPCONTAINER: <IconDownloadAlt className="mr-10" />,
    LIFTOFF: <IconDownloadAlt className="mr-10" />,
    HOOKCONTAINER: <IconUploadAlt className="mr-10" />,
    LIFTON: <IconUploadAlt className="mr-10" />,
    CHASSISTERMINATION: <IconLoadCartLeft className="mr-10" />,
    COMPLETED: <IconFlag className="mr-10" />,
};

export const getPreSetOrderStatus = (forManualSupportMove = false) => {
  const railOrderOn = isRailOrderOn()
  return [
    { label: "Pick And Run + Drop & Hook", value: "PULL_DELIVER_DROP_RETURN" },
    { label: "Pick And Run + Live", value: "" },
    { label: "Prepull + Drop & Hook", value: "PULL_DROP_DELIVER_DROP_RETURN" },
    { label: "Prepull + Live", value: "PULL_DROP_DELIVER_RETURN" },
    { label: "One Way Move", value: "PULL_RETURN" },
    { label: "Pick And Run + Gray Pool", value: "PICK_RUN_GRAY_POOL" },
    { label: "Prepull + Gray Pool", value: "PREPULL_GRAY_POOL" },
    { label: "Shunt", value: "SHUNT_PULL_RETURN" },
    ...(railOrderOn ? [{ label: "Pick and Lift + Deliver and Lift + Return", value: "PICK_LIFT_DELIVER_LIFT_RETURN" }, { label: "Pick and Lift + Live", value: "PICK_LIFT_DELIVER_RETURN" }] : []),
    ...(forManualSupportMove && isNewSupportMovesEnabled() ? [{ label: "Bobtail", value: "BOBTAIL" }, { label: "Bobtail To Hook Chassis", value: "BOBTAIL_TO_HOOK_CHASSIS" }] : []),
  ]
}

export const ROUTING_TYPE_PARAMS = {
  CHASSISPICK: ['ALL', 'chassisPick'],
  PULLCONTAINER: ['ALL', 'shipper'],
  DELIVERLOAD: ['ALL', 'consignee'],
  RETURNCONTAINER: ['ALL', 'containerReturn','shipper'],
  CHASSISTERMINATION: ['ALL', 'chassisTermination']
};

export const ROUTING_EVENTS = [
  { label: "Pick Up Container", value: "PULLCONTAINER" },
  { label: "Return Container", value: "RETURNCONTAINER" },
  { label: "Deliver Container", value: "DELIVERLOAD" },
  { label: "Drop Container", value: "DROPCONTAINER" },
  { label: "Hook Container", value: "HOOKCONTAINER" },
  { label: "Hook Chassis", value: "CHASSISPICK" },
  { label: "Terminate Chassis", value: "CHASSISTERMINATION" },
  { label: "Lift Off", value: "LIFTOFF" },
  { label: "Lift On", value: "LIFTON" }
]

export const getRoutingEvents = () => {
  let userBasicSettings = JSON.parse(getStorage("userBasicSettings"))
  let result = [...ROUTING_EVENTS]
  if (userBasicSettings?.stopoff && userBasicSettings?.isStopOff) {
    let allStopOffOption = userBasicSettings?.stopoff?.filter((d) => !d.isDeleted)
    if (allStopOffOption?.length > 0) {
      allStopOffOption.forEach(element => {
        result.push({label: `Stop Off - ${element?.name || ''}`, value:  `STOPOFF-${element?.name || ''}`})
      }); 
    } else {
      result.push({label: `Stop Off`, value:  `STOPOFF`}) 
    }
  }
  return result
}

export const commonAsyncSearch = async (searchFunction, strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove prev timer
      timer = setTimeout(async () => {
        freeze = false;
        try {
          const r = await searchFunction(strSearch); //request
          res(r);
        } catch (error) {
          err(error); // Reject the inner promise
        }
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    }).catch((error) => {
      console.log({error});
      err(error); // Reject the outer promise
    });
  });
};