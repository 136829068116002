import { getStorage, addressFormatEnum, parsedAddressIntoLocality } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import config from '../../../config';
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams'
import { updatePreAppointmentFieldByCarrier } from '../Truck/actionCreators';
import * as ToQuery from '../../../Components/Common/jsonToQueryParams';
import { euAddressFormate } from "pages/tms/Dispatcher/actionCreators";
export {updatePreAppointmentFieldByCarrier};

export function getProfile() {
  const url = 'getUser';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function getDriverAudits(params) {
  let url = 'carrier/getTMSDriverAudit';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function listAllDrivers(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function newDriverRegister(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'carrier/newDriverRegister', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function assignRole(params) {
  let url = 'admin/assignRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function removeRole(params) {
  let url = 'admin/removeRoles';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateDriverProfile(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', 'carrier/updateDriverProfile', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function editDriverProfile(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', 'carrier/editDriverProfile', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function deleteDriverProfile(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('delete', 'carrier/deleteDriverProfile', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function resetDriverPassword(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'user/driver-resetpassword', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateDriverAccessorials(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('put', 'carrier/updateDriverAccessorialsByCarrier', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function checkUserNameAvailability(data) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', 'driver/username-availability', data, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateDriverPermissions(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','driver/update-permissions',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function updateDriverOnHold(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','driver/update-driver-hold',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function profileCheck(params) {
  let url = 'user/profile-check';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function userUnlock(params) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post','user/unlock',params, {
        'authorization': getStorage('token'),
      })
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error);
      });
    })
  }
}

export function getDecryptedValues(params) {
  let url = 'carrier/getDecryptedValues';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
}

export function getTruckOwnerDecryptedValues(params) {
  let url = 'truck-owner/get-decrypted-value';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
}

export const getCurrencyList = () => {
  return function(dispatch) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await HTTP(
          "GET",
          "currency",
          null,
          {
            authorization: getStorage("token"),
          }
        );
  
        const data = response?.data?.data?.map((e) => {
          return {
            label: `${e.currencyCode} (${e.symbol})`,
            value: e._id,
          }
        });
        resolve(data);
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  }
}

export function getSecurityFields() {
  const url = 'driver/getSecurityFields';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'Authorization': getStorage('token')
      })
        .then((result) => {
          resolve(result.data.data);
        })
        .catch((error) => {
          reject(error);
        })
    });
  }
}

export function addDriverTags(params) {
  let url = 'tms/addDriverTag';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getDriverTags(params) {
  let url = 'tms/getDriverTags';
  if(params)
    url+="?"+jsonToQueryParams(params)
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, {},{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getVendorExpenseAccount(params) {
  let url = "v1/qb-settlement/get-vendor-expense-account";
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP("get", url, {}, {
      authorization: getStorage("token"),
    }
    )
      .then((result) => {
        resolve(result.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getHosAvailability(params) {
  let url = 'hos-availability';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getHosEvents(params) {
  let url = 'hos-events';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export async function getTerminalDrivers(search) {
  let url = 'driver/driver-terminal-info-list';
  url = search ? url + "?" + jsonToQueryParams({ search }) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export async function connectTerminalDriver(params) {
  let url = `driver/${params?.driverId}/sync-driver-terminal-info`;
  const payload = { terminalDriverName: params?.terminalDriverName };
    return new Promise((resolve, reject) => {
      HTTP('post', url, payload, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export async function disconnectTerminalDriver(driverId) {
  let url = `driver/${driverId}/unsync-driver-terminal-info`;
    return new Promise((resolve, reject) => {
      HTTP('delete', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}


export function getAllIdsToCustomers(params ) {
  let obj = { allIds:params}
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        
        let customers = []
        allDatas.forEach((obj) => {
            customers.push({ value: obj._id, label: obj.company_name, allInfos: obj });
        });
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function driverInfoDownloadCSVData(params) {
  let url = `${config.newDocUrl}tms/driver/downloadCSV`;
  return new Promise((resolve, reject) => {
    HTTP('post', null, params, {
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        const data = result?.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}