import React, { Component } from "react";
import Select from "react-select";
import { Modal } from 'react-bootstrap'
import { ModalBody } from 'reactstrap'
import { Document, Page } from "react-pdf";
import { getDocumentType } from "../../../../../services";
import { getStorage, HTTP, toastr } from "../../../../../../../services";
import Dropzone from "react-dropzone";
import { ImageUploader } from "../../../../../../../Components/Common/Components/Component";
import { IconCheck, IconDargUpload, IconTimes, IconTrash, IconUpload } from "../../../../../../../Components/Common/Icons";
import { newDocUpload, newImageUpload, oldDocUpload, oldImageUpload } from "../../../../../Dispatcher/actionCreators";

class DocumentsFreeFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listAllImagingDocuments:
                props.selectedLoads && props.selectedLoads.documents
                    ? props.selectedLoads.documents
                    : [],
            docUploading: false,
            documents: [],
            processing: false,
            uploadedFileName: "",
            degree: 0,
            height: "0px",
            selectedDoc: null,
            selectedUrl: null,
            url: null,
            numPages: null,
            pageNumber: 1,
            activeTab: "mainCustomer",
            customerId: null,
            allCustomers: [],
            DOC_OPTIONS: props.docOptions,
            showUploadModal: false,
            uploadFileDetails: [],
            progress: [],
            documentObj: [],
            loader: false,
            isSaving: props.isSaving ?? true,
            previewUrl: [],
            editDoc: null,
            showFullDropScreen: false,
            isPopupOpen: false,
            isSend: false,
            isType: false,
            isDisabledDropOverListener: false,
            sendDocumentId: null,
        }
    }

    componentWillReceiveProps(props) {
        if (this.props.uploadBtn  === props.uploadBtn) return; 
        this.setState({showUploadModal: props.uploadBtn})
    }

    fileUploader = (data) => {
        if (data) {
            let reader = new FileReader();
            let file = data[0];
            reader.onload = () => {
                this.uploadFile(file, reader.result, 'image');
            };
            this.props.check ? this.uploadFile(data) : reader.readAsDataURL(file);

        }
    };

    uploadFile = (file, preview, type) => {
        this.setState({ docUploading: true });
        if (file.type.match("image.*") && file.size > 5242880) {
            toastr.show(
                "File too Big, please select a file less than 5mb",
                "warning"
            );
        } else {
            this.setState({ showUploadModal: true })
            this.uploadResult(file, preview, type);
        }
    };

    uploadResult(file, preview, type) {
        file.progress = 0;
        let dummydata = [];
        const fileType = file.type.toLowerCase();
        const temp =
            this.state.uploadFileDetails &&
            this.state.uploadFileDetails.map((d) => d.name);
        let dummySize = this.state.uploadFileDetails && this.state.uploadFileDetails.filter((d) => d.size === file.size);
        dummydata = temp;
        dummySize = dummySize.map((d) => d.size)
        if (
            (fileType.match("image/png") || fileType.match("image/jpg") || fileType.match("image/jpeg") ||
                fileType.match("application.pdf") ||
                fileType.match("message/rfc822") ||
                fileType.match("text/csv") ||
                fileType.match("application/vnd.ms-excel") ||
                fileType.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) &&
            (!dummydata.includes(file.name) || !dummySize.includes(file.size))
        ) {
            if (fileType.indexOf("image") === -1) {
                type = fileType.split("/")[1];
            }
            let previewUrl = this.state.previewUrl
                ? [...this.state.previewUrl]
                : [];
            previewUrl.push({ pre: preview, name: file.name, size: file.size });
            this.setState({
                documentFile: file,
                uploadedFileName: file.name,
                previewUrl,
                [type]: preview,
                selectedDoc: null,
                uploadFileDetails: [...this.state.uploadFileDetails, file],
                fileSize: file.size
            }, () => {
                this.uploadFileData();
            });
        } else {
            if (dummydata.includes(file.name) && dummySize.includes(file.size)) {
                toastr.show("This file has already been uploaded.", "error");
                this.setState({ isSaving: false, docUploading: false });
                return;
            } else {
                toastr.show("Only Pdf, CSV, XLS, XLSX and Image files are allowed.", "error");
                this.setState({ isSaving: false, docUploading: true });
                return;
            }
        }
    }

    uploadFileData = () => {
        let data = new FormData();
        let newDocAPIFlags = JSON.parse(getStorage('newDocAPIFlags'))
    	let isNewDoc = newDocAPIFlags?.isNewDocUpload;
        let docUpload

        if (!this.state.documentFile) {
            toastr.show("Please select a document.", "error");
            return false;
        }
        if (this.state.documentFile) {
            const docFileName = this.state.documentFile.name.toLowerCase();
            if (
                docFileName.split(".").pop() !== "png" &&
                docFileName.split(".").pop() !== "jpg" &&
                docFileName.split(".").pop() !== "gif" &&
                docFileName.split(".").pop() !== "pdf" &&
                docFileName.split(".").pop() !== "jpeg" &&
                docFileName.split(".").pop() !== "csv" &&
                docFileName.split(".").pop() !== "xls" &&
                docFileName.split(".").pop() !== "eml" &&
                docFileName.split(".").pop() !== "xlsx"
            ) {
                toastr.show(
                    "Only select pdf, png, jpg, csv, xls, xlsx and jpeg file",
                    "error"
                );
                return this.removeDocument();
            }
        }
        if (this.state.documentFile.type.indexOf("image") > -1) {
            data.append("proofOfDelivery", this.state.image);
            data.append("shipmentId", new Date().getTime().toString());

            docUpload = isNewDoc ? newImageUpload(data, {isGenerateSignUrl: this.props.isGenerateSignUrl}) : oldImageUpload(data);
        } else {
            data.append("document", this.state.documentFile);
            data.append("filename", new Date().getTime().toString());

            docUpload = isNewDoc ? newDocUpload(data, {isGenerateSignUrl: this.props.isGenerateSignUrl}) : oldDocUpload(data);
        }

        docUpload.then((result) => {
                if (result?.statusCode === 200) {
                    const documentObjData = {
                        type: "",
                        url: result?.data?.url,
                        signedUrl: result?.data?.signedUrl,
                        date: new Date().toISOString(),
                        userId:getStorage("currentUserID")
                    };
                    if(this.state.fileSize) {
                        documentObjData.fileSize = this.state.fileSize
                    }
                    if (this.state.activeTab === "subCustomer") {
                        documentObjData.customerId = "";
                    }
                    let documentObj = this.state.documentObj
                        ? [...this.state.documentObj]
                        : [];
                    documentObj.push(documentObjData);

                    const progressBar = this.state.uploadFileDetails.map((item) => {
                        if (item.name === this.state.uploadedFileName) {
                            item.progress = 100;
                        }
                        return item;
                    });

                    this.setState({
                        documentObj,
                        uploadFileDetails: progressBar,
                        isSaving: false,
                        docUploading: false,
                        isType: true
                    });
                } else {
                    toastr.show("Something went wrong! Please try again.", "error");
                    this.setState({ docUploading: false });
                }
            })
            .catch((err) => {
                toastr.show("Something went wrong! Please try again.", "error");
                this.setState({ docUploading: false });
            });
    };

    handleDocumentType = (type, index) => {
        let data = this.state.documentObj.findIndex((obj, i) => i === index);
        let documentObj = this.state.documentObj;
        documentObj[data].type = type.value;
        let typeValue = documentObj.filter((d) => d.type === "")
            if(typeValue.length > 0) {
                this.setState({isType: true})
            } else {
                this.setState({isType: false})
            }
        this.setState({ documentObj: documentObj });
    };

    removeDocument = (index) => {
        let uploadFileDetails = this.state.uploadFileDetails;
        let documentObj = this.state.documentObj;
        let previewUrl = this.state.previewUrl;
        uploadFileDetails.splice(index, 1);
        documentObj.splice(index, 1);
        previewUrl.splice(index, 1);
        this.setState({
            uploadFileDetails,
            documentObj,
            documentFile: null,
            previewUrl,
        });
        if (this.state.uploadFileDetails.length === 0) {
            this.setState({ docUploading: true });
        }
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    uploadDocument = () => {
        this.props.handleOnChangeOrder(this.props.orderIndex,"documents",this.state.documentObj)
        this.setState({showUploadModal: false})
        this.props.uploadDocBtn();
    }
    render() {
        return (
            <div key={this.props.orderIndex}>
                <div>
                    <Dropzone
                        className="border-0 w-100"
                        onDrop={(acceptedFiles) => {
                            this.fileUploader(acceptedFiles);
                        }}
                        // disabled={props.disabled}
                        disabled={this.props?.creditMemoEditDisable}
                        acceptedFiles={['.csv', 'text/*']}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div className="dropzone" {...getRootProps()}>
                                    <div className="dz-message needsclick" tabIndex="0">
                                        <input {...getInputProps()} style={{ display: "none" }} />
                                        <div className="d-flex align-items-center justify-content-center">
                                            <IconDargUpload className="dropzone__uploadimage" />
                                            <div className="ml-30">
                                                <div className="text-muted font-12 mb-10 text-capitalize">
                                                    Drag & Drop your files here or
                                                </div>
                                                <button className="btn btn-primary btn-sm text-capitalize" type="button">
                                                    Choose from your device...
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
                {this.props.isShow && this.state?.previewUrl?.map((d, index) => {
                    let fileType = d.name.split(".").pop();
                    return (
                        <div className="dropzone-fileupload d-flex">
                            <button className="btn btn-success btn-circle btn-xs mr-15">
                                <IconCheck />
                            </button>
                            <div className="document-preview document-preview--sm mr-3">
                                <div className="document-preview__image">
                                    {fileType === "png" || fileType === "jpg" || fileType === "jpeg" ?
                                         (
                                            <img
                                                className="document-preview__image"
                                                src={d.pre}
                                                alt={""}
                                            />
                                        )
                                        : fileType === "pdf" ?
                                             (
                                                <div id="holder">
                                                    <Document
                                                        file={d.pre}
                                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                                    >
                                                        <Page pageNumber={1} renderAnnotationLayer={false} />
                                                    </Document>
                                                </div>
                                            )
                                        : fileType === "csv" ?
                                             (
                                                <img
                                                    className="document-preview__image"
                                                    src="/assets/img/csv.png"
                                                    alt="csv.png"
                                                />
                                            )
                                        : fileType === "xls" || fileType === "xlsx" &&
                                             (
                                                <img
                                                    className="document-preview__image"
                                                    src="/assets/img/xls.png"
                                                    alt="xls.png"
                                                />
                                            )
                                    }
                                </div>
                            </div>
                            <div className="w-100 d-flex">
                                <div className="mr-2">
                                    <div className="font-medium text-break">
                                        {d.name}
                                    </div>
                                    
                                </div>
                                <div className="w-150 ml-auto  mr-2">
                                    <Select
                                        className="w-100"
                                        placeholder="Select Doc Type"
                                        isDisabled={true}
                                        value={(this.state.documentObj && this.state.documentObj[index]) ? { label: this.state.documentObj[index].type, value: this.state.documentObj[index].type } : null}
                                    />
                                </div>
                            </div>
                            <button className="btn btn-link btn-circle btn-xs ml-3">
                                <span onClick={() => this.removeDocument(index)}>
                                    {" "}
                                    <IconTrash />{" "}
                                </span>
                            </button>
                        </div>
                    )
                })}
                <Modal className="backdrop_modal_open" show={this.state.showUploadModal} centered animation={false}>
                    <Modal.Header>
                        <Modal.Title>Upload Document</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <ImageUploader
                                    uploadResult={this.uploadFile}
                                    multi={false}
                                    name="Choose from your device..."
                                    type={"image"}
                                    className="custom-file-input "
                                />
                                {this.state.uploadFileDetails &&
                                    this.state.uploadFileDetails.map((fileData, index) => {
                                        return (
                                            <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                                                <IconUpload className="mr-20" />
                                                <div className="w-100">
                                                    <div className="font-medium d-flex justify-content-between w-100 mb-1">
                                                        <div>{fileData.name}</div>
                                                        <div className="ml-auto">
                                                            {fileData.progress}%
                                                        </div>
                                                    </div>
                                                    <div className="progress">
                                                        <div
                                                            className="progress-bar bg-primary"
                                                            role="progressbar"
                                                            aria-valuenow={`${fileData.progress}`}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{ width: `${fileData.progress}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                                                    <span onClick={() => this.removeDocument(index)}>
                                                        {" "}
                                                        <IconTimes className="" />
                                                    </span>
                                                </button>
                                            </div>
                                        );
                                    })}
                            </div>
                            {this.state.uploadFileDetails &&
                                this.state.uploadFileDetails.map((fileData, index) => {
                                    const doc = this.state.documentObj ? this.state.documentObj[index] : [];
                                    return (
                                        <div className="dropzone-fileupload d-flex">
                                            <button className="btn btn-success btn-circle btn-xs mr-15">
                                                <IconCheck />
                                            </button>
                                            <div className="document-preview document-preview--sm mr-3">
                                                <div className="document-preview__image">
                                                    {this.state.previewUrl &&
                                                        this.state.previewUrl.map((d) => {
                                                            if (fileData.name === d.name) {
                                                                if ((d.name.split(".").pop() === "png" || d.name.split(".").pop() === "jpg" || d.name.split(".").pop() === "jpeg") && d.size === fileData.size) {
                                                                    return (
                                                                        <img
                                                                            className="document-preview__image"
                                                                            src={d.pre}
                                                                            alt={""}
                                                                        />
                                                                    );
                                                                } else if (d.name.split(".").pop() === "pdf") {
                                                                    return (
                                                                        <div id="holder">
                                                                            <Document
                                                                                file={d.pre}
                                                                                onLoadSuccess={this.onDocumentLoadSuccess}
                                                                            >
                                                                                <Page pageNumber={1} renderAnnotationLayer={false} />
                                                                            </Document>
                                                                        </div>
                                                                    );
                                                                } else if (d.name.split(".").pop() === "csv") {
                                                                    return (
                                                                        <img
                                                                            className="document-preview__image"
                                                                            src="/assets/img/csv.png"
                                                                            alt="csv.png"
                                                                        />
                                                                    );
                                                                } else if (d.name?.split(".").pop() === "xls" || d.name?.split(".").pop() === "xlsx") {
                                                                    return (
                                                                        <img
                                                                            className="document-preview__image"
                                                                            src="/assets/img/xls.png"
                                                                            alt="xls.png"
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className="w-100 d-flex">
                                                <div className="mr-2">
                                                    <div className="font-medium text-break">
                                                        {fileData.name}
                                                    </div>
                                                    <div className="text-muted">
                                                        {(fileData.size * 0.0009765625).toFixed(1)} kb
                                                    </div>
                                                </div>
                                                <div className="w-150 ml-auto  mr-2">
                                                    <Select
                                                        className="w-100"
                                                        options={this.state.DOC_OPTIONS}
                                                        placeholder="Select Doc Type"
                                                        style={{ width: "150px", flex: "1 1 0%" }}
                                                        onChange={(imageType) =>
                                                            this.handleDocumentType(imageType, index)
                                                        }
                                                        isDisabled={this.state.docUploading}
                                                        value={(doc && doc.type) ? { label: doc.type, value: doc.type } : null}
                                                    />
                                                </div>
                                            </div>
                                            <button className="btn btn-link btn-circle btn-xs ml-3">
                                                <span onClick={() => this.removeDocument(index)}>
                                                    {" "}
                                                    <IconTrash />{" "}
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-close"
                            onClick={() =>
                                {
                                this.setState({
                                    showUploadModal: false,
                                    previewUrl: null,
                                    documentObj: null,
                                    uploadFileDetails: []
                                })
                                this.props.uploadDocBtn();
                                }
                            }
                        >
                            Close
                        </button>
                        <button
                            className="btn btn-primary"
                            disabled={this.state.isSaving || this.state.docUploading || this.state.isType}
                            onClick={() => {
                                this.uploadDocument();
                            }}
                        >
                            {this.state.isSaving && (
                                <span
                                    class="spinner-border spinner-border-sm mr-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default DocumentsFreeFlow;