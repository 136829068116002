import React, {useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import _ from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { updateLoad, getTMSUsers } from "../../actionCreator";
import { makeOptionsForSelect, updateTerminology } from "../../../../../../services/Common.services";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { errorColorResponse, successColorResponse } from "../../../utility";
import { APPOINTMENT_STATUSES, APPOINTMENT_MESSAGES } from "../../../../../../constants";
import { useAwaitablePrompt, useCurrentUserSettings } from "../../../../../../hooks";
import { loadEvents } from "pages/tms/constant";

const ShipperList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress , column }) => {
  const { openAwaitablePrompt, isAwaitablePrompt, AwaitablePromptAlert } = useAwaitablePrompt();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};

  const { shippers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const allShipper = makeOptionsForSelect(
    shippers,
    "company_name",
    "address.address",
    "_id"
  );
  const [dList, setDList] = useState(allShipper || []);
  const [cursor, setCursor] = useState(null);
  const [SearchShipper, setSearchShipper] = useState(null);
  const searchInputRef = React.useRef(null);
  const onSearch = useCallback(
    _.debounce((params) => getSearchData(params), 500),
    []
  );
  const cell = document.querySelector('[aria-selected="true"]');

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef,boxRef)
  useOnClickOutside(boxRef, () => {
    if (isAppointmentFeatureEnabled && isAwaitablePrompt) return;
    onClose(false);
  });

  useEffect(() => {
    searchInputRef.current.focus();
    window.document.getElementById('root').style.pointerEvents="none"
    return ()=>{
        window.document.getElementById('root').style.pointerEvents="auto"
    }
  }, []);

  useEffect(() => {
      onChangeDebounced();
  }, [SearchShipper]);

  const onChangeDebounced = () => {
    onSearch(SearchShipper);
  };

  const getSearchData = (params) => {
    const obj = { limit: 10 }
    if (params) {
      obj.nameSearch = params
      obj.customerType = ['ALL', 'shipper']
    }
    getTMSUsers(obj).then((data) => {
      setDList(data || []);
    }).catch(e => {
      console.log("🚀 ~ file: ShipperList.jsx:67 ~ getTMSUsers ~ e:", e)
    });
  };

  const get = async (data) => {
    const showAwaitablePrompt = Row && isAppointmentFeatureEnabled && Row?.pickupAppointmentSystem && !Row?.pickUpApptStatus?.includes(APPOINTMENT_STATUSES.CANCELLED);
    if(showAwaitablePrompt) {
      const confirmed = await openAwaitablePrompt();
      if (!confirmed) return;
    }
    if (Row) {
      let updatedShipper = Row.shipper ? Row.shipper.map(cons => cons._id) : [];
      updatedShipper[0] = data.value.toString();
      const payload = {
        shipper: [ ...updatedShipper],
        reference_number: Row.reference_number,
      };
      let _pickupTimes = _.cloneDeep(Row?.pickupTimes)
      if (_pickupTimes?.length > 0) {
        _pickupTimes[0].customerId = data.value.toString();
        payload['pickupTimes'] = _pickupTimes
      }
        let pullContainers = [];
        Row?.driverOrder?.forEach((d) => {
          if (d.type === loadEvents.PULLCONTAINER) {
            pullContainers.push(d);
          }
        })
      setIsAssignInProgress(true);
      editLoad(payload).then((res) => {
        onRowChange({ ...Row, shipper: [...res.shipper] });
        setIsAssignInProgress(false);
        onClose(false);
        successColorResponse(cell,column?.idx, Row?.className);
      }).catch((err) => {
        onClose(false);
        setIsAssignInProgress(false);
        errorColorResponse(cell,column?.idx, Row?.className)
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.cursor !== null && event.keyCode === 38 ) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`shipper-${cursor}`).focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= dList.length - 1) {
        if (cursor === null) {
          setCursor(0);
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`shipper-${cursor}`).focus();
        }
      }

      event.preventDefault();
    } else if (event.keyCode === 13 && event.cursor !== null) {
      dList.forEach((val, index) => {
        if (index === cursor) get(val);
      });
      event.preventDefault();
    }else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false);
      event.preventDefault();
      event.stopPropagation();
    }

  };

  return createPortal(
    <div
      ref={boxRef}
      className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
      style={Style}
      data-testid="shipper-list"
    >
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder={`Search ${updateTerminology("Shipper")}..`}
          id="domTextElement"
          onChange={(e) => setSearchShipper(e.target.value)}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          ref={searchInputRef}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul
        className="driverList m-0"
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        {dList?.length === 0 ? (
          <p className="p-1 bg-light">No Options</p>
        ) : (
          dList?.map((el, index) => (
            <li
              key={index}
              className={`popdrop__dropdown-item ${
                cursor === index ? "bg-gradient-light" : null
              }`}
              tabIndex="-1"
              id={`shipper-${index}`}
              onClick={() => get(el)}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              {el.label}
            </li>
          ))
        )}
      </ul>
      <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENT} />
    </div>,
    document.getElementById("pp-overlay-container")
  );
};
export default ShipperList;
