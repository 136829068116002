import React, {  useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { getTMSUsers } from "../../actionCreator";
import { makeOptionsForSelect } from "../../../../../../services/Common.services";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse , errorColorResponse } from "../../../utility";
import UpdateBillCharge from "../../../../Load/UpdateBillCharge";
import { checkAccountReceivablePermission } from "../../../../../../services";

const CustomerList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress ,column}) => {
  const { callers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const customers = makeOptionsForSelect(
    callers,
    "company_name",
    "address.address",
    "_id"
  );
  const [dList, setDList] = useState(customers);
  const [cursor, setCursor] = useState(null);
  const [searchCustomer, setSearchCustomer] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const { billingInvoiceEditPermission } = checkAccountReceivablePermission();
  const searchInputRef = React.useRef(null);
  const onSearch = useCallback(
    _.debounce((params) => getSeachData(params), 500),
    []
  );
  const cell = document.querySelector('[aria-selected="true"]');

  const boxRef = useRef();
  const Style = useBoxPosition(CellRef,boxRef)

  useEffect(() => {
    searchInputRef.current.focus();
    window.document.getElementById('root').style.pointerEvents="none"
    return ()=>{
      window.document.getElementById('root').style.pointerEvents="auto"
    }
  }, []);

  useEffect(() => {
      onChangeDebounced();
  }, [searchCustomer]);

  const onChangeDebounced = () => {
    onSearch(searchCustomer);
  };

  const getSeachData = (params) => {
    const obj = { limit: 10 }
    if (params) {
      obj.nameSearch = params
      obj.customerType = ['ALL', 'caller']
    }
    getTMSUsers(obj).then((data) => {
      setDList(data);
    });
  };


  useOnClickOutside(boxRef, () => { if(!isShowModal) onClose(false) });

  const get = (data, isUpdateBillToCustomer = false) => {
    if (Row) {
      const payload = {
        caller: data.value,
        reference_number: Row.reference_number,
        isUpdateBillToCustomer
      };
      setIsAssignInProgress(true);
      editLoad(payload)?.then((el) => {
        onRowChange({ ...Row, caller: data.allInfos });
        setIsAssignInProgress(false);
        onClose(false);
        successColorResponse(cell,column.idx, Row?.className);
      }).catch((err) => {
        setIsAssignInProgress(false);
        onClose(false);
        errorColorResponse(cell,column.idx, Row?.className)
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (cursor >= 0) {
        setCursor(cursor - 1);
        document.getElementById(`customer-${cursor}`).focus();
      }
      event.preventDefault();
    } else if (event.keyCode === 40) {
      if (cursor <= dList.length - 1) {
        if (cursor === null) {
          setCursor(0);
        } else {
          setCursor((prev) => prev + 1);
          document.getElementById(`customer-${cursor}`).focus();
        }
      }
      event.preventDefault();
    } else if (event.keyCode === 13) {
      dList.forEach((val, index) => {
        if (index === cursor) get(val);
      });
      event.preventDefault();
    } else if (event.keyCode === 9 || event.keyCode === 27) {
      onClose(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const onCustomerSelect = (data) => {
    if(billingInvoiceEditPermission && !isChargeDisable()) {
      setSelectedCustomer(data);
      setIsShowModal(true);
    } else {
      get(data);
    }
  }

  const updateBillToCustomer = (isUpdateBillToCustomer = false) => {
    setIsShowModal(false);
    get(selectedCustomer, isUpdateBillToCustomer)
  }

  const isChargeDisable = () => {
    const chargeDisableStatus = ["APPROVED","BILLING","PARTIAL_PAID", "FULL_PAID"];
    return chargeDisableStatus.includes(Row?.chargeStatus);
  }

  return createPortal(
    <div
      ref={boxRef}
      className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200"
      data-testid="customer-list"
      style={Style}
    >
      <div className="app-search bg-gray-50 rounded-lg mb-10">
        <input
          className="input-search-left form-control py-2"
          placeholder="Search Customer.."
          id="domTextElement"
          onChange={(e) => setSearchCustomer(e.target.value)}
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          ref={searchInputRef}
        />
        <span className="search-icon ml-1"></span>
      </div>

      <ul
        className="driverList m-0"
        style={{ maxHeight: "200px", overflowY: "auto" }}
      >
        {dList.length === 0 ? (
          <p className="p-1 bg-light">No Options</p>
        ) : (
          dList.map((el, index) => (
            <li
              key={index}
              className={`popdrop__dropdown-item ${
                cursor === index ? "bg-gradient-light" : null
              }`}
              tabIndex="-1"
              id={`customer-${index}`}
              onClick={async () => onCustomerSelect(el)}
              onKeyDown={(e) => handleKeyDown(e)}
            >
              {el.label}
            </li>
          ))
        )}
      </ul>
      {isShowModal && billingInvoiceEditPermission && !isChargeDisable() &&
        <UpdateBillCharge show={isShowModal} onselect={(value) => updateBillToCustomer(value)} close={() => setIsShowModal(false)} />
      }
    </div>,
    document.getElementById("pp-overlay-container")
  );
};
export default CustomerList;
