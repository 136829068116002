import { SCREENGRAB_CATEGORIES_HUMAN } from "constants/appointmentSystem.constant";

const PERDIEM_FIGHTING_DOCUMENT_TYPES = Object.freeze({
  ...SCREENGRAB_CATEGORIES_HUMAN,
});

const MARKET_CODE_TIMEZONES = {
  "1": "America/Los_Angeles",
  "2": "America/New_York",
  "3": "America/Amsterdam",
  "4": "America/Chicago",
  "5": "America/New_York",
  "6": "America/New_York",
  "7": "America/New_York",
  "8": "America/New_York",
  "9": "America/New_York",
  "10": "America/New_York",
  "11": "America/New_York",
  "12": "America/New_York",
  "13": "America/Edmonton",
  "14": "America/Edmonton",
  "15": "America/Toronto",
  "16": "America/New_York",
  "17": "America/Los_Angeles",
  "18": "America/Chicago",
  "19": "America/Los_Angeles",
  "20":"America/Los_Angeles",
  "21":"America/New_York",
  "22":"America/New_York",
  "23":"America/Vancouver",
  "24":"America/New_York",
  "25":"America/Denver",
  "26":"America/Chicago",
  "27":"America/Denver",
  "28":"America/New_York",
  "29":"America/Phoenix",
  "30":"America/Los_Angeles",
  "31":"America/Chicago",
  "32":"America/Chicago",
  "33":"America/New_York",
  "34":"America/Chicago",
  "35":"America/Chicago",
  "36":"America/New_York"
}

const MARKET_NAME_TIMEZONES = {
  "LA": "America/Los_Angeles",
  "NY": "America/New_York",
  "HOUSTON": "America/Chicago",
  "OAKLAND": "America/Los_Angeles",
  "VIRGINIA": "America/New_York",
  "NORFOLK": "America/New_York",
  "SEATTLETACOMA": "America/Los_Angeles",
  "MIAMI": "America/New_York",
  "JACKSONVILLE": "America/New_York",
  "CHARLESTON": "America/New_York",
  "SAVANNAH": "America/New_York",
  "ATLANTA": "America/New_York",
  "PHILADELPHIA": "America/New_York",
  "CALGARY": "America/Edmonton",
  "TORONTO": "America/Toronto",
  "BOSTON": "America/New_York",
  "CHICAGO": "America/Chicago",
  "MINNESOTA": "America/Los_Angeles",
  "MEMPHIS": "America/New_York",
  "MONTREAL": "America/New_York",
  "VANCOUVER": "America/Vancouver",
  "DETROIT": "America/New_York",
  "DENVER": "America/Denver",
  "DALLAS_FW": "America/Chicago",
  "UTAH": "America/Denver",
  "GREATER_NY": "America/New_York",
  "ARIZONA": "America/Phoenix",
  "NEVADA": "America/Los_Angeles",
  "NEBRASKA": "America/Chicago",
  "GREATER_TX": "America/Chicago",
  "KENTUCKY": "America/New_York",
  "KANSAS_CITY": "America/Chicago",
  "NEWORLEANS": "America/Chicago",
  "NORTH_CAROLINA": "America/New_York"
}

const MARKET_TIMEZONES = Object.freeze({
  ...MARKET_CODE_TIMEZONES,
  ...MARKET_NAME_TIMEZONES,
});

export { PERDIEM_FIGHTING_DOCUMENT_TYPES, MARKET_TIMEZONES };
