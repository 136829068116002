import React, { useEffect, useMemo, useRef, useState } from "react";
import DynamicTableHeight from "../../../../Components/Common/DynamicTableHeight";
import { IconEyeShow, IconPen, IconTrash } from "../../../../Components/Common/Icons";
import _ from "lodash";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { isAtBottom } from "../../NewDispatcher/DispatcherTable/Functions";
import RatesPromptAlert from "../Components/ratesPromptAlert";
import { ChargeProfilesFromGroup } from "pages/tms/Components/ChargeProfilesFromGroup";

const ChargeGroupsTable = ({ 
  editModal, 
  chargeGroupList, 
  removeChargeProfile, 
  deleteChargeProfileGroup, 
  scrolledToEnd, 
  handleSelectRow, 
  checkedChargeGroups = [],
  isLoading,
  showEditOptions = true,
  showDeleteOption = true,
  showCheckbox = false,
  vendorType,
  isFetchingPopulatedData = false,
}) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkedRows, setCheckedRows] = useState([...checkedChargeGroups]);
  const [expandedRows, setExpandedRows] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const tableRef = useRef();

  const confirmDelete = () => {
    if(deleteChargeProfileGroup) {
      deleteChargeProfileGroup(recordToDelete);
    }
    setIsDeleteModalOpen(false);  // Close the modal after delete
    setRecordToDelete(null);     // Reset the record to delete
  };

  useEffect(() => {
    const isEqual = _.isEqualWith(chargeGroupList, checkedRows, (obj1, obj2) => obj1?._id === obj2?._id);
    const isAllChecked = (chargeGroupList?.length && checkedChargeGroups?.length) && (chargeGroupList?.length === checkedChargeGroups?.length) && isEqual;

    setAllChecked(isAllChecked ?? false);
  }, [chargeGroupList, checkedChargeGroups]);

  useEffect(() => {
    setAllChecked(chargeGroupList?.length === checkedRows?.length);
    if(handleSelectRow) handleSelectRow("chargeGroups", checkedRows);
  }, [checkedRows])

  const handleShowRowExpansionModal = (index) => {
    setExpandedRows(index);
  };

  const handleHideRowExpansionModal = (index) => {
    setExpandedRows(false);
  };

  const handleScroll = (e) => { 
    const { currentTarget } = e;
    const hasScrollBar = (currentTarget.scrollHeight - currentTarget.clientHeight) > 0;
    if(scrolledToEnd && hasScrollBar && !isAtBottom(e)) scrolledToEnd(true);
  };


  const handleHeaderCheckboxToggle = () => {
    setCheckedRows(!allChecked ? chargeGroupList.filter((d)=>d.chargeTemplates?.length) : []);
    setAllChecked(!allChecked);
  };

  const handleCheckboxToggle = (isChecked, charge) => {
    if(!isChecked) setCheckedRows(checkedRows?.filter(d => d?._id !== charge?._id));
    else setCheckedRows([...checkedRows, charge ]);
  };

  const rows = useMemo(() => {
    const row = [];

    chargeGroupList?.forEach((chargeGroup, i) => {
      const isRowChecked = checkedRows?.find((d) => d?._id === chargeGroup?._id) ?? false;
      const groupRow = (
        <>
          <tr key={`main-${i}`} className="expanded-row">
            { showCheckbox && 
              <td>
                <input
                  type="checkbox"
                  className="form-control"
                  name="select-row-charge-profile"
                  checked={isRowChecked}
                  disabled={!chargeGroup.chargeTemplates?.length}
                  onChange={(e) => handleCheckboxToggle(e.target.checked, chargeGroup)}
                />
              </td>
            }
            <td>
              <button className="btn btn-link px-0"  tabIndex="0" onClick={() => handleShowRowExpansionModal(i) }>
                <IconEyeShow />
              </button>
            </td>
            <td>{chargeGroup.name}</td>
            <td className="text-wrap">{chargeGroup.description}</td>
            { showEditOptions && 
              <td>
                <button className="btn btn-link" onClick={() => editModal(chargeGroup._id, chargeGroup)}>
                  <IconPen />
                </button>
              </td>
            }
            { showDeleteOption && 
              <td>
                <button onClick={() => {
                   setRecordToDelete(chargeGroup);   // Set the record to delete
                   setIsDeleteModalOpen(true); 
                }} className="btn btn-link">
                  <IconTrash />
                </button>
              </td>
            }
          </tr>
          {expandedRows === i && ( // Conditionally render based on the expanded state
            
                  <ChargeProfilesFromGroup
                    vendorType= {vendorType}
                    showDeleteOptions={true}
                    showChargeProfileGroup={expandedRows === i}
                    chargeName={chargeGroup?.name}
                    chargeProfileGroupId={chargeGroup?._id}
                    hide={handleHideRowExpansionModal}
                    removeChargeProfile={(profile) => {
                      if (profile) {
                        removeChargeProfile(chargeGroup, profile);
                      }
                    }}
                    chargeProfilesData= {chargeGroup?.chargeTemplates}
                  />
                
          )}
        </>
      );

      row.push(groupRow);
    });

    return row;
  }, [chargeGroupList, expandedRows, checkedRows]);

  useEffect(() => {
    tableRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      tableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {editModal && <DynamicTableHeight pageId="charge-templates-table" /> }
      <div 
        ref={tableRef}
        className={`${editModal ? 'table-responsive' : 'overflow-auto d-block'}`} 
        style={{ 
          height: editModal 
          ? 'calc(100vh - var(--dynamic-table-height-offset))'  
          : 'calc(100vh - 271px)' // fallback height when modal is open in Rates Profile selecting charge group 
        }}>
        <table className="table table-card"
        >
          <thead>
            <tr>
              { showCheckbox &&  
                <th width="40px">
                  <input
                    type="checkbox"
                    className="form-control"
                    name="select-all"
                    checked={allChecked}
                    onChange={handleHeaderCheckboxToggle}
                  />
                </th>
              }
              <th width="40px"></th>
              <th>Group Name</th>
              <th>Charge Description</th>
              { showEditOptions &&  <th width="40px"></th> }
              { showDeleteOption && <th width="40px"></th> }
            </tr>
          </thead>
          <tbody>
            {isLoading && <LoaderBar fullPageLoader={true}  /> }
            { !chargeGroupList?.length && <tr><td className="text-capitalize" colSpan="100%">No data found</td></tr> }
            {rows}
          </tbody>
        </table>
        {isFetchingPopulatedData && <LoaderBar />}
      </div>
      {isDeleteModalOpen && (
        <RatesPromptAlert 
          showPrompt={isDeleteModalOpen} 
          closePrompt={() => setIsDeleteModalOpen(false)} 
          confirmDelete={confirmDelete}
          content={`Are you sure you want to delete the charge profile group "${recordToDelete?.name}"?`}
        />
      )}
    </>
  );
};

export default ChargeGroupsTable;
