import React,{ useEffect } from 'react'

function useOnKeyDown(ref, handler) {
  useEffect( () => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      else if(event.keyCode === 27 || event.keyCode === 9){
        handler();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  },

  [ref, handler]);
}

export default useOnKeyDown
