import moment from "moment";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
// import $ from 'jquery';
import { connect } from "react-redux";
import { Link } from "react-router";
import { bindActionCreators } from "redux";
import { displayName, getStorage, toastr } from "../../../services/Common.services";
import Avatar from "../Avatar";
import {
  IconAngleArrowDown,
  IconAngleArrowTop,
  IconAttachment,
  IconSendMessage,
  IconTimes,
  IconFile
} from "../Icons";
import * as actionCreators from "./actionCreators";
import "./style.css";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import CellSpinner from "../Spinner/CellSpinner";
import _ from "lodash";

let loggedInUser;
let timeZone;
class ChatBoxWrapperNew extends Component {
  constructor(props) {
    loggedInUser = JSON.parse(getStorage("loggedInUser"));
    timeZone = getTimeZone({ preferred: true });
    super(props);
    this.scroll = React.createRef();
    this.state = {
      text: "",
      dragging: false,
      acceptedFiles: undefined,
      loadingMore: false, // Prevent multiple fetches
      hasMore: true, // Indicates whether more messages are available
    };

    this.sendMessage = this.sendMessage.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    this.scrolledToTop = false;
    this.prevScrollHeight = null;

    this.adjustScrollPosition = this.adjustScrollPosition.bind(this);
  }

  componentWillMount() {
    this.props.actions.readByGroupChat(this.props._id).then(() => {});
  }

  componentDidMount() {
    // Scroll to bottom on initial load
    setTimeout(() => {
      let ele = document.getElementById(`member_${this.props._id}`);
      ele && ele.scrollTo(0, ele.scrollHeight);
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const { groups, _id } = this.props;
  
    if (prevProps.groups !== groups) {
      const ele = document.getElementById(`member_${this.props._id}`);
      const currentGroup = groups.find((group) => group._id === _id);
      const prevGroup = prevProps.groups.find((group) => group._id === _id);
  
      if (ele && currentGroup && prevGroup) {
        const currentMessageCount = currentGroup.messages?.length || 0;
        const prevMessageCount = prevGroup.messages?.length || 0;
  
        if (currentMessageCount > prevMessageCount) {
          this.adjustScrollPosition(ele);
        }
      }
    }
  }

  adjustScrollPosition(ele) {
    if (!ele) return;

    // Calculate the previous and current scroll heights
    const prevScrollHeight = this.prevScrollHeight || 0;
    const currentScrollHeight = ele.scrollHeight;

    // Adjust the scroll position to maintain the user's viewport after new messages have been added.
    if (this.scrolledToTop) {
        ele.scrollTop = currentScrollHeight - prevScrollHeight;
    }

    // Reset tracking variables
    this.prevScrollHeight = null;
    this.scrolledToTop = false;
  }

  handleScroll(event) {
    const element = event.target;

    // Trigger load more when scrolled to the top
    if (element.scrollTop === 0 && !this.state.loadingMore && this.state.hasMore) {
      this.scrolledToTop = true;
      this.prevScrollHeight = element.scrollHeight;

      this.setState({ loadingMore: true }, async () => {
        const { _id, groups, actions } = this.props;
        const currentGroup = groups.find((group) => group._id === _id);

        if (currentGroup) {
          const { messages = [] } = currentGroup;
          const skip = messages.length;

          // Fetch older messages
          await actions
            .getChatByGroupIds({ _ids: [_id], skip })
            .then((res) => {
              if (res && res[0] && res[0].messages) {
                const newMessages = res[0].messages;

                if (newMessages.length === 0) {
                  this.setState({ hasMore: false });
                } else {
                  // Combine new and old messages, removing duplicates if necessary
                  const updatedMessages = _.uniqBy([...newMessages, ...messages], 'sentAt');

                  // Update the local state with updated messages
                  const updatedGroups = _.cloneDeep(groups);
                  const groupIndex = updatedGroups.findIndex((g) => g._id === _id);
                  if (groupIndex > -1) {
                    updatedGroups[groupIndex].messages = updatedMessages;
                  }
                  actions.setGroupChats(updatedGroups);
                }
              }
            })
            .catch((err) => console.error(err))
            .finally(() => {
              this.setState({ loadingMore: false });
            });
        }
      });
    }
  }
  
  sendMessage(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    const { text, acceptedFile } = this.state;

    if (acceptedFile) {
      this.setState({ uploading: true });
      const formData = new FormData();
      formData.append("document", acceptedFile);
      formData.append("filename", text);

      this.props.actions.uploadChatfile(formData).then((res) => {
        const data = { msg: res.data.url, type: "url", _id: this.props._id };

        this.props.actions
          .sendGroupChatMsg({ ...data })
          .then((res) => {
            const groups = _.cloneDeep(this.props.groupChatList)
            const groupIndex = groups?.findIndex((g) => g?._id+ "" === res?._id+"")
            if(groupIndex > -1) groups[groupIndex] = res
            this.props.actions.setGroupChats(groups);

            this.setState({ text: "", acceptedFile: undefined, uploading: false, isSaving: false })
          })
          .catch((err) => {
            console.error(err);
            this.setState({ uploading: false, acceptedFile: undefined, text: "", isSaving: false })
          });
      }).catch((err) => {
        console.error(err);
        this.setState({ uploading: false });
      });
    } else {
      const data = { msg: text, type: "text", _id: this.props._id };
      this.props.actions
        .sendGroupChatMsg({ ...data })
        .then((res) => {
          this.setState({ text: "", isSaving: false });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ text: "", isSaving: false });
        });
    }
  }

  deleteFile(file) {
    const acceptedFiles = this.state.acceptedFiles.filter(
      (data) => data.name != file.name
    );
    this.setState({ acceptedFiles });
  }

  onDrop(acceptedFiles) {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      if (!["png", "jpg", "jpeg", "pdf"].includes(file.name.split(".").pop())) {
        return toastr.show("Please select only PDF, JPG, PNG file formats.", "error");
      }

      this.setState({
        isUploading: true,
        acceptedFile: file,
        text: file.name,
      });
    }
  }

  // stringToJSX(str) {
  //   let parts = str.split(/(#[MER]{1}[0-9]*)/gi);
  //   for (var i = 1; i < parts.length; i += 2) {
  //     parts[i] = (
  //       <Link
  //         to={`/tms/customer-service/load?reference_number=${parts[i]
  //           .replace("#", "")
  //           .toUpperCase()}`}
  //       >
  //         {parts[i].toUpperCase()}
  //       </Link>
  //     );
  //   }
  //   return parts;
  // }
  handleMessage(e) {
    this.setState({text: e.target.value})
  }

  removeDuplicateMessages (messages, prop) {
    const seen = new Set();
    return messages.filter(msg => {
      const identifier = msg[prop];
      if (seen.has(identifier)) {
        return false;
      }
      seen.add(identifier);
      return true;
    });
  };
  
  render() {
    const { _id, members, title, index, type, toggleChatBox, closeChatBox, hide, groups } = this.props;

    let messages = [];
    const currentGroup = groups.find((group) => group._id === _id);
    if (currentGroup) {
      messages = this.removeDuplicateMessages(currentGroup.messages, 'sentAt');
    }

    const toPerson = members?.find((d) => d._id !== loggedInUser._id);

    return (
      <div
        className="chatbox rounded-5"
        style={{
          right: index * 315 + 15 + index * 10,
          display: index > 4 ? "none" : "",
        }}
        rel={`group_${index}`}
      >
        <div
          className="chatbox__head bg-white d-flex align-items-center px-15 py-10"
          onClick={() => toggleChatBox(_id)}
        >
          <div className="chatpop-image">
            {type === "groups" && <Avatar size={30}>{displayName(title)}</Avatar>}
            {type !== "groups" ? (
              toPerson?.profilePicture ? (
                <Avatar src={toPerson.profilePicture} />
              ) : (
                <Avatar>{displayName(toPerson && `${toPerson.name} ${toPerson?.lastName ?? ""}`)}</Avatar>
              )
            ) : null}
          </div>
          <div className="ml-10 text-truncate">
            <h5 className="font-14 mb-0 text-truncate">
              <a href="#" className="text-dark d-flex align-items-center">
               <span className={`${toPerson?.name?.length > 10 ? "w-200 text-truncate" : ""}`}>
                  {type === "groups" ? title : toPerson ? `${toPerson.name} ${toPerson?.lastName ?? ""}` : ""}
                </span>

                {!hide ? <IconAngleArrowDown className="ml-2" /> : <IconAngleArrowTop className="ml-2" />}
              </a>
            </h5>

            {type === "customer" && (
              <div className="badge badge-gray-300 mt-1">
                {toPerson.company}
              </div>
            )}

            {type === "groups" && (
              <div className="group d-flex align-items-center mt-1">
                {members &&
                  members.length &&
                  members.map((member, i) => {
                    if (i > 5) {
                      return false;
                    }

                    return (
                      <div className="group__avatar test">
                        {member && member.profilePicture ? (
                          <Avatar  src={member.profilePicture} className="avatar-xs" />
                        ) : (
                          <Avatar  className="avatar-xs">
                            {displayName(member && member.name)}
                          </Avatar>
                        )}
                      </div>
                    );
                  })}
                {members && members.length > 5 && (
                  <Avatar size={10}>+{members.length - 5}</Avatar>
                )}
              </div>
            )}
          </div>
          <a href="javascript:void(0)" className="ml-auto text-muted" onClick={() => closeChatBox(_id)}>
            <IconTimes className="text-muted wh-20px" />
          </a>
        </div>
        {!hide && (
          <div
            className="chatpop shadow-none chatpop--full"
            style={{
              transition: "display 1.5s cubic-bezier(0.5, 0.94, 0.93, 0.51) 0s",
            }}
          >
            <div
              data-ps-id="e299ca54-d31d-62db-445d-f6507685af6c"
              className={`chatpop__conversation msg_container_base ${
                !hide ? "chatpop__conversation--popdrop px-3 py-4" : ""
              }`}
              id={`member_${_id}`}
              ref={this.scroll}
              onScroll={this.handleScroll}
            >
              {messages.map((obj, key) => {
                  let fileType = obj.msg.split('/').pop().split('.').pop().split('?AWSAccessKeyId')?.[0];
                  return (
                    <ul className="list-unstyled mb-0" key={obj._id}>
                      {/* For Date Title */}
                      {/* <li className="chat-day-title">
                      <div className="title">June 21, 2021</div>
                    </li> */}

                      <li
                        className={`chatpop__conversation__list ${
                          loggedInUser && loggedInUser._id === obj.sentBy._id
                            ? "right"
                            : ""
                        }`}
                      >
                        <div className="chatpop-image">
                          {obj.sentBy.profilePicture ? (
                            <Avatar src={obj.sentBy.profilePicture} />
                          ) : (
                            <Avatar>{displayName(`${obj.sentBy.name} ${obj.sentBy?.lastName ?? ''}`)}</Avatar>
                          )}
                        </div>
                        <div className="chat__wrap w-100">
                          <div>
                            <div className={`${(fileType === "pdf" || obj.type === "text") ? 'chatpop__bubble text-break' : 'pl-0 pr-0 bg-transparent' }`}>
                              {obj.type === "text" ? (
                                obj.msg
                              ) : (
                                <div>
                                  {fileType !== "pdf"  ? (
                                    <a onClick={() => window.open(obj.msg)}>
                                      <img
                                        src={obj.msg}
                                        className="d-block ml-auto rounded"
                                        style={{maxHeight: '150px', width: '100%', objectFit: 'cover'}}
                                      /> 
                                    </a>
                                  ) : (
                                    <div
                                      className="d-flex flex-row align-items-center chat__image-wrapper"
                                      key={key}
                                    >
                                      <a 
                                        className="d-flex flex-row align-items-center chat__image-wrapper"
                                        onClick={() => window.open(obj.msg)}
                                      >
                                        <div className="mr-2 avatar-circle">
                                          <IconFile />
                                        </div>
                                        <div className="mb-0 align-self-center d-flex align-items-center text-truncate">
                                          {obj.msg.split("/").pop().split('?AWSAccessKeyId')?.[0]}
                                        </div>
                                      </a>
                                    </div>
                                  )}
                                </div>

                                // <img
                                //   src={obj.msg}
                                //   className="d-block ml-auto"
                                // />
                              )}
                            </div>
                          </div>

                          <div className="chatpop__time">
                            {moment(obj.sentAt).tz(timeZone).fromNow()}
                          </div>
                        </div>
                      </li>
                    </ul>
                  );
                })}
            </div>
            <div
              className={`chatpop__input ${!hide == true ? "h-auto p-15" : ""}`}
            >
              <form
                onSubmit={(e) => {
                  this.sendMessage(e);
                }}
              >
                <div className="form-row">
                  <div className="col d-flex">
                    <Dropzone
                      multiple={false}
                      onDrop={(files) => this.onDrop(files)}
                    >
                      {({getRootProps, getInputProps}) => (
                        <div className="btn btn-outline-light btn-lg mr-2" {...getRootProps()}>
                          <input {...getInputProps()} style={{ display: "none" }} />
                          {this.state.uploading ? 
                            <CellSpinner />
                            :
                            <IconAttachment />
                          }
                        </div>
                      )}
                    </Dropzone>
                    <input
                      type="text"
                      id="textarea1"
                      placeholder="Write A Message..."
                      className="form-control"
                      value={this.state.text}
                      onChange={(e) => this.handleMessage(e)}
                      
                    />
                  </div>
                  <div className="col-auto">
                    <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.isSaving}>
                      <IconSendMessage className="text-white" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    userProfile: state.ProfileReducer,
    unreadUserMessages: state.HomeReducer.unreadMessages,
    groups: state.HomeReducer.groups,
    user: state.LoginReducer.profile,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBoxWrapperNew);
