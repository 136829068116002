import { MOVE_TYPES } from "constants/appointmentSystem.constant";
import { LOAD_STATUSES } from 'constants/tms.constant'
import moment from "moment";
import { DateTimeFormatUtils, getStorage } from "services";

export const formatDateFreeDay = (date) => {
  if (date) {
    return moment(date).tz(getStorage("timeZone")).format(DateTimeFormatUtils.abbreviatedDateFormat());
  } else {
    return "";
  }
};

export const formatToDateAndTime = (date) => {
  if (date) {
    return moment(date).tz(getStorage("timeZone")).format(DateTimeFormatUtils.abbreviatedDateTimeFormat());
  } else {
    return "";
  }
};

export const colorStatusBased = function (status) {
  let color = "red";
  if (status === "PENDING") {
    color = "#ff8099";
  } else if (status === "AVAILABLE") {
    color = "#bed96c";
  } else if (status === "DROPCONTAINER_DEPARTED") {
    color = "#c791d9";
  } else {
    color = "#A5DB03";
  }
  return color;
};

export const isAtMiddleOrLower = ({ currentTarget }, scrollPositionRef) => {
  const middleThreshold = currentTarget.scrollTop + 10;
  const _scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight / 2;
  const middlePosition = currentTarget.scrollHeight / 2;

  const currentScrollPosition = currentTarget.scrollTop;
  const scrollDifference = currentScrollPosition - scrollPositionRef.current;
  scrollPositionRef.current = currentScrollPosition
  const isScrollingDown = scrollDifference > 0;
  
  return {isMiddle: Math.abs(_scrollPosition - middlePosition) <= middleThreshold, isScrollingDown: isScrollingDown};
};

export const isAtBottom = ({ currentTarget }) => {
  return !(
    currentTarget.scrollTop + 10 >=
    currentTarget.scrollHeight - currentTarget.clientHeight
  );
};

export const BoxPosition = (Size, boxRef) => {
  const { x, y } = Size.current.parentNode.getBoundingClientRect();
  const clickX = x;
  const clickY = y;
  const screenW = window.innerWidth;
  const screenH = window.innerHeight;
  const rootW = boxRef.current.offsetWidth;
  const rootH = 200;

  const right = screenW - clickX > rootW;
  const left = !right;
  const top = screenH - clickY > rootH;
  const bottom = !top;
  const style = {
    position: "absolute",
    transition: "none",
    opacity: 1,
  };
  if (right) {
    style.left = `${clickX + 5}px`;
  }

  if (left) {
    style.left = `${clickX - rootW - 5}px`;
  }

  if (top) {
    style.top = `${clickY + 5}px`;
  }

  if (bottom) {
    style.top = `${clickY - rootH - 5}px`;
  }

  return style;
};

const REGEXP = /^$/;
export const IsEmptyString = (text) => {
  return REGEXP.test(text);
};

export const CompareStrings = (o, n) => {
  if (o.length !== n.length) {
    return false;
  }
  return o.localeCompare(n) === 0;
};

export const generateStatusColor = (status) => {
  switch (status) {
    case "CONFIRMED":
      return "confirmed"
    case "ERROR":
      return "error"
    case "REQUESTED":
      return "requested"
    case "TENTATIVE":
      return "tentative"
    case "CANCELLED":
      return "cancelled"
    case "COMPLETED":
      return "completed"
    case "MISSED":
      return "missed"
    case "EXPIRED":
      return "expired"
    case "Open":
      return "blue-100"
    case "Scheduled":
      return "light"
    // Portbase
    case "DECLINED":
      return "danger"
    case "PENDING":
      return "warning"
    case "REJECTED":
      return "danger"
    default:
      break
  }
}


export const getScheduleButtonColor = (currentStatus, moveType) => checkForDisable(currentStatus, moveType) ? "btn-gray-100" : "btn-outline-primary"

export const checkForDisable = (currentStatus, moveType) => {
  const statuses = Array.isArray(currentStatus) ? currentStatus : [currentStatus]
  let isDisabled = false
  for (let index = 0; index < statuses.length; index += 1) {
    const loadStatusValues = Object.values(LOAD_STATUSES)
    const currentIndex = loadStatusValues.indexOf(statuses[index])
    isDisabled = moveType === MOVE_TYPES.PICKUP && currentIndex > loadStatusValues.indexOf(LOAD_STATUSES.PULLCONTAINER_ARRIVED) ||
      moveType === MOVE_TYPES.EMPTY && currentIndex > loadStatusValues.indexOf(LOAD_STATUSES.RETURNCONTAINER_ARRIVED)
    if (isDisabled)
      break
  }
  return isDisabled
}

export const isOddIndexAndHidden = (context, referenceNumber) => {
  const index = context?.sortedRows?.findIndex(row => row?.reference_number === referenceNumber);
  const isOddIndex = index % 2 !== 0;
  return isOddIndex && context?.isHideData;
};