import React, { useRef, useState, useEffect } from 'react'
import DateEditor from '../CellComponents/DateEditor';
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import { formatToDateAndTime } from '../../Functions';
import { useDispatch, useSelector } from 'react-redux';

const PickupToTimes = ({ row, column, onRowChange, isCellSelected }) => {
  const [showBox, setShowBox] = useState(false);
  const dispatch = useDispatch();
  const fAction = useSelector((state) => state.dispatcherTableReducer);
	const cellRef = useRef();
	const prevSelectionRef = useRef();
  const [isColorPortal, setColorPortal] = useState(false);

	const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel();
		}
		prevSelectionRef.current = isCellSelected;
		if(!isCellSelected){
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {

		cellColorSetter(cellRef, row?.colorOfCells?.pickupToTime);
    }, [column, row?.colorOfCells?.pickupToTime]);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "pickupTimes") {
			onRowChange({ ...row, pickupTimes: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])

    return (
		<>
			<div ref={cellRef} className="cell-content" onClick={() => showModel()} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
				{row.pickupTimes &&
					row.pickupTimes
						.map((obj) => {
							return formatToDateAndTime(obj.pickupToTime);
						})
						.compact()
						.join(" ")}
				{!row.pickupTimes?.find(el => el.pickupToTime) && <span data-testid="date-field" className='date_field'></span>}
			</div>
			{row?.isEditPermission && showBox && row.pickupTimes?.length > 0 &&
				<DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onOpen={showModel}
					row={row}
					column={column}
					onRowChange={onRowChange} 
				/>
			}
			{isColorPortal &&
				<ColorPortal  CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName= "pickupToTime" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
			}
		</>
    );
}

export default PickupToTimes;