import { DateTimeFormatUtils, isValidContainer, updateTerminology } from 'services';
import { getCarrierProfileFilter } from '../DrayosCarrier/actions';
import { getCustomers } from './actionCreators';
import { LOAD_LABELS } from 'labels';
import { getColumns } from '../NewDispatcher/DispatcherTable/column';
import moment from 'moment-timezone';
import { getTimeZone } from '../../trucker/Tracking/constant';

export function returnLegColor({ type, status }) {
  let obj = { status };
  if (type.includes("PULL"))
    obj.leg = "PULL";
  if (type.includes("DROP"))
    obj.leg = "DROP";
  if (type.includes("DELIVER"))
    obj.leg = "DELIVER";
  if (type.includes("RETURN"))
    obj.leg = "RETURN";
  if (["DROPYARD"].indexOf(type) > -1) {
    obj.status = "DROP AT";
  }
  if (["RETURNCONTAINER", "DELIVERLOAD"].indexOf(type) > -1) {
    obj.status = "RETURN TO";
  }
  return obj;
}

export const TRANSFORM_HEADER = {
  caller: LOAD_LABELS.CUSTOMER,
  shipper: LOAD_LABELS.PICK_UP_LOCATION,
  consignee: LOAD_LABELS.DELIVERY_LOCATION,
  consigneeAddress: `${updateTerminology('consignee')} Address`,
  emptyOrigin: LOAD_LABELS.RETURN_LOCATION,
  chassisPick: "Hook Chassis",
  chassisTermination: "Terminate Chassis",
  containerType: LOAD_LABELS.CONTAINER_TYPE,
  containerSize: LOAD_LABELS.CONTAINER_SIZE,
  containerOwner: LOAD_LABELS.CONTAINER_OWNER,
  chassisType: LOAD_LABELS.CHASSIS_TYPE,
  chassisSize: LOAD_LABELS.CHASSIS_SIZE,
  chassisOwner: LOAD_LABELS.CHASSIS_OWNER,
  isReadyForPickup: "Is Ready To Return",
  currentLocation: "Current Location",
  locationId: "Current Location",
  deliveryOrder: LOAD_LABELS.VESSEL_NAME,
  deliveryOrderNo: LOAD_LABELS.VESSEL_NAME
}

let freeze = false //mark delay 
let timer //saved timer
export const getCustomerAsyncSearch = async (strSearch,limit, customerType,isWithOutAddress) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCustomers(strSearch,limit,customerType,isWithOutAddress)//request
        res(r);
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getCustomerAsyncSearchWithOutAddress = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCustomers(strSearch,null,[],true)//request
        res(r.map(data => {return {label: data.label, value: data.value} }));
      }, 1000)
    })

    p.then(function (x) {
      res(x);
    })
  });
};

export const getCarrierAsyncSearch = async (strSearch) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        const r = await getCarrierProfileFilter({searchText:strSearch})//request
        res(r?.drayosCarriers);
      }, 1000)
    })

    p.then(function (x) {
      let ob = []
      if(x?.length > 0) {
        ob = x.map(carrier => ({label:`${carrier.company_name}, ${carrier?.defaultAddress ? carrier?.defaultAddress: ' '} ${carrier?.city ? carrier?.city:""} ${carrier?.state ? carrier?.state : ""}`,value:carrier._id, allInfos:{company_name:carrier.company_name,address: `${carrier?.defaultAddress ? carrier?.defaultAddress: ' '} ${carrier?.city ? carrier?.city:""} ${carrier?.state ? carrier?.state : ""}` ,_id:carrier._id}}))
      }
      res(ob);
    })
  });
};

export const getCustomerAsyncSearchForDriverPlanner = async (searchQuery) => {
  const response = await getCustomers(searchQuery);
  return response;
}

export const driverPreferences = {
  states: [],
  loadTypes: [],
  distance: [],
  liquor: [],
  reefer: []
}

export const LOADSFILTERS = {
  grid3TypeOfLoad: 'Load Type',
  grid3caller: 'Customer',
  grid3consignee: LOAD_LABELS.DELIVERY_LOCATION,
  grid3emptyorigin: LOAD_LABELS.RETURN_LOCATION,
  grid3driver: 'Driver',
  grid3shipper: LOAD_LABELS.PICK_UP_LOCATION,
  grid3filterStatusField: LOAD_LABELS.LOAD_STATUS,
  isReadyForPickupShow: 'Ready To Return',
  terminal: 'Terminal',
  grid3ContainerType: LOAD_LABELS.CONTAINER_TYPE,
  grid3ContainerSize: LOAD_LABELS.CONTAINER_SIZE,
  grid3SSL: LOAD_LABELS.CONTAINER_OWNER,
  grid3createdByUser: 'Created By',
  grid3csr : 'CSR',
  grid3loadtemplate : LOAD_LABELS.LOAD_TEMPLATE,
  pickUpTo: LOAD_LABELS.PICK_UP_APPT_TO,
  pickupFrom: LOAD_LABELS.PICK_UP_APPT_FROM,
  deliveryFrom: LOAD_LABELS.DELIVERY_APPT_FROM,
  deliveryTo: LOAD_LABELS.DELIVERY_APPT_TO,
  returnAPTFrom: LOAD_LABELS.RETURN_APPT_FROM,
  returnAPTTo: LOAD_LABELS.RETURN_APPT_TO,
  lastFreeDayFrom: LOAD_LABELS.LAST_FREE_DAY,
  lastFreeDayTo: LOAD_LABELS.LAST_FREE_DAY,
  freeReturnFrom: `${LOAD_LABELS.PER_DIEM_LFD} From`, 
  freeReturnTo: `${LOAD_LABELS.PER_DIEM_LFD} To`,
  cutOffFrom: LOAD_LABELS.CUT_OFF_FROM,
  cutOffTo: LOAD_LABELS.CUT_OFF_TO,
  vesselName: 'Vessel Name',
  deliveryOrderNo:'Vessel Name',
  tenderStatus: 'Tender Status',
  selectedCarriers:'Carrier',
  routingEventStatus: 'Routing Event',
  grid3ChassisType: LOAD_LABELS.CHASSIS_TYPE,
  grid3ChassisSize: LOAD_LABELS.CHASSIS_SIZE,
  grid3ChassisOwner: LOAD_LABELS.CHASSIS_OWNER,
  pickUpApptStatus: "Pick Up Appt Status",
  emptyApptStatus: "Return Apt Status",
  isEdiTest: LOAD_LABELS.TEST_EDI,
}

export const LOAD_DATE_PAIRS = {
  pickupFrom: 'pickupTo',
  pickupTo: 'pickupFrom',
  deliveryFrom: 'deliveryTo',
  deliveryTo: 'deliveryFrom',
  returnAPTFrom: 'returnAPTTo',
  returnAPTTo: 'returnAPTFrom',
  lastFreeDayFrom: 'lastFreeDayTo',
  lastFreeDayTo: 'lastFreeDayFrom',
  freeReturnFrom: 'freeReturnTo',
  freeReturnTo: 'freeReturnFrom',
  cutOffFrom: 'cutOffTo',
  cutOffTo: 'cutOffFrom',
}

export const sortableFields = [
  { name: 'Load #', field: 'reference_number' },
  { name: 'LFD', field: 'lastFreeDay', field1: 'containerAvailableDay' },
  { name: 'CUT', field: 'cutOff' },
  { name: 'Per Diem LFD', field: 'freeReturnDate',},
  { name: 'PICKUP', field: 'pickupTimes.pickupFromTime' },
  { name: 'APT', field: 'deliveryTimes.deliveryFromTime' },
  { name: 'Return', field: 'returnFromTime' },
  { name: 'Driver', field: 'driverName', field1: 'driverOrderId.loadAssignedDate' },
  { name: 'Move', field: 'type_of_load' },
  { name: 'ETA', field: 'vessel.eta' },
  { name: 'Load Status', field: 'status' },
  { name: 'Customer', field: 'callerName' },
  { name: 'Port', field: 'shipperName' },
  { name: 'Consignee', field: 'consigneeName' },
  { name: 'Location', field: 'consigneeInfo.city' },
  { name: 'Container', field: 'containerNo' },
  { name: 'Chassis', field: 'chassisNo' },
  { name: 'Size', field: 'containerSizeName' },
  { name: 'Type', field: 'containerTypeName' },
  { name: 'SSL', field: 'containerOwnerName' },
  { name: 'PICKUP #', field: 'callerPONo' },
  { name: 'Next Address', field: 'nextDriverOrderId.company_name'},
  { name: 'Vessel Name', field: 'deliveryOrderNo'},
  { name: 'RTN', field: 'emptyOrigin'},
]

export const DEFAULT_STATUS = [
  'LFD',
  'COMBINE_PENDING',
  'AVAILABLE',
  'DROPPED',
  'FREERETURN',
  'COMBINE_DISPATCHED',
  'COMPLETED'
];

export const COMBINE_PENDING_STATUSES = [
  'PENDING',
  'DEPARTED'
];

export const COMBINE_DISPATCHED_STATUSES = [
  'DISPATCHED',
  'CHASSISPICK_ARRIVED',
  'CHASSISPICK_DEPARTED',
  'PULLCONTAINER_ARRIVED',
  'PULLCONTAINER_DEPARTED',
  'DELIVERLOAD_ARRIVED',
  'DELIVERLOAD_DEPARTED',
  'RETURNCONTAINER_ARRIVED',
  'RETURNCONTAINER_DEPARTED',
  'CHASSISTERMINATION_ARRIVED',
  'CHASSISTERMINATION_DEPARTED',
  'DROPCONTAINER_ARRIVED',
  'HOOKCONTAINER_ARRIVED',
  'HOOKCONTAINER_DEPARTED'
];

export const ALL_DISPATCHER_STATUS = [
  "AVAILABLE",
  "PENDING",
  "DEPARTED",
  "DISPATCHED",
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED",
  "HOOKCONTAINER_DEPARTED",
  "STOPOFF_DEPARTED",
  "STOPOFF_ARRIVED"
];

export const ACTIVE_DRIVER_LOAD_STATUSES = [
  "CHASSISPICK_ARRIVED",
  "CHASSISPICK_DEPARTED",
  "PULLCONTAINER_ARRIVED",
  "PULLCONTAINER_DEPARTED",
  "DROPCONTAINER_ARRIVED",
  "DROPCONTAINER_DEPARTED",
  "HOOKCONTAINER_ARRIVED,",
  "HOOKCONTAINER_DEPARTED",
  "DELIVERLOAD_ARRIVED",
  "DELIVERLOAD_DEPARTED",
  "RETURNCONTAINER_ARRIVED",
  "RETURNCONTAINER_DEPARTED",
  "CHASSISTERMINATION_ARRIVED",
  "CHASSISTERMINATION_DEPARTED",
];

export function cityStateOnlyAddress(address) {
  if (address) {
    let addresses = address.split(',');
    return `${addresses[1]}, ${addresses[2] ? addresses[2].trim().split(' ')[0] : ''}`?.replace(/,*$/, '')
  } else {
    return ''
  }
}

export const _getDriverFullName = (driver) => {
  return `${driver.name} ${driver.lastName || ''}`.trim()
}

export const loadFlags = [
  { label: 'Yes', value: true },
  { label: 'No', value: false}
]
export const routeTypes = [
  { label: 'Local', value: "Local" },
  { label: 'Highway', value: "Highway" }
]

export const excludeLoadFilter = {
  grid3caller: 'excludeCaller',
  grid3consignee: 'excludeConsignee',
  grid3emptyorigin: 'excludeEmptyOrigin',
  grid3driver: 'excludeDriver',
  grid3shipper: 'excludeShipper',
  grid3SSL: 'excludeContainerOwner',
}

export const defaultBottomActionsMenu = [
  "ready-to-return",
  "load-info",
  "load-time",
  "pickup-APT",
  "delivery-APT",
  "return-APT",
  "reference",
  "equipment-info",
  "documents",
  "charges",
  "live-share",
  "copy-container",
  "print"
]

export const STATUSES_NOT_INCLUDED_IN_FILTER = [
  "FULL_PAID", 
  "PARTIAL_PAID", 
  "REBILLING", 
  "UNAPPROVED", 
  "BILLING", 
  "APPROVED",
]

export const STATUSES_NOT_INCLUDED_IN_AVAILABLE_STREET_TURN_FILTER_ = [
  "FULL_PAID", 
  "PARTIAL_PAID", 
  "REBILLING", 
  "UNAPPROVED", 
  "BILLING", 
  "APPROVED",
  "COMPLETED"
]


export const problemContainerTableColumns = () =>[
  ...getColumns(),
  {
    key: "bobtail",
    name: LOAD_LABELS.BOBTAIL,
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 100,
    formatter: ({row}) => {
      return (
        <>
          {row.bobTail && (
            <div className='cell-content' style={{ color: "#a6d92c", textDecoration: "underline" }}>
              <div>
                {row.bobTail.customer.company_name},
                {row.bobTail && row.bobTail.destinationaddress
                  ? row.bobTail.destinationaddress.address
                  : ""}
                , {row.bobTail.city} ,{row.bobTail.state},{" "}
                {row.bobTail.status}
              </div>
            </div>
          )}</>
      )
    },
    hidden: false,
    colOptions: false
  },
  {
    key: "distance",
    name: "Distance",
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 100,
    formatter: ({row}) => {
      let startIndex =
        row.driverOrder &&
        row.driverOrder.findIndex(
          (status) => status.type === "PULLCONTAINER" && !status.isVoidOut
        );
      let endIndex =
        row.driverOrder &&
        row.driverOrder.findIndex(
          (status) => status.type === "DELIVERLOAD" && !status.isVoidOut
        );
      if (startIndex === -1) startIndex = 0;
      if (endIndex === -1)
        endIndex = row.driverOrder ? row.driverOrder.length - 1 : 0;
      return (
        <div className='cell-content'>
          {(
            (row.driverOrder &&
              row.driverOrder
                .slice(startIndex, endIndex + 1)
                .sum((obj) => obj.distance)) ||
            0
          ).toFixed(2)}
        </div>
      )
    },
    hidden: false,
    colOptions: false
  },
  {
    key: "error_message",
    name: "Error Message",
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 250,
    formatter: ({row}) => {
      let message = "";
      if (isValidContainer() && row.isContainerInvalid) {
        message += "Container # doesn't match check digit. ";
      }
      if (row?.loadTracks?.errorMessage) {
        message += (row?.loadTracks?.errorMessage);
      }
      return (
        <>
          <div className='cell-content'>{message}</div>
        </>
      )
    },
    hidden: false,
    colOptions: false
  },
  {
    key: "lastTrack",
    name: "Last Tracked",
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 250,
    formatter: ({row}) => {
      return (
        <>
          <div className='cell-content'>{(row?.loadTracks?.lastContainerTracedDate)
                  &&	moment(row?.loadTracks?.lastContainerTracedDate)
                    .tz(getTimeZone({ preferred: true }))
                    .format(DateTimeFormatUtils.fullDateTimeFormat())	}</div>
        </>
      )
    },
    hidden: false,
    colOptions: false
  },
  {
    key: "location",
    name: "Location",
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 100,
    formatter: ({row}) => {
      return <div className='cell-content'>{row.consigneeInfo &&
        row.consigneeInfo.city + 
        `${row.consigneeInfo.state ? "," +row.consigneeInfo.state :"" }`}</div>
    },
    hidden: false,
    colOptions: false
  },
  {
    key: "nextAddress",
    name: "Next Address",
    sortable: false,
    frozen: false,
    cellClass: "cell",
    minWidth: 100,
    formatter: ({row}) => {
      let nextAddress = row.nextDriverOrderId;
      if (row.status === 'DISPATCHED') {
        nextAddress = row.driverOrder[0];
      }
      return (
        <>
          <span className="badge badge-sm badge-light text-capitalize">
            {nextAddress && nextAddress.type.toLowerCase()}
          </span>

          <div>
            {nextAddress && (
              <>
                <div className="tooltip-label position-relative">
                  <div className="text-truncate w-150">
                    {nextAddress.company_name}
                  </div>
                  <div
                    className="tooltip bs-tooltip-bottom bg-dark text-white w-max rounded-10 px-3  py-2 text-capitalize"
                    data-placement="bottom-center"
                  >
                    <div className="arrow"></div>
                    {nextAddress.company_name}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )
    },
    hidden: false,
    colOptions: false
  }
]
