import React from "react";
import { IconLongArrowRight, IconTimer, IconCalendar } from "../../../../../Components/Common/Icons";
import ToggleSwitch from "./ToggleSwitch";

const TimeSlot = ({ slot: { startTime, startTimeValue, endTime, label, key }, timeBucket, index, handleRowClick, timePreferencesValue, isDayPreference = false }) => {
  const isPriority = timePreferencesValue?.[index]?.isPriority || false;
  const isFallback = timePreferencesValue?.[index]?.isFallback || false;
  const isDisabled = ![isPriority, isFallback].some((i) => i);
  const payload = {
    index,
    ...(key ? { ...key } : {}),
    ...(timeBucket ? { timeBucket } : {}),
    ...(startTimeValue ? { time: startTimeValue} : {})
  };

  const handleToggleChange = (toggleType, checked) => {
    if (checked) {
      handleRowClick({
        ...payload,
        label: toggleType,
        value: true,
        additionalUpdates: {
          [toggleType === 'isPriority' ? 'isFallback' : 'isPriority']: false
        }
      });
    } else {
      handleRowClick({
        ...payload,
        label: toggleType,
        value: false
      });
    }
  };
  
  return(
    <div className={`cursor-pointer d-flex align-items-center gap-5 outline-1 ${isDisabled ? 'outline-gray-100' : 'outline-primary'} rounded-3 p-10`}>
      {
        isDayPreference ? (
          <IconCalendar className={isDisabled ? 'text-gray-300' : 'text-primary'} />
        ) : (
          <IconTimer className={isDisabled ? 'text-gray-300' : 'text-primary'} />
        )
      }
      <div className={`d-flex align-items-center gap-5 font-14 line-height-20 font-medium ${isDisabled ? 'opacity-03' : ''}`}>
        {
          startTime && endTime ? 
          <>
            <span>{startTime}</span>
            <IconLongArrowRight className="text-gray-300" />
            <span>{endTime}</span>
          </>
          :
          <span>
            {label ?? ''}
          </span>
        }
      </div>
      <div className="d-flex align-items-center gap-10 ml-auto">
        <ToggleSwitch 
          id={`priority-${startTime}-${endTime}`}
          label="Priority"
          checked={isPriority}
          onChange={(e) => handleToggleChange('isPriority', e.target.checked)}
        />
        <ToggleSwitch 
          id={`fallback-${startTime}-${endTime}`}
          label="Fall Back"
          checked={isFallback}
          onChange={(e) => handleToggleChange('isFallback', e.target.checked)}
        />
      </div>
    </div>
  );
};

export default TimeSlot; 