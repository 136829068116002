import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import {
  Icondots,
  IconTrash,
  IconPrinter,
  IconAngleArrowLeft,
  IconAngleArrowRight,
  IconDownload,
  IconReturn,
  IconDargUpload,
} from "Components/Common/Icons";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import { HTTP } from "services";
import { DateTimeFormatUtils, fileNameTypeFromUrl, getStorage, toastr } from "services";
import Dropzone from "react-dropzone";
import _ from "lodash";
import { isCustomerPortalUser } from "utils";
import Doc from "./Components/Doc";
import { checkBillingConfig } from "pages/tms/carrier/AccountReceivable/utils";
import FileUploadSection from "./Components/FileUploadSection";
import FileUploading from "./Components/FileUploading";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { moveChargeDocuments } from "../Billing/actionCreator";
import CellSpinner from "../../../../Components/Common/Spinner/CellSpinner";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AllUploadedDocuments = (props) => {
  const [ShowUpDocumentUploadModal, setShowUpDocumentUploadModal] = useState(false);
  const [index, setIndex] = useState(null);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [isDisabledPrev, setIsDisabledPrev] = useState(true);
  const { selectedDoc, setSelectedDoc, chargeDetails, getAllDocForCharges, checkedDocs, loadId } = props;

  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [currPage, setCurrPage] = useState(1);
  const [listDocumentData, setListDocumentData] = useState([]);
  const [requireDocumentList, setRequireDocumentList] = useState([]);
  const [isReqOrDocs, setIsReqOrDocs] = useState(false);
  const [pasted, setPasted] = useState(false);
  const isVisibleForCustomer = props?.allowCustomers || !isCustomerPortalUser();
  const isRequired = checkBillingConfig("isDocRequiredForInvoice");
  const [upDocType, setUpDocType] = useState();
  const [isDropZoneEnabled, setEnabledDropZone] = useState(true);

  const [taskCompleted, setTaskCompleted] = useState(false);
  useEffect(() => {
    let copy = props.listDocuments.filter((listData) => {
      if (listData && listData._id) {
        return listData;
      }
    });
    if (isRequired) {
      const reqList = chargeDetails?.billToId?.requiredDocList;
      let docTypes = _.uniq(props.listDocuments.map((doc) => doc?.type));
      let requlist = reqList?.filter((type) => !docTypes.includes(type));
      setRequireDocumentList(requlist);
      if (requlist?.length) setIsReqOrDocs(true);
    }
    setListDocumentData(copy);
    if (taskCompleted) setTaskCompleted(false);
  }, [props.listDocuments]);

  const handleShowUpDocumentUploadModal = (doc, index, url) => {
    setSelectedDoc(index);
    setIndex(index);
    setCurrPage(1);
    let disabledPrev = index === 0;
    let disabledNext = index === listDocumentData.length - 1;
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
    setShowUpDocumentUploadModal(true);
  };
  const handleHideDocumentUploadModal = (event) => {
    setShowUpDocumentUploadModal(false);
  };
  const [isMoreOptions, setisMoreOptions] = useState();
  const handleisMoreOptions = (i) => {
    setisMoreOptions(isMoreOptions === i ? "" : i);
  };
  const handlePrev = () => {
    let indexValue = index - 1;
    let disabledPrev = indexValue === -1;
    let disabledNext = indexValue === listDocumentData.length;
    if (!disabledPrev) {
      setIndex(indexValue);
      setSelectedDoc(indexValue);
    }
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
  };
  const handleNext = () => {
    let indexValue = index + 1;
    let disabledPrev = indexValue === -1;
    let disabledNext = indexValue === listDocumentData.length;
    if (!disabledNext) {
      setIndex(indexValue);
      setSelectedDoc(indexValue);
    }
    setIsDisabledPrev(disabledPrev);
    setIsDisabledNext(disabledNext);
  };

  //For Closing OutsideClick
  const refs = useRef([]);

  //ClickOutside The Ref to close the document dropdown
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (!isNaN(isMoreOptions) && refs.current[isMoreOptions] && !refs.current[isMoreOptions].contains(e.target)) {
        setisMoreOptions(null);
        props.handlePopupOpen && props.handlePopupOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMoreOptions]);

  const handleKeyPress = (e) => {
    if (e.key === "ArrowLeft") {
      if (!isDisabledPrev) {
        handlePrev();
      }
    } else if (e.key === "ArrowRight") {
      if (!isDisabledNext) {
        handleNext();
      }
    } else if (e.key === "Escape") {
      handleHideDocumentUploadModal();
    }
  };

  const getOffset = (maxlimit, currentPosition) => {
    return (currentPosition * 100) / maxlimit;
  };

  const handleImageMagnifier = (e) => {
    var x = 0,
      y = 0;
    const parent = e.currentTarget;
    const parentBoundingRect = parent.getBoundingClientRect();
    x = e.pageX - parentBoundingRect.left;
    y = e.pageY - parentBoundingRect.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    const xOffset = getOffset(parentBoundingRect.width, x);
    const yOffset = getOffset(parentBoundingRect.height, y);
    parent.style.transition = "all .5s ease";
    parent.style.transform = `translate(${50 - xOffset}%,${50 - yOffset}%) scale(2)`;
  };

  const handleMouseLeave = (e) => {
    if (e.currentTarget) {
      const elem = e.currentTarget;
      elem.style.transition = "all .5s ease";
      elem.style.transform = "scale(1)";
      setScale(1);
    }
  };

  const onMouseEnter = (e) => {
    setScale(2);
  };

  const onMouseMove = (e) => {
    var x = 0,
      y = 0;
    const parent = e.currentTarget;
    const child = e.currentTarget.querySelector(".react-pdf__Page");
    const parentBoundingRect = parent.getBoundingClientRect();
    x = e.pageX - parentBoundingRect.left;
    y = e.pageY - parentBoundingRect.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    const xOffset = getOffset(parentBoundingRect.width, x);
    const yOffset = getOffset(parentBoundingRect.height, y);
    child.style.transition = "all 1s ease";
    child.style.transform = `translate(${50 - xOffset}%,${50 - yOffset}%) scale(1.5)`;
  };

  const onMouseLeave = (e) => {
    if (e.currentTarget) {
      const elem = e.currentTarget.querySelector(".react-pdf__Page");
      elem.style.transition = "all .5s ease";
      elem.style.transform = "scale(1)";
      setScale(1);
    }
  };

  let fileTypes = fileNameTypeFromUrl(listDocumentData && listDocumentData[index] && listDocumentData[index].url)[1];

  if (
    listDocumentData &&
    listDocumentData[index] &&
    listDocumentData[index].url?.includes("portpro-scraper-logs.s3.amazonaws.com") &&
    !["zip"]?.includes(fileTypes)
  ) {
    fileTypes = "pdf";
  }

  const fileUploader = (data, docType) => {
    if (data) {
      if (props.multi) {
        let allFiles = [];
        for (let index = 0; index < data.length; index++) {
          allFiles.push(data[index]);
        }
        props.uploadResult(allFiles, null, props.type, docType);
      } else {
        let reader = new FileReader();
        let file = data[0];
        reader.onload = () => {
          props.uploadResult(file, reader.result, props.type, docType);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const selectedElementId = useRef(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false); // State to track drag state

  const handleDrop = (docType) => {
    setTaskCompleted(true);
    setUpDocType(docType);
  };


  const handlePaste = (docType) => {
    setPasted(true);
  };

  const handleSelectClick = (fieldName) => {
    // You can trigger file selection for a specific field.
    if (dropzoneRefs[chargeDetails._id]) {
      dropzoneRefs[chargeDetails._id].open();
    }
  };

  const dropzoneRefs = {};

  const handleCardDrop = (acceptedFiles, chargeDetails, docType) => {
    setIsDraggingOver(false);

    const processFile = (file) => {
      const docFileName = file?.name?.toLowerCase();
      if (file.type.match("image.*") && file.size > 5242880) {
        toastr.show("File too Big, please select a file less than 5mb", "warning");
        return true;
      }
      if (
        docFileName.split(".").pop() !== "png" &&
        docFileName.split(".").pop() !== "jpg" &&
        docFileName.split(".").pop() !== "gif" &&
        docFileName.split(".").pop() !== "pdf" &&
        docFileName.split(".").pop() !== "jpeg" &&
        docFileName.split(".").pop() !== "csv" &&
        docFileName.split(".").pop() !== "xls" &&
        docFileName.split(".").pop() !== "eml" &&
        docFileName.split(".").pop() !== "xlsx"
      ) {
        toastr.show("Only select pdf, png, jpg, csv, xls, xlsx and jpeg file", "error");
        return true;
      }
      return false;
    };

    const updatedAcceptedFiles = acceptedFiles.map((file) => {
      file.chargeId = chargeDetails?._id;
      if (!chargeDetails?.isDefault) {
        file.customerId = chargeDetails?.billToId?._id;
      }
      return file;
    });

    if (updatedAcceptedFiles.some(processFile)) {
      return;
    }
    if (docType) {
      handleDrop(docType);
    }

    fileUploader(updatedAcceptedFiles, docType);
  };

  const handleDragEnter = (e) => {
    if(!isVisibleForCustomer || !isDropZoneEnabled) return;
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { relatedTarget } = e;
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDraggingOver(false);
    }
  };
  const handleFileChange = (e, chargeDetails) => {
    const file = e.target.files[0];
    if (file) {
      handleCardDrop(Array.from(e.target.files), chargeDetails)
      return
    }
  };

  const handleOnDocumentDrop = (e, chargeDetails, docType) => {
    if(!isVisibleForCustomer || !isDropZoneEnabled) return
    const file = e.dataTransfer.files[0];
    if (file) {
      handleCardDrop(Array.from(e.dataTransfer.files), chargeDetails)
      return
    }
    handlePaste();
    setIsDraggingOver(false);
    const data = JSON.parse(e.dataTransfer.getData("text") ?? "{}");
    if (docType && data.type !== docType) {
      toastr.show("Document Type must be same", "error");
      return;
    }
    if (docType) handleDrop(docType);

    if (data?.chargeId && data?.chargeId + "" === chargeDetails?._id + "") return;

    const payload = {
      documents: [data?._id],
      loadId,
      chargeId: chargeDetails?._id,
    };
    moveChargeDocuments(payload).then((response) => {
      setPasted(false);
      getAllDocForCharges();
    });
  };

  const handleEnabledDropZone = (flag) => {
    setEnabledDropZone(flag)
  }

  const handleDownload = async () => {
    try {
      const fileUrl = listDocumentData?.[index]?.url;

      if (!fileUrl) throw new Error("File url is required*");

      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      const fileName = fileUrl.split('/').pop().split('?')[0];
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <div className="bg-white mb-10">
        <div
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
          onDragEnter={handleDragEnter}
          onDrop={(e) => handleOnDocumentDrop(e, chargeDetails)}
          className={`${isDraggingOver ? "drag-over-document" : ""}`}
        >
          <div
            className={`card card-tborder border-gray p-10 mb-0 `}
          >
            <div className="d-flex align-items-center gap-20">
              <div>
                <div className="font-14 font-medium">{chargeDetails?.billToId?.company_name}</div>
                <div className="text-muted">
                  Charge Set #<span className="text-dark ml-1">{chargeDetails?.charge_reference_number}</span>
                </div>
              </div>
            </div>
            

            <section className=" mt-10 py-0" >
              {isVisibleForCustomer && <div className="dropzone dz-message p-0 d-flex flex-wrap align-items-center justify-content-center">
                <input
                  type="file"
                  id={`fileInput-${chargeDetails._id}`}
                  style={{ display: 'none' }}
                  ref={(node) => (dropzoneRefs["main" + chargeDetails?._id] = node)}
                  onChange={(e) => handleFileChange(e, chargeDetails)}
                  onClick={(e) => { e.target.value = null }}
                />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: `${isReqOrDocs || listDocumentData?.length > 0 ? "50px" : "200px"}` }}
                >
                  <div className="w-100 text-center d-flex align-items-center justify-content-center">
                    <IconDargUpload
                      className={`dropzone__uploadimage ${isRequired || listDocumentData?.length > 0 ? "wh-40px" : ""
                        }`}
                    />
                    <div className="text-muted font-12 mr-1 text-capitalize">
                      Drag & Drop your files here or{" "}
                      <button
                        className="btn btn-primary btn-sm text-capitalize"
                        type="button"
                        // onClick={handleSelectClick}
                        onClick={() => document.getElementById(`fileInput-${chargeDetails._id}`).click()}

                      >
                        Select file...
                      </button>
                    </div>
                  </div>
                </div>
              </div>}
              {(requireDocumentList?.length > 0 || listDocumentData?.length > 0) && <div className="p-10">
                <div className="bg-gray-50">
                  <div className="form-row-md p-10 rounded-6">
                    {isVisibleForCustomer && requireDocumentList?.map((doc, i) => {
                      return (
                        <FileUploadSection
                          documentLabel={doc}
                          handleDrop={handleCardDrop}
                          handleSelectClick={handleSelectClick}
                          dropzoneRefs={dropzoneRefs}
                          chargeDetails={chargeDetails}
                          isUploading={upDocType === doc}
                          taskCompleted={taskCompleted}
                          onDocDrop={handleOnDocumentDrop}
                          isDraggingOver={isDraggingOver}
                          setIsDraggingOver={setIsDraggingOver}
                        />
                      );
                    })}
                    {listDocumentData?.map((doc, i) => {
                      let [fileName, fileType] = fileNameTypeFromUrl(doc.url);
                      if (doc?.url?.includes("portpro-scraper-logs.s3.amazonaws.com") && !["zip"]?.includes(fileType)) {
                        fileType = "pdf";
                      }
                      if (!doc || !doc.url) {
                        return;
                      }
                      return (
                        <Doc
                          {...props}
                          fileName={fileName}
                          doc={doc}
                          isVisibleForCustomer={isVisibleForCustomer}
                          handleShowUpDocumentUploadModal={handleShowUpDocumentUploadModal}
                          fileType={fileType}
                          i={i}
                          isSelectDisabled={checkedDocs?.every((doc) => doc.chargeId) && checkedDocs?.find((doc) => doc.chargeId !== chargeDetails?._id)}
                          onCheckDoc={props.onCheckDoc}
                          updateList={props.updateList}
                          handleEnabledDropZone={handleEnabledDropZone}
                        />
                      );
                    })}
                    {/* {pasted && <FileUploading pasted={pasted} />} */}
                  </div>
                </div>
              </div>}
            </section>
          </div>
        </div>


        {(selectedDoc === 0 || selectedDoc) && (
          <Modal
            show={ShowUpDocumentUploadModal}
            centered
            animation={false}
            dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable"
            backdropClassName="z-1050"
            onKeyDown={handleKeyPress}
          >
            <Modal.Header className="d-flex align-items-start modal-header--gray">
              <div>
                <h5>{listDocumentData && listDocumentData[index] && listDocumentData[index].type}</h5>
                <div className="text-muted mb-1">
                  {fileNameTypeFromUrl(listDocumentData && listDocumentData[index] && listDocumentData[index].url)[0]}
                </div>
                <div className="text-muted mb-2">
                  {fileNameTypeFromUrl(listDocumentData && listDocumentData[index] && listDocumentData[index].url)[1]}
                </div>
                <div className="text-muted">
                  {moment(listDocumentData && listDocumentData[index] && listDocumentData[index].date).format(
                    DateTimeFormatUtils.fullDateTimeFormat()
                  )}
                </div>
              </div>
              <div className="form-check form-check-right form-switch form-switch-md">
                <label className="form-check-label text-muted">Ready For Sending</label>
                {props.docTab === "load" && (
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="document1"
                    checked={
                      listDocumentData && listDocumentData[index] && listDocumentData[index].checked ? true : false
                    }
                    onClick={() => props.addCheck(listDocumentData && listDocumentData[index])}
                  />
                )}
              </div>
            </Modal.Header>
            <Modal.Body className="py-30 doc-preview-modal">
              {listDocumentData && listDocumentData[index] && listDocumentData[index].url && (
                <div
                  id="holder"
                  className="modal-document-preview d-flex align-items-center justify-content-center"
                  style={{ overflow: "hidden", cursor: "zoom-in" }}
                >
                  <React.Fragment>
                    {fileTypes === "png" || fileTypes === "jpg" || fileTypes === "jpeg" ? (
                      <img
                        className="document-preview__image"
                        src={listDocumentData && listDocumentData[index] && listDocumentData[index].url}
                        alt={""}
                        onMouseMove={handleImageMagnifier}
                        onMouseLeave={handleMouseLeave}
                      />
                    ) : (
                      ""
                    )}
                    {fileTypes === "pdf" && (
                      <React.Fragment>
                        <Document
                          file={listDocumentData && listDocumentData[index] && listDocumentData[index].url}
                          onLoadSuccess={({ numPages }) => {
                            setPdfPageNumber(numPages);
                            props.onDocumentLoadSuccess && props.onDocumentLoadSuccess({ numPages });
                          }}
                          // onClick={onMouseClick}
                          onMouseEnter={onMouseEnter}
                          // onMouseMove={onMouseMove}
                          onMouseMove={onMouseMove}
                          onMouseLeave={onMouseLeave}
                        >
                          <Page scale={scale} pageNumber={currPage} renderAnnotationLayer={false} />
                        </Document>
                      </React.Fragment>
                    )}
                    {fileTypes === "zip" && ""}
                  </React.Fragment>
                </div>
              )}
              {fileTypes === "pdf" && (
                <div className="d-flex align-items-center text-center">
                  <button
                    className="btn btn-link"
                    disabled={currPage <= 1}
                    onClick={() => {
                      setCurrPage(currPage - 1);
                    }}
                  >
                    <IconAngleArrowLeft />
                  </button>
                  <div className="mx-15">
                    <span>{currPage || (currPage ? 1 : "--")}</span>
                    <span className="text-muted mx-1">/</span>
                    <span className="text-muted">{pdfPageNumber || "--"}</span>
                  </div>
                  <button
                    className="btn btn-link"
                    disabled={currPage >= pdfPageNumber}
                    onClick={() => {
                      setCurrPage(currPage + 1);
                    }}
                  >
                    <IconAngleArrowRight />
                  </button>
                </div>
              )}
              {fileTypes === "csv" || fileTypes === "xls" || fileTypes === "xlsx" ? (
                <DocViewer
                  className={"custom-doc-table table-bordered border-0"}
                  documents={[{ uri: listDocumentData?.[index]?.url, fileType: fileTypes }]}
                  pluginRenderers={DocViewerRenderers}
                  config={{ header: { disableHeader: true } }}
                  style={{ maxHeight: 'calc(100vh - 686px)'}}
                />
              ) : (
                ""
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <div className="d-flex align-items-center">
                <button className="btn btn-link" onClick={handlePrev} disabled={isDisabledPrev}>
                  <IconAngleArrowLeft />
                </button>
                <div className="mx-15">
                  <span>{index + 1}</span>
                  <span className="text-muted mx-1">/</span>
                  <span className="text-muted">{listDocumentData.length}</span>
                </div>
                <button className="btn btn-link" onClick={handleNext} disabled={isDisabledNext}>
                  <IconAngleArrowRight />
                </button>
              </div>
              <div className="d-flex align-items-center">
                {fileTypes === "pdf" && props.rotateDoc && isVisibleForCustomer && (
                  <button
                    className="btn btn-outline-light mr-2"
                    onClick={() => props.rotatePdf(listDocumentData[index])}
                    disabled={props.processing}
                  >
                    {props.processing ? 
                      <CellSpinner className="mr-2" />
                      : <IconReturn className="mr-2" /> 
                    }
                    Rotate
                  </button>
                )}
                <button
                  className="btn btn-outline-light mr-2"
                  onClick={handleDownload}
                >
                  <IconDownload className="mr-2" />
                  Download
                  {/* </a> */}
                </button>
                <button
                  className="btn btn-outline-light mr-2"
                  onClick={() =>
                    window.open(listDocumentData && listDocumentData[index] && listDocumentData[index].url)
                  }
                >
                  <IconPrinter className="mr-2" />
                  Print
                </button>
                {isVisibleForCustomer && (
                  <button
                    className="btn btn-outline-light mr-2"
                    onClick={() => {
                      props.deleteDocument(listDocumentData && listDocumentData[index] && listDocumentData[index]._id);
                      setShowUpDocumentUploadModal(false);
                    }}
                  >
                    <IconTrash />
                  </button>
                )}
                <button className="btn btn-primary" onClick={handleHideDocumentUploadModal}>
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </React.Fragment>
  );
};
export default AllUploadedDocuments;
