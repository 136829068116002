import moment from "moment";
import { statusType } from "../../../tms/LoadRouting/constants";
import { IconCheck, IconNavigationArrowBottom, IconTimes, IconTimesThin } from "Components/Common/Icons";
import { DateTimeFormatUtils, addressParser, getStorage } from "services";
import { useTrackingDriver, useTrackingLoad, useTrackingLoadDispatch, useTrackingShared } from "../context/TrackingContext";
import { LOAD_ACTIONS } from "../store/actions";
import { useCurrentUser } from "hooks";
import { getDurationInHHMMFormat, arrivedDateTimeForTracking, trackingDriverOrdersRoutes } from "../utility";
import { trackingSharedSelectors } from "../store/selectors";
import { isUserSpecificTimeZoneEnabled } from "../../../../services";
import { getTrackingEventClasses } from "utils";

const EventCard = ({
  driverOrder,
  driverOrders,
  orderIndex,
  isDriverOrderSelected,
  onClose,
  setSelectedDriverOrder = () => { },
  isOnMap,
  load,
  driverHistoryList = {},
  legIndex,
  startMove,
  isLastLeg = false,
  readonly
}) => {
  const { currentUserTimeZone } = useCurrentUser();
  const stateShared = useTrackingShared();
  const contextType = trackingSharedSelectors.currentTrackingContextType(stateShared);
  const  { selectedDriver } = useTrackingDriver() || {};
  const { selectedEvent } = useTrackingLoad() || {};
  
  const activeEvent = driverOrders.find(order => order?.arrived &&  (!order.isEndingMove && !order.departed));
  const isCurrentEventActive = activeEvent && activeEvent?._id === driverOrder._id;

  const loadDispatch = useTrackingLoadDispatch();

  const handleLoadHistory = async () => {
    loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
  };

  const handleEventCardClick = (e) => {
    if(readonly) return    
    e.stopPropagation();
    const selectedLegVal = {
      id: driverOrder._id,
      legType: driverOrder.type,
      legLocation: driverOrder.customerId.company_name,
      arrived: driverOrder.arrived,
      departed: driverOrder.departed,
      duration: driverOrder.durationTaken,
      moveId: driverOrder.moveId,
      isVoidOut: !!driverOrder.isVoidOut,
    };
    let arrivedDepartedDateTimes = arrivedDateTimeForTracking(driverOrders);
    const routes = trackingDriverOrdersRoutes(driverOrders, driverOrder.driver);
    setSelectedDriverOrder(selectedLegVal, arrivedDepartedDateTimes, routes, orderIndex);
    
    if(contextType === "loadInfo"){
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_MOVE, payload: driverOrder?.moveId });
      
      // const filterDriverOrder = driverOrders?.filter(d => d?.driver?._id === driverOrder?.driver?._id && d?.moveId ===  driverOrder?.moveId) ?? [];
      // const selectedDriverOrderIndex = filterDriverOrder?.findIndex(d => d?._id === driverOrder?._id);

      const _driverOrder = load?.driverOrder?.filter(d => d?.moveId === driverOrder?.moveId) ?? [];
      const selectedEventIndex = _driverOrder?.findIndex(d => d?._id === selectedLegVal?.id);
      const prevEvents = driverOrders?.[selectedEventIndex-1];
 
      let arrivedDate = selectedEventIndex === 0 ? _driverOrder[0]?.arrived : prevEvents?.departed;
      let departedDate;

      if(!arrivedDate) return loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });
      
      // if(selectedDriverOrderIndex == (filterDriverOrder?.length-1)) {
      //   arrivedDate = filterDriverOrder[selectedDriverOrderIndex-1]?.arrived;

      //   if(!arrivedDate) return loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });

      //   departedDate = filterDriverOrder[selectedDriverOrderIndex-1]?.departed || moment().toISOString();
      // } else 
      
      departedDate = driverOrder?.arrived || driverOrder?.departed || moment().toISOString();

      const selectedLoadHistory = driverHistoryList?.[legIndex]?.filter((obj) => {
        const startTime = moment(obj?.date_time || obj?.time || obj?.t).toISOString();
        const isInBetween = moment(startTime).isBetween(arrivedDate, departedDate);
        return isInBetween;
      })?.sort((a, b) => new Date(a?.date_time || a?.time || a?.t) - new Date(b?.date_time || b?.time || b?.t))

      if (selectedLoadHistory && selectedLoadHistory?.length > 0) {
        loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY, payload: selectedLoadHistory });
      }
    }

    loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: driverOrder });  
  }
  const timeZoneEnable = isUserSpecificTimeZoneEnabled();
  const timeZone = getStorage("timeZone");
  
	let durationTaken = driverOrder?.departed && driverOrder?.arrived ? getDurationInHHMMFormat(
		driverOrder?.departed, 
		driverOrder?.arrived, 
		(timeZoneEnable && {fromTimeZone: driverOrder?.timeZone || timeZone, toTimeZone: driverOrder?.timeZone || timeZone})) : null

  const firstStartDate = driverOrder?.startDate || load?.driverOrder[0]?.arrived;
  const firstArrivalDate = driverOrder?.arrived || load?.driverOrder[0]?.departed;

  let startToArrivalDuration = firstStartDate && firstArrivalDate ? getDurationInHHMMFormat(
    firstArrivalDate, 
    firstStartDate, 
    (timeZoneEnable && {fromTimeZone: driverOrder?.timeZone || timeZone, toTimeZone: driverOrder?.timeZone || timeZone})) : null


  const isEventActive = selectedEvent && selectedEvent?.moveId === driverOrder?.moveId;

  //Routing Color Conditons 
  const isArrived = driverOrder?.arrived;
  const isDeparted = driverOrder?.departed;
  const isVoidOut = driverOrder?.isVoidOut;
  
  const isLastEvent = isLastLeg || (driverOrders?.length - 1 === orderIndex);
  const isFirstEvent = orderIndex === 0;

  const isStartMoveEvent = isFirstEvent && startMove && !isArrived && !isDeparted &&  !isLastEvent;
  const isArrivedCompleted = isLastEvent && isArrived && !isVoidOut;
  const isCompleted = (isDeparted && isArrived && !isVoidOut) || isArrivedCompleted; 
  const isOnProgress = (isArrived && !isDeparted && !isLastEvent); 
  const isNextPending = !isVoidOut && isArrived && !isDeparted;

  const eventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress && !isNextPending, isNextPending && !isOnProgress);
  const topEventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress||isStartMoveEvent, isNextPending);

  const routeLineClass = `route-line route-line--tracking route-line--top ${!isLastEvent? "route-line--bottom": ""} ${isStartMoveEvent? eventClass?.routeLineColorClass : eventClass?.routeLineColorClass}`;
  const eventMainClass = `route-line route-line-full route-line--bottom ${topEventClass?.routeLineColorClass} ${topEventClass?.eventCircleColorClass}`  

  const visibleDriverOrders = driverOrders.filter(order => !order.isVoidOut);
  const isFirstVisibleEvent = visibleDriverOrders.findIndex(o => o._id === driverOrder._id) === 0;

  const nextValidEventIndex = driverOrders.findIndex((d, i) => i > orderIndex && !d.isVoidOut)
  const nextValidEvent = driverOrders[nextValidEventIndex]

  return (
    <>
      <div
        onClick={handleEventCardClick}
        className={`
          d-flex flex-column
          ${!isOnMap && isDriverOrderSelected && isEventActive ? "" : ""} 
          ${isOnMap ? "p-10 rounded-5 bg-white shadow-5" : ""} 
          ${!isOnMap && !isDriverOrderSelected ? "" : ""}
          `}
      >
        <div className="d-flex gap-5 align-items-start">
            {!isOnMap &&
            <div className="position-relative h-100 w-30px flex-shrink-0 d-flex align-items-start justify-content-center">
              <div className={`wh-20px d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle ${eventMainClass}`}>
              {
                isCompleted && !isVoidOut ? (
                  <IconCheck />
                ) : isVoidOut ? (
                  <IconTimesThin className="text-danger" />
                ) : orderIndex + 1
              }
              </div>
              
            </div>
            }
            <div className={`w-100 d-flex gap-5 pb-10 border-bottom-dashed-1 border-gray-200`}>
            <div className="d-flex flex-column align-items-start gap-2" style={{ maxWidth: 90, minWidth: 90 }}>
              <span
                className={`badge badge-sm 
                ${topEventClass?.badgeColorClass}
                px-1
                text-capitalize`}
              >
                {driverOrder?.type && statusType[driverOrder.type]}
              </span>
              {driverOrder?.isVoidOut && (
                <span className="badge badge-sm bg-danger text-white px-1 text-capitalize">Voided Out</span>
              )}
            </div>

          <div className="d-flex flex-column gap-3 w-100">
            <div className="font-12 font-medium d-flex pr-25 position-relative">
              {driverOrder.company_name ? driverOrder.company_name : "No Location Provided"}
              {isDriverOrderSelected && (
                <span
                  className="pointer ml-auto position-absolute right-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onClose) {
                      onClose();
                    } else {
                      setSelectedDriverOrder(null);
                    }
                    handleLoadHistory();
                  }}
                >
                  <IconTimes />
                </span>
              )}
            </div>
            <div className="text-muted">{driverOrder?.address && addressParser(driverOrder)}</div>
          </div>
          </div>
        </div>
        <>

          <div className="d-flex flex-column position-relative">
          {isFirstVisibleEvent && 
          <div className="d-inline-flex position-relative align-self-start">
            <div className="d-flex gap-5 align-items-center h-26px">
              {!isOnMap &&
              <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                {startMove && !isOnProgress && !isCompleted ?
                  <div 
                    className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                    style={{
                      outline: '5px solid rgba(54, 123, 246, 0.3)',
                    }}
                  >
                    <IconNavigationArrowBottom />
                  </div>
                  :
                  <div className={`wh-6px rounded-circle outline-1 ${topEventClass?.dotColorClass} ${routeLineClass}`}></div>
                }
              </div>

              }
              <div className="text-muted" style={{ width: 90 }}>Start </div>

              <div className="font-medium">
                {startMove ? startMove : "-"}
              </div>
            </div>
            <span
                className={`position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative left-100 ml-10 curved-arrow-sm curved-arrow-left-top curved-arrow-left-bottom ${
                  !isOnMap && isDriverOrderSelected ? "bg-brand-100" : ""
                }`}
                style={{ top: "90%", transform: "translateY(-50%)", left: "100%" }}
              >
                {firstStartDate && firstArrivalDate ? startToArrivalDuration : "-"}
              </span>
          </div>
          }

            <div className="d-inline-flex flex-column position-relative align-self-start">
              <div className="d-inline-flex gap-5 align-items-center h-26px">
                {!isOnMap &&
                <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                  
                  {isOnProgress ?
                    <div 
                      className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                      style={{
                        outline: '5px solid rgba(54, 123, 246, 0.3)',
                      }}
                    >
                      <IconNavigationArrowBottom />
                    </div>
                    :
                    <div className={`wh-6px rounded-circle outline-1 ${eventClass?.dotColorClass} ${routeLineClass}`}></div>
                  }

                </div>
                }
                <div className="text-muted" style={{ width: 90 }}>Arrival</div>

                <div className="font-medium">
                  {driverOrder?.arrived
                    ? moment(driverOrder.arrived).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
                    : "-"}
                </div>
              </div>

              
              <span
                className={`position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative left-100 ml-10 curved-arrow-sm curved-arrow-left-top curved-arrow-left-bottom ${
                  !isOnMap && isDriverOrderSelected ? "bg-brand-100" : ""
                }`}
                style={{ top: "50%", transform: "translateY(-50%)", left: "100%" }}
              >
                {(!driverOrder?.isEndingMove || driverOrder?.type === "RETURNCONTAINER") && !isLastEvent && (durationTaken || "-")}
              </span>


              {(!driverOrder?.isEndingMove || driverOrder?.type === "RETURNCONTAINER") && !isLastEvent && (
                <div className="d-inline-flex gap-5 align-items-center h-26px">
                  {!isOnMap &&
                  <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                    {!isLastEvent && !nextValidEvent?.arrived  && driverOrder?.departed?
                      <div 
                      className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                      style={{
                        outline: '5px solid rgba(54, 123, 246, 0.3)',
                      }}
                    >
                      <IconNavigationArrowBottom />
                    </div>
                    :  
                    <div className={`wh-6px rounded-circle outline-1 ${eventClass?.dotColorClass} ${routeLineClass}`}></div>
                  }
                  </div>
                  }
                  <div className="text-muted" style={{ width: 90 }}>
                    Departure
                  </div>
                  <div className="font-medium">
                    {driverOrder?.departed
                      ? moment(driverOrder.departed)
                          .tz(currentUserTimeZone)
                          .format(DateTimeFormatUtils.fullDateTimeFormat())
                      : "-"}
                  </div>
                </div>
              )}
            </div>

          </div>

        </>
      </div>
    </>
  );
}

export default EventCard;
