import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "../PromptAlert/style.css";

const streetTurnPromptAlert = (reference_number, title, message, callback) => {
  confirmAlert({
    title,
    message,
    customUI: ({ title, message, onClose }) => {
      return (
        <div className="card modal-w600">
          
          <div className="modal-body text-center py-30 px-50">
          <h4 class="modal-title font-20 line-height-22 font-weight-500 text-capitalize" id="myModalLabel">
              {title}
            </h4>
            <div className="text-center font-20 font-weight-500 d-inline-block bg-gray-100 p-15 rounded-5 my-15">
              {reference_number}
            </div>
            <div className="text-center">
              <p className="text-dark font-20 line-height-22 font-weight-500 mb-0 text-capitalize">{message}</p>
            </div>
          </div>
          <div className="modal-footer d-flex align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
                No
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                callback(true);
                onClose();
              }}
            >
                Yes
            </button>
          </div>
        </div>
      )
    },
  });
};

export default streetTurnPromptAlert;
