import React from "react";

const ToggleSwitch = ({ id, label, checked, onChange, disabled }) => (
  <div className="form-check form-switch form-switch-sm">
    <input
      className="form-check-input"
      type="checkbox"
      role="switch"
      id={id}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <span className="form-check-label">
      {label}
    </span>
  </div>
);

export default ToggleSwitch; 