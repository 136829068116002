import moment from "moment-timezone";

const generateDatesForWeek = ({ overrideToday, lastDate, timeZone }) => {
  let today = moment.tz(timeZone);
  if (overrideToday) today = moment.tz(overrideToday, timeZone);

  let totalDays = 7;

  if (lastDate) {
    if (!moment(lastDate).isValid()) throw new Error("Invalid last free day.");
    totalDays = moment.tz(lastDate, timeZone).startOf("day").diff(today.startOf("day"), "days") + 1;
    // TODO: if LFD is missed?
    if (totalDays < 1) totalDays = 7;
  }

  const weekDates = [];

  for (let i = 0; i < totalDays; i++) {
    const date = moment(today).add(i, "days");
    weekDates.push(date?.toISOString());
  }

  return weekDates;
};

const generateDateTimes = ({ overrideToday, lastDate, hours, timeZone }) => {
  const rows = [];

  const weekDates = generateDatesForWeek({ overrideToday, lastDate, timeZone });
  // 6, 7, 8, 9, 10, ....24

  for (let i = 0; i < weekDates.length; i++) {
    const eachDate = weekDates[i];
    const eachRow = [];

    const firstCell = {
      isFirstCell: true,
      date: eachDate,
    };

    eachRow.push(firstCell);

    for (let j = 0; j < hours.length; j++) {
      const eachHour = hours[j];

      const startDate = moment.tz(eachDate, timeZone).hour(parseInt(eachHour)).startOf("hour");
      const endDate = moment(startDate).add(1, "hour");

      const eachCell = {
        hour: eachHour,
        date: eachDate,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      };

      eachRow.push(eachCell);
    }

    rows.push(eachRow);
  }
  return rows;
};

export default generateDateTimes;
