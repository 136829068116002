import { useMemo, useReducer } from "react";
import reducers from "../store/reducers";
import generateDateTimes from "../helpers/generateDateTimes";
import { dateTimeConfig } from "../../../../../../../constants";

/**
 * useDateTimeRangePicker is a companion hook for DateTimeRangePicker component
 */
const useDateTimeSlotsPicker = ({ timeZone, lastDate }) => {
  const hours = Array.from({ length: dateTimeConfig.militaryhoursLength }, (_, index) => index + dateTimeConfig.hoursStart);
  const rows = generateDateTimes({ hours, timeZone, lastDate }) ?? [];

  const defaultState = {
    rows,
    selectedSlots: [],
    slotsCount: [],
  };

  const [state, dispatch] = useReducer(reducers, defaultState);

  const slots = useMemo(() => {
    return state.rows;
  }, [state]);

  const selectedSlots = useMemo(() => {
    return state?.selectedSlots;
  }, [state]);

  const slotsCount = useMemo(() => {
    return state.slotsCount;
  }, [state]);

  const getSlotColor = (slotValue) => {
    // if (slotValue > 10) {
    //   return "bg-danger-600";
    // } else if (slotValue >= 5 && slotValue <= 10) {
    //   return "bg-warning-100";
    // } else if (slotValue < 5) {
    //   return "bg-success-500";
    // } else if (slotValue >= 0) {
    //   return "bg-primary";
    // } else {
    //   return "";
    // }
    return "bg-accent-200";
  };

  return {
    slots,
    dispatch,
    hours,
    getSlotColor,
    selectedSlots,
    slotsCount,
  };
};

export default useDateTimeSlotsPicker;
