import { useCallback, useEffect, useMemo, useState } from "react";
import { updateRateRecord } from "../service/chargeProfileService";
import { showForTerminal, toastr, updateTerminology } from "../../../../services";
import _ from "lodash";
import { isEventListValid } from "../service/routeValidationService";
import moment from "moment";
import { getCustomerRateRecordsById } from "../service/customerRateService";
import useCurrentUser from "hooks/users/useCurrentUser";
import { radiusRate } from "../constants/chargeProfiles";
import { removeTerminalTrackingFields } from "../../../../utils";


export const useUpdateRateRecord = (id, rateRecordData, activeTab) => {

  const [rateRecord, setRateRecord] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUserTimeZone } = useCurrentUser();

  const init = useCallback(() => {
    if (id && rateRecordData) {
      setRateRecord(rateRecordData);
      setInitialData(rateRecordData);
    } else if (id) {
      fetchRateRecords();
    }
  }, [id, rateRecordData])

  const isRoutingRulesValid = useMemo(() => {
    const routingRules = rateRecord?.routingRules;
    if (!routingRules || routingRules?.length === 0) return [true, null];
    const [isValid, errorMsg] = isEventListValid(routingRules?.map((e) => e.type), rateRecord?.routingTemplate);

    return [isValid, errorMsg];
  }, [rateRecord])

  useEffect(() => {
    init();
  }, [])

  const fetchRateRecords = useCallback(() => {
    setIsLoading(true);
    getCustomerRateRecordsById(id)
      .then((data) => {
        if (data) {
          setRateRecord(data);
          setInitialData(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const handleUpdate = useCallback((callback, closeModal) => {
    const { name, loadType, customers, pickupLocation, deliveryLocation, terminals, chargeGroups, effectiveStartDate, effectiveEndDate } = rateRecord
    const payload = _.cloneDeep(rateRecord);

    const [routingRulesValid, routingRuleErrmsg] = isRoutingRulesValid;
    if (!routingRulesValid) {
      toastr.show(routingRuleErrmsg, 'error');
      return;
    }

    payload.customerRateRecordId = rateRecord._id;

    delete payload.owner;
    delete payload._id;
    delete payload.__v;
    delete payload.createdAt;
    delete payload.updatedAt;
    payload.customerEmployee = undefined;

    _.forEach(payload?.chargeGroups ?? [], chargeGroup => {
      _.forEach(chargeGroup?.oneOffCharges ?? [], oneOffCharge => {
        _.unset(oneOffCharge, 'ruleErrorMessages');
      });
    });

    if (activeTab === 'advanced') {
      payload.pickupLocation = null;
      payload.deliveryLocation = null;
      payload.returnLocation = null;
    }

    if (activeTab === 'basic') {
      payload.routingRules = null;
      payload.routingTemplate = null;
    }

    if (!name) {
      return toastr.show("Name is required", "error")
    }
    if (!loadType.length) {
      return toastr.show("Load Type is required", "error")
    }

    if (!customers.length) {
      return toastr.show("Customer is required", "error")
    }
    if (showForTerminal() && !terminals.length) {
      return toastr.show(`${updateTerminology("terminal")} is required`, "error")
    }

    if (!rateRecord?.routingRules?.length) {
      if (!deliveryLocation.length) {
        return toastr.show("Delivery Location is required", "error")
      }
      if (!pickupLocation.length) {
        return toastr.show("Pickup Location is required", "error")
      }
    }
    if (activeTab === "basic") {
      if (!deliveryLocation) {
        return toastr.show("Delivery Location is required", "error")
      }
      else if (!deliveryLocation.length) {
        return toastr.show("Delivery Location is required", "error")
      }
      if (!pickupLocation) {
        return toastr.show("Pickup Location is required", "error")
      }
      else if (!pickupLocation.length) {
        return toastr.show("Pickup Location is required", "error")
      }
    }

    if (!effectiveStartDate) {
      return toastr.show("Effective End Date is required", "error")
    }

    if (!effectiveEndDate) {
      return toastr.show("Effective End Date is required", "error")
    }

    if (moment(effectiveEndDate).isBefore(effectiveStartDate)) {
      return toastr.show("End Date must be after Start Date!", "error")
    }

    if (!chargeGroups?.[0]?.oneOffCharges?.length && !chargeGroups?.[0]?.chargeProfileGroups?.length && !chargeGroups?.[0]?.chargeProfiles?.length) {
      return toastr.show("Please add at least one Charge Set!", "error");
    }

    const hasEmptyCharges = chargeGroups?.some((d, index) => {
      const hasEmptyCharges = !d?.chargeProfileGroups?.length && !d?.chargeProfiles?.length && !d?.oneOffCharges?.length;
      const hasEmptyBillToCustomer = !d?.billTo?.name
      const isInvalidOneOffCharges = d?.oneOffCharges?.some((charge) => {
        const isChargesInvalid = charge?.charges?.some(a => {
          const hasUnitOfMeasure = a?.hasOwnProperty('unitOfMeasure');
          const isRadiusRate = radiusRate?.includes(a?.unitOfMeasure);

          if (isNaN(parseInt(a?.amount))) {
            toastr.show(`Per unit is required!`, "error");
            return true;
          }
          if (hasUnitOfMeasure && !isRadiusRate && isNaN(parseInt(a?.freeUnits))) {
            toastr.show(`Free units is required!`, "error");
            return true;
          }
          return false;
        });
        if (!charge?.name) toastr.show(`Charge Name is required!`, "error");

        return !charge?.name || isChargesInvalid;
      });
      const chargeGroupBoxRef = document.getElementById(`charge-group-${index}`);
      if (hasEmptyCharges || isInvalidOneOffCharges ||hasEmptyBillToCustomer) {
        hasEmptyCharges && toastr.show(`Charge group should have at least one charge!`, "error");
        hasEmptyBillToCustomer && toastr.show(` Bill To customer is required!`, "error");
        chargeGroupBoxRef && (chargeGroupBoxRef.style.border = "1px solid #f85c4a");
        return true;
      }

      chargeGroupBoxRef && (chargeGroupBoxRef.style.border = "0px");
    });

    if(payload?.customers) payload.customers = removeTerminalTrackingFields(payload?.customers);
    if(payload?.returnLocation) payload.returnLocation = removeTerminalTrackingFields(payload?.returnLocation);
    if(payload?.pickupLocation) payload.pickupLocation = removeTerminalTrackingFields(payload?.pickupLocation);
    if(payload?.deliveryLocation) payload.deliveryLocation = removeTerminalTrackingFields(payload?.deliveryLocation);

    if (payload?.routingRules?.length > 0) {
      payload.routingRules = payload.routingRules.map((rule) => {
        rule.profile = removeTerminalTrackingFields(rule?.profile);
        return rule;
      });
    }

    if (hasEmptyCharges) return;

    setIsLoading(true);
    updateRateRecord({
      ...payload,
      effectiveStartDate: moment(effectiveStartDate).tz(currentUserTimeZone).startOf('day').toISOString(), 
      effectiveEndDate: moment(effectiveEndDate).tz(currentUserTimeZone).endOf('day').toISOString(), 
    })
      .then((data) => {
        if (callback) {
          callback(data);
          closeModal()
        }
        toastr.show("Load Tariff updated successfully", "success");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [rateRecord, isRoutingRulesValid]);

  const updateRateRecordData = (updates) => {
    setRateRecord((prevState) => {
      return { ...prevState, ...updates };
    });
  }

  return {
    rateRecord,
    initialData,
    handleUpdate,
    updateRateRecordData,
    isLoading,
  };
}