import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { IconFolderPlus, IconPen, IconTimes, IconTrash } from "../../../../../Components/Common/Icons";
import DynamicTableHeight from "../../../../../Components/Common/DynamicTableHeight";
import _ from "lodash";
import { LoaderBar } from "../../../../../Components/Common/LoaderBar";
import { unitOfMeasure } from "../../service/customerRateService";
import { isAtBottom } from "../../../NewDispatcher/DispatcherTable/Functions";
import RatesPromptAlert from "../../Components/ratesPromptAlert";
import AdjustRatesModal from "./adjustRatesModal";
import ReactTooltip from "react-tooltip";
import ChargeValidationErrorModal from "./ChargeValidationErrorModal";
import { useChargeCode } from "../../../../../hooks/helpers/useChargeCode";
import { getLatestTarrifAmount } from "services";

const ChargeTemplatesTable = ({ editModal, handleDeleteRow, handleSoftDelete, handleDeleteSelectedRows, handleAssignChargeGroup, chargeProfileList = [], handleSelectRow, isLoading, allowEdit = true, allowDelete= true, allowCheckbox=true, hideBottomFilter = false, checkedChargeProfiles = [], scrolledToEnd, bottomBarRef, resetWithSearch, hasProfileValidation = false, isAllChargesValid }) => {
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [checkedRows, setCheckedRows] = useState([...checkedChargeProfiles]);
  const [allChecked, setAllChecked] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedBulkDeleteRows, setSelectedBulkDeleteRows] = useState([]);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [isAdjustRatesModal, setIsAdjustRatesModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);
  const tableRef = useRef();
  
  const { findChargeNameByCode } = useChargeCode();

  const confirmDelete = () => {
    setDeleteLoading(true);

    if(handleSoftDelete) handleSoftDelete(recordToDelete);
    else if(selectedBulkDeleteRows?.length) handleDeleteSelectedRows(checkedRows, handleCloseBottomBar).then(() => setDeleteLoading(false));
    else handleDeleteRow(recordToDelete.id).then(() => setDeleteLoading(false));

    setDeleteLoading(false);
    setIsDeleteModalOpen(false);  // Close the modal after delete
    setRecordToDelete(null);     // Reset the record to delete
    setSelectedBulkDeleteRows([]); 
  };

  useEffect(() => {
    const isEqual = _.isEqualWith(chargeProfileList, checkedRows, (obj1, obj2) => obj1?._id === obj2?._id);
    const isAllChecked = (checkedChargeProfiles?.length && chargeProfileList?.length) && (checkedChargeProfiles?.length === chargeProfileList?.length) && isEqual;

    setAllChecked(isAllChecked ?? false);
  }, [chargeProfileList, checkedChargeProfiles]);

  useEffect(() => {
    setAllChecked(chargeProfileList?.length === checkedRows?.length);
    if(handleSelectRow) handleSelectRow("chargeProfiles", checkedRows);
  }, [checkedRows]);

  const handleScroll = (e) => { 
    const { currentTarget } = e;
    const hasScrollBar = (currentTarget.scrollHeight - currentTarget.clientHeight) > 0;
    if(scrolledToEnd && hasScrollBar && !isAtBottom(e)) scrolledToEnd(true);
  };

  useEffect(() => {
    tableRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      tableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleHeaderCheckboxToggle = () => {
    setCheckedRows(!allChecked ? chargeProfileList : []);
    setAllChecked(!allChecked);
  };

  const handleCheckboxToggle = (isChecked, charge) => {
    if(!isChecked) setCheckedRows(checkedRows?.filter(d => d?._id !== charge?._id));
    else setCheckedRows([...checkedRows, charge ]);
  };

  const handleCloseBottomBar = () => {
    setCheckedRows([]);
    setAllChecked(false);
  };

  const handleBulkDelete = (checkedRows) => {
    setIsDeleteModalOpen(true);
    setSelectedBulkDeleteRows(checkedRows);
  }
  const handleShowAdjustRatesModal = () => {
    setIsAdjustRatesModal(true);
  }

  const handleHideAdjustRatesModal = () => {
    setIsAdjustRatesModal(false);
  }

  const handleShowErrorsModal = (errors) => {
    setValidationErrors(errors);
  }

  return (
    <>
      {editModal && <DynamicTableHeight pageId="charge-templates-table" /> }
      {isDeleteLoading && <LoaderBar fullPageLoader={true}  />}

      <div 
        className={`${editModal ? 'table-responsive' : 'overflow-auto d-block'}`} 
        style={{ 
          height: editModal 
          ? 'calc(100vh - var(--dynamic-table-height-offset))'  
          : `calc(100vh - 261px)` // fallback height when modal is open in Rates Profile selecting charge profile
        }}
        ref={tableRef}
      >
        <table className="table table-card">
          <thead>
            <tr>
              {
                allowCheckbox && (
                  <th width="40px">
                    <input
                      type="checkbox"
                      className="form-control"
                      name="select-all"
                      checked={allChecked}
                      onChange={handleHeaderCheckboxToggle}
                    />
                  </th>
                )
              }
              <th>Charge Profile Name</th>
              {
                hasProfileValidation && (
                  <th className="text-right">Error</th>
                )
              }
              <th>Charge Name</th>
              <th>Unit Of Measure</th>
              <th>Charge Description</th>
              <th>Auto Add</th>
              <th className="text-right">Per Unit</th>
              <th className="text-right">Min. Total Price</th>
              <th className="text-right">Free Units</th>
              {allowEdit && <th width="60px"></th>}
              {allowDelete && <th width="60px"></th>}
            </tr>
          </thead>
          <tbody key={checkedRows}>
            {!isLoading && !chargeProfileList?.length && <tr><td className="text-capitalize" colSpan="100%">No data found</td></tr>}
            {isLoading && <LoaderBar fullPageLoader={true}  />}
            {
              chargeProfileList && chargeProfileList?.map((charge, i) => {
                const latestCharge = charge?.charges?.length ?
                charge?.charges?.every(charge => charge?.effectiveStartDate) ?
                  charge?.charges?.reduce((prev, current) => moment(prev?.effectiveStartDate)?.isBefore(current?.effectiveStartDate) ? current : prev)
                  : charge?.charges?.length ? charge?.charges[charge?.charges?.length - 1] : false
                : charge?.charges?.[0];
                const isRowChecked = checkedRows?.find((d) => d?._id === charge?._id) ?? false;
                const totalRulesErrorMessage = charge?.ruleErrorMessages ? Object.keys(charge?.ruleErrorMessages ?? {})?.length : 0;

                return (
                  <tr key={i} className={`${totalRulesErrorMessage ? 'bg-red-soft' : ''} ${hasProfileValidation && isAllChargesValid ? 'bg-success' : ''}`}>
                    {
                      allowCheckbox && (
                        <td>
                          <input
                            type="checkbox"
                            className="form-control"
                            name="select-row-charge-profile"
                            checked={isRowChecked}
                            onChange={(e) => handleCheckboxToggle(e.target.checked, charge)}
                          />
                        </td>
                      )
                    }
                    <td className="text-primary pointer" onClick={() => editModal(charge)}>{charge?.name}</td>
                    {
                      hasProfileValidation && (
                        <td className="text-right" data-tip data-for={`error-${i}`} onClick={() => handleShowErrorsModal(charge?.ruleErrorMessages)}>
                          {
                            totalRulesErrorMessage ? (
                              <>
                                <span className="badge badge-danger cursor-pointer">+ {totalRulesErrorMessage}</span>
                                <ReactTooltip className="text-capitalize" id={`error-${i}`}>Click to see the error list.</ReactTooltip>
                              </>
                            ) : null
                          }
                        </td>
                      )
                    }
                    <td>{findChargeNameByCode(charge?.chargeCode)?.label}</td>
                    <td>
                      <span className="badge badge-light">{unitOfMeasure?.find(d => d.value === charge?.unitOfMeasure)?.label ?? ''}</span>
                    </td>
                    <td className="text-capitalize">{charge?.description}</td>
                    <td>
                      <span className="badge badge-light">{charge?.autoAdd ? "Yes" : "No"}</span>
                    </td>
                    <td className="text-right">{getLatestTarrifAmount(charge?.charges)?.amount.print()}</td>
                    <td className="text-right">{getLatestTarrifAmount(charge?.charges)?.minimumAmount.print()}</td>
                    <td className="text-right">{getLatestTarrifAmount(charge?.charges)?.freeUnits}</td>
                    {allowEdit && <td>
                      <button className="btn btn-link" onClick={() => editModal(charge)}>
                        <IconPen />
                      </button>
                    </td>
                    }
                    {allowDelete && <td>
                      <button 
                        className="btn btn-link" 
                        onClick={() => { setRecordToDelete({id: charge?._id, name: charge?.name, charge}); setIsDeleteModalOpen(true); }}
                        >
                        <IconTrash />
                      </button>
                    </td>
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      {!hideBottomFilter && checkedRows?.length > 0 && (
        <div className="batch-menu batch-menu--auto px-15 gap-10">
          <div className="d-flex align-items-center">
            <div className="avatar-circle avatar-sm bg-white mr-10">{checkedRows?.length}</div>
            <div className="text-gray-200">Selected</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="batch-menu__item" onClick={() => handleAssignChargeGroup(checkedRows)}>
                <IconFolderPlus className="batch-menu__itemicon" />
                <span className="batch-menu__itemtext">Assign Charge Group</span>
              </div>
            </div>
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="batch-menu__item" onClick={handleShowAdjustRatesModal}>
                <IconPen className="batch-menu__itemicon"/>
                <span className="batch-menu__itemtext">Adjust Rates</span>
              </div>
            </div>
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="batch-menu__item"  onClick={() => checkedRows?.length && handleBulkDelete(checkedRows)}>
                <IconTrash className="batch-menu__itemicon" />
                <span className="batch-menu__itemtext">Delete</span>
              </div>
            </div>
            <div className="dialog-wrapper duplicate-wrapper">
              <div className="batch-menu__item" onClick={handleCloseBottomBar} ref={bottomBarRef}>
                <IconTimes className="cicon" />
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <RatesPromptAlert 
          showPrompt={isDeleteModalOpen} 
          closePrompt={() => setIsDeleteModalOpen(false)} 
          confirmDelete={confirmDelete}
          content={
            selectedBulkDeleteRows?.length ? 
              `Are you sure you want to delete ${selectedBulkDeleteRows?.length} charge profiles?` : `Are you sure you want to delete the charge profile "${recordToDelete?.name}"?`
          }
        />
      )}
      {isAdjustRatesModal && 
        <AdjustRatesModal show={isAdjustRatesModal} hide={handleHideAdjustRatesModal} checkedRows={checkedRows} resetWithSearch= {resetWithSearch} handleCloseBottomBar= {handleCloseBottomBar} />
      }
      
      {
        Object.keys(validationErrors ?? {})?.length ? (
         <ChargeValidationErrorModal validationErrors={validationErrors} handleShowErrorsModal={handleShowErrorsModal} />
        ) : null
      }
    </>
  );
};

export default ChargeTemplatesTable;