import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from "react-dom";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import useBoxPosition from '../../../../../../Components/CustomHooks/useBoxPosition';
import { successColorResponse , errorColorResponse } from '../../../utility';
import { getTMSChassis } from '../../../../Dispatcher/actionCreators';

const fetchChassisUser = (chassis, searchChassis) => {
    let allChassisList = chassis
    if (allChassisList.length > 0) {
        if (searchChassis !== null) {
            allChassisList = allChassisList.filter((chassis) => {
                return (
                    chassis.chassisNo
                        .toLowerCase()
                        .indexOf(
                            searchChassis.toLowerCase().trim()
                        ) != -1
                );
            });
        }
        return allChassisList
    }
}

const PreventChassisList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress , column }) => {
    const [chassisOptions, setChassisOptions] = useState([])
    const [cursor,setCursor] = useState(null)
    const [searchChassis,setSearchChassis] = useState(null)
    const [cList, setdList] = useState([])
    const [isOptionsLoading,setIsOptionsLoading] = useState(false);
    const searchInputRef = React.useRef(null);

    const cell = document.querySelector('[aria-selected="true"]');

    const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)

    const fetchAllChassis = ()=>{
        setIsOptionsLoading(true)
        getTMSChassis()()
            .then((result) => {
              setChassisOptions(result)
              setIsOptionsLoading(false)
              setdList(result)
            })
            .catch((err) => {
                setIsOptionsLoading(false)
              console.log(err);
            });
    }

    useEffect(() => {
        fetchAllChassis()
        searchInputRef.current.focus();
        window.document.getElementById('root').style.pointerEvents="none"
        return ()=>{
            window.document.getElementById('root').style.pointerEvents="auto"
        }
    }, [])

    useOnClickOutside(boxRef, () => onClose(false))

    useEffect(() => {
		const _chassis = fetchChassisUser(chassisOptions, searchChassis);
		setdList(_chassis || []);
	}, [searchChassis]);

    const get = (data) => {
        if(Row){
            const payload = {
                chassisNo : data.chassisNo,
                reference_number : Row.reference_number,
                chassisId : data._id,
            }
            if (data.chassisType) payload.chassisType = data.chassisType._id;
            if (data.chassisSize) payload.chassisSize = data.chassisSize._id;
            if (data.chassisOwner) payload.chassisOwner = data.chassisOwner._id;

            setIsAssignInProgress(true);
            editLoad(payload).then(el => {
                onRowChange({ ...Row, chassisNo: data.chassisNo });
                setIsAssignInProgress(false);
                onClose(false)
                successColorResponse(cell, column.idx, Row?.className);       
            }).catch((err) => {
				setIsAssignInProgress(false);
                onClose(false)
                errorColorResponse(cell, column.idx, Row?.className);
			})
        }
    }

    const handleKeyDown=(event)=> {
        if (event.keyCode === 38) {
          if (cursor >= 0) {
            setCursor(cursor - 1)
            document.getElementById(`chassis-${cursor}`).focus();
          }
          event.preventDefault();
        } else if (event.keyCode === 40) {
            if (cursor <= cList.length - 1) {
                if(cursor===null){
                    setCursor(0)
                }else{
                    setCursor((prev)=>prev + 1 )
                    document.getElementById(`chassis-${cursor}`).focus();
                    
                }
            } 
            event.preventDefault();
        } else if (event.keyCode === 13) {
            cList.forEach((val, index) => {
                if (index === cursor) get(val);
            });
            event.preventDefault();
        }   else if (event.keyCode === 9 || event.keyCode === 27) {
            onClose(false)
            event.preventDefault();
            event.stopPropagation();
        }
    }

    return createPortal(
        <div ref={boxRef} className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200" style={Style}>
            <div className="app-search bg-gray-50 rounded-lg mb-10">
                <input
                    className="input-search-left form-control py-2"
                    placeholder="Search Chassis.."
                    id="domTextElement"
                    onChange={(e)=>setSearchChassis(e.target.value)}
                    onKeyDown={(event) =>{ handleKeyDown(event)} }
                    ref={searchInputRef}
                />
                <span className="search-icon ml-1"></span>
            </div>

            <ul className="driverList m-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {isOptionsLoading && <span className="p-1" >Loading ...</span>}
                {cList && cList.length === 0 ? (
                    <p 
                        className="p-1" 
                    >No Options</p>) : (cList && cList.map((el, index) => (
                    <li 
                        key={index}  
                        className={`popdrop__dropdown-item ${!el.isAvailable && "disabled"} ${el.isAvailable && "cursor-pointer"}`} 
                      tabIndex="-1" 
                      id={`chassis-${index}`}   
                      style={{ height: '30px' }} 
                      onClick={async () => el.isAvailable && get(el)}
                      onKeyDown={(e) => handleKeyDown(e)}
                    >{el.chassisNo}
                    </li>
                )))}
            </ul>
        </div>,
        document.getElementById('pp-overlay-container')
    );
};
export default PreventChassisList