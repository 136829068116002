import React, { memo, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { useHover } from 'hooks';
import { IconAngleArrowRight, IconAngleArrowDown, Icondots } from '../../../../../../Components/Common/Icons';
import { getStorage, setStorage, capitalizeFirstLetterOfEachWord } from '../../../../../../services';
import { getEmailByAccountId, getIconV2, getLabelV2 } from '../../../helper';
import { convertFolderNameForOutlook, EMAIL_CONTEXT, EMAIL_PROVIDER, NEW_EMAIL_NAV_CONTEXT } from '../../../constant';
import FolderContextMenuWrapper from '../../FolderContextMenuWrapper';
import { setActiveFolderForBE } from 'pages/tms/Email/actionCreator';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import useTextTruncation from '../../../hooks/useTextTruncation';

const folderIdMap = {
    "ALL EMAILS": "all",
    "DRAFT": "Drafts",
    "CATEGORY_SOCIAL": "social",
    "CATEGORY_UPDATES": "updates",
    "CATEGORY_FORUMS": "forums",
    "CATEGORY_PROMOTIONS": "promotions"
};

const convertFolderNameForGmail = (name, systemFolder) => {
    let gmailName;
    if (systemFolder) {
        if (name === "DRAFT") gmailName = "Drafts";
        else if (name.includes("CATEGORY")) {
            gmailName = folderIdMap[name] ? folderIdMap[name]?.slice(0, 1)?.toUpperCase() + folderIdMap[name]?.slice(1) : name;
        } else {
            gmailName = capitalizeFirstLetterOfEachWord(name, "SPACE");
        }
    } else {
        gmailName = name
    }
    return gmailName;
}

const FolderItem = memo(({
    folder,
    level,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDragLeave,
    onDrop,
    updateFolders,
    folders,
    onToggle,
    dragOverId,
    draggedFolder,
    isDragging,
    provider,
}) => {
    const [labelRef, labelHovering] = useHover();
    const [isFavFolder, setIsFavFolder] = useState(folder?.isFavFolder || false);
    const [textRef, isTextTruncated] = useTextTruncation([folder?.name]);

    const hasSubFolders = folder?.subfolders?.length > 0;
    const { resetState, activeFolder, setActiveFolder, setIsMoveEmailLoader, favoriteFolders } = useContext(EMAIL_CONTEXT);
    const { setEmailChanged, setActiveNav, _moveEmail, labels, activeNav, handleHideBatchMenu } = useContext(NEW_EMAIL_NAV_CONTEXT);
    const isCategories = folder?.id === "categories";

    useEffect(() => {
        const favoriteFolder = favoriteFolders.find(fav => fav?.id === folder?.id && fav?.grantId === folder?.grantId);
        if (!favoriteFolder) {
            setIsFavFolder(false);
        }
    }, [favoriteFolders]);

    const handleDragOver = (e) => {
        e.preventDefault();
        if (e.dataTransfer.types.includes('accountid')) {
            return;
        } else if (e.dataTransfer.types.includes('dragtype')) {
            onDragOver(e, folder)
        } else {
            onEmailDragOver(e, folder);
        }
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        if (e.dataTransfer.types.includes('dragtype')) {
            onDragLeave(e);
        } else {
            onEmailDragLeave();
        }
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.types.includes('accountid')) {
            return;
        }
        else if (e.dataTransfer.types.includes('dragtype')) {
            onDrop(e, folder)
        } else {
            handleEmailDrop(e);
        }
        e.stopPropagation();
    };

    const handleToggle = (e) => {
        e.stopPropagation();
        onToggle(folder?.id);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            handleToggle(e);
        }
    };

    const debouncedUpdateActiveFolder = useCallback(
        _.debounce((payload) => {
            setActiveFolderForBE(payload);
        }, 500),
        []
    );

    const isDraggedOver = dragOverId === folder?.id;
    const isBeingDragged = isDragging && draggedFolder?.id === folder?.id;

    const handleClick = (e) => {
        if (isCategories) {
            handleToggle(e)
        } else {
            const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
            let currentAccountId = embeddedEmailAccount?.accountId;
            let { provider } = embeddedEmailAccount;
            let dataToSet;
            resetState()
            if (currentAccountId !== folder?.grantId) {
                const foundMail = getEmailByAccountId(folder?.grantId)
                if (foundMail) {
                    currentAccountId = foundMail.accountId;
                    provider = foundMail?.provider;
                    setStorage("embeddedEmailAccount", JSON.stringify(foundMail))
                    setEmailChanged(prev => prev + 1);
                }
            }
            if (provider === EMAIL_PROVIDER.GMAIL) {
                if (folder?.systemFolder) {
                    if (folderIdMap[folder?.id]) {
                        dataToSet = folderIdMap[folder?.id];
                    } else if (folder?.name === "ALL EMAILS") {
                        dataToSet = "All Emails";
                    } else {
                        dataToSet = folder?.name?.charAt(0)?.toUpperCase() + folder?.name?.slice(1)?.toLowerCase();
                    }
                } else {
                    dataToSet = folder?.id;
                }
            } else {
                if (folder?.name === "Inbox") {
                    dataToSet = folder?.name;
                } else {
                    dataToSet = folder?.id;
                }
            }
            setActiveFolder((prev) => {
                return { ...prev, grantId: currentAccountId, id: folder?.id }
            })
            setActiveNav(dataToSet)
            const mappedFolder = {
                id: dataToSet,
                grantId: folder?.grantId,
            }
            debouncedUpdateActiveFolder({ activeFolder: mappedFolder })
        }
    }

    const isActive = React.useMemo(() => {
        if (activeFolder?.grantId !== folder?.grantId) return false;
        const isDraftMatch = folderIdMap[folder?.id] === activeFolder?.id;
        const isIdMatch = activeFolder?.id === folder?.id;
        const isNameMatch = activeFolder?.id?.toUpperCase() === folder?.name?.toUpperCase();

        return isDraftMatch || isIdMatch || isNameMatch;
    }, [activeFolder, folder]);

    const [isMoveTarget, setIsMoveTarget] = useState("");

    const removeElement = () => {
        const element = document.querySelector("#draggable-email-element");
        const escInfo = document.querySelector("#esc-info-bar");
        if (element) element?.remove();
        if (escInfo) escInfo?.remove();
    }

    const removeClass = () => {
        document.querySelectorAll('.draggable-row').forEach(rowElement => {
            rowElement.classList.remove('draggable-row');
            rowElement.querySelectorAll('.rdg-cell').forEach(cell => cell.classList.remove('opacity-03'));
        });
    }

    const isDragAllowed = (id) => {
        const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL)
        if ((activeNav === "Spam" && ["Trash", "Inbox"].includes(id)) && !isOutlookProvider) {
            return false;
        }
        return activeNav !== id && Boolean(getLabelV2(id, labels, isOutlookProvider));
    }

    const handleEmailDrop = async (e) => {
        e.preventDefault();
        const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL);
        setIsMoveEmailLoader(true);
        removeClass();
        setIsMoveTarget("");

        const cleanup = () => {
            setIsMoveEmailLoader(false);
            removeElement();
            handleHideBatchMenu();
        };

        const data = e.dataTransfer.getData('application/json');
        const grantId = e.dataTransfer.getData('grantId');
        if (folder?.grantId !== grantId) {
            cleanup();
            return;
        }
        const item = JSON.parse(data);
        const id = isOutlookProvider && folder?.name === "Inbox" ? "Inbox" : folder?.id;
        if (!isDragAllowed(id)) {
            cleanup();
            return;
        }
        const folderId = folder?.id;
        let labelId = getLabelV2(folderId, labels, isOutlookProvider);
        labelId = labelId.toLowerCase() === "inbox" ? "INBOX" : labelId;
        await _moveEmail({ ids: item }, false, labelId);
        cleanup();
    };

    const onEmailDragOver = (e, folder) => {
        e.preventDefault();
        if (Array.isArray(labels) && labels.length > 0) {
            const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL);
            const grantId = labels[0]?.grantId;
            if (folder?.grantId !== grantId) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            if ((activeNav === "Spam" && ["Trash", "Inbox"].includes(folder?.id)) && !isOutlookProvider) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            const moveTarget = getLabelV2(folder?.id, labels, isOutlookProvider, true);
            if (moveTarget === undefined) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            setIsMoveTarget(getLabelV2(folder?.id, labels, isOutlookProvider, true) ?? "");
        }
    }

    const onEmailDragLeave = () => setIsMoveTarget("");

    const onRightClick = (e) => {
        // setShowDropdown(!showDropdown);
        setContextMenu(e)
    }

    let setContextMenu = () => { }

    return (
        <div className="select-none">
            <FolderContextMenuWrapper
                folder={folder}
                setIsFavFolder={setIsFavFolder}
                isFavFolder={isFavFolder}
                setActiveNav={setActiveNav}
                isSubFolder={true}
                provider={provider}
                mainFolders={folders}
                setFolders={updateFolders}
                setContextMenu={(f) => {
                    setContextMenu = f
                }}
            >
                <div
                    className={`d-flex align-items-center gap-5 h-30px pr_5 pointer rounded-5 position-relative      
                    ${isActive && !isDraggedOver ? "bg-white shadow-1" : ""} 
                    ${isDraggedOver ? 'bg-primary-100' : ''} 
                    ${labelHovering ? "bg-gray-25" : ""}
                    ${isBeingDragged ? 'bg-blue-50' : ''}
                    ${isMoveTarget === folder?.id ? "bg-primary-100" : ""}`}
                    draggable
                    onDragStart={(e) => onDragStart(e, folder)}
                    onDragEnd={onDragEnd}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    tabIndex={0}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    ref={labelRef}
                >
                    <div className="w-20px" style={{ minWidth: '20px' }}>
                        {hasSubFolders && (
                            <button
                                onClick={handleToggle}
                                className="btn btn-link p-0"
                            >
                                {folder?.collapse ? (
                                    <IconAngleArrowRight />
                                ) : (
                                    <IconAngleArrowDown />
                                )}
                            </button>
                        )}
                    </div>
                    <div className="w-20px" style={{ minWidth: '20px' }}>
                        {getIconV2(provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : folder?.name)}
                    </div>
                    <span 
                        ref={textRef}
                        className="font-medium text-truncate" 
                        data-tip={isTextTruncated ? "true" : ""} 
                        data-for={`folder-tooltip-${folder?.id}`}
                    >
                        {provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : convertFolderNameForOutlook(folder?.name)}
                    </span>
                    {isTextTruncated && (
                        <ReactTooltip 
                            id={`folder-tooltip-${folder?.id}`} 
                            effect="solid" 
                            place="bottom"
                        >
                            {provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : convertFolderNameForOutlook(folder?.name)}
                        </ReactTooltip>
                    )}
                    {labelHovering && !isCategories ? (
                        <span
                            className={"text-muted ml-auto hover:text-gray-900"}
                            onClick={onRightClick}
                        >
                            <Icondots />
                        </span>
                    ) : (
                        <>
                            {folder?.unreadCount > 0 && (
                                <span className="ml-auto badge badge-pill badge-rounded badge-gray-100 font-normal">
                                    {folder?.unreadCount}
                                </span>
                            )}
                        </>
                    )}
                </div>
                {!folder?.collapse && hasSubFolders && (
                    <div className="pl-10">
                        {folder?.subfolders?.map((subFolder) => (
                            <FolderItem
                                key={subFolder.id}
                                folder={subFolder}
                                level={level + 1}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                                onDragOver={onDragOver}
                                onDragLeave={onDragLeave}
                                onDrop={onDrop}
                                updateFolders={updateFolders}
                                onToggle={onToggle}
                                dragOverId={dragOverId}
                                draggedFolder={draggedFolder}
                                isDragging={isDragging && draggedFolder?.id === subFolder.id}
                                provider={provider}
                                folders={folders}
                            />
                        ))}
                    </div>
                )}
            </FolderContextMenuWrapper>
        </div>
    );
});

FolderItem.displayName = 'FolderItem';

export default FolderItem;