import { isCombineTripEnabled } from "../Dispatcher/NewDriverItinerary/constants";

const isCombinedTrip = isCombineTripEnabled()

export const invoiceJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
    header: {
        isShow: true,
        fields: [{
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title: 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            }, {
                title: 'Phone',
                isShow: true,
                type: "text",
                value: "mobile",
                label: "Phone",
                isColor : true
            },
            {
                title: 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Invoice Number",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "InvoiceNumber",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }, {
            title: "InvoiceDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Invoice Date",
        }, {
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        }, {
            title: "DueDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        }],
    },
    section2: {
        isShow: true,
        fields: [{
                title: "Bill To",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
            {
                title: "PORT/SHIPPER",
                isShow: true,
                type: "text",
                value: "PORT/SHIPPER",
                label: "PORT/SHIPPER",
                isColor : true
            }, {
                title: "Consignee",
                isShow: true,
                type: "text",
                value: "CONSIGNEE",
                label: "CONSIGNEE",
                isColor : true
            }, {
                title: "RETURN",
                isShow: true,
                type: "text",
                value: "RETURN",
                label: "RETURN",
                isColor : true
            }
        ],

    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Reference #",
                isShow: true,
                type: "dynamic",
                value: "secondaryReferenceNo",
                label: "Reference #"
            },
            {
                title: "Booking/BL",
                isShow: true,
                type: "dynamic",
                value: "bookingNo",
                label: "Booking/BL",

            },
            {
                title: "MBOL #",
                isShow: true,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            },
            {
                title: "HBOL #",
                isShow: true,
                type: "dynamic",
                value: "doNo",
                label: "HBOL #"
            },
            {
                title: "Pickup Appointment",
                isShow: false,
                type: "date",
                value: "pickupFromTime",
                label: "Pickup Appointment",
                format: "LL"
            },
            {
                title: "Delivery Appointment",
                isShow: false,
                type: "date",
                value: "deliveryFromTime",
                label: "Delivery Appointment",
                format: "LL"
            },
            {
                title: "Last Free day",
                isShow: false,
                type: "date",
                format: "LL",
                value: "lastFreeDay",
                label: "Last Free Day",
            },
            {
                title: "Last Free Date Return Day",
                isShow: false,
                type: "date",
                value: "freeReturnDate",
                label: "Last Free Date Return Day",
                format: "LL"
            }, {
                title: "Pull Container Date",
                isShow: false,
                type: "date",
                value: "pullContainerDate",
                label: "Pull Container Date",
                format: "LL"
            }, {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            }, {
                title: "Return Container Date",
                isShow: false,
                type: "date",
                value: "returnContainerDate",
                label: "Return Container Date",
                format: "LL"
            }, 
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",

            },
            {
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size",

            }, {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",

            },
             {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "Purchase Order #",

            }, {
                title: "Temperature",
                isShow: false,
                type: "dynamic",
                value: "temperature",
                label: "Temperature",

            }, {
                title: "Hazmat",
                isShow: false,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat",

            }, {
                title: "Shipment #",
                isShow: false,
                type: "dynamic",
                value: "shipmentNo",
                label: "Shipment #",

            }, {
                title: "Pick Up #",
                isShow: false,
                type: "dynamic",
                value: "callerPONo",
                label: "Pick Up #",

            }, {
                title: "Chassis #",
                isShow: false,
                type: "dynamic",
                value: "chassisIdName",
                label: "Chassis #",

            }, {
                title: "Chassis Size",
                isShow: false,
                type: "dynamic",
                value: "chassisSize",
                label: "Chassis Size",

            }, {
                title: "Chassis Type",
                isShow: false,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",

            }, {
                title: "Chassis Owner",
                isShow: false,
                type: "dynamic",
                value: "chassisOwnerName",
                label: "Chassis Owner",

            }, {
                title: "Steamship Line",
                isShow: true,
                type: "dynamic",
                value: "containerOwner",
                label: "Steamship Line",

            }, {
                title: "Seal #",
                isShow: true,
                type: "dynamic",
                value: "sealNo",
                label: "Seal #",

            }, {
                title: "Vessel Name",
                isShow: false,
                type: "dynamic",
                value: "deliveryOrderNo",
                label: "Vessel Name",

            }, {
                title: "Voyage Name",
                isShow: false,
                type: "dynamic",
                value: "releaseNo",
                label: "Voyage Name",
            }, {
                title: "Genset #",
                isShow: false,
                type: "dynamic",
                value: "genset",
                label: "Genset #",

            }, {
                title: "Appointment #",
                isShow: false,
                type: "dynamic",
                value: "appointmentNo",
                label: "Appointment #",

            }, {
                title: "Return #",
                isShow: false,
                type: "dynamic",
                value: "returnNo",
                label: "Return #",

            }, {
                title: "Reservation #",
                isShow: false,
                type: "dynamic",
                value: "reservationNo",
                label: "Reservation #",

            },
           {
              title : "Hook Chassis",
              isShow : false,
              type : "dynamic",
              value : "chassisPickup",
              label : "Hook Chassis"   
           },
           {
            title : "Terminate Chassis",
            isShow : false,
            type : "dynamic",
            value : "chassisTermination",
            label : "Terminate Chassis"   
         },
         {
            title : "Total Miles",
            isShow : false,
            type : "dynamic",
            value : "totalMiles",
            label : "Total Miles",
            unit : "mi"   
         },
         {
            title : "Gray Pool Container #",
            isShow : false,
            type : "dynamic",
            value : "grayContainerNo",
            label : "Gray Pool Container #"   
         },
         {
            title : "Gray Pool Chassis #",
            isShow : false,
            type : "dynamic",
            value : "grayChassisNo",
            label : "Gray Pool Chassis #"   
         },
         {
             title : "Discharged Date", 
             isShow : false,
             type : 'date',
             value : "dischargedDate",
             label : 'Discharged Date',
             format : "LL",
         },
         {
            title : "Ingate Date", 
            isShow : false,
            type : 'date',
            value : "ingateDate",
            label : 'Ingate Date',
            format : "LL",
         },
         {
            title : "Outgate Date", 
            isShow : false,
            type : 'date',
            value : "outgateDate",
            label : 'Outgate Date',
            format : "LL",
         },
        ],
    },
    commodity: {
        isShow: true,
        fields: [{
                title: "Commodity",
                isShow: true,
                type: "dynamic",
                value: "commodity",
                label: "Commodity",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
            },
            {
                title: "Pallets",
                isShow: true,
                type: "dynamic",
                value: "pallets",
                label: "Pallets",
                isColor : true
            },
            {
                title: "Pieces",
                isShow: true,
                type: "dynamic",
                value: "pieces",
                label: "Pieces",
                isColor : true
            }
        ],
    },
    pricing: {
        isShow: true,
        fields: [{
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "Charge Name",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "Units",
                isColor : true
            },
            {
                title : "Free Units",
                isShow: true,
                type: "dynamic",
                value: "freeDays",
                label: "Free Units",
                isColor : true
                },
            {
                title: "Rates",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "Rates",
                isColor : true
            },
            {
                title: "Charges",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "Charges",
                isColor : true
            },
        ],
    },
    notes : {
        isShow : false,
        fields : [
            {
                title : "Driver Notes",
                isShow : true,
                type : "dynamic",
                value : "driverNote",
                label: "Driver Notes"
            },
            {
                title : "Billing Notes",
                isShow : true,
                type :"dynamic",
                value :"billingNote",
                label : "Billing Notes"
            },
            {
                title : "Yard Notes",
                isShow : true,
                type :"dynamic",
                value :"yardNote",
                label : "Yard Notes"
            },
            {
                title : "Load Notes",
                isShow : true,
                type : "dynamic",
                value : "officeNote",
                label: "Load Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]},
    watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}

};
export const subInvoiceJSON = {
    accentColor : "#367BF6",
    header: {
        isShow: true,
        fields: [{
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title: 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            }, {
                title: 'Phone',
                isShow: true,
                type: "text",
                value: "mobile",
                label: "Phone",
                isColor : true
            },
            {
                title: 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Invoice Number",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "InvoiceNumber",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }, {
            title: "InvoiceDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Invoice Date",
        }, {
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        }, {
            title: "DueDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        }],
    },
    section2: {
        isShow: true,
        fields: [{
                title: "Bill To",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
            {
                title: "PORT/SHIPPER",
                isShow: true,
                type: "text",
                value: "PORT/SHIPPER",
                label: "PORT/SHIPPER",
                isColor : true
            }, {
                title: "Consignee",
                isShow: true,
                type: "text",
                value: "CONSIGNEE",
                label: "CONSIGNEE",
                isColor : true
            }, {
                title: "RETURN",
                isShow: true,
                type: "text",
                value: "RETURN",
                label: "RETURN",
                isColor : true
            }
        ],

    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Reference #",
                isShow: true,
                type: "dynamic",
                value: "secondaryReferenceNo",
                label: "Reference #"
            },
            {
                title: "Booking/BL",
                isShow: true,
                type: "dynamic",
                value: "bookingNo",
                label: "Booking/BL",

            },
            {
                title: "MBOL #",
                isShow: true,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            },
            {
                title: "HBOL #",
                isShow: true,
                type: "dynamic",
                value: "doNo",
                label: "HBOL #"
            },
            {
                title: "Pickup Appointment",
                isShow: false,
                type: "date",
                value: "pickupFromTime",
                label: "Pickup Appointment",
                format: "LL"
            },
            {
                title: "Delivery Appointment",
                isShow: false,
                type: "date",
                value: "deliveryFromTime",
                label: "Delivery Appointment",
                format: "LL"
            },
            {
                title: "Last Free day",
                isShow: false,
                type: "date",
                format: "LL",
                value: "lastFreeDay",
                label: "Last Free Day",
            },
            {
                title: "Last Free Date Return Day",
                isShow: false,
                type: "date",
                value: "freeReturnDate",
                label: "Last Free Date Return Day",
                format: "LL"
            }, {
                title: "Pull Container Date",
                isShow: false,
                type: "date",
                value: "pullContainerDate",
                label: "Pull Container Date",
                format: "LL"
            }, {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            }, {
                title: "Return Container Date",
                isShow: false,
                type: "date",
                value: "returnContainerDate",
                label: "Return Container Date",
                format: "LL"
            }, 
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",

            },
            {
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size",

            }, {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",

            },
             {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "Purchase Order #",

            }, {
                title: "Temperature",
                isShow: false,
                type: "dynamic",
                value: "temperature",
                label: "Temperature",

            }, {
                title: "Hazmat",
                isShow: false,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat",

            }, {
                title: "Shipment #",
                isShow: false,
                type: "dynamic",
                value: "shipmentNo",
                label: "Shipment #",

            }, {
                title: "Pick Up #",
                isShow: false,
                type: "dynamic",
                value: "callerPONo",
                label: "Pick Up #",

            }, {
                title: "Chassis #",
                isShow: false,
                type: "dynamic",
                value: "chassisIdName",
                label: "Chassis #",

            }, {
                title: "Chassis Size",
                isShow: false,
                type: "dynamic",
                value: "chassisSize",
                label: "Chassis Size",

            }, {
                title: "Chassis Type",
                isShow: false,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",

            }, {
                title: "Chassis Owner",
                isShow: false,
                type: "dynamic",
                value: "chassisOwnerName",
                label: "Chassis Owner",

            }, {
                title: "Steamship Line",
                isShow: true,
                type: "dynamic",
                value: "containerOwner",
                label: "Steamship Line",

            }, {
                title: "Seal #",
                isShow: true,
                type: "dynamic",
                value: "sealNo",
                label: "Seal #",

            }, {
                title: "Vessel Name",
                isShow: false,
                type: "dynamic",
                value: "deliveryOrderNo",
                label: "Vessel Name",

            }, {
                title: "Voyage Name",
                isShow: false,
                type: "dynamic",
                value: "releaseNo",
                label: "Voyage Name",
            }, {
                title: "Genset #",
                isShow: false,
                type: "dynamic",
                value: "genset",
                label: "Genset #",

            }, {
                title: "Appointment #",
                isShow: false,
                type: "dynamic",
                value: "appointmentNo",
                label: "Appointment #",

            }, {
                title: "Return #",
                isShow: false,
                type: "dynamic",
                value: "returnNo",
                label: "Return #",

            }, {
                title: "Reservation #",
                isShow: false,
                type: "dynamic",
                value: "reservationNo",
                label: "Reservation #",

            },
           {
              title : "Hook Chassis",
              isShow : false,
              type : "dynamic",
              value : "chassisPickup",
              label : "Hook Chassis"   
           },
           {
            title : "Terminate Chassis",
            isShow : false,
            type : "dynamic",
            value : "chassisTermination",
            label : "Terminate Chassis"   
         },
         {
            title : "Total Miles",
            isShow : false,
            type : "dynamic",
            value : "totalMiles",
            label : "Total Miles",
            unit : "mi"   
         },
         {
            title : "Gray Pool Container #",
            isShow : false,
            type : "dynamic",
            value : "grayContainerNo",
            label : "Gray Pool Container #"   
         },
         {
            title : "Gray Pool Chassis #",
            isShow : false,
            type : "dynamic",
            value : "grayChassisNo",
            label : "Gray Pool Chassis #"   
         },
         {
             title : "Discharged Date", 
             isShow : false,
             type : 'date',
             value : "dischargedDate",
             label : 'Discharged Date',
             format : "LL",
         },
         {
            title : "Ingate Date", 
            isShow : false,
            type : 'date',
            value : "ingateDate",
            label : 'Ingate Date',
            format : "LL",
         },
         {
            title : "Outgate Date", 
            isShow : false,
            type : 'date',
            value : "outgateDate",
            label : 'Outgate Date',
            format : "LL",
         }
        ],
    },
    commodity: {
        isShow: true,
        fields: [{
                title: "Commodity",
                isShow: true,
                type: "dynamic",
                value: "commodity",
                label: "Commodity",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
            },
            {
                title: "Pallets",
                isShow: true,
                type: "dynamic",
                value: "pallets",
                label: "Pallets",
                isColor : true
            },
            {
                title: "Pieces",
                isShow: true,
                type: "dynamic",
                value: "pieces",
                label: "Pieces",
                isColor : true
            }
        ],
    },
    pricing: {
        isShow: true,
        fields: [{
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "Charge Name",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "Units",
                isColor : true
            },
            {
                title : "Free Units",
                isShow: true,
                type: "dynamic",
                value: "freeDays",
                label: "Free Units",
                isColor : true
                },
            {
                title: "Rates",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "Rates",
                isColor : true
            },
            {
                title: "Charges",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "Charges",
                isColor : true
            },
        ],
    },
    notes : {
        isShow : true,
        fields : [
            {
                title : "Driver Notes",
                isShow : true,
                type : "dynamic",
                value : "driverNote",
                label: "Driver Notes"
            },
            {
                title : "Billing Notes",
                isShow : true,
                type :"dynamic",
                value :"billingNote",
                label : "Billing Notes"
            },
            {
                title : "Yard Notes",
                isShow : true,
                type :"dynamic",
                value :"yardNote",
                label : "Yard Notes"
            },
            {
                title : "Load Notes",
                isShow : true,
                type : "dynamic",
                value : "officeNote",
                label: "Load Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]},
    watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}
};
export const deliveryOrderJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
     header: {
          isShow: true,
          fields: [
          {
          title: 'Logo',
          isShow: true,
          type: "image",
          value: "",
          label: "Logo"
          },
          {
            title: 'Company Name',
            isShow: true,
            type: "text",
            value: "company_name",
            label: "Company Name"
          },
          {
             title : 'Address',
             isShow: true,
             type: "text",
             value: "company_address",
             label: "Address",
         },{
         title : 'Phone',
         isShow: true, type: "text", value: "mobile", label: "Phone",
         isColor : true
         },
         {
             title : 'Website',
             isShow: false,
             type: "text",
             value: "www.example.com",
             label: "Website",
             isColor : true
         }
         ]
        },
        section1: {
            isShow: true,
            isColor : true,
            fields: [{
                title: "Delivery Order",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "Delivery Order",
            }]
        },
        subSection: {
          isShow: true,
          fields : [
            {
                title: "Delivery Appointment",
                isShow: true,
                type: "date",
                value: "deliveryFromTime",
                label: "Date",
                format: "LL"
            },{
              title : "Pickup #",
              isShow: true,
              type: "dynamic",
              value: "callerPONo",
              label: "Pickup #",
            },{
              title : "Reference #",
              isShow: true,
              type: "dynamic",
              value: "secondaryReferenceNo",
              label: "Reference #",
            },{
                title : "Driver Name",
                isShow: true,
                type: "dynamic",
                value: "driverName",
                label: "Driver Name",
              },
          ],
        },
        section2: {
          isShow: true,
          fields : [
             
              {
                  title : "PICK EMPTY",
                  isShow: true,
                  type: "text",
                  value: "PICK EMPTY",
                  label: "PICK EMPTY",
                  isColor : true
              },{
                  title : "GET LOADED",
                  isShow: true,
                  type: "text",
                  value: "GET LOADED",
                  label: "GET LOADED",
                  isColor : true
              },{
                  title : "RETURN LOAD",
                  isShow: true, 
                  type: "text", 
                  value: "RETURN LOAD", 
                  label: "RETURN LOAD",
                  isColor : true
              }
          ],
         
        },
        orderDetail: {
            isShow: true,
            type: "text",
            value: "Order Details",
            label: "Order Details",
            title: "Order Details",
            fields: [{
                    title: "Reference #",
                    isShow: true,
                    type: "dynamic",
                    value: "secondaryReferenceNo",
                    label: "Reference #"
                },
                {
                    title: "Booking/BL",
                    isShow: true,
                    type: "dynamic",
                    value: "bookingNo",
                    label: "Booking/BL",
    
                },
                {
                    title: "MBOL #",
                    isShow: true,
                    type: "dynamic",
                    value: "callerbillLandingNo",
                    label: "MBOL #",
                },
                {
                    title: "HBOL #",
                    isShow: true,
                    type: "dynamic",
                    value: "doNo",
                    label: "HBOL #"
                },
                {
                    title: "Pickup Appointment",
                    isShow: false,
                    type: "date",
                    value: "pickupFromTime",
                    label: "Pickup Appointment",
                    format: "LL"
                },
                {
                    title: "Delivery Appointment",
                    isShow: false,
                    type: "date",
                    value: "deliveryFromTime",
                    label: "Delivery Appointment",
                    format: "LL"
                },
                {
                    title: "Last Free day",
                    isShow: false,
                    type: "date",
                    format: "LL",
                    value: "lastFreeDay",
                    label: "Last Free Day",
                },
                {
                    title: "Last Free Date Return Day",
                    isShow: false,
                    type: "date",
                    value: "freeReturnDate",
                    label: "Last Free Date Return Day",
                    format: "LL"
                }, {
                    title: "Pull Container Date",
                    isShow: false,
                    type: "date",
                    value: "pullContainerDate",
                    label: "Pull Container Date",
                    format: "LL"
                }, {
                    title: "Deliver Load Date",
                    isShow: false,
                    type: "date",
                    value: "deliverLoadDate",
                    label: "Deliver Load Date",
                    format: "LL"
                }, {
                    title: "Return Container Date",
                    isShow: false,
                    type: "date",
                    value: "returnContainerDate",
                    label: "Return Container Date",
                    format: "LL"
                }, 
                {
                    title: "Container #",
                    isShow: true,
                    type: "dynamic",
                    value: "containerNo",
                    label: "Container #",
    
                },
                {
                    title: "Container Size",
                    isShow: true,
                    type: "dynamic",
                    value: "containerSize",
                    label: "Container Size",
    
                }, {
                    title: "Container Type",
                    isShow: true,
                    type: "dynamic",
                    value: "containerType",
                    label: "Container Type",
    
                },
                 {
                    title: "Purchase Order #",
                    isShow: true,
                    type: "dynamic",
                    value: "purchaseOrderNo",
                    label: "Purchase Order #",
    
                }, {
                    title: "Temperature",
                    isShow: false,
                    type: "dynamic",
                    value: "temperature",
                    label: "Temperature",
    
                }, {
                    title: "Hazmat",
                    isShow: false,
                    type: "dynamic",
                    value: "hazmat",
                    label: "Hazmat",
    
                }, {
                    title: "Shipment #",
                    isShow: false,
                    type: "dynamic",
                    value: "shipmentNo",
                    label: "Shipment #",
    
                }, {
                    title: "Pick Up #",
                    isShow: false,
                    type: "dynamic",
                    value: "callerPONo",
                    label: "Pick Up #",
    
                }, {
                    title: "Chassis #",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisIdName",
                    label: "Chassis #",
    
                }, {
                    title: "Chassis Size",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisSize",
                    label: "Chassis Size",
    
                }, {
                    title: "Chassis Type",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisType",
                    label: "Chassis Type",
    
                }, {
                    title: "Chassis Owner",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisOwnerName",
                    label: "Chassis Owner",
    
                }, {
                    title: "Steamship Line",
                    isShow: true,
                    type: "dynamic",
                    value: "containerOwner",
                    label: "Steamship Line",
    
                }, {
                    title: "Seal #",
                    isShow: true,
                    type: "dynamic",
                    value: "sealNo",
                    label: "Seal #",
    
                }, {
                    title: "Vessel Name",
                    isShow: false,
                    type: "dynamic",
                    value: "deliveryOrderNo",
                    label: "Vessel Name",
    
                }, {
                    title: "Voyage Name",
                    isShow: false,
                    type: "dynamic",
                    value: "releaseNo",
                    label: "Voyage Name",
                }, {
                    title: "Genset #",
                    isShow: false,
                    type: "dynamic",
                    value: "genset",
                    label: "Genset #",
    
                }, {
                    title: "Appointment #",
                    isShow: false,
                    type: "dynamic",
                    value: "appointmentNo",
                    label: "Appointment #",
    
                }, {
                    title: "Return #",
                    isShow: false,
                    type: "dynamic",
                    value: "returnNo",
                    label: "Return #",
    
                }, {
                    title: "Reservation #",
                    isShow: false,
                    type: "dynamic",
                    value: "reservationNo",
                    label: "Reservation #",
    
                },
               {
                  title : "Hook Chassis",
                  isShow : false,
                  type : "dynamic",
                  value : "chassisPickup",
                  label : "Hook Chassis"   
               },
               {
                title : "Terminate Chassis",
                isShow : false,
                type : "dynamic",
                value : "chassisTermination",
                label : "Terminate Chassis"   
             },
             {
                title : "Total Miles",
                isShow : false,
                type : "dynamic",
                value : "totalMiles",
                label : "Total Miles",
                unit : "mi"   
             },
             {
                title : "Gray Pool Container #",
                isShow : false,
                type : "dynamic",
                value : "grayContainerNo",
                label : "Gray Pool Container #"   
             },
             {
                title : "Gray Pool Chassis #",
                isShow : false,
                type : "dynamic",
                value : "grayChassisNo",
                label : "Gray Pool Chassis #"   
             },
             {
                 title : "Discharged Date", 
                 isShow : false,
                 type : 'date',
                 value : "dischargedDate",
                 label : 'Discharged Date',
                 format : "LL",
             },
             {
                title : "Ingate Date", 
                isShow : false,
                type : 'date',
                value : "ingateDate",
                label : 'Ingate Date',
                format : "LL",
             },
             {
                title : "Outgate Date", 
                isShow : false,
                type : 'date',
                value : "outgateDate",
                label : 'Outgate Date',
                format : "LL",
             }
            ],
        },
        commodity: {
          isShow: true,
          fields: [
             {
                title : "commodity",
                isShow: true,
                type: "dynamic",
                value: "commodity",
                label: "Commodity",
                isColor : true
              },
               {
                title : "description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
              },
              {
                title : "weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
              },
               {
                title : "pallets",
                isShow: true,
                type: "dynamic",
                value: "pallets",
                label: "Pallets",
                isColor : true
              },
               {
                title : "pieces",
                isShow: true,
                type: "dynamic",
                value: "pieces",
                label: "Pieces",
                isColor : true
              }
          ],
        },
        
        loadInfos: {
            isShow: true,
              fields: [
                {    
                    title : "Print Name",
                    isShow: true,
                    type: "dynamic",
                    value: "partyName",
                    label: "Print Name",
                },
                {
                    title : "Receiver Signature",
                    isShow: true,
                    type: "dynamic",
                    isUrl: true,
                    value: "partySignature",
                    label: "Receiver Signature",
                },
                {
                    title : "Date",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "agreementTime",
                    label: "Date",
                },
                {
                    title : "Time In",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "timeIn",
                    label: "Time In",
                },
                {
                    title : "Time Out",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "timeOut",
                    label: "Time Out",
                },
                {
                    title : "Signature",
                    isShow: true,
                    type: "dynamic",
                    isUrl: true,
                    value: "signatureurl",
                    label: "Signature",
                },
                {
                    title : "Date",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "LFD",
                    label: "Date",
                },
              ],
        },
        notes : {
            isShow : false,
            fields : [
                {
                    title : "Driver Notes",
                    isShow : true,
                    type : "dynamic",
                    value : "driverNote",
                    label: "Driver Notes"
                },
                {
                    title : "Billing Notes",
                    isShow : true,
                    type :"dynamic",
                    value :"billingNote",
                    label : "Billing Notes"
                },
                {
                    title : "Yard Notes",
                    isShow : true,
                    type :"dynamic",
                    value :"yardNote",
                    label : "Yard Notes"
                },
                {
                    title : "Load Notes",
                    isShow : true,
                    type : "dynamic",
                    value : "officeNote",
                    label: "Load Notes"
                },
                {
                    title : "Customer Notes",
                    isShow : false,
                    type : "dynamic",
                    value : "customerNotes",
                    label: "Customer Notes"
                },
            ]
        },
        disclaimer :{
            isShow : true,
            fields: [
            {
                title: 'Disclaimer',
                isShow: true,
                type: "text",
                value: "Disclaimer",
                label: "Disclaimer",
            }
        ]}
};

export const ProofofDeliveryJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
     header: {
          isShow: true,
          fields: [
            {
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title : 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            },{
                title : 'Phone',
                isShow: true, type: "text", value: "mobile", label: "Phone", isColor : true
            },
            {
                title : 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
         ]
        },
        section1: {
            isShow: true,
            fields: [{
                title: "Proof of Delivery",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "Proof of Delivery",
            }]
        },
        subSection: {
          isShow: true,
          fields : [
            {
                title : "Delivery Appointment",
                isShow: true,
                type: "date",
                format: "LL",
                value: "deliveryFromTime",
                label: "Delivery Appt",
            },{
              title : "Pickup #",
              isShow: true,
              type: "dynamic",
              value: "callerPONo",
              label: "Pickup #",
            },{
              title : "Reference #",
              isShow: true,
              type: "dynamic",
              value: "secondaryReferenceNo",
              label: "Reference #",
            },{
                title : "Driver",
                isShow: true,
                type: "dynamic",
                value: "driverName",
                label: "Driver",
            },
          ],
        },
        section2: {
          isShow: true,
          fields : [
              {
                  title : "CUSTOMER",
                  isShow : true,
                  type : "text",
                  value : "caller",
                  label : "CUSTOMER",
                  isColor : true

              },
              {
                  title : "PICKUP-LOADED",
                  isShow: true,
                  type: "text",
                  value: "PORT/SHIPPER",
                  label: "PICK UP - LOADED",
                  isColor : true
              },{
                  title : "DELIVERLOAD",
                  isShow: true,
                  type: "text",
                  value: "CONSIGNEE",
                  label: "DELIVER LOAD",
                  isColor : true
              },{
                  title : "RETURNEMPTY",
                  isShow: true, 
                  type: "text", 
                  value: "RETURN", 
                  label: "RETURN EMPTY",
                  isColor : true
              }
          ],
         
        },
        orderDetail: {
            isShow: true,
            type: "text",
            value: "Order Details",
            label: "Order Details",
            title: "Order Details",
            fields: [{
                    title: "Reference #",
                    isShow: true,
                    type: "dynamic",
                    value: "secondaryReferenceNo",
                    label: "Reference #"
                },
                {
                    title: "Booking/BL",
                    isShow: true,
                    type: "dynamic",
                    value: "bookingNo",
                    label: "Booking/BL",
    
                },
                {
                    title: "MBOL #",
                    isShow: true,
                    type: "dynamic",
                    value: "callerbillLandingNo",
                    label: "MBOL #",
                },
                {
                    title: "HBOL #",
                    isShow: true,
                    type: "dynamic",
                    value: "doNo",
                    label: "HBOL #"
                },
                {
                    title: "Pickup Appointment",
                    isShow: false,
                    type: "date",
                    value: "pickupFromTime",
                    label: "Pickup Appointment",
                    format: "LL"
                },
                {
                    title: "Delivery Appointment",
                    isShow: false,
                    type: "date",
                    value: "deliveryFromTime",
                    label: "Delivery Appointment",
                    format: "LL"
                },
                {
                    title: "Last Free day",
                    isShow: false,
                    type: "date",
                    format: "LL",
                    value: "lastFreeDay",
                    label: "Last Free Day",
                },
                {
                    title: "Last Free Date Return Day",
                    isShow: false,
                    type: "date",
                    value: "freeReturnDate",
                    label: "Last Free Date Return Day",
                    format: "LL"
                }, {
                    title: "Pull Container Date",
                    isShow: false,
                    type: "date",
                    value: "pullContainerDate",
                    label: "Pull Container Date",
                    format: "LL"
                }, {
                    title: "Deliver Load Date",
                    isShow: false,
                    type: "date",
                    value: "deliverLoadDate",
                    label: "Deliver Load Date",
                    format: "LL"
                }, {
                    title: "Return Container Date",
                    isShow: false,
                    type: "date",
                    value: "returnContainerDate",
                    label: "Return Container Date",
                    format: "LL"
                }, 
                {
                    title: "Container #",
                    isShow: true,
                    type: "dynamic",
                    value: "containerNo",
                    label: "Container #",
    
                },
                {
                    title: "Container Size",
                    isShow: true,
                    type: "dynamic",
                    value: "containerSize",
                    label: "Container Size",
    
                }, {
                    title: "Container Type",
                    isShow: true,
                    type: "dynamic",
                    value: "containerType",
                    label: "Container Type",
    
                },
                 {
                    title: "Purchase Order #",
                    isShow: true,
                    type: "dynamic",
                    value: "purchaseOrderNo",
                    label: "Purchase Order #",
    
                }, {
                    title: "Temperature",
                    isShow: false,
                    type: "dynamic",
                    value: "temperature",
                    label: "Temperature",
    
                }, {
                    title: "Hazmat",
                    isShow: false,
                    type: "dynamic",
                    value: "hazmat",
                    label: "Hazmat",
    
                }, {
                    title: "Shipment #",
                    isShow: false,
                    type: "dynamic",
                    value: "shipmentNo",
                    label: "Shipment #",
    
                }, {
                    title: "Pick Up #",
                    isShow: false,
                    type: "dynamic",
                    value: "callerPONo",
                    label: "Pick Up #",
    
                }, {
                    title: "Chassis #",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisIdName",
                    label: "Chassis #",
    
                }, {
                    title: "Chassis Size",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisSize",
                    label: "Chassis Size",
    
                }, {
                    title: "Chassis Type",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisType",
                    label: "Chassis Type",
    
                }, {
                    title: "Chassis Owner",
                    isShow: false,
                    type: "dynamic",
                    value: "chassisOwnerName",
                    label: "Chassis Owner",
    
                }, {
                    title: "Steamship Line",
                    isShow: true,
                    type: "dynamic",
                    value: "containerOwner",
                    label: "Steamship Line",
    
                }, {
                    title: "Seal #",
                    isShow: true,
                    type: "dynamic",
                    value: "sealNo",
                    label: "Seal #",
    
                }, {
                    title: "Vessel Name",
                    isShow: false,
                    type: "dynamic",
                    value: "deliveryOrderNo",
                    label: "Vessel Name",
    
                }, {
                    title: "Voyage Name",
                    isShow: false,
                    type: "dynamic",
                    value: "releaseNo",
                    label: "Voyage Name",
                }, {
                    title: "Genset #",
                    isShow: false,
                    type: "dynamic",
                    value: "genset",
                    label: "Genset #",
    
                }, {
                    title: "Appointment #",
                    isShow: false,
                    type: "dynamic",
                    value: "appointmentNo",
                    label: "Appointment #",
    
                }, {
                    title: "Return #",
                    isShow: false,
                    type: "dynamic",
                    value: "returnNo",
                    label: "Return #",
    
                }, {
                    title: "Reservation #",
                    isShow: false,
                    type: "dynamic",
                    value: "reservationNo",
                    label: "Reservation #",
    
                },
               {
                  title : "Hook Chassis",
                  isShow : false,
                  type : "dynamic",
                  value : "chassisPickup",
                  label : "Hook Chassis"
               },
               {
                title : "Terminate Chassis",
                isShow : false,
                type : "dynamic",
                value : "chassisTermination",
                label : "Terminate Chassis"   
             },
             {
                title : "Total Miles",
                isShow : false,
                type : "dynamic",
                value : "totalMiles",
                label : "Total Miles",
                unit : "mi"   
             },
             {
                title : "Gray Pool Container #",
                isShow : false,
                type : "dynamic",
                value : "grayContainerNo",
                label : "Gray Pool Container #"   
             },
             {
                title : "Gray Pool Chassis #",
                isShow : false,
                type : "dynamic",
                value : "grayChassisNo",
                label : "Gray Pool Chassis #"   
             },
             {
                 title : "Discharged Date", 
                 isShow : false,
                 type : 'date',
                 value : "dischargedDate",
                 label : 'Discharged Date',
                 format : "LL",
             },
             {
                title : "Ingate Date", 
                isShow : false,
                type : 'date',
                value : "ingateDate",
                label : 'Ingate Date',
                format : "LL",
             },
             {
                title : "Outgate Date", 
                isShow : false,
                type : 'date',
                value : "outgateDate",
                label : 'Outgate Date',
                format : "LL",
             }
            ],
        },
        commodity: {
          isShow: true,
          fields: [
             {
                title : "Commodity",
                isShow: true,
                type: "dynamic",
                value: "commodity",
                label: "Commodity",
                isColor : true
              },
               {
                title : "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
              },
              {
                title : "Weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
              },
               {
                title : "Pallets",
                isShow: true,
                type: "dynamic",
                value: "pallets",
                label: "Pallets",
                isColor : true
              },
               {
                title : "Pieces",
                isShow: true,
                type: "dynamic",
                value: "pieces",
                label: "Pieces",
                isColor : true
              }
          ],
        },      
        loadInfos: {
            isShow: true,
              fields: [
                {    
                    title : "Print Name",
                    isShow: true,
                    type: "dynamic",
                    value: "partyName",
                    label: "Print Name",
                },
                {
                    title : "Receiver Signature",
                    isShow: true,
                    isUrl: true,
                    type: "dynamic",
                    value: "partySignature",
                    label: "Receiver Signature",
                },
                {
                    title : "Date",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "agreementTime",
                    label: "Date",
                },
                {
                    title : "Time In",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "timeIn",
                    label: "Time In",
                },
                {
                    title : "Time Out",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "timeOut",
                    label: "Time Out",
                },
                {
                    title : "Signature",
                    isShow: true,
                    type: "dynamic",
                    isUrl: true,
                    value: "signatureurl",
                    label: "Signature",
                },
                {
                    title : "Date",
                    isShow: true,
                    type: "date",
                    format: "LL",
                    value: "LFD",
                    label: "Date",
                },
              ],
        },  
        notes : {
            isShow : false,
            fields : [
                {
                    title : "Driver Notes",
                    isShow : true,
                    type : "dynamic",
                    value : "driverNote",
                    label: "Driver Notes"
                },
                {
                    title : "Billing Notes",
                    isShow : true,
                    type :"dynamic",
                    value :"billingNote",
                    label : "Billing Notes"
                },
                {
                    title : "Yard Notes",
                    isShow : true,
                    type :"dynamic",
                    value :"yardNote",
                    label : "Yard Notes"
                },
                {
                    title : "Load Notes",
                    isShow : true,
                    type : "dynamic",
                    value : "officeNote",
                    label: "Load Notes"
                },
                {
                    title : "Customer Notes",
                    isShow : false,
                    type : "dynamic",
                    value : "customerNotes",
                    label: "Customer Notes"
                },
            ]
        },
        disclaimer :{
            isShow : true,
            fields: [
            {
                title: 'Disclaimer',
                isShow: true,
                type: "text",
                value: "Disclaimer",
                label: "Disclaimer",
            }
        ]}
}

export const rateConfJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
    header: {
          isShow: true,
          fields: [
          {
            title: 'Logo',
            isShow: true,
            type: "image",
            value: "",
            label: "Logo"
          }, 
          {
            title: 'Company Name',
            isShow: true,
            type: "text",
            value: "company_name",
            label: "Company Name"
          },
          {
            title : 'Address',
            isShow: true,
            type: "text",
            value: "company_address",
            label: "Address",
        },{
            title : 'Phone',
            isShow: true, 
            type: "text", 
            value: "mobile", 
            label: "Phone",
            isColor : true
        },
        {
            title : 'Website',
            isShow: false,
            type: "text",
            value: "www.example.com",
            label: "Website",
            isColor : true
        },
        {
            title : 'Remit Payment to:',
            isShow: false,
            type: "text",
            value: "remiteAddress",
            label: "Remit Payment to",
            isColor : true
        },
         ]
        },
    section1: {
        isShow: true,
        isColor : true,
        fields: [{
            title: "Rate Confirmation",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Rate Confirmation",
        }]
    },
    subSection: {
        isShow: true,
        fields : [
            {
            title : "Rate Confirmation",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Rate Confirmation",
        },{
            title : "Rate Confirmation Date",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Rate Confirmation Date",
        },{
            title : "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        },{
            title : "Due Date",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        }
        ],
    },
    section2: {
        isShow: true,
        fields : [
        
            {
                title : "BILL TO",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },{
                title : "PORT/SHIPPER",
                isShow: true,
                type: "text",
                value: "PORT/SHIPPER",
                label: "PORT/SHIPPER",
                isColor : true
            },{
                title : "CONSIGNEE",
                isShow: true, 
                type: "text", 
                value: "CONSIGNEE", 
                label: "CONSIGNEE",
                isColor : true
            },{
            title : "RETURN",
            isShow: true, 
            type: "text", 
            value: "RETURN", 
            label: "RETURN",
            isColor : true
        }
        ],
        
    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Reference #",
                isShow: true,
                type: "dynamic",
                value: "secondaryReferenceNo",
                label: "Reference #"
            },
            {
                title: "Booking/BL",
                isShow: true,
                type: "dynamic",
                value: "bookingNo",
                label: "Booking/BL",

            },
            {
                title: "MBOL #",
                isShow: true,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            },
            {
                title: "HBOL #",
                isShow: true,
                type: "dynamic",
                value: "doNo",
                label: "HBOL #"
            },
            {
                title: "Pickup Appointment",
                isShow: false,
                type: "date",
                value: "pickupFromTime",
                label: "Pickup Appointment",
                format: "LL"
            },
            {
                title: "Delivery Appointment",
                isShow: false,
                type: "date",
                value: "deliveryFromTime",
                label: "Delivery Appointment",
                format: "LL"
            },
            {
                title: "Last Free day",
                isShow: false,
                type: "date",
                format: "LL",
                value: "lastFreeDay",
                label: "Last Free Day",
            },
            {
                title: "Last Free Date Return Day",
                isShow: false,
                type: "date",
                value: "freeReturnDate",
                label: "Last Free Date Return Day",
                format: "LL"
            }, {
                title: "Pull Container Date",
                isShow: false,
                type: "date",
                value: "pullContainerDate",
                label: "Pull Container Date",
                format: "LL"
            }, {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            }, {
                title: "Return Container Date",
                isShow: false,
                type: "date",
                value: "returnContainerDate",
                label: "Return Container Date",
                format: "LL"
            }, 
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",

            },
            {
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size",

            }, {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",

            },
             {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "Purchase Order #",

            }, {
                title: "Temperature",
                isShow: false,
                type: "dynamic",
                value: "temperature",
                label: "Temperature",

            }, {
                title: "Hazmat",
                isShow: false,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat",

            }, {
                title: "Shipment #",
                isShow: false,
                type: "dynamic",
                value: "shipmentNo",
                label: "Shipment #",

            }, {
                title: "Pick Up #",
                isShow: false,
                type: "dynamic",
                value: "callerPONo",
                label: "Pick Up #",

            }, {
                title: "Chassis #",
                isShow: false,
                type: "dynamic",
                value: "chassisIdName",
                label: "Chassis #",

            }, {
                title: "Chassis Size",
                isShow: false,
                type: "dynamic",
                value: "chassisSize",
                label: "Chassis Size",

            }, {
                title: "Chassis Type",
                isShow: false,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",

            }, {
                title: "Chassis Owner",
                isShow: false,
                type: "dynamic",
                value: "chassisOwnerName",
                label: "Chassis Owner",

            }, {
                title: "Steamship Line",
                isShow: true,
                type: "dynamic",
                value: "containerOwner",
                label: "Steamship Line",

            }, {
                title: "Seal #",
                isShow: true,
                type: "dynamic",
                value: "sealNo",
                label: "Seal #",

            }, {
                title: "Vessel Name",
                isShow: false,
                type: "dynamic",
                value: "deliveryOrderNo",
                label: "Vessel Name",

            }, {
                title: "Voyage Name",
                isShow: false,
                type: "dynamic",
                value: "releaseNo",
                label: "Voyage Name",
            }, {
                title: "Genset #",
                isShow: false,
                type: "dynamic",
                value: "genset",
                label: "Genset #",

            }, {
                title: "Appointment #",
                isShow: false,
                type: "dynamic",
                value: "appointmentNo",
                label: "Appointment #",

            }, {
                title: "Return #",
                isShow: false,
                type: "dynamic",
                value: "returnNo",
                label: "Return #",

            }, {
                title: "Reservation #",
                isShow: false,
                type: "dynamic",
                value: "reservationNo",
                label: "Reservation #",

            },
           {
              title : "Hook Chassis",
              isShow : false,
              type : "dynamic",
              value : "chassisPickup",
              label : "Hook Chassis"   
           },
           {
            title : "Terminate Chassis",
            isShow : false,
            type : "dynamic",
            value : "chassisTermination",
            label : "Terminate Chassis"   
         },
         {
            title : "Total Miles",
            isShow : false,
            type : "dynamic",
            value : "totalMiles",
            label : "Total Miles",
            unit : "mi"   
         },
         {
            title : "Gray Pool Container #",
            isShow : false,
            type : "dynamic",
            value : "grayContainerNo",
            label : "Gray Pool Container #"   
         },
         {
            title : "Gray Pool Chassis #",
            isShow : false,
            type : "dynamic",
            value : "grayChassisNo",
            label : "Gray Pool Chassis #"   
         },
         {
             title : "Discharged Date", 
             isShow : false,
             type : 'date',
             value : "dischargedDate",
             label : 'Discharged Date',
             format : "LL",
         },
         {
            title : "Ingate Date", 
            isShow : false,
            type : 'date',
            value : "ingateDate",
            label : 'Ingate Date',
            format : "LL",
         },
         {
            title : "Outgate Date", 
            isShow : false,
            type : 'date',
            value : "outgateDate",
            label : 'Outgate Date',
            format : "LL",
         }
        ],
    },
    commodity: {
        isShow: true,
        fields: [
            {
            title : "Commodity",
            isShow: true,
            type: "dynamic",
            value: "commodity",
            label: "Commodity",
            isColor : true
            },
            {
            title : "Description",
            isShow: true,
            type: "dynamic",
            value: "description",
            label: "Description",
            isColor : true
            },
            {
            title : "Weight",
            isShow: true,
            type: "dynamic",
            value: "weight",
            label: "Weight",
            isColor : true,
            unit : "LBS"
            },
            {
            title : "Pallets",
            isShow: true,
            type: "dynamic",
            value: "pallets",
            label: "Pallets",
            isColor : true
            },
            {
            title : "Pieces",
            isShow: true,
            type: "dynamic",
            value: "pieces",
            label: "Pieces",
            isColor : true
            }
        ],
    },
    pricing: {
        isShow: true,
        fields: [
            {    
            title : "Charge Name",
            isShow: true,
            type: "dynamic",
            value: "name",
            label: "Charge Name",
            isColor : true
            },
            {
            title : "Description",
            isShow: true,
            type: "dynamic",
            value: "description",
            label: "Description",
            isColor : true
            },
            {
            title : "Units",
            isShow: true,
            type: "dynamic",
            value: "unit",
            label: "Units",
            isColor : true
            },
            {
            title : "Free Units",
            isShow: true,
            type: "dynamic",
            value: "freeDays",
            label: "Free Units",
            isColor : true
            },
            {
            title : "Rates",
            isShow: true,
            type: "dynamic",
            value: "chargePerDay",
            label: "Rates",
            isColor : true
            },
            {
            title : "Charges",
            isShow: true,
            type: "dynamic",
            value: "finalAmount",
            label: "Charges",
            isColor : true
            },
        ],
    },
    estimatedRate :{
        isShow : true,
        fields: [
        {
            title: 'Total Estimated Rate',
            isShow: true,
            type: "text",
            value: "finalAmount",
            label: "Total Estimated Rate",
        }
    ]},
    loadInfos: {
        isShow: true,
          fields: [
            {
                title : "Signature",
                isShow: true,
                type: "dynamic",
                value: "signature",
                label: "Signature",
            },
            {
                title : "Date",
                isShow: true,
                type: "dynamic",
                value: "signdate",
                label: "Date",
            },
          ],
    }, 
    notes : {
        isShow : false,
        fields : [
            {
                title : "Driver Notes",
                isShow : true,
                type : "dynamic",
                value : "driverNote",
                label: "Driver Notes"
            },
            {
                title : "Billing Notes",
                isShow : true,
                type :"dynamic",
                value :"billingNote",
                label : "Billing Notes"
            },
            {
                title : "Yard Notes",
                isShow : true,
                type :"dynamic",
                value :"yardNote",
                label : "Yard Notes"
            },
            {
                title : "Load Notes",
                isShow : true,
                type : "dynamic",
                value : "officeNote",
                label: "Load Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]}
}

export const combineInvoiceNewJSON = {
    accentColor: "#367BF6",
    textColor : '#FFF',
    header: {
        isShow: true,
        fields: [
            {
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            }, 
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title : 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            },{
                title : 'Phone',
                isShow: true, 
                type: "text", 
                value: "mobile", 
                label: "Phone",
                isColor : true
            },
            {
                title : 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            },
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Invoice Number",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "InvoiceDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Invoice Date",
        }, {
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        }, {
            title: "DueDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        }, {
            title: "Total Amount",
            isShow: true,
            type: "dynamic",
            value: "totalAmount",
            label: "Total Amount",
        }],
    },
    section2: {
        isShow: true,
        fields: [{
                title: "Bill To",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
        ],

    },
    loads: {
        isShow: true,
        fields: [{
                title: "No #",
                isShow: true,
                type: "dynamic",
                value: "srNo",
                label: "NO #",
                isColor : true
            },
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",
                isColor : true
            },
            {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "PURCHASE ORDER #",
                isColor : true
            },
            {
                title: "Load Ref #",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "LOAD REF #",
                isColor : true
            },
          
            {
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "CHARGE NAME",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "DESCRIPTION",
                isColor : true,
                "subFields" : [
                    {
                        "label" : "REF #",
                        "value" : "secondaryReferenceNo",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - REF #"
                    },
                    {
                        "label" : "ORIGIN",
                        "value" : "shipper",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - ORIGIN"
                    },
                    {
                        "label" : "DEST",
                        "value" : "consignee",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - DESTINATION"
                    }
                ]
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "UNITS",
                isColor : true
            },
            {
                title: "Rates",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "RATES",
                isColor : true
            },
            {
                title: "Charges",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "CHARGES",
                isColor : true
            },
            {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            },
            {
                title: "MBOL #",
                isShow: false,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            }
        ],
    },

    notes : {
        isShow : false,
        fields : [
            {
                title : "Notes",
                isShow : true,
                type : "dynamic",
                value : "notes",
                label: "Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    totalAmount: {
        isShow: true,
        fields: [{
                title: "Total",
                isShow: true,
                type: "dynamic",
                value: "total",
                label: "Total",
                isColor : true
            },
            {
                title: "Amount Paid",
                isShow: true,
                type: "dynamic",
                value: "amountPaid",
                label: "Amount Paid",
                isColor : true
            },
            {
                title: "Amount Due",
                isShow: true,
                type: "dynamic",
                value: "amountDue",
                label: "Amount Due",
                isColor : true
            },
        ],
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]},
    watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}
}

export const combineSubInvoiceNewJSON = {
    accentColor: "#367BF6",
    header: {
        isShow: true,
        fields: [
            {
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            }, 
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title : 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            },{
                title : 'Phone',
                isShow: true, 
                type: "text", 
                value: "mobile", 
                label: "Phone",
                isColor : true
            },
            {
                title : 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            },
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Invoice Number",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Invoice #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "InvoiceDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Invoice Date",
        }, {
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        }, {
            title: "DueDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        }, {
            title: "Total Amount",
            isShow: true,
            type: "dynamic",
            value: "totalAmount",
            label: "Total Amount",
        }],
    },
    section2: {
        isShow: true,
        fields: [{
                title: "Bill To",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
        ],

    },
    loads: {
        isShow: true,
        fields: [{
                title: "No #",
                isShow: true,
                type: "dynamic",
                value: "srNo",
                label: "NO #",
                isColor : true
            },
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",
                isColor : true
            },
            {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "PURCHASE ORDER #",
                isColor : true
            },
            {
                title: "Load Ref #",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "LOAD REF #",
                isColor : true
            },
          
            {
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "CHARGE NAME",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "DESCRIPTION",
                isColor : true,
                "subFields" : [
                    {
                        "label" : "REF #",
                        "value" : "secondaryReferenceNo",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - REF #"
                    },
                    {
                        "label" : "ORIGIN",
                        "value" : "shipper",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - ORIGIN"
                    },
                    {
                        "label" : "DEST",
                        "value" : "consignee",
                        "type" : "dynamic",
                        "isShow" : true,
                        "title" : "Description - DESTINATION"
                    }
                ]
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "UNITS",
                isColor : true
            },
            {
                title: "Rates",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "RATES",
                isColor : true
            },
            {
                title: "Charges",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "CHARGES",
                isColor : true
            },
        ],
    },

    notes : {
        isShow : true,
        fields : [
            {
                title : "Notes",
                isShow : true,
                type : "dynamic",
                value : "notes",
                label: "Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    totalAmount: {
        isShow: true,
        fields: [{
                title: "Total",
                isShow: true,
                type: "dynamic",
                value: "total",
                label: "Total",
                isColor : true
            },
            {
                title: "Amount Paid",
                isShow: true,
                type: "dynamic",
                value: "amountPaid",
                label: "Amount Paid",
                isColor : true
            },
            {
                title: "Amount Due",
                isShow: true,
                type: "dynamic",
                value: "amountDue",
                label: "Amount Due",
                isColor : true
            },
        ],
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]},
    watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}
}

export const carrierRateConJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
    header: {
        isShow: true,
        fields: [{
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title: 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            }, {
                title: 'Phone',
                isShow: true,
                type: "text",
                value: "mobile",
                label: "Phone",
                isColor : true
            },
            {
                title: 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Carrier Rate Confirmation",
            isShow: true,
            type: "dynamic",
            value: "reference_number",
            label: "Carrier Rate Confirmation",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "ConfirmationDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "billingDate",
            label: "Confirmation Date",
        }, {
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        }, {
            title: "Total Amount",
            isShow: true,
            type: "dynamic",
            value: "totalAmount",
            label: "Total Amount",
        }],
    },

    section2: {
        isShow: true,
        fields: [{
                title: "BILL TO",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
            {
                title: "PORT/SHIPPER",
                isShow: true,
                type: "text",
                value: "PORT/SHIPPER",
                label: "PORT/SHIPPER",
                isColor : true
            }, {
                title: "Consignee",
                isShow: true,
                type: "text",
                value: "CONSIGNEE",
                label: "CONSIGNEE",
                isColor : true
            },
        ],

    },
    section3 : {
       isShow: true,
       fields : [
           {title : "Carrier Name",
            isShow : true,
            type: "text",
            value : "registerBusinessName",
            label : "Carrier Name",
          },
          {title : "MC #",
          isShow : true,
          type: "text",
          value : "MCNo",
          label : "MC #",
        },
        {title : "Contact",
          isShow : true,
          type: "text",
          value : "driverName",
          label : "Contact",
        },
        {title : "Phone",
          isShow : true,
          type: "text",
          value : "mobile",
          label : "Phone",
        },

       ]
    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Reference #",
                isShow: true,
                type: "dynamic",
                value: "secondaryReferenceNo",
                label: "Reference #"
            },
            {
                title: "Booking/BL",
                isShow: true,
                type: "dynamic",
                value: "bookingNo",
                label: "Booking/BL",

            },
            {
                title: "MBOL #",
                isShow: true,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            },
            {
                title: "HBOL #",
                isShow: true,
                type: "dynamic",
                value: "doNo",
                label: "HBOL #"
            },
            {
                title: "Pickup Appointment",
                isShow: false,
                type: "date",
                value: "pickupFromTime",
                label: "Pickup Appointment",
                format: "LL"
            },
            {
                title: "Delivery Appointment",
                isShow: false,
                type: "date",
                value: "deliveryFromTime",
                label: "Delivery Appointment",
                format: "LL"
            },
            {
                title: "Last Free day",
                isShow: false,
                type: "date",
                format: "LL",
                value: "lastFreeDay",
                label: "Last Free Day",
            },
            {
                title: "Last Free Date Return Day",
                isShow: false,
                type: "date",
                value: "freeReturnDate",
                label: "Last Free Date Return Day",
                format: "LL"
            }, {
                title: "Pull Container Date",
                isShow: false,
                type: "date",
                value: "pullContainerDate",
                label: "Pull Container Date",
                format: "LL"
            }, {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            }, {
                title: "Return Container Date",
                isShow: false,
                type: "date",
                value: "returnContainerDate",
                label: "Return Container Date",
                format: "LL"
            }, 
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",

            },
            {
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size",

            }, {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",

            },
             {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "Purchase Order #",

            }, {
                title: "Temperature",
                isShow: false,
                type: "dynamic",
                value: "temperature",
                label: "Temperature",

            }, {
                title: "Hazmat",
                isShow: false,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat",

            }, {
                title: "Shipment #",
                isShow: false,
                type: "dynamic",
                value: "shipmentNo",
                label: "Shipment #",

            }, {
                title: "Pick Up #",
                isShow: false,
                type: "dynamic",
                value: "callerPONo",
                label: "Pick Up #",

            }, {
                title: "Chassis #",
                isShow: false,
                type: "dynamic",
                value: "chassisIdName",
                label: "Chassis #",

            }, {
                title: "Chassis Size",
                isShow: false,
                type: "dynamic",
                value: "chassisSize",
                label: "Chassis Size",

            }, {
                title: "Chassis Type",
                isShow: false,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",

            }, {
                title: "Chassis Owner",
                isShow: false,
                type: "dynamic",
                value: "chassisOwnerName",
                label: "Chassis Owner",

            }, {
                title: "Steamship Line",
                isShow: true,
                type: "dynamic",
                value: "containerOwner",
                label: "Steamship Line",

            }, {
                title: "Seal #",
                isShow: true,
                type: "dynamic",
                value: "sealNo",
                label: "Seal #",

            }, {
                title: "Vessel Name",
                isShow: false,
                type: "dynamic",
                value: "deliveryOrderNo",
                label: "Vessel Name",

            }, {
                title: "Voyage Name",
                isShow: false,
                type: "dynamic",
                value: "releaseNo",
                label: "Voyage Name",
            }, {
                title: "Genset #",
                isShow: false,
                type: "dynamic",
                value: "genset",
                label: "Genset #",

            }, {
                title: "Appointment #",
                isShow: false,
                type: "dynamic",
                value: "appointmentNo",
                label: "Appointment #",

            }, {
                title: "Return #",
                isShow: false,
                type: "dynamic",
                value: "returnNo",
                label: "Return #",

            }, {
                title: "Reservation #",
                isShow: false,
                type: "dynamic",
                value: "reservationNo",
                label: "Reservation #",

            },
           {
              title : "Hook Chassis",
              isShow : false,
              type : "dynamic",
              value : "chassisPickup",
              label : "Hook Chassis"   
           },
           {
            title : "Terminate Chassis",
            isShow : false,
            type : "dynamic",
            value : "chassisTermination",
            label : "Terminate Chassis"   
         },
         {
            title : "Total Miles",
            isShow : false,
            type : "dynamic",
            value : "totalMiles",
            label : "Total Miles",
            unit : "mi"   
         },
         {
            title : "Gray Pool Container #",
            isShow : false,
            type : "dynamic",
            value : "grayContainerNo",
            label : "Gray Pool Container #"   
         },
         {
            title : "Gray Pool Chassis #",
            isShow : false,
            type : "dynamic",
            value : "grayChassisNo",
            label : "Gray Pool Chassis #"   
         },
         {
             title : "Discharged Date", 
             isShow : false,
             type : 'date',
             value : "dischargedDate",
             label : 'Discharged Date',
             format : "LL",
         },
         {
            title : "Ingate Date", 
            isShow : false,
            type : 'date',
            value : "ingateDate",
            label : 'Ingate Date',
            format : "LL",
         },
         {
            title : "Outgate Date", 
            isShow : false,
            type : 'date',
            value : "outgateDate",
            label : 'Outgate Date',
            format : "LL",
         }
        ],
    },
    commodity: {
        isShow: true,
        fields: [{
                title: "Commodity",
                isShow: true,
                type: "dynamic",
                value: "commodity",
                label: "Commodity",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
            },
            {
                title: "Pallets",
                isShow: true,
                type: "dynamic",
                value: "pallets",
                label: "Pallets",
                isColor : true
            },
            {
                title: "Pieces",
                isShow: true,
                type: "dynamic",
                value: "pieces",
                label: "Pieces",
                isColor : true
            }
        ],
    },

    pricing: {
        isShow: true,
        fields: [{
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "Charge Name",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "Units",
                isColor : true
            },
            {
                title : "Free Units",
                isShow: true,
                type: "dynamic",
                value: "freeDays",
                label: "Free Units",
                isColor : true
            },
            {
                title: "Rates",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "Rates",
                isColor : true
            },
            {
                title: "Charges",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "Charges",
                isColor : true
            },
        ],
    },
    driverPays: {
        isShow: false,
        fields: [
            {
                title: "Driver",
                isShow: true,
                type: "dynamic",
                value: "driverName",
                label: "Driver",
                isColor : true
            },
            {
                title: "Name",
                isShow: true,
                type: "dynamic",
                value: "status",
                label: "Name",
                isColor : true
            },
            {
                title: "From",
                isShow: true,
                type: "dynamic",
                value: "from",
                label: "From",
                isColor : true
            },
            {
                title: "To",
                isShow: true,
                type: "dynamic",
                value: "to",
                label: "To",
                isColor : true
            },
            {
                title : "Amount",
                isShow: true,
                type: "dynamic",
                value: "amount",
                label: "Amount",
                isColor : true
            },
            {
                title : "Tax Amount",
                isShow: true,
                type: "dynamic",
                value: "taxAmount",
                label: "Tax Amount",
                isColor : true
            },
            {
                title : "Final Amount",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "Final Amount",
                isColor : true
            }
        ],
    },
    estimatedRate :{
        isShow : true,
        fields: [
        {
            title: 'Total Agreed Rate',
            isShow: true,
            type: "text",
            value: "finalAmount",
            label: "Total Agreed Rate",
        }
    ]},
    loadInfos: {
        isShow: true,
          fields: [
            {
                title : "Signature",
                isShow: true,
                type: "dynamic",
                value: "signature",
                label: "Signature",
            },
            {
                title : "Date",
                isShow: true,
                type: "dynamic",
                value: "signdate",
                label: "Date",
            },
          ],
    }, 
    notes : {
        isShow : false,
        fields : [
            {
                title : "Driver Notes",
                isShow : true,
                type : "dynamic",
                value : "driverNote",
                label: "Driver Notes"
            },
            {
                title : "Billing Notes",
                isShow : true,
                type :"dynamic",
                value :"billingNote",
                label : "Billing Notes"
            },
            {
                title : "Yard Notes",
                isShow : true,
                type :"dynamic",
                value :"yardNote",
                label : "Yard Notes"
            },
            {
                title : "Load Notes",
                isShow : true,
                type : "dynamic",
                value : "officeNote",
                label: "Load Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            },
        ]
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]}
}

export const summaryJSON = {
  accentColor: "#367BF6",
  textColor : '#FFF',
  header: {
    fields: [
      {
        label: "Logo",
        value: "",
        type: "image",
        isShow: true,
        title: "Logo",
      },
      {
        label: "Company Name",
        value: "company_name",
        type: "text",
        isShow: true,
        title: "Company Name",
      },
      {
        title: "Address",
        isShow: true,
        type: "text",
        value: "company_address",
        label: "Address",
      },
      {
        title: "Phone",
        isShow: true,
        type: "text",
        value: "mobile",
        label: "Phone",
        isColor: true,
      },
      {
        title: "Website",
        isShow: true,
        type: "text",
        value: "www.example.com",
        label: "Website",
        isColor: true,
      },
    ],
    isShow: true,
  },
  section1: {
    fields: [
      {
        label: "Statement",
        value: "invoiceNumber",
        type: "fixed",
        isShow: true,
        title: "Statement",
      },
    ],
    isColor: true,
    isShow: true,
  },
  subSection: {
    fields: [
      {
        label: "Invoice Date",
        value: "billingDate",
        format: "LL",
        type: "date",
        isShow: true,
        title: "Invoice Date",
      },
      {
        label: "Terms",
        value: "terms",
        type: "dynamic",
        isShow: true,
        title: "Terms",
      },
      {
        label: "Total Amount",
        value: "totalAmount",
        type: "dynamic",
        isShow: true,
        title: "Total Amount",
      },
    ],
    isColor: true,
    isShow: true,
  },
  section2: {
    fields: [
      {
        isColor: true,
        label: "REMIT TO",
        value: "remiteAddress",
        type: "text",
        isShow: true,
        title: "REMIT TO",
      },
      {
        isColor: true,
        label: "BILL TO",
        value: "BILL TO",
        type: "text",
        isShow: true,
        title: "BILL TO",
      },
    ],
    isShow: true,
  },
  summary: {
    fields: [
      {
        isColor: true,
        label: "DELIVERY DATE",
        value: "approvedDate",
        type: "text",
        isShow: true,
        title: "Delivery Date",
      },
      {
        isColor: true,
        label: "COMPLETED DATE",
        value: "completedDate",
        type: "text",
        isShow: true,
        title: "Completed Date",
      },
      {
        isColor: true,
        label: "BILLING DATE",
        value: "billingDate",
        type: "text",
        isShow: true,
        title: "Billing Date",
      },
      {
        isColor: true,
        label: "LOAD #",
        value: "reference_number",
        type: "text",
        isShow: true,
        title: "Load #",
      },
      {
        isColor: true,
        label: "SUB REFERENCE #",
        value: "sub_reference_number",
        type: "text",
        isShow: true,
        title: "Sub reference #",
      },
      {
        isColor: true,
        label: "REFERENCE #",
        value: "secondaryReferenceNo",
        type: "text",
        isShow: true,
        title: "Reference #",
      },
      {
        isColor: true,
        label: "CONTAINER #",
        value: "containerNo",
        type: "text",
        isShow: true,
        title: "Container #",
      },
      {
        isColor: true,
        label: "BOL/BKG",
        value: "bok",
        type: "text",
        isShow: true,
        title: "BOL/BKG",
      },
      {
        isColor: true,
        label: "PORT",
        value: "port",
        type: "text",
        isShow: true,
        title: "Port",
      },
      {
        isColor: true,
        label: "CONSIGNEE",
        value: "consignee",
        type: "text",
        isShow: true,
        title: "Consignee",
      },
      {
        isColor: true,
        label: "BASE CHARGE",
        value: "Base Charge",
        type: "text",
        isShow: true,
        title: "Base Charge",
      },
      {
        isColor: true,
        label: "OTHER CHARGES",
        value: "Other Charges",
        type: "text",
        isShow: true,
        title: "Other Charges",
      },
      {
        isColor: true,
        label: "TOTAL CHARGES",
        value: "Total Charges",
        type: "text",
        isShow: true,
        title: "Total Charges",
      },
    ],
    isShow: true,
  },
  watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}
};

export const creditMemoJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
    header: {
        isShow: true,
        fields: [{
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title: 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            }, {
                title: 'Phone',
                isShow: true,
                type: "text",
                value: "mobile",
                label: "Phone",
                isColor : true
            },
            {
                title: 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Credit Memo",
            isShow: true,
            type: "dynamic",
            value: "credit_memo_number",
            label: "Credit Memo #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "Credit Memo Number",
            isShow: true,
            type: "dynamic",
            value: "credit_memo_number",
            label: "Credit Memo Number",
        }, {
            title: "Date",
            isShow: true,
            type: "date",
            format: "LL",
            value: "date",
            label: "Date",
        },{
            title: "Terms",
            isShow: true,
            type: "dynamic",
            value: "terms",
            label: "Terms",
        },{
            title: "DueDate",
            isShow: true,
            type: "date",
            format: "LL",
            value: "dueDate",
            label: "Due Date",
        },{
            title: "Total Credit",
            isShow: true,
            type: "dynamic",
            value: "total",
            label: "Total Credit",
        }
    ],
    },
    section2: {
        isShow : true,
        fields: [{
            title: "Bill To",
            isShow: true,
            type: "text",
            value: "BILL TO",
            label: "BILL TO",
            isColor : true
        },
        {
            title: "PORT/SHIPPER",
            isShow: true,
            type: "text",
            value: "PORT/SHIPPER",
            label: "PORT/SHIPPER",
            isColor : true
        }, {
            title: "Consignee",
            isShow: true,
            type: "text",
            value: "CONSIGNEE",
            label: "CONSIGNEE",
            isColor : true
        }, {
            title: "RETURN",
            isShow: true,
            type: "text",
            value: "RETURN",
            label: "RETURN",
            isColor : true
        }
    ],

    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Reference #",
                isShow: true,
                type: "dynamic",
                value: "secondaryReferenceNo",
                label: "Reference #"
            },
            {
                title: "Booking/BL",
                isShow: true,
                type: "dynamic",
                value: "bookingNo",
                label: "Booking/BL",

            },
            {
                title: "MBOL #",
                isShow: true,
                type: "dynamic",
                value: "callerbillLandingNo",
                label: "MBOL #",
            },
            {
                title: "HBOL #",
                isShow: true,
                type: "dynamic",
                value: "doNo",
                label: "HBOL #"
            },
            {
                title: "Pickup Appointment",
                isShow: false,
                type: "date",
                value: "pickupFromTime",
                label: "Pickup Appointment",
                format: "LL"
            },
            {
                title: "Delivery Appointment",
                isShow: false,
                type: "date",
                value: "deliveryFromTime",
                label: "Delivery Appointment",
                format: "LL"
            },
            {
                title: "Last Free day",
                isShow: false,
                type: "date",
                format: "LL",
                value: "lastFreeDay",
                label: "Last Free Day",
            },
            {
                title: "Last Free Date Return Day",
                isShow: false,
                type: "date",
                value: "freeReturnDate",
                label: "Last Free Date Return Day",
                format: "LL"
            }, {
                title: "Pull Container Date",
                isShow: false,
                type: "date",
                value: "pullContainerDate",
                label: "Pull Container Date",
                format: "LL"
            }, {
                title: "Deliver Load Date",
                isShow: false,
                type: "date",
                value: "deliverLoadDate",
                label: "Deliver Load Date",
                format: "LL"
            }, {
                title: "Return Container Date",
                isShow: false,
                type: "date",
                value: "returnContainerDate",
                label: "Return Container Date",
                format: "LL"
            }, 
            {
                title: "Container #",
                isShow: true,
                type: "dynamic",
                value: "containerNo",
                label: "Container #",

            },
            {
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size",

            }, {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",

            },
             {
                title: "Purchase Order #",
                isShow: true,
                type: "dynamic",
                value: "purchaseOrderNo",
                label: "Purchase Order #",

            }, {
                title: "Temperature",
                isShow: false,
                type: "dynamic",
                value: "temperature",
                label: "Temperature",

            }, {
                title: "Hazmat",
                isShow: false,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat",

            }, {
                title: "Shipment #",
                isShow: false,
                type: "dynamic",
                value: "shipmentNo",
                label: "Shipment #",

            }, {
                title: "Pick Up #",
                isShow: false,
                type: "dynamic",
                value: "callerPONo",
                label: "Pick Up #",

            }, {
                title: "Chassis #",
                isShow: false,
                type: "dynamic",
                value: "chassisIdName",
                label: "Chassis #",

            }, {
                title: "Chassis Size",
                isShow: false,
                type: "dynamic",
                value: "chassisSize",
                label: "Chassis Size",

            }, {
                title: "Chassis Type",
                isShow: false,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",

            }, {
                title: "Chassis Owner",
                isShow: false,
                type: "dynamic",
                value: "chassisOwnerName",
                label: "Chassis Owner",

            }, {
                title: "Steamship Line",
                isShow: true,
                type: "dynamic",
                value: "containerOwner",
                label: "Steamship Line",

            }, {
                title: "Seal #",
                isShow: true,
                type: "dynamic",
                value: "sealNo",
                label: "Seal #",

            }, {
                title: "Vessel Name",
                isShow: false,
                type: "dynamic",
                value: "deliveryOrderNo",
                label: "Vessel Name",

            }, {
                title: "Voyage Name",
                isShow: false,
                type: "dynamic",
                value: "releaseNo",
                label: "Voyage Name",
            }, {
                title: "Genset #",
                isShow: false,
                type: "dynamic",
                value: "genset",
                label: "Genset #",

            }, {
                title: "Appointment #",
                isShow: false,
                type: "dynamic",
                value: "appointmentNo",
                label: "Appointment #",

            }, {
                title: "Return #",
                isShow: false,
                type: "dynamic",
                value: "returnNo",
                label: "Return #",

            }, {
                title: "Reservation #",
                isShow: false,
                type: "dynamic",
                value: "reservationNo",
                label: "Reservation #",

            },
           {
              title : "Hook Chassis",
              isShow : false,
              type : "dynamic",
              value : "chassisPickup",
              label : "Hook Chassis"   
           },
           {
            title : "Terminate Chassis",
            isShow : false,
            type : "dynamic",
            value : "chassisTermination",
            label : "Terminate Chassis"   
         },
         {
            title : "Total Miles",
            isShow : false,
            type : "dynamic",
            value : "totalMiles",
            label : "Total Miles",
            unit : "mi"   
         },
         {
            title : "Gray Pool Container #",
            isShow : false,
            type : "dynamic",
            value : "grayContainerNo",
            label : "Gray Pool Container #"   
         },
         {
            title : "Gray Pool Chassis #",
            isShow : false,
            type : "dynamic",
            value : "grayChassisNo",
            label : "Gray Pool Chassis #"   
         },
         {
             title : "Discharged Date", 
             isShow : false,
             type : 'date',
             value : "dischargedDate",
             label : 'Discharged Date',
             format : "LL",
         },
         {
            title : "Ingate Date", 
            isShow : false,
            type : 'date',
            value : "ingateDate",
            label : 'Ingate Date',
            format : "LL",
         },
         {
            title : "Outgate Date", 
            isShow : false,
            type : 'date',
            value : "outgateDate",
            label : 'Outgate Date',
            format : "LL",
         }
        ],
    },

    pricing: {
        isShow: true,
        fields: [{
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "Charge Name",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Reference",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "Reference",
                isColor : true
            },
            {
                title: "Credit Amount",
                isShow: true,
                type: "dynamic",
                value: "amount",
                label: "Credit Amount",
                isColor : true
            },
        ],
    },
    appliedCredit: {
        isShow: false,
        label: "Applied Credits",
        title: "Applied Credits",
        fields: [
            {
                title: "Reference",
                isShow: true,
                type: "dynamic",
                value: "reference_number",
                label: "Reference",
                isColor : true
            },
            {
                title: "Credited Amount",
                isShow: true,
                type: "dynamic",
                value: "amount",
                label: "Credited Amount",
                isColor : true
            },
        ],
    },
    totalAmount : {
        isShow  :true,
        fields : [
            {
                title: "Net Credit Amount",
                isShow: true,
                type: "dynamic",
                value: "netCredit",
                label: "Net Credit Amount",
                isColor : true
            },
            {
                title: "Credited Tax Amount",
                isShow: true,
                type: "dynamic",
                value: "taxAmount",
                label: "Credited Tax Amount",
                isColor : true
            },
            {
                title: "Total Credit Amount",
                isShow: true,
                type: "dynamic",
                value: "total",
                label: "Total Credit Amount",
                isColor : true
            },

        ]
    },
    notes : {
        isShow : false,
        fields : [
            {
                title : "Driver Notes",
                isShow : false,
                type : "dynamic",
                value : "driverNote",
                label: "Driver Notes"
            },
            {
                title : "Billing Notes",
                isShow : false,
                type :"dynamic",
                value :"billingNote",
                label : "Billing Notes"
            },
            {
                title : "Yard Notes",
                isShow : false,
                type :"dynamic",
                value :"yardNote",
                label : "Yard Notes"
            },
            {
                title : "Load Notes",
                isShow : false,
                type : "dynamic",
                value : "officeNote",
                label: "Load Notes"
            },
            {
                title : "Customer Notes",
                isShow : false,
                type : "dynamic",
                value : "customerNotes",
                label: "Customer Notes"
            }
        ]
    },
    disclaimer :{
        isShow : false,
        fields: [
        {
            title: 'Disclaimer',
            isShow: false,
            type: "text",
            value: "Disclaimer",
            label: "Disclaimer",
        }
    ]},
    watermark : {
        isShow : true,
        fields: [
        {
            title: `Watermark Text`,
            isShow: true,
            type: "text",
            value: "DRAFT",
            label: "DRAFT",
        }
      ]}
};

export const settlementJSON = {
  accentColor: "#367BF6",
  textColor: "#FFF",
  header: {
    isShow: true,
    fields: [
      {
        title: "Logo",
        isShow: true,
        type: "image",
        value: "",
        label: "Logo",
      },
      {
        title: "Company Name",
        isShow: true,
        type: "text",
        value: "company_name",
        label: "Company Name",
      },
      {
        title: "Address",
        isShow: true,
        type: "text",
        value: "company_address",
        label: "Address",
      },
      {
        title: "Phone",
        isShow: true,
        type: "text",
        value: "mobile",
        label: "Phone",
        isColor: true,
      },
      {
        title: "Website",
        isShow: false,
        type: "text",
        value: "www.example.com",
        label: "Website",
        isColor: true,
      },
    ],
  },
  section1: {
    isShow: true,
    isColor: true,
    fields: [
      {
        title: "Driver Settlement",
        isShow: true,
        type: "dynamic",
        value: "settlementNo",
        label: "Driver Settlement",
      },
    ],
  },
  subSection: {
    isShow: true,
    fields: [
      {
        title: "Driver",
        isShow: true,
        type: "dynamic",
        value: "driverName",
        label: "Driver",
      },
      {
        title: "Settlement",
        isShow: true,
        type: "dynamic",
        value: "settlementNo",
        label: "Settlement",
      },
      {
        title: "Settlement Period",
        isShow: true,
        type: "date",
        format: "LL",
        value: "settlementPeriod",
        label: "Settlement Period",
      },
      {
        title: 'Vendor [Tax-Terminology] No',
        isShow: false,
        type: "dynamic",
        value: "registrationNumber",
        label: "Vendor [Tax-Terminology] No"
      }
    ],
  },
  selfBillingDetails: {
    isShow: true,
    fields: [
      {
        title: "Truck Owner",
        isShow: true,
        type: "text",
        value: "company_name",
        label: "Truck Owner",
        isColor: true,
      },
      {
        title: "Truck Owner Address",
        isShow: true,
        type: "text",
        value: "address",
        label: "Truck Owner Address",
        isColor: true,
      },
      {
        title: "Truck Owner [Tax-Terminology]",
        isShow: false,
        type: "text",
        value: "taxId",
        label: "Truck Owner [Tax-Terminology]",
        isColor: true,
      },
      {
        title: "Truck #",
        isShow: true,
        type: "text",
        value: "truck",
        label: "Truck #",
        isColor: true,
      },
      {
        title: "Doc Date",
        isShow: true,
        type: "date",
        format: "LL",
        value: "finalizedDate",
        label: "Doc Date",
      },
      {
        title: "Due Date",
        isShow: true,
        type: "date",
        format: "LL",
        value: "dueDate",
        label: "Due Date",
      },
      {
        title : "Registered Business Name" ,
        isShow : false,
        type : "dynamic",
        value : "registerBusinessName",
        label : "Registered Business Name"
      },
      {
        title : "HST #" ,
        isShow : false,
        type : "dynamic",
        value : "hst",
        label : "HST #"
      },
      {
        title : "HST %" ,
        isShow : false,
        type : "dynamic",
        value : "hstPercentage",
        label : "HST %"
      }
    ],
  },
  payDetails: {
    isShow: true,
    fields: [
      {
        title: "Total Trip Pay",
        isShow: true,
        type: "dynamic",
        value: "totalAmount",
        label: "Total Trip Pay",
      },
      {
        title: "HST @ [HST %]",
        isShow: false,
        type: "dynamic",
        value: "hstAmount",
        label: "HST @ [HST %]",
      },
      {
        title: "Total Deduction",
        isShow: true,
        type: "dynamic",
        value: "deductionAmount",
        label: "Total Deduction",
      },
      {
        title: "[Tax-Terminology] @ [Tax %]",
        isShow: false,
        type: "dynamic",
        value: "totalTax",
        label: "[Tax-Terminology] @ [Tax %]",
      },
      {
        title: "Total Payment",
        isShow: true,
        type: "dynamic",
        value: "netAmount",
        label: "Total Payment",
      },
    ],
  },
  notes: {
    isShow: true,
    fields: [
      {
        title: "Notes",
        isShow: true,
        type: "dynamic",
        value: "public_notes",
        label: "Notes",
      },
    ],
  },
  deductions: {
    isShow: true,
    fields: [
      {
        title: "Deduction Name",
        isShow: true,
        type: "dynamic",
        value: "name",
        label: "Deduction Name",
      },
      {
        title: "Description",
        isShow: true,
        type: "dynamic",
        value: "description",
        label: "Description",
      },
      {
        title: "Amount",
        isShow: true,
        type: "dynamic",
        value: "amount",
        label: "Amount",
      },
    ],
  },
  settlementDetails: {
    isShow: true,
    fields: [
      {
        title: "Load #",
        isShow: true,
        type: "dyanamic",
        value: "reference_number",
        label: "Load #",
      },
      {
        title: "Container #",
        isShow: true,
        type: "dyanamic",
        value: "containerNo",
        label: "Container #",
      },
      {
        title: "Date",
        isShow: true,
        type: "date",
        format: "LLL",
        value: "date",
        label: "Date",
      },
      {
        title: "From",
        isShow: true,
        type: "dyanamic",
        value: "from",
        label: "From",
        format : "company, city, state"
      },
      {
        title: "Duration",
        isShow: false,
        type: "dyanamic",
        value: "duration",
        label: "Duration",
      },
      {
        title: "To",
        isShow: true,
        type: "dyanamic",
        value: "to",
        label: "To",
        format : "company, city, state"
      },
      {
        title: "Status",
        isShow: true,
        type: "dyanamic",
        value: "status",
        label: "Status",
      },
      {
        title: "[Tax-Terminology] Exempt",
        isShow: false,
        type: "dyanamic",
        value: "taxExempt",
        label: "[Tax-Terminology] Exempt",
      },
      {
        title: "[Tax-Terminology] Applicable",
        isShow: false,
        type: "dyanamic",
        value: "taxApplicable",
        label: "[Tax-Terminology] Applicable",
      },
      {
        title: "Payment",
        isShow: true,
        type: "dyanamic",
        value: "totalAmount",
        label: "Payment",
        },
        
        ...(
            isCombinedTrip ?
                [
                    {
                        title: "Combination Trip",
                        isShow: false,
                        type: "dynamic",
                        value: "combinationTrip",
                        label: "Combination Trip #",
                    },
                ] : []
        ), 
        
    ],
  },
  watermark : {
    isShow : true,
    fields: [
    {
        title: `Watermark Text`,
        isShow: true,
        type: "text",
        value: "DRAFT",
        label: "DRAFT",
    }
  ]}
};

export const agingReportJSON = {
    accentColor: "#367BF6",
    textColor: "#FFF",
    header: {
      isShow: true,
      fields: [
        {
          title: "Logo",
          isShow: true,
          type: "image",
          value: "",
          label: "Logo",
        },
        {
          title: "Company Name",
          isShow: true,
          type: "text",
          value: "company_name",
          label: "Company Name",
        },
        {
          title: "Address",
          isShow: true,
          type: "text",
          value: "company_address",
          label: "Address",
        },
        {
          title: "Phone",
          isShow: true,
          type: "text",
          value: "mobile",
          label: "Phone",
          isColor: true,
        },
        {
          title: "Website",
          isShow: false,
          type: "text",
          value: "www.example.com",
          label: "Website",
          isColor: true,
        },
      ],
    },
    section1: {
      isShow: true,
      isColor: true,
      fields: [
        {
          title: "Aging Report",
          isShow: true,
          type: "dynamic",
          value: "agingReport",
          label: "Aging Report",
        },
      ],
    },
    customer: {
      isShow: true,
      fields: [
        {
          title: "Customer Name",
          isShow: true,
          type: "dynamic",
          value: "company_name",
          label: "Customer",
        },
        {
          title: "Address",
          isShow: true,
          type: "dynamic",
          value: "address",
          label: "Address",
        },
        {
          title: "Email",
          isShow: true,
          type: "dynamic",
          value: "email",
          label: "Email",
        },
        {
          title: 'Phone',
          isShow: true,
          type: "dynamic",
          value: "phone",
          label: "Phone"
        }
      ],
    },
    reportDetails : {
        isShow : true,
        fields : [
            {
                title: "Report Generated On",
                isShow: true,
                type: "date",
                format: "LLL",
                value: "reportGeneratedDate",
                label: "Report Generated On",
            }
        ]
    },
    table : {
        isShow : true,
        fields : [
            {
                title : "Customer",
                isShow : 'true',
                type : 'dynamic',
                value : 'company_name',
                label : 'Customer'
            },
            {
            
                title: "Load #",
                isShow: true,
                type: "dyanamic",
                value: "reference_number",
                label: "Document #",
            },
            {
            
                title: "Charge Set #",
                isShow: true,
                type: "dyanamic",
                value: "charge_reference_number",
                label: "Charge Set #",
            },
            {
                title: "Container #",
                isShow: true,
                type: "dyanamic",
                value: "container",
                label: "Container #",
            },
            {
                title: "Secondary Reference #",
                isShow: true,
                type: "dynamic",
                value: "sec_refNo",
                label: "Secondary Reference #"
            },
            {
                title: "Billing Date",
                isShow: true,
                type: "date",
                format: "LLL",
                value: "billingDate",
                label: "Billing Date",
              },
              {
                title: "Due Date",
                isShow: true,
                type: "date",
                format: "LLL",
                value: "dueDate",
                label: "Due Date",
              },
              {
                title: "0-30 Days",
                isShow: true,
                type: "dynamic",
                value: "first",
                label: "0-30 Days",
              },
              {
                title: "30-60 Days",
                isShow: true,
                type: "dynamic",
                value: "second",
                label: "30-60 Days",
              },

              {
                title: "60-90 Days",
                isShow: true,
                type: "dynamic",
                value: "third",
                label: "60-90 Days",
              },
              {
                title: "90-120 Days",
                isShow: true,
                type: "dynamic",
                value: "fourth",
                label: "90-120 Days",
              },
              {
                title: "120+ Days",
                isShow: true,
                type: "dynamic",
                value: "fifth",
                label: "120+ Days",
              },
              {
                title: "Total Charges",
                isShow: true,
                type: "dynamic",
                value: "eachTotal",
                label: "Total Charges",
              },


        ]
    }
  };

  export const quotePricingJSON = {
    accentColor : "#367BF6",
    textColor : '#FFF',
    header: {
        isShow: true,
        fields: [{
                title: 'Logo',
                isShow: true,
                type: "image",
                value: "",
                label: "Logo"
            },
            {
                title: 'Company Name',
                isShow: true,
                type: "text",
                value: "company_name",
                label: "Company Name"
            },
            {
                title: 'Address',
                isShow: true,
                type: "text",
                value: "company_address",
                label: "Address",
            }, 
            {
                title: 'Phone',
                isShow: true,
                type: "text",
                value: "mobile",
                label: "Phone",
                isColor : true
            },
            {
                title: 'Website',
                isShow: false,
                type: "text",
                value: "www.example.com",
                label: "Website",
                isColor : true
            }
        ]
    },
    section1: {
        isShow: true,
        isColor :true,
        fields: [{
            title: "Quote #",
            isShow: true,
            type: "dynamic",
            value: "quote",
            label: "Quote #",
        }]
    },
    subSection: {
        isShow: true,
        fields: [{
            title: "# of Loads",
            isShow: true,
            type: "dynamic",
            value: "numberofLoad",
            label: "# of Loads",
        }, {
            title: "Load Type",
            isShow: true,
            type: "text",
            value: "type_of_load",
            label: "Load Type",
        }, {
            title: "Valid Upto",
            isShow: true,
            type: "date",
            format: "LL",
            value: "validUpTo",
            label: "Valid Upto",
        }],
    },
    section2: {
        isShow: true,
        fields: [{
                title: "Bill To",
                isShow: true,
                type: "text",
                value: "BILL TO",
                label: "BILL TO",
                isColor : true
            },
            {
                title: "Port/Shipper",
                isShow: true,
                type: "text",
                value: "PORT/SHIPPER",
                label: "SHIPPER",
                isColor : true
            }, {
                title: "Delivery Location",
                isShow: true,
                type: "text",
                value: "CONSIGNEE",
                label: "DELIVERY LOCATION",
                isColor : true
            },
            {
                title: "Return Location",
                isShow: true,
                type: "text",
                value: "RETURN",
                label: "RETURN LOCATION",
                isColor : true
            }
        ],

    },
    orderDetail: {
        isShow: true,
        type: "text",
        value: "Order Details",
        label: "Order Details",
        title: "Order Details",
        fields: [{
                title: "Container Size",
                isShow: true,
                type: "dynamic",
                value: "containerSize",
                label: "Container Size"
            },
            {
                title: "Container Type",
                isShow: true,
                type: "dynamic",
                value: "containerType",
                label: "Container Type",
            },
            {
                title: "Chassis Type",
                isShow: true,
                type: "dynamic",
                value: "chassisType",
                label: "Chassis Type",
            },
            {
                title: "Genset",
                isShow: true,
                type: "dynamic",
                value: "genset",
                label: "Genset"
            },
            {
                title: "Hazmat",
                isShow: true,
                type: "dynamic",
                value: "hazmat",
                label: "Hazmat"
            },
            {
                title: "Hot",
                isShow: true,
                type: "dynamic",
                value: "hot",
                label: "Hot"
            },
            {
                title: "Liquor",
                isShow: true,
                type: "dynamic",
                value: "liquor",
                label: "Liquor"
            },
            {
                title: "Over Weight",
                isShow: true,
                type: "dynamic",
                value: "overweight",
                label: "Over Weight"
            },
            {
                title: "Terminal",
                isShow: true,
                type: "dynamic",
                value: "terminal",
                label: "Terminal"
            }
        ],
    },
    notes : {
        isShow : true,
        fields : [
            {
                title : "Notes",
                isShow : true,
                type : "dynamic",
                value : "notes",
                label: "Notes"
            }
        ]
    },
    commodity: {
        isShow: true,
        fields: [{
                title: "Commodity",
                isShow: true,
                type: "dynamic",
                value: "profile",
                label: "Commodity Profile",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Weight",
                isShow: true,
                type: "dynamic",
                value: "weight",
                label: "Weight",
                isColor : true,
                unit : "LBS"
            },
        ],
    },
    pricing: {
        isShow: true,
        fields: [{
                title: "Charge Name",
                isShow: true,
                type: "dynamic",
                value: "name",
                label: "Name",
                isColor : true
            },
            {
                title: "Description",
                isShow: true,
                type: "dynamic",
                value: "description",
                label: "Description",
                isColor : true
            },
            {
                title: "Units",
                isShow: true,
                type: "dynamic",
                value: "unit",
                label: "Units Count",
                isColor : true
            },
            {
                title: "Unit of Measure",
                isShow: true,
                type: "dynamic",
                value: "perType",
                label: "Unit of Measure",
                isColor : true
            },
            {
                title : "Free Units",
                isShow: true,
                type: "dynamic",
                value: "freeDays",
                label: "Free Units",
                isColor : true
                },
            {
                title: "[currency] Per Unit",
                isShow: true,
                type: "dynamic",
                value: "chargePerDay",
                label: "[currency] Per Unit",
                isColor : true
            },
            {
                title: "Price",
                isShow: true,
                type: "dynamic",
                value: "finalAmount",
                label: "Price",
                isColor : true
            },
            
          
        ],
    },
    totalAmount : {
        isShow : true,
        fields : [{
            title: "Total Amount",
            isShow: true,
            type: "dynamic",
            value: "totalAmount",
            label: "Total Amount",
        }]
    },
    disclaimer :{
        isShow : true,
        fields: [
        {
            title: 'Disclaimer',
            isShow: true,
            type: "text",
            value: "",
            label: "Disclaimer",
        }
    ]}
};
