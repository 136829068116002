import jsonToQueryParams from "../../../Components/Common/jsonToQueryParams";
import { getStorage, HTTP } from "../../../services";
import { getTMSChassis } from "../AddChassis/actionCreators";
import { euAddressFormate } from "../Dispatcher/actionCreators";



export function createTender(params) {
    let url = 'send-tender';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function getDrayosCarriers(data) {
    let url = 'getDrayosCarriers';
    url = data ? url + "?" + jsonToQueryParams(data) : url;
    return new Promise((resolve, reject) => {
      HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }


// New ROUTING SERVICES APIs
export const updateLoadAssignDate = (payload) => {
  let url = `routing/update-load-assign-date`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export const updateLoadBobtail = (payload) => {
  let url = `routing/update-load-bobtail`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export const updateLoadOptions = (payload) => {
  let url = `routing/update-load-options`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export const addEvent = (payload) => {
  let url = `routing/add-event`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const addManualSupportMoveViaAutomation = (payload) => {
  let url = `load/addManualSupportMoveViaAutomation`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const removeEvent = (payload) => {
  let url = 'routing/remove-event'
  url = payload ? url + "?" + jsonToQueryParams(payload) : url;
  return new Promise((resolve, reject) => {
    HTTP('delete', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const assignDriverNewRouting = (params) => {
  let url = 'routing/assign-driver';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const assignVendorNewRouting = (params) => {
  let url = 'routing/assign-vendor';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const removeVendorNewRouting = (params) => {
  let url = 'routing/remove-vendor';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const removeDriverNewRouting = (params) => {
  let url = 'routing/remove-driver';
  return new Promise((resolve, reject) => {
    HTTP('PATCH', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const updateGrayPool = (payload) => {
  let url = `routing/gray-pool`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateEventAddress = (payload) => {
  let url = `routing/update-event-address`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateRoutingTemplate = (payload) => {
  let url = `routing/update-routing-template`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function removeEventAddress(params){
  const url = 'routing/remove-event-address';
  return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
        });
      });
    }
export const updateArrivedDeparted = (payload) => {
  let url = `routing/update-event-status`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const removeEventStatus = (payload) => {
  let url = `routing/remove-event-status`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCompleteLoadDate(params){
  const url = 'routing/update-complete-load-date';
  return new Promise((resolve, reject) => {
      HTTP('put', url, params, {
          'authorization': getStorage('token')
      })
      .then((result) => {
          if (result)
            resolve(result.data.data)
      })
      .catch((error) => {
          reject(error);
      });
  });
}
export const completeLoad = (payload) => {
  let url = `routing/complete-load`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const updateVoidOut = (payload) => {
  let url = `routing/void-out`;
  return new Promise((resolve, reject) => {
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const uncompleteLoad = (payload) => {
  let url = `routing/uncomplete-load`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function removeStreetTurn(params) {
  let url = 'tms/removeStreetTurn';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function removeDomesticMove(params) {
  let url = 'routing/remove-domestic-move';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export const getChassis = (searchTerm = "") => {
  return new Promise((resolve, reject) => {
    getTMSChassis({ limit: 10, searchTerm })
      .then((result) => {
        let data =
          result?.data?.data?.map((d) => ({
            value: d._id,
            label: d.chassisNo,
            allInfos: d,
          }))
        resolve(data)
      })
      .catch((err) => {
        console.log("-*-* GET Chassis error -*-*", err)
        reject(err)
      })
  })
}

export const manageEventOrder = (payload) => {
  let url = `routing/manage-event-order`;
  return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addManualSupportMove(params) {
  let url = 'load/addManualSupportMove';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function updateNewSupportMove(params) {
  let url = 'load/updateNewSupportMove';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function deleteSupportMove(params) {
  let url = 'load/deleteSupportMove';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
// load/update-support-move-status

export function updateSupportMoveStatus(params) {
  let url = 'load/update-support-move-status';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
// Update support move details
export function updateSupportMove(params) {
  let url = 'load/update-support-move';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// get Driver last location
export function getDriverLastLocation(params) {
  let url = 'driverPlanner/driverLastLocation';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let customerDetail = {};
        let labelData = [];
        const obj = result?.data?.data || {};
        if (obj?._id) {
          const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? "us";
          if (defaultAddressFormat !== "us") {
            euAddressFormate(obj, labelData)
            customerDetail = { value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj }
            
          } else {
            customerDetail = { value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj }
          }
        } else if(obj?.lastEvent){
          customerDetail = { lastEvent: obj.lastEvent }
        }
        resolve(customerDetail)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const cabotagePointCalculate = (params) => {
  let url = 'cabotage-points/pre-calculate';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export const updateAppointmentDate = (payload) => {
  let url = 'routing/appointment-dates';
    return new Promise((resolve, reject) => {
      HTTP('post', url, payload, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export const removeAppointmentDate = (payload) => {
  let url = 'routing/appointment-dates';
  return new Promise((resolve, reject) => {
    HTTP('delete', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result?.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const addEventInCombineTrip = (params) => {
  let url = 'combine-trip/add-trip-event';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export const removeLegForCombinedTrip = (params) => {
  let url = 'combine-trip/remove-trip-event';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getBulkHosAvailability = (params) => {
  let url = 'bulk/hos-availability';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}