import { getOrderNo } from "pages/tms/AppointmentModal/AppointmentSlotsPicker/helpers/appointmentHelper";
import { APPOINTMENT_SOURCES, MOVE_TYPES } from "constants/appointmentSystem.constant";

export const useAutoAppointmentSystem = () => {

    const getPayload = (load, moveType, appointmentId = null) => {
        const orderNo = getOrderNo(load?.type_of_load, moveType);
        const portCode = moveType === MOVE_TYPES.PICKUP
            ? (load?.shipper?.[0]?.company_name ?? load?.shipperName)
            : (load?.emptyOrigin?.company_name ?? load?.emptyOriginName);

        const loadPayload = {
            loadId: load?._id,
            referenceNumber: load?.reference_number,
            containerNo: load?.containerNo,
            loadType:load?.type_of_load,
            moveType,
            portCode,
            lineScac: load?.containerOwnerName ?? load?.containerOwner?.company_name,
            sealNo: load?.sealNo,
            source: APPOINTMENT_SOURCES.GENERAL_APPOINTMENT,
            ...(load[orderNo] && { orderNo: load[orderNo] }),
        }

        if (appointmentId) {
            return { ...loadPayload, appointmentId: appointmentId, };
        }

        if (moveType === MOVE_TYPES.PICKUP) {
            return { ...loadPayload, appointmentId: load.pickupAppointmentSystem };
        } else if (moveType === MOVE_TYPES.EMPTY) {
            return { ...loadPayload, appointmentId: load.emptyAppointmentSystem };
        }

        return loadPayload;
    };

    const buildPayload = (load, moveType, appointmentId = null) => {
        return Array.isArray(load)
            ? load.map(l => getPayload(l, moveType))
            : getPayload(load, moveType, appointmentId);
    };

    return {
        buildPayload,
    };
};
