import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import useBoxPosition from '../../../../../../Components/CustomHooks/useBoxPosition';
import { getStorage } from 'services';
import { getTMSChassis3 } from 'pages/tms/AddChassis/actionCreators';

const fetchChassisUser = (chassis, searchChassis) => {
    let allChassisList = chassis
    if (allChassisList.length > 0) {
        if (searchChassis !== null) {
            allChassisList = allChassisList.filter((chassis) => {
                return (
                    chassis.chassisNo
                        .toLowerCase()
                        .indexOf(
                            searchChassis.toLowerCase().trim()
                        ) != -1
                );
            });
        }
        return allChassisList
    }
}

const GrayChassisList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress }) => {
    const { chassis } = useSelector((state) => state.chassisReducer, shallowEqual);
    const [cursor,setCursor] = useState(null)
    const [searchChassis,setSearchChassis] = useState(null);
    const [chassisOptions, setChassisOptions] = useState(chassis);
    const [cList, setdList] = useState(fetchChassisUser(chassis, searchChassis))
    const searchInputRef = React.useRef(null);
    const [isLoding, setIsLoding] = useState(false);
    const isChassisV3 = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isChassisV3;

    const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)
    useEffect(() => {
        if (isChassisV3) {
            setIsLoding(true)
            getTMSChassis3({isIncludeDeleted: true}).then((res) => {
                let chassisV3 = res?.data?.data;
                setIsLoding(false)
		        setdList(fetchChassisUser(chassisV3, searchChassis));
                setChassisOptions(chassisV3)
              }).catch((err) => {
                setIsLoding(false)
                console.log("err", err);
              })
        } else {
            setChassisOptions(chassis)
        }
        searchInputRef.current.focus();
        window.document.getElementById('root').style.pointerEvents="none"
        return ()=>{
            window.document.getElementById('root').style.pointerEvents="auto"
        }
    }, [])

    useOnClickOutside(boxRef, () => onClose(false))

    useEffect(() => {
		const _chassis = fetchChassisUser(chassisOptions, searchChassis);
		setdList(_chassis);
	}, [searchChassis]);

    const get = (data) => {
        if(Row){
            const payload = {
                grayChassisNo : data.chassisNo,
                reference_number : Row.reference_number,
                chassisId : data._id,
            }
            if (data.chassisType) payload.grayChassisType = data.chassisType._id;
            if (data.chassisSize) payload.grayChassisSize = data.chassisSize._id;
            if (data.chassisOwner) payload.grayChassisOwner = data.chassisOwner._id;

            setIsAssignInProgress(true);
            editLoad(payload).then(el => {
                onRowChange({ ...Row, grayChassisNo: data.chassisNo });
                setIsAssignInProgress(false);
                onClose(false)
            }).catch((err) => {
				setIsAssignInProgress(false);
			})
        }
    }

    const addChassis = () => {
        if(Row){
            const payload = {
                grayChassisNo : searchChassis,
                reference_number : Row.reference_number,
            }
            setIsAssignInProgress(true);
            editLoad(payload).then(el => {
                onRowChange({ ...Row, grayChassisNo: searchChassis });
                setIsAssignInProgress(false);
                onClose(false)
            }).catch((err) => {
				setIsAssignInProgress(false);
			})
        }
    }


    const handleKeyDown=(event)=> {
        if (event.keyCode === 38) {
          if (cursor >= 0) {
            setCursor(cursor - 1)
            document.getElementById(`chassis-${cursor}`).focus();
          }
          event.preventDefault();
        } else if (event.keyCode === 40) {
            if (cursor <= cList.length - 1) {
                if(cursor===null){
                    setCursor(0)
                }else{
                    setCursor((prev)=>prev + 1 )
                    document.getElementById(`chassis-${cursor}`).focus();
                    
                }
            } 
            event.preventDefault();
        } else if (event.keyCode === 13) {
            cList.forEach((val, index) => {
                if (index === cursor) get(val);
            });
            event.preventDefault();
        }   else if (event.keyCode === 9 || event.keyCode === 27) {
            onClose(false)
            event.preventDefault();
            event.stopPropagation();
        }
    }

    return createPortal(
        <div ref={boxRef} className="card card--shadow-5 mb-0 p-10 text-left z-1 w-200" data-testid="gray-chassis-list" style={Style}>
            <div className="app-search bg-gray-50 rounded-lg mb-10">
                <input
                    className="input-search-left form-control py-2"
                    placeholder="Search Graypool Chassis.."
                    id="domTextElement"
                    onChange={(e)=>setSearchChassis(e.target.value)}
                    onKeyDown={(event) =>{ handleKeyDown(event)} }
                    ref={searchInputRef}
                />
                <span className="search-icon ml-1"></span>
            </div>

            <ul className="driverList m-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                {isLoding && (
                    <li className='popdrop__dropdown-item text-center'>
                        <span
                            className="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </li>
                )}
                {cList && cList.length === 0 ? (
                    <p 
                        className="p-1 bg-light pointer" 
                        onClick={() => addChassis()}
                    >Add {searchChassis}</p>) : (!isLoding && cList && cList.map((el, index) => (
                    <li 
                        key={index}  
                        className={`popdrop__dropdown-item ${
                        cursor === index
                          ? "bg-gradient-light"
                          : null
                        }`}  
                      tabIndex="-1" 
                      id={`chassis-${index}`}
                      onClick={async () => get(el)}
                      onKeyDown={(e) => handleKeyDown(e)}
                      >
                      {isChassisV3 ? `${
                          el?.latestChassisLocation?.address ?
                          `${el?.chassisNo} - ${el?.latestChassisLocation?.address ? el?.latestChassisLocation?.address : ""}`
                          : el?.chassisNo
                      }` : `${el.chassisNo}`}
                  </li>
                )))}
            </ul>
        </div>,
        document.getElementById('pp-overlay-container')
    );
};
export default GrayChassisList