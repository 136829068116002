import { euAddressFormate } from "pages/tms/Dispatcher/actionCreators";
import jsonToQueryParams from "../../../../Components/Common/jsonToQueryParams";
import { HTTP, convertUnit, addressFormatEnum, getStorage, makeOptionsForSelect, types, isZipcodeWithCountryEnabled } from "../../../../services";
import * as ToQuery from '../../../../Components/Common/jsonToQueryParams';

let freeze = false //mark delay 
let timer //saved timer

export const getCustomerRateRecords = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "v1/rate-engine/get-customer-rate-record";
    const newPayload = { 
      ...payload,
      limit: payload?.limit ?? 30,
      skip: payload?.skip ?? 0, 
      search:payload?.query?.search ?payload?.query?.search: payload.search ?? ""
    }
    delete newPayload?.query
    HTTP("post", url, newPayload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        console.log(error, "error")
        reject(error);
      });
  });
};


export const getCustomerRateRecordsById = (id) => {
  return new Promise((resolve, reject) => {
    const url = `customer-rate-record-id?customerRateRecordId=${id}`;

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getChargeRateGroups = ({ skip, limit, query }) => {
  return new Promise((resolve, reject) => {
    const formattedQuery = jsonToQueryParams(query);
    const url = `charge-template-groups?skip=${skip ?? 0}&limit=${limit ?? 0}&${formattedQuery}`;

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getChargeProfiles = ({ skip, limit, query, chargeCode }) => {
  return new Promise((resolve, reject) => {
    const formattedQuery = jsonToQueryParams(query);
    let url = `charge-templates?skip=${skip ?? 0}&limit=${limit ?? 0}&${formattedQuery}`;
    if (chargeCode) {
      url = url + `&chargeCode=${chargeCode}`;
    }

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};



export const createChargeProfileGroup = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "charge-template-groups";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//rate record
export const createRateRecord = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "customer-rate-record";

    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const deleteRateRecord = (customerRateRecordId) => {
  return new Promise((resolve, reject) => {
    let url = `customer-rate-record?customerRateRecordId=${customerRateRecordId}`;

    HTTP("DELETE", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//for customer async search
export const getCustomerGroupAsyncSearch = (strSearch, groupProfiles = []) => {
  let freeze = true; // set mark for stop calls
  return new Promise((resolve, reject) => { // return promise 
    let timer;
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); // remove prev timer 
      timer = setTimeout(() => {
        freeze = false;
        strSearch = strSearch.replace(/[{}*+?\\^$|#]/g, '');
        getCustomers(strSearch, 10)
          .then((r) => {
            if (Array.isArray(groupProfiles) && groupProfiles.length) {
              const search = new RegExp(strSearch, 'ig');
              r = r.concat(groupProfiles.filter((prof) => search.test(prof.label)));
            }
            res(r);
          })
          .catch((error) => {
            err(error);
          });
      }, 500);
    });

    p.then(function (x) {
      resolve(x);
    });
  });
};
export function getCustomers(params, limit, customerType) {
  let obj = { nameSearch: params, limit: limit ? limit : 10 };
  if (customerType) {
    obj.customerType = JSON.stringify(customerType)
  }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('caller') > -1) })
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({
                value: obj._id,
                label: `${obj.company_name}, ${labelData.join(', ')}`,
                allInfos: obj,
                parameterProfile: {
                  _id: obj._id,
                  name: obj.company_name,
                  profileGroup: [],
                  profileType: 'customer',
                  profile: {
                    _id: obj?._id,
                    name: obj?.company_name,
                    city: obj?.city,
                    state: obj?.state,
                    country: obj?.country,
                    zipCode: obj?.zip_code,
                    address1: obj?.address1,
                    address:obj?.address?.address,
                  },
                }
              });
            } else {
              if (obj.company_name) {
                labelData.push(obj.company_name)
              }
              if (obj.address && obj.address.address) {
                let street = obj.address.address.split(',');
                if (street && street.length > 0) {
                  labelData.push(street[0]);
                }
              }
              if (obj.city) {
                labelData.push(obj.city)
              }
              if (obj.state) {
                labelData.push(obj.state)
              }
              if (obj.zip_code) {
                labelData.push(obj.zip_code)
              }
              customers.push({
                value: obj._id,
                label: `${labelData.join(', ')}`,
                allInfos: obj,
                parameterProfile: {
                  _id: obj._id,
                  name: obj.company_name,
                  profileGroup: [],
                  profileType: 'customer',
                  profile: {
                    _id: obj._id,
                    name: obj.company_name,
                    city: obj?.city,
                    state: obj?.state,
                    country: obj?.country,
                    zipCode: obj?.zip_code,
                    address1: obj?.address1,
                    address:obj?.address?.address,
                  },
                }
              });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

//for shipper, consignee, return async search
export const getCustomerCityAsyncSearch = async (strSearch, groupProfiles = [], groupedCities = [], groupedZip = [], customerType  ) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        strSearch = strSearch.replace(/[{}*+?\\^$|#]/g, '');
        let r = await getCustomerCityOptions(strSearch, 5, customerType)//request
        if (Array.isArray(groupProfiles) && groupProfiles.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupProfiles.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedCities) && groupedCities.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedCities.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedZip) && groupedZip.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedZip.filter((prof) => search.test(prof.label)))
        }
        res(r);
      }, 500)
    })
    p.then(function (x) {
      res(x);
    })
  });
};

export const getCustomerCityWithCountryCodeAsyncSearch = async (strSearch, groupProfiles = [], groupedCities = [], groupedZip = [], customerType  ) => {
  freeze = true //set mark for stop calls
  return new Promise(async (res, err) => { //return promise 
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer) //remove  prev timer 
      timer = setTimeout(async () => {
        freeze = false
        strSearch = strSearch.replace(/[{}*+?\\^$|#]/g, '');
        let r = await getCustomerCityWithCountryCodeOptions(strSearch, 5, customerType)//request
        if (Array.isArray(groupProfiles) && groupProfiles.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupProfiles.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedCities) && groupedCities.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedCities.filter((prof) => search.test(prof.label)))
        }
        if (Array.isArray(groupedZip) && groupedZip.length) {
          const search = new RegExp(strSearch, 'i');
          r = r.concat(groupedZip.filter((prof) => search.test(prof.label)))
        }
        res(r);
      }, 500)
    })
    p.then(function (x) {
      res(x);
    })
  });
};

export function getCustomerCityOptions(params, limit, customerType) {
  let obj = { searchTerm: params, limit: limit ? limit : 5 };
  if (params && customerType ) {
    obj.customerType =  customerType
  }
  let url = 'carrier/getCustomerCityOptions';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        let city = []
        allDatas.forEach((obj) => {
          let labelData = [];
          const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
          if (defaultAddressFormat !== addressFormatEnum.US) {
            euAddressFormate(obj, labelData)
            city.push({
              value: obj._id,
              label: `${obj?.name}  ${labelData?.length > 0 ? " | " + labelData.join(', ') : ""}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj?.city ?? obj.name.split(',')?.[0]?.trim(),
                  state: obj?.state ?? obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address:obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          } else {
            city.push({
              value: obj._id,
              label: `${obj?.name} ${obj?.address ? `| ${obj?.address?.address}` : ''}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj?.city ?? obj.name.split(',')?.[0]?.trim(),
                  state: obj?.state ?? obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address:obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          }
        });
        resolve(city);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getCustomerCityWithCountryCodeOptions(params, limit, customerType) {
  let obj = { searchTerm: params, limit: limit ? limit : 5 };
  if (params && customerType ) {
    obj.customerType =  customerType
  }
  let url = 'carrier/getCustomerCityWithCountryCodeOptions';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        let city = []
        let labelData = [];
        const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
        allDatas.forEach((obj) => {
          if (isZipcodeWithCountryEnabled() && obj.type === 'zipCode') {
            city.push({
              value: obj._id,
              label: `${obj.name}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'zipCode' ? 'zipCode' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj?.city ?? obj.name.split(',')?.[0]?.trim(),
                  state: obj?.state ?? obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.value,
                  address1: obj?.address1,
                  address: obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          }
          else if (defaultAddressFormat !== addressFormatEnum.US) {
            euAddressFormate(obj, labelData)
            city.push({
              value: obj._id,
              label: `${obj?.name}  ${labelData?.length > 0 ? " | " + labelData.join(', ') : ""}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj?.city ?? obj.name.split(',')?.[0]?.trim(),
                  state: obj?.state ?? obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address:obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          } else {
            city.push({
              value: obj._id,
              label: `${obj?.name} ${obj?.address ? `| ${obj?.address?.address}` : ''}`,
              parameterProfile: {
                _id: obj._id,
                name: obj.name,
                profileGroup: [],
                profileType: obj?.type === 'city' ? 'cityState' : 'customer',
                profile: {
                  _id: obj._id,
                  name: obj.name,
                  city: obj?.city ?? obj.name.split(',')?.[0]?.trim(),
                  state: obj?.state ?? obj.name.split(',')?.[1]?.trim(),
                  country: obj?.country,
                  zipCode: obj?.zip_code,
                  address1: obj?.address1,
                  address:obj?.address?.address,
                },
              },
              allInfos: obj,
            });
          }
        });
        resolve(city);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getChargeProfileGroupById = (id, limit = undefined, skip = undefined,) => {
  return new Promise((resolve, reject) => {
    let url = `charge-template-groups/charge-template-group-by-id?chargeTemplateGroupId=${id}`;

    if (limit) {
      url += `&limit=${limit}`;
    }

    if (!isNaN(skip)) {
      url += `&skip=${skip}`;
    }

    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateChargeProfileGroup = (payload) => {
  return new Promise((resolve, reject) => {
    let url = "charge-template-groups";

    HTTP("PATCH", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const deleteChargeProfileGroup = (chargeGroupId) => {
  return new Promise((resolve, reject) => {
    let url = `charge-template-groups?groupId=${chargeGroupId}`;

    HTTP("DELETE", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const convertProfileToOptions = (profileParameters) => {
  const profiles = profileParameters?.filter((e) => e.profile);
  const profileGroups = profileParameters?.filter((e) => e.profileGroup?.length);


  const options = [
    ...makeOptionsForSelect(profiles, 'profile.name', '', ''),
    ...makeOptionsForSelect(profiles, 'name', '', ''),
  ];
}

export const unitOfMeasure = [
  { index: 0, value: 'perday', label: 'Per Day' },
  { index: 1, value: 'perhour', label: 'Per Hour' },
  { index: 2, value: `per${convertUnit('convertedUnit','weight')==="Pounds" ? "pound":"kilogram"}`, label: `Per ${convertUnit('convertedUnit', 'weight')}` },
  { index: 3, value: 'permile', label: `Per ${convertUnit()}` },
  { index: 4, value: 'perRoadTollMile', label: `Per Road Toll ${convertUnit()}` },
  { index: 5, value: 'fixed', label: 'Fixed' },
  { index: 6, value: 'percentage', label: 'Percentage' },
  { index: 7, value: 'per15min',label: 'Per 15min' },
  { index: 8, value: 'per30min',label: 'Per 30min' },
  { index: 9, value: 'per45min',label: 'Per 45min' },
  { index: 10, value: 'radiusRate',label: 'Radius Rate' },
  { index: 11, value: 'compoundingRadiusRate',label: 'Compounding Radius Rate' },
];

