import React, {Component} from 'react';
import { Builder } from '@vojtechportes/react-query-builder';
import { conType } from './utils';
import { SelectMulti } from './SelectMulti';
import { SelectInput } from './SelectInput';
import { useState } from 'react';
import { useEffect } from 'react';
import { NEW_CUSTOMER_ROUTES } from '../../pages/tms/constant';
import { isMultiContainerOn, isStopOffOn } from 'services';
import { isNewEndorsement } from '../../services';
import { COMBINE_TRIP_TYPE } from '../Common/CombineTrip/constants'
import { APPOINTMENT_TEMPLATE_RULES } from 'pages/tms/ContainerTracking/AppointmentSystemSettings/Components/TemplateRules'
export default function QueryBuilder(props){
    const [fields, setFields] = useState([]);
    const [data, setData] = useState(props.data || [])
    const [components, setComponents] = useState({
        form: {
            Select: SelectInput,
            SelectMulti: SelectMulti
        }
    })
    let CON_TYPE = [...conType]?.filter(x => Object.keys(x)?.length > 0)?.filter(x=>!(x.label === "STOPOFF") || isStopOffOn()).filter(x=>!(x.label === "TRIP TYPE") || isMultiContainerOn());

    if(!NEW_CUSTOMER_ROUTES.includes(window.location.pathname)){
        CON_TYPE = CON_TYPE.filter(d => d.value !== "deliveryCountry");
    }

    if (isNewEndorsement()) {
        CON_TYPE = [
            ...CON_TYPE,
            { label: "DOMESTIC", value : "domestic"},
            { label: "EV", value : "ev"},
            { label: "WASTE", value : "waste"},
            { label: "GDP", value : "gdp"},
            { label: "RAIL", value : "isRail"},
          ]
    }
    const hasExtraFields = props?.extraFields && Array.isArray(props?.extraFields) && props?.extraFields?.length > 0;
    if(hasExtraFields) {
        props?.extraFields?.forEach((field) => {
            if (!field?.name || !field?.options?.length) return;
            switch (field.name) {
              case APPOINTMENT_TEMPLATE_RULES.LOAD_STATUS.VALUE:
                CON_TYPE = [
                  ...CON_TYPE,
                  {
                    label: APPOINTMENT_TEMPLATE_RULES.LOAD_STATUS.LABEL,
                    value: APPOINTMENT_TEMPLATE_RULES.LOAD_STATUS.VALUE,
                    options: field.options
                  }
                ];
                break;
          
              case APPOINTMENT_TEMPLATE_RULES.CONTAINER_STATUS.VALUE:
                CON_TYPE = [
                  ...CON_TYPE,
                  {
                    label: APPOINTMENT_TEMPLATE_RULES.CONTAINER_STATUS.LABEL,
                    value: APPOINTMENT_TEMPLATE_RULES.CONTAINER_STATUS.VALUE,
                    options: field.options
                  }
                ];
                break;
          
              case APPOINTMENT_TEMPLATE_RULES.READY_TO_RETURN.VALUE:
                CON_TYPE = [
                  ...CON_TYPE,
                  {
                    label: APPOINTMENT_TEMPLATE_RULES.READY_TO_RETURN.LABEL,
                    value: APPOINTMENT_TEMPLATE_RULES.READY_TO_RETURN.VALUE,
                    options: field.options
                  }
                ];
                break;
          
              default:
                break;
            }
          });
    }
    function statesChange (){
        const temp_fields = [];
        let conditionsTypes = [...CON_TYPE]
        if(props.extraConTypes?.length){
            props?.extraConTypes?.forEach(rule => {
                if(!conditionsTypes.find(item => item.value == rule.value)){
                    conditionsTypes.push(rule)
                }
            })

        }
        conditionsTypes && conditionsTypes.length>0 && conditionsTypes?.forEach((con) => {
            let multiValue = [];
            if (con.value === 'state') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.consingeeState
                ]
            } else if (con.value === 'terminal') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.terminals
                ]
            }  else if (con.value === 'deliveryDay') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...Days
                ]
            } else if (con.value === 'cityGroups') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.cityGroups?props.cityGroups:[]
                ]
            } else if ( ['overWeight','hot', 'hazmat', 'liquor', 'isGenset', 'domestic', 'ev', 'waste', 'gdp', 'isRail', 'scale', 'dualTransaction', 'isStreetTurn'].indexOf(con.value) > -1) {
                multiValue = [
                    { value: con.value, label: con.label},
                    ...yesNoOptions
                ]
            } else if (con.value === 'type_of_load') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...TypeOfLoad
                ]
            } else if (con.value === "commodityProfile") {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.commodities
                ]
            }else if(con.value === "dropped"){
                multiValue = [
                    { value: con.value, label: con.label },
                    ...beforeAfter
                ]
            }else if(["sourceCountry","destinationCountry"].includes(con.value)){
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.countryList
                ]
            }else if (con.value === 'deliveryCountry') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.deliveryCountry
                ]
            } else if (con.value === 'zipCodeGroups') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.zipCodeGroups ? props.zipCodeGroups : []
                ]
            } else if (con.value === "streetTurnType") {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...StreetTurnTypes
                ]
            } else if (con.value === "combineTripType") {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...TripTypes
                ]
            } else if (con.value === 'zipCode') {
                multiValue = [
                    { value: con.value, label: con.label },
                    ...props.zipCode ? props.zipCode : []
                ]
            }

            if (['totalWeight', 'deliveryTime'].indexOf(con.value) > -1) {
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'NUMBER',
                    operators: ['EQUAL', 'NOT_EQUAL', 'LARGER', 'SMALLER', 'LARGER_EQUAL', 'SMALLER_EQUAL', 'BETWEEN', 'NOT_BETWEEN'],
                });
            } else if (['state', 'terminal', 'deliveryDay', 'cityGroups', 'overWeight', 'type_of_load', "commodityProfile",'hot', 'hazmat', 'liquor', 'domestic', 'ev', 'waste', 'gdp', 'isRail', "dropped", "sourceCountry", "destinationCountry", "isGenset", "deliveryCountry", "zipCodeGroups", "streetTurnType", "zipCode", "combineTripType", "scale", "dualTransaction", "isStreetTurn"].indexOf(con.value) > -1) {
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'MULTI_LIST',
                    operators: ['ANY_IN', 'NOT_IN'],
                    value: multiValue
                });
            } else if (['temperature'].indexOf(con.value) > -1) {
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'TEXT',
                    operators: ['EQUAL', 'NOT_EQUAL', 'LIKE', 'NOT_LIKE'],
                });
            } else if(con?.value === APPOINTMENT_TEMPLATE_RULES.LOAD_STATUS.VALUE){
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'MULTI_LIST',
                    operators: ['ANY_IN', 'NOT_IN'],
                    value: [ { value: con?.value, label:con.label }, ...con?.options ]
                }); 
            } else if(con?.value === APPOINTMENT_TEMPLATE_RULES.CONTAINER_STATUS.VALUE){
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'MULTI_LIST',
                    operators: ['ANY_IN', 'NOT_IN'],
                    value: [ { value: con?.value, label:con.label }, ...con?.options ]
                }); 
            } else if(con?.value === APPOINTMENT_TEMPLATE_RULES.READY_TO_RETURN.VALUE){
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'MULTI_LIST',
                    operators: ['ANY_IN', 'NOT_IN'],
                    value: [ { value: con?.value, label:con.label }, ...con?.options ]
                }); 
            } 
            else {
                temp_fields.push({
                    field: con.value,
                    label: con.label,
                    type: 'MULTI_LIST',
                    operators: ['ANY_IN', 'NOT_IN'],
                    value: [
                        { value: con.value, label: con.label },
                    ],
                });
            }
        }); 
        setFields(temp_fields)
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(()=>{
        statesChange()
    },[props])
    return(
        <>
            <Builder
                readOnly={props.readOnly ? props.readOnly : false}
                fields={fields}
                data={data}
                components={components}
                onChange={data => { setData(data); props.setData(data);}}
            />
        </>
        );
}

const StreetTurnTypes = [
    { label: 'Commercial', value: 'commercial'},
    { label: 'Operational', value: 'operational'}
]

const TripTypes = [
    { label: 'Commercial', value: COMBINE_TRIP_TYPE.COMMERCIAL},
    { label: 'Operational', value: COMBINE_TRIP_TYPE.OPERATIONAL}
]

const Days = [
    { label: 'Sunday', value: 'Sunday' },
    { label: 'Monday', value: 'Monday' },
    { label: 'Tuesday', value: 'Tuesday' },
    { label: 'Wednesday', value: 'Wednesday' },
    { label: 'Thursday', value: 'Thursday' },
    { label: 'Friday', value: 'Friday' },
    { label: 'Saturday', value: 'Saturday' }
];

const TypeOfLoad = [
    { label: 'IMPORT', value: 'IMPORT' },
    { label: 'EXPORT', value: 'EXPORT' },
    { label: 'ROAD', value: 'ROAD' },
]

const yesNoOptions = [
    { label: 'YES', value: "true" },
    { label: 'NO', value: "false" },
];
const beforeAfter = [
    { label: 'After Delivery', value: "droppedAfterDelivery" },
    { label: 'Before Delivery', value: "droppedBeforeDelivery" },
]