import React, { useState, useCallback, useMemo, useEffect } from "react";
import { changeAccountOrderCollapseStatus } from "../../../actionCreator"
import _ from "lodash";
import SingleEmail from "./SingleEmail";

let currentDragType = null;
const DRAG_TYPE = 'account-folder';

const AccountSection = ({ allNestedFolders, changeInEmail }) => {

    const [accountOrder, setAccountOrder] = useState(() =>
        Object.keys(allNestedFolders).map(accountId => ({
            id: accountId,
            order: allNestedFolders[accountId].order,
            collapse: allNestedFolders[accountId].collapse
        }))
    );

    const [collapsedAccounts, setCollapsedAccounts] = useState(() => {
        const initialCollapsedState = {};
        Object.keys(allNestedFolders).forEach(accountId => {
            initialCollapsedState[accountId] = allNestedFolders[accountId].collapse;
        });
        return initialCollapsedState;
    });

    const [dragTargetId, setDragTargetId] = useState(null);

    const debouncedChangeAccountStatus = useCallback(
        _.debounce((payload) => {
            changeAccountOrderCollapseStatus(payload);
        }, 500),
        []
    );

    const toggleCollapse = (accountId) => {
        setCollapsedAccounts(prev => {
            const newState = {
                ...prev,
                [accountId]: !prev[accountId]
            };

            const updatedFolders = Object.keys(allNestedFolders).map(id => {
                const allConnectedEmailAccounts = JSON.parse(localStorage.getItem("allConnectedEmailAccounts"));
                const account = allConnectedEmailAccounts.find(account => account.accountId === id);
                return {
                    accountId: allNestedFolders[id].accountId,
                    order: account?.order,
                    collapse: newState[id],
                }
            });

            const grant = allNestedFolders[accountId].accountId;
            debouncedChangeAccountStatus({ grant, accounts: updatedFolders });

            return newState;
        });
    }

    useEffect(() => {
        if (changeInEmail) {
            if (!collapsedAccounts[changeInEmail]) return
            toggleCollapse(changeInEmail)
        }
    }, [changeInEmail])

    const handleDragStart = (e, accountId) => {
        currentDragType = DRAG_TYPE;
        e.dataTransfer.setData('accountId', accountId);
        const rect = e.currentTarget.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;

        const dragImage = e.currentTarget.cloneNode(true);

        const children = dragImage.children;
        for (let i = children.length - 1; i >= 0; i--) {
            if (!children[i].classList.contains("email-header")) {
                dragImage.removeChild(children[i]);
            }
        }

        dragImage.style.width = `${rect.width}px`;
        dragImage.style.height = `${rect.height}px`;
        dragImage.style.position = "absolute";
        dragImage.style.top = "-9999px";
        document.body.appendChild(dragImage);

        e.dataTransfer.setDragImage(dragImage, offsetX, offsetY);

        setTimeout(() => {
            document.body.removeChild(dragImage);
        }, 0);
    }

    const handleDragOver = (e, accountId) => {
        e.preventDefault();
        if (currentDragType === DRAG_TYPE) {
            e.dataTransfer.dropEffect = 'move';
            setDragTargetId(accountId);
            return false;
        }
        e.dataTransfer.dropEffect = 'none';
    }

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragTargetId(null);
    }

    const handleDrop = useCallback((e, targetAccountId) => {
        e.preventDefault();
        setDragTargetId(null);
        const draggedAccountId = e.dataTransfer.getData('accountId');
        currentDragType = null;

        if (draggedAccountId === targetAccountId) return;

        setAccountOrder(prev => {
            const draggedIndex = prev.findIndex(a => a.id === draggedAccountId);
            const targetIndex = prev.findIndex(a => a.id === targetAccountId);

            const newOrder = [...prev];
            const [draggedItem] = newOrder.splice(draggedIndex, 1);
            newOrder.splice(targetIndex, 0, draggedItem);

            const updatedOrder = newOrder.map((item, index) => ({
                ...item,
                order: index,
            }));

            const updatedFolders = updatedOrder.map(({ id, order, collapse }) => ({
                accountId: id,
                order,
                collapse,
            }));

            const grant = updatedFolders[0].accountId;
            debouncedChangeAccountStatus({ grant, accounts: updatedFolders });

            return updatedOrder;
        });
    }, [debouncedChangeAccountStatus]);

    const sortedAccounts = useMemo(() => {
        return accountOrder
            .sort((a, b) => {
                if (a?.order == null) return 1;
                if (b?.order == null) return -1;
                return a?.order - b?.order;
            })
            .map(({ id: accountId }) => ({
                accountId,
                ...allNestedFolders[accountId]
            }));
    }, [accountOrder, allNestedFolders]);

    return (
        <div className="d-flex flex-column">
            {sortedAccounts?.filter(account => account?.email).map(({ accountId, name, email, folders, provider }) => {
                return (
                    <SingleEmail
                        key={accountId}
                        accountId={accountId}
                        name={name}
                        email={email}
                        folders={folders}
                        isCollapsed={collapsedAccounts[accountId]}
                        onToggleCollapse={toggleCollapse}
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        provider={provider}
                        dragTargetId={dragTargetId}
                    />
                )
            })}
        </div>
    );
};

export default AccountSection;