import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import CustomTooltip from './CustomLineChartToolTip';
import Popup from "../Popup"

const CustomLineChart = (props) => {
  const { data, downloadCSV, tooltipPostFix, startDate, endDate, isPopup, setIsPopup } = props
  const [hovered, setHovered] = useState("");
  const DataKey = Object.keys(data?.[0] ?? []);

  const xAxisKey = DataKey?.find(key => key === "start_date")  // The first key will be used for the X-Axis (e.g., time, date)
  const yAxisKey = DataKey?.find(key => key === "count")  // The second key will be used for the Y-Axis (e.g., value, count)

  return (
    <ResponsiveContainer className={"customLineChart"} maxHeight={"100%"} maxWidth={"100%"}>
      <LineChart
        data={data}
        style={{ paddingRight: "17px" }}
      >
        <CartesianGrid stroke={"#F0F0F0"} x2={10} className='x-cartesian' />
        <XAxis dataKey={xAxisKey} strokeWidth={0} />
        <YAxis strokeWidth={0} />
        <Line 
          type="monotone" 
          dataKey={yAxisKey} 
          stroke={hovered.toString() ? "#94b5f1" : "#367BF6"} 
          activeDot={{ r: 0 }} 
          strokeWidth={5} 
          dot={e => <CustomTooltip {...e} 
          tooltipPostFix={tooltipPostFix} 
          setHovered={setHovered} 
          hovered={hovered} 
          onClick={() => setIsPopup(true)} />} />
      </LineChart>
      {isPopup &&
        <Popup
          rows={data}
          downloadCSV={downloadCSV}
          reportType={props?.reportType}
          isPopup={isPopup}
          handleOnClose={() => setIsPopup(false)}
          fromDate={startDate}
          toDate={endDate}
        />}
    </ResponsiveContainer>
  );
};

export default CustomLineChart;
