import React, { useRef, useState, useEffect } from 'react'
import ContainerOwnerList from '../portals/ContainerOwnerList';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from '../../../../../../Components/Common/Spinner/CellSpinner';
import { useDispatch, useSelector } from 'react-redux';

const ContainerOwner = (props) => {
	const [showBox, setShowBox] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	const [isColorPortal, setColorPortal] = useState(false);
	const dispatch = useDispatch();
	const fAction = useSelector((state) => state.dispatcherTableReducer);


	useEffect(() => {
		if (prevSelectionRef.current === false && props.isCellSelected && !isColorPortal) {
			setShowBox(true)
		}
		prevSelectionRef.current = props.isCellSelected
		if (!props.isCellSelected || props.row.type_of_load=="ROAD") {
			setColorPortal(false);
		}
	}, [props.isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, props.row?.colorOfCells?.containerOwnerName);
	}, [props.column, props.row?.colorOfCells?.containerOwnerName]);

	useEffect(() => {
		const row = props.row
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "containerOwner") {
			props.onRowChange({ ...row, ["containerOwner"]: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		} else if (fAction.reference_number === row.reference_number && fAction.field === "containerOwner") {
			setTimeout(() => {
				dispatch({ type: 'CLEAR_VALUE' });
			}, 500);
		}
	}, [fAction]);

	return (
		<>
		<div ref={cellRef} className="cell-content" onClick={() => setShowBox(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
		
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && props.row.containerOwnerName ? props.row.containerOwnerName : ""}
			{props.row?.isEditPermission && showBox && !isAssignInProgress
				&& props.row.type_of_load !== "ROAD" && <ContainerOwnerList
					CellRef={cellRef}
					Row={props.row}
					onClose={(val) => {
						setShowBox(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onRowChange={props.onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={props.column}
				/>
			}
	
		</div>
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={props.row?._id} fieldName="containerOwnerName" defaultCellColors={props.row?.colorOfCells || {}} Row={props.row} onRowChange={props.onRowChange}/>
		}
	</>)
}
export default ContainerOwner