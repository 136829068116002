import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetLoad } from '../../../../Dispatcher/actionCreators';
import confirm from "../../../../../../Components/Common/ConfirmAert";
import { IconReset } from "../../../../../../Components/Common/Icons";
import { getStorage } from "../../../../../../services";
import AlertBreakDualTransaction from '../../../../../../Components/Common/AlertBreakDualTransaction'
import { DUAL_TRANSACTION_MESSAGES } from "pages/tms/constant";

const Reset = ({ row, isCellSelected }) => {
    const dispatch = useDispatch();

    const _resetLoad = async (e) => {
        const isDualTransactionLoad = row?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
        if (isDualTransactionLoad) {
          await AlertBreakDualTransaction(row, DUAL_TRANSACTION_MESSAGES.RESET_LOAD, isLoader);
          dispatch(resetLoad({ reference_number: row.reference_number }))
                .then(() => {});
        } else { 
            confirm("Alert", "Are you sure to reset!", (confirm) => {
                if (confirm) {
                    dispatch(resetLoad({ reference_number: row.reference_number }))
                    .then(() => {});
                }
            });
        }
    }
    const isLoader = (val) => { }
    useEffect(() => {
        const keydown = async (e) => {
            if(e.keyCode === 13 && isCellSelected) {
                const isDualTransactionLoad = row?.driverOrder?.find((leg) => !leg.isVoidOut && leg.isDualTransaction);
                if (isDualTransactionLoad) {
                    await AlertBreakDualTransaction(row, DUAL_TRANSACTION_MESSAGES.RESET_LOAD, isLoader);
                    dispatch(resetLoad({ reference_number: row.reference_number }))
                            .then(() => {});
                } else { 
                    confirm("Alert", "Are you sure to reset!", (confirm) => {
                        if (confirm) {
                            dispatch(resetLoad({ reference_number: row.reference_number }))
                            .then(() => {});
                        }
                    });
                }
            }
        }
        document.addEventListener('keydown',keydown)
        return () => {
            document.removeEventListener('keydown', keydown)
        }
    },[isCellSelected])

	return (
		<>
			<div className="cell-content text-center">
                <button
                    data-testid="reset-btn"
                    className="btn btn-outline-light btn-sm"
                    onClick={(e) =>  _resetLoad(e)}
                    type="button"
                    disabled={JSON.parse(getStorage("userBasicSettings"))?.isChassisV3}
                >
                    <IconReset className="mr-2" /> Reset
                </button>
            </div>
		</>
	);
};

export default Reset;