import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CustomIconAlert } from "../../../../Components/Common/CustomIcons/Index";
import { IconCheck } from "../../../../Components/Common/Icons";

class ModifyAppointment extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { selectedApptType, onClose} = this.props;
        return (
            <Modal dialogClassName="modal-sm" show={this.props.selectedApptType}>
                <Modal.Body>
                    <div className="d-flex flex-column p-10 align-items-center text-center">
                        <div className="my-30">
                            <CustomIconAlert />
                        </div>
                        <h3>Do you want to modify this selected appointment? </h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex">
                        <button className="btn btn-primary mr-10" onClick={() => this.props.modifyAppointment(selectedApptType)}>
                            <IconCheck className="mr-1"/> YES
                        </button>
                        <button className="btn btn-outline-light" onClick={() => onClose()}>
                            NO
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModifyAppointment;