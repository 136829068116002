import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as services from "services";
import Select from "react-select";
import { smallSelectStyle } from "assets/js/select-style";
import { ENTITY_PERMISSIONS } from "pages/tms/Settings/MyApps/FleetMaintenance/constants/eld.constants";
import CheckboxGroup from "pages/tms/Settings/MyApps/FleetMaintenance/ConnectELD/helpers/CheckboxGroup";
import { toastr } from "../../services";

const ConnectEldProvider = ({ entityType, eldConnections, show, hide, onConfirm }) => {
  const [isEldProfileLoading, setIsEldProfileLoading] = useState(false);
  const [eldProfileOptions, setEldProfileOptions] = useState([]);
  const [selectedEldProfile, setSelectedEldProfile] = useState({});
  const [isConfirming, setIsConfirming] = useState(false);
  const [currentConnection, setCurrentConnection] = useState();
  const [selectedDataPoints, setSelectedDataPoints] = useState({
    [entityType]: [],
  });

  const filteredEntityGroups = ENTITY_PERMISSIONS.filter((group) => group.title === entityType);

  const [filteredPermissions, setFilteredPermissions] = useState(filteredEntityGroups);

  async function fetchELDProfiles() {
    try {
      setIsEldProfileLoading(true);
      const { data: eldProfilesExtracted } = await services
        .getELDProfiles({ profileType: entityType })
        .then((resp) => resp?.data?.data);

      const formattedEldProfiles = eldProfilesExtracted
        ?.filter((filteredEldProfile) => !filteredEldProfile?.isConnected)
        ?.map((eachEldProfile) => ({
          label:
            eldConnections && eldConnections.length > 1
              ? `${eachEldProfile?.name}-${eachEldProfile?.tspSource?.toUpperCase()}`
              : eachEldProfile?.name,
          value: eachEldProfile?.name,
          id: eachEldProfile?.id,
          isConnected: eachEldProfile?.isConnected,
          tspSource: eachEldProfile?.tspSource,
        }));

      setEldProfileOptions(formattedEldProfiles);
      setIsEldProfileLoading(false);
    } catch (e) {
      console.log("Error fetching drivers:", e);
    }
  }

  const handleEntityDataPermission = (groupTitle, checkboxVal, isChecked) => {
    const permissions = currentConnection?.permissions;

    if (permissions) {
      groupTitle = entityType;

      setSelectedDataPoints((prevState) => {
        const updatedGroup = isChecked
          ? [...prevState[groupTitle], checkboxVal]
          : prevState[groupTitle].filter((label) => label !== checkboxVal);
        return { ...prevState, [groupTitle]: updatedGroup };
      });

      setFilteredPermissions((prevPermissions) =>
        prevPermissions.map((group) =>
          group.title === groupTitle
            ? {
                ...group,
                checkboxes: group.checkboxes.map((checkbox) => ({
                  ...checkbox,
                  checked: checkbox.value === checkboxVal ? isChecked : checkbox.checked,
                })),
              }
            : group
        )
      );
    }
  };

  const handleConfirmDataPoints = async () => {
    if (Object.keys(selectedEldProfile).length === 0) {
      services.toastr.show(`Please Select Eld ${entityType} Profile.`, "warning");
      return;
    }

    setIsConfirming(false);
    hide();

    onConfirm && onConfirm(selectedEldProfile);
  };

  const handleSetPermissions = (selectedTSPConnection) => {
    if (!selectedTSPConnection) return;

    setCurrentConnection(selectedTSPConnection);

    const permissions = selectedTSPConnection?.permissions;
    const filteredPermissionGroups = filteredEntityGroups?.map((group) => ({
      ...group,
      checkboxes: group.checkboxes.map((checkbox) => ({
        ...checkbox,
        checked:
          permissions && Object.keys(permissions).length > 0 && permissions[group.title]
            ? permissions[group.title].includes(checkbox.value)
            : false,
        disabled: true, // once set, permissions to be updated only from myapp screen
      })),
    }));

    setFilteredPermissions(filteredPermissionGroups);

    if (permissions && Object.keys(permissions).length > 0) {
    }
  };

  const fetchEldConnectionPermissions = async () => {
    try {
      if (eldConnections && Array.isArray(eldConnections) && eldConnections.length === 1) {
        handleSetPermissions(eldConnections[0]);
      }
    } catch (e) {
      console.log("Error fetching eld connections:", e);
    }
  };

  const setPermissionsBasedOnSelectedEldProfile = (selectedTspSource) => {
    const selectedTSPConnection =
      eldConnections && eldConnections?.find((eldConnection) => eldConnection.tspSource === selectedTspSource);

    handleSetPermissions(selectedTSPConnection);
  };

  useEffect(() => {
    if (eldConnections && Array.isArray(eldConnections) && eldConnections.length > 0) {
      fetchELDProfiles();
      fetchEldConnectionPermissions();
    } else {
      toastr.show("Eld Not Connected, Please connect from My Apps Fleet Maintenance Settings.", "warning");
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        dialogClassName="modal-sm modal-dialog-scrollable"
        backdropClassName="z-1051"
        className="z-1051"
      >
        <Modal.Header>
          <Modal.Title>
            Connect ELD Provider
            <div className="font-12 font-normal text-muted text-capitalize">
              Select which data points you will like to pull from your ELD.
            </div>
          </Modal.Title>
          <img src="" />
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Select {entityType}</label>
            <Select
              styles={smallSelectStyle}
              value={selectedEldProfile}
              options={eldProfileOptions}
              isMulti={false}
              onChange={(selectedEldProfile) => {
                setSelectedEldProfile(selectedEldProfile);
                setPermissionsBasedOnSelectedEldProfile(selectedEldProfile?.tspSource);
              }}
              menuPosition="fixed"
              isLoading={isEldProfileLoading}
            />
          </div>

          <div className={`form-group mt-10 ${!currentConnection ? "d-none" : ""}`}>
            <label>Specify the data points you wish to import from your ELD</label>
            {filteredPermissions?.map((group, index) => (
              <CheckboxGroup key={index} {...group} onCheckboxChange={handleEntityDataPermission} showTitle={false} />
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-light" onClick={hide}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmDataPoints}>
            {isConfirming && (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            )}
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConnectEldProvider;
