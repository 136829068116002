import { disconnectEmail } from "../actionCreator"
import { getStorage, isDesktopMailboxUI, removeItem, setStorage, toastr } from "../../../../services/Common.services"
import { getValidDefaultAccount } from "../helper"

export const useDisConnectMail = (setSelectedMail, callback, countCallback) => {

    const _disconnectEmail = async (payload) => {
        try {
            const data = await disconnectEmail(payload)
            const allEmail = JSON.parse(getStorage('allConnectedEmailAccounts')) ?? []
            if (data?.success) {
                const defaultEmail = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}

                const foundMailIndex = allEmail?.findIndex(ele => ele?.accountId === payload?.accountId)
                if (foundMailIndex > -1) {
                    allEmail[foundMailIndex].isDisconnected = true
                    setStorage('allConnectedEmailAccounts', JSON.stringify(allEmail))
                    if (defaultEmail?.accountId === payload?.accountId) {
                        const nextValidAccount = getValidDefaultAccount(allEmail) ?? {}
                        if (Object.keys(nextValidAccount)?.length) {
                            setStorage('embeddedEmailAccount', JSON.stringify(nextValidAccount))
                            callback && callback()
                        }
                    }
                    if(!isDesktopMailboxUI()) setSelectedMail && setSelectedMail(allEmail[foundMailIndex])
                }
                toastr.show("disconnected Successfully.", "success")
                if (allEmail?.every(ele => ele?.isDisconnected)) {
                    removeItem('embeddedEmailAccount')
                    removeItem('allConnectedEmailAccounts')
                    window.location.reload()
                    return 
                } else {
                    countCallback && countCallback()
                }
                return data
            } else {
                toastr.show("Something went wrong!", "error")
                return {}
            }
        } catch (error) {
            console.log("error", error)
            toastr.show("Something went wrong!", "error")
        }
    }

    return {
        _disconnectEmail
    }
}