import axiosClient from "./axiosClient";

export const createAppointmentSystem = async (payload) => {
  return axiosClient().post(`myapp/appointments-system`, payload);
};

export const updateAppointmentSystem = async (payload, appointmentId) => {
  // "source" is only allowed during creation, not during update
  delete payload.source;
  return axiosClient().put(`myapp/appointments-system/${appointmentId}`, payload);
};

// startDate - these are pickupFrom time
// endDate - lfd or empty
export const countAppointmentsSlot = async ({ startDate, endDate, moveType }) => {
  if (!moveType) throw new Error("MoveType is required.");
  const query = new URLSearchParams();
  query.append("moveType", moveType);
  startDate && query.append("appointmentStartDate", startDate);
  endDate && query.append("appointmentEndDate", endDate);

  return axiosClient().get(`myapp/appointment-slots-count?${query}`);
};

export const getAppointmentSystem = async ({ appointmentId }) => {
  return axiosClient().get(`myapp/appointments-system/${appointmentId}`);
};

export const getFilteredAppointmentList = async (params) => {
  return axiosClient().get(`myapp/appointments-list?${params}`);
};

export const cancelAppointmentSystem = async (appointmentId) => {
  return axiosClient().delete(`myapp/appointments-system/${appointmentId}`);
};

export const getAppointmentSystemSettings = async () => {
  return axiosClient().get(`myapp/appointments-system-settings`);
};

export const updateAppointmentSystemSettings = async (payload) => {
  return axiosClient().put(`myapp/appointments-system-settings`, payload);
};

export const updateMaxAppointmentSettingsV2 = async (payload) => {
  return axiosClient().post(`myapp/appointment/maxApptSettingsV2`, payload);
};

export const getMaxAppointmentSettingsV2 = async () => {
  return axiosClient().get(`myapp/appointment/maxApptSettingsV2`);
};

export const getAppointmentSlots = (payload) => {
  return axiosClient().post(`myapp/get-appointments-slots`, payload);
};

export const myAppImageToBase64 = (imageURL) => {
  return axiosClient()
    .post(`myapp/image-to-base64`, { imageURL })
    .then((resp) => resp?.data?.data);
};

export const getAppointmentTemplates = async ({ containerNo, moveType }) => {
  const baseUrl = 'appointment-templates/templates-list';
  if (!containerNo || !moveType) {
    return axiosClient().get(baseUrl);
  }
  return axiosClient().get(`${baseUrl}?${new URLSearchParams({ containerNo, moveType })}`);
};

export const postAppointmentTemplates = async (payload) => {
  return axiosClient().post(`appointment-templates`,payload);
};

export const updateAppointmentTemplates = async (id,payload) => {
  if(!id) return
  return axiosClient().patch(`appointment-templates/${id}`, payload);
};

export const deleteAppointmentTemplates = async (id) => {
  if(!id) return
  return axiosClient().delete(`appointment-templates/${id}`);
};
