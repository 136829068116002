import React from 'react'
import useHover from '../../../../../../Components/CustomHooks/useHover';
import PopperTooltip from '../portals/PopperTooltip';

 const CsrToolTip = (props) => {
    const {csr} = props;
    const [csrRef, scrElement] = useHover();
  return (
    <>
                        <span
                            ref={csrRef}
                        >
                            <div className={`dispatch-icon`}>
                                <div data-testid="profilePicture" className={`avatar-circle avatar-xs ${!csr.profilePicture && "border-1 text-primary" }` }>
                                    {csr.profilePicture ?
                                        <img className="avatar rounded-circle " src={csr.profilePicture}  />
                                        : <div className="avatar-circle__initial ">{`${csr.name ? csr.name[0] : ''}${csr.lastName ? csr.lastName[0] : ''}`}</div>}
                                </div>
                            </div>
                        </span>
                        {csr.name && scrElement && 
                            <PopperTooltip isOpen={scrElement} refNo={csrRef?.current} name={`${csr.name} ${csr.lastName}`} color={'gray-700'} cssClass={"csr_tooltip"} />
                        }
    </>
  )
}
export default CsrToolTip