import { ACTIONS } from "./action.js";
import { APPOINTMENT_TIME_CATEGORY } from "../constants";

export default function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_TIME_SLOT: {
      const { payload } = action;

      const updatedState = {
        ...state,
        timeSlots: payload,
        timeCategory: APPOINTMENT_TIME_CATEGORY.TIME_RANGE_SLOT,
      };

      return updatedState;
    }

    case ACTIONS.SET_DATETIME_SLOT: {
      const { payload } = action;

      const updatedState = {
        ...state,
        dateTimeSlots: payload,
        timeCategory: APPOINTMENT_TIME_CATEGORY.DATETIME_RANGE_SLOT,
      };

      return updatedState;
    }

    case ACTIONS.SET_ISLOADING: {
      const { payload } = action;

      return {
        ...state,
        isLoading: payload,
      };
    }

    default:
      return state;
  }
}
