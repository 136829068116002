import React, { Component } from "react";
import _ from "lodash";
import mapboxgl from "mapbox-gl";

import configuration from "../../../config/index";
import { getLoadRoute } from "../../../Components/CustomerServiceSideBar/actions";
import { streetTile } from "../../trucker/NewTrack1/utility";
import { IconAt, IconPhone } from "../../../Components/Common/Icons";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

const polyline = require("@mapbox/polyline");
mapboxgl.accessToken = configuration.map_box_api_key;
let routePoints = [];

class DriverOrderSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLoad: null,
    };

    this.routeLayers = [];
    this.routeLayerGroup = null;
  }

  componentDidMount() {
    this.showMap(this.props);
  }

  updateRouteLine() {
    if (this.map) {
      this.addRouteToMap();
    }
  }

  boundToRouteLine() {
    let coordinates = routePoints[0];
    if (coordinates.length > 0) {
      this.map.fitBounds(
        new window.L.LatLngBounds(
          coordinates[0],
          coordinates[coordinates.length - 1]
        )
      );
    }
  }

  addRouteToMap() {
    if (!this.routeLayerGroup) {
      const layerGroup = new window.L.LayerGroup(null, {
        pane: "overlayPane",
      });
      layerGroup.addTo(this.map);
      this.routeLayerGroup = layerGroup;
    }
    if (this.routeLayers.length > 0 && this.routeLayerGroup) {
      // delete existing routes
      this.routeLayerGroup.clearLayers();
      this.routeLayers = [];
    }

    const routeLayer = window.L.polyline(
      routePoints[0].map((e) => e.reverse()),
      {
        color: "var(--color-primary-500)",
        weight: 7,
        opacity: 1.0,
      }
    );

    this.routeLayers.push(routeLayer);
    routeLayer.addTo(this.routeLayerGroup);
    this.boundToRouteLine();
  }

  _getLoadRoute() {
    if (this.map) {
      const { selectedLoads = {} } = this.props;
      const { reference_number } = selectedLoads;
      this.map && this.map.stop();
      if (reference_number) {
        this.setState({ isMapRouteLoading: true });
        getLoadRoute(reference_number)
          .then((routePath) => {
            //let routePoints = [];
            let pointsArr = routePath.map((d) => polyline.decode(d));
            //routePoints = pointsArr.slice().flat()
            routePoints = pointsArr
              .slice()
              .map((pArr) => pArr.map((p) => p.slice().reverse()));
            //geojson.data.geometry.coordinates = routePoints[2];
            this.updateRouteLine();
          })
          .catch(() => {
            this.setState({ isMapRouteLoading: false });
          });
      }
    }
  }

  showMap(props) {
    const oldReference_number = _.get(
      this.state.selectedLoad,
      "reference_number"
    );
    const newReference_number = _.get(props.selectedLoads, "reference_number");

    if (true && newReference_number != oldReference_number) {
      setTimeout(() => {
        this.loadMap();
      }, 1000);
      setTimeout(() => {
        this._getLoadRoute();
      }, 2000);
    }
    this.setState({ selectedLoad: props.selectedLoads });
  }

  loadMap() {
    if (this.mapContainer) {
      if (!this.map) {
        this.map = new window.L.Map("DriverLoadPopUpMap", {
          zoom: 1,
          minZoom: 1,
          center: [35.71, -75.25],
          layers: [streetTile()],
        });
      }
    }
  }

  render() {
    const selectedLoad = this.props.selectedLoads;
    const showEvent = this.props.showEvent
    if(!showEvent){
    return (
      <div className="col-md overflow-y-auto h-100 custom-scrollbar-sm px-0" style={{ maxWidth: 270 }}>
        <div className="card p-15 mb-1">
          <h5>Customer</h5>
          <div>
            {selectedLoad.caller ? selectedLoad.caller.company_name : ""}
          </div>
          <div className="mb-10">
            {selectedLoad.caller && selectedLoad.caller.address
              ? selectedLoad.caller.address.address
              : ""}
          </div>
          {selectedLoad.caller && selectedLoad.caller.mobile && (
            <div className="mb-10">
              <IconPhone className="mr-2" />
              <span>{selectedLoad.caller.mobile}</span>
            </div>
          )}
          {selectedLoad.caller && selectedLoad.caller.billingEmail && (
            <div>
              <IconAt className="mr-2" />
              <span>{selectedLoad.caller.billingEmail}</span>
            </div>
          )}
        </div>

        <div className="card p-15 mb-1">
          <h5>Summary</h5>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Created by </span>&nbsp;
            <span>{`${selectedLoad.addedBy.name}`.trim()}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Container #</span>
            <span>{selectedLoad.containerNo}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">BOL #</span>
            <span>{selectedLoad.callerbillLandingNo}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Chassis #</span>
            <span>{selectedLoad.chassisNo}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">SSL</span>
            <span>
              {selectedLoad.containerOwner
                ? selectedLoad.containerOwner.company_name
                : ""}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Size</span>
            <span>
              {selectedLoad.containerSize
                ? selectedLoad.containerSize.name
                : ""}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Type</span>
            <span>
              {selectedLoad.containerType
                ? selectedLoad.containerType.name
                : ""}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Reference</span>
            <span>
              {selectedLoad.secondaryReferenceNo
                ? selectedLoad.secondaryReferenceNo
                : ""}
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-10">
            <span className="text-muted">Total Miles</span>
            <span>{selectedLoad.distance}</span>
          </div>
          <div className="no-block align-items-center">
            <h4 className="card-title">Preview: </h4>
            <div className="ml-auto">
              <div
                id="DriverLoadPopUpMap"
                className="mapboxgl-map-300"
                style={{ height: 300 }}
                ref={(el) => (this.mapContainer = el)}
              ></div>
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>
    );
    } else {
      return <></>
    }
  }
}

export default DriverOrderSidebar;
