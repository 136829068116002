import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CancelIcon,
  IconDisconnect,
  IconDotSeperator,
  IconForward,
  IconHash,
  IconMailLabel,
  IconInbox,
  IconTimes,
  IconTrash,
  IconMultiSelection,
  IconMoveFolder,
} from "Components/Common/Icons";
import { disconnectToLoads, disconnectToQuotes } from "../actionCreator";
import { EMAIL_PROVIDER, EMAIL_TABS, OUTLOOK_PROVIDER, PERMISSION_ERROR, SEND_EMAIL_TYPE, statusLabels, AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT } from "../constant";
import { amplitudeTrack, getStorage, toastr } from "../../../../services";
import confirm from "Components/Common/ConfirmAert";
import _, { compact, flatten } from "lodash";
import { IconStarOutline } from "Components/Common/Icons";
import { useArchiveEmail, useDeleteDraftEmail, useToggleMarkAsUnread, useToggleStar, useMoveEmail } from "../hooks";
import { MessageComposer } from "./EmailDetails";
import { Modal } from "react-bootstrap";
import DocumentUpload from "./EmailDetails/DocumentUpload";
import ModalTagAs from "./ModalTagAs"
import { getThreadId, isEmbeddedEmailConnected } from "../helper";
import { LabelAsModal } from "./BottomBar";
import { IconStar } from "../../../../Components/Common/Icons";
import EmailToolTip from "./EmailDetails/EmailToolTip";
import ExternalMailForwardAlert from "./EmailDetails/ExternalMailForwardAlert";
import StatusSelectOptions from "./StatusSelectOptions";
import MoveToModal from "./BottomBar/MoveToModal";

const BatchMenuItem = ({ isAnyApiCall, onClick, icon, text, isLoading, disabled, name }) => {
  return (
    <div className="dialog-wrapper duplicate-wrapper" id={`${text === "Status" ? "status-1" : ""}`}>
      <div 
        className={`batch-menu__item ${isAnyApiCall ? "pointer-not-allowed opacity-05" : ""} ${disabled ? "pointer-not-allowed opacity-05" : ""}`}
        onClick={!(isAnyApiCall || (disabled)) && onClick}
      >
        {isLoading ? <span className="spinner-border spinner-border-sm batch-menu__itemicon" /> : icon}
        <div className="batch-menu__itemtext ">{text}</div>
      </div>
    </div>
  );
};

const EmailBottombar = ({
  handleHideBatchMenu,
  selectedRows,
  setSelectedRows,
  allEmails,
  _getCounts,
  setAllEmails,
  activeNav,
  isFromLoad,
  loadId,
  _getEmailByLoad,
  isFromQuote,
  quoteId,
  _getEmailByQuote,
  onDisconnectMail,
  allEmailSignature,
  getAllEmailSignatures,
  allEmailStatus,
  isEmailMode,
  handleOnArchive,
  activePage,
  selectedRow,
  getReply,
  isSubFolderActive,
  setupdateStatusData,
  setupdateStatusDataFlag,
  moveEmail,
  archiveLoaderIds,
  deleteDraftEmail,
  deleteLoader
}) => {
  const [isForward, setIsForward] = useState(false);
  const [showMailAlert, setShowMailAlert] = useState(false)
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false)
  const [showTagAsModal, setShowTagAsModal] = useState(false)
  const [showAddLabel, setShowLabelAs] = useState(false)
  const [showMoveTo, setShowMoveTo] = useState(false)
  const [showStatus, setShowStatus] = useState(false)
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {};
  const cellRef = useRef(null);
  const { accountId, provider } = embeddedEmailAccount ?? {}
  const isEmailConnected = isEmbeddedEmailConnected()
  const callback = (folderIds, archiveCount) => {
    handleHideBatchMenu();
    _getCounts(folderIds, archiveCount);
  };
  const { isMoveEmailLoader, setIsMoveEmailLoader } = useContext(EMAIL_CONTEXT);

  const { _moveEmail, moveEmailloaderIds } = useMoveEmail(allEmails, setAllEmails, callback);
  // const { archiveLoaderIds } = useArchiveEmail(activeNav, allEmails, setAllEmails, callback);
  const { _deleteDraftEmail, deleteLoaderIds } = useDeleteDraftEmail(allEmails, setAllEmails, callback);
  const { toggleStar, loaderIds, isStar } = useToggleStar(allEmails, setAllEmails, callback, activeNav, selectedRows, setSelectedRows);
  const { _toggleMarkAsUnread, loading: isMarkAsUnreadLoad, readUnreadIds } = useToggleMarkAsUnread(allEmails, setAllEmails, callback);
  const isAnyApiCall = (!!deleteLoader?.length && selectedRows?.some((d) => deleteLoader.includes(d.id))) || (!!archiveLoaderIds.length && selectedRows?.some((d) => archiveLoaderIds.includes(d.id))) || !!loaderIds.length || !!readUnreadIds.length;

  const ids = selectedRows.map((d) => d.id);
  const draftIds = flatten(selectedRows.map((row) => row?.draftIds?.map((d) => d)) ?? []);
  const addStarIds = selectedRows.filter((d) => !d.starred).map((dd) => dd.id)
  const removeStarTds = selectedRows.filter((d) => d.starred).map((dd) => dd.id)
  const archiveIds = selectedRows.map((d) => d.id);
  const markAsUnreadIds = selectedRows.filter((d) => !d.unread)?.map((d) => d.id);
  const markAsReadIds = selectedRows.filter((d) => d.unread)?.map((d) => d.id);
  
  const handleBottomDelete = () => {
    try{
      const isDraftSelected = selectedRows?.every((d) => d?.object === "draft");
      (activeNav === EMAIL_TABS.DRAFT  || isDraftSelected)? deleteDraftEmail({ ids: isDraftSelected ? ids : draftIds,rowIds:ids }) : moveEmail({ ids }, true);
    }catch(err){
      console.log("errror", err)
    }
  }
    
  const handleBottomUnread = () => {
    removeStatusBox()
    _toggleMarkAsUnread({ ids: markAsUnreadIds, isUnread: true });
    let eventProperties = {
      source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
      emailCount: markAsUnreadIds.length
    }
    amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_UNREAD, eventProperties)
  }
  const handleBottomRead = () => {
    removeStatusBox()
    _toggleMarkAsUnread({ ids: markAsReadIds, isUnread: false });
    let eventProperties = {
      source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
      emailCount: markAsReadIds.length
    }
    amplitudeTrack(AMPLITUDE_EVENTS.MARK_AS_READ, eventProperties)
  }
  const onToggleStar = () => {
    removeStatusBox()
    toggleStar({ ids: addStarIds, isStar: true })
    let eventProperties = {
      source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
      emailCount: addStarIds.length
    }
    amplitudeTrack(AMPLITUDE_EVENTS.STAR, eventProperties)
  };
  const onToggleRemoveStar = () => {
    removeStatusBox()
    toggleStar({ ids: removeStarTds, isStar: false })
    let eventProperties = {
      source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
      emailCount: removeStarTds.length
    }
    amplitudeTrack(AMPLITUDE_EVENTS.UNSTAR, eventProperties)
  };


  const handleBottomDisconnectLoad = () => {
    confirm("Disconnect Load", "Are you sure you want to disconnect the load?", (value) => {
      if (value) {
        let params = {
          id: loadId,
        };
        const threadIds = []
        selectedRows?.forEach((ele) => {
          if(ele?.object === "thread"){
            threadIds.push(ele?.id)
          } else {
            threadIds.push(ele?.threadId)
          }
        })
        if(threadIds?.length){
          params.threadIds = threadIds
        }
        const isExternalEmail = selectedRows.find((email) => email.grantId !== accountId)
        params.isExternalMessage = isExternalEmail ? true : false
        if(!isEmailConnected) params.skipMiddleWare = true
        disconnectToLoads(params)
          .then(() => {
            toastr.show("Load disconnected successfully.", "success");
            handleHideBatchMenu();
            _getEmailByLoad({skip:0},true);
            let eventProperties = {
              source: AMPLITUDE_EVENTS_SOURCE.LOAD_COMMUNICATION_BOTTOM_BAR,
            }
            amplitudeTrack(AMPLITUDE_EVENTS.DISCONNECT_LOAD, eventProperties)
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  const removeStatusBox = () =>{
    setShowStatus(false)
  } 
  const handleBottomDisconnectQuote = () => {
    confirm("Disconnect Quote", "Are you sure you want to disconnect the quote?", (value) => {
      if (value) {
        let params = { quoteId };
        const threadIds = []

        selectedRows?.forEach((ele) => {
          if(ele?.object === "thread"){
            threadIds.push(ele?.id)
          } else {
            threadIds.push(ele?.threadId)
          }
        })
        if(threadIds?.length){
          params.threadIds = threadIds
        }
        
        disconnectToQuotes(params)
          .then((data) => {
            toastr.show("Quote disconnected successfully.", "success");
            handleHideBatchMenu();
            _getEmailByQuote({skip:0},true);
            onDisconnectMail();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    const message = selectedRows.length === 1 && selectedRows[0];
    if(message?.files?.length) {
      const initialDocs = [];
      message?.files.forEach(val => {
        initialDocs.push(val)
      })
      setUploadedDocuments(current => [
        ...current, ...initialDocs
      ])
    }
  }, [selectedRows])

  const removeDocument = (fileId) => {
    const _documentFile = _.cloneDeep(uploadedDocuments)
    const newDocList = _documentFile?.filter((document) => document?.id !== fileId)
    setUploadedDocuments(newDocList)
  }
  const handleShowTagAsModal = () => {
    removeStatusBox()
    setShowTagAsModal(true);
  }
  const handleHideTagAsModal = () => {
    setShowTagAsModal(false);
  }
  const options = compact([
    selectedRows.length === 1 && {
      icon: <IconForward className="batch-menu__itemicon" />,
      text: "Forward",
      onClick: () => {
        removeStatusBox();
        setIsForward(true);
        let eventProperties = {
          source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
        }
        amplitudeTrack(AMPLITUDE_EVENTS.FORWARD, eventProperties)
      },
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
      name: "forward"
    },
    (selectedRows?.find((d) => !d?.unread) && {
      icon: <IconDotSeperator className="batch-menu__itemicon" />,
      text: "Mark as Unread",
      onClick: handleBottomUnread,
      isLoading: isMarkAsUnreadLoad && !!readUnreadIds.length,
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
      name: "unread"
    }),
    (selectedRows?.find((d) => d?.unread) && {
      icon: <IconDotSeperator className="batch-menu__itemicon" />,
      text: "Mark as Read",
      onClick: handleBottomRead,
      isLoading: !isMarkAsUnreadLoad && !!readUnreadIds.length,
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
      name: "unread"
    }),
    {
      icon: <IconHash className="batch-menu__itemicon" />,
      text: "Tag as",
      onClick: handleShowTagAsModal,
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
    },
    {
      // need to fix color css
      icon: <IconMultiSelection className="batch-menu__itemicon" />,
      text: "Status",
      onClick: () => setShowStatus(true),
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
      ref: cellRef
    },
    (provider === EMAIL_PROVIDER.GMAIL && {
      icon: <IconMailLabel className="batch-menu__itemicon" />,
      text: "Add Label",
      onClick: () => {removeStatusBox();setShowLabelAs(true)},
    }),
    {
      icon: <IconMoveFolder className="batch-menu__itemicon" />,
      text: "Move to",
      disabled: isMoveEmailLoader || (OUTLOOK_PROVIDER.includes(provider) && [EMAIL_TABS.DRAFT].includes(activePage) ? true : false),
      isLoading: isMoveEmailLoader,
      onClick: () => {removeStatusBox();setShowMoveTo(true)},
    },
    selectedRows?.find((d) => !d?.starred) && {
      icon: <IconStar className="batch-menu__itemicon" />,
      text: "Add Star",
      onClick: onToggleStar,
      isLoading: isStar && !!loaderIds.length,
      disabled: [EMAIL_TABS.DRAFT].includes(activePage),
      name: "star"
    },
    selectedRows?.find((d) => d?.starred) && {
      icon: <IconStarOutline className="batch-menu__itemicon" />,
      text: "Remove Star",
      onClick: onToggleRemoveStar,
      isLoading: !isStar && !!loaderIds.length,
      disabled:  [EMAIL_TABS.DRAFT].includes(activePage),
      name: "star"
    },
    {
      icon: <IconInbox className="batch-menu__itemicon" />,
      text: "Archive",
      onClick: () => {
        removeStatusBox();
        let eventProperties = {
          source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
          emailCount: selectedRows.length
        }
        handleOnArchive(archiveIds,AMPLITUDE_EVENTS.ARCHIVE,eventProperties)
      },
      isLoading: !!archiveLoaderIds.length && selectedRows?.some((d) => archiveLoaderIds.includes(d.id)),
      disabled: provider === EMAIL_PROVIDER.GMAIL ? 
        (selectedRows.some((d) => !d?.folders.includes("INBOX"))) : 
        OUTLOOK_PROVIDER.includes(provider) && 
        [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL, EMAIL_TABS.DRAFT].includes(activePage),
      name: 'archive',
    },
    {
      icon: <IconTrash className="batch-menu__itemicon" />,
      text: "Delete",
      onClick: ()=>{
        removeStatusBox();
        handleBottomDelete();
        let eventProperties = {
          source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR,
          emailCount : selectedRows.length
        }
        amplitudeTrack(AMPLITUDE_EVENTS.TRASH,eventProperties)
      },
      isLoading: (!!deleteLoader?.length && selectedRows?.some((d) => deleteLoader.includes(d.id))) && !isMoveEmailLoader,
      disabled: provider === EMAIL_PROVIDER.GMAIL ? 
        selectedRows.some((d) => d?.labels?.map((d) => d.name)?.includes("trash")) : 
        OUTLOOK_PROVIDER.includes(provider) && 
        [EMAIL_TABS.DELETED_ITEMS].includes(activePage),
      name: 'delete'
    },
  ]);

  const isExternalMessage = selectedRows.find((email) => email.grantId !== accountId);
  const handleClick = (emailType) => {
    if(isExternalMessage){
      handleAlertModal(true)
    }else{
      setIsForward(true)
    }
  }

  const handleAlertModal = (value) => {
    setShowMailAlert(value)
  }

  const onClose = () => {
    handleAlertModal(false)
  }
  return (
    <div className="d-flex justify-content-center position-fixed left-0 right-0">
      <div className="invoice-bottombar mx-auto d-flex justify-content-between px-15 position-fixed rounded-5">
        <div class="d-flex align-items-center">
          <div class="avatar-circle avatar-sm bg-white mr-10">{selectedRows.length}</div>
          <div class="text-gray-200">Selected</div>
        </div>
        {(isFromLoad || isFromQuote )? (
          <div className="d-flex align-items-center pl-10">
            {selectedRows.length === 1 && isEmailConnected && (
              <div className="dialog-wrapper duplicate-wrapper">
                <div
                  className="batch-menu__item"
                  onClick={() => handleClick(SEND_EMAIL_TYPE.FORWARD)}
                >
                  <IconForward className="batch-menu__itemicon" />
                  <div className="batch-menu__itemtext ">Forward</div>
                </div>
              </div>
            )}
            {selectedRows?.find((d) => !d?.starred) && (
              <div className="dialog-wrapper duplicate-wrapper">
                <div
                  className={`batch-menu__item ${(isExternalMessage || (isStar && !!loaderIds.length)) && 'pointer-not-allowed opacity-05'}`}
                  data-tip={PERMISSION_ERROR.CONNECT_MAIL}
                  data-for={`tabel-star`}
                  onClick={(e) => {
                    if (!(isExternalMessage || (isStar && !!loaderIds.length))) {
                      onToggleStar(e)
                    }
                  }}
                >
                  {isStar && !!loaderIds.length ? 
                    <span className="spinner-border spinner-border-sm batch-menu__itemicon" /> : 
                    <IconStarOutline className="batch-menu__itemicon" />
                  }
                  <div className="batch-menu__itemtext ">Mark as Starred</div>
                  {isExternalMessage && <EmailToolTip toolTipId={`tabel-star`} />}
                </div>
              </div>
            )}
            {selectedRows?.find((d) => d?.starred) && (
              <div className="dialog-wrapper duplicate-wrapper">
                <div
                  className={`batch-menu__item ${(isExternalMessage || (!isStar && !!loaderIds.length)) && 'pointer-not-allowed opacity-05'}`}
                  data-tip={PERMISSION_ERROR.CONNECT_MAIL}
                  data-for={`tabel-star`}
                  onClick={(e) => {
                    if (!(isExternalMessage || (!!loaderIds.length))) {
                      onToggleRemoveStar(e)
                    }
                  }}
                >
                  {!isStar && !!loaderIds.length ? 
                    <span className="spinner-border spinner-border-sm batch-menu__itemicon" /> : 
                    <IconStar className="batch-menu__itemicon" />
                  }
                  <div className="batch-menu__itemtext ">Mark as Unstarred</div>
                  {isExternalMessage && <EmailToolTip toolTipId={`tabel-star`} />}
                </div>
              </div>
            )}
            <div className="dialog-wrapper duplicate-wrapper">
              <div
                className="batch-menu__item"
                onClick={() => handleShowTagAsModal()}
              >
                <IconHash className="batch-menu__itemicon" />
                <div className="batch-menu__itemtext ">Tag</div>
              </div>
            </div>
            <div className="dialog-wrapper duplicate-wrapper">
              <div
                className="batch-menu__item"
                onClick={() => {
                  isFromQuote ? handleBottomDisconnectQuote() : handleBottomDisconnectLoad();
                }}
              >
                <IconDisconnect className="batch-menu__itemicon" />
                <div className="batch-menu__itemtext ">Disconnect from the {isFromQuote ? 'Quote' : 'Load'}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            {options.map((o) => (
              <BatchMenuItem isAnyApiCall={isAnyApiCall} {...o} />
            ))}
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center pointer" onClick={handleHideBatchMenu}>
          <div className="batch-menu__item">
            <CancelIcon className="batch-menu__itemicon " />
          </div>
        </div>
      </div>
      <Modal show={isForward} onHide={() => setIsForward(false)} dialogClassName="modal-dialog-scrollable">
        <Modal.Header className="border-bottom py-20 flex-wrap gap-10 px-15">
          <div className="d-flex gap-5 align-items-center">
            <Modal.Title>Forward Mail</Modal.Title>
          </div>
          <div className="d-flex align-items-center ml-auto gap-20">
            <button className="btn btn-outline-light" onClick={() => setIsForward(false)}>
              <IconTimes className="mr-2 text-dark" />
              Close
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="pt-0 d-flex flex-column gap-10 pb-0 px-15">
          <MessageComposer
            email={selectedRows[0]}
            sendMailType={SEND_EMAIL_TYPE.FORWARD}
            setIsSendEmail={setIsForward}
            uploadedDocuments={uploadedDocuments}
            callback={handleHideBatchMenu}
            removeDocument={removeDocument}
            setUploadedDocuments={setUploadedDocuments}
            setOpenDocumentUploadModal={setOpenDocumentUploadModal}
            loadId={loadId}
            _getEmailByLoad={_getEmailByLoad}
            isFromLoad={isFromLoad}
            isFromBottomBar={true}
            allEmailSignature={allEmailSignature}
            getAllEmailSignatures={getAllEmailSignatures}
            currentSelectedThread={selectedRow}
            getReply={getReply}
          />
        </Modal.Body>
      </Modal>
      {openDocumentUploadModal && (
        <DocumentUpload
          setOpenDocumentUploadModal={setOpenDocumentUploadModal}
          removeDocument={removeDocument}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
        />
      )}

      {showTagAsModal &&
        <ModalTagAs
          show={showTagAsModal}
          hide={handleHideTagAsModal}
          selectedRows={selectedRows}
          callback={() => {
            setShowTagAsModal(false);
            handleHideBatchMenu();
          }}
          isEmailMode={isEmailMode}
        />
      }
     
      {showAddLabel && (
        <LabelAsModal
          show={showAddLabel}
          hide={() => setShowLabelAs(false)}
          selectedRows={selectedRows}
          callback={() => {
            setShowLabelAs(false);
            handleHideBatchMenu();
          }}
        />
      )}
      {showMoveTo && (
        <MoveToModal
          show={showMoveTo}
          activePage={activePage}
          hide={() => setShowMoveTo(false)}
          selectedRows={selectedRows}
          isMoveEmailLoader={isMoveEmailLoader}
          callback={async(folderId) => {
            setShowMoveTo(false);
            setIsMoveEmailLoader(true)
            await _moveEmail({ ids }, false,folderId)
            setIsMoveEmailLoader(false)
            handleHideBatchMenu();
          }}
        />
      )}
      {(showStatus &&
        !!allEmailStatus.length
      ) &&
        <StatusSelectOptions
          idName="status-1"
          accountId={selectedRows[0]?.accountId}
          threadId={selectedRows.map(ele => getThreadId(ele))}
          setShowStatus={setShowStatus}
          handleHideBatchMenu={handleHideBatchMenu}
          isEmailMode={isEmailMode}
          isFromBottomBar={true}
          setupdateStatusData={setupdateStatusData}
          setupdateStatusDataFlag={setupdateStatusDataFlag}
        />
      }
      {
        <ExternalMailForwardAlert
          show={showMailAlert}
          onClose={() => { onClose() }}
          onApply={() => {
            setIsForward(true);
            handleAlertModal(false)
          }}
        />
      }
    </div>
  );
};

export default EmailBottombar;
