import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';

import {
  IconFile,
  IconFire,
  IconMapMarker,
  IconSnowFlake,
  IconStatus,
  IconWarning,
  IconWeightTool,
  IconExpand,
  IconFlash,
  IconTrainTrack,
  IconPlug,
  IconWaste,
  IconPlusCircle,
  IconRail,
  IconScale,
  IconStreetTurn,
} from "../../../../../../Components/Common/Icons";
import useHover from "../../../../../../Components/CustomHooks/useHover";
import { getStorage, isNewEndorsement, removeItem, setStorage } from "../../../../../../services";
import { LoadReferenceContext } from "../../NewDispatcherContext";

import '../../style.css';
import PopperTooltip from "../portals/PopperTooltip";
import { EdiTestLabel } from "./EdiTestLabel";
import { isTestLabelEnabled } from "Components/Common/functions";
import { createSource } from "pages/tms/constant";

const LoadRefNo = (props) => {
  const userBasicSettings = JSON.parse(getStorage("userBasicSettings") || "{}");

  const { row, isCellSelected } = props;
  
  let context = useContext(LoadReferenceContext);


  const [referenceElementRef, referenceElement] = useHover();
  const [hazmatRef, hazmat] = useHover();
  const [triaxleRef, triaxle] = useHover();
  const [overWeightRef, overWeight] = useHover();
  const [notesRef, notes] = useHover();
  const [reeferRef, reefer] = useHover();
  const [hotRef, hot] = useHover();
  const [routeTypeRef, routeType] = useHover();
  const [gensetRef, genset] = useHover();
  const [domesticRef, domestic] = useHover();
  const [evRef, isev] = useHover();
  const [wasteRef, iswaste] = useHover();
  const [gdpRef, isgdp] = useHover();
  const [isRailRef, _isRail] = useHover();
  const [scaleRef, scale] = useHover();
  const [isStreetTurnRef, _isStreetTurn] = useHover();


  const showModel = () => {
    context.selectLoad(row);
  };
  
  useEffect(()=>{
    const keydown = (e) => {
      const element = document?.querySelector(".load-info-modal") || document?.querySelector(".modal-content") || document?.querySelector(".table--new-row");
      if(e.keyCode === 13 && isCellSelected && !element){
        showModel()
      }
    }
    document.addEventListener('keydown',keydown)
    return () => {
      document.removeEventListener('keydown', keydown)
    }
  }, [isCellSelected]);


  const  handleDragStart = (e,load) => {
    setStorage("dnd",JSON.stringify(load))
    e.stopPropagation()
    if(e?.dataTransfer?.effectAllowed){
      e.dataTransfer.effectAllowed = "move";
    }
  }
  const handleDragEnd = (e) => {
    if (JSON.parse(getStorage('dnd'))) {
      removeItem('dnd')
    }
  }
  return (
    <>
        <span className="cell-content ref_number_cell">
        <div className="d-flex justify-content-between align-items-center grabbable"
           draggable
           onDragStart={(e) => {
            handleDragStart(e,row)
           }}
           onDragEnd={(e) => {
              handleDragEnd(e)
           }}
        >
          <div>
            <span
              className="text-primary z-3 pointer"
              onClick={(e) => {
                showModel();
              }}
            >
              {row.reference_number}
            </span>
            <span className="d-flex postion-relative">
              {row?.hazmat && <>
              <span
                ref={hazmatRef}
              >
                <IconWarning
                  className={`dispatch-icon ${row.hazmat && "text-warning-500"}`}
                />
              </span>
                { hazmat && row.hazmat && 
                    <PopperTooltip isOpen={hazmat} refNo={hazmatRef?.current} name={'Hazmat'} color={'warning-500'} cssClass={"load-equipment"} />
                }
              </>}
              {row.chassisType && row.chassisType.name === "Triaxle" && <>
              <span
               ref={triaxleRef}
              >
                <IconMapMarker
                  className={`dispatch-icon ${
                    row.chassisType &&
                    row.chassisType.name === "Triaxle" &&
                    "text-gray-700"
                  }`}
                />
              </span>
                { row.chassisType && row.chassisType.name === "Triaxle" && triaxle && 
                  <PopperTooltip isOpen={triaxle} refNo={triaxleRef?.current} name={'Triaxle'} color={'gray-700'} cssClass={"load-equipment"} />
                }
                </>}

              {row?.overWeight && <>
              <span
               ref={overWeightRef}
              >
                <IconWeightTool
                  className={`dispatch-icon ${row.overWeight && "text-gray-700"}`}
                />
              </span>
                {row.overWeight && overWeight && 
                  <PopperTooltip isOpen={overWeight} refNo={overWeightRef?.current} name={'OverWeight'} color={'gray-700'} cssClass={"load-equipment"} />
                }
              </>}

              {row.officeNote && <>
              <span
                ref={notesRef}
                className={"pointer"}
                onClick={() => {
                  // manageLoad(row, true);
                  context.selectLoad(row,"notes")
                }}
              >
                <IconFile
                  className={`dispatch-icon ${row.officeNote && "text-gray-700"}`}
                />
              </span>
              { row.officeNote && notes && 
                  <PopperTooltip isOpen={notes} refNo={notesRef?.current} name={row.officeNote} color={'gray-700'} cssClass={"load-equipment"} />
              }
              </>}
              {row.temperature && <>
              <span
                ref={reeferRef}
              >
                <IconSnowFlake
                  className={`dispatch-icon ${row.temperature && "text-badgeblue"}`}
                />
              </span>
              {row.temperature && reefer && 
                <PopperTooltip  isOpen={reefer} refNo={reeferRef?.current} name={"Reefer"} color={'badgeBlue'}  cssClass={"load-equipment"} />
              }
              </>}
              {row?.hot && <>
                <span
                  ref={hotRef}
                >
                  <IconFire
                    className={`dispatch-icon ${row.hot && "text-error-500"}`}
                  />
                </span>
                {row.hot && hot && 
                  <PopperTooltip isOpen={hot} refNo={hotRef?.current} name={"Hot"} color={'danger'} cssClass={"load-equipment"} />
                }
              </>}
                {row?.routeType && <>
              <span
                ref={routeTypeRef}
              >
                <IconStatus
                  className={`dispatch-icon ${row.routeType && "text-gray-700"}`}
                />
              </span>
              {routeType && row.routeType && 
                <PopperTooltip isOpen={routeType} refNo={routeTypeRef?.current} name={"Route Type"} color={'gray-700'}  cssClass={"load-equipment"} />
              }
                </>}
              {row?.isGenset && <>
                <span
                  ref={gensetRef}
                >
                  <IconFlash className={`dispatch-icon ${row.isGenset && "text-gray-700"}`} />
                </span>
                {genset && row.isGenset && 
                  <PopperTooltip isOpen={genset} refNo={gensetRef?.current} name={"Genset"} color={'gray-700'}  cssClass={"load-equipment"} />
                }
              </>}
              {isNewEndorsement() && <>
                {row?.domestic && <>
                  <span
                    ref={domesticRef}
                  >
                    <IconTrainTrack className={`dispatch-icon wh-14px mr_3 ${row.domestic && "text-gray-700"}`} />
                    
                  </span>
                  {domestic && row.domestic && 
                    <PopperTooltip isOpen={domestic} refNo={domesticRef?.current} name={"Domestic"} color={'gray-700'}  cssClass={"load-equipment"} />
                  }
                </>}
                {row?.ev && <>
                  <span
                    ref={evRef}
                  >
                    <IconPlug className={`dispatch-icon wh-14px mr_3 ${row.ev && "text-gray-700"}`} />
                  </span>
                  {isev && row.ev && 
                    <PopperTooltip isOpen={isev} refNo={evRef?.current} name={"EV"} color={'gray-700'}  cssClass={"load-equipment"} />
                  }
                </>}
                {row?.waste && <>
                  <span
                    ref={wasteRef}
                  >
                    <IconWaste className={`dispatch-icon wh-14px mr_3 ${row.waste && "text-gray-700"}`} />
                    
                  </span>
                  {iswaste && row.waste && 
                    <PopperTooltip isOpen={iswaste} refNo={wasteRef?.current} name={"Waste"} color={'gray-700'}  cssClass={"load-equipment"} />
                  }
                </>}
                {row?.gdp && <>
                  <span
                    ref={gdpRef}
                  >
                    <IconPlusCircle className={`dispatch-icon wh-14px mr_3 ${row.gdp && "text-gray-700"}`} />
                    
                  </span>
                  {isgdp && row.gdp && 
                    <PopperTooltip isOpen={isgdp} refNo={gdpRef?.current} name={"GDP"} color={'gray-700'}  cssClass={"load-equipment"} />
                  }
                </>}
                {row?.isRail && <>
                  <span
                    ref={isRailRef}
                  >
                    <IconRail className={`dispatch-icon wh-14px mr_3 ${row.isRail && "text-gray-700"}`} />
                    
                  </span>
                  {_isRail && row.isRail && 
                    <PopperTooltip isOpen={_isRail} refNo={isRailRef?.current} name={"Rail"} color={'gray-700'}  cssClass={"load-equipment"} />
                  }
                </>}
              </>}
              {row?.isStreetTurn && <>
                <span
                  ref={isStreetTurnRef}
                >
                  <IconStreetTurn className={`dispatch-icon wh-14px mr_3 ${row.isStreetTurn && "text-gray-700"}`} />

                </span>
                {_isStreetTurn && row.isStreetTurn &&
                  <PopperTooltip isOpen={_isStreetTurn} refNo={isStreetTurnRef?.current} name={"Street Turn"} color={'gray-700'}  cssClass={"load-equipment"} />
                }
              </>}
              {
                userBasicSettings?.isScaleEnabled && row?.scale && (
                  <>
                    <span ref={scaleRef}>
                      <IconScale className={`dispatch-icon ${row.scale && "text-warning-500"}`} />
                    </span>
                    {
                      scale && row.scale && (
                        <PopperTooltip isOpen={scale} refNo={scaleRef?.current} name={'Scale'} color={'warning-500'} cssClass={"load-equipment"} />
                      )
                    }
                  </>
                )
              }
            </span>
            {isTestLabelEnabled() && row.createSource === createSource.EDI_TEST && <EdiTestLabel className="d-inline-flex" />}
          </div>
          <div>
            <span ref={referenceElementRef}>
              <div className="open-load">
                <button className="btn btn-xs btn-primary pl-1 pr-1" onClick={() => showModel()}>
                  <IconExpand className="text-white" />
                </button>
              </div>
            </span>
          </div>
          {referenceElement && 
            <PopperTooltip isOpen={referenceElement} refNo={referenceElementRef.current} name={"Open Load"} color={'gray-700'} cssClass={"load-open"} />
          }
        </div>
      </span>
    </>
  );
};

export default LoadRefNo;
