import jsonToQueryParams, { objToQueryParams } from "Components/Common/jsonToQueryParams";
import { getStorage, setStorage, isEmbeddedEmailEnabled, isGlobalizationEmailEmbeddedEnabled, nylasAccountId } from "../../../services/Common.services";
import { HTTP } from "../../../services/Http.service";
import { flattenFolders, isEmbeddedEmailConnected, getEmailByPermissions, sortAllMailBasedOnDefaul } from "./helper";
import config from '../../../config';


export const getEEIUrl = () => {
    const user = JSON.parse(getStorage('loggedInUser'))
    const carrierId = user.role === 'carrier' ? user?._id : user.fleetManager.carrier
    return `${process.env.REACT_APP_EEI_URL}/${carrierId}/${user._id}`
}
export const getCachedEmails = async (params) => {
    let url = `${nylasAccountId()}/v1/embedded-email/email/cache`;
    // url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired })
        })
        .catch((error) => {
            console.log("Error while fetching cached data : ", error)
            // reject(error);
        });
    })
}
export function getEmailFromThreadId(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailFromThreadIdFromEEI(params)
    }
    else {
        return getEmailFromThreadIdFromBE(params)
    }
}
export function getEmailFromThreadIdFromEEI(params) {
    let url = `${getEEIUrl()}/email/by-threadId`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result?.data?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getEmailFromThreadIdFromBE(params) {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email/by-threadId`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve(res?.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}
export function getEmails(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailsFromEEI(params)
    }
    else {
        return getEmailsFromBE(params)
    }
}
export function getEmailsFromEEI(params) {
    const url = `${getEEIUrl()}/email/get-emails`
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        },url).then((result) => {
            const res = result?.data
            result.data && resolve({ messages: res?.data, pageToken: res?.nextCursor ?? "", apiCallRequired: res?.apiCallRequired })
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getEmailsFromBE(params) {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, pageToken: res?.nextCursor ?? "", apiCallRequired: res?.apiCallRequired })
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function clearCachedInboxMails() {
    // need to change later with BE call
    let url = `${nylasAccountId()}/v1/embedded-email/email/clear-inbox-cache`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'Authorization': getStorage('token'),
            "Accept":"application/json",
            "Content-Type":"application/json"
        })
        .then((result) => {
            resolve(result)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getAllEmails(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return getAllEmailsFromEEI(params)
    } else {
        return getAllEmailsFromBE(params)
    }
}

export function getAllEmailsFromEEI(params) {
    let url = `${getEEIUrl()}/email/all-email`
    params.grant = nylasAccountId();
    return new Promise((resolve, reject) => {
        HTTP("post", null, params, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            const res = result?.data;
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getAllEmailsFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/all-email`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                const res = result?.data?.data
                resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function getDraftEmails(params = {}) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return getDraftEmailsFromEEI(params)
    } else {
        return getDraftEmailsFromBE(params)
    }
}

export function getDraftEmailsFromEEI(params) {
    let url = `${getEEIUrl()}/email/drafts`
    params.grant = nylasAccountId()

    const queryParams = Object.keys(params)
        .map(key => {
            const paramValue = Array.isArray(params[key])
                ? `${key}=${encodeURIComponent(JSON.stringify(params[key]))}`
                : `${key}=${encodeURIComponent(params[key])}`;
            return paramValue;
        })
        .join('&');

    url = queryParams ? `${url}?${queryParams}` : url;
    
    return new Promise((resolve, reject) => {
        HTTP("get", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve({ 
                messages: result.data?.data || [],
                apiCallRequired: result.data?.apiCallRequired, 
                pageToken: result.data?.nextCursor ?? "" 
            })
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getDraftEmailsFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/draft`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
          })
          .catch((error) => {
            reject(error);
          });
      })
  }

export function createDraftEmail(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return createDraftEmailFromEEI(params)
    } else {
        return createDraftEmailFromBE(params)
    }
}

function parseFormDataToPlainObject(formData) {
    const plainParams = {};
    
    for (let [key, value] of formData.entries()) {
        switch(key) {
            case 'to':
            case 'cc': 
            case 'bcc':
                plainParams[key] = JSON.parse(value);
                break;

            case 'attachments':
                if (!plainParams[key]) {
                    plainParams[key] = [];
                }
                plainParams[key].push(value);
                break;

            case 'inlineAttachments':
                if (!plainParams[key]) {
                    plainParams[key] = [];
                }
                plainParams[key].push(value);
                break;

            case 'body':
            case 'subject':
                plainParams[key] = value;
                break;

            default:
                plainParams[key] = value;
        }
    }
    return plainParams;
}

const convertAttachmentsToBase64 = async (attachments) => {
    try {
        const files = Array.isArray(attachments) ? attachments : [attachments];
        if(!files?.length) return [];
        
        return await Promise.all(files.map(async (file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve({
                        filename: file.name,
                        content: reader.result.split(',')[1],
                        content_type: file.type,
                        size: file.size
                    });
                };
                reader.readAsDataURL(file);
            });
        }));
    } catch (error) {
        return [];
    }
};

export function createDraftEmailFromEEI(params) {
    let url = `${getEEIUrl()}/email/drafts`
    url = url + "?grant=" + nylasAccountId();

    return new Promise(async (resolve, reject) => {
        try {
            const plainParams = parseFormDataToPlainObject(params);
            
            if(plainParams.attachments?.length) {
                plainParams.attachments = await convertAttachmentsToBase64(plainParams.attachments);
            }

            HTTP("post", null, plainParams, {
                authorization: getStorage("token"),
                'be_token': true,
                'Content-Type': 'application/json',
                maxBodyLength: 262144000 // 250MB
            }, url).then((result) => {
                resolve(result?.data || {});
            }).catch((error) => {
                reject(error);
            });
        } catch(error) {
            reject(error); 
        }
    });
}

export function createDraftEmailFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function updateDraftEmail(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return updateDraftEmailFromEEI(params)
    } else {
        return updateDraftEmailFromBE(params)
    }
}

export function updateDraftEmailFromEEI(params) {
    let url = `${getEEIUrl()}/email/drafts`;
    url = url + "?grant=" + nylasAccountId();

    return new Promise(async (resolve, reject) => {
        try {
            const plainParams = parseFormDataToPlainObject(params);

            if (plainParams.attachments?.length) {
                plainParams.attachments = await convertAttachmentsToBase64(plainParams.attachments);
            }

            HTTP('put', null, plainParams, {
                authorization: getStorage("token"),
                'be_token': true,
                'Content-Type': 'application/json',
                maxBodyLength: 262144000 // 250MB
            }, url).then((result) => {
                resolve(result?.data || {});
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export function updateDraftEmailFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function deleteDraftEmail(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return deleteDraftEmailFromEEI(params)
    } else {
        return deleteDraftEmailFromBE(params)
    }
}

export function deleteDraftEmailFromEEI(params) {
    let url = `${getEEIUrl()}/email/drafts/${params.ids.join(',')}`
    url = url + "?grant=" + nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('delete', null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function deleteDraftEmailFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/draft-email`
    return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getLabels(params = {}) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return getLabelsFromEEI(params)
    } else {
        return getLabelsFromBE(params)
    }
}

export function getLabelsFromEEI(params) {
    let url = `${getEEIUrl()}/email/folders`
    params.grant = nylasAccountId()

    const queryParams = new URLSearchParams(jsonToQueryParams(params));
    if (queryParams.toString().length > 0) {
        url = `${url}?${queryParams.toString()}`;
    }

    return new Promise((resolve, reject) => {
        HTTP("get", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getLabelsFromBE(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/label`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}
export function getFolders(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return getFoldersFromEEI(params)
    } else {
        return getFoldersFromBE(params)
    }
}

export function getFoldersFromEEI(params = {}) {
    let url = `${getEEIUrl()}/email/folders`
    if (!params?.grant) {
        params.grant = nylasAccountId()
    }

    const queryParams = new URLSearchParams(jsonToQueryParams(params));
    if (queryParams.toString().length > 0) {
        url = `${url}?${queryParams.toString()}`;
    }    

    return new Promise((resolve, reject) => {
        HTTP("get", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getFoldersFromBE(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/folder`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function createLabel(params, grantId) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return createLabelFromEEI(params, grantId)
    } else {
        return createLabelFromBE(params)
    }
}

export function createLabelFromEEI(params, grantId) {
    let url = `${getEEIUrl()}/email/labels`
    url = url + "?grant=" + (grantId ?? nylasAccountId());

    return new Promise((resolve, reject) => {
        HTTP("post", null, params, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function createLabelFromBE(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/label`
        return new Promise((resolve, reject) => {
            HTTP('post', url, params, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function deleteLabel(params, grantId) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return deleteLabelFromEEI(params, grantId)
    } else {
        return deleteLabelFromBE(params)
    }
}

export function deleteLabelFromEEI(labelId, grantId) {
    let url = `${getEEIUrl()}/email/label/${labelId}`
    url = url + "?grant=" + (grantId ?? nylasAccountId());
    return new Promise((resolve, reject) => {
        HTTP("delete", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function deleteLabelFromBE(labelId) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/delete-label/${labelId}`;
        return new Promise((resolve, reject) => {
            HTTP('delete', url, null, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function createFolder(params, grantId) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return createFolderFromEEI(params, grantId)
    }
    else {
        return createFolderFromBE(params)
    }
}

export function createFolderFromEEI(params, grantId) {
    const url = `${getEEIUrl()}/email/folders`
    params.grant = grantId ?? nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            console.error(error)
            if (error?.data?.customMessage
            ) {
                error.data.message = error.data.customMessage;
            }
            reject(error);
        });
    })
}

export function createFolderFromBE(params) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/folder`
        return new Promise((resolve, reject) => {
            HTTP('post', url, params, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function deleteFolder(folderId, grantId) {
    if(isGlobalizationEmailEmbeddedEnabled()) {
        return deleteFolderFromEEI(folderId, grantId)
    } else {
        return deleteFolderFromBE(folderId)
    }
}

export function deleteFolderFromEEI(folderId, grantId) {
    let url = `${getEEIUrl()}/email/folder/${folderId}`
    url = url + "?grant=" + (grantId ?? nylasAccountId());

    return new Promise((resolve, reject) => {
        HTTP("delete", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function deleteFolderFromBE(folderId) {
    try {
        const url = `${nylasAccountId()}/v1/embedded-email/delete-folder/${folderId}`;
        return new Promise((resolve, reject) => {
            HTTP('delete', url, null, {
                'authorization': getStorage('token'),
            }).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function sendMail(payload, isDraft = false) {
    // need to change later with BE call
    let url = isDraft ? `${nylasAccountId()}/v1/embedded-email/draft` : `${nylasAccountId()}/v1/embedded-email/send-email`;
    const auth = {
        "Accept":"application/json",
        "Content-Type":"application/json"
    }
    auth.Authorization = getStorage('token')
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, auth, null)
        .then((result) => {
            const res = result?.data?.data
            resolve(res)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function uploadFiles(params) {
    let fullUrl = `${nylasAccountId()}/v1/embedded-email/upload-file`;
    return new Promise((resolve, reject) => {
        HTTP('post', fullUrl, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function authenticateEmbeddedEmail(params) {
    let url = 'v1/embedded-email-auth/generate-auth-url';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                if (result)
                    resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function exchangeMailBoxToken(params) {
    let url = 'v1/embedded-email-auth/exchange-mailbox-token';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
            .then((result) => {
                if (result)
                    resolve(result.data.data)
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function archiveEmail(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return archiveEmailFromEEI(params)
    } else {
        return archiveEmailFromBE(params)
    }
}

export function archiveEmailFromEEI(params = {}) {
    let url = `${getEEIUrl()}/email/archives/`
    url = url + "?grant=" + nylasAccountId();

    const { ids = [] } = params;

    return new Promise((resolve, reject) => {
        HTTP("put", null, ids, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function archiveEmailFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/add-to-archive`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            const res = result?.data?.data
            resolve(res)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function forwardEmail(payload) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return forwardEmailFromEEI(payload)
    } else {
        return forwardEmailFromBE(payload)
    }
}

export function forwardEmailFromEEI(payload) {
    let url = `${getEEIUrl()}/email/forward`
    url = url + "?grant=" + nylasAccountId();

    return new Promise(async (resolve, reject) => {
        try {
            const plainParams = parseFormDataToPlainObject(payload);

            if (plainParams.attachments?.length) {
                plainParams.attachments = await convertAttachmentsToBase64(plainParams.attachments);
            }

            const { messageId, draftId, ...body } = plainParams;
            const params = { messageId, draftId, body };

            HTTP("post", null, params, {
                authorization: getStorage("token"),
                'be_token': true,
                'Content-Type': 'application/json',
                maxBodyLength: 25 * 1024 * 1024 // 25MB
            }, url).then((result) => {
                resolve(result?.data)
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export function forwardEmailFromBE(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/forward-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function replyEmail(payload) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return replyEmailFromEEI(payload)
    }
    else {
        return replyEmailFromBE(payload)
    }
}

export function replyEmailFromEEI(params) {
    const url = `${getEEIUrl()}/email/reply`
    const plainParams = parseFormDataToPlainObject(params);
    
    return new Promise(async (resolve, reject) => {
        try {
            if (plainParams.attachments?.length) {
                plainParams.attachments = await convertAttachmentsToBase64(plainParams.attachments);
            }
            const { messageId, draftId, all, ...body } = plainParams;
            const payload = {messageId, draftId, all ,body}
            payload.grant = nylasAccountId()

            HTTP("post", null, payload, {
                authorization: getStorage("token"),
                'be_token': true,
                'Content-Type': 'application/json',
                maxBodyLength: 25 * 1024 * 1024 // 25MB
            }, url).then((result) => {
                resolve(result?.data || {});
            }).catch((error) => {
                reject(error);
            });
        } catch (error) {
            reject(error);
        }
    });
}

export function replyEmailFromBE(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/reply-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getCounts() {
    const url = `${nylasAccountId()}/v1/embedded-email/count`
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result?.data?.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getSentEmails(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/sent`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
          })
          .catch((error) => {
            reject(error);
          });
      })
  }
  export function getEmailByLabel(labelName, params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailByLabelFromEEI(labelName, params)
    }
    else {
        return getEmailByLabelFromBE(labelName, params)
    }
}
export function getEmailByLabelFromEEI(labelName, params) {
    const url = `${getEEIUrl()}/email/labels/${labelName}`
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        },url).then((result) => {
            const res = result?.data
          resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getEmailByLabelFromBE(labelName, params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-by-label/${labelName}`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve({ messages: res?.data, apiCallRequired: res?.apiCallRequired, pageToken: res?.nextCursor ?? ""})
        }).catch((error) => {
            reject(error);
        });
    })
}

export function connectToLoads(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/connect-to-load`;
      return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function getSingleEmail(id, isReply, skipMiddleWare, isRetrieveFromNylas) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getSingleEmailFromEEI(id, isReply, skipMiddleWare, isRetrieveFromNylas)
    }
    else {
        return getSingleEmailFromBE(id, isReply, skipMiddleWare, isRetrieveFromNylas)
    }
}
export function getSingleEmailFromEEI(id, isReply, skipMiddleWare, isRetrieveFromNylas) {
    let url = `${getEEIUrl()}/email/${id}`
    const params = {
        isRetrieveFromNylas
    };
    if(skipMiddleWare) params.skipMiddleWare = skipMiddleWare;
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getSingleEmailFromBE(id, isReply, skipMiddleWare, isRetrieveFromNylas) {
    let query = isReply ? `?isReply=true` : ''
    let url = `${nylasAccountId()}/v1/embedded-email/email-detail/${id}`;
    const params = {
        isRetrieveFromNylas
    };
    if(skipMiddleWare) params.skipMiddleWare = skipMiddleWare;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getSignleDraft(id) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getSignleDraftFromEEI(id)
    }
    else {
        return getSignleDraftFromBE(id)
    }
}

export function getSignleDraftFromEEI(id) {
    let url = `${getEEIUrl()}/email/draft/${id}`
    const params = {}
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getSignleDraftFromBE(id) {
    let url = `${nylasAccountId()}/v1/embedded-email/draft-detail/${id}`;
      return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data?.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function getEmailByLoad(params, reference_number) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-by-load/${reference_number}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailConnected()) return resolve([])
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getEmailByLoadId(params, loadId) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailByLoadIdFromEEI(params, loadId)
    }
    else {
        return getEmailByLoadIdFromBE(params, loadId)
    }
}
export function getEmailByLoadIdFromEEI(params, loadId) {
    let url = `${getEEIUrl()}/load-email/loads/${loadId}`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params)  : url ;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getEmailByLoadIdFromBE(params, loadId) {
    let url = `${nylasAccountId()}/v1/embedded-email/loads-by-id/${loadId}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getEmailByQuoteId(params, quoteNumber) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailByQuoteIdFromEEI(params, quoteNumber)
    }
    else {
        return getEmailByQuoteIdFromBE(params, quoteNumber)
    }
}
export function getEmailByQuoteIdFromEEI(params, quoteNumber) {
    let url = `${getEEIUrl()}/quote-email/:quote_number`
    params.quoteNumber = quoteNumber
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getEmailByQuoteIdFromBE(params, quoteNumber) {
    let url = `${nylasAccountId()}/v1/embedded-email/quote/get-emails-by-quote-number?quoteNumber=${quoteNumber}`;
    url = params ? url + "&&" + jsonToQueryParams(params) : url;

    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailEnabled()) return resolve([]);
        
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getTotalEmailCountByQuoteId(params, quoteNumber) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getTotalEmailCountByQuoteIdFromEEI(params, quoteNumber)
    }
    else {
        return getTotalEmailCountByQuoteIdFromBE(params, quoteNumber)
    }
}
export function getTotalEmailCountByQuoteIdFromEEI(params, quoteNumber) {
    let url = `${getEEIUrl()}/quote-email/count/:quote_number`
    params.quoteNumber = quoteNumber
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getTotalEmailCountByQuoteIdFromBE(params, quoteId) {
    let url = `${nylasAccountId()}/v1/embedded-email/quote/get-emails-count-by-quote-number?quoteNumber=${quoteId}`;
    url = params ? url + "&&" + jsonToQueryParams(params) : url;

    return new Promise((resolve, reject) => {
        if(!isEmbeddedEmailEnabled()) return resolve({});
        
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getLoadsByEmailId(emailId, param = {}) {
    let url = `${nylasAccountId()}/v1/embedded-email/ref-by-id/${emailId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res?.load)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function disconnectToLoads(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return disconnectToLoadsFromEEI(params)
    }
    else {
        return disconnectToLoadsFromBE(params)
    }
}
export function disconnectToLoadsFromEEI(params) {
    let url = `${getEEIUrl()}/load-email`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function disconnectToLoadsFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-to-load`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function disconnectToQuotes(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return disconnectToQuotesFromEEI(params)
    }
    else {
        return disconnectToQuotesFromBE(params)
    }
}
export function disconnectToQuotesFromEEI(params) {
    let url = `${getEEIUrl()}/quote-email`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function disconnectToQuotesFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-to-quote`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            resolve(result.data.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function toggleMarkAsUnReadFromEEI(params) {
    let url = `${getEEIUrl()}/email/mark-as-unread`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function toggleMarkAsUnReadFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/mark-as-unread`;
      return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
          'authorization': getStorage('token'),
        })
          .then((result) => {
            const res = result?.data.data
            resolve(res)
          })
          .catch((error) => {
            reject(error);
          });
      })
}

export function toggleMarkAsUnRead(params) {
    const isRedirectToEEI = JSON.parse(localStorage.getItem("isRedirectToEEI")) || false;
    if(isRedirectToEEI){
        return toggleMarkAsUnReadFromEEI(params)
    }
    else {
        return toggleMarkAsUnReadFromBE(params)
    }
}
export function getEmailPeople(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailPeopleFromEEI(params)
    }
    else {
        return getEmailPeopleFromBE(params)
    }
}
export function getEmailPeopleFromEEI(params) {
    // Construct URL with query parameters
    let url = `${getEEIUrl()}/people`;
    if (params) {
        params.grant = nylasAccountId();
        const queryString = new URLSearchParams(params).toString();
        url = `${url}?${queryString}`;
    }
    return new Promise((resolve, reject) => {
        HTTP('GET', null, null, {
            'authorization': ` ${getStorage('token')}`,
            'be_token': true
        }, url)
            .then((result) => {
                if (result.data) {
                    resolve(result.data);
                } else {
                    reject(new Error("No data returned from API"));
                }
            })
            .catch((error) => {
                console.error(error, "API Error");
                reject(error);
            });
    });
}

export function getEmailPeopleFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-people`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function addLabelsToEmail(payload) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return addLabelsToEmailFromEEI(payload)
    }
    else {
        return addLabelsToEmailFromBE(payload)
    }
}
export function addLabelsToEmailFromEEI(payload) {
    let url = `${getEEIUrl()}/email/add-label`;
    const params = {}
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('put', null, payload, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function addLabelsToEmailFromBE(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/add-label-to-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function removeLabelsFromEmail(payload) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return removeLabelsFromEmailFromEEI(payload)
    }
    else {
        return removeLabelsFromEmailFromBE(payload)
    }
  }
  
  export function removeLabelsFromEmailFromEEI(payload) {
    const params ={}
    let url = `${getEEIUrl()}/email/remove-label-from-email/`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("put", null, payload, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
  }
export function removeLabelsFromEmailFromBE(payload) {
    let url = `${nylasAccountId()}/v1/embedded-email/remove-label-from-email`
    return new Promise((resolve, reject) => {
        HTTP('put', url, payload, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function removeEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return removeEmailFromEEI(params)
    }
    else {
        return removeEmailFromBE(params)
    }
}
export function removeEmailFromEEI(params) {
    let url = `${getEEIUrl()}/nylas/disconnect-email-account`;
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
      return new Promise((resolve, reject) => {
        HTTP('delete', null, null, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function removeEmailFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-email-account`;
      return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
          'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
          }).catch((error) => {
            reject(error);
          });
      })
}

export function reconnectEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return reconnectEmailFromEEI(params)
    }
    else {
        return reconnectEmailFromBE(params)
    }
}
export function reconnectEmailFromEEI(params) {
    let url = `${getEEIUrl()}/nylas/reconnect-email-account`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function reconnectEmailFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/reconnect-email-account`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function disconnectEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return disconnectEmailFromEEI(params)
    }
    else {
        return disconnectEmailFromBE(params)
    }
}
export function disconnectEmailFromEEI(params) {
    let url = `${getEEIUrl()}/nylas/disconnect-email-account`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function disconnectEmailFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/disconnect-email-account`;
      return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
          'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
          }).catch((error) => {
            reject(error);
          });
      })
}

export function getAllMailUnreadCount() {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getAllMailUnreadCountFromEEI()
    }
    else {
        return getAllMailUnreadCountFromBE()
    }
}

export function getAllMailUnreadCountFromEEI() {
    const param = {}
    let url = `${getEEIUrl()}/email/all-connected-mail-counts`
    param.grant = nylasAccountId()
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getAllMailUnreadCountFromBE() {
    let url = `${nylasAccountId()}/v1/embedded-email/all-connected-emails-count`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function changeDefaultEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return changeDefaultEmailFromEEI(params)
    }
    else {
        return changeDefaultEmailFromBE(params)
    }
}
export function changeDefaultEmailFromEEI(params) {
    let url = `${getEEIUrl()}/nylas/change-default-email`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function changeDefaultEmailFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/change-default-mail`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeEmailNameForPesonal(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return changeEmailNameForPesonalFromEEI(params)
    }
    else {
        return changeEmailNameForPesonalFromBE(params)
    }
}
export function changeEmailNameForPesonalFromEEI(params) {
    let url = `${getEEIUrl()}/account/change-email-name-personal`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function changeEmailNameForPesonalFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/change-email-name-personal`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function customerEmployeeUploadFile(params) {
    let url = 'tms/uploadfile';
    return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
        'authorization': getStorage('token'),
    })
        .then((result) => {
        if (result)
            resolve(result.data)
        })
        .catch((error) => {
        reject(error);
        });
    })
  }
  export function getAttachment(fileId, param, messageId) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getAttachmentFromEEI(fileId, param, messageId)
    }
    else {
        return getAttachmentFromBE(fileId, param, messageId)
    }
}

export function getAttachmentFromEEI(fileId, param, messageId) {
    let url = `${getEEIUrl()}/email/attachment/${fileId}/${messageId}`
    param.grant = nylasAccountId()
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getAttachmentFromBE(fileId, param, messageId) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-attachment/${fileId}/${messageId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getAttachmentsByThreadFromBE(param, messageId) {
    param.skipMiddleWare = !isEmbeddedEmailConnected()
    let url = `${nylasAccountId()}/v1/embedded-email/email-attachment/${messageId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getAttachmentsByThreadFromEEI(param, messageId) {
    param.skipMiddleWare = !isEmbeddedEmailConnected()
    let url = `${getEEIUrl()}/email/attachment/${messageId}`;
    param.grant = nylasAccountId()
    url = param ? url + "?" + jsonToQueryParams(param) : url
    return new Promise((resolve, reject) => {
        HTTP('get', null, null, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getAttachmentsByThread(param, messageId) {
    const isRedirectToEEI = JSON.parse(localStorage.getItem("isRedirectToEEI")) || false;
    if(isRedirectToEEI){
        return getAttachmentsByThreadFromEEI(param, messageId)
    }
    else {
        return getAttachmentsByThreadFromBE(param, messageId)
    }
}
export function getContact(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getContactFromEEI(params)
    }
    else {
        return getContactFromBE(params)
    }
}
export function getContactFromEEI(params) {
    let url = `${getEEIUrl()}/contact`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getContactFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/contact`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function moveEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return moveEmailFromEEI(params)
    }
    else {
        return moveEmailFromBE(params)
    }
}
export function moveEmailFromEEI(params) {
    let url = `${getEEIUrl()}/email/move`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function moveEmailFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/move-email`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function toggleStarredEmail(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return toggleStarredEmailFromEEI(params)
    }
    else {
        return toggleStarredEmailFromBE(params)
    }
}
export function toggleStarredEmailFromEEI(params) {
    let url = `${getEEIUrl()}/email/star`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function toggleStarredEmailFromBE(params) {
  return new Promise((resolve, reject) => {
    HTTP("put", `${nylasAccountId()}/v1/embedded-email/star`, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        const res = result?.data?.data
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function draftLoadEmailInfo(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return draftLoadEmailInfoFromEEI(params)
    }
    else {
        return draftLoadEmailInfoFromBE(params)
    }
}
export function draftLoadEmailInfoFromEEI(id) {
    const isEmailConnected = isEmbeddedEmailConnected() ?? {}
    const params = {}
    params.grant = nylasAccountId()
    if(!isEmailConnected){
        params.skipMiddleWare = true
    }
    let url = `${getEEIUrl()}/draft-load/email/${id}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', null, null, {
            'authorization': ` ${getStorage('token')}`,
            'be_token': true
        }, url)
            .then((result) => {
                if (result.data) {
                    resolve(result.data);
                } else {
                    reject(new Error("No data returned from API"));
                }
            })
            .catch((error) => {
                console.error(error, "API Error");
                reject(error);
            });
    });
}
export function draftLoadEmailInfoFromBE(id) {
    const isEmailConnected = isEmbeddedEmailConnected() ?? {}
    const params = {}
    if(!isEmailConnected){
        params.skipMiddleWare = true
    }
    let url = `${nylasAccountId()}/v1/embedded-email/draft-load-emails/${id}`;
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("get", url , null, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function updateDraftLoadInfo(id, params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return updateDraftLoadInfoFromEEI(id, params)
    }
    else {
        return updateDraftLoadInfoFromBE(id, params)
    }
}
export function updateDraftLoadInfoFromEEI(id, params) {
    let url = `${getEEIUrl()}/draft-load/${id}`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function updateDraftLoadInfoFromBE(id, data) {
    return new Promise((resolve, reject) => {
        HTTP("post", `${nylasAccountId()}/v1/embedded-email/update-draft-load/${id}`, data, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function reviewTenderLoad(draftLoadId) {
    return new Promise((resolve, reject) => {
        HTTP("post", `${nylasAccountId()}/v1/embedded-email/review-tender/${draftLoadId}`, {}, {
            authorization: getStorage("token"),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getLoadsByThreadId(threadId, param = {}) {
    let url = `${nylasAccountId()}/v1/embedded-email/ref-by-id/${threadId}`;
    url = param ? url + "?" + jsonToQueryParams(param) : url;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailViews(){
    let url = 'email-view/get-views'
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getCompanyEmailViews(){
    let url = 'email-view/get-company-views'
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function deleteEmailView(params) {
    let url = 'email-view/delete-view';
    return new Promise((resolve, reject) => {
        HTTP('delete', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function createEmailView(params) {
    let url = 'email-view/create-view';
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function editEmailView(params) {
    let url = 'email-view/edit-view';
    return new Promise((resolve, reject) => {
        HTTP('PATCH', url, params, {
            'authorization': getStorage('token'),
        })
        .then((result) => {
            resolve(result.data)
        })
        .catch((error) => {
            reject(error);
        });
    })
}

export function getEmailByFilters(params = {}) {
    return new Promise((resolve, reject) => {
        let url = `${nylasAccountId()}/v1/embedded-email/email-by-filter`;
        url = params ? url + "?" + jsonToQueryParams(params) : url;
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function getSubCardCounts(params = {}) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getSubCardCountsFromEEI(params)
    }
    else {
        return getSubCardCountsFromBE(params)
    }
  }
  
  export function getSubCardCountsFromEEI(params) {
    let url = `${getEEIUrl()}/email/subcard-counts`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
  }
export function getSubCardCountsFromBE(params) {
  return new Promise((resolve, reject) => {
      let url = `${nylasAccountId()}/v1/embedded-email/sub-card-count`;
      url = params ? url + "?" + jsonToQueryParams(params) : url;
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getTopBarCounts(params = {}) {
  return new Promise((resolve, reject) => {
      let url = `${nylasAccountId()}/v1/embedded-email/email-count`;
      url = params ? url + "?" + jsonToQueryParams(params) : url;
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getEmailStatus(payload) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/status`;
    if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true
    if(payload?.threadIds) payload.threadIds= payload?.threadIds?.filter(Boolean)
    HTTP("post", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getChangedLoads(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getChangedLoadsFromEEI(params)
    }
    else {
        return getChangedLoadsFromBE(params)
    }
}
export function getChangedLoadsFromEEI(params) {
    const url = `${getEEIUrl()}/email/load-changes`
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        },url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}
export function getChangedLoadsFromBE(params) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/load-changes`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// export function updateLoadChangeStatus(params) {
//     if (isGlobalizationEmailEmbeddedEnabled()) {
//         return updateLoadChangeStatusFromEEI(params)
//     }
//     else {
//         return updateLoadChangeStatusFromBE(params)
//     }
// }

// export function updateLoadChangeStatusFromEEI(params) {
//     const url = `${getEEIUrl()}/email/load-changes-status`
//     params.grant = nylasAccountId()
//     const user = JSON.parse(getStorage('loggedInUser'))
//     // Add full name to params (BE process)
//     let fullName = ""
//     try {
//         const user = JSON.parse(getStorage('loggedInUser'))
//         if (user) {
//             fullName = `${user?.name ?? ""} ${user?.lastName ?? ""}`.trim()
//         }
//     } catch (e) {
//         console.error('Error parsing user data:', e)
//     }
//     params.userName = fullName

//     return new Promise((resolve, reject) => {
//         HTTP('post', null, params, {
//             'authorization': getStorage('token'),
//             'be_token': true
//         }, url).then((result) => {
//             result.data && resolve(result.data)
//         }).catch((error) => {
//             reject(error);
//         });
//     })
// }

export function updateLoadChangeStatus(params) {
    return new Promise((resolve, reject) => {
    const url = `${nylasAccountId()}/v1/embedded-email/load-changes-status`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function makeLoadReadyForPickup(params) {
    return new Promise((resolve, reject) => {
    const url = `tms/makeLoadReadyForPickup`;
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateEmailStatus(payload) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return updateEmailStatusFromEEI(payload)
    }
    else {
        return updateEmailStatusFromBE(payload)
    }
}
export function updateEmailStatusFromEEI(payload) {
    let url = `${getEEIUrl()}/status`;
    payload.grant = nylasAccountId()
    if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true
      return new Promise((resolve, reject) => {
        HTTP('put', null, payload, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function updateEmailStatusFromBE(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/status`;

    if(!isEmbeddedEmailConnected()) payload.skipMiddleWare = true

    return new Promise((resolve, reject) => {
    HTTP("put", url, payload, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        resolve(result?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllEmailSignature() {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getAllEmailSignatureFromEEI()
    }
    else {
        return getAllEmailSignatureFromBE()
    }
}

export function getAllEmailSignatureFromEEI() {
    let params ={}
    let url = `${getEEIUrl()}/email/get-signature`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getAllEmailSignatureFromBE() {
    let url = `${nylasAccountId()}/v1/embedded-email/get-signature?`;
    return new Promise((resolve, reject) => {
        HTTP('GET', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data);
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getUpdateEmailSignature(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getUpdateEmailSignatureFromEEI(params)
    }
    else {
        return getUpdateEmailSignatureFromBE(params)
    }
}

export function getUpdateEmailSignatureFromEEI(params) {
    let url = `${getEEIUrl()}/email/update-signature`;
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getUpdateEmailSignatureFromBE(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/update-signature`;
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function deleteEmailSignature(params) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return deleteEmailSignatureFromEEI(params)
    }
    else {
        return deleteEmailSignatureFromBE(params)
    }
}

export function deleteEmailSignatureFromEEI(params) {
    let url = `${getEEIUrl()}/email/delete-signature`;
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function deleteEmailSignatureFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/delete-signature`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function updateAccountInfo(payload) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return updateAccountInfoFromEEI(payload)
    }
    else {
        return updateAccountInfoFromBE(payload)
    }
}

export function updateAccountInfoFromEEI(params) {
    let url = `${getEEIUrl()}/account/update-account-info`;
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function updateAccountInfoFromBE(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/update-account-info`;
    return new Promise((resolve, reject) => {
        HTTP("put", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                resolve(result?.data?.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function convertMailBox(payload) {
    if (isGlobalizationEmailEmbeddedEnabled()) {
        return convertMailBoxFromEEI(payload)
    }
    else {
        return convertMailBoxFromBE(payload)
    }
}

export function convertMailBoxFromEEI(payload) {
    const url = `${getEEIUrl()}/account/convert-mailbox`;
    payload.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('put', null, payload, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function convertMailBoxFromBE(payload) {
    const url = `${nylasAccountId()}/v1/embedded-email/convert-mailbox`;
    return new Promise((resolve, reject) => {
        HTTP("put", url, payload, {
            authorization: getStorage("token"),
        })
            .then((result) => {
                resolve(result?.data?.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getFoldersInfoFromEEI(params) {
    if (!params?.folderIds || params?.folderIds?.length === 0) return []; 
    const url = `${getEEIUrl()}/email/folders/info`
    params.grant = nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
            'authorization': getStorage('token'),
            'be_token': true
        },url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getFoldersInfoFromBE(params) {
    if (!params?.folderIds || params?.folderIds?.length === 0) return [];
    const url = `${nylasAccountId()}/v1/embedded-email/folders-info`
    return new Promise((resolve, reject) => {
        HTTP('post', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getFoldersInfo(params) {
    const isRedirectToEEI = JSON.parse(localStorage.getItem("isRedirectToEEI")) || false;
    if(isRedirectToEEI){
        return getFoldersInfoFromEEI(params)
    }
    else {
        return getFoldersInfoFromBE(params)
    }
}
export function createSignature(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return createSignatureFromEEI(params)
    }
    else {
        return createSignatureFromBE(params)
    }
}
export function createSignatureFromEEI(params) {
    let url = `${getEEIUrl()}/email/signature`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function createSignatureFromBE(params) {
    const url = `${nylasAccountId()}/v1/embedded-email/signature`
    return new Promise((resolve, reject) => {
        HTTP('put', url, params, {
            'authorization': getStorage('token'),
        }).then((result) => {
            const res = result?.data?.data
            resolve(res)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function getEmailInsights(params) {
    let url = `${nylasAccountId()}/v1/embedded-email/email-insights`
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        })
        .then((result) => {
            resolve(result?.data?.data);
        })
        .catch((error) => {
            reject(error);
        });
    });
}

export function getThread(params) {
    if(!isEmbeddedEmailConnected()) params.skipMiddleWare = true
    let url = `${nylasAccountId()}/v1/embedded-email/thread`
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getThreadSummaryFromEEI(params) {
    if(!isEmbeddedEmailConnected()) params.skipMiddleWare = true
    let url = `${getEEIUrl()}/email/thread-summary`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getThreadSummaryFromBE(params) {
    if(!isEmbeddedEmailConnected()) params.skipMiddleWare = true
    let url = `${nylasAccountId()}/v1/embedded-email/thread-summary`
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getThreadSummary(params) {
    const isRedirectToEEI = JSON.parse(localStorage.getItem("isRedirectToEEI")) || false;
    if(isRedirectToEEI){
        return getThreadSummaryFromEEI(params)
    }
    else {
        return getThreadSummaryFromBE(params)
    }
}

export function getEmailAssignees(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailAssigneesFromEEI(params)
    }
    else {
        return getEmailAssigneesFromBE(params)
    }
}
export function getEmailAssigneesFromEEI(threadId) {
    let params ={}
    if(!isEmbeddedEmailConnected()) params.skipMiddleWare = true
    let url = `${getEEIUrl()}/email-assignee`
    params.grant = nylasAccountId()
    params.threadId = threadId
    url = params ? url + "?" + jsonToQueryParams(params)  : url ;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export const getEmailAssigneesFromBE = (threadId) => {
    let url = `${nylasAccountId()}/v1/embedded-email/assignee?threadId="${threadId}"`
    if(!isEmbeddedEmailConnected()) url = `${url}&skipMiddleWare=true`
    return new Promise((resolve, reject) => {
        HTTP("GET", url, null, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export const addEmailAssignee = (params) => {
    let url = `${nylasAccountId()}/v1/embedded-email/assignee`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function removeEmailAssignee(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return removeEmailAssigneeFromEEI(params)
    }
    else {
        return removeEmailAssigneeFromBE(params)
    }
}
export function removeEmailAssigneeFromEEI(params) {
    let url = `${getEEIUrl()}/email-assignee`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('put', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
const createDynamicFormData = (params = {}) => {
    const formData = new FormData();
    Object.entries(params).forEach(([key, value]) => {
        formData.append(key, value);
    });
    return formData;
};
export const removeEmailAssigneeFromBE = (params) => {
    const formData = createDynamicFormData({
        threadId: params?.threadId,
        userId: params?.assigneeId,
    });
    let url = `${nylasAccountId()}/v1/embedded-email/assignee`
    return new Promise((resolve, reject) => {
        HTTP("put", url, formData, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getMainFolderList(accountId) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getMainFolderListFromEEI(accountId)
    }
    else {
        return getMainFolderListFromBE(accountId)
    }
}

export function getFavoriteFolders(accountId) {
    let params ={}
    let url = `${getEEIUrl()}/account/favorite-folders`
    params.grant = accountId ?? nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getActiveFolder(accountId) {
    let params ={}
    let url = `${getEEIUrl()}/account/active-folder`
    params.grant = accountId ?? nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function setActiveFolderForBE(params) {
    let url = `${getEEIUrl()}/account/active-folder`
    params.grant = params?.activeFolder?.grantId ?? nylasAccountId()
    return new Promise((resolve, reject) => {
        HTTP("put", null, params, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}

export function getMainFolderListFromEEI(accountId) {
    let params ={}
    let url = `${getEEIUrl()}/email/main-folders`
    params.grant = accountId ?? nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getMainFolderListFromBE(accountId) {
    try {
        const url = `${accountId ?? nylasAccountId()}/v1/embedded-email/main-folders`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function signatureImageUpload(data) {
  let url = `${config.newDocUrl}emailImageUpload`
  return new Promise((resolve, reject) => {
    HTTP('post', null, data, {
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function convertUrlToBuffer(obj) {
    let url = 'tms/convert-url-to-buffer';
    url = obj ? url + "?" + objToQueryParams(obj) : url;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }

export function getReplyEmailTemplate(){
    try {
        const url = "notification-templates/get-notification-template-by-rule?ruleName=TENDER"
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}

export function getEmailTemplateValue(params) {
    let url = `tender-reply`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}
export function updateAccountConfig(params) {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return updateAccountConfigFromEEI(params)
    }
    else {
        return updateAccountConfigFromBE(params)
    }
}
export function updateAccountConfigFromEEI(params) {
    let url = `${getEEIUrl()}/nylas/update-account-config`;
    params.grant = nylasAccountId()
      return new Promise((resolve, reject) => {
        HTTP('post', null, params, {
          'authorization': getStorage('token'),
          'be_token': true
        }, url)
          .then((result) => {
            result.data && resolve(result.data)
          })
          .catch((error) => {
            reject(error);
          });
      })
}
export function updateAccountConfigFromBE(params) {
    let url = `v1/embedded-email-auth/update-account-config`
    return new Promise((resolve, reject) => {
        HTTP("post", url, params, {
            authorization: getStorage("token"),
        }).then((result) => {
            resolve(result?.data?.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function userAccountInfo() {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return userAccountInfoFromEEI()
    }
    else {
        return userAccountInfoFromBE()
    }
}
export function userAccountInfoFromEEI() {
    let params ={}
    let url = `${getEEIUrl()}/nylas/user-account-info`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params)  : url ;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function userAccountInfoFromBE() {
    try {
        const url = `v1/embedded-email-auth/user-account-info`
        return new Promise((resolve, reject) => {
            HTTP('get', url, null, {
                'authorization': getStorage('token'),
            })
            .then((result) => {
                const res = result?.data?.data
                resolve(res)
            })
            .catch((error) => {
                reject(error);
            });
        })
    } catch (error) {
        console.log(error)
    }
}
export function getEmailsUnreadCountFromRedis() {
    if(isGlobalizationEmailEmbeddedEnabled()){
        return getEmailsUnreadCountFromRedisFromEEI()
    }
    else {
        return getEmailsUnreadCountFromRedisFromBE()
    }
}
export function getEmailsUnreadCountFromRedisFromEEI() {
    let params ={}
    params.skipMiddleWare = true
    let url = `${getEEIUrl()}/email/email-unread-count-from-redis`
    params.grant = nylasAccountId()
    url = params ? url + "?" + jsonToQueryParams(params)  : url ;
    return new Promise((resolve, reject) => {
        HTTP("GET", null, null, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}
export function getEmailsUnreadCountFromRedisFromBE() {
    let url = `${nylasAccountId()}/v1/embedded-email/email-unread-count-from-redis`;
    return new Promise((resolve, reject) => {
        HTTP('get', url, null, {
            'authorization': getStorage('token'),
        }).then((result) => {
            resolve(result.data.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeFolderOrderCollapseStatus(folders) {
    const flattenedFolder = flattenFolders(folders);
    const grantId = folders[0].grantId;
    let url = `${getEEIUrl()}/account/update-folder-info`;
    return new Promise((resolve, reject) => {
        HTTP('put', null, {
            grant: grantId,
            folderStructure: flattenedFolder
        }, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            resolve(result?.data?.data)
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeAccountOrderCollapseStatus(folders) {
    let url = `${getEEIUrl()}/account/update-account-order`;
    return new Promise((resolve, reject) => {
        HTTP('put', null, folders, {
            'authorization': getStorage('token'),
            'be_token': true
        }, url).then((result) => {
            const embeddedEmailAccount = result?.data;
            const validAccounts = getEmailByPermissions(embeddedEmailAccount)
            setStorage('allConnectedEmailAccounts', JSON.stringify(sortAllMailBasedOnDefaul(validAccounts)))
        }).catch((error) => {
            reject(error);
        });
    })
}

export function changeFavoriteOrder(favorites) {
    let url = `${getEEIUrl()}/account/favorite-folders`
    return new Promise((resolve, reject) => {
        HTTP("put", null, favorites, {
            authorization: getStorage("token"),
            'be_token': true
        }, url).then((result) => {
            result.data && resolve(result.data)
        }).catch((error) => {
            reject(error);
        });
    });
}