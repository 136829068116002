
import React from 'react';
import { Modal } from 'react-bootstrap';
import { CHARGEPROFILES_KEY_MAPPER } from '../../constants/chargeProfiles';

const ChargeValidationErrorModal = ({ validationErrors, handleShowErrorsModal }) => {
    return (
        <Modal show={true} bsSize='sm'>
            <Modal.Header className="pb-0 pt-15">
              <Modal.Title>Error List</Modal.Title>
            </Modal.Header>
            <div className="hr-light" />
            <Modal.Body className="pt-0">
                <>
                    <div className='bg-gray-50 p-1 rounded-5 d-flex flex-column gap-2'>
                        {
                            Object.keys(validationErrors)?.map((key, i) => (
                                <div className='bg-white rounded-5 d-flex align-items-center p-10 text-capitalize'>
                                    <span className='mr-10'>{++i}.</span>
                                    <span key={i}>{`${CHARGEPROFILES_KEY_MAPPER?.[key] ?? ''}: ${validationErrors[key]}`}</span>
                                </div>
                            ))
                        }
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-light" onClick={() => handleShowErrorsModal()}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChargeValidationErrorModal