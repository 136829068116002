import React, { Component } from "react";
import { browserHistory } from "react-router";
import _ from "lodash";
import $ from "jquery";
import * as Yup from "yup";
import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import { Link } from "react-router";
import Datetime from "react-datetime";
import { Document, Page } from "react-pdf";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import Pagination from "react-js-pagination";
import "../style.css";
import { Modal } from "react-bootstrap";
import * as action from "./actionCreators";
import configuration from "../../../config";
import confirm from "../../../Components/Common/ConfirmAert";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import { convertTMSStatusName, isEmptyOrLoaded } from "../../../Components/Common/functions";
import { ImageUploader } from "../../../Components/Common/Components/Component";
import ChassisLoadSubHeader from "./chassisLoadSubHeader";
import ChassisLoadPage from "./chassisLoadPage";
import { smallSelectStyle } from "../../../assets/js/select-style";
import { LOAD_LABELS } from "labels";
import { getDocumentTypesByType } from '../../../Components/Common/functions';
import {
  getStorage,
  toastr,
  downloadTableAsCSV,
  showForTerminal,
  getYear,
  isNewModal,
  splitStreetNameNumber,
  defaultAddressFormat,
  addressFormatEnum,
  updateTerminology,
} from "../../../services/Common.services";
import { getTerminal, deleteChassisDoc, getChassisHistory } from "../services";
import { differnceFinder, fetchEldConnections } from "../../../services/index";
import {
  StateFormDateTime,
  StateFormInput,
  StateFormSelect,
} from "../../../Components/FormComponent/StateFormComponent";
import {
  IconDownload,
  IconPlus,
  IconPen,
  IconCheckCircle,
  IconTrash,
  IconColumn,
  IconCalendar,
  IconUpload,
  IconTimes,
  IconInfo,
  IconNotes,
  IconSearchData,
  IconClock9,
  IconDocument,
  IconLoadCartRight,
  IconCheck,
  IconSortDown,
  IconSortUp,
  IconSort,
} from "../../../Components/Common/Icons";
import SubHeaderTab from "../../../Components/SubHeaderTab";
import TabChassis from "./tabContent/chassis";
import TabHistory from "./tabContent/history";
import TabAudit from "./tabContent/audit";
import TabNotes from "./tabContent/notes";
import TabDocument from "./tabContent/documents";
import UploadedDocuments from "../Load/DocumentList";
import FullScreenUploader from "../../../Components/FullScreenUploader";
import LoadInfoModal from "../../../Components/LoadInfoModal/LoadInfoModal";
import config from "../../../config";
import * as actionCreators from "Components/FetchData/actionCreators";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as eldService from "../Settings/MyApps/FleetMaintenance/ConnectELD/helpers/eld.helpers";
import EldConnectionCard from "Components/EldConnection/EldConnectionCard";
import ConnectEldProvider from "Components/EldConnection/ConnectEldProvider";
import { ELD_ENTITIES } from "../Settings/MyApps/FleetMaintenance/constants/eld.constants";
import { getTimeZone } from "../NewDispatcher/constants";
import CellSpinner from "Components/Common/Spinner/CellSpinner";

let blankdata = {
  chassisNo: "",
  Chassis_Type: "",
  Chassis_Size: "",
  Chassis_Owner: "",
  chassis_id: undefined,
  year: "",
  make: "",
  model: "",
  AID: "",
  ITD: "",
  terminal: "",
  licenceState: "",
  licenceNumber: "",
  vin: "",
  registration: "",
  inspection: "",
  insurance: "",
  notes: "",
  collapseSubCards: true,
  gps: "",
  preventativeMaintance: "",
  formErrors: {},
  uploadFileDetails: "",
  progress: 0,
};

const sortingOptions = {
  [LOAD_LABELS.CHASSIS_NUMBER] :"chassisNo",
  [LOAD_LABELS.LOAD_NUMBER]: "load.reference_number",
  Address: "lastCompletedStatus.address.address",
  [LOAD_LABELS.CHASSIS_OWNER]: "chassisOwner.company_name",
  [LOAD_LABELS.CHASSIS_SIZE]: "chassisSize.name",
  [LOAD_LABELS.CHASSIS_TYPE]: "chassisType.name",
  'TERMINAL': "terminal.name",
  'License Number': "licenceNumber",
  VIN: "vin",
  Registration: "registration",
  Inspection: "inspection",
  Insurance: "insurance",
};
const dateInputProps = {
  onKeyDown: (e) => e.preventDefault(),
  placeholder: "Select Date",
};
                              
const yearOptions = getYear().map((year) => {
  return { value: year, label: year }
});
class AddChassis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listAllImagingDocuments:
        props.selectedLoads && props.selectedLoads.documents
          ? props.selectedLoads.documents
          : [],
      activePage: 1,
      chassis: null,
      TMSChassisOwner: [],
      ChassisSize: [],
      ChassisType: [],
      ...blankdata,
      terminals: [],
      AddChassisModal: false,
      terminal: "",
      fileType: null,
      tab: 1,
      documents: [],
      documentFile: null,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      type: "",
      chassisHistory: [],
      selectedStatuses: [],
      sort: undefined,
      asce: true,
      filterChassisTypes: "All",
      audits: [],
      isLoadingBarShow: false,
      subCardFilter: null,
      choosenTerminals: [],
      gps: "",
      preventativeMaintance: "",
      chassisHistoryPage: 1,
      chassisHistoryTotal: 1,
      formErrors: {},
      formsTouched: {},
      isSavingChassisDoc: false,
      isTerminal: false,
      isEnableChassis: true,
      isDisableChassis: false,
      ChassisLoad: false,
      isCardActive: 2,
      isLoadTabActive: 1,
      showUploadModal: false,
      showUpDocumentUploadModal: false,
      uploadFileDetails: "",
      progress: 0,
      documentObj: {},
      documentImage: true,
      isSaving: false,
      showFullDropScreen: false,
      documentTypes: [],
      externalSystemID: "",
      disabledExternalSystemID: false,
      _chassisOwner: null,
      isEldSettingsEnabled: eldService.checkIfEldPermissionEnabled(ELD_ENTITIES.CHASSIS),
      isConnectEld: false,
      formData: {},
      isEldConnected: false,
      eldProfilesData: [],
      selectedEldChassisProfile: {},
      eldConnections: [],
    };

    this.allStatuses = ["Available", "Dispatched", "Loaded", "Empty"];
    this.resetform = this.resetform.bind(this);
    this.changeColor = this.changeColor.bind(this);
  }

  deleteEquipmentDoc = (params) => {
    deleteChassisDoc(params).then((response) => {
      const documents = response.data.data.documents;
      this.setState({ documents });
      toastr.show("Deleted!", "success");
    });
  };

  handleshowUpDocumentUploadModal = (event) => {
    this.setState({
      showUpDocumentUploadModal: true,
      documentImage: true,
      progress: 0,
    });
  };
  handlehideUpDocumentUploadModal = (event) => {
    this.setState({
      showUpDocumentUploadModal: false,
      uploadFileDetails: "",
      documentFile: null,
      progress: 0,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      fileType: ""
    });
  };

  UNSAFE_componentWillMount() {
    let defaultState = {};
    this.allStatuses.forEach((key) => {
      defaultState[`${key}`] = [];
    });
    this.setState({ ...defaultState });
    // fetch and fill document types option from api
    (async () => {
      let types = await getDocumentTypesByType('chassisTypes');
      this.setState({ documentTypes: types });
      this.setState({ isLoadingBarShow: true })
    })();
  }

  componentDidMount() {
    if (!this.props.isOnlyPopup) {
      //Table Height Offset
      this.getChassis();
    }
    action.getTMSChassisOwner().then((data) => {
      this.setState({ TMSChassisOwner: data.data.data });
    });
    action.getChassisSize().then((data) => {
      this.setState({ ChassisSize: data.data.data });
    });
    action.getChassisType().then((data) => {
      this.setState({ ChassisType: data.data.data });
    });

    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal });
    if (isTerminal) {
      getTerminal().then((response) => {
        const terminals = response.data.data ? response.data.data : [];
        this.setState({ terminals, isTerminal: true });
      });
    }

    if (this.props.isOnlyPopup) {
      this.setState({ AddChassisModal: true });
    }
    this.setState({showFullDropScreen:true})

    if (this.state.isEldSettingsEnabled) {
      this.fetchEldProfiles();
      this.fetchCurrentEldConnection();
    }
  }

  componentWillUnmount() {
    this.setState({showFullDropScreen:false})
  }

  findAddress(d) {
    return d.load && d.load.driverOrderId;
  }

  sortTable(sort) {
    this.setState({
      sort,
      asce: this.state.sort !== sort ? true : !this.state.asce,
    });
  }

  _findValue = (key, value, label, chassisOwnerValue) => {
    let data = this.state[key].find((d) => d._id === value);
    if(!data && chassisOwnerValue) {
      data = chassisOwnerValue
    }
    if (data) return { label: data[label] || data.name, value: data["_id"] };
    return;
  };

  getChassis() {
    let data = {
      isIncludeDeleted: true,
      isGenerateSignUrl:true
    };
    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      data.terminals = JSON.stringify(
        this.state.choosenTerminals.map((J) => {
          return J.value;
        })
      );
    }
    this.setState({isLoadingBarShow: true})
    action.getTMSChassis(data).then((res) => {
      let data = res.data.data;
      let { Available, Dispatched, Loaded, Empty } = this.state;
      Available = data.filter(
        (obj) =>
          !obj.load ||
          (obj.load &&
            [
              "PENDING",
              "AVAILABLE",
              "DEPARTED",
              "COMPLETED",
              "APPROVED",
              "BILLING",
            ].indexOf(obj.load.status) > -1)
      );
      Dispatched = data.filter(
        (obj) =>
          obj.load &&
          [
            "DISPATCHED",
            "CHASSISPICK_ARRIVED",
            "CHASSISPICK_DEPARTED",
            "PULLCONTAINER_ARRIVED",
            "PULLCONTAINER_DEPARTED",
            "DROPCONTAINER_ARRIVED",
            "DELIVERLOAD_ARRIVED",
            "DELIVERLOAD_DEPARTED",
            "RETURNCONTAINER_ARRIVED",
            "RETURNCONTAINER_DEPARTED",
            "CHASSISTERMINATION_ARRIVED",
            "CHASSISTERMINATION_DEPARTED",
          ].indexOf(obj.load.status) > -1
      );
      Loaded = data.filter(
        (obj) =>
          obj.load &&
          isEmptyOrLoaded(obj.load)?.status === "loaded" &&
          [
            "DROPCONTAINER_DEPARTED",
            "HOOKCONTAINER_ARRIVED",
            "HOOKCONTAINER_DEPARTED",
          ].indexOf(obj.load.status) > -1
      );
      Empty = data.filter(
        (obj) =>
          obj.load &&
          isEmptyOrLoaded(obj.load)?.status === "empty" &&
          [
            "DROPCONTAINER_DEPARTED",
            "HOOKCONTAINER_ARRIVED",
            "HOOKCONTAINER_DEPARTED",
          ].indexOf(obj.load.status) > -1
      );
      const documents = data.find((obj) => obj._id == this.state.chassis_id);
      this.setState({
        chassis: res.data.data,
        Available,
        Dispatched,
        Loaded,
        Empty,
        isLoadingBarShow: false,
        documents: documents && documents.documents,
      });
    });
  }

  removeChassis(chassis) {
    if (!chassis._id) {
      toastr.show("Please make a selection.", "warning");
      return;
    }
    const formData = new FormData();
    if (this.state.chassis) formData.append("_id", chassis._id);
    formData.append("isDeleted", !chassis.isDeleted);
    confirm(
      `${chassis?.isDeleted ? "Enable Chassis?" : "Disable Chassis?" }`,
      `Are you sure you want to ${chassis.isDeleted ? "enable" : "disable"} this chassis?`,
      (confirm) => {
        if (confirm) {
          action.removeChassis(formData).then((e) => {
            this.resetform();
            this.setState({isLoadingBarShow : true})
            this.getChassis();
            this.props.actions.getTMSChassis();
            toastr.show(
              `Successfully ${chassis.isDeleted ? "Enabled" : "Disabled"}`,
              "success"
            );
          });
        }
      }
    );
  }

  // Validate form
  insertdata = (isSubmit = true) => {
    this.setState({isSavingChassisDoc: true})
    var errors = {};
    var touched = { ...this.state.formsTouched };
    schemaValidatorObject
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({ formErrors: {}, formsTouched: {} });
        isSubmit && this.insertdataa();
      })
      .catch((err) => {
        err &&
          err.inner &&
          err.inner.forEach((e, i) => {
            if (isSubmit && i + 1 === err.inner.length) {
              toastr.show(e.message, "error");
            }
            errors[e.path] = e.message;
            if (isSubmit) touched[e.path] = true;
          });
          this.setState({ formErrors: errors, formsTouched: touched, isSavingChassisDoc: false }, () => {
          isSubmit && toastr.show("Please enter all required fields.", "warning");
        });
      });
  };
  // Set State For Val
  setValChange = (key, val) => {
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    this.setState({ [key]: val, formsTouched }, () => {
      this.insertdata(false);
    });
  };

  insertdataa = () => {
    if (
      this.state.isTerminal &&
      (!this.state.newTerminal || this.state.newTerminal.length == 0)
    ) {
      toastr.show("Please choose a terminal.", "error");
      return;
    }

    const formData = new FormData();
    if (this.state.documentFile && !this.state.fileType) {
      toastr.show("You must choose a type.", "warning");
      return;
    }
    if (this.state.chassis_id) formData.append("_id", this.state.chassis_id);
    formData.append("chassisNo", this.state.chassisNo);
    formData.append("chassisType", this.state.Chassis_Type);
    formData.append("chassisSize", this.state.Chassis_Size);
    formData.append("chassisOwner", this.state.Chassis_Owner);
    formData.append("year", this.state.year);
    formData.append("make", this.state.make);
    formData.append("model", this.state.model);
    formData.append("AID", this.state.AID ? moment(this.state.AID).toISOString() : "");
    formData.append("ITD", this.state.ITD ? moment(this.state.ITD).toISOString() : "");
    if (this.state.terminal) {
      formData.append("terminal", this.state.terminal);
    }
    if (this.state.newTerminal && this.state.newTerminal.length > 0) {
      let newTerminal = this.state.newTerminal.map((Data) => {
        return Data.value;
      });
      formData.append("newTerminal", JSON.stringify(newTerminal));
    }
    if (this.state.licenceState) {
      formData.append("licenceState", this.state.licenceState);
    }
    if (this.state.licenceNumber) {
      formData.append("licenceNumber", this.state.licenceNumber);
    }
    formData.append("vin", this.state.vin);
    formData.append(
      "registration",
      this.state.registration ? moment(this.state.registration).toISOString() : ""
    );
    formData.append(
      "inspection",
      this.state.inspection ? moment(this.state.inspection).toISOString() : ""
    );
    formData.append("insurance", this.state.insurance ? moment(this.state.insurance).toISOString() : "");
    formData.append("notes", this.state.notes);

    if (this.state.documentFile && this.state.fileType) {
      formData.append("document", this.state.documentFile);
      formData.append("fileType", Object.isObject(this.state.fileType ) ? this.state.fileType.value : this.state.fileType );
      formData.append("filename", `${new Date().getTime().toString()}`);
    }
    formData.append("gps", this.state.gps);
    formData.append(
      "preventativeMaintance",
      moment(this.state.preventativeMaintance).toISOString()
    );

    if(this.state.externalSystemID) {
      let externalSystemID = this.state.externalSystemID.trim()
      if(externalSystemID.length > 0) formData.append("externalSystemID", externalSystemID);
    }

    const nullOrUndefinedFeilds = [];

    for(const [key, value] of formData.entries()){
      if(/undefined|null/.test(value)) nullOrUndefinedFeilds.push(key);
    }

    nullOrUndefinedFeilds.forEach((d) => formData.set(d,  ""));

    this.setState({ isSaving: true });
    action.addChassis(formData).then((res) => {
      if (!this.props.isOnlyPopup) {
        this.getChassis();
      }
      if (this.props.isOnlyPopup) {
        this.props.falsyData();
      }

      if(this.state.externalSystemID) {
        let externalSystemID = this.state.externalSystemID.trim()
        if(externalSystemID.length > 0) this.setState({disabledExternalSystemID : true})
        else this.setState({disabledExternalSystemID : false})
      }

      if (!this.state.chassis_id) this.resetform();

      if (!this.state.chassis_id) {
        toastr.show("Your chassis has been added.", "success");
        this.setState({ AddChassisModal: false });

        const chassisData = res?.data?.data;
        this.connectWithEldProfile(chassisData, this.state.selectedEldChassisProfile);
      } else if (this.state.chassis_id && this.state.tab === 2) {
        toastr.show("This document has been uploaded.", "success");
      } else {
        toastr.show("The chassis has been updated.", "success");
        this.setState({ ChassisLoad: false });
      }
      this.setState({
        documentFile: null,
        imagePreviewUrl: null,
        fileType: null,
        [this.state.chassis_id]: false,
        isSaving: false
      });
      this.setState({ showUpDocumentUploadModal: false });
    })
    .finally(f => this.setState({isSavingChassisDoc: false})
    );
    eldService.redirectBack();
  };

  resetform() {
    if (this.state.chassis) {
      this.setState({externalSystemID : ""})
      this.state.chassis.map((e) => {
        this.setState({ [e._id]: false });
      });
      this.setState({
        ...blankdata,
        chassisHistory: [],
        audits: [],
        tab: 1,
        chassisHistoryPage: 1,
        chassisHistoryTotal: 1,
        formErrors: {},
        formsTouched: {},
      });
    }
  }

  // get ChassisHistory of given ChassisId
  getChassisHistories(id) {
    let timeZone = getStorage("timeZone");
    let payload = {
      limit: 20,
      skip: (this.state.chassisHistoryPage - 1) * 50,
      chassisId: id,
      timeZone: timeZone,
      // sortBy:
    };
    getChassisHistory(payload).then((response) => {
      this.setState({
        chassisHistory: response.data.data.results,
        chassisHistoryTotal: response.data.data.count,
      });
    });
  }

  changeColor(id) {
    this.setState(
      { chassisHistory: [], audits: [], formErrors: {}, formsTouched: {} },
      () => {
        this.getChassisHistories(id);
        action
          .getChassisAudits({ chassisId: id, isGenerateSignUrl: true })
          .then((result) => this.setState({ audits: result }));
      }
    );

    if (this.state[id]) {
      this.setState({ [id]: false, ...blankdata });
    }
    this.state.chassis.map((e) => {
      this.setState({ [e._id]: false });
    });

    let data = _.find(this.state.chassis, function (s) {
      return s._id == id;
    });
    var selectedchassis = {
      _id: data._id,
      chassisNo: data.chassisNo,
      externalSystemID: data.externalSystemID || "",
      Chassis_Type: data.chassisType && data.chassisType._id,
      Chassis_Size: data.chassisSize && data.chassisSize._id,
      Chassis_Owner: data.chassisOwner && data.chassisOwner._id,
      _chassisOwner: data.chassisOwner,
      year: data.year,
      make: data.make,
      model: data.model,
      AID: data.AID ? new Date(data.AID) : "",
      ITD: data.ITD ? new Date(data.ITD) : "",
      terminal: data.terminal ? data.terminal._id : "",
      licenceState: data.licenceState,
      licenceNumber: data.licenceNumber,
      vin: data.vin,
      registration: data.registration,
      inspection: data.inspection,
      insurance: data.insurance,
      newTerminal:
        data.newTerminal && data.newTerminal.length > 0 ? data.newTerminal : [],
      notes: data.notes,
      gps: data.gps,
      preventativeMaintance: data.preventativeMaintance,
      fileType: data.documents
        ? data.documents[0]
          ? data.documents[0].type
          : null
        : null,
    };
    if (selectedchassis.newTerminal && selectedchassis.newTerminal.length > 0) {
      let newTerminal = [];
      let selectedChassisTerminal = [];
      if (selectedchassis.newTerminal.every(item => typeof item === 'string')) {
        selectedChassisTerminal = selectedchassis.newTerminal;
      } else {
        selectedChassisTerminal = selectedchassis.newTerminal.map(terminal => terminal._id);
      }

      this.state.terminals.forEach((item, i) => {
        if (selectedChassisTerminal?.indexOf(item._id) > -1) {
          newTerminal.push({
            value: item._id,
            label: item.name,
          });
        }
      });
      selectedchassis.newTerminal = newTerminal;
    }
    if(data.externalSystemID) {
      let externalSystemID = data.externalSystemID.trim()
      if(externalSystemID.length > 0) this.setState({disabledExternalSystemID : true})
      else this.setState({disabledExternalSystemID : false})
    }
    else this.setState({disabledExternalSystemID : false})
    this.setState({
      [id]: true,
      ...selectedchassis,
      id: id,
      chassis_id: id,
      documents: data.documents ? data.documents : [],
      imagePreviewUrl: null,
      documentFile: null,
      pdfPreviewUrl: null,
      ChassisLoad: true,
    });
  }
  handleChassisLoad = () => {
    this.setState({ ChassisLoad: false,  isLoadTabActive: 1  });
  };
  setval = (name, value) => {
    this.setState({ [name]: value });
  };

  updateDocument = (e) => {
    this.setState({ fileType: e });
  };

  previewImage = (data) => {
    if (!data.url) {
      toastr.show("There is no document.", "warning");
      return;
    }
    let extension;
    let fileName = data.url;
    if (fileName) {
      extension = fileName.split(".").pop();
      if (extension == "pdf") {
        this.setState(
          { imagePreviewUrl: null, pdfPreviewUrl: data.url },
          () => {
            pdfjsLib.getDocument(fileName).then((pdf) => {
              pdf.getPage(1).then((page) => {
                var scale = 1.5;
                var viewport = page.getViewport(scale);
                // Prepare canvas using PDF page dimensions.
                var canvas = document.getElementById("the-canvas");
                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                // Render PDF page into canvas context.
                var renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };
                page.render(renderContext);
              });
            });
          }
        );
      } else {
        this.setState({ imagePreviewUrl: data.url, pdfPreviewUrl: null });
      }
    }
  };

  downloadDoc(doc) {
    $.ajax({
      url: doc.url,
      xhrFields: {
        responseType: "blob",
      },
      success: (blob) => {
        const extension = doc.url.split(".").pop();
        if (extension != "pdf") {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          link.download = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.click();
        } else {
          var blob = new Blob([blob], { type: "application/pdf" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName = `${doc.type}-${moment().toISOString()}.${extension}`;
          link.download = fileName;
          link.click();
        }
      },
    });
  }

  filterStatus(status) {
    let newStatus = this.state.selectedStatuses;
    if (
      this.state.selectedStatuses.find((obj) => {
        return obj === status;
      })
    ) {
      newStatus = newStatus.subtract(status);
    } else {
      newStatus.insert(status);
    }
    this.setState({ selectedStatuses: newStatus, subCardFilter: null });
  }

  searchInChassis(chassis) {
    const searchTerm = this.state.searchTerm?.toLowerCase()
    
    const matchesLicenseCombination = (lNumber, lState) => {
      if (lNumber || lState) {
        const combined = (lNumber || '') + (lState || '');
        return combined.toLowerCase().includes(searchTerm);
      }
      return false;
    };
    return chassis.filter((obj) => {
      return (
        (obj.chassisNo?.toString().toLowerCase().includes(searchTerm)) ||
        (obj.chassisOwner?.company_name?.toString().toLowerCase().includes(searchTerm)) ||
        (obj.chassisSize?.name?.toString().toLowerCase().includes(searchTerm)) ||
        (obj.chassisType?.name?.toString().toLowerCase().includes(searchTerm)) ||
        matchesLicenseCombination(obj.licenceNumber, obj.licenceState) ||
        (obj.AID?.toString().toLowerCase().includes(searchTerm)) ||
        (obj.ITD?.toString().toLowerCase().includes(searchTerm))
      );
    });
  } 

  mutateObject(chassis = []) {
    chassis.forEach((d) => {
      let completedStatus = d.load && d.load.loadCompletedAt;
      let enrouteToPickup =
        d.load &&
        d.load.driverOrder &&
        d.load.driverOrder.find(
          (e) => e.status === "PULLCONTAINER" && e.arrived
        );
      let totalAmountOfDays;

      if (enrouteToPickup) {
        totalAmountOfDays =
          moment(completedStatus).diff(
            moment(enrouteToPickup.arrived),
            "days"
          ) + 1;
      }

      if (d.load) {
        let lastCompletedStatus = this.findAddress(d);
        d.lastCompletedStatus = lastCompletedStatus;
        d.lastAddress =
          lastCompletedStatus &&
          lastCompletedStatus.address &&
          lastCompletedStatus.address.address;
      } else {
        d.lastAddress = d.lastDroppedStatus && d.lastDroppedStatus.address;
      }

      d.totalAmountOfDays = totalAmountOfDays;
      d.completedStatus = completedStatus;
    });

    return chassis;
  }

  _subFilter(key) {
    this.setState({
      subCardFilter: key === this.state.subCardFilter ? null : key,
    });
  }

  downloadTableAsCSVFn = (filename) => {
    const rows = document.querySelectorAll("table tr");
    downloadTableAsCSV(filename, rows);
  };

  uploadResult = (file, preview, type) => {
    if (
      (file.type.match("image/png")||file.type.match("image/jpg") ||file.type.match("image/jpeg")  || file.type.match("application.pdf")) ){
        this.setState({showUpDocumentUploadModal:true})
        if (file.type.indexOf('image') == -1) {
          type = file.type.split('/')[1];
        }
        setTimeout(() => {
          this.setState({ progress: 100 });
        }, 1000);
        this.setState({
          documentFile: null,
          [`${type}PreviewUrl`]: null,
          pdfPreviewUrl: null,
          [type]: null
        }, () => {
          this.setState({
            documentFile: file,
            [`${type}PreviewUrl`]: preview,
            [type]: preview
          });
        });

        if (type == 'pdf') {
          this.setState({ imagePreviewUrl: null })
          let base64result = preview.split(',')[1];
          var pdfData = atob(base64result);
          var loadingTask = pdfjsLib?.getDocument({ data: pdfData });
          loadingTask?.promise.then(function (pdf) {
            // Fetch the first page.
            pdf.getPage(1).then(function (page) {
              var scale = 1.5;
              var viewport = page.getViewport(scale);
              // Prepare canvas using PDF page dimensions.
              var canvas = document.getElementById('the-canvas');
              var context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              // Render PDF page into canvas context.
              var renderContext = {
                canvasContext: context,
                viewport: viewport
              };
              page.render(renderContext);
            });
          });
        }
    }else {
    toastr.show("Please enter only PDF and/or Image (JPG, PNG) file formats.", "error");
    return;
}
  }
  
  removeDocument = () => {
    this.setState({
      uploadFileDetails: "",
      progress: 0,
      imagePreviewUrl: null,
      pdfPreviewUrl: null,
      documentImage: true,
    });
    this.setState({ documentFile: null });
  };
  handleShowUploadModal = (event) => {
    this.setState({ showUploadModal: true });
  };
  handleHideUploadModal = (event) => {
    this.setState({ showUploadModal: false });
  };
  handleLoadTabActive = (i) => {
    this.setState({
      isLoadTabActive: this.state.isLoadTabActive === i ? null : i,
    });
  };
  fetchEldProfiles () {
    if (!this.state.isEldSettingsEnabled) return;
    eldService.fetchEldProfiles(ELD_ENTITIES.CHASSIS).then((resp) => {
      this.setState({
        eldProfilesData: resp.data
      })
    });
  }

  fetchCurrentEldConnection = async () => {
    try {
      const { data: eldConnectionsData } = await fetchEldConnections("connected").then((resp) => resp?.data);

      if (eldConnectionsData && eldConnectionsData.length > 0) {
        this.setState({
          eldConnections: eldConnectionsData
        })
      }
    } catch (e) {
      console.log("Error fetching eld connections:", e);
    }
  };


  handleEldConnectedProfile (chassis) {
    if (!this.state.isEldSettingsEnabled) return;
    const filteredEldProfile = this.state.eldProfilesData?.find(eachEldProfile => eachEldProfile.id == chassis?.eldIdentifier);
  
    this.setState({
      isEldConnected: chassis?.eldIdentifier ? true : false,
      selectedEldChassisProfile: filteredEldProfile,
    });
  }

  connectWithEldProfile (chassisData, selectedEldChassisProfile) {
    if (!this.state.isEldSettingsEnabled || (selectedEldChassisProfile && Object.keys(selectedEldChassisProfile).length === 0) || !chassisData?._id) return;
    eldService.connectEntityWithEldProfile(chassisData, ELD_ENTITIES.CHASSIS, selectedEldChassisProfile).then(() => {
      toastr.show(`${chassisData?.chassisNo} connected with ${selectedEldChassisProfile?.name} successfully`, "success");
      this.setState({
        isEldConnected: true,
        selectedEldChassisProfile,
      })
      this.getChassis();
    })
    .catch((error) => {
      toastr.show(`${chassisData?.chassisNo} and ${selectedEldChassisProfile?.name} : ${error?.data?.message}`, "warning");
      this.setState({
        isEldConnected: false
      })
    });
  }

  render() {
    const {
      filterChassisTypes,
      subCardFilter,
      formErrors,
      formsTouched,
      isEnableChassis,
      isDisableChassis,
      choosenTerminals
    } = this.state;
    var tableGetHeight = this.state.getOffset;
    let timeZone = getStorage("timeZone");
    let documents = _.filter(this.state.documents, (i) => i.checked);

    let { selectedStatuses } = this.state;
    let chassis = selectedStatuses.length === 0 ? this.state.chassis : [];
    if (!this.state.chassis) {
      chassis = [];
    }

    selectedStatuses.length > 0 &&
      selectedStatuses.map(
        (key) => (chassis = chassis.concat(this.state[`${key}`]))
      );
    chassis = this.mutateObject([...chassis]);

    if (this.state.searchTerm && chassis) {
      chassis = this.searchInChassis(chassis);
    }

    let enableChassisCount = chassis.filter((d) => !d.isDeleted).length;
    let disableChassisCount = chassis.filter((d) => d.isDeleted).length;

    if (!isEnableChassis && isDisableChassis) {
      chassis = chassis.filter((d) => d.isDeleted);
    } else if (isEnableChassis && !isDisableChassis) {
      chassis = chassis.filter((d) => !d.isDeleted);
    }
    if (this.state.sort) {
      chassis = _.orderBy(chassis, this.state.sort, !this.state.asce && "desc");
    }

    const groupedChassis = _.groupBy(
      chassis,
      "lastCompletedStatus.address.address"
    );
    if (subCardFilter) chassis = groupedChassis[subCardFilter];

    if (chassis && chassis.length > 0) {
      if (
        getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1
      ) {
        let fleetTerminal = JSON.parse(getStorage("loggedInUser")).fleetManager
          .terminals;
        chassis = chassis?.filter((D) =>
          D.newTerminal?.filter((t) => fleetTerminal.indexOf(t) > -1)
        );
      }
    }

    let Terminal = [];
    if (this.state.terminals && this.state.terminals.length > 0) {
      Terminal = JSON.parse(JSON.stringify(this.state.terminals));
      Terminal = Terminal.map((Data) => {
        return {
          value: Data._id,
          label: Data.name,
        };
      });
    }

    return (
      <React.Fragment>
      <div className="position-relative subheader-wrapper">
        {!this.state.ChassisLoad ? (
          <React.Fragment>
            {!this.props.isQuickAdd && (
              <SubHeaderTab
                val="equipmentItems"
                buttons={
                  <React.Fragment>
                    {this.state.chassis && this.state.chassis.length > 0 && (
                      <span>
                        <button
                          type="button"
                          className="btn btn-outline-light mr-2"
                          onClick={() =>
                            this.downloadTableAsCSVFn("Chassis-Report")
                          }
                        >
                          <IconDownload className="mr-2" />
                          Download CSV
                        </button>
                      </span>
                    )}
                    <button
                      className="btn btn-success"
                      data-toggle="modal"
                      data-target="#addnewprofile"
                      onClick={() => {
                        this.resetform();
                        this.setState({
                          AddChassisModal: true,
                          newTerminal: null,
                          isEldConnected: false,
                          selectedEldChassisProfile: {},
                          _id: null,
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add New Chassis
                    </button>
                  </React.Fragment>
                }
              />
            )}
            {!this.props.isQuickAdd && (
              <div className="page-fluid page-fluid-block">
                <div className="top-page">
                  <div className="pb-15 d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <h4 className="font-20 mb-0">Chassis</h4>
                    </div>
                  </div>
                  {/* Cards Row */}
                  <div
                    className={`form-row mb-15 ${
                      this.state.selectedStatuses.length > 0 && "hasactive"
                    }`}
                  >
                    {this.allStatuses.map((status, i) => {
                      let statusName = status;
                      
                      return (
                        <div className="col-xl col-md-3 col-sm-6 col-6" key={i}>
                          <div
                            className={`ncard p-10 d-flex no-block align-items-center justify-content-between flex-row  ${
                              this.state.selectedStatuses.indexOf(statusName) >
                              -1
                                ? "active"
                                : ""
                            }`}
                            onClick={() => this.filterStatus(status)}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className={`mr-10 sbox sbox--default px-2 h-100 ${
                                  this.state.selectedStatuses.indexOf(
                                    statusName
                                  ) > -1 && "sbox--active"
                                }`}
                              >
                                {["Enabled", "Disabled"].includes(
                                  filterChassisTypes
                                )
                                  ? this.state[`${status}`].filter((d) =>
                                      filterChassisTypes === "Enabled"
                                        ? !d.isDeleted
                                        : d.isDeleted
                                    ).length
                                  : this.state[`${status}`].length || 0}
                              </div>
                              <div>{statusName}</div>
                            </div>
                            <div className="ml-2">
                              <div className="form-check">
                                <input
                                  checked={
                                    selectedStatuses.indexOf(statusName) > -1
                                  }
                                  type="checkbox"
                                  className="form-check-input"
                                />
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* Filter Row */}
                  <div className="filter-row d-flex align-items-center">
                    <div className="app-search header-search">
                      <span className="search-icon"> </span>
                      <div className="position-relative">
                        <input
                          type="search"
                          className="input-search-left form-control"
                          name=""
                          id="customerSearchInput"
                          placeholder="Search..."
                          value={this.state.searchTerm}
                          onChange={(e) => {
                            this.setState({
                              searchTerm: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="form-check form-check--gray pr-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Available"
                          checked={isEnableChassis}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({ isEnableChassis: true });
                              this.getChassis();
                            } else {
                              this.setState({ isEnableChassis: false });
                            }
                          }}
                        />
                        <label className="form-check-label" for="Available">
                          Enabled
                          <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                            {enableChassisCount}
                          </span>
                        </label>
                      </div>
                      <div className="form-check form-check--gray pr-30">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Pending"
                          checked={isDisableChassis}
                          onChange={(e) => {
                            if (e.target.checked) {
                              this.setState({ isDisableChassis: true });
                              this.getChassis();
                            } else {
                              this.setState({ isDisableChassis: false });
                            }
                          }}
                        />
                        <label className="form-check-label" for="Pending">
                          Disabled
                          <span className="badge badge-gray-100 font-12 ml-2 badge-pill">
                            {disableChassisCount}
                          </span>
                        </label>
                      </div>
                    </div>

                    {this.state.isTerminal && (
                      <div className="w-150 mr-2">
                        <Select
                          onChange={(choosenTerminals) =>
                            this.setState({ choosenTerminals }, () => {
                              this.getChassis();
                            })
                          }
                          value={choosenTerminals}
                          styles={smallSelectStyle}
                          isMulti
                          name="choosenTerminals"
                          options={Terminal}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    {/* <div className="ml-auto d-flex">
                      <div className="position-relative">
                        <button className="btn btn-outline-light position-relative">
                          <IconColumn />
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* Table */}
                <div
                  className="table-responsive flex-grow-1"
                >
                  {this.state.isLoadingBarShow && <LoaderBar></LoaderBar>}
                  <table className="table table-card table-card--ls">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        {[
                          LOAD_LABELS.CHASSIS_NUMBER,
                          LOAD_LABELS.LOAD_NUMBER,
                          "Load","Status",
                          "Address",
                          LOAD_LABELS.CHASSIS_OWNER,
                          LOAD_LABELS.CHASSIS_SIZE,
                          LOAD_LABELS.CHASSIS_TYPE,
                          "License Number",
                          // "License State",
                          // "License Plate #",
                          "VIN",
                          "Registration",
                          "Inspection",
                          "Insurance",
                        ].map((thStr) => {

                          const hiddenField=["Load","Status"].includes(thStr)
                          const removeField=["Load #"].includes(thStr)
                          return (
                            <th
                              key={thStr}
                              scope="col"
                              onClick={() =>
                                sortingOptions.hasOwnProperty(thStr) &&
                                this.sortTable(sortingOptions[thStr])
                              }
                              className={`${!removeField && 'csv'} ${
                                hiddenField && "d-none"}
                                ${sortingOptions.hasOwnProperty(thStr) &&
                                  (this.state.sort == undefined ||
                                    this.state.sort !== sortingOptions[thStr]) ? (
                                    'table-sort table-sort--default'
                                  ) : sortingOptions.hasOwnProperty(thStr) ? (
                                    this.state.sort === sortingOptions[thStr] &&
                                    this.state.asce == true ? (
                                      'table-sort table-sort--ascending'
                                    ) : (
                                      'table-sort table-sort--descending'
                                    )
                                  ) : (
                                    ""
                                  )}
                                `}
                            >
                              {thStr}
                              
                            </th>
                          );
                        })}

                        {this.state.isTerminal && (
                          <th
                            onClick={() =>
                              sortingOptions.hasOwnProperty("TERMINAL") &&
                              this.sortTable(sortingOptions["TERMINAL"])
                            }
                            className={`csv ${sortingOptions.hasOwnProperty("TERMINAL") &&
                            (this.state.sort == undefined ||
                              this.state.sort !==
                                sortingOptions["TERMINAL"]) ? (
                                'table-sort table-sort--default'
                            ) : sortingOptions.hasOwnProperty("TERMINAL") ? (
                              this.state.sort === sortingOptions["TERMINAL"] &&
                              this.state.asce == true ? (
                                'table-sort table-sort--ascending'
                              ) : (
                                'table-sort table-sort--descending'
                              )
                            ) : (
                              ""
                            )}` }
                          >
                            {updateTerminology('terminal')}
                          </th>
                        )}
                        {this.state.isEldSettingsEnabled &&
                          <th scope="col">
                            ELD
                          </th>
                        }
                        
                      </tr>
                    </thead>
                    <tbody>
                      {chassis &&
                        chassis.map((d) => {
                          return (
                            <tr
                              key={d._id}
                              className={`${
                                d.isDeleted ? "text-danger" : null
                              }`}
                            >
                              <td className="text-center">
                                {!d.isDeleted && (
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      this.changeColor(d._id);
                                      this.handleEldConnectedProfile(d, true);
                                    }}
                                  >
                                    <IconPen />
                                  </button>
                                )}
                              </td>
                              <td className="text-center">
                                {d.isDeleted ? (
                                  <button
                                    type="button"
                                    className="btn btn-link text-success"
                                    onClick={() => this.removeChassis(d)}
                                  >
                                    <IconCheckCircle />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => this.removeChassis(d)}
                                  >
                                    <IconTrash />
                                  </button>
                                )}
                              </td>
                              <td className="text-center" data-tip data-for={d._id}>   
                                <button
                                  type="button"
                                  className="btn btn-link"
                                        
                                >
                                  <IconNotes/>
                                </button>
                                {d.notes && (
                                  <ReactTooltip id={d._id}  className="type-gray w-300 text-wrap text-break line-break" >
                                    <span>{d.notes}</span>
                                  </ReactTooltip>
                                )}
                              </td>
                              <td className="csv">{d.chassisNo}</td>
                              <td>
                                {d.load ? (
                                  <>
                                  <span
                                  className="pointer text-primary"
                                  onClick={()=>this.setState({reference_number: d.load.reference_number, showLoadModel:true})}
                                  >
                                    {" "}
                                    {`${
                                      d.load.reference_number
                                    } (${convertTMSStatusName(
                                      d.load.type_of_load,
                                      d.load.status,
                                      d.load
                                    )})`}{" "}
                                  </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>

                              <td className="csv d-none">
                                {d.load && d.load.reference_number}
                              </td>
                              <td className="csv d-none">
                                {d.load && d.load.status}
                              </td>

                              <td className="csv">
                                {d.load
                                  ?  defaultAddressFormat() === addressFormatEnum.EU? splitStreetNameNumber(d?.lastAddress) :d?.lastAddress
                                  : d.lastAddress
                                  ? d.lastAddress + " (Last Location)"
                                  : ""}
                              </td>
                              <td className="csv">
                                {d.chassisOwner && d.chassisOwner.company_name}
                              </td>
                              <td className="csv">
                                {d.chassisSize && d.chassisSize.name}
                              </td>
                              <td className="csv">
                                {d.chassisType && d.chassisType.name}
                              </td>
                              <td className="csv">
                                {d.licenceNumber}
                                <br />
                                {d.licenceState}
                              </td>
                              {/* <td className="csv">{d.licenceNumber}</td> */}
                              <td className="csv">{d.vin}</td>
                              <td className="csv p-0">
                                <span
                                  className={`h-100 d-flex align-items-center justify-content-center px-1 ${
                                    d.registration &&
                                    moment(d.registration).diff(
                                      moment(),
                                      "days"
                                    ) <= 0
                                      ? "bg-warning-50"
                                      : ""
                                  } `}
                                >
                                  {d.registration && d.registration.length === 7
                                    ? moment(d.registration, "MM/YYYY").tz(getTimeZone())
                                        .endOf("month")
                                        .format("MM/DD/YYYY")
                                    : d.registration &&
                                      d.registration.length > 7
                                    ? moment(d.registration).tz(getTimeZone()).format(
                                        "MM/DD/YYYY"
                                      )
                                    : ""}
                                </span>
                              </td>
                              <td className="csv p-0">
                                <span
                                  className={`h-100 d-flex align-items-center justify-content-center px-1 ${
                                    d.inspection &&
                                    moment(d.inspection).diff(
                                      moment(),
                                      "days"
                                    ) <= 0
                                      ? "bg-warning-50 "
                                      : ""
                                  } `}
                                >
                                  {d.inspection
                                    ? moment(d.inspection).tz(getTimeZone()).format("MM/DD/YYYY")
                                    : ""}
                                </span>
                              </td>
                              <td className="csv p-0">
                                <span
                                  className={`h-100 d-flex align-items-center justify-content-center px-1 ${
                                    d.insurance &&
                                    moment(d.insurance).diff(
                                      moment(),
                                      "days"
                                    ) <= 0
                                      ? "bg-warning-50"
                                      : ""
                                  } `}
                                >
                                  {d.insurance
                                    ? moment(d.insurance).tz(getTimeZone()).format("MM/DD/YYYY")
                                    : ""}
                                </span>
                              </td>
                              {this.state.isTerminal && (
                                <td className={`hideextra csv`}>
                                  {d.newTerminal &&
                                  d.newTerminal.length > 0 &&
                                  this.state.terminals &&
                                  this.state.terminals.length > 0
                                    ? d.newTerminal.map((ids, index) => {
                                        const terminalId = typeof ids === 'object' && ids._id ? ids._id : ids;
                                        let thatTerminal =
                                          this.state.terminals.find(
                                            (D) => D._id == terminalId
                                          );
                                        return (
                                          <div key={index}>
                                            <span>
                                              {thatTerminal
                                                ? thatTerminal.name
                                                : ""}
                                            </span>
                                          </div>
                                        );
                                      })
                                    : ""}
                                </td>
                              )}
                              {this.state.isEldSettingsEnabled && 
                                  <td>
                                    {d.eldIdentifier &&
                                      <span className="badge badge-sm badge-brand-50">Connected</span>
                                    }
                                  </td>
                                }
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {this.state.AddChassisModal &&
            <Modal
              show={this.state.AddChassisModal}
              centered
              dialogClassName="modal-fullpage modal-dialog-scrollable"
            >
              <Modal.Header className="bg-gray-50 py-20">
                <Modal.Title>Add New Chassis</Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-gray-50 pt-0 pb-0">
                <div className="form-row-md fcpopup flex-grow-1 bs-100">
                  {this.state.isEldSettingsEnabled &&
                    <div className={`col-sm fcpopup__left overflow-auto mh-100 pb-10 ${!this.state.isEldSettingsEnabled ? "mw-0 p-0" : ""}`}>
                        <EldConnectionCard
                          entityType={ELD_ENTITIES.CHASSIS}
                          entityID={this.state._id}
                          selectedEldProfile={this.state.selectedEldChassisProfile}
                          isEldConnectionSuccess={this.state.isEldConnected}
                          handleConnectEldFromProfile={() => this.setState({ isConnectEld: true })}
                          onDisconnect={() => this.getChassis()}
                        />
                    </div>
                  }
                  <div className={`col-sm fcpopup__right d-flex flex-column bs-100 overflow-y-auto pb-20 ${!this.state.isEldSettingsEnabled ? "w-100" : "" }`}>
                    <div className="form-wrapper bg-white rounded-5 p-20">
                      <div className="form-wrapper bg-white rounded-5 p-20">
                        <form onSubmit={(e) => e.preventDefault()}>
                          <div className="font-14 font-medium mb-15">
                            Equipment
                          </div>
                          <div className="form-row">
                            <StateFormSelect
                              formErrors={formErrors}
                              formsTouched={formsTouched}
                              className="col-md-3"
                              label={LOAD_LABELS.CHASSIS_OWNER}
                              isRequired={true}
                              name="Chassis_Owner"
                              options={this.state.TMSChassisOwner.map((co) => ({
                                value: co._id,
                                label: co.company_name,
                              }))}
                              value={
                                this.state.Chassis_Owner &&
                                this._findValue(
                                  "TMSChassisOwner",
                                  this.state.Chassis_Owner,
                                  "company_name"
                                )
                              }
                              placeholder="Select Owner"
                              onValChange={this.setValChange}
                              isClearable={true}
                              isMulti={false}
                            />
                            <StateFormInput
                              className="col-md-3"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label={LOAD_LABELS.CHASSIS_NUMBER}
                              name="chassisNo"
                              isRequired={true}
                              value={this.state.chassisNo}
                              onValChange={this.setValChange}
                              placeholder="Enter Chassis Number.."
                              type="text"
                            />
                            <StateFormSelect
                              formErrors={formErrors}
                              formsTouched={formsTouched}
                              className="col-md-3"
                              label={LOAD_LABELS.CHASSIS_TYPE}
                              isRequired={true}
                              name="Chassis_Type"
                              options={this.state.ChassisType.map((ct) => ({
                                value: ct._id,
                                label: ct.name,
                              }))}
                              value={
                                this.state.Chassis_Type &&
                                this._findValue(
                                  "ChassisType",
                                  this.state.Chassis_Type,
                                  "name"
                                )
                              }
                              placeholder="Select Type"
                              onValChange={this.setValChange}
                              isClearable={true}
                              isMulti={false}
                            />
                            <StateFormSelect
                              formErrors={formErrors}
                              formsTouched={formsTouched}
                              className="col-md-3"
                              label={LOAD_LABELS.CHASSIS_SIZE}
                              isRequired={true}
                              name="Chassis_Size"
                              options={this.state.ChassisSize.map((cs) => ({
                                value: cs._id,
                                label: cs.name,
                              }))}
                              value={
                                this.state.Chassis_Size &&
                                this._findValue(
                                  "ChassisSize",
                                  this.state.Chassis_Size,
                                  "name"
                                )
                              }
                              placeholder="Select Size"
                              onValChange={this.setValChange}
                              isClearable={true}
                              isMulti={false}
                            />
                          </div>
                            {/* new added */}
                            {/* <StateFormInput
                              className="col-md-4"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={false}
                              label="Year"
                              name="year"
                              value={this.state.year}
                              onValChange={this.setValChange}
                              placeholder="Select Year"
                              type="number"
                            /> */}
                            <div className="form-row">
                            <StateFormSelect
                              className="col-lg"
                              formErrors={formErrors}
                              formsTouched={formsTouched}
                              styles={smallSelectStyle}
                              name="year"
                              options={yearOptions}
                              label="Year"
                              value={
                              this.state.year && yearOptions.find(el => el?.label === this.state.year)}
                              placeholder="Select Year"
                              onValChange={this.setValChange}
                              isClearable={true}
                            />
                            <StateFormInput
                              className="col-lg"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={false}
                              label="Model"
                              name="model"
                              value={this.state.model}
                              onValChange={this.setValChange}
                              placeholder="Enter Model"
                              type="text"
                            />
                            <StateFormInput
                              className="col-lg"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={true}
                              label="License State"
                              name="licenceState"
                              value={
                                this.state.licenceState ? this.state.licenceState : ""
                              }
                              onValChange={this.setValChange}
                              placeholder="Enter License State"
                              type="text"
                            />
                            <StateFormInput
                              className="col-lg"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={true}
                              label="License Number"
                              name="licenceNumber"
                              value={
                                this.state.licenceNumber
                                  ? this.state.licenceNumber
                                  : ""
                              }
                              onValChange={this.setValChange}
                              placeholder="Enter License Number"
                              type="text"
                            />

                            <StateFormInput
                              className="col-lg"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={false}
                              label="VIN"
                              name="vin"
                              value={this.state.vin}
                              onValChange={this.setValChange}
                              placeholder="Enter VIN"
                              type="text"
                            />
                            <StateFormInput
                              className="col-lg"
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              isRequired={false}
                              label="GPS #"
                              name="gps"
                              value={this.state.gps}
                              onValChange={this.setValChange}
                              placeholder="Enter GPS"
                              type="text"
                            />
                          </div>
                          <div className="hr-light mb-20 mt-10"></div>
                          <div className="form-row">
                            <div className="col-12">
                              <div className="font-14 font-medium mb-15">
                                Expiration Dates
                              </div>
                            </div>
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="Inspection"
                              name="inspection"
                              value={
                                this.state.inspection
                                  ? moment(this.state.inspection)
                                  : ""
                              }
                              inputClassName = "left"
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="Insurance"
                              name="insurance"
                              value={
                                this.state.insurance
                                  ? moment(this.state.insurance)
                                  : ""
                              }
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="Registration"
                              name="registration"
                              value={
                                this.state.registration &&
                                this.state.registration.length === 7
                                  ? moment(this.state.registration, "MM/YYYY")
                                      .endOf("month")
                                      .format("MM/DD/YYYY")
                                  : this.state.registration &&
                                    this.state.registration.length > 7
                                  ? moment(this.state.registration).format(
                                      "MM/DD/YYYY"
                                    )
                                  : ""
                              }
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />                      
                            
                            {this.state.isTerminal && (
                              <StateFormSelect
                                formErrors={formErrors}
                                formsTouched={formsTouched}
                                className="col-md-3"
                                label={updateTerminology("Terminal")}
                                isRequired={true}
                                name="newTerminal"
                                options={Terminal}
                                value={this.state.newTerminal}
                                placeholder={`Select ${updateTerminology("Terminal")}`}
                                onValChange={this.setValChange}
                                isClearable={true}
                                isMulti={true}
                              />
                            )}
                    
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="Preventative Maintenance"
                              name="preventativeMaintance"
                              value={
                                this.state.preventativeMaintance
                                  ? moment(this.state.preventativeMaintance)
                                  : ""
                              }
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />
                          
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="AID"
                              name="AID"
                              inputClassName = "left"
                              value={this.state.AID ? moment(this.state.AID) : ""}
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />
                            <StateFormDateTime
                              formsTouched={formsTouched}
                              formErrors={formErrors}
                              label="ITD"
                              name="ITD"
                              value={this.state.ITD ? moment(this.state.ITD) : ""}
                              onValChange={this.setValChange}
                              isRequired={false}
                              className="col-md-3"
                            />
                          </div>
                          <div className="hr-light mt-10 mb-20"></div>
                          {/* Upload Document */}
                          <div className="form-group">
                            <label className="col-form-label">Upload Document</label>
                            <ImageUploader
                              uploadResult={this.uploadResult}
                              multi={false}
                              type={"image"}
                              className="custom-file-input "
                            />
                            {this.state.documentFile && (
                              <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                                <IconUpload className="mr-20" />
                                <div className="w-100">
                                  <div className="font-medium d-flex justify-content-between w-100 mb-1">
                                    <div>{this.state.documentFile.name}</div>
                                    <div className="ml-auto">{this.state.progress}%</div>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar bg-primary"
                                      role="progressbar"
                                      aria-valuenow={`${this.state.progress}`}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      style={{ width: `${this.state.progress}%` }}
                                    ></div>
                                  </div>
                                </div>
                                <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                                  <span
                                    onClick={() =>
                                      this.setState({
                                        documentFile: null,
                                        imagePreviewUrl: null,
                                        pdfPreviewUrl: null,
                                        progress: 0,
                                      })
                                    }
                                  >
                                    {" "}
                                    <IconTimes className="" />
                                  </span>
                                </button>
                              </div>
                            )}
                              {this.state.documentFile  && (
                                  <div className="dropzone-fileupload d-flex">
                                    <button className="btn btn-warning btn-circle btn-xs mr-15">
                                    <IconCheck />
                                    </button>
                                    <div className="document-preview document-preview--sm mr-3">
                                    {this.state.imagePreviewUrl && (
                                      <img src={this.state.imagePreviewUrl} alt={""} />
                                    )}
                                    {this.state.pdfPreviewUrl && (
                                      <canvas id="the-canvas"></canvas>
                                    )}
                                    
                                  </div>

                                  <div className="w-100 d-flex">
                                    <div>
                                      <div className="font-medium ">
                                        {this.state.documentFile.name}
                                      </div>
                                      <div className="text-muted">
                                        {(
                                          this.state.documentFile.size *
                                          0.0009765625
                                        ).toFixed(1)}{" "}
                                        kb
                                      </div>
                                    </div>
                                    <div className="w-150 ml-auto">
                                      <Select
                                        styles={smallSelectStyle}
                                      options={this.state.documentTypes}
                                        value={
                                          this.state.fileType &&
                                          this.state.documentTypes.find(
                                            (d) => d.value === this.state.fileType
                                          )
                                        }
                                        onChange={({ value }) =>
                                          this.setState({ fileType: value })
                                        }
                                      />
                                    </div>
                                  </div>
                                  <button className="btn btn-link btn-circle btn-xs ml-3">
                                    <span onClick={() => this.removeDocument()}>
                                      {" "}
                                      <IconTrash />{" "}
                                    </span>
                                  </button>
                                </div>
                              )}
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-close"
                  onClick={() => {
                    this.setState({ AddChassisModal: false });
                    this.setState({showUpDocumentUploadModal:false});
                    if (this.props.isQuickAdd) {
                      this.props.falsyData("chassisModal");
                    }
                    this.removeDocument()
                    eldService.redirectBack();
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`btn ${
                    this.state.chassis_id ? "btn-primary" : "btn-success"
                  }`}
                  onClick={() => {
                    this.insertdata();
                  }}
                  disabled={this.state.isSaving}
                >
                  {this.state.isSaving && (
                    <CellSpinner className="mr-2" />
                  )}
                  Add New Chassis
                </button>
              </Modal.Footer>
            </Modal>
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            {this.state.ChassisLoad &&
            <Modal 
              show={this.state.ChassisLoad}
              dialogClassName="modal-fullpage modal-dialog-scrollable"
            >
              <Modal.Header className="py-20 bg-gray-50">
                <Modal.Title>
                  {this.state.chassisNo}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="bg-gray-50 py-0 modal-body">
              <div className="form-row-md fcpopup h-100">
                <div className="col-sm fcpopup__left mh-100" ref="inner">
                  <div className="card p-15 mb-1">
                    <h5>About</h5>
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">{LOAD_LABELS.CHASSIS_NUMBER}</span>
                      <span>{this.state.chassisNo}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">{LOAD_LABELS.CHASSIS_TYPE}</span>
                      <span>
                        {this.state.Chassis_Type &&
                          this._findValue(
                            "ChassisType",
                            this.state.Chassis_Type,
                            "name"
                          )?.label}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">{LOAD_LABELS.CHASSIS_SIZE}</span>
                      <span>
                        {this.state.Chassis_Size &&
                          this._findValue(
                            "ChassisSize",
                            this.state.Chassis_Size,
                            "company_name"
                          )?.label}
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <span className="text-muted">{LOAD_LABELS.CHASSIS_OWNER}</span>
                      <span>
                        <div>
                          {this.state.Chassis_Owner &&
                            this._findValue(
                              "TMSChassisOwner",
                              this.state.Chassis_Owner,
                              "company_name",
                              this.state._chassisOwner
                            ) &&
                            this._findValue(
                              "TMSChassisOwner",
                              this.state.Chassis_Owner,
                              "company_name",
                              this.state._chassisOwner
                            )?.label}
                        </div>
                      </span>
                    </div>
                  </div>
                  {this.state.isEldSettingsEnabled &&
                    <EldConnectionCard
                      entityType={ELD_ENTITIES.CHASSIS}
                      entityID={this.state._id}
                      selectedEldProfile={this.state.selectedEldChassisProfile}
                      isEldConnectionSuccess={this.state.isEldConnected}
                      handleConnectEldFromProfile={() => this.setState({ isConnectEld: true })}
                      onDisconnect={() => this.getChassis()}
                    />
                  }
                </div>
                <div className="col-sm fcpopup__right h-100 d-flex flex-column" ref="inner">
                  <div className="card mb-0">
                    <ul className="nav nav-tabs nav-tabs-custom nav-justified nav-border-bottom">
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 1 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(1)}
                        >
                          <IconLoadCartRight />
                          <div className="nav-link__text">Chassis</div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 2 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(2)}
                        >
                          <IconDocument />
                          <div className="nav-link__text">Documents</div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 3 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(3)}
                        >
                          <IconClock9 />
                          <div className="nav-link__text">History</div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 4 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(4)}
                        >
                          <IconSearchData />
                          <div className="nav-link__text">Audit</div>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.state.isLoadTabActive === 5 ? "active" : ""
                          }`}
                          href="javascript:void(0)"
                          onClick={() => this.handleLoadTabActive(5)}
                        >
                          <IconNotes />
                          <div className="nav-link__text">Notes</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-loads flex-grow-1">
                    {this.state.isLoadTabActive === 1 && (
                      <TabChassis
                        chassisDetail={{ ...this.state }}
                        setValChange={this.setValChange}
                        setVal={this.setval}
                      />
                    )}
                    {this.state.isLoadTabActive === 2 && (
                      <div className="tab" id="tab-chassis">
                        <div className="card p-20 mb-10 d-flex flex-row align-items-center justify-content-between">
                          <h4 className="mb-0 font-20">
                            {documents && documents.length} Documents{" "}
                          </h4>
                          <button
                            className="ml-auto btn btn-primary"
                            onClick={this.handleshowUpDocumentUploadModal}
                          >
                            <IconUpload className="mr-2" />
                            Upload Document
                          </button>
                        </div>
                        <div style={{ overflowY: 'auto', height: 'calc(100vh - 287px)', overflowX: 'hidden' }}>
                        {!this.props.type &&
                          getStorage("currentUserRole") != "customer" && (
                            <UploadedDocuments
                              listDocuments={documents}
                              deleteDocument={(id) =>
                                this.deleteEquipmentDoc({
                                  docId: id,
                                  _id: this.state._id,
                                })
                              }
                              onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                              newView={this.newView}
                              selectedDoc={this.state.selectedDoc}
                              setSelectedDoc={(docIndex) => {
                                this.setState({ selectedDoc: docIndex });
                              }}
                              handleShowUploadModal={this.handleShowUploadModal}
                              uploadResult={this.uploadResult}
                              multi={false} 
                              type={"image"}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.isLoadTabActive === 3 && (
                      <TabHistory chassisHistory={this.state.chassisHistory} />
                    )}
                    {this.state.isLoadTabActive === 4 && (
                      <TabAudit audits={this.state.audits} eldProfilesData={this.state.eldProfilesData} />
                    )}
                    {this.state.isLoadTabActive === 5 && (
                      <TabNotes notes={this.state.notes}  setVal={this.setval} />
                    )}
                  </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <button 
                  className="btn btn-close"
                  onClick={() => {
                    this.handleChassisLoad();
                    if (this.props.isQuickAdd) {
                      this.props.falsyData("chassisModal");
                    }
                    eldService.redirectBack();
                  }}
                >
                  Cancel
                </button>
                <button 
                  className="btn btn-success"
                  onClick={() => {
                    this.insertdata();
                  }}
                >
                  Save Changes
                </button>
              </Modal.Footer>

            </Modal>
            }

            <Modal
              show={this.state.showUpDocumentUploadModal}
              centered
              animation={false}
            >
              <Modal.Header>
                <Modal.Title>Upload Document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  {!this.props.type &&
                    getStorage("currentUserRole") != "customer" && (
                      <div>
                        <ImageUploader
                          uploadResult={this.uploadResult}
                          multi={false}
                          name="Choose from your device..."
                          type={"image"}
                          className="custom-file-input "
                        />
                        {this.state.documentFile && (
                          <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
                            <IconUpload className="mr-20" />
                            <div className="w-100">
                              <div className="font-medium d-flex justify-content-between w-100 mb-1">
                                <div>{this.state.documentFile.name}</div>
                                <div className="ml-auto">
                                  {this.state.progress}%
                                </div>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuenow={`${this.state.progress}`}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  style={{ width: `${this.state.progress}%` }}
                                ></div>
                              </div>
                            </div>
                            <button className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
                              <span onClick={() => this.removeDocument()}>
                                {" "}
                                <IconTimes className="" />
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  {/* {acceptedFileItems} */}

                  {/* After File is Uploaded sue the code block below */}
                  {this.state.documentFile && (
                    <div className="dropzone-fileupload d-flex">
                      <button className="btn btn-success btn-circle btn-xs mr-15">
                        <IconCheck />
                      </button>
                      {/* {this.state.documentFile === "image" && ( */}
                        <div className="document-preview document-preview--sm mr-3">
                          <div className="document-preview__image">
                            {this.state.imagePreviewUrl && (
                              <img
                                className="document-preview__image"
                                src={this.state.imagePreviewUrl}
                                alt={""}
                              />
                            )}
                            {this.state.pdfPreviewUrl && (
                              <canvas id="the-canvas"></canvas>
                            )}
                            {/* {this.state.pdfPreviewUrl && (
                              <div id="holder">
                                <div>
                                  <Document
                                    file={this.state.pdfPreviewUrl}
                                    onLoadSuccess={this.onDocumentLoadSuccess}
                                  >
                                    <Page pageNumber={1} />
                                  </Document>
                                </div>
                              </div>
                            )} */}
                          </div>
                        </div>
                      {/* )} */}
                      <div className="w-100 d-flex">
                        <div>
                          <div className="font-medium ">
                            {this.state.documentFile.name}
                          </div>
                          <div className="text-muted">
                            {(
                              this.state.documentFile.size * 0.0009765625
                            ).toFixed(1)}{" "}
                            kb
                          </div>
                        </div>
                        {!this.props.type &&
                          getStorage("currentUserRole") != "customer" && (
                            <div className="w-150 ml-auto">
                              <Select
                                options={this.state.documentTypes}
                                styles={smallSelectStyle}
                                value={this.state.fileType}
                                onChange={(e) => this.updateDocument(e)}
                              />
                            </div>
                          )}

                        {!this.props.type &&
                          getStorage("currentUserRole") != "customer" &&
                          this.state.activeTab === "subCustomer" && (
                            <div className="w-150 ml-auto">
                              <Select
                                options={this.state.documentTypes}
                                styles={smallSelectStyle}
                                value={this.state.fileType}
                                onChange={(e) => {
                                  this.setState({ fileType: e.target.value });
                                }}
                              />
                            </div>
                          )}
                      </div>
                      <button className="btn btn-link btn-circle btn-xs ml-3">
                        <span onClick={() => this.removeDocument()}>
                          {" "}
                          <IconTrash />{" "}
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-close"
                  onClick={this.handlehideUpDocumentUploadModal}
                >
                  Close
                </button>
                {this.state.isSavingChassisDoc && (
                    <CellSpinner className="mr-2" />
                  )}
                <button
                  className="btn btn-primary"
                  disabled={this.state.isSavingChassisDoc}
                  onClick={() => {
                    if (this.state.documentFile && this.state.fileType) {
                      this.insertdata();
                    } else {
                      toastr.show("Please select the type.", "error");
                    }
                  }}
                >
                  Save Changes
                </button>
              </Modal.Footer>
            </Modal>
            {this.state.showFullDropScreen && this.state.isLoadTabActive === 2 && !this.state.showUpDocumentUploadModal && 
              <FullScreenUploader  uploadResult={this.uploadResult} multi={false} type={"image"}/>
            }
          </React.Fragment>
        )}
      </div>
      {this.state.showLoadModel &&
          this.state.reference_number &&
          <LoadInfoModal
            refNumber={this.state.reference_number}
            hideLoadModal={() => this.setState({ reference_number: null, showLoadModel: false })}
          />
        }
      
      {this.state.isConnectEld &&
        <ConnectEldProvider
          entityType={ELD_ENTITIES.CHASSIS}
          eldConnections={this.state.eldConnections}
          show={this.state.isConnectEld}
          hide={() =>
            this.setState({
              isConnectEld: false,
            })
          }
          onConfirm={(selectedEldProfile) => {
            const _selectedChassisEldProfile = {
              id: selectedEldProfile?.id,
              name: selectedEldProfile?.value,
              tspSource: selectedEldProfile?.tspSource,
            };

            if (!this.state._id) {
              this.setState({
                selectedEldChassisProfile: _selectedChassisEldProfile,
                isEldConnected: true,
              })
            }
      
            const selectedChassis = {
              _id: this.state._id,
              chassisNo: this.state.chassisNo
            };

            this.connectWithEldProfile(selectedChassis, _selectedChassisEldProfile);  
          }}
        />
      }
      </React.Fragment>
    );
  }
}

const schemaValidatorObject = Yup.object().shape({
  isTerminal: Yup.boolean(),
  newTerminal: Yup.array()
    .nullable()
    .when("isTerminal", {
      is: (val) => val === true,
      then: Yup.array()
        .min(1, `${updateTerminology('terminal')} Is Required.`)
        .of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        )
        .typeError(`${updateTerminology('terminal')} Is Required.`)
        .required(`${updateTerminology('terminal')} Is Required.`),
    }),
    licenceState: Yup.string()
    .min(2, "License State Is Too Short - Should Be 2 Chars Minimum.")
    .trim()
    .required("License State Is Required."),
  Chassis_Size: Yup.string().required("Chassis Size Is Required."),
  licenceNumber: Yup.string()
    .min(3, "License Plate Is Too Short - Should Be 3 Chars Minimum.")
    .trim()
    .required("License Plate Is Required."),
  Chassis_Type: Yup.string().required("Chassis Type Is Required."),
  Chassis_Owner: Yup.string().required("Chassis Owner Is Required."),
  chassisNo: Yup.string().trim().required("Chassis # Is Required."),
});


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(AddChassis)