import { Modal } from "react-bootstrap"
import { Link } from "react-router"
import { CustomIconAlert } from "../../../../../../Components/Common/CustomIcons/Index"
import { IconRedirect } from "../../../../../../Components/Common/Icons"
import { INSUFFICIENT_CREDIT_MODAL_TYPE } from "../../Constants/constants"

const InsufficientCreditModal = ({ type, showModal, redirectPath, redirectQuery, redirectState, onCancel }) => {
    return (
        <>
            <Modal show={showModal} dialogClassName="modal-600px modal-dialog-centered" backdropClassName="z-1050">
                <Modal.Body className="text-center pt-35">
                    <CustomIconAlert className={"mb-15 w-54 h-54"}></CustomIconAlert>
                    <div className="font-20 font-weight-500 line-height-25 mb-15 text-capitalize">
                        {type === INSUFFICIENT_CREDIT_MODAL_TYPE.INSUFFICIENT_CREDIT ?
                            "The new Bill To profile selected does not have sufficient credit for this move." :
                            "The new Bill To profile selected is on Account Hold."}
                    </div>
                    <p className="text-muted font-14 text-capitalize">
                        {type === INSUFFICIENT_CREDIT_MODAL_TYPE.INSUFFICIENT_CREDIT ?
                            'You can update the credit limit in the Organization’s profile page.' :
                            "You can update the Account Hold in the Organization’s profile page."}
                    </p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Link to={{ pathname: redirectPath, query: redirectQuery, state: redirectState }}>
                        <button className='btn btn-outline-light gap-8 text-capitalize'>
                            <IconRedirect /> Open Organization's Profile
                        </button>
                    </Link>
                    <button className="btn btn-outline-light" onClick={onCancel}>
                        Okay
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default InsufficientCreditModal
