import moment from "moment";
import pdfjsLib from "pdfjs-dist";
import Select from "react-select";
import React, { Component, Fragment, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import _ from 'lodash';
import { connect } from "react-redux";
import {
    IconDownArrow,
    IconDownload,
    IconFileUpload,
    IconPlus,
    IconPrinter,
    IconTopDocUpload,
    IconTrash,
    IconAngleArrowDown,
    IconReset
} from "../../../../Components/Common/Icons";
import configuration from "../../../../config";
import { getStorage, isMilitaryTime, toastr } from "../../../../services/Common.services";
import { HTTP } from "../../../../services/Http.service";
import { TMS_GET_USER_LOAD } from "../../constant";
import { addPaymentTMSLoad, getPaymentMethod, removePaymentTMSLoad } from "./actionCreator";
import $ from 'jquery'

import { newSmallSelectStyle } from "../../../../assets/js/select-style";
import DateTimePicker from "../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateTimePicker";
import DeleteCell from "../DeleteCell";
import DocUpload from "../DocUpload.jsx";
import SelectCustomComponent from "../../SelectComponent";
import { getCarrierPayPaymentHistory, removeCarrierPayPayment, syncBillPayment, updateAccountDeposit } from "./actionCreator";
import { updatePayments } from "./actionCreator";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import ReactTooltip from "react-tooltip";
import { newDocUpload } from "pages/tms/Dispatcher/actionCreators";
import { getAccountEntityList } from "pages/tms/services";


const firebase = configuration.firebase;

const dragAndDropFile = (isFileUpload, getRootProps) => {
    if (isFileUpload) return { ...getRootProps() };
    else return {};
}

const sortingOptions = {
    Amount: "amount",
};

const docuementType = [
    { value: "Check", label: "Check" },
    { value: "ACH", label: "ACH" },
    { value: "Wire", label: "Wire" },
    { value: "Credit", label: "Credit" },
    { value: "Credit Card", label: "Credit Card" },
    { value: "Cash", label: "Cash" },
    { value: "Other", label: "Other" },
];
class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLoads: null,
            documentType: null,
            PaymentMethod: [],
            Account: [],
            DepositToAccountRef: null,
            pdfPreviewUrl: null,
            imagePreviewUrl: null,
            selectedDocuments: {},
            allCustomers: props.customers,
            tab: 1,            
            isDropdownPopup: { state: false, name: null },
            showAddPaymentModal: false,
            selectedData: [],
            loader: false,
            isSaving: false,
            isLoadingBarShow: false,
            paymentHistory: [],
            paymentDate: "",
            isSuccess: false,
            isRequied: "transition-white-field",
            isDocType: "transition-white-field",
            customerIdValue: null,
            isFailed: "transition-white-field",
            isApiCall: false,
            isDocFile: false,
            isBlockingChangeSubTab : false,
            nextTab : undefined,
            asce: true,
            isDateSelect: false,
            carrierPayHistory:[],
            loadId:props.loads._id ? props.loads._id : null,
            selectedCarrierPay:null,
            isFetching:false,
            prevCarrierPay: [],
            isSyncing: null,
            qbConnected: getStorage("loggedInUser") && JSON.parse(getStorage("loggedInUser")) ? JSON.parse(getStorage("loggedInUser")).role === 'carrier' ? JSON.parse(getStorage("loggedInUser")).qbConnection
                : JSON.parse(getStorage("loggedInUser")).role === 'fleetmanager' ? getStorage("carrierDetail") && JSON.parse(getStorage("carrierDetail")).qbConnection : false : false
        };
        this.onDrop = this.onDrop.bind(this);
        this.timezone = getStorage("timeZone");
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyboardEvent = this.handleKeyboardEvent.bind(this);
        this.unselectCell = this.unselectCell.bind(this);
        this.timeZone = getStorage("timeZone");
        this.userTimeFormat = isMilitaryTime();
        this.firebaseEventRef = null;
    }

    updateInput = (event) => {
        this.setState({ documentType: event.target.value });
    };

    componentDidMount() {
        if (getStorage("loggedInUser") != null) {
            const currentUser = JSON.parse(getStorage("loggedInUser"));
            let carrierId = currentUser._id;
            if (currentUser.role === "driver") {
                carrierId = currentUser.driver.carrier;
            }
            if (currentUser.role === "fleetmanager") {
                carrierId = currentUser.fleetManager.carrier;
            }
            if(this.state.qbConnected) {
                this.subscribeFirebase(carrierId)
                this.getPaymentMethodReq()
            }
        }

        window.addEventListener('mouseup', this.onDragLeave);
        window.addEventListener('dragenter', this.onDragEnter);
        window.addEventListener('dragover', this.onDragOver);
        if (document.getElementById('dropZone'))
            document.getElementById('dropZone').addEventListener('dragleave', this.onDragLeave);
        window.addEventListener('drop', this.onDrop);
        document.addEventListener("mousedown", this.handleClickOutside);

    }
    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onDragLeave);
        window.removeEventListener('dragenter', this.onDragEnter);
        window.removeEventListener('dragover', this.onDragOver);
        if (document.getElementById('dropZone'))
            document.getElementById('dropZone').removeEventListener('dragleave', this.onDragLeave);
        window.removeEventListener('drop', this.onDrop);
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    componentWillMount() {
        // this.unlisten = browserHistory.listen((location, action) => {
        //     debugger
        // });
        this.unsubscribeFirebase()
    }

    subscribeFirebase = (carrierId) => {
        this.firebaseEventRef = firebase.database().ref(`${carrierId}/syncQbCarrierPaymentHistory`)
        this.firebaseEventRef.on('value', (snapshot) => {
            const event = snapshot.val();
            const data = event && JSON.parse(event)
            const { error, response } = data
            if (data && moment().diff(moment(data.updatedAt), 'seconds') <= 10 && response?.carrierPay?._id === this.state.selectedCarrierPay?._id) {
                if (error) {
                    let errorMessage = "Error while syncing payment to quickbooks"
                    if (typeof error == "object") {
                        if (error?.message && typeof error.message == "string") errorMessage = error.message
                        else if (error?.error && typeof error.error == "string") {
                            if (error.error == 'invalid_grant') errorMessage = "Your QuickBooks token has expired please reconnect to QuickBooks and try again"
                            else errorMessage = error.error
                        }
                    } else if (typeof error == "string") errorMessage = error;
                    toastr.show(errorMessage, "error")
                }
                this.setState({ carrierPayHistory: response?.data })
            }
        });
    }
    
     unsubscribeFirebase = () => {
        if(this.firebaseEventRef) return this.firebaseEventRef.off();
     }

    onDragEnter = (e) => {
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: true })
            // dropZone.classList.add("tr-disable");
        }
    }

    onDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onDragLeave = (e) => {
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: false })
            // dropZone.classList.remove("tr-disable");
        }
        e.stopPropagation();
        e.preventDefault();
        return false;
    }

    onDrop = (e) => {
        e.preventDefault();
        const dropZone = document.getElementById('outline');
        if (dropZone) {
            this.setState({ isFileUpload: false })
            // dropZone.classList.remove("tr-disable");
        }
        return false;
    }

    UNSAFE_componentWillReceiveProps(props) {
        props.customers && this.setState({ allCustomers: props.customers });
        // this.setState({isBlockingChangeSubTab : props.isBlockingTabChange},()=>{
        //     if(!props.isBlockingTabChange){
        //         this.changeSubTab(this.state.nextTab || this.state.tab)
        //     }
        // })
    }

    handleShowAddPaymentModal = () => {
        this.setState({ showAddPaymentModal: true });
    };
    handleHidePaymentModal = () => {
        this.setState({ showAddPaymentModal: false });
    };

    selectDocumentType = (value) => {
        this.setState({ documentType: value });
    };
    selectAccountDeposit = (value) => {
        this.setState({DepositToAccountRef: value})
    }
    removeDocument = () => {
        this.setState({ documentFile: null });
    };
    

    selectCell=(name)=>{
        this.setState({
            isDropdownPopup: { state: true, name: name }
        });
    }
    unselectCell=()=>{
        this.setState({
            isDropdownPopup: { state: false, name: null },
        });
    }
    updateAccountDepositReq = async (account, paymentId) => {
        try {
            const payload = {
                qbAccountId: account?.value,
                qbAccountName: account?.label,
                paymentId
            }
            const res = await updateAccountDeposit(payload);
            if (res) {
                let updatedCarrierPayHistory = this.state?.carrierPayHistory?.map(history => {
                    if (history?._id == paymentId) return { ...history, qbAccount: res?.qbAccount }
                    return history
                })
                this.setState({
                    carrierPayHistory: updatedCarrierPayHistory,
                    prevCarrierPay: updatedCarrierPayHistory
                })
            }
            this.unselectCell()
        } catch (error) {
            this.unselectCell()
            console.log(error)
        }
    }
    getPaymentMethodReq = ()=>{
        getPaymentMethod().then((response) => {
            if (response?.data?.data) {
              const { PaymentMethod } = response.data.data;
              getAccountEntityList().then((response) => {
                const Account = response.filter(acc => acc.Type == "Bank" || acc.Type == "Credit Card")
                this.setState({ Account, PaymentMethod });
              });
            }
          });
    }


    // Return  sub ref number for each payments
    getSubRefNo = (data, isCustomer) => {
        let val = "";
        if (
            data.additionalPricingId &&
            this.props.selectedLoads &&
            this.props.loads.additionalPricing.length
        ) {
            let find = this.props.loads.additionalPricing.find(
                (item) => (item._id).toString() === data.additionalPricingId
            );
            if (find && find.sub_reference_number && !isCustomer) {
                val = find.sub_reference_number;
            }
            if (find && find.customerId && isCustomer) {
                val = find.customerId.company_name;
            }
        }
        return val;
    };
    selectAll = (e) => {
        let check = e.target.checked;
        const data = this.props.loads || this.props.selectedLoads;
        if (check) {
            this.setState({ selectedData: data.paymentHistory.map((d) => d._id) });
        } else {
            this.setState({ selectedData: [] });
        }
    };
    checkHandler = (e, id) => {
        let check = e.target.checked;
        if (check) {
            if (!this.state.selectedData.includes(id)) {
                let dummyData = this.state.selectedData;
                dummyData.push(id);
                this.setState({ selectedData: dummyData });
            }
        } else {
            const newData = this.state.selectedData.filter((d) => d !== id);
            this.setState({ selectedData: newData });
        }
    };

    handleSubmit = (e) => {
        if (this.state.addPayment) {
            this.unselectCell()
            if (!this.state.amount || !this.state.paymentDate || (this.state.tab === 2 && !this.state.additionalPricingId)) {
                this.setState({ isRequied: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isRequied: "background-failure" })
                    }, 400);
                })
                if ((this.state.documentUrl && !this.state.documentType)) {
                    this.setState({ isDocType: "background-error" }, () => {
                        setTimeout(() => {
                            this.setState({ isDocType: "background-failure" })
                        }, 400);
                    })
                }
                return false;
            }

            if ((this.state.documentUrl && !this.state.documentType)) {
                this.setState({ isDocType: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isDocType: "background-failure" })
                    }, 400);
                })
                return false;
            }
            if (this.state.qbConnected && this.state.tab === 3 && !this.state.DepositToAccountRef) {
                this.setState({ isRequied: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isRequied: "background-failure" })
                    }, 400);
                })
                return false;
            }

            const formData = new FormData();
            if(this.state.tab === 1 || this.state.tab === 2){
            if (this.props.loads) {
                formData.append("reference_number", this.props.loads.reference_number);
            } else {
                formData.append("reference_number", this.props.selectedLoads.reference_number);
            }
            if (this.state.amount) {
                formData.append("amount", Number(this.state.amount));
            }

            if (this.state.documentUrl && this.state.documentType) {
                formData.append("documentUrl", this.state.documentUrl);
                formData.append("filename", `${new Date().getTime().toString()}`);
                formData.append("documentType", this.state.documentType);
            }

            if (this.state.notes) formData.append("notes", this.state.notes);
            if (this.state.checkNo) formData.append("checkNo", this.state.checkNo);

            if (this.state.paymentDate) {
                formData.append("paymentDate", this.state.paymentDate);
            }

            if (this.state.tab === 2)
                formData.append(
                    "additionalPricingId",
                    this.state.additionalPricingId
                );

            if (this.state.customerIdValue) {
                formData.append("customerId", this.state.customerIdValue);
            }
            formData.append("timeZone", this.timeZone);

            this.setState({ isApiCall: true })
            addPaymentTMSLoad(formData).then((da) => {
                this.setState({nextTab : this.state.tab},()=>{
                    this.props.blockTabChange(false);
                })
                this.setState({ isSaving: false, addPayment: false, paymentHistory: da.data.data?.paymentHistory },);
                this.setState({
                    amount: "",
                    notes: "",
                    paymentDate: "",
                    checkNo: "",
                    customerId: "",
                    documentFile: "",
                    documentType: "",
                    isSuccess: true,
                    isRequied: "transition-white-field",
                    isDocType: "transition-white-field",
                    isFailed: "transition-white-field",
                    documentUrl: null,
                    isApiCall: false,
                    additionalPricingId:null,
                    customerIdValue:null
                });
                if (this.props.updateList) {
                    this.props.updateList(da.data.data);
                } else {
                    this.props.dispatch({
                        type: TMS_GET_USER_LOAD,
                        reference_number: this.props.selectedLoads
                            ? this.props.selectedLoads.reference_number
                            : this.props.loads.reference_number,
                        payload: {
                            skip: this.state.activePage - 1,
                            limit: this.state.limit,
                        },
                    });
                }
                setTimeout(() => {
                    this.setState({ isSuccess: false });
                }, 400);
                this.setState({
                    showAddPaymentModal: false,
                    loader: false,
                    isSaving: false,
                });
            }).catch(() => {
                this.setState({nextTab : this.state.tab},()=>{
                    this.props.blockTabChange(false);
                })
                this.setState({
                    loader: false,
                    isSaving: false,
                    isFailed: "background-error",
                    isRequied: "background-error",
                    isDocType: "background-error",
                    isApiCall: false
                }, () => {
                    setTimeout(() => {
                        this.setState({ isFailed: "background-failure", isRequied: "background-failure", isDocType: "background-failure" })
                    }, 400);
                });
            });
        }
            else if(this.state.tab === 3){
                if(this.state.selectedCarrierPay){
                    formData.append("carrierId",this.state.selectedCarrierPay.drayosCarrier );
                }
                if(this.state.amount){
                    let payment = [{
                        carrierPayId:this.state.selectedCarrierPay._id,
                        amount:this.state.amount,
                        shipLoadId: this.state.loadId
                    }]
                    formData.append("payments", JSON.stringify(payment));
                }
                if (this.state.documentType) {
                    formData.append("docType", this.state.documentType);
                }
                if (this.state.qbConnected && this.state.DepositToAccountRef) {
                    formData.append("DepositToAccountRef", JSON.stringify(this.state.DepositToAccountRef));
                }
                if (this.state.notes) {
                    formData.append("notes", this.state.notes);
                }
                if (this.state.checkNo) {
                    formData.append("checkNo", this.state.checkNo);
                }
                formData.append("paymentDate", this.state.paymentDate);
                if (this.state.documentUrl) {
                    formData.append("documents", this.state.documentFile);
                }
                formData.append("timeZone", getStorage("timeZone"));

                this.setState({ isApiCall: true})
                updatePayments(formData).then(()=>{
                    getCarrierPayPaymentHistory(this.state.loadId).then((data)=>{
                        this.setState({
                            carrierPayHistory:data.data.data,
                            prevCarrierPay: data.data.data,
                            selectedCarrierPay:data.data.carrierPay,
                            isSaving: false, 
                            addPayment: false,
                            amount: "",
                            notes: "",
                            paymentDate: "",
                            checkNo: "",
                            documentFile: "",
                            documentType: "",
                            DepositToAccountRef: null,
                            isSuccess: true,
                            isRequied: "transition-white-field",
                            isDocType: "transition-white-field",
                            isFailed: "transition-white-field",
                            documentUrl: null,
                            isApiCall: false,
                            nextTab : this.state.tab
                        },()=>{
                            setTimeout(() => {
                                this.setState({ isSuccess: false });
                            }, 400);
                        })
                    }).catch((err)=>{
                        console.log("err",err)
                    })
                    this.props.blockTabChange(false)
                  }).catch((err) => {
                    console.log(err)
                    this.setState({nextTab : this.state.tab},()=>{
                        this.props.blockTabChange(false);
                    })
                    this.setState({
                        loader: false,
                        isSaving: false,
                        isFailed: "background-error",
                        isRequied: "background-error",
                        isDocType: "background-error",
                        isApiCall: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ isFailed: "background-failure", isRequied: "background-failure", isDocType: "background-failure" })
                        }, 400);
                    });
                });

            }

        }
    }


    syncBillPaymentReq = async (payment) => {
        if (this.state?.isSyncing) return
        if(!payment?.qbAccount?.qbAccountName) {
            return toastr.show("Please Select Account To Deposit","error")
        }
        try {
            this.setState({ isSyncing: payment._id })
            let payload = {
                paymentId: payment._id
            }
            const res = await syncBillPayment(payload)
            if (res?.isQbSynced) {
                let carrierPayHistory = this.state.carrierPayHistory?.map(elem => {
                    if (elem?._id == payment?._id) {
                        return { ...elem, isQbSynced: true }
                    }
                    return elem;
                })
                this.setState({ carrierPayHistory })
            }
            this.setState({ isSyncing: null })
        } catch (error) {
            console.log(error)
            this.setState({ isSyncing: null })
        }
    }
    

    removeCarrierPayment = (historyObj) => {
        let data ={
            paymentHistoryId:historyObj._id,
            loadId :this.state.loadId,
            paidAmount:historyObj.paidAmount
        }
        
        removeCarrierPayPayment(data).then(()=>{
            this.setState({nextTab : this.state.tab},()=>{
                this.props.blockTabChange(false);
            })
            toastr.show("You have removed this payment.", "success");
            getCarrierPayPaymentHistory(this.state.loadId).then((data)=>{
                if(data){
                    this.setState({carrierPayHistory:data.data.data,selectedCarrierPay:data.data.carrierPay,prevCarrierPay:data.data.data})
                }
            }).catch((err)=>{
                console.log("err",err)
            })
        }).catch((err)=>{
            console.log("removeCarrierPayPayment",err)
        })
    }

    fileUploader = (data) => {
        if (data) {
            if (this.props.multi) {
                let allFiles = [];
                for (let index = 0; index < data.length; index++) {
                    allFiles.push(data[index]);
                }
                this.uploadResult(allFiles, null, this.props.type);
            } else {
                let reader = new FileReader();
                let file = data[0];
                reader.onload = () => {
                    this.uploadResult(file, reader.result, this.props.type);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    getSubCustomerBillingOpts = (isCustomer = false) => {
        let selectedLoads =
            this.props.selectedLoads && this.props.selectedLoads.length == 0
                ? undefined
                : this.props.selectedLoads;
        if (this.props.loads) {
            selectedLoads = this.props.loads;
        }
        let opts = [];
        if (
            selectedLoads &&
            selectedLoads.additionalPricing &&
            selectedLoads.additionalPricing.length > 0
        ) {
            selectedLoads.additionalPricing.map((item, i) => {
                if (item.sub_reference_number) {
                    let value = {
                        label: isCustomer
                            ? item.customerId.company_name
                            : item.sub_reference_number
                                ? item.sub_reference_number
                                : "",
                        value: item._id,
                        customerId: item.customerId._id,
                    };
                    opts.push(value);
                }
            });
        }
        return opts;
    };

    // GEt SubCustomer
    getCustomerVal = () => {
        let opts = this.getSubCustomerBillingOpts(true);
        let val = opts.find((item) => item.customerId === this.state.customerIdValue);
        return val;
    };

    getPricingVal = () => {
        let opts = this.getSubCustomerBillingOpts();
        let val = opts.find(item => item.value === this.state.additionalPricingId);
        return val;
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (
            this.wrapperRef &&
            !this.wrapperRef.contains(event.target) &&
            (document.querySelector(".tab-loads").contains(event.target) ||  document.querySelector(".fcpopup__left").contains(event.target))
        ) {
            this.setState({isDateSelect: false}, () => {
                this.handleSubmit()
            })
        }
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };
    

    existNewPayment = () => {
        this.setState({
            addPayment: false,
            amount: "",
            notes: "",
            paymentDate: "",
            checkNo: "",
            customerId: "",
            documentFile: "",
            documentType: "",
            DepositToAccountRef: null,
            isRequied: "transition-white-field",
            isDocType: "transition-white-field",
            additionalPricingId: null,
            customerIdValue: null,
            isFailed: "transition-white-field",
            documentUrl: null
        },()=>this.props.blockTabChange(false))
    };

    handleKeyboardEvent(){
        const dateDiv = document.getElementById('payment-selectDate')
        if(dateDiv) {
            dateDiv.addEventListener("focusin", () => {
                this.setState({isDateSelect:true})
            })
            dateDiv.addEventListener("focusout", (event) => {
                this.setState({isDateSelect:false})
            })
        }
    }

    sortTable(sort) {
        this.setState({
          sort : !this.state.asce ? undefined : sort,
          asce: this.state.sort !== sort ? true : !this.state.asce,
        });
    }

    renderPaymentHistory = (paymentHistoryData, colspan) => {
        let paymentData 
        if(this.state.tab == 1 || this .state.tab == 2){
            paymentData = this.state.paymentHistory.length > 0 ? this.state.paymentHistory : paymentHistoryData
        }else{
            paymentData = this.state.carrierPayHistory.length > 0 ? this.state.carrierPayHistory : [...this.state.prevCarrierPay]
        }
        if(paymentData.length === 1 && Object.keys(paymentData[0])?.length === 0 ) paymentData = []
        if (this.state.tab == 1 && this.state.paymentHistory.length > 0) {
            paymentData = this.state.paymentHistory.filter((payment) => {
                return (
                    !payment.customerId ||
                    payment.customerId == "undefined" ||
                    payment.customerId == this.state.customerId
                );
            });
        }
        if (this.state.tab == 2 && this.state.paymentHistory.length > 0) {
            paymentData = this.state.paymentHistory.filter((payment) => {
                return (
                    payment.customerId && payment.customerId != this.state.customerId
                );
            });
        }

        if (this.state.sort) {
            paymentData = _.orderBy(paymentData,
              payment => {return Number(payment[this.state.sort])},
              !this.state.asce && "desc"
            );
        }
        const isPaymentDisable = (this.state.tab === 3 && (!this.state?.selectedCarrierPay?.status || !['APPROVED', 'PARTIAL_PAID', 'FULL_PAID'].includes(this.state.selectedCarrierPay?.status)))
        return (
            <div className="mb-20" >
                <div  ref={!this.state.isApiCall && this.setWrapperRef}>
                    <table className={`table table-card mb-10 payment-table position-relative`} >
                        {this.state.isApiCall && <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                            <span
                                class="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Processing...
                        </div>}
                        <thead>
                            <tr>
                                <th 
                                    style={{width: '8%'}} 
                                    onClick={() =>
                                        sortingOptions.hasOwnProperty("Amount") &&
                                        this.sortTable(sortingOptions["Amount"])
                                    }
                                    className={`text-right ${sortingOptions.hasOwnProperty("Amount") && (this.state.sort == undefined || this.state.sort !== sortingOptions["Amount"])
                                        ? 'table-sort table-sort--default'
                                        : (sortingOptions.hasOwnProperty("Amount") ? this.state.sort === sortingOptions["Amount"] && this.state.asce == true ?   'table-sort table-sort--ascending text-gray-900' : 'table-sort table-sort--descending text-gray-900' : '')}`}
                                >Amount</th>
                                <th style={{width: '13%'}}>Note</th>
                                <th style={{width: '10%'}}>Check #</th>
                                <th style={{width: '12%'}}>File</th>
                                {(this.state.tab == 3 && this.state.qbConnected) && <th style={{ width: '14' }}>Account To Deposit</th>}
                                <th style={{ width: '14%' }}>{this.state.tab == 3 && this.state.qbConnected ? "Deposit Type" : "Doc Type"}</th>
                                <th style={{width: '7%'}}>Date & Time</th>
                                {this.state.tab == 2 && (
                                    <Fragment>
                                        <th style={{width: '10%'}}>Reference No</th>
                                        <th style={{width: '11%'}}>Sub Customer</th>
                                    </Fragment>
                                )}
                                <th style={{width: '0.1%'}}></th>
                                <th style={{width: '0.1%'}}></th>
                                {(this.state.tab == 3 && this.state.qbConnected) && <th style={{ width: '0.3%' }}></th>}
                                <th style={{width: '0.3%'}}></th>
                            </tr>
                        </thead>
                        <tbody >
                            {(this.state.tab === 1 || this.state.tab === 2) && 
                                paymentData && paymentData.length > 0 ? (
                                paymentData.map((data, index) => {
                                    return (
                                        <tr key={index}
                                            style={{ opacity: `${this.state.addPayment ? '0.2' : ""} `, transition: 'background .4s ease-out', pointerEvents: `${this.state.addPayment ? 'none' : ""} ` }}
                                            className={`transition-white-field ${paymentData.length - 1 === index && this.state.isSuccess ? 'background-success' : ''}`}
                                        >
                                            <td className="font-14 text-right text-dark">$
                                                {data.amount && data.amount.toFixed(2)}
                                            </td>
                                            <td className="font-12 text-truncate">
                                                {data.notes}
                                            </td>
                                            <td className="font-12">
                                                {data.checkNo}
                                            </td>
                                            {<DocUpload
                                                index={index}
                                                data={data}
                                                onDocumentLoadSuccess={() => this.onDocumentLoadSuccess}
                                                addPayment={this.state.addPayment}
                                            />}

                                            <td>
                                                <div className="font-12 d-flex justify-content-between">
                                                    {data.documentType}
                                                </div>
                                            </td>
                                            <td>
                                                <React.Fragment>
                                                    <div className="font-weight-500">
                                                        {moment(data.paymentDate)
                                                            .tz(this.timeZone)
                                                            .format("MM/DD")}
                                                    </div>
                                                    <div className="font-weight-normal">
                                                        {moment(data.paymentDate)
                                                            .tz(this.timeZone)
                                                            .format(`${this.userTimeFormat? "HH:mm": "hh:mm a" }`)}
                                                    </div>
                                                </React.Fragment>

                                            </td>
                                            {this.state.tab != 1 && (
                                                <Fragment>
                                                    <td className="pr-0">{this.getSubRefNo(data)}</td>
                                                    <td className="pr-0"> {this.getSubRefNo(data, true)}</td>
                                                </Fragment>
                                            )}
                                            <td className="text-center event_icon" style={{minWidth: '42px'}}>
                                                {data.document &&
                                                <div
                                                    data-tip
                                                    data-for="fullscreen"
                                                    className="tooltip-label position-relative"
                                                >
                                                    <button
                                                        className="btn btn-link text-dark p-0"
                                                        onClick={() => {
                                                            !this.state.addPayment && this.downloadDoc(data.document);
                                                        }}
                                                    >
                                                        <IconDownload className={"icon-button"} />
                                                    </button>
                                                    {!this.state.addPayment && <div
                                                        className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                                                        id="fullscreen"
                                                        data-placement="top-center"
                                                        style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                                                    >
                                                        Download Payment Doc
                                                        <span className="arrow"></span>
                                                    </div>}
                                                </div>}
                                            </td>
                                            <td className="text-center event_icon"  style={{minWidth: '42px'}}>
                                                {data.document && <div
                                                    data-tip
                                                    data-for="fullscreen"
                                                    className="tooltip-label position-relative"
                                                >
                                                    <button
                                                        className="btn btn-link text-dark p-0"
                                                        onClick={() => {
                                                            !this.state.addPayment && this.handlePrint(data.document);
                                                        }}
                                                    >
                                                        <IconPrinter className={"icon-button"} />
                                                    </button>
                                                    {!this.state.addPayment && <div
                                                        className="tooltip tooltip--gray-700 bs-tooltip-top"
                                                        id="fullscreen"
                                                        data-placement="top-center"
                                                        style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                                                    >
                                                        Print
                                                        <span className="arrow"></span>
                                                    </div>}
                                                </div>}
                                            </td>
                                            {<DeleteCell 
                                                removePayment={() => this.removePayment(data)} 
                                                addPayment={this.state.addPayment}
                                                data={data}
                                            />}
                                        </tr>
                                    );
                                })
                            ) : (
                                null
                            )}
                            {this.state.tab == 3 && this.state.isFetching && <LoaderBar></LoaderBar>}
                            {this.state.tab == 3 && 
                                paymentData && paymentData.length > 0 ? (
                                    paymentData.map((data, index) => {
                                        return (
                                            <tr key={index}
                                                style={{ opacity: `${this.state.addPayment ? '0.2' : ""} `, transition: 'background .4s ease-out', pointerEvents: `${this.state.addPayment ? 'none' : ""} ` }}
                                                className={`transition-white-field ${paymentData.length - 1 === index && this.state.isSuccess ? 'background-success' : ''}`}
                                            >
                                                <td className="font-14 text-right text-dark">$
                                                    {data?.paidAmount?.toFixed(2)}
                                                </td>
                                                <td className="font-12 text-truncate">
                                                    {data?.notes}
                                                </td>
                                                <td className="font-12">
                                                    {data?.checkNo}
                                                </td>
                                                {<DocUpload
                                                    index={index}
                                                    data={data}
                                                    onDocumentLoadSuccess={() => this.onDocumentLoadSuccess}
                                                    addPayment={this.state.addPayment}
                                                />}
                                                {
                                                    this.state.qbConnected &&
                                                    <td className={`pr-1 select-cell-${index}`}
                                                        style={{ transition: 'background .4s ease-out' }}
                                                        onClick={(e) => (!data?.qbAccount?.qbAccountName) && this.selectCell(`add-account-deposit-${index}`)}
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            {data?.qbAccount?.qbAccountName || <span className="text-muted">Select Account To Deposit</span>}
                                                            {!data?.qbAccount?.qbAccountName && <IconAngleArrowDown className='' />}
                                                            {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === `add-account-deposit-${index}` &&
                                                                <SelectCustomComponent
                                                                    name='type'
                                                                    searchable={true}
                                                                    refClassName={`select-cell-${index}`}
                                                                    listData={this.state.Account.map((pay) => ({
                                                                        value: pay.Id ? pay.Id : pay.ID,
                                                                        label: pay.Name,
                                                                    }))}
                                                                    onSelect={(name, value) => { this.updateAccountDepositReq(value, data?._id) }}
                                                                    keyName="label"
                                                                    unselectCell={this.unselectCell}
                                                                />
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                                <td>
                                                    
                                                    <div className="font-12 d-flex justify-content-between">
                                                        {data?.depositType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <React.Fragment>
                                                        <div className="font-weight-500">
                                                            {moment(data.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format("MM/DD")}
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            {moment(data.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format(`${this.userTimeFormat? "HH:mm": "hh:mm a" }`)}
                                                        </div>
                                                    </React.Fragment>

                                                </td>
                                                
                                                <td className="text-center event_icon" style={{minWidth: '42px'}}>
                                                    {data.document &&
                                                    <div
                                                        data-tip
                                                        data-for="fullscreen"
                                                        className="tooltip-label position-relative"
                                                    >
                                                        <button
                                                            className="btn btn-link text-dark p-0"
                                                            onClick={() => {
                                                                !this.state.addPayment && this.downloadDoc(data.document);
                                                            }}
                                                        >
                                                            <IconDownload className={"icon-button"} />
                                                        </button>
                                                        {!this.state.addPayment && <div
                                                            className="tooltip tooltip--gray-700 bs-tooltip-top react-tooltip-new"
                                                            id="fullscreen"
                                                            data-placement="top-center"
                                                            style={{ left: "11px", bottom: "29px", minWidth: "50px", position: "absolute" }}
                                                        >
                                                            Download Payment Doc
                                                            <span className="arrow"></span>
                                                        </div>}
                                                    </div>}
                                                </td>
                                                <td className="text-center event_icon"  style={{minWidth: '42px'}}>
                                                    {data.document && <div
                                                        data-tip
                                                        data-for="fullscreen"
                                                        className="tooltip-label position-relative"
                                                    >
                                                        <button
                                                            className="btn btn-link text-dark p-0"
                                                            onClick={() => {
                                                                !this.state.addPayment && this.handlePrint(data.document);
                                                            }}
                                                        >
                                                            <IconPrinter className={"icon-button"} />
                                                        </button>
                                                        {!this.state.addPayment && <div
                                                            className="tooltip tooltip--gray-700 bs-tooltip-top"
                                                            id="fullscreen"
                                                            data-placement="top-center"
                                                            style={{ left: "12px", bottom: "29px", minWidth: "45px", position: "absolute" }}
                                                        >
                                                            Print
                                                            <span className="arrow"></span>
                                                        </div>}
                                                    </div>}
                                                </td>
                                                {this.state.qbConnected &&
                                                    <td className="text-center event_icon" style={{ minWidth: '42px' }} >
                                                        <div
                                                            className="tooltip-label position-relative"
                                                        >
                                                            {data?.isQbSynced ?
                                                                <div className={`text-center opacity-05`}><IconReset /> </div> :
                                                                ((this.state?.isSyncing == data?._id) ?
                                                                    <div className="text-center opacity-05">
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        >
                                                                        </span>
                                                                    </div> :
                                                                    <>
                                                                        <div
                                                                            data-tip
                                                                            data-for={`quickbook-sync-${index}`}
                                                                            className={`text-center text-danger ${this.state?.isSyncing ? "" : "pointer"}`}
                                                                            onClick={() => this.syncBillPaymentReq(data)}>
                                                                            <IconReset />
                                                                        </div>
                                                                        <ReactTooltip id={`quickbook-sync-${index}`} place="top" effect="solid" className="react-tooltip-new">
                                                                            {"Sync QuickBooks Payment"}
                                                                        </ReactTooltip>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                                {<DeleteCell 
                                                    removePayment={() => this.removeCarrierPayment(data)} 
                                                    addPayment={this.state.addPayment}
                                                    data={data}
                                                />}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    null
                                )}
                            {this.state.addPayment &&
                                <Dropzone
                                    className="border-0 w-100"
                                    onDrop={(acceptedFiles) => {
                                        this.fileUploader(acceptedFiles);
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <tr
                                            className={`shadow-md select-custom-input newdriverrow position-relative ${this.state.isFailed}`}
                                            id="outline"
                                            style={{ transition: 'background .4s ease-out' }}
                                            {...dragAndDropFile(this.state.isFileUpload, getRootProps)}
                                            onKeyDown={(e) => { !this.state.isApiCall && e.keyCode === 13 && this.handleSubmit() }}
                                        >

                                            {this.state.isFileUpload && <div className="tr-disable"></div>}
                                            <td className={`${this.state.isFailed === "transition-white-field" && this.state.amount ? "transition-white-field" : this.state.isRequied} "font-14 text-right text-dark" ${!this.state.amount && 'requied_hover'}
                                              ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-amount" && 'newdriverrow td-clicked'}`} 
                                            style={{ transition: 'background .4s ease-out',}}
                                            onClick={()=>this.selectCell("add-amount")}
                                            >
                                                <input
                                                    className={`form-control border-0 font-14 text-right pr-0 ${this.state.amount ? 'text-gray-900' : 'text-gray-300'}  bg-transparent`}
                                                    placeholder="$0.00"
                                                    type="number"
                                                    value={this.state.amount}
                                                    onChange={(e) => this.setState({ amount: e.target.value.trim() }, ()=>{this.props.blockTabChange(true)})}
                                                />
                                            </td>
                                            <td className={`${this.state.isFailed} text-break font-12 text-truncate text-truncate"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-des" && 'newdriverrow td-clicked'}`}
                                             onClick={()=>this.selectCell("add-des")}
                                            >
                                                <input
                                                    className="form-control border-0 bg-transparent pl-0"
                                                    placeholder="Add Description..."
                                                    onChange={(e) => this.setState({ notes: e.target.value }, ()=>{this.props.blockTabChange(true)})}
                                                    value={this.state.notes}
                                                />
                                            </td>
                                            <td className={`${this.state.isFailed} "font-12"
                                             ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-check" && 'newdriverrow td-clicked'}`} 
                                              onClick={()=>this.selectCell("add-check")}
                                            >
                                                <input
                                                    className="form-control border-0 bg-transparent pl-0"
                                                    placeholder="Enter Check #"
                                                    value={this.state.checkNo}
                                                    onChange={(e) => this.setState({ checkNo: e.target.value }, ()=>{this.props.blockTabChange(true)})}
                                                />
                                            </td>
                                            <td className={`${this.state.isFailed}`} >

                                                <div className='btn btn-outline-primary-border rounded-lg pt_1 pb_1' {...getRootProps()} >
                                                    <input {...getInputProps()} style={{ display: "none" }} />
                                                    {this.state.documentFile ?
                                                        this.state.isDocFile ? (
                                                            <div className="text-center position-relative">
                                                                <span
                                                                    className="spinner-border spinner-border-sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                            </div>
                                                        ) :
                                                            <IconFileUpload />
                                                        :
                                                        <div className="font_10 d-flex">
                                                            <IconTopDocUpload className="mr-10"/> Upload Document</div>
                                                    }
                                                </div>
                                            </td>
                                            {this.state.tab === 3 && this.state.qbConnected &&
                                                <td className={`pr-1 select-cell-3 
                                                        ${this.state.isFailed === "transition-white-field" && this.state.DepositToAccountRef ? "transition-white-field" : this.state.isRequied}
                                                        ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-account-deposit" && 'newdriverrow td-clicked'}`
                                                }
                                                    style={{ transition: 'background .4s ease-out' }}
                                                    onClick={(e) => this.selectCell("add-account-deposit")}
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {this.state.DepositToAccountRef?.label || <span className="text-muted">Select Account To Deposit</span>}
                                                        <IconAngleArrowDown className='' />
                                                        {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-account-deposit" &&
                                                            <SelectCustomComponent
                                                                name='type'
                                                                searchable={true}
                                                                refClassName="select-cell-3"
                                                                listData={this.state.Account.map((pay) => ({
                                                                    value: pay.Id ? pay.Id : pay.ID,
                                                                    label: pay.Name,
                                                                }))}
                                                                onSelect={(name, value) => { this.selectAccountDeposit(value); this.unselectCell() }}
                                                                keyName="label"
                                                                unselectCell={this.unselectCell}
                                                            />}
                                                    </div>
                                                </td>
                                            }
                                            <td className={`pr-1 select-cell-1
                                                ${this.state.isFailed === "transition-white-field" &&
                                                    this.state.documentType ? "transition-white-field" : this.state.isDocType}
                                                ${this.state.documentUrl && !this.state.documentType ? 'requied_hover' : 'select_hover'} 
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" && 'newdriverrow td-clicked'}`}
                                                style={{ transition: 'background .4s ease-out' }}
                                                onClick={(e)=>this.selectCell("add-docType")}
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    { this.state.documentType|| <span className="text-muted">Select Document Type</span>}
                                                    <IconAngleArrowDown className='' />
                                                    {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-docType" &&
                                                        <SelectCustomComponent
                                                            name='type'
                                                            searchable={true}
                                                            refClassName="select-cell-1"
                                                            listData={docuementType}
                                                            onSelect={(name,value)=>{this.selectDocumentType(value.value);this.unselectCell()}}
                                                            keyName="label"
                                                            unselectCell={this.unselectCell}
                                                        />}
                                                </div>
                                            </td>
                                            <td
                                                className={`${this.state.isFailed === "transition-white-field" && this.state.paymentDate ? "transition-white-field" : this.state.isRequied} ${!this.state.paymentDate && 'requied_hover'}
                                                ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-date" && 'newdriverrow td-clicked'}
                                                `}
                                                style={{ transition: 'background .4s ease-out' }}
                                                onClick={() => this.setState({ isDateSelect: true }, ()=>{this.props.blockTabChange(true); this.selectCell("add-date")})}
                                            >
                                                <div className="position-relative bg-transparent" ref={this.wrapperRef} >
                                                    <div>
                                                        <div className={`font-weight-500 ${!this.state.paymentDate && 'text-center'}`}>
                                                            {this.state.paymentDate ? moment(this.state.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format("MM/DD") : "-"}
                                                        </div>
                                                        <div className="font-weight-normal">
                                                            {this.state.paymentDate && moment(this.state.paymentDate)
                                                                .tz(this.timeZone)
                                                                .format(`${this.userTimeFormat? "HH:mm": "hh:mm a" }`)}
                                                        </div>
                                                    </div>
                                                    {this.state.isDateSelect &&
                                                        <div className="date-time-popup" >
                                                            <DateTimePicker
                                                                dateFormat="MM/DD"
                                                                displayTimeZone={this.timeZone}
                                                                onChange={(e) => this.setState({ paymentDate: e })}
                                                                defaultDate={this.state.paymentDate &&
                                                                    moment(this.state.paymentDate).tz(this.timeZone).format(`${this.userTimeFormat ? 'MMM D, YYYY HH:mm': 'lll'}`)
                                                                }
                                                                dontShowSelectDate={true}
                                                                isShowTime={true}
                                                                hideShowSelectDate={true}
                                                                className="right-0"
                                                                OnBlurUpdate={() => {
                                                                    this.setState({ isDateSelect: false }, ()=>{this.props.blockTabChange(true);this.unselectCell()})
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </td>
                                            {this.state.tab === 2 && (
                                                <Fragment>
                                                    <td
                                                        style={{ transition: 'background .4s ease-out' }}
                                                        className={`pr-0 select-cell-2 ${!this.state.additionalPricingId ? 'requied_hover' : 'select_hover'} ${this.state.additionalPricingId ? "transition-white-field" : this.state.isRequied}
                                                        ${this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-subref" && 'newdriverrow td-clicked'}
                                                        `}
                                                        onClick={()=>this.selectCell("add-subref")}
                                                    >

                                                    <div className="d-flex justify-content-between align-items-center">
                                                    { this.getPricingVal()?.label|| <span className="text-muted">Select Sub Ref</span>}
                                                    <IconAngleArrowDown className='mt-n1' />
                                                    {this.state.isDropdownPopup.state && this.state.isDropdownPopup.name === "add-subref" &&
                                                        <SelectCustomComponent
                                                            name='sub ref number'
                                                            refClassName="select-cell-2"
                                                            listData={this.getSubCustomerBillingOpts()}
                                                            onSelect={(name,value)=>{
                                                                this.setState({
                                                                    additionalPricingId: value.value,
                                                                    customerIdValue: value.customerId,
                                                                }, ()=>{this.props.blockTabChange(true);this.unselectCell()});
                                                            }
                                                            }
                                                            keyName="label"
                                                            unselectCell={this.unselectCell}
                                                        />}
                                                </div>
                                                    </td>
                                                    <td className={`${this.state.isFailed} pr-0 disable-select`}
                                                    >
                                                        <input
                                                            className="form-control border-0 bg-transparent pl-0"
                                                            placeholder=""
                                                            disabled={true}
                                                            value={this.getCustomerVal()?.label}
                                                        />
                                                    </td>
                                                </Fragment>
                                            )}
                                            <td className={`${this.state.isFailed}`} style={{minWidth: '42px'}}></td>
                                            <td className={`${this.state.isFailed}`} style={{minWidth: '42px'}}></td>
                                            {this.state.tab === 3 && this.state.qbConnected && <td className={`${this.state.isFailed}`} style={{ minWidth: '42px' }}></td>}
                                            <td className={`${this.state.isFailed} text-center event_icon`} >
                                                <span onClick={() => this.existNewPayment()}>
                                                    <IconTrash />
                                                </span>
                                            </td>
                                        </tr>

                                    )}
                                </Dropzone>
                            }
                        </tbody>
                    </table>
                    <div className="mt-10">
                        <button
                            type="button"
                            class="btn btn-white btn-sm text-primary"
                            onClick={() => this.setState({ addPayment: true,
                                isRequied: "transition-white-field",
                                isDocType: "transition-white-field",
                                isFailed: "transition-white-field", 
                            })}
                            disabled={isPaymentDisable}
                        >
                            <IconPlus className="mr-2" />
                            <span className="font-weight-500">Add Payment</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    uploadResult = (file, preview, type) => {
        // data.size * 0.0009765625
        if (file.type.indexOf("image") == -1) {
            type = file.type.split("/")[1];
        }
        this.setState(
            {
                documentFile: null,
                [`${type}PreviewUrl`]: null,
                pdfPreviewUrl: null,
                [type]: null,
            },
            () => {
                this.setState({
                    documentFile: file,
                    [`${type}PreviewUrl`]: preview,
                    [type]: preview,
                }, () => this.uploadFileData());
            }
        );

    };

    uploadFileData = () => {
        let data = new FormData();
        let docUpload;
        if (!this.state.documentFile) {
            toastr.show("Please select a document.", "error");
            return false;
        }
        if (this.state.documentFile) {
            const docFileName = this.state.documentFile.name.toLowerCase();
            if (
                docFileName.split(".").pop() !== "png" &&
                docFileName.split(".").pop() !== "jpg" &&
                docFileName.split(".").pop() !== "gif" &&
                docFileName.split(".").pop() !== "pdf" &&
                docFileName.split(".").pop() !== "jpeg" &&
                docFileName.split(".").pop() !== "csv" &&
                docFileName.split(".").pop() !== "xls" &&
                docFileName.split(".").pop() !== "eml" &&
                docFileName.split(".").pop() !== "xlsx"
            ) {
                toastr.show(
                    "Only select pdf, png, jpg, csv, xls, xlsx and jpeg file",
                    "error"
                );
                return this.removeDocument();
            }
        }
        data.append("document", this.state.documentFile);
        data.append("filename", new Date().getTime().toString());
          
        docUpload =  newDocUpload(data) 

        this.setState({ isDocFile: true })
        docUpload
            .then((result) => {
                if (result.statusCode === 200) {
                    this.setState({
                        documentUrl: result?.data?.url,
                        isSaving: false,
                        docUploading: false,
                        isType: true,
                        isDocFile: false
                    });
                } else {
                    toastr.show("Something went wrong! Please try again.", "error");
                    this.setState({ docUploading: false });
                }
            })
            .catch((err) => {
                toastr.show("Something went wrong! Please try again.", "error");
                this.setState({ docUploading: false });
            });
    };

    handleHide = () => {
        this.setState({ showModal: false });
    };

    viewDocument = (data) => {
        if (!data.document) {
            toastr.show("There is no document.", "warning");
            return;
        }
        let extension;
        let fileName = data.document;
        if (fileName) {
            extension = fileName.split(".").pop();
            if (extension == "pdf") {
                this.setState(
                    { imagePreviewUrl: null, pdfPreviewUrl: data.document },
                    () => {
                        pdfjsLib.getDocument(fileName).then((pdf) => {
                            pdf.getPage(1).then((page) => {
                                var scale = 1.5;
                                var viewport = page.getViewport(scale);
                                // Prepare canvas using PDF page dimensions.
                                var canvas = document.getElementById("the-canvas");
                                var context = canvas.getContext("2d");
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                // Render PDF page into canvas context.
                                var renderContext = {
                                    canvasContext: context,
                                    viewport: viewport,
                                };
                                page.render(renderContext);
                            });
                        });
                    }
                );
            } else {
                this.setState({ imagePreviewUrl: data.document, pdfPreviewUrl: null });
            }
        }
    };

    handlePrint = (url) => {
        window.open(url, "PRINT", "_blank");
    };

    downloadDoc = (url) => {
        if (url) {
            let fileName = url.split("/tms");
            let file = fileName[1].replace("/", "");
            $.ajax({
                url: url,
                xhrFields: {
                    responseType: 'blob'
                },
                success: function (blob) {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = file;
                    link.click();
                }
            })
        }

    }

    removePayment = (data) => {
        this.setState({ isLoadingBarShow: true, isDeleted: true });
        const formData = new FormData();
        if (this.props.loads) {
            formData.append("reference_number", this.props.loads.reference_number);
        } else {
            formData.append(
                "reference_number",
                this.props.selectedLoads.reference_number
            );
        }
        formData.append("paymentId", data._id);
        removePaymentTMSLoad(formData).then((da) => {
            this.setState({nextTab : this.state.tab},()=>{
                this.props.blockTabChange(false);
            })
            this.setState({ isLoadingBarShow: false, isDeleted: false, paymentHistory: da.data.data?.paymentHistory });
            toastr.show("You have removed this payment.", "success");
            if (this.props.updateList) {
                this.props.updateList(da.data.data);
            } else {
                this.props.dispatch({
                    type: TMS_GET_USER_LOAD,
                    reference_number: this.props.selectedLoads
                        ? this.props.selectedLoads.reference_number
                        : this.props.loads.reference_number,
                    payload: {
                        skip: this.state.activePage - 1,
                        limit: this.state.limit,
                    },
                });
            }
        });
    };
    changeSubTab = (tab) => {
        if(this.props.isBlockingTabChange){
            this.props.showPrompt(true) ;
            this.setState({nextTab : tab})
        } else{
            this.setState({
                tab: tab,
                addPayment: false,
                amount: "",
                notes: "",
                paymentDate: "",
                checkNo: "",
                customerId: "",
                documentFile: "",
                documentType: "",
                isRequied: "transition-white-field",
                isDocType: "transition-white-field",
                additionalPricingId: null,
                customerIdValue: null,
                isFailed: "transition-white-field",
                documentUrl: null,
                carrierPayHistory:[],
                selectedCarrierPay:null
            })
        }
       
    }
    renderReturn = () => {
        var selectedLoads =
            this.props.selectedLoads && this.props.selectedLoads.length == 0
                ? undefined
                : this.props.selectedLoads;
        if (this.props.loads) {
            selectedLoads = this.props.loads;
        }
        let filteredPayment = [];
        if (
            this.state.tab == 1 &&
            selectedLoads &&
            selectedLoads.paymentHistory &&
            selectedLoads.paymentHistory.length > 0
        ) {
            filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
                return (
                    !payment.customerId ||
                    payment.customerId == "undefined" ||
                    payment.customerId == this.state.customerId
                );
            });
        }
        if (
            this.state.tab == 2 &&
            selectedLoads &&
            selectedLoads.paymentHistory &&
            selectedLoads.paymentHistory.length > 0
        ) {
            filteredPayment = selectedLoads.paymentHistory.filter((payment) => {
                return (
                    payment.customerId && payment.customerId != this.state.customerId
                );
            });
        }

        return (
            <React.Fragment>
                <div className="card p-15 mb-0 d-flex flex-row align-items-center justify-content-between border-top">
                    <ul className="nav nav-compact" role="tablist">
                        <li className="nav-item">
                            <a
                                className={`nav-link ${this.state.tab == 1 && "active"}`}
                                onClick={() => this.changeSubTab(1)}
                                role="tab"
                            >
                                Main Customer
                            </a>
                        </li>
                        {getStorage("currentUserRole") != "customer" && (
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${this.state.tab == 2 && "active"}`}
                                    onClick={() => this.changeSubTab(2)}
                                    role="tab"
                                >
                                    Sub Customer
                                </a>
                            </li>
                        )}
                        {getStorage("currentUserRole") != "customer" && (
                        <li className="nav-item">
                            <a
                                className={`nav-link ${this.state.tab == 3 && "active"}`}
                                onClick={() => {
                                    this.changeSubTab(3)
                                    this.setState({isFetching:true})
                                    getCarrierPayPaymentHistory(this.state.loadId).then((data)=>{
                                        if(data){
                                            this.setState({
                                                carrierPayHistory:data.data.data,
                                                prevCarrierPay: data.data.data,
                                                selectedCarrierPay:data.data.carrierPay,
                                                isFetching:false,
                                            })
                                        }
                                    }).catch((err)=>{
                                        console.log("err",err)
                                        this.setState({isFetching:false})
                                    })
                                }}
                                role="tab"
                            >
                                Carrier
                            </a>
                        </li>
                        )}
                    </ul>
                    <div className="ml-auto">
                        <span>Balance Due</span>
                        <div
                            className="ml-15 border pt-1 pb-1 pl-3 pr-3 d-inline rounded-lg border-0 font-16"
                            style={{ backgroundColor: "#EBF1FE", color: '#172A41' }}
                        >
                            {this.state.tab === 1 && `$ ${ selectedLoads?.remainAmount.toFixed(2)}`}
                            {this.state.tab === 2 && `$ ${ selectedLoads?.additionalPricing.reduce((total, d) => total + d.remainAmount, 0).toFixed(2)}`}
                            {this.state.tab === 3 && `$ ${this.state?.selectedCarrierPay?.calculation?.remainAmount ? this.state.selectedCarrierPay?.calculation?.remainAmount?.toFixed(2) : "0.00"}`}
                            {/* $ {this.state.tab === 1 ?
                                selectedLoads?.remainAmount.toFixed(2)
                                :
                                selectedLoads?.additionalPricing.reduce((total, d) => total + d.remainAmount, 0).toFixed(2)
                            } */}
                        </div>
                    </div>
                </div>

                {this.state.tab == 1 && !this.props.type && (
                    <React.Fragment>
                        {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment}></PaymentForm> */}
                        {this.renderPaymentHistory(filteredPayment, 8)}
                    </React.Fragment>
                )}

                {this.state.tab == 2 && !this.props.type && (
                    <React.Fragment>
                        {/* <PaymentForm allCustomers={this.props.customers} tab={this.state.tab} savepayment={this.savepayment} load={selectedLoads}></PaymentForm> */}
                        {this.state.allCustomers && this.state.allCustomers.length > 0 && (
                            <React.Fragment>{this.renderPaymentHistory(filteredPayment, 10)}</React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {this.state.tab == 3 && !this.props.type && (
                    <React.Fragment>
                        {this.renderPaymentHistory()}
                    </React.Fragment>
                )}

                {/* {this.state.showAddPaymentModal && renderModal()} */}
            </React.Fragment>
        );
    };
    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser)
            if (
                loggedInUser.role === "carrier" ||
                loggedInUser.role === "customer" ||
                loggedInUser.role === "fleetcustomer"
            )
                return this.renderReturn();
            else if (loggedInUser.permissions.length !== 0)
                if (
                    loggedInUser.permissions.includes("customer_service_payments") ||
                    (loggedInUser.role === "fleetmanager" &&
                        loggedInUser.fleetManager.isCustomer &&
                        loggedInUser.permissions.includes("customer_employee_load_payments"))
                )
                    return this.renderReturn();
                else
                    return (
                        <div className="nopermission">
                            <h1>You do not have permission to view this page</h1>
                        </div>
                    );
            else
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
    }
}



function mapStateToProps(state) {
    return {
        selectedLoads: state.TmsReducer.selectedLoads,
        customers: state.TmsReducer.customers,
    };
}

export default connect(mapStateToProps, null)(Payment);
