import _ from "lodash"
import { HTTP, getStorage, isStopOffOn } from "../services"
import moment from "moment"
import { MOVE_TYPES } from "constants"
import { APPOINTMENT_STATUSES } from "constants"
import { TERMINAL_TRACKING_PARAMETER_KEYS } from "pages/tms/constant";

export const chatTabs = [
  {
    chatTabID: "driver",
    label: "Driver",
    messageSnippets: [],
  },
  {
    chatTabID: "fleetmanager",
    label: "Employee",
    messageSnippets: [],
  },
  {
    chatTabID: "groups",
    label: "Group",
    messageSnippets: [],
  },
  // {
  //     chatTabID: 'group',
  //     label: 'Customer',
  //     messageSnippets: [],
  // },
]

export const validateEmail = (email) => {
  if (!email) return true
  let data = email.trim()
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !re.test(data)
}
export const isImage = (file) => file["type"].includes("image")

export const isImageFromUrl = (url) => {
  if (!url) return false
  const ext = url.split(/[#?]/)[0].split(".").pop().trim()
  return ["png", "jpg", "jped", "svg"].includes(ext)
}

export const splitEmailsFromString = (emails) => {
  var newEmails = []

  if (!emails) return newEmails

  if (_.isArray(emails)) {
    const splitted = emails.join(",").split(",")
    newEmails = [...splitted]
  } else {
    let multipleEmail = emails.includes(",")

    if (multipleEmail) {
      newEmails = [...newEmails, ...emails.split(",")]
    } else {
      newEmails.push(emails)
    }
  }

  newEmails = newEmails.filter((email) => !validateEmail(email))

  return _.compact(newEmails)
}

export const isCustomerPortalUser = () => {
  return getStorage("currentUserRole") === "customer"  // customer
    || (JSON.parse(getStorage("loggedInUser"))?.fleetManager?.isCustomer) // employee of that customer
    || getStorage("currentUserRole") === "fleetcustomer" // 
}

export const DriverItineraryDragDropType = {
  ASSIGNED_LOAD: 'assigned_load',
  UNASSIGNED_LOAD: 'unassigned_load',
  COMPLETED_LOAD: 'completed_load'
}

export const STOPOFF_TYPES = () => {
  return isStopOffOn() ? JSON.parse(getStorage("userBasicSettings"))?.stopoff : []
}

export const portShifts = [
  { port: 'YTI', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '17:00:00', shift2EndTime: '03:00:00' },
  { port: 'TTI', shiftCount: 3, shift1StartTime: '07:00:00', shift1EndTime: '16:59:00', shift2StartTime: '18:00:00', shift2EndTime: '02:30:00', shift3StartTime: '03:00:00', shift3EndTime: '06:30:00' },
  { port: 'WBCT', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '17:00:00', shift2EndTime: '03:00:00' },
  { port: 'PCT', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '18:00:00', shift2EndTime: '03:00:00' },
  { port: 'EVERPORT TERMINAL', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '16:00:00', shift2StartTime: '17:00:00', shift2EndTime: '02:00:00' },
  { port: 'TRAPAC', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '18:00:00', shift2EndTime: '03:00:00' },
  { port: 'PIER A', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '17:00:00', shift2EndTime: '03:00:00' },
  { port: 'ITS TERMINAL', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '17:00:00', shift2EndTime: '03:00:00' },
  { port: 'TERMINAL 18', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:15:00' },
  { port: 'TERMINAL 30', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:15:00' },
  { port: 'WUT', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:00:00' },
  { port: 'PCT EVERPORT', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:15:00' },
  { port: 'OICT', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '17:00:00', shift2StartTime: '18:00:00', shift2EndTime: '03:00:00' },
  { port: 'EVERPORT OAK', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '16:00:00', shift2StartTime: '18:00:00', shift2EndTime: '02:15:00' },
  { port: 'TRAPAC OAK', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '16:30:00', shift2StartTime: '18:00:00', shift2EndTime: '01:30:00' },
  { port: 'FIT TERMINAL', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:30:00' },
  { port: 'HUSKY TERMINAL & STEVEDORING', shiftCount: 1, shift1StartTime: '07:00:00', shift1EndTime: '16:50:00' },
  { port: 'TERMINAL 5', shiftCount: 1, shift1StartTime: '00:00:00', shift1EndTime: '24:00:00' },
  { port: 'LBCT', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '16:45:00', shift2StartTime: '17:00:00', shift2EndTime: '03:00:00' },
  { port: 'FENIX', shiftCount: 2, shift1StartTime: '07:00:00', shift1EndTime: '16:45:00', shift2StartTime: '17:00:00', shift2EndTime: '02:15:00' },
  { port: 'GCT BAYONNE', shiftCount: 1, shift1StartTime: '00:00:00', shift1EndTime: '24:00:00' },
  { port: 'APM', shiftCount: 1, shift1StartTime: '00:00:00', shift1EndTime: '24:00:00' },
  { port: 'APM TERMINAL', shiftCount: 3, shift1StartTime: '08:00:00', shift1EndTime: '16:59:00', shift2StartTime: '18:00:00', shift2EndTime: '03:00:00', shift3StartTime: '03:00:00', shift3EndTime: '08:00:00' },
]

export const getShiftBasedOnTime = (time, portCode) => {
  const currDate = moment(new Date()).format('MM/DD/YYYY')
  const currentDatetime = getAppendedDateTime(currDate, time)
  const currPortShift = portShifts.find(port => port.port === portCode)
  const isPrevDayShift = currentDatetime >= getAppendedDateTime(currDate, "00:00:00") && currentDatetime <= getAppendedDateTime(currDate, "03:00:00")
  if (currPortShift) {
    if (compareShiftTime(currentDatetime, currDate, currPortShift.shift1StartTime, currPortShift.shift1EndTime, time))
      return { shift: "shift1", isPrevDayShift }
    else if (currPortShift.shiftCount >= 2 && compareShiftTime(currentDatetime, currDate, currPortShift.shift2StartTime, currPortShift.shift2EndTime, time, true)) // adjust date to compare the new day time
      return { shift: "shift2", isPrevDayShift }
    else if (currPortShift.shiftCount >= 3 && compareShiftTime(currentDatetime, currDate, currPortShift.shift3StartTime, currPortShift.shift3EndTime, time))
      return { shift: "shift3", isPrevDayShift }
    return { shift: "", isPrevDayShift }
  }
  return { shift: "", isPrevDayShift }
}

export const getAppendedDateTime = (date = "", time = "") => {
  const datetime = new Date(`${date} ${time}`)
  return datetime
}

export const compareShiftTime = (currentDatetime, currDate, shiftStartTime = "", shiftEndTime = "", time = "", shouldChangeDate = false) => {
  const tomorrowDate = moment(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)).format('MM/DD/YYYY')
  const tommorrowDatetime = getAppendedDateTime(tomorrowDate, time)
  const dayChange = shouldChangeDate && currentDatetime >= getAppendedDateTime(currDate, "00:00:00") && currentDatetime <= getAppendedDateTime(currDate, "03:00:00")
  return (dayChange ? tommorrowDatetime : currentDatetime) >= getAppendedDateTime(currDate, shiftStartTime) &&
    (dayChange ? tommorrowDatetime : currentDatetime) < getAppendedDateTime((dayChange || shouldChangeDate) ? tomorrowDate : currDate, shiftEndTime)
}

export const checkIfPickupTimeExists = (moveType = "", load = {}, skipUserCheck = false) => {
  let pickupTimeExists = false
  for (let i = 0; i < load.pickupTimes?.length; i++) {
    if (load.pickupTimes[i]?.pickupFromTime && load.pickupTimes[i]?.pickupToTime) {
      pickupTimeExists = true
      break
    }
  }
  if (skipUserCheck)
    return moveType === MOVE_TYPES.PICKUP && pickupTimeExists
  return moveType === MOVE_TYPES.PICKUP && load.isUserEnteredPickupTimes && pickupTimeExists
}


export const checkIfReturnTimeExists = (moveType = "", load = {}) =>
  moveType === MOVE_TYPES.EMPTY && load.isUserEnteredReturnTimes && load.returnFromTime && load.returnToTime


export const checkIfPickupApptCancelled = (moveType = "", load = {}) =>
  moveType === MOVE_TYPES.PICKUP && load.pickUpApptStatus === APPOINTMENT_STATUSES.CANCELLED && load.pickupAppointmentSystem


export const checkIfReturnApptCancelled = (moveType = "", load = {}) =>
  moveType === MOVE_TYPES.EMPTY && load.emptyApptStatus === APPOINTMENT_STATUSES.CANCELLED && load.emptyAppointmentSystem

export const checkIfPickupReturnTimesExists = (moveType = "", load = {}) => {
  const loadItems = Array.isArray(load) ? load : [load]
  let isPickUpTimeExists = false
  let isReturnTimeExists = false
  for (let i = 0; i < loadItems.length; i++) {
    isPickUpTimeExists = checkIfPickupTimeExists(moveType, loadItems[i])
    isReturnTimeExists = checkIfReturnTimeExists(moveType, loadItems[i])
    if (isPickUpTimeExists || isReturnTimeExists)
      break
  }
  return isPickUpTimeExists || isReturnTimeExists
}

export const checkIfMoveIsCancelled = (moveType = "", load = {}) => {
  const loadItems = Array.isArray(load) ? load : [load]
  let isPickUpApptCancelled = false
  let isReturnApptCancelled = false
  for (let i = 0; i < loadItems.length; i++) {
    isPickUpApptCancelled = checkIfPickupApptCancelled(moveType, loadItems[i])
    isReturnApptCancelled = checkIfReturnApptCancelled(moveType, loadItems[i])
    if (!isPickUpApptCancelled && !isReturnApptCancelled)
      break
  }
  return isPickUpApptCancelled || isReturnApptCancelled
}

export const hasOnlyUndefined = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== undefined) {
      return false // If any non-undefined element is found, return false
    }
  }
  return true // If all elements are undefined, return true
}


export const isValidGlCode = (code) => {
  if(!code) return false;
  return /^\d+(-\d+)?-?$/.test(code);
}


export const removeTerminalTrackingField = (terminal = {}, skipFieldKeys = []) => {
  if(!terminal) return null;

  terminal = _.cloneDeep(terminal);
  terminal.name = terminal?.name?.props?.label ?? terminal?.name;

  TERMINAL_TRACKING_PARAMETER_KEYS.forEach((key) => {
    if(!skipFieldKeys.includes(key)) delete terminal[key];
  });

  return terminal;
}

export const removeTerminalTrackingFields = (terminals = [], skipFieldKeys = []) => {
  return terminals?.map(d => removeTerminalTrackingField(d, skipFieldKeys)) ?? [];
}

// trackingHelpers.js

export const getTrackingEventClasses = (isVoided, isCompleted, isOnProgress, isNotStarted) => {
  const legState = isVoided
    ? "voided"
    : isCompleted
      ? "completed"
      : isOnProgress
        ? "inProgress"
        : isNotStarted
          ? "notStarted"
          : "notStarted";

  return {
    dotColorClass: {
      voided: "bg-danger outline-danger-700",
      completed: "bg-gray-500 outline-gray-700 shadow-inset-light-1",
      inProgress: "bg-primary-500 outline-primary-700 shadow-inset-light-1",
      notStarted: "bg-white outline-gray-100",
    }[legState],
    routeLineColorClass: {
      completed: "route-line--completed",
      inProgress: "route-line--in-progress",
      voided: "route-line--voided-out",
      notStarted: "route-line--not-started",
    }[legState],
    badgeColorClass: {
      completed: "badge-gray-400 text-white",
      inProgress: "badge-primary text-white",
      notStarted: "badge-light text-dark",
      voided: "badge-light text-dark", // added voided state
    }[legState],
    eventCircleColorClass: {
      completed: "bg-gray-500 outline-gray-700 text-white shadow-inset-1 outline-1",
      inProgress: "bg-primary-500 outline-primary-700 text-white shadow-inset-1 outline-1",
      voided: "bg-white",
      notStarted: "bg-white outline-gray-100 text-dark shadow-light-1 outline-1",
    }[legState],
  };
};

export const getTrackingEventClassesForNewSupportMove = (isVoided, isCompletedEvent, isInProgressEvent, isNotStartedEvent, isSupportMove = false) => {
  const legState = isVoided ? "voided" : isCompletedEvent ? "completed" : isInProgressEvent ? "inProgress" : isNotStartedEvent ? "notStarted" : "notStarted";
  return {
    dotColorClass: {
      voided: "bg-danger outline-danger-700",
      completed: "bg-gray-500 outline-gray-700 shadow-inset-light-1",
      inProgress: isSupportMove ? "bg-purple-500 outline-purple-700 shadow-inset-light-1" : "bg-primary-500 outline-primary-700 shadow-inset-light-1",
      notStarted: "bg-white outline-gray-100",
    }[legState],
    routeLineColorClass: {
      completed: "route-line--completed",
      inProgress: isSupportMove ? "route-line--in-progress-support": "route-line--in-progress",
      voided: "route-line--voided-out",
      notStarted: "route-line--not-started",
    }[legState],
    badgeColorClass: {
      completed: "badge-gray-400 text-white",
      inProgress: isSupportMove ? "bg-purple-500 text-white" : "bg-primary-500 text-white",
      notStarted: "badge-light text-dark",
      voided: "badge-light text-dark", // added voided state
    }[legState],
    eventCircleColorClass: {
      completed: "bg-gray-500 outline-gray-700 text-white shadow-inset-1 outline-1",
      inProgress: isSupportMove ? "bg-purple-500 outline-purple-700 text-white shadow-inset-1 outline-1" : "bg-primary-500 outline-primary-700 text-white shadow-inset-1 outline-1",
      voided: "bg-white",
      notStarted: "bg-white outline-gray-100 text-dark shadow-light-1 outline-1",
    }[legState],
  };
};

export const isBooleanValue = (value) => {
  return /false|true/.test(value);
}
