import React, { Component, memo } from 'react';
// import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { browserHistory } from 'react-router';
// import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { cloneDeep } from 'lodash';
import { debounce } from "throttle-debounce";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import DraftPasteProcessor from "draft-js/lib/DraftPasteProcessor";
import draftToHtml from "draftjs-to-html";
import jsonLogic from "json-logic-js";
import moment from "moment";

import { CustomIconNotes } from "Components/Common/CustomIcons/Index";
import CustomCreatableSelect from "Components/Common/CustomSelect/creatable";
import CustomSelect from "Components/Common/CustomSelect/index";
import { getTruckersByCustomer } from "Components/Common/FloatingChat/actionCreators";
import { LoaderBar } from 'Components/Common/LoaderBar';
import {
    IconAngleArrowDown,
    IconCalendar,
    IconCircleClose,
    IconDownload,
    IconInfo,
    IconInfoCircleFilled,
    IconInfoCircleFilledWhite,
    IconMinus,
    IconPlus,
    IconSendEmail,
} from "Components/Common/Icons/index";
import { CustomIconSaveOutline } from "Components/Common/CustomIcons/Index";
import NewPriceInput from "Components/Common/PriceInput/NewPriceInput";
import { getPricingRules } from "Components/DynamicPricing/actionCreators";
import {
    checkIsTaxable,
    convertUnit,
    DateTimeFormatUtils,
    defaultAddressFormat,
    Currency,
    getBulkExchangeRates,
    getNewTranscation,
    getPricingSettingsOptions,
    getStorage,
    isCustomerAccount,
    isGlobalizationEnabled,
    isMilitaryTime,
    showForTerminal,
    toastr,
    treeToJsonParser,
    types,
    checkCombineInvoice,
    getEmailConfiguration,
    isShiposCarrier,
    isCreditMemo,
    hasVATPermissionForUser,
    isNewChargeCode,
    isMarginEnabled,
    amplitudeTrack,
    isCustomerLogin,
    defaultNumberSystem,
    filterChargeCodesByScreenType
} from "services/Common.services";
import { validateEmail } from "utils";
import { PREVENT_BILLING_DATE_CLEAR_INVOICE_STATUS, TMS_GET_USER_LOAD } from "pages/tms/constant";
import {
    getSettings,
    saveLoadNotes,
} from "pages/tms/services";
import * as tmsAction from "pages/tms/Load/actionCreator";
import NewLoadExpense from "pages/tms/Load/NewLoadExpense";
import confirmCharge from "Components/Common/NewChargeConflictAlert";
import "pages/tms/Load/style.css";
import { emailInvoice, getInvoiceDesign } from "pages/tms/DocumentConfiguration/actionCreators";
import { invoice, rateConf } from "pages/tms/DocumentConfiguration/constants";
import PricingComponent from "pages/tms/Load/PricingComponent";
import tabChangeConfirm from "pages/tms/Load/TabChangeConfirm";
import NewAdditionalPricing from "pages/tms/Load/NewAdditionalPricing";
import DateWrapper from "pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import SelectCustomComponent from "pages/tms/SelectComponent/index";
import { newSmallSelectStyle } from "assets/js/select-style";
import configuration from "config";
import CustomTooltip from "Components/Common/CustomTooltip";
import { NumberFormatterV2 } from "Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import { smallSelectStyle } from "assets/js/select-style";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import { setDefaultTaxType } from "pages/tms/Load/actionCreator";
import ExistingCreditMemos from "pages/tms/CreditMemo/components/ExistingCreditMemos";
import CreditMemoModal from "pages/tms/CreditMemo/components/CreditMemoModal/CreditMemoModal";
import SelectCustomCurrency from "pages/tms/SelectCurrency/index"
import { CREDITMEMODISPLAY } from "pages/tms/CreditMemo/constants";
import BillingMainTab from "pages/tms/Load/NewBillingComponents/index";
import billingConstant from 'pages/tms/Load/Billing/Constants/billingData.json'
import { addPricing, changeDefaultTaxType, removePricing, replaceConflictedCharge, updatePricing,getBillingChargeLists } from 'pages/tms/Load/Billing/actionCreator';
import { VATTYPES } from 'pages/tms/CreditMemoV2/constants';
import { LOAD_CHARGE_STATUS } from 'pages/tms/carrier/AccountReceivable/constants';
import { chargeNameMapper } from '../../../../../../../services';

const MANUALLY_CHANGED = 'MANUALLY_CHANGED';
const commodityItems = {
    commodity: "",
    description: "",
    pieces: "",
    units: "",
    weight: "",
    weightUnitType: "",
    pallets: "",
    palletsUnits: "",
};

const { INVOICED, PARTIALLY_PAID, PAID, APPROVED, DRAFT,UNAPPROVED,REBILLING } = LOAD_CHARGE_STATUS;
class ChargesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [{ ...commodityItems }],
            APPROVED: false,
            UNAPPROVED: false,
            REBILLING: false,
            BILLING: false,
            statusname: props.loads.status,
            paymentDate: new Date(),
            showConfirmModel: false,
            extraEmail: [],
            ccEmail: [],

            unApproveDisabler: false,
            approveDisabler: false,
            invoiceDisabler: false,
            approveAndInvoiceDisabler: false,
            rebillDisabler: false,

            showNote: false,
            isEmailSendInProcess: false,
            pricingRules: [],
            editorState: EditorState.createEmpty(),
            inpSubject: null,
            billingNote: {},
            officeNote: {},
            asce: true,
            isLoadTabActive: false,
            showUploadModal: false,
            showSendEmailModal: false,
            isCustomerTabActive: props.billingActiveTab ? props.billingActiveTab : "main-customer",
            isSubExpBillTabActive: "bill",
            isBillActive: true,
            billingNoteLoader: true,
            officeNoteLoader: true,
            name: "",
            description: "",
            unit: 1,
            amount: 0,
            finalAmount: "",
            manualFinalAmount: 0,
            perType: "",
            chargeType: MANUALLY_CHANGED,
            freeDays: 0,
            chargePerDay: 0,
            chargePerMinute: 0,
            isAPICall: false,
            isSuccess: false,
            nameColor: "transition-white-field",
            isFailed: "transition-white-field",
            type: "percentage",
            rowIndex: null,
            isSubPrompt: false,
            isEdited: false,
            addSub: false,
            selectedCell: null,
            nextClick: null,
            approvalStatus: props?.loads?.approvalStatus ? props?.loads?.approvalStatus : "",
            openHistory: false,
            allHistory: [],
            historyLoader: false,
            taxInfo: null,
            isTaxable: checkIsTaxable(),
            expenseLoading: false,
            expenseInInvoiceCurrency: 0,
            expenseInBranchCurrency: 0,
            invoiceConversionList: [],
            branchConversionList: [],
            defaultTaxType: this.props.loads?.defaultTaxType,
            isVatEnabled: JSON.parse(getStorage("userBasicSettings"))?.isVAT,
            existingCreditMemos: {
                mainCustomer: [],
                subCustomer: []
            },
            isShowMemoModal: false,
            AllCreditMemo: props.allCreditMemo,
            dynamicPricingId: null,
            chargeName: "",
            labelName: "",
            labelValue: "",
            taxType : this.props?.billingRow?.taxDetail?.defaultTaxType === "APPLICABLE" ? "APPLICABLE" : "EXEMPT",
            conflictedCharge : null,
            isCalculateTax : JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax,
        };

        this.pricingDescription = null;
        this.pricingFreeUnit = null;
        this.handleextraEmailChange = this.handleextraEmailChange.bind(this);
        this.handleccEmailChange = this.handleccEmailChange.bind(this);
        this.pricingSettingsOptions = getPricingSettingsOptions();
        this.saveNotesDebounce = debounce(2000, this.submitNotes);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.DeletePriceRow = this.DeletePriceRow.bind(this);
        this.pricingchanges = this.pricingchanges.bind(this);
        this.unselectCell = this.unselectCell.bind(this);
        this.userTimeZone = getStorage("timeZone");
        this.chargeCodeList = [];
        if (this.props.chargeCode?.length) {
            this.chargeCodeList = _.orderBy(this.props.chargeCode, "orderIndex", "asc");
        }
        this.userTimeFormat = isMilitaryTime();
        this.firebaseRef = React.createRef();
        this.isNewChargeCodeEnabled = isNewChargeCode()
        this.isShipOsCarrier = isShiposCarrier()
        this.isVATPermission = hasVATPermissionForUser()
        this.isCustomerLoggedIn = isCustomerLogin()
    }

    selectCell = (name) => {
        if (this.state.selectedCell === name) return
        this.setState({ selectedCell: name });
    }

    unselectCell = () => {
        this.setState({ selectedCell: null });
    }

    handleExpenseUpdate = () => { };
    addCustomerPriceRows = () => { };
    updateAdditionalPricing = () => { };

    handleCustomerTabActive = (i) => {
        this.setState({
            isCustomerTabActive: i,
        });
    };

    handleSubExpBillTabActive = (i) => {
        this.setState({
            isSubExpBillTabActive: i,
        });
    };

    gettaxNote = (loadInfo) => {
        let taxNote = loadInfo?.taxDetail?.taxNote  ?? "";
        let currency = loadInfo?.billToDetail?.currency;
        const numberSystem = defaultNumberSystem()
        let currencyCode = currency?.currencyCode ?? "USD"
        let currencySymbol = currency?.symbol ?? "$";
        let currencyType = numberSystem?.currencyDisplayMode === "symbol" ? currencySymbol : currencyCode
        let totalAmount = Number(loadInfo?.totalAmount)?.toFixed(2).toCurrency(currency);
        totalAmount = totalAmount.replaceAll(currencyType,"").trim();
        if (taxNote?.includes("[currency-code]")) {
          taxNote = taxNote.replaceAll("[currency-code]", currencyType);
        }
        if (taxNote?.includes("[document-amount]")) {
          taxNote = taxNote.replaceAll("[document-amount]", totalAmount);
        }
        return taxNote;
    }

    componentWillMount() {
        this.getPricingRules();
        if (this.props.selectedLoads && this.props.selectedLoads._id) {
            this.getLoad(this.props.selectedLoads.reference_number);
        } else {
            this.componentWillReceiveProps(this.props);
        }
    } 
    keyPressOnNumberInput = (evt) => {
        var invalidChars = ["+", "e"];
        if (invalidChars.includes(evt.key)) {
            evt.preventDefault();
        }
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
        return true;
    };

    componentDidMount() {
        if (getStorage("loggedInUser") != null) {
            this.setCcEmail()
            this.props.handleCleartableData(() => this.clearAddPricing());

            if (this.props.billingRow.taxDetail?.defaultTaxType === "APPLICABLE") {
                this.setState({
                    ...this.state,
                    taxType: "APPLICABLE"
                });
            } else {
                this.setState({
                    ...this.state,
                    taxType: "EXEMPT"
                });
            }
            this.state.pricing &&
                this.state.pricing.length > 0 &&
                this.state.pricing.map((item, key) => {
                    if (this?.props?.billingRow && this?.props?.billingRow?.chargeConflicts?.length > 0 && this?.props?.billingRow?.isDefault == true) {
                        this.setState({
                            conflictedCharge: this?.props?.billingRow?.chargeConflicts
                        });
                    }
                })
        }
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        // Check if billingRow props has changed
        if (prevProps?.billingRow !== this?.props?.billingRow) {
            if (this?.props?.billingRow && !this?.props?.billingRow?.chargeConflicts?.length > 0 && this?.props?.billingRow?.isDefault == true) {
                this.setState({
                    conflictedCharge: null
                });
            }
        
          // Update state based on the new billingRow value
          this.setState({
            taxType: this?.props?.billingRow?.taxDetail?.defaultTaxType === "APPLICABLE" ? "APPLICABLE" : "EXEMPT",
          });
        }
      }


    setCcEmail() {
        let ccEmail = "";
        let ccEmails = [];
        let inpBody = "";

        if (this.props && this.props.selectedLoads) {
            inpBody = "Please review attached billing invoice.";
            ccEmails = [
                ...ccEmails,
                this.props.selectedLoads.carrier.carrier.billingEmail,
                this.props.selectedLoads.caller.extraEmail,
            ];
            if (
                this.props.selectedLoads.customerEmployee &&
                this.props.selectedLoads.customerEmployee.length > 0 &&
                this.props.selectedLoads.customerEmployee[0].billingEmail
            ) {
                let customerEmployeeEmails = this.props.selectedLoads.customerEmployee.map(
                    (D) => D.billingEmail
                );
                ccEmails = [...ccEmails, ...customerEmployeeEmails];
            }
            ccEmail = ccEmails.join(",");
        } else if (this.props.loads) {
            inpBody = "Please review attached billing invoice.";
            let billingEmail =
                this.props.loads.carrier.carrier && this.props.loads.carrier.carrier.billingEmail
                    ? this.props.loads.carrier.carrier.billingEmail
                    : "";
            ccEmails = [...ccEmails, billingEmail, this.props.loads.caller.extraEmail];
            if (
                this.props.loads.customerEmployee &&
                this.props.loads.customerEmployee.length > 0 &&
                this.props.loads.customerEmployee[0].billingEmail
            ) {
                let customerEmployeeEmails = this.props.loads.customerEmployee.map(
                    (D) => D.billingEmail
                );
                ccEmails = [...ccEmails, ...customerEmployeeEmails];
            }
            ccEmail = ccEmails.join(",");
        }
        this.setState({
            inpBody,
            ccEmail: ccEmail ? ccEmail.split(",").compact(true) : [],
        })
    } // refactor this function

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    getPricingRules() {
        getPricingRules()
            .then((pricingRules) => {
                this.setState({ pricingRules });
            })
            .catch((error) => {
                console.log(" -*-*-* error get pricing rules -*-*-*- ", error);
            });
    }

    componentWillReceiveProps(props) {
        if (
            this.props.pricing !== props.pricing ||
            (props.pricing && !this.state.pricing)
        ) {
            let base_price = 0,
            x_charges = 0;
            let updatedPricing = props.pricing.filter((x) => x);
            if (updatedPricing && updatedPricing.length > 0) {
                updatedPricing = _.sortBy(updatedPricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
                updatedPricing.map((x) => {
                if (x) {
                    if (x.name == "Base Price") {
                    base_price +=  parseFloat(x.finalAmount)  ;
                    } else {
                    x_charges = (
                        parseFloat(x_charges) + parseFloat(x.finalAmount)
                    ).toFixed(2);
                    }
                    x.manualFinalAmount = x.finalAmount;
                }
                })
            }
            
            this.setState({ pricing: updatedPricing })
        }
    } // only creditMemo code required in this function

    findMatchingPricingRule(chargeName) {
        let selectedLoads = undefined;
        if (this.props.selectedLoads) {
            selectedLoads = this.props.selectedLoads;
        } else {
            selectedLoads = this.props.loads;
        }

        const loads = {
            ...selectedLoads,
            caller: selectedLoads.caller && selectedLoads.caller._id,
            shipper: selectedLoads.shipper && selectedLoads.shipper.map((s) => s._id),
            consignee:
                selectedLoads.consignee && selectedLoads.consignee.map((s) => s._id),
            chassisPick: selectedLoads.chassisPick && selectedLoads.chassisPick._id,
            emptyOrigin: selectedLoads.emptyOrigin && selectedLoads.emptyOrigin._id,
            chassisTermination:
                selectedLoads.chassisTermination &&
                selectedLoads.chassisTermination._id,
            containerType:
                selectedLoads.containerType && selectedLoads.containerType._id,
            containerSize:
                selectedLoads.containerSize && selectedLoads.containerSize._id,
            containerOwner:
                selectedLoads.containerOwner && selectedLoads.containerOwner._id,
            chassisType: selectedLoads.chassisType && selectedLoads.chassisType._id,
            chassisSize: selectedLoads.chassisSize && selectedLoads.chassisSize._id,
            chassisOwner:
                selectedLoads.chassisOwner && selectedLoads.chassisOwner._id,
            terminal: selectedLoads.terminal && selectedLoads.terminal._id,
            commodityProfile:
                selectedLoads.commodityProfile &&
                selectedLoads.commodityProfile.map((s) => s._id),
        };

        const { pricingRules } = this.state;
        const newPricingFromSettings = [];
        let weight = 0;

        if (selectedLoads.items) {
            selectedLoads.items.forEach((obj) => {
                weight += parseInt(obj.weight);
            });
        }
        selectedLoads.weight = weight;
        pricingRules
            .filter((rule) => rule.name === chargeName && rule.isManual)
            .forEach((obj) => {
                let isAllConditionMatched = true;

                if (obj.query && obj.query[0] && obj.query[0].children.length > 0) {
                    const rule = JSON.stringify(treeToJsonParser(obj.query)[0]);
                    const data = JSON.stringify(loads);
                    isAllConditionMatched = jsonLogic.apply(
                        JSON.parse(rule),
                        JSON.parse(data)
                    );
                }

                if (isAllConditionMatched) {
                    let totalDays = 0;
                    if (obj.type === "fixed") {
                        obj.finalAmount = obj.amount;
                    } else if (obj.type === "perpound") {
                        totalDays = weight;
                    }
                    obj.totalDays = totalDays;
                    newPricingFromSettings.push(obj);
                }
            });
        return newPricingFromSettings[0];
    }

    pricingchanges(index, e) {
        let { name, value } = e.target;
        const old = [...this.state.pricing];

        const positiveValuesOnly = [
            "freeDays",
            "chargePerDay",
        ];

        const parseFloatValue = parseFloat(value) || 0;

        if (positiveValuesOnly.includes(name)) {
            if (parseFloatValue >= 0) {
                old[index][name] = value;
            } else {
                toastr.show("You can not add a negative number.", "error");
            }
        } else {
            if (name === "amount" && !value) value = 0
            old[index][name] = value;
        }
        const basePrice = old.find((obj) => {
            return obj.name === "Base Price";
        });

        const data = this.findMatchingPricingRule(old[index]['name']);
        if (name === "name") {

            if (data) {
                let units = 0;
                const freeDays = data.freeDays || 0;
                const chargePerDay = data.amount || 0;

                if (data.type === "fixed") units = 1;

                let finalAmount = (units - freeDays) * chargePerDay;

                if (finalAmount < data.minimumAmount) finalAmount = data.minimumAmount;

                old[index]["description"] = data.description;
                old[index]["perType"] = data.type;
                old[index]["freeDays"] = freeDays > 0 ? freeDays : 0;
                old[index]["chargePerDay"] = chargePerDay;
                old[index]["unit"] = units;
                old[index]["finalAmount"] = finalAmount;
                old[index]["manualFinalAmount"] = 0;
                old[index]["amount"] = finalAmount;

                this.setState({
                    pricing: [...old],
                    rowIndex: index,
                });
                return;
            }
        }
        if (name === "type") {
            old[index]["finalAmount"] =
                old[index]["type"] === "fixed"
                    ? old[index]["amount"]
                    : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
                    100;
            old[index]["manualFinalAmount"] = old[index]["finalAmount"];
        } else if (name === "amount") {
            old[index]["finalAmount"] =
                old[index]["type"] === "fixed"
                    ? old[index]["amount"]
                    : (old[index]["amount"] * (basePrice ? basePrice.finalAmount : 0)) /
                    100;

            old[index]["manualFinalAmount"] = old[index]["finalAmount"];
        } else if (name === "finalAmount" && value) {
            old[index]["manualFinalAmount"] = value;
        } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
            let computedAmount = (parseFloat(old[index].unit) - parseFloat(old[index].freeDays)) * parseFloat(old[index].chargePerDay);

            old[index][name] = value;
            if (old[index].name != "Fuel") {
                if (computedAmount >= parseFloat(old[index].manualFinalAmount || 0)) {
                    old[index]["finalAmount"] = computedAmount.toFixed(2);
                } else {
                    old[index]["finalAmount"] = parseFloat(old[index].manualFinalAmount || 0).toFixed(2);
                }
            }

            if ((name == "perType" && value == "percentage")
                || (name !== "perType" && old[index]["perType"] == "percentage")) {
                if (old[index]["name"] !== 'Fuel') {
                    let basePriceObj = this.state.pricing;
                    if (!data?.percentageOf?.includes("ALL")) {
                        basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price') });
                    }
                    const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
                    if (basePriceObj.length > 0) {
                        // old[index]["chargePerDay"] = basePriceAmount;
                        old[index]["finalAmount"] = [data?.minimumAmount || 0, basePriceAmount * (value / 100)].max();
                        old[index]["dynamicPricingId"] = data._id;
                    }
                }
            }

        }

        if (!old[index].chargeType || old[index].chargeType !== MANUALLY_CHANGED) {
            old[index].chargeType = MANUALLY_CHANGED;
        }
        this.setState({
            pricing: [...old],
        });
    }

    DeletePriceRow(index) {
        let data = [...this.state.pricing];
        let payloadData = {};
        if (data[index]?._id) {
            payloadData.pricingId = data[index]._id;
            // if (this.props.selectedLoads) {
            //     payloadData.loadId = this.props.selectedLoads._id;
            // } else {
            //     payloadData.loadId = this.props.loads._id;
            // }
            payloadData.chargeId = this.props.billingRow._id;
            payloadData.chargeName = data[index]?.name;
            this.setState({ isAPICall: true });
            removePricing(payloadData).then((response) => {
                // const updatedData = data.filter((obj) => obj.id+"" !== response._id)
                // this.props.updateChargeInChargeList(response)
                const data = {
                    "chargeId": this?.props?.billingRow?._id
                }
                this.props.billingAction.getBillingChargeLists(data).then((res) => {
                    if (res?.data?.statusCode === 200) {
                        this.props.updateChargeInChargeList(res?.data?.data[0])
                    }
                }).catch()
                
                let base_price = 0, x_charges = 0;
                let allPricings = response.pricing.filter((x) => x);
                // allPricings = _.sortBy(allPricings, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
                // if (allPricings && allPricings.length > 0) {
                //     allPricings.map((x) => {
                //         if (x) {
                //             if (x.name == "Base Price") {
                //                 base_price += parseFloat(x.finalAmount);
                //             } else {
                //                 x_charges = (
                //                     parseFloat(x_charges) + parseFloat(x.finalAmount)
                //                 ).toFixed(2);
                //             }
                //             x.manualFinalAmount = x.finalAmount;
                //         }
                //     });
                // }
                this.setState({
                    Total: response.totalAmount,
                    pricing: allPricings,
                    isAPICall: false,
                    base_price,
                    x_charges
                }, () => {
                    this.props.handleReloadOfBillingSummary()
                })
                toastr.show(`Your pricing has been deleted!`, "success");
            }).catch((err) => {
                console.log("🚀 ~ file: ChargesTable.js:589 ~ ChargesTable ~ removePricing ~ err:", err)
                this.setState({ isAPICall: false })
            });
        } else {
            this.setState({ addCharge: false }, () => {
                this.props.blockTabChange(false)
                this.clearAddPricing();
            })
        }
    }


    async send_Email(isDownload) {
        return new Promise((resolve, reject) => {
            let load = {};
            let progressField = "";
            if (this.props.loads) {
                load = this.props.loads
            } else {
                load = this.props.selectedLoads
            }
            let query = {
                typeOfDocument: invoice,
            };
            if (this.state.isRateConfirm) {
                query.typeOfDocument = rateConf
            }
            if (load.reference_number) {
                query.reference_number = load.reference_number
            }
            if (!isDownload) {
                query.isEmailing = true
            }
            if (!isDownload) {
                progressField = "isEmailSendInProcessNew";

            } else {
                progressField = "isDownloadInProcess";
            }
            this.setState({ [progressField]: true });
            if (isDownload) {
                getInvoiceDesign(query).then((result) => {
                    const fileName = `${this.props.loads
                        ? this.props.loads.reference_number
                        : this.props.selectedLoads.reference_number
                        }-invoice.pdf`;
                    var byteArray = new Uint8Array(result.data[0].data);
                    var a = window.document.createElement("a");
                    a.href = window.URL.createObjectURL(
                        new Blob([byteArray], { type: "application/octet-stream" })
                    );
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    resolve(byteArray)
                    this.setState({ [progressField]: false })
                }).catch(err => { toastr.show("Failed to download invoice", "error"); this.setState({ [progressField]: false }); })
            } else {

                let formData = {};
                if (this.props.loads) {
                    formData.reference_number = this.props.loads.reference_number
                } else {
                    formData.reference_number = this.props.selectedLoads.reference_number

                }
                if (this.state.inpSubject) {
                    formData.subject = this.state.inpSubject
                }
                if (this.state.inpBody) {
                    formData.body = this.state.inpBody
                }

                if (this.state.extraEmail.length > 0) {
                    formData.email = this.state.extraEmail.join(",")
                }
                if (this.state.ccEmail.length > 0) {
                    formData.cc = this.state.ccEmail.join(",")
                }
                formData.typeOfDocument = query.typeOfDocument
                emailInvoice({ ...formData, ...query })
                    .then(res => {
                        toastr.show("email sent", "success");
                        this.setState({ [progressField]: false, showConfirmModel: false, isRateConfirm: false });
                    }).catch(err => {
                        toastr.show("Something went wrong, Please try again", "error");
                        this.setState({ [progressField]: false, isRateConfirm: false })
                    })
            }
        })
    }

    handleextraEmailChange(extraEmail) {
        this.setState({ extraEmail });
    }
    handleccEmailChange(ccEmail) {
        this.setState({ ccEmail });
    }

    dynamicStringByLoadInfo(str, data) {
        var allData = str.split(" ");
        var pattern = /\@(.*?)\@/g;
        allData.filter((obj) => {
            let vals = obj.match(pattern);
            if (vals) {
                vals.forEach((key) => {
                    if (key === "@referenceNo@") {
                        str = str.replace(key, data.secondaryReferenceNo || "");
                    } else if (key == "@combinedInvoice@" || key === "@invoiceNo@" || key === "@LoadNo@") {
                        str = checkCombineInvoice(allData, key, str, data);
                    } else if (key === "@consigneeName@") {
                        str = str.replace(key, data.consigneeName || "");
                    } else if (key === "@weight@") {
                        str = str.replace(key, data.totalWeight || "");
                    } else {
                        str = str.replace(key, data[key.substr(1, key.length - 2)] || "");
                    }
                });
            }
        });
        return str;
    }

    async setLoadEmails(load, document) {
        if (load && load.caller) {
            this.setState({ extraEmail: load.caller.billingEmail ? load.caller.billingEmail.split(',').filter(Boolean) : [load.caller.email] })
        }
        const updateState = {
            showConfirmModel: load,
        };
        const emails = [];
        const cc = [];
        let editorState;
        let carrierId;
        let body;
        let subject;
        let emailConfiguration = await getEmailConfiguration(load?.terminal?._id || load?.terminal);

        if (getStorage("currentUserRole") == "fleetmanager") {
            let loggedInUser = JSON.parse(getStorage("loggedInUser"));
            carrierId = loggedInUser.fleetManager.carrier;
        } else {
            carrierId = getStorage("currentUserID");
        }

        body =
            emailConfiguration?.emailBody
                ? this.dynamicStringByLoadInfo(
                    emailConfiguration?.emailBody,
                    load
                )
                : `<p>Please review attached billing ${document || invoice}s.</p>`;
        subject =
            emailConfiguration?.emailSubject
                ? this.dynamicStringByLoadInfo(
                    emailConfiguration?.emailSubject,
                    load
                )
                : `${document == rateConf ? rateConf : 'Billing'} for Load ${load.reference_number}`;

        const processedHTML = DraftPasteProcessor.processHTML(body);
        const contentState = ContentState.createFromBlockArray(processedHTML);
        editorState = EditorState.createWithContent(contentState);
        editorState = EditorState.moveFocusToEnd(editorState);
        updateState.inpSubject = subject;
        updateState.inpBody = body;
        emails.push(load.caller.billingEmail || load.caller.email);
        cc.push(load.carrier.carrier.billingEmail);
        cc.push(load.caller.extraEmail);
        if (load.customerEmployee && load.customerEmployee.length > 0) {
            load.customerEmployee.forEach((employee, i) => {
                if (employee.billingEmail) {
                    cc.push(employee.billingEmail);
                }
            });
        }
        updateState.emails = emails.compact(true);
        updateState.cc = cc.compact(true);
        if (document == rateConf) {
            updateState.isRateConfirm = true
        } else {
            updateState.isRateConfirm = false
        }
        this.setState(updateState);
        this.setState({ editorState });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            inpBody: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        });
    };

    redirectToRates = (chargeItem) => {
        if (chargeItem.chargeType === billingConstant.CHARGE_TYPES.CUSTOMER_RATES.name) {
            browserHistory.push('/tms/load-price-settings?rateId=' + chargeItem.customerRateId);
        }
        else if (chargeItem.chargeType === billingConstant.CHARGE_TYPES.DYNAMIC_PRICING.name) {
            this.props.closeCustomerServiceSideBar()
            browserHistory.push('/dynamic-pricing?rateId=' + chargeItem.dynamicPricingId);
        } else if (chargeItem.chargeType === billingConstant.CHARGE_TYPES.RADIUS_RATES.name) {
            browserHistory.push("/tms/load-price-settings?rateId=" + chargeItem.radiusRateId + "&rateType=radius_rate")
        }
    }
    
    confirmChargeConlicts = (index, charge, conflictedCharge, chargeCode) => {
        confirmCharge(
            (confirm, selectedCharge) => {
                if (confirm) {
                    const _pricing = cloneDeep(this.state.pricing);
                    _pricing[index] = selectedCharge;

                    const otherConflicts = this.state.conflictedCharge && this.state.conflictedCharge.filter((cc) => cc.name !== selectedCharge.name);
                    if (selectedCharge.chargeType === billingConstant.CHARGE_TYPES.DYNAMIC_PRICING.name && otherConflicts.length > 0) {
                        otherConflicts.forEach((cc) => {
                            const cIndex = this.state.pricing.findIndex((price) => price.name === cc.name);
                            if (cIndex >= -1) _pricing[cIndex] = cc;
                        })
                    }
                    this.conflictedPricing(selectedCharge, index)
                }
            },
            {
                charge,
                conflictedCharge: conflictedCharge,
                chargeCode,
                currency: this?.props?.billingRow?.billToDetail?.currency
            }
        );
    }

    conflictedPricing = (pricing, index) => {
        let payload = {};
        payload.chargeId = this?.props?.billingRow?._id;
        if (pricing.chargeType) {
            payload.preferredRateType = pricing.chargeType;
        }
        replaceConflictedCharge(payload)
            .then((response) => {
                const data = {
                    "chargeId": this?.props?.billingRow?._id
                }
                this.props.billingAction.getBillingChargeLists(data).then((res) => {
                    if (res?.data?.statusCode === 200) {

                        if (res?.data?.data[0]?.chargeConflicts?.length > 0) {
                            this.setState({
                                conflictedCharge: res?.data?.data[0]?.chargeConflicts,
                            });
                        } else {
                            this.setState({
                                conflictedCharge: null,
                            });
                        }
                        this.props.updateChargeInChargeList(res?.data?.data[0])
                    }
                }).catch()

                this.setState({ isSuccess: true, rowIndex: index, isFailed: "transition-white-field" }, () => { this.props.handleReloadOfBillingSummary() });
                toastr.show(`Your pricing has been updated!`, "success");
                setTimeout(() => {
                    this.setState({ isSuccess: false });
                }, 400);
            })
            .catch((err) => {
                this.setState({ isFailed: "background-error" }, () => {
                    setTimeout(() => {
                        this.setState({ isFailed: "background-failure" })
                    }, 400);
                });
            })
    }

    handleSubmit = (e) => {
        if (!this.state.name) {
            this.setState({ nameColor: "background-error" }, () => {
                setTimeout(() => {
                    this.setState({ nameColor: "background-failure" })
                }, 400);
            });
            return false;
        }
        if (this.state.selectedCell) return
        if (
            !this.state.isAPICall &&
            this.state.isEdited &&
            this.state.name) {
            let payload = {};
            payload.pricing = {
                "name": this.state.name,
                "amount": this.state.amount,
                "chargePerDay": this.state.chargePerDay ? parseFloat(this.state.chargePerDay).toFixed(2) : 0,
                "chargePerMinute": this.state.chargePerMinute ? parseFloat(this.state.chargePerMinute).toFixed(2) : 0,
                "finalAmount": +(parseFloat(this.state.finalAmount) || 0).toFixed(2),
                "unit": (parseFloat(this.state.unit) || 0).toFixed(2),
                "freeDays": this.state.freeDays ? parseFloat(this.state.freeDays).toFixed(2) : 0,
                "perType": this.state.perType,
                "chargeType": this.state.chargeType,
                "customerRateId": null,
                "dynamicPricingId": this.state.dynamicPricingId,
                "description": this.state.description,
                "type": this.state.type,
                "percentageOf": this.state.percentageOf
            }
            if (this.isNewChargeCodeEnabled) {
                payload.pricing["chargeName"] = this.state.chargeName
                payload.pricing["labelName"] = this.state.labelName
                payload.pricing["labelValue"] = this.state.labelValue
            }
            payload.chargeId = this.props.billingRow._id;
            this.setState({ isAPICall: true, rowIndex: this.state.pricing.length }, () => this.props.blockTabChange(true))
            addPricing(payload)
                .then((response) => {
                    // this.props.updateChargeInChargeList(response)
                    toastr.show("Updated Successfully", "success")
                    const data = {
                        "chargeId": this?.props?.billingRow?._id
                    }
                    this.props.billingAction.getBillingChargeLists(data).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            this.props.updateChargeInChargeList(res?.data?.data[0])
                        }
                    }).catch()

                    this.setState({ Total: response.totalAmount, isAPICall: false, isEdited: false, isSuccess: true, isFailed: "transition-white-field" });
                    let x_charges = 0, base_price = 0;
                    response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
                    response.pricing.map((x) => {
                        if (x) {
                            if (x.name == "Base Price") {
                                base_price += parseFloat(x.finalAmount);
                            } else {
                                x_charges = (
                                    parseFloat(x_charges) + parseFloat(x.finalAmount)
                                ).toFixed(2);
                            }
                            x.manualFinalAmount = x.finalAmount;
                        }
                    });
                    this.setState({ base_price, x_charges });
                    // toastr.show(`Your pricing has been added`, "success");
                    setTimeout(() => {
                        this.setState({ isSuccess: false });
                    }, 400);
                    this.setState({ pricing: response.pricing, addCharge: false, selectedCell: null }, () => {
                        document.querySelector(`#addChargeFocus`)?.focus()
                        this.props.blockTabChange(false)
                        this.props.handleReloadOfBillingSummary()
                    });
                    this.clearAddPricing();
                })
                .catch((err) => {
                    this.setState({ isFailed: "background-error", isAPICall: false }, () => {
                        this.props.blockTabChange(false);
                        setTimeout(() => {
                            this.setState({ isFailed: "background-failure" })
                        }, 400);
                    });
                })
        }
    }

    clearAddPricing = () => {
        this.setState({
            chargeName: "",
            labelName: "",
            labelValue: "",
            name: "",
            description: "",
            unit: 1,
            amount: 0,
            finalAmount: "",
            perType: "",
            chargeType: MANUALLY_CHANGED,
            freeDays: 0,
            chargePerDay: 0,
            chargePerMinute: 0,
            type: "",
            manualFinalAmount: 0
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    nextFunction() {
        if (["approveCheckbox", "chkApproved", "lblApproved"].includes(this.state.nextClick)) {
            this.onApprove();
            this.DeletePriceRow(this.state.pricing?.length)
            this.setState({ nextClick: null })
        } else if (["approveBillingCheckbox", "chkApprovedBilling", "lblApprovedBilling"].includes(this.state.nextClick)) {
            this.onApprovedBillling()
            this.DeletePriceRow(this.state.pricing?.length)
            this.setState({ nextClick: null })
        }
    }
    checkStatusOfLoad() {
        let selectedLoads;
        if (this.props.loads) {
            selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
        } else if (this.props.selectedLoads) {
            selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
        } else {
            selectedLoads = undefined;
        }
        return selectedLoads.status
    }
    handleClickOutside(event) {
        const status = this.checkStatusOfLoad();
        if (this.props.isShowingPrompt && this.props.isBlockingTabChange) {
            if (document.querySelector("#continueWthoutSaving")?.contains(event.target)) {
                this.nextFunction()
            } else if (document.querySelector("#cancleChanges")?.contains(event.target)) {
                this.setState({ nextClick: null })
            }
        }
        if ((document.querySelector("#approveBillingCheckbox")?.contains(event.target) ||
            document.querySelector("#approveCheckbox")?.contains(event.target) ||
            document.querySelector("#chkApprovedBilling")?.contains(event.target) ||
            document.querySelector("#chkApproved")?.contains(event.target) ||
            document.querySelector("#lblApprovedBilling")?.contains(event.target) ||
            document.querySelector("#lblApproved")?.contains(event.target)) && this.props.isBlockingTabChange && ['COMPLETED', 'UNAPPROVED'].includes(status)) {
            this.props.showPrompt(true)
            this.setState({ nextClick: event.target?.id })
        } else {
            if (
                this.wrapperRef &&
                !this.wrapperRef.contains(event.target) &&
                !document.querySelector("[role='tablist']")?.contains(event.target) &&
                (document.querySelector(".tab-loads").contains(event.target) || document.querySelector(".fcpopup__left").contains(event.target))
            ) {
                if (this.state.selectedCell) return
                this.handleSubmit()
            }
        }
    }
    updateRowPricing = (index, chargeDetail) => {
        this.setState({ rowIndex: index })
        let pricing = this.state.pricing;
        let currentRow = pricing[index];
        if (this.isNewChargeCodeEnabled && chargeDetail && chargeDetail?.labelValue) {
            currentRow.chargeName = chargeDetail?.chargeName
            currentRow.labelName = chargeDetail?.labelName
            currentRow.labelValue = chargeDetail?.labelValue
        }
        currentRow.finalAmount = +(parseFloat(currentRow.finalAmount) || 0).toFixed(2);
        currentRow.unit = (parseFloat(currentRow.unit) || 0).toFixed(2);
        currentRow.chargePerDay = currentRow.chargePerDay ? parseFloat(currentRow.chargePerDay).toFixed(2) : 0;
        currentRow.freeDays = currentRow.freeDays ? parseFloat(currentRow.freeDays).toFixed(2) : 0;
        delete currentRow.subChargeType;
        currentRow.chargeType = MANUALLY_CHANGED;
        if (currentRow?.invoiceCurrency) {
            currentRow.invoiceCurrency = currentRow?.invoiceCurrency?._id;
        }
        if (!currentRow.name) {
            this.setState({ nameColor: "background-error" }, () => {
                setTimeout(() => {
                    this.setState({ nameColor: "background-failure" })
                }, 400);
            });
            return false;
        }
        let payload = {};
        // if (this.props.selectedLoads) {
        //     payload.loadId = this.props.selectedLoads._id;
        // } else {
        //     payload.loadId = this.props.loads._id;
        // }
        delete currentRow.manualFinalAmount;
        payload.pricing = currentRow;
        payload.chargeId = this.props.billingRow._id;
        this.setState({ isAPICall: true });
        updatePricing(payload)
            .then((response) => {
                if (response) {
                    toastr.show("Updated Successfully", "success")
                    // this.props.updateChargeInChargeList(response)
                    const data = {
                        "chargeId": this?.props?.billingRow?._id
                    }
                    this.props.billingAction.getBillingChargeLists(data).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            this.props.updateChargeInChargeList(res?.data?.data[0])
                        }
                    }).catch()

                    this.setState({ defaultTaxType: response.defaultTaxType, Total: response.totalAmount, isSuccess: true, isAPICall: false });
                    let x_charges = 0, base_price = 0;
                    response.pricing = _.sortBy(response.pricing, (price) => this.chargeCodeList.findIndex((charge) => charge.value === price.name));
                    response.pricing.map((x) => {
                        if (x) {
                            if (x.name == "Base Price") {
                                base_price += parseFloat(x.finalAmount);
                            } else {
                                x_charges = (
                                    parseFloat(x_charges) + parseFloat(x.finalAmount)
                                ).toFixed(2);
                            }
                            x.manualFinalAmount = x.finalAmount;
                        }
                    });
                    this.setState({ base_price, x_charges });
                    // toastr.show(`Your pricing has been updated!`, "success");
                    setTimeout(() => {
                        this.setState({ isSuccess: false });
                    }, 400);
                    this.setState({ pricing: response.pricing, addCharge: false }, () => {
                        this.props.blockTabChange(false)
                        this.props.handleReloadOfBillingSummary()
                    });
                }
            })
            .catch((err) => {
                toastr.show("Something went wrong! Please try again.", "error");
                this.setState({ isFailed: "background-error", isAPICall: false }, () => {
                    this.props.blockTabChange(false);
                    setTimeout(() => {
                        this.setState({ isFailed: "background-failure" })
                    }, 400);
                });
            })

    }

    newRowChanges(e) {
        const { name, value } = e.target;
        const old = [...this.state.pricing];

        const basePrice = old.find((obj) => {
            return obj.name === "Base Price";
        });
        const data = this.findMatchingPricingRule(this.state.name);
        let { type, amount, unit, freeDays, chargePerDay, perType, manualFinalAmount, finalAmount } = this.state;
        if (name === "type") {
            finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
            manualFinalAmount = finalAmount;
        } else if (name === "amount") {
            finalAmount = type === "fixed" ? amount : (amount * (basePrice ? basePrice.finalAmount : 0)) / 100;
            manualFinalAmount = finalAmount;
        } else if (name === "finalAmount" && value) {
            manualFinalAmount = value;
        } else if (["unit", "freeDays", "chargePerDay", 'perType'].includes(name)) {
            let computedAmount = (parseFloat(unit) - parseFloat(freeDays)) * parseFloat(chargePerDay);
            if (this.state.name !== 'Fuel') {
                if (computedAmount >= parseFloat(manualFinalAmount || 0)) {
                    finalAmount = computedAmount.toFixed(2);
                } else {
                    finalAmount = parseFloat(manualFinalAmount || 0).toFixed(2);
                }
            }

            if ((name == "perType" && value == "percentage") || (name !== "perType" && perType == "percentage")) {
                if (this.state.name !== 'Fuel') {
                    finalAmount = (this.state.base_price * unit / 100).toFixed(2);
                    if (data) {
                        let basePriceObj = this.state.pricing;
                        if (!data?.percentageOf?.includes("ALL")) {
                            basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price') });
                        }
                        const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
                        if (basePriceObj.length > 0) {
                            finalAmount = [data?.minimumAmount || 0, basePriceAmount * (unit / 100)].max();
                            this.setState({ dynamicPricingId: data._id });
                        }
                    }
                }
            }

        }

        this.setState({
            finalAmount: finalAmount,
            manualFinalAmount: manualFinalAmount
        });
    }


    onApprove = (e) => {
        this.setState({
            APPROVED: true,
            UNAPPROVED: false,
            REBILLING: false,
            BILLING: false,
            APPROVEDBILLING: false,
            statusname: "APPROVED",
            approveDisabler: true
        })
        let payloadData = {};
        if (this.props.selectedLoads) {
            payloadData.reference_number = this.props.selectedLoads.reference_number;
        } else {
            payloadData.reference_number = this.props.loads.reference_number;
        }
        payloadData.status = "APPROVED";
        tmsAction.approveStatus(payloadData).then((response) => {
            this.setState({ approveDisabler: false })
            toastr.show("Load Status changed to APPROVED.", "success");
        }).catch((err) => {
            this.setState({ approveDisabler: false })
            toastr.show("Something went wrong! Please try again.", "error");
        });
    }

    onUnapprove = () => {
        this.setState({
            APPROVED: false,
            UNAPPROVED: true,
            REBILLING: false,
            BILLING: false,
            APPROVEDBILLING: false,
            statusname: "UNAPPROVED",
            unApproveDisabler: true
        })
        let payloadData = {};
        if (this.props.selectedLoads) {
            payloadData.reference_number = this.props.selectedLoads.reference_number;
        } else {
            payloadData.reference_number = this.props.loads.reference_number;
        }
        payloadData.status = "UNAPPROVED";
        tmsAction.unapproveStatus(payloadData).then((response) => {
            this.setState({ unApproveDisabler: false })
            toastr.show("Load Status changed to UNAPPROVED.", "success");
        }).catch((err) => {
            this.setState({ unApproveDisabler: false })
            toastr.show("Something went wrong! Please try again.", "error");
        });
    }

    onRebilling = (e) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("rebill_loads")) {
            toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
        } else {
            this.setState({
                APPROVED: false,
                UNAPPROVED: false,
                REBILLING: true,
                BILLING: false,
                APPROVEDBILLING: false,
                statusname: "REBILLING",
                rebillDisabler: true
            })
            let payloadData = {};
            if (this.props.selectedLoads) {
                payloadData.reference_number = this.props.selectedLoads.reference_number;
            } else {
                payloadData.reference_number = this.props.loads.reference_number;
            }
            payloadData.status = "REBILLING";
            tmsAction.rebillingStatus(payloadData).then((response) => {
                this.setState({ rebillDisabler: false })
                toastr.show("Load Status changed to REBILLING.", "success");
            }).catch((err) => {
                this.setState({ rebillDisabler: false })
                toastr.show("Something went wrong! Please try again.", "error");
            });
        }
    }

    onBilling = (e) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
            toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
        } else {
            this.setState({
                APPROVED: false,
                UNAPPROVED: false,
                REBILLING: false,
                BILLING: true,
                APPROVEDBILLING: false,
                statusname: "BILLING",
                invoiceDisabler: true
            })
            let payloadData = {};
            if (this.props.selectedLoads) {
                payloadData.reference_number = this.props.selectedLoads.reference_number;
            } else {
                payloadData.reference_number = this.props.loads.reference_number;
            }
            payloadData.status = "BILLING";
            tmsAction.billingStatus(payloadData).then((response) => {
                toastr.show("Load Status changed to BILLING.", "success");
                this.setLoadEmails(
                    this.props.selectedLoads
                        ? this.props.selectedLoads
                        : this.props.loads
                );
                this.setState({ showConfirmModel: true, invoiceDisabler: false });
            }).catch((err) => {
                this.setState({
                    APPROVED: true,
                    UNAPPROVED: false,
                    REBILLING: false,
                    BILLING: false,
                    APPROVEDBILLING: false,
                    statusname: "APPROVED",
                    invoiceDisabler: false
                })
                console.log(err);
            });
        }
    }

    onApprovedBillling = (e) => {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (loggedInUser.role === "fleetmanager" && !loggedInUser.permissions.includes("approve_invoice")) {
            toastr.show("You do not have permission to invoice a bill. Please contact your admin.", "error")
        } else {
            this.setState({
                APPROVED: false,
                UNAPPROVED: false,
                REBILLING: false,
                BILLING: false,
                APPROVEDBILLING: true,
                statusname: "APPROVEDBILLING",
                approveAndInvoiceDisabler: true
            })
            let payloadData = {};
            if (this.props.selectedLoads) {
                payloadData.reference_number = this.props.selectedLoads.reference_number;
            } else {
                payloadData.reference_number = this.props.loads.reference_number;
            }
            payloadData.status = "APPROVED";
            tmsAction.approveStatus(payloadData).then((response) => {
                toastr.show("Load Status changed to APPROVED.", "success");
                payloadData.status = "BILLING";
                tmsAction.billingStatus(payloadData).then((response) => {
                    this.setLoadEmails(
                        this.props.selectedLoads
                            ? this.props.selectedLoads
                            : this.props.loads
                    );
                    this.setState({ showConfirmModel: true, approveAndInvoiceDisabler: false });
                    toastr.show("Load Status changed to BILLING.", "success");
                }).catch((err) => {
                    this.setState({ approveAndInvoiceDisabler: false });
                    console.log(err);
                });
            }).catch((err) => {
                this.setState({ approveAndInvoiceDisabler: false });
                console.log(err);
            });
        }
    }

    onAddSelectCharge = (name, value) => {
        this.setState({
            name: value.value,
            chargeName: value?.chargeName || "",
            labelName: value?.labelName || "",
            labelValue: value?.labelValue || "",
            nameColor: "transition-white-field",
            isEdited: true,
            selectedCell: null,
        });

        if (this.pricingDescription) this.pricingDescription.focus();
        const data = this.findMatchingPricingRule(value.value);
        if (data) {
            let units = 0;
            const freeDays = data.freeDays || 0;
            let chargePerDay = data.amount || 0;

            if (data.type === "fixed") units = 1;

            let finalAmount = (units - freeDays) * chargePerDay;
            if (finalAmount < data?.minimumAmount) finalAmount = data?.minimumAmount;
            if (data.type === 'percentage') {
                let basePriceObj = this.state.pricing;
                if (!data?.percentageOf?.includes("ALL")) {
                    basePriceObj = basePriceObj.filter((obj) => { return (data?.percentageOf?.length ? data.percentageOf?.includes(obj.name) : obj.name === 'Base Price') });
                }
                const basePriceAmount = basePriceObj.sum((obj) => { return parseFloat(obj.finalAmount) });
                if (basePriceObj.length > 0) {
                    finalAmount = [data?.minimumAmount || 0, basePriceAmount * (data.amount / 100)].max();
                    units = data.amount;
                    chargePerDay = basePriceAmount
                    this.setState({ dynamicPricingId: data._id });
                }
            }

            this.setState({
                description: data.description,
                amount: value.value !== 'Fuel' ? finalAmount : data.amount,
                finalAmount: finalAmount,
                unit: units,
                perType: data.type,
                type: data.type,
                freeDays: freeDays > 0 ? freeDays : 0,
                chargePerDay: chargePerDay,
                percentageOf: data?.percentageOf
            });
        }
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            e?.target?.blur();
            this.unselectCell()
            this.handleSubmit();
            e.stopPropagation()
        }
    }

    focusOnButton() {
        const element = document?.getElementById("addChargeFocus")
        // element.style.border = "1px solid black"
    }

    handleOnKeyDown = (e, disabled) => {
        if (e.keyCode === 13) {
            !disabled && this.setState({ addCharge: true }, () => { this.props.blockTabChange(true); this.selectCell("add-Charge") })
            const element = document?.getElementById("addChargeFocus")
            element.style.border = "none"
        } else {
            const element = document?.getElementById("addChargeFocus")
            element.style.border = "none"
            return
        }
    }

    handleCreateMemoModal = () => {
        this.setState({
            isShowMemoModal: false
        })
    }

    setDefaultTax = () => {
        const payload = {
            reference_number: this.props.loads?.reference_number,
            defaultTaxType: this.state.defaultTaxType?.toUpperCase()
        }
        setDefaultTaxType(payload)
            .then((response) => {
                if (response) {
                    this.setState({ defaultTaxType: response.defaultTaxType })
                    toastr.show("Successfully updated!", "success");
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleToggleTaxType = (e) => {
        let data = {
            "chargeId": this.props.billingRow?._id,
            "defaultTaxType": this.props.billingRow.taxDetail?.defaultTaxType
        };
        if (e.target.checked) {
            this.setState({
                ...this.state,
                taxType: "APPLICABLE"
            });


            changeDefaultTaxType({ ...data, defaultTaxType: "APPLICABLE" }).then((res) => {
                if (res?.statusCode === 200) {
                    toastr.show("Updated Successfully", "success")
                    const data = {
                        "chargeId": this?.props?.billingRow?._id
                    }
                    // this.props.updateChargeInChargeList(res?.data)
                    this.props.billingAction.getBillingChargeLists(data).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            this.props.updateChargeInChargeList(res?.data?.data[0])
                            this.props.handleReloadOfBillingSummary()
                            if (res?.data?.data[0]?.taxDetail?.defaultTaxType === "APPLICABLE") {
                                this.setState({ taxType: "APPLICABLE" })
                            } else {
                                this.setState({ taxType: "EXEMPT" })
                            }
                        }
                    }).catch()
                }
            }).catch()

        } else {
            this.setState({
                ...this.state,
                taxType: "EXEMPT"
            });

            changeDefaultTaxType({ ...data, defaultTaxType: "EXEMPT" }).then((res) => {
                if (res?.statusCode === 200) {
                    // this.props.updateChargeInChargeList(res?.data)
                    toastr.show("Updated Successfully", "success")
                    const data = {
                        "chargeId": this?.props?.billingRow?._id
                    }
                    this.props.billingAction.getBillingChargeLists(data).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            this.props.updateChargeInChargeList(res?.data?.data[0])
                            this.props.handleReloadOfBillingSummary()
                        }
                    }).catch()
                }
            }).catch()
        }
    }


    renderReturn = () => {
        var disabled = true;
        let isCredit = false;
        let isVatDisabled = false;
        var billing_date = null;
        var selectedLoads;
        if (this.props.loads) {
            try {
                selectedLoads = JSON.parse(JSON.stringify(this.props.loads));
            } catch (err) {
                selectedLoads = this.props.loads
            }
        } else if (this.props.selectedLoads) {
            selectedLoads = JSON.parse(JSON.stringify(this.props.selectedLoads));
        } else {
            selectedLoads = undefined;
        }
        if (selectedLoads) {
            disabled = [
                "APPROVED",
                "BILLING",
                "PARTIAL_PAID",
                "FULL_PAID",
            ].includes(selectedLoads.status);
            billing_date = selectedLoads.billingDate;
        }

        if (this?.props?.billingRow) {
            disabled = [
                APPROVED,
                INVOICED,
                PARTIALLY_PAID,
                PAID
            ].includes(this?.props?.billingRow?.status);
        }
        
        if(!this?.props?.billingRow?.billToId?._id){
            disabled = true; 
        }

        if (CREDITMEMODISPLAY.includes(selectedLoads.status)) {
            isCredit = true;
        }
        let showSave = false;
        if (selectedLoads) {
            if (
                getStorage("currentUserRole") == "fleetmanager" &&
                JSON.parse(getStorage("loggedInUser")).fleetManager.carrier ==
                selectedLoads.carrier._id
            ) {
                showSave = true;
            } else if (
                getStorage("currentUserRole") == "carrier" &&
                getStorage("currentUserID") == selectedLoads.carrier._id
            ) {
                showSave = true;
            } else if (
                getStorage("currentUserRole") == "customer" &&
                getStorage("currentUserID") == selectedLoads.addedBy._id
            ) {
                showSave = true;
            }
            else if (
                JSON.parse(getStorage("loggedInUser")).role === "fleetmanager" &&
                JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer
            ) {
                disabled = true;
            }
            else if (
                ["customer", "fleetcustomer"].includes(JSON.parse(getStorage("loggedInUser")).role)
            ) {
                disabled = true;
            }
        }
        let { pricing, statusname } = this.state;
        const { chargeCode,billingRow, billingIndex } = this.props;
        const { totalBasePrice, totalAccessorialPrice, totalTaxAmount, taxAmount, taxDetail, totalAmount, totalAmountWithTax } = billingRow;
        const newTaxRate = taxDetail?.taxRate ? taxDetail?.taxRate*100 : 0

       let branchMargin = "0.00";
        let branchMarginAmount = 0;

        if (isMarginEnabled()) {
            const total = (this.props.loads?.totalTaxAmount ? this.props.loads?.totalTaxAmount : this.state.Total) ?? 0
            branchMarginAmount = (parseFloat(total)?.
                convertCurrencyWithFixedRateToCurrencyObject(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate)?.
                subtract(this.state.expenseInBranchCurrency).value ?? 0);
            const marginPercent = (branchMarginAmount / (parseFloat(total ?? 1)?.
                convertCurrencyWithFixedRateToCurrencyObject(selectedLoads?.branchCurrency, selectedLoads?.exchangeRate?.exchangeRate)?.value ?? 1
            ) * 100)?.toFixed(2);

            if (!isNaN(marginPercent) && isFinite(marginPercent)) {
                branchMargin = marginPercent;
            }

        }
        const loggedInUserData = JSON.parse(getStorage("loggedInUser"));
        const isCustAndCustEmpPermission = (["customer"].includes(loggedInUserData.role)) ||
            (["fleetmanager"].includes(loggedInUserData.role) && loggedInUserData?.fleetManager?.isCustomer)
        const isVATPermission = hasVATPermissionForUser()

        if(!this?.props?.billingRow?.billToId?._id){
            isVatDisabled = true; 
        }

        if(this?.props?.billingRow){
            isVatDisabled = [
                APPROVED,
                INVOICED,
                PARTIALLY_PAID,
                PAID
            ].includes(this?.props?.billingRow?.status);
        }
        if(selectedLoads.isDeleted){
            disabled = true;
            isVatDisabled= true;
        }
        return (
            <React.Fragment>
                <div className="tab">
                    <div id="main-customer">
                        <div className="mb-1">
                            {this.props.isSubExpBillTabActive === "credit_memo" &&
                                <ExistingCreditMemos
                                    selectedLoad={selectedLoads}
                                    existingCreditMemos={this.state.AllCreditMemo?.filter((P) => (P.reference_number === this.props.loads?.reference_number && !P.sub_reference_number))}
                                    chargeCode={chargeCode}
                                //   setNewCreditMemo={this.props.setNewCreditMemo}
                                />
                            }
                            {this.props.isSubExpBillTabActive === "bill" && (
                                <div className="bg-gray-50 p-1 mb-10 rounded-5" id="bills">
                                    <div className="position-relative">
                                        <div className="table-responsive">
                                            {<table className="table table-card table-card--wrap table-billing">
                                                {this.state.isAPICall && this.state.addCharge &&
                                                    <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                                                        <span
                                                            class="spinner-border spinner-border-sm mr-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                        Processing...
                                                    </div>
                                                }
                                                <thead>
                                                    <tr>
                                                        <th width="200" >Name of Charge #</th>
                                                        <th width="300">Description</th>
                                                        <th width="210" className="text-right">Price {billingRow?.billToDetail?.currency?.currencyCode ? `(${billingRow?.billToDetail?.currency?.currencyCode})` : ""}</th>
                                                        <th width="160" >Unit Count</th>
                                                        <th width="150" className="z-1">Unit of Measure</th>
                                                        <th width="100" className="text-right">Free Units</th>
                                                        <th width="100" className="text-right">Per Units</th>
                                                        {!this.isCustomerLoggedIn && <th width="42"></th>}
                                                        <th width="42"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pricing &&
                                                        pricing.length > 0 &&
                                                        pricing.map((item, key) => {
                                                            let newConflictedCharge = null;
                                                            if (this?.state?.conflictedCharge?.length > 0) {
                                                                newConflictedCharge = this.state.conflictedCharge.find((charge) => item.chargeType !== MANUALLY_CHANGED && charge.name === item.name && charge.perType === item.perType)
                                                            }
                                                            let chargeValue = this.chargeCodeList && this.chargeCodeList.find((d) => d.value === item.name)
                                                            let charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive)
                                                            if (this.isNewChargeCodeEnabled) {
                                                                charges = this.chargeCodeList && this.chargeCodeList.filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                                                            }

                                                            return (
                                                                <React.Fragment>
                                                                    <PricingComponent
                                                                        billingIndex={billingIndex}
                                                                        index={key}
                                                                        key={key}
                                                                        charges={charges}
                                                                        chargeValue={chargeValue}
                                                                        pricingchanges={this.pricingchanges}
                                                                        item={item}
                                                                        invoiceCurrency={selectedLoads?.invoiceCurrency}
                                                                        branchCurrency={selectedLoads?.branchCurrency}
                                                                        disabled={disabled}
                                                                        conflictedCharge={newConflictedCharge}
                                                                        chargeCode={chargeCode}
                                                                        showSave={showSave}
                                                                        addCharge={this.state.addCharge}
                                                                        DeletePriceRow={this.DeletePriceRow}
                                                                        keyPressOnNumberInput={this.keyPressOnNumberInput}
                                                                        confirmChargeConlicts={this.confirmChargeConlicts}
                                                                        redirectToRates={this.redirectToRates}
                                                                        isAPICall={this.state.isAPICall}
                                                                        isSuccess={this.state.isSuccess}
                                                                        updateRowPricing={this.updateRowPricing}
                                                                        rowIndex={this.state.rowIndex}
                                                                        setWrapperRef={this.setWrapperRef}
                                                                        handleSubmit={this.handleSubmit}
                                                                        selectedCell={this.state.selectedCell}
                                                                        selectCell={this.selectCell}
                                                                        unselectCell={this.unselectCell}
                                                                    />
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                    {this.state.addCharge &&
                                                        <tr className={`select-custom-input newdriverrow shadow-md ${this.state.isFailed}`}
                                                            onKeyDown={(e) => this.handleKeyDown(e)}
                                                            ref={this.setWrapperRef}
                                                        // onKeyDown={(e) => {this.setState({selectedCell:null});e.keyCode === 13 && this.handleSubmit() }}
                                                        >
                                                            <td className={`${this.state.nameColor} cell-1 text-dark ${!this.state.name ? 'requied_hover' : ""} 
                            ${this.state.selectedCell === "add-Charge" ? 'newdriverrow td-clicked' : ""}
                            pl-2 pr-1`}
                                                                style={{ transition: 'background .4s ease-out' }}
                                                                onClick={() => this.selectCell("add-Charge")}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center text-dark">
                                                                    {(this.isNewChargeCodeEnabled && this.state?.chargeName) ? this.state.chargeName : chargeNameMapper(this.state.name, this.chargeCodeList) || <span className="text-muted">Select Charge</span>}
                                                                    <IconAngleArrowDown className='' />
                                                                    {this.state.selectedCell === "add-Charge" &&
                                                                        <SelectCustomComponent
                                                                            name='charges'
                                                                            searchable={true}
                                                                            refClassName="cell-1"
                                                                            listData={
                                                                                (this.isNewChargeCodeEnabled)
                                                                                    ? this.chargeCodeList && filterChargeCodesByScreenType("receivable",this.chargeCodeList).filter((d) => d.isActive && d?.chargeName && d?.labelName && d?.labelValue)
                                                                                    : this.chargeCodeList && filterChargeCodesByScreenType("receivable",this.chargeCodeList).filter((d) => d.isActive)
                                                                            }
                                                                            keyName="chargeName"
                                                                            onSelect={this.onAddSelectCharge}
                                                                            unselectCell={this.unselectCell}
                                                                        />}
                                                                </div>
                                                            </td>
                                                            <td className={`px-0 ${this.state.selectedCell === "description" ? 'newdriverrow td-clicked' : ""}`}
                                                                onClick={() => this.selectCell("description")}
                                                            >
                                                                <input
                                                                    ref={(i) => { this.pricingDescription = i; }}
                                                                    type="text"
                                                                    className={`form-control border-0`}
                                                                    name="description"
                                                                    value={this.state.description || ""}
                                                                    onChange={(e) =>
                                                                        this.setState({ description: e.target.value, isEdited: true })
                                                                    }
                                                                    disabled={disabled}
                                                                    placeholder="Add Description..."
                                                                    autoComplete="off"
                                                                    onBlur={() => this.unselectCell()}
                                                                />
                                                            </td>
                                                            {this.state.name === "Fuel" ? (
                                                                <td
                                                                    className={`lint px-0 cell-7 ${["FA1", "FA2", "fixed"].includes(this.state.selectedCell) && 'newdriverrow td-clicked'}`}
                                                                // onClick={() => this.selectCell("finalAmount")}
                                                                >
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <div className="mr-1 d-flex w-100" onClick={() => this.selectCell("FA1")}>
                                                                            {!isGlobalizationEnabled() && <input
                                                                                className="form-control border-0 text-right"
                                                                                type="number"
                                                                                disabled={disabled}
                                                                                name="amount"
                                                                                min={0}
                                                                                value={this.state.amount || ""}
                                                                                onChange={(e) => {
                                                                                    this.setState({ amount: e.target.value, isEdited: true }, () => {
                                                                                        this.newRowChanges({
                                                                                            target: {
                                                                                                value: e.target.value,
                                                                                                name: "amount",
                                                                                            },
                                                                                        });
                                                                                    });
                                                                                }}
                                                                                autoFocus={["FA1"].includes(this.state.selectedCell)}
                                                                                autoComplete="off"
                                                                                onBlur={() => this.unselectCell()}
                                                                            />}
                                                                            {isGlobalizationEnabled() && (
                                                                                <NumberFormatterV2
                                                                                    selected={["FA1", "FA2", "fixed"].includes(this.state.selectedCell)}
                                                                                    className="form-control border-0 text-right"
                                                                                    type="number"
                                                                                    disabled={disabled}
                                                                                    name="amount"
                                                                                    min={0}
                                                                                    value={this.state.amount || "0"}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ amount: e.target.value, isEdited: true }, () => {
                                                                                            this.newRowChanges({
                                                                                                target: {
                                                                                                    value: e.target.value,
                                                                                                    name: "amount",
                                                                                                },
                                                                                            });
                                                                                        });
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    onBlur={() => this.unselectCell()}
                                                                                    childClassName="form-control border-0 text-right"
                                                                                    autoFocus={false}
                                                                                    showCurrency={false}
                                                                                    disablePlaceholder={true}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        {/* <div className="mr-1 d-flex w-100 custom-select-border price-component">
                                                                            <CustomSelect
                                                                                size="small"
                                                                                placement="right"
                                                                                placeholder="Select..."
                                                                                style={newSmallSelectStyle}
                                                                                options={[
                                                                                    {
                                                                                        value: "fixed",
                                                                                        label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                                                                    },
                                                                                    {
                                                                                        value: "percentage",
                                                                                        label: "%",
                                                                                    },
                                                                                ]}
                                                                                value={
                                                                                    this.state.type && this.state.type === "fixed"
                                                                                        ? {
                                                                                            value: "fixed",
                                                                                            label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                                                                        }
                                                                                        : {
                                                                                            value: "percentage",
                                                                                            label: "%",
                                                                                        }
                                                                                }
                                                                                onChange={({ value }) => {
                                                                                    this.setState({ type: value, isEdited: true }, () => {
                                                                                        this.newRowChanges({
                                                                                            target: {
                                                                                                value: value,
                                                                                                name: "type",
                                                                                            },
                                                                                        });
                                                                                    })
                                                                                    this.unselectCell()
                                                                                }}
                                                                                onBlur={() => this.unselectCell()}
                                                                            />
                                                                        </div> */}
                                                                        <div className="mr-1 d-flex"
                                                                        >
                                                                            <div className="d-flex justify-content-between align-items-center min-width-50 line-height-30 pointer"
                                                                                onClick={() => this.selectCell("fixed")}
                                                                            >
                                                                                <div className="transition-y2">{this.state.type && this.state.type === "fixed" ? `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}` : "%"}</div>
                                                                                <div className="ml-20"><IconAngleArrowDown /></div>
                                                                                {this.state.selectedCell === "fixed" &&
                                                                                    <SelectCustomCurrency
                                                                                        name='value'
                                                                                        searchable={false}
                                                                                        refClassName="cell-7"
                                                                                        listData={[
                                                                                            {
                                                                                                value: "fixed",
                                                                                                label: `${selectedLoads?.invoiceCurrency?.symbol ?? Currency.default()?.symbol ?? '$'}`,
                                                                                            },
                                                                                            {
                                                                                                value: "percentage",
                                                                                                label: "%",
                                                                                            },
                                                                                        ]}
                                                                                        keyName="label"
                                                                                        onSelect={(name, value) => {
                                                                                            this.setState({ type: value.value, isEdited: true }, () => {
                                                                                                this.newRowChanges({
                                                                                                    target: {
                                                                                                        value: value,
                                                                                                        name: "type",
                                                                                                    },
                                                                                                });
                                                                                            })
                                                                                            this.unselectCell()
                                                                                        }}

                                                                                        unselectCell={this.unselectCell}
                                                                                    />}
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex w-100" onClick={() => this.selectCell("FA2")}>
                                                                            {!isGlobalizationEnabled() && <input
                                                                                min={0}
                                                                                placeholder="0.00"
                                                                                onKeyDown={(e) => {
                                                                                    this.keyPressOnNumberInput(e);
                                                                                }}
                                                                                className="form-control border-0 text-right"
                                                                                type="number"
                                                                                disabled={disabled}
                                                                                name="FA2"
                                                                                value={this.state.finalAmount || ""}
                                                                                onChange={(e) => {
                                                                                    this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                                                        this.newRowChanges({
                                                                                            target: {
                                                                                                value: e.target.value,
                                                                                                name: "FA2",
                                                                                            },
                                                                                        });
                                                                                    })
                                                                                }}
                                                                                autoComplete="off"
                                                                                onBlur={() => this.unselectCell()}
                                                                            />}
                                                                            {isGlobalizationEnabled() && (
                                                                                <NumberFormatterV2
                                                                                    selected={["FA1", "FA2", "fixed"].includes(this.state.selectedCell)}
                                                                                    min={0}
                                                                                    placeholder="0.00"
                                                                                    onKeyDown={(e) => {
                                                                                        this.keyPressOnNumberInput(e);
                                                                                    }}
                                                                                    className="form-control border-0 text-right"
                                                                                    type="number"
                                                                                    disabled={disabled}
                                                                                    name="finalAmount"
                                                                                    value={this.state.finalAmount || "0"}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                                                            this.newRowChanges({
                                                                                                target: {
                                                                                                    value: e.target.value,
                                                                                                    name: "finalAmount",
                                                                                                },
                                                                                            });
                                                                                        })
                                                                                    }}
                                                                                    autoComplete="off"
                                                                                    onBlur={() => this.unselectCell()}
                                                                                    showCurrency={false}
                                                                                    autoFocus={false}
                                                                                    childClassName="form-control border-0 text-right"
                                                                                    disablePlaceholder={true}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                <td className={`px-0 ${this.state.selectedCell === "finalAmount" ? 'newdriverrow td-clicked' : ""}`}
                                                                    onClick={() => this.selectCell("finalAmount")}
                                                                >
                                                                    <span data-tip data-for={`discount${pricing.length}`}>
                                                                        {!isGlobalizationEnabled() && <input
                                                                            min={0}
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            className={`form-control border-0 text-right`}
                                                                            disabled={disabled}
                                                                            name="finalAmount"
                                                                            value={this.state.finalAmount || ""}
                                                                            onChange={(e) => {
                                                                                this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                                                    this.newRowChanges({
                                                                                        target: {
                                                                                            value: e.target.value,
                                                                                            name: "finalAmount",
                                                                                        },
                                                                                    });
                                                                                })
                                                                            }}
                                                                            autoComplete="off"
                                                                            onBlur={() => this.unselectCell()}
                                                                        />}
                                                                        {isGlobalizationEnabled() && (
                                                                            <NumberFormatterV2
                                                                                selected={this.state.selectedCell === "finalAmount"}
                                                                                min={0}
                                                                                placeholder="0.00"
                                                                                type="number"
                                                                                className={`form-control border-0 text-right`}
                                                                                disabled={disabled}
                                                                                name="finalAmount"
                                                                                value={this.state.finalAmount || "0"}
                                                                                onChange={(e) => {
                                                                                    this.setState({ finalAmount: e.target.value, isEdited: true }, () => {
                                                                                        this.newRowChanges({
                                                                                            target: {
                                                                                                value: e.target.value,
                                                                                                name: "finalAmount",
                                                                                            },
                                                                                        });
                                                                                    })
                                                                                }}
                                                                                autoComplete="off"
                                                                                onBlur={() => this.unselectCell()}
                                                                                showCurrency={false}
                                                                                childClassName={`form-control border-0 text-right`}
                                                                                autoFocus={true}
                                                                                disablePlaceholder={true}
                                                                            />
                                                                        )}
                                                                    </span>
                                                                    {this.state.discountApplied &&
                                                                        <ReactTooltip effect="solid" id={`discount${pricing.length}`}>
                                                                            <span>A discount has been applied</span>
                                                                        </ReactTooltip>
                                                                    }
                                                                </td>
                                                            )}

                                                            <td
                                                                className={`px-0 ${this.state.selectedCell === "unit" && 'newdriverrow td-clicked'}`}
                                                                onClick={() => this.selectCell("unit")}
                                                            >
                                                                <div>
                                                                    <NewPriceInput
                                                                        name="unit"
                                                                        value={this.state.unit}
                                                                        onChange={(val) => {
                                                                            this.setState({ unit: val, isEdited: true }, () => {
                                                                                this.newRowChanges({
                                                                                    target: {
                                                                                        value: val,
                                                                                        name: "unit",
                                                                                    },
                                                                                });
                                                                            })
                                                                        }}
                                                                        disabled={disabled}
                                                                        onBlur={() => this.unselectCell()}
                                                                        selected={this.state.selectedCell === "unit"}
                                                                    />
                                                                </div>
                                                            </td>

                                                            <td className={`px-1 pl-2 cell-5 ${this.state.selectedCell === "perType" ? 'newdriverrow td-clicked' : ""}`}
                                                                onClick={() => this.selectCell("perType")}
                                                            >
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    {this.state.perType || <span className="text-muted">Select...</span>}
                                                                    <IconAngleArrowDown className='' />
                                                                    {this.state.selectedCell === "perType" &&
                                                                        <SelectCustomComponent
                                                                            name='perType'
                                                                            searchable={true}
                                                                            refClassName="cell-5"
                                                                            listData={options}
                                                                            keyName="label"
                                                                            onSelect={(name, value) =>
                                                                                this.setState({ perType: value.value, isEdited: true, selectedCell: null }, () => {
                                                                                    this.newRowChanges({
                                                                                        target: {
                                                                                            value: value.value,
                                                                                            name: "perType",
                                                                                        },
                                                                                    });
                                                                                    if (this.pricingFreeUnit) this.pricingFreeUnit.focus();
                                                                                })
                                                                            }
                                                                            unselectCell={this.unselectCell}
                                                                        />}
                                                                </div>
                                                            </td>
                                                            <td
                                                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell === "freeDays" && 'newdriverrow td-clicked'}`}
                                                                onClick={() => {
                                                                    if (!this.state.perType) return
                                                                    this.selectCell("freeDays")
                                                                }}
                                                            >
                                                                {!isGlobalizationEnabled() && <input
                                                                    ref={(i) => { this.pricingFreeUnit = i; }}
                                                                    min={0}
                                                                    type="number"
                                                                    className={`form-control border-0 text-right`}
                                                                    name="freeDays"
                                                                    onKeyDown={(e) => {
                                                                        this.keyPressOnNumberInput(e);
                                                                    }}
                                                                    disabled={!this.state.perType || disabled || this.state.perType === "percentage"}
                                                                    value={this.state.freeDays || ""}
                                                                    onChange={(e) => {
                                                                        this.state.perType &&
                                                                            this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                                                                this.newRowChanges({
                                                                                    target: {
                                                                                        value: e.target.value,
                                                                                        name: "freeDays",
                                                                                    },
                                                                                });
                                                                            })
                                                                    }}
                                                                    placeholder="0"
                                                                    autoComplete="off"
                                                                    onBlur={() => this.unselectCell()}
                                                                />}
                                                                {isGlobalizationEnabled() && (
                                                                    <NumberFormatterV2
                                                                        selected={this.state.selectedCell === "freeDays"}
                                                                        ref={(i) => { this.pricingFreeUnit = i; }}
                                                                        min={0}
                                                                        type="number"
                                                                        className={`form-control border-0 text-right`}
                                                                        name="freeDays"
                                                                        onKeyDown={(e) => {
                                                                            this.keyPressOnNumberInput(e);
                                                                        }}
                                                                        disabled={!this.state.perType || disabled || this.state.perType === "percentage"}
                                                                        value={this.state.freeDays || "0"}
                                                                        onChange={(e) => {
                                                                            this.state.perType &&
                                                                                this.setState({ freeDays: e.target.value, isEdited: true }, () => {
                                                                                    this.newRowChanges({
                                                                                        target: {
                                                                                            value: e.target.value,
                                                                                            name: "freeDays",
                                                                                        },
                                                                                    });
                                                                                })
                                                                        }}
                                                                        placeholder="0.0"
                                                                        autoComplete="off"
                                                                        onBlur={() => this.unselectCell()}
                                                                        showCurrency={false}
                                                                        childClassName={`form-control border-0 text-right`}
                                                                        disablePlaceholder={!this.state.perType && !this.state.isFailed}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td
                                                                className={`${!this.state.perType && !this.state.isFailed ? 'disable-input' : ""} px-0 pl-2 ${this.state.selectedCell === "chargePerDay" && 'newdriverrow td-clicked'}`}
                                                                onClick={() => {
                                                                    if (!this.state.perType) return
                                                                    this.selectCell("chargePerDay")
                                                                }}
                                                            >
                                                                {!isGlobalizationEnabled() && <input
                                                                    min={0}
                                                                    type="number"
                                                                    className={`form-control border-0 text-right`}
                                                                    name="chargePerDay"
                                                                    disabled={!this.state.perType || disabled || this.state.perType === "percentage"}
                                                                    value={this.state.chargePerDay || ""}
                                                                    onChange={(e) => {
                                                                        this.state.perType &&
                                                                            this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                                                                this.newRowChanges({
                                                                                    target: {
                                                                                        value: e.target.value,
                                                                                        name: "chargePerDay",
                                                                                    },
                                                                                });
                                                                            })
                                                                    }}
                                                                    placeholder="0"
                                                                    autoComplete="off"
                                                                    onBlur={() => this.unselectCell()}
                                                                />}
                                                                {isGlobalizationEnabled() && (
                                                                    <NumberFormatterV2
                                                                        selected={this.state.selectedCell === "chargePerDay"}
                                                                        min={0}
                                                                        type="number"
                                                                        className={`form-control border-0 text-right`}
                                                                        childClassName={`form-control border-0 text-right`}
                                                                        name="chargePerDay"
                                                                        disabled={!this.state.perType || disabled || this.state.perType === "percentage"}
                                                                        value={this.state.chargePerDay || "0"}
                                                                        onChange={(e) => {
                                                                            this.state.perType &&
                                                                                this.setState({ chargePerDay: e.target.value, isEdited: true }, () => {
                                                                                    this.newRowChanges({
                                                                                        target: {
                                                                                            value: e.target.value,
                                                                                            name: "chargePerDay",
                                                                                        },
                                                                                    });
                                                                                })
                                                                        }}
                                                                        placeholder="0"
                                                                        autoComplete="off"
                                                                        onBlur={() => this.unselectCell()}
                                                                        showCurrency={false}
                                                                        disablePlaceholder={!this.state.perType && !this.state.isFailed}
                                                                    />
                                                                )}
                                                            </td>
                                                            <td className="px-1">

                                                            </td>
                                                            {showSave && (
                                                                <td className="d-flex align-items-center justify-content-center">
                                                                    <button
                                                                        className="btn btn-xs btn-circle bg-soft-danger"
                                                                        disabled={disabled}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                nameColor: "transition-white-field"
                                                                            })
                                                                            !this.state.isAPICall && this.DeletePriceRow(pricing.length)
                                                                        }}
                                                                    >
                                                                        <IconMinus className="text-danger" />
                                                                    </button>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>}

                                        </div>
                                    </div>

                                    {/* ------- */}

                                    <div className="form-row mt-1">
                                        <div className="col-lg-6">
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="d-flex flex-wrap gap-5 mb-1">
                                                <div className="rbox rbox--white text-right d-flex align-items-center gap-10">
                                                    <div className="flex-grow-1">
                                                        <div className="text-muted">Base</div>
                                                        <div className="font-medium">{(totalBasePrice || 0).toCurrency(this?.props?.billingRow?.billToDetail?.currency)}</div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="text-muted">Accessorials</div>
                                                        <div className="font-medium">{(totalAccessorialPrice || 0).toCurrency(this?.props?.billingRow?.billToDetail?.currency)}</div>
                                                    </div>
                                                    <div className="vr-line mx-2"></div>
                                                    <div className="flex-grow-1">
                                                        <div className="text-muted">Total Charges</div>
                                                        <div className="font-medium">{(totalAmount || 0).toCurrency(this?.props?.billingRow?.billToDetail?.currency)}</div>
                                                    </div>
                                                </div>
                                                {this.state.isTaxable && this?.props?.billingRow?.taxDetail?.taxType && <div className="rbox rbox--white d-flex align-items-center justify-content-between text-right">
                                                   {this.state.isVatEnabled && this.isVATPermission ? <div className="form-check form-switch form-switch-md">
                                                        <input type="checkbox" 
                                                        disabled={true} 
                                                        class="form-check-input" 
                                                        id="vat-enabled-billing" 
                                                        onChange={this.handleToggleTaxType} 
                                                        checked={this?.state?.taxType === "APPLICABLE" ? true : false} />
                                                    </div> : <div></div>}
                                                    <div>
                                                        <div className='d-flex align-items-center gap-2'>
                                                            <span className="text-muted">{taxDetail?.taxType} @ </span> 
                                                            <span>{newTaxRate || 0}%</span> 
                                                            {taxDetail?.taxNote && <>
                                                                <div data-tip data-for={`taxNote-${billingIndex}`}>
                                                                    <IconInfoCircleFilledWhite />
                                                                </div>
                                                                <ReactTooltip id={`taxNote-${billingIndex}`} place="top" effect="solid" className="react-tooltip-new">
                                                                    {this.gettaxNote(this.props.billingRow)}
                                                                </ReactTooltip>
                                                            </>}
                                                        </div>
                                                        {((this?.props?.billingRow?.taxDetail?.defaultTaxType === VATTYPES.APPLICABLE && this.state.isVatEnabled) || this.state.isCalculateTax) ? <div className="font-medium">{(taxAmount || 0).toCurrency(this?.props?.billingRow?.billToDetail?.currency)}</div> : VATTYPES.EXEMPT}
                                                    </div>
                                                </div>}
                                                <div className="rbox rbox--primary-100 text-right">
                                                    <div className="text-muted">Billing Total</div>
                                                    <div className="font-medium">{(totalAmountWithTax || 0).toCurrency(this?.props?.billingRow?.billToDetail?.currency)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            )}
                       </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

        if (loggedInUser)
            if (
                loggedInUser.role === "carrier" ||
                loggedInUser.role === "fleetcustomer"
            )
                return this.renderReturn();
            else if (loggedInUser.permissions.length !== 0)
                if (
                    loggedInUser.permissions.includes("customer_service_billing") ||
                    (((loggedInUser.role === "fleetmanager" &&
                        loggedInUser.fleetManager.isCustomer) ||
                        loggedInUser.role === "customer") &&
                        loggedInUser.permissions.includes("customer_employee_load_billing"))
                )
                    return this.renderReturn();
                else
                    return (
                        <div className="nopermission">
                            <h1>You do not have permission to view this page</h1>
                        </div>
                    );
            else
                return (
                    <div className="nopermission">
                        <h1>You do not have permission to view this page</h1>
                    </div>
                );
    }
}

function mapStateToProps(state) {
    return {
        chargeCode: state.chargeCodeReducer.chargeCode
    };
}

function mapDispatchToProps(dispatch) {
    return {
        tmsAction: bindActionCreators(tmsAction, dispatch),
        dispatch: dispatch,
        billingAction: bindActionCreators({ getBillingChargeLists }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChargesTable))


const options = types;

// const CHARGE_TYPES = {
//     CUSTOMER_RATES: { title: 'Customer Rates', shortName: 'CR', name: 'CUSTOMER_RATES' },
//     DYNAMIC_PRICING: { title: 'Dynamic Pricing', shortName: 'DP', name: 'DYNAMIC_PRICING' },
//     SYSTEM_GENERATED: { title: 'System Generated', shortName: 'SYS', name: 'SYSTEM_GENERATED' },
//     MANUALLY_CHANGED: { title: 'Manually Changed', shortName: 'MC', name: 'MANUALLY_CHANGED' },
//     PROFILE_RATES: { title: 'Profile Rates', shortName: 'PR', name: 'PROFILE_RATES' },
//     ZIPCODE_RATES: { title: 'Zip Code Rates', shortName: 'ZR', name: 'ZIPCODE_RATES' },
//     CITYSTATE_RATES: { title: 'City State Rates', shortName: 'CSR', name: 'CITYSTATE_RATES' },
//     RADIUS_RATES: { title: 'Radius Rates', shortName: 'RR', name: 'RADIUS_RATES' }
// };

const VATTypes = [
    {
        value: "APPLICABLE",
        label: "APPLICABLE",
    },
    {
        value: "EXEMPT",
        label: "EXEMPT",
    },
]