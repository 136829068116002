import React, { Component } from "react";
import moment from "moment";
import { getStorage,  timeFormatter, isCustomerAccount, isManageFleetOrOutSource } from "../../../services/Common.services";
import { arrivedDepartedDateTime, driverGeofenceIcon, getRoutesFromDriverOrders } from "./utility";
import TrackingHistoryPlayer from "./elements/TrackingHistoryPlayer";
import { IconCapture, IconCheck, IconDownload, IconNavigationArrowBottom, IconShare, IconTimesThin } from "../../../Components/Common/Icons";
import LiveShareModal from "./LiveShareModal";
import { createLegsFromDriverOrder, legTypeToHuman } from "../../../Components/Common/functions";
import { getLocationHistory } from "./actionCreators";
import ReactTooltip from "react-tooltip";
import { getTrackingEventClasses, isCustomerPortalUser } from "../../../utils";
import EquipmentDetails from "Components/Common/EquipmentDetails/EquipmentDetails";
import { getDistanceBetweenEvents } from "pages/tms/LoadRouting/utilities";
import { useCurrentUser } from "hooks";
import { getDurationInHHMMFormat } from "../Tracking/utility";
import { isUserSpecificTimeZoneEnabled } from "services";

let timeZone = null;

class NewDriverDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelectCard: -1,
      selectedDriverOrder: null,
      selectedLoad: null,
      showModal: false,
      liveShareReferenceNumber: null,
      documents: [],
      hideDriverInfo: false,
      load: null,
    };
    this.isManageFleetOrOutSource = isManageFleetOrOutSource()
    timeZone = getStorage("timeZone");
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d sec",
        m: "%d min",
        mm: "%d min",
        h: "%d hr",
        hh: "%d hr",
        d: "%d day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      },
    });
  }

  componentWillUnmount = () => {
    this.props.geofenceLocationMarkerLayer.clearLayers();
  }

  plotEntryExitPoint = async ({legIndex, load, leg}) => {
    this.props.geofenceLocationMarkerLayer.clearLayers();

    const { driverLegs } = createLegsFromDriverOrder(load?.driverOrder);
    const totalLegs = driverLegs.length;
    
    // get arrived/departed pair
    const arrivedDepartedList = [];
    let dateList = [];
    let arrivedDepartedHashMap = {};
    let nextDataDoesNotExists = false;
    
    leg.forEach((driverOrder, i) => {
      if(i === 0) {
        dateList.push(driverOrder.departed);
      } else {
        dateList.push(driverOrder.arrived);
        dateList.push(driverOrder.departed);
      }
    });
    
    if(legIndex !== totalLegs - 1) {
      dateList.push(driverLegs[legIndex + 1]?.[0]?.arrived);
    } else {
      nextDataDoesNotExists = true;
      dateList.push(moment().toISOString());
    }

    dateList.forEach((date, i) => {
      if(i % 2 == 0) {
        arrivedDepartedHashMap.startDate = date;
      } else {
        arrivedDepartedHashMap.endDate = date;
      }

      if(arrivedDepartedHashMap.startDate && arrivedDepartedHashMap.endDate) {
        arrivedDepartedList.push(JSON.parse(JSON.stringify(arrivedDepartedHashMap)));
        arrivedDepartedHashMap = {};
      }
    });

    const promiseList = [];
    arrivedDepartedList.forEach((data) => {
      promiseList.push(getLocationHistory({
        refNo: encodeURIComponent(load.reference_number),
        startDate: encodeURIComponent(data.startDate),
        endDate: encodeURIComponent(data.endDate),
      }));
    });

    if(promiseList.length > 0) {
      const results = await Promise.allSettled(promiseList);

      results?.forEach((promiseResult, i) => {
        if(promiseResult?.value?.data?.data?.length > 0) {
          console.log("Promise: ", i)
          const locations = [];
          promiseResult?.value?.data?.data?.forEach((responseData) => {
            locations.push(...(responseData?.locations ?? [] ));
          });
          const entry = locations?.[0];
          const exit = locations?.[locations?.length - 1];

          if(entry?.l?.length > 0) {
            const marker = window.L.marker([entry.l?.[0],entry.l?.[1]], {icon: driverGeofenceIcon});
            marker.addTo(this.props.geofenceLocationMarkerLayer);
          }
          
          if(exit?.l?.length > 0) {
            const marker = window.L.marker([exit.l?.[0],exit.l?.[1]], {icon: driverGeofenceIcon});
            marker.addTo(this.props.geofenceLocationMarkerLayer);
          }
        }
      });
    }
  }

  // legIndex, load, leg, driverId
  handleSelectCard = async (i, load = null, leg, driverId = null) => {
    if (!this.props.disableClicks) {
      this.setState({
        // isSelectCard: this.state.isSelectCard === i ? -1 : i,
        isSelectCard: i,
      });
    }

    if (this.props.drawRouteToMap && load) {
      const routes = getRoutesFromDriverOrders(leg, driverId);
      this.props.drawRouteToMap(routes);
    }

    await this.plotEntryExitPoint({legIndex: i, load: load, leg: leg, driverId: driverId});
  };
  handleShowModal = (loadObj) => {
    this.setState({ showModal: true, liveShareReferenceNumber: loadObj.reference_number, load: loadObj });
  };
  onCancel = () => {
    this.setState({ showModal: false, liveShareReferenceNumber: null, load: null });
  };
  getStatusClass = (driverOrder) => {
    if (driverOrder.arrived && driverOrder.departed) {
      return ["complete", "complete", "dlist--complete"];
    } else if (driverOrder.arrived && !driverOrder.departed) {
      return ["active", "active", "dlist--active"];
    } else if (!driverOrder.arrived && !driverOrder.departed) {
      return ["upcoming", "upcoming", "dlist--upcoming"];
    }
    return []
  };

  getTimeDifference(endTime, startTime) {
    if (startTime && endTime) {
      const startTimeObject = moment(startTime);
      const endTimeObject = moment(endTime);
      const duration = moment.duration(endTimeObject.diff(startTimeObject));

      return duration.humanize();
    }
    return null;
  }

  loads = [];
  componentWillReceiveProps(props){
    if (
      (!this.props.allLoads && props.allLoads) ||
      (this.props.allLoads &&
        this.props.allLoads != props.allLoads)
    ) {
      this.setState({ documents: props.allLoads?.documents}, ()=>{
        const length  = props.allLoads.driverOrder?.length;
        for(let i=0; i<length; i++){
          const moveDoc = [...props.allLoads?.documents || {}].reverse().find(doc => doc.moveId === `move-${i}`)
          this.setState({ [`move-${i}`]: moveDoc })
        }
      })
    }
  }
  componentDidMount() {
    const currentUser = JSON.parse(getStorage("loggedInUser"));
    if(this.isManageFleetOrOutSource?.isOutSource) {
      this.setState({hideDriverInfo: true});
    }
    if(currentUser.permissions?.length !== 0) {
      if(isCustomerPortalUser() && !currentUser.permissions.includes("customer_employee_load_summary")){
        this.setState({hideDriverInfo: true});
      }
    }
    this.setState({ documents: this.props.allLoads?.documents}, ()=>{
      const length  = this.props.allLoads.driverOrder?.length;
      for(let i=0; i<length; i++){
        const moveDoc = [...this.props.allLoads?.documents || {}].reverse().find(doc => doc.moveId === `move-${i}`)
        this.setState({ [`move-${i}`]: moveDoc })
      }
    })
  }
  
  render() {
    if (this.props.allLoads) {
      this.loads = [this.props.allLoads];
    } else {
      this.loads = this.props.selectedDriver.loads;
    }

    return (
      <React.Fragment>
        <LiveShareModal
          showModal={this.state.showModal}
          onCancel={this.onCancel}
          referenceNumber={this.state.liveShareReferenceNumber}
          isMoveDualTransaction={this.state.load?.driverOrderId?.dualTransactionTo || this.state.load?.driverOrderId?.dualTransactionFrom ? true : false}
          combinedTripNumber={this.state.load?.driverOrderId?.combineTripNumber}
        />
        {/* START TrackBar Design */}
        <div
          className="mapbar d-flex flex-column top-0 left-0 mh-100 h-auto pt-10 pl-10"
          style={{ width: "500px", zIndex: 400 }}
      >
            {/* custom design end */}

            {/* New Design Implementation */}
            {
              this.loads &&
              this.loads.map((load, index) => {
                const { driverLegs } = createLegsFromDriverOrder(load.driverOrder);
                this.driverLegs = driverLegs;
                return (
                  <div className="dlist flex-grow-1 d-flex flex-column dlist--cardselected gap-1 mb-10" key={index}>
                    {driverLegs.length > 0 && driverLegs.map((leg, legIndex) => {
                    let legColor = legIndex % 2 == 0 ? "blue" : "pink";
                    let firstStatus = leg.find((status) => !status.isVoidOut);

                    let arrivedDepartedDateTimes = arrivedDepartedDateTime({load});
                    if (leg.length > 0) {
                      let ignoreInSS = this.state.isSelectCard !== legIndex ? "true" : null;

                      return (
                        <div
                          key={legIndex}
                          className={`
                            ${driverLegs.length - 1 === legIndex ? "rounded-b-5" : "rounded-b-0 border-bottom-2 border-gray-100"} 
                            ${legIndex === 0 ? "rounded-t-5" : "rounded-t-0"}
                            dlist__card cursor-pointer 
                            ${this.state.isSelectCard === legIndex ? "dlist__card--selected" : ""}
                          `}
                            data-html2canvas-ignore={ignoreInSS}
                          onClick={() => {
                            if (
                              getStorage("currentUserRole") != "customer" &&
                              getStorage("currentUserRole") !== "fleetcustomer" &&
                              !isCustomerAccount()
                            ) {
                              if (this.props.toggleLiveLocation) {
                                this.props.toggleLiveLocation(false);
                              }
                              this.setState({
                                selectedLoad: load,
                                selectedDriverOrder: null,
                              });
                              this.selectedDriver =
                                this.props.selectedDriver &&
                                this.props.selectedDriver._id;
                              this.selectedDriver = null;
                              this.handleSelectCard(
                                legIndex,
                                load,
                                leg,
                                this.props.selectedDriver &&
                                this.props.selectedDriver._id
                              );
                            }
                          }}
                        >
                          <div className="d-flex align-items-stretch gap-5 px-10 pt-10">
                            
                            <span className={`d-flex align-items-center h-26px bg-brand-50 rounded-6 p-1 text-blue-500 font-medium`}>Container Move {legIndex + 1}</span>

                            <div className="flex-shrink-0 ml-auto">
                              <div className="d-flex align-items-center text-muted">
                                <EquipmentDetails
                                  load={load}
                                  layout="vertical"
                                  isGrayPool={firstStatus?.isGrayPool}
                                />
                                {(firstStatus.driver?.name || firstStatus.driver?.lastName) && !this.state.hideDriverInfo ? (
                                  <>
                                    <h5 className="font-14 mb-0 mr-10 font-weight-500">
                                      <span title="first-name">{firstStatus?.driver?.name ? firstStatus.driver.name : "" }</span>
                                      <span className="ml-1" title="last-name">{firstStatus.driver?.lastName ? firstStatus.driver.lastName : ""}
                                      </span>
                                    </h5>
                                    <div className="avatar-circle text-uppercase">
                                    {firstStatus.driver?.name?.charAt(0)}{firstStatus.driver?.lastName?.charAt(0)}
                                  </div>
                                  </>
                                ) : null}
                                 <button
                                className="btn btn-outline-primary p-1 ml-2"
                                onClick={(e) => {
                                  this.handleShowModal(load);
                                  e.stopPropagation();
                                }}
                              >
                                <IconShare />
                                <ReactTooltip id="buttonEnable" place="right">
                                  <span className="text-capitalize">
                                    Share a public links for others to view ETA and
                                    live Location
                                  </span>
                                </ReactTooltip>
                              </button>
                              </div>
                             
                            </div>
                          </div>
                          <div className="dlist__distance d-flex flex-column gap-5 px-10 py-10">
                            {leg.map((legItem, key) => {
                              let showLoad = true;
                              let showNextLeg = false;
                              const validNextLegIndex = leg.findIndex((d, i) => i > key && !d.isVoidOut);
                              const arrivedData = validNextLegIndex !== -1 ? leg[validNextLegIndex]?.arrived : null;

                              if (this.props.allLoads) {
                                showLoad = true;
                                showNextLeg = true;
                              } else if (
                                legItem.driver !== this.props.selectedDriver._id
                              ) {
                                showLoad = false;
                              }

                              if (
                                (load.driverOrder[key + 1] &&
                                  load.driverOrder[key + 1].driver) ===
                                (this.props.selectedDriver &&
                                  this.props.selectedDriver._id)
                              ) {
                                showNextLeg = true;
                              }
                              const statusClassData =
                                this.getStatusClass(legItem)
                              const statusClass =
                                statusClassData && statusClassData[2];
                              const statusIcon =
                                statusClassData && statusClassData[1];

                                const isArrived = legItem?.departed && !arrivedData;
                                const isDeparted = arrivedData;
                                const isVoidOut = legItem?.isVoidOut;
                                
                                const isLastEvent = key === leg.length - 1;
                                const isFirstEvent = key === 0;

                                const isStartMoveEvent = isFirstEvent && legItem?.arrived && !isArrived && !isDeparted &&  !isLastEvent;

                                const islastCompleted = isLastEvent && isArrived; 
                                const isCompleted = isDeparted || islastCompleted; 
                                const isOnProgress = isArrived && !isDeparted && !isLastEvent; 
                                const isNextPending = !isVoidOut && isArrived && !isDeparted;

                                // const eventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress);

                                const eventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress && !isNextPending, isNextPending && !isOnProgress);
                                const topEventClass = getTrackingEventClasses(isVoidOut, isCompleted, isOnProgress||isStartMoveEvent, isNextPending);
                                
                                const routeLineClass = `route-line route-line--tracking route-line--top ${!isLastEvent? "route-line--bottom": ""} ${isStartMoveEvent? eventClass?.routeLineColorClass : eventClass?.routeLineColorClass}`;
                                
                                const eventMainClass = `route-line route-line-full route-line--bottom ${topEventClass?.routeLineColorClass} ${topEventClass?.eventCircleColorClass}`  
                                
                                //For Card Route Line
                                const cardRouteLineClass = `position-relative route-line ${!isLastEvent? "route-line--bottom" : ""} ${key === 0 ? "" : "route-line--top"} route-line-tracking-card`;


                                const distanceBwEvents = getDistanceBetweenEvents(leg, key, validNextLegIndex);
                                

                                const timeZoneEnable = isUserSpecificTimeZoneEnabled();
                                const timeZone = getStorage("timeZone");

                                let durationTaken = legItem?.departed && arrivedData ? getDurationInHHMMFormat(
                                  arrivedData,
                                  legItem.departed,
                                  (timeZoneEnable && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: legItem?.timeZone || timeZone})
                                ) : null

                                let startToArrivalDuration = legItem.arrived && legItem.departed ? getDurationInHHMMFormat(
                                  legItem.departed, 
                                  legItem.arrived, 
                                  (timeZoneEnable && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: legItem?.timeZone || timeZone})) : null
                                
                                const nextValidEvent = leg[validNextLegIndex]
                                let nextEventDuration = getDurationInHHMMFormat(
                                  nextValidEvent?.departed,
                                  nextValidEvent?.arrived,
                                  (timeZoneEnable && {fromTimeZone: legItem?.timeZone || timeZone, toTimeZone: leg[validNextLegIndex]?.timeZone || timeZone})
                                )

                              return (
                                <>
                                <div 
                                  className={`
                                    d-flex flex-column gap-5 outline-1 outline-gray-100 rounded-5 px-10 pt-10 pb_5 hover-bg-primary-50 pointer  
                                    ${statusClass}
                                    ${cardRouteLineClass}
                                  `} 
                                  key={key}
                                  onClick={(e) => {
                                    leg[key].selectedLeg = true
                                    leg && leg.map((eachLeg, i) =>{
                                      if(key !== i ){
                                        eachLeg.selectedLeg = false
                                      }
                                    })
                                    if (
                                      getStorage("currentUserRole") != "customer" &&
                                      getStorage("currentUserRole") !== "fleetcustomer" &&
                                      !isCustomerAccount()
                                    ) {
                                      if (this.props.toggleLiveLocation) {
                                        this.props.toggleLiveLocation(false);
                                      }
                                      this.handleSelectCard(
                                        legIndex,
                                        load,
                                        leg,
                                        this.props.selectedDriver &&
                                        this.props.selectedDriver._id
                                        );
                                      // e.stopPropagation();
                                      this.setState({
                                        selectedDriverOrder: legItem,
                                        selectedLoad: load,
                                      });
            
                                      if (
                                        legItem.address &&
                                        this.props.handleDriverOrderClick
                                      ) {
                                        this.props.handleDriverOrderClick(
                                          legItem
                                        );
                                      }
            
                                      if (
                                        this.props.drawRouteToMap &&
                                        load.driverOrder[key + 1]
                                      ) {
                                        let routeId;
            
                                        if (
                                          this.props.selectedDriver &&
                                          this.props.selectedDriver._id &&
                                          load.driverOrder[key + 1].driver ===
                                            this.props.selectedDriver._id
                                        ) {
                                          routeId = `${legItem._id}-${
                                            load.driverOrder[key + 1]._id
                                          }`;
                                        } else if (
                                          this.props.selectedDriver &&
                                          this.props.selectedDriver._id &&
                                          load.driverOrder[key + 1].driver !==
                                            this.props.selectedDriver._id
                                        ) {
                                          routeId = "ignore";
                                        } else {
                                          routeId = `${legItem._id}-${
                                            load.driverOrder[key + 1]._id
                                          }`;
                                        }
            
                                        const routes = getRoutesFromDriverOrders(
                                          leg,
                                          this.props.selectedDriver &&
                                            this.props.selectedDriver._id,
                                          routeId
                                        );
                                        this.props.drawRouteToMap(routes);
                                      } else if (
                                        this.props.drawRouteToMap &&
                                        !load.driverOrder[key + 1]
                                      ) {
                                        const routes = getRoutesFromDriverOrders(
                                          leg,
                                          this.props.selectedDriver &&
                                            this.props.selectedDriver._id,
                                          "ignore"
                                        );
                                        this.props.drawRouteToMap(routes);
                                      }
                                      this.setState({
                                        isSelectCard: index,
                                      });
                                    }
                                  }}
                                >
                                  <div className="d-flex gap-5 align-items-start">
                                    <div className="position-relative align-self-stretch w-30px flex-shrink-0 d-flex align-items-start justify-content-center">
                                      <div className={`wh-20px d-flex align-items-center justify-content-center flex-shrink-0 rounded-circle ${eventMainClass}`}>
                                      {
                                        isCompleted && !isVoidOut ? (
                                          <IconCheck />
                                        ) : isVoidOut ? (
                                          <IconTimesThin className="text-danger" />
                                        ) : key + 1
                                      }
                                      </div>
                                      
                                    </div>
                                    <div className={`w-100 d-flex gap-5 pb-10 border-bottom-dashed-1 border-gray-200`}>
                                      <div className="d-flex flex-column align-items-start gap-2" style={{ maxWidth: 90, minWidth: 90 }}>
                                        <span className={`badge badge-sm ${eventClass?.badgeColorClass} px-1 text-capitalize`}>
                                          {legItem.type && legTypeToHuman(legItem.type)}
                                        </span>
                                        {legItem?.isVoidOut && (
                                          <span className="badge badge-sm bg-danger text-white px-1 text-capitalize">Voided Out</span>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column gap-3 w-100">
                                        <div className="font-12 font-medium d-flex pr-25 position-relative">
                                          {legItem.company_name ? legItem.company_name : "-"}
                                        </div>
                                        <div className="text-muted">
                                          {legItem.address && legItem.address.address}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-100 d-flex flex-column align-items-start">
                                  {isFirstEvent && 
                                  <div className="d-inline-flex position-relative">
                                    <div className="d-flex gap-5 align-items-center h-26px">

                                      <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                                        {/* <div className={`wh-6px rounded-circle outline-1 ${topEventClass?.dotColorClass} ${routeLineClass}`}></div> */}
                                        {legItem?.arrived && !isOnProgress && !isCompleted ?
                                        <div 
                                          className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                                          style={{
                                            outline: '5px solid rgba(54, 123, 246, 0.3)',
                                          }}
                                        >
                                          <IconNavigationArrowBottom />
                                        </div>
                                        :
                                        <div className={`wh-6px rounded-circle outline-1 ${topEventClass?.dotColorClass} ${routeLineClass}`}></div>
                                      }
                                      </div>


                                      <div className="text-muted" style={{ width: 90 }}>Start </div>

                                      <div className="font-medium">
                                        {legItem.arrived ? timeFormatter(legItem.arrived) : '-'}
                                      </div>
                                    </div>
                                    <span
                                    className={`position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative left-100 ml-10 curved-arrow-sm curved-arrow-left-top curved-arrow-left-bottom`}
                                    style={{ top: "90%", transform: "translateY(-50%)", left: "100%" }}
                                  >
                                    {legItem.arrived && legItem.departed ? startToArrivalDuration : "-"}
                                  </span>
                                  </div>
                                  }
                                    <div className="d-inline-flex flex-column position-relative">
                                      <div className="d-inline-flex gap-5 align-items-center h-26px">
                                        <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                                          
                                          {isOnProgress ?
                                            <div 
                                              className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                                              style={{
                                                outline: '5px solid rgba(54, 123, 246, 0.3)',
                                              }}
                                            >
                                              <IconNavigationArrowBottom />
                                            </div>
                                            :
                                            <div className={`wh-6px rounded-circle outline-1 ${eventClass?.dotColorClass} ${routeLineClass}`}></div>
                                          }
                                        </div>
                                        <div className="text-muted" style={{ width: 90 }}>Arrival</div>
                                        <div className="font-medium">
                                          {legItem?.departed ? timeFormatter(legItem.departed): '-'}
                                        </div>
                                      </div>
                                      {!isLastEvent &&
                                      <span
                                        className={`position-absolute badge badge-sm badge-light font-12 font-weight-normal position-relative left-100 ml-10 curved-arrow-sm curved-arrow-left-top curved-arrow-left-bottom`}
                                        style={{ top: "50%", transform: "translateY(-50%)", left: "100%" }}
                                      >
                                        {legItem.departed && arrivedData ?   durationTaken : "-"}
                                      </span>
                                      }
                                      {!isLastEvent &&
                                      <div className="d-inline-flex gap-5 align-items-center h-26px">
                                        <div className="w-30px h-20px d-flex align-items-center justify-content-center position-relative">
                                          {!nextValidEvent?.departed  && arrivedData?
                                              <div 
                                              className={`wh-26px bg-white rounded-circle flex-shrink-0 d-flex align-items-center justify-content-center shadow-2 ${routeLineClass}`}
                                              style={{
                                                outline: '5px solid rgba(54, 123, 246, 0.3)',
                                              }}
                                            >
                                              <IconNavigationArrowBottom />
                                            </div>
                                            :  
                                            <div className={`wh-6px rounded-circle outline-1 ${eventClass?.dotColorClass} ${routeLineClass}`}></div>
                                          }
                                        </div>
                                        <div className="text-muted" style={{ width: 90 }}>
                                          Departure
                                        </div>
                                        <div className="font-medium">
                                        {arrivedData ? timeFormatter(arrivedData) : '-'}
                                        </div>
                                      </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                                {(leg.length - 1) !== key && !legItem?.isVoidOut &&
                                  <div 
                                    className="d-flex justify-content-between position-relative"
                                    style={{lineHeight: '12px', padding: '0px 2px'}}
                                  >
                                    <div className="font-10 line-height-12 font-weight-normal">
                                    {  
                                      ((distanceBwEvents ?? 0) === distanceBwEvents)
                                        ? `${distanceBwEvents}mi` 
                                      : "-"
                                    }
                                    </div>
                                    
                                    
                                    <span className="font-10 line-height-12 font-weight-normal">
                                      {
                                        nextValidEvent?.arrived
                                          ?
                                          nextEventDuration
                                          : "-"
                                      }
                                      </span>
                                  </div>
                                }
                                </>
                              )
                            })}
                          </div>
                          {(((this.state.isSelectCard === legIndex && 
                            (!this.state[`move-${legIndex}`] || this.state[`move-${legIndex}`].type !== 'Wait Time')))) &&
                             <><div className="dlist__hr mb-25" data-html2canvas-ignore={true}></div><div className="d-flex align-items-center" data-html2canvas-ignore={true}>
                          <div className="flex-grow-1 mr-20">
                          <p className="text-muted mb-0 font-weight-normal font-14 line-height-20 text-capitalize">Do you want to add this Wait Time Report to your invoice?</p>
                          </div>
                          <div className="flex-shrink-0" onClick={(e)=>this.props.takeScreenShot(e,legIndex)}>
                            {this.props.loading ?
                              <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                              aria-hidden="true"
                            ></span>
                              : <a href="#" className="bg-blue-500 rounded-3 wh-44px d-flex align-items-center justify-content-center">
                              <IconCapture className="text-white" />
                            </a>}  
                          </div>

                        </div></>} { ((this.state.isSelectCard === legIndex && (this.state[`move-${legIndex}`] && this.state[`move-${legIndex}`]?.type == 'Wait Time'))
                         )&& 
                        <>
                        <div className="dlist__hr mb-25"></div>
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 mr-20">
                          <p className="text-muted mb-0 font-weight-normal font-14 line-height-20 text-capitalize"><IconCheck /> Wait Time Report is added to your invoice.</p>
                          </div>
                          <div className="flex-shrink-0" onClick={()=> window.open(this.state[`move-${legIndex}`]?.url)}>
                              <a href="#" className="rounded-3 wh-40px text-gray-100 border-1 d-flex align-items-center justify-content-center">
                                <IconDownload className="text-dark" />
                              </a>
                          </div>
                        </div>
                        </>}
                        </div>
                      )
                    }
                  })}
                  </div>
                )
              })
            }
        
          {this.state.isSelectCard !== -1 && (
            <TrackingHistoryPlayer
              driverOrder={this.state.selectedDriverOrder}
              load={this.state.selectedLoad}
              driver={this.props.selectedDriver}
              mapInstance={this.props.mapInstance}
              toggleLiveLocation={this.props.toggleLiveLocation}
              liveLocationToggled={this.props.liveLocationToggled}
            />           
          )}
           {/* <ContainerSummary /> */}
        </div>
        {/* END TrackBar Design */} 
      
      </React.Fragment>
    );
  }
}

export default NewDriverDetails;
