import React from "react";
import TrackingRangeSlider from "pages/trucker/Tracking/Components/Shared/Molecules/TrackingRangeSlider";
import { useMap } from "react-leaflet";
import { useTrackingHistoryLive } from "pages/trucker/Tracking/context/TrackingContext";

const TrackingRangeSliderContainer = ({
  isLoading,
  onChange,
  value,
  devicetime,
  labels,
  disabled = false,
  max = 0,
  min = 0,
}) => {
  const map = useMap();
  const stateHistoryLive = useTrackingHistoryLive();

  const stopZoomOnDblClick = () => {
    if (map) map.doubleClickZoom.disable();
  };

  return (
    <TrackingRangeSlider
      className="ml-20 mr-10 align-self-end mb-1"
      labels={stateHistoryLive?.isFollowLiveEntity ? "Live Location Data" : labels}
      minIndex={min}
      maxIndex={max}
      value={value}
      devicetime={stateHistoryLive?.isFollowLiveEntity ? "Live View" : devicetime}
      // deviceTimeRange={timeRange}
      disable={isLoading || disabled}
      isLoading={isLoading}
      onChange={(changedIndex) => onChange(changedIndex)}
      onDoubleClick={stopZoomOnDblClick}
    />
  );
};

export default TrackingRangeSliderContainer;
