import React, { useEffect } from 'react'
import { usePopper } from 'react-popper';
import ReactDOM from "react-dom";

export default function ToolTip(props) {
    const { open, refNo, note, isToolTipOpen, setIsToolTipOpen } = props;
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(refNo, popperElement, {placement: "top"}) || {};
    return (
        <>
          {(open || isToolTipOpen) && note &&
            ReactDOM.createPortal(
              <div
                className={`tooltip tooltip--gray-700 bs-tooltip-top portal-tooltip note_view p-10`}
                ref={setPopperElement}
                style={styles?.popper}
                {...(attributes?.popper || {})}
                onMouseEnter={()=>setIsToolTipOpen(true)}
                onMouseLeave={()=>setIsToolTipOpen(false)}
                data-testid="tooltip"
              >
                <div className="rounded-lg">
                    <span className="text-break">{note}</span>
                </div>
                <span className="arrow"></span>
              </div>,
              document.querySelector("#poper-dest")
            )}
        </>
      );
}
