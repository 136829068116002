import React, { useState, useRef, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import {
  IconCalendar,
  IconCopy,
  IconNewTab,
} from "../../../Components/Common/Icons";
import { Link } from "react-router";
import Datetime from "react-datetime";
import { copyToClipboard } from "../../../Components/Common/functions";
import moment, { tz } from "moment";
import { getLiveShareURL } from "./actionCreators";
import { DateTimeFormatUtils, amplitudeTrack, toastr } from "../../../services";
import { liveEntitiesSelectors } from "./store/selectors";
import { useLiveEntities } from "./context/TrackingContext";
import { getTimeZone } from "./constant";

const LiveShareModal = (props) => {
  const options = ["24hr", "one week", "custom"];

  const { showModal, onCancel, referenceNumber, entity } = props;
  const [dateState, setDate] = useState(moment());
  const [selectedOptionState, _setSelected] = useState(options[0]);
  const [liveURL, _setLiveURL] = useState(null);
  const [loadingState, _setLoadingState] = useState(false);

  const code = useRef(null);
  const selectedOption = useRef(options[0]);
  const date = useRef(moment());

  const liveEntities = useLiveEntities();

  const currentLiveMetaData =  useMemo(() => liveEntitiesSelectors.getLiveEntityById(liveEntities, entity), [liveEntities, entity]);

  const generateCode = async () => {
    if (referenceNumber) {
      _setLoadingState(true);
      try {
        let validTill = null;
        const now = moment();

        switch (selectedOption.current) {
          case options[0]:
            validTill = now.add(24, "hours").toISOString();
            break;
          case options[1]:
            validTill = now.add(7, "days").toISOString();
            break;
          case options[2]:
            validTill = date.current.endOf("day").toISOString();
          default:
            break;
        }

        const requestBody = {
          referenceNumber: referenceNumber,
          expiration: validTill,
          source : currentLiveMetaData?.source
        };

        if (code.current) {
          requestBody.code = code.current;
        }

        const [response, err] = await getLiveShareURL(requestBody);
        if (err) {
          // show error toastr
          toastr.show("Error generating URL, please try again later.", "error");
          return;
        } else if (!response) {
          // show error toastr
          toastr.show("Error generating URL, please try again later.", "error");
        } else if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.shortURL
        ) {
          code.current = response.data.data.code;
          _setLiveURL(response.data.data.shortURL);
        }
      } catch (e) {
        console.error(e);
        toastr.show("Error generating URL, please try again later.", "error");
      } finally {
        _setLoadingState(false);
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      generateCode();
    }
  }, [referenceNumber, showModal]);

  const reset = () => {
    _setLiveURL(null);
    _setSelected(options[0]);
    selectedOption.current = options[0];
    code.current = null;
    onCancel && onCancel();
  };

  const handleDateChange = (dateValue) => {
    setDate(dateValue);
    date.current = dateValue;
    generateCode();
  };

  const handleOnChange = (e) => {
    _setSelected(e.target.value);
    selectedOption.current = e.target.value;
    generateCode();
  };

  const copy = () => {
    if (!loadingState) {
      copyToClipboard(liveURL);
      let eventProperties = {
        source: "live_tracking_modal",
        expiration_time: selectedOption.current
      }
      amplitudeTrack('COPY_SHARE_URL',eventProperties)
    }
  };

  return (
    <Modal
      show={showModal}
      centered="true"
      animation={false}
      size="sm"
      onBackdropClick={reset}
      backdropClassName="z-1050"
    >
      <Modal.Header className="d-flex flex-column align-items-start">
        <div className="font-20 font-weight-500 mb-10">Live Tracking</div>
        <div className="text-gray-500 font-weight-400 text-capitalize">
          Share a public link for others to view ETA and live location
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="form-row">
          <div className="form-group col-lg-11">
            <input
              type="text"
              className="form-control bg-gray-50"
              readOnly={true}
              placeholder={liveURL}
              value={
                loadingState
                  ? "Generating optimized URL please wait..."
                  : liveURL || ""
              }
            />
          </div>
          <div className="form-group col-lg-1">
            <button className="btn btn-primary" onClick={copy}>
              {loadingState ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                <IconCopy />
              )}
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <IconNewTab className="mr-2" />
          <div className="font-12 font-weight-400">
            <Link href={loadingState ? null : liveURL} target="_blank" className="text-capitalize">
              or open in new tab
            </Link>
          </div>
        </div>
        <hr></hr>
        <div>
          <div className="font-12 font-weight-500 mb-1 text-capitalize">Link expiration</div>
          <div className="form-wrapper">
            <form>
              <div className="form-row d-flex align-items-center">
                <div className="col-md-2">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="24hr"
                      name="link-expiration"
                      value={options[0]}
                      checked={selectedOptionState === options[0]}
                      onChange={handleOnChange}
                    />
                    <label className="form-check-label" htmlFor="24hr">
                      24hr
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Oneweek"
                      name="link-expiration"
                      value={options[1]}
                      checked={selectedOptionState === options[1]}
                      onChange={handleOnChange}
                    />
                    <label className="form-check-label" htmlFor="Oneweek">
                      One week
                    </label>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-check form-group mb-0">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="Custom"
                      name="link-expiration"
                      value={options[2]}
                      checked={selectedOptionState === options[2]}
                      onChange={handleOnChange}
                    />
                    <label className="form-check-label" htmlFor="Custom">
                      Custom
                    </label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-group  mb-0">
                    <div className="input-wrapper">
                      <Datetime
                        inputProps={{
                          placeholder: "Select date..",
                          disabled: selectedOptionState !== options[2],
                        }}
                        onChange={(value) => handleDateChange(value)}
                        closeOnSelect
                        value={dateState}
                        dateFormat={DateTimeFormatUtils.fullDateFormat()}
                        timeFormat={false}
                        displayTimeZone = {getTimeZone({preferred: true})}
                        isValidDate={(current) => {
                          return current.isAfter(moment().subtract(1, "day"));
                        }}
                      />
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="">
          <button className="btn btn-outline-light" onClick={reset}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default LiveShareModal;
