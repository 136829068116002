import moment from "moment";
import { IconDotSeperator, IconShare, IconTimer } from "Components/Common/Icons";
import React, { useEffect, useState } from "react";

import LiveShareModal from "pages/trucker/Tracking/LiveShareModal";
import DriverOrderList from "../../../../trucker/Tracking/Components/TrackingDrivers/DriverOrders/DriverOrderList";
import { createLegsFromDriverOrder } from "Components/Common/functions";
import LiveDriverAvatar from "../../../../trucker/Tracking/Components/TrackingDrivers/Drivers/Components/LiveDriverAvatar";
import { useCurrentUser } from "hooks";
import { getEldLocationHistoryByDriver } from "../../../../../services";
import { DateTimeFormatUtils } from "services";
import { useTrackingDriver, useTrackingLoadDispatch } from "../../../../trucker/Tracking/context/TrackingContext";
import { LOAD_ACTIONS } from "../../../../trucker/Tracking/store/actions";
import ReactTooltip from "react-tooltip";
import { createPortal } from "react-dom";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import EquipmentDetails from "Components/Common/EquipmentDetails/EquipmentDetails";

export default function EachLoad(props) {
  const { load, onClick = () => { }, selectedDriverId, hideDriverInfo, driverHistoryList = {}, readonly } = props;

  const [liveShareState, setLiveShareState] = useState({ showModal: false, liveShareReferenceNumber: null });

  const [isSelectCard, setIsSelectCard] = useState(null);
  const [screenshotCodeBlock, setScreenshotCodeBlock] = useState(null);

  const { driverLegs } = createLegsFromDriverOrder(load.driverOrder);
  const loadDispatch = useTrackingLoadDispatch();
  const { selectedDriver } = useTrackingDriver() || {}

  const  currentUserTimeZone  = getTimeZone({preferred: true});

  useEffect(() => {
    if(!readonly){
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_LOAD, payload: load });
    }
  }, []);

  const handleShowModal = (referenceNumber) => {
    setLiveShareState({ showModal: true, liveShareReferenceNumber: referenceNumber });
  };

  const onCancel = () => {
    setLiveShareState({ showModal: false, liveShareReferenceNumber: null });
  };

  const handleSelectCard = async ({ legIndex, leg }) => {
    if(readonly) return
    if (!props.disableClicks) {
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_EVENT, payload: null });
      loadDispatch({ type: LOAD_ACTIONS.SET_SELECTED_MOVE, payload: leg?.[0]?.moveId });

      const driverId = leg?.[0]?.driver?._id;
      const firstOrderArrived = leg?.[0]?.arrived;
      const lastOrderDeparted = leg?.at(-1)?.departed ?? moment().toISOString();

      if (!firstOrderArrived) return setIsSelectCard(legIndex);

      const selectedLoadHistory = driverHistoryList?.[legIndex]?.filter((obj) => {
        const startTime = moment(obj?.date_time ?? obj?.t);
        return startTime.isBetween(firstOrderArrived, lastOrderDeparted, 'seconds', '[]');
      })?.sort((a, b) => new Date(a?.date_time ?? a?.t) - new Date(b?.date_time ?? b?.t));

      if (selectedLoadHistory && selectedLoadHistory?.length > 0) {
        loadDispatch({ type: LOAD_ACTIONS.SET_SELECT_LOAD_HISTORY, payload: selectedLoadHistory });
      }

      setIsSelectCard(legIndex);
    }
  };

  return (
    <React.Fragment>
      {
        liveShareState?.showModal && (
          <LiveShareModal
            showModal={liveShareState.showModal}
            onCancel={onCancel}
            referenceNumber={liveShareState.liveShareReferenceNumber}
            entity={selectedDriver}
          />
        )
      }
      {driverLegs.length > 0 &&
        driverLegs.map((leg, legIndex) => {
          let firstStatus = leg.find((status) => !status.isVoidOut);
          
          if (leg.length > 0) {
            let ignoreInSS = isSelectCard !== legIndex ? "true" : null;
            return (
              <div
                key={legIndex}
                className={`
                  ${driverLegs.length - 1 === legIndex ? "rounded-b-5" : "rounded-b-0 border-bottom-2 border-gray-100"} 
                  ${legIndex === 0 ? "rounded-t-5" : "rounded-t-0"}
                  dlist__card cursor-pointer 
                  ${isSelectCard === legIndex ? "dlist__card--selected" : ""}
                `}
                data-html2canvas-ignore={ignoreInSS}
                data-eventType="moveCardClick"
                onClick={() => handleSelectCard({ legIndex, leg })}
              >
                <DriverOrderHeader
                  legIndex={legIndex}
                  firstStatus={firstStatus}
                  loadAssignedDate={ firstStatus?.loadAssignedDate }
                  hideDriverInfo={hideDriverInfo}
                  handleShowModal={handleShowModal}
                  loadRefno={load?.reference_number}
                  selectedDriver={selectedDriver}
                  load={load}
                  screenshotCodeBlock={screenshotCodeBlock}
                  readonly={readonly}
                />

                <DriverOrderList 
                  legIndex={legIndex} 
                  driverOrders={leg} 
                  selectedDriverId={selectedDriverId} 
                  load={load} 
                  driverHistoryList={driverHistoryList} readonly={readonly} 
                  startMove={
                    firstStatus?.arrived &&
                      <>
                        {moment(firstStatus.arrived).tz(currentUserTimeZone).format(DateTimeFormatUtils.fullDateTimeFormat())}
                      </>
                  }
                  setScreenshotCodeBlockFromParent={setScreenshotCodeBlock}
                />
              </div>
            );
          }
        })}
    </React.Fragment>
  );
}

const DriverOrderHeader = ({ legIndex, firstStatus, selectedDriver, hideDriverInfo, loadAssignedDate, handleShowModal, loadRefno, load, screenshotCodeBlock, readonly }) => {
  const  currentUserTimeZone  = getTimeZone({preferred: true});
  const currentActiveDriver = selectedDriver?._id && firstStatus?.driver?._id && selectedDriver._id === firstStatus.driver._id ? selectedDriver : firstStatus?.driver;

  return (
    <div className="d-flex align-items-stretch gap-5 px-10 pt-10">
      <div className="flex-grow-1 flex-shrink-0 d-flex gap-3 flex-column align-items-start">
        <span
          className={`d-flex align-items-center h-26px bg-brand-50 rounded-6 p-1 text-blue-500 font-medium`}
        >
          Container Move {legIndex + 1}
        </span>
        {
            firstStatus?.loadAssignedDate &&
              <div className="d-flex align-items-center text-muted gap-2">
                <IconTimer className="wh-16px" />
                <span>{moment(loadAssignedDate).tz(currentUserTimeZone).format("MM-DD-YY")}</span>
                <IconDotSeperator />
                <span>{moment(loadAssignedDate).tz(currentUserTimeZone).format("hh:mm A")}</span>
              </div>
          }
      </div>
      <div className="text-truncate d-flex flex-column gap-3">
        {currentActiveDriver && !hideDriverInfo ? (
          <div className="d-flex align-items-center justify-content-end gap-5">
            <LiveDriverAvatar driver={currentActiveDriver} size="sm" />
            <div className="font-12 font-medium text-truncate">
              {firstStatus?.driver?.name ? firstStatus.driver.name : ""}
              {" "}
              {firstStatus.driver?.lastName ? firstStatus.driver.lastName : ""}
            </div>
          </div>
        ) : null}
        <EquipmentDetails 
          load={load} 
          layout="vertical" 
          isGrayPool={firstStatus?.isGrayPool || false} 
        />
      </div>

      <div className="d-flex flex-column gap-5">
       {!readonly && <button
          className="btn btn-outline-primary p-1 w-35px h-100"
          data-tip
          data-for="trackingtab-liveshare"
          onClick={(e) => {
            handleShowModal(loadRefno);
            e.stopPropagation();
          }}
        >
          <IconShare />
        </button>}
        {screenshotCodeBlock && screenshotCodeBlock}

      {createPortal(
        <ReactTooltip id="trackingtab-liveshare" className="text-left z-1051" effect="solid">
          <span className="text-capitalize">
            Share a public link for others to <br />
            view ETA and live location
          </span>
        </ReactTooltip>, document.getElementById("pp-tooltip")
      )}
      </div>
    </div>
  );
};
