import React, { useState } from 'react'
import { useHover } from '../../../../../../hooks';
import ToolTip from '../ToolTip/ToolTip';

export default function Note({row, column}) {
  
  const abbNote = row?.[column.key]?.substring(0,column?.width ? column.width / 9 : 10);
  const note = abbNote === row?.[column.key] ? row?.[column.key] : abbNote+"..."

  const [referenceElementRef, referenceElement] = useHover();
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  
  return (<>
    <div className={`p-2`} ref={referenceElementRef} >
        {note}
    </div>
    {row?.[column.key] && <ToolTip open={referenceElement} refNo={referenceElementRef.current} note={row[column.key]} setIsToolTipOpen={setIsToolTipOpen} isToolTipOpen={isToolTipOpen}/>}
  </>
  )
}

