import React, { useEffect, useState } from 'react'
import { DRIVER_PERMISSIONS } from '../../constant';
import _, { startCase, toLower } from 'lodash';
import { editDriverProfile } from '../../actionCreators';
import { toastr } from '../../../../../services';
import { transitionEffectForInput } from '../helpers/transitionEffects';

export default function MobilePermissions(props) {
  const [permissions,setPermissions] = useState(props.permissions)
  const [updatedFieldId,setUpdatedFieldId] = useState("")
  const checkIsPermissionAssigned = (rolename) => {
    let checked = false;
    if (Array.isArray(rolename)) {
      checked = rolename.every((e) => {
        return permissions.includes(e)
      })
    } else {
      checked = permissions.includes(rolename) ? true : false;
    }
    return checked;
  }
  const  handleChangeAll = ({target}) => {
    const permission = target.getAttribute("data-permission").split(",");
    let _permissions = _.cloneDeep(permissions)
    if(target.checked){
      _permissions = _.uniq(_permissions.concat(permission))
    }else{
      if(permission.length > 1){
        _permissions = _.pullAllWith(_permissions, permission)
      }else {
        _permissions.splice(_permissions.indexOf(target.value),1)
      }
    }
    setUpdatedFieldId(target.id)
    setPermissions(_permissions)
  }

  useEffect(()=>{
    if(permissions && !_.isEqual(permissions,props.permissions)){
      let params = new FormData();
      params.append("_id", props.selectedDriver._id);
      params.append("username", props.selectedDriver.username);
      params.append("permissions",JSON.stringify(permissions));
      
      editDriverProfile(params)().then((result) => {
        transitionEffectForInput(updatedFieldId,true)
        setUpdatedFieldId("");
        props.updatePermissions(permissions)
      }).catch((err) => { transitionEffectForInput(updatedFieldId,false);setUpdatedFieldId("");  console.log("err", err);  });
    }
  },[permissions])
  return (
    <div className="card p-10">
      <div className="form-row">
        {Object.keys(DRIVER_PERMISSIONS).map((permission, key) => (
          <div className="col" key={key}>
            <h6 className="font-size-regular mb-20 mt-10">
              {startCase(toLower(permission))}
            </h6>
            <div className="d-flex flex-column">
              {Array.isArray(Object.keys(DRIVER_PERMISSIONS[permission])) &&
                Object.keys(DRIVER_PERMISSIONS[permission]).map((d) => {
                  return (
                    <div className="form-check form-check mb-1" key={d}>
                      <input
                        name="showrequest"
                        id={`check${key}_${d}`}
                        checked={checkIsPermissionAssigned(d)}
                        type="checkbox"
                        className={`form-check-input border-gray check${key}_${d}`}
                        data-permission={d}
                        value={d}
                        onClick={(e)=>handleChangeAll(e)}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        htmlFor={`check${key}_${d}`}
                      >
                        {DRIVER_PERMISSIONS[permission][d]}
                      </label>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
