import { EMAIL_CONTEXT, TAG_SOURCE_TYPES } from "pages/tms/Email/constant";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useEmailTags } from "../../hooks";
import { toastr } from "services";
import _ from 'lodash';
import { calculateTextColor, getThreadId, isEmbeddedEmailConnected } from "../../helper";
import { isAtBottom } from "pages/tms/NewDispatcher/DispatcherTable/Functions";
import { amplitudeTrack, checkUserPermisison } from "../../../../../services";
import { IconPlus } from "Components/Common/Icons";
import { LoaderBar } from 'Components/Common/LoaderBar';

const EmailTags = ({ threadData, event, eventProperties, setShowCreateTag, closeDropdown }) => {
  const { allEmailTags, updateSelectedEmailtags } = useContext(EMAIL_CONTEXT);
  let mailTags = allEmailTags?.find((d) => d?.emailId === getThreadId(threadData))?.tags || [];
  const [checkedTags, setCheckedTags] = useState(mailTags ?? []);
  const [applyLoader, setApplyLoader] = useState(false)

  const { assignTagsToEmails, allTags, isTagsLoading  } = useEmailTags();
  const createTagPermission = checkUserPermisison(["settings_tags"]);
  useEffect(() => {
    if (allEmailTags && Array.isArray(allEmailTags) && allEmailTags?.length) {
      const foundEmailTagInContext = allEmailTags?.find(
        (eachTagInContext) => eachTagInContext?.emailId === threadData?.id
      );
      if (foundEmailTagInContext) setCheckedTags(foundEmailTagInContext?.tags);
    }
  }, [allEmailTags, threadData?.id]);

  const allEnableTags = useMemo(() => allTags.filter((t) => !t?.isDisable), [allTags]); 
  const checkTags = (tagValue, checked) => {
    const foundTag = allEnableTags?.find((eachTag) => eachTag?._id === tagValue);
    if (!foundTag) return;
    const _checkedLabel = checked ? [...checkedTags, foundTag] : [...checkedTags].filter((d)=>d?._id !== tagValue)
    setCheckedTags(_checkedLabel)
  }

  const handleAssignTagsToEmails = async () => {
    setApplyLoader(true)
    try {
      let updatedTags = [...checkedTags]
      const threadId = threadData?.threadId;
      if (!threadId) throw new Error("Missing required field: threadId");
      const threadsTagDetails = [
        {
          threadId,
          accountId: threadData?.grantId,
          tagValues: updatedTags.map((eachTag) => ({
            tagId: eachTag?._id,
            tagValue: eachTag?.value,
            source: eachTag?.source ?? TAG_SOURCE_TYPES.MANUAL,
          })),
        },
      ];
      const payload = {
        skipMiddleWare: !isEmbeddedEmailConnected(),
        threadData: threadsTagDetails
      }
      const assignedEmails = await assignTagsToEmails(payload);
      if(event && eventProperties){
        let newEventProperties = eventProperties;
        eventProperties.tagCount = checkedTags.length
        amplitudeTrack(event,newEventProperties);
      }
      if (assignedEmails) {
        setCheckedTags(updatedTags ?? []);
        updateSelectedEmailtags(
          updatedTags,
          assignedEmails?.data?.length > 0 ? assignedEmails?.data?.[0] : [],
          threadId
        );
        setApplyLoader(false)
      } else {
        setApplyLoader(false)
      }
    } catch (e) {
      console.log("errr", e)
      const message = e?.data?.message || e?.response?.data?.message || "Unable to assign tags to email(s).";
      toastr.show(`${message}`, "error");
      setApplyLoader(false)
    }
  };

  const isDisable = useMemo(
    () =>
      _.isEqual(
        mailTags?.map((i) => i._id),
        checkedTags?.map((i) => i._id)
      ),
    [checkedTags, mailTags]
  );


  return (
    <>
      <ul
        className="overflow-auto custom-scrollbar-md my-1 position-relative"
        style={{ maxHeight: 200, height: 200 }}
      >
        {isTagsLoading ? (
          <LoaderBar />
        ) : (
          allEnableTags?.map((eachTag) => {
            const textColor = calculateTextColor(eachTag.color) // Using the hook here

            return (<li
            key={eachTag?._id}
            className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100"
          >
            <div className="form-check pr-30">
              <input
                id={eachTag?._id}
                className="form-check-input mr-2"
                type="checkbox"
                checked={checkedTags?.map((d) => d?._id)?.includes(eachTag?._id)}
                onChange={(e) => {
                  checkTags(eachTag?._id, e.target.checked);
                }}
              />
              <label htmlFor={eachTag?._id} style={{ backgroundColor: eachTag?.color }} className={`badge badge-sm ${textColor ? textColor : "text-white"} mb-0  pointer text-left text-wrap`}>
                # {eachTag?.label}
              </label>
            </div>
          </li>)
          })
        )}
      </ul>
      <div className="hr-light my-1" />
      <ul className="my-1">
        <li
          className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${
            applyLoader && "pointer-not-allowed opacity-05"
          }${!isDisable ? 'pointer' : 'pointer-not-allowed opacity-05'}`}
          onClick={(e) => {
            if (!applyLoader && !isDisable) {
              e.stopPropagation();
              handleAssignTagsToEmails();
            }
          }}
        >
          {applyLoader ? <span class="spinner-border spinner-border-sm text-black ml-10"></span> : ""}
          <span className="font-14 font-weight-normal ml-10 text-center">Apply</span>
        </li>
      </ul>
      {createTagPermission && (
        <>
          <div className="hr-light my-1" />
          <ul className="my-1">
            <li
              className={`d-flex align-items-center justify-content-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100`}
              onClick={(e) => {
                e.stopPropagation();
                setShowCreateTag(true);
                if(closeDropdown) closeDropdown();
              }}
            >
              <IconPlus />
              <span className="font-14 font-weight-normal ml-10">Create New Tag</span>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default EmailTags;
