import moment from 'moment';
import { ENUM } from '../constants';
import Dropzone from 'react-dropzone';

import { useNewRow } from "../hooks/useNewRow";
import SelectCustomComponent from "pages/tms/SelectComponent";
import { DateTimeFormatUtils, isAccountServiceEnabled, isGlobalizationEnabled, toastr } from '../../../../../../services';
import DatePortal from '../../../../NewDispatcher/DispatcherTable/Components/portals/DatePickerPortal';
import { NumberFormatterV2 } from '../../../../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater';
import { IconAngleArrowDown, IconFileUpload, IconTopDocUpload, IconTrash } from '../../../../../../Components/Common/Icons';

const NewRow = ({ bill = null, handleShowNewRow, refreshCarrierPaymentSection, accountToDeposite }) => {
    const currency = bill?.billFromDetail?.currency;
    const {
        row,
        xRef,
        successBg,
        docTypes,
        timeZone,
        isApiCall,
        errorCells,
        wrapperRef,
        focusedCell,
        isSpaceAbove,
        isShowDatepicker,
        getDragAndDropFile,
        handleDrop,
        handleSubmit,
        handleRowChange,
        handleSelectCell,
        handleDeleteNewRow,
        handleShowDatepicker,
    } = useNewRow({ bill, refreshCarrierPaymentSection, handleShowNewRow });

    return (
        <Dropzone className="border-0 w-100" onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
                <>
                    <tr
                        id="outline"
                        ref={wrapperRef}
                        {...getDragAndDropFile(null, getRootProps)}
                        style={{ transition: "background .4s ease-out" }}
                        onKeyDown={(e) => !isApiCall && e.keyCode === 13 && handleSubmit()}
                        className={`shadow-md select-custom-input newdriverrow position-relative ${successBg ?? ''}`}
                    >
                        <td
                            onClick={() => handleSelectCell("add-amount")}
                            style={{ transition: "background .4s ease-out" }}
                            className={` font-14 text-right text-dark ${errorCells?.includes(ENUM.AMOUNT) ? 'background-failure' : ''} ${focusedCell === "add-amount" && "newdriverrow td-clicked"}`}
                        >
                            <div className="min-width-100">
                                {
                                    !isGlobalizationEnabled() && (
                                        <input
                                            type="number"
                                            value={row?.amount}
                                            placeholder={"0.00".toCurrency(currency)}
                                            onKeyDown={(e) => e.keyCode === 189 && e.preventDefault() }
                                            onChange={(e) => handleRowChange(ENUM.AMOUNT, e.target.value.trim())}
                                            className={`form-control border-0 font-14 text-right pr-0 ${row?.amount ? "text-gray-900" : "text-gray-300"}  bg-transparent`}
                                        />
                                    )
                                }
                                {
                                    isGlobalizationEnabled() && (
                                        <NumberFormatterV2
                                            type="number"
                                            placeholder={"0.00".toCurrency(currency)}
                                            selected={focusedCell === "add-amount"}
                                            onChange={(e) => handleRowChange(ENUM.AMOUNT, e.target.value.trim())}
                                            className={`form-control border-0 font-14 text-right pr-0 ${row?.amount ? "text-gray-900" : "text-gray-300"}  bg-transparent`}
                                            // showCurrency={this.state.tab === 2}
                                            // currencyOption={currency}
                                        />
                                    )
                                }
                            </div>
                        </td>
                        <td
                            onClick={() => handleSelectCell("add-des")}
                            className={` text-break font-12 text-truncate text-truncate" ${focusedCell === "add-des" && "newdriverrow td-clicked"}`}
                        >
                            <div className="min-width-200">
                                <input
                                    placeholder="Add Description..."
                                    className="form-control border-0 bg-transparent pl-0"
                                    onChange={(e) => handleRowChange(ENUM.NOTE, e.target.value)}
                                />
                            </div>
                        </td>
                        <td
                            onClick={() => handleSelectCell("add-check")}
                            className={` "font-12" ${focusedCell === "add-check" && "newdriverrow td-clicked"}`}
                        >
                            <div className="min-width-100">
                                <input
                                    placeholder="Enter Check #"
                                    className="form-control border-0 bg-transparent pl-0"
                                    onChange={(e) => {
                                        if ('accountingServiceInfo?.serviceName' === "QBD" && e.target.value && e.target.value?.length >= 21) {
                                            return toastr.show("Check # character limit is 20.", "error");
                                        } else {
                                            handleRowChange(ENUM.CHECK_NO, e.target.value)
                                        }
                                    }}
                                />
                            </div>
                        </td>
                        {isAccountServiceEnabled() && <td
                            className={`pr-1 select-cell-2 ${focusedCell === "accountDeposite" && "newdriverrow td-clicked"} ${errorCells?.includes(ENUM.AMOUNT) ? 'background-failure' : ''}` }
                            style={{ transition: "background .4s ease-out" }}
                            onClick={() => handleSelectCell("accountDeposite")}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                {accountToDeposite?.find(e=>(e?.Id ?? e?.ID)===row?.DepositToAccountRef)?.Name?? '' }
                                {!row?.DepositToAccountRef && <span className="text-muted">Select Account to Deposite</span>}
                                <IconAngleArrowDown className="" />
                                {focusedCell === "accountDeposite" && (
                                    <SelectCustomComponent
                                        name="type"
                                        keyName="label"
                                        searchable={false}
                                        listData={accountToDeposite.map((pay) => ({
                                            value: pay.Id ? pay.Id : pay.ID,
                                            label: pay.Name,
                                          }))}
                                        refClassName="select-cell-2"
                                        unselectCell={handleSelectCell}
                                        onSelect={(_name, value) => handleRowChange(ENUM.DEPOSITE_TO_ACCOUNT_REF, value?.value)}
                                    />
                                )}
                            </div>
                        </td>}
                        <td>
                            <div className="btn btn-outline-primary-border rounded-lg pt_1 pb_1" {...getRootProps()}>
                                <input {...getInputProps()} style={{ display: "none" }} />
                                {row?.docName ? <IconFileUpload /> : (
                                    <div className="font_10 d-flex">
                                        <IconTopDocUpload className="mr-10" /> Upload Document
                                    </div>
                                )}
                            </div>
                        </td>
                        <td
                            className={`pr-1 select-cell-1 ${focusedCell === "add-docType" && "newdriverrow td-clicked"}`}
                            style={{ transition: "background .4s ease-out" }}
                            onClick={() => handleSelectCell("add-docType")}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                {row?.documentType ?? ''}
                                {!row?.documentType && <span className="text-muted">Select Document Type</span>}
                                <IconAngleArrowDown className="" />
                                {focusedCell === "add-docType" && (
                                    <SelectCustomComponent
                                        name="type"
                                        keyName="label"
                                        searchable={true}
                                        listData={docTypes}
                                        refClassName="select-cell-1"
                                        unselectCell={handleSelectCell}
                                        onSelect={(_name, value) => handleRowChange(ENUM.DOC_TYPE, value?.value)}
                                    />
                                )}
                            </div>
                        </td>
                        <td
                            ref={xRef}
                            onClick={() => handleShowDatepicker({ isOutsideClick: false })}
                            style={{ transition: "background .4s ease-out" }}
                            className={`datepicker-portal ${errorCells?.includes(ENUM.PAYMENT_DATE) ? 'background-failure' : ''}  ${focusedCell === "add-date" && "newdriverrow td-clicked"} `}
                        >
                            <div className="position-relative bg-transparent">
                                <div className="font-weight-500 text-center">
                                    {row?.paymentDate ? moment(row?.paymentDate).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat()) : '-'}
                                </div>
                                <div onClick={(e) => e.stopPropagation()}>
                                    {isShowDatepicker && (
                                        <DatePortal
                                            dateFormat="MM/DD"
                                            isShowTime={true}
                                            displayTimeZone={timeZone}
                                            dontShowSelectDate={true}
                                            hideShowSelectDate={true}
                                            refClassName={"datepicker-portal"}
                                            className={`right-0 ${isSpaceAbove}`}
                                            onChange={(e) => handleRowChange(ENUM.PAYMENT_DATE, e)}
                                            OnBlurUpdate={() => handleShowDatepicker({ isOutsideClick: true })}
                                        />
                                    )}
                                </div>
                            </div>
                        </td>
                        <td className={`pointer text-center event_icon`}>
                            <span onClick={handleDeleteNewRow}>
                                <IconTrash />
                            </span>
                        </td>
                    </tr>

                    {
                        isApiCall && (
                            <div className="overly-lays d-flex justify-content-center align-items-center text-white">
                                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Processing...
                            </div>
                        )
                    }

                </>
            )}
        </Dropzone>
    )
}

export default NewRow;