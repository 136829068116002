import _ from "lodash"
import { getStorage, toastr } from "../../../services"
import {
    IconSearchData,
    IconDocument,
    IconNotes,
    IconUsers,
    IconMapWithMarker,
    IconUsersGroup,
    IconSendEmail,
  } from "../../../Components/Common/Icons";
export const waitingTime = () => {
    let time = []
    for (let i = 2; i <= 48; i++) {
        time.push({ label: `${i / 2} hr`, value: i * 30 })
    }
    return time
}

export const defaultWaitingTime = [
    { label: '30 min', value: 30 },
    { label: '1 hr', value: 60 },
    { label: '2 hr', value: 120 },
    { label: '3 hr', value: 180 },
    { label: '4 hr', value: 240 },
    { label: '5 hr', value: 300 },
]

const liftOffOnOptions = [{
    value: "LIFTOFF_ARRIVED",
    label: "Enroute to Lift Off"
},
{
    value: "LIFTOFF_DEPARTED",
    label: "Grounded"
},
{
    value: "LIFTON_ARRIVED",
    label: "Enroute to Lift On"
},
{
    value: "LIFTON_DEPARTED",
    label: "Arrived to Lift On"
}]

export const listLoadStatusForWaiting = [
        {
            value: 'PENDING',
            label: 'Pending'
        },
        {
            value: 'AVAILABLE', 
            label:'Available'
        },
        {
            value: "CHASSISPICK_ARRIVED",
            label: "Enroute to Chassis"
        },
        {
            value: "CHASSISPICK_DEPARTED",
            label: "Arrived to Chassis"
        },
        {
            value: "PULLCONTAINER_ARRIVED",
            label: "Enroute to Pick Up"
        },
        {
            value: "PULLCONTAINER_DEPARTED",
            label: "Arrived at Pick Up"
        },
        {
            value: "DROPCONTAINER_ARRIVED",
            label: `Enroute to Lift Off / Enroute to Drop (Container or Trailer)`
        },
        {
            value: "DROPCONTAINER_DEPARTED",
            label: `Grounded / Dropped`
        },
        {
            value: "HOOKCONTAINER_ARRIVED",
            label: `Enroute to Lift On / Enroute to Hook (Container or Trailer)`
        },
        {
            value: "HOOKCONTAINER_DEPARTED",
            label: `Arrived to Lift On / Arrived to Hook (Container or Trailer)`
        },
        {
            value: "RETURNCONTAINER_ARRIVED",
            label: "Enroute to Return Load"
        },
        {
            value: "RETURNCONTAINER_DEPARTED",
            label: "Arrived at Return Load"
        },
        {
            value: "CHASSISTERMINATION_ARRIVED",
            label: "Enroute to Return Chassis"
        },
        {
            value: "CHASSISTERMINATION_DEPARTED",
            label: "Arrived to Return Chassis"
        },
        ...liftOffOnOptions,
        {
            value: "DELIVERLOAD_ARRIVED",
            label: `Enroute to Deliver Load / Enroute to Get Loaded`
        },
        {
            value: "DELIVERLOAD_DEPARTED",
            label: `Arrived at Deliver Load / Arrived at Get Loaded`  
        },
        {
            value: "RETURNCONTAINER_ARRIVED",
            label: "Enroute to Return Empty"
        },
        {
            value: "RETURNCONTAINER_DEPARTED",
            label: "Arrived at Return Empty"
        },
        {
            value: "DISPATCHED",
            label: "Dispatched"
        },
]

export const optionsForTime = (array, status) => {
    let defaultVal = []
    if (array?.length > 0) {
        array.forEach((item) => {
            if (item.status === status?.value) {
                if (item.timeInterval?.length > 0) {
                    item.timeInterval.forEach((P) => {
                        defaultVal.push({ label: `${P < 60 ? P : P / 60} ${P < 60 ? 'min' : 'hr'}`, value: P })
                    })
                }
            }
        })
    }
    return defaultVal
}

export const missingLoadInfo = [
        {
            label: "Vessel ETA",
            value: "vessel"
        },
        {
            label: "Last Free Day",
            value: "lastFreeDay"
        },
        {
            label: "Discharged Date",
            value: "dischargedDate"
        },
        {
            label: "Outgate Date",
            value: "outgateDate"
        },
        {
            label: "Empty Date",
            value: "emptyDay"
        },
        {
            label: "Return Date",
            value: "freeReturnDate"
        },
        {
            label: "Ingate Date",
            value: "ingateDate"
        },
        {
            label: "Container #",
            value: "containerNo"
        },
        {
            label: "Container Size",
            value: "containerSize"
        },
        {
            label: "Container Type",
            value: "containerType"
        },
        {
            label: "Steamship Line",
            value: "containerOwner"
        },
        {
            label: "Chassis #",
            value: "chassisNo"
        },
        {
            label: "Chassis Size",
            value: "chassisSize"
        },
        {
            label: "Chassis Type",
            value: "chassisType"
        },
        {
            label: "Chassis Owner",
            value: "chassisOwner"
        },
        {
            label: "Genset #",
            value: "genset"
        },
        {
            label: "Temperature",
            value: "temperature"
        },
        {
            label: "Route",
            value: "routeType"
        },
        {
            label: "Master Bill of Lading",
            value: "callerbillLandingNo"
        },
        {
            label: "House Bill of Lading",
            value: "doNo"
        },
        {
            label: "Seal #",
            value: "sealNo"
        },
        {
            label: "Reference #",
            value: "secondaryReferenceNo"
        },
        {
            label: "Vessel Name",
            value: "deliveryOrderNo"
        },
        {
            label: "Voyage",
            value: "releaseNo"
        },
        {
            label: "Purchase Order #",
            value: "purchaseOrderNo"
        },
        {
            label: "Shipment #",
            value: "shipmentNo"
        },
        {
            label: "Pick Up #",
            value: "callerPONo"
        },
        {
            label: "Appointment #",
            value: "appointmentNo"
        },
        {
            label: "Return #",
            value: "returnNo"
        },
        {
            label: "Reservation #",
            value: "reservationNo"
        },
        {
            label: "Gray Container #",
            value: "grayContainerNo"
        },
        {
            label: "Gray Container Size",
            value: "grayContainerSize"
        },
        {
            label: "Gray Container Type",
            value: "grayContainerType"
        },
        {
            label: "Gray Steamship Line",
            value: "grayContainerOwner"
        },
        {
            label: "Gray Chassis #",
            value: "grayChassisNo"
        },
        {
            label: "Gray Chassis Size",
            value: "grayChassisSize"
        },
        {
            label: "Gray Chassis Type",
            value: "grayChassisType"
        },
        {
            label: "Gray Chassis Owner",
            value: "grayChassisOwner"
        },
        {
            label: "Load",
            value: "loadTime"
        },
        {
            label: "ERD",
            value: "containerAvailableDay"
        },
        {
            label: "Cutoff",
            value: "cutOff"
        },
        {
            label: "Return",
            value: "return"
        },
        {
            label: "SCAC",
            value: "scac"
        },
        {
            label: "Booking #",
            value: "bookingNo"
        },
        {
            label: "Return Location",
            value: "emptyOrigin"
        },
        {
            label: "Return From",
            value: "returnFromTime"
        },
        {
            label: "Return To",
            value: "returnToTime"
        },
        {
            label: "Hook Chassis",
            value: "chassisPick"
        },
        {
            label: "Terminate Chassis",
            value: "chassisTermination"
        },
        {
            label: "Freight Hold",
            value: "freight"
        },
        {
            label: "Custom Hold",
            value: "custom"
        },
        {
            label: "Trailer",
            value: "trailer"
        },
        {
            label: "Trailer Type",
            value: "trailerType"
        },
        {
            label: "Trailer Size",
            value: "trailerSize"
        }
]

export const getTimeOptions = (inputValue) => {
    return new Promise((resolve, reject) => {
      if(/\b(0?[1-9]|1[0-9]|2[0-4])\b/g.test(inputValue)){
        let filterSearch = waitingTime().filter((d) => d["label"].toLowerCase().indexOf(inputValue.toLowerCase().trim()) !== -1);
        resolve(filterSearch);
      } else {
        toastr.show("Enter Valid Time", "error");
        reject("Invalid input value");
      }
    });
}

export const isCustomerTypeFound = (inputData, types) => {
    if (!inputData?.customerType || !types)
        return false
    let newArray = _.intersection(inputData.customerType, types)
    if (newArray.length > 0) return true
    return false
}
  

export const validateCustomerAddress = function(data) {
    return (/[`!@#$%^&*_+\=\[\]{};:"\\|<>\/?~]/.test(data))
}

export const customerJoiValidateKeys = [
    "companyID",
    "company_name",
    "trackingID",
    "address",
    "address1",
    "isAddressVerified",
    "city",
    "state",
    "country",
    "zip_code",
    "main_contact_name",
    "secondary_contact_name",
    "mobile",
    "email",
    "qbEmail",
    "billingEmail",
    "extraEmail",
    "countryCode",
    "password",
    "credit_limit",
    "payment_terms",
    "account_hold",
    "_id",
    "filename",
    "isDeleted",
    "isEmailNeedToSend",
    "terminal",
    "document",
    "fileType",
    "documents",
    "customerType",
    "newTerminal",
    "receiverEmail",
    "geofence",
    "fleetCustomer",
    "notes",
    "payType",
    "mcNumber",
    "notificationEmails",
    "portUsername",
    "portPassword",
    "doc_all",
    "doc_tir",
    "doc_pod",
    "doc_none",
    "extraSubCustomer",
    "salesAgents",
    "customerTags",
    "balance",
    "canEditLoad",
    "isTIROptional",
    "secondaryPhoneNo",
    "officeHoursStart",
    "officeHoursEnd",
    "currency",
    "doc_all",
    "doc_tir",
    "doc_pod",
    "doc_none",
    "companyField",
    "customerSubType",
    "accessDocument",
    "webhookEvents",
    "isInvalid",
    "externalAccountId",
    "attemptCount",
    "preferredCurrency",
    "invoiceCurrencyWithBranch",
    "invoiceCurrencyWithCarrier",
    "requiredDocList",
    "taxType",
    "taxRate",
    "qbSalesTaxId",
    "masterTaxAll",
    "scac",
    "registrationNumber",
    "registrationCountry",
    "shareLoadNotes",
    "shareBillingNotes",
    "paymentTermQbId",
    "customerCombinationMethod",
    "isSeparateInvoicePerTerminal",
    "paymentTermsMethod",
    "terminalSpecificPaymentTerms",
    "defaultPaymentTerms",
    "externalSystemID",
    "organizationDomain",
    "accountID",
    "isSystemGeneratedTerminal",
    "portMarket",
    "profileLabel"
]

export const newTabLists = [
    {
      label:  "Organization Info" ,
      icon: <IconUsers />,
      id: 1,
    },
    {
      label: "Documents",
      icon: <IconDocument /> ,
      id: 2,
    },
    {
      label: "Geofence",
      icon: <IconMapWithMarker />,
      id: 3,
    },
    {
      label: "Notes",
      icon: <IconNotes />,
      id: 4,
    },
    {
      label: "Audit",
      icon: <IconSearchData />,
      id: 5,
    },
    {
      label: "People",
      icon: <IconUsersGroup />,
      id: 6,
    },
    {
      label: "Email Notification",
      icon: <IconSendEmail />,
      id: 7,
    },
  ];