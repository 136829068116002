import { IconPlus } from "Components/Common/Icons"
import { getSinglePeople } from "pages/tms/People/actionCreator"
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { amplitudeTrack, displayName, getStorage } from "../../../../../services"
import PeopleModal from "../../../People/PeopleModal"
import ExistingPersonModal from "./ExistingPersonModal"
import Avatar from "Components/Common/Avatar"
import { extractPersonData } from "../../helper"
import { convertS3ToCloudFrontURL } from "../../../People/constants"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../constant"
import EmailParticipantOrganizationModal from "../../../People/EmailParticipantOrganizationModal.jsx"
import { useGetOrgByDomain } from "./hooks";
import { LoaderBar } from "Components/Common/LoaderBar";

const TABS = {
  ALL_PARTICIPANTS: "All Participants",
  EXTERNAL: "External",
  TEAM: "Team"
}

const tabs = [
  { name: TABS.ALL_PARTICIPANTS },
  { name: TABS.EXTERNAL },
  { name: TABS.TEAM }
];

// const tabsData = {
//   "all" : "All People",
//   "customer": "Customers",
//   "team": "Team"
// }
const ParticipantInsight = ({ show, hide, emailPeople, getEmailPeople, isExternal, isEmailConnected, isLoggedInUserEmail  , isEmailMode, threadId }) => {
  const [activeTab, setActiveTab] = useState(TABS.ALL_PARTICIPANTS)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showExistingModal, setShowExistingModal] = useState(false)
  const [showMultiOrgModal, setShowMultiOrgModal] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState(null);
  const customerEmails = emailPeople?.filter((d) => d.organizationId) || []
  const teamEmails = emailPeople?.filter((d) => d.carrierFleet) || []
  const [loaderIds, setLoaderIds] = useState([]);
  const [personData, setPersonData] = useState()
  const phoneFormat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat

  const { organizations, loading, getOrgs } = useGetOrgByDomain();

  const handleShowAddModal = (peopleId, isUnassociated) => {
    if(peopleId) {
      setLoaderIds(peopleId);
      getSinglePeople(peopleId, isUnassociated).then((res) => {
        setSelectedPeople(res)
        setShowAddModal(true)
        setLoaderIds([]);
      }).catch((err) => {
        console.log(err);
        setLoaderIds([]);
      })
    }
  }

  const handleHideAddModal = () => {
    setShowAddModal(false)
    setShowMultiOrgModal(false)
  }

  const handleShowExistingModal = (person) => {
    const data = extractPersonData(person, true, phoneFormat)
    setShowExistingModal(true)
    setPersonData(data)
  }
  const handleHideExistingModal = () => {
    setShowExistingModal(false)
  }

  const handleActiveTab = (tab) => {
    setActiveTab(tab)
  }
  let peopleData = []
  if (activeTab === TABS.ALL_PARTICIPANTS) {
    peopleData = emailPeople || []
  } else if (activeTab === TABS.EXTERNAL) {
    peopleData = customerEmails || []
  } else {
    peopleData = teamEmails || []
  }

  const setPersonDataOnClick = async (personData) => {
    const data = extractPersonData(personData, false, phoneFormat);
    const orgs = await getOrgs(personData.email);
    setSelectedPeople(data);
    if (orgs.length > 1) {
      setShowMultiOrgModal(true);
    } else {
      setShowAddModal(true);
    }
  };

  const count = (tab) => {
    switch (tab) {
      case TABS.ALL_PARTICIPANTS: return emailPeople?.length || 0; break;
      case TABS.EXTERNAL: return customerEmails?.length || 0; break;
      default: return teamEmails?.length || 0; break;
    }
  }

  const isAllRecipientsTab = activeTab === TABS.ALL_PARTICIPANTS; 
  const hasCarrierFleet =  peopleData.some(e=>!!e?.carrierFleet)
    return (
    <>
      <Modal show={show} backdropClassName="z-1050" dialogClassName="modal-dialog-scrollable">
        <Modal.Header>
          <Modal.Title>
           Participants
            <div className="text-muted font-12">{emailPeople?.length || 0} participants in this conversation</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {loading && <LoaderBar />}
          <ul className="nav nav-tabs nav-tabs-custom nav-justified border-bottom-1 border-gray-100">
            {tabs.map((tab, index) => (
              <li className="nav-item" key={index}>
                <a
                  className={`nav-link ${tab.name === activeTab ? "active" : ""}`}
                  onClick={() => handleActiveTab(tab.name)}
                >
                  {tab.name}
                  <span className="badge badge-sm font-12 ml-1 px-1 text-white badge-primary badge-pill">
                  {count(tab.name)}
                  </span>
                </a>
              </li>
            ))}
          </ul>
          <div className="bg-gray-50 p-1 rounded-5 mt-20">
            <table className={`table table-card table-card-sm table-fixed ${!isAllRecipientsTab ? "table-no-hs": ""}`}>
              <tbody>
              {peopleData?.length > 0 ? peopleData.map((person, index) => (
                <tr key={index}>
                  <td className="p_5">
                  <div className="d-flex align-items-start gap-10 text-truncate">
                    <div className="wh-30px rounded-circle bg-gray-100 d-flex align-items-center justify-content-center flex-shrink-0">
                      {person?.profileUrl ? (
                        <Avatar src={convertS3ToCloudFrontURL(person?.profileUrl)} />
                      ) : (
                        displayName(person?.personName?.trim() ? person?.personName : person?.email)
                      )}
                    </div>
                    <div className="text-truncate">
                      <span className="font-14 line-height-20 font-medium text-truncate">{person.personName || "-"}</span>
                      <span className="text-muted ml-1 text-truncate">{person.email}</span>
                      <div className="text-muted">{person.organizationName || person.companyName || "-"}
                      <span className="badge badge-sm badge-light ml-1">{person?.title || ""}</span>
                      </div>
                    </div>
                  </div>
                  </td>
                  
                  {isAllRecipientsTab && hasCarrierFleet &&  (person?.carrierFleet ?
                  <td className="p_5" width={40}>
                    <span className="badge badge-secondary badge-sm">
                      Team
                    </span>
                  </td>:<td className="p_5" width={40}> </td>
                  )
                  }
                    <td className="p_5 text-right" width={240}>
                      {!person?.carrierFleet && !isLoggedInUserEmail  (person?.email) && (
                      <>
                      {!person?.peopleId ? (
                        <div className="d-flex align-items-center gap-5 justify-content-end">
                          <button 
                          className="btn btn-primary btn-sm" 
                          onClick={() => setPersonDataOnClick(person) }>
                            <IconPlus className="mr-1" />
                            <div className="text-nowrap">Add Person</div>
                          </button>
                          <button className="btn btn-outline-light btn-sm" onClick={() => handleShowExistingModal(person)}>
                            <IconPlus className="mr-1" />
                            <div className="text-nowrap">Add to Existing</div>
                          </button>
                        </div>
                      ) : (
                        <button key={index} className="btn btn-outline-light btn-sm" onClick={() => {
                            handleShowAddModal(person.peopleId, person?.organizationId ? false : true);
                            let eventProperties = {
                              source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_RECIPIENTS_MODAL : AMPLITUDE_EVENTS_SOURCE.GRID_RECIPIENTS_MODAL
                            }
                            amplitudeTrack(AMPLITUDE_EVENTS.EDIT_PERSON, eventProperties)
                        }}>
                          {loaderIds.includes(person.peopleId) && <span className="mr-2 spinner-border spinner-border-sm" ></span>}
                          Edit 
                        </button>
                      )}
                      </>
                      )} 
                    </td>
                </tr>
              )) : (
                <tr>
                  <td className="text-center text-capitalize">No data found</td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-light" onClick={hide}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {showAddModal && selectedPeople && (
        <PeopleModal
          show={showAddModal}
          hide={handleHideAddModal}
          selectEditPerson={selectedPeople}
          getEmailPeople={getEmailPeople}
          fromAdd={true}
          isExternal={isExternal}
          isEmailConnected={isEmailConnected}
          event={AMPLITUDE_EVENTS.ADD_PERSON}
          eventProperties={{ source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_RECIPIENTS_MODAL : AMPLITUDE_EVENTS_SOURCE.GRID_RECIPIENTS_MODAL }}
          threadId={threadId}
        />
      )}
      {showMultiOrgModal && (
        <EmailParticipantOrganizationModal
          people={selectedPeople}
          handlePeopleChange={setSelectedPeople}
          organizations={organizations}
          hide={() => setShowMultiOrgModal(false)}
          showPeople={() => {
            console.log("EmailParticipantOrganizationModal showPeople");
            setShowAddModal(true);
          }}
        />
      )}
      {showExistingModal && 
          <ExistingPersonModal show={showExistingModal} hide={handleHideExistingModal} closeInsight={hide} getEmailPeople={getEmailPeople} personData={personData} isExternal={isExternal} isEmailConnected={isEmailConnected} event={AMPLITUDE_EVENTS.ADD_TO_EXISTING} eventProperties={{ source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_RECIPIENTS_MODAL : AMPLITUDE_EVENTS_SOURCE.GRID_RECIPIENTS_MODAL }} threadId={threadId} />
      }
    </>
  )
}

export default ParticipantInsight
