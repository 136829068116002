// columns.js
import { IconDisconnect, IconInbox, IconTrash, IconDotSeperator, IconDot, IconAttachment } from "Components/Common/Icons";
import CheckboxFormatter from "./Components/Formatter/CheckboxFormatter";
import ContextMenuWrapper from "./Components/ContextMenuWrapper";
import StarEmailToggle from "./Components/StarEmailToggle";
import { formatDate, formateParticipants } from "./helper";
import { EMAIL_TABS, statusLabels, EMAIL_TOOL_TIPS, ARCHIVE_LABLES, AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_PROVIDER } from "./constant";
import EachEmailTag from "./Components/EmailTags/EachEmailTag";
import EmailTime from "./Components/EmailTime/EmailTime";
import { StatusCellDropdown } from "./Components/EmailStatus";
import ReadUnreadEmailToggle from "./Components/ReadUnreadEmailToggle";
import SafeTextDecode from "Components/Common/SafeContent/SafeTextDecode";
import DraggableWrapper from "./Components/DragAndDrop/DraggableWrapper"
import { getStorage } from "services";
import ReactTooltip from "react-tooltip";

const trashLabel = ["Trash", "Deleted Items", "Deletions"];
export const columns = ({
  selectedRows,
  handleCheckboxChange,
  handleRowClick,
  handleOnArchive,
  hanldeOnDelete,
  setRightClickKey,
  isFromLoad,
  handleDisconnect,
  _getCounts,
  activeNav,
  toggleStar,
  loaderIds,
  allLabels,
  archiveLoaderIds,
  deleteLoader,
  setSelectedRows,
  isFromQuote,
  handleDisconnectQuote,
  allEmailStatus,
  setShowCreateLabelModal,
  setShowCreateTag,
  handleBottomUnread,
  allTags,
  readUnreadIds,
  activePage,
  isSentEmailTab,
  isSubFolderActive,
  setupdateStatusData,
  setupdateStatusDataFlag
}) => {
  const archive = allLabels?.find(l => (l.name || "").toLowerCase() === "archive");
  const isArchiveTab = archive?.id === activeNav;
  const isSentTab = EMAIL_TABS.SENT === activeNav;
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
  const { provider } = embeddedEmailAccount??{}

  return [
    {
      key: "checkbox",
      name: "",
      width: 50,
      minWidth: 50,
      resizable: false,
      formatter: ({ row }) => (
        <CheckboxFormatter isFromLoad={isFromLoad} row={row} isHeader={false} selectedRows={selectedRows} onSelectRow={handleCheckboxChange} />
      ),
      headerRenderer: ({ column }) => (
        <CheckboxFormatter isFromLoad={isFromLoad} isHeader={true} selectedRows={selectedRows} onSelectRow={handleCheckboxChange} />
      ),
    },
    ...(![EMAIL_TABS.DRAFT].includes(activePage) ? [{
      key: "star",
      name: "",
      width: 41,
      minWidth: 41,
      resizable: false,
      formatter: ({ row }) => (
        <ContextMenuWrapper
          isFromLoad={isFromLoad}
          row={row}
          hanldeOnDelete={hanldeOnDelete}
          handleOnArchive={handleOnArchive}
          setRightClickKey={setRightClickKey}
          _getCounts={_getCounts}
          setSelectedRows={setSelectedRows}
          handleBottomUnread={handleBottomUnread}
          activePage={activePage}
          disable={row?.object === "draft" || [EMAIL_TABS.DRAFT].includes(activePage)}
        >
          <StarEmailToggle row={row} toggleStar={toggleStar} loaderIds={loaderIds} />
        </ContextMenuWrapper>
      ),
    }] : []),
    ...(![EMAIL_TABS.DRAFT].includes(activePage) ? [{
      key: "readUnread",
      name: "",
      width: 41,
      minWidth: 41,
      resizable: false,
      formatter: ({ row }) => (
        <ContextMenuWrapper
          isFromLoad={isFromLoad}
          row={row}
          hanldeOnDelete={hanldeOnDelete}
          handleOnArchive={handleOnArchive}
          setRightClickKey={setRightClickKey}
          _getCounts={_getCounts}
          setSelectedRows={setSelectedRows}
          handleBottomUnread={handleBottomUnread}
          activePage={activePage}
          disable={row?.object === "draft" || [EMAIL_TABS.DRAFT].includes(activePage)}
        >
          <ReadUnreadEmailToggle row={row} handleBottomUnread={handleBottomUnread} readUnreadIds={readUnreadIds} eventProperties={{ source : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_ROW }}/>
        </ContextMenuWrapper>
      ),
    }]: []),
    {
      key: "from/to",
      name: isSentTab ? "To" : "From/To",
      width: 200,
      minWidth: 200,
      resizable:false,
      formatter: ({ row = {} }) => {
        const { messageIds = [], draftIds = [] } = row;
        const threadCount = messageIds?.length + draftIds?.length;
        let isDraft = activeNav === "Drafts" || row?.object === "draft";
        return (
          <DraggableWrapper activePage={activePage} row={row} isFromLoad={isFromLoad} isFromQuote={isFromQuote}>
          <ContextMenuWrapper
            row={row}
            isFromLoad={isFromLoad}
            onClick={handleRowClick}
            hanldeOnDelete={hanldeOnDelete}
            handleOnArchive={handleOnArchive}
            setRightClickKey={setRightClickKey}
            _getCounts={_getCounts}
            setSelectedRows={setSelectedRows}
            setShowCreateLabelModal={setShowCreateLabelModal}
            setShowCreateTag={setShowCreateTag}
            handleBottomUnread={handleBottomUnread}
            activePage={activePage}
          >
            <div className="d-flex align-items-center justify-content-start">
              <div className={`d-flex align-items-center w-170 ${row?.unread ? "font-bold" : ""}`}>
                {isDraft ?
                  <div className="text-truncate mr_5">{"Draft"}</div> :
                  <div className="text-truncate mr_5">{formateParticipants(row,isSentTab)}</div>}
                {threadCount > 1 && <div className="text-muted">{threadCount}</div>}
              </div>
            </div>
          </ContextMenuWrapper>
          </DraggableWrapper>
        );
      },
    },
    ...(![EMAIL_TABS.DRAFT].includes(activePage) ? [{
      key: "status",
      name: "Status",
      width: 148,
      minWidth: 148,
      formatter: ({ row }) => {
        const lable = row?.status || allEmailStatus.find((a) => a?.threadId === row.id)?.status || "OPEN";
        const status = statusLabels?.find((s) => s.value === lable);
        return (
          <DraggableWrapper activePage={activePage} row={row} isFromLoad={isFromLoad} isFromQuote={isFromQuote}>
            <ContextMenuWrapper activePage={activePage} isFromLoad={isFromLoad}>
              <StatusCellDropdown threadId={row.id} accountId={row.grantId} status={status} setupdateStatusData={setupdateStatusData} setupdateStatusDataFlag={setupdateStatusDataFlag}/>
            </ContextMenuWrapper>
          </DraggableWrapper>
        )
      },
    }] : []),
    {
      key: "body",
      name: "Subject • Body",
      formatter: ({ row }) => {
        const { messageIds = [], folders = [] } = row;
        return (
        <DraggableWrapper activePage={activePage} row={row} isFromLoad={isFromLoad} isFromQuote={isFromQuote}>
          <ContextMenuWrapper
            row={row}
            onClick={handleRowClick}
            hanldeOnDelete={hanldeOnDelete}
            handleOnArchive={handleOnArchive}
            setRightClickKey={setRightClickKey}
            _getCounts={_getCounts}
            setSelectedRows={setSelectedRows}
            setShowCreateLabelModal={setShowCreateLabelModal}
            handleBottomUnread={handleBottomUnread}
            setShowCreateTag={setShowCreateTag}
            activePage={activePage}
            isFromLoad={isFromLoad}
          >
            <div className={`d-flex gap-10 align-items-center ${row?.snippet ? "" : "justify-content-between"}`}>
              {messageIds?.length > 0 && activeNav === EMAIL_TABS.DRAFT && folders?.includes('INBOX') && (
                <span className={`text-truncate badge badge-sm badge-gray-100`}>Inbox</span>
              )}
              
              <div className="text-truncate flex-basis-auto">
                <span className={`text-truncate ${row?.unread ? "font-bold" : ""}`}>
                  {row?.subject?.length ? row?.subject : row?.latestDraftOrMessage?.subject ? row?.latestDraftOrMessage?.subject : "(No Subject)"}
                </span>
              </div>

              {row?.snippet && (
                <>
                <IconDotSeperator className="flex-shrink-0 text-muted" />
                <span className="text-muted rdg-truncate flex-grow-1 flex-basis-0">
                  <SafeTextDecode text={row?.snippet} />
                </span>
                </>
              )}
              
              {row &&
                <div className="d-flex align-items-center gap-5 maybe-empty flex-shrink-0">
                  {row?.hasAttachments &&
                  <IconAttachment className="flex-shrink-0 text-gray-300 wh-20px" />
                  }
                  {row && row?.timeElapsedLow && row?.timeElapsedMedium && row?.timeElapsedHigh && <EmailTime row={row}/>}
                  <EachEmailTag key={row.id} row={row} allTags={allTags}/>
                </div>
              }
            </div>
          </ContextMenuWrapper>
        </DraggableWrapper>
        )},
    },
    {
      key: "date",
      name: "Date",
      width: 100,
      minWidth: 100,
      formatter: ({ row }) => {
        let isDraft = activeNav === "Drafts" || row?.object === "draft";
        const dateToSelect = (isDraft && row?.date) ? row?.date :
          isSentEmailTab ?
            (row?.latestMessageSentDate ?? row?.lastMessageTime) :
            (row?.latestMessageReceivedDate ?? row?.lastMessageTime ?? row?.latestMessageSentDate)
        return (
        <DraggableWrapper activePage={activePage} row={row} isFromLoad={isFromLoad} isFromQuote={isFromQuote}>
        <ContextMenuWrapper
          row={row}
          onClick={handleRowClick}
          hanldeOnDelete={hanldeOnDelete}
          handleOnArchive={handleOnArchive}
          setRightClickKey={setRightClickKey}
          _getCounts={_getCounts}
          setSelectedRows={setSelectedRows}
          setShowCreateLabelModal={setShowCreateLabelModal}
          setShowCreateTag={setShowCreateTag}
          handleBottomUnread={handleBottomUnread}
          activePage={activePage}
          isFromLoad={isFromLoad}
        >
          {formatDate(dateToSelect)}
        </ContextMenuWrapper>
        </DraggableWrapper>
      )},
    },
    ...(!isFromLoad && !isFromQuote
      ? [
        ...(!isArchiveTab && ![EMAIL_TABS.DRAFT].includes(activePage) ? [{
            key: "archive",
            name: "",
            width: 42,
            minWidth: 42,
            formatter: ({ row }) => {
              // const labelsValue = provider === EMAIL_PROVIDER.GMAIL ? row?.labels : OUTLOOK_PROVIDER.includes(provider) && row?.folders;
              const CheckFolderConditionForGmail = (provider === EMAIL_PROVIDER.GMAIL?  !row?.folders?.includes("INBOX") : false)
              return (
                <ContextMenuWrapper
                  row={row}
                  onClick={() => handleOnArchive([row.id], AMPLITUDE_EVENTS.ARCHIVE, { source: AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_ROW })}
                  hanldeOnDelete={hanldeOnDelete}
                  handleOnArchive={handleOnArchive}
                  setRightClickKey={setRightClickKey}
                  _getCounts={_getCounts}
                  setSelectedRows={setSelectedRows}
                  handleBottomUnread={handleBottomUnread}
                  activePage={activePage}
                  isFromLoad={isFromLoad}
                  disable={ARCHIVE_LABLES?.includes(activeNav?.toLowerCase()) || [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL]?.includes(activePage) || CheckFolderConditionForGmail}
                >
                  <div
                    data-tip={EMAIL_TOOL_TIPS.ARCHIVE}
                    data-for="email-table-tooltip"
                  >
                          {!!archiveLoaderIds.length && archiveLoaderIds.includes(row.id) ? (
                            <span key={row.id} className="spinner-border spinner-border-sm batch-menu__itemicon" />
                          ) : (
                            <IconInbox className="text-muted" />
                          )}
                          {/* <EmailToolTip toolTipId={`archive-mail-${row?.id}`}/> */}
                        </div>
                      </ContextMenuWrapper>
                    );
                  },
                }] : []),
          {
            key: "delete",
            name: "",
            width: 42,
            minWidth: 42,
            formatter: ({ row }) => {
              const disable = ["trash"].includes(activeNav) || [EMAIL_TABS.DELETED_ITEMS].includes(activePage);
              return (
                <ContextMenuWrapper
                  row={row}
                  onClick={() => hanldeOnDelete(row, AMPLITUDE_EVENTS.TRASH, { source: AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_ROW })}
                  hanldeOnDelete={hanldeOnDelete}
                  handleOnArchive={handleOnArchive}
                  setRightClickKey={setRightClickKey}
                  _getCounts={_getCounts}
                  setSelectedRows={setSelectedRows}
                  handleBottomUnread={handleBottomUnread}
                  activePage={activePage}
                  isFromLoad={isFromLoad}
                  disable={disable || trashLabel.includes(activeNav)}
                >
                  <div
                    data-tip={EMAIL_TOOL_TIPS.DELETE}
                    data-for="email-table-tooltip"
                  >
                    {!!deleteLoader?.length && deleteLoader.includes(row.id) ? (
                      <span key={row.id} className="spinner-border spinner-border-sm batch-menu__itemicon" />
                    ) : (
                      <IconTrash className="text-muted" />
                    )}
                    {/* <EmailToolTip toolTipId={`trash-mail-${row?.id}`} /> */}
                  </div>

                </ContextMenuWrapper>
              );
            },
          },
        ]
      : []),
    ...(isFromLoad || isFromQuote
      ? [
          {
            key: "disconnect",
            name: "",
            width: 42,
            minWidth: 42,
            formatter: ({ row }) => (
              <ContextMenuWrapper activePage={activePage} isFromLoad={isFromLoad} row={row} onClick={() => { isFromQuote ? handleDisconnectQuote(row) :  handleDisconnect(row, AMPLITUDE_EVENTS.DISCONNECT_LOAD, { source: AMPLITUDE_EVENTS_SOURCE.LOAD_COMMUNICATION_EMAIL_ROW }); ReactTooltip.hide();}} _getCounts={_getCounts}>
                <div
                  data-tip={EMAIL_TOOL_TIPS.UNLINK_LOAD}
                  data-for="email-table-tooltip"
                >
                  {/* <IconInbox className="text-muted" /> */}
                  <IconDisconnect className="text-muted" />
                  {/* <EmailToolTip toolTipId={`unlink-mail-${row?.id}`} /> */}
                </div>
              </ContextMenuWrapper>
            ),
          },
        ]
      : []),
  ];
};
