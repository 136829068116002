import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { smallSelectStyle } from "assets/js/select-style";
import AsyncSelect from 'react-select/async';
import {components} from 'react-select';
import { allTrucks } from '../../../../AssignTruck/actionCreators';
import CustomDateTimeRangePicker from './../../../../../../Components/CustomDateTimeRangePicker';
import { DateTimeFormatUtils, toastr, updateTerminology } from './../../../../../../services';
import { listAllFleetManagers } from './../../../../../tms/Customer/actionCreators';
import { getTimeZone, typeMapping } from './../../../../NewDispatcher/constants';

import { getFleetTruckOwner } from '../../../../Truck/actionCreators';
import { ADD_GROUPED_PROFILES_FILTER_KEYS, DAHSBOARD_CLEARABLE_DATE_FILTER, DASHBOARD_DATE_FILTERS, DASHBOARD_MULTI_SELECT_FILTER, EXCLUDE_FILTER_KEYS, excludeVeriableName, NEWEST_10_CUSTOMERS_LABEL } from '../../constants';
import { DATE_RANGE, getBooleanFilter, getDashboardFilterLoadOptions, getDashboardFilterOptions, getGroupProfiles } from '../../helper';
import { IconTimes } from '../../../../../../Components/Common/Icons';

/**
 * This component is used to render the dashboard filter.
 * It includes the filter title, key, options, and the selected value.
 * It also handles the change of filter and the load options for the filter.
 */
const DashBoardFilterComponent = (props) => {
    const {
        title,
        filterKey,
        reportDataFilter,
        redux,
        filter,
        customerActions,
        isChartLevelFilter = false,
        displayDispatcherFilter = false,
        allFilters,
        activeTabName
    } = props
    const DATE_RANGE_FOR_CHARTS = DATE_RANGE()
    const [filterValue, setFilterValue] = useState(filter || null)
    const [filterOptions, setFilterOptions] = useState([])
    const [excludeFilter, setExcludeFilter] = useState(allFilters[excludeVeriableName[filterKey]] || false)
    const addGroupedProfiles = displayDispatcherFilter && !isChartLevelFilter && ADD_GROUPED_PROFILES_FILTER_KEYS.includes(title)
    const booleanFilter = getBooleanFilter(activeTabName)
    const [groupedProfilesOptions, setGroupedProfilesOptions] = useState([])

    const setGroupProfiles = () => {
        if (!displayDispatcherFilter || isChartLevelFilter) return;
        const { groupedProfileOptions } = getGroupProfiles(redux?.measureUnitsReducer)

        setGroupedProfilesOptions(groupedProfileOptions);
    }

    /**
     * This function is used to get the filter options.
     * It sets the filter options in the state.
     */
    const getFilterOptions = async () => {
        let options = await getDashboardFilterOptions({ filterTitle: title, ...redux, customerActions, isChartLevelFilter, displayDispatcherFilter, filterValue})
        if(Array.isArray(filterValue) && filterValue.some(value => value.label === NEWEST_10_CUSTOMERS_LABEL)){
            options = options.filter(option => option.label !== NEWEST_10_CUSTOMERS_LABEL);
        }
        setFilterOptions(options)
    }

    useEffect(() => {
        getFilterOptions()
        setGroupProfiles()
    }, [filterValue])

    /**
     * This function is used to handle the change of the filter.
     * It sets the filter value in the state and dispatches the report data filter action.
     */
    const handleOnChangeofFilter = (e) => {
        let displayFilterValues = []
        let payloadFilterValues = []
        if (Array.isArray(e) && e.find(option => option.value === 'all')) {
            displayFilterValues = filterOptions.filter(option => option.value !== 'all')
            setFilterValue(displayFilterValues)
            payloadFilterValues = DASHBOARD_MULTI_SELECT_FILTER.includes(title) ? (displayFilterValues || []) : (displayFilterValues || {});
        } else {
            if (DASHBOARD_DATE_FILTERS.includes(title)) {
                setFilterValue(e.value)
                payloadFilterValues = DASHBOARD_MULTI_SELECT_FILTER.includes(title) ? (e.value || []) : (e.value || {})
            } else {
                setFilterValue(e)
                payloadFilterValues = DASHBOARD_MULTI_SELECT_FILTER.includes(title) ? (e || []) : (e || {})
            }
        }
        reportDataFilter(filterKey, payloadFilterValues)
    }

    const handleOnChangeofExcludeFilter = (checked) => {
        setExcludeFilter(checked)
        reportDataFilter(excludeVeriableName[filterKey], checked)
    }

    const handleOnChangeofBooleanFilter = (checked) => {
        setFilterValue(checked)
        reportDataFilter(filterKey, checked)
    }

    /**
     * This function is used to handle the load options for the filter.
     * It returns the filter options.
     */
    const handleLoadOptionsFilter = (e) => {
        return getDashboardFilterLoadOptions(title, e, filterOptions, {addGroupedProfiles, groupedProfilesOptions})
    }

    /**
     * This function is used to handle the apply event of the date range picker.
     * It checks if the date range is more than 3 months and shows a toastr error if it is.
     * It then handles the change of the filter.
     */
    const onApply = (e, picker) => {
        if (moment(picker.endDate).diff(moment(picker.startDate), "month") > 3) {
            toastr.show("Date range should not be more than 3 months.", "error");
            return;
        }
        handleOnChangeofFilter({
            value: {
                "key": title,
                "duration": picker.chosenLabel,
                "customRange": {
                    "from": picker.startDate,
                    "to": picker.endDate
                }
            }
        })
    }

    useEffect(() => {
        setFilterValue(filter || null)
    }, [filter])

    const CustomClearIndicator = (props) => {
        const {
            innerRef,
            innerProps,
            selectProps,
        } = props;
    
        const handleClear = (e) => {
            e.stopPropagation(); // Prevent triggering outside click logic
            selectProps.onChange(null); // Clear the selected options
        };
    
        return (
            <div
                {...innerProps}
                ref={innerRef}
                onMouseDown={handleClear} // Call clear logic
                className='p-2 hover:text-gray-300 pointer'
            >
                <IconTimes />
            </div>
        );
    };

    const CustomOption = (props) => {
        return (
            <components.Option {...props}>
            <div className={`d-flex p-0 align-items-center justify-content-between`}>
              <span
                className={`font-13 font-weight-normal text-dark`}
              >
                {props?.label}
              </span>
              { <span className="text-muted font-medium">{ props?.data?.type ? typeMapping[props?.data?.type] : ""}</span> }
            </div>
          </components.Option>
        )
    }
    

    return (
        <>
            <div className={`d-flex ${booleanFilter?.includes(filterKey) ? "outline-1 outline-gray-100 p-10 rounded-5" : "flex-column"}`}>
                <div className={`d-flex align-items-center justify-content-between ${booleanFilter?.includes(filterKey)? "form-check": ""}`}>
                    <label className={`col-form-label text-capitalize ${booleanFilter?.includes(filterKey) ? "mb-0" :""}`} htmlFor={`${booleanFilter?.includes(filterKey) ? filterKey:""}`}>
                    {booleanFilter?.includes(filterKey) &&
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${filterKey}`}
                            name={`${filterKey}`}
                            checked={filterValue}
                            onChange={(e) => {
                                handleOnChangeofBooleanFilter(e?.target?.checked)
                            }}
                        />
                    }
                        {title}</label>
                    {(displayDispatcherFilter && !isChartLevelFilter && EXCLUDE_FILTER_KEYS.includes(filterKey)) &&
                    <div className="form-check form-check--gray">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`${filterKey}-exclude`}
                            name={`${filterKey}-exclude`}
                            checked={excludeFilter}
                            onChange={(e)=> {
                                handleOnChangeofExcludeFilter(e?.target?.checked)
                            }}
                        />
                        <label className="form-check-label text-muted" htmlFor={`${filterKey}-exclude`}>
                            Exclude
                        </label>
                    </div>}
                </div>
                {DASHBOARD_DATE_FILTERS.includes(title) ?
                    <div className={`input-wrapper`}>
                        <CustomDateTimeRangePicker
                            ranges={DATE_RANGE_FOR_CHARTS}
                            onApply={onApply}
                            onClear={(e) => {
                                handleOnChangeofFilter({ value: null })
                            }}
                            fromDate={filterValue?.customRange?.from || ''}
                            toDate={filterValue?.customRange?.to || ''}
                            isClearable={!DAHSBOARD_CLEARABLE_DATE_FILTER.includes(title)}
                            dateFormat={DateTimeFormatUtils.fullDateFormat()}
                            userTimeZone={getTimeZone({ preferred: true })}
                        />
                    </div>
                    : !booleanFilter?.includes(filterKey) &&
                    <div className="input-wrapper">
                        <AsyncSelect
                            styles={smallSelectStyle}
                            name="template"
                            cacheOptions
                            isMulti={DASHBOARD_MULTI_SELECT_FILTER.includes(title)}
                            defaultOptions={filterOptions}
                            value={filterValue}
                            placeholder={`Select ${title}`}
                            onChange={handleOnChangeofFilter}
                            loadOptions={handleLoadOptionsFilter}
                            menuPosition='fixed'
                            isClearable
                            components={{ 
                                ClearIndicator: CustomClearIndicator,
                                Option: CustomOption
                            }}
                        />
                    </div>
                }
            </div>
        </>
    )
}

/**
 * This function is used to map the dispatch to the props.
 * It includes the customer actions.
 */
function mapDispatchToProps(dispatch) {
    return {
        customerActions: bindActionCreators({
            listAllFleetManagers,
            getFleetTruckOwner,
            allTrucks
        }, dispatch),
    };
}

/**
 * This function is used to map the state to the props.
 * It includes the redux state.
 */
function mapStateToProps(state) {
    return {
        redux: {
            customers: state.TmsReducer.customers,
            callers: state.TmsReducer.callers,
            terminals: state.HomeReducer.terminals,
            chassisOwners: state.chassisReducer.chassisOwner,
            chassisSizes: state.chassisReducer.chassisSize,
            chassisTypes: state.chassisReducer.chassisType,
            shippers: state.TmsReducer.shippers,
            consignees: state.TmsReducer.consignees,
            driverList: state.TmsReducer.drivers,
            allPublicUsers: state.TmsReducer.allPublicUsers,
            statusType: state.TmsReducer.statusTypes,
            containerType: state.containerReducer.containerType,
            containerSize: state.containerReducer.containerSize,
            containerOwner: state.containerReducer.containerOwner,
            chassisOwners: state.chassisReducer.chassisOwner,
            chassisTypes: state.chassisReducer.chassisType,
            measureUnitsReducer: state.measureUnitsReducer
        }

    };
}

/**
 * This function is used to connect the component to the redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardFilterComponent)