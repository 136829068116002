import update from 'react-addons-update';
import moment from 'moment';

import {
  TMS_LOGIN_SUCCESS,
  TMS_LOGOUT,
  TMS_GET_USER_LOAD_FETCH_SUCCEEDED,
  TMS_GET_CUSTOMERS_SUCCEEDED,
  TMS_GET_CUSTOMERS_FLEETSUCCEEDED,
  TMS_GET_LOCATION_PROFILE_SUCCEEDED,
  TMS_NEW_LOAD_SUCCEEDED,
  LOCATION_PRICE,
  UPDATE_LOCATION_PRICE,
  TMS_GET_CUSTOMERS_FAILED,
  TMS_GET_CUSTOMERS_START,
  TMS_GET_FLEETCUSTOMERS_FAILED,
  TMS_GET_FLEETCUSTOMERS_START,
  GET_SHIPPERS_START,
  GET_SHIPPERS_SUCCESS,
  GET_SHIPPERS_FAILED,
  GET_DRAYOSCARRIERS_START,
  GET_DRAYOSCARRIERS_SUCCESS,
  GET_DRAYOSCARRIERS_FAILED,
  GET_CONSIGNEES_START,
  GET_CONSIGNEES_SUCCESS,
  GET_CONSIGNEES_FAILED,
  GET_CHASSIS_TERMS_START,
  GET_CHASSIS_TERMS_SUCCESS,
  GET_CHASSIS_TERMS_FAILED,
  GET_CALLERS_START,
  GET_CALLERS_SUCCESS,
  GET_CALLERS_FAILED,
  GET_ALL_CUSTOMER_START,
  GET_ALL_CUSTOMER_SUCCESS,
  GET_ALL_CUSTOMER_FAILED,
  GET_ALL_USER_PUBLIC_INFO_SUCCESS,
  GET_ALL_USER_PUBLIC_INFO_FAILED,
  TMS_GET_CUSTOMERS_ALL_START,
  TMS_GET_CUSTOMERS_ALL_SUCCEEDED,
  TMS_GET_CUSTOMERS_ALL_FAILED,
  TMS_UPDATE_STATE,
  GET_TIMESLOTS_SUCCESS,
  GET_TIMESLOTS_START,
  GET_TIMESLOTS_FAILED,
  GET_BILLING_CHARGE_LISTS_PENDING,
  GET_BILLING_CHARGE_LISTS_SUCCESS,
  GET_BILLING_CHARGE_LISTS_FAILED,
  CUSTOMER_BILLING_CHARGE_LISTS_PENDING,
  CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS,
  CUSTOMER_BILLING_CHARGE_LISTS_FAILED
} from './constant';
import { getStorage } from 'services';

const defaultState = {
  user: {},
  selectedLoads: undefined,
  isFleetCustomerLoading: false,
  Fleetcustomers: [],
  customers: [],
  customersAll: [],
  isCustomerLoading: false,
  isCustomerAllLoading: false,
  locationProfiles: [],
  location_price: [],
  loads: [],
  drivers: [],
  isDriversCardVide: {cardView:false,filterType:"ALL"},
  statusTypes: [],
  csrUsers: [],
  isDriverLoading: false,
  isCallerLoading: false,
  isShipperLoading: false,
  isConsigneesLoading: false,
  isAllLoading: false,
  isLoadingChassisTerms: false,
  callers: [],
  shippers: [],
  drayosCarriers:[],
  bargeVendors: [],
  railVendors: [],
  consignees: [],
  allCustomers: [],
  allPublicUsers:[],
  chassisTerms: [],
  appSettings:[],
  trackosSynced:false,
  allEmpty: [],
  gateData:[],
  isEmptyEnabled: false,
  standard_ports:{},
  standard_ssl:[],
  standard_types:{},
  newPorts: {},
  default_driverplanner_date: {
    fdate: moment().add(-365, 'days').startOf("day").toISOString(),
    tdate: moment().endOf('day').toISOString()
  },
  default_driverplanner_sidebar_date: {
    fdate: moment().tz(getStorage("timeZone")).startOf("day").toISOString(),
    tdate: moment().tz(getStorage("timeZone")).endOf('day').toISOString()
  },
  isDriverPlannerDateChange: false,
  eLoading: false,
  empties: [],
  emErrMsg: null,
  terminals: [],
  emptiesData: [],
  isTomorrow: false,
  gatehour: [],
  downloadReportParams: {fromDate: null, toDate: null, status: null, to:null, offset:0, limit: 20},
  apptParams:{
    appointmentEndTime: "",
    appointmentStartTime: "",
    status: [],
    shipperName: [],
    loadType: null,
    moveType:null,
    containerNo: "",
    page: 1,
    isCardActive: false
  },
  isLoadingBillingCharges:false,
  billingCharges:[],
  isLoadingCustomerBillingCharges:false,
  customerBillingCharges: [],
  vendorPayFilterInitialState: {
    billFromDate: null,
    billToDate: null,
    // emailFromDate: null,
    // emailToDate: null,
    caller: [],
    billFromId: [],
    secondaryReferenceNo: null,
    // isEmailSent: false,
    chargeCodes: [],
    terminal: [],
    billedBy: [],
  },
  vendorBillFilterInitialState: {
    billedFrom: null,
    billedTo: null,
    // emailFromDate: null,
    // emailToDate: null,
    customerIds: [],
    billFromId:[],
    secRefNo: null,
    // isEmailSent: false,
    chargeCode: [],
    terminals: [],
    billedBy: [],
  }
};


export default function (state = defaultState, action) {
  switch (action.type) {
    case TMS_LOGIN_SUCCESS:
      var user = JSON.parse(JSON.stringify(action.payload));
      return {
        user
      }
    case TMS_LOGOUT:
      var user = {}
      return {
        user
      }
    case TMS_GET_USER_LOAD_FETCH_SUCCEEDED:
      var sendData = action.payload.length ? action.payload[0] : action.payload
      const newData = update(state, {
        selectedLoads: { $set: sendData },
      });
      return newData;

    case TMS_GET_CUSTOMERS_FLEETSUCCEEDED:
      return {
        ...state,
        Fleetcustomers: action.payload,
        isFleetCustomerLoading: false
      }
    case TMS_GET_FLEETCUSTOMERS_START:
      return { ...state, isFleetCustomerLoading: true }
    case TMS_GET_FLEETCUSTOMERS_FAILED:
      return { ...state, isFleetCustomerLoading: false }
    case TMS_GET_CUSTOMERS_SUCCEEDED:
      return {
        ...state,
        customers: action.payload,
        isCustomerLoading: false
      }
    case TMS_GET_CUSTOMERS_START:
      return { ...state, isCustomerLoading: true }
    case TMS_GET_CUSTOMERS_FAILED:
      return { ...state, isCustomerLoading: false }

    case TMS_GET_CUSTOMERS_ALL_SUCCEEDED:
      return {
        ...state,
        customersAll: action.payload,
        isCustomerAllLoading: false
      }
    case TMS_GET_CUSTOMERS_ALL_START:
      return { ...state, isCustomerAllLoading: true }
    case TMS_GET_CUSTOMERS_ALL_FAILED:
      return { ...state, isCustomerAllLoading: false }

    case 'TMS_UPDATE_LOAD':
      const newSelectedLoads = update(state, {
        selectedLoads: { $set: action.payload }
      });
      return newSelectedLoads;
    case TMS_GET_LOCATION_PROFILE_SUCCEEDED:
      const listOfLocationProfile = update(state, {
        locationProfiles: { $set: action.payload },
      });
      return listOfLocationProfile;
    case TMS_NEW_LOAD_SUCCEEDED:
      return update(state, {
        selectedLoads: { $set: undefined },
      });
    case 'TMS_DRIVER_START':
      return { ...state, isDriverLoading: true }
    case 'TMS_DRIVER_FAILED':
      return { ...state, isDriverLoading: false }
    case 'TMS_DRIVER':
      return {
        ...state,
        drivers: action.payload,
        isDriverLoading: false
      }
    case 'UPDATE_DRIVERS_CARD_VIDE':
      return { ...state, isDriversCardVide: action.payload }
    case TMS_NEW_LOAD_SUCCEEDED:
      return update(state, {
        selectedLoads: { $set: undefined },
      });
    case 'TMS_UPDATE_DOCUMENT':
      let updatedLoads = update(state, {
        selectedLoads: { $set: action.payload },
      });
      return updatedLoads;
    case 'TMS_UPDATE_DOCUMENT_ROTATE':
      let rotatedDocs = update(state, {
        selectedLoads: { documents: { $set: action.payload } },
      });
      return rotatedDocs;
    case 'TMS_UPDATE_NOTES':
      if (!state.selectedLoads) {
        return state
      }
      let updateNotesDoc = update(state, {
        selectedLoads: { notes: { $set: action.payload } },
      });
      return updateNotesDoc;
    case 'ALL_LOADS':
      let loadsUpdate = update(state, {
        loads: {
          $set: action.payload
        },
      });
      return loadsUpdate;
    case LOCATION_PRICE:
      const newTmsCollection = update(state, {
        location_price: { $push: [...action.payload] },
      });
      return newTmsCollection;
    case UPDATE_LOCATION_PRICE:
      let newLocationPrice = JSON.parse(JSON.stringify(state.location_price));
      newLocationPrice = newLocationPrice.map((data) => {
        if (action.payload.name == 'Pull Container' && data._id == action.payload._id) {
          data = Object.assign({}, data, {
            type: Object.assign({}, data.type, {
              part1: action.payload.amount
            })
          })
        }
        if (action.payload.name == 'Return Container' && data._id == action.payload._id) {
          data = Object.assign({}, data, {
            type: Object.assign({}, data.type, {
              part2: action.payload.amount
            })
          })
        }
        if (action.payload.name == 'Delivered' && data._id == action.payload._id) {
          data = Object.assign({}, data, { amount: action.payload.amount })
        }
      })
      const newTMsUpdate = update(state, {
        location_price: { $set: newLocationPrice },
      });
      return newTMsUpdate;
    case 'REFRESH_LOCATION_PRICE_AGAIN':
      let refreshLocationPrice = JSON.parse(JSON.stringify(state.location_price));
      const newTMsUpdate2 = update(state, {
        location_price: { $set: refreshLocationPrice },
      });
      return newTMsUpdate2;

    case 'SET_STATUS_TYPES':
      return {
        ...state,
        statusTypes: action.payload
      }

    case 'SET_CSR_USERS':
      return {
        ...state,
        csrUsers: action.payload
      }

    case GET_CALLERS_START:
      return {
        ...state,
        isCallerLoading: true
      }
    case GET_CALLERS_SUCCESS:
      return {
        ...state,
        isCallerLoading: false,
        callers: action.payload
      }
    case GET_CALLERS_FAILED:
      return {
        ...state,
        isCallerLoading: false
      }

    case GET_SHIPPERS_START:
      return {
        ...state,
        isShipperLoading: true
      }
    case GET_SHIPPERS_SUCCESS:
      return {
        ...state,
        isShipperLoading: false,
        shippers: action.payload
      }
    case GET_SHIPPERS_FAILED:
      return {
        ...state,
        isShipperLoading: false
      }

    case GET_DRAYOSCARRIERS_START:
      return {
        ...state,
        isDrayosCarrierLoading: true
      }
    case GET_DRAYOSCARRIERS_SUCCESS:
      const drayosCarriers = action.payload?.filter((d) => !d.vendorType || d.vendorType === "CARRIER");
      const railVendors = action.payload?.filter((d) => d.vendorType === "RAIL");
      const bargeVendors = action.payload?.filter((d) => d.vendorType === "BARGE");
      return {
        ...state,
        isDrayosCarrierLoading: false,
        drayosCarriers,
        railVendors,
        bargeVendors
      }
    case GET_DRAYOSCARRIERS_FAILED:
      return {
        ...state,
        isDrayosCarrierLoading: false
      }

    case GET_CONSIGNEES_START:
      return {
        ...state,
        isConsigneesLoading: true
      }
    case GET_CONSIGNEES_SUCCESS:
      return {
        ...state,
        isConsigneesLoading: false,
        consignees: action.payload
      }
    case GET_CONSIGNEES_FAILED:
      return {
        ...state,
        isConsigneesLoading: false
      }

    case GET_ALL_CUSTOMER_START:
      return {
        ...state,
        isAllLoading: true
      }
    case GET_ALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        isAllLoading: false,
        allCustomers: action.payload
      }
    case GET_ALL_CUSTOMER_FAILED:
      return {
        ...state,
        isAllLoading: false
      }

    case GET_CHASSIS_TERMS_START:
      return {
        ...state,
        isLoadingChassisTerms: true
      }
    case GET_CHASSIS_TERMS_SUCCESS:
      return {
        ...state,
        isLoadingChassisTerms: false,
        chassisTerms: action.payload
      }
    case GET_CHASSIS_TERMS_FAILED:
      return {
        ...state,
        isLoadingChassisTerms: false
      }
      case GET_ALL_USER_PUBLIC_INFO_SUCCESS:
      return {
        ...state,
        isAllLoading: false,
        allPublicUsers: action.payload
      }
      case GET_ALL_USER_PUBLIC_INFO_FAILED:
      return {
        ...state,
        isAllLoading: false
      }
    case TMS_UPDATE_STATE:
      // const customerAllTypes = ['ALL', 'chassisPick', 'caller', 'shipper', 'consignee', 'containerReturn', 'chassisTermination']
      const customerID = action.payload && action.payload._id?action.payload._id:''
      const customers = state.customers
      const customersAll = state.customersAll
      const allCustomers = state.allCustomers
      const allPublicUsers = state.allPublicUsers
      const Fleetcustomers = state.Fleetcustomers
      const callers = state.callers
      const shippers = state.shippers
      const consignees = state.consignees
      let customerIndex = -1

      const customerType = action.payload ? action.payload.customerType : []

      if (customerID) {
        if (customerType.includes('ALL')) {
          customerIndex = customers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            customers[customerIndex] = action.payload
          }

          customerIndex = customersAll.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            customersAll[customerIndex] = action.payload
          }
  
          customerIndex = allCustomers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            allCustomers[customerIndex] = action.payload
          }

          customerIndex = allPublicUsers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            allPublicUsers[customerIndex] = action.payload
          }

          customerIndex = Fleetcustomers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            Fleetcustomers[customerIndex] = action.payload
          }
        }

        if (customerType.includes('ALL') || customerType.includes('caller')) {
          customerIndex = callers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            callers[customerIndex] = action.payload
          }
        }

        if (customerType.includes('ALL') || customerType.includes('shipper')) {
          customerIndex = shippers.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            shippers[customerIndex] = action.payload
          }
        }

        if (customerType.includes('ALL') || customerType.includes('consignee')) {
          customerIndex = consignees.findIndex((d) => d._id.toString() === customerID.toString())
          if (customerIndex > -1) {
            consignees[customerIndex] = action.payload
          }
        }
      }
      return {
        ...state,
        customers,
        customersAll,
        allCustomers,
        callers,
        shippers,
        consignees,
        allPublicUsers,
        Fleetcustomers
      }

    case GET_TIMESLOTS_SUCCESS: {
      return {
        ...state,
        tLoading: false,
        timeSlots: action.payload.data.slots,
        timeSlotError: action.payload.data.error,
      };
    }
    case GET_TIMESLOTS_START: {
      return {
        ...state,
        tLoading: true,
        timeSlots: null,
        timeSlotError: null,
        bookApptData: null
      };
    }
    case GET_TIMESLOTS_FAILED: {
      return {
        ...state,
        tLoading: false,
        timeSlotError: action.payload.data.error,
      };
    }
    case "BOOK_APPOINTMENT_SUCCESS": {
      return {
        ...state,
        bLoading: false,
        bookApptData: action.payload.data,
        apptError: null,
      };
    }
    case "BOOK_APPOINTMENT_START": {
      return {
        ...state,
        bLoading: true,
        apptError: null,
      };
    }
    case "BOOK_APPOINTMENT_FAILED": {
      return {
        ...state,
        bLoading: false,
        apptError: "Booking Appointment Failed",
      };
      }
    case 'GET_APPOINTMENT_LIST_SUCCESS': {
      return {
        ...state,
        isApptloading: false,
        appointmentList: action.payload.data,
      };
    }

    case 'GET_APPOINTMENT_LIST_START': {
      return {
        ...state,
        isApptloading: true,
        appointmentList: null,

      };
    }
    case 'GET_APPOINTMENT_LIST_FAILED': {
          return {
              ...state,
              isApptloading: false,
          }
    }
    case "GET_APPOINTMENT_LIST_PARAMS": {
      return {
        ...state,
        apptParams: {...state.apptParams, ...action.payload},
      };
    }
    // appointment card
    case 'GET_APPOINTMENT_CARD_COUNT_SUCCESS': {
      return {
        ...state,
        apptCardCount: action.payload.data,
      };
    }

    case 'GET_APPOINTMENT_CARD_COUNT_START': {
      return {
        ...state,
        apptCardCount: null,

      };
    }
    case 'GET_APPOINTMENT_CARD_COUNT_FAILED': {
          return {
              ...state,
          }
    }
    case 'GET_TOTAL_APPT_SLOTS_SUCCESS': {
      return {
        ...state,
       totalApptSlots: action.payload.data
      };
    }
    case 'GET_TOTAL_APPT_SLOTS_START': {
      return {
        ...state,
       totalApptSlots: null
      };
    }

   // get standard PortName for drayostrack setting
   case 'GET_STPORTS_SUCCESS': {
    let data=action.payload ? action.payload:[]
    return {
      ...state,
      standard_ports: data.standardPorts,
      standard_ssl:data.standardSSL,
      standard_types:data.STANDARDTYPES,
      newPorts: data.newPorts,
    };
  }

    // get App settings for drayostrack setting
    case 'GET_APPSETTINGS_SUCCESS': {
      let myappFeature=action.payload ? action.payload:[]
      let isEmptyEnabled= (myappFeature?.includes('EMPTY_RETURN_LOCATIONS') && state.trackosSynced)
      return {
        ...state,
        appSettings: myappFeature,
        isEmptyEnabled:isEmptyEnabled
      };
    }

    // check if drayostrack is synced
    case 'CHECK_TRACKOSSYNC_SUCCESS': {
      let isSynced= action.payload.isSynced
      let isEmptyEnabled= (state.appSettings?.includes('EMPTY_RETURN_LOCATIONS') && isSynced)
      return {
        ...state,
        trackosSynced: isSynced,
        isEmptyEnabled:isEmptyEnabled
      };
    }

      // get App settings for drayostrack setting
      case 'GET_ALLEMPTY_SUCCESS': {
        let allEmpty=state.allEmpty
        allEmpty={...allEmpty,...action.payload};
        return {
          ...state,
          allEmpty: allEmpty,
          gateData: allEmpty?.gateData
        };
      }
  
      // get App settings for drayostrack setting
      case 'DRAYOSTRACK_LOADING': {
        let data=action.payload;
        return {
          ...state,
          drayosTrackRefLoad: data?.reference_number?data.reference_number:null,
          drayosTrackSuccess:data?.status?data.status:null
        };
      }
    case 'DRIVER_PLANNER_CURRENT_DATE': {
      return {
        ...state,
        default_driverplanner_date: action.payload
      }
    }
    case 'DRIVER_PLANNER_SIDEBAR_CURRENT_DATE': {
      return {
        ...state,
        default_driverplanner_sidebar_date: action.payload
      }
    }
    case 'DRIVER_PLANNER_FILTER_CURRENT_DATE': {
      return {
        ...state,
        driver_planner_filter_date: action.payload
      }
    }
    case "GETEMPTYDATA_START": {
      return {
        ...state,
        eLoading: false,
        emErrMsg: null,
      };
    }
    case "GET_EMPTY_DATA_SUCCESS": {
      return {
        ...state,
        eLoading: true,
        emErrMsg: null,
        emptiesData: action?.payload?.data,
        isTomorrow: action.payload?.other?.isTomorrow,
        currentPort: action.payload?.other?.currentPort,
        shiftCount: action.payload?.other?.shiftCount,
      };
    }
    case "GETEMPTYDATA_FAILED": {
      console.log("emoty rejected")
      return {
        ...state,
        eLoading: true,
        emErrMsg: action.payload.data?.data,
        emptiesData: [],
      };
    }
    case "DOWNLOAD_REPORT_PARAMS": {
      return {
        ...state,
        downloadReportParams: {...state.downloadReportParams, ...action.payload}
      }
    }

    case 'GET_ACCEPTEDPORTS_SUCCESS': {
      let data=action.payload ? action.payload:[]
      return {
        ...state,
        acceptedPorts: data,
      };
    }

    case GET_BILLING_CHARGE_LISTS_PENDING:
      return {
        ...state,
        isLoadingBillingCharges: true
      }
    case GET_BILLING_CHARGE_LISTS_SUCCESS:
      return {
        ...state,
        isLoadingBillingCharges: false,
        billingCharges: action.payload
      }
    case GET_BILLING_CHARGE_LISTS_FAILED:
      return {
        ...state,
        isLoadingBillingCharges: false
      }
    case CUSTOMER_BILLING_CHARGE_LISTS_PENDING:
      return {
        ...state,
        isLoadingCustomerBillingCharges: true
      }
    case CUSTOMER_BILLING_CHARGE_LISTS_SUCCESS:
      return {
        ...state,
        isLoadingCustomerBillingCharges: false,
        customerBillingCharges: action.payload
      }
    case CUSTOMER_BILLING_CHARGE_LISTS_FAILED:
      return {
        ...state,
        isLoadingCustomerBillingCharges: false
      }

    case 'PORTS_IN_MARKET_SUCCESS': {
      let data=action.payload ? action.payload:[]
      return {
        ...state,
        portInMarket: data,
      };
    }
    case 'SET_FILTER_DATA':
      return {
        ...state,
        vendorPayFilterInitialState: { ...state.vendorPayFilterInitialState, ...action.payload }
      };
    case 'RESET_FILTER_DATA':
      return {
        vendorPayFilterInitialState: { ...defaultState.vendorPayFilterInitialState }
      };
      case 'SET_VENDOR_BILL_FILTER_DATA':
        return {
          ...state,
          vendorBillFilterInitialState: { ...state.vendorBillFilterInitialState, ...action.payload }
        };
      case 'RESET_VENDOR_BILL_FILTER_DATA':
        return {
          vendorBillFilterInitialState: { ...defaultState.vendorBillFilterInitialState }
        };
    default:
      return state;
  }
}
