import { Marker } from "react-leaflet";
import { DateTimeFormatUtils, amplitudeTrack } from "services/Common.services";
import TrackingRangeSliderContainer from "pages/trucker/Tracking/Components/Shared/MarkerPolylines/Components/TrackingRangeSliderContainer";
import L from "leaflet";
import { useMap } from "react-leaflet";
import { useState, useRef, useEffect, useMemo } from "react";
import { inActivePathIcon, activePathIcon } from "../../Shared/Leaflet";
import { useVisibilityChange } from "hooks";
import { useTrackingDebug, useTrackingHistoryLiveDispatch } from "../../../context/TrackingContext";
import moment from "moment";
import { TRACKING_HISTORY_LIVE_ACTIONS } from "../../../store/actions";
import { TSP_HISTORY_SEGMENTATION_MAPPER } from "constants";
import { truckIconWithActiveGPS } from "pages/trucker/Tracking/utility";
import useCurrentUser from "hooks/users/useCurrentUser";
import { segmentHistory, removeCurves, smoothenHistory } from "../Utils/map.utils";
import FollowUnfollowEntityButton from "pages/trucker/Tracking/Components/Shared/MarkerPolylines/Components/FollowUnfollowEntityButton";
import PlayPauseButton from "pages/trucker/Tracking/Components/Shared/MarkerPolylines/Components/PlayPauseButton";
import DatetimeRangePickerContainer from "./Components/DatetimeRangePickerContainer";
import { debugTrackingSelectors } from "pages/trucker/Tracking/store/selectors";

const defaultDeviceTime = "Live location data"

const TrackingHistoryMarkerPolyline = ({
  history,
  isLoading,
  handleDateRangeChange = () => { },
  startDateTime,
  endDateTime,
  contextType = "",
  styles= {}
}) => {
  const dispatchHistoryLive = useTrackingHistoryLiveDispatch();

  const tspSource = useMemo(() => {
    if (history?.length > 0 && history.source) {
      return history.source
    }
  }, [history])

  const stateDebugTracking = useTrackingDebug();

  const playbackCofiguration = debugTrackingSelectors.getHistoricalPlaybackConfiguration(stateDebugTracking);

  const [_segmentedHistory, setSegmentedHistory] = useState([]);

  const segmentedHistory = useMemo(() => {
    if (_segmentedHistory?.length < 1) return [];

    return _segmentedHistory.filter((each) => each.length >= 2);
  });

  const segmentedHistoryFirstItem = useMemo(() => {
    return segmentedHistory?.[0]?.[0]
  }, [segmentedHistory])

  const segmentedHistoryLastItem = useMemo(() => {
    const lastSegment = segmentedHistory?.[segmentedHistory?.length - 1];
    const lastValue = lastSegment?.[lastSegment?.length - 1];
    return lastValue;
  }, [segmentedHistory])

  const map = useMap();

  const { currentUserTimeZone } = useCurrentUser();

  const [stopMarkerIcon, setStopMarkerIcon] = useState(inActivePathIcon);
  const [isPlaying, setIsPlaying] = useState(false);
  const [range, setRange] = useState(0);
  const [devicetime, setDeviceTime] = useState(defaultDeviceTime);
  const [locale, __] = useState({ format: DateTimeFormatUtils.fullDateFormat() });

  const isHistoryPlayed = useRef(false);
  const isSkippedWithoutPlaying = useRef(false);

  const trackRef = useRef(null);

  useEffect(() => {
    dispatchHistoryLive({ type: TRACKING_HISTORY_LIVE_ACTIONS.SET_HIDE_LIVE_ENTITY, payload: isPlaying });
  }, [isPlaying]);

  const play = () => {
    if (!trackRef?.current) return;

    if (!isHistoryPlayed.current && !isSkippedWithoutPlaying?.current) {
      const newMarker = L.marker(history[0], {
        icon: truckIconWithActiveGPS("default text-dark"),
      });
      initializeTrack(newMarker);
      isHistoryPlayed.current = true;
    }

    setIsPlaying(true);
    setStopMarkerIcon(inActivePathIcon);
    trackRef.current.updateNotPassedLine({
      notPassedLineColor: "#a3b3c6",
    });
    trackRef.current.start();
  };

  const pause = () => {
    setIsPlaying(false);
    if (trackRef.current) trackRef.current.pause();
  };

  const handleVisibilityChange = (isVisible) => {
    if (isVisible === false) {
      pause();
    }
  };

  useVisibilityChange(handleVisibilityChange);

  const initializeTrack = (newMarker) => {
    if (history?.length < 3) return;

    if (trackRef?.current) {
      trackRef.current.remove();
    }

    const options = {
      panTo: false,
      passedLineColor: "#288dff",
      notPassedLineColor: "#288dff",
      weight: 5,
      polylineDecoratorOptions: {},
    };

    if (newMarker) {
      options.marker = newMarker;
    }

    setRange(0);

    const curveRemovedHistory = removeCurves(history, { gDouglas: playbackCofiguration?.gDouglas });

    let minutesThreshold = playbackCofiguration?.segmentTimeThreshold;
    let milesTheshold = playbackCofiguration?.segmentDistanceThreshold;

    if (tspSource) {
      const foundTSPMapper = TSP_HISTORY_SEGMENTATION_MAPPER[tspSource?.toUpperCase()];
      if (foundTSPMapper?.segmentTimeThreshold && foundTSPMapper?.segmentDistanceThreshold) {
        minutesThreshold = foundTSPMapper.segmentTimeThreshold ?? 120;
        milesTheshold = foundTSPMapper.segmentDistanceThreshold ?? 2.5;
      }
    }

    const segmentedHistory = segmentHistory(curveRemovedHistory?.length > 0 ? curveRemovedHistory : history, {
      minutesThreshold: minutesThreshold,
      milesTheshold: milesTheshold,
    }).filter((eachHistory) => eachHistory.length > 1);

    const smoothenData = smoothenHistory(segmentedHistory, { factor: playbackCofiguration?.smoothFactor });

    setSegmentedHistory(smoothenData);

    trackRef.current = new L.TrackPlayer(smoothenData, options).addTo(map);

    trackRef.current.on("progress", (_, { cumulativePassedItem }) => {
      setRange(cumulativePassedItem);
    });

    trackRef.current.on("skipped", (_, segmentIndex, segmentChildIndex) => {
      if (typeof segmentIndex != "number") return;

      if (typeof segmentChildIndex === "number") {
        const currentTime = moment(segmentedHistory[segmentIndex][segmentChildIndex][2]);

        const time = moment(currentTime).tz(currentUserTimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        setDeviceTime(time);
      }
    });

    trackRef.current.on("finished", () => {
      isHistoryPlayed.current = false;
      setStopMarkerIcon(activePathIcon);
      pause();
    });

    trackRef.current.on("moving", (_, segmentIndex, segmentChildIndex) => {
      if (typeof segmentIndex != "number") return;
      if (segmentChildIndex) {
        const currentTime = moment(segmentedHistory[segmentIndex][segmentChildIndex][2]);

        const time = moment(currentTime).tz(currentUserTimeZone).format("dddd, MMMM DD, YYYY hh:mm A");
        setDeviceTime(time);
      }
    });
  };

  useEffect(() => {
    //when calender enable
    // if (startDateTime && endDateTime && history.length === 0) {
    //   setDeviceTime("No historical data found");
    // }

    if (trackRef.current === null && history?.length > 0) {
      isHistoryPlayed.current = false;
      isSkippedWithoutPlaying.current = false;
      initializeTrack();
      pause();
    }

    return () => {
      if (trackRef.current) {
        trackRef.current.remove();
        trackRef.current = null;
      }
    };
  }, [history, map]);

  useEffect(() => {
    if (trackRef?.current) {
      trackRef.current.remove();
      trackRef.current = null;
    }

    if (trackRef.current === null && history?.length > 0) {
      isHistoryPlayed.current = false;
      isSkippedWithoutPlaying.current = false;
      initializeTrack();
      pause();
    }

  }, [playbackCofiguration]);

  // useEffect(() => {
  //   if (!selectedTruck && trackRef?.current) {
  //     trackRef.current.remove();
  //     trackRef.current = null;
  //   }
  // }, [selectedTruck]);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleDateTimePicker = (start, end) => {
    handleDateRangeChange({ startDate: start, endDate: end });
    setDeviceTime(defaultDeviceTime);
  };

  const fromToDateFormatter = () => {
    // if (isLiveTrackingTarget) {
    //   return "Now";
    // }

    if (!startDateTime) {
      return "...";
    }

    return `${startDateTime && startDateTime.format(DateTimeFormatUtils.fullDateFormat())} to ${endDateTime && endDateTime.format(DateTimeFormatUtils.fullDateFormat())
      }`;
  };

  const stopZoomOnDblClick = () => {
    if (map) map.doubleClickZoom.disable();
  };

  const maxMin = useMemo(() => {
    const max = segmentedHistory.reduce((acc, innerArray) => acc + innerArray.length, 0);
    const min = segmentedHistory.length > 0 ? 1 : 0;

    return { max, min };
  }, [segmentedHistory]);

  useEffect(() => {

    // NOTE: pause on zoomstart
    const handleZoomStart = () => {
      if (trackRef.current) trackRef.current.pause();
    };

    // NOTE: play only if it was already playing
    const handleZoomEnd = () => {
      if (isPlaying) {
        play();
      }
    };

    map.on('zoomstart', handleZoomStart);
    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomstart', handleZoomStart);
      map.off('zoomend', handleZoomEnd);
    };
  }, [map, isPlaying]);

  return (
    <div>
      <div
        id="tracking-history-player"
        className="card mb-2 p-10 shadow-5 d-flex align-items-center flex-row"
        onMouseMove={stopPropagation}
        style={{
          marginLeft: "450px",
          ...styles
        }}
        onClick={stopZoomOnDblClick}
        onDoubleClick={stopZoomOnDblClick}
      >
        {contextType !== "loadInfo" && (
          <DatetimeRangePickerContainer
            isHistoricalPlaying={isPlaying}
            startDateTime={startDateTime}
            endDateTime={endDateTime}
            onApply={({ startDate, endDate }) => {
              handleDateTimePicker(startDate, endDate);
            }}
          />
        )}

        <PlayPauseButton
          disabled={history?.length < 3 || isLoading}
          onClick={() => {
            if (isPlaying) {
              pause();
            } else {
              play();
            }
            let eventProperties = {
              source: "load_modal",
            }
            amplitudeTrack('TRACKING_PLAY_PAUSE_BUTTON', eventProperties)
          }}
          isHistoricalPlaying={isPlaying}
          isLoading={isLoading}
        />

        <FollowUnfollowEntityButton disabled={isPlaying} isHistoricalPlaying={isPlaying} />

        <TrackingRangeSliderContainer
          max={maxMin?.max}
          min={maxMin.min}
          disabled={history?.length < 3 || isLoading}
          labels={history?.length === 0 ? "No Historical Data Found" : "Live Location Data"}
          isLoading={isLoading}
          devicetime={devicetime}
          value={range}
          onChange={(changedIndex) => {
            if (isHistoryPlayed?.current === false) {
              if (!isSkippedWithoutPlaying.current) {
                const newMarker = L.marker(history[0], {
                  icon: truckIconWithActiveGPS("default text-dark"),
                });
                initializeTrack(newMarker);
                isSkippedWithoutPlaying.current = true;
              }
            }

            pause();
            setRange(changedIndex);
            trackRef.current.setProgress(changedIndex);
          }}
        />
      </div>
      {segmentedHistoryFirstItem?.length > 0 && <Marker position={segmentedHistoryFirstItem} icon={activePathIcon}></Marker>}
      {segmentedHistoryLastItem?.length > 0 && <Marker position={segmentedHistoryLastItem} icon={stopMarkerIcon}></Marker>}
    </div>
  );
};

export default TrackingHistoryMarkerPolyline;
