import { DAY_PREFERENCE, MOVE_TYPE_CONSTANTS, LOAD_TYPE_CONSTANTS } from '../constants';

export const generateTimePreferences = (timePreferences, generatedTimeBucketData, direction = 'ui') => {
  if (direction === 'ui') {
    // Convert API response to UI format
    if (!generatedTimeBucketData) return {};
    
    return timePreferences?.reduce((acc, item) => {
      const matchedIndex = generatedTimeBucketData?.findIndex(
        pref => pref?.startTimeValue + '' === item?.time + ''
      );
      if (matchedIndex >= 0) {
        acc[matchedIndex] = {
          time: item?.time,
          isPriority: item?.isPriority || false,
          isFallback: item?.isFallback || false,
        };
      }
      return acc;
    }, {}) || {};
  } else {
    // Convert UI format to API payload
    return Object.values(timePreferences ?? {})
      ?.filter(item => item?.isPriority || item?.isFallback)
      ?.map(({ time, isPriority, isFallback }) => ({
        time,
        isPriority: isPriority || false,
        isFallback: isFallback || false,
      }));
  }
};

export const generateDayPreferences = (dayPreferences, dayPreferenceOptions, direction = 'ui') => {
  if (direction === 'ui') {
    // Convert API response to UI format
    if (!dayPreferenceOptions) return {};
    
    return dayPreferences?.reduce((acc, res) => {
      const matchingIndex = dayPreferenceOptions.findIndex(
        (pref) => pref?.key?.day === res?.day && pref?.key?.type === res?.type
      );
      if (matchingIndex !== -1) {
        acc[matchingIndex] = {
          type: res.type,
          day: res.day,
          isPriority: res?.isPriority || false,
          isFallback: res?.isFallback || false,
        };
      }
      return acc;
    }, {}) || {};
  } else {
    // Convert UI format to API payload
    return Object.values(dayPreferences ?? {})
      ?.filter(item => item?.isPriority || item?.isFallback)
      ?.map(({ isPriority, isFallback, day, type }) => ({
        day,
        type,
        isPriority: isPriority || false,
        isFallback: isFallback || false,
      }));
  }
};

export const templateDataMapper = (data, direction = 'ui', generatedTimeBucketData = 3) => {
  // Common fields that don't need transformation
  const commonFields = {
    templateName: data?.templateName || '',
    moveType: data?.moveType || MOVE_TYPE_CONSTANTS?.PICKUP,
    loadType: data?.loadType || LOAD_TYPE_CONSTANTS?.IMPORT,
    isDefaultBackgroundTemplate: data?.isDefaultBackgroundTemplate || false,
    bookDuringWeekend: data?.bookDuringWeekend || false,
    bookEvenIfContainerHasHolds: data?.bookEvenIfContainerHasHolds || false,
    automaticRescheduleMissed: data?.automaticRescheduleMissed || false,
    automaticRescheduleAfterFallbackBooked: data?.automaticRescheduleAfterFallbackBooked || false,
    rules: data?.rules || [],
  };

  if (direction === 'ui') {
    // API Response -> UI State
    const dayPreferences = DAY_PREFERENCE?.find((i) => i?.key === data?.relativeTo?.relativeKey)?.dayPreferences;

    return {
      ...commonFields,
      _id: data?._id || null,
      isNew: data?.isNew || false,
      bookAfterTriggerTime: data?.bookAfterTriggerTime 
        ? { 
            label: data?.bookAfterTriggerTime.includes('h') 
              ? `${data?.bookAfterTriggerTime.split('h')[0]} hr`
              : `${data?.bookAfterTriggerTime.split('m')[0]} min`, 
            value: data?.bookAfterTriggerTime 
          }
        : null,
      automaticRescheduleAfterFallbackBookedTime: data?.automaticRescheduleAfterFallbackBookedTime 
        ? { label: `${data?.automaticRescheduleAfterFallbackBookedTime.split('h')[0]} hr`, 
            value: data?.automaticRescheduleAfterFallbackBookedTime 
          }
        : null,
      relativeKey: data?.relativeTo?.relativeKey || DAY_PREFERENCE?.[0]?.key,
      timeBucketInHrs: data?.timeBucketInHrs 
        ? { label: `${data?.timeBucketInHrs} hr`, value: data?.timeBucketInHrs }
        : { label: `${generatedTimeBucketData} hr`, value: generatedTimeBucketData },
      timePreferences: generateTimePreferences(data?.timePreferences, generatedTimeBucketData, 'ui'),
      dayPreferences: generateDayPreferences(data?.dayPreferences, dayPreferences, 'ui'),
    };
  } else {
    // UI State -> API Payload
    return {
      ...commonFields,
      bookAfterTriggerTime: data?.bookAfterTriggerTime?.value,
      automaticRescheduleAfterFallbackBookedTime: data?.automaticRescheduleAfterFallbackBookedTime?.value,
      relativeTo: {
        relativeKey: data?.relativeKey
      },
      loadType: data?.loadType ?? LOAD_TYPE_CONSTANTS?.IMPORT,
      timeBucketInHrs: data?.timeBucketInHrs?.value ?? 1,
      timePreferences: generateTimePreferences(data?.timePreferences, null, 'payload'),
      dayPreferences: generateDayPreferences(data?.dayPreferences, null, 'payload'),
    };
  }
}; 