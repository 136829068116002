import React from "react";
import { useSelector, shallowEqual } from "react-redux";

import { getStorage } from "../../../../../../services/index.js";
import {
  getValidDrivers,
  isBargeVendorEnabled,
  isManageFleetOrOutSource,
} from "../../../../../../services/Common.services.js";
import { isMoveEmpty } from "../../../../LoadRoutingV2/helpers.js"

import NewDriversList from "../../../../../../Components/Common/DriversList";
import { cloneDeep } from "lodash";

const DriverList = (props) => {
  const { CellRef, onClose, Row, setShowViolatedModal, handleAssignDriver, bulkHosAvailability, moveId } = props;

  const { drivers, drayosCarriers, bargeVendors } = useSelector((state) => state.TmsReducer, shallowEqual);

  const isManageFleet = isManageFleetOrOutSource()?.isManageFleet;
  const isOutSource = isManageFleetOrOutSource()?.isOutSource;
  const isBargeVendor = isBargeVendorEnabled();

  const fetchDriverUser = (info) => {
    const AlldriverList = drivers;
    let driversList;
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? "{}");
    let driverValidation = [];
    if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
      driverValidation = userBasicSettings.driverValidation;
    }
    if (AlldriverList.length > 0) {
      let ddrivers = AlldriverList.filter((d) => !d.isDeleted && !d.isBlocked);
      const isEmpty = isMoveEmpty(info.type_of_load, info.driverOrder, moveId);
      driversList = getValidDrivers(ddrivers, driverValidation, info, !isEmpty, moveId);
    } else {
      driversList = [];
    }
    let vendorOptions = [];
    if (isManageFleet) {
      vendorOptions = [...driversList];
    }
    if (isOutSource) {
      let carriers = drayosCarriers;
      vendorOptions = [...vendorOptions, ...carriers];
    }
    if (isBargeVendor) {
      vendorOptions = [...vendorOptions, ...bargeVendors];
    }

    let FinalDrivers = vendorOptions.map((d) => {
      return {
        value: d?._id,
        label: d?.role === "driver" ? `${d?.name} ${d?.lastName || ""}` : d?.company_name,
        isDisabled: d?.disableValue,
        allInfos: d,
      };
    });
    return FinalDrivers;
  };

  return (
    <NewDriversList
      CellRef={CellRef}
      drivers={fetchDriverUser(cloneDeep(Row))}
      onChange={handleAssignDriver}
      onClose={onClose}
      Row={Row}
      customStyle={{ zIndex: 12 }}
      setShowViolatedModal={setShowViolatedModal}
      bulkHosAvailability={bulkHosAvailability}
    />
  );
};

export default DriverList;
