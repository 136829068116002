import { CustomIconFolder } from 'Components/Common/CustomIcons/Index'
import { getOptionsForMoveTo } from 'pages/tms/Email/helper'
import { useMoveEmail } from 'pages/tms/Email/hooks'
import { useContext, useState } from 'react'
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_CONTEXT, EMAIL_PROVIDER, EMAIL_TABS, OUTLOOK_PROVIDER } from "./../../../constant"
import { IconPlus } from 'Components/Common/Icons'
import { amplitudeTrack, getStorage } from 'services'
import _ from "lodash"

const MoveToDropdown = ({
	threadData,
	closeDropdown,
	handleCreateLabelModel,
	isEmailMode,
	activePage,
	threadFolder,
	closeModal
}) => {

	const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
  const { provider } = embeddedEmailAccount ?? {};

	const context = useContext(EMAIL_CONTEXT)
  const {allLabels, activeNav, allEmails, setAllEmails, _getCounts} = context

	const { _moveEmail, loading } = useMoveEmail(allEmails, setAllEmails, _getCounts, closeModal);
	const {labels:labelListForMoveTo, fixedOptions} = getOptionsForMoveTo(allLabels)

	const [labelList, setLabelList] = useState(labelListForMoveTo)

	const disableTrash = ["Trash", "Deleted Items", "Deletions"].includes(activeNav)

	const moveEmailTo = (id) => {
    moveEmailToLabel(id)
  }

	const moveEmailToLabel = (folderId) => {
    const payload = {
      ids: [threadData?.threadId],
      moveToId: folderId
    }
    _moveEmail(payload);
		closeDropdown();
  }
	return (
		<>
			<ul className="overflow-auto custom-scrollbar-md my-1" style={{ maxHeight: 200 }}>
				{(labelList?.length > 0) &&
					labelList?.map((option, index) => {
						const optionId = option?.id
						let optionName = option?.name
						const disable = optionName === activePage
						optionName = OUTLOOK_PROVIDER.includes(provider) ? option?.name : (option.systemFolder? _.startCase(_.toLower(option?.name)):option?.name)
						if(threadFolder.includes(optionId) && activeNav !==EMAIL_TABS.SPAM) return <></>
						return (
							<>
								<li
									key={index}
									className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${disable && 'pointer-not-allowed opacity-05'}`}
									onClick={(e) => {
										e.stopPropagation()
										if(!disable) {
											moveEmailTo(optionId)
										}
									}}
								>
									<CustomIconFolder />
									<span className="font-14 font-weight-normal ml-10">{optionName}</span>
								</li>
							</>
						)
					})
				}
			</ul>
			{activeNav !==EMAIL_TABS.SPAM && <><div className="hr-light my-1" />
			<ul className="overflow-auto custom-scrollbar-md my-1" style={{ maxHeight: 200 }}>
				{(fixedOptions?.length > 0) &&
					fixedOptions.map((option, index) => {
						const optionId = option?.id
						const optionName = option?.name
						const disable = (disableTrash && ["trash", "archive"].includes(option?.name)) || (EMAIL_TABS.ARCHIVE === optionName ? [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL].includes(activePage) : (EMAIL_TABS.DELETED_ITEMS === optionName && [EMAIL_TABS.DELETED_ITEMS].includes(activePage)))
						if(threadFolder.includes(optionId)) return <></>
						return (
							<li 
								className={`d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100 ${disable ? "pointer-not-allowed opacity-05" : ""}`}
								key={index}
								onClick={(e) => {
									e.stopPropagation()
									if(!disable){
										moveEmailTo(optionId)
										let eventProperties = {
											source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL_MORE : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL_MORE,
											moveTo: optionName
										}
										amplitudeTrack(AMPLITUDE_EVENTS.MOVE, eventProperties)
									}
								}}
							>
								<CustomIconFolder />
								<span className="font-14 font-weight-normal ml-10">{optionName}</span>
							</li>
						)
					}
				)}
			</ul></>}
			<div className="hr-light my-1" />
			<ul className="my-1">
				<li className="d-flex align-items-center py-2 px-15 rounded-0 pointer border-0 hover-bg-gray-100" onClick={()=>{handleCreateLabelModel(true)}}>
					<IconPlus />
					<span className="font-14 font-weight-normal ml-10">
						{`Create New ${provider === EMAIL_PROVIDER.GMAIL ? 'Labels' : OUTLOOK_PROVIDER.includes(provider) && 'Folder'}`}
					</span>
				</li>
			</ul>
		</>
	)
}

export default MoveToDropdown
