
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import { getTimeSlots, bookPickupAppointment, getApptSlots, getAppSettings } from "../actionCreators";
import { bindActionCreators } from "redux";
import PortImages from "../../../../assets/images/ports/PortImages";

import ConfirmAppointment from "./ConfirmAppointment";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { DateTimeFormatUtils, getStorage, toastr } from "../../../../services";
import moment from "moment";

class AppointmentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
            selectedTimeSlot: null,
            confirmAppt: null,
            selectedDate: null,
            totalSlots: 0,
            apptEnabled: true
        }
        this.requiredParams = {
            container_id: this.props.values.containerNo,
            terminal: this.props.values.shipperName?.includes("APM") ? "APM" : this.props.values.shipperName,
            date: this.state.date.toLocaleDateString(),
            shipper: this.props.values?.shipper[0]?._id ? this.props.values?.shipper[0]?._id : this.props?.values?.shipper[0],
        }
        this.requiredPostData = {
            ...this.requiredParams,
            shipper: {
                "_id": this.props.values?.shipper[0]?._id ? this.props.values?.shipper[0]?._id : this.props?.values?.shipper[0],
                "shipperName": this.props?.values?.shipperName,
            },
            type: "pickUpUrl",
            load: {
                "_id": this.props.values?._id,
                "reference_number": this.props.values?.reference_number
            }
        };

        this.getImportSlots = this.getImportSlots.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectTime = this.onSelectTime.bind(this);
        this.onConfirmAppointment = this.onConfirmAppointment.bind(this);
        this.bookAppointment = this.bookAppointment.bind(this)
        this.updateAppointment = this.updateAppointment.bind(this)
        this.getTotalApptSlots = this.getTotalApptSlots.bind(this)
        this.timeZone = getStorage("timeZone");
    }
    // GET- Import Slots for a container API call
    getImportSlots(selectedDate) {
        if (this.state.apptEnabled) {
            let slotParams = this.props.apptType === "pickup" ?
                {
                    ...this.requiredParams,
                    date: selectedDate,
                    type: "getSlotUrl"
                } :
                {
                    ...this.requiredParams,
                    type: "getEmptiesSlotUrl",
                    date: selectedDate,
                    line_id: this.props.values?.containerOwnerName,
                    size: this.props.values?.containerSize?.name.replace(/[']+/g, '')

                }
            this.props.actions.getTimeSlots(slotParams, this.props.apptType).then(() => {
                if (this.props.timeSlotError) {
                    toastr.show(this.props.timeSlotError, "error", "", 6000);
                }
            })
        } else {
            toastr.show("Please enable Appointment Scheduling in your App Settings.", "error", "", 5000)
        }
    };

    // show today's slots available
    componentDidMount() {
        this.props.actions.getAppSettings().then((res) => {
            if (res?.data && res?.data?.data?.message?.myappFeature?.includes("APPOINTMENT_FEATURE")) {
                this.setState({ apptEnabled: true })

                let todayDate = this.state.date;
                let formattedDate = moment(todayDate).tz(this.timeZone).format(DateTimeFormatUtils.fullDateFormat());

                this.setState({ selectedDate: moment(formattedDate).toDate() })
                this.getImportSlots(formattedDate);
                this.getTotalApptSlots(formattedDate);
            }else{
                this.setState({ apptEnabled: false })
                toastr.show("Please enable Appointment Scheduling in your App Settings.", "error", "", 5000)
            }
        }).catch(err=>{
            this.setState({ apptEnabled: false })
            toastr.show("Please enable Appointment Scheduling in your App Settings.", "error", "", 5000)
        })
    }

    // On date change get new slots
    onChange(newDate) {
        this.setState({ selectedDate: newDate })
        this.getImportSlots(newDate.toLocaleDateString())
    }

    // select Time
    onSelectTime(slotData) {
        this.setState({ selectedTimeSlot: slotData })
    };

    // Book Appointment API call
    bookAppointment(apptPayload, slot) {
        this.props.actions.bookPickupAppointment(apptPayload).then(() => {
            this.setState({ confirmAppt: slot })
            if (this.props.apptError) {
                toastr.show(this.props.apptError, "error", "", 5000);
            }
            this.props.updatePickup(this.props.bookApptData)
        });
    }

    // Confirm Booking
    onConfirmAppointment(slot) {
        if (slot) {
            let newBookingPayload = {
                ...this.requiredPostData,
                slot_id: slot?.key,
                timeSlots: slot,
            }

            // Payload separation For Pickup and Empty
            let bookApptPayload = this.props.apptType === "empty" ? {
                ...newBookingPayload,
                type: "emptiesUrl",
                line_id: this.props.values?.containerOwnerName,
                size: this.props.values?.containerSize?.name.replace(/[']+/g, '')
            } : newBookingPayload
            this.bookAppointment(bookApptPayload, slot);
        } else {
            toastr.show("Please Select TimeSlot First", "error", "", 5000)
        }
    };


    // For Updating or Modifying Appointment - only if appt_id is available
    updateAppointment(slot) {
        if (this.props.values?.pickupAppt_id && slot) {
            let updatedPostData = this.props.apptType === "pickup" ? {
                ...this.requiredPostData,
                slot_id: slot?.key,
                timeSlots: slot,
                appt_id: this.props.values?.pickupAppt_id
            } :
                {
                    ...this.requiredPostData,
                    slot_id: slot?.key,
                    timeSlots: slot,
                    appt_id: this.props.values?.emptyAppt_id
                }

            this.bookAppointment(updatedPostData, slot);
        } else {
            toastr.show("Please Select TimeSlot First", "error", "", 5000)
        }
    }

    // get Total Appointment Counts Per day
    getTotalApptSlots = (selectedDate) => {
        let slotParams = this.props.apptType === "pickup" ?
            {
                ...this.requiredParams,
                date: selectedDate,
                type: "getSlotUrl"
            } :
            {
                ...this.requiredParams,
                type: "getEmptiesSlotUrl",
                date: selectedDate,
                line_id: this.props.values?.containerOwnerName,
                size: this.props.values?.containerSize?.name.replace(/[']+/g, '')

            }

        this.props.actions.getApptSlots(slotParams);
    }

    render() {
        return (
            <React.Fragment>
                <Modal dialogClassName={this.state.confirmAppt ? "modal-sm" : "modal-md"} show={this.props.values && this.props.values.containerNo ? true : false}>
                    {this.state.confirmAppt ? (
                        <ConfirmAppointment
                            selectedDateTime={this.state.date.toDateString()}
                            selectedSlot={this.state.confirmAppt}
                            onClose={() => {
                                this.setState({ confirmAppt: null })
                                this.props.onCancel();
                            }}
                            apptData={this.props.bookApptData}
                            closeModals={() => this.props.closeAllModals()}
                        />
                    ) : (
                        <React.Fragment>
                            <Modal.Header>
                                <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
                                    <div>
                                        {this.props.apptType === "pickup" && this.props.values?.pickupAppt_id ||
                                            this.props.apptType === "empty" && this.props.values?.emptyAppt_id ? "Update " : "Book "}

                                        {this.props.apptType === "pickup" ? "a Pickup" : "an Empty"} Appointment for <span className="text-brand-500">{this.props.values?.containerNo}</span>
                                    </div>
                                    <div className="img-wrapper h-60px">
                                        <img
                                            src={PortImages[this.props.values?.shipperName?.replace(" ","")]}
                                            alt={this.props.values?.shipperName}
                                            className="h-100 w-100 img-contain"
                                        />
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-7">
                                        <Calendar
                                            onChange={this.onChange}
                                            value={this.state.selectedDate}
                                            className="custom-calendar"
                                            tileClassName={({ date }) => {
                                                return date < this.state.date ? 'calendar-tile past-days' : 'calendar-tile'
                                            }}
                                            tileContent={({ date, view }) => {
                                                let findSlotsPerDay = this.props.totalApptSlots?.find(x => moment(x.response.date).tz(this.timeZone).format(DateTimeFormatUtils.fullDateFormat()) === moment(date).tz(this.timeZone).format(DateTimeFormatUtils.fullDateFormat()));
                                                if (findSlotsPerDay) {
                                                    return <div className="calendar_appt-count">
                                                        {findSlotsPerDay?.response.total} Apt.
                                                    </div>
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-5 bg-gray-50 p-20 font-14 text-gray-900">
                                        {this.props.tLoading && <LoaderBar />}
                                        {this.props.timeSlots && this.props.timeSlots.length ? (
                                            <div className="timeslot-grid">
                                                {this.props.timeSlots.map((timeSlot) => (
                                                    <div
                                                        key={timeSlot.key}
                                                        className={this.state.selectedTimeSlot !== null && this.state.selectedTimeSlot.text === timeSlot.text ? "active" : ""}
                                                        onClick={() => this.onSelectTime(timeSlot)}
                                                    >
                                                        {timeSlot.text}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            this.props.tLoading ? <LoaderBar /> :
                                                <div className="d-flex align-items-center justify-content-center h-100">
                                                    <h5 className="text-gray-500 mb-0">No Appointments Available</h5>
                                                </div>
                                        )}
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="d-flex">
                                    <button className="btn mr-40" onClick={() => this.props.onCancel()}>
                                        Cancel
                                    </button>
                                    <button
                                        className={`btn btn-primary ${this.props.tLoading || this.props.timeSlotError || !this.state.apptEnabled ? "disabled" : ""}`}
                                        onClick={() => {
                                            !this.props.values?.pickupAppt_id || !this.props.values?.emptyAppt_id ?
                                                this.onConfirmAppointment(this.state.selectedTimeSlot) :
                                                this.updateAppointment(this.state.selectedTimeSlot)
                                        }}
                                    >
                                        {this.props.bLoading && (
                                            <span
                                                className="spinner-border spinner-border-sm mr-2"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        )}
                                        {this.props.apptType === "pickup" && this.props.values?.pickupAppt_id ||
                                            this.props.apptType === "empty" && this.props.values?.emptyAppt_id ? "Update" : "Confirm"}
                                    </button>
                                </div>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                </Modal>
            </React.Fragment>

        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getTimeSlots, bookPickupAppointment, getApptSlots, getAppSettings }, dispatch),
});

function mapStateToProps(state) {
    return {
        timeSlots: state.TmsReducer.timeSlots,
        tLoading: state.TmsReducer.tLoading,
        timeSlotError: state.TmsReducer.timeSlotError,
        bLoading: state.TmsReducer.bLoading,
        apptError: state.TmsReducer.apptError,
        bookApptData: state.TmsReducer.bookApptData,
        totalApptSlots: state.TmsReducer.totalApptSlots
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(AppointmentModal);