import { useRef, useState, useEffect } from 'react';

/**
 * Hook to detect if text in an element is truncated
 * @param {Object} dependencies - Values that should trigger a re-check
 * @returns {[React.RefObject, boolean]} - Ref to attach to element and boolean indicating if text is truncated
 */
const useTextTruncation = (dependencies = []) => {
  const elementRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (elementRef.current) {
        const element = elementRef.current;
        const isTruncated = element.scrollWidth > element.clientWidth;
        setIsTruncated(isTruncated);
      }
    };
    
    // Check on initial render and whenever dependencies change
    checkTruncation();
    
    // Re-check on window resize
    window.addEventListener('resize', checkTruncation);
    
    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [...dependencies]);

  return [elementRef, isTruncated];
};

export default useTextTruncation;