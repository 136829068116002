import Datetime from "react-datetime";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { IconCalendar } from '../../../../Components/Common/Icons';
import { getStorage } from '../../../../services';
import { getCreditMemoDetails } from '../actionCreator';
import { caseStatus } from '../constants';
import DateWrapper from "../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import CreditMemoModalV2 from "../CreditMemoModalV2";
import { LoaderBar } from "Components/Common/LoaderBar";


const ExistingCreditMemos = ({ existingCreditMemos, setNewCreditMemo, getAllCreditMemoForLoad,selectedLoad, handleReloadOfBillingSummary }) => {
    const timeZone = getStorage("timeZone");
    const [isShow, setIsShow] = useState(false);
    const [exitingCreditMemo, setExitingCreditMemo] = useState([]);

    const getCreditMemoDetail = (memoNumber) => {
        const params = {
            credit_memo_number: memoNumber,
            isGenerateSignUrl: true,
        }
        getCreditMemoDetails(params).then((creditMemo) => {
            setExitingCreditMemo(creditMemo);
            setIsShow(true);
        }).catch((err) => {
            console.log(err)
        })
    }
    const handleMemoModal = () => {
        setIsShow(false);
        getAllCreditMemoForLoad()
        const loadId= selectedLoad?._id;
        if (loadId) {
          handleReloadOfBillingSummary && handleReloadOfBillingSummary()
        }
    }

    const setCreditMemo = (memo) => {
        setExitingCreditMemo(memo);
    }
    return (
        <>
            <div className="m-0 p-0 mb-20">
                <div className="table-responsive">
                    {existingCreditMemos?.length > 0 ? <table className="table table-card mt-0 table-borderless border-spacing-2">
                        <thead>
                            <tr>
                                <th scope="col">Memo #</th>
                                <th scope="col">Status</th>
                                <th scope="col">Date</th>
                                <th scope="col" className='text-right'>Credit Amount</th>
                                <th scope="col" className='text-right'>Credit Applied</th>
                                <th scope="col" className='text-right'>Outstanding</th>
                                <th scope="col" width="20px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {existingCreditMemos?.map((item) => {
                                return (
                                    <tr>
                                        <td>{item?.credit_memo_number}</td>
                                        <td>{caseStatus(item?.status)}</td>
                                        <td>{`${moment(item?.date).tz(timeZone).format("MM/DD/YY")}`}</td>
                                        <td className='text-right' >{(item.totalAmountWithTax ?? 0).toFixed(2).toCurrency(item?.customerDetail?.currency)}</td>
                                        <td className='text-right'>{(item.totalPaidAmount ?? 0).toFixed(2).toCurrency(item?.customerDetail?.currency)}</td>
                                        <td className='text-right'>{(item.totalRemainAmount ?? 0).toFixed(2).toCurrency(item?.customerDetail?.currency)}</td>
                                        <td>
                                            <button className='btn btn-outline-light btn-sm'
                                                onClick={() => {
                                                    getCreditMemoDetail(item?.credit_memo_number)
                                                }}
                                            >Details</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table> : <div className={` py-40 text-center px-20`}><span className='text-muted text-capitalize'>{"There are no credit memos referencing this Charge Set"}</span></div>}
                    {isShow &&
                        <CreditMemoModalV2
                            setShowCreditMemoModal={handleMemoModal}
                            refInvoiceDetails={exitingCreditMemo?.referenceInvoiceDetails}
                            existingData={exitingCreditMemo}
                            isEdit={false}
                            setExitingCreditMemo={setCreditMemo}
                            setNewCreditMemo={setNewCreditMemo}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default ExistingCreditMemos;
