import { changeTableColumnLabel } from "./services/Common.services";

// Load Info labels
export const LOAD_LABELS = {
    LOAD_TYPE: 'Load Type',
    LOAD_STATUS: 'Load Status',
    BRANCH: 'Branch',
    REFERENCE_NUMBER: 'Reference #',
    CUSTOMER: "Customer",
    REFERENCE_CONTAINER: "Reference Container #",

    NEXT_ADDRESS: 'Next Address',
    PICK_UP_LOCATION: 'Pick Up Location',
    PICK_UP_APPT_FROM: 'Pick Up Apt From',
    PICK_UP_APPT_TO: 'Pick Up Apt To',
    PICK_UP_APPT_TIMES: 'Pick Up Apt',
    EMPTY_PICK_UP_LOCATION: 'Empty Pickup Location',
    PICK_UP : "Pick Up",
    PICK_UP_CITY_STATE: 'Pick Up City/State',

    DELIVERY_LOCATION: 'Delivery Location',
    DELIVERY_APPT_FROM: 'Delivery Apt From',
    DELIVERY_APPT_TO: 'Delivery Apt To',
    DELIVERY_APPT_TIMES: 'Delivery Apt',
    DELIVERY_CITY_STATE: 'Delivery City/State',
    DELIVERY: 'Delivery',
 
    RETURN_LOCATION: 'Return Location',
    RETURN_APPT_FROM: 'Return Apt From',
    RETURN_APPT_TO: 'Return Apt To',
    RETURN_APPT_TIMES: 'Return Apt',
    RETURN: 'Return',
    
    LOADING_LOCATION: 'Loading Location',
    LOADING_APT_FROM: 'Loading Apt From',
    LOADING_APT_TO: 'Loading Apt To',

    CHASSIS_LOCATION: 'Chassis Locations',
    HOOK_CHASSIS_LOCATION: 'Hook Chassis Location',
    RETURN_CHASSIS_LOCATION: 'Return Chassis Location',
    TERMINATE_CHASSIS_LOCATION: 'Terminate Chassis Location',
    
    CONTAINER_VISIBILITY: 'Container Visibility',
    FREIGHT_HOLD: 'Freight Hold',
    CUSTOM_HOLD: 'Custom Hold',
    CARRIER_HOLD: 'Carrier Hold',
    BROKER_HOLD: 'Broker Hold',

    CUT_OFF_FROM: 'Cut Off From',
    CUT_OFF_TO: 'Cut Off To',

    CONTAINER_ETA: 'Container ETA',
    VESSEL_ETA: 'Vessel ETA',

    ETA: 'ETA',

    CUT_OFF_DATE: 'Cut Off Date',
    CUT_OFF: 'Cut Off',
    DISCHARGED_DATE: 'Discharge Date',
    OUTGATE_DATE: 'Outgate Date',
    DELIVERED_TO_UNLOAD_DATE: 'Delivered To Unload Date',
    DELIVERED_TO_LOAD_DATE: 'Delivered To Load Date',
    PICK_UP_LOADED_CONTAINER_DATE: 'Pick Up Loaded Container Date',
    EMPTY_DATE: 'Empty Date',
    RETURN_DATE: 'Per Diem Free Day',
    IN_GATE_DATE: 'Ingate Date',
    READY_TO_RETURN_DATE: 'Ready To Return Date',
    COMPLETED_DATE: 'Completed Date',
    INGATE_DATE: 'Ingate Date',
    LOADED_DATE: 'Loaded Date',
    PER_DIEM_LFD: 'Per Diem Free Day',
    LAST_FREE_DAY: 'Last Free Day',
    LFD_ERD: 'LFD/ERD',

    ERD: 'ERD',

    DATE_RETURNED: 'Date Returned',

    PICKED_UP_LOADED_CONTAINER_DATE: 'Picked Up Loaded Container Date',


    CONTAINER_NUMBER: 'Container #',
    CONTAINER_TYPE: 'Container Type',
    CONTAINER_SIZE: 'Container Size',
    CONTAINER_OWNER: 'SSL',
    TYPE: 'Type',
    SIZE: 'Size',

    CHASSIS_NUMBER: 'Chassis #',
    CHASSIS_TYPE: 'Chassis Type',
    CHASSIS_SIZE: 'Chassis Size',
    CHASSIS_OWNER: 'Chassis Owner',

    GENSET_NUMBER: 'Genset #',
    TEMPERATURE: 'Temperature',
    ROUTE: 'Route',
    SCAC: 'SCAC',
    TARE_WEIGHT: 'Tare Weight',
    ISO_CODE: 'ISO Code',

    HAZMAT: 'Hazmat',
    HOT: 'Hot',
    SCALE: 'Scale',
    DOMESTIC: 'Domestic',
    EV: 'EV',
    WASTE: 'Waste',
    GDP: 'GDP',
    RAIL: 'Rail',
    STREET_TURN: 'Street Turn',
    LIQUOR: 'Liquor',
    LOCAL: 'Local',
    HIGHWAY: 'Highway',
    REEFER: 'Reefer',
    GENSET: 'Genset',
    OVERHEIGHT: 'Overheight',
    OVERWEIGHT: 'Overweight',
    TRAILER: 'Trailer',
    TRIAXLE: 'Triaxle',

    TRAILER_TYPE: 'Trailer Type',
    TRAILER_SIZE: 'Trailer Size',


    GRAY_POOL_EMPTY: 'Gray Pool Empty',
    GRAY_CONTAINER_NUMBER: 'Gray Container #',
    GRAY_CONTAINER_SIZE: 'Gray Container Size',
    GRAY_CONTAINER_TYPE: 'Gray Container Type',
    GRAY_CONTAINER_OWNER: 'Gray SSL',

    GRAY_CHASSIS_NUMBER: 'Gray Chassis #',
    GRAY_CHASSIS_SIZE: 'Gray Chassis Size',
    GRAY_CHASSIS_TYPE: 'Gray Chassis Type',
    GRAY_CHASSIS_OWNER: 'Gray Chassis Owner',


    MBOL_BKG: "MBOL/BKG",
    MASTER_BILL_OF_LADING: "Master Bill Of Lading",
    HOUSE_BILL_OF_LADING: "House Bill Of Lading",
    SEAL_NUMBER: 'Seal #',
    REFERENCE: 'Reference #',
    VESSEL_NAME: 'Vessel Name',
    VOYAGE: 'Voyage',
    ORDER_NUMBER: 'Order #',
    SHIPMENT_NUMBER: 'Shipment #',
    PICK_UP_NUMBER: 'Pick Up #',
    APPOINTMENT_NUMBER: 'Appointment #',
    RETURN_NUMBER: 'Return #',
    RESERVATION_NUMBER: 'Reservation #',
    DELEVERY_REFERENCE_NUMBER: 'Delivery Reference #',
    LIFT_OFF_REFERENCE_NUMBER: 'Lift Off Reference #',
    BOOKING_NUMBER: 'Booking #',

    FREIGHT_DESCRIPTION: 'Freight Description',
    COMMODITY: 'Commodity',
    DESCRIPTION: 'Description',
    PIECES: 'Pieces',
    WEIGHT_LBS: 'Weight LBS',
    WEIGHT_KGS: 'Weight KGS',
    TOTAL_WEIGHT: 'Total Weight',
    PALLETS: 'Pallets',
    CONTAINER_STATUS: 'Container Status',
    CUSTOMS_TYPE: 'Customs Type',

    CARRIER_DATES: 'Carrier Dates',
    MY_DATES: 'My Dates',


    DRIVER: 'Driver',
    CARRIER: 'Carrier',
    VENDOR: 'Vendor',

    REF_CONTAINER_NUMBER: "Ref Container #",
    LOAD_TEMPLATE: "Routing Template",

    RESET_ROUTING: "Reset Routing",
    CHANGE_STATUS: 'Change Status',
    PURCHASE_ORDER_NUMBER: 'Purchase Order #',

    DRIVER_ETA: 'Driver ETA',
    OFFICE_NOTE: 'Load Note',
    DRIVER_NOTE: 'Driver Note',
    YARD_NOTE: 'Yard Note',
    CUSTOMS_NOTE: 'Customs Note',
    NOTES: 'Notes',
    PORT_NOTE: 'Terminal Note',

    CREATED_BY: 'Created By',

    TABLE_COLUMNS: 'Board Columns',

    TERMINAL_CHASSIS_LOCATION: 'Terminate Chassis Location',
    CUT_OFF_HEADER: "Cut Off", // use only for header

    BOBTAIL : "BobTail",
    ORGANIZATION: 'Organization',
    SEALINK_NUMBER: 'Sealink #',
    BILL_TO: "Bill To",
    NAME_OF_CHARGE: "Name Of Charge #",
    TOTAL_CHARGES: "Total Charges",
    BILLING_TOTAL: "Billing Total",
    BILLING_NOTE: "Billing Note",
    ROUTING_TEMPLATE: "Routing Template",
    LOAD_NUMBER: "Load #",

    TEST_EDI: "Test EDI",
};

export const updateTerminologyForTableAndFilter = (columnName) => {
    if(!columnName) return "";
    switch (columnName) {
      case "APT":
      case "Delivery Appt":
        return LOAD_LABELS.DELIVERY_APPT_FROM;
      case "BOL/BKG":
      case "Master BOL/BKG":
      case "BOK/BKG":
        return LOAD_LABELS.MBOL_BKG;
      case "CUT":
      case "Cut Off":
        return LOAD_LABELS.CUT_OFF_DATE;
      case "Chassis":
        return LOAD_LABELS.CHASSIS_NUMBER;
      case "Container":
      case "containerNo":
      case "Container No":
        return LOAD_LABELS.CONTAINER_NUMBER;
      case "Location":
        return LOAD_LABELS.DELIVERY_CITY_STATE;
      case "PICKUP #":
        return LOAD_LABELS.PICK_UP_NUMBER;
      case "LFD":
        return LOAD_LABELS.LAST_FREE_DAY;
      case "Per Diem LFD":
        return LOAD_LABELS.PER_DIEM_LFD;
      case "PICKUP":
        return LOAD_LABELS.PICK_UP_APPT_FROM;
      case "RESET":
        return LOAD_LABELS.RESET_ROUTING;
      case "RTN":
        return LOAD_LABELS.RETURN_LOCATION;
      case "Move":
        return LOAD_LABELS.LOAD_TYPE;
      case "Return":
        return LOAD_LABELS.RETURN_APPT_FROM;
      case "SEAL NO":
        return LOAD_LABELS.SEAL_NUMBER;
      case "Weight":
        return LOAD_LABELS.TOTAL_WEIGHT;
      case "Size":
        return LOAD_LABELS.SIZE;
      case "Type":
        return LOAD_LABELS.TYPE;
      case "Graypool Container #":
        return LOAD_LABELS.GRAY_CONTAINER_NUMBER;
      case "Graypool Chassis #":
        return LOAD_LABELS.GRAY_CHASSIS_NUMBER;
      case "BOBTAIL":
        return LOAD_LABELS.BOBTAIL;  
      case "Port": 
        return LOAD_LABELS.PICK_UP_LOCATION;
      case "Consignee":
        return LOAD_LABELS.DELIVERY_LOCATION;
      case "Bill to":
        return LOAD_LABELS.BILL_TO;
      case "House BOL":
        return LOAD_LABELS.HOUSE_BILL_OF_LADING;
      case "APPT #":
        return LOAD_LABELS.APPOINTMENT_NUMBER;
      case "Pickup Location":
        return LOAD_LABELS.PICK_UP_CITY_STATE;
      case "Charge Codes":
        return LOAD_LABELS.NAME_OF_CHARGE;
      case "Charges":
        return LOAD_LABELS.TOTAL_CHARGES;
      case "Billable":
        return LOAD_LABELS.BILLING_TOTAL;
      case "Billing Note":
        return LOAD_LABELS.BILLING_NOTE;
      case "Load":
        return LOAD_LABELS.LOAD_NUMBER;
      case "ETA":
        return LOAD_LABELS.CONTAINER_ETA;
      case "PO #":
        return LOAD_LABELS.PURCHASE_ORDER_NUMBER;
      case "APT #":
        return LOAD_LABELS.APPOINTMENT_NUMBER;
      default:
        return changeTableColumnLabel(columnName) ?? columnName;
    }
  };