import { getStorage, toastr } from 'services';
import { useEffect, useRef, useState } from 'react';
import { ENUM, defaultBill, requiredCells } from '../constants';
import { getCustomerDocumentType } from 'pages/tms/services';
import { makePayments } from "pages/tms/VendorPayment/actionCreator";
import { uploadLogoFile } from "pages/tms/Profile/actionCreators.js";
import useOnClickOutside from "pages/tms/NewDispatcher/DispatcherTable/CustomHooks/useOnClickOutside";
import { isAccountServiceEnabled } from '../../../../../../services';

let isReserved = false;

export const useNewRow = ({ bill, handleShowNewRow, refreshCarrierPaymentSection }) => {
    const timeZone = getStorage('timeZone');

    const xRef = useRef(null);
    const wrapperRef = useRef();

    const [row, setRow] = useState(defaultBill);
    const [docTypes, setDocTypes] = useState([]);
    const [successBg, setSuccessBg] = useState(null);
    const [errorCells, setErrorCells] = useState([]);
    const [isApiCall, setIsApiCall] = useState(false);
    const [focusedCell, setFocusCell] = useState(null);
    const [isShowDatepicker, setShowDatepicker] = useState(false);

    useEffect(() => {
        getCustomerDocumentType().then((res) => {
            setDocTypes(res ?? []);
        });
    }, []);

    useOnClickOutside(wrapperRef, () => {
        if(isReserved) return;
        
        isReserved = true;
        
        const requiredCellList = requiredCells
        if(isAccountServiceEnabled()){
            requiredCellList.push("DepositToAccountRef")
        }

        setTimeout(() => {
            const cellKeys = [];

            Object.keys(row ?? {}).forEach(key => {
                const value = row[key];

                if (!value && requiredCellList.includes(key)) cellKeys.push(key);
            });

            setFocusCell(null);
            setErrorCells(cellKeys);

            isReserved = false;
            if (!cellKeys?.length && !isShowDatepicker && !focusedCell && !isApiCall) handleSubmit();
        }, 200);
    });

    const handleSelectCell = (cell) => setFocusCell(cell);

    const getDragAndDropFile = (isFileUpload, getRootProps) => {
        if (isFileUpload) return { ...getRootProps() };
        else return {};
    };

    const handleRowChange = (key, value) => {
        if(key === ENUM.AMOUNT && value > bill?.totalRemainAmount) return;
        setRow({ ...row, [key]: value });
    }
    
    const handleDrop = (files) => {
        const file = files?.[0];
        setRow({ ...row, document: file, docName: file.name });
    }

    const handleShowDatepicker = ({ isOutsideClick }) => {
        setShowDatepicker(!isShowDatepicker);
        if (!isOutsideClick) setFocusCell("add-date");
    };

    const handleDeleteNewRow = () => {
        setRow({...defaultBill});
        handleShowNewRow();
    }

    const handleSubmit = async () => {
        try {
            if(isApiCall) return;

            setIsApiCall(true);

            const payload = { ...row, bills: [{ amount: row?.amount, _id: bill?._id }] };

            if (payload?.document) {
                const formData = new FormData();
                const { document, docName } = payload ?? {};

                formData.append("filename", docName);
                formData.append("document", document);

                const result = await uploadLogoFile(formData);

                payload.documentUrl = result?.data?.url;
            }

            delete payload.image;
            delete payload.amount;
            delete payload.docName;
            delete payload.document;
            delete payload.imagePreviewUrl;

            Object.keys(payload).forEach(key =>  !payload[key] && delete payload[key]);

            await makePayments(payload);

            handleDeleteNewRow();

            setSuccessBg('background-success');
            toastr.show('Apply Vendor Payment Successfull.', 'success');

            setTimeout(() => {
                setSuccessBg(null);
                setIsApiCall(false);
                refreshCarrierPaymentSection();
            }, 600);
        } catch (error) {
            console.error(error);
            setIsApiCall(false);
            setSuccessBg('background-failure');
            refreshCarrierPaymentSection();
            toastr.show('Failed to apply Vendor Payment !', 'error')
        }
    }

    const isSpaceAbove = (() => {
        if (!xRef?.current) return ""
        const element = xRef?.current;

        const elementRect = element.getBoundingClientRect();

        const spaceAbove = elementRect.top;
        const spaceBelow = window.innerHeight - elementRect.bottom;

        return (spaceAbove > spaceBelow) ? "bottom-20" : ""
    })();

    return {
        row,
        xRef,
        docTypes,
        timeZone,
        successBg,
        isApiCall,
        errorCells,
        wrapperRef,
        focusedCell,
        isSpaceAbove,
        isShowDatepicker,
        getDragAndDropFile,
        handleDrop,
        handleSubmit,
        handleRowChange,
        handleSelectCell,
        handleDeleteNewRow,
        handleShowDatepicker,
    }
}
