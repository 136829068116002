import React, { useState } from "react";
import { APPOINTMENT_STATUSES, APPOINTMENT_MESSAGES } from "../../../../../../constants";
import DateTimePicker from "./DateTimePicker";
import { useAwaitablePrompt, useCurrentUserSettings } from "../../../../../../hooks";

const AppointmentDateWrapper = ({
  defaultDate,
  isShowTime,
  onChange,
  OnBlurUpdate,
  onRemoveDate,
  displayTimeZone,
  setShow,
  openAppointmentModal,
  isPortAccepted,
  appointmentId,
  autoAppointmentId,
  apptStatus,
  date,
  loadId,
  loadStatus,
	moveType,
  openManualBooking,
  load,
  standardPorts,
  isAppointmentProcessing,
  isAllowedForDirectBooking,
}) => {
  const { openAwaitablePrompt, isAwaitablePrompt, AwaitablePromptAlert } = useAwaitablePrompt();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};

  const [isCancelAppt, setIsCancelAppt] = useState(false);

  const handleRemoveDate = async () => {
    setIsCancelAppt(true);

    if (isAppointmentFeatureEnabled && appointmentId && apptStatus !== APPOINTMENT_STATUSES.CANCELLED) {
      const confirmed = await openAwaitablePrompt();
      if (!confirmed) {
        setIsCancelAppt(false);
        setShow && setShow(false);
        return;
      }
    }
    setIsCancelAppt(false);
    onRemoveDate && onRemoveDate();
  };

  const handleAppointment = async () => {
    if (isCancelAppt) return;
    if (!date) {
      setShow(false);
      return;
    }
    if (isAppointmentFeatureEnabled && appointmentId && apptStatus !== APPOINTMENT_STATUSES.CANCELLED && !isAllowedForDirectBooking) {
      const confirmed = await openAwaitablePrompt();
      if (!confirmed) {
        setShow && setShow(false);
        return;
      }
    }
    OnBlurUpdate && OnBlurUpdate();
  };

  return (
    <>
      <DateTimePicker
        onRemoveDate={handleRemoveDate}
        OnBlurUpdate={handleAppointment}
        onChange={onChange}
        isShowTime={isShowTime}
        defaultDate={defaultDate}
        displayTimeZone={displayTimeZone}
        openAppointmentModal={openAppointmentModal}
        isPortAccepted={isPortAccepted}
        loadId={loadId}
        loadStatus={loadStatus}
        moveType={moveType}
        openManualBooking={openManualBooking}
        load={load}
        standardPorts={standardPorts}
        isAppointmentProcessing={isAppointmentProcessing}
        setShow={setShow}
        appointmentId={appointmentId}
        autoAppointmentId={autoAppointmentId}
        isAllowedForDirectBooking={isAllowedForDirectBooking}
      />

      <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENT} />
    </>
  );
};

export default AppointmentDateWrapper;
