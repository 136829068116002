import React, { useCallback, useEffect, useState } from "react"
import { IconDownload, IconSendEmail } from "Components/Common/Icons"

import { Modal } from "react-bootstrap"
import ChargeSetModalTable from "./ChargeSetModalTable"
import ChargeSetModalSidebar from "./ChargeSetModalSidebar"
import { useBillHook } from "../hooks/useBillHook"
import { getBillStatus, getPaymentStatus } from "../Components/vendorBillStatus"
import moment from "moment"
import { checkIsVendorBillRebillEnabled, DateTimeFormatUtils } from "services"
import { getChargeSetSummaryById } from "../service/VendorBillsService"
import CellSpinner from "../../../../Components/Common/Spinner/CellSpinner"
import { getTimeZone } from "pages/tms/NewDispatcher/constants"
import { checkIsTaxable, getStorage, userTimeZone } from "../../../../services"
import DateWrapper from "../../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper"
import { IconCalendar } from "../../../../Components/Common/Icons"

function ChargeSetModal(props) {
  const { billId, closeModal, triggerRefresh, getCarrierBillChargeLists } = props
  
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [chargeSetSummary, setChargeSetSummary] = useState();
  const [chargeSetId, setChargeSetId] = useState();

  const { 
    billData, 
    getBillData, 
    resetData, 
    accept, 
    decline, 
    declineReason, 
    declineLoading, 
    setDeclineReason, 
    acceptLoading, 
    disableAccept, 
    disableDecline,
    disableRebill,
    rebill,
    disableRebillToBill,
    rebillLoading,
    rebillToBillLoading,
    rebillToBill
   } = useBillHook(triggerRefresh);
  useEffect(() => {
    getBillData(billId);
  }, [billId]);

  const [invoiceModal, setInvoiceModal] = useState({});
  const [reloadSingleRow, setReloadSingleRow] = useState(false)
  

  // for charge set summary
  useEffect(()=>{
    chargeSetId && fetchChargeSet(chargeSetId);
  },[chargeSetId])

  const handleShowDeclineModal = () => {
    setShowDeclineModal(true)
  }
  const handleHideDeclineModal = () => {
    setShowDeclineModal(false)
  }
  const handleConfirmDecline = useCallback(() => {
    decline(billId, declineReason);
    setReloadSingleRow(true)
    setShowDeclineModal(false);
    setDeclineReason();
  }, [billId, declineReason]);
  
  const handleChargeSetId = (id)=>{
    if(chargeSetId===id){
      setChargeSetId(null);
      setChargeSetSummary(false)
    }else{
      setChargeSetId(id)
    }
}
  const fetchChargeSet = ()=>{
    getChargeSetSummaryById(chargeSetId).then((response)=>{
      setChargeSetSummary(response);
    }).catch((error)=>{
        console.log(error);
    })
}

  const handleRebillToBill = () => {
    setInvoiceModal({
      inVoiceSelectedDate: moment().toISOString() || "",
      billNo: billData?.billNumber || "",
      billDueDate: moment().toISOString()|| "",
      isOpen: true
    });
  }



const timeZone = getStorage("timeZone")

  return (
    <>
      <Modal show={billData} onHide={() => {
        closeModal();
        resetData();
      }} className="full--modal p-20 vh-100 " dialogClassName="modal-xl">
        <div className="d-flex w-100 vh-100 rounded-10 overflow-hidden">
          <div className="w-100 d-flex flex-column border-right-2 border-gray-200 overflow-x-auto overflow-y-inherit bg-white">
            <div className=" px-15 py-20">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h5 className="mb-0 font-20 d-flex align-items-center gap-5">
                    Bill# { billData?.billNumber }
                    {billData?.paymentStatus ? getPaymentStatus(billData?.paymentStatus) : getBillStatus( billData?.status ) }
                  </h5>
                  <div className="text-muted small">{billData?.chargeCount} Charge Sets Billed by: { billData?.billCreatedBy?.name }</div>
                </div>
                {/* <div className="d-flex gap-5">
                  <button className="btn btn-outline-light">
                    <IconDownload />
                  </button>
                  <button className="btn btn-outline-light">
                    <IconSendEmail />
                  </button>
                </div> */}
              </div>
              {/* //Toaster Error message */}
              {(billData?.status === 'DECLINED' && billData?.reason) && (<div className="toast-container-relative mt-10">
                <div className="toast toast-error mw-100 w-100 mb-10">
                  <div className="toast-message">
                    { billData?.reason }
                  </div>
                </div>
              </div>)}
              <div className="form-row mt-10">
                <div className="col-lg-5 d-flex gap-5 mb-1">
                  <div className="rbox">
                    <div className="text-muted mb-1">Pay To</div>
                    <div className="font-medium">{ billData?.billSummary?.billFromId?.company_name }</div>
                    <div>{ billData?.billSummary?.billFromId?.defaultAddress }</div>
                    <div>{ billData?.billSummary?.billFromId?.tenderEmail }</div>
                  </div>
                </div>
                <div className="col-lg-7 d-flex flex-column gap-5 justify-content-end">
                  <div className="d-flex gap-5">
                    <div className="rbox text-right flex-one">
                      <div className="text-muted">Bill Date</div>
                      <div className="font-medium">{moment(billData?.billDate).tz(getTimeZone()).format(DateTimeFormatUtils.fullDateFormat())}</div>
                    </div>
                    <div className="rbox text-right flex-one">
                      <div className="text-muted">Bill Due Date</div>
                      <div className="font-medium">{billData?.billDueDate ? moment(billData?.billDueDate).tz(getTimeZone()).format(DateTimeFormatUtils.fullDateFormat()) : '-'}</div>
                    </div>
                    {/* <div className="rbox text-right flex-one">
                      <div className="text-muted">Terms</div>
                      <div className="font-medium">Net 0</div>
                    </div> */}
                    {/* <div className="rbox text-right flex-one">
                      <div className="text-muted">Due Date</div>
                      <div className="font-medium">08-Jan-24</div>
                    </div> */}
                  </div>
                  <div className="d-flex gap-5">
                    <div className="rbox text-right flex-one">
                      <span className="text-primary pointer">Total Charges</span>
                      <div className="font-medium">{ (billData?.totalAmount ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>
                    {checkIsTaxable() && (<div className="rbox text-right flex-one">
                      <span className="text-primary pointer">Tax</span>
                      <div className="font-medium">{(billData?.totalTaxAmount ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>)}
                    <div className="rbox rbox--primary-100 text-right flex-one">
                      <div>Bill Total</div>
                      <div className="font-medium">{ (billData?.totalAmountWithTax ?? 0).toCurrency(billData?.billSummary?.currency) }</div>
                    </div>
                  </div>
                </div>
              </div>
             { checkIsVendorBillRebillEnabled() && <div className="d-flex align-items-center justify-content-end pt-10 gap-20 flex-wrap">
                {/* <div className="d-flex">
                  <span className="text-primary mr-1 pointer">Credit Applied</span>
                  <span className="font-medium">$0.00</span>
                </div> */}
                <div className="d-flex">
                  <span className="text-primary mr-1 pointer">Payment Applied</span>
                  <span className="font-medium">{(billData?.billSummary?.totalPaymentAmount ?? 0).toCurrency(billData?.billSummary?.currency)}</span>
                </div>
                <div className="d-flex">
                  <span className="text-muted mr-1">Balance Due</span>
                  <span className="font-medium">{(billData?.billSummary?.totalRemainAmount ?? 0).toCurrency(billData?.billSummary?.currency)}</span>
                </div>
              </div>}
              <div className="px-10 py-1 bg-gray-50 rounded-5 text-muted mt-10">
                Internal Notes for Bill. Internal Notes for Bill.
              </div>
              <div className="p-1 bg-gray-50 rounded-5 mt-10">
                <ChargeSetModalTable charges={billData?.chargeTableData} billFromCurrency={billData?.billSummary?.currency} chargeDetails={billData?.chargeIds} handleChargeSetId={handleChargeSetId}/>
              </div>
            </div>
          </div>
          {chargeSetSummary && <ChargeSetModalSidebar chargeSetSummary={chargeSetSummary}/>}
        </div>
        <Modal.Footer className=" bg-white">
          {checkIsVendorBillRebillEnabled() && <div className="d-flex">
            <div className="d-flex gap-5 align-items-cente">
              <button disabled={disableRebillToBill} className="btn btn-primary" onClick={() => handleRebillToBill()}>
                {rebillToBillLoading && <CellSpinner wrapperClass="mr-1" />} Bill
              </button>
              <button  disabled={disableRebill} className="btn btn-danger" onClick={() => {
                rebill(billId)
                setReloadSingleRow(true)
              }}>
                {rebillLoading && <CellSpinner wrapperClass="mr-1" />} Rebill
              </button>
            </div>
          </div>}
          <button className="btn btn-outline-light ml-auto" onClick={() => {
            resetData();
            closeModal();
            if (reloadSingleRow && getCarrierBillChargeLists) getCarrierBillChargeLists()
          }}>
            Close
          </button>
          <button disabled={disableDecline} className="btn btn-danger" onClick={handleShowDeclineModal}>
          { declineLoading && <CellSpinner wrapperClass="mr-1"/> } Decline
          </button>
          <button disabled={disableAccept} className="btn btn-primary" onClick={() => {
            accept(billId);
            setReloadSingleRow(true)
          }}>
            { acceptLoading && <CellSpinner wrapperClass="mr-1"/> }  Accept
          </button>
        </Modal.Footer>
      </Modal>
      {/* For Decline Modal */}
      {showDeclineModal && (
        <Modal
          show={showDeclineModal}
          onHide={handleHideDeclineModal}
          dialogClassName="modal-sm"
          backdropClassName="z-1050"
        >
          <Modal.Header>
            <Modal.Title>Decline</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea rows={5} className="form-control" placeholder="Describe the reason.." onChange={(e) => {
              setDeclineReason(e?.target?.value);
            }}></textarea>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-close" onClick={handleHideDeclineModal}>
              Cancel
            </button>
            <button disabled={!declineReason?.length} className="btn btn-danger" onClick={handleConfirmDecline}>
              Decline
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {/* For Rebill to Bill Modal  */}
      <Modal dialogClassName="modal-dialog-centered modal-sm w-350" backdropClassName="z-1050" show={invoiceModal?.isOpen} centered animation={false}>
        <Modal.Header className="pb-10">
          <Modal.Title>Bill Details<div className="text-muted font-14 font-weight-normal">{""}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-wrapper">
            <div className="input-wrapper mb-10">
              <label>
                Bill Date
              </label>
              <div className="position-relative">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone()}
                  onChange={(e) =>
                    setInvoiceModal((prevState) => ({
                      ...prevState,
                      inVoiceSelectedDate: moment(e).toISOString(),
                    }))
                  }
                  defaultDate={invoiceModal?.inVoiceSelectedDate}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="input-wrapper mb-10">
              <label>
                Bill Due Date
              </label>
              <div className="position-relative">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone()}
                  onChange={(e) =>
                    setInvoiceModal((prevState) => ({
                      ...prevState,
                      billDueDate: moment(e).toISOString(),
                    }))
                  }
                  defaultDate={invoiceModal?.billDueDate}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                Bill #
              </label>
              <input
                value={invoiceModal?.billNo}
                className="form-control input-search-left"
                name=""
                id="acReceivableSearch"
                placeholder="Bill #"
                disabled={true}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
              setInvoiceModal({ isOpen: false });
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              rebillToBill({
                billId,
                billDate: invoiceModal?.inVoiceSelectedDate,
                billDueDate: invoiceModal?.billDueDate,
              });
              setReloadSingleRow(true)
              setInvoiceModal((prev) => ({
                ...prev,
                isOpen:false
              }))
            }}
            disabled={!invoiceModal?.billNo || !invoiceModal?.inVoiceSelectedDate || !invoiceModal?.billDueDate || rebillToBillLoading}
          >
            Confirm
          </button>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ChargeSetModal
