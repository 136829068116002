

export const YARD_TABLE_NAME = {
    DUAL_TRANSACTION_LINKED_TABLE: "LINKED_DUAL_TRANSACTION",
    YARD_CONTAINER_TABLE: "YARD_CONTAINER",
    CUSTOMER_DASHBOARD_TABLE: "CUSTOMER_DASHBOARD",
    PICKUP_TABLE_DOUBLE_DISPATCH_TABLE: "PICKUP_TABLE_DOUBLE_DISPATCH",
    RETURNS_TABLE_DOUBLE_DISPATCH_TABLE: "RETURNS_TABLE_DOUBLE_DISPATCH",
    PROBLEM_CONTAINER_TABLE: "PROBLEM_CONTAINER",
    PICKUP_TABLE_DUAL_TRANSACTION_TABLE: "PICKUP_TABLE_DUAL_TRANSACTION",
    RETURN_TABLE_DUAL_TRANSACTION_TABLE: "RETURN_TABLE_DUAL_TRANSACTION",
    RAIL_ORDER_TABLE: "RAIL_ORDER_TABLE",
    EXPORT_TABLE_STREET_TURN_TABLE: "EXPORT_TABLE_STREET_TURN",
    IMPORT_TABLE_STREET_TURN_TABLE: "IMPORT_TABLE_STREET_TURN",
    LINKED_STREET_TURN_TABLE: "LINKED_STREET_TURN",
    SCHEDULING_LOAD_TABLE: "SCHEDULING_LOAD_TABLE"
}