import React, { useEffect, useMemo, useState } from "react";
import TimeRangePicker from "./Components/TimeRangePicker";
import DateTimeSlotsPicker from "./Components/DateTimeSlotsPicker";
import useAppointmentSystem from "./hooks/useAppointmentSystem";
import { ACTIONS } from "./store/action";
import { convertTimeToMilitary } from "./helpers/timeConverter";
import { APPOINTMENT_TIME_CATEGORY } from "./constants";

const FORM_MODE = Object.freeze({
  CREATE: "CREATE",
  EDIT: "EDIT",
});

// AppointmentSlotsPicker component should be isolated. We can reuse in other projects.
const AppointmentSlotsPicker = ({
  isLoading,
  timeZone,
  handleSlotSelection = () => { },
  defaultTimeSlots,
  defaultDateTimeSlots,
  slotsCount,
  lastDate,
  isScheduleAppointment
}) => {
  const { dispatch, selectedTimeSlots } = useAppointmentSystem({
    timeZone,
    handleSlotSelection,
  });

  const onTimeRangeSelected = (startTime, endTime) => {
    const startTimeMil = convertTimeToMilitary(startTime);
    const endTimeMil = convertTimeToMilitary(endTime);
    dispatch({
      type: ACTIONS.SET_TIME_SLOT,
      payload: [
        {
          startTime: startTimeMil,
          endTime: endTimeMil,
        },
      ],
    });
  };

  //For Selecting From Two Aoopintment Options through radio
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  // Set the default selected option based on the available data
  useState(() => {
    if (defaultDateTimeSlots?.length > 0) setSelectedOption(APPOINTMENT_TIME_CATEGORY.DATETIME_RANGE_SLOT);
    if (defaultTimeSlots?.length > 0) setSelectedOption(APPOINTMENT_TIME_CATEGORY.TIME_RANGE_SLOT);
  }, []);

  // CREATE, EDIT
  const formMode = useMemo(() => {
    if (defaultTimeSlots || defaultDateTimeSlots) return FORM_MODE.EDIT;
    return FORM_MODE.CREATE;
  }, [defaultTimeSlots, defaultDateTimeSlots]);

  useEffect(() => {
    if (formMode == FORM_MODE.CREATE) return;
  }, [defaultTimeSlots, defaultDateTimeSlots]);


  useEffect(() => {
    if (defaultTimeSlots && defaultTimeSlots.length === 0) return;
    defaultTimeSlots.map((timeSlot) => {
      const startTimeMil = convertTimeToMilitary(timeSlot?.startTime);
      const endTimeMil = convertTimeToMilitary(timeSlot?.endTime);
      dispatch({
        type: ACTIONS.SET_TIME_SLOT,
        payload: [
          {
            startTime: startTimeMil,
            endTime: endTimeMil,
          },
        ],
      });
    });
  }, [defaultTimeSlots]);

  return (
    <React.Fragment>
      {isScheduleAppointment && <>
        <div className={`form-check form-check-inline ${selectedOption === APPOINTMENT_TIME_CATEGORY.RECOMMENDED_SLOT ? "" : "app-slot-disabled"}`}>
          <label className="form-check-label ml-0">
            <input
              type="radio"
              className="form-check-input"
              name="appointment-option"
              checked={selectedOption === APPOINTMENT_TIME_CATEGORY.RECOMMENDED_SLOT}
              onChange={() => handleOptionChange(APPOINTMENT_TIME_CATEGORY.RECOMMENDED_SLOT)}
            />
            I want to use the recommended appointment times.
          </label>
        </div>
        <div className="d-flex py-20 align-items-center">
          <div className="font-weight-bold">OR</div>
          <hr className="ml-20 w-100" />
        </div>
      </>}

      <TimeRangePicker
        timeZone={timeZone}
        defaultTimeSlot={defaultTimeSlots?.[0]}
        handleSelectedTimeSlot={onTimeRangeSelected}
        selectedTimeSlot={selectedTimeSlots}
        selectedOption={selectedOption}
        onOptionChange={handleOptionChange}
      />

      <div className="d-flex py-20 align-items-center">
        <div className="font-weight-bold">OR</div>
        <hr className="ml-20 w-100" />
      </div>

      <DateTimeSlotsPicker
        lastDate={lastDate}
        isLoading={isLoading}
        defaultSlotsCount={slotsCount}
        timeZone={timeZone}
        defaultDateTimeSlots={defaultDateTimeSlots}
        handleSelectedDateTime={(selectedSlots) => {
          dispatch({ type: ACTIONS.SET_DATETIME_SLOT, payload: selectedSlots });
        }}
        selectedOption={selectedOption}
        onOptionChange={handleOptionChange}
      />
    </React.Fragment>
  );
};

export default AppointmentSlotsPicker;
