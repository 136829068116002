import React, { memo, useContext, useState, useCallback } from 'react';
import { useHover } from 'hooks';
import { Icondots } from '../../../../../../Components/Common/Icons';
import { getStorage, setStorage, capitalizeFirstLetterOfEachWord } from '../../../../../../services';
import { getEmailByAccountId, getIconV2, getLabelV2 } from '../../../helper';
import { convertFolderNameForOutlook, EMAIL_CONTEXT, EMAIL_PROVIDER, NEW_EMAIL_NAV_CONTEXT } from '../../../constant';
import FolderContextMenuWrapper from "../../FolderContextMenuWrapper";
import { setActiveFolderForBE } from 'pages/tms/Email/actionCreator';
import useTextTruncation from '../../../hooks/useTextTruncation';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

const folderIdMap = {
    "ALL EMAILS": "all",
    "DRAFT": "Drafts",
    "CATEGORY_SOCIAL": "social",
    "CATEGORY_UPDATES": "updates",
    "CATEGORY_FORUMS": "forums",
    "CATEGORY_PROMOTIONS": "promotions"
};

const convertFolderNameForGmail = (name, systemFolder) => {
    let gmailName;
    if (systemFolder) {
        if (name.includes("CATEGORY")) {
            gmailName = folderIdMap[name]?.slice(0, 1)?.toUpperCase() + folderIdMap[name]?.slice(1);
        } else {
            gmailName = capitalizeFirstLetterOfEachWord(name, "SPACE");
        }
    } else {
        gmailName = name;
    }
    return gmailName;
}

const FavoriteItem = memo(({ folder, provider }) => {
    const [labelRef, labelHovering] = useHover();
    const [textRef, isTextTruncated] = useTextTruncation([folder?.name, folder?.accountName]);
    const context = useContext(EMAIL_CONTEXT);
    const { setEmailChanged, setActiveNav, activeNav, _moveEmail, labels, handleHideBatchMenu } = useContext(NEW_EMAIL_NAV_CONTEXT)
    const { resetState, activeFolder, setActiveFolder, setIsMoveEmailLoader } = context;
    const [isMoveTarget, setIsMoveTarget] = useState("");

    const debouncedUpdateActiveFolder = useCallback(
        _.debounce((payload) => {
            setActiveFolderForBE(payload);
        }, 500),
        []
    );

    const handleClick = () => {
        const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
        let currentAccountId = embeddedEmailAccount?.accountId;
        let { provider } = embeddedEmailAccount;
        let dataToSet;
        resetState()
        if (currentAccountId !== folder?.grantId) {
            const foundMail = getEmailByAccountId(folder?.grantId)
            if (foundMail) {
                currentAccountId = foundMail.accountId;
                provider = foundMail?.provider;
                setStorage("embeddedEmailAccount", JSON.stringify(foundMail))
                setEmailChanged(prev => prev + 1);
            }
        }
        if (provider === EMAIL_PROVIDER.GMAIL) {
            if (folder?.systemFolder) {
                if (folderIdMap[folder?.id]) {
                    dataToSet = folderIdMap[folder?.id];
                } else if (folder?.name === "ALL EMAILS") {
                    dataToSet = "All Emails";
                } else {
                    dataToSet = folder.name.charAt(0).toUpperCase() + folder.name.slice(1).toLowerCase();
                }
            } else {
                dataToSet = folder?.id;
            }
        } else {
            if (folder.name === "Inbox") {
                dataToSet = folder.name;
            } else {
                dataToSet = folder.id;
            }
        }
        setActiveFolder((prev) => {
            return { ...prev, grantId: currentAccountId, id: folder?.id }
        })
        setActiveNav(dataToSet)
        const mappedFolder = {
            id: dataToSet,
            grantId: folder?.grantId,
        }
        debouncedUpdateActiveFolder({ activeFolder: mappedFolder })
    }

    const isActive = React.useMemo(() => {
        if (activeFolder?.grantId !== folder?.grantId) return false;
        const isDraftMatch = folderIdMap[folder?.id] === activeFolder?.id;
        const isIdMatch = activeFolder?.id === folder?.id;
        const isNameMatch = activeFolder?.id?.toUpperCase() === folder?.name?.toUpperCase();

        return isDraftMatch || isIdMatch || isNameMatch;
    }, [activeFolder, folder]);

    const handleDrop = async (e) => {
        if (e.dataTransfer.types.includes('dragtype')) {
            console.log("reorder favs")
            return;
        };
        e.preventDefault();
        e.stopPropagation();
        const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL);
        setIsMoveEmailLoader(true);
        removeClass();
        setIsMoveTarget("");

        const cleanup = () => {
            setIsMoveEmailLoader(false);
            removeElement();
            handleHideBatchMenu();
            e.stopPropagation();
        };

        const data = e.dataTransfer.getData('application/json');
        const grantId = e.dataTransfer.getData('grantId');
        if (folder?.grantId !== grantId) {
            cleanup();
            return;
        }
        const item = JSON.parse(data);
        const id = isOutlookProvider && folder?.name === "Inbox" ? "Inbox" : folder?.id;
        if (!isDragAllowed(id)) {
            cleanup();
            console.log("not-allowed");
            return;
        }
        const folderId = folder?.id;
        let labelId = getLabelV2(folderId, labels, isOutlookProvider);
        labelId = labelId.toLowerCase() === "inbox" ? "INBOX" : labelId;
        await _moveEmail({ ids: item }, false, labelId);
        cleanup();
    };

    const handleDragOver = (e) => {
        if (e.dataTransfer.types.includes('dragtype')) return;
        e.preventDefault();
        e.stopPropagation();
        if (Array.isArray(labels) && labels.length > 0) {
            const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL);
            const grantId = labels[0]?.grantId;
            if (folder?.grantId !== grantId) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            if ((activeNav === "Spam" && ["Trash", "Inbox"].includes(folder?.id)) && !isOutlookProvider) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            const moveTarget = getLabelV2(folder?.id, labels, isOutlookProvider, true);
            if (moveTarget === undefined) {
                e.dataTransfer.dropEffect = "none";
                return false;
            }
            setIsMoveTarget(getLabelV2(folder?.id, labels, isOutlookProvider, true) ?? "");
        }
    }

    const handleDragLeave = (e) => {
        if (e.dataTransfer.types.includes('dragtype')) return;
        e.preventDefault();
        e.stopPropagation();
        setIsMoveTarget("")

    };

    const removeElement = () => {
        const element = document.querySelector("#draggable-email-element");
        const escInfo = document.querySelector("#esc-info-bar");
        if (element) element?.remove();
        if (escInfo) escInfo?.remove();
    }

    const removeClass = () => {
        document.querySelectorAll('.draggable-row').forEach(rowElement => {
            rowElement.classList.remove('draggable-row');
            rowElement.querySelectorAll('.rdg-cell').forEach(cell => cell.classList.remove('opacity-03'));
        });
    }

    const isDragAllowed = (id) => {
        const isOutlookProvider = !(provider === EMAIL_PROVIDER.GMAIL)
        if ((activeNav === "Spam" && ["Trash", "Inbox"].includes(id)) && !isOutlookProvider) {
            return false;
        }
        return activeNav !== id && Boolean(getLabelV2(id, labels, isOutlookProvider));
    }

    const onRightClick = (e) => {
        // setShowDropdown(!showDropdown);
        setContextMenu(e)
    }

    let setContextMenu = () => { }

    return (
        <FolderContextMenuWrapper folder={folder} fromFavorites={true} setContextMenu={(f) => {
            setContextMenu = f
        }}>
            <div className="select-none" onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                <div
                    className={`d-flex align-items-center gap-5 h-30px pr_5 pointer rounded-5 position-relative 
                    ${isActive ? "bg-white shadow-1" : ""} 
                    ${labelHovering ? "bg-gray-25" : ""}
                    ${isMoveTarget === folder?.id ? "bg-primary-100" : ""}`}
                    onClick={handleClick}
                    ref={labelRef}
                >
                    <div className="w-20px" style={{ minWidth: '20px' }}></div>
                    <div className="w-20px" style={{ minWidth: '20px' }}>
                        {getIconV2(provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : folder?.name)}
                    </div>
                    <span 
                        ref={textRef}
                        className="font-medium text-truncate"
                        data-tip={isTextTruncated ? "true" : ""}
                        data-for={`favorite-tooltip-${folder?.id}-${folder?.grantId}`}
                    >
                        {provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : convertFolderNameForOutlook(folder?.name)} - {folder?.accountName}
                    </span>
                    {isTextTruncated && (
                        <ReactTooltip
                            id={`favorite-tooltip-${folder?.id}-${folder?.grantId}`}
                            effect="solid"
                            place="bottom"
                        >
                            {provider === EMAIL_PROVIDER.GMAIL ? convertFolderNameForGmail(folder?.name, folder?.systemFolder) : convertFolderNameForOutlook(folder?.name)} - {folder?.accountName}
                        </ReactTooltip>
                    )}
                    {labelHovering ? (
                        <span
                            className={"text-muted ml-auto hover:text-gray-900"}
                            onClick={onRightClick}
                        >
                            <Icondots />
                        </span>
                    ) : (
                        <>
                            {folder?.unreadCount > 0 && (
                                <span className="ml-auto badge badge-pill badge-rounded badge-gray-100 font-normal">
                                    {folder?.unreadCount}
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
        </FolderContextMenuWrapper>
    );
});

export default FavoriteItem;