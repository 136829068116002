import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector, shallowEqual } from "react-redux";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import { editLoad } from "../../../../Load/actionCreator";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { successColorResponse , errorColorResponse} from "../../../utility";

const fetchContainerSize = (containerSize, searchSize) => {
	let allContainerSize = containerSize
	if (allContainerSize.length > 0) {
		if (searchSize !== null) {
			allContainerSize = allContainerSize.filter((size) => {
				return (
					size.name
						.toLowerCase()
						.indexOf(
							searchSize.toLowerCase().trim()
						) != -1
				);
			});
		}
		return allContainerSize;
	}
}

const ContainerSizeList = ({ CellRef, onClose, onRowChange, Row, setIsAssignInProgress,column}) => {
	const { containerSize } = useSelector((store) => store.containerReducer, shallowEqual);

	const [cursor, setCursor] = useState(null);
	const [searchSize, setSearchSize] = useState(null);
	const [dList, setDList] = useState(fetchContainerSize(containerSize, searchSize));
	const searchInputRef = React.useRef(null);

	const cell = document.querySelector('[aria-selected="true"]');

	const boxRef = useRef()
	const Style = useBoxPosition(CellRef,boxRef)

	useEffect(() => {
		searchInputRef.current.focus();
		window.document.getElementById('root').style.pointerEvents="none"
		return ()=>{
			window.document.getElementById('root').style.pointerEvents="auto"
		}
	}, [])

	useOnClickOutside(boxRef, () => onClose(false))

	useEffect(() => {
		const _containerSize = fetchContainerSize(containerSize, searchSize);
		setDList(_containerSize);
	}, [searchSize]);

	const get = (data) => {
        if(Row){
            const payload = {
                reference_number : Row.reference_number,
                containerSize : data._id,
            }
			setIsAssignInProgress(true);
            editLoad(payload).then(el => {
                onRowChange({ ...Row, containerSizeName: data.name });
				setIsAssignInProgress(false);
                onClose(false);
                successColorResponse(cell,column.idx, Row?.className);
            }).catch((err) => {
				setIsAssignInProgress(false);
				onClose(false)
				errorColorResponse(cell,column.idx, Row?.className)
			})
        }
    }
	const handleKeyDown = (event) => {
		if (event.keyCode === 38) {
			if (cursor >= 0) {
				setCursor(cursor - 1)
				document.getElementById(`containerSize-${cursor}`).focus();
			}
			event.preventDefault();
		} else if (event.keyCode === 40) {
			if (cursor <= dList.length - 1) {
				if (cursor === null) {
					setCursor(0)
				} else {
					setCursor((prev) => prev + 1)
					document.getElementById(`containerSize-${cursor}`).focus();

				}
			}
			event.preventDefault();
		} else if (event.keyCode === 13) {
			dList.forEach((val, index) => {
				if (index === cursor) get(val);
			});
			event.preventDefault();
		}else if (event.keyCode === 9 || event.keyCode === 27) {
			onClose(false)
			event.preventDefault();
			event.stopPropagation();
		  }
	  
	}
	return createPortal(
		<div
			ref={boxRef}
			data-testid="container-size-list"
			className="card card--shadow-5  mb-0 p-10 text-left z-1 w-200"
			style={Style}
		>
			<div className="app-search bg-gray-50 rounded-lg mb-10">
				<input
					className="input-search-left form-control py-2"
					placeholder="Search Container Size.."
					id="domTextElement"
					onChange={(e) => setSearchSize(e.target.value)}
					onKeyDown={(event) => { handleKeyDown(event) }}
					ref={searchInputRef}
				/>
				<span className="search-icon ml-1"></span>
			</div>
			<ul className="driverList m-0" style={{ maxHeight: '200px', overflowY: 'auto' }}>
				{(!dList || dList?.length === 0) ? (<p className="p-1">No size available</p>) : dList?.map((el, index) => (
					<li
						key={index}
						className={`popdrop__dropdown-item pointer ${cursor === index
								? "bg-gradient-light"
								: null
							}`}
						data-testid={`container-size-${el.name}`}
						tabIndex="-1"
						id={`containerSize-${index}`}
						style={{ height: '30px' }}
						onClick={async () => get(el)}
						onKeyDown={(e) => handleKeyDown(e)}
					>{el.name}</li>
				))}
			</ul>
		</div>,
		document.getElementById('pp-overlay-container')
	);
};

export default ContainerSizeList;
