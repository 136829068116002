export const sortableFields = [
  {
    name: "Customer",
    key: "customerName",
    field: "loadCustomerDetail.company_name",
    extraField: "_id", 
  },
  {
    name: "Bill to",
    key: "billTo",
    field: "billToDetail.company_name",
  },
  {
    name: "Invoice #",
    key: "invoiceNumber",
    field: "invoiceNumber",
  },
  {
    name: "Charges",
    key: "totalAmount",
    field: "totalAmount",
  }, 
  {
    name: "Charge Set #",
    key: "chargeNumber",
    field: "loadInfos.chargeReferenceNo",
  },
  {
    name: "Outstanding",
    key: "totalRemainAmount",
    field: "totalRemainAmount",
  },
  {
    name: "Invoice Total",
    key: "totalAmountWithTax",
    field: "totalAmountWithTax",
  },
  {
    name: "Status",
    key: "status",
    field: "status",
  },
  {
    key: "invoiceDate", 
    name: "Invoice Date",
    field: "invoiceDate",
  },
  {
    key: "lastPaymentDate", 
    name: "Last Payment",
    field: "lastPaymentDate",
  }
];

export const STATUS_FILTER= [
  {label:"INVOICED", value:"INVOICED" },
  {label:"PARTIALLY_PAID", value:"PARTIALLY_PAID" },
]

export const INVOICE_DYNAMIC_WIDTH_HEADER = {
  chargeNumber: "loadInfos.chargeReferenceNo",
  containerNo: "loadInfos.loadContainerNo",
  loadPurchaseOrderNo: "loadInfos.loadPurchaseOrderNo",
  loadSecondaryReferenceNo: "loadInfos.loadSecondaryReferenceNo",
  customerName: "loadCustomerDetail",
  billTo:"billToDetail.company_name",
  type_of_load:"loadInfos.loadIds",
  totalAmount:"totalAmount",
  terminalName:"terminalDetails.name",
  driverName:"loadInfos.loadIds",
  loadReferenceNo: "loadInfos.loadReferenceNo",
}

export const INVOICE_SELECTION_LIMIT_MESSAGE = "More than 100 invoices cannot be selected at a time";