import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPortForPortMarket } from "./actionCreators";
import AlertShow from "./alertShow";
import PortLogin from "./portLogin";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import { browserHistory } from "react-router";
import {  IconPen } from "../../../Components/Common/Icons";
import Credentials from "./Credentials";
import { containerTrackingColumnNames, VISIBLE_LOGO_PORTS } from "./constant";
import { isSchedulingAIEnabled, isShiposCarrier } from "../../../services";
import useCurrentUserRole from "../../../hooks/users/useCurrentUserRole";
import { getBulkCustomerPortMarket } from "../../../services/myapp.services";
import SuggestDriverAlert from "../Dispatcher/NewDriverItinerary/Components/SuggestDriverAlert";

const ContainerEnabledTrackingTable = ({ portMarkets, showTable = true }) => {
  const [showPortLoginModal, setShowPortLoginModal] = useState(false);
  const [portMarket, setPortMarket] = useState();
  const [ports, setPorts] = useState({});
  const [open, setOpen] = useState(true);
  const [errorTotal, setErrorTotal] = useState(0);
  const [showCredentialModal, setShowCredentialModal] = useState(false);
  const [isAlertView, setIsAlertView] = useState(false);

  const [portStat, setPortStat] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [shiposCarrier,setShiposCarrier] = useState(()=>{return isShiposCarrier()});

  const { isCurrentUserRoles } = useCurrentUserRole();

  const dispatch = useDispatch();
  const { portStats } = useSelector((state) => state.HomeReducer);

  const ShowLoginModal = (portMarket) => {
    setIsLoading(true);
    setPortMarket(portMarket);
    setShowPortLoginModal(true);
    setIsLoading(false);
    setShowCredentialModal(false);
    setIsAlertView(false);
  };
  const HideLoginModal = () => {
    setShowPortLoginModal(false);
    if(isAlertView) {
      setShowCredentialModal( portMarkets?.length === 1 ? false : true);
    } else {
      setShowCredentialModal(false);
    }
  };

  const handleShowCredentialModal = () => {
    setShowCredentialModal(true);
  };
  const handleHideCredentialModal = () => {
    setShowCredentialModal(false);
  };

  const getPortData = async () => {
    const payloadMarkets = portMarkets.map(eachMarket => eachMarket?.value);
    const bulkCustomerMarket = await getBulkCustomerPortMarket({ portMarkets: payloadMarkets }).then(resp => resp?.data?.data);

    const bulkStats = {};
    bulkCustomerMarket?.forEach(eachMarket => { bulkStats[eachMarket?.portMarket] = eachMarket?.count });

    setPortStat(bulkStats);
  };

  useEffect(() => {
    setOpen(true);
    if (portMarkets && portMarkets.length > 0) {
      dispatch(getPortForPortMarket({
          portMarket: portMarkets.map((market) => market.value),
        })).then(data=>{
          setPorts(data.ports);
      })
      getPortData().catch(()=> {});
    }
    updateReduxForAlertOfActionsLoads(true);
  }, [portMarkets]);

  useEffect(() => {
    let total = 0;
    Object.values(portStat).map((stat) => {
      if (!!stat) {
        total += (stat.missing ?? 0) + (stat.errors ?? 0);
      }
    });
    dispatch({type:"NOT_TRACKING_CONTAINER_PORT_COUNT", payload:total});
    setErrorTotal(total);
    setOpen(true);
    updateReduxForAlertOfActionsLoads(true);
  }, [portStat]);
  const handleClick = () => {
    if (portMarkets && portMarkets.length > 0) {
      setShowCredentialModal(true);
      setIsAlertView(true);
      // ShowLoginModal(portMarkets[0]);
    }
  };

  const updateReduxForAlertOfActionsLoads = (isEnabledMarketsError) => {
    dispatch({
      type: "SET_ENABLED_MARKETS_ERROR",
      payload: isEnabledMarketsError
    });
  }
  const handleClose = () => {
    setOpen(false);
    updateReduxForAlertOfActionsLoads(false);
  };
  const updateStats = (market, stats) => {
    const updatedStats = { ...portStat };
    updatedStats[market] = stats;
    setPortStat(updatedStats);
    dispatch({
      type: 'FETCH_PORT_MARKETS_SUCCESS',
      payload: {
        portStats: {market, updatedStats: updatedStats[market]}
      }
    });
  };


  const updatePortStats = {}
  Object.keys(portStat ?? {}).forEach((key) => {
    if(portStats?.market && portStats?.updateStats && portStats.market === key){
      updatePortStats[key] = portStats.updateStats;
      return;
    }
    updatePortStats[key] = portStat[key];
  });
  return (
    <>
      {isCurrentUserRoles(["carrier", "fleetmanager"]) && errorTotal > 0 && !shiposCarrier && open ? (
        <AlertShow
          totalError={errorTotal}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      ) : null}
      {((isLoading && showTable) || (isLoading && showPortLoginModal)) && (
        <LoaderBar />
      )}
      {showTable && (
        <>
          <div className="top-page">
            <div className="pb-15 d-flex align-items-center ">
              <h4 className="font-20 mb-0 text-capitalize">
                {portMarkets?.length} Port Enabled for Container Tracking
              </h4>
            </div>
          </div>
          <div
            className="table-responsive"
            // style={{ height: "calc(100vh - 196px)" }}
          >
            <table className=" table table-card">
              <thead>
                <tr>
                  <th className="w-20">{containerTrackingColumnNames.COMPANY_NAME}</th>
                  <th className="w-20">{containerTrackingColumnNames.STATUS}</th>
                  {/* <th className="w-20">Status</th> */}
                  <th className="w-10">{containerTrackingColumnNames.CONNECTED}</th>
                  <th className="w-10">{containerTrackingColumnNames.ERROR}</th>
                  <th className="w-10">{containerTrackingColumnNames.MISSING}</th>
                  <th className="w-10">{containerTrackingColumnNames.PROCESSING}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* {isLoading && <LoaderBar />} */}
                {portMarkets?.map((portMarket) => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex justify-content-between align-items-center ">
                          <div>{portMarket.label} 
                          </div>
                        </div>
                      </td>

                      <td>
                        <div
                          className="badge badge-light text-primary pointer text-capitalize"
                          onClick={() => ShowLoginModal(portMarket)}
                        >
                          Setup credentials
                        </div>
                      </td>
                      <td>{updatePortStats[portMarket.value]?.connected ?? 0}</td>
                      <td className="text-danger">
                        {updatePortStats[portMarket.value]?.errors ?? 0}
                      </td>
                      <td>{updatePortStats[portMarket.value]?.missing ?? 0}</td>
                      <td>{updatePortStats[portMarket.value]?.processing ?? 0}</td>
                      <td
                        width={"3%"}
                        className="text-brand-500 pointer"
                        onClick={() => ShowLoginModal(portMarket)}
                        >
                        <IconPen />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
      {(showPortLoginModal || ( showCredentialModal && portMarkets?.length === 1)) && (
        <PortLogin
          showModal={showPortLoginModal || ( showCredentialModal && portMarkets?.length === 1)}
          hideModal={HideLoginModal}
          terminalCode={portMarkets?.length === 1 ? portMarkets[0].value: portMarket.value}
          label={ portMarkets?.length === 1 ? portMarkets[0].label: portMarket.label}
          ports={ports ? ports[portMarkets?.length === 1 ? portMarkets[0].value : portMarket.value] : []}
          updateStats={updateStats}
          isAlertView={isAlertView}
        />
      )}
      {showCredentialModal && portMarkets?.length !== 1 && (
        <Credentials
          showModal={showCredentialModal}
          hideModal={handleHideCredentialModal}
          portMarkets={portMarkets}
          portStat={portStat}
          showLoginModal={(portMarket) => {
            ShowLoginModal(portMarket)
            setIsAlertView(true);
          }}
          isAlertView={isAlertView}
        />
      )}
    </>
  );
};

export default ContainerEnabledTrackingTable;
