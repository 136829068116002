import React, { useContext, useMemo, useRef, useState } from "react";
import { IconAngleArrowLeft, IconAngleArrowRight, IconHash, IconInbox, IconMailLabelOutline, IconMore, IconTimes } from "Components/Common/Icons";
import MoreDropdown from "./MoreDropdown";
import { useDropDownPosition } from "hooks/helpers/useDropDownPosition";
import { useOnClickOutside } from "hooks";
import EmailTags from "../../EmailTags";
import LabelDropDownContent from "./LabelDropDownContent";
import ExternalMailForwardAlert from "../ExternalMailForwardAlert.jsx";
import { ARCHIVE_LABLES, EMAIL_CONTEXT , EMAIL_PROVIDER, EMAIL_TABS, EMAIL_TOOL_TIPS} from "pages/tms/Email/constant";
import AddLabelModal from "./../../EmailNav/LabelModals/AddLabelModal"
import { getThreadId, isEmbeddedEmailConnected } from "pages/tms/Email/helper";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../../../constant";
import { amplitudeTrack, getStorage } from "services";
import { StatusSelect } from "../../EmailStatus";
import EmailToolTip from "./../EmailToolTip"
import ReactTooltip from "react-tooltip";

const EmailDetailsActionBar = ({ 
  isExternalMessage, 
  closeModal, 
  actionBarOptions, 
  starredMail, 
  emailData, 
  handleSendEmailModal,
  threadData,
  setRowOpen,
  handleNextEmail,
  setIsSendEmail,
  handlePrevEmail,
  isFromLoad,
  isCloseButton,
  prevEmail,
  nextEmail,
  handleOnArchive,
  archiveLoaderIds,
  rowId,
  isEmailMode,
  handleBottomUnread,
  setShowCreateTag,
  activePage,
  isReadUnreadLoding,
  setRightClickKey,
  setSelectedRow,
  updateActivePageKey,
  labelsValue,
  setupdateStatusData,
  setupdateStatusDataFlag
 }) => {
  const [isMoreActive, setIsMoreActive] = useState(false);
  const [showMailAlert, setShowMailAlert] = useState(false)
  const [sendType, setSendType] = useState("")
  const [isApiCall, setIsApiCall] = useState(false)
	const activeNavFilter = [EMAIL_TABS.INBOX, EMAIL_TABS.STARRED, "IMPORTANT", EMAIL_TABS.SENT, EMAIL_TABS.DRAFT, EMAIL_TABS.ALL_MAIL, "Spam", "Trash"]
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {}
  const { provider } = embeddedEmailAccount
  const [showCreateLabelModal, setShowCreateLabelModal] = useState(false)
  const [selectedColor, setSelectedColor] = useState(null)
  const addLabelModalRef = useRef()

  const context = useContext(EMAIL_CONTEXT)
  const { activeNav, allEmails, setAllEmails, isSubFolderActive, allLabels } = context

  const btnMoreRef = useRef(null);

  const isEmailConnected = isEmbeddedEmailConnected()

  const handleMoreToggle = () => {
    setIsMoreActive((prevState) => !prevState);
  };
  const { starredContent, starredOnClick, isToggleLoading, starredLabel } = starredMail;
  const toolTip = starredLabel === "Mark as Starred" ? EMAIL_TOOL_TIPS.NOT_STARRED : EMAIL_TOOL_TIPS.STARRED
  const toolTipWidth = starredLabel !== "Mark as Starred" ? "w-80px" : null

  const handleAlertModal = (value) => {
    setShowMailAlert(value)
  }

  const handleClick = (emailType) => {
    if(isExternalMessage){
      handleAlertModal(true)
      setSendType(emailType)
    }else{
      handleSendEmailModal(emailData, emailType)
    }
    setIsMoreActive(false)
  }

  const onClose = () => {
    setSendType("")
    handleAlertModal(false)
  }

  const onApply = async() => {
    setIsApiCall(true)
    await handleSendEmailModal(emailData, sendType)
    setIsApiCall(false)
    onClose()
  }

  const popThread = () => {
    try{
      if(!activeNavFilter.includes(activeNav)) {
        const updatedMailIndex = allEmails.findIndex((email) => email?.id === threadData?.id);
        const labels = threadData?.folders ||[]
        const params = { activePage: activeNav }
        updateActivePageKey(params)
        const isSameTab =  labels?.length && !labels?.includes(params?.activePage);
        if (isSameTab) {
          const newData = allEmails?.filter((_, index) => index !== updatedMailIndex); 
          setAllEmails(newData);
        } 
      }
      setRightClickKey("")
      setSelectedRow && setSelectedRow({})
      closeModal()
    }catch(err){
      console.log("Error in popThread", err)
    }
    
  }


  const handleColorCheckboxChange = (index) => {
    setSelectedColor(index)
  }

  const handleCreateLabelModel = (value) => {
    setShowCreateLabelModal(value)
    setIsMoreActive(false)
  }
  const CheckFolderConditionForGmail = (provider === EMAIL_PROVIDER.GMAIL?  !labelsValue?.some(label => ["INBOX"].includes(label.name)) : false)
  
  const draftFolderId = useMemo(() => {
    const draftFolder = allLabels?.find((x) => x?.name === "Drafts");
    return draftFolder?.id;
  }, [allLabels]);   

  return (
    <>
      <StatusSelect
        threadId={getThreadId(emailData)}
        accountId={emailData?.accountId}
        event={AMPLITUDE_EVENTS.EMAIL_STATUS}
        eventProperties={{ source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL }} 
        setupdateStatusData={setupdateStatusData}
        setupdateStatusDataFlag={setupdateStatusDataFlag}
     />
      {isEmailConnected && !isExternalMessage &&  
        <div
          data-tip={toolTip}
          data-for={`detail-star`}
          className="d-tags-none"
        >
        <button className="btn btn-outline-light wh-32px px-1 d-flex align-items-center justify-content-center" onClick={() => {
              starredOnClick();
              if (threadData) {
                let eventProperties = {
                  source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
                }
                if (threadData.starred) {
                  amplitudeTrack(AMPLITUDE_EVENTS.UNSTAR, eventProperties)
                }
                else {
                  amplitudeTrack(AMPLITUDE_EVENTS.STAR, eventProperties)
                }
              }
            }} disabled={isToggleLoading}>
            {starredContent}
          </button>
          <EmailToolTip toolTipId={`detail-star`} width={toolTipWidth}/>
        </div>
      }
      <div className="d-flex align-item-center gap-5">
          <ButtonWithDropDownWrapper
            renderContent={
              <EmailTags
                threadData={threadData}
                event={AMPLITUDE_EVENTS.TAG_AS}
                setShowCreateTag={setShowCreateTag}
                eventProperties={{
                  source:
                    isEmailMode === "card"
                      ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL
                      : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL,
                }}
              />
            }
            icon={<IconHash className="text-muted" />}
            id="tag"
          />
        {isEmailConnected && !isExternalMessage && provider === EMAIL_PROVIDER.GMAIL && !isFromLoad &&(
          <ButtonWithDropDownWrapper
            renderContent={
              <LabelDropDownContent closeModal={closeModal} emailData={emailData} threadData={threadData} setRowOpen={setRowOpen} setShowCreateLabelModal={setShowCreateLabelModal}  />
            }
            icon={<IconMailLabelOutline className="text-muted" />}
            id="label"
          />
        )}
      </div>
      {isEmailConnected && !isExternalMessage && !isFromLoad && (
        <div className="d-tags-none">
          <button
            data-tip={EMAIL_TOOL_TIPS.ARCHIVE}
            data-for="email-modal-tooltip"
            className="btn btn-outline-light wh-30px px-1 d-flex align-items-center justify-content-center"
            onClick={() => {
              handleOnArchive([rowId])
              let eventProperties = {
                source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
              }
              amplitudeTrack(AMPLITUDE_EVENTS.ARCHIVE,eventProperties)
            }}
            disabled={ARCHIVE_LABLES.includes(activeNav?.toLowerCase()) || [EMAIL_TABS.ARCHIVE, EMAIL_TABS.DELETED_ITEMS, EMAIL_TABS.JUNK_EMAIL].includes(activePage) || CheckFolderConditionForGmail}
          >
            {!!archiveLoaderIds.length && archiveLoaderIds.includes(rowId) ? (
              <span key={rowId} className="spinner-border spinner-border-sm batch-menu__itemicon" />
            ) : (
              <IconInbox className="text-muted" />
            )}
            <EmailToolTip toolTipId={`email-modal-tooltip`} />
          </button>
        </div>
      )}

      {isEmailConnected &&
        <div>
          <button
            data-tip={EMAIL_TOOL_TIPS.MORE}
            data-for={`more-option`}
            className="btn btn-outline-light wh-32px px-1 d-flex align-items-center justify-content-center"
            onClick={handleMoreToggle} ref={btnMoreRef}>
            <IconMore className='text-muted' />
            <EmailToolTip toolTipId={`more-option`} width={`w-80px`} />
          </button>
        </div>
      }

      {isMoreActive && (
        <MoreDropdown
          cellRef={btnMoreRef}
          closeDropdown={() => setIsMoreActive(false)}
          isExternalMessage={isExternalMessage}
          actionBarOptions={actionBarOptions}
          starredMail={starredMail}
          handleClick={handleClick}
          emailData={emailData}
          threadData={threadData}
          setRowOpen={setRowOpen}
          handleCreateLabelModel={handleCreateLabelModel}
          isEmailMode={isEmailMode}
          handleBottomUnread={handleBottomUnread}
          setShowCreateTag={setShowCreateTag}
          activePage={activePage}
          isReadUnreadLoding={isReadUnreadLoding}
          isToggleLoading={isToggleLoading}
          popThread={popThread}
          isFromLoad={isFromLoad}
          closeModal={closeModal}
        />
      )}

      {![EMAIL_TABS.DRAFT, draftFolderId]?.includes(activeNav) && (
        <div className="d-flex align-item-center gap-5">
          {!isFromLoad && <div>
            <button
              data-tip
              data-for="anglearrowlefticon"
              className="btn btn-outline-light bg-white day-card prev__arrow"
              disabled={!Object.keys(prevEmail ?? {})?.length}
              onClick={() => {
                handlePrevEmail()
                setIsSendEmail(false)
                let eventProperties = {
                  source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
                }
                amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_PREV,eventProperties)
              }}
            >
              <IconAngleArrowLeft />
            </button>
            <ReactTooltip id="anglearrowlefticon" place="top" effect="solid" className="react-tooltip-new">
              {"Prev"}
            </ReactTooltip>
          </div>}

          {!isFromLoad && <div>
            <button
              data-tip
              data-for="anglearrowrighticon"
              className="btn btn-outline-light bg-white day-card"
              disabled={!Object.keys(nextEmail ?? {})?.length}
              onClick={() => {
                handleNextEmail()
                setIsSendEmail(false)
                let eventProperties = {
                  source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL
                }
                amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_NEXT,eventProperties)
              }}
            >
              <IconAngleArrowRight />
            </button>
            <ReactTooltip id="anglearrowrighticon" place="top" effect="solid" className="react-tooltip-new">
              {"Next"}
            </ReactTooltip>
          </div>}
        </div>
      )}

      {isCloseButton && <button className="btn btn-outline-light px-2" onClick={popThread}>
          <IconTimes className="text-muted" />
      </button>}
      {
        <ExternalMailForwardAlert
          show={showMailAlert}
          onClose={() => { onClose() }}
          onApply={() => { onApply() }}
          isApiCall={isApiCall}
        />
      }
      {showCreateLabelModal &&
        <AddLabelModal
          show={showCreateLabelModal}
          modalRef={addLabelModalRef}
          selectedColor={selectedColor}
          handleColorCheckboxChange={handleColorCheckboxChange}
          handleCreateLabelModel={handleCreateLabelModel}
        />
      }
    </>
  );
};

export default EmailDetailsActionBar;

const ButtonWithDropDownWrapper = ({ label, icon, id, renderContent }) => {
  const [showButtonWrapper, setShowButtonWrapper] = useState(false);

  const boxRef = useRef(null);
  const cellRef = useRef(null);

  const toolTip = id === "tag" ? EMAIL_TOOL_TIPS.TAG_AS : EMAIL_TOOL_TIPS.LABEL_AS
  const toolTipWidth = id === "tag" ? "w-80px" : "w-90"

  useOnClickOutside([boxRef, cellRef], () => {
    setShowButtonWrapper(false);
  });

  const DropDownWrapper = ({ boxRef, cellRef }) => {
    const popupPlacement = "auto";
    const dropDownStyles = useDropDownPosition(popupPlacement, boxRef, cellRef);
    return (
      <div
        className="w-300 bg-white rounded-10 pt-10 position-fixed  z-1051 overflow-auto shadow-5"
        style={dropDownStyles}
        ref={boxRef}
      >
        {renderContent}
      </div>
    );
  };

  return (
    <div className="d-tags-none">
      <button
        className="btn btn-outline-light  wh-32px px-1 d-flex align-items-center justify-content-center"
        ref={showButtonWrapper ? cellRef : null}
        onClick={() => setShowButtonWrapper(id)}
        data-tip={toolTip}
        data-for={`email-detail-${id}`}
      >
        {icon && icon}
        {label && <span className="ml-2">{label}</span>}
        <EmailToolTip toolTipId={`email-detail-${id}`} width={toolTipWidth} />
      </button>
      {showButtonWrapper === id && <DropDownWrapper renderContent={renderContent} boxRef={boxRef} cellRef={cellRef} />}
    </div>
  );
};
