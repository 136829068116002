import React, { useEffect, useRef, useMemo } from 'react';
import { getAttachmentsByThread } from '../../actionCreator';
import DOMPurify from 'dompurify';
import { convertLinksToAnchorTags, isEmbeddedEmailConnected, removeThreeDotDataV2 } from '../../helper';
import { toastr } from 'services';
import _ from 'lodash';

const ShadowDOMInjectorWithAttachment = ({ 
  emailBody,
  uploadedDocuments = [],
  accountId,
  dotDataRemove = true,
  messageId,
  matchText = ''
}) => {


  const shadowContainerRef = useRef(null);

  const body = useMemo(() => DOMPurify.sanitize(emailBody), [emailBody]);
  let sanitizedEmailBody = convertLinks(body)

  function changeTextColor() {
    // Create a temporary div element to hold the provided HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedEmailBody;
    const arrayFromInput = _.uniq(matchText?.split(',')?.map(item => item?.trim()).filter(p => p));

    // Traverse through all text nodes within the temporary div
    const traverse = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          let newText = node.textContent;

          console.log("🚀 ~ traverse ~ arrayFromInput:", arrayFromInput)
          // Iterate over each target text in the array
          arrayFromInput.forEach(targetText => {
            // Use a regular expression to find and replace the target text
            const regex = new RegExp(targetText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'); // 'gi' for case-insensitive global match
            newText = newText.replace(regex, match => `<span style="color: #f19c38;">${match}</span> <span style="color: #367BF6;"><svg width="20" height="20" fill="none"  style="margin-bottom: -5px;" viewBox="0 0 20 20"><rect width="15" height="15" x="2.5" y="2.5" fill="#fff" stroke="currentColor" rx="3.5"/><path fill="currentColor" fill-rule="evenodd" d="M6.957 6.48a.694.694 0 0 1 .66-.48h1.498c.3 0 .567.194.66.48l1.923 5.938a.694.694 0 0 1-1.32.427l-.23-.707H6.585l-.23.707a.694.694 0 1 1-1.32-.427l.345-1.067a.69.69 0 0 1 .077-.235L6.957 6.48Zm1.653.908 1.089 3.362H7.033l1.09-3.362h.487ZM15 6.694a.694.694 0 0 0-1.388 0v5.938a.694.694 0 0 0 1.388 0V6.694Z" clip-rule="evenodd"/></svg></span>`);
          });
            // Create a new element to contain the replaced text
            const newNode = document.createElement('span');
            newNode.innerHTML = newText;
            // Replace the original text node with the new element
            node.parentNode.replaceChild(newNode, node);

        } else if (node.nodeType === Node.ELEMENT_NODE) {
            // Recursively traverse child nodes
            node.childNodes.forEach(traverse);
        }
    };

    // Start traversing from the temporary div element
    traverse(tempDiv);

    // Return the updated HTML content inside the temporary div
    return tempDiv.innerHTML;
}
// Call the function to change text color
if (matchText.length > 0) {
  sanitizedEmailBody = changeTextColor();
}
  const isEmailConnected = isEmbeddedEmailConnected()
 
  function convertLinks(sanitizedEmailBody) {
    try{      
      let htmlData = sanitizedEmailBody
      if(dotDataRemove){
        const htmlToReturn = removeThreeDotDataV2(sanitizedEmailBody)
        htmlData = htmlToReturn
      }
      const linkedHTML = convertLinksToAnchorTags(htmlData)
      return linkedHTML;
    } catch (error) {
      console.error('Error converting links to anchor tags:', error);
      return sanitizedEmailBody;
    }
  }

  const displayLazyLoadingImages = (sanitizedEmailBody) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = sanitizedEmailBody;
    const imgTags = tempDiv.querySelectorAll('img');
    if(!imgTags?.length) return sanitizedEmailBody
    imgTags?.forEach(img => {
      const imageHeight = img.getAttribute('height') ?? 50; 
      const imageWidth = img.getAttribute('width');
      if(imageHeight && imageWidth){
        const placeholderDiv = document.createElement('div');
        placeholderDiv.style.width = `${imageWidth}px`;
        placeholderDiv.style.height = `${imageHeight}px`;
        placeholderDiv.style.backgroundColor = '#f0f0f0'; // Set background color for the skeleton
        placeholderDiv.style.display = 'inline-block';
        img.parentNode.replaceChild(placeholderDiv, img);
      }
    });
    return tempDiv.innerHTML;
  }


  useEffect(() => {
    async function replaceCID() {
      try {
        // Check if there are any img tags with src starting with cid:
        if (shadowContainerRef.current.shadowRoot) {
          return;
        }
        const hasCIDImages = sanitizedEmailBody.includes('src="cid:') && (uploadedDocuments?.length > 0);
        // Attach shadow root
        const shadowRoot = shadowContainerRef.current.attachShadow({ mode: 'open' });

        // If no cid: images, simply append sanitizedEmailBody to shadow DOM

        shadowRoot.innerHTML = hasCIDImages ? displayLazyLoadingImages(sanitizedEmailBody) : sanitizedEmailBody;

        if(hasCIDImages){

        const param = {
          accountId: accountId,
        }
        const {
          attachments: attachmentResponses, 
          isSuccess, 
          message: errorMessage,
          statusCode
        } = await getAttachmentsByThread(param, messageId)
        if(!isSuccess) toastr.show(errorMessage, 'error')
        
        if(attachmentResponses?.length){

        // Create a map of attachment IDs to response data
        const attachmentMap = new Map(attachmentResponses.map((attachment, index) => [attachment.content_id, attachmentResponses[index]]));

        // Create a temporary div element
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = sanitizedEmailBody;

        // Find all img tags within the temp div
        const imgTags = tempDiv.querySelectorAll('img');

        // Batch image tag updates for efficiency
        const updates = [];

        // Iterate over img tags and process cid: images
        for (const img of imgTags) {
          const src = img.getAttribute('src');
          if (src && src.startsWith('cid:')) {
            const contentId = src.slice(4);
            const attachment = attachmentMap.get(contentId);
            if (attachment) {
              const arrayBuffer = new Uint8Array(attachment.buffer.data).buffer;
              const blob = new Blob([arrayBuffer], { type: attachment.content_type });
              const url = URL.createObjectURL(blob);
              updates.push({ img, url });
            }
          }
        }

        // Apply image tag updates in one go
        for (const { img, url } of updates) {
          img.setAttribute('src', url);
        }

        // Append the updated HTML content to the Shadow DOM container
        shadowRoot.innerHTML = tempDiv.innerHTML;
      }
      }

      } catch (error) {
        console.error('Error fetching attachments:', error);
      }
    }

    replaceCID();
  }, [emailBody, uploadedDocuments]);

  return <div ref={shadowContainerRef} style={{font: "small/1.5 Arial,Helvetica,sans-serif"}}/>;
};

export default ShadowDOMInjectorWithAttachment;
