import React, { useState, useEffect } from "react";
import PreferenceSection from "./PreferenceSection";

const TemplateTimingPreferences = (props) => {
  const {
    timeBucketOptions,
    timeBucketInHrs,
    handleSelectChange,
    timePreferences,
    handleTimePreferenceRowClick,
    timePreferencesValue,
    dayPreferencesValue,
    handleTimingPreferencesChange,
    handledayPreferencesRowClick,
    dayPreferencesOptions,
    timingPreferencesTab,
    defaultTimingPreferences
  } = props;
  const defaultdayPreferences = dayPreferencesOptions?.find((i) => i?.key === timingPreferencesTab)?.dayPreferences || [];  
  const [activeTab, setActiveTab] = useState(timingPreferencesTab || dayPreferencesOptions?.[0]?.key);
  const [dayPreferences, setDayPreferences] = useState(defaultdayPreferences);

  useEffect(() => {
    if (timingPreferencesTab) {
      setActiveTab(timingPreferencesTab);
      const dayPreferences = dayPreferencesOptions?.find((i) => i?.key === timingPreferencesTab)?.dayPreferences || [];
      setDayPreferences(dayPreferences);
    }
  }, [timingPreferencesTab, dayPreferencesOptions]);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    const dayPreferences = dayPreferencesOptions?.find((i) => i?.key === tabKey)?.dayPreferences || [];
    setDayPreferences(dayPreferences);

    const setParentSettingValue = [
      { 
        setterKey: "timePreferences",
        value: tabKey === defaultTimingPreferences?.key ? defaultTimingPreferences.time : {}
      },
      {
        setterKey: "dayPreferences", 
        value: tabKey === defaultTimingPreferences?.key ? defaultTimingPreferences.day : {}
      },
      {
        setterKey: "relativeKey",
        value: tabKey
      }
    ];

    handleTimingPreferencesChange(setParentSettingValue);
  };

  return (
    <div className="d-flex flex-column gap-15">
      <div className="font-16 line-height-20 font-medium">Timing Preferences</div>
      
      <ul className="nav nav-compact border-0 gap-5 nav-justified" role="tablist">
        {dayPreferencesOptions?.map(tab => (
          <li key={tab?.id} className="nav-item pointer">
            <span 
              className={`nav-link justify-content-center d-flex align-items-center gap-5 rounded-3 ${activeTab === tab?.key ? 'active' : ''}`}
              onClick={() => handleTabChange(tab?.key)}
            >
              {tab.label}
            </span>
          </li>
        ))}
      </ul>

      <div className="d-flex gap-30">
        <PreferenceSection 
          title="Time Preferences"
          slots={timePreferences}
          showTimeBucket={true}
          timeBucketOptions={timeBucketOptions}
          timeBucketInHrs={timeBucketInHrs}
          handleSelectChange={handleSelectChange}
          handleRowClick={handleTimePreferenceRowClick}
          timePreferencesValue={timePreferencesValue}
        />
        {
          !(timingPreferencesTab === 'firstAvailableDate') && (
            <PreferenceSection
              title="Day Preferences"
              slots={dayPreferences}
              timePreferencesValue={dayPreferencesValue}
              handleRowClick={handledayPreferencesRowClick}
              isDayPreference={true}
            />
          )
        }
      </div>
    </div>
  );
};

export default TemplateTimingPreferences;
