import React, { useRef, useState, useEffect } from 'react'
import DateEditor from '../CellComponents/DateEditor';
import { formatToDateAndTime, generateStatusColor } from '../../Functions';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import AppointmentModal from '../../../../AppointmentModal';
import { toastr } from '../../../../../../services';
import { connect, useSelector } from 'react-redux';
import { checkIfPortIsAccepted, checkIfAllowedForDirectBooking } from '../../../utility';
import RequestedAppointmentTimeInfo from '../../../../Load/Components/RequestedAppointmentTimeInfo';
import { APPOINTMENT_STATUSES, MOVE_TYPES } from '../../../../../../constants';
import { useDispatch } from 'react-redux'
import ACTIONS from 'pages/tms/AppointmentModal/store/actions'
import { useCurrentUserSettings } from 'hooks'
import { isNewAppointmentUIEnabled } from '../../../../../../services';
import ReturnAutoAppointmentModal from '../../../../AppointmentModal/AutoAppointmentModal';
import { useAutoAppointmentSystem } from '../../CustomHooks/useAutoAppointmentSystem';

const ReturnTimes = ({ row, column, onRowChange, isCellSelected, appSettings, standardPorts , acceptedPorts, newPorts }) => {
	const [showBox, setShowBox] = useState(false)
	const [isColorPortal, setColorPortal] = useState(false);
	const [showAppointmentModal, setShowAppointmentModal] = useState();
	const [isAppointmentProcessing, setIsAppointmentProcessing] = useState(false);
	
	const dispatch = useDispatch()
	const { isAppointmentFeatureEnabled } = useCurrentUserSettings()

	const { buildPayload } = useAutoAppointmentSystem();

	const cellRef = useRef()
	const prevSelectionRef = useRef();
  	const fAction = useSelector((state) => state.dispatcherTableReducer);

	const isPortAccepted = checkIfPortIsAccepted(row?.emptyOrigin?.company_name ?? row?.emptyOriginName, standardPorts, acceptedPorts, row.type_of_load, newPorts);
	const isAllowedForDirectBooking = checkIfAllowedForDirectBooking(row?.emptyOrigin?.company_name ?? row?.emptyOriginName, standardPorts);

	const handleOpenAppointmentModal = () => {
		if (isAppointmentFeatureEnabled) {
			setShowAppointmentModal(true);
			setIsAppointmentProcessing(true);
		} else {
			toastr.show("Please enable Appointment Feature to schedule appointment!", "warning")
		}
	};

	const handleCloseAppointmentModal = () => {
		setShowAppointmentModal(false);
	};

	const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected;
		if (!isCellSelected || row.type_of_load=="ROAD") {
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.ReturnTimes);
	}, [column, row?.colorOfCells?.ReturnTimes]);

	// For Manual Booking
	const handleOpenManualBooking = () => {
    if (isAppointmentFeatureEnabled) {
      const appointmentData = {
        load: row,
        moveType: MOVE_TYPES.EMPTY,
        appointmentId: row?.emptyAppointmentSystem,
      }
      dispatch({ type: ACTIONS.OPEN_MANUAL_BOOKING_MODAL, payload: appointmentData })
    } else {
      toastr.show("Please enable Appointment Feature to book an appointment!", "warning")
    }
  }

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "returnFromTime") {
			onRowChange({ ...row, returnFromTime: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "returnToTime") {
			onRowChange({ ...row, returnToTime: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])
	return (
		<>
			<div ref={cellRef} className="cell-content cell-content--fill" onClick={() => showModel()} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
				<div className="rdg-truncate">
					{row.returnFromTime && 
						formatToDateAndTime(row.returnFromTime)
					}
				</div>
					{/* Both of these badges are required. */}
					{/* For EU */}
					<div className={`badge badge-sm badge-${generateStatusColor(row.emptyAppointment?.status?.toUpperCase())} mt-1`}>{row.emptyAppointment?.status}</div>
					{/* Appointment Status */}
					{/* For US */}
					{row.emptyApptStatus !== APPOINTMENT_STATUSES.CANCELLED && <RequestedAppointmentTimeInfo label="Return" moveType={MOVE_TYPES.EMPTY} load={row} isAllowedForDirectBooking={isAllowedForDirectBooking} />}
			</div>
			{row.isEditPermission && showBox
				&& row.type_of_load !== "ROAD" 
				&& <DateEditor
						CellRef={cellRef}
						onClose={() => {
							hideModel();
							const a = document.querySelector('[aria-selected="true"]');
							if (a) a.focus();
						}}
						openAppointmentModal={handleOpenAppointmentModal} //Opening Appointment Modal From Here.
						onOpen={showModel}
						row={row}
						column={column}
						onRowChange={onRowChange} 
						isPortAccepted={isPortAccepted}
						appointmentId={row?.emptyAppointmentSystem}
						autoAppointmentId={row?.dropAutoAppointmentSystem}
						apptStatus={row?.emptyApptStatus}
						moveType={MOVE_TYPES.EMPTY}
					    openManualBooking={handleOpenManualBooking}
						standardPorts={standardPorts}
						isAppointmentProcessing={isAppointmentProcessing}
						isAllowedForDirectBooking={isAllowedForDirectBooking}
					/>
			}
			{isColorPortal && 
				<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="ReturnTimes" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
			}
			{/* Appointment Booking Modal */}
     		 {showAppointmentModal && ( !isNewAppointmentUIEnabled() ? (
				<AppointmentModal 
					load={row} moveType="EMPTY" 
					closeModal={handleCloseAppointmentModal} 
					appointmentId={row?.emptyAppointmentSystem}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
					bookDirectAppointment={(isBooked) => {
						if (isAllowedForDirectBooking) {
							setShowBox(isBooked);
							setIsAppointmentProcessing(isBooked);
							setShowAppointmentModal(isBooked);
						}
					}}
				/>
			) : (
				<ReturnAutoAppointmentModal
					load={row} 
					moveType="EMPTY"
					closeModal={handleCloseAppointmentModal}
					appointmentId={row?.emptyAppointmentSystem}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
					buildPayload={buildPayload}
					setIsAppointmentProcessing={setIsAppointmentProcessing}
				/>
			)
			)}
		</>
	)
}


function mapStateToProps(state) {
    return {
        appSettings: state.TmsReducer.appSettings,
		standardPorts: state.TmsReducer.standard_ports,
		acceptedPorts: state.TmsReducer.acceptedPorts,
		newPorts: state.TmsReducer.newPorts,
    }
}

export default connect(mapStateToProps, null)(ReturnTimes);
