import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { DateTimeFormatUtils, getStorage } from '../../../../../../services';
import DateEditor from '../CellComponents/DateEditor';
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import { useDispatch, useSelector } from 'react-redux';

const Eta = ({ row, column, onRowChange,isCellSelected, isCellEditable=true }) => {
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	const TimeZone = getStorage('timeZone')
	const [showBox, setShowBox] = useState(false)
	const [isColorPortal, setColorPortal] = useState(false);
	const dispatch = useDispatch();
  	const fAction = useSelector((state) => state.dispatcherTableReducer) || {};
	
	const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}
	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected
		if(!isCellSelected || row.type_of_load=="ROAD" ||row.type_of_load =="EXPORT"){
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected ,isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.eta);
	}, [column ,row?.colorOfCells?.eta]);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "vessel") {
			onRowChange({ ...row, vessel: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])
	return (
		<>
			<div ref={cellRef} className="cell-content text-center" onClick={() => showModel()} onContextMenu={(e)=>{e.preventDefault();setColorPortal(true);}}>
				<div className="rdg-truncate">
					{row.vessel && row.vessel.eta ?
						moment.tz(row.vessel.eta, TimeZone).format(DateTimeFormatUtils.abbreviatedDateFormat()) : <span className='date_field'></span>
					}
				
				</div>
			</div>
			{isCellEditable && row?.isEditPermission && showBox
				&& row.type_of_load !== "ROAD" && row.type_of_load !== "EXPORT" &&<DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					row={row}
					column={column}
					onRowChange={onRowChange} />
			}
			{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="eta" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
		</>
	)
}
export default Eta