import React from "react";
import _ from "lodash";


const TenderStatus = ({ row, column }) => {

  const tender = (() => {
    let index = -1;
    
    if(column.name?.includes("Tender Status")) {
      index = parseInt(column.name?.split(" ")?.[2]) - 1;
    }

    let extractedDriverOrder = row?.driverOrder;
    extractedDriverOrder = _.uniqBy(extractedDriverOrder, 'moveId');
    if(extractedDriverOrder) {  
        let tenderData
        if (extractedDriverOrder[index]) {
          tenderData = extractedDriverOrder[index]?.tenderId
        } else {
          tenderData = -1
        }
        return tenderData
    }
  })();

  const tenderColor = () => {
    const status = tender?.status?.toLowerCase();
  
    switch (status) {
      case 'rejected':
        return 'badge-danger-light';
      case 'pending':
        return 'badge-status-pending';
      case 'accepted':
        return 'badge-blue-light';
      default:
        return 'badge-gray-300';
    }
  };
  
  return (
    <div data-testid="cell-content" className="cell-content">
      { tender !== -1 && (<div className={`badge badge-sm ${tenderColor()}`}>
        {tender?.status ?? "NONE"}
      </div>)}
      {/* { tender?.drayOSTenderRefNo && <span className="badge badge-sm badge-status-light-brown ml-2"> {tender.drayOSTenderRefNo} </span>} */}
      {tender?.drayOSTenderRefNo && (
        <div className="rdg-truncate">
          {tender?.drayOSTenderRefNo  ? tender?.drayOSTenderRefNo  : <span className="text-muted font-italic">""</span>
          }
        </div>
      )}

    </div>
  );
};

export default TenderStatus;
