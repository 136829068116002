
export const EmptyRowsRenderer = () => {
  return (
    <div style={{ textAlign: "center", gridColumn: "1/-1" }}>
      <div style={{ padding: "20px 10px", backgroundColor: "white", fontSize: "12px" }}>No Result</div>
    </div>
  )
}


export const cardOptions = [
  { label: "Customers", value: ["caller", "ALL"], countKey: "customer" },
  {
    label: "Shipper/PORT",
    value: ["shipper", "containerReturn", "ALL"],
    countKey: "shipper",
  },
  { label: "Consignees", value: ["consignee", "ALL"], countKey: "consignees" },
  {
    label: "Chassis",
    value: ["chassisTermination", "chassisPick", "ALL"],
    countKey: "chassis",
  },
  { label: "Unassociated", value: ["ALL"], countKey: "unassociated" },
];

export const convertS3ToCloudFrontURL = (s3Url) => {
  try {
    if (!s3Url) return ''
    const excludePatterns = [
      'https://portpro-logistics',
      'https://portpro-dev-assets',
      'https://site-assets-com',
      'https://eu-site-assets-com'
    ];

    if (excludePatterns.some(pattern => s3Url.includes(pattern))) {
      return s3Url;
    }
    return s3Url.replace(/https:\/\/[^/]+/, process.env.REACT_APP_CLOUDFRONT_URL);
  } catch (err) {
    return s3Url
  }
}