import React from "react";
import { DateTimeFormatUtils } from "services";
import moment from "moment";
import useHover from "../../../../../../Components/CustomHooks/useHover";
import PopperTooltip from "pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";

const CustomTooltip = (props) => {
  const { hovered, setHovered, cy, cx, index, payload,onClick, tooltipPostFix } = props;
  const [referenceElementRef, referenceElement] = useHover();
  const DataKey = Object.keys(payload);

  return (
    <>
      <svg
        x={cx - (hovered === index ? 10 : 8)}
        y={cy - (hovered === index ? 10 : 8)}
        width={hovered === index ? 20 : 14}
        height={hovered === index ? 20 : 14}
        onMouseEnter={() => setHovered(index)}
        onMouseLeave={() => setHovered("")}
        ref={referenceElementRef}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer lineChart-transform"
        onClick={(event) => {
          event?.stopPropagation?.();
          onClick(props);
        }}
      >
        <path
          d="M7 13L7.001 13C8.59097 12.9984 10.1216 12.3657 11.2464 11.2389C12.3704 10.1129 13.0012 8.58653 13 6.99549C12.9988 5.40445 12.3657 3.87903 11.24 2.7547C10.1142 1.63037 8.58801 0.999205 6.99698 1C5.40594 1.0008 3.88036 1.6335 2.75576 2.75896C1.63114 3.88442 0.999602 5.41048 1 7.00151C1.0004 8.59256 1.63273 10.1183 2.7579 11.2432C3.88308 12.3681 5.40896 13 7 13Z"
          fill="#367BF6"
          stroke="white"
          stroke-width="2"
        />
      </svg>
      {referenceElement && (
        <PopperTooltip
          isOpen={referenceElement}
          refNo={referenceElementRef.current}
          isArrow={false}
          name={
            <div className="custom-tooltip dlist__card shadow-5 p-2">
              <div className="d-flex gap-15">
                <p className="font-14 font-weight-500 text-dark">{payload[DataKey?.[1]] || 0} {tooltipPostFix || "loads"}</p>
                {payload[DataKey?.[0]] && <p className="font-12 font-weight-normal text-muted">{payload[DataKey?.[0]]}</p>}
              </div>
              <p className="des text-primary font-weight-500 font-12 mb-0 text-capitalize">Click to view more details</p>
            </div>
          }
          cssClass="add_view"
        />
      )}
    </>
  );
};

export default CustomTooltip;
