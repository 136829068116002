import React, { useState } from 'react'
import LabelListPortal from './LabelListPortal'
import AddLabelModal from './LabelModals/AddLabelModal'
import RemoveLabelModal from './LabelModals/RemoveLabelModal'

const LabelListPortalWrapper = (props) => {
    const {
        boxRef,
        cellRef,
        showDropdown,
        handleToggleNewLabelDrop,
        addLabelModalRef,
        onClose,
        allLabels,
        setActiveNav
    } = props

    const [selectedColor, setSelectedColor] = useState(null)
    const [showCreateLabelModal, setShowCreateLabelModal] = useState(false)
    const [showDeleteLabelModal, setShowDeleteLabelModal] = useState(false)
    const [labelToRemove, setLabelToRemove] = useState(null)

    const handleColorCheckboxChange = (index) => {
        setSelectedColor(index)
    }

    const handleCreateLabelModel = (value) => {
        setShowCreateLabelModal(value)
        onClose(false)
    }

    const handelDeleteLabelModel = (labelId = null, value = false) => {
        setShowDeleteLabelModal(value)
        setLabelToRemove(labelId)
        onClose(false)
    }
    return (
        <div>
            {showDropdown &&
                <LabelListPortal
                    boxRef={boxRef}
                    cellRef={cellRef}
                    onClose={() => onClose(false)}
                    setShowCreateLabelModal={setShowCreateLabelModal}
                    addLabelModalRef={addLabelModalRef}
                    handleCreateLabelModel={handleCreateLabelModel}
                    handelDeleteLabelModel={handelDeleteLabelModel}
                    allLabels={allLabels}
                />
            }
            {showCreateLabelModal && (
                <AddLabelModal
                    show={showCreateLabelModal}
                    modalRef={addLabelModalRef}
                    selectedColor={selectedColor}
                    handleColorCheckboxChange={handleColorCheckboxChange}
                    handleCreateLabelModel={handleCreateLabelModel}
                />
            )}
            {showDeleteLabelModal && (
                <RemoveLabelModal
                    show={showDeleteLabelModal}
                    modalRef={addLabelModalRef}
                    labelToRemove={labelToRemove}
                    handelDeleteLabelModel={handelDeleteLabelModel}
                    setActiveNav={setActiveNav}
                />
            )}
        </div>
    )
}

export default LabelListPortalWrapper
