import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

import { Field, reduxForm } from "redux-form";
import Datetime from "react-datetime";

import { getStorage, splitStreetNameNumber,DateTimeFormatUtils, defaultAddressFormat, addressFormatEnum, isConnectedWithOutSource } from "../../services/Common.services";
import moment from "moment";
import { smallSelectStyle } from "../../assets/js/select-style";
import { IconCalendar, IconMapLight, IconTimes, IconInfo, IconErrorExclamation, IconExclamation, IconWarningCircleDanger, IconCheckCircle, IconInfoDarkI } from "../../Components/Common/Icons";
import SyncError from "./Load/SyncError";
import ReactTooltip from "react-tooltip";
import EmailToolTip from "./Email/Components/EmailDetails/EmailToolTip";
import { PERMISSION_ERROR } from "./Email/constant";


const validate = (values) => {
  var errors = {};
  if (!values.commodity) {
    errors.commodity = "Please enter commodity";
  }
  if (!values.description) {
    errors.description = "Please enter description";
  }
  if (!values.pieces) {
    errors.pieces = "Please enter pieces";
  }
  if (!values.units) {
    errors.units = "Please enter units";
  }
  if (!values.weight) {
    errors.weight = "Please enter weight";
  }
  if (!values.weightUnitType) {
    errors.weightUnitType = "Please enter weight unit";
  }
  if (!values.pallets) {
    errors.pallets = "Please enter pallets";
  }
  if (!values.palletsUnits) {
    errors.palletsUnits = "Please enter pallets units";
  }
  return errors;
};

export const NewInputField = ({
  input,
  type,
  label,
  placeholder,
  keyPress,
  autocomplete,
  maxLength,
  required,
  meta: { touched, error, warning },
  isIcon,
  isShowAddressModal
}) => {
  return (
    <>
    <label className="label-control d-block">
        {required && <span className="text-danger">*</span>} {label}
      </label>
    <div className="input-wrapper">
      <input {...input} className="form-control" type={type} name={input.name} autoComplete={autocomplete || "on"} placeholder={placeholder} onKeyPress={keyPress} maxLength={maxLength} />
      {isIcon && <span className="input-icon pointer" onClick={() => {
        isShowAddressModal()
      }}>
        <IconMapLight className="text-muted" />
      </span>}
    </div>
    {touched && error && <span className="error" style={{ color: "red" }}>{label} {error}</span>}
    </>
  )
}

export const InputField = ({
  input,
  type,
  label,
  className,
  onKeyChange,
  keyPress,
  disabled,
  required,
  isRemove,
  onRemovePress,
  meta: { touched, error },
  placeholder,
  maxlength,
  autoComplete,
  toolTipValue,
  alert,
  errorInfo,
  validIcon,
  tooltipId
}) => {
  if(input.name === "address") input.value = splitStreetNameNumber(input.value);
  if(input.name === "zip_code" && defaultAddressFormat() === addressFormatEnum.EU) input.value = input.value?.toUpperCase();
  if(input.name === "externalSystemID" && input.value && onKeyChange) disabled = true
  return (
    <React.Fragment>
      {!toolTipValue ? <label className="label-control d-block text-capitalize">
        {required && <span className="text-danger">*</span>} {label}
      </label> : 
      <div className="">
        <div className="d-flex">
        <label className="label-control ">
        {required && <span className="text-danger">*</span>} {label}
      </label>
        <span
          data-tip
          data-for={tooltipId ? tooltipId : 'tooltip'}
        >
          <IconInfoDarkI className="text-gray-100" />
        </span>
        </div>
        <ReactTooltip
          className="w-300"
          place="top"
          effect="solid"
          id={tooltipId ? tooltipId : 'tooltip'}
        ><span>{toolTipValue}</span>
        </ReactTooltip>
        </div>}
        <div className={`input-wrapper position-relative ${(errorInfo || validIcon) ? 'w-200' : ''}  ${alert ? 'mb-10': ''}`}>
        {onKeyChange ? (
          <input
            {...input}
            placeholder={
              placeholder ? placeholder : typeof label === "string" ? label : ""
            }
            autoComplete={autoComplete ? autoComplete : input.name}
            type={type}
            className={className}
            aria-describedby="basic-addon1"
            disabled={disabled}
            onKeyPress={(e) => (keyPress ? keyPress(e, input) : null)}
            onChange={(e) => onKeyChange(e, input)}
           
          />
        ) : (
          <input
            {...input}
            placeholder={
              placeholder ? placeholder : typeof label === "string" ? label : ""
            }
            autoComplete={autoComplete ? autoComplete : input.name}
            type={type}
            className={className}
            aria-describedby="basic-addon1"
            disabled={disabled}
            onKeyPress={(e) => (keyPress ? keyPress(e, input) : null)}
            maxLength={maxlength}
          />
        )}
        {isRemove && input.value && !disabled && (
          <div className="input-icon">
            <span
              onClick={(e) => {
                onRemovePress(e, input);
              }}
            >
              {" "}
              <IconTimes />{" "}
            </span>
          </div>
        )}
        {label === "Receiver Email"
          ? touched &&
            error && (
              <span className="error" style={{ color: "red" }}>
                {error}
              </span>
            )
          : touched &&
            error && (
              <span className="error text-capitalize" style={{ color: "red" }}>
                {label} {error}
              </span>
            )}
        {isConnectedWithOutSource() && <SyncError alert={alert} className={'position-absolute'}/>}
        {validIcon ? <div className="input-icon"><IconCheckCircle className="text-success" /></div> : ""}
        {errorInfo ? <div className="input-icon"  data-tip={PERMISSION_ERROR.SAME_ORG} data-for="same-org-error">
          <IconWarningCircleDanger />
          <EmailToolTip toolTipId={'same-org-error'} width={'w-150'}/>
        </div> : ''}
      </div>
    </React.Fragment>
  );
};

export const SelectField = ({
  input,
  type,
  label,
  labelcalss,
  className,
  required,
  data,
  disabled,
  backgroundColor,
  meta: { touched, error, warning },
  alert
}) => {
  return (
    <div>
      <label>
        {required && <span className="text-danger">*</span>} {label}
      </label>
      <div>
        <Select
          styles={smallSelectStyle}
          options={data}
          isDisabled={disabled}
          menuPlacement="top"
          className={backgroundColor}
          value={data.find((G) => input.value === G.value)}
          onChange={(value) => input.onChange(value.value)}
        />
      </div>
      {touched && error && (
        <span className="error" style={{ color: "red" }}>
          {error}
        </span>
      )}
      {isConnectedWithOutSource() && <SyncError alert={alert} className={'position-absolute'}/>}
    </div>
  );
};

export const DateTimeField = (props) => {
  const { meta, input, label, isInMinute } = props;
  let timeZone = getStorage("timeZone");
  return (
    <div>
      <label>{label}</label>
      <div className="input-wrapper">
        <Datetime
          displayTimeZone={timeZone}
          timeFormat={DateTimeFormatUtils.timeFormat()}
          onChange={(value) => input.onChange(moment(value))}
          value={input.value ? moment(input.value) : ""}
          // inputProps={{ readOnly: true }}
          {...props}
        />
        <div className="input-icon">
          {!input.value ? (
            <IconCalendar />
          ) : (
            <span className="pointer" onClick={() => input.onChange("")} >
              <IconTimes />  
            </span>
          )}
        </div>
      </div>
      {meta.touched && meta.error && (
        <span className="error" style={{ color: "red" }}>
          {meta.error}
        </span>
      )}
    </div>
  );
};

export const ButtonComponent = ({ label, children, className }) => (
  <button className={`btn ${className}`} type="submit">
    {label}
  </button>
);

export class PopUp extends Component {
  state = {
    inputValue: "",
  };

  componentDidMount() {
    this.inputField.focus();
    this.setState({ inputValue: this.props.inputValue });
  }

  onChange = (e) => {
    const inputValue = e.target.value;
    this.setState({ inputValue }, () => {
      this.props.fetchUsers(null, inputValue);
    });
  };

  render() {
    const { datas, inputValue, close, onSelect, fieldName } = this.props;

    return (
      <div className="static-modal">
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Suggestions</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <input
              className="pop-input"
              value={this.state.inputValue}
              ref={(input) => {
                this.inputField = input;
              }}
              onChange={this.onChange}
            />
            <ul className="pop-li">
              {(fieldName != "containerSize" || fieldName != "containerType") &&
                datas.map((data, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => this.props.onSelect(data, fieldName)}
                    >
                      {data.company_name}
                    </li>
                  );
                })}
              {(fieldName == "containerSize" || fieldName == "containerType") &&
                datas.map((data, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => this.props.onSelect(data, fieldName)}
                    >
                      {fieldName == "containerSize"
                        ? `${data.l}X${data.b}X${data.h} ${data.unit}^3`
                        : data.name}
                    </li>
                  );
                })}
              {(fieldName == "chassisNo" || fieldName == "chassisOwner") &&
                datas.map((data, key) => {
                  return (
                    <li
                      key={key}
                      onClick={() => this.props.onSelect(data, fieldName)}
                    >
                      {fieldName == "chassisOwner"
                        ? data.owner
                        : data.chassisNo}
                    </li>
                  );
                })}
            </ul>
          </Modal.Body>

          <Modal.Footer>
            <Button style={{ padding: 0 }} onClick={() => this.props.close()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    );
  }
}

export class PriceEditModal extends Component {
  render() {
    const { close, handleSubmit, updatePricing } = this.props;
    return (
      <Modal.Dialog bsSize="lg" aria-labelledby="contained-modal-title-lg">
        <Modal.Header>
          <Modal.Title>EDIT</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            <form
              onSubmit={handleSubmit((data) => {
                updatePricing(data);
                close();
              })}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="commodity"
                      label="Commodity"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="description"
                      label="Description"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="pieces"
                      label="Pieces"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="units"
                      label="Units"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="weight"
                      label="Weight"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="weightUnitType"
                      label="Weight Unit Type"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="pallets"
                      label="Pallets"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <Field
                      type="text"
                      className="form-control"
                      component={InputField}
                      name="palletsUnits"
                      label="Pallets Units"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-default">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>Close</Button>
        </Modal.Footer>
      </Modal.Dialog>
    );
  }
}

PriceEditModal = reduxForm({
  // a unique name for the form
  form: "axlepricingedit",
  validate,
})(PriceEditModal);
