import moment from "moment";
import { SelectColumn } from "react-data-grid";
import { DateTimeFormatUtils, getStorage, updateTerminology } from "../../../services";
import { caseStatus } from "./utils";
import AppointmentDateTimeFrom from "./Components/FormatedCell/appointmentDateTimeFrom";
import AppointmentDateTimeTo from "./Components/FormatedCell/appointmentDateTimeTo";
import RemarksCell from "./Components/FormatedCell/remarksCell";
import SystemRemarksCell from "./Components/FormatedCell/systemRemarksCell";
import LoadRefNo from "./Components/LoadRefNo";
import StatusComponent from "../../../Components/Common/StatusComponent";
import ContainerRefNo from "./Components/ContainerRefNo";
import { MOVE_TYPES } from "../../../constants";
import ReactToolTip from "react-tooltip";
const timeZone = getStorage("timeZone");

export const tableHeaders_backup = [
  {
    name: "Container No",
    value: "Container No",
    sort: "-",
  },
  {
    name: "Reference No",
    value: "Reference No",
    sort: "-",
  },
  {
    name: "Status",
    value: "Status",
    sort: "-",
  },
  {
    name: `${updateTerminology("Shipper")} Name`,
    value: "shipperName",
    sort: "-",
  },
  {
    name: "Type",
    value: "type",
    sort: "-",
  },
  {
    name: "Size",
    value: "size",
  },

  {
    name: "AppointmentDateTime",
    value: "appointmentDateTime",
    sort: "-",
  },

  {
    name: "Appointment From Time",
    value: "apptFrom",

    sort: "-",
  },
  {
    name: "Appointment To Time",
    value: "apptTo",
    sort: "-",
  },
];

export const sortableFields = [
  {
    name: "Container No",
    field: "containerNo",
    test: "test",
  },
  {
    name: "Reference No",
    field: "reference_number",
  },
  {
    name: "Status",
    field: "status",
  },
  {
    name: "shipperName",
    field: "shipperName",
  },
  {
    name: "type",
    field: "type",
  },
  {
    name: "size",
    field: "size",
  },

  {
    name: "appointmentDateTime",
    field: "appointmentDateTime",
  },

  {
    name: "Appointment From Time",
    field: "apptFrom",
  },
  {
    name: "Appointment To Time",
    field: "apptTo",
  },
];

export const tableHeaders = [
  {
    name: "Load #",
    value: "reference_number",
    sort: "-",
  },
  {
    name: "Container No",
    value: "ContainerNo",
    sort: "-",
  },
  {
    name: "Load Status",
    value: "loadStatus",
    sort: "-",
  },
  {
    name: "Load Type",
    value: "loadType",
    sort: "-",
  },
  {
    name: "Customer",
    value: "caller",
    sort: "-",
  },
  {
    name: "Port",
    value: "portCode",
    sort: "-",
  },
  {
    name: "Move Type",
    value: "moveType",
    sort: "-",
  },
  {
    name: "Unit Type",
    value: "fullEmpty",
    sort: "-",
  },
  {
    name: "Appointment Date Time From",
    value: "appointmentDateTimeStart",
    sort: "-",
  },
  {
    name: "Appointment Date Time To",
    value: "appointmentDateTimeEnd",
    sort: "-",
  },
  {
    name: "Appointment From Time",
    value: "apptFrom",

    sort: "-",
  },
  {
    name: "Appointment To Time",
    value: "apptTo",
    sort: "-",
  },
  {
    name: "Status",
    value: "Status",
    sort: "-",
  },
  {
    name: "Error Message",
    value: "errorMessage",
    sort: "-",
  },
];

export const appointmentListTableColumnNames = {
  loadNo: "Load No",
  containerNo: "Container No",
  loadStatus: "Load Status",
  status: "Status",
  customer: "Customer",
  loadType: "Load Type",
  moveType: "Move Type",
  fullEmpty: "Full/Empty",
  port: "Port",
  requestedDateTime: "Requested Time/DateTime",
  remarks: "Remarks",
  systemRemarks: "System Remarks"
}

// SelectColumn.width = 58;
// SelectColumn.minWidth = 58;
export const column = [
  // SelectColumn,
  {
    name: appointmentListTableColumnNames.loadNo,
    key: "reference_number",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2 justify-content-start",
    minWidth: 180,
    formatter: LoadRefNo,
  },
  {
    name: appointmentListTableColumnNames.containerNo,
    key: "containerNo",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 160,
    formatter: ContainerRefNo
  },
  {
    name: appointmentListTableColumnNames.loadStatus,
    key: "loadStatus",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 100,
    formatter: ({ row }) => {
        let load={...row}
        load.type_of_load=row.loadType;
        load.status=row.loadStatus;
        return <div className="cell-content"><StatusComponent  load={load}/></div>
    },
  },
  {
    name: appointmentListTableColumnNames.status,
    key: "status",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 160,
    // TODO: Status saved in addtionalData previously --> pushed into status
    formatter: ({ row }) => {
      return <div className="cell-content">{caseStatus(row.status)}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.customer,
    key: "caller",
    cellClass: "cell disabled_input_bg",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 100,
    formatter: ({ row }) => {
      return <div className="cell-content" title={row.caller?.company_name}>{row.caller?.company_name}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.loadType,
    key: "loadType",
    cellClass: "cell disabled_input_bg",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 100,
    formatter: ({ row }) => {
      return <div className="cell-content">{row.loadType}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.moveType,
    key: "moveType",
    cellClass: "cell disabled_input_bg",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 160,
    formatter: ({ row }) => {
      return <div className="cell-content">{row.moveType == MOVE_TYPES.EMPTY ? "DROP" : row.moveType}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.fullEmpty,
    key: "fullEmpty",
    cellClass: "cell disabled_input_bg",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 160,
    formatter: ({ row }) => {
      return <div className="cell-content">{row.fullEmpty}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.port,
    key: "portCode",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 160,
    formatter: ({ row }) => {
      return <div className="cell-content" title={row.portCode}>{row.portCode}</div>;
    },
  },
  {
    name: appointmentListTableColumnNames.requestedDateTime,
    key: "requestedAppointmentDateTimeSlots",
    cellClass: "cell",
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    minWidth: 290,
    formatter: AppointmentDateTimeFrom, 
  },
  {
    name: appointmentListTableColumnNames.remarks,
    key: "remarks",
    cellClass: "cell",
    minWidth: 500,
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    formatter: RemarksCell,
  },
  {
    name: appointmentListTableColumnNames.systemRemarks,
    key: "systemRemarks",
    cellClass: "cell",
    minWidth: 500,
    headerCellClass: "rdg-header-sort-name p-0 pl-2",
    formatter: SystemRemarksCell,
  },
];


export const filterValue = {
  appointmentEndTime: "",
  appointmentStartTime: "",
  status: [],
  shipperName: [],
  loadType: null,
  moveType:null,
  containerNo: "",
  page: 1,
  isCardActive: false
}
