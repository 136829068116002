import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IconExpand } from "../../../../Components/Common/Icons";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { toastr } from "../../../../services";
import { assignLoadToDriver } from "../../NewDispatcher/DispatcherTable/actionCreator";
import { directCompletTrip, voidOutTrip } from "../actionsCreator";

const TripIncompleteList = ({  tripDetails, setShowTripListModel, setIsDriverAssingInProgress, OpenDriverOrderModal }) => {
	const [isLoading, setIsLoading] = useState(false);

    const completeTrip = (data) => {
		setIsLoading(true)
        directCompletTrip(data).then((res) => {
			handleSkipAndContinue()
        }).catch((err) => {
            console.log(err);
        })
    }
	const handleSkipAndContinue = async() => {
		setIsLoading(true)
		const formData = new FormData();
			formData.append("reference_number", tripDetails.reference_number);
			formData.append("userId", tripDetails.userId);
			formData.append("isloadStartable", false);
			assignLoadToDriver(formData)
			.then((el) => {
				setIsLoading(false)
				toastr.show("Assigned!", "success");		
				setIsDriverAssingInProgress(false);
				OpenDriverOrderModal(el);
				setShowTripListModel(false)
			})
			.catch((err) => {
				setIsLoading(false)
				setIsDriverAssingInProgress(false);
        		setShowTripListModel(false);
				console.log("-*-* GET Driver Assigned error -*-*", err);
			});
	}
	const voidOutModal = (trip) => {
		setIsLoading(true)
		const params = { _id : trip._id, isVoidOut :!trip.isVoidOut }
		voidOutTrip(params).then((res) => {
			handleSkipAndContinue()
			setIsLoading(false);
		}).catch(res => {
			setIsLoading(false);
			toastr.show("Something went wrong.", "error");
		})
	}

  return (
    <React.Fragment>
		<div data-testid="trip-incomplete-list" /> {/* {is require on test case } */}
      {tripDetails?.trips && (
		<>
			<Modal
			show={tripDetails.trips}
			centered={true}
			className="modal-extend modal-full p-4 tripModal"
			scrollable={true}
			>
			<Modal.Header>
            	<h5 className="modal-title">Incomplete Trip List</h5>
          	</Modal.Header>
			<Modal.Body className="bg-gray-50 p-3 h-100 load-modal-full load-modal-driver">
			{isLoading &&
				<LoaderBar></LoaderBar>
			}
			<div
								className="table-responsive"
								style={{ height: "calc(100vh - 306px)" }}
							>
								<table className="table table-card">
									<thead>
										<tr>
											<th scope='col'>Trip #</th>
											<th scope="col">Status</th>
											<th scope="col">Driver</th>
											<th scope="col">Pick up location</th>
											<th scope="col">Container Size</th>
											<th scope="col">MBOL</th>
											<th scope="col">ACTION</th>
										</tr>
									</thead>
									<tbody>
										{tripDetails.trips && tripDetails.trips.length ? (
											tripDetails.trips.map((d, index) => {
												return(
													<tr>
														<td>
															<span className="cell-content lh-16 ref_number_cell">
																<div className="d-flex justify-content-between align-items-center">
																	<div>
																		<span
																			className="text-primary z-3 pointer"
											
																		>
																			{d.tripNumber ? d.tripNumber : d.reference_number}
																		</span>
																		
																	</div>
																	<div>
																		<span>
																			<div className="open-load">
																				<button className="btn btn-xs btn-primary pl-1 pr-1" >
																					<IconExpand className="text-white" />
																				</button>
																			</div>
																		</span>
																	</div>
																</div>
															</span>
														</td>
														<td>{d.status}
														</td>
														<td >
															{d?.driverId.name} {d?.driverId?.lastName}
														</td>
														<td>{d?.tripOrder ? d?.tripOrder[0]?.customerId?.company_name : ""}</td>
														<td>{d?.orderId?.containerSize?.name}</td>
														<td>{d?.orderId?.callerbillLandingNo}</td>
														<td>
															<button 
																className="btn btn-success btn-sm" 
																onClick={() => {
																	completeTrip({_id : d?._id, loadId : tripDetails.loadId})}
																}>
																	Complete
															</button>
														</td>
														<td>
														<button
															type="button"
															className={`btn btn-sm ${d.isVoidOut ? 'btn-danger' : 'btn-secondary'}`}
															onClick={()=>voidOutModal(d)}
														>
															{`${d.isVoidOut ? 'Voided Out' : 'Void Out'}`}
														</button>
														</td>
													</tr>
												)
											})) : 
											( 
												<tr>
												<td colSpan={100}>Data Not Found</td>
												</tr>
											)}
									</tbody>
								</table>
						</div>
			</Modal.Body>
			<Modal.Footer>
				<button 
					className="btn btn-close"  
					disabled={isLoading}
					onClick={()=>{setShowTripListModel(false);setIsDriverAssingInProgress(false)}}>
						{isLoading && <span 
							className="spinner-border spinner-border-sm mr-2"
							role="status"
							aria-hidden="true"></span>
						}
						Cancel
				</button>
				<button 
					className="btn btn-primary"  
					disabled={isLoading}
					onClick={()=>{handleSkipAndContinue()}}>
						Skip & Continue
				</button>
			</Modal.Footer>
			</Modal>
		</>
      )}
    </React.Fragment>
  );
};

export default TripIncompleteList;
