import { IconCapture, IconCheck, IconDownload } from "Components/Common/Icons";
import EachDriverOrder from "./EachDriverOrder";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { toPng } from 'html-to-image';
import ReactTooltip from "react-tooltip";
import { LOAD_ACTIONS, DRIVER_ACTIONS } from "pages/trucker/Tracking/store/actions";
import { DateTimeFormatUtils, addressParser, getStorage } from "services/Common.services";
import { useCurrentUser } from "hooks";
import { Modal } from "react-bootstrap";
import { IconTimes, IconUpload } from "../../../../../../Components/Common/Icons";
import { uploadDocumentForLoad, uploadDocumentTracking, generateWaitTimeReport } from "../../../actionCreators";
import { useTrackingLoadDispatch , useTrackingDriverDispatch} from "pages/trucker/Tracking/context/TrackingContext";
import { Document, Page } from "react-pdf";
import { isMoveEndingEvent } from "../../../utility";
import { createPortal } from "react-dom";
import { ALL_LOAD_TYPES, LOAD_STATUSES } from "constants";

const initialSelectedDriverOrder = {
  id: null,
  legType: null,
  arrived: null,
  departed: null,
  duration: null,
  moveId: null,
  legLocation: null,
  isVoidOut: false
};

const initialScreenshotStates = {
    showConfirmModal: false,
    loading: false,
    docUploading: false,
    isSaving: false,
    progress: 0,
    url: "",
    image: null
}
const waitTimeSupportedEvents = ["PULLCONTAINER", "DELIVERLOAD", "RETURNCONTAINER"];

export default function DriverOrderList({ driverOrders, load, selectedDriverId, driverHistoryList = {}, legIndex, startMove, setScreenshotCodeBlockFromParent= () =>{}, readonly }) {
  const [selectedDriverOrder, setSelectedDriverOrder] = useState(initialSelectedDriverOrder);
  const [screenshotStates, setScreenshotStates] = useState(initialScreenshotStates);
  const [_driverList, _setDriverList] = useState([]);
  const [waitTimeDocs, setWaitTimeDocs] = useState({});

  const dispatchLoad = useTrackingLoadDispatch();
  const dispatchDriver = useTrackingDriverDispatch();

  useEffect(()=>{
    if(screenshotStates.showConfirmModal && !screenshotStates.loading){
      uploadDocument();
    }
  },[screenshotStates.showConfirmModal])


  useEffect(() => {
    const legDoc = load?.documents?.find(doc =>  doc.moveId === selectedDriverOrder.moveId && doc.description ===  selectedDriverOrder.id)
    if (legDoc) {
      setWaitTimeDocs({
        [selectedDriverOrder.id]: legDoc
      })
    }
  }, [load, selectedDriverOrder]);

  const uploadDocument = () => {
    setScreenshotStates({...screenshotStates, loading: true});
    let loggedInUser = JSON.parse(getStorage("loggedInUser"));
    const carrierId = 
    loggedInUser.role === "carrier"
      ? loggedInUser._id
      : loggedInUser.role === "fleetmanager"
      ? loggedInUser.fleetManager.carrier
      : undefined;

    const formData = new FormData();
    formData.append("proofOfDelivery", screenshotStates.image);
    formData.append("shipmentId", new Date().getTime().toString());


    uploadDocumentTracking(formData).then(res => {
      if(res) {
        setScreenshotStates({...screenshotStates, loading: false, url: res.url, isSaving: false, progress: 100 });
      }
    })

    // to enable when wait time is to be implemented
    // formData.append("type", "Wait Time");
    // formData.append("carrier",carrierId );
    // formData.append("reference_number",load.reference_number);
    // formData.append("driverOrderId", selectedDriverOrder.id);
    // formData.append("eventName", selectedDriverOrder.legType);
    // formData.append("eventLocation", selectedDriverOrder.legLocation);
    // formData.append("arrived", selectedDriverOrder.arrived);
    // formData.append("departed", selectedDriverOrder.departed);
    // formData.append("duration", selectedDriverOrder.duration);
    // formData.append("moveId", selectedDriverOrder.moveId);

    // generateWaitTimeReport(formData).then((res) => {
    //   if (res) {
    //     setScreenshotStates({...screenshotStates, loading: false, url: res.url, isSaving: false, progress: 100 });
    //   }
    // });
  };


  const handleUploadDocumentForLoad = (e) => {
    let data = {
      moveId: selectedDriverOrder.moveId,
      reference_number: load.reference_number,
      type: "Wait Time",
      url: screenshotStates.url,
      description: selectedDriverOrder.id,
    };

    setScreenshotStates({...screenshotStates, docUploading: true, isSaving: true });

    uploadDocumentForLoad(data).then((response) => {
      if (response) {
        setScreenshotStates({ ...screenshotStates, showConfirmModal: false, docUploading: false, progress: 0, isSaving: false, image: null});
        const { _id, reference_number, documents } = response || {};
        const updatedLoadDocuments = { loadId: _id, reference_number, updatedDocuments: documents, selectedDriverId };
        dispatchDriver({ type: DRIVER_ACTIONS.UPDATE_LOAD_DOCS, payload: updatedLoadDocuments });
      }
    });
  };

  const closeConfirmModal = () => {
    setScreenshotStates({ ...screenshotStates, showConfirmModal: false, image: null, docUploading: false, loading: false });
  };

  const cancelUpload = () => {
    setScreenshotStates({ ...screenshotStates, showConfirmModal: false, image: null, progress: 0 });
  };

  const takeScreenShot = (e, k) => {
    e.stopPropagation();
    let mapContainer = document.getElementById("main-driver-tracking");

    setScreenshotStates({ ...screenshotStates, loading: true });

    toPng(mapContainer, { cacheBust: true })
      .then((imgData) => {
        setScreenshotStates({ ...screenshotStates, showConfirmModal: true, loading: false, image: imgData });
      }).catch((e) => {
        setScreenshotStates(initialScreenshotStates);
      });
  };

  const isButtonEnabled = waitTimeSupportedEvents.includes(selectedDriverOrder?.legType) &&
    selectedDriverOrder?.id &&  (
      (selectedDriverOrder?.legType === "RETURNCONTAINER" && selectedDriverOrder?.arrived) || 
      (selectedDriverOrder?.arrived && selectedDriverOrder?.departed)
    ) && !selectedDriverOrder?.isVoidOut;

  let tooltipDisabledText;
  if (!waitTimeSupportedEvents.includes(selectedDriverOrder?.legType)) {
    tooltipDisabledText = "Wait Time Report can be generated only for PULLCONTAINER, DELIVERLOAD, RETURNCONTAINER events!";
  }
  else if (!selectedDriverOrder?.legType === "RETURNCONTAINER" && !selectedDriverOrder?.arrived || !selectedDriverOrder?.departed ) {
    tooltipDisabledText = "Wait Time Report can be generated only for completed events!";
  }
  else if (selectedDriverOrder?.isVoidOut) {
    tooltipDisabledText = "Wait Time Report cannot be generated for voided out events!";
  }

  const correctedDriverOrders = driverOrders.map((driverOrder, index, driverOrderArr) => {
    const validNextLegIndex = driverOrderArr.findIndex((order, i) => i > index && !order.isVoidOut);
    const departedDate = index < driverOrderArr.length - 1 ? driverOrderArr?.[validNextLegIndex]?.arrived : null;
    const arrived = driverOrder.departed;
    let departed = departedDate;
    let isEndingMove = isMoveEndingEvent(driverOrderArr[index], driverOrderArr[index+1]);
    const lastEvent = load?.originalDriverOrder?.findLast(d => !d.isVoidOut);
    const isRoadAndCompleted = load?.type_of_load === ALL_LOAD_TYPES.ROAD && load?.status === LOAD_STATUSES.COMPLETED;
    const isDateException = (lastEvent?._id === driverOrder?._id && isRoadAndCompleted) 
      || (isEndingMove && driverOrder?.type === "RETURNCONTAINER");
    if (isDateException) departed = load?.loadCompletedAt;
    return {...driverOrder, departed, arrived, isEndingMove, isDateException, validNextLegIndex};
  });

  const setScreenshotCodeBlock = (
    selectedDriverOrder?.id && (
    <> 
    {
      !waitTimeDocs[selectedDriverOrder.id]?.url ?
      (
        <>        
        <button 
          className="btn btn-outline-light p-1 w-35px h-100" 
          onClick={(e) => takeScreenShot(e)} 
          disabled={!isButtonEnabled}
          data-tip data-for="waittimess-tooltip"
        >
          {screenshotStates.loading ?
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            : 
            <IconCapture />
          }
          
          {isButtonEnabled && createPortal (
            <ReactTooltip id="waittimess-tooltip" className="z-1051" effect="solid">
              <span>Add <span className="font-bold">Wait Time Report</span> to the documents of this load? </span>
            </ReactTooltip>, document.getElementById("pp-tooltip")
          )}
          {!isButtonEnabled && createPortal (
            <ReactTooltip id="waittimess-tooltip" className="z-1051" effect="solid">
              <span> { tooltipDisabledText} </span>
            </ReactTooltip>, document.getElementById("pp-tooltip")
          )}
        </button>                                  
      </> 
      ) 
      :
      (
        <>
          <button data-tip data-for="waiting-time-tooltip-download" className="btn btn-outline-light p-1 w-35" onClick={() => window.open(waitTimeDocs[selectedDriverOrder.id]?.url)}>
            <IconDownload className="text-dark" />
          </button>
          {createPortal (
            <ReactTooltip id="waiting-time-tooltip-download" className="z-1051" effect="solid">
              <IconCheck /> Wait Time Report is added to your invoice.
            </ReactTooltip>, document.getElementById("pp-tooltip")
          )}
        </>

      )
    }
    </>
    )
  )

  //Pass the screenshot code block to parent
  useEffect(() => {
    setScreenshotCodeBlockFromParent(setScreenshotCodeBlock)
  },[selectedDriverOrder, waitTimeDocs, screenshotStates, setScreenshotCodeBlockFromParent])

  return (
    <>
      <div className="dlist__distance d-flex flex-column gap-5 px-10 py-10">
        {correctedDriverOrders.map((driverOrder, index, driverOrderArr) => {
          return (
            <EachDriverOrder
              driverOrder={driverOrder}
              key={index}
              index={index}
              selectedDriverOrder={selectedDriverOrder}
              setSelectedDriverOrder={(val, arrivedDepartedDateTimes, routes, selectedDriverOrderIndex) => {
                let driverOrderPayload = {};
                let newSelectedDriverOrder = initialSelectedDriverOrder;
                if(val && Object.keys(val).length > 0){
                  driverOrderPayload = { ...driverOrder, driverOrders: driverOrderArr, arrivedDepartedDateTimes, routes, selectedDriverOrderIndex };
                  newSelectedDriverOrder = val;
                }

                setSelectedDriverOrder(newSelectedDriverOrder);
                dispatchLoad({ type: LOAD_ACTIONS.SET_SELECTED_DRIVER_ORDER, payload: driverOrderPayload });
              }}
              driverOrders={driverOrderArr}
              load={load}
              driverHistoryList={driverHistoryList}
              legIndex={legIndex}
              startMove={startMove}
              readonly={readonly}
            />
          );
        })}
      </div>

      
      <Modal
        className="backdrop_modal_open"
        show={screenshotStates.showConfirmModal}
        centered
        animation={false}
        bsSize="xl"
      >
        <Modal.Header>
          <Modal.Title>Confirm Document</Modal.Title>
        </Modal.Header>
        <Modal.Body
          // className="pt-0 d-flex flex-column overflow-hidden"
          className="pt-0"
        >
            {/* <div id="renderingArea" className="flex-grow-1 overflow-y-auto"> */}
            <div id="renderingArea">
              <img src={screenshotStates.image} />
            </div>
            <div className="dropzone-fileupload dropzone-fileupload--uploading d-flex">
            <IconUpload className="mr-20" />
            <div className="w-100">
              <div className="font-medium d-flex justify-content-between w-100 mb-1">
                <div></div>
                <div className="ml-auto">{screenshotStates.progress}%</div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  aria-valuenow={`${screenshotStates.progress}`}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${screenshotStates.progress}%` }}
                ></div>
              </div>
            </div>
            <button onClick={() => cancelUpload()} className="btn btn-white btn-circle btn-xs ml-3 shadow-none">
              <span>
                {" "}
                <IconTimes className="" />
              </span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
              closeConfirmModal();
            }}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            disabled={screenshotStates.docUploading || screenshotStates.progress !== 100}
            onClick={(e) => {
              e.stopPropagation();
              handleUploadDocumentForLoad(e);
            }}
          >
            {screenshotStates.isSaving && <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
            Upload Document
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
