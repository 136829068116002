import { updateTerminology } from "services";

export const AddNewLoadSteps = [
    {
        element: '.types',
        intro: 'Select a Load Type You Want'
    },
    {
        element: '.customers',
        intro: `Select a Customer`
    },
    {
        element: '.ports',
        intro: 'Select a Port'
    },
    {
        element: '.consignee',
        intro: 'Select a Consignee'
    }
];

export const ReferenceInfo = [
    {
        element: '.reference-info',
        intro: 'Here are Reference Part'
    }
];

export const AppoinmentInfo = [
    {
        element: '.appoinment-info',
        intro: 'Here are Appoinment Part',
        position: 'right'
    }
];

export const ContainerInfo = [
    {
        element: '.container-info',
        intro: 'Here are Container Part'
    }
];

export const DispatchDriver = [
    {
        element: '.driver-dispatch',
        intro: 'Here Select Driver'
    },
    {
        element: '.open-driver-dropdown',
        intro: 'Open Drop Down'
    },
    {
        element: '.select-driver-name',
        intro: 'Select Driver Name'
    }
];

export const GreenCircleLoad = [
    {
        element: '.on-green-load',
        intro: 'Click Here to start load'
    }
];

export const pickUp = [
    {
        element: '.pick-up',
        position: 'right',
        intro: 'Check Ready To Return'
    }
]

export const OrderStatusDrop = [
    {
        element: '.order-status-drop',
        intro: 'Click To Add/Remove Drop Order Status'
    }
]