import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconRedAlert } from "../CustomIcons/Index";
import { IconCheckCircle } from "../Icons";
import { updateTerminology } from "services";

const NewChargeConflictAlert = (callback, options) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      const { charge, conflictedCharge, chargeCode, currency } = options;
      let  chargeName  = chargeCode?.find(code => code.value == charge.name).chargeName
      let conflictedChargeName = chargeCode.find(code => code.value == conflictedCharge.name)?.chargeName
      return (
        <div className="custom-ui-1040 card conflicting-charge">
          <div className={`modal-header`}>
            <h4 class="modal-title" id="myModalLabel">
              Conflicting Charges
            </h4>
          </div>

          <div className="modal-body">
            <div className={`text-center`}>
              <div className="mb-30">
               <CustomIconRedAlert />
              </div>
              <p className="text-muted font-14">
                  This charge has conflicting rates in your rating,
              </p>
              <p className="text-muted font-14">
                  Please select the correct rate...
              </p>
            </div>
            <div className="mt-5">
                <table className="table table-responsive table-card  mb-1">
                    <thead>
                        <tr>
                            <th width="250">From</th>
                            <th width="250">Name of Charge #</th>
                            <th width="160">Price</th>
                            <th width="160">Unit Count</th>
                            <th width="150">Unit of Measure</th>
                            <th width="100">Free Units</th>
                            <th width="100">Per Units</th>
                            <th width="42"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="font-weight-bold">{charge?.chargeType==="RADIUS_RATES" ? "Radius Rate" : `Customer Rate`}</span></td>
                            <td>{chargeName}</td>
                            <td className="font-14 d-flex justify-content-end align-items-center">{charge.finalAmount?.toCurrency(currency || charge?.invoiceCurrency)}</td>
                            <td>{charge.unit?.print()}</td>
                            <td>{charge.perType}</td>
                            <td>{charge.freeDays?.print()}</td>
                            <td>{charge.chargePerDay?.print()}</td>
                            <td>
                                <span
                                    className="text-success cursor-pointer"
                                    onClick={() => {
                                        callback(true, charge);
                                        onClose();
                                    }}
                                >
                                    <IconCheckCircle />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="font-weight-bold">Dynamic Pricing</span></td>
                            <td>{conflictedChargeName}</td>
                            <td className="font-14 d-flex justify-content-end align-items-center">{conflictedCharge.finalAmount?.toCurrency(currency || charge?.invoiceCurrency)}</td>
                            <td>{conflictedCharge.unit?.print()}</td>
                            <td>{conflictedCharge.perType}</td>
                            <td>{conflictedCharge.freeDays?.print()}</td>
                            <td>{conflictedCharge.chargePerDay?.print()}</td>
                            <td>
                                <span
                                    className="text-success cursor-pointer"
                                    onClick={() => {
                                        callback(true, conflictedCharge);
                                        onClose();
                                    }}
                                >
                                    <IconCheckCircle />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    },
  });
};

export default NewChargeConflictAlert;
