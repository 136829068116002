import { MOVE_TYPES } from '../../../constants';

export const SCHEDULED_STATUS = {
  requested: "Waiting for Response",
  scheduled: "Scheduled",
  need_appointment: "Need Appointment"
}
export const STATUS_APPOINTMENT = {
    REQUESTED: "requested",
    SCHEDULED: "scheduled",
    NEED_APPOINTMENT: "need_appointment"
}

export const SCHEDULED_EVENT = {
    PULLCONTAINER: "Pick Up",
    RETURNCONTAINER: "Return",
    DELIVERLOAD: "Deliver",
    LIFTOFF: "Lift Off",
    STOPOFF: "Stop Off",
    DROPCONTAINER: "Drop",
    CHASSISTERMINATION: "Terminate Chassis",
    CHASSISPICK: "Hook Chassis",
    HOOKCONTAINER: "Hook Container",
    LIFTON: "Lift On",
    COMPLETED: "Completed"
}

export const PROFILE_TYPE = {
    PULLCONTAINER: "PULLCONTAINER",
    DELIVERLOAD: "DELIVERLOAD",
    RETURNCONTAINER: "RETURNCONTAINER",
}

export const PROFILE_TYPE_TO_MOVE_TYPES = {
  PULLCONTAINER: MOVE_TYPES.PICKUP,
  RETURNCONTAINER: MOVE_TYPES.EMPTY,
}


export const SCHEDULED_APPOINTMENT_TOOLTIP = {
    ENABLE_APPOINTMENT_FEATURE: "Please enable Appointment Feature to schedule appointment!",
    COMING_SOON: "Coming Soon...",
    PORT_NOT_ACCEPTED: "Port not accepted for booking!",
    APPOINTMENT_ALREADY_REQUESTED: "Appointment already requested / scheduled!",
}

export const loadType = {
  PULLCONTAINER: "Pull Container",
  DELIVERLOAD: "Deliver Load",
  RETURNCONTAINER: "Return Container",
  LIFTOFF: "Lift Off",
  STOPOFF: "Stop Off",
  DROPCONTAINER: "Drop Container",
  CHASSISTERMINATION: "Terminate Chassis",
  CHASSISPICK: "Hook Chassis",
  HOOKCONTAINER: "Hook Container",
  LIFTON: "Lift On",
  COMPLETED: "Completed"

}

export const STATUS_LABELS = {
  need_appointment: "Need Appointment",
  scheduled: "Scheduled",
  waiting_for_response: "Waiting for Response"
};
export const cardScheduling = [
    {
      Title: "Pick Up",
      TitleA: "Need Appt",
      TitleB: 'Scheduled',
      TitleC: 'Requested',
      CountCard: "PULLCONTAINER",
      CountA: 'SIDE_TOTAL_PULLCONTAINER_NEED_APT_COUNT',
      CountB: 'SIDE_TOTAL_PULLCONTAINER_SCHEDULED_APT_COUNT',
      CountC: 'SIDE_TOTAL_PULLCONTAINER_REQUESTED_APT_COUNT',
      Total: 'TOTAL_PULLCONTAINER',
      SubCardA: "need_appointment",
      SubCardB: "scheduled",
      SubCardC: "requested",

    },
    {
      Title: "Deliver/ Get Loaded",
      TitleA: "Need Appt",
      TitleB: 'Scheduled',
      TitleC: 'Requested',
      CountCard: 'DELIVERLOAD',
      CountA: 'SIDE_TOTAL_DELIVERLOAD_NEED_APT_COUNT',
      CountB: 'SIDE_TOTAL_DELIVERLOAD_SCHEDULED_APT_COUNT',
      CountC: 'SIDE_TOTAL_DELIVERLOAD_REQUESTED_APT_COUNT',
      Total: 'TOTAL_DELIVERLOAD',
      SubCardA: "need_appointment",
      SubCardB: "scheduled",
      SubCardC: "requested",
    },
    {
      Title: "Return",
      TitleA: "Need Appt",
      TitleB: 'Scheduled',
      TitleC: 'Requested',
      CountCard: 'RETURNCONTAINER',
      CountA: 'SIDE_TOTAL_RETURNCONTAINER_NEED_APT_COUNT',
      CountB: 'SIDE_TOTAL_RETURNCONTAINER_SCHEDULED_APT_COUNT',
      CountC: 'SIDE_TOTAL_RETURNCONTAINER_REQUESTED_APT_COUNT',
      Total: 'TOTAL_RETURNCONTAINER',
      SubCardA: "need_appointment",
      SubCardB: "scheduled",
      SubCardC: "requested",
    }
  ]

export const LOAD_FILTERS = {
    shipper: "shipper",
    assignedCSR: "assignedCSR",
    terminal: "terminal",
    emptyOrigin: "emptyOrigin",
    consignee: "consignee",
    caller: "caller",
    searchTerm: "searchTerm",
}

export const LOAD_FILTER_MULTI_SELECT = [
    LOAD_FILTERS.shipper, 
    LOAD_FILTERS.assignedCSR, 
    LOAD_FILTERS.terminal,
    LOAD_FILTERS.emptyOrigin,
    LOAD_FILTERS.consignee, 
    LOAD_FILTERS.caller,
];

export const sortableSchedulingFields = [
  { key: 'pickupAppt', field: "pickupAppt"},
  { key: 'deliveryAppt', field: "deliveryAppt"},
  { key: 'returnAppt', field: "returnAppt"},
];

export const SCHEDULE_APPT_COL_KEYS = Object.freeze({
  PICKUP_APPT: 'pickupAppt',
  DELIVER_APPT: 'deliveryAppt',
  RETURN_APPT: 'returnAppt',

  PICKUP_APPT_TO: 'pickupApptTo',
  DELIVER_APPT_TO: 'deliveryApptTo',
  RETURN_APPT_TO: 'returnApptTo',

  PICKUP_BOOKED_STATUS: "pickupBookedStatus",
  RETURN_BOOKED_STATUS: "returnBookedStatus",
  DELIVER_BOOKED_STATUS: "deliveryBookedStatus",

  PICKUP_APPT_STATUS: "pickupApptStatus",
  DELIVER_APPT_STATUS: "deliveryApptStatus",
  RETURN_APPT_STATUS: "returnApptStatus",
});

export const PICKUP_APPT_KEYS = ["pickupEvent", SCHEDULE_APPT_COL_KEYS.PICKUP_APPT, SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_STATUS, SCHEDULE_APPT_COL_KEYS.PICKUP_BOOKED_STATUS, SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO];
export const RETURN_APPT_KEYS = ["returnEvent", SCHEDULE_APPT_COL_KEYS.RETURN_APPT, SCHEDULE_APPT_COL_KEYS.RETURN_APPT_STATUS, SCHEDULE_APPT_COL_KEYS.RETURN_BOOKED_STATUS, SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO];
export const DELIVERY_APPT_KEYS = ["deliveryEvent", SCHEDULE_APPT_COL_KEYS.DELIVER_APPT, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_STATUS, SCHEDULE_APPT_COL_KEYS.DELIVER_BOOKED_STATUS, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO];
export const VIEW_TYPES = {
  DEFAULT: 'default',
  LIST: 'list',
  WEEK: 'week'
}

export const filterFields = [
  'PICKUP_APPOINTMENT', 'DELIVERY_APPOINTMENT', 'RETURN_APPOINTMENT', 'TERMINAL', 'LAST_FREE_DAY', 'FREE_RETURN_DAY', 'CUT_OFF', 'LOAD_TYPE', 'CUSTOMER', 'PORT', 'CONSIGNEE', 'RETURN', 'STATUS', 'APPOINTMENT_STATUS', 'CONTAINER_TYPE', 'Chassis Size', 'CHASSIS_OWNER', 'CSR'
];

export const appointmentStatusTypes = [
  { value: 'requested', label: 'REQUESTED' },
  { value: 'scheduled', label: 'SCHEDULED' },
  { value: 'need_appointment', label: 'NEED APPOINTMENT' }
]

export const UPCOMING_VIEW = {
  label: 'Upcoming View', value: 'upcoming view'
}