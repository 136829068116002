import { toastr } from "services";
import { acceptBill, declineBill, getVendorBillById, rebillVendorBills, rebillToBillVendorBills } from "../service/VendorBillsService";
import { LOAD_CHARGE_STATUS } from "../../VendorPay/constant";
import { checkAccountPayablePermission } from "../../../../services";
import { useCurrentUser } from "../../../../hooks";
import { useState, useMemo } from "react";

export const useBillHook = (triggerRefresh) => {
    const [billData, setBillData] = useState();
    const [isBillLoading, setBillLoading] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(false);
    const [declineLoading, setDeclineLoading] = useState(false);
    const [declineReason, setDeclineReason] = useState();
    const [rebillLoading, setRebillLoading] = useState(false)
    const [rebillToBillLoading, setRebillToBillLoading] = useState(false)

    const { currentUser } = useCurrentUser();
    const { vendorBillRebillPermission } = checkAccountPayablePermission(currentUser)

    const disableAccept = useMemo(() => {
        return isBillLoading || acceptLoading || declineLoading  || rebillLoading || billData?.status !== 'PENDING';
    }, [isBillLoading, acceptLoading, declineLoading, billData, rebillLoading]);

    const disableDecline = useMemo(() => {
        return isBillLoading || acceptLoading || declineLoading || rebillLoading || billData?.status !== LOAD_CHARGE_STATUS.PENDING;
    }, [isBillLoading, acceptLoading, declineLoading, rebillLoading, billData]);



    const disableRebill = useMemo(() => {
        return  isBillLoading || acceptLoading || declineLoading  || rebillLoading
         ||  [LOAD_CHARGE_STATUS.REBILLING].includes(billData?.status)
         ||  billData?.chargeIds?.find(charge =>  charge?.tenderId)
         ||  !vendorBillRebillPermission
         ||  billData?.paymentStatus 
    }, [isBillLoading, acceptLoading, declineLoading, rebillLoading, billData]);

    const disableRebillToBill = useMemo(() => {
        return isBillLoading || acceptLoading || declineLoading || rebillLoading 
        || ![LOAD_CHARGE_STATUS.REBILLING].includes(billData?.status)
        ||  billData?.chargeIds?.find(charge =>  charge?.tenderId)
    }, [isBillLoading, acceptLoading, declineLoading, rebillLoading, billData]);

    const resetLoaders = () => {
        setBillLoading(false);
        setAcceptLoading(false);
        setDeclineLoading(false);
        setRebillLoading(false)
        setRebillToBillLoading(false)
    }

    const getBillData = async (billId) => {
        return new Promise(async (reolve) => {
            if(!billId) return;
            setBillLoading(true);
            await getVendorBillById(billId).then((data) => {
                setBillData(data);
                reolve(data);
            }).catch((e) => {});
            resetLoaders();
        });
    }

    const accept = async (billId) => {
        if(!billId) return;
        setAcceptLoading(true);
        await acceptBill(billId).then((data) => {
            toastr.show('Updated Successfully!', 'success');
            getBillData(billId).then((data) => {
                if(triggerRefresh) triggerRefresh(data);
            }).catch((_) => {});
        }).catch((e) => {
            toastr.show(e, 'error');
            console.error(e);
            resetLoaders();
        });
    }

    const rebill = async (billId) => {
        if (!billId) return;
        setRebillLoading(true);
        rebillVendorBills({ billId }).then((data) => {
            toastr.show('Rebilled Successfully!', 'success');
            getBillData(billId).then((data) => {
                if (triggerRefresh) triggerRefresh(data);
            }).catch((_) => { });
        }).catch((e) => {
            resetLoaders();
        });
    }

    const rebillToBill = async ({billId, billDate, billDueDate}) => {
        if (!billId || !billDate || !billDueDate) return;
        setRebillToBillLoading(true);
        rebillToBillVendorBills({ billId, billDate, billDueDate }).then((data) => {
            toastr.show('Rebilled to Bill Successfully!', 'success');
            getBillData(billId).then((data) => {
                if (triggerRefresh) triggerRefresh(data);
            }).catch((_) => { });
        }).catch((e) => {
            console.error(e);
            resetLoaders();
        });
    };

    const decline = async (billId, reason) => {
        if(!billId) return;
        setDeclineLoading(true);
        await declineBill(billId, reason).then((data) => {
            toastr.show('Updated Successfully!', 'success');
            getBillData(billId).then((data) => {
                if(triggerRefresh) triggerRefresh(data);
            }).catch((_) => {});
        }).catch((e) => {
            console.error(e);
            toastr.show(e, 'error');
            resetLoaders();
        });
    };

    const resetData = () => {
        setBillData();
        resetLoaders();
    };



    return {
        billData,
        isBillLoading,
        acceptLoading,
        declineLoading,
        rebillLoading,
        rebillToBillLoading,
        rebillToBill,
        getBillData,
        resetData,
        accept,
        decline,
        rebill,
        disableAccept,
        disableDecline,
        disableRebillToBill,
        disableRebill,
        declineReason, 
        setDeclineReason,
    };
};