import { LOAD_LABELS } from "labels";
import { addressFormatEnum, defaultAddressFormat, getAddressTerminology, getStorage, isGlobalizationEnabled, isNewEndorsement, isNewSettingsEnabled, updateTerminology } from "../../../../services";

const FILE_HEADERS = {

  LOAD_HEADERS: {
    'Load Type*': 'type_of_load',
    'Customer*': 'caller',
    'Pick Up Location*': 'shipper',
    [`${LOAD_LABELS?.DELIVERY_CITY_STATE}*`]: 'consignee',
    'Container Return': 'emptyOrigin',
    [`${LOAD_LABELS?.CONTAINER_NUMBER}`]: 'containerNo',
    'Container Size': 'containerSize',
    'Container Type': 'containerType',
    'Weight LBS': 'weightLBS',
    'Weight KGS': 'weightKGS',
    'Delivery Order': 'documents',
    'Owner': 'containerOwner',
    [`${LOAD_LABELS?.BOOKING_NUMBER}`]: 'bookingNo',
    [`${LOAD_LABELS?.MASTER_BILL_OF_LADING}`]: 'callerbillLandingNo',
    [`${LOAD_LABELS?.CONTAINER_ETA}`]: 'vessel.eta',
    'Last Free Day': 'lastFreeDay',
    [`${LOAD_LABELS?.HOOK_CHASSIS_LOCATION}`]: 'chassisPick',
    [`${LOAD_LABELS?.TERMINAL_CHASSIS_LOCATION}`]: 'chassisTermination',
    'Reference #': 'secondaryReferenceNo',
    [`${LOAD_LABELS?.EMPTY_DATE}`]: 'emptyDay',
    [`${LOAD_LABELS?.DATE_RETURNED}`]: 'return',
    [`${LOAD_LABELS?.PICK_UP_APPT_FROM}`]: 'pickupTimes',
    [`${LOAD_LABELS?.DELIVERY_APPT_FROM}`]: 'deliveryTimes',
    [`${LOAD_LABELS?.ERD}`]: 'containerAvailableDay',
    [`${LOAD_LABELS?.PER_DIEM_LFD}`]: 'freeReturnDate',
    'Loaded Date': 'loadTime',
    'Billing Date': 'billingDate',
    [`${LOAD_LABELS?.CHASSIS_NUMBER}`]: 'chassisNo',
    'Chassis Owner': 'chassisOwner',
    'Chassis Size': 'chassisSize',
    'Chassis Type': 'chassisType',
    [`${LOAD_LABELS?.CUT_OFF_DATE}`]: 'cutOff',
    [`${LOAD_LABELS?.HOUSE_BILL_OF_LADING}`]: 'doNo',
    [`${LOAD_LABELS?.PICK_UP_NUMBER}`]: 'callerPONo',
    'Purchase Order #': 'purchaseOrderNo',
    // 'Seal Booking Number': 'sealBookingNo',
    'Seal #': 'sealNo',
    [`${LOAD_LABELS?.SHIPMENT_NUMBER}`]: 'shipmentNo',
    'Temperature': 'temperature',
    [`${updateTerminology('terminal')}`]: 'terminal',
    'Vessel Name': 'deliveryOrderNo',
    'Voyage': 'releaseNo',
    'Commodity': 'commodity',
    'Pieces': 'pieces',
    'Genset': 'isGenset',
    [`${LOAD_LABELS?.STREET_TURN}`]: 'isStreetTurn',
    ...(isNewEndorsement() ? {'Domestic': 'domestic', 'EV': 'ev', 'Waste': 'waste', 'GDP': 'gdp', 'Rail': 'isRail'} : {}),
    'Hazmat': 'hazmat',
    'Hot': 'hot',
    'Liquor': 'liquor',
    'Overheight': 'overHeight',
    'Overweight': 'overWeight',
    'Routes': 'routes',
    'Tare Weight': 'tareWeight',
    'Scale': 'scale',
  },

  LOAD_HEADERS_LABEL_VALUE:{
    'Load Type*': "IMPORT",
    'Customer*': "APM",
    'Pick Up Location*': "PNTC",
    [`${LOAD_LABELS?.DELIVERY_CITY_STATE}*`]: "SMITHSON WAREHOUSE",
    [`${LOAD_LABELS?.CONTAINER_NUMBER}`]: "ABCD123456",
    'Container Size': "53'",
    'Container Type': "ST",
    'Weight LBS': "324.01",
    'Weight KGS': "54",
    'Delivery Order': "",
    'Owner': "test",
    [`${LOAD_LABELS?.BOOKING_NUMBER}`]: "123456",
    [`${LOAD_LABELS?.MASTER_BILL_OF_LADING}`]: "HULM12345678",
    [`${LOAD_LABELS?.CONTAINER_ETA}`]: "Jun-02-23",
    'Last Free Day': "Jul-02-23",
    'Container Return': "APM TERMINAL",
    [`${LOAD_LABELS?.HOOK_CHASSIS_LOCATION}`]: "SMITHSON WAREHOUSE",
    [`${LOAD_LABELS?.TERMINAL_CHASSIS_LOCATION}`]: "APM",
    'Reference #': "BUG_NEW_M100448",
    [`${LOAD_LABELS?.EMPTY_DATE}`]: "Jun-04-23",
    [`${LOAD_LABELS?.DATE_RETURNED}`]: "Aug-04-23",
    [`${LOAD_LABELS?.PICK_UP_APPT_FROM}`]: "Aug-04-23",
    [`${LOAD_LABELS?.DELIVERY_APPT_FROM}`]: "Aug-04-23",
    [`${LOAD_LABELS?.ERD}`]: "Aug-04-23",
    [`${LOAD_LABELS?.PER_DIEM_LFD}`]: "Aug-04-23",
    'Loaded Date': "Aug-04-23",
    'Billing Date': "Aug-04-23",
    [`${LOAD_LABELS?.CHASSIS_NUMBER}`]: "chassis_63",
    'Chassis Owner': "grayChassisOwner",
    'Chassis Size': "53'",
    'Chassis Type': "ASW",
    [`${LOAD_LABELS?.CUT_OFF_DATE}`]: "Feb-04-2023",
    [`${LOAD_LABELS?.HOUSE_BILL_OF_LADING}`]: "33455",
    [`${LOAD_LABELS?.PICK_UP_NUMBER}`]: "5",
    'Purchase Order #': "12345",
    'Seal #': "23234",
    [`${LOAD_LABELS?.SHIPMENT_NUMBER}`]: "12312",
    'Temperature': "7",
    [`${updateTerminology('terminal')}`]: "TTI",
    'Vessel Name': "SHIP 1",
    'Voyage': "VOYAGE 1",
    'Commodity': "JK commodity",
    'Pieces': "15",
    'Genset': "YES",
    [`${LOAD_LABELS?.STREET_TURN}`]: 'YES',
    'Hazmat': 'YES',
    'Hot': 'NO',
    'Liquor': 'YES',
    'Overheight': 'YES',
    'Overweight': 'NO',
    'Routes': 'routes',
    'Scale': 'YES',
    ...(isNewEndorsement() ? {'Domestic': 'YES', 'EV': 'NO', 'Waste': 'NO', 'GDP': 'YES', 'Rail': 'NO'} : {}),
  },

  CUSTOMER_HEADERS: {
    'Profile Name*': 'company_name',
    'Address*': 'address',
    // 'Address1*': 'address1',
    'City*': 'city',
    [`State${defaultAddressFormat() === addressFormatEnum.EU ? '' : '*'}`]: 'state',
    'Country*': 'country',
    [`${getAddressTerminology()}*`]: 'zip_code',
    'Main Contact Name': 'main_contact_name',
    'Secondary Contact Name': 'secondary_contact_name',
    'Secondary Phone': 'secondaryPhoneNo',
    'Mobile': 'mobile',
    'Email': 'email',
    ...({'Quickbooks Email': 'qbEmail',"Quickbooks accountName":"accountID"}),
    'Billing Email': 'billingEmail',
    'Password*': 'password',
    'Payment Terms Method': 'defaultPaymentTerms.paymentTermsMethod',
    'Payment Terms + Days': 'defaultPaymentTerms.days',
    'Currency Type':'invoiceCurrencyWithCarrier',
    'Credit Limit': 'credit_limit',
    [`${updateTerminology('Terminal')}*`]: 'newTerminal',
    [`${LOAD_LABELS?.ORGANIZATION} Type*`]: 'customerType',
    'Receiver email': 'receiverEmail',
    'Mc number': 'mcNumber',
    'Fleet customer': 'fleetCustomer',
    'Pay type': 'payType',
  },

  CUSTOMER_HEADERS_LABEL_VALUE: {
    'Company Name*': "Company DKK",
    'Address*': "519 Main Street Newark NJ 07105",
    // 'Address1*': 'address1',
    'City*': "Newyork",
    [`State${defaultAddressFormat() === addressFormatEnum.EU ? '' : '*'}`]: "NJ",
    'Country*': "US",
    [`${getAddressTerminology()}*`]: "07105",
    'Main Contact Name': "John Doe",
    'Secondary Contact Name': "Doe John",
    'Secondary Phone': "9876567657",
    'Mobile': "9876567657",
    'Email': "abcd@gmail.com",
    ...({'Quickbooks Email': "asdf@gmail.com","Quickbooks accountName":"company file name"}),
    'Billing Email': "bill@gmail.com",
    'Password*': "123@12",
    'Credit Limit': "5000",
    [`${updateTerminology('Terminal')}*`]: "London(GBP)",
    [`${LOAD_LABELS?.ORGANIZATION} Type*`]: "WAREHOUSE",
    'Receiver email': "receiver@yopmail.com",
    'Mc number': "(323) 987-6543",
    'Fleet customer': "APM",
    'Pay type': "Direct_Pay",
    ...(isGlobalizationEnabled() && {'Currency Type':"USD"})
  },


  TRAILER_HEADERS: {
    'Trailer #*': 'equipmentID',
    'Year': 'year',
    'Make': 'make',
    'Model': 'model',
    'AID': 'AID',
    'ITD': 'ITD',
    'VIN': 'vin',
    'Registration Expiration': 'reg_expiration',
    'Inspection Expiration': 'inspection_exp',
    'License Plate State': 'licence_plate_state',
    'License Plate #': 'licence_plate_number',
    'HUT Expiration': 'hut_exp',
    'Trailer Type': 'trailerType',
    'Trailer Size': 'size',
    [`${updateTerminology('terminal')}`]: 'terminal',
  },

  TRAILER_HEADERS_LABEL_VALUE: {
    'Trailer #*': "53901",
    'Year':"2022",
    'Make': "WABASH",
    'Model':"DURAPLATE",
    'AID': "02-Jun-23",
    'ITD': "02-Jul-23",
    'VIN': "C12345678",
    'Registration Expiration': "02-Jun-23",
    'Inspection Expiration': "02-Jul-23",
    'License Plate State':"NY",
    'License Plate #': "AB1234",
    'HUT Expiration':"02-Jun-23",
    'Trailer Type': "Reefer",
    'Trailer Size': "40'",
    [`${updateTerminology('terminal')}`]: "GothenBurg(SEK)",
  },

  TRUCK_HEADERS: {
    'Equipment ID*': 'equipmentID',
    'License State': 'licence_plate_state',
    'License Plate #*': 'licence_plate_number',
    'Year': 'year',
    'Make': 'make',
    'Model': 'model',
    'AID': 'AID',
    'ITD': 'ITD',
    'VIN': 'vin',
    'Registration Expiration': 'reg_expiration',
    'Inspection Expiration': 'inspection_exp',
    'HUT Expiration': 'hut_exp',
    'Annual Inspection': 'annual_inspection',
    'Bobtail Insurance': 'bobtail_insurance',
    'Diesel Emission': 'diesel_emission',
    'Truck owner': 'fleetTruckOwner',
    [`${updateTerminology('terminal')}`]: 'newTerminal',
  },

  TRUCK_HEADERS_LABEL_VALUE: {
    'Equipment ID*': "12345678",
    'License State': "NJ",
    'License Plate #*': "AB12345",
    'Year': "2023",
    'Make': "Mack",
    'Model': "Anthem",
    'AID': "02-Jun-23",
    'ITD': "02-Jun-23",
    'VIN': "1A123456789",
    'Registration Expiration': "02-Jun-23",
    'Inspection Expiration': "02-Jul-23",
    'HUT Expiration': "02-Apr-23",
    'Annual Inspection': "02-Jul-23",
    'Bobtail Insurance': "02-Aug-23",
    'Diesel Emission': "02-Jul-23",
    'Truck owner': "Test 1",
    [`${updateTerminology('terminal')}`]: "London(GBP)",
  },

  DRIVER_HEADERS: {
    'First Name*': 'name',
    'Last name*': 'lastName',
    'Email': 'email',
    'Phone*': 'mobile',
    'Password*': 'password',
    'Username': 'username',
    'Truck Number': 'truck',
    'Country Code': 'country_code',
    'License State': 'licence',
    'License Number': 'licenceNumber',
    // 'TWIC Number' :'TWICNumber',
    [`${LOAD_LABELS?.SEALINK_NUMBER}`]: 'seaLinkNumber',
    'Emergency Contact Name': 'EmergencyContactName',
    'Emergency Relation': 'EmergencyRelation',
    'Emergency Contact Number': 'EmergencyContactNumber',
    'Billing Email': 'billingEmail',
    'Profile Type': 'profileType',
    'License Expiration Date': 'dlExp',
    'Date Of Birth': 'dob',
    'Date Of Hire': 'doh',
    'Medical Expiration': 'medicalExp',
    'Twic Expiration': 'twicExp',
    'Sea Link Expiration': 'seaLinkExp',
    [`${updateTerminology('terminal')}`]: 'terminal',
    'Home Branch Time Zone': 'homeTerminalTimezone'
  },

  DRIVER_HEADERS_LABEL_VALUE: {
    'First Name*': "Michael",
    'Last name*': "Cameron",
    'Email': "philips@yopmail.com",
    'Phone*': "2324252627",
    'Password*':"jo@qwe1",
    'Username': "John-Doe-123",
    'Truck Number': 'truck1234',
    'Country code': "91",
    'License State': "Arizona",
    'License Number': "F123555",
    // 'TWIC Number' :'TWICNumber',
    [`${LOAD_LABELS?.SEALINK_NUMBER}`]: "3343421",
    'Emergency Contact Name': "Luisa",
    'Emergency Relation': "sister",
    'Emergency Contact Number': "1234567890",
    'Billing Email': "bill@gmail.com",
    'Profile Type': "INDEPENDENT CONTRACTOR",
    'License Expiration Date': "02-Jun-23",
    'Date Of Birth': "02-Jun-90" ,
    'Date Of Hire': "02-Jun-23",
    'Medical Expiration': "02-Aug-23",
    'Twic Expiration': "02-Jul-23",
    'Sea Link Expiration': "02-Jun-23",
    [`${updateTerminology('terminal')}`]: "NEWYORK(USD)",
    'Home Branch Time Zone': "Eastern Time (US & Canada)"
  },

  DRIVER_PAY_HEADERS: {
    'Load Type*': 'type_of_load',
    'Amount*': 'amount',
    'Group Name*': 'type',
  },

  DRIVER_PAY_HEADERS_PROFILE_PAYMENT_LABEL_VALUE: {
    "Group Name*":"MyGroup",
    'From*' : "America",
    'To*':"Netherlands",
    "Amount*":"700",
    "Load Type*":"ROAD",
  },
  
  DRIVER_PAY_HEADERS_BY_MOVE_LABEL_VALUE: {
    "Group Name*":"Group2",
    'City*' : "NewYork",
    'State*':"NJ",
    "Amount*":"1000",
    "Load Type*":"IMPORT",
  },

  DRIVER_PAY_HEADERS_BY_ZIPCODE_LABEL_VALUE: {
    "Group Name*":"ZIP_GROUP",
    "ZIP*":"07104",
    "Amount*":"700",
    "Load Type*":"EXPORT",
  },

  EMPLOYEE_HEADERS: {
    "Email*": "email",
    'First Name*':  'firstName',
    "Last Name*":  'lastName',
    "Mobile*": 'mobile',
    "Customer ID*": "CustomerID",
    "Password*":  "password",
    'Loads Permission': 'customer_employee_load',
    'Dropped Containers Permission': 'dropped_containers',
    'Account Payable Permission': 'account_payable',
    'Info Permission': 'customer_employee_load_info',
    'Billing Permission': 'customer_employee_load_billing',
    'Documents Permission': 'customer_employee_load_documents',
    'Upload Documents Permission': 'customer_employee_load_upload_documents',
    'Payments Permission': 'customer_employee_load_payments',
    'Tracking Permission': 'customer_employee_load_tracking',
    'Service Messaging Permission': 'customer_employee_load_messaging',
    'Summary Permission': 'customer_employee_load_summary',
    'Shipment Tracking Permission': 'customer_shipments',
    'Customer Permission': 'customer'
  },


  
  EMPLOYEE_HEADERS_LABEL_VALUE: {
    "Email*":"valid@gmail.com" ,
    'First Name*': "Lucy",
    "Last Name*": "Doe",
    "Mobile*": "1234567890",
    "Customer ID*": "Company DKK",
    "Password*":  "@1234",
    'Loads Permission': "TRUE",
    'Dropped Containers Permission': "TRUE",
    'Account Payable Permission': "TRUE",
    'Info Permission': "TRUE",
    'Billing Permission': "FALSE",
    'Documents Permission': "FALSE",
    'Upload Documents Permission': "FALSE",
    'Payments Permission': "FALSE",
    'Tracking Permission': "TRUE",
    'Service Messaging Permission': "TRUE",
    'Summary Permission': "FALSE",
    'Shipment Tracking Permission': "TRUE",
    'Customer Permission': "TRUE"
  },

  LOAD_PRICING_SETTINGS_HEADERS: {
    'Customer': 'caller',
    [`${updateTerminology("Port")}`]: 'shipper',
    [`${updateTerminology("Consignee")}`]: 'consignee',
    'Return Location' : 'returnLocation',
    'City': 'city',
    'State': 'state',
    [`Delivery ${getAddressTerminology()}`]: 'zipCode',
    'Base Price': 'Base Price',
    'Chassis': 'Chassis',
    'Detention': 'Detention',
    'Fuel': 'Fuel',
    'Fuel %': 'fuel_per',
    'Overweight': 'Over Weight',
    'Toll': 'Toll',
    'PrePull': 'PrePull',
    "Attempted to Drop": 'attempted_to_drop',
    "Demur-Det Fee" : "demur_det_fee",
    "Attempted to Pick up": 'attempted_to_pick_up',
    "Bonded Cargo Charge": 'bonded_cargo_charge',
    "Container Inspection": 'container_inspection',
    "Demurrage": 'demurrage',
    "Domestic Move" : 'domestic_move',
    "Drop Charge": 'drop_charge',
    "Dry Run": 'dry_run',
    "Hazmat": 'Hazmat',
    "Line Haul": 'line_hall',
    "Maintenance and Repair": 'maintenance_and_repair',
    "Other" : 'other',
    "Per Diem": 'per_diem',
    "Pick up Charge": 'pick_up_charge',
    "Port Congestion Fee": 'port_congestion_fee',
    "Redelivery": 'redelivery',
    "Scale Load": 'scale_load',
    "Stop Off": 'stop_off',
    "Storage": 'storage',
    "Strap": 'strap',
    "Tire Rebill": 'tire_rebill',
    "Traffic Fine": 'traffic_fine',
    "Transaction Fee": 'transaction_fee',
    "Tri Axle": 'tri_axle',
    "Washout Container": 'washout_container',
    "Reefer": 'reefer',
    "Bobtail Charge" : 'bobtail_charge',
    "Pier Congestion": 'pier_congestion',
    "Hourly Pay" : 'hourly_pay',
    "Transload": 'transload',
    "Placard Removal": 'placard_removal',
    "Flexi Bag Disposal": 'flexi_bag_disposal',
    "Handling - Documentation Fee": 'Handling - Documentation Fee',
    "AES Fee": 'AES Fee',
    "Ocean Freight": 'Ocean Freight',
    "Dead Run": 'Dead Run',
    "Tarp": 'Tarp',
    "Unload": 'Unload',
    "Flatbed": 'Flatbed',
    "Waiting time": 'Waiting time',
    "Shunt": 'Shunt',
    "Tolls": 'Tolls',
    "Exam Site Pick Up": 'Exam Site Pick Up',
    'Pier Pass': 'pier_pass',
    "Chassis Split": 'Chassis Split',
    "Tank": 'Tank'
  },

  LOAD_PRICING_SETTINGS_HEADERS_LABEL_VALUE: {
    'Customer': "GLOBAL",
    [`${updateTerminology("Port")}`]: "DKK New Customer",
    [`${updateTerminology("Consignee")}`]:"Company DKK" ,
    'Return Location' : "APM",
    'City': "NEWYORK",
    'State': "NJ",
    [`Delivery ${getAddressTerminology()}`]: "07302",
    'Base Price':"11",
    'Chassis': "12",
    'Detention':"13",
    'Fuel': "14",
    'Fuel %': "15",
    'Overweight':"16",
    'Toll': "17",
    'PrePull': "18",
    "Attempted to Drop": "19",
    "Demur-Det Fee" : "20",
    "Attempted to Pick up":"21",
    "Bonded Cargo Charge": "22",
    "Container Inspection": "23",
    "Demurrage": "24",
    "Domestic Move" :"25",
    "Drop Charge": "26",
    "Dry Run": "27",
    "Hazmat": "28",
    "Line Haul":"29",
    "Maintenance and Repair":"30",
    "Other" :"31",
    "Per Diem":"32",
    "Pick up Charge":"33",
    "Port Congestion Fee": "34",
    "Redelivery": "35",
    "Scale Load": "36",
    "Stop Off": "37",
    "Storage": "38",
    "Strap": "39",
    "Tire Rebill": "40",
    "Traffic Fine": "41",
    "Transaction Fee": "42",
    "Tri Axle": "43",
    "Washout Container":"44",
    "Reefer": "45",
    "Bobtail Charge" :"46",
    "Pier Congestion": "47",
    "Hourly Pay" : "48",
    "Transload": "50",
    "Placard Removal": "51",
    "Flexi Bag Disposal": "52",
    "Handling - Documentation Fee": "53",
    "AES Fee": "54",
    "Ocean Freight":"55",
    "Dead Run": "56",
    "Tarp":"57" ,
    "Unload": "58",
    "Flatbed": "59",
    "Waiting time":"60",
    "Shunt": "61",
    "Tolls":"62",
    "Exam Site Pick Up":"63",
    'Pier Pass':"64",
    "Chassis Split": "65",
    "Tank":"66" 
  },

  CHASSIS_HEADERS: {
    'Chassis #*': 'chassisNo',
    'Chassis Type*': 'chassisType',
    'Chassis Size*': 'chassisSize',
    'Chassis Owner*': 'chassisOwner',
    'Year': 'year',
    'Make': 'make',
    'Model': 'model',
    'Annual Inspection Date': 'AID',
    'ITD': 'ITD',
    [`${updateTerminology('terminal')}`]: 'newTerminal',
    'License State*': 'licenceState',
    'License Number*': 'licenceNumber',
    'VIN': 'vin',
    'Registration': 'registration',
    'Inspection':'inspection',
    'Insurance':'insurance'
  },

  CHASSIS_HEADERS_LABEL_VALUE: {
    'Chassis #*': "ASDF123456",
    'Chassis Type*': "Tri-Axle",
    'Chassis Size*': "53'",
    'Chassis Owner*': "Postman Chassis",
    'Year': "2021",
    'Make': "HERCULES",
    'Model': "S100",
    'Annual Inspection Date': "02-Jun-23",
    'ITD': "02-Jun-23",
    [`${updateTerminology('terminal')}`]: "Arhus(DKK)",
    'License State*': "MAINE",
    'License Number*': "TX12345",
    'VIN': "kb1234567890",
    'Registration': "02-Jun-23",
    'Inspection':"02-Jul-23",
    'Insurance':"02-Aug-23"
  },
  
  CHASSIS_OWNER_HEADERS: {
    'Company Name*': 'company_name',
    'Contact Name*': 'contact_name',
    'Phone*': 'mobile',
    'Address*': 'address',
    'City*': 'city',
    [`State${defaultAddressFormat() === addressFormatEnum.EU ? '' : '*'}`]: 'state',
    [`${getAddressTerminology()}*`]: 'zip_code',
    'Country*': 'country'
  }, 

  CHASSIS_OWNER_HEADERS_LABEL_VALUE: {
    'Company Name*': "Company DKK",
    'Contact Name*': "John Amber Doe",
    'Phone*': "1234567890",
    'Address*': "512 New Street NEWYORK",
    'City*': "NewJersey",
    [`State${defaultAddressFormat() === addressFormatEnum.EU ? '' : '*'}`]: "Alabama",
    [`${getAddressTerminology()}*`]: "14532"
  },
  USER_HEADERS: {
    'First Name*': 'firstName',
    'Last Name*': 'lastName',
    'Phone': 'mobile',
    'Email*': 'email',
    'Password*': 'password',
    [isNewSettingsEnabled() ? 'System Roles*': 'Role*']: 'role',
    [`${updateTerminology('terminal')}*`]: 'terminals',
    ...(isNewSettingsEnabled()&& {'Custom Role': 'customRole'})
  },
  FLEET_OWNER_HEADERS: {
    'Company Name*': 'company_name',
    'Address*': 'address',
    'City*': 'city',
    'State*': 'state',
    'Country*': 'country',
    'Zip*': 'zip_code',
    'Main Contact Name*': 'main_contact_name',
    'Secondary Contact Name': 'secondary_contact_name',
    'Phone*': 'mobile',
    'Email*': 'email',
    'DOT #': 'DOTNumber',
    'MOT #':'MCNumber',
    'Tax ID/EIN #': 'taxId',
    'SSN': 'ssn',
  },
  CARRIER_VENDOR_HEADERS :{
    'Company Name*': 'companyName',
    'Contact Name*': 'contactName',
    'Address*': 'address',
    'Country*': 'country',
    'State*': 'state',
    'City*': 'city',
    'ZIP*': 'zip',
    'Login Email Address*': 'email',
    'Tender Email Address 1*': 'tenderEmailAddress1',
    'Tender Email Address 2': 'tenderEmailAddress2',
    'Tender Email Address 3': 'tenderEmailAddress3',
    'Phone Number*': 'mobile',
    'SCAC': 'scac',
    'MC#': 'MCNumber',
    'External ID': 'shiposCarrierID',
    'USDOT Number': 'USDOTNumber',
    'Branch 1': 'terminal1',
    'Branch 2': 'terminal2',
    'Branch 3': 'terminal3',
    'Branch 4': 'terminal4',
    'Branch 5': 'terminal5',
    'Branch 6': 'terminal6',
    'Branch 7': 'terminal7',
    'Branch 8': 'terminal8',
    'Branch 9': 'terminal9',
    'Branch 10': 'terminal10',
    'Branch 11': 'terminal11',
    'Branch 12': 'terminal12',
    'Branch 13': 'terminal13',
    'Branch 14': 'terminal14',
    'Branch 15': 'terminal15'
},
}

export const LOAD_ROUTES = {
 "Pick and Run + Drop & Hook":"PULL_DELIVER_DROP_RETURN",
  "Pick and Run + Live":"",
  "Prepull + Drop & Hook":"PULL_DROP_DELIVER_DROP_RETURN",
  "Prepull + Live":"PULL_DROP_DELIVER_RETURN",
  "One Way Move":"PULL_RETURN",
  "Pick and Run + Gray Pool":"PICK_RUN_GRAY_POOL",
  "Prepull + Gray Pool":"PREPULL_GRAY_POOL",
  "Pick and Lift + Deliver and Lift + Return":"PICK_LIFT_DELIVER_LIFT_RETURN",
  "Pick and Lift + Live":"PICK_LIFT_DELIVER_RETURN",
  "Shunt": "SHUNT_PULL_RETURN",
}

export const LOAD_ROUTES_LABEL = {
  "PULL_DELIVER_DROP_RETURN":"Pick and Run + Drop & Hook",
   "": "Pick and Run + Live",
   "PULL_DROP_DELIVER_DROP_RETURN": "Prepull + Drop & Hook",
   "PULL_DROP_DELIVER_RETURN":"Prepull + Live",
   "PULL_RETURN":"One Way Move",
   "PICK_RUN_GRAY_POOL":"Pick and Run + Gray Pool",
   "PREPULL_GRAY_POOL":"Prepull + Gray Pool",
   "PICK_LIFT_DELIVER_LIFT_RETURN":"Pick and Lift + Deliver and Lift + Return",
   "PICK_LIFT_DELIVER_RETURN":"Pick and Lift + Live",
   "SHUNT_PULL_RETURN": "Shunt",
 }

export const NO_DELIVERY_TEMPLATES = [ "PULL_RETURN", "SHUNT_PULL_RETURN" ]
export const NO_DELIVERY_TEMPLATE_LABELS = [ "One Way Move", "Shunt" ] 


export default FILE_HEADERS;

export const CHARGE_PROFILE_SAMPLE_CSV_FILENAME = 'Charge Profile.csv';
export const LOAD_TARIFF_SAMPLE_CSV_FILENAME = 'Load Tariff.csv';
export const DRIVER_TARIFF_SAMPLE_CSV_FILENAME = 'Driver Tariff.csv';
export const CARRIER_TARIFF_SAMPLE_CSV_FILENAME = 'Carrier Tariff.csv';
export const DRIVER_CHARGE_PROFILE_SAMPLE_CSV_FILENAME = 'Driver Charge Profile.csv';
export const CARRIER_CHARGE_PROFILE_SAMPLE_CSV_FILENAME = 'Carrier Charge Profile.csv';
export const CHARGE_PROFILE_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Charge Profile.csv`;
export const LOAD_TARIFF_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Load Tariff.csv`;
export const DRIVER_TARIFF_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Driver Tariff.csv`;
export const CARRIER_TARIFF_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Carrier Tariff.csv`;
export const DRIVER_CHARGE_PROFILE_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Driver Charge Profile.csv`;
export const CARRIER_CHARGE_PROFILE_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Carrier Charge Profile.csv`;

export const USER_SAMPLE_CSV_FILENAME = 'Users.csv';
export const USER_SAMPLE_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Users.csv`;
export const USER_ROLES_KEYS = Object.freeze({
  'Admin': 'admin',
  'CSR': 'CSR',
  'Sales Agent': 'salesAgent',
  ...(!isNewSettingsEnabled() && {'Mechanics': 'mechanic'})
});
export const BULKUPLOAD_TYPES = Object.freeze({
  FLEET: 'fleetOwnerBulkUploadHeaders',
  USER: 'userBulkUploadHeaders',
  CARRIER: 'carrierVendorBulkUploadHeaders'
});

export const FLEET_OWNER_SAMPLE_CSV_FILENAME = 'Fleet Owner.csv';
export const FLEET_OWNER_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Fleet Owner.csv`;

export const CARRIER_SAMPLE_CSV_FILENAME = 'Carrier.csv';
export const CARRIER_CSV_PATH = `${process.env.PUBLIC_URL}/assets/files/Carrier.csv`;

export const EMAIL_REGEX = /^[\w-.+]+@([a-zA-Z0-9.-]{1,253}\.)+[^\W_]{2,63}$/;
export const PHONE_FORMATS_REGEX = Object.freeze({
  intl: /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/,
  us: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
})

export const DOT_MOT_TAX_SSN_KEYS = ['ssn','taxId','MCNumber','DOTNumber'];