import { NEW_EMAIL_NAV_CONTEXT, EMAIL_CONTEXT } from '../../constant'
import FavoriteSection from "./FavoriteSideBar/FavoriteSection"
import AccountSection from "./FolderSideBar/AccountSection"

const NewEmailNav = ({ allNestedFolders, favoriteFolders, setEmailChanged, setActiveNav, activeNav, _moveEmail, labels, handleHideBatchMenu, changeInEmail }) => {

  const contextValue = {
    setEmailChanged,
    setActiveNav,
    activeNav,
    _moveEmail,
    labels,
    handleHideBatchMenu
  };

  return (
    <NEW_EMAIL_NAV_CONTEXT.Provider value={contextValue}>
      <div className="d-flex flex-column">
        <FavoriteSection favorites={favoriteFolders} />
        <AccountSection allNestedFolders={allNestedFolders} changeInEmail={changeInEmail} />
      </div>
    </NEW_EMAIL_NAV_CONTEXT.Provider>
  );
};

export default NewEmailNav;
