import { approvedLoadCharge, invoiceLoadCharge, unApprovedLoadCharge, getBillingChargeLists, rebillLoadCharge, getInvoicedDetail, invoiceRebilledLoadCharge, updatePaymentTerms } from "pages/tms/Load/Billing/actionCreator";
import React, { memo, useState, useEffect, useCallback,useMemo } from "react";
import moment from 'moment';
import { DateTimeFormatUtils, convertDateWithTimezoneToEquivalentUTC, getStorage, toastr } from "services";
import { addLoadChargeNotes, requestRateConfirmation } from "../../../actionCreator";
import _, {debounce}from 'lodash'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import DateWrapper from "pages/tms/NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import { IconCalendar, IconInfoCircleFilledWhite } from "Components/Common/Icons";
import InvoiceHoveredContent from "../../HoveredContent/InvoiceHoveredContent";
import { LOAD_CHARGE_STATUS } from "pages/tms/carrier/AccountReceivable/constants";
import { Spinner } from "reactstrap";
import { amplitudeTrack, checkUserPermisison, isNewBillingEnabled, checkAccountReceivablePermission, enableNewCustomerApprovalStatus } from "../../../../../../../services";
import ReactTooltip from "react-tooltip";
import { REBILL_TOOLTIP } from "./constant";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { LOAD_LABELS } from "../../../../../../../labels";


const ChargesBottomAction = (props) => {
  const { billingRow, updateChargeInChargeList, loads, setOpenEmailModal, getLoadNotesCount, setBillingCards } = props;
  const { DRAFT, APPROVED, REBILLING, INVOICED, PARTIALLY_PAID, PAID, UNAPPROVED } = LOAD_CHARGE_STATUS;
  const userTimeZone = getTimeZone({preferred: true});
  const timeZone = getTimeZone()
  const basicSettings = JSON.parse(getStorage("userBasicSettings"));
  const loggedInUser = JSON.parse(getStorage("loggedInUser"));
  const { billingApprovedPermission, billingInvoicePermission, billingInvoiceEditPermission, billingEmailSendPermission } = checkAccountReceivablePermission()
  const [isRebillLoads, setIsRebillLoads] = useState(true)
  const [hasPaymentTermsPermission,setHasPaymentTermsPermission] = useState(true)
  
  const [billingNotes, setBillingNotes] = useState("");
  const [previousBillingNotes, setPreviousBillingNotes] = useState('');
  const [isLoadingAddNotes, setIsLoadingAddNotes] = useState(false);
  const [isNoteDisabled, setIsNoteDisabled] = useState(false);
  const [invoicedData, setInvoicedData] = useState(null);
  const [preventInvoice, setPreventInvoice] = useState(false);
  const [preventReBilling, setPreventReBilling] = useState(false);
  const [invoiceModal,setInvoiceModal] = useState({
    isOpen : false,
    inVoiceSelectedDate: basicSettings?.isUserSpecificTimeZoneEnabled ? moment().tz(getTimeZone()).toString() : moment().toString(),
  });
  const[submissionDate, setSubmissionDate] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [isApiCall, setIsApiCall] = useState(false)
  const [payment, setPayment] = useState(billingRow?.billToDetail?.payment_terms || 0)
  const isPaymentDisable = [INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status)
  useEffect(() => {
    let hasRebillPermission = false;
    let hasInvoicePermission = false;
    if (loggedInUser?.role == "fleetmanager") {
      let permissions = _.cloneDeep(loggedInUser?.permissions);
      if(!permissions.includes("rebill_loads")){
        hasRebillPermission = true;
      } 
      if(!permissions.includes("approve_invoice")){
        hasInvoicePermission = true;
      }
      setHasPaymentTermsPermission(permissions.includes("customer_payment_terms"))
    }
    setPreventInvoice(hasInvoicePermission);
    setPreventReBilling(hasRebillPermission);
    if(["PARTIALLY_PAID", "PAID"].includes(billingRow?.invoice?.invoiceId?.status)) setPreventReBilling(true); 
  }, []);

  useEffect(() => {
    const loggedInUser = JSON.parse(getStorage("loggedInUser"));
    let hasRebillPermission = true;
    if (loggedInUser?.role == "fleetmanager") {
      let permissions = _.cloneDeep(loggedInUser?.permissions);
      if(!permissions.includes("rebill_loads")){
        hasRebillPermission = false;
      }
    }
    setIsRebillLoads(hasRebillPermission);
  }, []);

  useEffect(() => {
    if (billingRow?.billingNote) {
      setBillingNotes(billingRow?.billingNote);
      setPreviousBillingNotes(billingRow?.billingNote)
    }
    if(billingRow?.billToDetail) {
      setPayment(billingRow?.billToDetail?.payment_terms || 0)
    }
  }, [billingRow?.billingNote, billingRow?.billToDetail]);

  useEffect(() => {
    if (billingRow) {
      const valueData = [
        "DRAFTED",
      ].includes(billingRow?.status);
      setIsNoteDisabled(valueData);
    }

    if(!billingRow?.billToId?._id){
      setIsNoteDisabled(true);
    }

    if(loads.isDeleted) setIsNoteDisabled(true)
  }, [billingRow,setIsNoteDisabled]);

  const handleApprovedClick = () => {
    setIsApiCall(true)
    const data = {
      "chargeId": billingRow?._id
    }
    let eventProperties = {
      source: "AR_BILLING",
      loadNo: `${loads?.reference_number}`
    }
    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_APPROVE" : "AR_SUB_BILLING_APPROVE"}`,eventProperties)
    approvedLoadCharge(data).then((res) => {
      toastr.show("Updated Successfully","success")
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
        setIsApiCall(false)
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      })
    }).catch((err) => {
      console.log("err", err)
      setIsApiCall(false)
    });
  }

  const handleUnApprovedClick = () => {
    setIsApiCall(true)
    const data = {
      "chargeId": billingRow?._id
    }
    let eventProperties = {
      source: "AR_BILLING",
      loadNo: `${loads?.reference_number}`
    }
    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_UNAPPROVE" : "AR_SUB_BILLING_UNAPPROVE"}`,eventProperties)
    unApprovedLoadCharge(data).then((res) => {
      toastr.show("Updated Successfully","success")
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
        setIsApiCall(false)
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      })
    }).catch((err) => {
      console.log("err", err)
      setIsApiCall(false)
    });
  }

  const handleApproveAndInvoiceClick = (isTodayInvoiceDate) => {
    setIsApiCall(true)
    const data = {
      "chargeId": billingRow?._id
    }
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    let eventProperties = {
      source: "AR_BILLING",
      loadNo: `${loads?.reference_number}`
    }
    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_APPROVE_AND_INVOICE" : "AR_SUB_BILLING_APPROVE_AND_INVOICE"}`,eventProperties)
    approvedLoadCharge(data).then((res) => {
      if (res) {
        toastr.show("Updated Successfully","success")
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            updateChargeInChargeList(res?.data?.data[0])
            setInvoicedData(null)
            isTodayInvoiceDate == true ? handleConfirmCreateInvoice() :  setInvoiceModal({ isOpen: true });
            setIsApiCall(false)
          }
        }).catch((err)=>{
          console.log("err",err)
          setIsApiCall(false)
        });
        // handleCreateInvoiceClick();
      }
    }).catch((err)=>{
      console.log("err",err)
      setIsApiCall(false)
    });

  }

  const handleCreateInvoiceClick = (isTodayInvoiceDate) => {
    setIsApiCall(true)
    let eventProperties = {
      source: "AR_BILLING",
      loadNo: `${loads?.reference_number}`
    }
    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_INVOICE" : "AR_MAIN_BILLING_INVOICE"}`,eventProperties)
    setInvoicedData(null)
    isTodayInvoiceDate == true ? handleConfirmCreateInvoice() : setInvoiceModal({ isOpen: true });

  }

  const handleConfirmCreateInvoice = ()=>{
    setIsApiCall(true)
    setSpinner(true)
    const chargeIdArray = [];
    chargeIdArray.push(billingRow?._id);
    let invoiceDate = basicSettings?.isUserSpecificTimeZoneEnabled ? convertDateWithTimezoneToEquivalentUTC(invoiceModal?.inVoiceSelectedDate, userTimeZone) : moment(invoiceModal?.inVoiceSelectedDate)?.toISOString();

    const data = {
      "chargeIds": chargeIdArray,
      "customerCombinationMethod": "MANUAL",
      "combinationValue": "MANUAL",
      "invoiceDate" : invoiceDate
    }
    if(isSubmissionDate && submissionDate){
      data.submissionDate = invoiceDate;
    }

    if(billingRow?.status === "REBILLING"){
      data.invoiceId = billingRow?.invoice?.invoiceId?._id || billingRow?.invoice?.invoiceId;
      delete data.chargeIds;
      invoiceRebilledLoadCharge(data).then((res) => {
        toastr.show("Updated Successfully","success")
        const data = {
          "chargeId": billingRow?._id
        }
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            setInvoiceModal({ isOpen: false });
            updateChargeInChargeList(res?.data?.data[0])
            if(billingEmailSendPermission) {
              setOpenEmailModal(true)
            } else {
              setOpenEmailModal(false)
            }
            setSpinner(false)
            setIsApiCall(false)
            setSubmissionDate(false)
          }
        }).catch((err)=>{
          console.log("err",err)
          setIsApiCall(false)
        })
      }).catch(err => {
        setInvoiceModal({isOpen:false})
        setOpenEmailModal(false)
        setSpinner(false)
        setIsApiCall(false)
        setSubmissionDate(false)
      });

    }else{
      invoiceLoadCharge(data).then((res) => {
        toastr.show("Updated Successfully","success")
        const data = {
          "chargeId": billingRow?._id
        }
        props.actions.getBillingChargeLists(data).then((res) => {
          if (res?.data?.statusCode === 200) {
            setInvoiceModal({ isOpen: false });
            updateChargeInChargeList(res?.data?.data[0])
            if(billingEmailSendPermission) {
              setOpenEmailModal(true)
            } else {
              setOpenEmailModal(false)
            }
            setSpinner(false)
            setIsApiCall(false)
          }
        }).catch((err)=>{
          console.log("err",err)
          setIsApiCall(false)
        })
      }).catch(err => {
        setInvoiceModal({isOpen:false})
        setOpenEmailModal(false)
        setSpinner(false)
        setIsApiCall(false)
      });
    }
  }

  const handleReBillClick = () => {
    if(isRebillLoads){
      if(billingRow?.invoice){
        setIsApiCall(true)
        const data = {
          "invoiceNumber": billingRow?.invoice?.invoiceId?.invoiceNumber
        }
        let eventProperties = {
          source: "AR_BILLING",
          loadNo: `${loads?.reference_number}`
        }
        amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_REBILL_INVOICE" : "AR_SUB_BILLING_REBILL_INVOICE"}`,eventProperties)
        rebillLoadCharge(data).then((res) => {
          toastr.show("Updated Successfully","success")
          const data = {
            "chargeId": billingRow?._id
          }
          props.actions.getBillingChargeLists(data).then((res) => {
            if (res?.data?.statusCode === 200) {
              updateChargeInChargeList(res?.data?.data[0])
              setIsApiCall(false)
            }
          }).catch((err)=>{
            console.log("err",err)
            setIsApiCall(false)
          })
        }).catch((err)=>{
          console.log("err",err)
          setIsApiCall(false)
        });
      }
    }else{
      toastr.show("You don't have permission.", 'error');
    }
    
   
  }
  
  const handleAddNotes = (value) => {
    const data = {
      "chargeId": billingRow?._id,
      "billingNote": value
    }

    setIsLoadingAddNotes(true);
    addLoadChargeNotes(data).then((res) => {
      getLoadNotesCount(loads)
      setIsLoadingAddNotes(false);
      setBillingNotes(res?.data?.billingNote);
      setPreviousBillingNotes(res?.data?.billingNote);
      toastr.show("Updated Successfully", "success")
      // updateChargeInChargeList(res?.data)
      const data = {
        "chargeId": billingRow?._id
      }
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
      }).catch()
    }).catch((error) => {
      setIsLoadingAddNotes(false);
    })
  }

  const handleRateConfirmation = () => {
    setIsApiCall(true)
    const data = {
      "chargeId": billingRow?._id
    }
    let eventProperties = {
      source: "AR_BILLING",
      loadNo: `${loads?.reference_number}`
    }
    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_REQUEST" : "AR_SUB_BILLING_REQUEST"}`,eventProperties)
    requestRateConfirmation(data).then((res) => {
      toastr.show("Updated Successfully","success")
      props.actions.getBillingChargeLists(data).then((res) => {
        if (res?.data?.statusCode === 200) {
          updateChargeInChargeList(res?.data?.data[0])
        }
        setIsApiCall(false)
      }).catch((err) => {
        console.log("err", err)
        setIsApiCall(false)
      })
    }).catch((err) => {
      console.log("err", err)
      setIsApiCall(false)
    });
  }

  const debounceFn = useCallback(debounce((data)=>{
    setIsLoadingAddNotes(true);
  }, 2000), [billingRow]);

  const handleChangeNBillingNotes = (e) => {
    debounceFn(e.target.value);
    setBillingNotes(e.target.value);
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if (billingNotes) {
        handleAddNotes(billingNotes)
      }
    }
  }

  const handleKeyDown = (event) => {
    if(event.key === 'Tab'){
      if (billingNotes) {
        handleAddNotes(billingNotes)
      }
    }
  }

  // Define the content of the hovered div

  const checkUnApproved = () =>
  loads.isDeleted ||
  (![DRAFT, APPROVED].includes(billingRow?.status)) ||
  !billingRow?.billToId?._id ||
  ([REBILLING, INVOICED, PARTIALLY_PAID, PAID, UNAPPROVED].includes(billingRow?.status));

  const checkApproved = () =>
  loads.isDeleted ||
  !billingRow?.billToId?._id ||
  (![DRAFT,UNAPPROVED].includes(billingRow?.status)) ||
  ([PARTIALLY_PAID, PAID,APPROVED,REBILLING,INVOICED].includes(billingRow?.status));

  const checkApprovedAndInvoiced = () =>
  loads.isDeleted || preventInvoice ||
  !billingRow?.billToId?._id ||
  (![DRAFT,UNAPPROVED].includes(billingRow?.status)) ||
  ([PARTIALLY_PAID, PAID,APPROVED,REBILLING, INVOICED].includes(billingRow?.status));

  const checkInvoiced = () =>
  loads.isDeleted || preventInvoice ||
  !billingRow?.billToId?._id || !billingRow?.status ||
  (![APPROVED,REBILLING].includes(billingRow?.status)) ||
  ([PARTIALLY_PAID, PAID,INVOICED,UNAPPROVED,DRAFT].includes(billingRow?.status));

  const checkReBill = () =>
  loads?.isDeleted || preventReBilling ||
  !billingRow?.billToId?._id || !billingRow?.status ||
  ([UNAPPROVED,DRAFT,APPROVED,PARTIALLY_PAID, PAID,REBILLING].includes(billingRow?.status));

  const isUnApproved = checkUnApproved();
  const isApproved = checkApproved();
  const isApprovedAndInvoiced = checkApprovedAndInvoiced();
  const isInvoiced = checkInvoiced();
  const isReBill = checkReBill();
  const canRequestConfirmation = useMemo(() => {
    if(billingRow?.status !== INVOICED) return true;
    return false;
  }, [billingRow])
  
  const handleBlurEvent = (e) => {
    if(!billingRow || isPaymentDisable) return;
    const params = {
      chargeId: billingRow?._id,
      paymentTerms: e.target.value
    }
    updatePaymentTerms(params)
    .then((res) => {
      toastr.show("Updated Successfully","success");
    })
    .catch((err) => {
      setPayment(billingRow?.billToDetail?.payment_terms);
    })
  }

  const { isSubmissionDate } = useMemo(() => {
    const settings = JSON.parse(localStorage.getItem('userBasicSettings'));
    const submissionDateEnabled = settings?.billingConfiguration?.config?.isSubmissionDate;
    return {
      isSubmissionDate: submissionDateEnabled,
    };
  }, []);
  
  return (
    <div>
      <div className={`form-row-lg mb-10`}>
        <div className="col-lg-12">
        <label>{LOAD_LABELS.BILLING_NOTE}</label>
          <textarea
            className="form-control"
            style={{ minHeight: "60px" }}
            name="billingNote"
            type="message"
            placeholder="Enter Billing Note..."
            value={billingNotes}
            onChange={billingInvoiceEditPermission && handleChangeNBillingNotes}
            disabled={isNoteDisabled || !billingInvoiceEditPermission}
            onBlur={() => {
              const trimmedNotes = billingNotes?.trim();
              if (
                trimmedNotes !== previousBillingNotes &&
                trimmedNotes !== undefined &&
                trimmedNotes !== null 
              ) {
                handleAddNotes(trimmedNotes);
              }

              if(billingInvoiceEditPermission) {
                let eventProperties = {
                  source: "AR_BILLING",
                  loadNo: `${loads?.reference_number}`
                }
                amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_CHANGE_BILLING_NOTES" : "AR_SUB_BILLING_CHANGE_BILLING_NOTES"}`,eventProperties)
              }
            }}
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between border-1 border-gray-100 p-10 rounded-3 flex-wrap gap-5">
        <div className={`d-flex align-items-center flex-wrap gap-5`}>
        {
          (loads?.createSource === 'BROKER-TENDER' && enableNewCustomerApprovalStatus()) && (
            <>
              <button className="btn btn-primary" 
                disabled={!billingInvoiceEditPermission || isApiCall || !canRequestConfirmation || !(billingInvoicePermission && billingApprovedPermission) } 
                onClick={(e) => {
                  e.preventDefault()
                  handleRateConfirmation();
                }}>
                Request Confirmation
              </button>
              <div className="vr-line mx-1"></div>
            </>
          )
        }
        <button className="btn btn-primary" disabled={isUnApproved || !billingInvoiceEditPermission || isApiCall} 
            onClick={(e) => {
              e.preventDefault()
              if(billingInvoiceEditPermission && !isApiCall){
                handleUnApprovedClick()
              }
            }}>
            Unapprove
        </button>
         <button className="btn btn-primary" disabled={isApproved || !billingApprovedPermission || isApiCall} 
            onClick={(e) => {
              e.preventDefault()
              if (billingApprovedPermission && !isApiCall) {
                handleApprovedClick()
              }
            }}>
            Approve
          </button>
        <button className="btn btn-primary p-0" disabled={isApprovedAndInvoiced || !(billingInvoicePermission && billingApprovedPermission) || isApiCall}>
          <div className="px-10" data-tip data-for= {`invoice-approve-today-${billingRow?._id}`} 
              onClick={(e) => {
                e.preventDefault()
                if (billingInvoicePermission && billingApprovedPermission && !isApiCall) {
                  handleApproveAndInvoiceClick(true)
                }
              }}>
            Approve & Invoice  </div> 
          {!isApprovedAndInvoiced && <ReactTooltip id={`invoice-approve-today-${billingRow?._id}`} offset={{ top: 10 }}  place="top" effect="solid" className="react-tooltip-new mb-2">
              Use Current Date
           </ReactTooltip>
          }
          <div className={`py_5 px-10 ${isApprovedAndInvoiced ? "cale-hov-btn-disabled opacity-05" : "cale-hov-btn"} border-left-1 border-gray-600`} data-tip data-for={`invoice-approve-pickdate-${billingRow?._id}`} 
              onClick={(e) => {
                e.preventDefault()
                if (!isApiCall) {
                  handleApproveAndInvoiceClick()
                }
              }} 
          > <IconCalendar /></div>
          {!isApprovedAndInvoiced &&<ReactTooltip id={`invoice-approve-pickdate-${billingRow?._id}`} offset={{ top: 5 }}  place="top" effect="solid" className="react-tooltip-new mb-2">
              Use Manual Date
           </ReactTooltip>
          }
          </button>
        <button disabled={isInvoiced || !billingInvoicePermission || isApiCall}  className="btn btn-primary p-0">
           <div className="px-10" data-tip data-for={`invoice-today-${billingRow?._id}`} 
              onClick={(e) => {
                e.preventDefault()
                if (billingInvoicePermission && !isApiCall) {
                  handleCreateInvoiceClick(true)
                }
              }}
           >
            Invoice</div> 
          {!isInvoiced && <ReactTooltip id={`invoice-today-${billingRow?._id}`}place="top" offset={{ top: 10 }}  effect="solid" className="react-tooltip-new">
              Use Current Date
           </ReactTooltip>
           } 
           <div  data-tip data-for={`invoice-pickdate-${billingRow?._id}`}  
              onClick={(e) => {
                e.preventDefault()
                if (!isApiCall) {
                  handleCreateInvoiceClick()
                }
              }} 
           className={`py_5 px-10 ${isInvoiced ? "cale-hov-btn-disabled opacity-05" : "cale-hov-btn"} border-left-1 border-gray-600` }> <IconCalendar /></div>
           {!isInvoiced && <ReactTooltip id={`invoice-pickdate-${billingRow?._id}`} offset={{ top: 5 }}  place="top" effect="solid" className="react-tooltip-new mb-2">
              Use Manual Date
           </ReactTooltip>
           }
          </button>
          
        {basicSettings?.billingConfiguration?.config?.isAllowRebillingInvoice && <button 
            onClick={(e) => {
              e.preventDefault()
              if (billingInvoiceEditPermission && !isApiCall) {
                handleReBillClick()
              }
            }} 
        disabled={isReBill || !billingInvoiceEditPermission || isApiCall || (billingRow?.invoice?.invoiceId?.isCustomerBillPaymentInitiated && enableNewCustomerApprovalStatus())} className="btn btn-danger" >
            Rebill
          </button>}
          
        {billingRow?.invoice?.invoiceId?.isCustomerBillPaymentInitiated && enableNewCustomerApprovalStatus() && (
          <>
            <span data-tip data-for="rebill-disabled">
              <IconInfoCircleFilledWhite className="ml-1" />
            </span>
            <ReactTooltip id="rebill-disabled" type="dark" effect="solid" className="w-350">
              {REBILL_TOOLTIP}
            </ReactTooltip>
          </>
        )}
        </div>

        <div className="vr-line"></div>
        <div className="d-flex gap-10">
          <div className={`${billingRow?.invoice?.invoiceId?.invoiceNumber && 'pointer'}`}>
            <div className={`text-muted`}>Invoice #</div>
            <InvoiceHoveredContent
              invoiceNumber={billingRow?.invoice?.invoiceId?.invoiceNumber}
              chargeId={billingRow?._id}
              invoiceId={billingRow?.invoice?.invoiceId?._id}
              invoicedData={invoicedData}
              setInvoicedData={setInvoicedData}
              billingRow={billingRow}
              isInvoiceModel={true}
              setBillingCards={setBillingCards}
            />
          </div>
          <div className={``}>
            <div className="text-muted">Invoice Date</div>
            <div className="font-medium">{billingRow?.invoice?.invoiceId?.invoiceDate ? moment(billingRow?.invoice?.invoiceId?.invoiceDate).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : "-"}</div>
          </div>

        {!hasPaymentTermsPermission && <ReactTooltip id='payment-terms'><span className="text-capitalize">You do not have permission to change Payment Terms</span></ReactTooltip>}
          <div data-tip data-for="payment-terms" className={``}>
            <input type="number" value={payment} className="form-control" disabled={loads.isDeleted || !billingInvoiceEditPermission || isPaymentDisable || !hasPaymentTermsPermission} placeholder="Payment Terms..." onChange={(e) => {setPayment(e.target.value)}} onBlur={handleBlurEvent}/>
          </div>
        </div>

      </div>
        <Modal dialogClassName="modal-dialog-centered modal-sm w-350" backdropClassName="z-1050" show={invoiceModal?.isOpen} centered animation={false}>
          <Modal.Header className="pb-10">
            <Modal.Title>Create Invoice <div className="text-muted font-14 font-weight-normal">{billingRow?.billToId?.company_name}</div></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-wrapper">
              <div className="input-wrapper">
                <DateWrapper
                  dateFormat={DateTimeFormatUtils.fullDateFormat()}
                  displayTimeZone={userTimeZone}
                  onChange={(e) =>{ setInvoiceModal({
                    ...invoiceModal,
                    inVoiceSelectedDate: basicSettings?.isUserSpecificTimeZoneEnabled ? convertDateWithTimezoneToEquivalentUTC(moment(e).startOf('day'), userTimeZone) : moment(e).toISOString(),
                  })
                  setSubmissionDate(true)
                  }}
                  defaultDate={invoiceModal?.inVoiceSelectedDate || moment().toString()}
                  dontShowSelectDate={true}
                  isShowTime={false}
                  hideShowSelectDate={true}
                  className="right-0"
                  placeholder="Select Date..."
                />
                <div
                  className="input-icon"
                >
                  <IconCalendar />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-close"
              onClick={() => {
                setInvoiceModal({ isOpen: false });
                setIsApiCall(false);
              }}
            >
              Cancel
            </button>


            <button
              // disabled={
              //   this.state.isEmailSendInProcessNew ||
              //   this.state.extraEmail.length === 0
              // }
              className="btn btn-primary"
              disabled={spinner}
            onClick={handleConfirmCreateInvoice}
            >
              {spinner && <Spinner size="sm" />}
              Confirm
            </button>

          </Modal.Footer>
        </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getBillingChargeLists }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(ChargesBottomAction));