import React, { useCallback, useContext, useMemo } from "react";
import { createRoot } from "react-dom/client";
import {
  IconDotSeperator,
  IconMailDrag,
  IconAttachment,
  IconDot,
  IconStar,
  IconStarOutline,
} from "Components/Common/Icons";
import SafeTextDecode from "Components/Common/SafeContent/SafeTextDecode";
import { EMAIL_CONTEXT, EMAIL_TABS, OUTLOOK_PROVIDER, statusLabels } from "../../constant";
import { formatDate, formateParticipants } from "../../helper";
import EmailTime from "../EmailTime/EmailTime";
import EachEmailTag from "../EmailTags/EachEmailTag";
import { getStorage } from "services";

const DraggableWrapper = React.memo(({ activePage, children, row, isReadingMode, allEmailStatus, activeNav, allTags, isFromLoad, isFromQuote }) => {
  const { selectedRows } = useContext(EMAIL_CONTEXT);
  const isSingleElement = useMemo(() => !selectedRows?.length || selectedRows.length === 1 || !selectedRows.some((sl) => sl.id === row.id), [selectedRows, row.id]);
  const moveItems = useMemo(() => isSingleElement ? [row.id] : selectedRows.map((sel) => sel.id), [isSingleElement, row.id, selectedRows]);
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {};

  const isNotDraggable = activePage==="Drafts" && OUTLOOK_PROVIDER.includes(provider)
  const onDragStart = useCallback((e) => {
    e.stopPropagation();
    if(isNotDraggable) return
    e.dataTransfer.setData("application/json", JSON.stringify(moveItems));
    e.dataTransfer.setData("grantId", row.grantId);
    const addClassFunc = isReadingMode ? addAndRemoveClassForCard : addAndRemoveClassForTable;
    moveItems.forEach((item) => addClassFunc(true, `drag-${item}`));

    const dragImage = createDragImage(row, selectedRows, isReadingMode, allEmailStatus, activeNav, allTags, isSingleElement);
    const escInfo = escInformationBar();
    e.dataTransfer.setDragImage(dragImage, 0, 0);
    setTimeout(() => {
      dragImage.parentNode?.removeChild(dragImage)
      dragImage.parentNode?.removeChild(escInfo)
    }, 0);
  }, [row, selectedRows, isReadingMode, allEmailStatus, activeNav, allTags, moveItems]);

  const onDragEnd = useCallback((e) => {
    e.stopPropagation();
    const element = document.querySelector("#draggable-email-element");
    const escInfo = document.querySelector("#esc-info-bar");
    const removeClassFunc = isReadingMode ? addAndRemoveClassForCard : addAndRemoveClassForTable;
    removeClassFunc();
    element?.remove();
    escInfo?.remove();
  }, [isReadingMode]);

  const addAndRemoveClassForTable = (isAdd = false, className) => {
    if (isAdd) {
      const escapedClass = className.replace(/([^\w-])/g, '\\$1');
      const rowElement = document.querySelector(`.${escapedClass}`);
      rowElement?.classList.add('draggable-row');
      rowElement?.querySelectorAll('.rdg-cell').forEach(cell => cell.classList.add('opacity-03'));
    } else {
      document.querySelectorAll('.draggable-row').forEach(rowElement => {
        rowElement.classList.remove('draggable-row');
        rowElement.querySelectorAll('.rdg-cell').forEach(cell => cell.classList.remove('opacity-03'));
      });
    }
  };

  const addAndRemoveClassForCard = (isAdd = false, id) => {
    if (isAdd) {
      const escapedClass = id.replace(/([^\w-])/g, '\\$1');
      const el = document.querySelector(`#${escapedClass}`);
      el?.classList.remove("hover-bg-gray-25");
      el?.classList.add('draggable-row', 'opacity-03');
    } else {
      document.querySelectorAll('.draggable-row').forEach(el => {
        el.classList.remove('draggable-row', 'opacity-03');
        el.classList.add("hover-bg-gray-25");
      });
    }
  };

  return (
    <>
      {isFromLoad || isFromQuote || isNotDraggable ? (
        <div className="w-100">{children}</div>
      ) : (
        <div draggable onDragStart={onDragStart} onDragEnd={onDragEnd} className="w-100">
          {children}
        </div>
      )}
    </>
  );
});

const createDragImage = (row, selectedRows, isReadingMode, allEmailStatus, activeNav, allTags, isSingleElement) => {
  const container = document.createElement("div");
  container.id = "draggable-email-element";
  container.style.position = "absolute";
  container.style.top = "-1000px";
  container.style.bottom = "1000px";
  container.style.left = "-1000px";
  container.style.width = "100%";
  // container.style.padding = "30px";
  document.body.appendChild(container);

  const root = createRoot(container);
  root.render(
    <div className="shadow-5 p-2 text-dark gap-5 bg-white d-flex align-items-center rounded-5 ml-10" style={{ maxWidth: isReadingMode && isSingleElement ? "300px" : "222px" }}>
      <PopupItem row={row} selectedRows={selectedRows} isReadingMode={isReadingMode} allEmailStatus={allEmailStatus} activeNav={activeNav} allTags={allTags} />
    </div>
  );

  return container;
};

const PopupItem = React.memo(({ row, selectedRows, isReadingMode, allEmailStatus, activeNav, allTags }) => {
  const shouldRenderSingleItem = !selectedRows?.length || selectedRows.length === 1 || !selectedRows.some((sl) => sl.id === row.id);

  if (shouldRenderSingleItem) {
    return isReadingMode ? (
      <ReadingModeDragItem row={row} allEmailStatus={allEmailStatus} activeNav={activeNav} allTags={allTags} />
    ) : (
      <NonReadingModeDragItem row={row} />
    );
  }

  return (
    <>
      <IconMailDrag className="text-primary" />
      <span>Move {selectedRows?.length} conversations</span>
    </>
  );
});

const NonReadingModeDragItem = React.memo(({ row }) => (
  <>
    <IconMailDrag className="text-primary" />
    <div className="text-muted rdg-truncate flex-grow-1 flex-basis-0">
      <div className={`d-flex gap-10 align-items-center ${row?.snippet ? "" : "justify-content-between"}`}>
        <div className="text-truncate flex-basis-auto">
          <span className="text-truncate font-12 font-weight-500 text-dark">
            {row?.subject?.length ? (row?.subject.length > 10 ? row?.subject.substring(0, 10) : row?.subject) : "(No Subject)"}
          </span>
        </div>
        {row?.snippet && (
          <>
            <IconDotSeperator className="flex-shrink-0 text-muted" />
            <span className="text-muted rdg-truncate flex-grow-1 flex-basis-0 font-12 font-weight-normal">
              <SafeTextDecode text={row?.snippet} />
            </span>
          </>
        )}
      </div>
    </div>
  </>
));

const ReadingModeDragItem = React.memo(({ row, allEmailStatus, activeNav, allTags }) => {
  const isSentTab = EMAIL_TABS.SENT === activeNav;
  const isDraft = activeNav === "Drafts" || row?.object === "draft";
  const label = row.status || "OPEN";
  const status = statusLabels.find((s) => s.value === label);

  return (
    <div className="rounded-5 pointer bg-white hover-bg-gray-25 w-100">
      <div className="d-flex align-items-start justify-content-between mb-2 gap-10">
        <div className="font-medium d-flex align-items-start text-truncate">
          {!isDraft && <IconDot className={`flex-shrink-0 ${row?.unread ? "text-primary" : "text-defaultbrand-50"}`} />}
          <div className="text-truncate">
            <div className="d-flex align-items-center gap-3 mb_2">
              <div className={`text-truncate font-12 ${row?.unread ? "font-weight-bold" : "font-weight-500"} text-dark text-capitalize`}>
                {isDraft ? "Draft" : formateParticipants(row, isSentTab)}
              </div>
              {row?.messageIds?.length > 1 && <div className="text-muted">{row?.messageIds?.length}</div>}
            </div>
            {allEmailStatus.length > 0 && status && <span className={`badge badge-sm ${status.badgeClass}`}>{status.label}</span>}
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 flex-shrink-0">
          <span className="text-gray-500">
            {formatDate(row?.date || row?.latestMessageReceivedDate || row?.latestMessageSentDate || row?.earliestMessageDate)}
          </span>
          {!isDraft && (row?.starred ? <IconStar className="text-primary" /> : <IconStarOutline className="text-muted" />)}
        </div>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className={`font-medium clamp-1 text-dark font-12 ${row?.unread ? "font-weight-bold" : "font-weight-500"}`}>
          {row?.subject || "No Subject"}
        </div>
        {row?.snippet && (
          <div className="d-flex">
            <div className="text-muted clamp-2 text-muted font-12 font-weight-normal">
              <SafeTextDecode text={row.snippet} />
            </div>
          </div>
        )}
      </div>
      {row && (
        <div className="d-flex align-items-center justify-content-between mt-1 gap-5 maybe-empty">
          {<EachEmailTag row={row} isEmailMode="card" allTags={allTags} />}
          {row && (
            <div className="d-flex align-items-center gap-5 ml-auto">
              {row?.hasAttachments && <IconAttachment className="flex-shrink-0 text-gray-300 wh-16px" />}
              {row?.timeElapsedLow && row?.timeElapsedMedium && row?.timeElapsedHigh && <EmailTime row={row} />}
            </div>
          )}
        </div>
      )}
    </div>
  );
});

const escInformationBar = () => {
  const container = document.createElement("div")
  container.id = "esc-info-bar";
  container.style.position = "absolute";
  container.style.top = '12px';
  container.style.left= '50%';
  container.style.transform = 'translateX(-50%)';
  container.style['z-index'] = 9999;
  container.style.display = 'flex';
  container.style['justify-content'] = 'center';
  document.body.appendChild(container);

  const root = createRoot(container);
  root.render(
    <div className="shadow-5 text-white bg-gray-700 p-2 d-flex align-items-center rounded-5 w-200">
      Press “<span className="font-bold">ESC</span>” button to cancel...
    </div>
  );

  return container;
}
export default DraggableWrapper;
