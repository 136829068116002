import React, { useState, useRef } from "react";
import {
    IconAngleArrowDown,
    IconAngleArrowRight,
} from "Components/Common/Icons";
import { EMAIL_PROVIDER } from "pages/tms/Email/constant"
import FolderTree from "./FolderTree";
import { Icondots, IconDrag, IconPlus } from "../../../../../../Components/Common/Icons";
import AddLabelModal from "../LabelModals/AddLabelModal";
import { createPortal } from 'react-dom';
import { useDropDownPosition } from 'hooks/helpers/useDropDownPosition';
import { useOnClickOutside, useOnEscKey } from 'hooks';
import { addFolderToStructure } from "../../../helper";

const SingleEmail = React.memo(({
    accountId,
    name,
    email,
    folders,
    isCollapsed,
    onToggleCollapse,
    dragTargetId,
    onDragStart,
    onDragOver,
    onDragLeave,
    onDrop,
    provider
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCreateLabelModal, setShowCreateLabelModal] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const iconRef = useRef(null);
    const dropdownRef = useRef(null);
    const addLabelModalRef = useRef(null);

    useOnClickOutside([dropdownRef], () => {
        setShowDropdown(false);
    });

    useOnEscKey(() => {
        if (dropdownRef.current) {
            setShowDropdown(false);
        }
    });

    const handleDragStart = (e) => {
        setIsDragging(true);
        onDragStart(e, accountId);
    };

    const handleDragEnd = () => {
        setIsDragging(false);
        setIsHovered(false);
    };

    const handleIconClick = (e) => {
        e.stopPropagation();
        setShowDropdown(!showDropdown);
    };

    const handleAddFolder = (e) => {
        e.stopPropagation();
        setShowDropdown(false);
        setShowCreateLabelModal(true);
    };

    const handleColorCheckboxChange = (index) => {
        setSelectedColor(index);
    };

    const handleCreateLabelModel = (value) => {
        setShowCreateLabelModal(value);
    };

    let setNestedFolders = () => { }

    const dragstyle = { top: "10px", left: "-13px" };

    const Dropdown = () => {
        const style = useDropDownPosition("auto", dropdownRef, iconRef);

        return createPortal(
            <div
                className="w-200 bg-white rounded-5 position-fixed z-1051 overflow-auto shadow-5 d-flex flex-column gap-10"
                ref={dropdownRef}
                style={{ ...style, position: 'fixed' }}
                onClick={(e) => e.stopPropagation()}
            >
                <ul className="p-1 mb-0">
                    <li className="d-flex align-items-center popdrop__dropdown-item pointer gap-7 p-10 rounded-0"
                        onClick={handleAddFolder}
                    >
                        <IconPlus className="text-muted" /> Add {provider === EMAIL_PROVIDER.GMAIL ? "Label" : "Folder"}
                    </li>
                </ul>
            </div>,
            document.getElementById("pp-overlay-container")
        );
    };

    const handleOnAddFolder = (newFolder, parentFolderId) => {
        const inboxFolder = folders.find(folder => folder.name?.toLowerCase() === "inbox" || folder.name?.toLowerCase() === "sent items");
        if(parentFolderId === inboxFolder.parentId){
            parentFolderId = null;
        }
        const updatedFolders = addFolderToStructure(folders, newFolder, parentFolderId);
        setNestedFolders(updatedFolders);
    }


    return (
        <div
            className={`d-flex flex-column mb_4 position-relative ${dragTargetId === accountId && !isDragging ? 'bg-gray-15' : ''}`}
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => onDragOver(e, accountId)}
            onDragLeave={onDragLeave}
            onDrop={(e) => onDrop(e, accountId)}
        >
            {!isDragging && isHovered && <div className="position-absolute" style={dragstyle}><IconDrag className="text-gray-200" /></div>}
            <div
                className={`d-flex align-items-center justify-content-between h-40px pointer gap-5 rounded-3 email-header ${isHovered && !isDragging ? "bg-light-30" : ""} ${isDragging ? 'opacity-03' : 'text-muted'}`}
                onClick={() => onToggleCollapse(accountId)}
                onMouseEnter={() => !showDropdown && setIsHovered(true)}
                onMouseLeave={() => !showDropdown && setIsHovered(false)}
            >
                <div className="d-flex align-items-center gap-5">
                    <div className="w-20px">{isCollapsed ? <IconAngleArrowRight /> : <IconAngleArrowDown />}</div>
                    <div className="d-flex flex-column gap-1">
                        <span className="">{name}</span>
                        <span className="font-10 line-height-12">{email}</span>
                    </div>
                </div>
                <div
                    ref={iconRef}
                    className="icon-container"
                    onClick={handleIconClick}
                >
                    {isHovered && <Icondots className="text-gray-300 hover:text-gray-900 pointer" />}
                </div>
            </div>
            <div className="d-flex flex-column gap-1">
                <FolderTree nestedFolders={folders} provider={provider} isCollapsed={isCollapsed} setNestedFolders={(f) => {
                    setNestedFolders = f
                }}/>
            </div>
            {showDropdown && <Dropdown />}
            {showCreateLabelModal && (
                <AddLabelModal
                    show={showCreateLabelModal}
                    modalRef={addLabelModalRef}
                    selectedColor={selectedColor}
                    handleColorCheckboxChange={handleColorCheckboxChange}
                    handleCreateLabelModel={handleCreateLabelModel}
                    folder={{ grantId: accountId, name }}
                    onAddFolder={handleOnAddFolder}
                />
            )}
        </div>
    );
});

export default SingleEmail;