import React from "react";
import { Modal } from "react-bootstrap";
import { IconWarning } from "../../../../Components/Common/Icons";

const RatesPromptAlert = ({ showPrompt, closePrompt, content, confirmDelete }) => {
  return (
    <Modal show={showPrompt} onHide={closePrompt} dialogClassName="modal-sm modal-dialog-centered">
      <Modal.Body className="text-center pt-35">
        <IconWarning className={"mb-15 w-54 h-54 text-warning"} />
        <div className="font-20 font-weight-500 line-height-25 mb-15 text-capitalize">{content}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={confirmDelete}>
          Delete
        </button>
        <button className="btn btn-outline-dark" onClick={closePrompt}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RatesPromptAlert;