import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { createPortal } from "react-dom";
import useOnKeyDown from "../../CustomHooks/useOnKeyDown";
import DateTimePicker from "../CustomDatePicker/DateTimePicker";
import useBoxPosition from "../../../../../../Components/CustomHooks/useBoxPosition";
import { editLoad } from "../../../../../tms/Load/actionCreator";
import _ from "lodash";
import { amplitudeTrack, getStorage, toastr } from "../../../../../../services/Common.services";
import { removeValueLoad } from "../../../../services";
import { successColorResponse , errorColorResponse, getWeekCount } from "../../../utility";
import AppointmentDateWrapper from "../CustomDatePicker/AppointmentDateWrapper";
import { useCurrentUserSettings } from "../../../../../../hooks";
import { useDispatch } from "react-redux";
import { SYNC_REQUEST_SENT } from "pages/tms/Load/utility";
import { SCHEDULE_APPT_COL_KEYS } from "pages/tms/Scheduling/constants";
import { getEventDetails } from "pages/tms/Scheduling/helper";


const DateEditor = ({ CellRef, onClose, row, column, onRowChange, openAppointmentModal, isPortAccepted, appointmentId, autoAppointmentId, apptStatus, openManualBooking , moveType, standardPorts, isAppointmentProcessing, isAllowedForDirectBooking }) => {
	const timeZone = getStorage('timeZone');
	const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};
	const dispatch = useDispatch();

	const [date, setDate] = useState(null)
	const field = Fields[column.key];
	const cell = document.querySelector('[aria-selected="true"]');

	useEffect(() => {
		window.document.getElementById('root').style.pointerEvents = "none"
		return () => {
			window.document.getElementById('root').style.pointerEvents = "auto"
		}
	}, []);

	useEffect(() => {
		const keydown = (e) => {
			if (e.keyCode === 13) OnBlurUpdate();
		}
		document.addEventListener('keydown', keydown);
		return () => {
			document.removeEventListener('keydown', keydown);
		}
	}, [date]);

	const StateValUpdate = () => {
        if (column.key === "pickupTimes" || column.key === SCHEDULE_APPT_COL_KEYS?.PICKUP_APPT) {
            return {
                val:
                    row.pickupTimes && row.pickupTimes?.[0]?.pickupFromTime
                        ? moment(row.pickupTimes?.[0]?.pickupFromTime)
                              .tz(timeZone)
                              .format("lll")
                        : null,
                dateWithTime: true,
            };
        }
        if (column.key === "apt" || column.key === SCHEDULE_APPT_COL_KEYS?.DELIVER_APPT) {
            return {
                val:
                    row.deliveryTimes && row.deliveryTimes[0]?.deliveryFromTime
                        ? moment(row.deliveryTimes[0]?.deliveryFromTime)
                              .tz(timeZone)
                              .format("lll")
                        : null,
                dateWithTime: true,
            };
        }
        if (column.key === "lfd") {
            if (row.type_of_load === "IMPORT") {
                return {
                    val: row.lastFreeDay
                        ? moment(row.lastFreeDay).tz(timeZone).format("lll")
                        : null,
                    dateWithTime: false,
                };
            }
            if (row.type_of_load === "EXPORT") {
                return {
                    val: row.containerAvailableDay
                        ? moment(row.containerAvailableDay)
                              .tz(timeZone)
                              .format("lll")
                        : null,
                    dateWithTime: false,
                };
            }
        }
        if (column.key === "eta") {
            return {
                val:
                    row.vessel && row.vessel.eta
                        ? moment(row.vessel.eta).tz(timeZone).format("lll")
                        : null,
                dateWithTime: true,
            };
        }
        if (column.key === "cut") {
            if (row.type_of_load === "EXPORT") {
                return {
                    val: row.cutOff
                        ? moment(row.cutOff).tz(timeZone).format("lll")
                        : null,
                    dateWithTime: true,
                };
            }
        }
		if(column.key === "freeReturnDate"){
			if (["IMPORT","EXPORT"].includes(row.type_of_load)) {
				return {
                    val: row.freeReturnDate
                        ? moment(row.freeReturnDate).tz(timeZone).format("lll")
                        : null,
                    dateWithTime: false,
                };
            }
		}
        if (column.key === "returnFromTime" || column.key === SCHEDULE_APPT_COL_KEYS.RETURN_APPT) {
            return {
                val: row.returnFromTime
                    ? moment(row.returnFromTime).tz(timeZone).format("lll")
                    : null,
                dateWithTime: true,
            };
        }
				if (column.key === "returnToTime" || column.key === SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO) {
					return {
							val: row.returnToTime
									? moment(row.returnToTime).tz(timeZone).format("lll")
									: null,
							dateWithTime: true,
					};
				}
				if (column.key === "readyToReturnDate") {
					return {
							val: row?.readyToReturnDate
									? moment(row?.readyToReturnDate).tz(timeZone).format("lll")
									: null,
							dateWithTime: true,
					};
				}
				if (column.key === "pickupToTime" || column.key === SCHEDULE_APPT_COL_KEYS?.PICKUP_APPT_TO) {
					return {
						val:
								row.pickupTimes && row?.pickupTimes[0]?.pickupToTime
										? moment(row?.pickupTimes[0]?.pickupToTime)
													.tz(timeZone)
													.format("lll")
										: null,
						dateWithTime: true,
					};
				}
				if (column.key === "deliveryToTime" || column.key === SCHEDULE_APPT_COL_KEYS?.DELIVER_APPT_TO) {
					return {
						val:
								row.deliveryTimes && row?.deliveryTimes[0]?.deliveryToTime
										? moment(row?.deliveryTimes[0]?.deliveryToTime)
													.tz(timeZone)
													.format("lll")
										: null,
						dateWithTime: true,
					};
				}
				if (column.key === "destinationETA") {
					return {
						val: row.destinationETA
							? moment(row.destinationETA).tz(timeZone).format("lll")
							: null,
						dateWithTime: true,
					};
				}
				if([SCHEDULE_APPT_COL_KEYS.PICKUP_APPT, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT, SCHEDULE_APPT_COL_KEYS.RETURN_APPT].includes(column.key)){
					const {fromDate, toDate} = getEventDetails({key: column.key, row}) || {};
					return {
						val: fromDate
							? moment(fromDate).tz(timeZone).format("lll")
							: null,
						dateWithTime: true,
					};
				}
				if ([SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO, SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO].includes(column.key)) {
					const {fromDate, toDate} = getEventDetails({key: column.key, row}) || {};
					return {
						val: toDate
							? moment(toDate).tz(timeZone).format("lll")
							: null,
						dateWithTime: true,
					};
				}
        return { val: null, dateWithTime: false };
    };

	const { val, dateWithTime } = StateValUpdate();
	const boxRef = useRef();
	const Style = useBoxPosition(CellRef, boxRef, getWeekCount(val) > 5 ? 400 : 360);

	const OnBlurUpdate = () => {
		if (date) {
			let value = getEventDetails({ key: column.key, row });
			if (field === "pickupTimes" || field === SCHEDULE_APPT_COL_KEYS?.PICKUP_APPT) {
				const pickupTimes = _.cloneDeep(row.pickupTimes);
				pickupTimes?.forEach((pTime, i) => {
					if (i === 0) {
						pTime.pickupFromTime = date;
						pTime.pickupToTime = date;
					}
				})
				const payload = {
					pickupTimes: pickupTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, pickupTimes: row.pickupTimes ?? '' });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row.pickupTimes ?? "", field: "pickupTimes"});
                    }else{
						onRowChange({ ...row, pickupTimes: pickupTimes });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				  });;
			} else if(field === "apt" || field === SCHEDULE_APPT_COL_KEYS?.DELIVER_APPT) {
				const deliveryTimes = _.cloneDeep(row.deliveryTimes);
				deliveryTimes.forEach((dTime, i) => {
					if (i === 0) {
						dTime.deliveryFromTime = date;
						dTime.deliveryToTime = date;
					}
				})
				const payload = {
					deliveryTimes: deliveryTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, deliveryTimes: row.deliveryTimes ?? '' });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row.deliveryTimes ?? "", field: "deliveryTimes"});
                    }else{
						onRowChange({ ...row, deliveryTimes: deliveryTimes });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				  });;
			} else if (field === "deliveryToTime" || field === SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO) {
				const deliveryTimes = _.cloneDeep(row.deliveryTimes);
				deliveryTimes.forEach((dTime, i) => {
					if (i === 0) {
						dTime.deliveryToTime = date;
					}
				})
				const payload = {
					deliveryTimes: deliveryTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, deliveryTimes: row.deliveryTimes ?? '' });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row.deliveryTimes ?? "", field: "deliveryTimes"});
                    }else{
						onRowChange({ ...row, deliveryTimes: deliveryTimes });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			} else if (field === "pickupToTime" || field === SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO) {
				const pickupTimes = _.cloneDeep(row.pickupTimes);
				pickupTimes?.forEach((pTime, i) => {
					if (i === 0) {
						pTime.pickupToTime = date;
					}
				})
				const payload = {
					pickupTimes: pickupTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, pickupTimes: row.pickupTimes ?? '' });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row.pickupTimes ?? "", field: "pickupTimes"});
                    }else{
						onRowChange({ ...row, pickupTimes: pickupTimes });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}	else if (field === "returnToTime" || field === SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO) {
				const payload = {
					reference_number: row.reference_number,
					returnToTime: date,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
                        toastr.show(SYNC_REQUEST_SENT, "success");
                        onRowChange({ ...row, returnToTime: row.returnToTime ?? '' });
                        dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row.returnToTime ?? "", field: "returnToTime"});
                    }else{
						onRowChange({ ...row, returnToTime: date });
					}
					onRowChange({ ...row, returnToTime: date });
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			} else if(field === "lfd") {
				const payload = {
					reference_number: row.reference_number,
				};
				let eventProperties = {
					source: 'dispatcher_table'
				}
				amplitudeTrack("EDIT_LFD",eventProperties)
				if (row.type_of_load === "IMPORT") {
					payload.lastFreeDay = date
					editLoad(payload).then((el) => {
						if(el === SYNC_REQUEST_SENT){
							toastr.show(SYNC_REQUEST_SENT, "success");
							onRowChange({ ...row, lastFreeDay: row?.lastFreeDay ?? '' });
							dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.lastFreeDay ?? "", field: "lastFreeDay"});
						}else{
							onRowChange({ ...row, lastFreeDay: date });
						}
						onClose(false);
						successColorResponse(cell, column.idx, row?.className);
					}).catch((err) => {
						onClose(false);
						errorColorResponse(cell, column.idx, row?.className);
					  });;
				}
				if (row.type_of_load === "EXPORT") {
					payload.containerAvailableDay = date
					editLoad(payload).then((el) => {
						if(el === SYNC_REQUEST_SENT){
							toastr.show(SYNC_REQUEST_SENT, "success");
							onRowChange({ ...row, containerAvailableDay: row?.containerAvailableDay ?? '' });
							dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.containerAvailableDay ?? "", field: "containerAvailableDay"});
						}else{
							onRowChange({ ...row, containerAvailableDay: date });
						}
						onClose(false);
						successColorResponse(cell, column.idx, row?.className);
					}).catch((err) => {
						onClose(false);
						errorColorResponse(cell, column.idx, row?.className);
					  });;
				}
			} else if(field === "vessel.eta") {
				const payload = {
					vessel: date,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
						toastr.show(SYNC_REQUEST_SENT, "success");
						onRowChange({ ...row, vessel: row?.vessel ?? '' });
						dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.vessel ?? "", field: "vessel"});
					}else{
						onRowChange({ ...row, vessel: date });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				  });;
			} else if(field === "freeReturnDate"){
				const payload = {
					reference_number: row.reference_number,
				};
				if (["IMPORT","EXPORT"].includes(row.type_of_load)) {
					payload.freeReturnDate = date
					editLoad(payload).then((el) => {
						if(el === SYNC_REQUEST_SENT){
							toastr.show(SYNC_REQUEST_SENT, "success");
							onRowChange({ ...row, freeReturnDate: row?.freeReturnDate ?? '' });
							dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.freeReturnDate ?? "", field: "freeReturnDate"});
						}else{
							onRowChange({ ...row, freeReturnDate: date });
						}
						onClose(false);
						successColorResponse(cell,column.idx, row?.className);
					}).catch((err) => {
						onClose(false);
						errorColorResponse(cell,column.idx, row?.className)
					  });;
				}
			} else if(field === "cut") {
				const payload = {
					reference_number: row.reference_number,
				};
				
				if (row.type_of_load === "EXPORT") {
					payload.cutOff = date
					editLoad(payload).then((el) => {
						if(el === SYNC_REQUEST_SENT){
							toastr.show(SYNC_REQUEST_SENT, "success");
							onRowChange({ ...row, cutOff: row?.cutOff ?? '' });
							dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.cutOff ?? "", field: "cutOff"});
						}else{
							onRowChange({ ...row, cutOff: date });
						}
						onClose(false);
						successColorResponse(cell, column.idx, row?.className);
					}).catch((err) => {
						onClose(false);
						errorColorResponse(cell, column.idx, row?.className);
					  });;
				}
			} else if (field === "returnFromTime" || field === SCHEDULE_APPT_COL_KEYS.RETURN_APPT) {
				const payload = {
					reference_number: row?.reference_number || row?.referenceNumber,
					returnFromTime: date,
					returnToTime: date,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
						toastr.show(SYNC_REQUEST_SENT, "success");
						onRowChange({ ...row, returnToTime: row?.returnToTime ?? '', returnFromTime: row?.returnFromTime ?? ''});
						dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.returnToTime ?? "", field: "returnToTime"});
						dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.returnFromTime ?? "", field: "returnFromTime"});
					}else{
						onRowChange({ ...row, returnFromTime: date, returnToTime: date });
					}
					onRowChange({ ...row, returnFromTime: date, returnToTime: date });
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				  });;
			} else if (field === "readyToReturnDate") {
				const payload = {
					reference_number: row.reference_number,
					readyToReturnDate: date,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
						toastr.show(SYNC_REQUEST_SENT, "success");
						onRowChange({ ...row, readyToReturnDate: row?.readyToReturnDate ?? '' });
						dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.readyToReturnDate ?? "", field: "readyToReturnDate"});
					}else{
						onRowChange({ ...row, readyToReturnDate: date });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			} else if (field === "destinationETA") {
				const payload = {
					reference_number: row.reference_number,
					destinationETA: date,
				};
				editLoad(payload).then((el) => {
					if(el === SYNC_REQUEST_SENT){
						toastr.show(SYNC_REQUEST_SENT, "success");
						onRowChange({ ...row, destinationETA: row?.destinationETA ?? '' });
						dispatch({ type: 'RESET_ANYKEY', reference_number: row?.reference_number, value: row?.destinationETA ?? "", field: "destinationETA"});
					}else{
						onRowChange({ ...row, destinationETA: date });
					}
					onClose(false);
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			} else if([SCHEDULE_APPT_COL_KEYS.PICKUP_APPT, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT, SCHEDULE_APPT_COL_KEYS.RETURN_APPT].includes(field)){
				const pickupTimes = {
					pickupFromTime: date,
					pickupToTime: date ? date : value.toDate,
				}
				const returnFroms = {
					returnFromTime: date,
					returnToTime: date ? date : value.toDate,
				}
				const deliveryTimes = {
					deliveryFromTime: date,
					deliveryToTime: date ? date : value.toDate,
				}
				
				const payload = {
					reference_number: row.referenceNumber || row?.reference_number,
				};

				if (SCHEDULE_APPT_COL_KEYS.PICKUP_APPT == field) {
					const customerId = row?.driverOrder?.find(item => item.type === "PULLCONTAINER")?.customerId;
					payload.pickupTimes = [{...pickupTimes, customerId:customerId?._id}];
				} else if (SCHEDULE_APPT_COL_KEYS.DELIVER_APPT == field) {
					const customerId = row?.driverOrder?.find(item => item.type === "DELIVERLOAD" )?.customerId;
					payload.deliveryTimes = [{...deliveryTimes, customerId:customerId?._id}];
				} else if (SCHEDULE_APPT_COL_KEYS.RETURN_APPT == field) {	
					payload.returnFromTime = returnFroms.returnFromTime;
					payload.returnToTime = value.toDate;
				}

				editLoad(payload).then((el) => {
					onRowChange({ ...row, scheduledAppointmentFrom: date, scheduledAppointmentTo: value.toDate });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			} else if ([SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO, SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO, SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO].includes(field)) {
				const pickupTimes = {
					pickupToTime: date,
					pickupFromTime: value.fromDate,
				}
				const returnFroms = {
					returnToTime: date,
					returnFromTime: value.fromDate,
				}
				const deliveryTimes = {
					deliveryToTime: date,
					deliveryFromTime: value.fromDate,
				}
				
				const payload = {
					reference_number: row.referenceNumber || row?.reference_number,
				};

				if (SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO == field) {
					const customerId = row?.driverOrder?.find(item => item.type === "PULLCONTAINER")?.customerId;
					payload.pickupTimes = [{...pickupTimes, customerId:customerId?._id}];
				} else if (SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO == field) {
					const customerId = row?.driverOrder?.find(item => item.type === "DELIVERLOAD" )?.customerId;
					payload.deliveryTimes = [{...deliveryTimes, customerId:customerId?._id}];
				} else if (SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO == field) {	
					payload.returnToTime = returnFroms.returnToTime;
					payload.returnFromTime = value.fromDate;
				}

				editLoad(payload).then((el) => {
					onRowChange({ ...row, scheduledAppointmentFrom: value.fromDate, scheduledAppointmentTo: date });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}
		onClose()
	}

	const removeDate = () => {
		if (field === "pickupTimes" || field === SCHEDULE_APPT_COL_KEYS?.PICKUP_APPT) {
			const pickupTimes = _.cloneDeep(row.pickupTimes);
			if (pickupTimes && pickupTimes[0] && pickupTimes[0].pickupFromTime) {
				pickupTimes?.forEach((pTime, i) => {
					if (i === 0) {
						delete pTime.pickupFromTime;
						delete pTime.pickupToTime;
					}
				});
				const payload = {
					pickupTimes: pickupTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					onRowChange({ ...row, pickupTimes: pickupTimes });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});;
			}
		} else if(field === "apt" || field === SCHEDULE_APPT_COL_KEYS?.DELIVER_APPT) {
			const deliveryTimes = _.cloneDeep(row.deliveryTimes);
			if (deliveryTimes && deliveryTimes[0] && deliveryTimes[0].deliveryFromTime) {
				deliveryTimes.forEach((dTime, i) => {
					if (i === 0) {
						delete dTime.deliveryFromTime;
						delete dTime.deliveryToTime;
					}
				})
				const payload = {
					deliveryTimes: deliveryTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					onRowChange({ ...row, deliveryTimes: deliveryTimes });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		} else if(field === "lfd") {
			const payload = {
				reference_number: row.reference_number,
			};
			if (row.type_of_load === "IMPORT") payload.key = 'lastFreeDay';
			if (row.type_of_load === "EXPORT") payload.key = 'containerAvailableDay';

			if (row[payload.key]) {
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, [payload.key]: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		} else if(field === "vessel.eta") {
			const payload = {
				reference_number: row.reference_number,
				key: 'vessel'
			};
			if (row[payload.key]) {
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, vessel: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}else if(field === "freeReturnDate"){
			const payload = {
				reference_number: row.reference_number,
			};
			if (['IMPORT', 'EXPORT'].includes(row.type_of_load)) payload.key = 'freeReturnDate';
			if (row[payload.key]) {
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, [payload.key]: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		} else if(field === "cut") {
			const payload = {
				reference_number: row.reference_number,
			};
			if (row.type_of_load === "EXPORT") payload.key = 'cutOff';
			
			if (row[payload.key]) {
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, [payload.key]: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		} else if (field === "returnFromTime" || field === SCHEDULE_APPT_COL_KEYS.RETURN_APPT) {
			const payload = {
				reference_number: row.reference_number,
			};
			if (row.returnFromTime) {
				payload.key = 'returnFromTime';
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, returnFromTime: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});

				payload.key = 'returnToTime';
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, returnToTime: null });
				});
			}
		} else if (field === "readyToReturnDate") {
			const payload = {
				reference_number: row.reference_number,
			};
			if (row.readyToReturnDate) {
				payload.key = 'readyToReturnDate';
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, readyToReturnDate: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}
		else if (field === "returnToTime" || field === SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO) {
			const payload = {
				reference_number: row.reference_number,
			};
			if (row.returnToTime) {
				payload.key = 'returnToTime';
				removeValueLoad(payload).then((el) => {
					onRowChange({ ...row, returnToTime: null });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}
		else if (field === "pickupToTime" || field === SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO) {
			const pickupTimes = _.cloneDeep(row.pickupTimes);
			if (pickupTimes && pickupTimes[0] && pickupTimes[0].pickupToTime) {
				pickupTimes?.forEach((pTime, i) => {
					if (i === 0) {
						delete pTime.pickupToTime;
					}
				});
				const payload = {
					pickupTimes: pickupTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					onRowChange({ ...row, pickupTimes: pickupTimes });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}
		else if (field === "deliveryToTime" || field === SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO) {
			const deliveryTimes = _.cloneDeep(row.deliveryTimes);
			if (deliveryTimes && deliveryTimes[0] && deliveryTimes[0].deliveryToTime) {
				deliveryTimes.forEach((dTime, i) => {
					if (i === 0) {
						delete dTime.deliveryToTime;
					}
				})
				const payload = {
					deliveryTimes: deliveryTimes,
					reference_number: row.reference_number,
				};
				editLoad(payload).then((el) => {
					onRowChange({ ...row, deliveryTimes: deliveryTimes });
					successColorResponse(cell, column.idx, row?.className);
				}).catch((err) => {
					onClose(false);
					errorColorResponse(cell, column.idx, row?.className);
				});
			}
		}
		setDate(null);
	}
	
	useOnKeyDown(boxRef, onClose);

	return createPortal(
		<div ref={boxRef} className={`${dateWithTime ? 'date-time-dropdown__menu' : 'date-dropdown__menu'}`} style={Style}>
			{!isAppointmentFeatureEnabled ?
				<DateTimePicker
					onRemoveDate={removeDate}
					OnBlurUpdate={OnBlurUpdate}
					onChange={setDate}
					isShowTime={dateWithTime}
					defaultDate={val}
					displayTimeZone={timeZone}
					openAppointmentModal={openAppointmentModal}
					isPortAccepted={isPortAccepted}
					setShow={onClose}
					openManualBooking={openManualBooking}
					load={row}
					standardPorts={standardPorts}
				/>
				:
				<AppointmentDateWrapper 
					onRemoveDate={removeDate}
					OnBlurUpdate={OnBlurUpdate}
					onChange={setDate}
					isShowTime={dateWithTime}
					defaultDate={val}
					displayTimeZone={timeZone}
					openAppointmentModal={openAppointmentModal}
					isPortAccepted={isPortAccepted}
					appointmentId={appointmentId}
					autoAppointmentId={autoAppointmentId}
					setShow={onClose}
					apptStatus={apptStatus}
					date={date}
					loadId={row._id}
					loadStatus={row.status}
					moveType={moveType}
					openManualBooking={openManualBooking}
					load={row}
					standardPorts={standardPorts}
					isAppointmentProcessing={isAppointmentProcessing}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
				/>
			}
		</div>,
		document.getElementById('pp-overlay-container')
	);
};

export default DateEditor;

const Fields = {
	pickupTimes: "pickupTimes",
	apt: "apt",
	lfd: "lfd",
	eta: "vessel.eta",
	cut: "cut",
	freeReturnDate: "freeReturnDate",
	returnFromTime: "returnFromTime",
	deliveryToTime: "deliveryToTime",
	pickupToTime: "pickupToTime",
	returnToTime: "returnToTime",
	readyToReturnDate: "readyToReturnDate",
	destinationETA: "destinationETA",
	[SCHEDULE_APPT_COL_KEYS.PICKUP_APPT]: SCHEDULE_APPT_COL_KEYS.PICKUP_APPT,
	[SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO]: SCHEDULE_APPT_COL_KEYS.PICKUP_APPT_TO,
	[SCHEDULE_APPT_COL_KEYS.DELIVER_APPT]: SCHEDULE_APPT_COL_KEYS.DELIVER_APPT,
	[SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO]: SCHEDULE_APPT_COL_KEYS.DELIVER_APPT_TO,
	[SCHEDULE_APPT_COL_KEYS.RETURN_APPT]: SCHEDULE_APPT_COL_KEYS.RETURN_APPT,
	[SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO]: SCHEDULE_APPT_COL_KEYS.RETURN_APPT_TO,
}