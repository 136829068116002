import { Modal } from "react-bootstrap";
import { IconWarning, IconWarningCircle } from "Components/Common/Icons";

export default function DeletedEmailModal({ selectedRow, emailDeleted, setEmailDeleted, callbackFunction }) {
    const { from = [], subject = '' } = selectedRow.message || {};
    const senderName = from[0]?.name || 'Unknown Sender';
    const handleClose = () => {
        setEmailDeleted(false);
        callbackFunction();
    }
    return (
        <Modal show={emailDeleted} dialogClassName="modal-sm" backdropClassName="z-1050">
            <Modal.Body className="px-30 pb-30 pt-10 text-center">
                <IconWarning className={"wh-80px text-gray-300"} />
                <div className="font-20 font-medium line-height-25 mb-1">Email is no longer available</div>
                <div className="d-flex flex-wrap gap-30">
                    <div>
                        <div className="d-flex flex-wrap gap-2">
                            <div className="background-failure p-2 rounded-5 w-100">
                                <div className="font-12 font-weight-medium text-muted">From</div>
                                <div className="text-dark line-height-20 font-16">{senderName}</div>
                            </div>
                            <div className="background-failure p-2 rounded-5 w-100">
                                <div className="font-12 font-weight-medium text-muted">Subject</div>
                                <div className="text-dark line-height-20 font-16">{subject}</div>
                            </div>
                        </div>
                        <p className="m-0 font-14 font-weight-normal line-height-18 text-dark text-left mt-1">This email has been removed from the mail system by another user or process. It is no longer available.</p>
                    </div>
                    <div className='d-flex justify-content-between border-1 border-warning-200 p-10 rounded-5 w-100 bg-warning-50'>
                        <IconWarningCircle className="text-warning-500 mr-10" />
                        <div className="d-flex flex-wrap gap-5">
                            <div className="font-14 font-weight-500 text-dark">Instructions</div>
                            <div className='text-dark font-12 font-weight-normal align-text-center text-error-500 line-height-18 text-left'>
                                The email will now be removed from your Embedded Email mailbox. It may be possible to recover this email.  If so, this would need to be done using the mail system application.
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-light" onClick={handleClose}>
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    )
}