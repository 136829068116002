import React from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip from 'react-tooltip'

const EmailToolTip = ({
  toolTipId,
  width,
  isMultiLine = false,
  line1,
  line2
}) => {
  return createPortal(
    <ReactTooltip
      id={toolTipId}
      className={`text-wrap z-1051 ${width ? width : ""} text-left`}
      type="dark"
      role="true"
      style={{ display: 'block' }} // Add this inline style
      effect='solid'

    >
      {
        isMultiLine &&
        <div className='d-flex flex-column font-weight-normal font-12'>
          <span className='text-capitalize'>{line1}</span>
          <span className='text-gray-300 text-capitalize'>{line2}</span>
        </div>
      }
    </ReactTooltip>, document.getElementById("pp-tooltip")
  )
}

export default EmailToolTip
