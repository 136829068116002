import React, { Component } from 'react';
const Index =()=>{
    return(
        <div className="upgrade__pro--msg text-center pointer position-fixed" style={{position: 'fixed', right: 0, top: '50%', zIndex: 9, transform:'translateY(-50%)',width: 'calc(100vw - 70px)'}}>
            <h3>For more features and a better user experience, 
                <div>please upgrade to full version of PortPro</div></h3>
            <span className="btn btn-primary">Upgrade to full version</span>
        </div>
    )
}
export default Index