import { useState } from "react";
import { deleteDraftEmail } from "../actionCreator";
import { toastr } from "../../../../services";
import { EMAIL_MSG } from "../constant";

export const useDeleteDraftEmail = (allEmails, setAllEmails, callback) => {
  const [data, setData] = useState(null);
  const [deleteLoaderIds, setDeleteLoaderIds] = useState(false);
  const _deleteDraftEmail = async (params, isFromSendEmail=false) => {
    setDeleteLoaderIds(params.rowIds);
    delete params?.rowIds;
    try {
      const result = await deleteDraftEmail(params);
      if (!!result?.success) {
        const newMails = allEmails?.filter((email) => !result.success?.includes(email?.draftIds?.[0] || email?.id));
        if(setAllEmails) setAllEmails(newMails);
        if (!isFromSendEmail) toastr.show(EMAIL_MSG.EMAIL_DELETED, "success");
      }
      setData(data);
      setDeleteLoaderIds(false);
    } catch (err) {
      console.log(err);
      if (!isFromSendEmail) toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
      setDeleteLoaderIds(false);
    }
   
  };
  return { data, deleteLoaderIds, _deleteDraftEmail };
};
