import moment from "moment";
import { getTimeZone } from "../NewDispatcher/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DateTimeFormatUtils } from "../../../services";
import { isNewEndorsement } from "../../../services";
import useWindowSize from "../../../Components/CustomHooks/useWindowSize";
import LoadDetailCard from "./LoadDetailCard";
import {
  Icondirection,
  IconFile,
  IconMapMarker,
  IconWeightTool,
  IconWarning,
  IconSnowFlake,
  IconFire,
  IconFlash,
  IconTrainTrack,
  IconPlug,
  IconWaste,
  IconPlusCircle,
  IconRail,
} from "../../../Components/Common/Icons";
import EquipmentDetails from "Components/Common/EquipmentDetails/EquipmentDetails";

const LoadCompactCard = ({ load, isFromDriverDetailView, isSelected, onChange, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_isSelected, _setIsSelected] = useState(isSelected);

  useEffect(()=>{
    _setIsSelected(isSelected)
  },[isSelected])

  const handleMouseEnter = () => {
    setIsOpen(true);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const cellRef = useRef();
  
  return (
    <>
      <div
        ref={cellRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`position-relative d-flex align-items-top w-100 ${!isFromDriverDetailView && "p-2 mt-2"} ${!isFromDriverDetailView && (!isOpen && "bg-white")}`}
      >
        <div className="w-100">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="font-14 font-weight-500 line-height-20">{load?.reference_number}</div>
            {_isSelected === true && (
              <div>
                <button
                  className="btn btn-outline-light"
                  onClick={() => {
                    _setIsSelected(false);
                    onChange(false)
                  }}
                >
                  Selected
                </button>
              </div>
            )}
            {_isSelected === false && (
              <div>
                <button
                  className={`btn btn-primary ${isDisabled && "btn-disabled"}`}
                  onClick={() => {
                    _setIsSelected(true);
                    onChange(true)
                  }}
                  disabled={isDisabled}
                >
                  Select
                </button>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center gap-5">
            <EquipmentDetails load={load} layout="horizontal"  isGrayPool={load?.driverOrder?.[0]?.isGrayPool || false} />
            <div className="d-flex align-items-center gap-1">
              {load?.hazmat && (
                <div>
                  <IconWarning className="dispatch-icon text-warning-400" />
                </div>
              )}
              {load?.routeType === "Local" && (
                <div>
                  <IconMapMarker className="dispatch-icon text-gray-700" />
                </div>
              )}
              {load?.routeType === "Highway" && (
                <div>
                  <Icondirection className="dispatch-icon text-gray-700" />
                </div>
              )}
              {load?.overWeight && (
                <div>
                  <IconWeightTool className="dispatch-icon text-gray-700" />
                </div>
              )}
              {load?.officeNote && (
                <div>
                  <IconFile className="dispatch-icon text-gray-700" />
                </div>
              )}
              {load?.temperature && (
                <div>
                  <IconSnowFlake className="dispatch-icon text-badgeblue" />
                </div>
              )}
              {(load?.isHot || load?.hot) && (
                <div>
                  <IconFire className="dispatch-icon text-error-500" />
                </div>
              )}
              {load?.isGenset && (
                <div>
                  <IconFlash className="dispatch-icon text-gray-700" />
                </div>
              )}
              {isNewEndorsement() && (
                <>
                  {load?.domestic && (
                    <div>
                      <IconTrainTrack className="dispatch-icon text-gray-700" />
                    </div>
                  )}
                  {load?.ev && (
                    <div>
                      <IconPlug className="dispatch-icon text-gray-700" />
                    </div>
                  )}
                  {load?.waste && (
                    <div>
                      <IconWaste className="dispatch-icon text-gray-700" />
                    </div>
                  )}
                  {load?.gdp && (
                    <div>
                      <IconPlusCircle className="dispatch-icon text-gray-700" />
                    </div>
                  )}
                  {load?.isRail && (
                    <div>
                      <IconRail className="dispatch-icon text-gray-700" />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center gap-5">
            <div className="text-muted font-medium">Assigned:</div>
            {load.loadAssignedDate ? (
              <>
                <div className="text-muted">
                  {moment(load.loadAssignedDate)
                    .tz(getTimeZone({ preferred: true }))
                    .format(`${DateTimeFormatUtils.abbreviatedDateFormat()}`)}
                </div>
                <span className="text-gray-500">•</span>
                <div className="text-muted">
                  {moment(load.loadAssignedDate)
                    .tz(getTimeZone({ preferred: true }))
                    .format(`${DateTimeFormatUtils.timeFormat()}`)}
                </div>
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      {isOpen && !isFromDriverDetailView && <LoadRoutingPortal cellRef={cellRef} load={load} />}
    </>
  );
};

const LoadRoutingPortal = ({ cellRef, load }) => {
  const boxRef = useRef();
  const style = useBoxPosition(cellRef.current, boxRef);
  return createPortal(
    <div
      className="card clist__card clist__card_info mb-0 shadow-md p-10 top-10"
      ref={boxRef}
      style={{ 
        ...style, 
        zIndex: 10000,
        transition: "opacity .6s ease-in-out"
      }}
    >
      <LoadDetailCard load={load} isFromCompactView={true} />
    </div>,
    document.getElementById("pp-overlay-container")
  );
};

const useBoxPosition = (cellRef, boxRef, refHeight) => {
  const [style, setStyle] = useState({
    position: "absolute",
    top: 0,
    transition: "none",
    opacity: 0,
  });

  const { height: screenH, width: screenW } = useWindowSize();

  const calculateBoxPosition = useCallback(() => {
    const { x: cellX, y: cellY, height: cellH } = cellRef?.getBoundingClientRect();
    const { width: boxW, height: boxH } = boxRef?.current?.getBoundingClientRect();
    const clickX = cellX;
    const clickY = cellY + cellH;

    const right = screenW - clickX > boxW;
    const left = !right;
    const top = screenH - clickY > (refHeight || boxH) + 5;
    const bottom = !top;
    const style = {
      position: "absolute",
      opacity: 1,
      transition: "none",
      zIndex: 10000,
    };
    if (right) {
      style.left = `${clickX}px`;
    }

    if (left) {
      style.left = `${screenW - boxW}px`;
    }

    if (top) {
      style.top = `${clickY + 11}px`;
    }
    if (bottom) {
      style.bottom = `${screenH - cellY - 45}px`;
    }

    if (cellRef?.offsetWidth) {
      style.width = `${cellRef?.offsetWidth}px`;
    }
    setStyle(style);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateBoxPosition();
    }, 500);
    calculateBoxPosition();
    return () => clearInterval(interval);
  }, [screenH, screenW]);

  return style;
};

export default LoadCompactCard;
