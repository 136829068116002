import React, { useEffect } from "react";
import { browserHistory } from 'react-router';
import $ from "jquery";
import ReactTooltip from "react-tooltip";

const SubTabs = ({setTabName, tabName, tabItems = [], header = ""}) => {

  useEffect(() => {
    if(tabName){
      
      const p =  $(`.sub_header_tab a`)?.filter(function() {
        return $(this).text().trim() === header;
      });

			p?.addClass?.("active")
    }
  }, [tabName]);

  return (
    <>
    <ul className="nav nav-compact border-0 p-0 mb-10 gap-5" role="tablist">
      {tabItems.map((tabItem, i) => (
        <li className="nav-item pointer" key={i}>
          <span className={`nav-link d-flex align-items-center gap-5 ${tabName === tabItem.title ? 'active' : 'rounded-3 bg-light'}`}
            onClick={() => {
              if(tabItem.title === tabName) return;
              browserHistory.push(tabItem.path);
              setTabName(tabItem.title)
            }}>
            {tabItem.title}
          </span>
        </li>
      ))}
    </ul>
    <ReactTooltip id="settings-tooltip" effect="solid" html={true} />
    </>
  );
};

export default SubTabs;
