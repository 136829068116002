// import moment from "moment";
import { getStorage } from "../../../../services/Common.services";
import moment from 'moment-timezone';

export const getRanges = () => {
  const timeZone = getStorage("timeZone") || moment.tz.guess();

  return {
    Tomorrow: [
      moment().tz(timeZone).add(1, "days").startOf("day"),
      moment().tz(timeZone).add(1, "days").endOf("day"),
    ],
    Today: [
      moment().tz(timeZone).startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    Yesterday: [
      moment().tz(timeZone).subtract(1, "days").startOf("day"),
      moment().tz(timeZone).subtract(1, "days").endOf("day"),
    ],
    "Last 7 Days": [
      moment().tz(timeZone).subtract(6, "days").startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    "Last 14 Days": [
      moment().tz(timeZone).subtract(13, "days").startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
    "This Month": [
      moment().tz(timeZone).startOf("month").startOf("day"),
      moment().tz(timeZone).endOf("month").endOf("day"),
    ],
    "Last 3 Months": [
      moment().tz(timeZone).subtract(3, "months").startOf("day"),
      moment().tz(timeZone).endOf("day"),
    ],
  };
};
