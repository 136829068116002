const initialState = {
    isMultiAccountEnabled: false,
    allInfo: [],
    companyFiles: [],
    isLoading: false,
    isAPEnabled: false,
    isDriverPayEnabled: false,
    isCarrierPayEnabled: false,
    driverPayAccountInfo: {},
    carrierPayAccountInfo: {}
}

export default function accountingServiceReducer(state = initialState, action) {
    switch (action.type) {
        case 'ACCOUNTING_SERVICE_COMPANYINFO':
            return {
                isMultiAccountEnabled: action.payload.isMultiAccountEnabled,
                allInfo: action.payload.allInfo,
                companyFiles: action.payload.companyFiles,
                isAPEnabled: action.payload.isAPEnabled,
                isDriverPayEnabled: action.payload.isDriverPayEnabled,
                isCarrierPayEnabled: action.payload.isCarrierPayEnabled,
                driverPayAccountInfo: action.payload.driverPayAccountInfo ?? {},
                carrierPayAccountInfo: action.payload.carrierPayAccountInfo ?? {}
            };
        case 'ACCOUNTING_SERVICE_ACCOUNTINFO':
            return {
                ...state,
                companyFiles: action.payload,
            };
        case 'ACCOUNTING_SERVICE_LOADER':
            return {
                ...state,
                isLoading: action.payload,
            };
        case 'ACCOUNTING_SERVICE_UPDATE_ALLINFO':
            return {
                ...state,
                allInfo: state.allInfo.map(info =>
                    info.accountID === action.payload.accountID
                        ? { ...info, ...action.payload.updatedInfo }
                        : info
                )
            };
        default: return { ...state }
    }
};
