import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash';

import { IconPlus } from "Components/Common/Icons";

import * as appointmentSystemServices from "services/appointmentSystem.services";
import { getCountryCode } from 'pages/tms/services.js';
import { toastr, changePropertyName } from 'services/Common.services';

import { trackAndAppointments } from "../../NewSettings/CompanySettings/TrackAndAppointments/constant";
import SubTabs from "../../NewSettings/CompanySettings/SubTabs";
import SubHeaderTab from "../../../../Components/SubHeaderTab";
import { settingsTabTitles } from "pages/tms/NewSettings/CompanySettings/constant";
import TemplateBlock from "./Components/TemplateBlock";
import { defaultEmptyAppointmentTemplate } from './constants'
import { LoaderBar } from "Components/Common/LoaderBar";

function NewAppointmentSystemSettings({ 
  isFromCompanySettings,
  setTabName,
  tabName,
  moveType,
  load,
  appointmentId,
  buildPayload,
  closeModal,
  setIsAppointmentModal,
  setIsAppointmentProcessing
   }) {

  const [isApptSystemSettingLoading, setIsApptSystemSettingLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTemplateRow, setSelectedTemplateRow ] = useState(null);
  const [selectedApptTemplate, setSelectedApptTemplate] = useState({});
  const [appointmentTemplates, setAppointmentTemplates] = useState([]);
  const [filteredAppointmentTemplates, setFilteredAppointmentTemplates] = useState([]);
  const isCloned = useRef(false);

  const handleAddAppointmentTemplate = () => {
    setFilteredAppointmentTemplates((prev) => {
      const hasEmptyTemplate = prev.some(template => template?.isNew);
      if (!hasEmptyTemplate) {
        return [...prev, defaultEmptyAppointmentTemplate];
      }
      return prev;
    });
  };

  const debouncedSearch = _.debounce((query) => {
    if (!query.trim()) {
      setFilteredAppointmentTemplates(appointmentTemplates);
      return;
    }
    const filtered = appointmentTemplates.filter((template) =>
      template.templateName?.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredAppointmentTemplates(filtered);
  }, 300);

  const handleSearchTemplate = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleTemplateSelection = (rowIndex) => {
    setSelectedTemplateRow(rowIndex);
    setSelectedApptTemplate(filteredAppointmentTemplates?.[rowIndex] || {});
  }

  const getAppointmentTemplates = async (showActiveTemplate=true) => {
    try {
      let queryParams = {};
      if(load?.containerNo && moveType && showActiveTemplate){
        queryParams = {
          containerNo: load?.containerNo,
          moveType: moveType
        }
      }
      const { data } = await appointmentSystemServices.getAppointmentTemplates(queryParams).then((resp) => resp.data);
      setAppointmentTemplates(data);
      setFilteredAppointmentTemplates(data);
    } catch (e) {
      setAppointmentTemplates([]);
      setFilteredAppointmentTemplates([]);
      toastr.show(`${e?.message || "Failed to fetch appointment templates."}`, "error");
    } finally {
      setIsApptSystemSettingLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const countriesData = await getCountryCode();
      const allCountryCodes = changePropertyName(countriesData);
      setCountries(allCountryCodes);
    } catch (e) {
      setCountries([]);
      toastr.show(`${e?.message || "Failed to fetch country codes."}`, "error");
    }
  }

  useEffect(() => {
    fetchCountries();
    getAppointmentTemplates();
  }, []);

  useEffect(() => {
    if (filteredAppointmentTemplates?.length > 0) {
      const apptIndex = filteredAppointmentTemplates?.findIndex(template => template?.isActive)
      const newTemplates = filteredAppointmentTemplates.filter(template => template?.isNew);
      if(isCloned.current) {
        handleTemplateSelection(filteredAppointmentTemplates.length - 1);
        isCloned.current = false;
      } else if (newTemplates.length > 0) {
        const newTemplateIndex = filteredAppointmentTemplates.findIndex(template => template?.isNew);
        handleTemplateSelection(newTemplateIndex);
      } else if(selectedTemplateRow) {
        handleTemplateSelection(selectedTemplateRow);
      } else if(apptIndex !== -1) {
        handleTemplateSelection(apptIndex);
      } else {
        handleTemplateSelection(0);
      }
    } else {
      handleTemplateSelection(null);
    }

  }, [filteredAppointmentTemplates]);

  return (
    <>
      {
        isApptSystemSettingLoading && <LoaderBar />
      }
      {
        <div className="position-relative subheader-wrapper">
          {isFromCompanySettings && <SubHeaderTab val="companySettings" />}
          <div className={isFromCompanySettings && "page-fluid page-fluid-block"}>
            {isFromCompanySettings && (
              <div className="top-page mb-20" id="dth-top-page">
                <SubTabs
                  setTabName={setTabName}
                  tabName={tabName}
                  header={settingsTabTitles.TRACK_AND_APPOINTMENTS}
                  tabItems={trackAndAppointments()}
                />
              </div>
            )}
            <div className="row h-100 overflow-hidden">
              <div className="col-md d-flex flex-column gap-5 h-100 overflow-y-auto pb-1" style={{ maxWidth: 313 }}>
                <div className="d-flex align-items-center gap-10">
                  <div className="app-search header-search bg-white rounded-5 h-32px w-100">
                    <span className="search-icon"></span>
                    <div className="position-relative">
                      <input
                        type="search"
                        className="form-control input-search-left"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchTemplate}
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary" onClick={handleAddAppointmentTemplate}>
                    <IconPlus className="mr-10" />
                    Add
                  </button>
                </div>
                {filteredAppointmentTemplates?.map((apptTemplate, index) => (
                  <div
                    key={index}
                    className={`bg-white rounded-5 font-medium font-14 line-height-20 d-flex align-items-center justify-content-between p-10 pointer ${
                     index === selectedTemplateRow ? "outline-2 outline-primary" : ""
                    }`}
                    onClick={() => {
                      setSelectedTemplateRow(index)
                      setSelectedApptTemplate(apptTemplate)
                    }}
                  >
                    <div className={`text-wrap text-break ${apptTemplate?.isNew ? 'text-gray-400' : ''}`} style={{ maxWidth: "calc(100% - 40px)" }}>
                      {apptTemplate?.isNew ? 'Template Name' : apptTemplate?.templateName}
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md h-100">  
                {selectedApptTemplate && Object.keys(selectedApptTemplate)?.length > 0 && ( 
                <TemplateBlock 
                  countries={countries}
                  selectedRow={selectedTemplateRow}
                  selectedApptTemplate={selectedApptTemplate}
                  filteredAppointmentTemplates={filteredAppointmentTemplates}
                  setFilteredAppointmentTemplates={setFilteredAppointmentTemplates}
                  getAppointmentTemplates={getAppointmentTemplates}
                  isFromCompanySettings={isFromCompanySettings}
                  moveType={moveType}
                  load={load}
                  appointmentId={appointmentId}
                  buildPayload={buildPayload}
                  closeModal={closeModal}   
                  setIsAppointmentModal={setIsAppointmentModal}
                  setSelectedTemplateRow={setSelectedTemplateRow}
                  setIsAppointmentProcessing={setIsAppointmentProcessing}
                  isCloned={isCloned}
                />)}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default NewAppointmentSystemSettings;
