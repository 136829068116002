import * as $ from "jquery";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  assignLoad,
  getAvailableLoads,
  makeLoadStartable
} from "../../../pages/trucker/NewTrack1/actionCreators";
import DriverDetails from "../../../pages/trucker/NewTrack1/DriverDetails";
import { DriverSummaryComponent } from "../../../pages/trucker/NewTrack1/elements/DriverSummaryComponent";
import { toastr, getStorage, getCarrierId, showForTerminal, amplitudeTrack, isRoutingService, updateTerminology } from "../../../services/Common.services";
import "./scrollhide.css";
import "./style.css";
import _ from "lodash";
import { ActionType } from "../../../pages/tms/ChassisValidation/utils/constants";
import { loadEvents } from "../../../pages/tms/constant";
import { setLoadStatusOrder } from "../../../pages/tms/Load/actionCreator";
import { handleAddLegs, handleAddSupportrMove } from "../../../pages/tms/ChassisValidation/actions";
import { mappedPayloadSuggestion } from "../../../pages/tms/ChassisValidation/utils";
import SuggestionModal from "../../../pages/tms/ChassisValidation/components/SuggestionModal";
import InvalidLegModal from "../../../pages/tms/ChassisValidation/components/InvalidLegModal";
import { createLegsFromDriverOrder } from "../../Common/functions";
import moment from "moment";
import configuration from '../../../config'
import LoadRouting from "../../../pages/tms/LoadRouting";
import Select from "react-select";
import { smallSelectStyle } from "../../../assets/js/select-style";
import DynamicTableHeight from "../../Common/DynamicTableHeight";
import { getTerminal } from "../../../pages/tms/services";
import * as actionCreators from "./actionCreators";
import { bindActionCreators } from "redux";
import { LoaderBar } from "../../Common/LoaderBar";
import { addEvent, assignDriverNewRouting, removeEvent } from "pages/tms/LoadRoutingV2/actionCreator";
import ChassisValidationModal from "pages/tms/LoadRoutingV2/ChassisValidationModal";
import { getLoadDetailFromRedis, lastUpdatedLoadChannel } from "../../../services/common";
const firebase = configuration.firebase;


const profileOptions = [
  { label: "PORT", value: "PORT" },
  { label: "DELIVERY", value: "DELIVERY" },
  { label: "TRAILER", value: "TRAILER" },
  { label: "COMPANY DRIVER", value: "COMPANY DRIVER" },
  { label: "INDEPENDENT CONTRACTOR", value: "INDEPENDENT CONTRACTOR" },
  { label: "OWNER OPERATOR", value: "OWNER OPERATOR" },
  { label: "3rd PARTY DRIVER", value: "3rd PARTY DRIVER" },
  { label: "TEMPORARY", value: "TEMPORARY" },
  { label: "All", value: "All" },
];

class TrackLeftBar extends Component {
  constructor(props) {
    super(props);
    this.dayDrivingLimit = 11 * 60;
    this.dayOnLimit = 14 * 60;
    this.dayOffSlLimit = 10 * 60;
    this.maxShiftLimit = 14 * 60;
    this.restBreakLimit = 30;
    this.untilBreakLimit = 8 * 60;
    this.consecutiveDriveLimit = 8 * 60;
    this.weekOnDrivingLimit = 60 * 60;
    this.state = {
      name: "",
      driverELDdata: {},
      closeRow: {},
      remainUntilBreakLimit: 0,
      remainDriveLimitPerWeek: this.weekOnDrivingLimit,
      remainShiftLimitToday: this.maxShiftLimit,
      dayDrivingLimit: this.dayDrivingLimit,
      dayOnLimit: this.dayOnLimit,
      dayOffSlLimit: this.dayOffSlLimit,
      clockTime: 0,
      driverList: [],
      isLoaded: false,
      innerHeight: null,
      activeDriver: null,
      loads: [],
      isLoading: false,
      showDriverModal: false,
      selectedLoad: null,
      suggestions: [],
      indexForSelect: null,
      terminals: [],
      isTerminal: false,
      choosenTerminals: [],
      allDrivers: null,
      filterByDriverTags: [],
    };

    this.closeDriverEldRow = this.closeDriverEldRow.bind(this);
    this.activeDriver = this.activeDriver.bind(this);
    this.activeDriverState = this.activeDriverState.bind(this);
    this.updateDriver = this.updateDriver.bind(this);
    this.handleUpdateActiveDriver = this.handleUpdateActiveDriver.bind(this);
    this.handleAssignLoad = this.handleAssignLoad.bind(this);
    this.saveAnyWay = this.saveAnyWay.bind(this);
    this.socketupdatedLoadRef = null;  
  }

  // close the eld row
  closeDriverEldRow(id, status) {
    let closeRow = Object.assign({}, this.state.closeRow);
    status = status === undefined ? false : status ? false : true;
    closeRow[id] = status;
    this.setState({
      closeRow,
    });
  }

  activeDriverState(driverid) {
    this.setState({
      activeDriver: driverid,
    });
  }
  
  componentDidMount() { 
    $(".driver-list").css(
      "height",
      window.innerHeight -
        $("nav").height() -
        25 -
        $(".shipment-filter").height() -
        $(".search").height()
    );
    this.setState({
      innerHeight: window.innerHeight,
    });
    var self = this;
    $(window).resize(() => {
      self.setState({
        innerHeight: window.innerHeight,
      });
    });
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? '{}');
    let driverValidation = [];
    if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
			driverValidation = userBasicSettings.driverValidation;
		}
    this.setState({ driverValidation });

    this.socketupdatedLoadRef = firebase
        .database()
        .ref(lastUpdatedLoadChannel());
      this.socketupdatedLoadRef.on("value", (snapshot) => {
        const data = snapshot.val();
        const loadDetail = JSON.parse(data);

        if (
          loadDetail &&
          moment().diff(moment(loadDetail.updatedAt), "seconds") <= 10
        ) {
          if(loadDetail?.reference_number===self.state.selectedLoad?.reference_number){
            getLoadDetailFromRedis(loadDetail?.reference_number, loadDetail).then((res) => {
              if(res){
                this.setState({selectedLoad:res})
              }
            });
            
          }
        }
      })
    let isTerminal = showForTerminal();
    this.setState({ isTerminal: isTerminal });
    if (isTerminal) {
      getTerminal().then((response) =>
        this.setState({
          terminals: response.data.data ? response.data.data : [],
        })
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.drivers && nextProps.dispatchedDriverList && nextProps.notAssignedDriverList ) {
      const driverList = nextProps.filterSelected === 1 ?  nextProps.drivers 
                        : nextProps.filterSelected === 2 ? nextProps.dispatchedDriverList
                        : nextProps.filterSelected === 3 ? nextProps.notAssignedDriverList
                        : nextProps.drivers
      this.setState({
        driverList,
        allDrivers:driverList,
        isLoaded: true,
        driverPlaces: nextProps.driverPlaces,
      });
    }
  }
  componentWillUnmount() {
    if (this.socketupdatedLoadRef) {
      this.socketupdatedLoadRef.off("value");
    }
  }

  updateDriver(params) {
    const driverList = this.state.driverList;
    driverList.forEach((user) => {
      if (user.driver._id === params.driverId) {
        user.driver.currentDriverAddress = params.currentDriverAddress;
        user.driver.lastUpdatedAddress = params.lastUpdatedAddress;
      }
    });
    this.setState({ driverList,  allDrivers:driverList, });
  }
  clearFilter() {
    let self = this;
    this.setState(
      {
        name: "",
        activeDriver: undefined,
      },
      () => {
        self.props.getallDriverLogs();
        // self.props.selectDriver();
      }
    );
  }
  handleDriverSearch = (e) => {
    const value = e.target.value;
    if (this.props.handleDriverSearch) {
      // console.log("Handle Driver Search: Valid");
      this.props.handleDriverSearch(value);
    } else {
      // console.log("Handle Driver Search: In Valid");
    }
  };

  handleSearchLoad(e) {
    const value = e.target.value;
    this.props.handleSearchLoad(value);
  }

  activeDriver(driverID) {
    return this.state.activeDriver === driverID ? "active" : "test";
  }

  async handleUpdateActiveDriver(driverID) {
    try {
      this.setState({
        isLoading: true,
        activeDriver: driverID,
      });
      let _loads = await getAvailableLoads();
      let loads = []
      let driver = this.state.driverList.find((d) => d._id === driverID)
      if(this.state.driverValidation && this.state.driverValidation?.length > 0 && this.state.driverValidation.includes('driverPreferences') && driver) {
        loads = _loads.filter((load) => {
          if (load.hazmat && !driver.driver.hazmat) return false;
          else if (load.liquor && driver.driver.liquor === "false") return false;
          else if ((load.overWeight && !driver.driver.overweight)
            || (load.overWeight && _.intersection(driver.driver.owerWeightStates, load.consignee.map((d) => d.state)).length === 0))
            return false;
          return true;
        })
      } else {
        loads = _loads;
      }
      this.setState({
        loads,
        isLoading: false,
      });
    } catch (e) {
      toastr.show("We could not get the load.  Please try again.", "error");
    }
  }
  checkIsChassisV3 = (chassisV3) => {
    if(chassisV3[0]?.error){
      this.setState({ isInvalidLeg: chassisV3[0]?.error })
      return true
    } else {
      this.setState({ 
        suggestions: chassisV3, 
        showModal: true,
        isLoading: false
       });
      return true
    }
  }
  async handleAssignLoad(referenceNumber, driverID, driverOrder, load) {
    try {
      this.setState({
        isLoading: true,
        driverOrder,
        referenceNumber, 
        driverID
      });
      const data = await assignLoad({
        reference_number: referenceNumber,
        userId: driverID,
        isloadStartable: false,
      });
      if (data?.isChassisv3){
        if(isRoutingService()) {
          this.setState({ chassisValidation: data?.isChassisv3, loadDetail: load })
          return
        }
        let isChassiV3 = this.checkIsChassisV3(data?.isChassisv3)
        if(isChassiV3) return
      }
      else {
        let eventProperties = {
          source: 'tracking page'
        }
        amplitudeTrack('DISPATCH_LOAD',eventProperties)
        this.setState({ addLegLoader: false, showModal: false, indexForSelect: null })
      }

      // await makeLoadStartable({
      //   reference_number: referenceNumber,
      // });

      this.setState({
        activeDriver: null,
        loads: [],
        isLoading: false,
        showDriverModal: true,
        selectedLoad: data,
      });

      if (this.props.getallDriverLogs) {
        this.props.getallDriverLogs();
      }

      toastr.show("Your load has been assigned!", "success");
    } catch (e) {
      toastr.show("We could not assign this load. Try again!", "error");
      this.setState({
        isLoading: false,
      });
    }
  }
  updateState = (obj) => {
    this.setState(obj)
  }

  handleInCurrentDriverOrder = (obj) => {
    if (obj.action[0]?.actionType === ActionType.ADD && obj.action[0]?.actionEvent === loadEvents.CHASSISPICK) {
      this.updateDriverOrder({ eventType: loadEvents.CHASSISPICK, action: ActionType.ADD, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName, moveId: obj?.moveId })
    }
    else if (obj.action[0]?.actionType === ActionType.ADD && obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION) {
      this.updateDriverOrder({ eventType: loadEvents.CHASSISTERMINATION, action: ActionType.ADD, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName, moveId: obj?.moveId })
    }
    else if (obj.action[0]?.actionType === ActionType.REMOVE && obj.action[0]?.actionEvent === loadEvents.CHASSISPICK) {
      this.updateDriverOrder({ eventType: loadEvents.CHASSISPICK, action: ActionType.REMOVE, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName })
    }
    else if (obj.action[0]?.actionType === ActionType.REMOVE && obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION) {
      this.updateDriverOrder({ eventType: loadEvents.CHASSISTERMINATION, action: ActionType.REMOVE, id: obj?.id, driver: obj?.driver, driverName: obj?.driverName })
    }
  }

  addSuportMoveDynamic = async () => {
    let params = {
      reference_number: this.state.suggestions[this.state.indexForSelect]?.reference_number,
      driverId: this.state.suggestions[this.state.indexForSelect]?.driver,
      customerId: this.state.selectedSupportMove?.value,
      endLocation: this.state.selectedSupportMove?.allInfos?.address,
      saveInChassisHistory: true
    }
    handleAddSupportrMove(params).then((result) => {
      this.saveAnyWay()
    }).catch((err) => {
      this.setState({
        addLegLoader: false
      })
      console.log(err, "err")
    })
  }

  handleAddValidLeg = async (obj) => {
    let params = {
      leg: obj
    }
    handleAddLegs(params).then((result) => {
      this.saveAnyWay()
    }).catch((err) => {
      this.setState({
        addLegLoader: false
      })
      console.log(err, "err")
    })
  }

  updateDriverOrder = (eventType) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    const formData = new FormData();
      if(eventType){
        let mappedDriverOrder = mappedPayloadSuggestion(driverOrder, eventType)
        if(mappedDriverOrder?.length > 0) driverOrder = _.cloneDeep(mappedDriverOrder)
      }

      const driverOrderLegWise = createLegsFromDriverOrder(driverOrder)
      const firstMoveId = driverOrderLegWise?.driverLegs[0].map((v)=> v?._id)
      let driverOrderWithId = driverOrder.map((v)=> {
        if(firstMoveId.includes(v?._id) || v.type === "CHASSISPICK"){
          v.driver = this.state.driverID
          v.loadAssignedDate = moment()
              .tz(getStorage("timeZone"))
              .toISOString();
        }
        return v;
      })

      formData.append("reference_number", this.state.referenceNumber);
      formData.append("driverOrder", JSON.stringify(driverOrderWithId));
      setLoadStatusOrder(formData).then((result) => {
        this.setState({ selectedLoad: result, addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
      }).catch((e)=>{this.setState({
        isLoading: false,
        showModal: false,
      })})
  }

  saveAnyWay = async () => {
    try {
      this.setState({ isLoading: true });
      const data = await assignLoad({
        reference_number: this.state.referenceNumber,
        userId: this.state.driverID,
        isloadStartable: false,
        skipValidation: true,
        moveId: this.state.driverOrder[0]?.moveId
      });

      await makeLoadStartable({
        reference_number: this.state.referenceNumber,
      });
      this.setState({
        activeDriver: null,
        saveLoader: false,
        loads: [],
        isLoading: false,
        showDriverModal: true,
        selectedLoad: data,
        showModal: false,
        driverID: null,
        addLegLoader: false,
      });
      if (this.props.getallDriverLogs) {
        this.props.getallDriverLogs();
      }
      toastr.show("Your load has been assigned!", "success");
    } catch (e) {
      toastr.show("We could not assign this load. Try again!", "error");
      this.setState({
        isLoading: false,
      });
    }
  }

  getlist() {
    this.setState({
      isLoading: true,
    });
    let data = {
      isIncludeDeleted: true,
    };

    if (this.state.choosenTerminals && this.state.choosenTerminals.length > 0) {
      data.terminals = JSON.stringify(
        this.state.choosenTerminals.map((terminal) => {
          return terminal.value;
        })
      );
    }
    this.props.actions.listDrivers(data).then((result) => {
      let allDrivers = result;
      this.setState({
        driverList: allDrivers,
        allDrivers,
        isLoading:false
      
      });
    });
  }


  onChassisEventSuccess = () => {
    this.saveAnyWay()
    this.setState({ loadDetail: null })
  }

  onChassisEventFailure = () => {
    this.setState({ loadDetail: null })
  }

  onConfirmSuggestion = () => {
    this.setState({
      addLegLoader: true
    })
    if (this.state.suggestions[this.state.indexForSelect]?.loadType === "Current") {
      this.updateCurrentDriverOrder(this.state.suggestions[this.state.indexForSelect])
    }
    else if (this.state.selectedSupportMove) this.addSuportMoveLeg()
    else this.addOrRemoveValidLeg(this.state.suggestions[this.state.indexForSelect])
  }

  updateCurrentDriverOrder = (obj) => {
    // event to call
    const actionType = obj.action[0]?.actionType;
    const actionEvent = obj.action[0]?.actionEvent;

    if (actionType === ActionType.ADD) {
      if (actionEvent === loadEvents.CHASSISPICK || actionEvent === loadEvents.CHASSISTERMINATION) {
        const index = this.state.driverOrder?.findIndex((leg) => leg._id+"" === obj?.id+"")
        let payloadData = {
          reference_number: this.state.referenceNumber,
          eventType: actionEvent,
          ...(index >= 1 && { eventId:  this.state.driverOrder?.[index-1]?._id })
        };
        addEvent(payloadData).then((result) => {
          this.setState({ selectedLoad: result, addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
          this.saveAnyWay()
        })
        .catch((err) => {
          this.setState({ addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
          console.log(err);
        })
      }
    } else if (actionType === ActionType.REMOVE) {
      if (actionEvent === loadEvents.CHASSISPICK || actionEvent === loadEvents.CHASSISTERMINATION) {

        let payloadData = {
          reference_number: this.state.referenceNumber,
          eventId: obj?.id
        }
        // TODO: Add New API
        removeEvent(payloadData)
        .then((result) => {
          this.saveAnyWay()
          this.setState({ selectedLoad: result, addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
        }).catch((err) => {
          this.setState({ addLegLoader: false, showModal: false, indexForSelect: null, showDriverModal: true });
          console.log(err); 
        });
      }
    }
  }
  addSuportMoveLeg = () => {
    let params = {
      reference_number: this.state.suggestions[this.state.indexForSelect]?.reference_number,
      driverId: this.state.suggestions[this.state.indexForSelect]?.driver,
      customerId: this.state.selectedSupportMove?.value,
      endLocation: this.state.selectedSupportMove?.allInfos?.address,
      saveInChassisHistory: true
    }
    handleAddSupportrMove(params).then((result) => {
      this.setState({
        addLegLoader: false
      })
      this.saveAnyWay()
      // event to call
    }).catch((err) => {
      this.setState({
        addLegLoader: false
      })
      console.log(err, "err")
    })
  }

  addOrRemoveValidLeg = (obj) => {
    this.setState({ isLoading: true });
    const actionType = obj.action[0]?.actionType;
    const actionEvent = obj.action[0]?.actionEvent;
    
    const apiToCall = actionType === ActionType.ADD ? addEvent : removeEvent;

    const payload = {
      reference_number: obj.reference_number,
      ...(actionType === ActionType.ADD && { eventType: actionEvent }),
      ...(obj.id && { eventId: obj.id }),
      skipValidation: true
    }
    apiToCall(payload).then((result) => {
      this.saveAnyWay()
      this.setState({
        addLegLoader: false
      })
    }).catch((err) => {
      this.setState({
        addLegLoader: false
      })
    })
  }

  render() {
    let terminal = [];
    if (this.state.terminals && this.state.terminals.length > 0) {
      terminal = JSON.parse(JSON.stringify(this.state.terminals));
      terminal = terminal.map((terminaldata) => {
        return {
          value: terminaldata._id,
          label: terminaldata.name,
        };
      });
    }
    const { loads, activeDriver, isLoading, showDriverModal, selectedLoad } = this.state;
    return (
      <React.Fragment>
        {!this.props.selectedDriverID ? (
          <div className="mapbar" style={{ height: "calc(100vh - 70px)" }}>
            <div id="dth-top-page" className="card p-2 mb-1">
              <div className="app-search mb-2">
                <input
                  type="text"
                  className="input-search-left form-control"
                  placeholder="Search..."
                  onChange={this.handleDriverSearch}
                />
                <span className="search-icon"></span>
              </div>
              <ul
                className="nav nav-compact nav-justified text-nowrap flex-nowrap"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 1 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(1);
                      }
                    }}
                  >
                    All Drivers (
                      {this.props.drivers.length})
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 2 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(2);
                      }
                    }}
                  >
                    Dispatched ({this.props.dispatchedDriverList.length})
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      this.props.filterSelected === 3 && "active"
                    }`}
                    data-bs-toggle="tab"
                    role="tab"
                    onClick={() => {
                      if (this.props.handleDispatchFilter) {
                        this.props.handleDispatchFilter(3);
                      }
                    }}
                  >
                    Not Assigned (
                   {this.props.notAssignedDriverList.length}
                    )
                  </a>
                </li>
              </ul>
              <div className="form-row mt-10">
                <div className="col-6">
                  {this.state.isTerminal && (
                    <Select
                      onChange={(data) => this.props.handleSearchTerminal(data)}
                      styles={smallSelectStyle}
                      isMulti
                      name="choosenTerminals"
                      options={terminal}
                      placeholder={`Select ${updateTerminology('terminal')}`}
                    />
                  )}
                </div>
                <div className={`${this.state.isTerminal ? "col-6" : "col-12"}`}>
                  <Select
                    options={profileOptions}
                    isMulti={true}
                    onChange={(data) => this.props.handleSearchDriverTag(data)}
                    placeholder="Select Driver Tags..."
                    styles={
                      smallSelectStyle
                    }
                  />
                </div>
              </div>
            </div>
            <DynamicTableHeight pageId={'tracking-main'} />
            <div className="dlist" style={{ height: "calc(100vh - var(--dynamic-table-height-offset))" }}>
              
            {(isLoading || !this.state.isLoaded)&& <LoaderBar></LoaderBar>}
                {this.state.driverList &&
                  this.state.driverList.map((driver) => {
                    return (
                      <DriverSummaryComponent
                        driver={driver}
                        driverLocationDataFromFirebase={this.props.driverLocationDataFromFirebase && this.props.driverLocationDataFromFirebase[driver._id]}
                        key={driver._id + Math.random()}
                        selectDriver={this.props.selectDriver}
                        selectedDriverID={this.props.selectedDriverID}
                        driverPlaces={this.props.driverPlaces}
                        closeDriverEldRow={this.closeDriverEldRow}
                        stateTo={this.state}
                        Id={driver._id}
                        closeRow={this.state.closeRow}
                        activeDriverState={this.activeDriverState}
                        driverELDdata={this.state.driverELDdata}
                        updateDriver={this.updateDriver}
                        updateReverseLocation={this.props.updateReverseLocation}
                        filterDriverList={this.props.filterDriverList}
                        userType={this.props.userType}
                        activeDriver={activeDriver}
                        changeActiveDriver={this.handleUpdateActiveDriver}
                        loads={loads}
                        assignLoad={this.handleAssignLoad}
                        isLoading={isLoading}
                        showAssignBtn={true}
                      />
                    );
                  })}
            </div>
          </div>
        ) : (
          <DriverDetails
            selectedDriver={this.props.selectedDriver}
            drawRouteToMap={this.props.drawRouteToMap}
            mapInstance={this.props.mapInstance}
            toggleLiveLocation={this.props.toggleLiveLocation}
            liveLocationToggled={this.props.liveLocationToggled}
            resetMap={this.props.resetMap}
            sendMessage={this.props.sendMessage}
            driverLocationDataFromFirebase={this.props.driverLocationDataFromFirebase && this.props.driverLocationDataFromFirebase[this.props.selectedDriver?._id]}
           
          />
        )}
        { showDriverModal && <Modal
          show={showDriverModal}
          centered={true}
          className="modal-extend"
          scrollable={true}
        >
          <Modal.Body className="bg-gray-50 p-3 h-100">
            <LoadRouting
              selectedLoads={selectedLoad}
              reference_number={selectedLoad && selectedLoad.reference_number}
              key={`sub_${selectedLoad ? selectedLoad.reference_number : ""}`}
              changeList={this.updateList}
              closeStatusModal={() => {
                this.setState({ showDriverModal: false });  
              }}
              dispatcherModal={true}
            />
          </Modal.Body>
        </Modal>}
        {this.state.suggestions && 
          <SuggestionModal
            showModal={this.state.showModal}
            suggestions={this.state.suggestions}
            customers={this.props.customers}
            selectedSupportMove={this.state.selectedSupportMove}
            updateState={this.updateState}
            indexForSelect={this.state.indexForSelect}
            addLegLoader={this.state.addLegLoader}
            saveLoader={this.state.saveLoader}
            onCancel={() => {
              this.setState({
                showModal: false,
                indexForSelect: null,
              })
            }}
            onConfirm={() => {
              if(isRoutingService()){
                this.onConfirmSuggestion();
                return
              }
              this.setState({
                addLegLoader: true
              })
              if (this.state.suggestions[this.state.indexForSelect]?.loadType === "Current") { this.handleInCurrentDriverOrder(this.state.suggestions[this.state.indexForSelect]) }
              else if (this.state.selectedSupportMove) this.addSuportMoveDynamic()
              else this.handleAddValidLeg(this.state.suggestions[this.state.indexForSelect])
            }}
          />
        }
        {this.state.isInvalidLeg && 
          <InvalidLegModal 
            isInvalidLeg={this.state.isInvalidLeg}
            onCancel={() => {
              this.setState({
                isInvalidLeg: false,
                driverOrder: this.prevDriverOrder
              })
            }}
          />
        }
        <ChassisValidationModal
          validations={this.state.chassisValidation}
          loadDetails={this.state.loadDetail}
          onChassisEventSuccess={this.onChassisEventSuccess}
          onChassisEventFailure={this.onChassisEventFailure}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.TmsReducer.customers,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(TrackLeftBar);
