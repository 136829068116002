import React, { useRef, useState, useEffect } from 'react';
import { IconDot } from '../../../../../../Components/Common/Icons';
import { ICON_COLORS } from '../../constants';

const StackedBarChartToolTip = ({ active, payload, coordinate, keysForStackedBarChart }) => {

    if (active && payload && payload.length) {
        const data = payload[0].payload;

        return (
            <div
                className="p-2 custom-tooltip dlist__card mb-1 shadow-5"
             >
                <div className="d-flex flex-column gap-5 mb-1">
                    <div className="font-14 font-weight-500 text-dark ml-2">{data.name}</div>
                    {keysForStackedBarChart.map((key, index) => {
                        return (
                            <React.Fragment key={key}>
                                <div className="d-flex align-items-center ml-1">
                                    <div><IconDot className={ICON_COLORS[index % ICON_COLORS?.length]} /></div>
                                    <div className="font-12 font-weight-normal text-dark">{key}</div>
                                    <div className="badge bg-gray-50 font-weight-bold font-12 ml-2 badge-pill">{data[key]}</div>
                                </div>
                            </React.Fragment>
                        );
                    })}
                    <div className="d-flex align-items-center ml-1">
                        <div className="font-12 font-weight-normal text-dark">Total Moves</div>
                        <div className="badge bg-gray-50 font-weight-bold font-12 ml-2 badge-pill">{data?.count}</div>
                    </div>
                </div>
                <div className='text-primary font-12 font-weight-500 ml-2 text-capitalize'>
                    Click to view more details
                </div>

            </div>
        );
    }

    return null;
};

export default StackedBarChartToolTip;