import React, { useRef, useState,useEffect } from 'react'
import { genratePillColor } from '../../../utility';
import { formatDateFreeDay } from '../../Functions';
import DateEditor from '../CellComponents/DateEditor';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import '../../../DispatcherTable/style.css';
import { useDispatch, useSelector } from 'react-redux';

const Lfd = ({ row, column, onRowChange, isCellSelected, isCellEditable=true }) => {
	const [showBox, setShowBox] = useState(false)
	const [isColorPortal, setColorPortal] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();
	const dispatch = useDispatch();
  	const fAction = useSelector((state) => state.dispatcherTableReducer);

	const hideModel = () => {
		setShowBox(false)
	}
	const showModel = () => {
		setShowBox(true)
	}
	const lfdKey = row.type_of_load === "IMPORT" ? "lastFreeDay" : "containerAvailableDay";
	let lfdPillColor = genratePillColor(lfdKey, row);

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected
		if (!isCellSelected || row.type_of_load=="ROAD" || lfdPillColor=="bg-warning-500") {
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal ,lfdPillColor]);

	useEffect(() => {	
		cellColorSetter(cellRef, row?.colorOfCells?.Lfd);
	}, [column, row?.colorOfCells?.Lfd]);

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === lfdKey) {
			onRowChange({ ...row, [lfdKey]: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])

	return (
		<>
			<div ref={cellRef} className={"cell-content text-center " + lfdPillColor} onClick={() => showModel()} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
				<div className="rdg-truncate">
					{row.type_of_load === "IMPORT" && row.lastFreeDay
						&& formatDateFreeDay(row.lastFreeDay)}
					{row.type_of_load === "EXPORT" && row.containerAvailableDay
						&& formatDateFreeDay(row.containerAvailableDay)}
					{!(row.lastFreeDay || row.containerAvailableDay) && <span className='date_field'></span>}
				</div>
			</div>
			{isCellEditable && row?.isEditPermission && showBox
				&& row.type_of_load !== "ROAD" &&<DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					onOpen={showModel}
					row={row}
					column={column}
					onRowChange={onRowChange} />
			}
			{isColorPortal &&
				<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="Lfd" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
			}
		</>
	)
}
export default Lfd