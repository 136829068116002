import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";

import { IconCloseBold } from "Components/Common/Icons";

import PromptAlert from "./Components/PromptAlert";
import NewAppointmentSystemSettings from "../ContainerTracking/AppointmentSystemSettings/NewAppointmentSystemSettings";
import { MOVE_TYPES, FORM_MODE } from "../../../constants";
import { checkIfMoveIsCancelled, checkIfPickupReturnTimesExists } from "utils";

const AutoAppointmentModal = ({ load, moveType, closeModal, appointmentId, isAllowedForDirectBooking = false, buildPayload, setIsAppointmentProcessing }) => {

  const [isPrompt, setIsPrompt] = useState(null);
  const [isAppointmentModal, setIsAppointmentModal] = useState(moveType ? true : false);

  const formMode = useMemo(() => {
    if (appointmentId) return FORM_MODE.EDIT;
    return FORM_MODE.CREATE;
  }, [appointmentId]);

  const handleClosePrompt = () => {
    setIsPrompt(null);
    closeModal();
  };


  useEffect(() => {
    if (checkIfPickupReturnTimesExists(moveType, load)) {
      setIsPrompt(`Do you want to remove entered ${moveType === MOVE_TYPES.PICKUP ? 'pickup' : 'return'} times ?`)
    }
    else if (formMode == FORM_MODE.EDIT) {
      if (!checkIfMoveIsCancelled(moveType, load))
        setIsPrompt("Are you sure you want to update and re-schedule the appointment?")
    } else {
      setIsPrompt(null)
    }
  }, [formMode || load])

  return (
    <React.Fragment>
      <Modal
        show={isAppointmentModal && !isPrompt && !isAllowedForDirectBooking}
        animation={false}
        onHide={closeModal}
        dialogClassName="modal-fullpage modal-dialog-scrollable"
      >
         <Modal.Header className="shadow-sm">
          <Modal.Title>
            Automatically Book Appointment
          </Modal.Title>
          <button className="btn btn-outline-light" onClick={() => {
            setIsAppointmentModal(false);
            setIsAppointmentProcessing(false);
            closeModal();
          }}>
            <IconCloseBold className="mr-10" />
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="flex-grow-1 d-flex flex-column pt-20 pb-0 bg-gray-50">
          <NewAppointmentSystemSettings
            isFromCompanySettings={false}
            setTabName={() => {}}
            tabName={""}
            moveType={moveType}
            load={load}
            appointmentId={appointmentId}
            buildPayload={buildPayload}
            closeModal={closeModal}
            setIsAppointmentModal={setIsAppointmentModal}
            setIsAppointmentProcessing={setIsAppointmentProcessing}
          />
        </Modal.Body>
      </Modal>
      <PromptAlert
        showPrompt={isPrompt && !isAllowedForDirectBooking}
        content={isPrompt}
        confirm={() => setIsPrompt(null)}
        closePrompt={() => {
          handleClosePrompt();
        }}
        modalBodyClass="px-10"
      />
    </React.Fragment>
  );
};

export default AutoAppointmentModal;
