import { IconCancel, IconSparkleGrediant } from 'Components/Common/Icons';
import { useEffect, useState } from 'react';

const SuggestDriverAlert = (props) => {
    const {
        isNotificationAlert,
        setIsNotificationAlert,
        isEnabledMarketsError,
        setClickedOnShowEvents,
        selectedTabs,
        setIsCollapsedSideBar,
        isCollapsedSideBar
    } = props;

    const [isClose, setIsClose] = useState(false);

    useEffect(() => {
        if(isNotificationAlert) {
            setIsClose(false);
        }
    }, [isNotificationAlert]);

    const handleClose = () => {
        setIsClose(true);
        setIsNotificationAlert(false);
    }

    const handleShowEvents = () => {
        if(isCollapsedSideBar) setIsCollapsedSideBar(false);
        setClickedOnShowEvents(true);
        setIsNotificationAlert(false);
        setIsClose(true);
    }
    return (
        <>
            {!isClose &&
                <div className={`alertShow position-fixed ${isEnabledMarketsError ? 'mt-40' : ''}`}>
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="bg-purple-50 shadow-3-blue rounded-lg py-1 px-2 border-1 border-purple-400 rounded-8">
                            <div className="d-flex align-items-center">
                                <div className='d-flex align-items-center gap-10'>
                                    <IconSparkleGrediant />
                                    <div className="mr-40 text-dark font-14 font-weight-normal">
                                        Important: new events are awaiting action.
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-sm btn-white" onClick={handleShowEvents}>
                                        Show Events
                                    </button>
                                </div>
                                <div className="ml-10" onClick={() => handleClose()}>
                                    <IconCancel />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SuggestDriverAlert