import React, { useRef, useState, useEffect } from 'react'
import _ from 'underscore';
import ContainerTypeList from '../portals/ContainerTypeList';
import ColorPortal,{cellColorSetter} from "../portals/ColorPortal";
import CellSpinner from '../../../../../../Components/Common/Spinner/CellSpinner';

const ContainerType = ({ row, onRowChange, isCellSelected , column }) => {
	const [showBox, setShowBox] = useState(false);
	const [isAssignInProgress, setIsAssignInProgress] = useState(false);
	const [isColorPortal, setColorPortal] = useState(false);
	const cellRef = useRef()
	const prevSelectionRef = useRef();

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			setShowBox(true)
		}
		prevSelectionRef.current = isCellSelected
		if (!isCellSelected) {
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.containerType);
	}, [column, row?.colorOfCells?.containerType]);

	return (
		<>
		<div ref={cellRef} className="cell-content" onClick={() => setShowBox(true)} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>
		
			{isAssignInProgress && (
				<CellSpinner />
			)}
			{!isAssignInProgress && row.containerType ? row.containerType.name : ""}
			{row?.isEditPermission && showBox && !isAssignInProgress
				&& <ContainerTypeList 
					Row={row} 
					CellRef={cellRef} 
					onClose={(val) => {
						setShowBox(val);
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}} 
					onRowChange={onRowChange}
					setIsAssignInProgress={setIsAssignInProgress}
					column={column}
				/>
			}
			
		</div>
		{isColorPortal &&
			<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="containerType" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
		}
	</>)
}
export default ContainerType