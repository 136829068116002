import { useState } from "react";
import { toggleMarkAsUnRead } from "../actionCreator";
import { toastr } from "services";
import _ from "lodash";
import { EMAIL_MSG } from "../constant";

export const useToggleMarkAsUnread = (allEmails, setAllEmails, callback,updateUnreadCountLoadInfoTab) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [readUnreadIds, setReadUnreadIds] = useState([]);

  const onSuccess = (data, params) => {
    if (!!data?.notFound?.length) {
      const notFoundMessage = (data.notFound.length && !data.success.length)
        ? EMAIL_MSG.NOT_FOUND_IN_NYLAS_SINGLE
        : EMAIL_MSG.NOT_FOUND_IN_NYLAS_MULTIPLE;
      if (params?.setEmailDeleted) params?.setEmailDeleted(true);
      else toastr.show(notFoundMessage, "success"); 
    }

    if(data?.failed?.length) {
      return toastr.show('Something went wrong!', "error");
    }

    const uniqueFolders = _.uniq(_.flatMap(data.success, 'folders'));
    callback(uniqueFolders);
    
    setAllEmails(_allEmails => _allEmails
      ?.filter(email => !data?.notFound?.some(item => item === email.id))
      ?.map(email => {
        const foundEmail = data?.success.find(item => item.id === email.id);
        if (foundEmail) {
          return {
            ...email,
            unread: foundEmail.unread,
            starred: foundEmail.starred
          };
        }
        return email;
      })
    );
  };

  const _toggleMarkAsUnread = async (params) => {
    setLoading(params.isUnread);
    setReadUnreadIds(params.ids);
    try {
      const data = await toggleMarkAsUnRead(params);
      setData(data);
      onSuccess(data, params);
      if(updateUnreadCountLoadInfoTab) updateUnreadCountLoadInfoTab()
    } catch (err) {
      console.log(err);
    }
    setReadUnreadIds([]);
  };
  return { data, loading, _toggleMarkAsUnread, readUnreadIds };
};
