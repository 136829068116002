import React, { useRef, useState } from "react";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant";
import { amplitudeTrack } from "services";
import FolderContextMenu from "./FolderContextMenu";
import RemoveLabelModal from "./EmailNav/LabelModals/RemoveLabelModal";
import AddLabelModal from "./EmailNav/LabelModals/AddLabelModal";
import { addFolderToStructure } from "../helper";

const FolderContextMenuWrapper = ({
    children,
    folder,
    onClick,
    setActiveNav,
    isSubFolder,
    provider,
    mainFolders,
    setFolders,
    fromFavorites = false,
    isFavFolder,
    setIsFavFolder,
    setContextMenu
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [cellRef, setCellRef] = useState(null); // Using useState to manage cellRef
  const [showDeleteLabelModal, setShowDeleteLabelModal] = useState(false)
  const [showCreateLabelModal, setShowCreateLabelModal] = useState(false)
  const addLabelModalRef = useRef(null)
  const [selectedColor, setSelectedColor] = useState(null)

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (folder?.id === "categories") return;
    if (e.button === 2) { // check for right-click event
      console.log("right click")
      // Update cellRef with mouse position
      setCellRef({ x: e.clientX, y: e.clientY });
      setShowContextMenu(true)
    }
  };

  const openContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCellRef({ x: e.clientX, y: e.clientY });
    setShowContextMenu(true)
  }

  const onClose = () => {
    setShowContextMenu(false);
  };

  const handleAddFolder = () => {
    setShowContextMenu(false)
    setShowCreateLabelModal(true)
  }

  const handleDeleteFolder = () => {
    setShowContextMenu(false)
    setShowDeleteLabelModal(true)
  }

  const handleCreateLabelModel = (value) => {
    setShowCreateLabelModal(value)
}

  const handleColorCheckboxChange = (index) => {
    setSelectedColor(index)
}

const onRemoveFolder = (folderToRemove) => {
  const removeFolderFromStructure = (folders, targetId) => {
    if (!folders || !Array.isArray(folders)) return folders;

    let removedFolderOrder;
    const filteredFolders = folders.filter(f => {
      if (f.id === targetId) {
        removedFolderOrder = f.order;
        return false;
      }
      return true;
    });
    const updatedFolders = filteredFolders.map(f => ({
      ...f,
      order: f.order > removedFolderOrder ? f.order - 1 : f.order
    }));

    return updatedFolders.map(folder => ({
      ...folder,
      subfolders: removeFolderFromStructure(folder.subfolders, targetId)
    }));
  };

  const updatedFolders = removeFolderFromStructure(mainFolders, folderToRemove.id);
  setFolders(updatedFolders);
};

const onAddFolder = (newFolder, parentFolderId = null) => {
  const updatedFolders = addFolderToStructure(mainFolders, newFolder, parentFolderId);
  setFolders(updatedFolders);
};

  setContextMenu && setContextMenu(openContextMenu)

  return (
    <div
      // className={`h-100 w-100 d-flex align-items-center pointer`}
      onContextMenu={(e) => handleContextMenu(e)}
    >
      <div>{children}</div>
      {showContextMenu && (
        <FolderContextMenu
          cellRef={cellRef}
          showContextMenu={showContextMenu}
          onClose={onClose}
          folder={folder}
          fromFavorites={fromFavorites}
          setIsFavFolder={setIsFavFolder}
          isFavFolder={isFavFolder}
          handleAddFolder={handleAddFolder}
          handleDeleteFolder={handleDeleteFolder}
          isSubFolder={isSubFolder}
          provider={provider}
        />
      )}

        {showCreateLabelModal && (
          <AddLabelModal
              show={showCreateLabelModal}
              modalRef={addLabelModalRef}
              selectedColor={selectedColor}
              handleColorCheckboxChange={handleColorCheckboxChange}
              handleCreateLabelModel={handleCreateLabelModel}
              isSubFolder={isSubFolder}
              folder={folder}
              onAddFolder={onAddFolder}
            />
        )}

        {showDeleteLabelModal && (
            <RemoveLabelModal
                show={showDeleteLabelModal}
                modalRef={addLabelModalRef}
                labelToRemove={folder?.id}
                handelDeleteLabelModel={() => {
                    setShowDeleteLabelModal(false)
                }}
                setActiveNav={setActiveNav}
                onRemoveFolder={onRemoveFolder}
                folder={folder}
                provider={provider}
            />
        )}
    </div>
  );
};

export default FolderContextMenuWrapper;
