import React from "react";
import Select from "react-select";
import AsyncCreatableSelect from 'react-select/async-creatable';

import { ENUM, calculationTypes } from "./constant";
import { useCustomerSelect } from "../../hooks/useCustomerSelect";
import { status } from "../../../../../Components/DynamicPricing/utils";
import { smallSelectStyle } from "../../../../../assets/js/select-style";
import { convertProfileListToOption } from "../../service/profileService";
import useTariffChargeProfileFilterbar from "./hooks/useTariffChargeProfileFilterbar";
import { getDrayosCarriers } from "pages/tms/carrier/ApplyCarrierPay/actionCreator.js";
import { IconPlus, IconReset, IconTimesThin } from "../../../../../Components/Common/Icons";
import { getCustomerCityAsyncSearch } from 'pages/tms/CustomerRates/service/customerRateService.js';

export default function TariffChargeProfileFilterbar({ vendorType, onApply, onClose }) {
    const {
        formData, chargeCodeListOptions, locationOptions, fromEventOptions, selectOptionsForLegs,
        toEventOptions, eventOptions, eventLocationOptions, getLegOptions, getLegProfileOptions,
        handleApplyFilter, handleChange, handleClear, handleAddMoveEvent, handleClose
    } = useTariffChargeProfileFilterbar({ vendorType, onApply, onClose });

    const {
        groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions,
        shipperFilterOptions, driverGroupsOptions, carrierGroupsOptions,
        carrierOptions, allDriverGroup,
    } = useCustomerSelect();

    return (
        <>
            <div className="bdpopup-backdrop"></div>
            <div className="bdpopup">
                <div className="bdpopup__content d-flex flex-column">
                    <button className="btn btn-none bdpopup__close text-white bg-gray-500 rounded-circle" onClick={handleClose}>
                        <IconTimesThin className="close-icon" />
                    </button>
                    <div className="d-flex px-30 align-items-center justify-content-between pt-20">
                        <p className="font-20 mb-0">Filters & Settings</p>
                        <button className="btn btn-outline-light btn-sm" onClick={handleClear}>
                            <IconReset className="mr-2" /> Reset
                        </button>
                    </div>
                    <div className="hr-light"></div>
                    <div className="d-flex flex-column flex-grow-1 h-100 overflow-hidden">
                    <div className="d-flex flex-column gap-10 overflow-auto">
                        <div className="px-30 d-flex justify-content-between align-items-center">
                            <label className="col-form-label">Calculation Types</label>
                            <Select
                                isClearable
                                className="w-60"
                                menuPosition="fixed"
                                styles={smallSelectStyle}
                                options={calculationTypes}
                                onChange={(value) => handleChange(ENUM.CALCULATION_TYPE, value?.label)}
                                value={calculationTypes?.find(d => d.label === formData?.calculationType) ?? null}
                            />
                        </div>

                        {
                            formData.calculationType === ENUM.BETWEEN_STATUS && (
                                <>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Condition 1</label>
                                        <Select
                                            isClearable
                                            className="w-60"
                                            options={status}
                                            menuPosition="fixed"
                                            value={fromEventOptions}
                                            styles={smallSelectStyle}
                                            onChange={(option) => handleChange(ENUM.FROM_EVENT, option?.value)}
                                        />
                                    </div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Condition 2</label>
                                        <Select
                                            isMulti
                                            isClearable
                                            className="w-60"
                                            options={status}
                                            menuPosition="fixed"
                                            value={toEventOptions}
                                            styles={smallSelectStyle}
                                            onChange={(option) => handleChange(ENUM.TO_EVENT, option)}
                                        />
                                    </div>
                                </>
                            )
                        }

                        {
                            formData.calculationType === ENUM.BY_EVENT && (
                                <>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Condition 1</label>
                                        <Select
                                            isClearable
                                            className="w-60"
                                            value={eventOptions}
                                            menuPosition="fixed"
                                            options={locationOptions}
                                            styles={smallSelectStyle}
                                            onChange={(option) => handleChange(ENUM.EVENT, option?.value, 0)}
                                        />
                                    </div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Location 1</label>
                                        <AsyncCreatableSelect
                                            isClearable
                                            cacheOptions
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            value={eventLocationOptions}
                                            placeholder='City/State/ZipCode/Profile/Profile-Group'
                                            onChange={(option) => handleChange(ENUM.EVENT_LOCATION, option, 0)}
                                            defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                            formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                            loadOptions={(strSearch) => getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])}
                                        />
                                    </div>
                                </>
                            )
                        }

                        {
                            formData.calculationType === ENUM.BY_LEG && (
                                <>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Condition 1</label>   
                                        <Select
                                            isMulti
                                            isClearable
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            options={selectOptionsForLegs}
                                            value={getLegOptions(ENUM.FROM_LEGS)}
                                            onChange={(option) => handleChange(ENUM.FROM_LEGS, option)}
                                        />
                                    </div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Location 1</label>
                                        <AsyncCreatableSelect
                                            isClearable
                                            cacheOptions
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            value={getLegProfileOptions(ENUM.FROM_PROFILE)}
                                            placeholder='City/State/ZipCode/Profile/Profile-Group'
                                            onChange={(option) => handleChange(ENUM.FROM_PROFILE, option)}
                                            defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                            formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                            loadOptions={(strSearch) => getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])}
                                        />
                                    </div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Condition 2</label>
                                        <Select
                                            isMulti
                                            isClearable
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            options={selectOptionsForLegs}
                                            value={getLegOptions(ENUM.TO_LEGS)}
                                            onChange={(option) => handleChange(ENUM.TO_LEGS, option)}
                                        />
                                    </div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">Location 2</label>
                                        <AsyncCreatableSelect
                                            isClearable
                                            cacheOptions
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            value={getLegProfileOptions(ENUM.TO_PROFILE)}
                                            placeholder='City/State/ZipCode/Profile/Profile-Group'
                                            onChange={(option) => handleChange(ENUM.TO_PROFILE, option)}
                                            defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                            formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                            loadOptions={(strSearch) => getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])}
                                        />
                                    </div>
                                </>
                            )
                        }
                        {
                            formData.calculationType === ENUM.BY_MOVE && (
                                <>
                                    {
                                        formData?.locations?.map((location, i) => (
                                            <>
                                                <div className="px-30 d-flex justify-content-between align-items-center">
                                                    <label className="col-form-label">Condition {i + 1}</label>
                                                    <Select
                                                        isClearable
                                                        className="w-60"
                                                        menuPosition="fixed"
                                                        options={locationOptions}
                                                        styles={smallSelectStyle}
                                                        onChange={(option) => handleChange(ENUM.EVENT, option?.value, i)}
                                                        value={locationOptions?.find(d => d.value === location?.event) ?? null}
                                                    />
                                                </div>
                                                <div className="px-30 d-flex justify-content-between align-items-center">
                                                    <label className="col-form-label">Location {i + 1}</label>
                                                    <AsyncCreatableSelect
                                                        isClearable
                                                        cacheOptions
                                                        className="w-60"
                                                        menuPosition="fixed"
                                                        styles={smallSelectStyle}
                                                        placeholder='City/State/ZipCode/Profile/Profile-Group'
                                                        value={convertProfileListToOption(location?.eventLocation)}
                                                        onChange={(option) => handleChange(ENUM.EVENT_LOCATION, option, i)}
                                                        defaultOptions={[...shipperFilterOptions, ...groupedZipCodesOptions]}
                                                        formatCreateLabel={(inputValue) => `Add "${inputValue}" ZIP/Postal Code`}
                                                        loadOptions={(strSearch) => getCustomerCityAsyncSearch(strSearch, groupedProfilesOptions, groupedCitiesOptions, groupedZipCodesOptions, ['ALL', 'shipper', 'containerReturn', 'caller', 'consignee', 'chassisTermination', 'chassisPick'])}
                                                    />
                                                </div>
                                            </>
                                        ))
                                    }
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <div className="text-primary pointer mt-2" onClick={handleAddMoveEvent}>
                                            <IconPlus className="mr-2" />
                                            Add Event
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>

                    <div>
                        {
                            vendorType !== 'Load' && (
                                <>
                                    <div className="hr-light"></div>
                                    <div className="px-30 d-flex justify-content-between align-items-center">
                                        <label className="col-form-label">{vendorType} Group</label>
                                        <Select
                                            isMulti
                                            isClearable
                                            className="w-60"
                                            menuPosition="fixed"
                                            styles={smallSelectStyle}
                                            value={formData?.vendor}
                                            placeholder={`Select ${vendorType} Group`}
                                            onChange={(value) => handleChange('vendor', value)}
                                            loadOptions={vendorType !== 'Driver' ? getDrayosCarriers : null}
                                            options={vendorType === "Driver" ? [allDriverGroup, ...(driverGroupsOptions ?? [])] : [...carrierGroupsOptions, ...carrierOptions]}
                                        />
                                    </div>
                                </>
                            )
                        }

                        <div className="my-2 px-30 d-flex justify-content-between align-items-center">
                            <label className="col-form-label">Charge Name</label>
                            <Select
                                isClearable
                                className="w-60"
                                menuPosition="fixed"
                                placeholder="Charge Name"
                                styles={smallSelectStyle}
                                options={chargeCodeListOptions}
                                onChange={(option) => handleChange('chargeName', option?.value)}
                                value={chargeCodeListOptions?.find(d => d.value == formData?.chargeName) ?? null}
                            />
                        </div>
                    </div>
                    </div>

                    <div className="bdpopup__footer w-100 position-relative">
                        <button className="btn btn-primary" onClick={handleApplyFilter}>Apply</button>
                    </div>
                </div>
            </div>
        </>
    )
};
