import { useEffect, useState } from 'react';
import moment from 'moment';
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker-edited";
import { convertRawDateToISOString, getTimeZone, getDynamicRanges } from "pages/tms/NewDispatcher/constants";
import { DateTimeFormatUtils, defaultDateTimeFormat } from 'services';
import { IconCalendar, IconTriangleArrowDown } from 'Components/Common/Icons';
import { IconTimes } from '../Common/Icons';
import { checkDateRange } from 'pages/tms/reports/Dashboards/helper';
const RANGES = getDynamicRanges();
const formattedDate = (date, timeZone, isEndDate=false, format = 'lll') => {
    const momentDate = date ? moment(date).tz(timeZone) : isEndDate ? moment().tz(timeZone)?.endOf("day") : moment().tz(timeZone)?.startOf("day");
    return moment(momentDate.format(format))
}


const CustomDateTimeRangePicker = (props) => {
    const {
        ranges = RANGES,
        onApply,
        onClear,
        fromDate,
        toDate,
        isTimePicker = false,
        dateFormat,
        disabled,
        disableFromDate,
        disableUptoDate,
        userTimeZone = getTimeZone(),
        isClearable=true,
        isInput = true,
        name,
        color,
        labelText,
        isCustomLabel= false,
        isManageDropdownPosition= 'body',
        isMoreNumberofRanges = false
    } = props;
    const [key, setKey] = useState(false);
    const [remount, setRemount] = useState(true);
    const [locale, setLocale] = useState({ format: DateTimeFormatUtils.fullDateFormat() })

    const rangeLabel = fromDate && toDate
        ? moment(fromDate).tz(userTimeZone).format(dateFormat || DateTimeFormatUtils.abbreviatedDateFormat()) +
        " - " +
        moment(toDate).tz(userTimeZone).format(dateFormat || DateTimeFormatUtils.abbreviatedDateFormat())
        : "";
    useEffect(() => {
        if (remount) {
            setKey(!key);
            setRemount(false);
        }
    }, [remount]);

    const moreNumberofRangesForScroll = () => {
        return (
            <style>
                {`
                .daterangepicker .ranges {
                    max-height: 320px;
                    overflow-y: auto; 
                    scrollbar-width: thin;
                    width: auto;
                }

                .daterangepicker .ranges::-webkit-scrollbar {
                    width: 5px; 
                }

                .daterangepicker .ranges::-webkit-scrollbar-thumb {
                    background-color: #c1c1c1; 
                    border-radius: 10px; 
                }
                `}
            </style>
        )
    }

    return (
        <>
            {isMoreNumberofRanges && moreNumberofRangesForScroll()}
            <DatetimeRangePicker
                ranges={ranges}
                startDate={fromDate && fromDate !== "" ? formattedDate(fromDate,userTimeZone) : formattedDate('', userTimeZone)}
                endDate={toDate && toDate !== "" ? formattedDate(toDate,userTimeZone) : formattedDate('', userTimeZone, true)}
                onApply={(e, picker) => {
                    const _picker = {
                        ...picker,
                        startDate: convertRawDateToISOString(moment(picker.startDate).format('lll'), userTimeZone),
                        endDate: convertRawDateToISOString(moment(picker.endDate).format('lll'), userTimeZone)
                    }
                    onApply(e, _picker);
                }}
                timePicker={isTimePicker}
                onHide={(e, picker) => {
                    if (picker.chosenLabel === "Custom Range") {
                        return setRemount(true);
                    }
                }}
                timePicker24Hour={DateTimeFormatUtils.timeFormat() === defaultDateTimeFormat.time ? false : true}
                timePickerIncrement={1}
                locale={locale}
                minDate={disableFromDate ? disableFromDate : null}
                maxDate={disableUptoDate ? disableUptoDate : null}
                drops={isManageDropdownPosition}
            >
                {
                    isInput ?
                    <div className="input-wrapper">
                        <input
                            type="text"
                            className="form-control"
                            placeholder={isTimePicker ? "Select Date And Time.." : "Select Date.."}
                            value={
                                fromDate && toDate
                                    ? moment(fromDate).tz(userTimeZone).format(dateFormat || DateTimeFormatUtils.fullDateFormat() + (isTimePicker ? ` ${DateTimeFormatUtils.timeFormat()}` : "")) 
                                        + " - " +
                                    moment(toDate).tz(userTimeZone).format(dateFormat || DateTimeFormatUtils.fullDateFormat() + (isTimePicker ? ` ${DateTimeFormatUtils.timeFormat()}` : ""))
                                    : ""
                            }
                            onChange={() => { }}
                        />
                        {!isClearable || disabled || !fromDate || !toDate ? (
                            <div className="input-icon">
                                <IconCalendar />
                            </div>
                        ) : null}
                            {isClearable && !disabled && fromDate && toDate ? (
                                <span
                                    className="input-icon"
                                    onClick={(e) => {
                                        onClear(e);
                                    }}
                                >
                                    <IconTimes />
                                </span>
                            ) : null}
                    </div> 
                    : <div className={`position-relative d-flex align-items-center ${color ? name ?? '' : ''}`}>
                        <div>
                            <span className="text-muted line-height-10 font-10 font-weight-normal"> {labelText}</span>
                            <h4 className="pointer font-12 font-weight-500 mb-0 line-height-14">
                                    {rangeLabel && (isCustomLabel ? <h4 className='pt-2'>{checkDateRange(fromDate, toDate, rangeLabel)}</h4> : rangeLabel) }
                            </h4>
                        </div>
                        <div className="ml-2 text-dark">
                        {" "}
                        <IconTriangleArrowDown />
                        </div>
                    </div>
                }
            </DatetimeRangePicker>
        </>
    );
}

export default CustomDateTimeRangePicker;
