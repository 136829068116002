import { ORDER_TYPES } from "constants"

export const getOrderNo = (type_of_load, moveType) => {
    switch (`${type_of_load}_${moveType}`) {
        case ORDER_TYPES.IMPORT_PICKUP:
            return "callerbillLandingNo"
        case ORDER_TYPES.EXPORT_EMPTY:
            return "bookingNo"
        case ORDER_TYPES.IMPORT_EMPTY:
            return "callerbillLandingNo"
        case ORDER_TYPES.EXPORT_PICKUP:
            return "bookingNo"
    }
}