import { MY_APP_FEATURES } from "constants";
import { checkUserPermisison, getStorage, isNewAppointmentUIEnabled } from "../../../../../services/Common.services";
import { allTabsIndexRoutes } from "../constant";
import { isDakosySlotsEnabled } from "Components/Common/functions";

export const checkAppPermission = (permission) => {
  return JSON.parse(getStorage("userBasicSettings"))?.myappFeature?.includes(permission)
}
export const trackAndAppointments = () => {
  const defaultRoute = allTabsIndexRoutes.trackAndAppointmentsIndexRoute

  return ([
    ...(checkAppPermission(MY_APP_FEATURES.APPOINTMENT) && checkUserPermisison(["appointment_list"]) ? [{
      title: "Appointment List",
      path: `${defaultRoute}/appointments-list`,
    }] : []),
    ...(checkAppPermission(MY_APP_FEATURES.APPOINTMENT) && checkUserPermisison(["appointment_settings"]) ? [{
      title: "Appointment Settings",
      path: `${defaultRoute}/appointment-settings`,
    }] : []),
    ...((checkAppPermission(MY_APP_FEATURES.APPOINTMENT) && checkUserPermisison(["maximum_appointment_slots"]) && isNewAppointmentUIEnabled()) ? [{
      title: "Maximum Appointment Slots",
      path: `${defaultRoute}/maximum-appointment-slots`,
    }]: []),
    ...(checkUserPermisison(["container-tracking-credentials"]) ? [{
      title: "Container Tracking Credentials",
      path: `${defaultRoute}/container-tracking-credentials`,
    }] : []),
    ...(checkUserPermisison(["container-tracking"]) ? [{
      title: "Container Tracking Settings",
      path: `${defaultRoute}/container-tracking-settings`,
    }]: []),
    ...((checkUserPermisison(["slots-availability"]) && isDakosySlotsEnabled()) ? [{
      title: "Slots Availability",
      path: `${defaultRoute}/slots-availability`,
    }]: [])
  ])
};

export const MANUAL_OVERRIDE_STATEMENT = "Allow for Manual Override of LFD and Status";
export const PREVENT_OVERRIDE_CONTAINER = "Prevent overriding container status from Enhanced Container Tracking";