import React from "react"
import { IconAlphaAutomatic, IconAlphaManual } from "../../../../Components/Common/Icons"
import { DateTimeFormatUtils, getStorage, showForTerminal, updateTerminology } from "services"
import moment from "moment"
import { getVendorPaystatus } from "../../VendorPay/Components/VendorPayStatus"

const RoutingItem = ({ type, distance, companyName, address }) => {
  return (
    <div>
      <div className="d-flex">
        <div className="badge badge-sm badge-gray-300">{type}</div>
        <div className="ml-auto font-10 font-bold">{`${distance} mi`}</div>
      </div>
      <div className="font-medium">{companyName ?? "-"}</div>
      <div className="text-muted">{address ?? "-"}</div>
    </div>
  )
}

const ChargeSetModalSidebar = ({ chargeSetSummary }) => {
  // routing data
  let routingData = chargeSetSummary?.loadId?.driverOrder.map((dO) => ({
    type: dO?.type,
    distance: dO?.distance,
    companyName: dO?.company_name,
    address: dO?.address?.address,
  }))

  // total weight and weight unit
  let totalWeight = 0
  let weightUnit = "LBS"
  if (chargeSetSummary?.loadId && chargeSetSummary?.loadId?.items) {
    chargeSetSummary?.loadId?.items.forEach((item) => {
      if (
        chargeSetSummary?.loadId?.carrier &&
        chargeSetSummary?.loadId?.carrier?.carrier &&
        chargeSetSummary?.loadId?.carrier?.carrier?.weightMeasure == "kg"
      ) {
        totalWeight += Number(item.weightKgs)
        weightUnit = "KG"
      } else {
        totalWeight += Number(item.weight)
      }
    })
  }

  let measureUnit = ""
  let totalDistance = 0
  let showSecondaryDistance = false

  let secondaryDistance = 0
  const setDistanceUnit = () => {
    const selectedLoad = chargeSetSummary?.loadId || []
    const measureUnits =
      selectedLoad &&
      selectedLoad.carrier &&
      selectedLoad.carrier.carrier &&
      selectedLoad.carrier.carrier.distanceMeasure
    let _measureUnit = measureUnits && measureUnits == "km" ? "Kilometers" : "Miles"
    let _totalDistance = 0
    if (selectedLoad && selectedLoad?.totalMiles) {
      if (selectedLoad.totalMiles > 6000) _totalDistance = "6000+"
      else _totalDistance = selectedLoad?.totalMiles?.toFixed(2)
    }
    let _secondaryDistance = 0
    let _showSecondaryDistance = false
    for (const driverOrder of selectedLoad?.driverOrder ?? []) {
      _secondaryDistance += driverOrder.defaultDistance ?? driverOrder.distance
      if (driverOrder.defaultDistance) {
        _showSecondaryDistance = true
      }
    }
    _secondaryDistance = _secondaryDistance.toFixed(2)
    measureUnit = _measureUnit
    totalDistance = _totalDistance
    showSecondaryDistance = _showSecondaryDistance
    secondaryDistance = _secondaryDistance
  }

  setDistanceUnit()

  // load static load info
  const timeZone = getStorage("timeZone")
  const loadStaticInfo = [
    { key: "Customer", value: chargeSetSummary?.loadId?.callerName ?? "-" },
    showForTerminal() ? { key: `${updateTerminology('terminal')}`, value: chargeSetSummary?.loadId?.terminal?.name ?? "-" } : {},
    { key: "Created By", value: chargeSetSummary?.loadId?.addedBy?.name ?? "-" },
    {
      key: "Created Date",
      value: chargeSetSummary?.loadId?.createdAt
        ? moment(chargeSetSummary?.loadId?.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
        : "-",
    },
    { key: "Reference #", value: chargeSetSummary?.loadId?.reference_number ?? "-" },
    {
      key: "Delivery Appointment",
      value:
        chargeSetSummary?.loadId?.deliveryTimes?.length &&
        (chargeSetSummary?.loadId?.deliveryTimes[0]?.deliveryFromTime
          ? moment(chargeSetSummary?.loadId?.deliveryTimes[0]?.deliveryFromTime)
              .tz(timeZone)
              .format(DateTimeFormatUtils.fullDateTimeFormat())
          : "-"),
    },
    {
      key: "Return Container Date",
      value: chargeSetSummary?.loadId?.returnFromTime
        ? moment(chargeSetSummary?.loadId?.returnFromTime).tz(timeZone).format(DateTimeFormatUtils.fullDateTimeFormat())
        : "-",
    },
    { key: "Purchase Order", value: chargeSetSummary?.loadId?.purchaseOrderNo ?? "-" },
    { key: "Container #", value: chargeSetSummary?.loadId?.containerNo ?? "-" },
    {
      key: "Container Size & Type",
      value: `${chargeSetSummary?.loadId?.containerSize?.name ?? "-"}' ${
        chargeSetSummary?.loadId?.containerType?.name ?? "-"
      }`,
    },
    { key: "Chassis #", value: chargeSetSummary?.loadId?.chassisNo ?? "-" },
    {
      key: "Chassis Size & Type",
      value: `${chargeSetSummary?.loadId?.chassisSize?.name ?? "-"}' ${
        chargeSetSummary?.loadId?.chassisType?.name ?? "-"
      }`,
    },
    { key: "Master BOL/BKG", value: chargeSetSummary?.loadId?.callerbillLandingNo ?? "-" },
    { key: "Seal #", value: chargeSetSummary?.loadId?.sealNo ?? "-" },
    { key: "Vessel", value: chargeSetSummary?.loadId?.deliveryOrderNo ?? "-" },
    { key: "SSL", value: chargeSetSummary?.loadId?.containerOwnerName ?? "-" },
    { key: "Weight", value: `${totalWeight.toFixed(2)} ${weightUnit}` },
    {
      key: "Commodity",
      value: chargeSetSummary?.loadId?.items?.length ? chargeSetSummary?.loadId?.items[0]?.commodity : "-",
    },
    { key: `Total ${measureUnit}`, value: totalDistance },
    showSecondaryDistance ? { key: `Secondary ${measureUnit}`, value: secondaryDistance } : {},
  ]
  return (
    <div className="overflow-y-auto light-scrollbar bg-white" style={{ minWidth: "340px" }}>
      <div className="py-10 px-10 bg-white mb-10">
        <div className="d-flex align-items-start mb-10">
          <div>
            <h5 className="font-20 line-height-25 text-primary font-medium mb-0 pointer">
              {chargeSetSummary?.charge_reference_number}
            </h5>
            <div className="">{chargeSetSummary?.billFromDetail?.company_name}</div>
          </div>
          <div className="ml-auto d-flex flex-column align-items-end">
            <>
              <div className="text-muted">{chargeSetSummary?.loadId?.status}</div>
              <div>{moment(chargeSetSummary?.createdAt).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat())}</div>
            </>

            <div className="badge badge-confirmed badge-sm"> {getVendorPaystatus(chargeSetSummary?.status)}</div>
          </div>
        </div>
        <div className="font-14 font-medium line-height-20 mb-1">Routing</div>
        <div className="card card-outline flex-column gap-10 p-1 mb-15">
          {routingData?.map((item, index) => (
            <RoutingItem
              key={index}
              type={item.type}
              distance={item.distance}
              address={item.address}
              companyName={item.companyName}
            />
          ))}
        </div>
        {/* For Charge Set Recap */}
        <div className="mb-10">
          <div className="font-14 font-medium line-height-20 mb-1">Charge Set Recap</div>
          <div className="table-responsive">
            <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
              <thead>
                <tr>
                  <th>Charge</th>
                  <th>QTY</th>
                  <th className="text-right">Charges</th>
                  <th width="30px"></th>
                </tr>
              </thead>
              <tbody>
                {chargeSetSummary?.pricing.map((charge) => (
                  <tr>
                    <td>{charge?.labelName ?? charge?.chargeName ?? charge?.name}</td>
                    <td>{charge?.unit?.print()}</td>
                    <td className="text-right">{(charge?.finalAmount || 0)?.toCurrency(chargeSetSummary?.billFromDetail?.currency)}</td>
                    <td>
                      {charge?.chargeType === "SYSTEM_GENERATED" ? (
                        <IconAlphaAutomatic />
                      ) : (
                        <IconAlphaManual></IconAlphaManual>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <thead>
                <tr>
                  <th className="font-12 text-dark" colSpan="2">
                    Total Charges
                  </th>
                  <th className="text-right font-12 text-dark">{(chargeSetSummary?.totalAmountWithTax || 0)?.toCurrency(chargeSetSummary?.billFromDetail?.currency)}</th>
                  <th></th>
                </tr>
              </thead>
            </table>
          </div>
        </div>

        {/* <>
          <div className="mb-10">
            <div className="d-flex align-items-center mb-1">
              <div className="font-14 font-medium line-height-20 mb-1">Credit Memos</div>
              <button className="btn btn-primary btn-sm ml-auto">
                <IconPlus className="mr-10" />
                Create Credit Memo
              </button>
            </div>
            <div className="table-responsive">
              <table className="table table-card table-card-xs border-1 rounded-5 border-gray-100 border-spacing-none">
                <thead>
                  <tr>
                    <th>Credit Memo #</th>
                    <th>Date</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-primary">POP1234</td>
                    <td>12/12/2023</td>
                    <td className="text-right">$123.00</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th className="font-12 text-dark" colSpan="2">
                      Total Credits
                    </th>
                    <th className="text-right font-12 text-dark">$123.00</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </> */}

        {/* For Billing Notes */}
        {/* <div className="mb-10">
          <div class="font-14 font-medium line-height-20 mb-1">Billing Notes</div>
          <div class="form-group">
            <textarea class="form-control" placeholder={"Billing Notes here.."} disabled />
          </div>
        </div> */}
        {/* For Tax Notes */}
        {/* <div className="mb-10">
          <div class="font-14 font-medium line-height-20 mb-1">Tax Notes</div>
          <div className="bg-gray-50 rounded-5 py-2 px-10">Notes Here</div>
        </div> */}

        {/* For Documents */}
        {/* <div className="d-flex justify-content-between align-items-center mb-10">
          <h5 className="mb-0">Documents</h5>
        </div>
        <div className="mb-10">
          <div className="position-relative d-inline-block w-100">
            <div
              className={`summary-doc-list border-1 rounded-lg text-gray-100 mb_2 py_5 px_6 w-100 justify-content-between pointer`}
            >
              <div className="d-flex align-items-center w-100">
                <div className="d-flex align-items-center w-100 justify-content-between" data-for="upload-by-tooltip">
                  <div className="d-flex align-items-center">
                    <IconFileUpload />
                    <div className={`text-gray-700 d-flex ml_12 font-weight-500`}>POD</div>
                  </div>
                  <div className="text-muted font-12 font-weight-normal d-flex align-items-center">
                    23/12/2023
                    <IconDotSeperator className="mx-2" />
                    01:09 am
                  </div>
                </div>

                <div
                  data-tip={"This document will be included with the Invoice PDF when it is emailed or downloaded."}
                  data-for="document-print-invoice"
                >
                  <IconPrinter className="ml-2 text-muted" />
                  <ReactTooltip id="document-print-invoice" type="dark" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* For Load Details */}
        <div class="font-14 font-medium line-height-20 mb-1">Load Details</div>
        <div className="card card-outline flex-column gap-5 p-1 mb-15">
          {loadStaticInfo.map((element, index) => {
            if (!element?.key) return <></>
            return (
              <div key={index} className="d-flex align-items-center text-muted">
                {element.key}
                <div className="ml-auto text-dark font-medium">{element.value}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ChargeSetModalSidebar
