import React, { useState } from "react"
import { smallSelectStyle } from "../../../../../assets/js/select-style"
import Select from "react-select";
import ChargeRow from "./ChargeRow"
import ChargesBottomAction from "./ChargesBottomAction"
import { IconTrash, Icondots } from "../../../../Common/Icons"
import TenderHistorySection from "../TenderHistoryComponent"
import { getbadgeColor } from "../utils"
import { getVendorPaystatus } from "pages/tms/VendorPay/Components/VendorPayStatus"
import { getBillStatus } from "pages/tms/VendorBills/Components/vendorBillStatus"
import { CHARGE_SET_TAB_LABELS, DISABLED_STATUS, auditSubTabs, chargeSetTabs } from "./constants";
import VendorChargeSetStatusHistory from "../VendorChargeSetStatusHistory/VendorChargeSetStatusHistory.c"
import VendorBillStatusHistoryC from "../BIllStatusHistory/BIllStatusHistory.c"
import { LOAD_CHARGE_STATUS } from "pages/tms/VendorPay/constant";
import { getPaymentStatus } from "../../../../../pages/tms/VendorBills/Components/vendorBillStatus";

const SingleVendorChargeSet = ({
  billingRow,
  carriersList,
  BillingIndex,
  findCarrier,
  isBlockingTabChange,
  selectedLoads,
  getCarrierBillChargeLists,
  updateChargeInChargeList,
  handleReloadOfBillingSummary,
  cardDropdownStates,
  blockTabChange,
  onChangeCarrier,
  toggleDropdown,
  handleDeleteChargeSet,
  dropdownRef,
  showDefaultChargeOnly,
  tenderHistory,
  carrierPays,
  handleUpdateBillingCards
}) => {
  const [singleCarrierPay, setSingleCarrierPay] = useState(
    showDefaultChargeOnly && carrierPays.find((carrierPay) => carrierPay._id === billingRow.carrierPayId)
  )
  const [rateOrAuditTab, setRateOrAuditTab] = useState(CHARGE_SET_TAB_LABELS.CHARGE_RATE);
  const [subAuditTab, setSubAuditTab] = useState(CHARGE_SET_TAB_LABELS.TENDER_STATUS);
  const { tenderId, status, isDefault } = billingRow || {};
  const isCancelled = (singleCarrierPay?.tenderId?.status === 'CANCELED') || false;
  const isDisabledChargeSet = isCancelled
    || isDefault && ((Object.values(tenderId || {}))?.length > 0)
    || (status === LOAD_CHARGE_STATUS.DRAFT) && ((Object.values(tenderId || {}))?.length > 0)
    || (DISABLED_STATUS.includes(status) && billingRow?.billStatus != LOAD_CHARGE_STATUS.REBILLING)
    || ((Object.values(tenderId || {}))?.length > 0)

  const handleRateOrAuditTab = (tabName) => {
    setRateOrAuditTab(tabName);
  }

  const handleSubAuditTab = (tabName) => {
    setSubAuditTab(tabName);
  }
  const filteredTenderHistory = tenderHistory?.filter(history=>history?.tenderReferenceNumberAtStatusChange===billingRow?.tenderId?.drayOSTenderRefNo)
  return (
    <>
      <div className={`card card-tborder border-gray p-10 mb-15 `}>
        <React.Fragment>
          <div className={`${billingRow?.isDefault &&billingRow.TenderId ? "disabled-pointer-events" : ""}`}>
            <div className="d-flex justify-content-between mb-10 flex-wrap gap-5">
              <div className="d-flex align-items-center gap-10 flex-wrap">
                <div className="d-flex flex-column gap-5">
                  { getVendorPaystatus(billingRow?.status ?? 'draft') }
                  {billingRow?.bill?.billId?.paymentStatus ? getPaymentStatus(billingRow?.bill?.billId?.paymentStatus) : billingRow?.billStatus ? getBillStatus(billingRow?.billStatus) : ""}
                </div>
                <span className="text-muted">Bill From</span>
                <Select
                  options={carriersList}
                  defaultMenuIsOpen={!billingRow?.billFromId?._id}
                  value={billingRow.billFromId?._id ? carriersList?.find((t) => t?.value === billingRow.billFromId?._id) ?? {value: billingRow.billFromId?._id, label: billingRow.billFromId?.company_name} : undefined}
                  onChange={(carrier) => {
                    onChangeCarrier(carrier, BillingIndex, billingRow)
                  }}
                  placeholder="Select..."
                  styles={smallSelectStyle}
                  className="w-200"
                  isDisabled={isDisabledChargeSet}
                />

                <div class="d-flex flex-column">
                  <span class="text-muted">Charge Set # </span>
                  <span class="font-medium">{billingRow?.charge_reference_number || "-"}</span>
                </div>
                {
                  billingRow?.tenderId && (
                    <ul className="nav nav-compact gap-5" role="tablist">
                      {
                        chargeSetTabs?.map((d, i) => (
                          <li className="nav-item" key={i.toString()}>
                            <a
                              href="#!"
                              className={`nav-link ${rateOrAuditTab === d?.value ? 'active' : ""}`}
                              onClick={() => handleRateOrAuditTab(d?.value)}
                            >{d?.label}</a>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }

                {
                  rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && (
                    <ul className="nav nav-compact gap-5" role="tablist">
                      {
                        auditSubTabs?.map((d, i) => (
                          <li className="nav-item" key={i.toString()}>
                            <a
                              href="#!"
                              className={`nav-link ${subAuditTab === d?.value ? 'active' : ""}`}
                              onClick={() => handleSubAuditTab(d?.value)}
                            >{d?.label}</a>
                          </li>
                        ))
                      }
                    </ul>
                  )
                }

              </div>
              {(!billingRow?.isDefault && !billingRow?.isManualChargeSet && !billingRow?.bill?.billId) && <div ref={dropdownRef} className="position-relative">
                <>
                  <button className="btn btn-link btn-sm" onClick={(e) => toggleDropdown(e, BillingIndex)}>
                    <Icondots />
                  </button>
                  {cardDropdownStates[BillingIndex] && (
                    <ul className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right position-absolute mb-0 p-2 animated w-200">
                      <li
                        onClick={() => {
                          handleDeleteChargeSet(billingRow, BillingIndex)
                        }}
                        className={`rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer text-danger `}
                      >
                        <IconTrash className="mr-10" />
                        Remove Charge Set
                      </li>
                    </ul>
                  )}
                </>
              </div>}
            </div>
            {
              rateOrAuditTab === CHARGE_SET_TAB_LABELS.CHARGE_RATE && (
                <>
                  <ChargeRow
                    pricing={billingRow?.pricing}
                    billingRow={billingRow}
                    chargeId={findCarrier?.value}
                    isBlockingTabChange={isBlockingTabChange}
                    selectedLoads={selectedLoads}
                    handleReloadOfBillingSummary={handleReloadOfBillingSummary}
                    blockTabChange={blockTabChange}
                    billingIndex={BillingIndex}
                    getCarrierBillChargeLists={getCarrierBillChargeLists}
                    updateChargeInChargeList={updateChargeInChargeList}
                    isDisabledChargeSet={isDisabledChargeSet}
                    handleUpdateBillingCards={handleUpdateBillingCards}
                  />
                  <ChargesBottomAction
                    pricing={billingRow?.pricing}
                    billingRow={billingRow}
                    chargeId={findCarrier?.value}
                    isBlockingTabChange={isBlockingTabChange}
                    selectedLoads={selectedLoads}
                    handleReloadOfBillingSummary={handleReloadOfBillingSummary}
                    blockTabChange={blockTabChange}
                    billingIndex={BillingIndex}
                    getCarrierBillChargeLists={getCarrierBillChargeLists}
                    updateChargeInChargeList={updateChargeInChargeList}
                    isDisabledChargeSet={isDisabledChargeSet}
                  />
                </>
              )
            }
          </div>
        </React.Fragment>

        {
          rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && subAuditTab === CHARGE_SET_TAB_LABELS.TENDER_STATUS && (
            <>
              {showDefaultChargeOnly && filteredTenderHistory && Object.keys(filteredTenderHistory).length > 0 && (
                <div className="p-1 bg-gray-50 mt-10 rounded-5">
                  <TenderHistorySection
                    tenderId={singleCarrierPay?.tenderId}
                    tenderHistory={filteredTenderHistory}
                    getbadgeColor={getbadgeColor}
                  />
                </div>
              )}
            </>
          )
        }

        {
          rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && subAuditTab === CHARGE_SET_TAB_LABELS.CHARGE_SET_STATUS && (
            <>
              {(showDefaultChargeOnly || billingRow?.isManualChargeSet) && billingRow?.approvalStatusHistory?.length > 0 && (
                <VendorChargeSetStatusHistory vendorChargeSet={billingRow}></VendorChargeSetStatusHistory>
              )}
            </>
          )
        }
        {
          rateOrAuditTab === CHARGE_SET_TAB_LABELS.AUDIT && subAuditTab === CHARGE_SET_TAB_LABELS.BILL_STATUS_HISTORY && (
            <>
              {(showDefaultChargeOnly || billingRow?.isManualChargeSet) && billingRow?.bill?.billId?.approvalInvoiceHistory?.length > 0 && (
                <VendorBillStatusHistoryC vendorBill={billingRow?.bill}></VendorBillStatusHistoryC>
              )}
            </>
          )
        }
        
      </div>
    </>
  )
}
export default SingleVendorChargeSet
