import React from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

const PopperTooltip = (props) => {
  const { isOpen, refNo, color, name, cssClass, nameArray, isArrow = true, children } = props;
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(refNo, popperElement, { placement: 'top' });

  const renderContent = () => {
    if (name !== undefined) {
      return name;
    } else if (children) {
      return children;
    } else {
      return null; 
    }
  };

  return (
    <>
      {isOpen &&
        ReactDOM.createPortal(
          <div
            className={`tooltip tooltip--${color} bs-tooltip-top portal-tooltip ${cssClass}`}
            ref={setPopperElement}
            style={styles.popper}
            data-testid="tooltip"
            {...attributes.popper}
          >
            <span className="text-capitalize">{renderContent()}</span>
            {nameArray && nameArray.map((P, index)=>{
                                  if(P.name) {
                                      return (
                                          <span key={P.index} className="text-capitalize">
                                              {P.name}{" "}
                                              {P.lastName}{<br/>}
                                          </span>
                                      )
                                  }
                                })}
            {isArrow && <span className="arrow" data-testid="tooltip-arrow"></span>}
          </div>
        ,
        document.querySelector('#poper-dest')
        )}
    </>
  );
};
export default PopperTooltip;
