import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { Modal } from "react-bootstrap";
import * as actionCreators from "./actionCreators";
import * as customerActionCreators from "../../Customer/actionCreators";
import * as Yup from "yup";
import _ from "underscore";
import $ from "jquery";
import { withFormik } from "formik";
import { toastr, getStorage, cleanMobileNumber, isNewBillingEnabled, isManageFleetOrOutSource, isEmbeddedEmailEnabled, checkIsCustomer, generateRandomPasswordForFleet, amplitudeTrack, updateTerminology } from "../../../../services/Common.services";
import { getTerminal } from "../../services";
import configuration from "../../../../config";
import { convertTMSStatusName } from "../../../../Components/Common/functions";
import confirm from "../../../../Components/Common/ConfirmAert";
import {
  IconPen,
  IconPlus,
  IconTrash,
  IconEye,
  IconMinus,
  IconInfoCircleFilled,
  IconRedirect,
  IconAngleArrowTop,
  IconKey,
  IconEyeHidden,
  IconTimes,
} from "../../../../Components/Common/Icons";
import {
  StateFormInput,
  StateFormNumberFormat,
  StateFormNumberIntFormat,
} from "../../../../Components/FormComponent/StateFormComponent";
import { LoaderBar } from "../../../../Components/Common/LoaderBar";
import { Spinner } from "../../../../Components/Common/Components/Component";
import EditPersonModal from "../../Email/Components/EmailDetails/EditPersonModal";

let blank = {
  _id: undefined,
  email: "",
  billingEmail: "",
  password: "",
  password1: "",
  firstName: "",
  lastName: "",
  title: "",
  profileUrl: "",
  primaryEmailLabel: "",
  alternativeEmailAddresses: [{email: "", label: ""}],
  mobileNumbers: [{mobile: "", label: ""}],
  mobile: "",
  // admin: true,
  allTerminal: false,
  // mechanic: false,
  notificationEmails: [],
  roles: "",
  permissions: [],
};

const Permissions = {
  customer_shipments: "customer_shipments",
  customer_employee_load: [
    "customer_employee_load_info",
    "customer_employee_load_billing",
    "customer_employee_load_documents",
    "customer_employee_load_upload_documents",
    "customer_employee_load_payments",
    "customer_employee_load_tracking",
    "customer_employee_load_messaging",
    "customer_employee_load_summary",
  ],
  dropped_containers: "dropped_containers",
  account_payable: "account_payable",
  customer: "customer",
  reports: "reports",
  customer_quotes: "customer_quotes"
};
const columns = ["First name", "Last Name", "Phone ", "Email", "User Type"];
const employeePermission = [
  { label: "Loads", value: "customer_employee_load" },
  { label: "Dropped container", value: "dropped_containers" },
  { label: "Accounts payable", value: "account_payable" },
  { label: "Info", value: "customer_employee_load_info" },
  { label: "Billing", value: "customer_employee_load_billing" },
  { label: "Documents", value: "customer_employee_load_documents" },
  { label: "Document Upload", value: "customer_employee_load_upload_documents" },
  { label: "Summary", value: "customer_employee_load_summary" },
  { label: "Payments", value: "customer_employee_load_payments" },
  { label: "Tracking", value: "customer_employee_load_tracking" },
  { label: "Service Messaging", value: "customer_employee_load_messaging" },
  { label: "Tracking", value: "customer_shipments" },
  { label: "Customer", value: "customer" },
  { label: "Reports", value: "reports" },
  { label: "Reports Customer Revenue", value: "reports_customer_revenue" },
  { label: "Reports Aging", value: "reports_aging" },
  { label: "Reports Driver Revenue", value: "reports_driver_revenue" },
  { label: "Reports Status Driver", value: "reports_status_driver" },
  { label: "Reports Clock Report", value: "reports_clock_report" },
  { label: "Customer Quotes", value: "customer_quotes"}
];
class CustomerFleetManagers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allFleetManagers: [],
      // admin: true,
      // mechanic: false,
      allTerminal: false,
      passwordShown: false,
      password1Shown: false,
      ...blank,
      fleetID: "",
      formErrors: {},
      formsTouched: {},
      selectedEmployee: {},
      employeePermission: [], 
      loading: false,
      isSpinner: false,
      customerUser: null,
    };
    this.handleChangeFleetManager = this.handleChangeFleetManager.bind(this);
    this.updatePermissionForAdminEmployee = this.updatePermissionForAdminEmployee.bind(this);
    this.checkIsManageFleet = isManageFleetOrOutSource()
    this.Terminals = [];
    this.isPerson = JSON.parse(localStorage.getItem('userBasicSettings'))?.isEmbeddedEmailEnabled
  }

  handleNotificationEmailsChange = (notificationEmails) => {
    notificationEmails = notificationEmails.filter((d) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(d)
    );
    this.setState({ notificationEmails });
  };

  setValChange = (key, val) => { 
    var formsTouched = { ...this.state.formsTouched };
    formsTouched[key] = true;
    this.setState({ [key]: val, formsTouched }, () => {
      this.insertdata(false);
    });
  };

  componentWillReceiveProps(props) {
    if (props.CustomerID) {
      this.getdata(props.CustomerID);
    }
  }

  insertdata = (isSubmit = true) => {
    var errors = {};
    var touched = { ...this.state.formsTouched };
    if (!this.state._id) {
      let validate  = isEmbeddedEmailEnabled(true) ? this.state.organizationName?.value ?  validationShapewithPassV1() : validationShapewithOutPassV1() : validationShapewithPass();
      validate
        .validate(this.state, { abortEarly: false })
        .then((value) => {
          this.setState({ formErrors: {}, formsTouched: {} });
          isSubmit && this.insertdataa(); 
        })
        .catch((err) => {
          this.setState({ loader: false, isSaving: false, isSpinner: false });
          err &&
            err.inner &&
            err.inner.forEach((e, i) => {
              if (isSubmit && i + 1 === err.inner.length) {
                toastr.show(e.message, "error");
              }
              errors[e.path] = e.message;
              if (isSubmit) touched[e.path] = true;
            });
          this.setState({ formErrors: errors, formsTouched: touched }, () => {
            isSubmit && toastr.show("Please enter all required fields.", "warning");
          });
        });
    } else {
      let validate = isEmbeddedEmailEnabled(true) ? validationShapewithOutPassV1() : validationShapewithOutPass()
      validate
      .validate(this.state, { abortEarly: false })
      .then((value) => {
        this.setState({ formErrors: {}, formsTouched: {} });
        isSubmit && this.insertdataa();
      })
      .catch((err) => {
          this.setState({ loader: false, isSaving: false, isSpinner: false });
          err &&
            err.inner &&
            err.inner.forEach((e, i) => {
              if (isSubmit && i + 1 === err.inner.length) {
                toastr.show(e.message, "error");
              }
              errors[e.path] = e.message;
              if (isSubmit) touched[e.path] = true;
            });
          this.setState({ formErrors: errors, formsTouched: touched }, () => {
            isSubmit && toastr.show("Please enter all required fields.", "warning");
          });
        });
    }
  };

  insertdataa() {
    let valid = true;
    let employPerm = [];
    if (valid) {
      let formData = new FormData();
      let newMobile;

      if (this.state.mobile) {
        newMobile = cleanMobileNumber(this.state.mobile);
      }
      formData.append("firstName", this.state.firstName);

      if(isEmbeddedEmailEnabled(true)) {
        formData.append("lastName", this.state.lastName || "");
      } else {
        formData.append("lastName", this.state.lastName);
      }

      if (
        this.state.notificationEmails &&
        this.state.notificationEmails.length > 0
      ) {
        formData.append(
          "notificationEmails",
          this.state.notificationEmails.toString()
        );
      }
      if(isEmbeddedEmailEnabled()) {
        if(this.state.organizationName?.value) {
          formData.append("CustomerID", this.state.organizationName?.value);
          formData.append("isCustomer", true);
        } else {
          formData.append("addWithOutOrganization", true);
        }
      } else {
        if (this.props.CustomerID) {
          formData.append("CustomerID", this.props.CustomerID);
        }
        if (this.props.CustomerID) {
          formData.append("isCustomer", true);
        }
      }
      // if (this.state.billingEmail) {
        formData.append("billingEmail", this.state.billingEmail);
      // }
      if (this.state.terminals && this.state.terminals.length > 0) {
        let lastTerminalValue = this.state.terminals.map((data) => {
          return data.value;
        });
        formData.append("terminals", JSON.stringify(lastTerminalValue));
      }
      // if (this.state.roles) {
      //   formData.append("roles", this.state.roles);
      // }
      if (this.state.employeePermission) {
        this.state.employeePermission.map((item) => {
          if (item.children && item.children.length) {
            item.checked && employPerm.push(item.values);
            const permissions =  item.children.filter((elem) => elem.checked === true);
            employPerm.push(...permissions.map(element => element.values));
          } else {          
          item.checked && employPerm.push(item.values);
          }
        })
        formData.append(
          "permissions",
          JSON.stringify(employPerm)
        );
      }

      if (this.state.email) {
        formData.append("email", this.state.email);
      }

      if(isEmbeddedEmailEnabled(true)) {
        formData.append("title", this.state.title || "");
        if(this.state.profileUrl) {
          formData.append("profileUrl", this.state.profileUrl)
        }
        formData.append("primaryEmailLabel", this.state.primaryEmailLabel || "")
        if(this.state.alternativeEmailAddresses?.length) {
          formData.append("alternativeEmailAddresses", JSON.stringify(this.state.alternativeEmailAddresses))
        }
        if(this.state.mobileNumbers?.length) {
          formData.append("mobileNumbers", JSON.stringify(this.state.mobileNumbers.map((d) => ({mobile: cleanMobileNumber(d.mobile), label: d.label}))))
        }
        if (this.state.password) {
          formData.append("password", this.state.password);
        } else if(!this.state._id) {
          formData.append("password", generateRandomPasswordForFleet());
        }
      } else {
        formData.append("mobile", newMobile);

        if (this.state.password) {
          formData.append("password", this.state.password);
        }
      }
      if (!this.state._id) {
        this.setState({isSpinner: true});
        this.props.actions.addFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false, isSpinner: false });
          toastr.show("This has been added!", "success");
        })
        .catch((err) => {
          this.setState({isSpinner: false})
          console.log("err -=-=-= ",err)
        })
      } else {
        formData.append("_id", this.state._id);
        this.setState({isSpinner: true});
        this.props.actions.updateFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false, isSpinner: false });
          toastr.show("Updated!", "success");
        })
        .catch((err) => {
          this.setState({isSpinner: false})
          console.log("err -=-=-= ",err)
        })
      }
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }

  ConvertObjectintoArrays (permissionList=[]) {
    let temp = [];

    Object.keys(Permissions).map((permission) => { 
      if (Array.isArray(Permissions[permission])) {
        let final = [];
        Permissions[permission].map(elem => {
          final.push({
            values: elem,
            checked: permissionList.includes(elem)?true:false
          })
        })
        temp.push({values: permission, checked:  permissionList.includes(permission)?true:false, children: final});
      } else {
        temp.push({
          values: permission,
          checked: permissionList.includes(permission)?true:false,
        })
      }
    })
    return temp
  }

  componentDidMount() {
    let organizationName = {label: this.props.selectedCustomer?.company_name, value: this.props.selectedCustomer?._id, allInfos: this.props.selectedCustomer}
    this.setState({employeePermission: this.ConvertObjectintoArrays(), organizationName: organizationName });
    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        let terminals = response.data.data;
        this.Terminals = terminals.map((terminal) => {
          return {
            value: terminal._id,
            label: terminal.name,
          };
        });
        // this.setState({ terminals });
      });
    }
  }

  componentWillMount() {
    this.getdata();
    const currentUser= JSON.parse(getStorage("loggedInUser"))
    if(currentUser.role === "carrier" || 
    (currentUser.role === "fleetmanager" && !currentUser?.isCustomer)){
      this.getCustomerUser();
    }
  }

  getdata(CustomerID) {
    let params = {};
    let customerId = CustomerID ? CustomerID : this.props.CustomerID;
    if (customerId) {
      params.CustomerID = customerId;
    }
    if (this.props.isQuick && !this.props.CustomerID) {
      return;
    }

    if (this.props.isCustomerProfile && !customerId) {
      return;
    }
    this.setState({loading: true})
    this.props.actions.listAllFleetManagers(params).then((result) => {
      this.setState({ allFleetManagers: result, loading: false });
    }).catch((err) => {
      this.setState({loading: false})
    });
  }

  getCustomerUser(){
    const apiCallPayload={
      customerId: this.props?.initialValues?._id
    }
    this.props.actions.getUserByCustomer(apiCallPayload).then((result)=>{
      this.setState({customerUser:result?.data})
    }).catch(err=>{
      this.setState({customerUser:null})
    })
  }
  checkIsPermissionAssigned(roles, rolename) {
    let checked = true;
    if (Array.isArray(rolename)) {
      rolename.forEach((e) => {
        if (roles.indexOf(e) === -1) checked = false;
      });
    } else {
      checked = roles.indexOf({value: rolename, checked: true}) === -1 ? false : true;
    }
    return checked;
  }

  handleChangeFleetManager(event, permission, key, ikey=null) {
      let current_data = [...this.state.employeePermission];
      if (permission.children && permission.children.length>0) {
        current_data[key] = {
          ...current_data[key],
          checked: event.target.checked
        }
        permission.children && permission.children.map((item, index)=> {
          current_data[key].children[index] = {
            ...current_data[key].children[index],
            checked: event.target.checked
          }
        })
      }else if(Number.isInteger(ikey)){
          current_data[key].children[ikey] = {
            ...current_data[key].children[ikey],
            checked: event.target.checked
          }
      } 
      else {
          current_data[key] = {
            ...current_data[key],
            checked: event.target.checked,
          };
      }
      this.setState({
       employeePermission: current_data
      })
    }

  Newform() {
    this.state.allFleetManagers.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ ...blank, selectedEmployee: {}, employeePermission: this.ConvertObjectintoArrays()});
  }
  setval(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "mobile") {
      value = value.replace(/[^0-9]+/g, "");
      this.setState({ [name]: value });
    } else {
      this.setState({ [name]: value });
    }
  }
  insetupdate() {
    let valid = true;
    $(".require").each(function () {
      if ($(this).val().trim() == "") {
        $(this).addClass("errorClass");
        valid = false;
      } else {
        $(this).removeClass("errorClass");
      }
    });
    if (
      this.state.email &&
      !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      toastr.show("Please enter a valid phone number format.", "warning");
      return;
    }
    if (
      !this.state._id &&
      valid &&
      (this.state.password.length < 5 || this.state.password.length > 20)
    ) {
      toastr.show("Please enter a password with a length between 5 to 20 characters.", "warning");
      return;
    }
    if (
      !this.state._id &&
      valid &&
      this.state.password !== this.state.password1
    ) {
      toastr.show("The passwords do not match.  Please try again.", "warning");
      return;
    }
    if (
      ((getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
        (getStorage("currentUserRole") == "carrier" &&
          configuration.idForTerminal.indexOf(getStorage("currentUserID")) !=
            -1)) &&
      (!this.state.terminals || this.state.terminals.length == 0)
    ) {
      toastr.show("Please enter a terminal.", "warning");
      return;
    }

    if (valid && this.state.mobile) {
      let mobile = this.state.mobile;
      mobile =  mobile.replace(/-|_| /g, "");

      if (mobile.length < 10 || mobile.length > 10) {
        toastr.show("Please enter a mobile number with at least 10 digits.", "warning");
        return;
      }

      this.setState({ mobile });
    }
    if (
      this.state.password &&
      (this.state.password.length < 5 || this.state.password.length > 20)
    ) {
      toastr.show("Please enter a password with a length between 5 to 20 characters.", "warning");
      return;
    }
    if (this.state.password && this.state.password !== this.state.password1) {
      toastr.show("The passwords do not match.  Please try again.", "warning");
      return;
    }

    if (valid) {
      let formData = new FormData();
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("mobile", this.state.mobile);
      // formData.append("admin", this.state.admin);
      // formData.append("mechanic", this.state.mechanic);

      if (
        this.state.notificationEmails &&
        this.state.notificationEmails.length > 0
      ) {
        formData.append(
          "notificationEmails",
          this.state.notificationEmails.toString()
        );
      }
      if (this.props.CustomerID) {
        formData.append("CustomerID", this.props.CustomerID);
      }
      if (this.props.CustomerID) {
        formData.append("isCustomer", true);
      }
      // if (this.state.billingEmail) {
        formData.append("billingEmail", this.state.billingEmail);
      // }
      if (this.state.terminals && this.state.terminals.length > 0) {
        let lastTerminalValue = this.state.terminals.map((data) => {
          return data.value;
        });
        formData.append("terminals", JSON.stringify(lastTerminalValue));
      }
      // if (this.state.roles) {
      //   formData.append("roles", this.state.roles);
      // }
      if (this.state.permissions) {
        formData.append(
          "permissions",
          JSON.stringify(this.state.permissions.map((p) => p.value))
        );
      }

      if (this.state.email) {
        formData.append("email", this.state.email);
      }

      if (this.state.password) {
        formData.append("password", this.state.password);
      }
      if (!this.state._id) {
        this.props.actions.addFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("This has been added!", "success");
        });
      } else {
        formData.append("_id", this.state._id);
        this.props.actions.updateFleetManager(formData).then(() => {
          this.getdata();
          this.Newform();
          this.setState({ addUser: false });
          toastr.show("Updated!", "success");
        });
      }
    } else {
      toastr.show("Please enter all mandatory fields properly.", "warning");
    }
  }
  isPermissionChecked=()=>{

  }

  changeColor(id) {
    this.setState({fleetID: id});
    const selectedEmployee = this.state.allFleetManagers.find(elem  => elem._id === id);
      this.setState({employeePermission: this.ConvertObjectintoArrays(selectedEmployee.permissions)});
    if (this.state[id]) {
      this.setState({ [id]: false, ...blank });
      return;
    }
    this.state.allFleetManagers.map((e) => {
      this.setState({ [e._id]: false });
    });
    this.setState({ [id]: true, addUser: true });
    let selectData = _.find(this.state.allFleetManagers, function (s) {
      return s._id == id;
    });
    let setdata = {
      _id: selectData.fleetManager._id,
      email: selectData.email,
      password: selectData.fleetManager.password,
      password1: selectData.fleetManager.password1,
      firstName: selectData.fleetManager.firstName,
      title: selectData?.fleetManager?.title,
      profileUrl: selectData?.fleetManager?.profileUrl,
      primaryEmailLabel: selectData?.fleetManager?.primaryEmailLabel,
      alternativeEmailAddresses: selectData?.fleetManager?.alternativeEmailAddresses || [{email: "", label: ""}],
      mobileNumbers: selectData?.fleetManager?.mobileNumbers?.length > 0 ? selectData?.fleetManager?.mobileNumbers : [{mobile: "", label: ""}],
      lastName: selectData.fleetManager.lastName,
      mobile: selectData.fleetManager.mobile,
      // admin: selectData.fleetManager.admin,
      // mechanic: selectData.fleetManager.mechanic,
      notificationEmails: selectData.fleetManager.notificationEmails
        ? selectData.fleetManager.notificationEmails.split(",")
        : [],
      billingEmail:
        selectData.fleetManager && selectData.fleetManager.billingEmail
          ? selectData.fleetManager.billingEmail
          : "",
      // roles: selectData.fleetManager.roles,
      permissions:
        selectData.permissions && selectData.permissions.length
          ? selectData.permissions.map((p) => ({ label: p, value: p }))
          : [],
      organizationName: selectData?.fleetManager?.customer === this.props.selectedCustomer?._id ? {label: this.props.selectedCustomer?.company_name, value: this.props.selectedCustomer?._id, allInfos: this.props.selectedCustomer} : null
    };
    if (
      selectData.fleetManager.terminals &&
      selectData.fleetManager.terminals.length > 0
    ) {
      let terminals = this.Terminals.filter((terminal) => {
        return selectData.fleetManager.terminals.indexOf(terminal.value) != -1;
      });
      let allTerminal = false;
      if (terminals.length == this.Terminals.length) {
        allTerminal = true;
      }
      this.setState({ allTerminal, terminals });
    }
    this.setState({ ...setdata, selectData });
  }

  updateForAdminEmployee(id){
    this.setState({fleetID:id});
    const selectedEmployee= this.state.customerUser;
    if(selectedEmployee?.permissions?.length>=0 ){
      this.setState({employeePermission: this.ConvertObjectintoArrays(selectedEmployee?.permissions)});
    } 
    if (this.state[id]) {
      this.setState({ [id]: false, ...blank });
      return;
    }
    this.setState({ [id]: true, addUser: true });
    let setData = {
      _id: id,
      permissions: selectedEmployee?.permissions?.length
          ? selectedEmployee?.permissions?.map((p) => ({ label: p, value: p }))
          : [],
    };
    this.setState({ ...setData });
  }

  updatePermissionForAdminEmployee(){
    const updatePermissionPayload = {};
    updatePermissionPayload.customerId= this.state?.customerUser?.customer;
    if (this.state.employeePermission) {
      const employPerm = [];
      this.state.employeePermission.forEach((item) => {
        if (item?.children?.length) {
          if (item?.checked) employPerm.push(item?.values);
          const permissions =  item?.children?.filter((elem) => elem?.checked === true);
          employPerm.push(...permissions?.map(element => element?.values) ?? []);
        } else {          
        if (item?.checked) employPerm.push(item?.values);
        }
      })
    updatePermissionPayload.permissions= employPerm;
    }
    this.props.actions.updateUserPermissionByCustomer(updatePermissionPayload).then((updatedData)=>{
      this.setState({ addUser: false, isSpinner: false,customerUser:updatedData?.data });
      toastr.show("Updated!", "success");
    }).catch(err=>{
      this.setState({isSpinner: false})
      console.log("err -=-=-= ",err)
    })
  }

  handleChange = (values) => {
    let terminals = [];
    if (values.length) {
      terminals = JSON.parse(JSON.stringify(values));
    }
    if (terminals.length == this.Terminals.length) {
      this.setState({ allTerminal: true });
    } else {
      this.setState({ allTerminal: false });
    }
    this.setState({ terminals });
  };

  onDeleteClick = (id) => {
    confirm(
      "Delete Employee?",
      `Are You sure to Delete this Employee ?`,
      (confirm) => {
        if (confirm) {
          this.props.actions.removeFleetManager(id).then(() => {
            let allFleetManagers = this.state.allFleetManagers.filter(
              (item) => item._id !== id
            );
            this.setState({ allFleetManagers: allFleetManagers });
            toastr.show("Removed!", "success");
            amplitudeTrack( `DELETE_PERSON_BUTTONE`,{source: 'employees_tab'})
          });
        }
      }
    );
  };
  togglePasswordVisiblity = () => {
    this.setState({ passwordShown: !this.state.passwordShown });
  };
  setChangePasswordId(data, role) {
    this.setState({
      employeeData: data, role: role
    })
  }

  addAleternativeEmails = () => {
    let data = [...this.state.alternativeEmailAddresses]
    data.push({email: "", label: ""})
    this.setState({alternativeEmailAddresses: data})
  }

  handleRemoveAlternativeEmail = (index) => {
    let data = this.state.alternativeEmailAddresses?.filter((d, i) => i !== index)
    this.setState({alternativeEmailAddresses: data}, () => {
      if(this.state.alternativeEmailAddresses?.length === 0) {
        let alternativeEmailAddresses = [...this.state.alternativeEmailAddresses]
        alternativeEmailAddresses.push({email: "", label: ""})
         this.setState({alternativeEmailAddresses})
      }
    })
  }

  handleChangeAlternativeEmail = (event, index, key) => {
    let alternativeEmailAddresses = this.state.alternativeEmailAddresses;
    alternativeEmailAddresses = alternativeEmailAddresses.map((d, i) => {
      if(index === i) {
        if(key === "label") d.label = event.target.value
        if(key === "email") d.email = event.target.value
      }
      return d
    })
    this.setState({alternativeEmailAddresses})
  }

  addMobileNumbers = () => {
    let data = [...this.state.mobileNumbers]
    data.push({mobile: "", label: ""})
    this.setState({mobileNumbers: data})
  }

  handleRemoveMobileNumbers = (index) => {
    let data = this.state.mobileNumbers?.filter((d, i) => i !== index)
    this.setState({mobileNumbers: data}, () => {
      if(this.state.mobileNumbers?.length === 0) {
        let mobileNumbers = [...this.state.mobileNumbers]
        mobileNumbers.push({mobile: "", label: ""})
         this.setState({mobileNumbers})
      }
    })
  }

  handleChangeMobileNumbers = (event, index, key) => {
    let mobileNumbers = this.state.mobileNumbers;
    mobileNumbers = mobileNumbers.map((d, i) => {
      if(index === i) {
        if(key === "label") d.label = event.target.value
        if(key === "mobile") d.mobile = event.target.value
      }
      return d
    })
    this.setState({mobileNumbers})
  }
  render() {
    let { formErrors, formsTouched } = this.state;
    return (
      <div>
        <div className="card p-20 mb-0">
          <button
            className="ml-auto btn btn-primary"
            onClick={() => {
              this.Newform();
              this.setState({ addUser: true });
            }}
          >
            <IconPlus className="mr-2" />
            Add People
          </button>
        </div>
        <div className="page-content container-fluid px-0">
          <div className="table-responsive">
          {this.state.loading ? (<LoaderBar />) : (
            <table className="table table-card table-card--ls">
              <thead>
                <tr>
                  {columns.map((column) => {
                    return (
                      <th scope="col" key={column}>
                        {column}
                      </th>
                    );
                  })}
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                  <th style={{ width: "2%" }}></th>
                </tr>
              </thead>
              <tbody>
                {((getStorage("currentUserRole") == "fleetmanager" && !(JSON.parse(getStorage("loggedInUser")).fleetManager && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer)) || getStorage("currentUserRole") == "carrier" ) &&
                  <tr>
                    <td>{this.props.initialValues && this.props.initialValues.main_contact_name?.split(" ")[0]}</td>
                    <td>{this.props.initialValues && this.props.initialValues.main_contact_name?.split(" ").splice(1).join(" ")}</td>
                    <td>{this.props.initialValues && this.props.initialValues.mobile}</td>
                    <td>{this.props.initialValues && this.props.initialValues.email}</td>
                    <td>{this.props.initialValues && this.props.initialValues.email && 'Admin'}</td>
                    <td>
                          <button className="btn btn-link">
                            <span onClick={() => this.updateForAdminEmployee(this.state?.customerUser?._id)}>
                              <IconPen />
                            </span>
                          </button>
                        </td>
                    <td className="text-center">
                      { this.props.initialValues && this.props.initialValues.isDeleted ? null : (
                        <button
                          type="button"
                          title="Change Password"
                          className="btn btn-link text-muted"
                          onClick={() => this.setChangePasswordId(this.props.initialValues, 'Admin')}
                        >
                          <IconKey />
                        </button>
                      )}
                    </td>
                    <td></td>
                  </tr>
                }
                  {this.state.allFleetManagers.map((datum) => {
                    return (
                      <tr key={datum.id}>
                        <td>
                          <a
                            href="javascript:void(0)"
                            className="text-primary font-weight-500"
                          >
                            {datum.fleetManager.firstName}
                          </a>
                        </td>
                        <td>{datum.fleetManager.lastName}</td>
                        <td>
                          {isEmbeddedEmailEnabled(true)
                            ? datum.fleetManager.mobileNumbers?.map((d) => d.mobile)?.join(", ")
                            : (datum.fleetManager.mobile || "")}
                        </td>
                        <td>{datum.email}</td>
                        <td>{datum.email && 'Standard'}</td>
                        <td>
                          <button className="btn btn-link">
                            <span onClick={() => this.changeColor(datum._id)}>
                              {" "}
                              <IconPen />{" "}
                            </span>
                          </button>
                        </td>
                        <td className="text-center">
                      {datum.isDeleted ? null : (
                        <button
                          type="button"
                          title="Change Password"
                          className="btn btn-none text-muted"
                          onClick={() => this.setChangePasswordId(datum, 'Standard')}
                        >
                          <IconKey />
                        </button>
                       )} 
                    </td>
                        <td>
                          <button className="btn btn-link text-muted">
                            <span onClick={() => this.onDeleteClick(datum._id)}>
                              {" "}
                              <IconTrash />{" "}
                            </span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          </div>
        </div>
        {!this.isPerson ?
          this.state.addUser &&
          <Modal show={this.state.addUser} dialogClassName="modal-xl modal-dialog-centered modal-dialog-scrollable">
            <Modal.Header>
              <h4 className="modal-title">
                {this.state._id ? `Update ${updateTerminology('employee')}` : `Add ${updateTerminology('employee')}`}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="form-wrapper">
                <form>
                  {((this.state?._id + "" !== this.state.customerUser?._id+ "")
                  || !this.state?.customerUser) &&
                  (<> 
                  <div className="form-row">
                    <div className=" col-md-4">
                      <StateFormInput
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        isRequired={true}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"First Name"}
                        value={this.state.firstName}
                      />
                    </div>
                    <div className=" col-md-4">
                      <StateFormInput
                        type="text"
                        name="lastName"
                        isRequired={true}
                        placeholder="Enter Last Name"
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Last Name"}
                        value={this.state.lastName}
                      />
                    </div>
                    <div className="col-md-4">
                      {JSON.parse(getStorage("userBasicSettings"))?.phoneFormat === 'international' ? 
                      <StateFormNumberIntFormat
                        type="text"
                        name="mobile"
                        mask="_"
                        isRequired={true}
                        value={this.state.mobile}
                        onValChange={this.setValChange}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        placeholder="Enter Phone Number"
                        label={"Phone"}
                    />
                     :
                      <StateFormNumberFormat
                        type="text"
                        name="mobile"
                        format="###-###-####"
                        mask="_"
                        isRequired={true}
                        value={this.state.mobile}
                        onValChange={this.setValChange}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        placeholder="Enter Phone Number"
                        label={"Phone"}
                      />
                      }
                      
                    </div>
                    <div className=" col-md-12">
                      <StateFormInput
                        type="text"
                        isRequired={true}
                        name="email"
                        placeholder="Enter Email "
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Email"}
                        value={this.state.email}
                      />
                    </div>
                    <div className=" col-md-6">
                      <div className="input-wrapper">
                        <StateFormInput
                          type={this.state.passwordShown ? "text" : "password"}
                          isRequired={!this.state._id ? true : false}
                          placeholder="Enter Password "
                          name="password"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Password"}
                          value={this.state.password}
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px" , marginBottom:"50px"}}
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                        {this.state.passwordShown ? <IconEye /> : <IconEyeHidden />} 
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-6">
                      <div className="input-wrapper">
                        <StateFormInput
                          type={this.state.passwordShown ? "text" : "password"}
                          placeholder="Enter Password"
                          isRequired={!this.state._id ? true : false}
                          name="password1"
                          formErrors={formErrors}
                          formsTouched={formsTouched}
                          onValChange={this.setValChange}
                          label={"Repeat Password"}
                          value={this.state.password1}
                        />
                        <div
                          className="input-icon"
                          style={{ top: "35px", marginBottom :"50px" }}
                          onClick={() => this.togglePasswordVisiblity()}
                        >
                        {this.state.passwordShown ? <IconEye /> : <IconEyeHidden />} 
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <StateFormInput
                        type="email"
                        // isRequired={true}
                        placeholder="Enter Notification Email "
                        name="billingEmail"
                        value={this.state.billingEmail}
                        formErrors={formErrors}
                        formsTouched={formsTouched}
                        onValChange={this.setValChange}
                        label={"Notification Email"}
                      />
                    </div>
                  </div>
                  <div className="hr-light my-20"></div>
                  <div className="form-row align-items-center">
                    <div className="ml-2 text-capitalize">
                      {`Email Notifications have been moved to Settings > Notification Settings, click this link to take you there: `}
                      <a className="text-primary" onClick={() => browserHistory.push(`/settings/notifications#templates`)}>Notification Settings</a>
                    </div>
                  </div>

                  <div className="hr-light"></div>
                  </>)
                  }

                  {/* {this.state.allFleetManagers &&
                    this.state._id && this.state.fleetID &&
                    this.state.allFleetManagers.filter(elem => this.state.fleetID === elem._id).map((info, i) => {
                      //#region
                      return ( */}
                        <div
                          // key={i}
                          // id=""
                          className="d-flex flex-column"
                        >
                          <div className="font-14 font-medium mb-20">Permission</div>
                          {this.state.employeePermission.map((permission, key) => {
                            if(!isNewBillingEnabled() && permission.values === 'account_payable_invoices') return <></>
                            if(this.checkIsManageFleet?.isOutSource && ["customer_shipments"].includes(permission.values)) return <></>
                            return (
                              <div className="" key={key}>
                              <div className="form-group form-check">
                                <input
                                  name="permissions"
                                  id={`check${key}_${
                                    permission.values
                                  }`}
                                  checked={permission.checked}
                                  type="checkbox"
                                  className="form-check-input"
                                  data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                  // data-permission={Permissions[
                                  //   permission
                                  // ].toString()}
                                  onChange = {(e) => this.handleChangeFleetManager(e, permission, key)}
                                  disabled={getStorage("currentUserRole") === "customer" || (getStorage("currentUserRole") === "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false)}
                                />
                                <label
                                  className="form-check-label text-capitalize"
                                  // htmlFor={`check${key}_${
                                  //   permission.values
                                  // .toString()}`}
                                >
                                  {updateTerminology(employeePermission.find(item => item.value === permission.values) && employeePermission.find(item => item.value === permission.values).label)}
                                </label>
                              </div>
                              {permission.children && permission.children.length>0 && (
                              <div className="bg-gray-50 px-10 pt-10 mb-15">
                                <div className="form-row">
                                  {permission.children.map((d, index) => {
                                      return (
                                        <div className="col-md-3">
                                          <div
                                            className="form-group form-check"
                                            key={`${index}-children`}
                                          >
                                            <input
                                              name="permissions"
                                              id={`check${
                                                index + "_" + key
                                              }_${d.values}`}
                                              checked={d.checked}
                                              type="checkbox"
                                              className="form-check-input"
                                              data-userId={this.state.selectedEmployee && this.state.selectedEmployee._id || ""}
                                              // data-permission={d}
                                              onChange={(e) => this.handleChangeFleetManager(e, d, key, index)}
                                              disabled={getStorage("currentUserRole") === "customer" || (getStorage("currentUserRole") === "fleetmanager" && JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer ? JSON.parse(getStorage("loggedInUser")).fleetManager.isCustomer : false)}
                                            />
                                            <label
                                              className="form-check-label text-capitalize"
                                              htmlFor={`check${
                                                index + "_" + key
                                              }_${d.values}`}
                                            >
                                              {employeePermission.find(item => item.value === d.values) && employeePermission.find(item => item.value === d.values).label}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              )

                              }
                              
                            </div>
                            )
                          })}
                        </div>
                </form>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-close"
                onClick={() => {
                  this.setState({ addUser: false });
                }}
              >
                Close
              </button>
              <button
                className={`btn ${
                  this.state._id ? "btn-primary" : "btn-success"
                }`}
                onClick={() => {
                  if((this.state?._id + "" === this.state?.customerUser?._id+"")
                  && this.state?.customerUser){
                    this.updatePermissionForAdminEmployee();
                    amplitudeTrack( `UPDATE_PERSON_BUTTONE`,{source: 'employees_tab'})
                  }else{
                    this.insertdata();
                    amplitudeTrack( `ADD_PERSON_BUTTONE`,{source: 'employees_tab'})
                  }
                }}
                disabled={this.state.isSpinner}
              >
                {this.state.isSpinner && <span 
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"></span>
						    }
                {this.state._id ? "Update Employee" : "Add Employee"}
              </button>
            </Modal.Footer>
          </Modal>
        : this.state.addUser &&
          <EditPersonModal
            show={this.state.addUser}
            hide={() => this.setState({ addUser: false })}
            selectedData={this.state.selectData}
            formErrors={formErrors}
            formsTouched={formsTouched}
            employeePermission={this.state.employeePermission}
            setValChange={this.setValChange}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            title={this.state.title}
            organizationName={this.state.organizationName}
            handleOrganizationName={(val) => this.setState({organizationName: val})}
            profileUrl={this.state.profileUrl}
            handleProfileUrl={(data) => this.setState({ profileUrl: data })}
            email={this.state.email}
            billingEmail={this.state.billingEmail}
            password={this.state.password}
            togglePasswordVisiblity={this.togglePasswordVisiblity}
            passwordShown={this.state.passwordShown}
            togglePassword1Visiblity={() => this.setState({password1Shown: !this.state.password1Shown})}
            password1Shown={this.state.password1Shown}
            password1={this.state.password1}
            _id={this.state._id}
            selectedEmployee={this.state.selectedEmployee}
            handleChangeFleetManager={this.handleChangeFleetManager}
            isSpinner={this.state.isSpinner}
            updatePermissionForAdminEmployee={this.updatePermissionForAdminEmployee}
            insertdata={this.insertdata}
            customerUser={this.state.customerUser}
            mobileNumbers={this.state.mobileNumbers}
            addMobileNumbers={this.addMobileNumbers}
            handleRemoveMobileNumbers={this.handleRemoveMobileNumbers}
            handleChangeMobileNumbers={this.handleChangeMobileNumbers}
            alternativeEmailAddresses={this.state.alternativeEmailAddresses}
            addAleternativeEmails={this.addAleternativeEmails}
            handleRemoveAlternativeEmail={this.handleRemoveAlternativeEmail}
            handleChangeAlternativeEmail={this.handleChangeAlternativeEmail}
            selectedCustomer={this.props.selectedCustomer}
            callers={this.props.callers}
            primaryEmailLabel={this.state.primaryEmailLabel}
            isAdmin={(this.state?._id + "" !== this.state.customerUser?._id+ "") || !this.state?.customerUser}
            isFromOrg={true}
          />
        }
      
           <Modal show={this.state.employeeData} className="show">
            <ResetCustomerPassword
              setResetId={() => this.setState({ role: undefined, employeeData:undefined})}
              employeeData={this.state.employeeData}
              actions={this.props.actions}
              customerActions={this.props.customerActions}
              passwordShown={this.state.passwordShown}
              togglePasswordVisiblity={this.togglePasswordVisiblity}
              role={this.state.role}
            />
          </Modal> 
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actionCreators, dispatch),
    customerActions:bindActionCreators(customerActionCreators, dispatch),
  };
}
function mapStateToProps(state) {
  return {
    profile: state.ProfileReducer.profile,
    callers: state.TmsReducer.callers,
  };
}


const validationShapewithPass = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : 
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validSchema = Yup.object().shape({ 
    mobile: Yup.string()
      .matches(phoneRegExp, "Mobile is not valid")
      .required("Mobile is Required."),
    lastName: Yup.string().required("This field is Required."),
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string().required("This field is Required."),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email"),
    Permission: Yup.object(),
  });
  return validSchema;
} 

const validationShapewithPassV1 = () => {
  const validSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string().required("This field is Required."),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email"),
    Permission: Yup.object(),
  });
  return validSchema;
} 

const validationShapewithOutPass = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : 
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validSchema = Yup.object().shape({ 
    mobile: Yup.string()
      .matches(phoneRegExp, "Mobile is not valid")
      .required("Mobile is Required."),
    lastName: Yup.string().required("This field is Required."),
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string(),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    Permission: Yup.object(),
  });
  return validSchema
}

const validationShapewithOutPassV1 = () => {
  let phoneformat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat;
  const phoneRegExp = phoneformat == 'international'?  /^(\+|00)[1-9][0-9 \-\(\)\.]{7,20}$/ : 
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validSchema = Yup.object().shape({ 
    firstName: Yup.string().required("This field is Required."),
    email: Yup.string()
      .email("Must be a valid email")
      .required("This field is Required."),
    password: Yup.string(),
    password1: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        let mainPass = this.parent.password
        if(value === undefined || mainPass === undefined) {
          value = ""
          mainPass = ""
        }
        return mainPass === value;
      }
    ),
    billingEmail: Yup.string()
      .email("Must be a valid email"),
    Permission: Yup.object(),
  });
  return validSchema
}

const ResetForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = props;
  return (
    <div className="center-child">
      <Modal.Header>
        <h4 className="modal-title" id="myModalLabel">
          Reset Password
        </h4>
        <button
          type="button"
          className="close"
          onClick={() => props.setResetId()}
        >
          <IconTimes />
        </button>
      </Modal.Header>
      <div className="sign-up-form">
        <form className="form" onSubmit={handleSubmit}>
          <div>
            <Modal.Body>
              <div className={`form-group  ${errors.password && "has-error"}`}>
                <label htmlFor="password">
                  New Password
                  <span
                    className="required-field"
                    title="This field is reqired."
                  >
                    *
 
                  </span>
                </label>
                <input
                  type={props.passwordShown ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                <div
                  className="input-icon"
                  style={{ top: "35px" , marginBottom:"50px"}}
                  onClick={()=>props.togglePasswordVisiblity()}
                >
                {props.passwordShown ? <IconEye /> : <IconEyeHidden />} 
                </div>
                {errors.password && touched.password && (
                  <span className="text-danger text-capitalize">{errors.password}</span>
                )}
              </div>
              {isSubmitting && (
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <Spinner />
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-close"
                onClick={() => {
                  props.setResetId();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                name="resetCustomerPassword"
                id="resetCustomerPassword"
                value="Save"
                className="btn btn-danger"
              >
                Reset
              </button>
            </Modal.Footer>
          </div>
        </form>
      </div>
    </div>
  );
};

const ResetCustomerPassword = withFormik({
  mapPropsToValues: (props) => ({
    password: "",
    confirmPassword: "",
  }),
  validationSchema: Yup.object().shape({
    password: Yup.string()
      .min(5, "Enter atleast 5 characters.")
      .max(25, "Enter less than 25 characters.")
      .required("Password is required."),
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const role = props.role
    const password = values.password;
    const customerId =props.employeeData._id;
    
    if (role === "Admin") {
      props.customerActions
        .resetCustomerPassword({
          customerId,
          password,
        })
        .then((result) => {
          props.setResetId();
          toastr.show("Your password has been successfully changed!", "success");
          setSubmitting(false);
        })
        .catch((Err) => { })
        .finally(() => {
          setSubmitting(false);
        });
        
    }
    else {
      const _id =  props.employeeData.fleetManager._id
      const firstName = props.employeeData.fleetManager.firstName
      const lastName = props.employeeData.fleetManager.lastName
      const mobile =props.employeeData.fleetManager.mobile
      props.actions
        .updateFleetManager({
          _id,
          firstName,
          lastName,
          mobile,
          password
         
        })
        .then((result) => {
          
          props.setResetId();
          toastr.show("Your password has been successfully changed!", "success");
          setSubmitting(false);
        })
        .catch((Err) => { console.log("Err----", Err); })
        .finally(() => {
          setSubmitting(false);
        });
    }
  },
})(ResetForm);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerFleetManagers);
