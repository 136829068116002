import { updateTerminology } from "services";

export const email_options = [
  "referenceNo",
  "invoiceNo",
  "combinedInvoice",
  "LoadNo",
  "callerName",
  "shipperName",
  "consigneeName",
  "chassisPickName",
  "emptyOriginName",
  "chassisTerminationName",
  "containerTypeName",
  "containerSizeName",
  "containerOwnerName",
  "containerNo",
  "chassisTypeName",
  "chassisSizeName",
  "chassisOwnerName",
  "weight",
  "hot",
  "hazmat",
  "temperature",
  "purchaseOrderNo",
  "bookingNo",
  "shipmentNo",
  "callerbillLandingNo",
  "doNo",             
  "sealNo",            
  "deliveryOrderNo",  
  "releaseNo",    
  "callerPONo",      
  "appointmentNo",      
  "returnNo",           
  "reservationNo", 
  "subCombinedInvoice",
];
export const auto_email = [
  "invoiceNo",
  "customerName",
  "companyName"
]

export const LEFT_SIDEBAR_TABS = [
  { route: "/settings/general-info", label: "Company Info" },
  { route: "/account/email-configuration", label: "Email Configuration" },
  { route: "/account/document-setup", label: "Document Configuration" },
  { route: "/account/accounting-preference", label: "Accounting Preference" },
  { route: "/account/equipment-setting", label: "Equipment Setting" },
  {
    route: "/account/equipment-parameters",
    label: "Profile Equipment Parameters",
  },
  { route: "/account/geo-fence", label: "Geo Fence" },
];

// Check if all Object Properties are Null
export const isNullUndefEmptyStr = (arr) => {
  let result;
  arr.map((obj) => {  
    if(obj.sno === undefined || obj.sno && obj.chassisType === null && obj.containerSize === "" && 
      obj.firstValue === null && obj.secondOperator === "" && obj.secondValue === null 
      && obj.size === "" && obj.type === "" ) {
      result = true;
    }else{
      result = false;
    }
  })
  return result;
}

export const configurationTabHeaders = {
  companyLogo: "Company Logo",
  address: "Address",
  invoiceNotes: "Invoice Notes",
  podNotes: "POD Notes",
  rateConNotes: "Rate Con Notes",
  emailNotificationFooter: "Email Notification Footer",
  deliveryOrder: "Delivery Order",
  rateNote: "Rate Note"
}

export const documentConfigHeaders = {
  CONFIGURATION: "Configuration",
  DESIGNER: "Designer"
}

export const externalIDLabel =  "Add IDs to Profiles"

export const validationsTableHeaders = {
  CONTAINER_SIZE: "Container Size",
  CONTAINER_TYPE: "Container Type",
  CHASSIS_SIZE: "Chassis Size",
  CHASSIS_TYPE: "Chassis Type"
}

export const parametersTableHeader = [
  "Container Size",
  "Container Type",
  "Field To Change",
  "Chassis Type",
  "Condition",
  "Amount ",
  "",
  "Condition",
  "Amount",
  " ",
];

export const terminalLabels = {
  TERMINAL: "Terminal",
  CUSTOMIZE_CONTENT: `Customize content for each ${updateTerminology('terminal')}`,
}

export const configurationLabels = {
  EMAIL_CONTENT: "Email Content",
  EMAIL_FROM: "Email From",
  EMAIL_SUB: "Email Subject",
  EMAIL_BODY: "Email Body",
  INVOICE_EMAILS: "Invoice Emails",
  EMAIL_INVOICE: "Email address to be CCed on every invoice"
}