import { smallSelectStyle } from "../../../assets/js/select-style";
import { ClearIndicator } from "./components";
import { IconAI } from "../../Common/Icons";
import CheckboxAsyncSelect, { ValueContainer } from "Components/Common/CustomSelect/CheckboxAsyncSelect";
import { useRef, useState } from "react";
const CustomReactSelect = (props) => {
  const {
    value,
    onChange,
    isDisabled,
    loadOptions,
    isRequired,
    label,
    className,
    err,
    touched,
    formErrors,
    name,
    showAIicon,
    options,
    onBlur,
    formatOptionLabel,
    placeholder,
    isMulti,
    isClearable,
    isCustom=false
  } = props;
  const getDefaultOptions = () => {
    const defaultOpts = props.defaultOptions || [];
    if (Array.isArray(value)) {
      return value.reduce((acc, curr) => {
        const existingOpt = defaultOpts.find((opt) => opt.value === curr.value);
        if (!existingOpt) {
          acc.push(curr);
        }
        return acc;
      }, defaultOpts);
    }
    return defaultOpts;
  };
  const components = {
    ValueContainer: (e) => <ValueContainer {...(e || {})} isClearable={isClearable} name={name} isCustom={isCustom}/>,
    ClearIndicator: (e) => <ClearIndicator {...(e || {})} isClearable={isClearable}  name={name} isCustom={isCustom}/>,
  };
  return (
    <div className={`form-group ${className} ${err && touched && "error"}`}>
      <div>
        <label>
          {isRequired && <span className="text-danger">* </span>}
          {label}
        </label>
        {showAIicon && <IconAI className="text-primary ml-1" />}
        <div>
          <CheckboxAsyncSelect
            name={name}
            styles={smallSelectStyle}
            isMulti={isMulti}
            defaultOptions={getDefaultOptions()}
            value={value}
            onChange={onChange}
            loadOptions={loadOptions}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isAsync={loadOptions ? true : false}
            components={components}
            onBlur={onBlur}
            formatOptionLabel={formatOptionLabel}
            options={options}
            isClearable={true}
            className="not-show-count"
            isCustom={isCustom}
          />
            {err && touched && <small className="text-danger">{formErrors[name]}</small>}
        </div>
      </div>
    </div>
  );
};
export default CustomReactSelect;
