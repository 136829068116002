import moment from 'moment';
import React from 'react';
import { getStorage, updateTerminology } from '../../../../../services';
import { titleCase } from '../../utility';

const LoadDetails = ({ loadDetail }) => {
    const timeZone = getStorage("timeZone");
    return (
        <div className='px-15'>
            <div className='d-flex align-items-start justify-content-between mb-10 '>
                <span className='text-muted font-12 font-weight-normal'>Load #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.reference_number || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Reference #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.secondaryReferenceNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Terminal (Branch)</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.terminal ? loadDetail?.terminal?.name : "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>{updateTerminology("Port")}</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.shipperName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Delivery Warehouse</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.consigneeName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Return Location</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{titleCase(loadDetail?.emptyOriginName || "-")}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Container #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.containerNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Seal #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.sealNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Vessel Name</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.deliveryOrderNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Master BoL #</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{loadDetail?.callerbillLandingNo || "-"}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Deliver Load Date</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{`${loadDetail?.deliverTime ? moment(loadDetail.deliverTime).tz(timeZone).format("MM/DD hh:mm a") : "-"}`}</span>
            </div>
            <div className='d-flex align-items-start justify-content-between mb-10'>
                <span className='text-muted font-12 font-weight-normal'>Return Container Date</span>
                <span className='text-muted text-dark font-weight-normal font-12 text-right'>{`${loadDetail?.returnTime ? moment(loadDetail?.returnTime).tz(timeZone).format("MM/DD hh:mm a") : "-"}`}</span>
            </div>
        </div>
    );
}

export default LoadDetails;
