import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const MasterBillOfLading = ({ row, column, onRowChange, isCellSelected }) => {
	const dispatch = useDispatch();
	const fAction = useSelector((state) => state.dispatcherTableReducer);
	const masterBillOfLading = row.type_of_load == "EXPORT" ? "bookingNo" : (row.type_of_load === "IMPORT" ? "callerbillLandingNo" : (row.type_of_load == "ROAD" ? "secondaryReferenceNo" : "")) 
	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === masterBillOfLading) {
			onRowChange({ ...row, [masterBillOfLading]: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		} else if (fAction.reference_number === row.reference_number && fAction.field === masterBillOfLading) {
			setTimeout(() => {
				dispatch({ type: 'CLEAR_VALUE' });
			}, 500);
		}
	}, [fAction]);

	return (
		<div className="cell-content">
			{row.type_of_load == "EXPORT" && <div className="rdg-truncate">{row.bookingNo}</div>}
			{["IMPORT", "ROAD"].includes(row.type_of_load) && (
				<div className="rdg-truncate">{row.callerbillLandingNo}</div>
			)}
		</div>
	);
};

export default MasterBillOfLading;
