import React, { memo, useEffect, useState, useRef } from 'react'
import { IconDownload, IconPlus, IconSendEmail, IconTrash, Icondots, IconCancel } from 'Components/Common/Icons';
import { smallSelectStyle } from 'assets/js/select-style';
import BillingConstants from 'pages/tms/Load/Billing/Constants/billingData.json'
import StatusBadge from '../../Badge/StatusBadge';
import tabChangeConfirm from "pages/tms/Load/TabChangeConfirm";
import {
    makeOptionsForSelect, isGlobalizationEnabled, toastr, getStorage, isManageFleetOrOutSource, enableNewCustomerApprovalStatus
} from "services/Common.services";
import { CREDITMEMODISPLAY } from "pages/tms/CreditMemo/constants";
import AsyncSelect from "react-select/async";
import * as actionCreators from "pages/tms/Dispatcher/actionCreators";
import { connect } from 'react-redux';
import { addNewCustomer, checkBillToForShiposUser, deleteBillingCharge, updateCustomer } from 'pages/tms/Load/Billing/actionCreator';
import { getBillingChargeLists } from 'pages/tms/Load/Billing/actionCreator'
import { bindActionCreators } from 'redux';
import { transitionEffect } from 'pages/tms/Load/Billing/Utils/index';
import CreditMemoModalV2 from 'pages/tms/CreditMemoV2/CreditMemoModalV2';
import { getInvoicePdfByCharge } from 'pages/tms/DocumentConfiguration/actionCreators';
import axios from 'axios';
import EmailModal from '../../EmailModal';
import { LOAD_CHARGE_STATUS } from 'pages/tms/carrier/AccountReceivable/constants';

import ReactTooltip from 'react-tooltip';
import { CUSTOMER_APPROVAL_STATUS, INSUFFICIENT_CREDIT_MODAL_TYPE } from '../../../Constants/constants';
import { amplitudeTrack, isShiposCarrier, checkAccountReceivablePermission } from '../../../../../../../services';
import { checkUserPermisison, isNewBillingEnabled } from '../../../../../../../services';
import { getApprovalPricingHistory } from '../../../../actionCreator';
import WarningModal  from "../WarningModal";
import InsufficientCreditModal from "../InsufficientCreditModal";
import _ from "lodash";
import { LOAD_LABELS } from '../../../../../../../labels';

const CardHeader = (props) => {
    const { billingCards, isSubExpBillTabActive, setIsSubExpBillTabActive, handleCleartableData, isBlockingTabChange, handleExpenseTab, loads, billingIndex, billingRow, setBillingCards, updateChargeInChargeList, totalCredits, getAllCreditMemoForLoad, allCredits, handleReloadOfBillingSummary, setOpenEmailModal, openEmailModal, handleShowInvoiceHistory } = props;
    const { CREDIT_MEMO, DOWNLOAD_PDF, SEND_INVOICE, RATE_CONF, DOWNLOAD_DRAFT_INVOICE } = BillingConstants;
    const { INVOICED, PARTIALLY_PAID, PAID, APPROVED, DRAFT,UNAPPROVED,REBILLING } = LOAD_CHARGE_STATUS;
    const [isCredit, setIsCredit] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [findCustomer, setFindCustomer] = useState(null);
    const [isCreateMemo, setIsCreateMemo] = useState();
    const [badgeStatus, setBadgeStatus] = useState("");
    const [isDownloadInProcess,setIsDownloadInProcess] = useState(false)
    const [isConnectUser, setIsConnectUser] = useState(JSON.parse(getStorage("userBasicSettings") ?? "{}")?.isConnectedWithOutSource || isShiposCarrier())
    const checkIsManageFleetOrOutsource = isManageFleetOrOutSource()
    const dropdownRef = useRef(null);
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
    const { billingEmailSendPermission, billingInvoiceDownloadPermission, billingInvoiceEditPermission, 
        creditMemoEditPermission, billingInvoiceDeletePermission, creditMemoDeletePermission } = checkAccountReceivablePermission();
    let timer; //saved timer
    const [chargeSetToastMsg, setChargeSetToastMsg] = useState();
    const [showChargeSetToast, setShowChargeSetToast] = useState(false);

    const [invoiceChargeSetToastMsg, setInvoiceChargeSetToastMsg] = useState();
    const [showInvoiceChargeSetToast, setShowInvoiceChargeSetToast] = useState(false);
    const [billToIsShipOSUser, setBillToIsShipOSUser] = useState(false);
    const [selectedBillToDetails, setSelectedBillToDetails] = useState();
    const [insufficientCreditModal, setInsufficientCreditModal] = useState({ isOpen: false, billToId: null, type: INSUFFICIENT_CREDIT_MODAL_TYPE.INSUFFICIENT_CREDIT })

    const flag = JSON.parse(getStorage("userBasicSettings") ?? "{}")?.creditFlag ?? false;
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (CREDITMEMODISPLAY.includes(loads.status)) {
            setIsCredit(true);
        }
    }, [loads, isCredit]);

    useEffect(() => {
        if (billingRow) {
            const valueData = [
                APPROVED,
                INVOICED,
                PARTIALLY_PAID,
                PAID,
            ].includes(billingRow?.status);
            setDisabled(valueData);


            const obj = billingRow?.billToId ? { value: billingRow?.billToId?._id, label: billingRow?.billToId?.company_name } : null;
            setFindCustomer(obj);

        } else if (isGlobalizationEnabled()) {
            setDisabled(true);
        }

        if(billingRow?.customerApprovalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet) {
            const params  = { 
                reference_number: billingRow?.isDefault ?  billingRow?.load_reference_number : billingRow?.charge_reference_number ,
                charge_reference_number: billingRow?.charge_reference_number,
                isDefault: billingRow?.isDefault,
              };
            getApprovalPricingHistory(params).then((data) => {
                data = data?.filter(history=>history?.historyType==="charge");
                if(data?.length && data[data?.length - 1]?.status === CUSTOMER_APPROVAL_STATUS.REJECTED) {
                    setChargeSetToastMsg(data[data?.length - 1]?.reason);
                    setShowChargeSetToast(true);
                }
            }).catch((e) => {});
        }

        if(billingRow?.invoice?.invoiceId?.customerInvoiceApprovalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet) {
            const params  = { 
                reference_number: billingRow?.isDefault ?  billingRow?.load_reference_number : billingRow?.charge_reference_number ,
                charge_reference_number: billingRow?.charge_reference_number,
                isDefault: billingRow?.isDefault,
              };
            getApprovalPricingHistory(params).then((data) => {
                const invoiceTypeHistory = data?.filter(history=>history?.historyType==="invoice");
                if(invoiceTypeHistory?.length && invoiceTypeHistory[invoiceTypeHistory?.length - 1]?.status === CUSTOMER_APPROVAL_STATUS.REJECTED) {
                    setInvoiceChargeSetToastMsg(invoiceTypeHistory[invoiceTypeHistory?.length - 1]?.reason);
                    setShowInvoiceChargeSetToast(true);
                }
            }).catch((e) => {});
        }
        if(loads.isDeleted) setDisabled(true)
        switch (billingRow?.status) {
            case DRAFT:
                return setBadgeStatus("DRAFTED");
            default:
                return setBadgeStatus(billingRow?.status)
        }
    }, [billingRow]);

    const handleSubExpBillTabActive = (i) => {
        setIsSubExpBillTabActive(i);
    }

    const toggleDropdown = (e) => {
        e.stopPropagation();
        setIsDropdownOpen((prevState) => !prevState);
    };

    const getTMScallerAsyncSearch = async (strSearch) => {
        let freeze = true; //set mark for stop calls
        return new Promise(async (res, err) => {
            //return promise
            let p = new Promise((res, err) => {
                if (freeze) clearTimeout(timer); //remove  prev timer
                timer = setTimeout(async () => {
                    freeze = false;
                    const r = await actionCreators.getTMScaller(strSearch); //request
                    res(r);
                }, 1000);
            });

            p.then(function (x) {
                res(x);
            });
        });
    };

    // const _findCustomer = (data) => {
    //     let obj = null;
    //     if (data.billToDetail)
    //         obj = { value: data.billToDetail._id, label: data.billToDetail.company_name };
    //     return obj;
    // }
    const handleCreateMemoModal = () => {
        setIsCreateMemo(false);
        getAllCreditMemoForLoad()
        const loadId= loads?._id;
          if (loadId) {
            handleReloadOfBillingSummary && handleReloadOfBillingSummary()
          }
    }

    const preCustomerChange = async(customer, k, isDefault)=>{
        setSelectedBillToDetails({customer, k, isDefault});
        if(!loads?.tenderReferenceNumber){
            const billToIsShipOSUser = await checkBillToForShiposUser(customer?.value);
            if(billToIsShipOSUser){
                setBillToIsShipOSUser(billToIsShipOSUser);
                return;
            }
        };
        onChangeCustomer(customer, k, isDefault,);
    }
    const onChangeCustomer = async (customer, k, isDefault) => {

        try {
            if (!billingRow.billToId) {
                const payload = {
                    loadId: loads?._id,
                    billToId: customer.value
                };
                const res = await addNewCustomer(payload);
                const obj = { value: res?.billToId?._id, label: res?.billToId?.company_name };
                setFindCustomer(obj);
                if(res?._id){
                    const data = {
                        "chargeId": res?._id
                    }
                    props.actions.getBillingChargeLists(data).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            updateChargeInChargeList(res?.data?.data[0], k)
                        }
                    }).catch();
                }
                
                toastr.show("Updated Successfully", "success")
            } else {
                const payload = {
                    chargeId: billingRow._id,
                    billToId: customer.value,
                }
                const data = {
                    "chargeId": billingRow?._id
                }
                await updateCustomer(payload);
                toastr.show("Updated Successfully", "success")
                props.actions.getBillingChargeLists(data).then((res) => {
                    if (res?.data?.statusCode === 200) {
                        updateChargeInChargeList(res?.data?.data[0], k)
                    }
                }).catch((err) => { console.error(err) })
                // updateChargeInChargeList(res, k);
                handleReloadOfBillingSummary()
            }

            transitionEffect(`customer-${k}`, true);

            let eventProperties = {
                source: "AR_BILLING",
                loadNo: `${loads.reference_number}`
            }
            amplitudeTrack(`${isDefault ? "AR_BILLING_CHANGE_MAIN_CUSTOMER" : "AR_BILLING_CHANGE_SUB_CUSTOMER"}`,eventProperties)
        } catch (error) {
            if (/insufficient credit/i.test(error?.data?.message)) {
                setInsufficientCreditModal({ isOpen: true, billToId: customer.value, type: INSUFFICIENT_CREDIT_MODAL_TYPE.INSUFFICIENT_CREDIT })
            } else if (/account.* on hold/i.test(error?.data?.message)) {
                setInsufficientCreditModal({ isOpen: true, billToId: customer.value, type: INSUFFICIENT_CREDIT_MODAL_TYPE.ACCOUNT_ON_HOLD })
            }
            console.error("Error occurred:", error);
        }
    }

    const handleDeleteBillingCharge = () => {
        if (billingRow?._id) {
            const data = {
                "chargeId": billingRow?._id,
            }
            deleteBillingCharge(data).then((res) => {
                if (res?.statusCode === 200) {
                    const loadId = loads?._id;
                    setIsDropdownOpen(false);
                    // const result = billingCards?.filter((dd) => dd?._id !== res?.data?._id);
                    // setBillingCards(result);
                    props.actions.getBillingChargeLists({loadId:loadId}).then((res) => {
                        if (res?.data?.statusCode === 200) {
                            setBillingCards(res?.data?.data)
                        }
                    }).catch();
                    
                    if (loadId) {
                        handleReloadOfBillingSummary && handleReloadOfBillingSummary()
                    }
                    toastr.show(`Charge has been deleted!`, "success");
                }
            }).catch((error) => {
                console.log("error =>", error)
            });
        } else {
            const result = billingCards?.filter((dd) => dd?.id !== billingRow?.id);
            setBillingCards(result);
        }

    }

    const downloadInvoicePdf = (row) => {
        let query = {
            charge_reference_number : row.charge_reference_number,
        }
        setIsDownloadInProcess(true)
        getInvoicePdfByCharge(query).then(async result => {
            if(!result?.data) throw new Error();
            const url = result.data;
            const { data } = await axios({ url, responseType:"arraybuffer",method:"GET" });
            if(!data) throw new Error();
            const fileName = `${
               row?.invoice?.invoiceNumber ??
               row.charge_reference_number
            }-invoice.pdf`;
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(
              new Blob([data], { type: "application/octet-stream" })
            );
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setIsDownloadInProcess(false)
        }).catch(err => {
            console.log(err);
            setIsDownloadInProcess(false)
        })
    }

    const handleOnChargeSetAdd = ()=>{
        setBillToIsShipOSUser(false);
        const {customer, k, isDefault} = selectedBillToDetails;
        onChangeCustomer(customer, k, isDefault);
        
    }
    const handleOnChargeSetCancel =()=>{
        let previousBillingCards = _.cloneDeep(billingCards);
        previousBillingCards.pop();
        setBillToIsShipOSUser(false);
        setBillingCards(previousBillingCards);
    }
    return (
        <>
        <div className="d-flex justify-content-between mb-10 flex-wrap gap-5">
            <div className="d-flex align-items-center gap-10 flex-wrap">
                <div className="d-flex flex-column gap-5">
                    <div className='pointer' onClick={(e)=>handleShowInvoiceHistory(billingRow)}><StatusBadge label={badgeStatus} customerApprovalStatus={
                        enableNewCustomerApprovalStatus() && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet ? billingRow?.invoice?.invoiceId?.customerInvoiceApprovalStatus : null
                    }
                    /></div>
                    {
                        (enableNewCustomerApprovalStatus() && ([CUSTOMER_APPROVAL_STATUS.REJECTED,CUSTOMER_APPROVAL_STATUS.PENDING,CUSTOMER_APPROVAL_STATUS.APPROVED].indexOf(billingRow?.customerApprovalStatus) > -1) && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet) && (
                            <div className='pointer' onClick={(e) => { 
                                    handleShowInvoiceHistory(billingRow);
                            }}>
                                <StatusBadge label={billingRow?.customerApprovalStatus === 'APPROVED' ? 'Confirmed' : billingRow?.customerApprovalStatus} />
                            </div>
                        )
                    }
                </div>
                <div className='d-flex flex-column'>
                    <span className="text-muted">{LOAD_LABELS.BILL_TO}</span>
                </div>
                <div>
                    <div>
                        <AsyncSelect
                            defaultOptions={makeOptionsForSelect(
                                props.customers,
                                "company_name",
                                "address.address",
                                "_id"
                            )}
                            defaultMenuIsOpen={!billingRow?.billToId?._id}
                            value={findCustomer}
                            onChange={(customer) => {
                                billingInvoiceEditPermission && preCustomerChange(customer, billingIndex, billingRow?.isDefault)
                            }}
                            placeholder="Select Sub Customer..."
                            styles={smallSelectStyle}
                            className={`customer-${billingIndex} w-200`}
                            loadOptions={getTMScallerAsyncSearch}
                            isDisabled={disabled || !billingInvoiceEditPermission}
                        />
                    </div>
                </div>

                <div className='d-flex flex-column'>
                    <span className="text-muted">Charge Set # </span>
                    <span className='font-medium'>{billingRow?.charge_reference_number || "-"}</span>
                </div>


                {flag && <ul className="nav nav-compact border-0" role="tablist">
                    <li className="nav-item">
                        <a className={`nav-link  ${isSubExpBillTabActive === "bill" ? 'active' : ''}`} href="#!" onClick={() => {
                            if (isBlockingTabChange) {
                                tabChangeConfirm((change) => {
                                    if (change) {
                                        handleCleartableData()
                                        handleSubExpBillTabActive("bill")
                                        handleExpenseTab()
                                        // this.setState({ addCharge: false })
                                    } else return
                                })
                            } else handleSubExpBillTabActive("bill")
                        }}>
                            <div className='d-flex flex-column'>
                                <div className="text-muted">{isVatEnabled ? 'Charges & Tax' : 'Charges'}</div>
                                {`${(billingRow?.totalAmountWithTax || 0).toCurrency(billingRow?.billToDetail?.currency)}`}
                            </div>
                            {/* <span className={`badge badge-rounded font-12 ml-1 badge-sm ${loads?.pricing?.length > 0 ? 'badge-primary' : 'badge-gray-100 text-white'}`}>{loads?.pricing?.length || 0}</span> */}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${isSubExpBillTabActive === "credit_memo" ? 'active' : ''}  ${[PARTIALLY_PAID,PAID,INVOICED]?.includes(billingRow?.status) ? '' : 'disabled'}`} href="#!" onClick={() => {
                            if (isBlockingTabChange) {
                                tabChangeConfirm((change) => {
                                    if (change) {
                                        handleSubExpBillTabActive("credit_memo")
                                        // this.setState({ addCharge: false })
                                    } else return
                                })
                            } else handleSubExpBillTabActive("credit_memo")
                        }}>
                            <div className='d-flex flex-column'>
                                <div className="text-muted">Credit Memos</div>
                                {`${(totalCredits || 0).toCurrency(billingRow?.billToDetail?.currency)}`}
                            </div>
                            {/* <span className={`badge badge-rounded font-12 ml-1 badge-sm ${loads?.pricing?.length > 0 ? 'badge-primary' : 'badge-gray-100 text-white'}`}>{loads?.pricing?.length || 0}</span> */}
                        </a>
                    </li>
                </ul>}
                {!enableNewCustomerApprovalStatus() && ([CUSTOMER_APPROVAL_STATUS.REJECTED,CUSTOMER_APPROVAL_STATUS.PENDING,CUSTOMER_APPROVAL_STATUS.APPROVED].indexOf(billingRow?.customerApprovalStatus) > -1) && [INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet &&  (<div className="d-flex ml-3 justify-content-end"> 
                    <ReactTooltip id="approval" place="bottom" className="type-gray" effect="solid">Invoice Approval History</ReactTooltip>
                    <button 
                        data-tip data-for="approval"
                        disabled={billingRow?.customerApprovalStatus === CUSTOMER_APPROVAL_STATUS.PENDING}
                        className={`btn btn-sm mr-2 ${billingRow?.customerApprovalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED ? 'btn-danger': billingRow?.customerApprovalStatus === CUSTOMER_APPROVAL_STATUS.PENDING ? 'btn-primary' :'btn-success'}`}
                        onClick={()=>{handleShowInvoiceHistory(billingRow)}}
                    >
                        INVOICE APPROVAL: {billingRow?.customerApprovalStatus}
                    </button>
                </div>)}
            </div>
            <div className="d-flex align-items-center gap-5">
                {isSubExpBillTabActive === "bill" ? <>
                  {[APPROVED,DRAFT, UNAPPROVED, REBILLING].includes(billingRow?.status) &&
                        <button
                            className="btn btn-outline-light"
                            disabled={!billingEmailSendPermission || openEmailModal}
                            onClick={() =>  {
                                    if(billingEmailSendPermission) {
                                        setOpenEmailModal(true);
                                        let eventProperties = {
                                            source: "AR_BILLING",
                                            loadNo: `${loads.reference_number}`
                                        }
                                        amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_SEND_RATE_CONFIRMATIONS" : "AR_SUB_BILLING_SEND_RATE_CONFIRMATIONS"}`,eventProperties)
                                    }
                                }
                            }
                        >
                            <IconSendEmail className="mr-10" />
                            {`Send ${RATE_CONF}`}
                        </button>
                    } 
                    {[INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) &&
                        <button
                            className="btn btn-outline-light"
                            disabled={!billingEmailSendPermission || openEmailModal}
                            onClick={() => {
                                if(billingEmailSendPermission) {
                                    setOpenEmailModal(true)
                                    let eventProperties = {
                                        source: "AR_BILLING",
                                        loadNo: `${loads.reference_number}`
                                    }
                                    amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_SEND_INVOICE" : "AR_SUB_BILLING_SEND_INVOICE"}`,eventProperties)
                                }
                            }}
                        >
                            <IconSendEmail className="mr-10" />
                            {SEND_INVOICE}
                        </button>
                    }   
                    <button className="btn btn-outline-light" 
                        disabled={!billingInvoiceDownloadPermission || isDownloadInProcess} 
                        onClick={()=>{
                            if(billingInvoiceDownloadPermission) {
                                downloadInvoicePdf(billingRow)
                                let eventProperties = {
                                    source: "AR_BILLING",
                                    loadNo: `${loads.reference_number}`
                                }
                                let eventName = [INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) ? billingRow?.isDefault ? "AR_MAIN_BILLING_DOWNLOAD_INVOICE_PDF" : "AR_SUB_BILLING_DOWNLOAD_INVOICE_PDF" : billingRow?.isDefault ? "AR_MAIN_BILLING_DOWNLOAD_DRAFT_INVOICE" : "AR_SUB_BILLING_DOWNLOAD_DRAFT_INVOICE";
                                amplitudeTrack(`${eventName}`,eventProperties)
                            }
                        }}>
                        {isDownloadInProcess ? (
                            <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        ) : (
                            <IconDownload className="mr-10" />
                        )}
                        { [INVOICED,PARTIALLY_PAID,PAID].includes(billingRow?.status) ? DOWNLOAD_PDF : DOWNLOAD_DRAFT_INVOICE}
                    </button>
                </> : <button
                    className="btn btn-primary text-capitalize"
                    disabled={!creditMemoEditPermission}
                    onClick={() => {
                        if(creditMemoEditPermission) {
                            let eventProperties = {
                                source: "AR_BILLING",
                                loadNo: `${loads.reference_number}`
                            }
                            amplitudeTrack(`${billingRow?.isDefault ? "AR_MAIN_BILLING_ADD_CREDIT_MEMO" : "AR_SUB_BILLING_ADD_CREDIT_MEMO"}`,eventProperties)
                            setIsCreateMemo(true);
                        }
                    }}
                >
                    <IconPlus className="mr-2" />
                    {`Add ${CREDIT_MEMO}`}
                </button>}
                <div ref={dropdownRef} className="position-relative">
                    {!billingRow?.isDefault && !allCredits?.length && <>
                        <button className="btn btn-link btn-sm" onClick={(e) => toggleDropdown(e)} disabled={loads.isDeleted}>
                            <Icondots />
                        </button>
                        {isDropdownOpen && (
                            <ul
                                className="card card--shadow-5 popdrop popdrop--bottom-no-space popdrop--right position-absolute mb-0 p-2 animated w-200"

                            >
                                <li 
                                onClick={() => {
                                    if((billingInvoiceDeletePermission && isSubExpBillTabActive === "bill") || ((creditMemoDeletePermission && isSubExpBillTabActive !== "bill"))) {
                                        let eventProperties = {
                                            source: "AR_BILLING",
                                            loadNo: `${loads.reference_number}`
                                        }
                                        let eventName = isSubExpBillTabActive === "bill" ? billingRow?.isDefault ? "AR_MAIN_BILLING_REMOVE_CHARGE_SET" : "AR_SUB_BILLING_REMOVE_CHARGE_SET" : billingRow?.isDefault ? "AR_MAIN_BILLING_REMOVE_CREDIT_MEMO" : "AR_SUB_BILLING_REMOVE_CREDIT_MEMO";
                                        amplitudeTrack(`${eventName}`,eventProperties)
                                        handleDeleteBillingCharge();
                                    }
                                }} 
                                className={`rounded-3 d-flex align-items-center hover-gray-50 p-2 pointer text-danger ${(!billingInvoiceDeletePermission && isSubExpBillTabActive === "bill") || ((!creditMemoDeletePermission && isSubExpBillTabActive !== "bill")) ? " disabled-pointer-events" : " "}`}>
                                    <IconTrash className="mr-10" />
                                    Remove {isSubExpBillTabActive === "bill" ? "Charge" : "Credit"} Set
                                </li>
                            </ul>
                        )}
                    </>}

                </div>
            </div>

            {isCreateMemo && <CreditMemoModalV2
                setShowCreditMemoModal={handleCreateMemoModal}
                isEdit={true}
                isCreate={true}
                chargeRefNo={billingRow?.charge_reference_number}
            // setNewCreditMemo={this.props.setNewCreditMemo}
            />}
            {billingRow && openEmailModal &&
                <EmailModal openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal} selectedCharge={billingRow} chargeIds={[billingRow?._id]} />
            }
        </div>
        {
            (billingRow?.customerApprovalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet && showChargeSetToast) && (<div className="toast-container-relative position-relative mb-10">
                {chargeSetToastMsg && <div className="toast toast-warning mw-100 text-left mb-0 py-2 pr-0">
                    <div className="toast-message d-flex align-items-center">
                        {`Charge set rejected: ${chargeSetToastMsg}`}
                    <button className="btn btn-sm btn-link ml-auto" onClick={() => {setShowChargeSetToast(false)}}>
                        <IconCancel />
                    </button>
                    </div>
                </div>}
            </div>)
        }
        {
            (billingRow?.invoice?.invoiceId?.customerInvoiceApprovalStatus === CUSTOMER_APPROVAL_STATUS.REJECTED && isConnectUser && checkIsManageFleetOrOutsource?.isManageFleet && showInvoiceChargeSetToast) && (<div className="toast-container-relative position-relative mb-10">
                {invoiceChargeSetToastMsg && <div className="toast toast-warning mw-100 text-left mb-0 py-2 pr-0">
                    <div className="toast-message d-flex align-items-center">
                        {`Invoice rejected: ${invoiceChargeSetToastMsg}`}
                    <button className="btn btn-sm btn-link ml-auto" onClick={() => {setShowInvoiceChargeSetToast(false)}}>
                        <IconCancel />
                    </button>
                    </div>
                </div>}
            </div>)
        }
            {billToIsShipOSUser &&
                <WarningModal
                    showModal = {billToIsShipOSUser}
                    subTitle = "The Bill To is a connnected customer. If this charge set is created, it will not sync to their portal or be allowed to combine with other charges sets from tendered loads for invoicing. Do you still want to create charge set?"
                    button1="Cancel"
                    button2="Confirm"
                    handleOnYes={handleOnChargeSetAdd}
                    handleOnNo={handleOnChargeSetCancel}
                ></WarningModal>
            }

            {insufficientCreditModal.isOpen &&
                <InsufficientCreditModal
                    type={insufficientCreditModal.type}
                    showModal={insufficientCreditModal.isOpen}
                    redirectPath="/tms/customer"
                    redirectQuery={{ id: insufficientCreditModal.billToId }}
                    redirectState={{ returnUrl: window.location.pathname + window.location.search }}
                    onCancel={() => setInsufficientCreditModal({ isOpen: false, billToId: null })}
                />
            }
        </>
    )
}

const states = (state) => ({
    customers: state.TmsReducer.customers,
    chargeCode: state.chargeCodeReducer.chargeCode,
    billingChargeLists: state.TmsReducer.billingCharges,
});

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({ getBillingChargeLists }, dispatch)
    };
}

export default connect(states, mapDispatchToProps)(memo(CardHeader));

