import React, {useContext, useEffect, useState} from "react";
import StatusComponent from "../../../../../../Components/Common/StatusComponent";
import ReactDOM from "react-dom";
import { usePopper } from 'react-popper';
import { tmsDrops } from "../../../../constant";

const LoadStatus = ({ row, column, isFrom }) => {
  let orangeLeftBorder = false;
  let blueLeftBorder = false;
  let driverOrder = row.driverOrder ? row.driverOrder : [];
  let currentStatusCompanyName = undefined;

  const [open, setOpen] = useState(false)
  const [referenceElement, setReferenceElenent] =  React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'top' }) || {};

  if (driverOrder) {
    const completedStatuses = driverOrder.filter(
      (D) => !D.isVoidOut && (D.arrived || D.departed)
    );
    if (completedStatuses.length > 0) {
      currentStatusCompanyName =
        completedStatuses[completedStatuses.length - 1].customerId;
    }
  }
  const deliveryObj =
    row.driverOrder &&
    row.driverOrder.find(
      (status) => status.type === "DELIVERLOAD" && !status.isVoidOut
    );
  const droppedObj =
    row.driverOrder &&
    row.driverOrder.find(
      (status) =>
      tmsDrops.includes(status.type) &&
        status.prevType === "DELIVERLOAD" &&
        !status.isVoidOut
    );
  const returnObj =
    row.driverOrder &&
    row.driverOrder.find(
      (status) => status.type === "RETURNCONTAINER" && !status.isVoidOut
    );
  if (deliveryObj && droppedObj && !row.isReadyForPickup) {
    if (
      deliveryObj.customerId &&
      droppedObj.customerId &&
      deliveryObj.customerId._id === droppedObj.customerId._id
    ) {
      orangeLeftBorder = true;
    } else if (
      deliveryObj.customerId &&
      droppedObj.customerId &&
      deliveryObj.customerId._id !== droppedObj.customerId._id
    ) {
      blueLeftBorder = true;
    }
  }
  if (
    !orangeLeftBorder &&
    !blueLeftBorder &&
    !row.isReadyForPickup &&
    deliveryObj &&
    returnObj
  ) {
    if (
      deliveryObj.customerId &&
      returnObj.customerId &&
      deliveryObj.customerId._id !== returnObj.customerId._id
    ) {
      blueLeftBorder = true;
    }
  }
  return (
    <div
      className={`cell-content ${
        row.isReadyForPickup ? "border-left-success border--sm-height" : ""
      } ${blueLeftBorder ? "border-left-primary border--sm-height" : ""} ${
        orangeLeftBorder ? "border-left-warning border--sm-height" : ""
      } position-relative pointer`}
    >
        <div
          className={`rdg-truncate`}
          ref={setReferenceElenent}
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          {row.status === "PENDING" && (row.custom || row.freight) ? (
            <>
              <div className="d-flex flex-column align-items-start">
                {row.custom && (
                  <div
                    className={`mr-1 text-capitalize badge badge-sm badge-status-${
                      row.custom === "RELEASED" ? "hold" : "released"
                    } ${row.freight ? "mb-1" : ""}
                    `}
                  >
                    Customs{" "}
                    {row.custom != "null" ? row.custom.toLowerCase() : ""}
                  </div>
                )}
                {row.freight && (
                  <div
                    className={`mr-1 text-capitalize badge badge-sm badge-status-${
                      row.freight === "RELEASED" ? "hold" : "released"
                    }`}
                  >
                    Freight{" "}
                    {row.freight != "null" ? row.freight.toLowerCase() : ""}
                  </div>
                )}
              </div>
              {row.gate_closed && (
                <div className="text-danger">Closed Area</div>
              )}
            </>
          ) : (
            <StatusComponent load={row} />
          )}
          {currentStatusCompanyName &&<div className="rdg-truncate">
            {currentStatusCompanyName.company_name}
          </div>}
        </div>
        {(row.isReadyForPickup || orangeLeftBorder || blueLeftBorder) && open && ReactDOM.createPortal(
                    <div
                      className={`tooltip tooltip--gray-700 ${isFrom === "sideBar" ? "bs-tooltip-bottom" : "bs-tooltip-top"}  portal-tooltip ${isFrom === "sideBar" ? "load_portal_status" : "load-equipment"} `}
                      ref={setPopperElement}
                      style={styles?.popper}
                      {...(attributes.popper || {})}
                    >
                      {row.isReadyForPickup && <span>(Ready To Return)</span>}
                      {orangeLeftBorder && <span>(Drop and Hook)</span>}
                      {blueLeftBorder && <span>(Live Unload)</span>}
                      <span className="arrow"></span>
                    </div>
                  ,
                  document.querySelector('#poper-dest')
                )}
      </div>
  );
};

export default LoadStatus;
