import React from "react";
import { useSelector } from "react-redux";
import QueryBuilder from '../../../../../Components/DynamicPricing/QueryBuilder'
import { STATUSES_NOT_INCLUDED_IN_FILTER } from 'pages/tms/Dispatcher/utility'
import { containerStatusOption } from 'pages/tms/constant'
export const READY_TO_RETURN_OPTIONS = [
  { label: 'YES', value: "true" },
  { label: 'NO', value: "false" },
];
export const APPOINTMENT_TEMPLATE_RULES = {
  LOAD_STATUS: {
    LABEL: "LOAD STATUS",
    VALUE: "LOAD_STATUS"
  },
  CONTAINER_STATUS: {
    LABEL: "CONTAINER STATUS",
    VALUE: "CONTAINER_STATUS"
  },
  READY_TO_RETURN: {
    LABEL: "READY TO RETURN",
    VALUE: "READY_TO_RETURN"
  }
};

const TemplateRules = (props) => {
  const { rules, setRules, countries } = props;
  const rulesData = Array.isArray(rules) ? rules : [];
  const { StatesReducer, HomeReducer, TmsReducer } = useSelector(state => state);
  const terminals = HomeReducer?.terminals?.map((terminal) => ({ label: terminal?.name, value: terminal?._id }));
  const consingeeState = StatesReducer?.consingeeState;
  const loadStatusType= TmsReducer?.statusTypes?.filter(e => !STATUSES_NOT_INCLUDED_IN_FILTER.includes(e.value))
  const extraFields = [
    { name: APPOINTMENT_TEMPLATE_RULES.LOAD_STATUS.VALUE, options: loadStatusType },
    { name: APPOINTMENT_TEMPLATE_RULES.CONTAINER_STATUS.VALUE, options: containerStatusOption},
    { name: APPOINTMENT_TEMPLATE_RULES.READY_TO_RETURN.VALUE, options: READY_TO_RETURN_OPTIONS}
  ];  
  
  return (
    <>
      <div className="d-flex flex-column gap-5">
        <div className="font-16 line-height-20 font-medium">Rules</div>
        <QueryBuilder
          data={rulesData}
          setData={(val) => setRules(val)}
          consingeeState={consingeeState}
          commodities={[]}
          cityGroups={[]}
          terminals={terminals}
          deliveryCountry={countries}
          readOnly={false}
          extraFields={extraFields}
        />
      </div>
    </> 
  );
};

export default TemplateRules;
