export const memoHeaders = [
  "Memo #",
  "Customer",
  "Status",
  "Reference #",
  "Created Date",
  "Total Credit",
  "Credit Applied",
  "Outstanding",
  "Use Memo",
]

export const memoTableHeaders = [
  // "Number",
    "Customer",
    "Memo #",
    "Reference Invoice #",
    "Date",
    "Status",
    "Total",
    "Credit Applied",
    "Outstanding",
]

export const memoTableWithTaxHeaders = [
  // "Number",
  "Customer",
  "Memo #",
  "Reference Invoice #",
  "Date",
  "Status",
  "Tax",
  "Total",
  "Credit Applied",
  "Outstanding",
]

export const invoiceHeaders = [
  "Load #",
  "Driver",
  "Customer",
  "Movement",
  "Load Status",
  "Container",
  "Reference #",
  "Billing Date",
  "Invoice Amount",
  "Payment Received",
  "Credit Applied",
  "Outstanding",
  "Payment"
]

export const invoiceHeadersWithTax = [
  "Combine Invoice",
  "Load #",
  "Driver",
  "Customer",
  "Movement",
  "Load Status",
  "Container",
  "Reference #",
  "Billing Date",
  "Invoice Amount",
  "Tax Amount",
  "Total Invoice Amount",
  "Payment Received",
  "Credit Applied",
  "Outstanding",
  "Payment"
]

export const statusAllowed = ["APPROVED", "INVOICED", "PARTIAL_PAID","FULL_PAID","DRAFTED","UNAPPROVED"]

export const onlyIssuedStatus = ["INVOICED", "PARTIAL_PAID"]

export const caseStatus = (statusName) => {
  switch (statusName) {
    case "APPROVED":
      return <span className="text-capitalize text-white badge badge-sm badge-success">Approved</span>;
    case "INVOICED":
      return <span className="text-capitalize text-white badge badge-sm badge-status-dark-yellow">Issued</span>;
    case "PARTIAL_PAID":
      return <span className="text-capitalize text-white badge badge-sm badge-status-skylight-blue">Partially Applied</span>;
    case "FULL_PAID":
      return <span className="text-capitalize text-white badge badge-sm badge-status-light-dark">Fully Applied</span>;  
    case "DRAFTED":
      return <span className="text-capitalize text-white badge badge-sm badge-status-light-pista">Drafted</span>;
    case "UNAPPROVED":
      return <span className="text-capitalize text-white badge badge-sm badge-status-light-danger">Unapproved</span>;
    default:
      return "";
  }
}; 

export const INVOICE_STATUS = {
  BILLING: "BILLING",
  REBILLING: "REBILLING",
  PENDING: "PENDING",
  PARTIAL_PAID:'PARTIAL_PAID',
  FULL_PAID:'FULL_PAID',
  APPROVE : 'APPROVED',
  UNAPPROVED :'UNAPPROVED',
  VOIDED : "VOIDED"
}

export const INVOICE_STATUS_ARRAY = ["UNAPPROVED", "APPROVE", "BILLING", "REBILLING", "PARTIAL_PAID", "FULL_PAID"]
export const PAID_MEMOS = ["PARTIAL_PAID", "FULL_PAID"]
export const DOCUMENTS_ABLE_TO_UPLOAD = ["APPROVED", "INVOICED", "PARTIAL_PAID", "FULL_PAID"]
export const MEMO_NOT_ALLOWED_TO_EDIT = ["INVOICED", "PARTIAL_PAID", "FULL_PAID"]
export const MEMO_UNAPPROVE_DISABLED = ["INVOICED", "DRAFTED", "PARTIAL_PAID", "FULL_PAID"]
export const MEMO_ISSUE_DISABLED = ["DRAFTED", "UNAPPROVED", "PARTIAL_PAID", "FULL_PAID"]
export const MEMO_ISSUEANDAPPROVE_DISABLED = ["APPROVED", "INVOICED", "PARTIAL_PAID", "FULL_PAID"]

export const CREDIT_MEMO_STATUS = {
  DRAFTED: "DRAFTED",
  APPROVED : 'APPROVED',
  UNAPPROVED :'UNAPPROVED',
  INVOICED: "INVOICED",
  PARTIAL_PAID: "PARTIAL_PAID", 
  FULL_PAID: "FULL_PAID"
}

export const VATTYPES = {
  APPLICABLE: "APPLICABLE",
  EXEMPT: "EXEMPT"
}
export const CREDITMEMODISPLAY = ["FULL_PAID","PARTIAL_PAID","BILLING"];