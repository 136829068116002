import { useEffect, useRef } from "react";
import useDateTimeSlotsPicker from "./hooks/useDateTimeSlotsPicker";
import SlotCell from "./Components/SlotCell";
import { ACTIONS } from "./store/action";
import { slotsCountTransform } from "../../helpers/timeConverter";
import { APPOINTMENT_TIME_CATEGORY } from "../../constants";
import { useScrollRestoration } from "hooks";
const { DATETIME_RANGE_SLOT } = APPOINTMENT_TIME_CATEGORY;

/**
 * Keep this component isolated, decoupled from the drayos
 * UI/REFERENCE: https://github.com/PortPro-Technologies-Inc/portpro-trackos-backend/wiki/AppointmentSystem-Time-Format
 */
const DateTimeSlotsPicker = ({
  handleSelectedDateTime,
  timeZone,
  defaultDateTimeSlots,
  defaultSlotsCount,
  isLoading,
  lastDate, // lastdate for the slots row, lastFreeDay or cutoff
  selectedOption,
  onOptionChange,
}) => {
  const rangeInputRef = useRef(null);
  const { dispatch, slots, hours, getSlotColor, selectedSlots, slotsCount } = useDateTimeSlotsPicker({
    timeZone,
    lastDate,
  });

  const [scrollRef, scrollToPosition, saveScrollPosition] = useScrollRestoration();

  useEffect(() => {
    // Restore scroll position on every render
    scrollToPosition();
  });

  const toggleSlotSelection = (data) => {
    saveScrollPosition(); // Save scroll position before toggling

    const payload = {
      ...data,
      rowIndex: data.rowIndex,
      cellIndex: data.cellIndex,
    };
    dispatch({ type: ACTIONS.TOGGLE_SLOT, payload });
  };

  useEffect(() => {
    handleSelectedDateTime(selectedSlots);
  }, [selectedSlots]);

  useEffect(() => {
    if (!(defaultDateTimeSlots?.length > 0)) return;
    dispatch({ type: ACTIONS.BULK_ADD_SLOTS, payload: defaultDateTimeSlots });
  }, [defaultDateTimeSlots]);

  useEffect(() => {
    if (!(defaultSlotsCount?.length > 0)) return;
    const formatted = slotsCountTransform(defaultSlotsCount, timeZone);
    dispatch({ type: ACTIONS.COUNT_APPOINTMENTS, payload: formatted });
  }, [defaultSlotsCount]);

  const handleChange = () => {
    onOptionChange(DATETIME_RANGE_SLOT);
  };

  return (
    <>
      <div
        className={`form-check form-check-inline ${
          selectedOption === DATETIME_RANGE_SLOT ? "" : "app-slot-disabled"
        }`}
      >
        <div className="form-check-label ml-0 w-100 d-block">
          <label for="appointment-option">
            <input
              type="radio"
              class="form-check-input"
              name="appointment-option"
              checked={selectedOption === DATETIME_RANGE_SLOT}
              onChange={handleChange}
              id="appointment-option"
              ref={rangeInputRef}
            />
            I want to schedule an appointment in the selected time slots (select time slots in order of how you would
            prefer to schedule)
          </label>
        </div>
      </div>
          <div
            className="table-responsive mt-10 flex-grow-1"
            style={{ opacity: selectedOption === DATETIME_RANGE_SLOT ? "1" : "0.3" }}
            key={selectedSlots}
            ref={scrollRef}
            onClick={(e) => {
              const target = e.target;
              if (target.tagName !== "INPUT" && rangeInputRef?.current?.click) {
                rangeInputRef.current.click();
              }
            }}
          >
            <table className="table sticky-table-header-column">
              <thead>
                <tr>
                  <th className="border-top-0 text-muted">Date</th>
                  {hours.map((hour) => (
                    <th className="border-top-0 text-muted" key={hour}>
                      {hour % 12 === 0 ? hour === 0 ? "12:00 AM" : "12:00 PM" : `${hour % 12}:00 ${hour >= 12 ? "PM" : "AM"}`}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {slots.map((eachRow, rowIndex) => (
                  <tr key={rowIndex}>
                    {eachRow.map((eachCell, cellIndex) => {
                      let slotColor = getSlotColor(eachCell.color);
                      const found = selectedSlots?.find(
                        (eachSelected) =>
                          eachSelected.startDate == eachCell?.startDate && eachSelected.endDate == eachCell?.endDate,
                      );

                      const foundSlots = slotsCount?.find((eachSlot) => {
                        return (
                          eachSlot.startDate == eachCell?.startDate &&
                          eachSlot.endDate == eachCell?.endDate &&
                          !eachCell.isFirstCell
                        );
                      });

                      return (
                        <SlotCell
                          isLoading={isLoading}
                          count={foundSlots?.count ?? 0}
                          key={cellIndex}
                          rowIndex={rowIndex}
                          cellIndex={cellIndex}
                          isActive={found ? true : false}
                          toggleSelection={toggleSlotSelection}
                          eachCell={eachCell}
                          slotColor={slotColor}
                        />
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>

            {/* {selectedDateTime && <div>Selected Date and Time: {selectedDateTime}</div>} */}
          </div>
    </>
  );
};

export default DateTimeSlotsPicker;
