import React, { Component } from "react";
import { Field } from "redux-form";
import moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  getTerminal,
  removeValueLoad,
  getTrailers,
  getTrailerTypes,
  getTrailerSizes,
  editLoad,
  UpdatePricing,
} from "../services";
import configuration from "../../../config";
import {
  toastr,
  makeOptionsForSelect,showForTerminal,
  getStorage,
  isMilitaryTime,
  DateTimeFormatUtils,
  updateTerminology,
} from "./../../../services/Common.services";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import { InputField, SelectField } from "../formComponent";
import Select from "react-select";
import { loadFlags } from "./utility";

import {
  getTMSshipper,
  getTMSconsignee,
  getTMScaller,
  getCommodityProfile,
} from "../Dispatcher/actionCreators";
import {
  IconCalendar,
  IconMinus,
  IconPlus,
  IconTimes,
} from "../../../Components/Common/Icons";
import { smallSelectStyle } from "../../../assets/js/select-style";

const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightKgs: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};

class RoadLoadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      terminals: [],
      commodityProfiles: [],
      Terminals: [],
      items: [{ ...commodityItems}],
      terminal:null
    };
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.userTimeFormat = isMilitaryTime();
    this.timeZone = getStorage("timeZone");
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }
    if (props.customerProfile.terminal) {
      if(props.customerProfile.terminal._id){
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });  
      }
      if(props.customerProfile.terminal.value){
        this.setState({ terminal:props.customerProfile.terminal });  
      }
      
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      getTerminal().then((response) => {
        const Terminals = response.data.data ? response.data.data : [];
        self.setState({ Terminals });
      });
    }
  }

  onRemovePress(e, input) {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: input.name,
    };
    removeValueLoad(params).then((result) => {
      toastr.show("Removed.", "sucess");
      this.props.updateState(input.name, result.data.data[input.name]);
    });
  }
  onRemovePressOnDate(e, input, index) {
    let params = {
      reference_number: this.props.customerProfile.reference_number,
      source: 'loadInfo'
    };
    let { customerProfile } = this.props;
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    params[`${input}Times`] = JSON.stringify(params[`${input}Times`]);
    editLoad(params).then((res) => {
      if (res.status === 200) {
        toastr.show("Successfully removed", "success");
        this.props.updateState(
          `${input}Times`,
          customerProfile[`${input}Times`]
        );
      }
    });
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  tblvalchanges = (index, e) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    old[index][name] = value;
    if (name == "weight") {
      old[index]["weightKgs"] = (parseFloat(value) * 0.453592).toFixed(2);
    }
    if (name == "weightKgs") {
      old[index]["weight"] = (parseFloat(value) * 2.20462).toFixed(2);
    }
    this.setState({
      items: [...old],
    });
  };

  DeleteItemRow = (index) => {
    let data = [...this.state.items];
    data.splice(index, 1);
    this.setState({
      items: data,
    });
  };

  AddItemRows = () => {
    this.state.items.push({ ...commodityItems });
    this.setState({
      rowsData: [...this.state.items],
    });
  };

  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {};
    let data = this.state.items.filter((d) => d.commodity);
    payload.items = data;
    
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => {});
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isSaveCalled !== nextProps.isSaveCalled) {
      this.updatePricing();
    }
    let self = this;
    if (nextProps.customerProfile) {
      if (
        nextProps.customerProfile.items &&
        nextProps.customerProfile.items.length > 0
      ) {
        let items = nextProps.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        });
        self.setState({ items });
      }
    }
  }

  componentDidMount() {
    this.getCommodityAllProfile();
  }

  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }

  getSeachTrailers = (params) => {
    const obj = { limit: 5 }
    if (params) {
      obj.searchTerm = params
    }
    return getTrailers(obj).then((data) => {
     return data;
    });
  };

  render() {
    let self = this;
    let ddlDisabled = false;
    let pickupFrom = "";
    let pickupTo = "";
    let deliveryFrom = "";
    let deliveryTo = "";
    let loadTime = "";
    let containerAvailableDay = "";
    let disabled = this.props.disabled;
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.pickupFromTime) {
        pickupFrom = moment(this.props.customerProfile.pickupFromTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
        pickupTo = moment(this.props.customerProfile.pickupToTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.deliveryFromTime) {
        deliveryFrom = moment(
          this.props.customerProfile.deliveryFromTime
        ).format(DateTimeFormatUtils.fullDateTimeFormat());
        deliveryTo = moment(this.props.customerProfile.deliveryToTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format(DateTimeFormatUtils.fullDateFormat());
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo != "string"
      )
        ddlDisabled = true;
    }
    const shippers =
      this.props.customerProfile && this.props.customerProfile.shipper
        ? this.props.customerProfile.shipper
        : [{}];
    const consignees =
      this.props.customerProfile && this.props.customerProfile.consignee
        ? this.props.customerProfile.consignee
        : [{}];
    let pickupTimes = this.props.customerProfile.pickupTimes
      ? this.props.customerProfile.pickupTimes
      : [{}];
    let deliveryTimes = this.props.customerProfile.deliveryTimes
      ? this.props.customerProfile.deliveryTimes
      : [{}];

    return (
      <div className="tab mt-10" id="tab-load-info">
        <div className="form-row">
          <div className="col-md-6">
            {/* Customer Info */}
            <div className="card pt-30 pb-30 px-20 mb-10">
              <div>
                <h4 className="font-16 mb-20">
                  
                  Customer Info
                </h4>

                <div className="form-row">
                  <div className="col-lg form-group mb-0">
                    <label className={`label-control`}>Customer<span className="text-danger">*</span></label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                                this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        this.props.updateState("caller", customer.allInfos);
                      }}
                      loadOptions={getTMScaller}
                      isDisabled={disabled}
                    />
                  </div>

                  { showForTerminal()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">{updateTerminology('terminal')}<span className="text-danger">*</span></label>
                      {
                        this.state.Terminals && 
                        <Select
                          styles={smallSelectStyle}
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled}
                        />
                      }
                    </div>
                  )} 
                </div>
                
              </div>

              <div className="hr-light my-25"></div>
              {shippers.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Pick Up Location</h4>

                    <div className="form-row">
                      <div className="col-md-6">
                        {shippers.map((shipper, i) => {
                          return (
                            <div className="form-group  " key={i}>
                              <label>
                                <span className="text-danger">*</span> Pick Up Location
                              </label>

                              <AsyncSelect
                                styles={smallSelectStyle}
                                defaultOptions={makeOptionsForSelect(
                                  this.props.shippersOptions,
                                  "company_name",
                                  "address.address",
                                  "_id"
                                )}
                                cacheOptions
                                value={
                                  shipper && shipper.company_name
                                    ? {
                                        label: shipper.company_name,
                                        value: shipper._id,
                                        allInfos: shipper,
                                      }
                                    : this.state["shipper" + i]
                                    ? {
                                        label:
                                          this.state["shipper" + i]
                                            .company_name,
                                        value: this.state["shipper" + i]._id,
                                        allInfos: this.state["shipper" + i],
                                      }
                                    : []
                                }
                                onChange={(shipper) => {
                                  let newShipper = self.props.customerProfile
                                    .shipper || [{}];
                                  newShipper[i] = shipper.allInfos;
                                  this.setState({
                                    ["shipper" + i]: shipper.label,
                                  });
                                  this.props.updateState(
                                    "shipper",
                                    newShipper
                                  );
                                }}
                                loadOptions={getTMSshipper}
                                isDisabled={disabled}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {pickupTimes &&
                          pickupTimes.map((pickupTime, i) => (
                            <React.Fragment>
                              <div className="form-row">
                                <div className=" col-lg">
                                  <label className="label-control">
                                    Pick Up From
                                  </label>
                                  <div className="input-wrapper">
                                    <Datetime
                                      inputProps={{ disabled, placeholder: 'MM-DD-YYYY' }}
                                      dateFormat="MM-DD-YYYY"
                                      timeFormat={DateTimeFormatUtils.timeFormat()}
                                      timeConstraints={{
                                        minutes: {
                                          step: 15,
                                        },
                                      }}
                                      displayTimeZone={this.timeZone}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery(
                                          "pickupFromTime",
                                          e,
                                          i
                                        );
                                        // setTimeout(() => {
                                        this.props.datechangesPickupDelivery(
                                          "pickupToTime",
                                          e,
                                          i
                                        );
                                        // }, 1000);
                                      }}
                                      value={
                                        pickupTime &&
                                        pickupTime.pickupFromTime
                                          ? moment(pickupTime.pickupFromTime)
                                          : ""
                                      }
                                    />
                                    {pickupTime &&
                                    pickupTime.pickupFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "pickup",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Pick Up To
                                    </label>
                                    <div className="input-wrapper">
                                      <Datetime
                                        inputProps={{ disabled, placeholder: 'MM-DD-YYYY' }}
                                        dateFormat="MM-DD-YYYY"
                                        timeFormat={DateTimeFormatUtils.timeFormat()}
                                        timeConstraints={{
                                          minutes: {
                                            step: 15,
                                          },
                                        }}
                                        displayTimeZone={this.timeZone}
                                        onChange={(e) =>
                                          this.props.datechangesPickupDelivery(
                                            "pickupToTime",
                                            e,
                                            i
                                          )
                                        }
                                        value={
                                          pickupTime &&
                                          pickupTime.pickupToTime
                                            ? moment(pickupTime.pickupToTime)
                                            : ""
                                        }
                                      />
                                      {pickupTime &&
                                      pickupTime.pickupToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "pickup",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              
                                {this.props.customerProfile &&
                                  this.props.customerProfile.shipper &&
                                  this.props.customerProfile.shipper.length >
                                    1 && (
                                    <div className="col-lg form-group d-flex align-items-end justify-content-end" style={{maxWidth: 30}}>
                                      <div
                                        className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                        onClick={(e) => {
                                          let newShipper =
                                            this.props.customerProfile.shipper ||
                                            [];
                                          if (newShipper.length > 1) {
                                            newShipper.splice(i, 1);
                                            this.props.updateState(
                                              "shipper",
                                              newShipper
                                            );
                                            this.setState({
                                              ["shipper" + i]: "",
                                            });
                                            pickupTimes.splice(i, 1);
                                            this.props.updateState(
                                              "pickupTimes",
                                              pickupTimes
                                            );
                                          }
                                        }}
                                      >
                                        <IconMinus />
                                      </div>
                                    </div>
                                  )}
                                
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>

                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newShipper =
                          self.props.customerProfile &&
                          self.props.customerProfile.shipper
                            ? self.props.customerProfile.shipper
                            : [];
                        newShipper.push({});
                        pickupTimes.push({});
                        self.props.updateState("pickupTimes", pickupTimes);
                        self.props.updateState("shipper", newShipper);
                        self.setState({
                          ["shipper" + newShipper.length]: "",
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add Pick Up Location
                    </button>
                  </div>
                  <div className="hr-light"></div>
                </React.Fragment>
              )}
              {consignees.length > 0 && (
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-20">Delivery Location</h4>
                    <div className="form-row">
                      <div className="col-md-6">
                        {consignees.map((consignee, i) => {
                          return (
                            <div className="form-group" key={i}>
                              <label>Delivery Location</label>

                              <AsyncSelect
                                styles={smallSelectStyle}
                                defaultOptions={makeOptionsForSelect(
                                  this.props.consigneesOptions,
                                  "company_name",
                                  "address.address",
                                  "_id"
                                )}
                                cacheOptions
                                value={
                                  consignee && consignee.company_name
                                    ? {
                                        label: consignee.company_name,
                                        value: consignee._id,
                                        allInfos: consignee,
                                      }
                                    : this.state["consignee" + i]
                                    ? {
                                        label:
                                          this.state["consignee" + i]
                                            .company_name,
                                        value:
                                          this.state["consignee" + i]._id,
                                        allInfos: this.state["consignee" + i],
                                      }
                                    : []
                                }
                                onChange={(consignee) => {
                                  let newConsignee = self.props
                                    .customerProfile.consignee || [{}];
                                  newConsignee[i] = consignee.allInfos;
                                  this.setState({
                                    ["consignee" + i]: consignee.label,
                                  });
                                  this.props.updateState(
                                    "consignee",
                                    newConsignee
                                  );
                                }}
                                loadOptions={getTMSconsignee}
                                isDisabled={disabled}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-md-6">
                        {deliveryTimes &&
                          deliveryTimes.map((deliveryTime, i) => (
                            <React.Fragment>
                              <div className="form-row ">
                                <div className=" col-md">
                                  <label className="label-control">
                                    Delivery From
                                  </label>
                                  <div className="input-wrapper">
                                    <Datetime
                                      inputProps={{ disabled, placeholder: 'MM-DD-YYYY' }}
                                      timeConstraints={{
                                        minutes: {
                                          step: 15,
                                        },
                                      }}
                                      dateFormat="MM-DD-YYYY"
                                      timeFormat={DateTimeFormatUtils.timeFormat()}
                                      displayTimeZone={this.timeZone}
                                      onChange={(e) => {
                                        this.props.datechangesPickupDelivery(
                                          "deliveryFromTime",
                                          e,
                                          i
                                        );
                                        // setTimeout(() => {
                                        this.props.datechangesPickupDelivery(
                                          "deliveryToTime",
                                          e,
                                          i
                                        );
                                        // }, 1000)
                                      }}
                                      value={
                                        deliveryTime &&
                                        deliveryTime.deliveryFromTime
                                          ? moment(
                                              deliveryTime.deliveryFromTime
                                            )
                                          : ""
                                      }
                                    />
                                    {deliveryTime &&
                                    deliveryTime.deliveryFromTime &&
                                    !disabled ? (
                                      <div
                                        className="input-icon"
                                        onClick={(e) => {
                                          this.onRemovePressOnDate(
                                            e,
                                            "delivery",
                                            i
                                          );
                                        }}
                                      >
                                        <IconTimes />{" "}
                                      </div>
                                    ) : (
                                      <div className="input-icon">
                                        <IconCalendar />{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md">
                                  <div className="form-group">
                                    <label className="label-control">
                                      Delivery To
                                    </label>
                                    <div className="input-wrapper">
                                      <Datetime
                                        inputProps={{ disabled, placeholder: 'MM-DD-YYYY' }}
                                        dateFormat="MM-DD-YYYY"
                                        timeFormat={DateTimeFormatUtils.timeFormat()}
                                        timeConstraints={{
                                          minutes: {
                                            step: 15,
                                          },
                                        }}
                                        displayTimeZone={this.timeZone}
                                        onChange={(e) =>
                                          this.props.datechangesPickupDelivery(
                                            "deliveryToTime",
                                            e,
                                            i
                                          )
                                        }
                                        value={
                                          deliveryTime &&
                                          deliveryTime.deliveryToTime
                                            ? moment(
                                                deliveryTime.deliveryToTime
                                              )
                                            : ""
                                        }
                                      />
                                      {deliveryTime &&
                                      deliveryTime.deliveryToTime &&
                                      !disabled ? (
                                        <div
                                          className="input-icon"
                                          onClick={(e) => {
                                            this.onRemovePressOnDate(
                                              e,
                                              "delivery",
                                              i
                                            );
                                          }}
                                        >
                                          <IconTimes />{" "}
                                        </div>
                                      ) : (
                                        <div className="input-icon">
                                          <IconCalendar />{" "}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {this.props.customerProfile &&
                                this.props.customerProfile.consignee &&
                                this.props.customerProfile.consignee.length >
                                  1 && (
                                  <div className="form-group col-md d-flex align-items-end justify-content-end" style={{maxWidth: 30}}>
                                    <div
                                      className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                      onClick={(e) => {
                                        let newConsignee =
                                          self.props.customerProfile
                                            .consignee || [];
                                        if (newConsignee.length > 1) {
                                          newConsignee.splice(i, 1);
                                          self.props.updateState(
                                            "consignee",
                                            newConsignee
                                          );
                                          self.setState({
                                            ["consignee" + i]: "",
                                          });
                                          deliveryTimes.splice(i, 1);
                                          self.props.updateState(
                                            "deliveryTimes",
                                            deliveryTimes
                                          );
                                        }
                                      }}
                                    >
                                      <IconMinus />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>

                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        let newConsignee =
                          self.props.customerProfile &&
                          self.props.customerProfile.consignee
                            ? self.props.customerProfile.consignee
                            : [];
                        newConsignee.push({});
                        deliveryTimes.push({});
                        self.props.updateState(
                          "deliveryTimes",
                          deliveryTimes
                        );
                        self.props.updateState("consignee", newConsignee);
                        self.setState({
                          ["consignee" + newConsignee.length]: "",
                        });
                      }}
                    >
                      <IconPlus className="mr-2" />
                      Add Delivery Location
                    </button>
                  </div>
                </React.Fragment>
              )}
              {/* Container Return */}
            </div>
          </div>

          <div className="col-md-6">
            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Reference
              </h4>
              <div className="form-row mb-10">
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Master Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerbillLandingNo"
                  />
                </div>

                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="House Bill of Lading"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="doNo"
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Reference Container #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="containerNo"
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Pick Up #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                  />
                </div>
                <div className="form-group col-lg-4 ">
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="secondaryReferenceNo"
                    label="Reference #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Shipment #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                  />
                </div>
                <div className="form-group col-lg-4 ">
                  <Field
                    type="text"
                    className="form-control"
                    component={InputField}
                    name="sealNo"
                    label="Seal #"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                  />
                </div>

                <div className="form-group col-lg-4 ">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Order #"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                  />
                </div>
              </div>
            </div>

            <div className="card pt-30 px-20 mb-10">
              <h4 className="font-16 mb-20">
                
                Equipment
              </h4>
              
              <div className="form-row ">
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="Temperature"
                    name="temperature"
                    disabled={this.props.disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control"
                    component={SelectField}
                    name="hazmat"
                    label="Hazmat"
                    data={loadFlags}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control"
                    component={SelectField}
                    name="hot"
                    label="Hot"
                    data={loadFlags}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    type="text"
                    className="form-control"
                    component={SelectField}
                    name="overWeight"
                    label="OVERWEIGHT"
                    data={loadFlags}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control"
                    label="SCAC"
                    name="scac"
                    disabled={this.props.disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label className="label-control">Trailer</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailer,
                      value: this.props.customerProfile.trailer,
                    }}
                    onChange={(trailer) => {
                      !trailer
                        ? this.onRemovePress(null, { name: "trailer" })
                        : this.props.updateState("trailer", trailer.value);
                    }}
                    loadOptions={this.getSeachTrailers}
                    isClearable={
                      this.props.customerProfile.trailer ? true : false
                    }
                    isDisabled={disabled}
                  />
                </div>
              </div>
              <div className="form-row mb-10">
                <div className="form-group col-lg-4">
                  <label className="label-control">Type</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailerType,
                      value: this.props.customerProfile.trailerType,
                    }}
                    onChange={(trailerType) => {
                      this.props.updateState(
                        "trailerType",
                        trailerType.value
                      );
                    }}
                    loadOptions={getTrailerTypes}
                    isDisabled={disabled}
                  />
                </div>
                <div className="form-group col-lg-4">
                  <label className="label-control">Size</label>
                  <AsyncCreatableSelect
                    styles={smallSelectStyle}
                    cacheOptions
                    // defaultOptions
                    value={{
                      label: this.props.customerProfile.trailerSize,
                      value: this.props.customerProfile.trailerSize,
                    }}
                    onChange={(trailerSize) => {
                      this.props.updateState(
                        "trailerSize",
                        trailerSize.value
                      );
                    }}
                    loadOptions={getTrailerSizes}
                    isDisabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>

        <div className="table">
          <table className="table table-card table-card--ls mb-10">
            <thead>
              <tr>
                <th scope="col">Commodity</th>
                <th scope="col">Description</th>
                <th width="10%" scope="col">
                  Pieces
                </th>
                <th width="10%" scope="col">
                  Weight LBS
                </th>
                <th width="10%" scope="col">
                  Weight KGS
                </th>
                <th width="10%" scope="col">
                  Pallets
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  return (
                    <tr key={key}>
                      <td>
                        {JSON.parse(getStorage("measureUnits"))
                          .isCommodityProfileShow == true ? (
                          <div className="form-group m-0">
                            <Select
                              styles={smallSelectStyle}
                              options={this.state.commodityProfiles}
                              // isDisabled={true}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                                
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className="form-control"
                            type="text"
                            name="commodity"
                            value={data.commodity}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            disabled={disabled}
                          />
                        )}
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          name="description"
                          value={data.description}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="pieces"
                          value={data.pieces}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="weight"
                          value={data.weight}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          className="tblrequire form-control"
                          type="number"
                          name="weightKgs"
                          value={data.weightKgs}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          className="tblrequire form-control"
                          name="pallets"
                          value={data.pallets}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          disabled={disabled}
                        />
                      </td>

                      <td>
                        <button
                          type="button"
                          disabled={disabled}
                          onClick={() => this.DeleteItemRow(key)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <button
            className="btn btn-white btn-sm text-primary"
            onClick={() => this.AddItemRows()}
          >
            <IconPlus className="mr-2" />
            Add Row
          </button>
        </div>
      </div>
    );
  }
}

export default RoadLoadComponent;

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus !== buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" for="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};