import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import { formateParticipants, getOptionsForMoveTo, getIconV2 } from "../../helper";

import { IconFolderFill } from "../../../../../Components/Common/Icons";
import { EMAIL_CONTEXT, EMAIL_TABS, OUTLOOK_PROVIDER } from "../../constant";
import { getStorage } from "../../../../../services";

const MoveToModal = ({ show, hide, selectedRows, callback, isMoveEmailLoader, activePage }) => {
  const [selectedFolder, setSelectedFolder] = useState(null);

  const handleFolderClick = (folder) => {
    setSelectedFolder(folder);
  };
  const fromEmails = selectedRows.map((r) => formateParticipants(r)).join(",");
  const { allLabels, activeTabId } = useContext(EMAIL_CONTEXT);

  const { labels } = getOptionsForMoveTo(allLabels, true)

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
  const { provider } = embeddedEmailAccount ?? {};

  return (
    <Modal show={show} dialogClassName="modal-sm modal-dialog-scrollable">
      <Modal.Header className="flex-column align-items-start gap-5">
        <Modal.Title>Move to folder</Modal.Title>
        <div className="text-muted">{`Move from: ${activePage}`}</div>
        <div className="text-muted">Emails from: {fromEmails}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-5">
          {labels.map((option, index) => {
						let optionName = option?.name
						optionName = OUTLOOK_PROVIDER.includes(provider) ? option?.name : (option.systemFolder? _.startCase(_.toLower(option?.name)):option?.name)
            if(activeTabId===option.id ||(activeTabId ==="SPAM" && ["TRASH","INBOX"].includes(option?.name))) return<></>
						return (
            <div
              key={option.id}
              className={`pointer border-1 rounded-5 py-2 px-10 d-flex gap-5 align-items-center ${
                selectedFolder?.id === option?.id ? "bg-brand-50 border-brand-200" : "border-gray-50"
              }`}
              onClick={() => handleFolderClick(option)}
            >
              {getIconV2(optionName)}
              <IconFolderFill className="text-muted" />
              <div className="font-medium">{optionName}</div>
            </div>
          )})}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-close" onClick={hide}>
          Cancel
        </button>
        <button type="button" disabled={isMoveEmailLoader} class="btn btn-primary" onClick={() => callback(selectedFolder?.id)}>
          {isMoveEmailLoader && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}{" "}
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveToModal;
