import React, { useRef, useState, useEffect } from 'react'
import DateEditor from '../CellComponents/DateEditor';
import { formatToDateAndTime, generateStatusColor } from '../../Functions';
import AppointmentModal from "../../../../Dispatcher/Appointment";
import ModifyAppointment from '../../../../Dispatcher/Appointment/ModifyAppointment';
import ColorPortal, {cellColorSetter} from "../portals/ColorPortal";
import { connect, useSelector } from 'react-redux';
import PickupAppointmentModal from '../../../../AppointmentModal';
import PickupAutoAppointmentModal from '../../../../AppointmentModal/AutoAppointmentModal';
import { toastr } from '../../../../../../services';
import { checkIfPortIsAccepted, checkIfAllowedForDirectBooking } from '../../../utility';
import RequestedAppointmentTimeInfo from '../../../../Load/Components/RequestedAppointmentTimeInfo';
import { APPOINTMENT_STATUSES, MOVE_TYPES } from '../../../../../../constants';
import { useDispatch } from 'react-redux'
import ACTIONS from 'pages/tms/AppointmentModal/store/actions'
import { useCurrentUserSettings } from 'hooks'
import { isNewAppointmentUIEnabled } from '../../../../../../services';
import { useAutoAppointmentSystem } from '../../CustomHooks/useAutoAppointmentSystem';


const PickupTimes = ({ row, column, onRowChange, isCellSelected, appSettings, standardPorts , acceptedPorts, newPorts }) => {
	const [showBox, setShowBox] = useState(false)
	const [isColorPortal, setColorPortal] = useState(false);
	const [isAppointmentProcessing, setIsAppointmentProcessing] = useState(false);
	
	const dispatch = useDispatch()
	const fAction = useSelector((state) => state.dispatcherTableReducer);
	const { isAppointmentFeatureEnabled } = useCurrentUserSettings()

	//For Appointment Modal
	const [showAppointmentModal, setShowAppointmentModal] = useState(false);
	const { buildPayload } = useAutoAppointmentSystem();

	const isPortAccepted = checkIfPortIsAccepted(row?.shipper?.[0]?.company_name ?? row?.shipperName, standardPorts, acceptedPorts, row.type_of_load, newPorts);
	const isAllowedForDirectBooking = checkIfAllowedForDirectBooking(row?.shipper?.[0]?.company_name ?? row?.shipperName, standardPorts);

	const handleOpenAppointmentModal = () => {
		if (isAppointmentFeatureEnabled) {
			isAllowedForDirectBooking && setIsAppointmentProcessing(true);
			setShowAppointmentModal(true);
		} else {
			toastr.show("Please enable Appointment Feature to schedule appointment!", "warning")
		}
	};

	const handleCloseAppointmentModal = () => {
		setShowAppointmentModal(false);
	};

	const cellRef = useRef()
	const prevSelectionRef = useRef();

	const hideModel = () => {
		setShowBox(false)
	}

	const showModel = () => {
		setShowBox(true)
	}

	const updatePickupTimes = (data) => {
		if (Object.keys(data).length > 0) {
		  if (data.type === "IMPORT PICKUP" && row.pickupTimes && row.pickupTimes.length > 0) {
			row.pickupTimes[0] = {
				...row.pickupTimes[0],
				pickupFromTime: data.apptFrom
			}
			row.pickUpApptStatus = data.status
		  }
		}
	}

	useEffect(() => {
		if (prevSelectionRef.current === false && isCellSelected && !isColorPortal) {
			showModel()
		}
		prevSelectionRef.current = isCellSelected
		if (!isCellSelected) {
			hideModel();
			setColorPortal(false);
		}
	}, [isCellSelected, isColorPortal]);

	useEffect(() => {
		cellColorSetter(cellRef, row?.colorOfCells?.pickupTimes);
	}, [column, row?.colorOfCells?.pickupTimes]);


	
	// For Manual Booking
	const handleOpenManualBooking=()=>{
		if (isAppointmentFeatureEnabled) {
			const appointmentData = {
				load: row,
				moveType: MOVE_TYPES.PICKUP,
				appointmentId: row?.pickupAppointmentSystem
			}
			dispatch({ type: ACTIONS.OPEN_MANUAL_BOOKING_MODAL, payload: appointmentData });
		} else {
			toastr.show("Please enable Appointment Feature to book an appointment!", "warning")
		}
	}

	useEffect(() => {
		if (fAction.reference_number === row.reference_number && fAction.action === 'RESET_ANYKEY' && fAction.field === "pickupTimes") {
			onRowChange({ ...row, pickupTimes: fAction.value });
			dispatch({ type: 'CLEAR_VALUE' });
		}
	}, [fAction])
	return (
		<>
			<div ref={cellRef} className="cell-content cell-content--fill" onClick={() => showModel()} onContextMenu={(e) => { e.preventDefault(); setColorPortal(true); }}>

				<div className='rdg-truncate'>
					{row?.pickupTimes && row?.pickupTimes.length>0 &&
						row?.pickupTimes?.map((obj) => {
							return formatToDateAndTime(obj?.pickupFromTime);
							})
							.compact()
							.join(" ")}
							{/* 
							**Consult Umanga before uncommenting to avoid unnecessary issue on cell.**
							{!row?.pickupTimes?.find(el=>el.pickupFromTime) && <span className='date_field'></span>} */}
				</div>
				{/* Portbase Appointment Status */}
				<div className={`badge badge-sm badge-${generateStatusColor(row.pickupAppointment?.status?.toUpperCase())}`}>{row.pickupAppointment?.status}</div>

				{/* Appointment Status */}
				{row.pickUpApptStatus !== APPOINTMENT_STATUSES.CANCELLED && <RequestedAppointmentTimeInfo label="Pick Up" moveType={MOVE_TYPES.PICKUP} load={row} isAllowedForDirectBooking={isAllowedForDirectBooking} />}
			</div>

			{/* TODO- Show Appointment Modal for trackos enabled???? Old date picker ?? */}
			{showAppointmentModal && ( !isNewAppointmentUIEnabled() ? (
        		<PickupAppointmentModal
					load={row}
					moveType="PICKUP"
					closeModal={handleCloseAppointmentModal}
					appointmentId={row?.pickupAppointmentSystem}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
					bookDirectAppointment={(isBooked) => {
						if (isAllowedForDirectBooking) {
							setShowBox(isBooked);
							setIsAppointmentProcessing(isBooked);
							setShowAppointmentModal(isBooked);
						}
					}}
			   	/>
			):
			(
				<PickupAutoAppointmentModal
					load={row}
					moveType="PICKUP"
					closeModal={handleCloseAppointmentModal}
					appointmentId={row?.pickupAppointmentSystem}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
					buildPayload={buildPayload}
					setIsAppointmentProcessing={setIsAppointmentProcessing}
				/>
			)
			)}
			{row?.isEditPermission && showBox && row.pickupTimes?.length > 0
				&& <DateEditor
					CellRef={cellRef}
					onClose={() => {
						hideModel();
						const a = document.querySelector('[aria-selected="true"]');
						if (a) a.focus();
					}}
					openAppointmentModal={handleOpenAppointmentModal} //Opening Appointment Modal From Here.
					onOpen={showModel}
					row={row}
					column={column}
					onRowChange={onRowChange}
					isPortAccepted={isPortAccepted}
					appointmentId={row?.pickupAppointmentSystem}
					autoAppointmentId={row?.pickupAutoAppointmentSystem}
					apptStatus={row?.pickUpApptStatus}
					moveType={MOVE_TYPES.PICKUP}
					openManualBooking={handleOpenManualBooking}
					standardPorts={standardPorts}
					isAppointmentProcessing={isAppointmentProcessing}
					isAllowedForDirectBooking={isAllowedForDirectBooking}
					/>
			}
			{isColorPortal &&
				<ColorPortal CellRef={cellRef} onClose={(val) => setColorPortal(val)} loadId={row?._id} fieldName="pickupTimes" defaultCellColors={row?.colorOfCells || {}} Row={row} onRowChange={onRowChange}/>
			}
	
		</>
	)
}

function mapStateToProps(state) {
    return {
        appSettings: state.TmsReducer.appSettings,
		standardPorts: state.TmsReducer.standard_ports,
		acceptedPorts: state.TmsReducer.acceptedPorts,
		newPorts: state.TmsReducer.newPorts,
    }
}

export default connect(mapStateToProps, null)(PickupTimes);
