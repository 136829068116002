export const aliasMap = {
  "TRAPAC OAK": ["TRAPAC OAKLAND"],
  "TRAPAC JAX": ["TRAPAC JACKSONVILLE"],
  "MAHER TERMINAL": ["MAHER ELIZABETH"],
  "APM": ["APM ELIZABETH"],
  "PNCT": ["PNCT NEWARK"],
  "FIT TERMINAL": ["FIT"],
  "SFCT TERMINAL": ["SFCT"],
  "CPR SCHILLER PARK": ["CP SCHILLER PARK"],
  "GCT DELTAPORT": ["DELTAPORT"],
  "GCT VANTERM": ["VANTERM"]
};